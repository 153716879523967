export const H2 = 'H2';
export const H3 = 'H3';
export const H4 = 'H4';
export const H5 = 'H5';
export const H6 = 'H6';
export const HR = 'HR';
export const SPECIAL_CHARACTER = 'special-character';
export const EMBED_BOOKING = 'embed-booking';
export const MARKUP = 'markup';
export const BLOCKQUOTE = 'blockquote';
export const LISTBULLETED = 'listbulleted';
export const INFOBOX = 'infobox';
export const NUMBERBOX = 'numberbox';
export const HIDE_SHOW = 'hide-show';
export const QUESTIONANSWER = 'questionanswer';
export const LISTNUMBERED = 'listnumbered';
export const TABLE = 'table';
export const RELATED_ARTICLE = 'related';
export const TWITTER = 'twitter';
export const FACEBOOK = 'facebook';
export const INSTAGRAM = 'instagram';
export const IFRAME = 'iframe';
export const HTML = 'html';
export const ARTICLE_LINK = 'article-link';
export const GALLERY = 'gallery';
export const IMAGE = 'image';
export const VIDEO = 'video';
export const LIVEBLOG = 'liveblog';
export const STORY = 'story';

export const CKEDITOR_CONFIG_BASE = {
  typing: {
    transformations: {
      extra: [
        {
          // convert single quote to closing quote (not in pair)
          from: /(\w)(')(\w?)$/,
          to: [null, '\u2019', null],
        },
      ],
    },
  },
};

export const CKEDITOR_BALLOON_TOOLBAR = [
  'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
  '|', 'link',
];

export const CKEDITOR_SIMPLE_TOOLBAR = [
  'bold', 'italic', 'underline', 'link',
];

export const TEXT_ENABLED_COMPONENT = new Set([
  MARKUP, LISTBULLETED, LISTNUMBERED, H2, H3, H4, H5, H6,
]);

export const SINGLE_MARKUP_COMPONENT = new Set([
  MARKUP, H2, H3, H4, H5, H6,
]);
