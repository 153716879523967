export const MEDIA_IMAGE_SAVE = 'MEDIA_IMAGE_SAVE';
export const MEDIA_IMAGE_SAVE_EDIT = 'MEDIA_IMAGE_SAVE_EDIT';
export const MEDIA_IMAGE_SAVE_CALLBACK = 'MEDIA_IMAGE_SAVE_CALLBACK';
export const MEDIA_CREATE_SUCCESS = 'MEDIA_CREATE_SUCCESS';
export const MEDIA_CREATE_REJECTED = 'MEDIA_CREATE_REJECTED';
export const MEDIA_EDIT_SUCCESS = 'MEDIA_EDIT_SUCCESS';
export const MEDIA_EDIT_REJECTED = 'MEDIA_EDIT_REJECTED';
export const MEDIA_CREATE_ENTITY = 'MEDIA_CREATE_ENTITY';
export const MEDIA_CREATE_ENTITY_SUCCESS = 'MEDIA_CREATE_ENTITY_SUCCESS';
export const MEDIA_CREATE_ENTITY_REJECTED = 'MEDIA_CREATE_ENTITY_REJECTED';

export const CHP_FETCH_MEDIA = 'CHP_FETCH_MEDIA';
export const CHP_FETCH_MEDIA_SUCCESS = 'CHP_FETCH_MEDIA_SUCCESS';
export const CHP_FETCH_MEDIA_REJECTED = 'CHP_FETCH_MEDIA_REJECTED';

export const GALLERY_FETCH = 'GALLERY_FETCH';
export const GALLERY_FETCH_SUCCESS = 'GALLERY_FETCH_SUCCESS';
export const GALLERY_FETCH_REJECTED = 'GALLERY_FETCH_REJECTED';
export const GALLERY_INIT = 'GALLERY_INIT';
export const GALLERY_DISPOSE = 'GALLERY_DISPOSE';
export const GALLERY_CONTEXT_SET = 'GALLERY_CONTEXT_SET';
export const GALLERY_CONTEXT_RESET = 'GALLERY_CONTEXT_RESET';

export const GALLERY_SAVE = 'GALLERY_SAVE';
export const GALLERY_SAVE_READY = 'GALLERY_SAVE_READY';
export const GALLERY_SAVE_REJECTED = 'GALLERY_SAVE_REJECTED';
export const GALLERY_SAVE_SUCCESS = 'GALLERY_SAVE_SUCCESS';

export const GALLERY_SEARCH = 'GALLERY_SEARCH';
export const GALLERY_SEARCH_SUCCESS = 'GALLERY_SEARCH_SUCCESS';
export const GALLERY_SEARCH_REJECTED = 'GALLERY_SEARCH_REJECTED';

export const VIDEO_JWPLAYER_SEARCH = 'VIDEO_JWPLAYER_SEARCH';
export const VIDEO_JWPLAYER_SEARCH_SUCCESS = 'VIDEO_JWPLAYER_SEARCH_SUCCESS';
export const VIDEO_JWPLAYER_SEARCH_REJECTED = 'VIDEO_JWPLAYER_SEARCH_REJECTED';
export const VIDEO_DUGOUT_SEARCH = 'VIDEO_DUGOUT_SEARCH';
export const VIDEO_DUGOUT_SEARCH_SUCCESS = 'VIDEO_DUGOUT_SEARCH_SUCCESS';
export const VIDEO_DUGOUT_SEARCH_REJECTED = 'VIDEO_DUGOUT_SEARCH_REJECTED';

export const PLAYLIST_FETCH = 'PLAYLIST_FETCH';
export const PLAYLIST_FETCH_SUCCESS = 'PLAYLIST_FETCH_SUCCESS';
export const PLAYLIST_FETCH_REJECTED = 'PLAYLIST_FETCH_REJECTED';
