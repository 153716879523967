import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import TextButton from '../../ui/buttons/TextButton';
import BookingDialog from '../../dialog/BookingDialog';

const BookingEmbed = ({ onChangeHotel, onChangeAmenities, data }) => {
  const { hotel, amenities } = data;
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Grid>
      {hotel && hotel.name && <Typography varient={'body2'}>{hotel.name}</Typography>}
      <TextButton onClick={() => setDialogOpen(true)}>{hotel && hotel.id ? 'Change Hotel' : 'Add Hotel'}</TextButton>
      {dialogOpen && <BookingDialog
        open={dialogOpen}
        hotel={hotel}
        amenities={amenities}
        onChangeHotel={value => onChangeHotel({ name: value.label, id: value.value })}
        onChangeAmenities={onChangeAmenities}
        handleClose={() => setDialogOpen(false)}
      />}
    </Grid>
  );
};

BookingEmbed.propTypes = {
  onChangeHotel: PropTypes.func.isRequired,
  onChangeAmenities: PropTypes.func.isRequired,
  data: PropTypes.object,
};

BookingEmbed.defaultProps = {
  data: {},
};

export default BookingEmbed;
