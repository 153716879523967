import {
  ARTICLE_EDITION_NOTE_DELETE,
  ARTICLE_EDITION_NOTE_SAVE,



} from '../constants/actionTypes';
import {
  EDITION_ARTICLE_ADD_VOCAB,
  EDITION_ARTICLE_REPLACE_VOCAB,
  ADD_MORE_EDITION_ARTICLE_COMPONENT,
  ARTICLE_EDITION_IMAGE_SELECTED,
  DROP_ARTICLE_EDITION_COMPONENT,
  EDITION_ARTICLE_IMAGE_REQUEST,
  EDITION_ARTICLE_MARKUP_PASTE_FROM_CLIPBOARD,
  EDITION_DETAIL_FETCH,
  EDITION_DETAIL_SAVE,
  EDITION_LIST_DRAFT_ONLOAD,
  EDITION_LIST_ONLOAD,
  EDITION_PAGE_RE_ORDER,
  EDITION_PREVIEW_COMPONENT_CLICK,
  ENTER_ARTICLE_EDITION_CREATION,
  EXPORT_ARTICLE_TO_EDITION,
  MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY,
  MERGE_EDITION_ARTICLE_COMPONENT_TO_PREVIOUS,
  ON_ENTER_ARTICLE_EDITION_CREATION,
  REMOVE_ARTICLE_EDITION_HERO_IMAGE,
  REMOVE_ARTICLE_EDITION_VOCAB,
  REMOVE_EDITION_ARTICLE_COMPONENT,
  SAVE_ARTICLE_EDITION,
  SAVE_EDITION_ARTICLE_COMPONENT,
  SET_ARTICLE_EDITION_COMPONENT_PROPERTY,
  SET_ARTICLE_EDITION_PROPERTY,
  SET_FOCUS_EDITION_ARTICLE_COMPONENT,
  SORT_EDITION_ARTICLE_COMPONENT,
  EDITION_DETAIL_NEW,
  EDITION_DETAIL_UPDATE,
  ADD_RELATED_ARTICLE_EDITION,
  REMOVE_RELATED_ARTICLE_EDITION,
  SORT_RELATED_ARTICLE_EDITION,
  CHANGE_RELATED_ARTICLE_PROPERTY,
  REMOVE_PAGE_FROM_CONTAINER,
  EDITION_SECTION_BODY_DISPOSE,
  SET_EDITION_FIELD_LOCK,
  EDITION_UPDATE_PING_APP,
  MOVE_ARTICLE_EDITION,
  EDITION_ARTICLE_ENTER_PAGE,
  EDITION_DISPOSE_PAGE,
  EDITION_DETAIL_ENTER,
  EDITION_DETAIL_DISPOSE,
  EDITION_BODY_SET_COMPONENT_PROPERTY,
  ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY,
  EDITION_REVISION_REVERT,
  CLONE_PAGE_EDITION,
  SET_IMAGE_HERO_ARTICLE_EDITION,
  EDITION_EXPORT_SCHEDULE,
  EDITION_EXPORT_UNSCHEDULE,
  EDITION_EXPORT_FETCH_SCHEDULED,
  EDITION_EXPORT_FTP_DELETE,
  EDITION_EXPORT_FTP_SAVE,
  EDITION_FTP_LOG_DISPOSE,
  EDITION_FTP_LOG_REQUEST,
  EDITION_EXPORT_FTP_FETCH_LIST,
} from '../constants/actionTypes/edition';

export const pingEditionApp = value => ({ type: EDITION_UPDATE_PING_APP, value });
export const editionListLoad = value => ({ type: EDITION_LIST_ONLOAD, value });
export const editionListDraftLoad = value => ({ type: EDITION_LIST_DRAFT_ONLOAD, value });
export const exportToEdition = value => ({ type: EXPORT_ARTICLE_TO_EDITION, value });
export const editionDetailFetch = value => ({ type: EDITION_DETAIL_FETCH, value });
export const editionArticleEnterPage = () => ({ type: EDITION_ARTICLE_ENTER_PAGE });
export const editionDisposePage = () => ({ type: EDITION_DISPOSE_PAGE });
export const editionDetailNew = () => ({ type: EDITION_DETAIL_NEW });
export const saveEditionDetail = value => ({ type: EDITION_DETAIL_SAVE, value });
export const selectedArticleImage = value => ({ type: ARTICLE_EDITION_IMAGE_SELECTED, value });
export const reOrderEditionPages = value => ({ type: EDITION_PAGE_RE_ORDER, value });
export const editionDetailEnter = () => ({ type: EDITION_DETAIL_ENTER });
export const editionDetailDispose = () => ({ type: EDITION_DETAIL_DISPOSE });
export const dropArticleEditionComponent = value =>
  ({ type: DROP_ARTICLE_EDITION_COMPONENT, value });
export const saveMarkupData = value => ({ type: SAVE_EDITION_ARTICLE_COMPONENT, value });
export const sortArticleComponent = value => ({ type: SORT_EDITION_ARTICLE_COMPONENT, value });
export const setFocusComponentIndex = value =>
  ({ type: SET_FOCUS_EDITION_ARTICLE_COMPONENT, value });
export const addMoreMarkup = value => ({ type: ADD_MORE_EDITION_ARTICLE_COMPONENT, value });
export const removeArticleComponent = value => ({ type: REMOVE_EDITION_ARTICLE_COMPONENT, value });
export const mergeMarkupToPrevious = value =>
  ({ type: MERGE_EDITION_ARTICLE_COMPONENT_TO_PREVIOUS, value });
export const currentComponentImageRequest = value =>
  ({ type: EDITION_ARTICLE_IMAGE_REQUEST, value });
export const onPasteFromClipboard = value =>
  ({ type: EDITION_ARTICLE_MARKUP_PASTE_FROM_CLIPBOARD, value });
export const onEnterArticleCreation = value =>
  ({ type: ON_ENTER_ARTICLE_EDITION_CREATION, value });
export const enterArticleEditionCreation = value =>
  ({ type: ENTER_ARTICLE_EDITION_CREATION, value });
export const saveArticleEdition = value => ({ type: SAVE_ARTICLE_EDITION, value });
export const onClone = value => ({ type: CLONE_PAGE_EDITION, value });
export const moveArticleEdition = value => ({ type: MOVE_ARTICLE_EDITION, value });
export const setArticleEditionProperty = value => ({ type: SET_ARTICLE_EDITION_PROPERTY, value });
export const addRelatedArticle = value => ({ type: ADD_RELATED_ARTICLE_EDITION, value });
export const removeRelatedArticle = value => ({ type: REMOVE_RELATED_ARTICLE_EDITION, value });
export const overrideProperty = value => ({ type: CHANGE_RELATED_ARTICLE_PROPERTY, value });
export const sortRelatedArticle = value => ({ type: SORT_RELATED_ARTICLE_EDITION, value });
export const onSelectTerm = value => ({ type: EDITION_ARTICLE_ADD_VOCAB, value });
export const onRemoveTerm = value => ({ type: REMOVE_ARTICLE_EDITION_VOCAB, value });
export const onReplaceTerm = value => ({ type: EDITION_ARTICLE_REPLACE_VOCAB, value });
export const setHeroImageComponent = value =>
  ({ type: SET_IMAGE_HERO_ARTICLE_EDITION, value });
export const removeHeroImageComponent = value =>
  ({ type: REMOVE_ARTICLE_EDITION_HERO_IMAGE, value });
export const setEditionBodyComponentProperty = value =>
  ({ type: EDITION_BODY_SET_COMPONENT_PROPERTY, value });
export const setArticleEditionComponentProperty = value =>
  ({ type: SET_ARTICLE_EDITION_COMPONENT_PROPERTY, value });
export const mergeArticleEditionComponentProperty = value =>
  ({ type: MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY, value });
export const resetEditionPreviewClick = () =>
  ({ type: EDITION_PREVIEW_COMPONENT_CLICK, value: -1 });
export const resetBody = () =>
  ({ type: EDITION_SECTION_BODY_DISPOSE });
export const changeHeroImageProperty = value => ({ type: ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY, value });
export const updateEditionDetail = value => ({ type: EDITION_DETAIL_UPDATE, value });
export const removePageFromContainer = value => ({ type: REMOVE_PAGE_FROM_CONTAINER, value });
export const saveNote = value => ({ type: ARTICLE_EDITION_NOTE_SAVE, value });
export const deleteNote = value => ({ type: ARTICLE_EDITION_NOTE_DELETE, value });

export const requestFTPLog = value => ({ type: EDITION_FTP_LOG_REQUEST, value });
export const disposeFTPLog = () => ({ type: EDITION_FTP_LOG_DISPOSE });
export const scheduleExport = value => ({ type: EDITION_EXPORT_SCHEDULE, value });
export const unscheduleExport = value => ({ type: EDITION_EXPORT_UNSCHEDULE, value });
export const saveExportFTP = value => ({ type: EDITION_EXPORT_FTP_SAVE, value });
export const fetchExportSettingTimeList = () => ({ type: EDITION_EXPORT_FETCH_SCHEDULED });
export const fetchExportSettingFTPList = () => ({ type: EDITION_EXPORT_FTP_FETCH_LIST });
export const deleteFTPEntry = value => ({ type: EDITION_EXPORT_FTP_DELETE, value });

export const setEditionLockField = value => ({ type: SET_EDITION_FIELD_LOCK, value });
export const editionRevert = value => ({ type: EDITION_REVISION_REVERT, value });
