import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as moment from 'moment';

import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';

import { Table, TableBody, TableHead, TableHeadCell, TableRow, TableCell, TableCellThumbnail } from '../ui/table/Table';
import { CMS_DATE_FORMAT } from '../../constants/common';

const EditionList = ({ type, title, list, push: routeChange, environment: { web } }) => (
  <Table>
    <TableHead>
      <TableRow hasBorder>
        <TableHeadCell colSpan={3}>{title && title}</TableHeadCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {list.map(({ data }) => (
        <TableRow hasActions key={data.id}>
          <TableCell>
            <TableCellThumbnail alt={data.key} src={`${web.replace(/\/$/, '')}/edition/screenshot/${data.key}.jpg`} />
          </TableCell>
          <TableCell fill>
            <Typography variant={'body1'}>{data.title}</Typography>
            {data.editionUpdated && <Typography variant={'caption'}>Last saved: {moment.unix(data.editionUpdated).format(CMS_DATE_FORMAT)}</Typography>}
          </TableCell>
          <TableCell>
            <Grid container wrap={'nowrap'} justify={'flex-end'}>
              <IconButton
                color={'primary'}
                onClick={() => routeChange(`/edition/${type}/${data.id}`)}
              ><EditIcon /></IconButton>
              <IconButton
                color={'primary'}
                onClick={() => routeChange(`/edition/${type}/${data.id}/browse`)}
              ><LaunchIcon /></IconButton>
            </Grid>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

EditionList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  push: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  environment: PropTypes.object.isRequired,
};

EditionList.defaultProps = {
  list: [],
  title: null,
};

export default connect(
  ({ frame: { selectedPublication: { environment } } }) => ({ environment }),
  { push },
)(EditionList);
