import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setArticleEditionProperty,
  setEditionLockField,
} from '../../../../actions/edition';

import { FIELD_AUTHOR_INFO } from '../../../../constants/edition/editionFields';
import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfieldDepricated';

/**
 * @todo review duplication, maybe should be moved to builder
 */
const AuthorInfo = ({
  authorInfo,
  setArticleEditionProperty: setProperty,
  setEditionLockField: lockField,
}) => (
  <FieldLockTextfield
    label={'Author(s) information'}
    field={FIELD_AUTHOR_INFO}
    onUnlock={() => lockField([FIELD_AUTHOR_INFO, authorInfo])}
    value={authorInfo}
    placeholder={'Type author information here'}
    onChange={event => setProperty([FIELD_AUTHOR_INFO, event.target.value])}
  />
);

AuthorInfo.propTypes = {
  authorInfo: PropTypes.string.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default connect(
  ({ edition: { authorInfo } }) => ({ authorInfo }),
  { setArticleEditionProperty, setEditionLockField },
)(AuthorInfo);

