import {
  DATASTATE_SET_DATA,
  DATASTATE_DISPOSE,
  DATASTATE_SET_PROPERTY,
  DATASTATE_UNSET_PROPERTY,
  DATASTATE_SERVER_SET_DATA,
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_SERVER_SET_PROPERTY,
  DATASTATE_LOCAL_SET_PROPERTY,
  DATASTATE_LOCAL_UNSET_PROPERTY,
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_EXTERNAL_SET_DATA,
  DATASTATE_EXTERNAL_DISPOSE_DATA,
  DATASTATE_EXTERNAL_SET_PROPERTY,
  DATASTATE_LOCAL_PROP_SET_PROPERTY,
  DATASTATE_LOCAL_PROP_SORT_ITEMS,
  DATASTATE_LOCAL_PROP_REMOVE_ITEM,
  DATASTATE_LOCAL_PROP_ADD_ITEM,
  DATASTATE_LOCAL_SET_DATA,
  DATASTATE_LOCAL_SORT_KEYED_ITEMS,
  DATASTATE_LOCAL_REMOVE_KEYED_ITEM, DATASTATE_LOCAL_ADD_KEYED_ITEM,
} from '../constants/actionTypes/dataState';

export const setDataState = value => ({ type: DATASTATE_SET_DATA, value });
export const disposeDataState = () => ({ type: DATASTATE_DISPOSE });
export const setDataStateProp = (prop, value, propChain) =>
  ({ type: DATASTATE_SET_PROPERTY, value: { prop, value, propChain } });
export const unsetDataStateProp = value => ({ type: DATASTATE_UNSET_PROPERTY, value });

export const setServerData = value => ({ type: DATASTATE_SERVER_SET_DATA, value });
export const disposeServerData = () => ({ type: DATASTATE_SERVER_DISPOSE_DATA });
export const setServerProp = (prop, value, propChain) =>
  ({ type: DATASTATE_SERVER_SET_PROPERTY, value: { prop, value, propChain } });

export const setExternalData = value => ({ type: DATASTATE_EXTERNAL_SET_DATA, value });
export const disposeExternalData = () => ({ type: DATASTATE_EXTERNAL_DISPOSE_DATA });
export const setExternalProp = (prop, value, propChain) =>
  ({ type: DATASTATE_EXTERNAL_SET_PROPERTY, value: { prop, value, propChain } });

// Local
export const setLocalData = value => ({ type: DATASTATE_LOCAL_SET_DATA, value });
export const disposeLocal = () => ({ type: DATASTATE_LOCAL_DISPOSE });
export const setLocalProp = (prop, value, propChain) =>
  ({ type: DATASTATE_LOCAL_SET_PROPERTY, value: { prop, value, propChain } });
export const unsetLocalProp = value => ({ type: DATASTATE_LOCAL_UNSET_PROPERTY, value });
export const addLocalKeyed = (prop, orderProp, orderIndex, key, item) => ({
  type: DATASTATE_LOCAL_ADD_KEYED_ITEM,
  value: { prop, orderProp, orderIndex, key, item },
});
export const removeLocalKeyed = (prop, orderProp, key) => ({
  type: DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
  value: { prop, orderProp, key },
});
export const sortLocalKeyed = (prop, oldIndex, newIndex) => ({
  type: DATASTATE_LOCAL_SORT_KEYED_ITEMS,
  value: { prop, oldIndex, newIndex },
});

// @todo deprecate below and remove usage
export const addToLocalProp = (prop, item) =>
  ({ type: DATASTATE_LOCAL_PROP_ADD_ITEM, value: { prop, item } });
export const removeFromLocalProp = (prop, index) =>
  ({ type: DATASTATE_LOCAL_PROP_REMOVE_ITEM, value: { prop, index } });
export const sortLocalProp = (prop, oldIndex, newIndex) =>
  ({ type: DATASTATE_LOCAL_PROP_SORT_ITEMS, value: { prop, oldIndex, newIndex } });
export const setItemPropOnLocal = (prop, index, itemProp, value, setOnObject) => ({
  type: DATASTATE_LOCAL_PROP_SET_PROPERTY,
  value: { prop, index, itemProp, value, setOnObject },
});
