import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import { vocabDelete, vocabMultipleUpdate } from '../../../actions/vocab';
import TextButton from '../../ui/buttons/TextButton';
import { SECTIONS } from '../../../constants/vocab';
import TaxonomyParentSelect from '../TaxonomyParentSelect';

const TaxonomySettings = ({
  // vocabDelete: onDelete, afterDelete,
  vocabMultipleUpdate: onUpdate,
  terms, vocab, sectionTree,
}) => {
  const [parents, setParents] = useState([]);
  return (<>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
    </ContextPanel>
    {vocab === SECTIONS && <ContextPanelDetails variant={'full'}><TaxonomyParentSelect
      id={'parent'}
      label={'Assign new parent'}
      value={parents.map(({ tid }) => tid)}
      onChange={(e) => {
        const { options } = e.target;
        const values = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
          if (options[i].selected) {
            values.push(options[i].value);
          }
        }
        setParents(sectionTree.filter(({ tid }) => values.includes(tid)));
      }}
      items={sectionTree}
      multiple
      native
      inputProps={{
        id: 'select-multiple-native',
      }}
    /></ContextPanelDetails>}
    <div style={{ marginTop: 20 }}>
      <ContextPanel>
        <ContextPanelDetails variant={'full'}>
          <Grid container justify={'space-between'}>
            {/*<TextButton*/}
            {/*  size={'large'}*/}
            {/*  onClick={() => {*/}
            {/*    if (window.confirm(`Please confirm you want to delete ${terms.length} item${terms.length === 1 ? '' : 's'} from the list`)) {*/}
            {/*      onDelete([vocab, terms]);*/}
            {/*      afterDelete();*/}
            {/*    }*/}
            {/*  }}*/}
            {/*  color={'warning'}*/}
            {/*>Delete</TextButton>*/}
            {vocab === SECTIONS && <TextButton
              size={'large'}
              disabled={parents.length === 0}
              onClick={() => {
                onUpdate([vocab, terms, {
                  parents,
                }]);
              }}
              color={'primary'}
            >Update</TextButton>}
          </Grid>
        </ContextPanelDetails>
      </ContextPanel>
    </div>
  </>);
};

TaxonomySettings.propTypes = {
  vocabMultipleUpdate: PropTypes.func.isRequired,
  // vocabDelete: PropTypes.func.isRequired,
  // afterDelete: PropTypes.func.isRequired,
  terms: PropTypes.array.isRequired,
  vocab: PropTypes.string.isRequired,
  sectionTree: PropTypes.object.isRequired,
};

export default connect(
  ({ vocab: { sectionTree } }) => ({ sectionTree }),
  { vocabDelete, vocabMultipleUpdate },
)(TaxonomySettings);
