import {
  APPEND_CONTACT_GROUP,
  APPEND_CONTACT_ITEM,
  CONTACT_US_PAGE_LOADED,
  REMOVE_CONTACT,
  REMOVE_CONTACT_GROUP,
  SAVE_CONTACT_US,
  SET_ADDRESS_PROP,
  SET_GROUP_PROP,
  SORT_CONTACT,
} from '../constants/staticPage/contactUs';

export const setAddressProperty = value => ({ type: SET_ADDRESS_PROP, value });
export const setGroupProperty = value => ({ type: SET_GROUP_PROP, value });
export const appendContactGroup = () => ({ type: APPEND_CONTACT_GROUP });
export const appendContact = value => ({ type: APPEND_CONTACT_ITEM, value });
export const saveContactUs = () => ({ type: SAVE_CONTACT_US });
export const contactUsPageLoaded = () => ({ type: CONTACT_US_PAGE_LOADED });
export const removeContact = value => ({ type: REMOVE_CONTACT, value });
export const removeContactGroup = value => ({ type: REMOVE_CONTACT_GROUP, value });
export const sortContact = value => ({ type: SORT_CONTACT, value });
