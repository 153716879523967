import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import { unlockFieldLock } from '../../actions/fieldLock';
import FieldLockOverlay from './FieldLockOverlay';

const styles = () => ({
  lock: {
    position: 'relative',
  },
});

const FieldLockContainer = ({
  classes, children, className, classWrapper, lockValue, field,
  unlockFieldLock: unlock, onUnlock,
  ...rest
}) => (
  <div className={classWrapper ? `${classWrapper} ${classes.lock}` : classes.lock} {...rest}>
    {children}
    {lockValue && <FieldLockOverlay
      className={className}
      lock={lockValue}
      onUnlock={() => {
        unlock(field);
        if (onUnlock) {
          onUnlock();
        }
      }}
    />}
  </div>
);

FieldLockContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  unlockFieldLock: PropTypes.func.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  classWrapper: PropTypes.string,
  lockValue: PropTypes.object,
  onUnlock: PropTypes.func,
};

FieldLockContainer.defaultProps = {
  lockValue: null,
  onUnlock: null,
  className: null,
  classWrapper: null,
};

export default withStyles(styles)(connect(
  () => ({}),
  { unlockFieldLock },
)(FieldLockContainer));
