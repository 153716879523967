import { equals } from 'rambdax';

// @todo include merging logic if required for internal locking
export const getArrayFromStates = (server, local, external) => {
  if (external.length > 0) return external;
  if (local.length > 0) return local;
  if (server.length > 0) return server;
  return [];
};

// @todo remove this requirement in refactor for sort vs data logic
export const getArrayChangesOnLocal = (server, local) =>
  local
    .map((item, index) => ({ item, index }))
    .filter(({ item }) => !server.some(serverItem => equals(item, serverItem)));
