import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './common/Dialog';
import MfaForm from 'components/login/common/MfaForm';

const MfaDialog = ({ onClose, ...rest }) => (
  <Dialog
    handleClose={onClose}
    title="TWO-FACTOR AUTHENTICATION SET UP"
    maxWidth={'sm'}
    margin
    {...rest}
  >
    <MfaForm />
  </Dialog>
);

MfaDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MfaDialog;
