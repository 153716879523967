import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';

import { setLocalProp } from '../../../../actions/dataState';

import { FIELD_FYI } from '../../../../constants/article/articleFields';
import {
  deserializeString,
  serializeNodes,
} from '../../../editor/helper/serializer';
import EditorSimple from '../../../editor/EditorSimple';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../../selectors/fieldLock/fieldLock';
import { MAX_LENGTH_SUB_HEADLINE } from '../../../../constants/article/article';
import { Typography } from '@material-ui/core';

const FYI = ({
  fyi, lockValue, hasChanges,
  setLocalProp: setProp, isProduct
}) => {
  const [valid, setValid] = useState(true);

  const renderFyI = (fyi) => {
    try {
      return deserializeString(fyi)
    } catch (err) {
      return []
    }
  }
  return (
    <>
      <Typography style={{ marginLeft: 10 }}>FYI</Typography>
      <div style={{ border: "2px solid #ebebeb", width: "100%", borderRadius: 4, padding: 12, margin: 6 }}>
        {!lockValue && <EditorSimple
          isProduct={isProduct}
          id={'standfirst'}
          content={renderFyI(fyi)}
          placeholder={'Fyi'}
          onChange={(nodes) => {
            const markup = serializeNodes(nodes);
            if (markup.length < MAX_LENGTH_SUB_HEADLINE) {
              setValid(true);
              setProp(FIELD_FYI, markup);
            } else {
              setValid(false);
            }
          }}
        />}
      </div>
      {!valid && <FormHelperText error>Sub headline is too long</FormHelperText>}
    </>
  );
};

FYI.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  fyi: PropTypes.string,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
};

FYI.defaultProps = {
  fyi: '',
  lockValue: null,
  hasChanges: false,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    fyi: getFieldValue(state, { field: FIELD_FYI }),
    lockValue: getFieldLockValue(state, { field: FIELD_FYI }),
    hasChanges: getFieldHasChanges(state, { field: FIELD_FYI }),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FYI);

