import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { CSSTransition } from 'react-transition-group';

const styles = theme => ({
  wrapper: {
    pointerEvents: 'none',
    zIndex: 2,
    width: '200vh',
    height: '200vh',
    right: '-45vh',
    bottom: '-10vh',
    transform: 'rotate(53deg)',
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      width: '160vw',
      height: '160vw',
      bottom: '-18vw',
      left: '-82vw',
      right: 'inherit',
    },
  },
  enter: {
    transition: 'bottom 1000ms, left 1000ms',
    '&.login-enter-active': {
      bottom: '-40vh',
    },
    '&.login-enter-done': {
      bottom: '-40vh',
    },
    [theme.breakpoints.up('md')]: {
      '&.login-enter-active': {
        bottom: '-25vw',
        left: '-15vw',
      },
      '&.login-enter-done': {
        bottom: '-25vw',
        left: '-15vw',
      },
    },
  },
  leave: {
    zIndex: 10000,
    bottom: '-40vh',
    transition: 'bottom 1000ms, left 1000ms',
    '&.login-enter-active': {
      bottom: '30vw',
    },
    '&.login-enter-done': {
      bottom: '30vw',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '-25vw',
      left: '-15vw',
      '&.login-enter-active': {
        left: '-125vw',
        bottom: '28vw',
      },
      '&.login-enter-done': {
        left: '-125vw',
        bottom: '28vw',
      },
    },
  },
  shape: {
    width: '100%',
    height: '100%',
    borderRadius: '3%',
    backgroundColor: theme.palette.background.contrast,
    transform: 'scaleX(0.62) rotate(-45deg)',
  },
});

const LoginBackground = ({ classes, inState, leave, ...rest }) => (
  <CSSTransition in={inState} timeout={leave ? 1000 : 1000} classNames={'login'} {...rest}>
    <div className={`${classes.wrapper} ${leave ? classes.leave : classes.enter}`}>
      <div className={classes.shape} />
    </div>
  </CSSTransition>
);

LoginBackground.propTypes = {
  classes: PropTypes.object.isRequired,
  inState: PropTypes.bool,
  leave: PropTypes.bool,
};

LoginBackground.defaultProps = {
  inState: false,
  leave: false,
};

export default withStyles(styles)(LoginBackground);
