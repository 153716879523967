import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getListArticles } from '../../../../utils/manualListHelper';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import { UI_HEIGHT } from '../common/ContextUI';

import { setLayoutContextFocus } from '../../../../actions/layout';
import {
  setListArticleProperty, unsetListArticleProperty,
  setPinnedArticleProperty, unsetPinnedArticleProperty,
} from '../../../../actions/manualList';

import TextField from '../../../contexts/form/TextField';
import LeadField from './articleform/LeadField';
import ShowRelatedLinksField from './articleform/ShowRelatedLinksField';
import CapsuleField from './articleform/CapsuleField';
import ImageField from '../../../contexts/form/ImageField';
import ShowLeadField from './articleform/ShowLeadField';
import RelatedLinksField from './articleform/RelatedLinksField';

import {
  FIELD_HEADLINE, FIELD_HERO_IMAGE,
  FIELD_SHORT_HEADLINE,
} from '../../../../constants/article/articleFields';
import {
  OVERRIDE_CAPSULE_HIGHLIGHT, OVERRIDE_CAPSULE_SECTION,
  OVERRIDE_RELATED_LINKS, OVERRIDE_TITLE,
  OVERRIDE_SHOW_LEAD, OVERRIDE_LEAD,
  OVERRIDE_SHOW_RELATED_LINKS, OVERRIDE_HERO, FIELD_PINNED,
} from '../../../../constants/layout/layout';
import { ARTICLE_ID } from '../../../../entities/ManualListEntity';
import {
  getArticleCountFromType,
  getComponentDefaultIndexes,
  getComponentLeadRequired,
  getRelatedLinkCount,
} from '../../../../utils/layoutHelper';

const BORDER_OFFSET = 3;

const getPanelScrollTop = (panel) => {
  const offset = panel.offsetTop;
  return (offset - UI_HEIGHT) + BORDER_OFFSET;
};

const ArticleEmpty = () => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>No article found</ContextPanelHeading>
    </ContextPanelSummary>
  </ContextPanel>
);

const ArticleOverrides = (props) => {
  const {
    component, domain, index, listId, itemId, article, setProp, unsetProp, focused,
  } = props;
  if (!article) return <ArticleEmpty />;
  const { overrides = {} } = article;
  const relatedLinkCount = getRelatedLinkCount(domain, component.type, index);
  const showLead = getComponentLeadRequired(domain, component.type, index);

  return (
    <ContextPanel focused={focused} data-type={'article-overrides'}>
      <ContextPanelSummary>
        <ContextPanelHeading>Article {index + 1}</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <TextField
          label={'Headline'}
          value={article[FIELD_SHORT_HEADLINE] || article[FIELD_HEADLINE]}
          overrideValue={overrides[OVERRIDE_TITLE]}
          allowEmpty
          onChange={e => setProp(
            listId,
            itemId,
            OVERRIDE_TITLE,
            e.target.value,
          )}
        />
        <ImageField
          component={component}
          defaultValue={article[FIELD_HERO_IMAGE]}
          overrideValue={overrides?.[OVERRIDE_HERO] ? overrides[OVERRIDE_HERO][0] : null}
          onRemove={() => {
            unsetProp(
              listId,
              itemId,
              OVERRIDE_HERO,
            );
          }}
          onSelect={value => setProp(
            listId,
            itemId,
            OVERRIDE_HERO,
            [
              {
                type: 'image',
                data: value,
              },
            ],
          )}
        />
        {showLead && <ShowLeadField
          value={overrides[OVERRIDE_SHOW_LEAD]}
          onChange={e => setProp(
            listId,
            itemId,
            OVERRIDE_SHOW_LEAD,
            e.target.checked,
          )}
        />}
        {showLead && overrides[OVERRIDE_SHOW_LEAD] && <LeadField
          defaultValue={article.lead}
          overrideValue={overrides[OVERRIDE_LEAD]}
          onChange={e => setProp(
            listId,
            itemId,
            OVERRIDE_LEAD,
            e.target.value,
          )}
        />}
        {relatedLinkCount > 0 && <ShowRelatedLinksField
          value={overrides[OVERRIDE_SHOW_RELATED_LINKS]}
          onChange={e => setProp(
            listId,
            itemId,
            OVERRIDE_SHOW_RELATED_LINKS,
            e.target.checked,
          )}
        />}
        {relatedLinkCount > 0 && overrides[OVERRIDE_SHOW_RELATED_LINKS] && <RelatedLinksField
          count={relatedLinkCount}
          value={overrides[OVERRIDE_RELATED_LINKS]}
          onChange={(e, i) => {
            const links = overrides[OVERRIDE_RELATED_LINKS].map((link, linkIndex) => (
              (linkIndex === i) ? {
                ...link,
                headline: e.target.value ? e.target.value : link.label,
              } : link
            ));
            setProp(
              listId,
              itemId,
              OVERRIDE_RELATED_LINKS,
              links,
            );
          }}
        />}
        <CapsuleField
          title={'Section'}
          defaultValue={article.section}
          overrideValue={overrides[OVERRIDE_CAPSULE_SECTION]}
          onChangeTitle={e => setProp(
            listId,
            itemId,
            OVERRIDE_CAPSULE_SECTION,
            { name: e.target.value },
          )}
          onChangeLink={e => setProp(
            listId,
            itemId,
            OVERRIDE_CAPSULE_SECTION,
            { path: e.target.value },
          )}
        />
        <CapsuleField
          title={'Highlight'}
          overrideValue={overrides[OVERRIDE_CAPSULE_HIGHLIGHT]}
          onChangeTitle={e => setProp(
            listId,
            itemId,
            OVERRIDE_CAPSULE_HIGHLIGHT,
            { name: e.target.value },
          )}
          onChangeLink={e => setProp(
            listId,
            itemId,
            OVERRIDE_CAPSULE_HIGHLIGHT,
            { path: e.target.value },
          )}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ArticleOverrides.propTypes = {
  component: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  listId: PropTypes.number.isRequired,
  itemId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  article: PropTypes.object.isRequired,
  setProp: PropTypes.func.isRequired,
  unsetProp: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
};

const ArticlesOverrides = (props) => {
  const {
    component, manualList, manualIndexes, data, domain,
    setListArticleProperty: overrideProperty,
    setPinnedArticleProperty: overridePinnedProperty,
    unsetListArticleProperty: unsetOverrideProperty,
    unsetPinnedArticleProperty: unsetOverridePinnedProperty,
    setLayoutContextFocus: setFocus,
  } = props;
  const [indexes, setIndexes] = useState([]);
  const { pinnedArticles } = component;
  const articleCount = getArticleCountFromType(component.type);
  const { targetIndex, focus, id } = data;
  useEffect(() => {
    if (manualIndexes) {
      setIndexes(manualIndexes);
    } else {
      setIndexes(getComponentDefaultIndexes(component));
    }
  }, [manualIndexes, component[FIELD_PINNED]]);
  useEffect(() => {
    if (typeof targetIndex !== 'undefined') {
      const target = document.querySelectorAll('[data-type="article-overrides"]')[targetIndex];
      if (typeof target !== 'undefined') {
        const parent = target.parentNode;
        parent.scrollTop = getPanelScrollTop(target);
        setFocus(targetIndex);
      }
    }
  }, [targetIndex, indexes]);
  useEffect(() => {
    const container = document.querySelector('#contextBody');
    const panels = document.querySelectorAll('[data-type="article-overrides"]');
    const positions = Array.from(panels).map(panel => (getPanelScrollTop(panel)));
    const onScroll = () => {
      const boundary = container.scrollTop + (container.clientHeight / 2);
      for (let i = 0; i < positions.length; i += 1) {
        if (boundary < positions[i]) {
          if (focus !== i) {
            setFocus(i);
          }
          break;
        }
        if (i === positions.length - 1) {
          if (focus !== i) {
            setFocus(i);
          }
          break;
        }
        if (boundary >= positions[i] && boundary < positions[i + 1]) {
          if (focus !== i) {
            setFocus(i);
          }
          break;
        }
      }
    };
    container.addEventListener('scroll', onScroll);
    return () => container.removeEventListener('scroll', onScroll);
  }, []);

  const articles = [];
  const listArticles = getListArticles(manualList);
  const articleIndexes = [...indexes];
  for (let index = 0; index < articleCount; index += 1) {
    if (typeof pinnedArticles !== 'undefined' && pinnedArticles[index]) {
      articles.push(
        <ArticleOverrides
          domain={domain}
          component={component}
          key={`article${index}`}
          index={index}
          listId={component.id} // for save purposes the component id replaced the listId
          itemId={index} // for save purposes the index replaced the itemId
          article={pinnedArticles[index]}
          setProp={overridePinnedProperty}
          unsetProp={unsetOverridePinnedProperty}
          focused={index === focus && component.id === id}
        />,
      );
    } else {
      const itemIndex = articleIndexes.shift();
      articles.push(
        <ArticleOverrides
          domain={domain}
          component={component}
          key={`article${index}`}
          index={index}
          listId={(manualList?.id) || null}
          itemId={listArticles?.[itemIndex]?.[ARTICLE_ID] || null}
          article={listArticles[itemIndex] || null}
          setProp={overrideProperty}
          unsetProp={unsetOverrideProperty}
          focused={index === focus && component.id === id}
        />,
      );
    }
  }
  return articles;
};

ArticlesOverrides.propTypes = {
  domain: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setListArticleProperty: PropTypes.func.isRequired,
  setPinnedArticleProperty: PropTypes.func.isRequired,
  unsetListArticleProperty: PropTypes.func.isRequired,
  unsetPinnedArticleProperty: PropTypes.func.isRequired,
  setLayoutContextFocus: PropTypes.func.isRequired,
  manualList: PropTypes.object,
  manualIndexes: PropTypes.array,
};

ArticleOverrides.defaultProps = {
  manualList: null,
  manualIndexes: null,
};

export default connect(
  ({
    frame: { selectedPublication: { domain } },
  }) => ({ domain }),
  {
    setListArticleProperty,
    unsetListArticleProperty,
    setPinnedArticleProperty,
    unsetPinnedArticleProperty,
    setLayoutContextFocus,
  },
)(ArticlesOverrides);
