import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_OPTA } from './defaults';

const renderElementOpta = (options) => {
  const { opta } = setDefaults(options, DEFAULTS_OPTA);
  return getRenderElement(opta);
};

export default renderElementOpta;
