import React, { useState } from 'react';
import { useSelector } from 'react-redux';  
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from 'components/ui/buttons/Button';
import Dialog from './common/Dialog';  

const style = theme => ({  
  mainDialog: { 
    height:400,  
  },
  gridContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    alignItems: 'center', 
    display: 'flex',
    height : '100%',  
    justifyContent:'space-between', 
  }, 
  downloadLinkContainer: {
    display: 'flex',
    flexDirection: 'row', 
    width:'content-width',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height:'content-height',
  },
  downloadLink: {
    display : 'flex',  
  },
  downloadButton: {
    width: 160,
    justifyContent: 'space-between', 
    borderRadius: '8px',
    height:45,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  completedMarkGrid:{
    justifyContent: 'center',
    alignItems: 'center',  
  },
  completedMarkDiv:{ 
    width:'100%', 
    height: 40,
    display: 'flex', 
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1), 
    alignItems: 'center', 
    justifyContent: 'start',
    left : '-4px',  
    position: 'relative',
  },
  completedMark: {  
    backgroudColour:'#ECF9F5', 
    color:'#08B6A9',  
    textAlign: 'center',
  },
});



const ManualFileDownloadDialog = ({ 
  classes,  
  editionId,
  pageId,
  pageType, 
  ...rest 
}) => { 
  const { selectedPublication } = useSelector((state) => state.frame); 
  const [downloadCompleted, setDownloadCompleted ] = useState({ singlePDF:'', a4Pdf:'', xml:'' }); 

  const completedMark = <Typography className={classes.completedMark}>Complete</Typography>;
  
  let frontEndUrl = selectedPublication.environment.web;
  frontEndUrl = frontEndUrl.replace(/\/$/, '');
  
  let entityType = 'article';
  switch (pageType) { 
    case 'pugpig_section_page':  
      entityType = 'section';
      break;
    default:
      break;
  }   
  const pdfLink = `${frontEndUrl}/edition/${entityType}/${pageId}?pdf&containerId=${editionId}`;

  return (
    <Dialog className={classes.mainDialog} open={true} title={'Select format'} {...rest}> 
      <DialogContent>
        <Grid  container spacing={1} className={classes.gridContainer}>
          <Grid item xs={3}></Grid>
          <Grid item xs={5}>
            <Grid item  className={classes.downloadLinkContainer} > 
              <Link 
                download={true}
                target="_blank"
                className={classes.downloadLink}
                href={`${pdfLink}&singlePage=1`} 
              >   
                <Button
                  className={classes.downloadButton}
                  variant="contained"
                  color="primary"  
                  endIcon={<GetAppIcon/>}
                  onClick={()=>setDownloadCompleted({ ...downloadCompleted, singlePDF:completedMark })}
                >Single PDF</Button>
              </Link>      
            </Grid>
            <Grid item  className={classes.downloadLinkContainer}>
              <Link 
                download={true}
                target="_blank"
                className={classes.downloadLink}
                href={pdfLink}  
              >   
                <Button
                  className={classes.downloadButton}
                  variant="contained"
                  color="primary"  
                  endIcon={<GetAppIcon/>}
                  onClick={()=>setDownloadCompleted({ ...downloadCompleted, a4Pdf:completedMark })}
                >A4 PDF</Button>
              </Link>     
            </Grid>
            <Grid item className={classes.downloadLinkContainer}> 
              <Link 
                download={true}
                target="_blank"
                className={classes.downloadLink}
                href={`${frontEndUrl}/edition/xml/${editionId}/${pageId}`} 
              >   
                <Button  
                  className={classes.downloadButton}
                  variant="contained"
                  color="primary"  
                  endIcon={<GetAppIcon/>}
                  onClick={()=>setDownloadCompleted({ ...downloadCompleted, xml:completedMark })}
                >XML</Button>
              </Link>          
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.completedMarkGrid}>
            <Grid item>
              <div className={classes.completedMarkDiv}>
                {downloadCompleted.singlePDF}
              </div>
            </Grid>
            <Grid item>
              <div className={classes.completedMarkDiv}>
                {downloadCompleted.a4Pdf} 
              </div>
            </Grid>
            <Grid item>
              <div className={classes.completedMarkDiv}>  
                {downloadCompleted.xml}
              </div>
            </Grid>
          </Grid>
        </Grid>  
      </DialogContent>
    </Dialog>);
};
 
ManualFileDownloadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  editionId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default withStyles(style)(ManualFileDownloadDialog);
