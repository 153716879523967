import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { i18nReducer } from 'react-redux-i18n';

import dataState from './dataState/dataState';
import localState from './dataState/localState';
import serverState from './dataState/serverState';
import externalState from './dataState/externalState';

import layout from './layout/layout';
import frame from './frame/frame';
import config from './config/config';
import configEntity from './config/configEntity';
import login from './login/login';
import resetPassword from './resetPassword/resetPassword';
import dialog from './dialog/dialog';
import sidebar from './sidebar/sidebar';
import menu from './menu/menu';
import userCurrent from './users/userCurrent';
import usersList from './users/usersList';
import userEdit from './users/userEdit';
import table from './table/table';
import ad from './ad/ad';
import affiliate from './affiliate/affiliate';
import manualList from './manualList/manualList';
import article from './article/article';
import scoreboard from './article/scoreboard';
import liveblog from './liveblog/liveblog';
import autocomplete from './autocomplete/autocomplete';
import drawer from './drawer/drawer';
import translation from './translation/translation';
import story from './story/story';
import storyList from './story/storyList';
import event from './event/event';
import dashboard from './dashboard/dashboard';
import media from './media/media';
import gallery from './media/gallery';
import video from './media/video';
import vocab from './vocab/vocab';
import stateHistory from './article/history';
import product from './product/product';
import productList from './product/productList';
import viafouraNotificationsList from './viafouraNotification/viafouraNotificationsList';
import revision from './article/revision';
import fieldLock from './fieldLock/fieldLock';
import edition from './edition/edition';
import redirect from './redirect/redirect';
import twitter from './socialMedia/twitter';
import feed from './feed/feed';
import contactUs from './staticPage/contactUs';
import staticPage from './staticPage/staticPage';

import userEvents from './service/userEvents';
import userComments from './service/userComments';

import booking from './integration/booking';
import playInc from './integration/playInc';

export default history => combineReducers({
  // Data states for a users interactions
  dataState,
  localState,
  serverState,
  externalState,
  // sort them, easier to find on redux console
  ad,
  affiliate,
  article,
  autocomplete,
  config,
  configEntity,
  contactUs,
  dashboard,
  dialog,
  drawer,
  edition,
  event,
  feed,
  fieldLock,
  frame,
  gallery,
  i18n: i18nReducer,
  integration: combineReducers({
    booking,
    playInc,
  }),
  layout,
  liveblog,
  login,
  manualList,
  media,
  menu,
  product,
  productList,
  redirect,
  resetPassword,
  revision,
  router: connectRouter(history),
  scoreboard,
  sidebar,
  stateHistory,
  staticPage,
  story,
  storyList,
  table,
  translation,
  twitter,
  userCurrent,
  userEdit,
  usersList,
  userEvents,
  userComments,
  viafouraNotificationsList,
  video,
  vocab,
});

