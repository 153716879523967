import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FlowPaper from '../layout/FlowPaper';

export const PagePanel = withStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}))(FlowPaper);

export const PagePanelSection = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:last-child': {
      borderBottom: '0 none',
    },
  },
}))(({ classes, children }) =>
  <div className={classes.root}>{children}</div>);

export const PagePanelHeading = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2.5, 3, 1.5),
  },
}))(({ classes, children }) =>
  <div className={classes.root}>{children}</div>);

export const PagePanelActions = withStyles(theme => ({
  root: {
    margin: theme.spacing(-2),
  },
}))(({ classes, children, withMargin = false  }) =>
  <div className={withMargin ? '' : classes.root}>{children}</div>);

export const PagePanelContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 3, 3),
  },
  noHeading: {
    padding: theme.spacing(3),
  },
}))(({ classes, children, noHeading = false, noPadding = false }) =>
  <div className={noPadding ? '' : noHeading ? classes.noHeading : classes.root}>{children}</div>);
