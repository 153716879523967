export const CONTEXT_DEFAULT = 'CONTEXT_DEFAULT';

export const CONTEXT_ARTICLE = 'CONTEXT_ARTICLE';
export const CONTEXT_COMPONENT = 'CONTEXT_COMPONENT';
export const CONTEXT_STORY = 'CONTEXT_STORY';
export const CONTEXT_LIVEBLOG = 'CONTEXT_LIVEBLOG';
export const CONTEXT_PRODUCT = 'CONTEXT_PRODUCT';
export const CONTEXT_IMAGE = 'CONTEXT_IMAGE';

export const CONTEXT_ARTICLE_SELECTOR = 'CONTEXT_ARTICLE_SELECTOR';
export const CONTEXT_COMPONENT_SELECTOR = 'CONTEXT_COMPONENT_SELECTOR';
export const CONTEXT_PAGE_SELECTOR = 'CONTEXT_PAGE_SELECTOR';

