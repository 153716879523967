import { PURGE } from 'redux-persist';
import { CONFIG_FETCH_SUCCESS, CONFIG_SET } from 'constants/actionTypes/config';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIG_FETCH_SUCCESS:
      return typeof action.value === 'object' && action.value !== null
        ? action.value
        : defaultState;

    case CONFIG_SET:
      return {
        ...state,
        [action.prop]: action.value,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
