import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { TablePagination } from '@material-ui/core';

import ArticleSearch from '../ui/table/filters/TextSearchFilter';
import ArticleResults, { SIZE_PER_PAGE } from './articleSelector/ArticleResults';

import {
  ContextPanel,
  ContextPanelDetails,
} from '../common/context/ContextPanel';
import { ContextBarBody } from '../common/context/ContextBar';

const styles = () => ({
  search: {
    width: '100%',
    padding: '10px 5px',
    boxSizing: 'border-box',
  },
});

const ArticleSelector = (props) => {
  const {
    classes,
    totalArticlePublished,
    query,
    setQuery,
    page,
    setPage,
  } = props;

  const setListPage = (e, i) => {
    setPage(i);
  };

  return (
    <ContextBarBody>
      <ContextPanel>
        <ContextPanelDetails variant={'full'}>
          <ArticleSearch
            className={classes.search}
            defaultValue={query}
            onSubmit={(textQuery) => {
              setQuery(textQuery);
              setPage(0);
            }}
          />
          <ArticleResults
            page={page}
            searchQuery={query}
          />
          {totalArticlePublished > SIZE_PER_PAGE && <TablePagination
            rowsPerPageOptions={[SIZE_PER_PAGE]}
            component="nav"
            count={totalArticlePublished}
            rowsPerPage={SIZE_PER_PAGE}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next',
            }}
            onChangePage={setListPage}
          />}
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBarBody>
  );
};

ArticleSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  totalArticlePublished: PropTypes.number,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

ArticleSelector.defaultProps = {
  totalArticlePublished: 0,
};

export default withStyles(styles)(connect(
  ({ article: { totalArticlePublished } }) =>
    ({ totalArticlePublished }),
  { },
)(ArticleSelector));
