import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import ToggleSwitch from '../../ui/ToggleSwitch';

import { setLiveblogProperty } from '../../../actions/liveblog';

const Status = (props) => {
  const { setLiveblogProperty: setProp, status, showLabel, className } = props;
  const switchTitle = (status) ? 'Active' : 'Inactive';

  return (
    <FormControl className={className} margin={'dense'}>
      {showLabel && <FormLabel>Status</FormLabel>}
      <ToggleSwitch
        label={switchTitle}
        labelPosition={'end'}
        value={!!(status)}
        onChange={e => setProp(['status', (e.target.checked) ? 1 : 0])}
      />
    </FormControl>
  );
};

Status.propTypes = {
  className: PropTypes.string,
  showLabel: PropTypes.bool,
  status: PropTypes.number,
  setLiveblogProperty: PropTypes.func.isRequired,
};

Status.defaultProps = {
  className: '',
  showLabel: true,
  status: 0,
};

export default connect(
  ({ liveblog: { status } }) => ({ status }),
  { setLiveblogProperty },
)(Status);

