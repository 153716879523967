import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableBase from '@material-ui/core/Table';
import TableBodyBase from '@material-ui/core/TableBody';
import TableCellBase from '@material-ui/core/TableCell';
import TableHeadBase from '@material-ui/core/TableHead';
import TablePaginationBase from '@material-ui/core/TablePagination';
import TableRowBase from '@material-ui/core/TableRow';
import TableSortLabelBase from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';

export const Table = withStyles(() => ({
  root: {},
}))(props => (<TableContainer><TableBase {...props} /></TableContainer>));

export const TableBody = withStyles(() => ({
  root: {},
}))(TableBodyBase);

export const TableHeadCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    '&:first-child': {
      padding: theme.spacing(1.5, 1.5, 1.5, 3),
    },
    '&:last-child': {
      padding: theme.spacing(1.5, 3, 1.5, 1.5),
    },
  },
  noPadding: {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    '&:first-child': {
      padding: theme.spacing(1.5, 1.5, 1.5, 0),
    },
    '&:last-child': {
      padding: theme.spacing(1.5, 0, 1.5, 0),
    },
  },
}))(({ classes, children, noPadding, ...rest }) => (
  <TableCellBase
    className={[
      classes.root,
      noPadding ? classes.noPadding : '',
    ].filter(str => str !== '').join(' ')}
    {...rest}
  >{children}</TableCellBase>
));

export const TableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1.5),
    '&:first-child': {
      padding: theme.spacing(3, 1.5, 3, 3),
    },
    '&:last-child': {
      padding: theme.spacing(3, 3, 3, 1.5),
    },
  },
  narrow: {
    padding: theme.spacing(1.5),
    '&:first-child': {
      padding: theme.spacing(1.5, 1.5, 1.5, 3),
    },
    '&:last-child': {
      padding: theme.spacing(1.5, 3, 1.5, 1.5),
    },
  },
  noPadding: {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    '&:first-child': {
      padding: theme.spacing(1.5, 1.5, 1.5, 0),
    },
    '&:last-child': {
      padding: theme.spacing(1.5, 0, 1.5, 0),
    },
  },
  fill: {
    width: '100%',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}))(({ classes, children, narrow, fill, noWrap, noPadding, ...rest }) => (
  <TableCellBase
    className={[
      classes.root,
      fill ? classes.fill : '',
      narrow ? classes.narrow : '',
      noWrap ? classes.noWrap : '',
      noPadding ? classes.noPadding : '',
    ].filter(str => str !== '').join(' ')}
    {...rest}
  >{children}</TableCellBase>
));

export const TableHead = withStyles(theme => ({
  root: {},
  noFill: {
    background: 'none',
    '& .MuiTableCell-head': {
      color: theme.palette.primary.main,
    },
  },
}))(({ classes, children, noFill, ...rest }) => (
  <TableHeadBase
    className={[
      classes.root,
      noFill ? classes.noFill : '',
    ].filter(str => str !== '').join(' ')}
    {...rest}
  >{children}</TableHeadBase>
));

export const TablePagination = withStyles(() => ({
  root: {},
  noCaption: {
    '& .MuiTablePagination-caption': {
      display: 'none',
    },
  },
}))(({ noCaption, classes, ...rest }) => <TablePaginationBase
  className={noCaption ? classes.noCaption : classes.root}
  {...rest}
/>);

export const TableRow = withStyles(theme => ({
  root: {
  },
  clickable: {
    borderLeft: `4px solid ${theme.palette.background.contrast}`,
    '&:hover': {
      borderLeftColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.hover,
      cursor: 'pointer',
    },
  },
  actions: {
    borderLeft: `4px solid ${theme.palette.background.contrast}`,
    '& .MuiTableCell-body:last-child *': {
      visibility: 'hidden',
    },
    '&:hover': {
      borderLeftColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.hover,
      '& .MuiTableCell-body:last-child *': {
        visibility: 'visible',
      },
    },
    '& .MuiIconButton-root': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
  hasBorder: {
    borderLeft: `4px solid ${theme.overrides.MuiTableHead.root.background}`,
  },
  selected: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.background.hover,
  },
}))(({ classes, className, children, selected, hasActions, onClick, hasBorder, ...rest }) => (
  <TableRowBase
    className={[
      classes.root,
      className,
      selected ? classes.selected : '',
      hasActions ? classes.actions : '',
      onClick ? classes.clickable : '',
      hasBorder ? classes.hasBorder : '',
    ].filter(str => str !== '').join(' ')}
    onClick={onClick}
    {...rest}
  >{children}</TableRowBase>
));

TableRow.propTypes = {
  className: PropTypes.string,
  hasActions: PropTypes.bool,
  selected: PropTypes.bool,
  hasBorder: PropTypes.bool,
  onClick: PropTypes.func,
};

TableRow.defaultProps = {
  className: '',
  hasActions: false,
  hasBorder: false,
  selected: false,
  onClick: null,
};

export const TableSortLabel = withStyles(() => ({
  root: {},
}))(TableSortLabelBase);

export const TableCellThumbnail = withStyles(() => ({
  image: {
    height: 80,
    width: 'auto',
  },
}))(({ classes, src, alt }) => (
  <img className={classes.image} src={src} alt={alt} />
));
