import { ofType } from 'redux-observable';
import { map, mergeMap} from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  VIAFOURA_NOTIFICATIONS_FETCH,
  VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS,
  VIAFOURA_NOTIFICATIONS_DISPOSE,
} from '../../constants/actionTypes/viafouraNotification';
import ViafouraNotificationEntity from '../../entities/ViafouraNotificationEntity';
import {
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_EXTERNAL_DISPOSE_DATA,
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_SERVER_SET_DATA,
} from '../../constants/actionTypes/dataState';

export const clearServerStateOnFetch = action$ => action$.pipe(
  ofType(VIAFOURA_NOTIFICATIONS_FETCH),
  map(() => ({ type: DATASTATE_SERVER_DISPOSE_DATA })),
);

export const setServerStateOnSuccess = action$ => action$.pipe(
  ofType(VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS),
  mergeMap(({ value }) => {
    const notification = new ViafouraNotificationEntity();
    return of({ type: DATASTATE_SERVER_SET_DATA, value: notification.getDataFromPayload(value) });
  }),
);

export const disposeStates = action$ => action$.pipe(
  ofType(VIAFOURA_NOTIFICATIONS_DISPOSE),
  mergeMap(() => from([
    { type: DATASTATE_SERVER_DISPOSE_DATA },
    { type: DATASTATE_EXTERNAL_DISPOSE_DATA },
    { type: DATASTATE_LOCAL_DISPOSE },
  ])),
);
