import VideoElement from './components/VideoElement';

export const ELEMENT_VIDEO = 'video';
export const PROP_VIDEO = 'video';
export const PROP_CAPTION = 'caption';

export const DEFAULTS_VIDEO = {
  video: {
    component: VideoElement,
    type: ELEMENT_VIDEO,
    rootProps: {
      className: 'slate-video',
    },
  },
};
