import React, { useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { push } from 'connected-react-router';

import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE } from '../../constants/preview';
import { redirectToArticlePreview } from '../../actions/article';
import { getClassName } from '../../utils/propHelper';

const styles = theme => ({
  root: {
    minHeight: '100vh',
    marginTop: theme.spacing(3),
  },
  frame: {
    width: '100%',
  },
  mobile: {
    maxWidth: '400px!important',
  },
  frameRoot: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 1200,
    width: '100%',
    height: '100%',
    display: 'flex',
    background: theme.palette.background.contrast,
  },
  editButton: {
    position: 'absolute',
    top: 0,
  },
});

const LivePreview = (props) => {
  const {
    classes,
    path,
    previewMode,
    selectedPublication,
    redirectToArticlePreview: redirect,
  } = props;
  const element = useRef(null);

  const frontEndUrl = selectedPublication.environment.web;

  function resizeCrossDomainIframe() {
    window.addEventListener('message', (event) => {
      if (event.origin !== frontEndUrl.replace(/\/$/, '')) return;
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'PWAMP_LOADED_HEIGHT') {
          const height = data.value + 20;
          element.current.height = `${height}px`;
        }

        if (data.type === 'PWAMP_ARTICLE_ID' && data.value) {
          redirect(data.value);
        }
      } catch (ex) {
        // console.error(ex);
      }
    }, false);
  }
  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <div
          className={getClassName({
            [classes.mobile]: previewMode === PREVIEW_MODE_MOBILE,
            [classes.frameRoot]: true,
          })}
        >
          <iframe
            className={classes.frame}
            title={'pwmap-preview'}
            ref={element}
            src={`${frontEndUrl.replace(/\/$/, '')}${path}?live-browse`}
            onLoad={resizeCrossDomainIframe()}
            frameBorder={'0'}
          />
        </div>
      </Grid>
    </Grid>
  );
};

LivePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  previewMode: PropTypes.string,
  selectedPublication: PropTypes.object.isRequired,
  redirectToArticlePreview: PropTypes.func.isRequired,
};

LivePreview.defaultProps = {
  previewMode: PREVIEW_MODE_DESKTOP,
};

export default withStyles(styles)(connect(
  ({
    frame: { selectedPublication },
    router,
  }) => ({ selectedPublication, router }),
  { push, redirectToArticlePreview },
)(LivePreview));

