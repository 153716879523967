import React from 'react';
import PropTypes from 'prop-types';

import { CONTENT_TYPE_ARTICLE } from '../../../constants/article/article';
import { ARTICLE_DRAG_START, ARTICLE_DRAG_END } from '../../../constants/actionTypes';

import Draggable from '../Draggable';

const DraggableArticle = (props) => {
  const { children, article, source, ...rest } = props;
  const DraggableComponent = Draggable(CONTENT_TYPE_ARTICLE);
  return (
    <DraggableComponent
      onDragStart={ARTICLE_DRAG_START}
      onDragEnd={ARTICLE_DRAG_END}
      data={{
        article,
        source,
      }}
      {...rest}
    >{children}</DraggableComponent>
  );
};

DraggableArticle.propTypes = {
  children: PropTypes.node.isRequired,
  article: PropTypes.object.isRequired,
  source: PropTypes.object,
};

DraggableArticle.defaultProps = {
  source: null,
};

export default DraggableArticle;
