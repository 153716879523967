import {
  DIALOG_GALLERY_ON_SEARCH,
  DIALOG_IMAGE_MEDIA_DISPOSE,
  DIALOG_ON_CLOSE,
  DIALOG_ON_EDIT_MEDIA_REQUEST,
  DIALOG_ON_RESET_EDIT_MEDIA,
  DIALOG_ON_SEARCH_MEDIA,
  DIALOG_ON_UPLOAD_CHP,
  DIALOG_ON_UPLOAD_MEDIA,
  DIALOG_OPEN_PUBLICATION,
} from 'constants/actionTypes/dialog';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const closeDialog = () => ({ type: DIALOG_ON_CLOSE });

// Publication Actions
const openDialogPublication = () => ({ type: DIALOG_OPEN_PUBLICATION, value: true });
export const closeDialogPublication = () => ({ type: DIALOG_OPEN_PUBLICATION, value: false });
export const selectPublication = value => ({ type: PUBLICATION_SELECTED, value });

export const gallerySearch = value => ({ type: DIALOG_GALLERY_ON_SEARCH, value });
export const onSearchMedia = value => ({ type: DIALOG_ON_SEARCH_MEDIA, value });
export const onEditMediaRequest = value => ({ type: DIALOG_ON_EDIT_MEDIA_REQUEST, value });
// uploadCHPMedia currently unused but might be useful in future
export const uploadCHPMedia = value => ({ type: DIALOG_ON_UPLOAD_CHP, value });
export const uploadMedia = (file, isCrop) =>
  ({ type: DIALOG_ON_UPLOAD_MEDIA, value: { file, isCrop } });
export const disposePreviousMedia = value => ({ type: DIALOG_IMAGE_MEDIA_DISPOSE, value });
export const onResetEditMediaRequest = value => ({ type: DIALOG_ON_RESET_EDIT_MEDIA, value });

export default openDialogPublication;
