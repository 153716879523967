export const EVENT_FETCH = 'EVENT_FETCH';
export const EVENT_FETCH_SUCCESS = 'EVENT_FETCH_SUCCESS';
export const EVENT_FETCH_REJECTED = 'EVENT_FETCH_REJECTED';

export const EVENT_INIT = 'EVENT_INIT';
export const EVENT_DISPOSE = 'EVENT_DISPOSE';

export const EVENT_SAVE = 'EVENT_SAVE';
export const EVENT_SAVE_READY = 'EVENT_SAVE_READY';
export const EVENT_SAVE_NOT_READY = 'EVENT_SAVE_NOT_READY';
export const EVENT_SAVE_SUCCESS = 'EVENT_SAVE_SUCCESS';
export const EVENT_SAVE_REJECTED = 'EVENT_SAVE_REJECTED';

export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS';
export const EVENT_DELETE_REJECTED = 'EVENT_DELETE_REJECTED';

export const EVENT_REQUEST_PREVIEW = 'EVENT_REQUEST_PREVIEW';
export const EVENT_REQUEST_PREVIEW_SUCCESS = 'EVENT_REQUEST_PREVIEW_SUCCESS';
export const EVENT_REQUEST_PREVIEW_REJECTED = 'EVENT_REQUEST_PREVIEW_REJECTED';

export const EVENT_LIST_FETCH = 'EVENT_LIST_FETCH';
export const EVENT_LIST_DISPOSE = 'EVENT_LIST_DISPOSE';
export const EVENT_LIST_FETCH_SUCCESS = 'EVENT_LIST_FETCH_SUCCESS';
export const EVENT_LIST_FETCH_REJECTED = 'EVENT_LIST_FETCH_REJECTED';
