import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';

import LiveblogPost from './LiveblogPost';
import { fetchLiveblogPostsForArticleComp } from '../../actions/liveblog';
import Button from '../ui/buttons/Button';

const sizePerPage = 10;
const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  filter: {
    margin: theme.spacing(2, 0, 1, 1),
  },
});

const LiveblogPostSelector = (props) => {
  const {
    classes, fetchLiveblogPostsForArticleComp: onFetch,
    currentLiveblog, onSelect, liveblog, postTotal, currentContainerFromServer,
  } = props;
  const [page, setPage] = useState(0);
  useEffect(() => {
    // liveblog prop comes from article's state
    // this component can be loaded from standalone liveblog page (for example for JPI)
    // so `currentContainerFromServer` needed to get container id
    const containerId = liveblog || currentContainerFromServer?.data?.id;
    if (containerId) {
      onFetch({
        id: containerId,
        posts: sizePerPage,
        offset: page * sizePerPage,
      });
    }
  }, [page, sizePerPage]);

  return (<div className={classes.root}>
    {currentLiveblog && currentLiveblog.posts && currentLiveblog.posts.map(post => (<LiveblogPost
      key={post?.data?.id || null}
      pinnable={false}
      post={post}
      action={() => (<div>
        <Button
          variant={'contained'}
          onClick={() => onSelect(post)}
        >
          Select
        </Button>
      </div>)}
    />))}
    {postTotal > sizePerPage && <TablePagination
      rowsPerPageOptions={[sizePerPage]}
      component="nav"
      count={postTotal}
      rowsPerPage={sizePerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next',
      }}
      onChangePage={(e, i) => setPage(i)}
    /> }
  </div>);
};

LiveblogPostSelector.defaultProps = {
  liveblog: null,
  postTotal: 0,
  currentContainerFromServer: null,
};

LiveblogPostSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchLiveblogPostsForArticleComp: PropTypes.object.isRequired,
  currentLiveblog: PropTypes.object.isRequired,
  currentContainerFromServer: PropTypes.object,
  liveblog: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  postTotal: PropTypes.number.isRequired,
};

export default withStyles(style)(connect(({
  article: { liveblog },
  liveblog: {
    selectedLiveblogForArticleComponent: currentLiveblog, postTotal, currentContainerFromServer,
  },
}) => ({ currentLiveblog, liveblog, postTotal, currentContainerFromServer }), {
  fetchLiveblogPostsForArticleComp,
})(LiveblogPostSelector));
