import { ofType } from 'redux-observable';
import {
  withLatestFrom,
  switchMap,
  filter,
  map,
  flatMap,
  mapTo,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { forkJoin, of, from } from 'rxjs';
import { push } from 'connected-react-router';

import {
  TRANSLATION_REQUEST_REJECTED,
  TRANSLATION_LIST_REQUEST,
  TRANSLATION_LIST_REQUEST_SUCCESS,
  TRANSLATION_LIST_REQUEST_REJECTED,
  TRANSLATE_ARTICLE_REQUEST,
  TRANSLATE_ARTICLE_REQUEST_SUCCESS,
  TRANSLATE_ARTICLE_REQUEST_REJECTED,
  TRANSLATION_LIST_PAGE_LOADED,
  TRANSLATION_FETCH_SECTION_SUCCESS,
  TRANSLATION_FETCH_SECTION_REJECTED,
  TRANSLATE_INTERNAL_ARTICLE_REQUEST,
  TRANSLATE_INTERNAL_ARTICLE_SUCCESS, TRANSLATE_INTERNAL_ARTICLE_REJECTED, DUMMY_ACTION,
} from '../../constants/actionTypes';

import generateKey from '../../utils/generateKey';
import apiCatchError from '../helper/notification';
import { reformatTranslationSection } from '../helper/utils';
import {
  PAGE_ENTER_ARTICLE_EDIT,
  PAGE_ENTER_ARTICLE_EDIT_LOADED,
} from '../../constants/actionTypes/route';
import {
  ARTICLE_SET_TRANSLATION_SOURCE_ALIAS,
  ARTICLE_SET_TRANSLATION_SOURCE_ALIAS_REJECTED,
} from '../../constants/actionTypes/article';

export const fetchArticleSource = action$ => action$.pipe(
  ofType(TRANSLATION_LIST_REQUEST),
  switchMap(({ value: { sourceId, page, base, query } }) =>
    ajax.getJSON(`/api/translation/source?page=${page}&base=${base}&termId=${sourceId}&query=${query}`)
      .pipe(
        map(response => ({
          type: TRANSLATION_LIST_REQUEST_SUCCESS,
          value: response,
        })),
        apiCatchError(TRANSLATION_LIST_REQUEST_REJECTED),
      ),
  ),
);

export const translateInternalArticle = (action$, state$) => action$.pipe(
  ofType(TRANSLATE_INTERNAL_ARTICLE_REQUEST),
  withLatestFrom(state$),
  switchMap(([{ value }, {
    frame: { selectedPublication: { publicationConfig: { country } } },
  }]) =>
    ajax.getJSON(`/api/internal-translation/import/${country}/${value}`).pipe(
      map(({ id }) => ({
        type: TRANSLATE_INTERNAL_ARTICLE_SUCCESS,
        value: id,
      })),
      apiCatchError(TRANSLATE_INTERNAL_ARTICLE_REJECTED),
    ),
  ),
);

export const getSections = action$ => action$.pipe(
  ofType(TRANSLATION_LIST_PAGE_LOADED),
  switchMap(() =>
    ajax.getJSON('/api/vocab-tree-formatted/sections').pipe(
      map(response => ({
        type: TRANSLATION_FETCH_SECTION_SUCCESS,
        value: reformatTranslationSection(response),
      })),
      apiCatchError(TRANSLATION_FETCH_SECTION_REJECTED),
    ),
  ),
);

export const requestInternalId = (action$, state$) => action$.pipe(
  ofType(TRANSLATE_ARTICLE_REQUEST),
  withLatestFrom(state$),
  switchMap(([{ value }, {
    frame: { selectedPublication: { publicationConfig: { country } } },
  }]) =>
    ajax.getJSON(`/api/translation/import/${country}/${value}`).pipe(
      map(({ id }) => ({
        type: TRANSLATE_ARTICLE_REQUEST_SUCCESS,
        value: id,
      })),
      apiCatchError(TRANSLATE_ARTICLE_REQUEST_REJECTED),
    ),
  ),
);

// export const resetPreviousArticleState = action$ => action$.pipe(
//   ofType(TRANSLATE_ARTICLE_REQUEST_SUCCESS, TRANSLATE_INTERNAL_ARTICLE_SUCCESS),
//   mapTo({ type: RESET_ARTICLE_STATE }),
// );

export const redirectToEditPage = action$ => action$.pipe(
  ofType(TRANSLATE_ARTICLE_REQUEST_SUCCESS, TRANSLATE_INTERNAL_ARTICLE_SUCCESS),
  map(({ value }) => push(`/article/edit/${value}`)),
);

// export const getArticleTranslation = (action$, state$) => action$.pipe(
//   ofType(PAGE_ENTER_ARTICLE_EDIT_LOADED),
//   withLatestFrom(state$),
//   filter(([, { router: { location: { pathname } } }]) => /^\/translate\/[a-z]{2}/.test(pathname)),
//   switchMap(([action, { router: { location: { pathname } }, article: { body } }]) => {
//     const target = pathname.match(/^\/translate\/([a-z]+)\//);
//     const articleBody = action.value.field_body_json[0].value.map((comp) => {
//       if (comp.type === MARKUP) {
//         let div = document.createElement('div');
//         div.innerHTML = comp.data[MARKUP];
//         const text = div.textContent;
//         div = null;
//         return ajax.post('/api/translate', { target: target[1], text }, { 'Content-Type': 'application/json' })
//           .pipe(
//             map(({ response: [text] }) => {
//               const data = {};
//               data[MARKUP] = '<p></p>';
//               const component = {
//                 type: MARKUP,
//                 data,
//                 id: generateKey(),
//               };
//               if (text) {
//                 component.data[MARKUP] = `<p>${text}</p>`;
//               }
//               return component;
//             }),
//           );
//       }
//       return of(comp);
//     });
//     return forkJoin(...articleBody).pipe(
//       flatMap(value => from([
//         {
//           type: ARTICLE_SET_PROPERTY,
//           value: ['body', value],
//         },
//         {
//           type: ARTICLE_SET_PROPERTY,
//           value: ['bodyTranslationOrigin', body],
//         },
//       ])),
//       apiCatchError(TRANSLATION_REQUEST_REJECTED),
//     );
//   }),
// );

export const checkOrignalUrlOnArticleEdit = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT_LOADED),
  filter(({ value: { field_remote_id: fieldRemoteId } }) => fieldRemoteId.length > 0 &&  /^TF/.test(fieldRemoteId[0].value)),
  withLatestFrom(state$),
  switchMap(([
    { value: { field_remote_id: [{ value: remoteId }] } },
    { frame: { publication, selectedPublication: { domain } } },
  ]) => {
    const matches = remoteId.match(/\d+$/);
    const matchPublicationName = remoteId.match(/^TF(.*)To/);
    let frontEndBaseURL;
    let rootPath = '';
    if (Array.isArray(matchPublicationName) && matchPublicationName.length > 1) {
      const sourcePublication = publication
        .find(({ name }) => name.toLowerCase() === matchPublicationName[1].toLowerCase());
      if (sourcePublication) {
        const { sectionRoot, environment: { web } } = sourcePublication;
        rootPath = sectionRoot.path;
        frontEndBaseURL = web.replace(/\/$/, '');
      }
    }
    return ajax.getJSON(`/api/article/one/${matches[0]}?publication=${domain}`).pipe(
      map(({ path: [{ alias }] }) => ({
        type: ARTICLE_SET_TRANSLATION_SOURCE_ALIAS,
        value: `${frontEndBaseURL}${alias.replace(rootPath, '')}`,
      })),
    );
  }),
  apiCatchError(ARTICLE_SET_TRANSLATION_SOURCE_ALIAS_REJECTED),
);
