import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { checkArticleType, feature } from '../../helper/utils';
import { ARTICLE_TYPE_REGEX_SERVICE, ARTICLE_TYPE_REGEX_VIDEO_HUB } from '../../../constants/article/article';
import HeroBadge from './articleBuilder/HeroBadge';
import BadgeRoot from './articleBuilder/BadgeRoot';
import {
  VIDEO_DM,
  VIDEO_DUGOUT,
  VIDEO_JWPLAYER,
  VIDEO_YOUTUBE,
  VIDEO_PLAYINC,
  VIDEO_ELEPHANT,
} from '../../../constants/media/media';
import {
  FIELD_HERO_VIDEO,
  FIELD_HERO_IMAGE,
  FIELD_HERO_GALLERY,
  FIELD_ARTICLE_TYPE,
  FIELD_SOCIAL_IMAGE,
  FIELD_VIDEO_PROVIDER, FIELD_PIXELS_AI_PLAYLIST,
} from '../../../constants/article/articleFields';
import { PagePanel, PagePanelContent } from '../../common/page/PagePanel';
import { galleryRegex, videoRegex, FEATURE_PLAY_INC } from '../../../constants/features';
import FieldLockImage from '../../fieldLock/form/FieldLockImage';
import FieldLockVideo from '../../fieldLock/form/FieldLockVideo';
import FieldLockGallery from '../../fieldLock/form/FieldLockGallery';
import { PLAYINC_PROVIDER } from '../../integration/playInc/PlayIncVideoSelectorList';
import FieldLockSelect from '../../fieldLock/form/FieldLockSelect';
import { fetchPlayIncPlaylists } from '../../../actions/integration/playInc';

const styles = () => ({
  root: {
    position: 'relative',
  },
});

const MediaRoot = withStyles(styles)(Grid);

const MediaPanel = ({
  features,
  articleType,
  videoProvider,
  playlists,
  fetchPlayIncPlaylists: fetchPlaylists,
}) => {
  const isArticleType = checkArticleType(articleType);
  const isHeroImageRequired = !isArticleType(ARTICLE_TYPE_REGEX_SERVICE);
  const checkFeatures = feature(features);
  const hasVideoEnabled = checkFeatures(videoRegex);
  const hasGalleryEnabled = checkFeatures(galleryRegex);
  const hasPlayIncEnabled = checkFeatures(new RegExp(FEATURE_PLAY_INC, 'i'));
  const isPlayInc = videoProvider === PLAYINC_PROVIDER;
  const isVideoHub = isArticleType(ARTICLE_TYPE_REGEX_VIDEO_HUB);

  const validVideoTypes = [VIDEO_JWPLAYER, VIDEO_YOUTUBE, VIDEO_DM, VIDEO_DUGOUT, VIDEO_ELEPHANT];
  if (hasPlayIncEnabled && isVideoHub) {
    validVideoTypes.push(VIDEO_PLAYINC);
  }

/*   useEffect(() => {
    if (hasPlayIncEnabled && playlists.length < 1) {
      fetchPlaylists();
    }
  }, [fetchPlaylists, playlists, hasPlayIncEnabled]); */

  return (
    <PagePanel>
      <PagePanelContent noHeading>
        <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'space-between'} wrap={'nowrap'}>
          <MediaRoot item xs={3}>
            <FieldLockImage
              field={FIELD_HERO_IMAGE}
              required={isHeroImageRequired}
              hasCaption
              hasCopyright
            >
              <BadgeRoot>
                <HeroBadge type={FIELD_HERO_IMAGE} />
              </BadgeRoot>
            </FieldLockImage>
          </MediaRoot>
          {hasVideoEnabled && <MediaRoot item xs={3}>
            <FieldLockVideo
              field={FIELD_HERO_VIDEO}
              hasCaption
              createEntity
              validTypes={validVideoTypes}
              disabled={isPlayInc}
            >
              <BadgeRoot>
                <HeroBadge type={FIELD_HERO_VIDEO} />
              </BadgeRoot>
              {isPlayInc &&
                <FieldLockSelect
                  field={FIELD_PIXELS_AI_PLAYLIST}
                  items={playlists.map(({ label, playlistId }) => ({ id: playlistId, name: label }))}
                  label={'Playlist'}
                  disabled
                />
              }
            </FieldLockVideo>
          </MediaRoot>}
          {hasGalleryEnabled && <MediaRoot item xs={3}>
            <FieldLockGallery field={FIELD_HERO_GALLERY}>
              <BadgeRoot>
                <HeroBadge type={FIELD_HERO_GALLERY} />
              </BadgeRoot>
            </FieldLockGallery>
          </MediaRoot>}
          <Grid item xs={3}>
            <FieldLockImage field={FIELD_SOCIAL_IMAGE} label={'Add social image'} />
          </Grid>
        </Grid>
      </PagePanelContent>
    </PagePanel>
  );
};

MediaPanel.propTypes = {
  fetchPlayIncPlaylists: PropTypes.func.isRequired,
  articleType: PropTypes.object,
  features: PropTypes.array,
  videoProvider: PropTypes.string,
  playlists: PropTypes.array,
};

MediaPanel.defaultProps = {
  features: [],
  articleType: {},
  videoProvider: '',
  playlists: [],
};

export default connect(
  ({
    dataState: { [FIELD_ARTICLE_TYPE]: articleType, [FIELD_VIDEO_PROVIDER]: videoProvider },
    frame: { selectedPublication: { features } },
    integration: { playInc: { playlists } },
  }) => ({ features, articleType, videoProvider, playlists }),
  { fetchPlayIncPlaylists },
)(MediaPanel);
