import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import Code from '@material-ui/icons/Code';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';

import { PROP_MARKUP } from '../defaults';

const styles = () => ({
  input: {
    fontFamily: 'Consolas,Monaco,Lucida Console,Liberation Mono,Courier New',
    fontSize: 12,
  },
});

const HtmlElement = (props) => {
  const { classes, attributes, children, element, className } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'HTML'} Icon={Code} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              multiline
              placeholder={'Insert HTML code here'}
              rows={5}
              ignoreSmartQuote
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_MARKUP]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_MARKUP] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

HtmlElement.propTypes = {
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyles(styles)(HtmlElement);
