import { ELEMENT_HTML, PROP_MARKUP } from './defaults';
import { HTML } from '../../../../../constants/editor/dataTypes';

export const convertToHtml = data => ({
  type: ELEMENT_HTML,
  [PROP_MARKUP]: data[PROP_MARKUP] || '',
  children: [{ text: '' }],
});

export const convertFromHtml = data => ({
  type: HTML,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP] || '',
  },
});
