import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.border.primary.main}`,
    width: 1,
    height: 40,
    paddingLeft: 5,
    marginRight: 5,
  },
  small: {
    height: 30,
  },
});

const Separator = (props) => {
  const { classes, className, small } = props;
  return (
    <span
      className={[
        classes.root,
        className || '',
        small ? classes.small : '',
      ].filter(str => str !== '').join(' ')}
    />
  );
};

Separator.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
};

Separator.defaultProps = {
  className: '',
  small: false,
};

export default withStyles(styles)(Separator);
