import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  indicator: {
    paddingLeft: theme.spacing(0.5),
    lineHeight: '1em',
  },
});

const RequiredIndicator = ({ classes }) => (
  <Typography className={classes.indicator} component={'span'}>*</Typography>
);

RequiredIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RequiredIndicator);
