import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import VideoSelector from '../../../ui/media/VideoSelector';
import { VIDEO_JWPLAYER } from '../../../../constants/media/media';
import { VIDEO } from '../../constants';

const VideoField = (props) => {
  const {
    component,
    componentSetProp: setProp,
  } = props;

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Video</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <VideoSelector
          video={component.video || null}
          onSelected={({ provider, video }) => {
            setProp(
              component.id,
              VIDEO,
              {
                videoType: provider,
                ...video,
              },
            );
          }}
          validTypes={[VIDEO_JWPLAYER]}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

VideoField.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(VideoField);
