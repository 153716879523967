import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import CompareIcon from '@material-ui/icons/Compare';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginBottom: theme.spacing(0.25),
    cursor: 'pointer',
    fontSize: 14,
    color: theme.typography.color.primary,
    backgroundColor: 'rgba(0, 0, 0, .05)',
  },
  compareButton: {
    cursor: 'pointer',
    color: theme.typography.color.primary,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    display: 'none',
    [theme.breakpoints.up('xl')]: {
      display: 'inherit',
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

const TranslationToggle = ({ classes, onClick, selected, langOptions }) => {
  const setSelectedClass = (target, comparator) => {
    return target === comparator ? classes.selected : '';
  };

  return (<div className={classes.root}>
    { langOptions.map(lang => (<Avatar
      key={lang}
      onClick={() => onClick(lang)}
      className={[classes.item, setSelectedClass(lang, selected)].join(' ')}
      variant={'square'}
    >{lang.toUpperCase()}</Avatar>))}
    <Avatar
      onClick={() => onClick('compare')}
      variant={'square'}
      className={classes.compareButton}
    >
      <CompareIcon />
    </Avatar>
  </div>);
};

TranslationToggle.defaultProps = {
  selected: 'ru',
};

TranslationToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string,
  langOptions: PropTypes.array.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  {},
)(TranslationToggle));

