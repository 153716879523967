import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';

import PageSection from '../common/page/PageSection';
import { Table, TableRow, TableBody, TableHead, TableCell, TableHeadCell } from '../ui/table/Table';
import MediaRatio from './MediaRatio';
import Loader from '../ui/Loader';
import GenericWorkflowSelector from './common/WorkflowSelector';
import { optimiseImage } from '../helper/media';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import IconButton from '../ui/buttons/IconButton';
import Button from '../ui/buttons/Button';

const styles = theme => ({
  root: {
    minHeight: 400,
  },
  gridList: {
    width: '100%',
    flexGrow: 1,
  },
  imageWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: 'black',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '& button': {
      position: 'absolute',
      zIndex: 2,
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  filter: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(0, -3),
  },
});

const TableGrid = props => (<Grid item xs={12} {...props} />);

const pageSizeOptions = [12, 24, 48];

// @todo refactor removing filters to another object, filters added to redux
const GalleryList = ({
  classes, onSearchGallery, galleries, isGallerySearching, onSelectGallery, pageStyle, useIcon,
}) => {
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(pageSizeOptions[0]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedWorkflow, setSelectedWorkflow] = useState('all');
  const Wrapper = pageStyle ? PageSection : TableGrid;
  useEffect(() => {
    onSearchGallery({
      bundle: 'gallery',
      q: query,
      limit,
      offset,
      workflowTid: selectedWorkflow === 'all' ? '' : selectedWorkflow,
    });
  }, [query, limit, page, selectedWorkflow]);
  const filterAttrs = pageStyle ? { includePadding: true } : { className: classes.filter };
  const tableAttrs = pageStyle ? { } : { className: classes.table };
  return (
    <>
      <Wrapper {...filterAttrs}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextSearchFilter
              placeholder={'Search'}
              defaultValue={query}
              onSubmit={(textQuery) => {
                setQuery(textQuery);
                setPage(1);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericWorkflowSelector
              showAllOption
              selectedValue={selectedWorkflow}
              onChange={setSelectedWorkflow}
              margin={'none'}
            />
          </Grid>
        </Grid>
      </Wrapper>
      <Wrapper>
        <div {...tableAttrs}>
          {isGallerySearching && <Loader />}
          <Table>
            <TableHead>
              <TableRow hasBorder>{['Gallery', 'Title', 'Workflow', '']
                .map(label => (<TableHeadCell
                  key={label}
                >{label}</TableHeadCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {galleries.length === 0 && !isGallerySearching && <TableRow>
                <TableCell colSpan={4} align={'center'} variant={'body2'}>No galleries found</TableCell>
              </TableRow>}
              {galleries.length > 0 && galleries.map(({ data }) => (<TableRow key={data.id} hasActions>
                <TableCell>
                  {data.gallery && data.gallery.length > 0 && data.gallery[0].data?.url &&
                    <MediaRatio>
                      <img
                        src={optimiseImage(data.gallery[0].data.url, { width: 200 })}
                        alt={data.gallery[0].data.description || data.gallery[0].data.title}
                      />
                    </MediaRatio>
                  }
                  {!data.gallery || data.gallery && data.gallery.length === 0 && <Typography>No images</Typography>}
                </TableCell>
                <TableCell>{data.title}</TableCell>
                <TableCell>{data.workflow ? data.workflow.name : 'Published'}</TableCell>
                <TableCell>
                  {pageStyle && <IconButton
                    onClick={() => onSelectGallery(data)}
                  ><EditIcon /></IconButton>}
                  {!pageStyle && <Button
                    variant={'contained'}
                    onClick={() => onSelectGallery(data)}
                  >Select</Button>}
                </TableCell>
              </TableRow>))}
            </TableBody>
          </Table>
          <Grid container direction={'row-reverse'}>
            <TablePagination
              rowsPerPageOptions={pageSizeOptions}
              component={'div'}
              count={10000}
              rowsPerPage={limit}
              page={page}
              labelDisplayedRows={() => ''} // nothing to show as there is no total
              onChangePage={(e, i) => {
                setPage(i++);
                setOffset(page * limit);
              }}
              onChangeRowsPerPage={e => setLimit(e.target.value)}
            />
          </Grid>
        </div>
      </Wrapper>
    </>
  );
};

GalleryList.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchGallery: PropTypes.func.isRequired,
  onSelectGallery: PropTypes.func.isRequired,
  galleries: PropTypes.array,
  isGallerySearching: PropTypes.bool,
  pageStyle: PropTypes.bool,
};

GalleryList.defaultProps = {
  isGallerySearching: true,
  pageStyle: false,
  galleries: [],
};

export default withStyles(styles)(connect(
  ({
    gallery: { galleries, isGallerySearching },
  }) => ({ galleries, isGallerySearching }),
  { },
)(GalleryList));
