import { ELEMENT_INSTAGRAM, PROP_URL, INSTAGRAM_PATTERN } from './defaults';
import { INSTAGRAM } from '../../../../../../constants/editor/dataTypes';

export const convertToInstagram = data => ({
  type: ELEMENT_INSTAGRAM,
  [PROP_URL]: data[PROP_URL] || '',
  children: [{ text: '' }],
});

export const convertFromInstagram = data => ({
  type: INSTAGRAM,
  data: {
    [PROP_URL]: data[PROP_URL] || '',
  },
});

export const isValidUrl = string => INSTAGRAM_PATTERN.test(string);
export const getValidUrl = (string) => {
  const matches = string.match(INSTAGRAM_PATTERN);
  return matches[0] || '';
};
