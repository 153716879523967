import { ofType } from 'redux-observable';
import {
  map, mergeMap,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  PRODUCT_FETCH,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_DISPOSE,
} from '../../constants/actionTypes/product';
import ProductEntity from '../../entities/ProductEntity';
import {
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_EXTERNAL_DISPOSE_DATA,
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_SERVER_SET_DATA,
} from '../../constants/actionTypes/dataState';

export const clearServerStateOnFetch = action$ => action$.pipe(
  ofType(PRODUCT_FETCH),
  map(() => ({ type: DATASTATE_SERVER_DISPOSE_DATA })),
);

export const setServerStateOnSuccess = action$ => action$.pipe(
  ofType(PRODUCT_FETCH_SUCCESS),
  mergeMap(({ value }) => {
    const product = new ProductEntity();
    return of({ type: DATASTATE_SERVER_SET_DATA, value: product.getDataFromPayload(value) });
  }),
);

export const disposeStates = action$ => action$.pipe(
  ofType(PRODUCT_DISPOSE),
  mergeMap(() => from([
    { type: DATASTATE_SERVER_DISPOSE_DATA },
    { type: DATASTATE_EXTERNAL_DISPOSE_DATA },
    { type: DATASTATE_LOCAL_DISPOSE },
  ])),
);
