import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';

import { fetchEventList, disposeEventList } from '../../../actions/event';

import OpenInNewTab from '../../ui/buttons/OpenInNewTabButton';
import IconButton from '../../ui/buttons/IconButton';
import { TableBody, TableRow, TableCell } from '../../ui/table/Table';

import ListTable from '../../table/ListTable';
import TableHeader from '../../table/TableHeader';
import ThumbnailImage from '../../table/content/ThumbnailImage';

import { slugify } from '../../../utils/urlHelper';
import { CMS_DATE_FORMAT } from '../../../constants/common';
import { getIsPublished, getScheduledDisplayStatus } from '../../helper/scheduler';

const EventListTable = (props) => {
  const {
    events, total, isFetching,
    textQuery, status, fromDate, toDate,
    pathname,
    push: routeChange,
    fetchEventList: fetchList,
    disposeEventList: disposeList,
  } = props;

  const queryData = [
    textQuery,
    status,
    fromDate,
    toDate,
    pathname,
  ];

  const getFetchData = (page, rowsPerPage) => ({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    q: textQuery,
    start: fromDate,
    end: toDate,
    status,
  });

  useEffect(() => () => {
    disposeList();
  }, []);

  const headers = ['Image', 'Event', 'From', 'To', 'Updated', 'status', ''];

  return (
    <ListTable
      isFetching={isFetching}
      total={total}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={fetchList}
    >
      <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
      <TableBody>
        {events.length === 0 && <TableRow>
          <TableCell colSpan={headers.length} align={'center'}>No events found</TableCell>
        </TableRow>}
        {events.length > 0 && events.map(({
          id,
          name: title,
          changed,
          path,
          published,
          thumbnail,
          startDate,
          endDate,
        }) => {
          const isPublished = getIsPublished(published);
          return (
            <TableRow key={id} hasActions>
              <TableCell>{thumbnail && <ThumbnailImage image={thumbnail} />}</TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>{moment.unix(startDate).format(CMS_DATE_FORMAT)}</TableCell>
              <TableCell>{moment.unix(endDate).format(CMS_DATE_FORMAT)}</TableCell>
              <TableCell>{moment.unix(changed).format(CMS_DATE_FORMAT)}</TableCell>
              <TableCell>{getScheduledDisplayStatus(isPublished)}</TableCell>
              <TableCell>
                <Grid container wrap={'nowrap'} justify={'flex-end'}>
                  <IconButton
                    onClick={() => routeChange(`/event/edit/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                  {isPublished && <OpenInNewTab
                    target={path || `/event/${slugify(title)}-${id}.html`}
                  />}
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </ListTable>
  );
};

EventListTable.defaultProps = {
  textQuery: '',
  status: '',
  fromDate: '',
  toDate: '',
  author: '',
};

EventListTable.propTypes = {
  pathname: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  push: PropTypes.func.isRequired,
  fetchEventList: PropTypes.func.isRequired,
  disposeEventList: PropTypes.func.isRequired,
  textQuery: PropTypes.string,
  status: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
};

export default connect(
  ({
    event: { events, total, isFetching },
    router: { location: { pathname } },
  }) =>
    ({
      events,
      total,
      isFetching,
      pathname,
    }),
  { push, fetchEventList, disposeEventList },
)(EventListTable);
