import React from 'react';
import PropTypes from 'prop-types';
import { ToolbarButton } from '@udecode/slate-plugins';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import ToolbarInput from '../../../../toolbar/common/ToolbarInput';

const ToolbarSearch = ({ resultsCount, selected, onNext, onPrev, ...props }) => (
  <>
    <ToolbarInput
      placeholder={'Search text'}
      InputProps={{
        startAdornment: (
          <InputAdornment position={'start'}>
            <Search />
          </InputAdornment>
        ),
      }}
      {...props}
    />
    <Typography variant={'caption'}>{selected} of {resultsCount}</Typography>
    <ToolbarButton
      icon={<ChevronLeftIcon />}
      onMouseDown={(e) => {
        e.preventDefault();
        onPrev();
      }}
    />
    <ToolbarButton
      icon={<ChevronRightIcon />}
      onMouseDown={(e) => {
        e.preventDefault();
        onNext();
      }}
    />
  </>
);

ToolbarSearch.propTypes = {
  resultsCount: PropTypes.number,
  selected: PropTypes.number,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
};

ToolbarSearch.defaultProps = {
  resultsCount: 0,
  selected: 0,
};

export default ToolbarSearch;
