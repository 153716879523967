import { PURGE } from 'redux-persist/es/constants';
import {
  VIAFOURA_NOTIFICATIONS_FETCH,
  VIAFOURA_NOTIFICATIONS_FETCH_REJECTED,
  VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS,
  VIAFOURA_NOTIFICATION_SEND,
  VIAFOURA_NOTIFICATION_SEND_SUCCESS,
  VIAFOURA_NOTIFICATION_SEND_REJECTED,
  VIAFOURA_NOTIFICATIONS_DISPOSE,
} from '../../constants/actionTypes/viafouraNotification';

const defaultState = {
  viafouraLogs: [],
  isFetching: false,
  isSending: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case VIAFOURA_NOTIFICATIONS_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS: {
      const { items } = action.value;
      return {
        ...state,
        viafouraLogs: items,
        isFetching: false,
      };
    }

    case VIAFOURA_NOTIFICATION_SEND:
      return {
        ...state,
        isSending: true,
      };

    case VIAFOURA_NOTIFICATION_SEND_SUCCESS:
      return {
        ...state,
        isSending: false,
      };

    case VIAFOURA_NOTIFICATION_SEND_REJECTED:
      return {
        ...state,
        isSending: false,
      };

    case VIAFOURA_NOTIFICATIONS_FETCH_REJECTED:
    case VIAFOURA_NOTIFICATIONS_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
