import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ARTICLE_TYPE,
  EDITION_SECTION,
} from '../../../constants/vocab';

import { onSelectTerm, onRemoveTerm } from '../../../actions/edition';

import ContextBar from '../../common/context/ContextBar';
import FieldLockVocabSelector from '../../fieldLock/form/FieldLockVocabSelectorDepricated';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../common/context/ContextPanel';
import Headline from './articleBuilder/Headline';

const SidebarSingleImage = ({
  sections,
  selectedPublication,
  onSelectTerm: onSelect, onRemoveTerm: onRemove,
}) => {
  const { vocabs } = selectedPublication;

  const taxomomyList = [
    [EDITION_SECTION, sections, 'Section'],
  ];

  return (
    <ContextBar>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Single Image Settings</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          <Headline
            label={'Title'}
            placeholder={''}
            InputProps={{}}
          />
          {taxomomyList
            .filter(([vocab]) => vocabs.includes(vocab))
            .filter(([vocab]) => vocab !== ARTICLE_TYPE)
            .map(([vocab, selectedTerms, label]) =>
              (<FieldLockVocabSelector
                required
                key={label}
                relatedTaxonomy={[]}
                popularTaxonomy={[]}
                vocab={vocab}
                selectedTerms={selectedTerms}
                label={label}
                onRemove={onRemove}
                onSelect={onSelect}
                setPrimary={() => {}}
              />),
            )
          }
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBar>
  );
};

SidebarSingleImage.defaultProps = {
  activePanelBlock: null,
};


SidebarSingleImage.propTypes = {
  sections: PropTypes.array.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  onSelectTerm: PropTypes.func.isRequired,
  onRemoveTerm: PropTypes.func.isRequired,
};

export default connect(
  ({
    edition: { pugpig_section: sections },
    frame: { selectedPublication },
  }) =>
    ({ sections, selectedPublication }),
  { onSelectTerm, onRemoveTerm },
)(SidebarSingleImage);

