import React from 'react';
import PropTypes from 'prop-types';
import { mergeDeepRight } from 'rambdax';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';


const ToolbarInput = ({ InputProps, ...props }) => (
  <TextField
    InputProps={mergeDeepRight(ToolbarInput.defaultProps.InputProps, InputProps)}
    {...props}
  />
);

ToolbarInput.propTypes = {
  InputProps: PropTypes.object,
};

ToolbarInput.defaultProps = {
  InputProps: { disableUnderline: true },
};

export default withStyles(theme => ({
  root: {
    height: 30,
    padding: theme.spacing(0, 0.5),
    '& .MuiSvgIcon-root': {
      fontSize: 16,
    },
  },
}))(ToolbarInput);
