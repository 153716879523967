export const ROLES = [
  {
    name: 'cms_admin',
    displayName: 'Admin',
  },
  {
    name: 'cms_editor',
    displayName: 'Editor',
  },
  {
    name: 'cms_curator',
    displayName: 'Curator',
  },
];
