import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { disposeFetchArticlePublished, fetchArticlePublished } from '../../../actions/article';
import ArticleDraggable from './ArticleDraggable';

export const SIZE_PER_PAGE = 10;

const ArticleSelector = (props) => {
  const {
    page, searchQuery, tags, start, end,
    fetchArticlePublished: onFetchArticlePublished,
    disposeFetchArticlePublished: onDisposeFetchArticlePublished,
    articlesPublished,
  } = props;

  useEffect(() => {
    const nowTimestamp = new Date().getTime();
    onFetchArticlePublished({
      status: 1,
      limit: SIZE_PER_PAGE,
      offset: SIZE_PER_PAGE * page,
      q: searchQuery,
      tids: tags.join('|'),
      start: start || '0',
      end: end || Math.floor(nowTimestamp / 1000), // not interested in scheduled article
      redirect: 0,
    });
    return () => {
      onDisposeFetchArticlePublished();
    };
  }, [page, SIZE_PER_PAGE, tags, start, end, searchQuery]);

  return (
    <div>
      {articlesPublished && articlesPublished.map(article => (
        <ArticleDraggable key={article.id} article={article} />
      ))}
    </div>
  );
};

ArticleSelector.propTypes = {
  page: PropTypes.number,
  searchQuery: PropTypes.string,
  tags: PropTypes.array,
  start: PropTypes.number,
  end: PropTypes.number,
  articlesPublished: PropTypes.array,
  fetchArticlePublished: PropTypes.func.isRequired,
  disposeFetchArticlePublished: PropTypes.func.isRequired,
};

ArticleSelector.defaultProps = {
  page: 0,
  searchQuery: '',
  articlesPublished: [],
  tags: [],
  start: 0,
  end: 0,
};

export default connect(
  ({ article: { articlesPublished } }) =>
    ({ articlesPublished }),
  { fetchArticlePublished, disposeFetchArticlePublished },
)(ArticleSelector);
