import { ofType } from 'redux-observable';
import { REHYDRATE } from 'redux-persist/es/constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { combineLatest, map } from 'rxjs/operators';
import {
  CHECK_SESSION_EXISTS,
} from '../../constants/actionTypes';
import {
  PAGE_ENTER,
  PAGE_ENTER_DASHBOARD,
  PAGE_ENTER_AD, PAGE_ENTER_AD_CREATE,
  PAGE_ENTER_ARTICLE, PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT,
  PAGE_ENTER_LIVEBLOG_BUILD, PAGE_ENTER_LIVEBLOG_LIST,
  PAGE_ENTER_MANUAL_LIST, PAGE_ENTER_MANUAL_LIST_EDIT,
  PAGE_ENTER_SIDEBAR,
  PAGE_ENTER_USERS,
} from '../../constants/actionTypes/route';

const onPageEnter = (action$) => {
  const rehydrate$ = action$.pipe(
    ofType(REHYDRATE),
  );
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    combineLatest(rehydrate$, locationChange$ => locationChange$),
    map(({ payload: { location: { pathname } } }) => {
      switch (true) {
        case /^\/$/.test(pathname):
          return {
            type: PAGE_ENTER_DASHBOARD,
          };
        case /^\/article\/build$/.test(pathname):
          return {
            type: PAGE_ENTER_ARTICLE_CREATE,
          };
        case /^\/article\/(edit|preview)\/\d+$/.test(pathname):
          return {
            type: PAGE_ENTER_ARTICLE_EDIT,
          };
        case /^\/article$/.test(pathname):
          return {
            type: PAGE_ENTER_ARTICLE,
          };
        case /^\/sidebar$/.test(pathname):
          return {
            type: PAGE_ENTER_SIDEBAR,
          };
        case /^\/manual-list$/.test(pathname):
          return {
            type: PAGE_ENTER_MANUAL_LIST,
          };
        case /^\/ad$/.test(pathname):
          return {
            type: PAGE_ENTER_AD,
          };
        case /^\/ad\/[0-9]+$/.test(pathname):
          return {
            type: PAGE_ENTER_AD_CREATE,
          };
        case /^\/users$/.test(pathname):
          return {
            type: PAGE_ENTER_USERS,
          };
        case /^\/liveblog$/.test(pathname):
          return {
            type: PAGE_ENTER_LIVEBLOG_LIST,
          };
        case /^\/liveblog\/build$/.test(pathname):
          return {
            type: PAGE_ENTER_LIVEBLOG_BUILD,
          };
        case /^\/manual-list\/[0-9]+$/.test(pathname):
          return {
            type: PAGE_ENTER_MANUAL_LIST_EDIT,
          };
        // case /^\/edition\/[^/]*\/[\d]*\/[\d]*$/.test(pathname):
        //   return {
        //     type: PAGE_ENTER_EDITION_ARTICLE,
        //   };
        case /^\/login$/.test(pathname):
        case /^\/reset-password$/.test(pathname):
          return {
            type: CHECK_SESSION_EXISTS,
          };
        default:
          return {
            type: PAGE_ENTER,
            value: pathname,
          };
      }
    }),
  );
};

export default onPageEnter;

