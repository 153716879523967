import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { dockLayoutPreview } from '../../../actions/layout';

import { copyStyles } from '../../../utils/previewHelper';
import { PREVIEW_MODE_MOBILE } from '../../../constants/preview';

// @todo similar to EditionPreviewWindow, both can extend a new abstract for previewWindow
class LayoutPreviewWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.containerEl.style.margin = '0 auto';
    this.setContainerMode(props.previewMode);
    this.containerWrapperEl = document.createElement('div');
    this.containerWrapperEl.appendChild(this.containerEl);
    this.containerEl.style.backgroundColor = '#EEE';
    this.externalWindow = null;
    this.onClose = props.dockLayoutPreview;
    this.onComponentHover = props.onComponentHover;
    this.onComponentClick = props.onComponentClick;
  }

  componentDidMount() {
    this.externalWindow = window.open('', '_blank');
    this.externalWindow.addEventListener('beforeunload', this.handleClose.bind(this));
    this.externalWindow.document.body.appendChild(this.containerWrapperEl);

    const onComponentHover = this.onComponentHover;
    const onComponentClick = this.onComponentClick;

    this.externalWindow.addEventListener('message', (e) => {
      if (typeof e.data !== 'undefined' && typeof e.data.event !== 'undefined') {
        switch (e.data.event) {
          case 'hydrated':
            this.updatePreview();
            break;
          case 'hover':
            onComponentHover(e.data.target);
            break;
          case 'click':
            onComponentClick(e.data.target);
            break;
          default:
            break;
        }
      }
    });

    copyStyles(document, this.externalWindow.document);
  }

  componentDidUpdate(prevProps) {
    const { previewMode, focused, highlighted } = this.props;
    this.updatePreview(prevProps);
    const iframe = this.externalWindow.document.querySelector('#layout-preview');
    if (iframe?.contentWindow) {
      if (prevProps.focused !== focused) {
        iframe.contentWindow.postMessage({ event: 'click', data: focused }, '*');
      }
      if (prevProps.highlighted !== highlighted) {
        iframe.contentWindow.postMessage({ event: 'hover', data: highlighted }, '*');
      }
      if (prevProps.previewMode !== previewMode) {
        this.setContainerMode(previewMode);
      }
    }
  }

  componentWillUnmount() {
    if (this.externalWindow !== null) {
      this.externalWindow.removeEventListener('beforeunload', this.handleClose.bind(this));
      this.externalWindow.close();
    }
  }

  setContainerMode(previewMode) {
    switch (previewMode) {
      case PREVIEW_MODE_MOBILE:
        this.containerEl.style.width = '375px';
        break;
      default:
        this.containerEl.style.width = '100%';
        break;
    }
  }

  updatePreview(prevProps = {}) {
    const { preview, previewProp, previewOverride } = this.props;
    const iframe = this.externalWindow.document.querySelector('#layout-preview');
    if (iframe?.contentWindow) {
      if (prevProps.preview !== preview && typeof preview.components !== 'undefined') {
        iframe.contentWindow.postMessage({ event: 'reload', data: preview }, '*');
      }
      if (prevProps.previewProp !== previewProp) {
        iframe.contentWindow.postMessage({ event: 'updateProp', data: previewProp }, '*');
      }
      if (prevProps.previewOverride !== previewOverride) {
        iframe.contentWindow.postMessage({ event: 'updateOverride', data: previewOverride }, '*');
      }
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.onClose();
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}

LayoutPreviewWindow.propTypes = {
  dockLayoutPreview: PropTypes.func.isRequired,
  onComponentHover: PropTypes.func.isRequired,
  onComponentClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  preview: PropTypes.object.isRequired,
  previewProp: PropTypes.object.isRequired,
  previewOverride: PropTypes.object.isRequired,
  previewMode: PropTypes.string.isRequired,
  focused: PropTypes.number.isRequired,
  highlighted: PropTypes.number.isRequired,
};

export default connect(
  ({
    layout: { preview, previewProp, previewOverride, previewMode },
  }) => ({ preview, previewProp, previewOverride, previewMode }),
  { dockLayoutPreview },
)(LayoutPreviewWindow);
