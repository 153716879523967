import TwitterElement from './components/TwitterElement';

export const ELEMENT_TWITTER = 'twitter';
export const PROP_URL = 'url';
export const PROP_CONVERSATION = 'isConversation';

export const TWITTER_PATTERN = /^http[s]?:\/\/(www\.)?twitter\.com\/[^/]*\/status\/\d+/;

export const DEFAULTS_TWITTER = {
  twitter: {
    component: TwitterElement,
    type: ELEMENT_TWITTER,
    rootProps: {
      className: 'slate-twitter',
    },
  },
};
