import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 11,
    backgroundColor: theme.palette.background.contrast,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
});

const AvatarNumbered = ({ classes, children }) =>
  (<Avatar className={classes.root}>{children}</Avatar>);

AvatarNumbered.defaultProps = {
};

AvatarNumbered.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(AvatarNumbered);

