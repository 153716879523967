import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

import { FIELD_NOTES } from '../../../constants/article/articleFields';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.background.contrast,
    padding: theme.spacing(0.5),
  },
  notice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginRight: 5,
  },
});

const ArticleNotes = ({ classes, notes }) => {
  const legal = notes.find(({ type }) => type === 'legal');
  return (legal ? <div className={classes.root}>
    <Typography variant={'body1'} className={classes.notice}><ErrorIcon className={classes.icon} /> This article contains a legal note</Typography>
  </div> : null);
};

ArticleNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.array,
};

ArticleNotes.defaultProps = {
  notes: [],
};

export default withStyles(styles)(connect(
  ({ dataState: { [FIELD_NOTES]: notes } }) => ({ notes }),
  {},
)(ArticleNotes));
