import { filter, mapTo, withLatestFrom, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';

import { REDIRECT_TO_HOME, CHECK_SESSION_EXISTS } from '../../constants/actionTypes';

const onExistingSession = (action$, state$) => {
  return action$.pipe(
    ofType(CHECK_SESSION_EXISTS),
    withLatestFrom(state$),
    map(([, state])=> state),
    filter(({ login }) => {
      if (login && login.user && login.user.name) {
        return true;
      }
    }),
    mapTo({
      type: REDIRECT_TO_HOME,
    }),
  );
};

export const redirectIfLoggedIn = (action$) =>  action$.pipe(
  ofType(REDIRECT_TO_HOME),
  mapTo(push('/')),
);

export default onExistingSession;
