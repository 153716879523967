import moment from 'moment';
import { mergeDeepRight } from 'rambdax';

import BaseEntity from './common/BaseEntity';

import {
  ARTICLE_FIELD_MAPPING,
  ARTICLE_PAYLOAD_FIELDS,
  ARTICLE_EXTRA_FIELDS,
  FIELD_BODY,
  FIELD_PRODUCTS,
  FIELD_SECTIONS,
  FIELD_AUTHORS,
  FIELD_TOPICS,
  FIELD_CHAIN,
  FIELD_EVENTS,
  FIELD_PEOPLE,
  FIELD_TEAMS,
  FIELD_TAGS,
  FIELD_HERO,
  FIELD_HERO_IMAGE,
  FIELD_HERO_GALLERY,
  FIELD_HERO_VIDEO,
  FIELD_NOTES,
  FIELD_URL_REDIRECT,
  FIELD_NID,
  FIELD_PRIORITY,
  FIELD_DISPLAY_DATE_PREF,
  FIELD_PUBLICATIONS,
  FIELD_PATH,
  FIELD_SCHEDULE_DATE,
  FIELD_UNPUBLISHED_DATE,
  FIELD_VANITY_DATE,
  FIELD_HEADLINE,
  FIELD_IS_PUBLISHED,
  FIELD_SHORT_HEADLINE,
  FIELD_SEO_DESCRIPTION,
  FIELD_LIVEBLOG,
  FIELD_CONTENT_SOURCE,
  FIELD_URL_KEYWORDS,
  FIELD_IS_BREAKING_NEWS,
  FIELD_ARTICLE_TYPE,
  FIELD_BADGE_PREFIX,
  FIELD_WORKFLOW,
  FIELD_HIDE_COMMENTING,
  CMS_SERVER_TIMESTAMP,
  FIELD_SERVER_TIMESTAMP,
  FIELD_IS_EMBARGO,
  FIELD_IS_HIDDEN_FROM_LAYOUT,
  FIELD_IS_PREMIUM,
  FIELD_IS_SENSITIVE,
  FIELD_ATEX, FIELD_SOCIAL_IMAGE, FIELD_BADGE_IMAGE, FIELD_LAST_UPDATED,
  FIELD_SOCIAL_HEADLINE,
  FIELD_SILENT_UPDATE, FIELD_AUTO_VIDEO, FIELD_CHANGED, FIELD_STAR_RATING, FIELD_STRUCTURED,
} from '../constants/article/articleFields';
import {
  HERO_VIDEO_TO_VIDEO,
  HERO_VIDEO_TYPES,
  MEDIA_GALLERY,
  MEDIA_IMAGE,
  PROP_LOCAL_CAPTION,
  PROP_LOCAL_COPYRIGHT,
} from '../constants/media/media';
import {
  FIELD_HERO_IMAGE_LOCAL_CAPTION,
  FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
  FIELD_HERO_VIDEO_LOCAL_CAPTION,
  FIELD_HERO_VIDEO_LOCAL_COPYRIGHT,
} from '../constants/common/commonFields';
import {
  ARTICLE_TYPE_REGEX_LIVEBLOG, ARTICLE_TYPE_REGEX_REVIEW,
  ARTICLE_TYPE_REGEX_SERVICE,
  DISPLAY_DATE_UPDATED, INVALID_SOCIAL_EMBED_WARNING,
  MAX_LENGTH_SEO_DESCRIPTION,
  MAX_LENGTH_SHORT_HEADLINE, MAX_LENGTH_SOCIAL_HEADLINE,
  REDIRECT_LOOP_DETECTED_ERROR_MESSAGE,
} from '../constants/article/article';
import { containsInvalidSocialEmbedComponent, preprocessEditorComponents } from '../utils/editorHelper';
import { checkArticleType} from '../components/helper/utils';
import { CONTENT_SOURCE, TAGS, TOPICS } from '../constants/vocab';
import {ITEM_REVIEWED, PROP_NAME, PROP_TYPE} from "../constants/structuredData";
import {emptyObject} from "../utils/helper";

const ENTITY_TYPE = 'node_type';
const ENTITY_BUNDLE = 'article';

const PRODUCT_ENTITY_TYPE = 'flowz_product';

class ArticleEntity extends BaseEntity {
  constructor() {
    super(
      ENTITY_TYPE,
      ENTITY_BUNDLE,
      ARTICLE_FIELD_MAPPING,
      ARTICLE_PAYLOAD_FIELDS,
      ARTICLE_EXTRA_FIELDS,
      [],
      {
        idField: FIELD_NID,
        publicationField: FIELD_PUBLICATIONS,
      },
    );
  }
  getPayloadBase(serverData, publication) {
    // article uses type rather than bundle
    const payload = {
      type: [{
        target_id: this.entityBundle,
        target_type: this.entityType,
      }],
    };
    if (this.config.idField && serverData[this.config.idField]) {
      payload[this.dataMapping[this.config.idField].key] =
        this.getPayloadFieldData(serverData, this.config.idField);
    }
    if (this.config.publicationField && publication) {
      payload[this.dataMapping[this.config.publicationField].key] = [{
        target_id: publication.id,
        target_type: 'taxonomy_term',
      }];
    }
    // headline is required for drupal validation
    payload[this.dataMapping[FIELD_HEADLINE].key] =
      this.getPayloadFieldData(serverData, FIELD_HEADLINE);
    // status is required for publish/unpublish function
    payload[this.dataMapping[FIELD_IS_PUBLISHED].key] =
      this.getPayloadFieldData(serverData, FIELD_IS_PUBLISHED);
    return payload;
  }
  getFieldDefault(field) {
    switch (field) {
      case FIELD_NOTES:
      case FIELD_PRODUCTS:
        return [];
      case FIELD_PRIORITY:
        return 4;
      case FIELD_DISPLAY_DATE_PREF:
        return DISPLAY_DATE_UPDATED;
      default:
        return super.getFieldDefault(field);
    }
  }
  getFieldData(payload, field) {
    const mapping = this.dataMapping[field];
    switch (field) {
      case FIELD_NOTES: {
        try {
          return JSON.parse(payload[mapping.key][0].value);
        } catch (e) {
          return this.getFieldDefault(field);
        }
      }
      case FIELD_BODY: {
        try {
          return preprocessEditorComponents(JSON.parse(payload[mapping.key][0].value));
        } catch (e) {
          return this.getFieldDefault(field);
        }
      }
      case FIELD_WORKFLOW:
        return payload[mapping.key]?.[0]?.target_id;
      case FIELD_PUBLICATIONS:
        return payload[mapping.key]
          ? payload[mapping.key].map(({ target_id: id }) => id.toString())
          : [];
      case FIELD_PATH:
        return payload[mapping.key]?.[0]?.alias || '';
      case FIELD_SECTIONS:
      case FIELD_AUTHORS:
      case FIELD_TOPICS:
      case FIELD_CHAIN:
      case FIELD_EVENTS:
      case FIELD_PEOPLE:
      case FIELD_TEAMS:
      case FIELD_TAGS:
      case FIELD_PRODUCTS:
      case FIELD_CONTENT_SOURCE:
        return payload[mapping.key] ? payload[mapping.key].map(({ pwamp }) => pwamp) : [];
      case FIELD_HERO_IMAGE: {
        const heroMapping = this.dataMapping[FIELD_HERO];
        const image = Array.isArray(payload[heroMapping.key])
          ? payload[heroMapping.key]
            .map(({ pwamp }) => pwamp).find(({ type }) => type === MEDIA_IMAGE)
          : null;
        if (image?.data) {
          const extras = this.getExtras(payload);
          if (extras && extras[FIELD_HERO_IMAGE_LOCAL_CAPTION]) {
            image.data[PROP_LOCAL_CAPTION] = extras[FIELD_HERO_IMAGE_LOCAL_CAPTION];
          }
          if (extras && extras[FIELD_HERO_IMAGE_LOCAL_COPYRIGHT]) {
            image.data[PROP_LOCAL_COPYRIGHT] = extras[FIELD_HERO_IMAGE_LOCAL_COPYRIGHT];
          }
        }
        return image || {};
      }
      case FIELD_HERO_GALLERY: {
        const heroMapping = this.dataMapping[FIELD_HERO];
        return Array.isArray(payload[heroMapping.key])
          ? payload[heroMapping.key]
            .map(({ pwamp }) => pwamp).find(({ type }) => type === MEDIA_GALLERY)
          : {};
      }
      case FIELD_HERO_VIDEO: {
        const heroMapping = this.dataMapping[FIELD_HERO];
        const video = Array.isArray(payload[heroMapping.key])
          ? payload[heroMapping.key]
            .map(({ pwamp }) => pwamp).find(({ type }) => HERO_VIDEO_TYPES.includes(type))
          : null;
        if (video?.data) {
          // @todo refactor video to remove videoType requirement replace with video.type
          // videoType is required for display
          if (video?.type) {
            video.data.videoType = HERO_VIDEO_TO_VIDEO[video.type];
          }
          if (video?.data?.extra) {
            // Video data from picker is stored in data, but from article fetch is stored in data.extra
            video.data = {
              ...video.data,
              ...video.data.extra,
            };
            delete video.data.extra;
          }
          const extras = this.getExtras(payload);
          if (extras && extras[FIELD_HERO_VIDEO_LOCAL_CAPTION]) {
            video.data[PROP_LOCAL_CAPTION] = extras[FIELD_HERO_VIDEO_LOCAL_CAPTION];
          }
          if (extras && extras[FIELD_HERO_VIDEO_LOCAL_COPYRIGHT]) {
            video.data[PROP_LOCAL_COPYRIGHT] = extras[FIELD_HERO_VIDEO_LOCAL_COPYRIGHT];
          }
        }
        return video || {};
      }
      case FIELD_SCHEDULE_DATE:
      case FIELD_UNPUBLISHED_DATE: {
        let date = 0;
        try {
          date = moment(
            payload[mapping.key][0].value,
          ).unix();
        } catch (ex) {
          // noop
        }
        return (date > moment().unix())
          ? moment.unix(date).tz('Europe/London').unix()
          : 0;
      }
      case FIELD_SERVER_TIMESTAMP:
        return payload[FIELD_SERVER_TIMESTAMP]?.[0]?.value || '';
      case FIELD_VANITY_DATE:
        return payload[mapping.key]?.[0]?.value
          ? moment(payload[mapping.key][0].value).unix()
          : null;
      case FIELD_HIDE_COMMENTING: // It was decided to reverse this logic...
        return !payload[mapping.key]?.[0]?.value;
      default:
        return super.getFieldData(payload, field);
    }
  }
  addFieldData(data, payload) {
    return (field) => {
      switch (field) {
        case CMS_SERVER_TIMESTAMP: {
          data[CMS_SERVER_TIMESTAMP] = this.getFieldData(payload, FIELD_SERVER_TIMESTAMP);
          break;
        }
        case FIELD_HERO: {
          // The hero media array is split for ease of CMS management
          data[FIELD_HERO_IMAGE] = this.getFieldData(payload, FIELD_HERO_IMAGE);
          data[FIELD_HERO_GALLERY] = this.getFieldData(payload, FIELD_HERO_GALLERY);
          data[FIELD_HERO_VIDEO] = this.getFieldData(payload, FIELD_HERO_VIDEO);
          break;
        }
        default:
          data[field] = this.getFieldData(payload, field);
      }
    };
  }
  getDataFromPayload(payload, exclude = []) {
    const data = super.getDataFromPayload(payload, exclude);
    data[FIELD_CHANGED] = payload?.[FIELD_CHANGED]?.[0]?.value
      ? moment(payload[FIELD_CHANGED][0].value).unix()
      : null;
    return data;
  }
  getPayloadFields(data, serverData) {
    // force commenting default
    if (
      typeof data[FIELD_HIDE_COMMENTING] === 'undefined' &&
      typeof serverData[FIELD_HIDE_COMMENTING] === 'undefined'
    ) {
      data[FIELD_HIDE_COMMENTING] = false;
    }
    // set schedule and unpublish dates to server if not defined
    if (
      typeof data[FIELD_SCHEDULE_DATE] === 'undefined' &&
      typeof serverData[FIELD_SCHEDULE_DATE] !== 'undefined'
    ) {
      data[FIELD_SCHEDULE_DATE] = serverData[FIELD_SCHEDULE_DATE];
    }
    if (
      typeof data[FIELD_UNPUBLISHED_DATE] === 'undefined' &&
      typeof serverData[FIELD_UNPUBLISHED_DATE] !== 'undefined'
    ) {
      data[FIELD_UNPUBLISHED_DATE] = serverData[FIELD_UNPUBLISHED_DATE];
    }
    // Kill date fields if falsy
    if (!data[FIELD_SCHEDULE_DATE]) {
      delete data[FIELD_SCHEDULE_DATE];
    }
    if (!data[FIELD_UNPUBLISHED_DATE]) {
      delete data[FIELD_UNPUBLISHED_DATE];
    }
    if (!data[FIELD_VANITY_DATE]) {
      delete data[FIELD_VANITY_DATE];
    }
    // Set defaults for new articles if not overridden
    if (!serverData[FIELD_NID]) {
      if (!data[FIELD_ARTICLE_TYPE]) {
        data[FIELD_ARTICLE_TYPE] = serverData[FIELD_ARTICLE_TYPE];
      }
      if (!data[FIELD_CONTENT_SOURCE]) {
        data[FIELD_CONTENT_SOURCE] = serverData[FIELD_CONTENT_SOURCE];
      }
      if (!data[FIELD_WORKFLOW]) {
        data[FIELD_WORKFLOW] = serverData[FIELD_WORKFLOW];
      }
      if (!data[FIELD_PRIORITY]) {
        data[FIELD_PRIORITY] = serverData[FIELD_PRIORITY];
      }
      if (!data[FIELD_PUBLICATIONS]) {
        data[FIELD_PUBLICATIONS] = serverData[FIELD_PUBLICATIONS];
      }
      if (!data[FIELD_DISPLAY_DATE_PREF]) {
        data[FIELD_DISPLAY_DATE_PREF] = serverData[FIELD_DISPLAY_DATE_PREF];
      }
    }
    const payload = super.getPayloadFields(data);
    if (
      Object.keys(data).includes(FIELD_HERO_IMAGE) ||
      Object.keys(data).includes(FIELD_HERO_VIDEO) ||
      Object.keys(data).includes(FIELD_HERO_GALLERY)
    ) {
      // hero array must be built with server data includes to avoid deletion
      const mapping = this.dataMapping[FIELD_HERO];
      const dataState = mergeDeepRight(serverData, data);
      // @todo review and simplify this logic
      if (
        typeof data[FIELD_HERO_IMAGE] !== 'undefined' &&
        (data[FIELD_HERO_IMAGE] === null || emptyObject(data[FIELD_HERO_IMAGE]))
      ) {
        dataState[FIELD_HERO_IMAGE] = null;
      }
      if (
        typeof data[FIELD_HERO_VIDEO] !== 'undefined' &&
        (data[FIELD_HERO_VIDEO] === null || emptyObject(data[FIELD_HERO_VIDEO]))
      ) {
        dataState[FIELD_HERO_VIDEO] = null;
      }
      if (
        typeof data[FIELD_HERO_GALLERY] !== 'undefined' &&
        (data[FIELD_HERO_GALLERY] === null || emptyObject(data[FIELD_HERO_GALLERY]))
      ) {
        dataState[FIELD_HERO_GALLERY] = null;
      }
      payload[mapping.key] = this.getPayloadFieldData(dataState, FIELD_HERO);
    }
    // hack to force update, this gets replaced with 'now' timestamp in api, only if not silent
    if (!data[FIELD_SILENT_UPDATE]) {
      payload[FIELD_LAST_UPDATED] = [{ value: 100 }];
    }
    return payload;
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_NOTES:
      case FIELD_BODY:
        return [{ value: JSON.stringify(data[field]) }];
      case FIELD_PRODUCTS:
        return [
          ...data[field].map(({ data: { id } }) => ({
            target_id: id,
            target_type: PRODUCT_ENTITY_TYPE,
          })),
        ];
      case FIELD_PATH:
        return [{ alias: data[field] }];
      case FIELD_WORKFLOW:
        return data[field] ? [{
          target_id: data[field],
          target_type: 'taxonomy_term',
        }] : [];
      case FIELD_ARTICLE_TYPE:
      case FIELD_BADGE_PREFIX:
        return data[field]?.id ? [{
          target_id: data[field].id,
          target_type: 'taxonomy_term',
        }] : [];
      case FIELD_SECTIONS:
      case FIELD_AUTHORS:
      case FIELD_TOPICS:
      case FIELD_CHAIN:
      case FIELD_EVENTS:
      case FIELD_PEOPLE:
      case FIELD_TEAMS:
      case FIELD_TAGS:
      case FIELD_CONTENT_SOURCE:
        return data[field] ? [
          ...data[field].filter(term => !!term).map(({ id }) => ({
            target_id: id,
            target_type: 'taxonomy_term',
          })),
        ] : [];
      case FIELD_PUBLICATIONS:
        return data[field] ? [
          ...data[field].filter(term => !!term).map(id => ({
            target_id: id,
            target_type: 'taxonomy_term',
          })),
        ] : [];
      case FIELD_HERO: {
        const hero = [];
        if (data[FIELD_HERO_VIDEO]?.data) {
          hero.push(this.getPayloadFieldData(data, FIELD_HERO_VIDEO)[0]);
        }
        if (data[FIELD_HERO_GALLERY]?.data) {
          hero.push(this.getPayloadFieldData(data, FIELD_HERO_GALLERY)[0]);
        }
        if (data[FIELD_HERO_IMAGE]?.data) {
          hero.push(this.getPayloadFieldData(data, FIELD_HERO_IMAGE)[0]);
        }
        return hero;
      }
      case FIELD_BADGE_IMAGE:
      case FIELD_SOCIAL_IMAGE:
        if (!data[field]) {
          return null;
        }
        return super.getPayloadFieldData(data, field);
      case FIELD_HIDE_COMMENTING: // It was decided to reverse this logic...
        return [{ value: !data[field] }];
      case FIELD_IS_EMBARGO:
      case FIELD_IS_HIDDEN_FROM_LAYOUT:
      case FIELD_IS_PREMIUM:
      case FIELD_IS_SENSITIVE:
      case FIELD_ATEX:
        if (!data[field] && data[field] !== false) {
          return null;
        }
        return [{ value: !!data[field] }];
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
  getPayloadExtras(data) {
    // handle captions
    const additional = {};
    if (data[FIELD_HERO_IMAGE]?.data) {
      additional[FIELD_HERO_IMAGE_LOCAL_CAPTION] = data[FIELD_HERO_IMAGE]?.data?.[PROP_LOCAL_CAPTION] || null;
      additional[FIELD_HERO_IMAGE_LOCAL_COPYRIGHT] = data[FIELD_HERO_IMAGE]?.data?.[PROP_LOCAL_COPYRIGHT] || null;
    }
    if (data[FIELD_HERO_VIDEO]?.data) {
      additional[FIELD_HERO_VIDEO_LOCAL_CAPTION] = data[FIELD_HERO_VIDEO]?.data?.[PROP_LOCAL_CAPTION] || null;
      additional[FIELD_HERO_VIDEO_LOCAL_COPYRIGHT] = data[FIELD_HERO_VIDEO]?.data?.[PROP_LOCAL_COPYRIGHT] || null;
    }
    return super.getPayloadExtras(data, additional);
  }
  getValidationErrors(data, serverData, toPublish, liveblog, publication) {
    const errors = super.getValidationErrors(data, serverData);
    const dataState = mergeDeepRight(serverData, data);
    const isPublished = typeof toPublish !== 'undefined' ? toPublish : dataState[FIELD_IS_PUBLISHED];
    const isArticleTypeOf = checkArticleType(dataState[FIELD_ARTICLE_TYPE]);
    const isService = isArticleTypeOf(ARTICLE_TYPE_REGEX_SERVICE);
    const isLiveblog = isArticleTypeOf(ARTICLE_TYPE_REGEX_LIVEBLOG);
    const isReview = isArticleTypeOf(ARTICLE_TYPE_REGEX_REVIEW);
    if (!publication) {
      errors.push({
        field: FIELD_PUBLICATIONS,
        message: 'Publication was not found. Please refresh your browser and try again.',
      });
      return errors;
    }
    if (!dataState[FIELD_HEADLINE] || dataState[FIELD_HEADLINE].length === 0) {
      errors.push({
        field: FIELD_HEADLINE,
        message: 'Missing article headline, please fill it in.',
      });
    } else if (dataState[FIELD_HEADLINE].length > 8000) {
      errors.push({
        field: FIELD_HEADLINE,
        message: 'Headline field has more than 8000 characters.',
      });
    }
    if (isPublished) {
      if (!dataState[FIELD_SHORT_HEADLINE] || dataState[FIELD_SHORT_HEADLINE].length === 0) {
        errors.push({
          field: FIELD_SHORT_HEADLINE,
          message: 'Missing article short headline, please fill it in.',
        });
      }
      if (
        dataState[FIELD_SHORT_HEADLINE] &&
        dataState[FIELD_SHORT_HEADLINE].length > MAX_LENGTH_SHORT_HEADLINE
      ) {
        errors.push({
          field: FIELD_SHORT_HEADLINE,
          message: `Short headline has more than ${MAX_LENGTH_SHORT_HEADLINE} characters.`,
        });
      }
      if (
        dataState[FIELD_SOCIAL_HEADLINE] &&
        dataState[FIELD_SOCIAL_HEADLINE].length > MAX_LENGTH_SOCIAL_HEADLINE
      ) {
        errors.push({
          field: FIELD_SOCIAL_HEADLINE,
          message: `Social headline has more than ${MAX_LENGTH_SOCIAL_HEADLINE} characters.`,
        });
      }
      if (
        dataState[FIELD_SEO_DESCRIPTION] &&
        dataState[FIELD_SEO_DESCRIPTION].length > MAX_LENGTH_SEO_DESCRIPTION
      ) {
        errors.push({
          field: FIELD_SEO_DESCRIPTION,
          message: `SEO description has more than ${MAX_LENGTH_SEO_DESCRIPTION} characters.`,
        });
      }
      if (
        publication.vocabs.some(vocab => vocab === CONTENT_SOURCE) &&
        (
          !dataState[FIELD_CONTENT_SOURCE] ||
          (
            Array.isArray(dataState[FIELD_CONTENT_SOURCE]) &&
            dataState[FIELD_CONTENT_SOURCE].length < 1
          )
        )
      ) {
        errors.push({
          field: FIELD_CONTENT_SOURCE,
          message: 'Please assign a content source to this article.',
        });
      }
      if (!dataState[FIELD_SECTIONS]?.[0]) {
        errors.push({
          field: FIELD_SECTIONS,
          message: 'Please assign a primary section to this article.',
        });
      }
      if (!dataState[FIELD_URL_KEYWORDS]) {
        errors.push({
          field: FIELD_URL_KEYWORDS,
          message: 'Missing url keywords, please fill it in.',
        });
      }
      if (!dataState[FIELD_BODY]) {
        errors.push({
          field: FIELD_BODY,
          message: 'Missing body, please fill it in.',
        });
      }
      if (!isService && !dataState[FIELD_IS_BREAKING_NEWS]) {
        if (
          publication.vocabs.some(vocab => vocab === TOPICS) &&
          (
            !dataState[FIELD_TOPICS] ||
            (
              Array.isArray(dataState[FIELD_TOPICS]) &&
              dataState[FIELD_TOPICS].length < 1
            )
          )
        ) {
          errors.push({
            field: FIELD_TOPICS,
            message: 'Please assign a topic to this article.',
          });
        }
        if (
          publication.vocabs.some(vocab => vocab === TAGS) &&
          (
            !dataState[FIELD_TAGS] ||
            (
              Array.isArray(dataState[FIELD_TAGS]) &&
              dataState[FIELD_TAGS].length < 1
            )
          )
        ) {
          errors.push({
            field: FIELD_TAGS,
            message: 'Please assign a tag to this article.',
          });
        }
      }
      if (
        !isService &&
        (
          !dataState[FIELD_HERO_IMAGE] ||
          (dataState[FIELD_HERO_IMAGE] && emptyObject(dataState[FIELD_HERO_IMAGE])) ||
          (data[FIELD_HERO_IMAGE] && emptyObject(data[FIELD_HERO_IMAGE])) // removed from local
        )
      ) {
        errors.push({
          field: FIELD_HERO_IMAGE,
          message: 'Please assign a hero image to this article.',
        });
      }
      if (isReview) {
        if (!dataState[FIELD_STAR_RATING]) {
          errors.push({
            field: FIELD_STAR_RATING,
            message: 'Missing a star rating.',
          });
        }
        if (!dataState[FIELD_STRUCTURED]?.[ITEM_REVIEWED]?.[PROP_TYPE]) {
          errors.push({
            field: FIELD_STRUCTURED,
            message: 'Missing a structured data type.',
          });
        }
        if (!dataState[FIELD_STRUCTURED]?.[ITEM_REVIEWED]?.[PROP_NAME]) {
          errors.push({
            field: FIELD_STRUCTURED,
            message: 'Missing a structured data name.',
          });
        }
      }
    }
    if (dataState[FIELD_URL_REDIRECT] && dataState[FIELD_NID]) {
      const currentNIDPattern = new RegExp(dataState[FIELD_NID]);
      if (currentNIDPattern.test(dataState[FIELD_URL_REDIRECT])) {
        errors.push({
          field: FIELD_URL_REDIRECT,
          message: REDIRECT_LOOP_DETECTED_ERROR_MESSAGE,
        });
      }
    }
    if (isLiveblog && !(liveblog.name || dataState[FIELD_LIVEBLOG])) {
      errors.push({
        field: FIELD_LIVEBLOG,
        message: 'Please fill in the liveblog title or assign an existing liveblog to this article.',
      });
    }
    if (dataState[FIELD_BODY] && containsInvalidSocialEmbedComponent(dataState[FIELD_BODY])) {
      errors.push({
        field: FIELD_BODY,
        message: INVALID_SOCIAL_EMBED_WARNING,
      });
    }
    return errors;
  }
  sanitizePayload = (payload, localData, serverData) => {
    const data = mergeDeepRight(serverData, localData);
    const articleType = data[FIELD_ARTICLE_TYPE];
    // ignore product field for non product article type
    if (!(articleType?.name && /product/i.test(articleType?.name)) && data[FIELD_PRODUCTS]) {
      const mapping = this.dataMapping[FIELD_PRODUCTS];
      return {
        ...payload,
        [mapping.key]: [],
      };
    }
    return payload;
  };
  getPayloadFromData(data, serverData, publication, silent) {
    if (typeof silent !== 'undefined') {
      data[FIELD_SILENT_UPDATE] = !!silent;
    }
    // if video swapped by api has been overridden set to reset
    if (
      serverData[FIELD_AUTO_VIDEO] === 'video swapped' &&
      (
        data[FIELD_HERO_VIDEO] ||
        !data[FIELD_HERO_VIDEO] && serverData[FIELD_HERO_VIDEO]
      )
    ) {
      data[FIELD_AUTO_VIDEO] = 'reset';
    }
    if (!data[FIELD_PUBLICATIONS] && !serverData[FIELD_PUBLICATIONS]) {
      data[FIELD_PUBLICATIONS] = [publication.id];
    }
    return this.sanitizePayload(
      super.getPayloadFromData(data, serverData),
      data,
      serverData,
    );
  }
}

export default ArticleEntity;
