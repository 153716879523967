import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { fetchList, removeRedirectItem, saveRedirect } from '../../actions/redirect';
import IconButton from '../ui/buttons/IconButton';
import PageSection from '../common/page/PageSection';
import { Table, TableBody, TableHead, TableHeadCell, TableRow, TableCell } from '../ui/table/Table';
import { initialState, invalidRedirect } from './AddNewRedirect';
import ArticleSearch from '../ui/table/filters/TextSearchFilter';

const RedirectListTable = (props) => {
  const {
    paging,
    isFetching,
    preContent,
    saveRedirect: onSave,
    removeRedirectItem: onRemove,
    fetchList: fetchRedirectPreContent,
  } = props;

  const pageOptions = [20, 40, 100];
  const [page, setPage] = useState(0);
  const [editId, setEditId] = useState(null);
  const [query, setQuery] = useState('');
  const [sizePerPage, setSizePerPage] = useState(pageOptions[0]);
  const [errors, setErrors] = useState({});
  const [state, setState] = useState({
    ...initialState,
    id: null,
  });

  useEffect(() => {
    fetchRedirectPreContent({
      q: query,
      limit: sizePerPage,
      offset: page * sizePerPage,
    });
  }, [
    page,
    sizePerPage,
    query,
  ]);

  const {
    total,
  } = paging;

  const columns = ['Source', 'Destination', ''];

  return (
    <>
      <PageSection includePadding>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ArticleSearch
              placeholder={'Search by url'}
              onSubmit={textQuery => setQuery(textQuery)}
            />
          </Grid>
        </Grid>
      </PageSection>
      <PageSection>
        {isFetching && <LinearProgress color={'primary'} />}
        <Table>
          <TableHead>
            <TableRow hasBorder>
              {columns
                .map(label => (<TableHeadCell key={label}>{label}</TableHeadCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            { preContent.length === 0 && <TableRow>
              <TableCell colSpan={columns.length} align="center" variant={'body2'} >No redirect is found</TableCell>
            </TableRow> }
            { preContent.length > 0 && preContent
              .map(({ data: { id, source, destination } }) =>
                (<TableRow hasActions key={id}>
                  <TableCell>{editId === id ? <TextField
                    defaultValue={source}
                    error={errors.source}
                    helperText={errors.source ? errors.source : ''}
                    fullWidth
                    onChange={e => setState({ ...state, source: e.target.value })}
                  /> : source}</TableCell>
                  <TableCell>{editId === id ? <TextField
                    defaultValue={destination}
                    error={errors.destination}
                    helperText={errors.destination ? errors.destination : ''}
                    fullWidth
                    onChange={e => setState({ ...state, destination: e.target.value })}
                  /> : destination}</TableCell>
                  <TableCell>
                    <Grid container wrap={'nowrap'} justify={'flex-end'}>
                      {editId === id && <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                          const invalid = invalidRedirect(state);
                          if (!invalid) {
                            onSave(state);
                            setEditId(null);
                          } else {
                            setErrors(invalid);
                          }
                        }}
                      >Save</Button> }
                      <IconButton
                        onClick={() => {
                          if (editId !== id) {
                            setEditId(id);
                            setState({
                              id,
                              source,
                              destination,
                            });
                          }
                          setEditId(editId !== id ? id : null)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          if (window.confirm('Are you sure you want to remove this item?')) {
                            onRemove(id);
                          }
                        }}
                      ><RemoveIcon /></IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="nav"
          count={total}
          rowsPerPage={sizePerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onChangePage={(e, i) => setPage(i)}
          onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
        />
      </PageSection>
    </>
  );
};

RedirectListTable.propTypes = {
  preContent: PropTypes.array,
  paging: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  removeRedirectItem: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  saveRedirect: PropTypes.func.isRequired,
};

RedirectListTable.defaultProps = {
  preContent: [],
};

export default connect(
  ({ redirect: { preContent, paging, isFetching } }) =>
    ({ preContent, paging, isFetching }),
  { fetchList, removeRedirectItem, saveRedirect },
)(RedirectListTable);
