import {
  FIELD_SHORT_HEADLINE,
  FIELD_SUB_HEADLINE,
} from './article/articleFields';
import { OVERRIDE_CAPSULE_HIGHLIGHT, OVERRIDE_CAPSULE_SECTION } from './layout/layout';

export const PREVIEW_MODE_DESKTOP = 'desktop';
export const PREVIEW_MODE_MOBILE = 'mobile';
export const PREVIEW_MODE_HIDDEN = 'hidden';
export const PREVIEW_MODE_NONE = 'none';
export const PDF_WIDTH = 768;

export const PREVIEW_MAPPING = {
  [FIELD_SHORT_HEADLINE]: 'titleShort',
  [FIELD_SUB_HEADLINE]: 'lead',
};
export const PREVIEW_CAPSULES = [
  OVERRIDE_CAPSULE_SECTION, OVERRIDE_CAPSULE_HIGHLIGHT,
];
