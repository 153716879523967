export const keyFieldsForData = fields => fields
  .filter(item => item[0] !== '')
  .reduce(
    (acc, item) => ({
      [item[0]]: {
        label: item[2],
        key: item[1],
      },
      ...acc,
    }),
    {},
  );

export const keyFieldsForPayload = fields => fields
  .filter(item => item[1] !== '')
  .reduce(
    (acc, item) => ({
      ...acc,
      [item[1]]: {
        label: item[2],
        key: item[0],
      },
    }),
    {},
  );
