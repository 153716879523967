import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  setEditionLockField,
  setArticleEditionProperty,
} from '../../../../actions/edition';

import { FIELD_BODY } from '../../../../constants/edition/editionFields';

import FieldLockContainer from '../../../fieldLock/FieldLockContainerDepricated';

import Editor, { VARIANT_EDITION } from '../../../editor/Editor';

const style = theme => ({
  overlay: {
    margin: theme.spacing(0, -2),
    width: 'calc(100% + 32px)',
  },
  editor: {
    margin: theme.spacing(0, -3),
  },
});

const ArticleContent = ({
  classes, body, sessionLockedFields,
  setArticleEditionProperty: setProp,
  setEditionLockField: lockField,
}) => {
  const lock = sessionLockedFields.filter(({ field }) => field === FIELD_BODY);
  const value = lock.length > 0 ? lock[0].value : body;
  return (
    <FieldLockContainer
      className={classes.overlay}
      field={FIELD_BODY}
      onUnlock={() => lockField([FIELD_BODY, body])}
    >
      <Editor
        className={classes.editor}
        variant={VARIANT_EDITION}
        content={value}
        onChange={newValue => setProp([FIELD_BODY, newValue])}
      />
    </FieldLockContainer>
  );
};

ArticleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  body: PropTypes.array,
};

ArticleContent.defaultProps = {
  body: [],
};

export default withStyles(style)(connect(
  ({
    edition: { body },
    fieldLock: { sessionLockedFields },
  }) =>
    ({
      body,
      sessionLockedFields,
    }), {
    setEditionLockField,
    setArticleEditionProperty,
  })(ArticleContent));
