import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'rxjs';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { CONTEXT_COMPONENT } from '../../../constants/contexts';
import { Title, NewsLetterSignup, AuthorHighlight, Video, VisualStories } from '../../../constants/components';
import { LABEL_TEXT, NEWSLETTER_TARGET, AUTHOR, CONTENT_SOURCE, CONTENT_SOURCE_MANUAL } from '../constants';

import {
  componentManualListSetLink,
  getComponentManualListCount,
} from '../../../actions/layout';

import ComponentUI from './common/ComponentUI';
import ComponentTitle from './common/ComponentTitle';
import ComponentContent from './common/ComponentContent';
import ComponentLinkIndicator from './common/ComponentLinkIndicator';
import ArticleSourceField from './form/ArticleSourceField';
import ArticlesFormGroup from './form/ArticlesFormGroup';
import ManualListTarget, { ManualListUI } from './form/ManualListTarget';

import ComponentLock from '../lock/ComponentLock';
import PlayIncComponentContents from '../../integration/playInc/PlayIncComponentContents';
import { getArticleCountFromType, getComponentDefaultIndexes } from '../../../utils/layoutHelper';
import { makeGetComponent, makeGetComponentLockedData } from '../../../selectors/layout/layout';
import {
  makeGetManualIndexesFromComponent,
  makeGetManualListFromComponent, makeGetManualListLockFromComponent,
} from '../../../selectors/manualList/manualList';
import { FIELD_PINNED } from '../../../constants/layout/layout';
import VisualStoriesSource from './componentContent/VisualStoriesSource';
import VisualStoriesContents from "./componentContent/VisualStoriesContents";

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .component-ui': {
      display: 'none',
    },
    '&:hover .component-ui': {
      display: 'flex',
    },
  },
  content: {
    position: 'relative',
  },
  highlight: {
    position: 'relative',
    background: theme.palette.background.paleBlue,
  },
  title: {
    cursor: 'pointer',
    padding: theme.spacing(1, 0),
  },
  active: {
    color: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerLocked: {
    pointerEvents: 'none',
  },
  metaForm: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    boxSizing: 'border-box',
  },
});

const ComponentMetaForm = withStyles(styles)((props) => {
  const { classes, component } = props;
  const form = [];
  const articleCount = getArticleCountFromType(component.type);
  if (articleCount > 0) {
    form.push(<ArticleSourceField key={'source'} component={component} />);
  }
  if (component?.type === VisualStories) {
    form.push(<VisualStoriesSource key={'vs-source'} component={component} />);
  }
  return <div className={classes.metaForm}>{form}</div>;
});

// @todo move to helper
const getNewsletterTitle = (newsletters, target) => {
  const newsletter = newsletters.filter(({ value }) => value === target);
  if (newsletter.length > 0) {
    return newsletter[0].title;
  }
  return '';
};

const ComponentContentForm = (props) => {
  const { classes, component, manualList, indexes, publicationConfig, position } = props;
  const form = [];
  const articleCount = getArticleCountFromType(component.type);
  if (articleCount > 0) {
    form.push(<ArticlesFormGroup
      key={'formGroup'}
      component={component}
      manualList={manualList}
      indexes={indexes}
      count={articleCount}
      position={position}
    />);
  }
  switch (component.type) {
    case Title:
      if (component[LABEL_TEXT]) {
        form.push(<Typography
          key={'componentTitle'}
          className={classes.typography}
          variant={'body2'}
        >{component[LABEL_TEXT]}</Typography>);
      }
      break;
    case NewsLetterSignup: {
      const newsletterTarget = (!publicationConfig || !publicationConfig.newsletters)
        ? component[NEWSLETTER_TARGET]
        : getNewsletterTitle(publicationConfig.newsletters, component[NEWSLETTER_TARGET]);
      if (newsletterTarget) {
        form.push(<Typography
          key={'componentNewsletter'}
          className={classes.typography}
          variant={'body2'}
        >{newsletterTarget}</Typography>);
      }
      break;
    }
    case AuthorHighlight: {
      if (component[AUTHOR] && component[AUTHOR].name) {
        form.push(
          <Typography key={'author'} className={classes.typography} variant={'body2'}>{component[AUTHOR].name}</Typography>,
        );
      }
      break;
    }
    case Video: {
      if (publicationConfig?.playInc?.endpoint && publicationConfig?.playInc?.domain) {
        form.push(
          <PlayIncComponentContents key={'playinc'} component={component} />,
        );
      }
      break;
    }
    case VisualStories: {
      form.push(
        <VisualStoriesContents key={'visualstories'} component={component} />,
      );
      break;
    }
    default:
      break;
  }
  return form;
};

const Component = (props) => {
  const {
    classes, component, manualIndexes, manualListIndex, position, selectedManualList, context,
    highlighted, isNew, lockData, listLockData, manualList,
    setPreviewFocus, setPreviewHighlight,
    componentManualListSetLink: setLink,
    getComponentManualListCount: getCount,
  } = props;
  const [indexes, setIndexes] = useState([]);
  const { [FIELD_PINNED]: pinned } = component || {};
  useEffect(() => {
    if (manualIndexes) {
      setIndexes(manualIndexes);
    } else {
      setIndexes(getComponentDefaultIndexes(component));
    }
  }, [manualIndexes, pinned]);
  const classesTitle = [classes.title];
  if (context.type === CONTEXT_COMPONENT && context.data.id === component.id) {
    classesTitle.push(classes.active);
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      role="form"
      className={classes.root}
      data-type={'layout-component'}
      onClick={() => setPreviewFocus(position)}
      onMouseEnter={() => setPreviewHighlight(position)}
      onMouseLeave={() => setPreviewHighlight(-1)}
    >
      <ComponentLock
        component={component}
        manualList={manualList}
        lockData={lockData || listLockData}
        isNew={isNew}
        listLocked={!lockData && !!listLockData}
      >
        <ComponentContent className={(highlighted) ? classes.highlight : classes.content}>
          {selectedManualList > -1 && <ManualListTarget
            sourceId={selectedManualList}
            component={component}
            setLink={setLink}
            getCount={getCount}
          />}
          <div className={lockData || listLockData ? `${classes.header} ${classes.headerLocked}` : classes.header}>
            <ComponentTitle component={component} className={classesTitle.join(' ')} position={position} />
            <ComponentLinkIndicator component={component} manualListIndex={manualListIndex} />
            <ComponentMetaForm component={component} />
            {selectedManualList === -1 && <ComponentUI
              disabled={!!(lockData || listLockData)}
              component={component}
              manualListIndex={manualListIndex}
            />}
            {
              selectedManualList > -1 &&
              component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
              <ManualListUI
                component={component}
                sourceId={selectedManualList}
              />
            }
          </div>
          <ComponentContentForm {...props} indexes={indexes} />
        </ComponentContent>
      </ComponentLock>
    </div>
  );
};

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  selectedManualList: PropTypes.number.isRequired,
  context: PropTypes.object.isRequired,
  componentManualListSetLink: PropTypes.func.isRequired,
  getComponentManualListCount: PropTypes.func.isRequired,
  component: PropTypes.object,
  manualList: PropTypes.object,
  manualIndexes: PropTypes.array,
  manualListIndex: PropTypes.number,
  publicationConfig: PropTypes.object,
  setPreviewFocus: PropTypes.func,
  setPreviewHighlight: PropTypes.func,
  highlighted: PropTypes.bool,
  updated: PropTypes.bool,
  isNew: PropTypes.bool,
  lockData: PropTypes.object,
  listLockData: PropTypes.object,
};

Component.defaultProps = {
  setPreviewFocus: noop,
  setPreviewHighlight: noop,
  component: {},
  manualList: {},
  manualIndexes: null,
  manualListIndex: -1,
  publicationConfig: null,
  highlighted: false,
  updated: false,
  isNew: false,
  lockData: null,
  listLockData: null,
};

const mapStateToProps = (state, props) => {
  const getComponent = makeGetComponent();
  const getManualList = makeGetManualListFromComponent();
  const getManualIndexes = makeGetManualIndexesFromComponent();
  const getComponentLockedData = makeGetComponentLockedData();
  const getManualListLockedData = makeGetManualListLockFromComponent(state);
  return {
    component: getComponent(state, props),
    manualList: getManualList(state, props),
    ...getManualIndexes(state, props),
    lockData: getComponentLockedData(state, props),
    listLockData: getManualListLockedData(state, props),
    selectedManualList: state.layout.selectedManualList,
    context: state.layout.context,
    publicationConfig: state.frame.selectedPublication.publicationConfig,
  };
};

export default withStyles(styles)(connect(
  mapStateToProps,
  { componentManualListSetLink, getComponentManualListCount },
)(Component));
