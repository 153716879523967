import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';

import { PlayOutline } from 'mdi-material-ui';

import { VIDEO_TYPES } from '../../../../../../../constants/media/media';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import VideoSelector from '../../../../../../ui/media/VideoSelector';
import TextField from '../../../../../../ui/TextField';

import { PROP_VIDEO, PROP_CAPTION } from '../defaults';

const VideoElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  const video = element[PROP_VIDEO] || null;
  const title = video && video.videoType ? `Video - ${VIDEO_TYPES[video.videoType]}` : 'Video';
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={title} Icon={PlayOutline} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={video ? 2 : 12}>
            <VideoSelector
              onSelected={({ provider, video: data }) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_VIDEO]: {
                  videoType: provider,
                  ...data,
                } }, { at: path });
              }}
              onClose={() => {
                const path = ReactEditor.findPath(editor, element);
                ReactEditor.focus(editor);
                Transforms.setSelection(editor, path);
              }}
              video={video}
            />
          </Grid>
          {video && <Grid item container xs={10} spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={'Caption'}
                value={
                  typeof element[PROP_CAPTION] !== 'undefined' &&
                  element[PROP_CAPTION] !== null
                    ? element[PROP_CAPTION] : video.title
                }
                onChange={(e) => {
                  const path = ReactEditor.findPath(editor, element);
                  Transforms.setNodes(editor, { [PROP_CAPTION]: e.target.value }, { at: path });
                }}
                margin={'none'}
              />
            </Grid>
          </Grid>}
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

VideoElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default VideoElement;
