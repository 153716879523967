import { setDefaults } from '@udecode/slate-plugins';
import deserializeFacebook from './deserializeFacebook';
import renderElementFacebook from './renderElementFacebook';
import { DEFAULTS_FACEBOOK } from './defaults';

const FacebookPlugin = (options) => {
  const { facebook } = setDefaults(options, DEFAULTS_FACEBOOK);
  return ({
    renderElement: renderElementFacebook(options),
    deserialize: deserializeFacebook(options),
    voidTypes: [facebook.type],
  });
};

export default FacebookPlugin;
