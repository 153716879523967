import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';

import { ContextPanelItem } from '../../common/context/ContextPanel';

const LIVE = 'Live';
const NOT_LIVE = 'Not Live';
const SCHEDULED = 'Scheduled';

const getStatusText = (scheduled, serverTimestamp, isPublished) => {
  const now = serverTimestamp || moment().unix();
  if (isPublished) {
    if (scheduled > now) {
      return SCHEDULED;
    }
    return LIVE;
  }
  return NOT_LIVE;
};

const PublishingStatus = ({ label, scheduled, isPublished, serverTimestamp }) => (
  <ContextPanelItem label={label}>
    <Typography variant={'caption'}>{getStatusText(scheduled, serverTimestamp, isPublished)}</Typography>
  </ContextPanelItem>
);

PublishingStatus.propTypes = {
  label: PropTypes.string,
  scheduled: PropTypes.number,
  isPublished: PropTypes.bool,
  serverTimestamp: PropTypes.number,
};

PublishingStatus.defaultProps = {
  label: 'Visibility',
  scheduled: 0,
  isPublished: false,
  serverTimestamp: 0,
};

export default PublishingStatus;
