import { PIN_WEIGHT_KEY } from '../constants/liveblog';
import generateKey from './generateKey';

// @todo refactor and move into specific helpers

export const isPinned = (extra) => {
  if (Array.isArray(extra) || extra === null) return false;
  return Number.isInteger(parseInt(extra[PIN_WEIGHT_KEY], 10));
};

export const camelCase = str => str.slice(0, 1).toLowerCase() + str.slice(1);

export const assignComponentIDIfNotExists = (component) => {
  if (!component.id) {
    return {
      ...component,
      id: generateKey(),
    };
  }
  return component;
};

export const createTestId = (props) => {
  if(props?.id){
    return props.id;
  }
  if(props?.name){
    return props.name;
  }
  if(props?.label){
    const string = props.label.charAt(0).toLowerCase() + props.label.slice(1);
    return string.replace(/[^a-zA-Z]/g, '-').replace(/--/g, '').replace(/-$/g, '').toLowerCase();
  }
  console.warn('we cannot test this element in isolation');
  return 'unknownElement';
};

export const emptyObject = data => Object.keys(data).length === 0;
