import { PURGE } from 'redux-persist';

import generateKey from '../../utils/generateKey';
import {
  MENU_LIST_LOADED,
  MENU_TREE_LOADED,
  MENU_TREE_ONCHANGE,
  MENU_ITEM_SECTION,
  MENU_TREE_ONEDIT,
  MENU_DIALOG_CLOSE,
  MENU_DIALOG_OPEN,
  MENU_ITEM_CHAIN,
} from '../../constants/actionTypes';

const defaultState = {
  menus: [],
  currentMenuTree: {
    menu: [],
  },
  currentMenuItemSection: {},
  dialogMenuOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MENU_TREE_LOADED:
      return { ...state, currentMenuTree: action.value };

    case MENU_TREE_ONCHANGE:
      return { ...state,
        currentMenuTree: {
          ...state.currentMenuTree,
          menu: action.value,
        } };

    case MENU_LIST_LOADED:
      return { ...state, menus: action.value };

    case MENU_DIALOG_OPEN:
      return { ...state, dialogMenuOpen: true };

    case MENU_DIALOG_CLOSE:
      return { ...state, dialogMenuOpen: false };

    case MENU_TREE_ONEDIT:
      return { ...state, dialogMenuOpen: true };

    case MENU_ITEM_CHAIN:
    case MENU_ITEM_SECTION:
      return { ...state,
        currentMenuTree: { ...state.currentMenuTree,
          menu: [...state.currentMenuTree.menu, {
            id: generateKey(),
            title: action.value.name,
            path: action.value.path,
          }],
        },
        dialogMenuOpen: false,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
