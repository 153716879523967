import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import LiveblogBuilder from 'components/liveblog/LiveblogBuilder';
import LiveblogPosts from 'components/liveblog/LiveblogPosts';
import LiveblogSidebar from 'components/liveblog/LiveblogSidebar';

import Page from 'components/common/page/Page';
import PageContent from 'components/common/page/PageContent';
import PageToolbar from 'components/common/page/PageToolbar';

import ControlButtons from 'components/liveblog/ControlButtons';
import {
  saveLiveblogContainer,
  enterLiveblogEdit,
  disposeLiveblogEdit,
} from 'actions/liveblog';

import { LiveblogLandingBreadcrumb } from './LiveblogList';

const LiveblogCreate = (props) => {
  const {
    match: { params: { id } },
    saveLiveblogContainer: onSave,
    disposeLiveblogEdit: dispose,
    enterLiveblogEdit: enter,
  } = props;
  useEffect(() => {
    enter();
    return () => {
      dispose();
    }
  }, []);

  const breadcrumb = [
    ...LiveblogLandingBreadcrumb,
    {
      title: !id ? 'New liveblog' : 'Edit liveblog',
      slug: !id ? 'build' : `edit/0/${id}`,
    },
  ];

  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <ControlButtons editMode={!!id} onSave={onSave} />
      </PageToolbar>}
    >
      <PageContent>
        <Grid item xs={12}>
          <LiveblogBuilder />
        </Grid>
        {!!id && <LiveblogPosts containerId={id} />}
      </PageContent>
      <LiveblogSidebar />
    </Page>
  );
};

LiveblogCreate.propTypes = {
  match: PropTypes.object.isRequired,
  saveLiveblogContainer: PropTypes.func.isRequired,
  disposeLiveblogEdit: PropTypes.func.isRequired,
  enterLiveblogEdit: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  {
    saveLiveblogContainer,
    enterLiveblogEdit,
    disposeLiveblogEdit,
  },
)(LiveblogCreate);
