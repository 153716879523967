import { ofType } from 'redux-observable';
import { debounceTime, filter, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { PAGE_ENTER_ARTICLE_EDIT } from '../../constants/actionTypes/route';
import {
  FIELD_LOCK_DISPOSE, FIELD_LOCK_FIELD,
  FIELD_LOCK_INIT,
  FIELD_LOCK_SET, WS_FIELD_LOCK_UNSET,
} from '../../constants/actionTypes/fieldLock';
import { CONTENT_TYPE_ARTICLE } from '../../constants/article/article';
import { INFO, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import { ARTICLE_FIELD_MAPPING_CMS_KEYED } from '../../constants/article/articleFields';
import { DATASTATE_LOCAL_SET_PROPERTY } from '../../constants/actionTypes/dataState';
import { PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT } from '../../constants/media/media';

export const subscribeOnArticleEnter = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT),
  withLatestFrom(state$),
  mergeMap(([, {
    router: { location: { pathname } },
  }]) => {
    const pathParams = pathname.match(/\/article\/[a-z_]+\/([a-z0-9]+)/);
    return of({
      type: FIELD_LOCK_INIT,
      value: {
        contentType: CONTENT_TYPE_ARTICLE,
        contentId: pathParams[1],
      },
    });
  }),
);

export const LockOnSetProperty = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_ARTICLE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { noUpdate } }) => !noUpdate),
    debounceTime(100),
    filter(({ value: { prop } }) => ![PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT].includes(prop)),
    mergeMap(({ value: { prop, propChain = [] } }) => {
      const fieldName = [prop, ...propChain].join('_');
      return of({
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      });
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const LockOnSetMediaCaption = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_ARTICLE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { noUpdate } }) => !noUpdate),
    debounceTime(100),
    filter(({ value: { prop, propChain } }) =>
      [PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT].includes(prop) &&
      Array.isArray(propChain) && propChain.length > 0,
    ),
    mergeMap(({ value: { propChain } }) => {
      const fieldName = propChain[0];
      return of({
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      });
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutLock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_ARTICLE),
  mergeMap(() => action$.pipe(
    ofType(FIELD_LOCK_SET),
    mergeMap(({ value: { field, user: { name } } }) => {
      const label = ARTICLE_FIELD_MAPPING_CMS_KEYED?.[field]?.label || 'A field';
      return of({
        type: SHOW_NOTIFICATION,
        value: {
          message: `${label} has been locked by ${name}`,
          variant: INFO,
        },
      });
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutUnlock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_ARTICLE),
  mergeMap(() => action$.pipe(
    ofType(WS_FIELD_LOCK_UNSET),
    mergeMap(({ value: { field, user: { name } } }) => {
      const label = ARTICLE_FIELD_MAPPING_CMS_KEYED?.[field]?.label || 'A field';
      return of({
        type: SHOW_NOTIFICATION,
        value: {
          message: `${label} has been unlocked by ${name}, your local changes have been removed.`,
          variant: INFO,
        },
      });
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);
