import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { Grid, Container } from '@material-ui/core';
import { headerHeight } from '../../header';
import { footerHeight } from './Page';

export const PageMainWrapper = withStyles(() => ({
  root:{
    position: 'relative',
  },
}))(({ classes, children, ...rest }) => (
  <Grid classes={classes} container spacing={0} {...rest}>{children}</Grid>
));

export const PageMainContent = withStyles(theme => ({
  root:{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1300,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > :first-child': {
      marginTop: theme.spacing(4),
    },
  },
}))(Container);

const styles = theme => ({
  root: {
    flex: 1,
    flexGrow: 1,
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
    },
  },
});

const PageMainDepricated = ({ classes, children, ...rest }) => (
  <Grid classes={classes} item {...rest}>
    {children}
  </Grid>
);

PageMainDepricated.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(PageMainDepricated);
