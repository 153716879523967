import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setArticleEditionProperty, setEditionLockField,
} from '../../../../actions/edition';

import { FIELD_HEADLINE } from '../../../../constants/article/articleFields';
import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfieldDepricated';

const Headline = ({
  classes, headline,
  setArticleEditionProperty: setProperty, setEditionLockField: lockField, ...rest
}) => (
  <FieldLockTextfield
    required
    multiline
    field={FIELD_HEADLINE}
    value={headline}
    label={'Headline'}
    placeholder={'Type your headline here'}
    onChange={event => setProperty([FIELD_HEADLINE, event.target.value])}
    onUnlock={() => lockField([FIELD_HEADLINE, headline])}
    {...rest}
  />
);

Headline.propTypes = {
  headline: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default connect(
  ({ edition: { headline } }) => ({ headline }),
  { setArticleEditionProperty, setEditionLockField },
)(Headline);

