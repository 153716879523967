import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import { FormatQuoteClose } from 'mdi-material-ui';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';
import MarkupField from '../../../../../ui/MarkupField';

import { PROP_MARKUP, PROP_CITATION, PROP_ALIGN } from '../defaults';
import AlignmentToggle from '../../../common/AlignmentToggle';

const BlockquoteElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Blockquote'}
        Icon={FormatQuoteClose}
        contentEditable={false}
        element={element}
        toolbar={<AlignmentToggle
          align={element[PROP_ALIGN] || null}
          onSelect={(value) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_ALIGN]: value }, { at: path });
          }}
        />}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MarkupField
              label={'Quote'}
              data={element[PROP_MARKUP] || null}
              onChange={(value) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_MARKUP]: value }, { at: path });
              }}
              nested
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Citation'}
              margin={'none'}
              placeholder={'Insert Citation'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_CITATION]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_CITATION] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

BlockquoteElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default BlockquoteElement;
