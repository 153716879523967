import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { SPEAKER_NAME, SPEAKER_ROLE, SPEAKER_IMAGE } from '../../../constants/event/eventFields';
import FieldLockImage from '../../fieldLock/form/FieldLockImage';
import FieldLockTextfield from '../../fieldLock/form/FieldLockTextfield';
import { ArrowSortableListItemContent } from '../../ui/ArrowSortable';

const EventSpeaker = ({
  field, itemId,
}) => (
  <ArrowSortableListItemContent>
    <Grid item xs={3}>
      <FieldLockImage field={[field, itemId, SPEAKER_IMAGE]} label={'Image'} />
    </Grid>
    <Grid item xs={9}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldLockTextfield field={[field, itemId, SPEAKER_NAME]} label={'Name'} />
        </Grid>
        <Grid item xs={12}>
          <FieldLockTextfield field={[field, itemId, SPEAKER_ROLE]} label={'Role'} />
        </Grid>
      </Grid>
    </Grid>
  </ArrowSortableListItemContent>
);

EventSpeaker.propTypes = {
  field: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default EventSpeaker;
