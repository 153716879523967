import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LocalCaption from '../ui/LocalCaption';

export const styles = theme => ({
  imageRatio: {
    position: 'relative',
    paddingTop: '56.25%',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      top: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      verticalAlign: 'bottom',
    },
  },
  imageNoRatio: {
    '& img': {
      maxWidth: '100%',
      width: '100%',
      objectFit: 'cover',
      verticalAlign: 'bottom',
    },
  },
  caption: {
    backgroundColor: '#333',
    color: '#f1f1f1',
    padding: theme.spacing(0.5, 1),
    fontSize: theme.typography.pxToRem(14),
  },
});

const Image = (props) => {
  const { classes, onChangeLocalCaption, noRatio, showCaption, alt = '', ...rest } = props;
  // eslint-disable-next-line jsx-a11y/alt-text
  return (<div className={classes.root}>
    <div className={noRatio ? classes.imageRatio : classes.imageNoRatio}>
      <img alt={alt} {...rest} />
    </div>
    {showCaption && <LocalCaption
      data={rest}
      editable={props.captionEditable}
      onChange={onChangeLocalCaption}
    />}
  </div>);
};

export default withStyles(styles)(Image);

