import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import CallToActionIcon from '@material-ui/icons/CallToAction';

import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE } from '../../../constants/preview';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  button: {
    padding: 6,
    '&:hover': {
      background: 'none',
      color: theme.palette.primary.main,
    },
  },
});

const PreviewUI = (props) => {
  const {
    classes, active, isDocked, desktopToggle, mobileToggle, popoutToggle,
  } = props;
  return (
    <div className={classes.root}>
      {desktopToggle && <IconButton className={classes.button} onClick={desktopToggle} color={active === PREVIEW_MODE_DESKTOP ? 'primary' : 'default'}>
        <DesktopWindowsIcon />
      </IconButton>}
      {mobileToggle && <IconButton className={classes.button} onClick={mobileToggle} color={active === PREVIEW_MODE_MOBILE ? 'primary' : 'default'}>
        <PhoneIphoneIcon />
      </IconButton>}
      {popoutToggle && <IconButton className={classes.button} onClick={popoutToggle} color={'default'}>
        {isDocked && <OpenInBrowserIcon />}
        {!isDocked && <CallToActionIcon />}
      </IconButton>}
    </div>
  );
};

PreviewUI.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.string,
  isDocked: PropTypes.bool,
  desktopToggle: PropTypes.func,
  mobileToggle: PropTypes.func,
  popoutToggle: PropTypes.func,
};

PreviewUI.defaultProps = {
  active: '',
  isDocked: true,
  desktopToggle: null,
  mobileToggle: null,
  popoutToggle: null,
};

export default withStyles(styles)(PreviewUI);
