import { setDefaults } from '@udecode/slate-plugins';
import deserializeHtml from './deserializeHtml';
import renderElementHtml from './renderElementHtml';
import { DEFAULTS_HTML } from './defaults';

const HtmlPlugin = (options) => {
  const { html } = setDefaults(options, DEFAULTS_HTML);
  return ({
    renderElement: renderElementHtml(options),
    deserialize: deserializeHtml(options),
    voidTypes: [html.type],
  });
};

export default HtmlPlugin;
