import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';
import Select from '../../../ui/Select';
import { EDITORS_PICK_PRIORITY_OPTION } from '../../constants';

export const EDITORS_PICK_PRIORITY_TAB_KEY = 'tabPriority';

const EditorsPickOptions = (props) => {
  const { component, componentSetProp: setProp } = props;

  const defaultId = EDITORS_PICK_PRIORITY_OPTION[0].id;
  useEffect(() => {
    if (!component[EDITORS_PICK_PRIORITY_TAB_KEY]) {
      setProp(component.id, EDITORS_PICK_PRIORITY_TAB_KEY, defaultId);
    }
  }, [component]);

  return (
    <Select
      label={'Select tab order'}
      value={component[EDITORS_PICK_PRIORITY_TAB_KEY]}
      defaultValue={defaultId}
      onChange={e => setProp(component.id, EDITORS_PICK_PRIORITY_TAB_KEY, e.target.value)}
      items={EDITORS_PICK_PRIORITY_OPTION}
    />
  );
};

EditorsPickOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(EditorsPickOptions);
