export const EDITION_LIST_ONLOAD = 'EDITION_LIST_ONLOAD';
export const EDITION_UPDATE_PING_APP = 'EDITION_UPDATE_PING_APP';
export const EDITION_UPDATE_PING_APP_SUCCESS = 'EDITION_UPDATE_PING_APP_SUCCESS';
export const EDITION_UPDATE_PING_APP_REJECTED = 'EDITION_UPDATE_PING_APP_REJECTED';
export const EDITION_LIST_DRAFT_ONLOAD = 'EDITION_LIST_DRAFT_ONLOAD';
export const EXPORT_ARTICLE_TO_EDITION = 'EXPORT_ARTICLE_TO_EDITION';
export const EDITION_DETAIL_FETCH = 'EDITION_DETAIL_FETCH';
export const EDITION_ARTICLE_ENTER_PAGE = 'EDITION_ARTICLE_ENTER_PAGE';
export const EDITION_DISPOSE_PAGE = 'EDITION_DISPOSE_PAGE';
export const EDITION_DETAIL_NEW = 'EDITION_DETAIL_NEW';
export const EDITION_DETAIL_SAVE = 'EDITION_DETAIL_SAVE';
export const EDITION_DETAIL_SAVE_SUCCESS = 'EDITION_DETAIL_SAVE_SUCCESS';
export const EDITION_DETAIL_PUBLISH_SUCCESS = 'EDITION_DETAIL_PUBLISH_SUCCESS';
export const EDITION_DETAIL_UNPUBLISH_SUCCESS = 'EDITION_DETAIL_UNPUBLISH_SUCCESS';
export const EDITION_DETAIL_SAVE_REJECTED = 'EDITION_DETAIL_SAVE_REJECTED';
export const EDITION_PAGE_RE_ORDER = 'EDITION_PAGE_RE_ORDER';
export const EDITION_DETAIL_ENTER = 'EDITION_DETAIL_ENTER';
export const EDITION_DETAIL_DISPOSE = 'EDITION_DETAIL_DISPOSE';
export const EDITION_DETAIL_FETCH_SUCCESS = 'EDITION_DETAIL_FETCH_SUCCESS';
export const EDITION_LIST_FETCH_SUCCESS = 'EDITION_LIST_FETCH_SUCCESS';
export const EDITION_LIST_DRAFT_FETCH_SUCCESS = 'EDITION_LIST_DRAFT_FETCH_SUCCESS';
export const EDITION_LIST_DRAFT_FETCH_REJECTED = 'EDITION_LIST_DRAFT_FETCH_REJECTED';
export const EDITION_LIST_FETCH_REJECTED = 'EDITION_LIST_FETCH_REJECTED';
export const EDITION_DETAIL_FETCH_REJECTED = 'EDITION_DETAIL_FETCH_REJECTED';

export const ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY = 'ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY';
export const EDITION_DETAIL_UPDATE = 'EDITION_DETAIL_UPDATE';
export const REMOVE_PAGE_FROM_CONTAINER = 'REMOVE_PAGE_FROM_CONTAINER';

export const SAVE_EDITION_ARTICLE_COMPONENT = 'SAVE_EDITION_ARTICLE_COMPONENT';
export const SORT_EDITION_ARTICLE_COMPONENT = 'SORT_EDITION_ARTICLE_COMPONENT';
export const SET_FOCUS_EDITION_ARTICLE_COMPONENT = 'SET_FOCUS_EDITION_ARTICLE_COMPONENT';
export const ADD_MORE_EDITION_ARTICLE_COMPONENT = 'ADD_MORE_EDITION_ARTICLE_COMPONENT';
export const REMOVE_EDITION_ARTICLE_COMPONENT = 'REMOVE_EDITION_ARTICLE_COMPONENT';
export const MERGE_EDITION_ARTICLE_COMPONENT_TO_PREVIOUS = 'MERGE_EDITION_ARTICLE_COMPONENT_TO_PREVIOUS';
export const EDITION_ARTICLE_IMAGE_REQUEST = 'EDITION_ARTICLE_IMAGE_REQUEST';
export const EDITION_ARTICLE_MARKUP_PASTE_FROM_CLIPBOARD = 'EDITION_ARTICLE_MARKUP_PASTE_FROM_CLIPBOARD';

export const ENTER_ARTICLE_EDITION_CREATION = 'ENTER_ARTICLE_EDITION_CREATION';
export const LEAVE_ARTICLE_EDITION_CREATION = 'LEAVE_ARTICLE_EDITION_CREATION';
export const RESET_ARTICLE_EDITION_CREATION = 'RESET_ARTICLE_EDITION_CREATION';
export const ON_ENTER_ARTICLE_EDITION_CREATION = 'ON_ENTER_ARTICLE_EDITION_CREATION';

export const SAVE_ARTICLE_EDITION = 'SAVE_ARTICLE_EDITION';
export const CLONE_PAGE_EDITION = 'CLONE_PAGE_EDITION';
export const CLONE_EDITION_SUCCESS = 'CLONE_EDITION_SUCCESS';
export const MOVE_ARTICLE_EDITION = 'MOVE_ARTICLE_EDITION';
export const MOVE_ARTICLE_SUCCESS = 'MOVE_ARTICLE_SUCCESS';
export const MOVE_ARTICLE_REJECTED = 'MOVE_ARTICLE_REJECTED';
export const SAVE_ARTICLE_EDITION_READY = 'SAVE_ARTICLE_EDITION_READY';
export const SAVE_ARTICLE_EDITION_SUCCESS = 'SAVE_ARTICLE_EDITION_SUCCESS';
export const CREATE_ARTICLE_EDITION_SUCCESS = 'CREATE_ARTICLE_EDITION_SUCCESS';
export const SAVE_ARTICLE_EDITION_REJECTED = 'SAVE_ARTICLE_EDITION_REJECTED';
export const ARTICLE_EDITION_FETCH_SUCCESS = 'ARTICLE_EDITION_FETCH_SUCCESS';
export const DROP_ARTICLE_EDITION_COMPONENT = 'DROP_ARTICLE_EDITION_COMPONENT';
export const ARTICLE_EDITION_IMAGE_SELECTED = 'ARTICLE_EDITION_IMAGE_SELECTED';
export const SET_ARTICLE_EDITION_PROPERTY = 'SET_ARTICLE_EDITION_PROPERTY';
export const ARTICLE_EDITION_EDIT_READY = 'ARTICLE_EDITION_EDIT_READY';
export const ADD_RELATED_ARTICLE_EDITION = 'ADD_RELATED_ARTICLE_EDITION';
export const SORT_RELATED_ARTICLE_EDITION = 'SORT_RELATED_ARTICLE_EDITION';
export const CHANGE_RELATED_ARTICLE_PROPERTY = 'CHANGE_RELATED_ARTICLE_PROPERTY';
export const REMOVE_RELATED_ARTICLE_EDITION = 'REMOVE_RELATED_ARTICLE_EDITION';
export const EDITION_ARTICLE_REPLACE_VOCAB = 'EDITION_ARTICLE_REPLACE_VOCAB';
export const EDITION_ARTICLE_ADD_VOCAB = 'ADD_ARTICLE_EDITION_VOCAB';
export const REMOVE_ARTICLE_EDITION_VOCAB = 'REMOVE_ARTICLE_EDITION_VOCAB';
export const REMOVE_ARTICLE_EDITION_HERO_IMAGE = 'REMOVE_ARTICLE_EDITION_HERO_IMAGE';
export const SET_IMAGE_HERO_ARTICLE_EDITION = 'SET_IMAGE_HERO_ARTICLE_EDITION';
export const SET_ARTICLE_EDITION_COMPONENT_PROPERTY = 'SET_ARTICLE_EDITION_COMPONENT_PROPERTY';
export const EDITION_BODY_SET_COMPONENT_PROPERTY = 'SET_EDITION_BODY_COMPONENT_PROPERTY';
export const MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY = 'MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY';

export const EDITION_PREVIEW_COMPONENT_HOVER = 'EDITION_PREVIEW_COMPONENT_HOVER';
export const EDITION_PREVIEW_COMPONENT_CLICK = 'EDITION_PREVIEW_COMPONENT_CLICK';
export const EDITION_SECTION_BODY_DISPOSE = 'EDITION_SECTION_BODY_DISPOSE';

export const SET_EDITION_FIELD_LOCK = 'SET_EDITION_FIELD_LOCK';

export const EDITION_REVISION_REVERT = 'EDITION_REVISION_REVERT';
export const EDITION_REVISION_PATCH_SUCCESS = 'EDITION_REVISION_PATCH_SUCCESS';

export const EDITION_ACTION_UPDATE = 'update';
export const EDITION_ACTION_PUBLISH = 'publish';

export const EDITION_FTP_LOG_REQUEST = 'EDITION_FTP_LOG_REQUEST';
export const EDITION_FTP_LOG_DISPOSE = 'EDITION_FTP_LOG_DISPOSE';
export const EDITION_FTP_LOG_SUCCESS = 'EDITION_FTP_LOG_SUCCESS';
export const EDITION_FTP_LOG_REJECTED = 'EDITION_FTP_LOG_REJECTED';

export const EDITION_EXPORT_FTP_FETCH_LIST = 'EDITION_EXPORT_FTP_FETCH_LIST';
export const EDITION_EXPORT_FTP_LIST_SUCCESS = 'EDITION_EXPORT_FTP_LIST_SUCCESS';
export const EDITION_EXPORT_FTP_LIST_REJECTED = 'EDITION_EXPORT_FTP_LIST_REJECTED';
export const EDITION_EXPORT_FTP_SAVE = 'EDITION_EXPORT_FTP_SAVE';
export const EDITION_EXPORT_FTP_SUCCESS = 'EDITION_EXPORT_FTP_SUCCESS';
export const EDITION_EXPORT_FTP_REJECTED = 'EDITION_EXPORT_FTP_REJECTED';
export const EDITION_EXPORT_FTP_DELETE = 'EDITION_EXPORT_FTP_DELETE';

export const EDITION_EXPORT_FETCH_SCHEDULED = 'EDITION_EXPORT_FETCH_SCHEDULED';
export const EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS = 'EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS';
export const EDITION_EXPORT_FETCH_SCHEDULED_REJECTED = 'EDITION_EXPORT_FETCH_SCHEDULED_REJECTED';
export const EDITION_EXPORT_SCHEDULE = 'EDITION_EXPORT_SCHEDULE';
export const EDITION_EXPORT_SCHEDULE_SUCCESS = 'EDITION_EXPORT_SCHEDULE_SUCCESS';
export const EDITION_EXPORT_SCHEDULE_REJECTED = 'EDITION_EXPORT_SCHEDULE_REJECTED';
export const EDITION_EXPORT_UNSCHEDULE = 'EDITION_EXPORT_UNSCHEDULE';
