export const PLAYINC_FETCH_VIDEOS = 'PLAYINC_FETCH_VIDEOS';
export const PLAYINC_FETCH_VIDEOS_SUCCESS = 'PLAYINC_FETCH_VIDEOS_SUCCESS';
export const PLAYINC_FETCH_VIDEOS_FAILURE = 'PLAYINC_FETCH_VIDEOS_FAILURE';
export const PLAYINC_DISPOSE_VIDEOS = 'PLAYINC_DISPOSE_VIDEOS';

export const PLAYINC_FETCH_PLAYLISTS = 'PLAYINC_FETCH_PLAYLISTS';
export const PLAYINC_FETCH_PLAYLISTS_SUCCESS = 'PLAYINC_FETCH_PLAYLISTS_SUCCESS';
export const PLAYINC_FETCH_PLAYLISTS_FAILURE = 'PLAYINC_FETCH_PLAYLISTS_FAILURE';
export const PLAYINC_DISPOSE_PLAYLISTS = 'PLAYINC_DISPOSE_PLAYLISTS';

export const PLAYINC_SAVE_VIDEO = 'PLAYINC_SAVE_VIDEO';
export const PLAYINC_SAVE_VIDEO_SUCCESS = 'PLAYINC_SAVE_VIDEO_SUCCESS';
export const PLAYINC_SAVE_VIDEO_FAILURE = 'PLAYINC_SAVE_VIDEO_FAILURE';

