import {
  CHP_FETCH_MEDIA,
  GALLERY_CONTEXT_RESET,
  GALLERY_CONTEXT_SET,
  GALLERY_DISPOSE,
  GALLERY_FETCH,
  GALLERY_INIT,
  GALLERY_SAVE,
  GALLERY_SEARCH,
  MEDIA_CREATE_ENTITY,
  MEDIA_IMAGE_SAVE,
  MEDIA_IMAGE_SAVE_EDIT,
  PLAYLIST_FETCH,
  VIDEO_DUGOUT_SEARCH,
  VIDEO_JWPLAYER_SEARCH,
} from '../constants/actionTypes/media';

export const fetchCHPMediaObject = (data, callback) =>
  ({ type: CHP_FETCH_MEDIA, value: { data, callback } });

export const saveMediaImage = (data, callback) => ({ type: MEDIA_IMAGE_SAVE, value: { data, callback } });
export const saveMediaEdit = (data, callback) => ({ type: MEDIA_IMAGE_SAVE_EDIT, value: { data, callback } });

export const createMediaEntity = (type, data, callback) =>
  ({ type: MEDIA_CREATE_ENTITY, value: { type, data, callback } });

export const fetchTVPlaylist = value => ({ type: PLAYLIST_FETCH, value });

// Gallery
export const initGallery = () => ({ type: GALLERY_INIT });
export const fetchGallery = value => ({ type: GALLERY_FETCH, value });
export const disposeGallery = () => ({ type: GALLERY_DISPOSE });

export const setGalleryContext = (type, data) =>
  ({ type: GALLERY_CONTEXT_SET, value: { type, data } });
export const resetGalleryContext = () => ({ type: GALLERY_CONTEXT_RESET });

export const saveGallery = value => ({ type: GALLERY_SAVE, value });
export const searchGallery = value => ({ type: GALLERY_SEARCH, value });

// Video
export const searchVideoJWPlayer = value => ({ type: VIDEO_JWPLAYER_SEARCH, value });
export const searchVideoDugout = value => ({ type: VIDEO_DUGOUT_SEARCH, value });
