import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import ListTable from '../components/table/ListTable';
import { deleteUserEvent, fetchUserEvents, setUserEventsFilter } from '../actions/service/userEvents';
import TableHeader from '../components/table/TableHeader';
import { TableBody, TableCell, TablePagination, TableRow } from '../components/ui/table/Table';
import IconButton from '../components/ui/buttons/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import moment from 'moment';
import { CMS_DATE_FORMAT } from '../constants/common';
import TableToolbar, { VARIANT_DENSE } from '../components/ui/table/TableToolbar';
import Select from '../components/ui/Select';
import UserEventsDialog from '../components/service/UserEventsDialog';

export const EventListBreadcrumb = [
  {
    title: 'Events',
    slug: 'user-events',
  },
];

export const USER_EVENTS_CATEGORIES = [
  'Car boot and Jumble sales',
  'Clubs / Groups / Societies',
  'Charity events',
  'Family fun days / events',
  'Film',
  'Markets',
  'On stage (Music, Theatre, Performance)',
  'Sports / Fitness / Outdoors activities',
  'Students',
  'Other',
];

const UserEventList = ({
  isFetching, items, filter, lastKey, publicationId,
  fetchUserEvents: fetchList,
  deleteUserEvent: deleteEvent,
  setUserEventsFilter: setFilter,
}) => {
  const [editEvent, setEditEvent] = useState(null);
  const [filterCategory, setFilterCategory] = useState('');
  const [keyHistory, setKeyHistory] = useState([]);
  const [page, setPage] = useState(0);
  const headers = ['Name', 'Location', 'Category', 'Start Date', 'End Date', ''];

  const queryData = [
    filter,
    publicationId,
  ];

  const getFetchData = () => filter || {};

  useEffect(() => {
    setFilter({
      category: filterCategory,
    });
  }, [setFilter, filterCategory]);

  useEffect(() => {
    if (lastKey && keyHistory.length > 0) {
      setPage(1);
    } else if (lastKey) {
      setPage(0);
    } else {
      setPage(2);
    }
  }, [lastKey, keyHistory]);

  return (
    <Page toolbarContent={<PageToolbar breadcrumb={EventListBreadcrumb} />}>
      <PageContent>
        <PageSection>
          <TableToolbar variant={VARIANT_DENSE}>
            <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
              <Grid item xs={3}>
                <Select
                  label={'Category'}
                  items={USER_EVENTS_CATEGORIES}
                  value={filterCategory}
                  emptyValue={'All'}
                  onChange={e => setFilterCategory(e.target.value)}
                />
              </Grid>
            </Grid>
          </TableToolbar>
        </PageSection>
        <PageSection>
          <ListTable
            getFetchData={getFetchData}
            isFetching={isFetching}
            fetchList={fetchList}
            queryData={queryData}
          >
            <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
            <TableBody>
              {items.length === 0 && <TableRow>
                <TableCell colSpan={headers.length} align={'center'}>No events found</TableCell>
              </TableRow>}
              {items.length > 0 && items
                .map(item => {
                  const { createdAt, name, location, category, startDate, endDate } = item;
                  return (
                    <TableRow key={createdAt} hasActions>
                      <TableCell>{name}</TableCell>
                      <TableCell>{location}</TableCell>
                      <TableCell>{category}</TableCell>
                      <TableCell>{moment.unix(startDate/1000).format(CMS_DATE_FORMAT)}</TableCell>
                      <TableCell>{moment.unix(endDate/1000).format(CMS_DATE_FORMAT)}</TableCell>
                      <TableCell>
                        <Grid spacing={2} container wrap={'nowrap'} justify={'flex-end'}>
                          <Grid item>
                            <IconButton
                              onClick={() => {
                                setEditEvent(item);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() =>
                                window.confirm('Please confirm you want to delete this event')
                                && deleteEvent({ createdAt })
                              }
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </ListTable>
          {(lastKey || keyHistory.length > 0) && <TablePagination
            noCaption
            rowsPerPageOptions={[]}
            rowsPerPage={1}
            count={3}
            page={page}
            component={'nav'}
            backIconButtonProps={{
              'aria-label': 'Previous',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next',
            }}
            onChangePage={(e, i) => {
              if (i < page) {
                keyHistory.pop();
                setKeyHistory(keyHistory);
                setFilter(keyHistory?.[0] || { category: filterCategory });
              } else {
                setFilter(lastKey);
                keyHistory.push(lastKey);
                setKeyHistory(keyHistory);
              }
            }}
          />}
        </PageSection>
      </PageContent>
      {editEvent && <UserEventsDialog
        handleClose={() => setEditEvent(null)}
        data={editEvent}
        open={!!editEvent}
      />}
    </Page>
  );
};

UserEventList.propTypes = {
  publicationId: PropTypes.number.isRequired,
  setUserEventsFilter: PropTypes.func.isRequired,
  fetchUserEvents: PropTypes.func.isRequired,
  deleteUserEvent: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  lastKey: PropTypes.object,
};

UserEventList.defaultProps = {
  lastKey: null,
};

export default connect(
  ({
    frame: { selectedPublication: { id: publicationId } },
    userEvents: { isFetching, items, lastKey, filter },
  }) => ({ isFetching, items, lastKey, filter, publicationId }),
  { fetchUserEvents, deleteUserEvent, setUserEventsFilter },
)(UserEventList);
