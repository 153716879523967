import { Transforms } from 'slate';
import {
  getNodes,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ELEMENT_OL,
} from '@udecode/slate-plugins';
import { paragraph } from '../../helper/elements';

// @todo rework insert empty P to be handled with an withPlugin
const insertEmbed = element => (editor) => {
  const rootPosition = editor?.selection?.focus?.path[0] || 0;
  const [nodeList] = getNodes(editor, { match: { type: [ELEMENT_UL, ELEMENT_OL] } });
  const [nodeP] = getNodes(editor, { match: { type: ELEMENT_PARAGRAPH } });
  if (nodeList) {
    Transforms.insertNodes(editor, [element, paragraph], { at: [rootPosition + 1] });
    Transforms.select(editor, [rootPosition + 2]);
  } else if (
    nodeP &&
    nodeP[0].children.length === 1 &&
    nodeP[0].children[0].text === ''
  ) {
    Transforms.setNodes(editor, element, { at: nodeP[1] });
  } else {
    Transforms.insertNodes(editor, [element]);
  }
};

export default insertEmbed;
