// automatic single and double replacement, ignore them inside html tag
export const reformatQuote = text => text
  // open singles
  .replace(
    /(^')|((([\s{[(>]')|([\s>]"'))(?!([^<])*?>))/gi,
    $1$2 => $1$2.replace(/'/g, '\u2018'),
  )
  // closing singles + apostrophes
  .replace(/'(?!([^<])*?>)/gi, '\u2019')
  // open doubles
  .replace(
    /(^")|((([\s{[(>]")|([\s>]'"))(?!([^<])*?>))/gi,
    $1$2 => $1$2.replace(/"/g, '\u201c'),
  )
  // closing doubles
  .replace(/"(?!([^<])*?>)/g, '\u201d');
