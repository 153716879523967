import BaseEntity from './common/BaseEntity';

import {
  PRODUCT_FIELD_MAPPING,
  PRODUCT_PAYLOAD_FIELDS,
  PRODUCT_EXTRA_FIELDS,
  FIELD_BODY,
} from '../constants/product/productFields';

const ENTITY_TYPE = 'flowz_product_type';
const ENTITY_BUNDLE = 'product';

class ProductEntity extends BaseEntity {
  constructor() {
    super(
      ENTITY_TYPE,
      ENTITY_BUNDLE,
      PRODUCT_FIELD_MAPPING,
      PRODUCT_PAYLOAD_FIELDS,
      PRODUCT_EXTRA_FIELDS,
    );
  }
  getFieldData(payload, field) {
    const mapping = this.dataMapping[field];
    switch (field) {
      case FIELD_BODY: {
        try {
          return JSON.parse(payload[mapping.key][0].value);
        } catch (e) {
          return this.getFieldDefault(field);
        }
      }
      default:
        return super.getFieldData(payload, field);
    }
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_BODY:
        return [{ value: JSON.stringify(data[field]) }];
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
}

export default ProductEntity;
