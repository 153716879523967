import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment';

import { saveEvent, deleteEvent, previewEvent } from '../../actions/event';

import { PageToolbarButtons } from '../common/page/PageToolbar';
import Button from '../ui/buttons/Button';

import IconButton from '../ui/buttons/IconButton';

import { getIsPublished } from '../helper/scheduler';
import { PRODUCTION } from '../../constants/env';

import { slugify } from '../../utils/urlHelper';
import { FIELD_PATH, FIELD_ID, FIELD_PUBLISHED } from '../../constants/common/commonFields';
import { FIELD_NAME } from '../../constants/event/eventFields';

const styles = theme => ({
  drawer: {
    maxWidth: 450,
    minWidth: 450,
    padding: theme.spacing(3),
    '&>.MuiGrid-container': {
      marginBottom: theme.spacing(4),
    },
  },
  moreAction: {
    marginRight: theme.spacing(-2),
  },
  scheduled: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiButton-label': {
      width: 190,
      whiteSpace: 'normal',
      lineHeight: '1.1em',
    },
  },
});

const EventControlButtons = (props) => {
  const {
    classes,
    id,
    alias,
    published,
    saveEvent: doSave,
    deleteEvent: doDelete,
    previewEvent: requestPreview,
    roles,
    selectedPublication,
    mode,
  } = props;
  const [isPublished, setIsPublished] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setIsPublished(published ? getIsPublished(moment(published).unix()) : false);
  }, [published]);

  const openLive = () => {
    const path = alias || `/events-preview?id=${id}`;
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url : selectedPublication.environment.web;
    window.open(`${frontEndUrl.replace(/\/$/, '')}${path}`, '_blank');
    return false;
  };

  const buttons = [];

  if (!isPublished) {
    buttons.push({
      title: 'Save',
      onClick: () => doSave(isPublished),
      startIcon: <SaveIcon />,
    });
  }

  buttons.push(
    {
      title: isPublished ? 'Update' : 'Publish',
      onClick: () => doSave(true),
      startIcon: <PublishIcon />,
      variant: 'contained',
    },
  );

  return (
    <PageToolbarButtons>
      {!isPublished && <Button
        onClick={requestPreview}
        startIcon={<OpenInBrowserIcon />}
      >Preview</Button>}
      {isPublished && <Button
        onClick={() => openLive()}
        startIcon={<VisibilityIcon />}
      >Live Event</Button>}
      {buttons.map(button => (<Button key={button.title} {...button}>{button.title}</Button>))}
      <IconButton
        className={classes.moreAction}
        onClick={(event) => {
          if (!id || !roles.some(role => role === 'cms_admin')) {
            return;
          }
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVert style={{ fill: '#DADADA' }} />
      </IconButton>
      <Menu
        id={'long-menu'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isPublished && <MenuItem
          onClick={() => {
            doSave(!isPublished);
            setAnchorEl(null);
          }}
        >Unpublish</MenuItem>}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (window.confirm('Please confirm you want delete this event?')) {
              doDelete();
            }
          }}
        >Delete</MenuItem>
      </Menu>
    </PageToolbarButtons>
  );
};

EventControlButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  saveEvent: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  previewEvent: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  alias: PropTypes.string,
  id: PropTypes.number,
  roles: PropTypes.array,
  published: PropTypes.string,
};

EventControlButtons.defaultProps = {
  id: null,
  alias: '',
  roles: [],
  published: null,
};

export default withStyles(styles)(connect(
  ({
    serverState: {
      [FIELD_ID]: id,
      [FIELD_PATH]: alias,
      [FIELD_PUBLISHED]: published,
    },
    login: { user: { roles } },
    frame: { selectedPublication },
    dashboard: { mode },
  }) => (
    {
      id,
      alias,
      published,
      roles,
      selectedPublication,
      mode,
    }
  ),
  { saveEvent, deleteEvent, previewEvent },
)(EventControlButtons));
