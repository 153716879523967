import React from 'react';
import PropTypes from 'prop-types';
import Headshot from './common/Headshot';

const FIELD_HEADSHOTS = 'headshots';
const HEADSHOT_COUNT = 4;

const HeadshotsX4 = (props) => {
  const {
    component: { headshots = [] },
    delta, mergeProperty, onImageRequest,
  } = props;
  const headshotArray = Array.isArray(headshots) ? headshots : Object.values(headshots);
  while (headshotArray.length < HEADSHOT_COUNT) {
    headshotArray.push({});
  }
  return (
    <>
      {headshotArray.map((article, index) => (
        <Headshot
          key={`${delta}-article-${index}`}
          delta={index}
          componentDelta={delta}
          article={article}
          prop={FIELD_HEADSHOTS}
          mergeProperty={mergeProperty}
          onImageRequest={onImageRequest}
        />
      ))}
    </>
  );
};

HeadshotsX4.propTypes = {
  component: PropTypes.object.isRequired,
  delta: PropTypes.number.isRequired,
  mergeProperty: PropTypes.func.isRequired,
  onImageRequest: PropTypes.func.isRequired,
};

export default HeadshotsX4;
