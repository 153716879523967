import { setDefaults } from '@udecode/slate-plugins';
import renderElementHideAndShow from './renderElementHideAndShow';
import { DEFAULTS_HIDE_AND_SHOW } from './defaults';

const HideAndShowPlugin = (options) => {
  const { hideAndShow } = setDefaults(options, DEFAULTS_HIDE_AND_SHOW);
  return ({
    renderElement: renderElementHideAndShow(options),
    voidTypes: [hideAndShow.type],
  });
};

export default HideAndShowPlugin;
