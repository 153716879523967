import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import TableFilters, { TableFiltersGroup } from '../components/ui/table/TableFilters';
import DatePickerFilter from '../components/ui/table/filters/DatePickerFilter';
import StatusFilter, { STATUS_SCHEDULED } from '../components/ui/table/filters/StatusFilter';
import TextSearchFilter from '../components/ui/table/filters/TextSearchFilter';
import EventListTable from '../components/event/eventList/EventListTable';
import AccessToggle from '../access/AccessToggle';
import Button from '../components/ui/buttons/Button';
import { CREATE_EVENT } from '../constants/permission';

export const EventListBreadcrumb = [
  {
    title: 'Events',
    slug: 'event',
  },
];

const EventList = ({ push: routeChange }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={EventListBreadcrumb}>
          <PageToolbarButtons>
            <AccessToggle restrictedTo={CREATE_EVENT}>
              <Button
                onClick={() => routeChange('/event/build')}
                variant={'contained'}
                startIcon={<AddIcon />}
              >New Event</Button>
            </AccessToggle>
          </PageToolbarButtons>
        </PageToolbar>}
    >
      <PageContent>
        <Grid item xs={12}>
          <TableFilters>
            <TableFiltersGroup>
              <TextSearchFilter
                placeholder={'Search by Title / Body'}
                onSubmit={textQuery => setSearchQuery(textQuery)}
              />
              <StatusFilter
                onChange={setStatus}
                value={status}
                margin={'none'}
                exclude={[STATUS_SCHEDULED]}
              />
              <DatePickerFilter
                toDate={toDate}
                fromDate={fromDate}
                onChangeFromDate={date => setFromDate(date)}
                onChangeToDate={date => setToDate(date)}
              />
            </TableFiltersGroup>
          </TableFilters>
        </Grid>
        <PageSection>
          <EventListTable
            textQuery={searchQuery}
            status={status}
            fromDate={fromDate ? fromDate.unix() : null}
            toDate={toDate ? toDate.unix() : null}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

EventList.propTypes = {
  push: PropTypes.func.isRequired,
};

export default connect(
  () => ({ }),
  { push },
)(EventList);
