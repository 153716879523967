import { PURGE } from 'redux-persist';
import {
  TABLE_SET_FILTER_BY,
  TABLE_SET_ORDER_BY,
  TABLE_SET_PAGE,
  TABLE_SET_PAGE_ROWS,
  TABLE_SET_HEADER,
} from '../../constants/actionTypes/table';

const defaultState = {
  order: 'asc',
  orderBy: 'name',
  filterBy: '',
  page: 0,
  rowsPerPage: 20,
  headers: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TABLE_SET_ORDER_BY: {
      const isDescending = state.orderBy === action.value && state.order === 'desc';

      return {
        ...state,
        orderBy: action.value,
        order: isDescending ? 'asc' : 'desc',
      };
    }
    case TABLE_SET_FILTER_BY:
      return { ...state, filterBy: action.value, page: 0 };

    case TABLE_SET_PAGE:
      return { ...state, page: action.value };

    case TABLE_SET_PAGE_ROWS:
      return { ...state, rowsPerPage: action.value, page: 0 };

    case TABLE_SET_HEADER:
      return {
        ...state,
        headers: {
          ...state.headers,
          ...action.value,
        },
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
