import { setDefaults } from '@udecode/slate-plugins';
import deserializeBlockquote from './deserializeBlockquote';
import renderElementBlockquote from './renderElementBlockquote';
import { DEFAULTS_BLOCKQUOTE } from './defaults';

const BlockquotePlugin = (options) => {
  const { blockquote } = setDefaults(options, DEFAULTS_BLOCKQUOTE);
  return ({
    renderElement: renderElementBlockquote(options),
    deserialize: deserializeBlockquote(options),
    voidTypes: [blockquote.type],
  });
};

export default BlockquotePlugin;
