import { ofType } from 'redux-observable';
import {
  debounceTime,
  filter, map, mergeMap, takeUntil, withLatestFrom,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { from, fromEvent, of } from 'rxjs';
import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import 'moment-timezone';

import sendMessage from '../../utils/sendMessage';
import {
  DATASTATE_EXTERNAL_SET_PROPERTY, DATASTATE_EXTERNAL_UNSET_PROPERTY, DATASTATE_LOCAL_DISPOSE,
  DATASTATE_LOCAL_SET_PROPERTY, DATASTATE_LOCAL_UNSET_PROPERTY,
} from '../../constants/actionTypes/dataState';
import {
  INIT_FIELD_LOCKING,
  DISPOSE_FIELD_LOCKING,
  FIELD_LOCK_SUBSCRIBE,
  FIELD_LOCK_UNSUBSCRIBE,
  SESSION_LOCK_FIELD,
  SESSION_LOCK_FIELD_REQUEST,
  SESSION_UNLOCK_FIELD,
  SESSION_UNLOCK_FIELD_REQUEST,
  SET_FIELD_LOCK,
  REMOVE_FIELD_LOCK,
  FIELD_LOCKING_DISPOSED,
  ADD_FIELD_LOCK,
  FIELD_LOCK_SET,
  FIELD_LOCK_INIT,
  FIELD_LOCK_DISPOSE,
  FIELD_LOCK_UNSET,
  WS_FIELD_LOCK_REFRESH,
  WS_FIELD_LOCK_SET,
  WS_FIELD_LOCK_UNSET,
  FIELD_LOCK_FIELD,
  FIELD_UNLOCK_FIELD, FIELD_LOCK_REFRESH,
} from '../../constants/actionTypes/fieldLock';
import { ARTICLE_EDIT_DISPOSE } from '../../constants/actionTypes/article';
import apiCatchError, { showErrorNotification } from '../helper/notification';
import {
  AWS_DATASTATE_DELETE_REJECTED,
  AWS_DATASTATE_GET_REJECTED, AWS_DATASTATE_POST_REJECTED, AWS_DATASTATE_PUT_REJECTED,
  AWS_DATASTATE_QUERY_REJECTED,
} from '../../constants/actionTypes/aws';
import { setExternalData, setLocalData } from '../../actions/dataState';
import { buildStatesFromDynamoDB, getFromState, getPropState } from '../../utils/stateHelper';
import { INFO, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';

const CONTENT_LOCK_TYPES = [];
const CONTENT_NOTIFICATION_TYPES = [];

export const subOnInit = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  withLatestFrom(state$),
  mergeMap(([
    { value: { contentType, contentId } },
    { frame: { socketId: id } },
  ]) => of(
    sendMessage({
      type: FIELD_LOCK_SUBSCRIBE,
      value: {
        contentType,
        contentId,
        id,
      },
    }),
  )),
);

export const fetchExternalOnInit = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  withLatestFrom(state$),
  mergeMap(([
    { value: { contentType, contentId } },
    { login: { user: { uid } } },
  ]) => ajax
    .getJSON(`/api/aws/data-state/${contentType}/${contentId}`)
    .pipe(
      mergeMap(({ success, data }) => {
        const { Items = [] } = data || {};
        if (!success) {
          // @todo error gracefully
          return showErrorNotification('Error: could not get initial data')();
        }
        const { localState, externalState } = buildStatesFromDynamoDB(Items, uid);

        return from([
          setLocalData(localState),
          setExternalData(externalState),
          // @todo review performance of this, might be better as one action
          ...Items
            .filter(({ userData }) => userData.uid !== uid)
            .map(({ fieldName, timestamp, userData: user }) => ({
              type: FIELD_LOCK_SET,
              value: {
                fieldName,
                timestamp,
                user,
              },
            })),
        ]);
      }),
      apiCatchError(AWS_DATASTATE_QUERY_REJECTED),
    ),
  ),
);

export const disposeOnRouteChange = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  mergeMap(() => action$.pipe(
    ofType(LOCATION_CHANGE),
    map(() => ({ type: FIELD_LOCK_DISPOSE })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const unsubOnDispose = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_DISPOSE),
  withLatestFrom(state$),
  mergeMap(([, {
    frame: { socketId: id },
  }]) => of(
    sendMessage({
      type: FIELD_LOCK_UNSUBSCRIBE,
      value: { id },
    }),
  )),
);

export const LockOnSetProperty = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => CONTENT_LOCK_TYPES.includes(contentType)),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { noUpdate } }) => !noUpdate),
    debounceTime(100),
    mergeMap(({ value: { prop, propChain = [] } }) => {
      const fieldName = [...propChain, prop].join('_');
      return of({
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      });
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const broadcastFieldLock = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  mergeMap(({ value: { contentType, contentId } }) => action$.pipe(
    ofType(FIELD_LOCK_FIELD),
    withLatestFrom(state$),
    mergeMap(([
      { value: { fieldName } },
      {
        localState, serverState,
        login: { user: { name, uid, mail } },
        frame: { socketId: id },
      },
    ]) => {
      const propChain = fieldName.split('_');
      const params = {
        fieldName,
        user: { name, uid, mail },
        payload: getPropState(propChain, localState, serverState),
      };
      return ajax
        .post(`/api/aws/data-state/${contentType}/${contentId}`, params, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(({ response: { success, message } }) => {
            if (!success) {
              // @todo handle error
              return showErrorNotification(message || 'Error locking field')();
            }
            return of(sendMessage({
              type: WS_FIELD_LOCK_SET,
              value: {
                contentType,
                contentId,
                fieldName,
                id,
              },
            }));
          }),
          apiCatchError(AWS_DATASTATE_POST_REJECTED),
        );
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const broadcastLayoutFieldUnlock = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  mergeMap(({ value: { contentType, contentId } }) => action$.pipe(
    ofType(FIELD_UNLOCK_FIELD),
    withLatestFrom(state$),
    mergeMap(([
      { value: { fieldName } },
      {
        frame: { socketId: id },
        login: { user: { name, uid, mail } },
      },
    ]) => ajax
      .delete(`/api/aws/data-state/${contentType}/${contentId}?fieldName=${fieldName}`)
      .pipe(
        mergeMap(({ response: { success, message } }) => {
          if (!success) {
            // @todo handle error
            return showErrorNotification(message || 'Error unlocking field')();
          }
          return from([sendMessage({
            type: WS_FIELD_LOCK_UNSET,
            value: {
              contentType,
              contentId,
              fieldName,
              id,
              user: { name, uid, mail },
            },
          }), {
            type: FIELD_LOCK_UNSET,
            value: fieldName,
          }]);
        }),
        apiCatchError(AWS_DATASTATE_PUT_REJECTED),
      ),
    ),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const handleDataOnFieldLock = (action$, state$) => action$.pipe(
  ofType(WS_FIELD_LOCK_SET),
  withLatestFrom(state$),
  mergeMap(([
    { value: { contentType, contentId, fieldName: field } },
    { login: { user: { uid } }, fieldLock },
  ]) => ajax
    .getJSON(`/api/aws/data-state/${contentType}/${contentId}?fieldName=${field}`)
    .pipe(
      mergeMap(({ success, data: { Item: { fieldName, payload, timestamp, userData: user } } }) => {
        if (!success) {
          // @todo error gracefully
          return showErrorNotification('Error: could not get locking data')();
        }
        const propChain = fieldName.split('_');
        if (user.uid === uid) {
          // when the user has 2 open sessions we attempt to match the local state
          // noUpdate prevents lock loop
          return of({
            type: DATASTATE_LOCAL_SET_PROPERTY,
            value: {
              prop: propChain.pop(),
              value: payload,
              propChain,
              noUpdate: true,
            },
          });
        }
        return from([
          {
            type: DATASTATE_EXTERNAL_SET_PROPERTY,
            value: {
              prop: propChain.pop(),
              value: payload,
              propChain,
            },
          },
          {
            type: FIELD_LOCK_SET,
            value: {
              fieldName,
              timestamp,
              user,
              isNew: !fieldLock[fieldName],
            },
          },
        ]);
      }),
      apiCatchError(AWS_DATASTATE_GET_REJECTED),
    )),
);

export const handleDataOnFieldLockUnset = (action$, state$) => action$.pipe(
  ofType(WS_FIELD_LOCK_UNSET),
  withLatestFrom(state$),
  filter(([{ value: { fieldName } }, { localState }]) => {
    const propChain = fieldName.split('_');
    return !!getFromState(propChain, localState);
  }),
  mergeMap(([
    { value: { fieldName } },
  ]) => of({
    type: DATASTATE_LOCAL_UNSET_PROPERTY,
    value: fieldName.split('_'),
  })),
);

export const handleDataOnFieldLockRefresh = (action$, state$) => action$.pipe(
  ofType(WS_FIELD_LOCK_REFRESH),
  withLatestFrom(state$),
  mergeMap(([
    { value: { contentType, contentId } },
    { login: { user: { uid } } },
  ]) => ajax
    .getJSON(`/api/aws/data-state/${contentType}/${contentId}`)
    .pipe(
      mergeMap(({ success, data: { Items } }) => {
        if (!success) {
          // @todo error gracefully
          return showErrorNotification('Error: could not get initial data')();
        }
        const { localState, externalState } = buildStatesFromDynamoDB(Items, uid);

        return from([
          setLocalData(localState),
          setExternalData(externalState),
          {
            type: FIELD_LOCK_REFRESH,
            value: Items
              .filter(({ userData }) => userData.uid !== uid)
              .reduce((acc, {
                fieldName,
                timestamp,
                userData: user,
              }) => ({ ...acc, [fieldName]: { timestamp, user } }), {}),
          },
        ]);
      }),
      apiCatchError(AWS_DATASTATE_QUERY_REJECTED),
    ),
  ),
);

export const handleDataOnFieldUnlock = action$ => action$.pipe(
  ofType(FIELD_LOCK_UNSET),
  mergeMap(({ value }) => of({
    type: DATASTATE_EXTERNAL_UNSET_PROPERTY,
    value: value.split('_'),
  })),
);

export const deleteLockOnLocalDispose = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  mergeMap(({ value: { contentType, contentId } }) => action$.pipe(
    ofType(DATASTATE_LOCAL_DISPOSE),
    withLatestFrom(state$),
    mergeMap(([, { login: { user: { uid } }, frame: { socketId: id } }]) => ajax
      .delete(`/api/aws/data-state/${contentType}/${contentId}?uid=${uid}`)
      .pipe(
        mergeMap(({ response: { success, message } }) => {
          if (!success) {
            // @todo handle error
            return showErrorNotification(message || 'Error unlocking field')();
          }
          return of(sendMessage({
            type: WS_FIELD_LOCK_REFRESH,
            value: {
              contentType,
              contentId,
              id,
            },
          }));
        }),
        apiCatchError(AWS_DATASTATE_DELETE_REJECTED),
      ),
    ),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutLock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => CONTENT_NOTIFICATION_TYPES.includes(contentType)),
  mergeMap(() => action$.pipe(
    ofType(FIELD_LOCK_SET),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `A field has been locked by ${name}`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutUnlock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => CONTENT_NOTIFICATION_TYPES.includes(contentType)),
  mergeMap(() => action$.pipe(
    ofType(WS_FIELD_LOCK_UNSET),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `A field has been unlocked by ${name}, your local changes have been removed.`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

// @todo depricate below functions once edition locking has been refactored

export const handleLockFieldLock = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(SET_FIELD_LOCK),
    withLatestFrom(state$),
    filter(
      ([{ value }, { fieldLock: { contentType, contentId } }]) => (
        value.contentType === contentType &&
        value.contentId === contentId
      ),
    ),
    mergeMap(([{ value }]) => {
      const actions = [
        { type: ADD_FIELD_LOCK, value: { field: value.field, value: value.value } },
        { type: SESSION_LOCK_FIELD_REQUEST, value },
      ];
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const handleUnlockFieldLock = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(REMOVE_FIELD_LOCK),
    withLatestFrom(state$),
    filter(
      ([{ value }, { fieldLock: { contentType, contentId, lockedFields } }]) => (
        value.contentType === contentType &&
        value.contentId === contentId &&
        Object.keys(lockedFields).indexOf(value.field) !== -1
      ),
    ),
    mergeMap(([{ value }]) => {
      const actions = [
        { type: SESSION_UNLOCK_FIELD_REQUEST, value },
      ];
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const broadcastLockField = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(SESSION_LOCK_FIELD_REQUEST),
    withLatestFrom(state$),
    map(([{ value: { contentType, contentId, field, value } }, {
      login: { user: { name, uid, mail } },
      frame: { socketId: id },
    }]) => sendMessage({
      type: SESSION_LOCK_FIELD,
      value: {
        contentType,
        contentId,
        field,
        value,
        timestamp: moment().unix(),
        user: { name, uid, mail },
        id,
      },
    })),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const broadcastUnLockField = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(SESSION_UNLOCK_FIELD_REQUEST),
    withLatestFrom(state$),
    map(([{ value: { contentType, contentId, field } }, {
      login: { user: { name, uid, mail } },
      frame: { socketId: id },
    }]) => sendMessage({
      type: SESSION_UNLOCK_FIELD,
      value: {
        contentType,
        contentId,
        field,
        timestamp: moment().unix(),
        user: { name, uid, mail },
        id,
      },
    })),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const broadcastLockedFields = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(FIELD_LOCK_SUBSCRIBE),
    withLatestFrom(state$, (a, b) => b),
    filter(({ fieldLock: { lockedFields } }) => !!lockedFields),
    mergeMap(({
      fieldLock: { contentType, contentId, lockedFields },
      login: { user: { name, uid, mail } },
      frame: { socketId: id },
    }) => {
      const actions = Object.entries(lockedFields).map(([field, value]) => (
        sendMessage({
          type: SESSION_LOCK_FIELD,
          value: {
            contentType,
            contentId,
            field,
            value,
            timestamp: moment().unix(),
            user: { name, uid, mail },
            id,
          },
        })
      ));
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const handleSessionResetLock = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(SESSION_LOCK_FIELD),
    withLatestFrom(state$),
    filter(
      ([{ value }, { fieldLock: { contentType, contentId, lockedFields } }]) => (
        value.contentType === contentType &&
        value.contentId === contentId &&
        Object.keys(lockedFields).indexOf(value.field) !== -1
      ),
    ),
    map(([{ value }]) => ({
      type: REMOVE_FIELD_LOCK,
      value,
    })),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

// only unsubscribe when navigating away from the article. This will maintain the content locking
// feature after user save the article
export const unsubscribeToContentLocking = (action$, state$) => action$.pipe(
  ofType(ARTICLE_EDIT_DISPOSE),
  withLatestFrom(state$),
  map(([, {
    login: { user: { name, uid, mail } },
    frame: { socketId: id },
  }]) => sendMessage({
    type: FIELD_LOCK_UNSUBSCRIBE,
    value: {
      user: { name, uid, mail },
      id,
    },
  })),
);

export const unSubscribeToFieldLock = (action$, state$) => action$.pipe(
  ofType(DISPOSE_FIELD_LOCKING),
  withLatestFrom(state$),
  mergeMap(([, {
    fieldLock: { contentType, contentId, lockedFields },
    login: { user: { name, uid, mail } },
    frame: { socketId: id },
  }]) => {
    const actions = Object.keys(lockedFields).map(field => (
      sendMessage({
        type: SESSION_UNLOCK_FIELD,
        value: {
          contentType,
          contentId,
          field,
          user: { name, uid, mail },
          id,
        },
      })
    ));
    actions.push(
      {
        type: FIELD_LOCKING_DISPOSED,
      },
    );
    return from(actions);
  }),
);

export const disposeOnBrowserUnload = action$ => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => fromEvent(window, 'beforeunload').pipe(
    map(() => ({ type: DISPOSE_FIELD_LOCKING })),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);
