import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../dialog/common/Dialog';
import Button from '../../ui/buttons/Button';
import PlayIncVideoSelectorList from './PlayIncVideoSelectorList';

const PlayIncVideoEdit = (props) => {
  const {
    handleClose,
    onSelect,
    limit,
    ...rest
  } = props;
  const [selected, setSelected] = useState([]);
  return (
    <Dialog
      title={'Select Video article'}
      maxWidth={'md'}
      handleClose={handleClose}
      margin
      actions={<>
        <Button
          variant={'contained'}
          onClick={() => onSelect(Object.entries(selected)
            .map(([id, title]) => ({ id, title })))}
        >Insert video articles</Button>
      </>}
      {...rest}
    >
      <PlayIncVideoSelectorList
        onSelect={setSelected}
        limit={limit}
        showVarticles
      />
    </Dialog>
  );
};

PlayIncVideoEdit.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  limit: PropTypes.number,
};

PlayIncVideoEdit.defaultProps = {
  limit: -1,
};

export default PlayIncVideoEdit;
