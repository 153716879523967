import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';
import PageSection from '../components/common/page/PageSection';

import { fetchExportSettingTimeList } from '../actions/edition';
import EditionListControlBar from '../components/edition/EditionListControlBar';

import { Tab, Tabs } from '../components/ui/Tabs';

import {
  EDITION_EXPORT_SETTING,
  EDITION_TYPES,
} from '../constants/edition/edition';
import TimeSetting from '../components/edition/exportSetting/TimeSetting';
import FTPSetting from '../components/edition/exportSetting/FTPSetting';
import Logs from '../components/edition/exportSetting/Logs';

export const EditionListBreadcrumb = [
  {
    title: 'Editions',
    slug: 'edition',
  },
];

const EditionExportSetting = ({
  fetchExportSettingTimeList: onFetch,
}) => {
  const breadcrumb = [
    ...EditionListBreadcrumb,
    {
      title: EDITION_TYPES[EDITION_EXPORT_SETTING],
      slug: EDITION_EXPORT_SETTING,
    },
  ];
  useEffect(() => {
    onFetch();
  }, []);
  const [tab, setTab] = useState(0);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <EditionListControlBar type={EDITION_EXPORT_SETTING} />
      </PageToolbar>}
    >
      <PageContent>
        <PageSection>
          <Tabs value={tab} onChange={(e, current) => setTab(current)}>
            <Tab value={0} label={'FTP details'} />
            <Tab value={1} label={'Editions'} />
            <Tab value={2} label={'Logs'} />
          </Tabs>
          {tab === 0 && <FTPSetting />}
          {tab === 1 && <TimeSetting />}
          {tab === 2 && <Logs />}
        </PageSection>
      </PageContent>
    </Page>
  );
};

EditionExportSetting.propTypes = {
  fetchExportSettingTimeList: PropTypes.func.isRequired,
};

export default connect(() => ({}), { fetchExportSettingTimeList })(EditionExportSetting);
