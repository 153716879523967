import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  noticeBoard: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 2, -2),
    textAlign: 'center',
    width: '100%',
  },
});

export const Notice = ({ text, color }) => (<Typography variant={'h3'} color={color}>{text}</Typography>);

Notice.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Notice.defaultProps = {
  color: 'inherit',
};

const NoticeBoard = (props) => {
  const { classes, legal, notices } = props;
  return (
    <div className={classes.noticeBoard}>
      {legal && <Notice text={legal} color={'error'} />}
      {notices && notices.map(({ notice }, index) => (<Notice key={`notice-${index}`} text={notice} />))}
    </div>
  );
};

NoticeBoard.propTypes = {
  classes: PropTypes.object.isRequired,
  legal: PropTypes.string,
  notices: PropTypes.array,
};

NoticeBoard.defaultProps = {
  legal: '',
  notices: [],
};

export default withStyles(styles)(NoticeBoard);
