import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SelectAutocomplete from 'components/ui/SelectAutocomplete';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from 'selectors/fieldLock/fieldLock';
import { setLocalProp } from 'actions/dataState';

import FieldLockField from '../FieldLockField';

const FieldLockSelect = ({
  value,
  label,
  lockValue,
  field,
  hasChanges,
  disabled,
  options,
  setLocalProp: setProp,
  ...rest
}) => {
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  return (
    <FieldLockField field={field} lockValue={lockValue}>
      <SelectAutocomplete
        label={label}
        hasChanges={hasChanges}
        value={
          value
            ? {
              label: value,
              value,
            }
            : ''
        }
        defaultOptions={options.map(option => ({
          label: option,
          value: option,
        }))}
        onChange={option => setProp(prop, option.value, propChain)}
        disabled={disabled}
        {...rest}
      />
    </FieldLockField>
  );
}

FieldLockSelect.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLockSelect.defaultProps = {
  value: '',
  lockValue: null,
  hasChanges: false,
  disabled: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockSelect);
