import { ofType } from 'redux-observable';
import {
  mergeMap,
  withLatestFrom,
  filter,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { arrayMove } from 'react-sortable-hoc';
import {
  DATASTATE_LOCAL_PROP_ADD_ITEM,
  DATASTATE_LOCAL_PROP_REMOVE_ITEM,
  DATASTATE_LOCAL_PROP_SORT_ITEMS,
  DATASTATE_LOCAL_SET_PROPERTY,
  DATASTATE_LOCAL_PROP_SET_PROPERTY,
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_LOCAL_ADD_KEYED_ITEM,
  DATASTATE_LOCAL_REPLACE_KEYED_ITEM,
  DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
  DATASTATE_LOCAL_SORT_KEYED_ITEMS,
  DATASTATE_EXTERNAL_SET_PROPERTY,
  DATASTATE_SERVER_SET_PROPERTY,
  DATASTATE_LOCAL_UNSET_PROPERTY,
  DATASTATE_EXTERNAL_UNSET_PROPERTY,
  DATASTATE_EXTERNAL_SET_DATA,
  DATASTATE_SERVER_SET_DATA,
  DATASTATE_LOCAL_SET_DATA,
  DATASTATE_SERVER_DISPOSE_DATA, DATASTATE_EXTERNAL_DISPOSE_DATA,
} from '../../constants/actionTypes/dataState';
import {
  setDataStateProp,
  setLocalProp,
  unsetDataStateProp,
  unsetLocalProp,
  setDataState,
} from '../../actions/dataState';
import { showErrorNotification } from '../helper/notification';
import { getFromState, getFromStates, getState } from '../../utils/stateHelper';

export const setDataStateOnSet = (action$, state$) => action$.pipe(
  ofType(
    DATASTATE_SERVER_SET_DATA,
    DATASTATE_EXTERNAL_SET_DATA,
    DATASTATE_LOCAL_SET_DATA,
    DATASTATE_LOCAL_DISPOSE,
    DATASTATE_SERVER_DISPOSE_DATA,
    DATASTATE_EXTERNAL_DISPOSE_DATA,
  ),
  filter(({ quiet }) => !quiet),
  withLatestFrom(state$),
  mergeMap(([, { localState, serverState, externalState }]) => {
    const dataState = getState(localState, serverState, externalState);
    return of(setDataState(dataState));
  }),
);

export const setDataStateOnSetLocalProp = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_SET_PROPERTY),
  withLatestFrom(state$),
  filter(([{ value: { prop, propChain = [] } }, { externalState }]) =>
    !getFromState([...propChain, prop], externalState)),
  mergeMap(([{ value: { prop, value, propChain } }]) =>
    of(setDataStateProp(prop, value, propChain))),
);

export const setDataStateOnSetExternalProp = action$ => action$.pipe(
  ofType(DATASTATE_EXTERNAL_SET_PROPERTY),
  mergeMap(({ value: { prop, value, propChain } }) =>
    of(setDataStateProp(prop, value, propChain))),
);

export const setDataStateOnSetServerProp = (action$, state$) => action$.pipe(
  ofType(DATASTATE_SERVER_SET_PROPERTY),
  withLatestFrom(state$),
  filter(([{ value: { prop, propChain = [] } }, { externalState, localState }]) =>
    !getFromState([...propChain, prop], externalState) &&
    !getFromState([...propChain, prop], localState)),
  mergeMap(([{ value: { prop, value, propChain } }]) =>
    of(setDataStateProp(prop, value, propChain))),
);

export const setDataStateOnUnsetLocalProp = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_UNSET_PROPERTY),
  withLatestFrom(state$),
  filter(([{ value }, { externalState }]) =>
    !getFromState(value, externalState)),
  mergeMap(([{ value }, { serverState }]) => {
    const serverValue = getFromState(value, serverState);
    if (serverValue) {
      const prop = Array.isArray(value) ? value.shift() : value;
      const propChain = Array.isArray(value) ? value : [];
      return of(setDataStateProp(prop, serverValue, propChain));
    }
    return of(unsetDataStateProp(value));
  }),
);

export const setDataStateOnUnsetExternalProp = (action$, state$) => action$.pipe(
  ofType(DATASTATE_EXTERNAL_UNSET_PROPERTY),
  withLatestFrom(state$),
  mergeMap(([{ value }, { serverState, localState }]) => {
    const serverValue = getFromStates(value, localState, serverState);
    if (serverValue) {
      const prop = Array.isArray(value) ? value.shift() : value;
      const propChain = Array.isArray(value) ? value : [];
      return of(setDataStateProp(prop, serverValue, propChain));
    }
    return of(unsetDataStateProp(value));
  }),
);


// @todo below functions can be deprecated once Article Websocket refactor done
export const processAddKeyedToLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_ADD_KEYED_ITEM, DATASTATE_LOCAL_REPLACE_KEYED_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { type, value: { prop, key, item, orderProp, orderIndex, propChain = [] } },
    { localState, serverState, externalState },
  ]) => {
    if (
      getFromState([...propChain, prop, key], externalState) ||
      (orderProp && getFromState([...propChain, orderProp], externalState))
    ) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const actions = [setLocalProp(key, item, [...propChain, prop])];
    if (orderProp) {
      const currentOrder = getFromState([...propChain, orderProp], localState)
        || getFromState([...propChain, orderProp], serverState) || [];
      const oldKey = currentOrder[orderIndex];
      actions.push(setLocalProp(
        orderProp,
        [
          ...currentOrder.slice(0, orderIndex),
          key,
          ...currentOrder.slice(orderIndex),
        ].filter(orderKey => !(type === DATASTATE_LOCAL_REPLACE_KEYED_ITEM && orderKey === oldKey)),
        propChain,
      ));
      if (type === DATASTATE_LOCAL_REPLACE_KEYED_ITEM) {
        actions.push(unsetLocalProp([...propChain, prop, oldKey]));
      }
    }
    return from(actions);
  }),
);

export const processRemoveKeyedFromLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_REMOVE_KEYED_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, key, index, orderProp, propChain = [] } },
    { localState, serverState, externalState },
  ]) => {
    if (typeof index !== 'undefined' && orderProp && !key) {
      const currentOrder = getFromStates([...propChain, orderProp], localState, serverState) || [];
      // eslint-disable-next-line no-param-reassign
      key = currentOrder[index];
    }
    if (
      getFromState([...propChain, prop, key], externalState) ||
      (orderProp && getFromState([...propChain, orderProp], externalState))
    ) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const actions = [unsetLocalProp([...propChain, prop, key])];
    if (orderProp) {
      const currentOrder = getFromStates([...propChain, orderProp], localState, serverState) || [];
      actions.push(setLocalProp(
        orderProp,
        currentOrder.filter(id => id !== key),
        propChain,
      ));
    }
    return from(actions);
  }),
);

export const processSortKeyedOnLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_SORT_KEYED_ITEMS),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, oldIndex, newIndex, propChain = [] } },
    { localState, serverState, externalState },
  ]) => {
    if (getFromState([...propChain, prop], externalState)) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const currentOrder = getFromState([...propChain, prop], localState)
      || getFromState([...propChain, prop], serverState) || [];
    return of(setLocalProp(
      prop,
      arrayMove(currentOrder, oldIndex, newIndex),
      propChain,
    ));
  }),
);

export const processItemAddToLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_PROP_ADD_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, item } },
    { localState, serverState, externalState },
  ]) => {
    if (externalState[prop]) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = localState[prop] || serverState[prop] || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        prop,
        value: [
          ...current,
          item,
        ],
      },
    });
  }),
);

export const processItemRemoveFromLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_PROP_REMOVE_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, index } },
    { localState, serverState, externalState },
  ]) => {
    if (externalState[prop]) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = localState[prop] || serverState[prop] || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        prop,
        value: current.filter((item, i) => i !== index),
      },
    });
  }),
);

export const processItemSortOnLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_PROP_SORT_ITEMS),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, oldIndex, newIndex } },
    { localState, serverState, externalState },
  ]) => {
    if (externalState[prop]) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = localState[prop] || serverState[prop] || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        prop,
        value: arrayMove(current, oldIndex, newIndex),
      },
    });
  }),
);

export const processItemSetPropOnLocal = (action$, state$) => action$.pipe(
  ofType(DATASTATE_LOCAL_PROP_SET_PROPERTY),
  withLatestFrom(state$),
  mergeMap(([
    { value: { prop, index, itemProp, value, setOnObject } },
    { localState, serverState, externalState },
  ]) => {
    if (externalState[prop]) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = localState[prop] || serverState[prop] || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        prop,
        value: [
          ...current.slice(0, index),
          setOnObject
            ? {
              ...current[index],
              [itemProp]: value,
            }
            : {
              ...current[index],
              data: {
                ...current[index].data,
                [itemProp]: value,
              },
            },
          ...current.slice(index + 1),
        ],
      },
    });
  }),
);
