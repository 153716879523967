/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'rxjs';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {
  VIDEO_DM,
  VIDEO_DUGOUT,
  VIDEO_JWPLAYER,
  VIDEO_YOUTUBE,
  VIDEO_YOUTUBE_LEGACY,
} from '../../../constants/media/media';
import {
  ARTICLE_LINK, BLOCKQUOTE, EMBED_BOOKING, FACEBOOK, GALLERY,
  H2, H3, H4, H5, H6, HIDE_SHOW, HR, HTML, IFRAME,
  INFOBOX, INSTAGRAM, LISTBULLETED, LISTNUMBERED,
  LIVEBLOG, MARKUP, NUMBERBOX, RELATED_ARTICLE, STORY, TABLE, TWITTER,
} from '../../../constants/builder/builder';

import Markup from '../components/MarkUp';
import InfoBoxMarkup from '../components/InfoBox';
import Blockquote from '../components/Blockquote';
import NumberBoxMarkup from '../components/NumberBox';
import RelatedArticle from '../components/RelatedArticle';
import ListItem from '../components/ListItem';
import GalleryComponent from '../components/GalleryEmbed';
import ExternalEmbed from '../components/ExternalEmbed';
import Liveblog from '../components/Liveblog';
import VideoJWPlayer from '../components/VideoJWPlayer';
import VideoDugout from '../components/VideoDugout';
import Separator from '../components/Separator';
import BookingEmbed from '../components/BookingEmbed';
import HTMLEmbed from '../components/HTMLEmbed';
import ArticleLink from '../components/ArticleLink';
import HideAndShow from '../components/HideAndShow';
import StoryEmbed from '../components/StoryEmbed';

import { parentWithScrollbar, docHeight, elementOutOfViewport, getOffsetTop } from '../../../utils/domHelper';

const styles = theme => ({
  component: {},
  contents: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 0, 1.5),
    minHeight: 36,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  actions: {
    display: 'flex',
    visibility: 'hidden',
    alignItems: 'center',
    marginRight: 20 + theme.spacing(1), // handle width
    '.row-component:hover &': {
      display: 'flex',
      visibility: 'visible',
      marginRight: 0,
    },
  },
  handleWrapper: {
    display: 'none',
    alignItems: 'flex-start',
    '.row-component:hover &': {
      display: 'flex',
    },
  },
  dragHandle: {
    height: '100%',
    margin: theme.spacing(-2, -2, -2, 3),
    padding: theme.spacing(2, 0),
  },
});

// revision comparision is the only place this is now used
// @todo depricate in favour of EditorPreview
const BodyComponent = (props) => {
  const {
    classes,
    data,
    switchBodyComponent,
    socialComponentOnBlur,
    onPasteFromClipboard,
    availableComponentSelector,
    sortComponentPosition,
    editorId,
    liveblog,
    sortableHandle,
    onlyMainComponent,
    currentComponentImageRequest,
    ...others
  } = props;
  let component;

  const { bodyMarkupFocus, setFocusComponentIndex } = others;
  const comp = useRef(null);
  useEffect(() => {
    if (bodyMarkupFocus === others.position) {
      setTimeout(() => {
        const el = comp.current;
        const scrollableParent = parentWithScrollbar(el);
        if (elementOutOfViewport(el) && scrollableParent) {
          const distance = getOffsetTop(el) - scrollableParent.scrollTop;
          const intendedYViewportPosition = docHeight() - el.offsetHeight - 50;
          const deltaY = distance - intendedYViewportPosition;
          scrollableParent.scrollTo({
            behavior: 'smooth',
            top: scrollableParent.scrollTop + deltaY,
          });
        }
      });
    }
  }, [bodyMarkupFocus]);

  // @todo get icon and title from componentSelector, like toolbar
  switch (data.type) {
    case HR:
      component = (<Separator />);
      break;
    case GALLERY:
      component = (<GalleryComponent
        {...data}
        {...others}
      />);
      break;
    case VIDEO_JWPLAYER:
      component = (<VideoJWPlayer
        onEnter={() => {
          others.addMoreMarkup(others.position);
        }}
        editorId={editorId}
        {...data}
        {...others}
      />);
      break;
    case VIDEO_DUGOUT:
      component = (<VideoDugout
        onEnter={() => {
          others.addMoreMarkup(others.position);
        }}
        editorId={editorId}
        {...data}
        {...others}
      />);
      break;
    case LIVEBLOG:
      component = (<Liveblog
        onEnter={() => {
          others.addMoreMarkup(others.position);
        }}
        {...data}
        {...others}
      />);
      break;
    case HTML:
      component = (<HTMLEmbed
        {...others}
        {...data}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
        onChange={(value) => {
          others.saveMarkupData([{ markup: value }, others.position]);
        }}
      />);
      break;
    case INSTAGRAM:
    case FACEBOOK:
    case TWITTER:
    case IFRAME:
    case VIDEO_YOUTUBE:
    case VIDEO_DM:
    case VIDEO_YOUTUBE_LEGACY:
      component = (<ExternalEmbed
        {...others}
        {...data}
        editorId={editorId}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
        onChange={(value) => {
          others.saveMarkupData([{ url: value }, others.position]);
          if (data.type === INSTAGRAM && value) {
            socialComponentOnBlur([data.id, value]);
          }
        }}
        onBlur={() => {}}
      />);
      break;
    case ARTICLE_LINK:
      component = (<ArticleLink
        {...others}
        {...data}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
        onChange={(value) => {
          others.saveMarkupData([{ url: value }, others.position]);
        }}
      />);
      break;
    case EMBED_BOOKING:
      component = (<BookingEmbed
        {...others}
        {...data}
        onChangeHotel={(value) => {
          others.saveMarkupData([{ hotel: value }, others.position]);
          others.saveMarkupData([{ amenities: [] }, others.position]);
        }}
        onChangeAmenities={(value) => {
          others.saveMarkupData([{ amenities: value }, others.position]);
        }}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
      />);
      break;
    case LISTNUMBERED:
      component = (<ListItem
        {...others}
        {...data}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
        initialContent={'<ol><li></li></ol>'}
      />);
      break;
    case LISTBULLETED:
      component = (<ListItem
        {...others}
        {...data}
        onFocus={() => {
          setFocusComponentIndex(others.position);
        }}
      />);
      break;
    case BLOCKQUOTE:
      component = (<Blockquote
        {...others}
        {...data}
        liveblog={liveblog}
        onFocus={() => setFocusComponentIndex(others.position)}
      />);
      break;
    case RELATED_ARTICLE:
      component = (<RelatedArticle
        {...others}
        {...data}
      />);
      break;
    case NUMBERBOX:
      component = (<NumberBoxMarkup
        {...others}
        {...data}
        onFocus={() => setFocusComponentIndex(others.position)}
      />);
      break;
    case HIDE_SHOW:
      component = (<HideAndShow
        {...others}
        {...data}
        onFocus={() => setFocusComponentIndex(others.position)}
      />);
      break;
    case INFOBOX:
      component = (<InfoBoxMarkup
        {...others}
        {...data}
        onFocus={() => setFocusComponentIndex(others.position)}
      />);
      break;
    case STORY:
      component = (<StoryEmbed
        {...others}
        {...data}
        onFocus={() => setFocusComponentIndex(others.position)}
      />);
      break;
    case H2:
    case H3:
    case H4:
    case H5:
    case H6:
    case TABLE:
    case MARKUP:
      component = (<Markup
        {...others}
        {...data}
        onFocus={() => {
          // noop atm
        }}
        onPasteFromClipboard={html => onPasteFromClipboard([others.position, html])}
      />);
      break;
    default:
      component = (<div>Unknown type component {data.type} / <Button
        onClick={() => others.removeArticleComponent(others.position)}
      >Remove</Button></div>);
  }
  return component;
};

BodyComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onlyMainComponent: PropTypes.bool,
  bodyMarkupFocus: PropTypes.number,
  context: PropTypes.string.isRequired,
  selectedRange: PropTypes.array.isRequired,
  editorId: PropTypes.string,
  data: PropTypes.object.isRequired,
  setFocusComponentIndex: PropTypes.func.isRequired,
  switchBodyComponent: PropTypes.func.isRequired,
  socialComponentOnBlur: PropTypes.func,
  onPasteFromClipboard: PropTypes.func,
  sortComponentPosition: PropTypes.func.isRequired,
  componentTypes: PropTypes.array,
  total: PropTypes.number.isRequired,
  liveblog: PropTypes.bool,
  selectMediaImage: PropTypes.func,
  onChangeBodyComponentProperty: PropTypes.func,
};

BodyComponent.defaultProps = {
  bodyMarkupFocus: null,
  socialComponentOnBlur: noop,
  availableComponentSelector: [],
  selectedRange: [],
  editorId: null,
  liveblog: false,
  onlyMainComponent: false,
  componentTypes: [],
  /* eslint-disable no-console */
  onPasteFromClipboard: () => console.log('Please implement onPasteFromClipboard'),
  /* eslint-enable no-console */
};

export default withStyles(styles)(BodyComponent);

