import React from 'react';

const ProductGfx = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 64" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M55.07 64H25.883c-2.015 0-3.649-1.592-3.649-3.556V32.25l-4.743 3.555a3.701 3.701 0 01-5.145 0L2.02 25.92a3.477 3.477 0 010-5.013L23.474 0h6.056c0 3.911 4.89 7.111 10.946 7.111 6.057 0 10.946-3.2 10.946-7.111h6.057l21.454 20.907a3.477 3.477 0 010 5.013L68.607 35.982c-1.423 1.351-3.721 1.351-5.144 0l-4.744-3.555v28.017c0 1.964-1.633 3.556-3.648 3.556" fill="#2196F3" />
      <path fill="#E7792B" d="M43 15h12v4H43z" />
    </g>
  </svg>
);

export default ProductGfx;
