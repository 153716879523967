import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import Button from '../components/ui/buttons/Button';
import AdsTxtInput from '../components/ad/AdsTxtInput';
import {
  setAdsTxt, adsTxtPageLoaded, saveAdsTxt, adsTxtPageDispose,
} from '../actions/staticPage';

export const AdTxtBreadcrumb = [
  {
    title: 'Ad Config',
    slug: 'ad',
  },
  {
    title: 'Ad Txt',
    slug: 'ads-txt',
  },
];

const AdsTxt = ({
  saveAdsTxt: onSave, adsTxtPageLoaded: onPageLoaded, adsTxtPageDispose: onDisposePage,
}) => {
  useEffect(() => {
    onPageLoaded();
    return () => {
      onDisposePage();
    };
  }, []);
  return (<Page
    toolbarContent={
      <PageToolbar breadcrumb={AdTxtBreadcrumb}>
        <PageToolbarButtons>
          <Button
            onClick={() => onSave()}
            variant={'contained'}
            startIcon={<SaveIcon />}
          >Save</Button>
        </PageToolbarButtons>
      </PageToolbar>
    }
  >
    <PageContent>
      <PageSection includePadding><AdsTxtInput /></PageSection>
    </PageContent>
  </Page>
  );
};

AdsTxt.propTypes = {
  saveAdsTxt: PropTypes.func.isRequired,
  adsTxtPageLoaded: PropTypes.func.isRequired,
  adsTxtPageDispose: PropTypes.func.isRequired,
};

AdsTxt.defaultTypes = {};

AdsTxt.defaultProps = {};

export default connect(
  () => ({}), {
    setAdsTxt, adsTxtPageLoaded, saveAdsTxt, adsTxtPageDispose,
  },
)(AdsTxt);
