import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  root: {
    padding: theme.spacing(4, 2),
    borderRadius: theme.border.radius,
    textAlign: 'center',
    border: `1.5px dashed ${theme.palette.border.primary.main}`,
    backgroundColor: theme.palette.common.white,
  },
});

const LiveblogWrapper = (props) => {
  const { classes, className, children, ...rest } = props;
  const wrapperClass = `${classes.root} ${className}`;
  return (
    <div className={wrapperClass} {...rest}>{children}</div>);
};

LiveblogWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LiveblogWrapper.defaultProps = {
  className: '',
};

export default withStyles(styles)(LiveblogWrapper);
