import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useResizeDetector } from 'react-resize-detector';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Breadcrumb from './Breadcrumb';

const styles = theme => ({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(4),
    position: 'relative',
  },
  breadcrumbRoot: {
    width: 'auto',
  },
  buttonsWrapper: {
    width: '100%',
  },
  buttonsStatic: {
    '& > *': {
      margin: theme.spacing(1.5, 0, 1.5, 1),
    },
    [theme.breakpoints.down(1300)]: {
      '& .MuiButton-text': {
        minWidth: 20,
        padding: theme.spacing(1, 1, 1, 2),
        '& .MuiButton-label': {
          fontSize: 0,
          width: 20,
          '& .MuiButton-startIcon': {
            margin: 0,
          },
        },
      },
    },
  },
  buttons: {
    width: 'auto',
    float: 'right',
    height: 64,
    overflow: 'hidden',
    '& > *': {
      margin: theme.spacing(1.5, 0, 1.5, 1),
    },
  },
  buttonsMinified: {
    '& .MuiButton-text': {
      minWidth: 20,
      padding: theme.spacing(1, 1, 1, 2),
      '& .MuiButton-label': {
        fontSize: 0,
        width: 20,
        '& .MuiButton-startIcon': {
          margin: 0,
        },
      },
    },
  },
});

export const PageToolbarButtons = withStyles(styles)(({ classes, children }) => {
  const [minified, setMinified] = useState(false);
  const [contentWidth, setContentWidth] = useState(-1);
  const contentRef = useRef();
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 500,
  });
  useEffect(() => {
    if (width) {
      if (Math.ceil(width) < contentWidth) {
        setMinified(true);
      } else {
        setMinified(false);
      }
    }
  }, [width, contentWidth]);
  useLayoutEffect(() => {
    setMinified(false);
    setContentWidth(-1);
  }, [children]);
  useLayoutEffect(() => {
    if (contentRef.current && contentWidth < 0) {
      setContentWidth(contentRef.current.offsetWidth);
    }
  }, [contentWidth]);
  return (
    <div ref={ref} className={classes.buttonsWrapper}>
      <Grid
        ref={contentRef}
        className={minified ? `${classes.buttonsMinified} ${classes.buttons}` : classes.buttons}
        container
        wrap={contentWidth > -1 && !minified ? 'wrap' : 'nowrap'} // hack to allow width calc
        justify={'flex-end'}
        alignItems={'center'}
      >
        {children}
      </Grid>
    </div>
  );
});

export const PageToolbarButtonsStatic = withStyles(styles)(({ classes, children }) => (
  <Grid
    className={classes.buttonsStatic}
    container
    wrap={'nowrap'}
    justify={'flex-end'}
    alignItems={'center'}
  >
    {children}
  </Grid>
));

const PageToolbar = ({ classes, breadcrumb, children }) => (
  <Grid className={classes.root}>
    {breadcrumb && <Breadcrumb
      classes={{ root: classes.breadcrumbRoot }}
      breadcrumb={breadcrumb}
    />}
    {children}
  </Grid>
);

PageToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumb: PropTypes.array.isRequired,
  children: PropTypes.node,
};

PageToolbar.defaultProps = {
  children: null,
};

export default withStyles(styles)(PageToolbar);
