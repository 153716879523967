import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';

import { copyStyles } from '../../../utils/previewHelper';

// @todo similar to LayoutPreviewWindow, both can extend a new abstract for previewWindow
class EditionPreviewWindow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
    this.onClose = props.onClose;
  }

  componentDidMount() {
    this.externalWindow = window.open('', '_blank');
    this.externalWindow.addEventListener('beforeunload', this.handleClose.bind(this));
    this.externalWindow.document.body.appendChild(this.containerEl);

    this.externalWindow.addEventListener('message', (e) => {
      if (typeof e.data !== 'undefined' && typeof e.data.event !== 'undefined') {
        switch (e.data.event) {
          case 'hydrated':
            this.updatePreview();
            break;
          default:
            break;
        }
      }
    });

    copyStyles(document, this.externalWindow.document);
  }

  componentDidUpdate(prevProps) {
    this.updatePreview(prevProps);
  }

  componentWillUnmount() {
    if (this.externalWindow !== null) {
      this.externalWindow.removeEventListener('beforeunload', this.handleClose.bind(this));
      this.externalWindow.close();
    }
  }

  updatePreview(prevProps = {}) {
    const { edition } = this.props;
    const {
      headline: title, subHeadline: subheadline, body, heroImage, slug, authors,
      authorInfo, relatedArticle, pugpig_section: sections, starRating, pugpig_templates: templates,
      awayScore, awayTeam, awayScorer, homeScore, homeTeam, homeScorer,
    } = edition;
    const {
      headline: prevTitle,
      subHeadline: prevSubheadline,
      body: prevBody,
      heroImage: prevHeroImage,
      slug: prevSlug,
      authors: prevAuthors,
      authorInfo: prevAuthorInfo,
      relatedArticle: prevRelatedArticle,
      pugpig_section: prevSections,
      pugpig_templates: prevTemplates,
      starRating: prevStarRating,
      awayScore: prevAwayScore,
      awayTeam: prevAwayTeam,
      awayScorer: prevAwayScorer,
      homeScore: prevHomeScore,
      homeTeam: prevHomeTeam,
      homeScorer: prevHomeScorer,
    } = prevProps.edition ? prevProps.edition : {};
    const iframe = this.externalWindow.document.querySelector('#edition-preview');
    if (iframe?.contentWindow) {
      if (
        prevTitle !== title ||
        prevSubheadline !== subheadline ||
        prevBody !== body ||
        prevHeroImage !== heroImage ||
        prevAuthors !== authors ||
        prevSlug !== slug ||
        prevAuthorInfo !== authorInfo ||
        prevRelatedArticle !== relatedArticle ||
        prevSections !== sections ||
        prevStarRating !== starRating ||
        prevAwayScore !== awayScore ||
        prevAwayTeam !== awayTeam ||
        prevAwayScorer !== awayScorer ||
        prevHomeScore !== homeScore ||
        prevHomeTeam !== homeTeam ||
        prevHomeScorer !== homeScorer ||
        prevTemplates !== templates
      ) {
        const templateType = typeof templates[0] !== 'undefined' ? templates[0].id : '';
        iframe.contentWindow.postMessage({
          event: 'updateData',
          data: {
            article: {
              data: {
                templateType,
                title,
                subheadline,
                body,
                hero: [
                  heroImage,
                ],
                sections,
                authors,
                slug,
                extra: {
                  authorInfo,
                  relatedArticle,
                  starRating,
                  awayScore,
                  awayTeam,
                  awayScorer,
                  homeScore,
                  homeTeam,
                  homeScorer,
                },
              },
            },
          },
        }, '*');
      }
    }
  }

  handleClose(event) {
    event.preventDefault();
    this.onClose();
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }
}

EditionPreviewWindow.propTypes = {
  children: PropTypes.node.isRequired,
  edition: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(
  ({ edition }) => ({ edition }),
  {},
)(EditionPreviewWindow);
