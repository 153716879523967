import {
  CLONE_ARTICLE_REQUEST,
  DISPOSE_FETCH_ARTICLE_PUBLISHED,
  FETCH_ARTICLE_PUBLISHED,
  ON_BLUR_HEADLINE_FIELD,
  REDIRECT_TO_ARTICLE_PREVIEW,
  RESET_PREVIEW_ID,
  SET_PREVIEW_MODE,
} from 'constants/actionTypes';
import {
  ARTICLE_AUTOCOMPLETE,
  ARTICLE_EDIT_DISPOSE,
  ARTICLE_LIST_DISPOSE,
  ARTICLE_LIST_FETCH,
  ARTICLE_REVISION_REQUESTED,
  ARTICLE_REVISION_REVERT, ARTICLE_DELETE, ARTICLE_SAVE,
  ARTICLE_GET_SUGGESTIONS, ARTICLE_AUTO_LINK_VOCAB,
  ARTICLE_SET_DEFAULTS,
} from 'constants/actionTypes/article';

export const saveArticle = (publish, silent = false) =>
  ({ type: ARTICLE_SAVE, value: { publish, silent } });
export const deleteArticle = () => ({ type: ARTICLE_DELETE });
export const fetchArticle = value => ({ type: ARTICLE_LIST_FETCH, value });
export const disposeFetchArticle = value => ({ type: ARTICLE_LIST_DISPOSE, value });
export const fetchArticlePublished = value => ({ type: FETCH_ARTICLE_PUBLISHED, value });
export const disposeFetchArticlePublished = value =>
  ({ type: DISPOSE_FETCH_ARTICLE_PUBLISHED, value });
export const disposeArticleCreation = value => ({ type: ARTICLE_EDIT_DISPOSE, value });
export const setArticleDefaults = () => ({ type: ARTICLE_SET_DEFAULTS });

export const setPreviewMode = value => ({ type: SET_PREVIEW_MODE, value });
export const cloneArticle = value => ({ type: CLONE_ARTICLE_REQUEST, value });
export const resetPreviewId = () => ({ type: RESET_PREVIEW_ID });
export const saveToRevision = () => ({ type: ARTICLE_REVISION_REQUESTED });
export const blurHeadlineField = value => ({ type: ON_BLUR_HEADLINE_FIELD, value });

export const redirectToArticlePreview = value => ({ type: REDIRECT_TO_ARTICLE_PREVIEW, value });

// Vocab
export const autoLinkVocab = value => ({ type: ARTICLE_AUTO_LINK_VOCAB, value });
export const getArticleSuggestions = () => ({ type: ARTICLE_GET_SUGGESTIONS });

// Autocomplete
export const searchArticle = value => ({ type: ARTICLE_AUTOCOMPLETE, value });

// Revisioning
export const revertArticle = value => ({ type: ARTICLE_REVISION_REVERT, value });
