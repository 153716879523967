import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { ContextPanelFormLabel } from '../../../../common/context/ContextPanel';
import TextField from '../../../../ui/TextField';

const styles = () => ({
  wrapper: {
    width: '100%',
    marginTop: '10px',
  },
});

const CapsuleField = (props) => {
  const { classes, title, defaultValue, overrideValue, onChangeTitle, onChangeLink } = props;
  return (
    <FormControl className={classes.wrapper}>
      <ContextPanelFormLabel>{title} capsule</ContextPanelFormLabel>
      <TextField
        label={'Title'}
        defaultValue={overrideValue.name !== null ? overrideValue.name : defaultValue.name || ''}
        onChange={onChangeTitle}
      />
      <TextField
        label={'Link'}
        defaultValue={overrideValue.path !== null ? overrideValue.path : defaultValue.path || ''}
        onChange={onChangeLink}
      />
    </FormControl>
  );
};

CapsuleField.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  overrideValue: PropTypes.object,
};

CapsuleField.defaultProps = {
  defaultValue: {
    name: null,
    path: null,
  },
  overrideValue: {
    name: null,
    path: null,
  },
};

export default withStyles(styles)(CapsuleField);
