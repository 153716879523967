import React from 'react';
import { useSlate } from 'slate-react';
import {
  ELEMENT_LINK,
  ToolbarButton,
  someNode,
  unwrapNodes,
  getAbove,
  isCollapsed,
} from '@udecode/slate-plugins';
import { Transforms, Editor } from 'slate';

const ToolbarLinkRemove = (props) => {
  const editor = useSlate();
  return (
    <>
      {someNode(editor, { match: { type: ELEMENT_LINK } }) && <ToolbarButton
        onMouseDown={(e) => {
          e.preventDefault();
          const linkNode = getAbove(editor, { match: { type: ELEMENT_LINK } });
          const hasWrap =
            typeof linkNode !== 'undefined' && isCollapsed(editor.selection);
          if (hasWrap && isCollapsed(editor.selection)) {
            const linkLeaf = Editor.leaf(editor, editor.selection);
            const [, inlinePath] = linkLeaf;
            Transforms.select(editor, inlinePath);
          }
          unwrapNodes(editor, { match: { type: ELEMENT_LINK }, at: editor.selection });
        }}
        {...props}
      />}
    </>
  );
};

export default ToolbarLinkRemove;
