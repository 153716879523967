import { ofType } from 'redux-observable';
import { EMPTY, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap, take,
  withLatestFrom,
} from 'rxjs/operators';
import { push } from 'connected-react-router';

import {
  REDIRECT_TO_ARTICLE_PREVIEW,
  SET_ARTICLE_SUGGESTION_TAG,
} from '../../constants/actionTypes';

import apiCatchError, {
  showSuccessNotification,
} from '../helper/notification';

import {
  PAGE_ENTER_ARTICLE_CREATE,
  PAGE_ENTER_ARTICLE_EDIT,
} from '../../constants/actionTypes/route';
import {
  ARTICLE_FETCH_SUCCESS, ARTICLE_SAVE_SUCCESS, ARTICLE_UNPUBLISH_SUCCESS,
  ARTICLE_DELETE_SUCCESS, ARTICLE_GET_SUGGESTIONS, ARTICLE_AUTO_LINK_VOCAB,
} from '../../constants/actionTypes/article';
import {
  FIELD_BODY,
  FIELD_HEADLINE,
  FIELD_SUB_HEADLINE,
} from '../../constants/article/articleFields';

import { setLocalProp } from '../../actions/dataState';
import { MARKUP } from '../../constants/editor/dataTypes';
import { VOCAB_SINGULAR } from '../../constants/vocab';
import { DATASTATE_LOCAL_DISPOSE } from '../../constants/actionTypes/dataState';
import { setContext } from '../../actions/page';
import { CONTEXT_DEFAULT } from '../../constants/contexts';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const setDefaultContextOnEnter = action$ => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT),
  mergeMap(() => of(setContext(CONTEXT_DEFAULT))),
);

export const redirectAfterDeleteSuccess = action$ => action$.pipe(
  ofType(ARTICLE_DELETE_SUCCESS),
  mapTo(push('/article')),
);

export const unpublishArticleSuccess = action$ => action$.pipe(
  ofType(ARTICLE_UNPUBLISH_SUCCESS),
  mergeMap(showSuccessNotification('Article is successfully unpublished')),
);

export const actionableAfterArticleSaved = (action$, state$) => action$.pipe(
  ofType(ARTICLE_SAVE_SUCCESS),
  withLatestFrom(state$),
  filter(([, {
    router: { location: { pathname } },
  }]) => /^\/article\/build/.test(pathname)),
  mergeMap(([
    { value: {
      nid: [{ value: articleId }],
    } },
  ]) => action$.pipe(
    ofType(DATASTATE_LOCAL_DISPOSE), // wait until disposal
    map(() => push(`/article/edit/${articleId}`)),
    take(1),
  )),
);

export const articleSavedNotification = action$ => action$.pipe(
  ofType(ARTICLE_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Article is successfully saved.')),
);

export const switchPublicationArticleLoad = (action$, state$) => action$.pipe(
  ofType(ARTICLE_FETCH_SUCCESS),
  withLatestFrom(state$),
  filter(([
    { value: { field_publications: publications } },
    { frame: { selectedPublication } },
  ]) =>
    !!publications &&
    !publications.find(({ target_id: id }) => id === Number(selectedPublication.id)),
  ),
  mergeMap(([{ value: { field_publications: publications } }, { frame: { publication } }]) => {
    const pubId = publications?.[0]?.target_id || publications?.[0];
    if (Number.isNaN(pubId)) {
      return EMPTY;
    }
    return of({
      type: PUBLICATION_SELECTED,
      value: publication.find(({ id }) => Number(id) === Number(pubId)),
    });
  }),
);

export const suggestTagBasedOnContent = (action$, state$) => action$.pipe(
  ofType(ARTICLE_GET_SUGGESTIONS),
  withLatestFrom(state$, (a, state) => state),
  map(({
    dataState: {
      [FIELD_HEADLINE]: headline,
      [FIELD_SUB_HEADLINE]: subHeadline,
      [FIELD_BODY]: body,
    },
  }) => {
    let n = 3;
    let div = document.createElement('div');
    const first3Paragraph = Array.isArray(body) && body.length > 0 ? body.reduce((acc, comp) => {
      if (comp.type === MARKUP && n > 0) {
        div.innerHTML = comp.data[MARKUP];
        const text = div.textContent;
        div.innerHTML = '';
        n -= 1;
        return `${acc} ${text}`;
      }
      return acc;
    }, '') : '';
    div.innerHTML = subHeadline || '';
    const subHeadlineAsPlainText = div.textContent;
    div = null;
    return `${headline || ''} ${subHeadlineAsPlainText} ${first3Paragraph}`;
  }),
  distinctUntilChanged(),
  switchMap(content =>
    ajax.post(
      '/api/text-entity',
      { content },
      { 'Content-Type': 'application/json' },
    ).pipe(
      map(({ response: entities }) => ({
        type: SET_ARTICLE_SUGGESTION_TAG,
        value: entities.filter(({ type }) => /LOCATION|ORGANIZATION|PERSON|OTHER/.test(type)),
      })),
      apiCatchError(),
    ),
  ),
);

export const autoTagVocab = (action$, state$) => action$.pipe(
  ofType(ARTICLE_AUTO_LINK_VOCAB),
  withLatestFrom(state$),
  filter(([{ value: vocab }, { dataState }]) => dataState?.[vocab] && dataState[vocab].length > 0),
  switchMap(([{ value: vocab }, { dataState }]) => {
    const { [FIELD_BODY]: body, [vocab]: terms } = dataState;
    const container = new DocumentFragment();

    // remove any linked term
    let copyBody = [...body].map((component) => {
      if (component.type !== MARKUP || !component.data?.markup) {
        return component;
      }
      const path = VOCAB_SINGULAR[vocab];
      const linkPattern = new RegExp(`/${path}`);
      if (linkPattern.test(component.data.markup)) {
        while (container.firstChild) {
          container.removeChild(container.lastChild);
        }
        const div = document.createElement('div');
        div.innerHTML = component.data.markup;
        container.appendChild(div);
        container.querySelectorAll(`[href^="/${path}"]`).forEach((anchor) => {
          anchor.parentNode.replaceChild(document.createTextNode(anchor.innerHTML), anchor);
        });
        return {
          ...component,
          data: {
            ...component.data,
            markup: container.firstChild.innerHTML,
          },
        };
      }
      return component;
    });

    const markupOnly = ({ type, data: { markup } }) => type === MARKUP && Boolean(markup);
    const checkLinkExists = list => ({ name, path }) => !list
      .filter(markupOnly)
      .some(({ data: { markup } }) => {
        const pattern = new RegExp(`href=["']${path}["'][^>]*?>(<.*>)?${name}(</.*>)?</a>`, 'i');
        return pattern.test(markup);
      });

    terms
      // first filter out the term that has link already
      .filter(checkLinkExists(copyBody))
      // now find any matching term and inject the anchor
      .forEach(({ name, path }) => {
        let found = false;
        copyBody = copyBody
          // .filter(markupOnly)
          .map((component) => {
            if (found || component.type !== MARKUP || !component.data?.markup) {
              return component;
            }
            const { data: { markup } } = component;
            const termPattern = new RegExp(`(?!<a[^>]*?>)(\\b${name}\\b)(?![^<]*?</a>)`, 'i');
            if (termPattern.test(markup)) {
              found = true;
              return {
                ...component,
                data: {
                  ...component.data,
                  [MARKUP]: markup.replace(termPattern, `<a href="${path}">$1</a>`),
                },
              };
            }
            return component;
          });
      });

    return of(setLocalProp(FIELD_BODY, copyBody));
  }),
);

export const redirect = (action$, state$) => action$.pipe(
  ofType(REDIRECT_TO_ARTICLE_PREVIEW),
  filter(({ value }) => !!value),
  distinctUntilChanged((prev, curr) => prev.value === curr.value),
  withLatestFrom(state$),
  filter(([{ value: articleId }, { router: { location: { pathname } } }]) =>
    pathname !== `/article/preview/${articleId}`),
  map(([{ value }]) => push(`/article/preview/${value}`)),
);
