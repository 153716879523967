import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { useDispatch } from 'react-redux';

const styles = theme => ({
  root: {
    margin: '5px',
    padding: '5px 8px',
    border: `0.5px solid ${theme.palette.border.secondary.main}`,
    borderRadius: '4px',
  },
  disabled: {
    '&:hover': {
      cursor: 'default',
    },
  },
  enabled: {
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#000',
      background: theme.palette.background.paleBlue,
    },
  },
});

const dragSource = {
  beginDrag(props) {
    return props.data;
  },
  endDrag(item, monitor) {
    const dropResult = monitor.getDropResult();
    if (dropResult !== null && typeof dropResult.handler !== 'undefined' && typeof dropResult.data !== 'undefined') {
      dropResult.handler(dropResult.dropEffect, dropResult.data);
    }
  },
};

const dragCollector = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

let isComponentDragging = false;

const Draggable = (props) => {
  const {
    classes,
    className,
    children,
    isDragging,
    connectDragSource,
    onDragEnd,
    onDragStart,
    disabled,
    ...rest
  } = props;
  const dispatch = useDispatch();
  if (onDragStart && onDragEnd) {
    if (isDragging) {
      dispatch({ type: onDragStart });
      isComponentDragging = true;
    } else if (!isDragging && isComponentDragging) {
      dispatch({ type: onDragEnd });
      isComponentDragging = false;
    }
  }
  const rootClasses = (className) ? [classes.root, className] : [classes.root];
  if (disabled) {
    rootClasses.push(classes.disabled);
    return <div className={rootClasses.join(' ')} {...rest}>{children}</div>;
  }
  rootClasses.push(classes.enabled);
  return connectDragSource(<div className={rootClasses.join(' ')} {...rest}>{children}</div>);
};

Draggable.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  onDragStart: PropTypes.string,
  onDragEnd: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Draggable.defaultProps = {
  className: null,
  onDragStart: null,
  onDragEnd: null,
  disabled: false,
};

export default type => (withStyles(styles)(DragSource(
  type,
  dragSource,
  dragCollector,
)(Draggable)));

