import { withStyles } from '@material-ui/core/styles';
import Button from './Button';

export default withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))(Button);
