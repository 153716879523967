import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Article from './common/Article';

const FIELD_ARTICLES = 'articles';

const ArticleX2 = (props) => {
  const {
    component,
    delta, mergeProperty, setProperty,
  } = props;

  const { articles } = component;
  useEffect(() => {
    // make sure the state has default value
    if (!component.articles) {
      setProperty({
        delta,
        prop: 'articles',
        value: [{}, {}],
      });
    }
  }, [component, delta, setProperty]);
  return (
    <>
      {articles && articles.map((article, index) => (
        <Article
          key={`${delta}-article-${index}`}
          delta={index}
          containImage={index === 0}
          containBody={index === 1}
          componentDelta={delta}
          article={article}
          showAuthorField={index === 1}
          prop={FIELD_ARTICLES}
          mergeProperty={mergeProperty}
        />
      ))}
    </>
  );
};

ArticleX2.propTypes = {
  component: PropTypes.object.isRequired,
  delta: PropTypes.number.isRequired,
  mergeProperty: PropTypes.func.isRequired,
  setProperty: PropTypes.func.isRequired,
};

export default ArticleX2;
