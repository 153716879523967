import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

import { removeNotification } from '../../actions/notification';

const styles = theme => ({
  button: {
    color: theme.palette.common.white,
    minWidth: 'auto',
  },
});

const Notification = ({
  classes,
  notifications,
  removeNotification: removeMessage,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, setDisplayed] = useState([]);

  useEffect(() => {
    notifications.forEach(({ key, message, variant = 'info', persist = false }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        variant,
        persist,
        onClose: (x, y, notificationId) => removeMessage(notificationId),
        preventDuplicate: true,
        action: myKey => (
          <Button
            className={classes.button}
            onClick={() => {
              closeSnackbar(myKey);
              setDisplayed(displayed.filter(({ key: thisKey }) => thisKey !== myKey));
              removeMessage(myKey);
            }}
          ><CloseIcon /></Button>
        ),
      });

      // keep track of snackbars that we've displayed
      setDisplayed([
        ...displayed,
        key,
      ]);
    });
  }, [notifications]);

  return null;
};

Notification.defaultProps = {
  notifications: [],
};

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array,
  removeNotification: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(({
  frame: { notifications },
}) => ({ notifications }), { removeNotification })(Notification));
