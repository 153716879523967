import { createSelector } from 'reselect';

export const getVocab = (state, props) => state.vocab[props.vocab].items;
export const getPopular = (state, props) => state.vocab.popular[props.vocab];
export const getRelated = (state, props) => state.vocab.related[props.vocab];

export const makeGetVocab = () => createSelector(
  [getVocab],
  items => items,
);

export const makeGetPopular = () => createSelector(
  [getPopular],
  items => items,
);

export const makeGetRelated = () => createSelector(
  [getRelated],
  items => items,
);
