import { PURGE } from 'redux-persist';
import {
  SET_SELECTED_USER,
  USER_EDIT_ONCHANGE,
  USER_SAVE_EDIT,
  USER_SAVE_EDIT_SUCCESS,
  USER_SAVE_EDIT_FAILED,
} from '../../constants/actionTypes';

const defaultState = {
  selectedUser: {},
  errorMessage: '',
  submittingForm: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SELECTED_USER:
      return {
        ...state, selectedUser: Object.assign({}, action.value), errorMessage: '', submittingForm: false,
      };

    case USER_EDIT_ONCHANGE:
      return {
        ...state, selectedUser: { ...state.selectedUser, [action.field]: action.value }, errorMessage: '',
      };

    case USER_SAVE_EDIT:
      return { ...state, submittingForm: true };

    case USER_SAVE_EDIT_FAILED:
      return { ...state, errorMessage: action.value, submittingForm: false };

    case USER_SAVE_EDIT_SUCCESS:
      return { ...state, submittingForm: false };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
