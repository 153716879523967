import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';


import MarkUp from './MarkUp';

const styles = {
  root: {
    position: 'relative',
    flexBasis: '100%',
  },
  options: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

const HideAndShow = (props) => {
  return (<Grid className={props.classes.root}>
    <MarkUp
      multiMarkup
      placeholder={'Hidden text'}
      extraFields={[
        { key: 'showPrompt', label: 'Show prompt text' },
        { key: 'hidePrompt', label: 'Hide prompt text' },
      ]}
      {...props}
    />
  </Grid>);
};

HideAndShow.defaultProps = {
  removeAble: false,
};

HideAndShow.propTypes = {
  classes: PropTypes.object.isRequired,
  removeArticleComponent: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  saveMarkupData: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  {},
)(HideAndShow));

