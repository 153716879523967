import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_INFOBOX } from './defaults';

const renderElementInfobox = (options) => {
  const { infobox } = setDefaults(options, DEFAULTS_INFOBOX);
  return getRenderElement(infobox);
};

export default renderElementInfobox;
