import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { ContextPanelItem } from '../../../common/context/ContextPanel';
import { CMS_SERVER_TIMESTAMP, FIELD_CHANGED } from '../../../../constants/article/articleFields';
import moment from 'moment';

const LastUpdatedStatus = ({ changed, serverTimestamp }) => {
  const [updated, setUpdated] = useState(null);
  useEffect(() => {
    if (changed) {
      const nowDiff = serverTimestamp
        ? moment().unix() - serverTimestamp
        : 0;
      setUpdated(moment.unix(changed + nowDiff).fromNow());
    }
  }, [changed, serverTimestamp]);
  if (!changed) return null;
  return (
    <ContextPanelItem label={'Last edited'}>
      <Typography variant={'caption'}>{updated}</Typography>
    </ContextPanelItem>
  );
};

LastUpdatedStatus.propTypes = {
  changed: PropTypes.number,
  serverTimestamp: PropTypes.number,
};

LastUpdatedStatus.defaultProps = {
  changed: null,
  serverTimestamp: null,
};

export default connect(
  ({
    dataState: {
      [FIELD_CHANGED]: changed,
      [CMS_SERVER_TIMESTAMP]: serverTimestamp,
    } },
  ) => ({ changed, serverTimestamp }),
  {},
)(LastUpdatedStatus);
