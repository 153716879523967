import { ARTICLE_TYPE } from 'constants/vocab';
import {
  DISPLAY_DATE_UPDATED, 
} from 'constants/article/article';
import {
  FIELD_ARTICLE_TYPE,
  FIELD_PUBLICATIONS,
  FIELD_WORKFLOW,
  FIELD_DISPLAY_DATE_PREF,  
} from 'constants/article/articleFields';

export const setArticleDataDefaults = (data, state) => {
  if (!data[FIELD_ARTICLE_TYPE]) {
    const articleTypes = state?.vocab?.[ARTICLE_TYPE]?.items;
    if (articleTypes) {
      data[FIELD_ARTICLE_TYPE] = articleTypes.find(({ name }) => /news/i.test(name)) || articleTypes[0] || null;
    }
  }
  if (
    !data[FIELD_PUBLICATIONS] ||
    (Array.isArray(data[FIELD_PUBLICATIONS]) && data[FIELD_PUBLICATIONS].length < 1)
  ) {
    const publication = state?.frame?.selectedPublication;
    if (publication?.id) {
      data[FIELD_PUBLICATIONS] = [publication.id];
    }
  }
  if (!data[FIELD_WORKFLOW]) {
    const workflowOptions = state?.frame?.workflowOptions;
    if (workflowOptions && workflowOptions.length > 0) {
      data[FIELD_WORKFLOW] = workflowOptions[0].id;
    }
  }
  if (!data[FIELD_DISPLAY_DATE_PREF] || data[FIELD_DISPLAY_DATE_PREF] == ''){
    data[FIELD_DISPLAY_DATE_PREF] = DISPLAY_DATE_UPDATED;
  }
};
