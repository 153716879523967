import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  form: {
    maxWidth: '300px',
    padding: theme.spacing(6),
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      background: theme.palette.background.contrast,
    },
  },
});

const LoginForm = ({ classes, noForm, ...rest }) => {
  if (noForm) {
    return <div className={classes.form} {...rest} />;
  }
  return <form className={classes.form} {...rest} />;
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  noForm: PropTypes.bool,
};

LoginForm.defaultProps = {
  noForm: false,
};

export default withStyles(styles)(LoginForm);
