import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';

import { CMS_DATE_FORMAT } from '../../constants/common';

import { fetchProductList, disposeProductList, fetchProduct, disposeProduct } from '../../actions/product';
import ThumbnailImage from '../table/content/ThumbnailImage';
import IconButton from '../ui/buttons/IconButton';
import { TableBody, TableRow, TableCell } from '../ui/table/Table';

import ListTable from '../table/ListTable';
import TableHeader from '../table/TableHeader';
import ProductEditDialog from '../dialog/ProductEditDialog';

const ProductsTable = (props) => {
  const {
    products,
    total,
    textQuery,
    errorCode,
    fromDate,
    toDate,
    isFetching,
    fetchProductList: fetchList,
    disposeProductList: disposeList,
    fetchProduct: fetch,
    disposeProduct: dispose,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);

  const queryData = [
    textQuery,
    fromDate,
    toDate,
    errorCode,
  ];

  const getFetchData = (page, rowsPerPage) => ({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    q: textQuery,
    link_http_code: errorCode,
    start: fromDate,
    end: toDate,
  });

  useEffect(() => () => disposeList(), []);

  const headers = ['Image', 'Product', 'Merchant', 'Brand', 'Model', 'Price', 'Updated', 'Error', ''];

  return (
    <ListTable
      isFetching={isFetching}
      total={total}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={fetchList}
    >
      <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
      <TableBody>
        {products.length === 0 && <TableRow>
          <TableCell colSpan={headers.length} align={'center'}>No products found</TableCell>
        </TableRow>}
        {products.length > 0 && products
          .filter(product => product && product.type)
          .map(({
            data: {
              id, image, title, vendorName, brand, model, price, changed, stats,
            },
          }) => (
            <TableRow key={id} hasActions>
              <TableCell><ThumbnailImage image={image} size={'small'} /></TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>{vendorName}</TableCell>
              <TableCell>{brand}</TableCell>
              <TableCell>{model}</TableCell>
              <TableCell>£{price || '0.00'}</TableCell>
              <TableCell>{moment.unix(changed).format(CMS_DATE_FORMAT)}</TableCell>
              <TableCell>{stats?.link_http_code && stats?.link_http_code !== 200 ? stats?.link_http_code : '-'}</TableCell>
              <TableCell>
                <Grid container wrap={'nowrap'} justify={'flex-end'}>
                  <IconButton
                    onClick={() => {
                      fetch(id);
                      setOpenDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
      {openDialog && <ProductEditDialog
        open={openDialog}
        handleClose={() => {
          dispose();
          setOpenDialog(false);
        }}
      />}
    </ListTable>
  );
};

ProductsTable.propTypes = {
  fetchProductList: PropTypes.func.isRequired,
  disposeProductList: PropTypes.func.isRequired,
  fetchProduct: PropTypes.func.isRequired,
  disposeProduct: PropTypes.func.isRequired,
  textQuery: PropTypes.string,
  errorCode: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  products: PropTypes.array,
  total: PropTypes.number,
  isFetching: PropTypes.bool,
};

ProductsTable.defaultProps = {
  textQuery: '',
  errorCode: '',
  fromDate: '',
  toDate: '',
  products: [],
  total: 0,
  isFetching: false,
};

export default connect(
  ({
    productList: { products, total, isFetching },
  }) =>
    ({ products, total, isFetching }),
  { fetchProductList, disposeProductList, fetchProduct, disposeProduct },
)(ProductsTable);
