import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import MarkupField from '../../../ui/MarkupField';
import Select from '../../../ui/Select';
import ImageField from './common/ImageField';
import { FieldWrapper } from './common/GridWrapper';

const TITLE_LABEL = 'Title';
const BODY_LABEL = 'Body';
const POSITION_LABEL = 'Body position';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA';

const POSITION_OPTIONS = ['bottom', 'top'];

const TemplateImage = (props) => {
  const {
    id, title, body, position, image, attachment, attachmentCTA,
    updateStoryPageProp: updateProp,
  } = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImageField
          id={id}
          onSelect={value => updateProp(id, 'image', {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateProp(id, 'image', null)}
          image={image}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={TITLE_LABEL}
          value={title}
          onChange={e => updateProp(id, 'title', e.target.value)}
        />
        <MarkupField
          label={BODY_LABEL}
          data={body}
          onChange={markup => updateProp(id, 'body', markup)}
          multiline
          toolbar={['bold', 'italic', 'underline', 'link']}
        />
        <Select
          label={POSITION_LABEL}
          value={position}
          onChange={e => updateProp(id, 'position', e.target.value)}
          items={POSITION_OPTIONS}
          margin={'normal'}
        />
        <TextField
          label={ATTACHMENT_LABEL}
          placeholder={ATTACHMENT_PLACEHOLDER}
          value={attachment}
          onChange={e => updateProp(id, 'attachment', e.target.value)}
        />
        <TextField
          label={ATTACHMENT_CTA_LABEL}
          placeholder={ATTACHMENT_CTA_PLACEHOLDER}
          value={attachmentCTA}
          onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
        />
      </FieldWrapper>
    </Grid>
  );
};

TemplateImage.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.object,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplateImage.defaultProps = {
  title: '',
  body: '',
  position: POSITION_OPTIONS[0],
  image: null,
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplateImage);
