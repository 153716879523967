import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize } from '@material-ui/core';

import {
  onRemoveTerm,
  onReplaceTerm,
  setEditionLockField,
} from '../../../../actions/edition';

import { PagePanel, PagePanelContent, PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';
import {
  CONTENT_SOURCE,
  VOCAB_SINGULAR,
} from '../../../../constants/vocab';
import { FIELD_CONTENT_SOURCE } from '../../../../constants/edition/editionFields';

import FieldLockVocabSelector from '../../../fieldLock/form/FieldLockVocabSelectorDepricated';

const SectionPanel = (props) => {
  const {
    contentSource,
    onReplaceTerm: onReplace,
    onRemoveTerm: onRemove,
    setEditionLockField: lockField,
  } = props;
  const taxomomyList = [
    [CONTENT_SOURCE, contentSource, FIELD_CONTENT_SOURCE],
  ];

  return (
    <PagePanel>
      <PagePanelHeading>
        <SectionTitle>Content Source</SectionTitle>
      </PagePanelHeading>
      <PagePanelContent>
        {taxomomyList
          .map(([vocab, selectedTerms, field, extraFields]) => (
            <FieldLockVocabSelector
              key={vocab}
              required
              field={field}
              onUnlock={() => {
                lockField([field, selectedTerms]);
              }}
              extraFields={extraFields}
              vocab={vocab}
              selectedTerms={selectedTerms}
              label={capitalize(VOCAB_SINGULAR[vocab])}
              onRemove={onRemove}
              onSelect={onReplace}
              limit={1}
            />
          ))
        }
      </PagePanelContent>
    </PagePanel>
  );
};

SectionPanel.propTypes = {
  contentSource: PropTypes.array.isRequired,
  onReplaceTerm: PropTypes.func.isRequired,
  onRemoveTerm: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default connect(
  ({
    edition: {
      content_source: contentSource,
    },
  }) => ({ contentSource }),
  { onReplaceTerm, onRemoveTerm, setEditionLockField },
)(SectionPanel);
