import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from '../../../../ui/TextField';

const styles = () => ({
  inputHidden: {
    display: 'none',
  },
});

const RelatedLinksField = (props) => {
  const { classes, value, count, onChange } = props;
  const links = value ? [...value] : [];
  while (links.length !== count) {
    if (links.length < count) {
      links.push({});
    }
    if (links.length > count) {
      links.pop();
    }
  }
  return (
    <>
      {links && links.map((link, index) => (
        <TextField
          key={`related-link-${index}`}
          label={`Related Link ${index + 1}`}
          className={link.headline ? classes.input : classes.inputHidden}
          value={link.headline || ''}
          onChange={event => onChange(event, index)}
          multiline
        />
      ))}
    </>
  );
};

RelatedLinksField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  value: PropTypes.array,
};

RelatedLinksField.defaultProps = {
  value: [],
};

export default withStyles(styles)(RelatedLinksField);
