import { ELEMENT_HR } from './defaults';
import { HR } from '../../../../../constants/editor/dataTypes';

export const convertToHR = () => ({
  type: ELEMENT_HR,
  children: [{ text: '' }],
});

export const convertFromHR = () => ({
  type: HR,
});
