import { ELEMENT_TWITTER, PROP_URL, PROP_CONVERSATION, TWITTER_PATTERN } from './defaults';
import { TWITTER } from '../../../../../../constants/editor/dataTypes';

export const convertToTwitter = data => ({
  type: ELEMENT_TWITTER,
  [PROP_URL]: data[PROP_URL] || '',
  [PROP_CONVERSATION]: data[PROP_CONVERSATION] || false,
  children: [{ text: '' }],
});

export const convertFromTwitter = data => ({
  type: TWITTER,
  data: {
    [PROP_URL]: data[PROP_URL] || '',
    [PROP_CONVERSATION]: data[PROP_CONVERSATION] || false,
  },
});

export const isValidUrl = string => TWITTER_PATTERN.test(string);
export const getValidUrl = (string) => {
  const matches = string.match(TWITTER_PATTERN);
  return matches[0] || '';
};
