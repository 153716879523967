import { combineEpics } from 'redux-observable';
import * as Sentry from '@sentry/react';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { APP_CRASHED } from 'constants/actionTypes/notification';

import * as layoutCreateEpic from 'epics/layout/layoutCreateEpic';
import * as layoutEpic from 'epics/layout/layoutEpic';
import * as layoutRealTimeEpic from 'epics/layout/layoutRealTimeEpic';
import * as layoutLockEpic from 'epics/layout/layoutLockEpic';
import * as layoutStateEpic from 'epics/layout/layoutStateEpic';
import * as layoutPreviewEpic from 'epics/layout/layoutPreviewEpic';
import * as articleBuilder from 'epics/article/articleBuilderEpic';
import * as articleApiEpic from 'epics/article/articleApiEpic';
import * as articleStateEpic from 'epics/article/articleStateEpic';
import * as articleLockEpic from 'epics/article/articleLockEpic';
import * as articleList from 'epics/article/articleListEpic';
import * as configEpic from 'epics/config/configEpic';
import * as twitterEpic from 'epics/article/twitterEpic';
import * as premiumAdEpic from 'epics/ad/premiumAdEpic';
import * as profileEpic from 'epics/login/profileEpic';
import * as contactUsEpic from 'epics/staticPage/requestStaticPageEpic';
import * as sentryEpic from 'epics/login/sentryEpic';
import * as publicationEpic from 'epics/frame/publicationEpic';
import closePublicationDialog from 'epics/frame/closePublicationDialog';
import * as loginEpic from 'epics/login/loginEpic';
import * as resetPasswordEpic from 'epics/resetPassword/resetPasswordEpic';
import onRehydrate from 'epics/login/onRehydrate';
import * as logout from 'epics/login/onLogout';
import * as existingSession from 'epics/login/onExistingSession';
import * as forgottenPasswordSubmit from 'epics/login/onSubmitForgottenPassword';
import * as sidebar from 'epics/sidebar/sidebarEpic';
import * as curated from 'epics/manualList/manualListEpic';
import * as feedEpic from 'epics/feed/feedEpic';
import * as menu from 'epics/menu/menuEpic';
import * as users from 'epics/users/usersEpic';
import * as ad from 'epics/ad/adEpic';
import * as affilate from 'epics/affiliate/affiliateEpic';
import * as routeEnter from 'epics/route/routeEnter';
import * as autocomplete from 'epics/autocomplete/autocompleteEpic';
import * as eventEpics from 'epics/common/eventEpics';
import * as redirectEpic from 'epics/redirect/redirectEpic';
import * as liveblogListEpic from 'epics/liveblog/liveblogListEpic';
import * as liveblogBuilderEpic from 'epics/liveblog/liveblogBuilderEpic';
import * as embedEpic from 'epics/liveblog/embedEpic';
import * as articleTranslationEpic from 'epics/article/articleTranslationEpic';
import * as storyEpic from 'epics/story/storyEpic';
import * as dashboardEpics from 'epics/dashboard/dashboardEpics';
import * as videoDugoutEpic from 'epics/media/video/videoDugoutEpic';
import * as videoJWPlayerEpic from 'epics/media/video/videoJWPlayerEpic';
import * as mediaEpic from 'epics/media/mediaEpic';
import * as galleryEpic from 'epics/media/galleryEpic';
import * as galleryStateEpic from 'epics/media/galleryStateEpic';
import * as termsLayout from 'epics/layout/vocabLayoutEpic';
import * as authorEpic from 'epics/vocab/authorEpic';
import * as simpleTermEpic from 'epics/vocab/vocabEpic';
import * as suggestionTagsEpic from 'epics/article/suggestionTagsEpic';
import * as connectionEpic from 'epics/websocket/socketConnectionEpic';
import * as productEpic from 'epics/product/productEpic';
import * as productStateEpic from 'epics/product/productStateEpic';
import * as viafouraNotificationEpic from 'epics/viafouraNotification/viafouraNotificationEpic';
import * as viafouraNotificationStateEpic from 'epics/viafouraNotification/viafouraNotificationStateEpic';
import * as revisionEpic from 'epics/article/revisionEpic';
import * as editionListEpic from 'epics/edition/editionListEpic';
import * as editionExportEpic from 'epics/edition/editionExportEpic';
import * as editionArticleEpic from 'epics/edition/editionArticleEpic';
import * as editionRealtimeEpic from 'epics/edition/editionRealTimeEpic';
import * as editionCloneEpic from 'epics/edition/editionCloneEpic';
import * as scoreBoardEpic from 'epics/liveblog/scoreBoardEpic';
import * as fieldLockEpic from 'epics/fieldLock/fieldLockEpic';
import * as dataStateEpic from 'epics/dataState/dataStateEpic';
import * as eventEpic from 'epics/event/eventEpic';
import * as eventStateEpic from 'epics/event/eventStateEpic';
import * as userEventsEpic from 'epics/service/userEventsEpic';
import * as userCommentsEpic from 'epics/service/userCommentsEpic';

// Integrations
import * as dailymotionEpic from 'epics/integration/dailymotionEpic';
import * as bookingEpic from 'epics/integration/bookingEpic';
import * as playIncEpic from 'epics/integration/playIncEpic';

export default (action$, store) => combineEpics(
  onRehydrate,
  ...Object.values(routeEnter),
  ...Object.values(publicationEpic),
  ...Object.values(fieldLockEpic),
  ...Object.values(dataStateEpic),
  ...Object.values(layoutCreateEpic),
  ...Object.values(layoutRealTimeEpic),
  ...Object.values(layoutLockEpic),
  ...Object.values(layoutStateEpic),
  ...Object.values(layoutPreviewEpic),
  closePublicationDialog,
  ...Object.values(eventEpics),
  ...Object.values(eventStateEpic),
  ...Object.values(articleBuilder),
  ...Object.values(articleApiEpic),
  ...Object.values(articleStateEpic),
  ...Object.values(articleLockEpic),
  ...Object.values(articleList),
  ...Object.values(layoutEpic),
  ...Object.values(configEpic),
  ...Object.values(loginEpic),
  ...Object.values(resetPasswordEpic),
  ...Object.values(forgottenPasswordSubmit),
  ...Object.values(logout),
  ...Object.values(existingSession),
  ...Object.values(sidebar),
  ...Object.values(menu),
  ...Object.values(users),
  ...Object.values(ad),
  ...Object.values(affilate),
  ...Object.values(curated),
  ...Object.values(feedEpic),
  ...Object.values(autocomplete),
  ...Object.values(liveblogBuilderEpic),
  ...Object.values(liveblogListEpic),
  ...Object.values(articleTranslationEpic),
  ...Object.values(storyEpic),
  ...Object.values(eventEpic),
  ...Object.values(dashboardEpics),
  ...Object.values(embedEpic),
  ...Object.values(videoJWPlayerEpic),
  ...Object.values(mediaEpic),
  ...Object.values(galleryEpic),
  ...Object.values(galleryStateEpic),
  ...Object.values(termsLayout),
  ...Object.values(videoDugoutEpic),
  ...Object.values(authorEpic),
  ...Object.values(simpleTermEpic),
  ...Object.values(suggestionTagsEpic),
  ...Object.values(productEpic),
  ...Object.values(productStateEpic),
  ...Object.values(viafouraNotificationEpic),
  ...Object.values(viafouraNotificationStateEpic),
  ...Object.values(revisionEpic),
  ...Object.values(connectionEpic),
  ...Object.values(editionListEpic),
  ...Object.values(editionArticleEpic),
  ...Object.values(editionExportEpic),
  ...Object.values(editionRealtimeEpic),
  ...Object.values(editionCloneEpic),
  ...Object.values(redirectEpic),
  ...Object.values(twitterEpic),
  ...Object.values(bookingEpic),
  ...Object.values(playIncEpic),
  ...Object.values(dailymotionEpic),
  ...Object.values(scoreBoardEpic),
  ...Object.values(premiumAdEpic),
  ...Object.values(profileEpic),
  ...Object.values(sentryEpic),
  ...Object.values(contactUsEpic),
  ...Object.values(userEventsEpic),
  ...Object.values(userCommentsEpic),
)(action$, store)
  .pipe(
    catchError((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      Sentry.captureException(error);
      return of({ type: APP_CRASHED });
    }),
  );
