import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import { CurrencyGbp } from 'mdi-material-ui';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import TextField from '../../../../../../ui/TextField';

import { PROP_TITLE, PROP_EMBED_ID } from '../defaults';

const PriceComparisonElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  console.log({ element })
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Price Comparison'} Icon={CurrencyGbp} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label={'Title'}
              margin={'none'}
              placeholder={'Insert title'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_TITLE]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_TITLE] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Id'}
              margin={'none'}
              placeholder={'Insert embed id'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_EMBED_ID]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_EMBED_ID] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

PriceComparisonElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default PriceComparisonElement;
