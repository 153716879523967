import { ofType } from 'redux-observable';
import {
  switchMap,
  mapTo,
  map,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import apiCatchError from '../helper/notification';

import { serialize } from '../../utils/urlHelper';
import {
  EDITION_EXPORT_FTP_DELETE, EDITION_EXPORT_FTP_FETCH_LIST,
  EDITION_EXPORT_FTP_LIST_REJECTED, EDITION_EXPORT_FTP_LIST_SUCCESS,
  EDITION_EXPORT_FTP_REJECTED,
  EDITION_EXPORT_FTP_SAVE,
  EDITION_EXPORT_FTP_SUCCESS,
  EDITION_EXPORT_UNSCHEDULE,
  EDITION_EXPORT_FETCH_SCHEDULED,
  EDITION_EXPORT_FETCH_SCHEDULED_REJECTED,
  EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS,
  EDITION_EXPORT_SCHEDULE_REJECTED,
  EDITION_EXPORT_SCHEDULE,
  EDITION_EXPORT_SCHEDULE_SUCCESS, EDITION_FTP_LOG_REJECTED, EDITION_FTP_LOG_REQUEST, EDITION_FTP_LOG_SUCCESS
} from "../../constants/actionTypes/edition";

export const saveExportTime = action$ => action$.pipe(
  ofType(EDITION_EXPORT_SCHEDULE),
  switchMap(({
    value: {
      bundle, name, changesOnly, createAtTime, createOnPublish, version, id, changesSinceTime,
    },
  }) => {
    const payload = {
      bundle: [{
        target_id: bundle,
        target_type: 'pugpig_edition_type',
      }],
      name: [{
        value: name,
      }],
      field_changes_only: [{
        value: changesOnly,
      }],
      field_pugpig_create_at_time: [{
        value: createAtTime,
      }],
      field_pugpig_create_on_publish: [{
        value: createOnPublish,
      }],
      field_pugpig_edition_version: [{
        value: version,
      }],
      field_changes_since_time: [{
        value: changesSinceTime,
      }],
    };
    if (id) {
      payload.id = [{
        value: id,
      }];
      return ajax.patch(`/api/edition/export/time/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mapTo({ type: EDITION_EXPORT_SCHEDULE_SUCCESS }),
        apiCatchError(EDITION_EXPORT_SCHEDULE_REJECTED),
      );
    }
    return ajax.post('/api/edition/export/time', payload, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: EDITION_EXPORT_SCHEDULE_SUCCESS }),
      apiCatchError(EDITION_EXPORT_SCHEDULE_REJECTED),
    );
  }),
);

export const getExportTimeList = action$ => action$.pipe(
  ofType(EDITION_EXPORT_FETCH_SCHEDULED, EDITION_EXPORT_SCHEDULE_SUCCESS),
  switchMap(() =>
    ajax.getJSON('/api/edition/export-setting/time').pipe(
      map(response => ({ type: EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS, value: response })),
      apiCatchError(EDITION_EXPORT_FETCH_SCHEDULED_REJECTED),
    ),
  ),
);

export const deleteTimeEntry = action$ => action$.pipe(
  ofType(EDITION_EXPORT_UNSCHEDULE),
  switchMap(({ value }) =>
    ajax.delete(`/api/edition/export-setting/time/${value}`).pipe(
      mapTo({ type: EDITION_EXPORT_FETCH_SCHEDULED }),
      apiCatchError(EDITION_EXPORT_FETCH_SCHEDULED_REJECTED),
    ),
  ),
);

export const deleteFTPEntry = action$ => action$.pipe(
  ofType(EDITION_EXPORT_FTP_DELETE),
  switchMap(({ value }) =>
    ajax.delete(`/api/edition/export-setting/ftp/${value}`).pipe(
      mapTo({ type: EDITION_EXPORT_FTP_FETCH_LIST }),
      apiCatchError(EDITION_EXPORT_FTP_LIST_REJECTED),
    ),
  ),
);

export const getExportFTPList = action$ => action$.pipe(
  ofType(EDITION_EXPORT_FTP_FETCH_LIST, EDITION_EXPORT_FTP_SUCCESS),
  switchMap(() =>
    ajax.getJSON('/api/edition/export-setting/ftp').pipe(
      map(response => ({ type: EDITION_EXPORT_FTP_LIST_SUCCESS, value: response })),
      apiCatchError(EDITION_EXPORT_FTP_LIST_REJECTED),
    ),
  ),
);

export const getFTPLogs = action$ => action$.pipe(
  ofType(EDITION_FTP_LOG_REQUEST),
  switchMap(({ value }) =>
    ajax.getJSON(`/api/edition/ftp/logs?${serialize(value)}`).pipe(
      map(response => ({ type: EDITION_FTP_LOG_SUCCESS, value: response })),
      apiCatchError(EDITION_FTP_LOG_REJECTED),
    ),
  ),
);

export const saveExportFTP = action$ => action$.pipe(
  ofType(EDITION_EXPORT_FTP_SAVE),
  switchMap(({
    value: {
      name,
      server,
      username,
      password,
      transportMethod,
      serverDirectoryPath,
      format,
      editionTime,
      id,
    },
  }) => {
    const payload = {
      bundle: [{
        target_id: 'consumer',
        target_type: 'pugpig_export_type',
      }],
      name: [{
        value: name,
      }],
      field_editions: editionTime.map(id => ({ target_id: id, target_type: 'pugpig_edition' })),
      field_formats: format.map(id => ({ value: id })),
      field_password: [{
        value: password,
      }],
      field_server: [{
        value: server,
      }],
      field_server_directory_path: [{
        value: serverDirectoryPath,
      }],
      field_transport_method: [{
        value: Array.isArray(transportMethod) ? transportMethod[0] : transportMethod,
      }],
      field_user_name: [{
        value: username,
      }],
    };
    if (id) {
      payload.id = [{
        value: id,
      }];
      return ajax.patch(`/api/edition/export/ftp/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mapTo({ type: EDITION_EXPORT_FTP_SUCCESS }),
        apiCatchError(EDITION_EXPORT_FTP_REJECTED),
      );
    }
    return ajax.post('/api/edition/export/ftp', payload, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: EDITION_EXPORT_FTP_SUCCESS }),
      apiCatchError(EDITION_EXPORT_FTP_REJECTED),
    );
  }),
);
