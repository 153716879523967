import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_FAQ, PROP_MARKUP, PROP_TITLE, PROP_SUBHEADING, PROP_ALIGN } from './defaults';
import { FAQ } from '../../../../../constants/editor/dataTypes';

export const convertToFaq = data => ({
  type: ELEMENT_FAQ,
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  question: data.question || '',
  [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  children: [{ text: '' }],
});

export const convertFromFaq = data => ({
  type: FAQ,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP] ? serializeNodes(data[PROP_MARKUP]) : null,
    question: data.question || '',
    [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  },
});
