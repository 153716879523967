import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import SearchIcon from '@material-ui/icons/Search';

import { FIELD_PRODUCTS } from '../../../constants/article/articleFields';
import { CONTENT_TYPE_PRODUCT } from '../../../constants/product/product';
import { CONTEXT_DEFAULT, CONTEXT_PRODUCT } from '../../../constants/contexts';

import { addToLocalProp, sortLocalProp, setItemPropOnLocal, removeFromLocalProp } from '../../../actions/dataState';
import { setContext } from '../../../actions/page';

import Product from './productPanel/Product';
import ProductEdit from './productPanel/ProductEdit';
import ProductSelectorDialog from '../../dialog/ProductSelectorDialog';
import FieldLockContainer from '../../fieldLock/FieldLockContainer';
import { PagePanel, PagePanelSection, PagePanelContent, PagePanelHeading } from '../../common/page/PagePanel';
import SectionTitle from '../../common/SectionTitle';
import Button from '../../ui/buttons/Button';
import { ArrowSortableDown, ArrowSortableUp } from '../../ui/ArrowSortable';
import IconButton from '../../ui/buttons/IconButton';
import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';

const ProductPanel = (props) => {
  const {
    products,
    lockValue,
    hasChanges,
    editIndex,
    addToLocalProp: addToLocal,
    removeFromLocalProp: removeFromLocal,
    sortLocalProp: sortLocal,
    setItemPropOnLocal: setLocalProp,
    setContext: setSidebarContext,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const removeProduct = index => () => {
    if (window.confirm('Are you sure you want to remove this product')) {
      removeFromLocal(FIELD_PRODUCTS, index);
    }
  };
  return (
    <PagePanel>
      <PagePanelSection>
        <FieldLockContainer field={FIELD_PRODUCTS} lockValue={lockValue}>
          <PagePanelHeading>
            <SectionTitle hasChanges={hasChanges}>Products</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {products.map(({ data }, index) => {
                  if (index === editIndex) {
                    return (
                      <ProductEdit
                        key={data.id}
                        product={data}
                        index={index}
                        setProp={(prop, value) => setLocalProp(FIELD_PRODUCTS, index, prop, value)}
                        actions={
                          <>
                            {index < products.length - 1 && <ArrowSortableDown
                              onClick={() => sortLocal(FIELD_PRODUCTS, index, index + 1)}
                            />}
                            {index > 0 && <ArrowSortableUp
                              onClick={() => sortLocal(FIELD_PRODUCTS, index, index - 1)}
                            />}
                            <IconButton
                              onClick={removeProduct(index)}
                            ><DeleteOutlinedIcon /></IconButton>
                            <IconButton
                              onClick={() => setSidebarContext(CONTEXT_DEFAULT)}
                            ><CloseIcon /></IconButton>
                          </>
                        }
                      />
                    );
                  }
                  return (
                    <Product
                      key={data.id}
                      index={index}
                      product={data}
                      actions={
                        <>
                          {index < products.length - 1 && <ArrowSortableDown
                            onClick={() => sortLocal(FIELD_PRODUCTS, index, index + 1)}
                          />}
                          {index > 0 && <ArrowSortableUp
                            onClick={() => sortLocal(FIELD_PRODUCTS, index, index - 1)}
                          />}
                          <IconButton
                            onClick={removeProduct(index)}
                          ><DeleteOutlinedIcon /></IconButton>
                          <IconButton
                            onClick={() => setSidebarContext(CONTEXT_PRODUCT, index)}
                          ><EditIcon /></IconButton>
                        </>
                      }
                    />
                  );
                })}
              </Grid>
              <Grid item container justify={'center'} spacing={2}>
                <Grid item>
                  <Button
                    variant={'outlined'}
                    onClick={() => {
                      addToLocal(
                        FIELD_PRODUCTS,
                        { type: CONTENT_TYPE_PRODUCT, data: {} },
                      );
                      setSidebarContext(CONTEXT_PRODUCT, products.length);
                    }}
                    startIcon={<AddIcon />}
                  >Add new product</Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={'outlined'}
                    onClick={() => {
                      setSidebarContext(CONTEXT_PRODUCT, products.length);
                      setDialogOpen(true);
                    }}
                    startIcon={<SearchIcon />}
                  >Add existing product</Button>
                </Grid>
              </Grid>
            </Grid>
          </PagePanelContent>
        </FieldLockContainer>
      </PagePanelSection>
      {dialogOpen && <ProductSelectorDialog
        open={dialogOpen}
        onSelectProduct={(product) => {
          addToLocal(
            FIELD_PRODUCTS,
            { type: CONTENT_TYPE_PRODUCT, data: product },
          );
          setDialogOpen(false);
        }}
        handleClose={() => {
          setDialogOpen(false);
        }}
      />}
    </PagePanel>
  );
};

ProductPanel.propTypes = {
  addToLocalProp: PropTypes.func.isRequired,
  sortLocalProp: PropTypes.func.isRequired,
  setItemPropOnLocal: PropTypes.func.isRequired,
  removeFromLocalProp: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  editIndex: PropTypes.number,
  products: PropTypes.array,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
};

ProductPanel.defaultProps = {
  editIndex: -1,
  products: [],
  lockValue: null,
  hasChanges: false,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    products: getFieldValue(state, { field: FIELD_PRODUCTS }),
    lockValue: getFieldLockValue(state, { field: FIELD_PRODUCTS }),
    hasChanges: getFieldHasChanges(state, { field: FIELD_PRODUCTS }),
    editIndex: state?.frame?.context?.data,
  };
};

export default connect(
  mapStateToProps,
  {
    addToLocalProp,
    sortLocalProp,
    setItemPropOnLocal,
    removeFromLocalProp,
    setContext,
  },
)(ProductPanel);
