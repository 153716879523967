import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import ArticleDropZone from './ArticleDropZone';
import ArticleRelatedLinks from './ArticleRelatedLinks';
import { OVERRIDE_SHOW_RELATED_LINKS } from '../../../../constants/layout/layout';
import { getListArticles } from '../../../../utils/manualListHelper';
import { ARTICLE_ID } from '../../../../entities/ManualListEntity';
import { getRelatedLinkCount } from '../../../../utils/layoutHelper';

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5px',
    width: 'calc(50% - 10px)',
    '&:nth-child(-n+2)': {
      flexGrow: 1,
    },
  },
});

const ArticleDropZoneContainer = (props) => {
  const { component, domain, children, article, classes, listId, index, itemIndex, pinned } = props;
  const form = [];
  const relatedLinkCount = getRelatedLinkCount(domain, component.type, index);
  if (typeof article !== 'undefined' && article !== null && relatedLinkCount > 0) {
    const { overrides = {} } = article;
    if (typeof overrides[OVERRIDE_SHOW_RELATED_LINKS] !== 'undefined' && overrides[OVERRIDE_SHOW_RELATED_LINKS]) {
      form.push(<ArticleRelatedLinks
        key={'relatedLinks'}
        overrides={overrides}
        articleId={article[ARTICLE_ID]}
        listId={listId}
        itemIndex={itemIndex}
        index={index}
        length={relatedLinkCount}
        component={component}
        pinned={pinned}
      />);
    }
  }
  return (
    <div className={classes.container}>
      {children}
      {form}
    </div>
  );
};

ArticleDropZoneContainer.propTypes = {
  component: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  domain: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  itemIndex: PropTypes.number,
  listId: PropTypes.number,
  article: PropTypes.object,
  pinned: PropTypes.bool,
};

ArticleDropZoneContainer.defaultProps = {
  article: null,
  listId: null,
  itemIndex: null,
  pinned: false,
};

const ArticleDropZoneGroup = (props) => {
  const {
    classes, indexes, manualList, component, slotCount, domain, focus, contextId,
  } = props;
  const { id: listId, isDefault } = manualList;
  const [articles, setArticles] = useState([]);
  const { pinnedArticles } = component;
  const slots = [];
  const articleIndexes = [...indexes]; // copied to preserve original
  const lastIndex = articleIndexes[articleIndexes.length - 1]
    ? articleIndexes[articleIndexes.length - 1] + 1
    : 0;
  useEffect(() => {
    setArticles(getListArticles(manualList));
  }, [manualList]);
  for (let i = 0; i < slotCount; i += 1) {
    if (pinnedArticles?.[i]) {
      slots.push(
        <ArticleDropZoneContainer
          key={`${component.id}-dropzone-${i}`}
          domain={domain}
          component={component}
          classes={classes}
          index={i}
          article={pinnedArticles[i]}
          pinned
        >
          <ArticleDropZone
            article={pinnedArticles[i]}
            index={i}
            listId={listId}
            itemIndex={typeof articleIndexes[0] !== 'undefined' ? articleIndexes[0] : lastIndex}
            component={component}
            pinned
            pinnable
            focused={focus === i && contextId === component.id}
          />
        </ArticleDropZoneContainer>,
      );
    } else {
      const itemIndex = articleIndexes.shift();
      slots.push(
        <ArticleDropZoneContainer
          key={`${component.id}-dropzone-${i}`}
          domain={domain}
          component={component}
          classes={classes}
          article={(articles && articles[itemIndex]) || {}}
          itemIndex={itemIndex}
          listId={listId}
          index={i}
        >
          <ArticleDropZone
            listId={listId}
            itemIndex={itemIndex}
            article={(articles && articles[itemIndex]) || {}}
            index={i}
            component={component}
            focused={focus === i && contextId === component.id}
            loading={!articles}
            pinnable={(articles && isDefault) || listId === null}
          />
        </ArticleDropZoneContainer>,
      );
    }
  }
  return slots;
};

ArticleDropZoneGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  indexes: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  slotCount: PropTypes.number.isRequired,
  manualList: PropTypes.object,
  focus: PropTypes.number,
  contextId: PropTypes.string,
};

ArticleDropZoneGroup.defaultProps = {
  manualList: null,
  focus: -1,
  contextId: '',
};

export default withStyles(styles)(connect(
  ({
    layout: { context: { data } },
    frame: { selectedPublication: { domain } },
  }) => ({ domain, focus: data?.focus, contextId: data?.id }),
  {},
)(ArticleDropZoneGroup));
