import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_PRICE_COMPARISON } from './defaults';

const renderElementPriceComparison = (options) => {
  const { priceComparison } = setDefaults(options, DEFAULTS_PRICE_COMPARISON);
  return getRenderElement(priceComparison);
};

export default renderElementPriceComparison;
