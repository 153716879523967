import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TwitterExplorerAddUser from './TwitterExplorerAddUser';
import TwitterExplorerAddKeyword from './TwitterExplorerAddKeyword';
import TwitterList from './TwitterExplorerList';

import { twitterOnLoad, twitterDispose } from '../../../../actions/twitter';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
  },
});

const TwitterExplorer = ({ classes, twitterOnLoad: onLoad, twitterDispose: onDispose }) => {
  useEffect(() => {
    onLoad();
    return () => {
      onDispose();
    };
  }, []);
  return (<ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Feed</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails variant={'full'}>
      <TwitterExplorerAddUser />
      <TwitterExplorerAddKeyword />
      <TwitterList />
    </ContextPanelDetails>
  </ContextPanel>);
};

TwitterExplorer.defaultProps = {
};

TwitterExplorer.propTypes = {
  classes: PropTypes.object.isRequired,
  twitterOnLoad: PropTypes.func.isRequired,
  twitterDispose: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { twitterOnLoad, twitterDispose },
)(TwitterExplorer));

