import capitalize from '@material-ui/core/utils/capitalize';
import {MEDIA_KEY_SOURCE_IDENTIFIER, MEDIA_SOURCE_CHP, MEDIA_SOURCE_INTERNAL} from '../../constants/media/media';
import {compose, pipe} from './functional';
import {ARTICLE_TYPE_REGEX_LIVEBLOG} from '../../constants/article/article';

const getJsonFromUrl = (url) => {
  const query = url;
  const result = {};
  query.split('&').forEach((part) => {
    if (!part) return;
    const keyValuePair = part.split('+').join(' '); // replace every + with space, regexp-free version
    const eq = keyValuePair.indexOf('=');
    let key = eq > -1 ? keyValuePair.substr(0, eq) : keyValuePair;
    const val = eq > -1 ? decodeURIComponent(keyValuePair.substr(eq + 1)) : '';
    const from = key.indexOf('[');
    if (from === -1) result[decodeURIComponent(key)] = val;
    else {
      const to = key.indexOf(']', from);
      const index = decodeURIComponent(key.substring(from + 1, to));
      key = decodeURIComponent(key.substring(0, from));
      if (!result[key]) result[key] = [];
      if (!index) result[key].push(val);
      else result[key][index] = val;
    }
  });
  return result;
};

export const parseMediaDroppedData = (text) => {
  if (!text) return false;
  // chp
  if (/\/CHP\//.test(text)) {
    const url = new URL(text);
    const params = getJsonFromUrl(url.search.replace(/^\?/, ''));
    return {
      ...params,
      [MEDIA_KEY_SOURCE_IDENTIFIER]: MEDIA_SOURCE_CHP,
    };
  }
  // image media
  try {
    return {
      ...JSON.parse(text),
      [MEDIA_KEY_SOURCE_IDENTIFIER]: MEDIA_SOURCE_INTERNAL,
    };
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    return false;
  }
};

export const normaliseLabel = text => text.replace(/\s/g, '').toLowerCase();

export const hasRoles = roles =>
  access => roles.some(role => access.includes(role));

export const hasPermissions = permissions =>
  access => permissions?.some(role => access.includes(role));

export const checkArticleType = type => pattern =>
  (type?.name ? pattern.test(normaliseLabel(type.name)) : false);

export const hasScoreboard = (type, section) =>
  (section?.path && checkArticleType(type)(ARTICLE_TYPE_REGEX_LIVEBLOG) && /\/sport/.test(section.path));

const replaceUnderscoreWithSpace = text => text.replace(/_/g, ' ');

export const humanFriendlyLabel = pipe(replaceUnderscoreWithSpace, capitalize);

// @todo refactor feature and hasFeatures into one util
export const feature = list =>
  regex => list.filter(item => !!item).some(
    compose(
      regex.test.bind(regex),
      normaliseLabel,
    ),
  );

export const hasFeatures = features => featureName =>
  features.filter(item => !!item).some(item => normaliseLabel(item) === featureName);

// eslint-disable-next-line no-console
export const log = type => message => console[type](message);
