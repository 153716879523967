import { setDefaults } from '@udecode/slate-plugins';
import renderElementNumberbox from './renderElementNumberbox';
import { DEFAULTS_NUMBERBOX } from './defaults';

const NumberboxPlugin = (options) => {
  const { numberbox } = setDefaults(options, DEFAULTS_NUMBERBOX);
  return ({
    renderElement: renderElementNumberbox(options),
    voidTypes: [numberbox.type],
  });
};

export default NumberboxPlugin;
