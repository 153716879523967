import { PAGE_TYPE_ARTICLE, PAGE_TYPE_ARTICLE_CUSTOM, PAGE_TYPE_SECTION, PAGE_TYPE_SINGLE_IMAGE } from './edition';
import { keyFieldsForAPI, keyFieldsForCMS } from '../helper';
import {
  FIELD_FYI,
  FIELD_HERO_IMAGE_LOCAL_CAPTION,
  FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
  FIELD_LOCAL_CAPTION,
  FIELD_LOCAL_COPYRIGHT,
} from '../common/commonFields';

export const FIELD_AUTHOR_NAME = 'authorName';
export const FIELD_AUTHOR_IMAGE = 'authorImage';
export const FIELD_TITLE = 'title';
export const FIELD_SECTION = 'section';
export const FIELD_ARTICLE_REF = 'articleRef';
export const FIELD_TEMPLATE = 'template';
export const FIELD_BODY = 'body';
export const FIELD_HEADLINE = 'headline';
export const FIELD_SUBHEADLINE = 'subHeadline';
export const FIELD_EDITION_TEMPLATES = 'pugpig_templates';
export const FIELD_EDITION_SECTIONS = 'pugpig_section';
export const FIELD_AUTHORS = 'authors';
export const FIELD_NOTES = 'notes';
export const FIELD_SLUG = 'slug';
export const FIELD_RELATED_ARTICLE = 'relatedArticle';
export const FIELD_AUTHOR_INFO = 'authorInfo';
export const FIELD_STAR_RATING = 'starRating';
export const FIELD_AWAY_SCORE = 'awayScore';
export const FIELD_AWAY_TEAM = 'awayTeam';
export const FIELD_AWAY_SCORER = 'awayScorers';
export const FIELD_HOME_SCORE = 'homeScore';
export const FIELD_HOME_TEAM = 'homeTeam';
export const FIELD_HOME_SCORER = 'homeScorers';

export const FIELD_HERO_IMAGE = 'heroImage';
export const FIELD_CONTENT_SOURCE = 'content_source';
export const FIELD_WORKFLOW = 'workflow';

export const IMAGE_OVERRIDE_PROPERTIES = {
  [FIELD_HERO_IMAGE_LOCAL_CAPTION]: FIELD_LOCAL_CAPTION,
  [FIELD_HERO_IMAGE_LOCAL_COPYRIGHT]: FIELD_LOCAL_COPYRIGHT,
};

export const PAYLOAD_FIELD_KEYS = {
  [PAGE_TYPE_ARTICLE]: [
    FIELD_HEADLINE,
    FIELD_SUBHEADLINE,
    FIELD_BODY,
    FIELD_HERO_IMAGE,
    FIELD_EDITION_TEMPLATES,
    FIELD_EDITION_SECTIONS,
    FIELD_AUTHORS,
    FIELD_NOTES,
    FIELD_SLUG,
    FIELD_CONTENT_SOURCE,
    FIELD_WORKFLOW,
  ],
  [PAGE_TYPE_ARTICLE_CUSTOM]: [],
  [PAGE_TYPE_SINGLE_IMAGE]: [
    FIELD_HEADLINE,
    FIELD_HERO_IMAGE,
    FIELD_EDITION_SECTIONS,
  ],
  [PAGE_TYPE_SECTION]: [
    FIELD_HEADLINE,
    FIELD_BODY,
    FIELD_TEMPLATE,
  ],
};

export const EXTRA_FIELD_KEYS = {
  [PAGE_TYPE_ARTICLE]: [
    FIELD_AUTHOR_INFO,
    FIELD_STAR_RATING,
    FIELD_RELATED_ARTICLE,
    FIELD_AWAY_SCORE,
    FIELD_AWAY_TEAM,
    FIELD_AWAY_SCORER,
    FIELD_HOME_SCORE,
    FIELD_HOME_TEAM,
    FIELD_HOME_SCORER,
    FIELD_HERO_IMAGE_LOCAL_CAPTION,
    FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
    FIELD_FYI,
  ],
  [PAGE_TYPE_ARTICLE_CUSTOM]: [],
  [PAGE_TYPE_SINGLE_IMAGE]: [
    FIELD_HERO_IMAGE_LOCAL_CAPTION,
    FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
  ],
  [PAGE_TYPE_SECTION]: [],
};

export const EDITION_FIELD_MAPPING = [
  [FIELD_HEADLINE, 'name', 'Headline'],
  [FIELD_SUBHEADLINE, 'field_pugpig_subheadline', 'Subheadline'],
  [FIELD_BODY, 'field_pugpig_body_json', 'body'],
  [FIELD_HERO_IMAGE, 'field_pugpig_hero_image', 'Hero Image'],
  [FIELD_HERO_IMAGE_LOCAL_CAPTION, FIELD_HERO_IMAGE_LOCAL_CAPTION, 'Hero Image Caption'],
  [FIELD_HERO_IMAGE_LOCAL_COPYRIGHT, FIELD_HERO_IMAGE_LOCAL_COPYRIGHT, 'Hero Image Copyright'],
  [FIELD_EDITION_SECTIONS, 'field_pugpig_home_section', 'Section'],
  [FIELD_SLUG, 'field_pugpig_slug', 'Slug'],
  [FIELD_NOTES, 'field_pugpig_notes', 'Notes'],
  [FIELD_EDITION_TEMPLATES, 'field_pugpig_template_type', 'Template'],
  [FIELD_AUTHORS, 'field_pugpig_author', 'Author(s)'],
  [FIELD_AUTHOR_INFO, FIELD_AUTHOR_INFO, 'Author(s) Info'],
  [FIELD_TEMPLATE, 'field_pugpig_sp_template_type', 'Template'],
  [FIELD_STAR_RATING, FIELD_STAR_RATING, 'Star Rating'],
  [FIELD_RELATED_ARTICLE, FIELD_RELATED_ARTICLE, 'Related Article'],
  [FIELD_AWAY_SCORE, FIELD_AWAY_SCORE, 'Away Score'],
  [FIELD_AWAY_TEAM, FIELD_AWAY_TEAM, 'Away Team'],
  [FIELD_AWAY_SCORER, FIELD_AWAY_SCORER, 'Away Scorer'],
  [FIELD_HOME_SCORE, FIELD_HOME_SCORE, 'Home Score'],
  [FIELD_HOME_TEAM, FIELD_HOME_TEAM, 'Home Team'],
  [FIELD_HOME_SCORER, FIELD_HOME_SCORER, 'Home Scorer'],
  [FIELD_CONTENT_SOURCE, 'field_pugpig_content_source', 'Content Source'],
  [FIELD_WORKFLOW, 'field_pugpig_workflow', 'Workflow'],
];
export const EDITION_FIELD_MAPPING_CMS_KEYED = keyFieldsForCMS(EDITION_FIELD_MAPPING);
export const EDITION_FIELD_MAPPING_API_KEYED = keyFieldsForAPI(EDITION_FIELD_MAPPING);

// This can be depricated once all fields are lockable
export const LOCKABLE_FIELDS = [
  FIELD_HEADLINE,
  FIELD_SUBHEADLINE,
  FIELD_BODY,
  FIELD_RELATED_ARTICLE,
  FIELD_AWAY_SCORE,
  FIELD_AWAY_TEAM,
  FIELD_AWAY_SCORER,
  FIELD_HOME_SCORE,
  FIELD_HOME_TEAM,
  FIELD_HOME_SCORER,
  FIELD_AUTHORS,
  FIELD_EDITION_SECTIONS,
  FIELD_AUTHOR_INFO,
  FIELD_SLUG,
];
