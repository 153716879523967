import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { getClassName } from '../../utils/propHelper';

const styles = () => ({
  img: {
    maxWidth: '100%',
    objectFit: 'contain',
    verticalAlign: 'bottom',
  },
});

const Image = ({ classes, className, data }) => (
  <img
    alt={data.caption}
    src={data.url}
    className={getClassName([classes.img, className])}
  />
);

Image.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: '',
};

export default withStyles(styles)(Image);

