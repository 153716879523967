import { ELEMENT_VIDEO, PROP_VIDEO, PROP_CAPTION } from './defaults';
import { PROP_LOCAL_CAPTION, PROP_VIDEOTYPE } from '../../../../../../constants/editor/dataTypes';

export const convertToVideo = (type, { [PROP_LOCAL_CAPTION]: localCaption, ...data }) => ({
  type: ELEMENT_VIDEO,
  [PROP_VIDEO]: {
    [PROP_VIDEOTYPE]: type,
    ...data,
  },
  [PROP_CAPTION]: localCaption || null,
  children: [{ text: '' }],
});

export const convertFromVideo = ({ [PROP_VIDEO]: video, [PROP_CAPTION]: caption }) => {
  const { [PROP_VIDEOTYPE]: type, ...data } = video || {};
  return ({
    type,
    data: {
      ...data,
      [PROP_LOCAL_CAPTION]: caption,
    },
  });
};
