import { ajax } from 'rxjs/ajax';
import { mergeDeepRight } from 'rambdax';
import { FIELD_NAME, FIELD_TITLE, FIELD_IMAGE, FIELD_URL, FIELD_TRACKING_TAG } from '../../constants/viafouraNotification/viafouraNotificationFields';
import ViafouraNotificationEntity from '../../entities/ViafouraNotificationEntity';
import apiCatchError from '../helper/notification';

export const sendViafouraNotificationRequest = (localState, serverState, config) => {
  const dataState = mergeDeepRight(serverState, localState);
  const formatUrl = new URL(dataState[FIELD_URL]);
  formatUrl.search = dataState[FIELD_TRACKING_TAG];

  const payload = {
    notification: {
      title: dataState[FIELD_TITLE],
      description: dataState[FIELD_NAME],
      image_url: dataState[FIELD_IMAGE],
      url: formatUrl.href,
      broadcast_type: 'site',
    },
    config,
  };
  return ajax.post('/api/viafoura-notification/send', payload, { 'Content-Type': 'application/json' }).pipe(
    apiCatchError(),
  );
};

export const saveViafouraNotificationRequest = (localState, serverState) => {
  const viafouraNotification = new ViafouraNotificationEntity();
  const payload = viafouraNotification.getPayloadFromData(localState, serverState);
  return ajax.post('/api/viafoura-notification', payload, { 'Content-Type': 'application/json' }).pipe(
    apiCatchError(),
  );
};
