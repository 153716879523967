import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import Grid from '@material-ui/core/Grid';
import React from 'react';

export const SortableHandle = sortableHandle;
export const SortableItem = SortableElement(props => <Grid {...props} />);
export const SortableRoot = SortableContainer(props => (<Grid {...props} />));
export const sortConfig = {
  axis: 'y',
  distance: 1,
};
