import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setLocalProp } from '../../../../actions/dataState';

import { PagePanel, PagePanelContent, PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';

import FieldLockContainer from '../../../fieldLock/FieldLockContainer';
import { FIELD_VERDICT } from '../../../../constants/article/articleFields';

import Editor, { VARIANT_REDUCED } from '../../../editor/Editor';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../../selectors/fieldLock/fieldLock';

const ProductVerdict = ({
  verdict,
  lockValue,
  hasChanges,
  setLocalProp: setProp,
}) => (
  <PagePanel>
    <FieldLockContainer field={FIELD_VERDICT} lockValue={lockValue}>
      <PagePanelHeading>
        <SectionTitle hasChanges={hasChanges}>Verdict</SectionTitle>
      </PagePanelHeading>
      <PagePanelContent noPadding>
        <Editor
          content={verdict}
          onChange={newValue => setProp(FIELD_VERDICT, newValue)}
          variant={VARIANT_REDUCED}
        />
      </PagePanelContent>
    </FieldLockContainer>
  </PagePanel>
);

ProductVerdict.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  verdict: PropTypes.array,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
};

ProductVerdict.defaultProps = {
  verdict: [],
  lockValue: null,
  hasChanges: false,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    verdict: getFieldValue(state, { field: FIELD_VERDICT }),
    lockValue: getFieldLockValue(state, { field: FIELD_VERDICT }),
    hasChanges: getFieldHasChanges(state, { field: FIELD_VERDICT }),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(ProductVerdict);
