import { jsx } from 'slate-hyperscript';

import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_CODE_BLOCK,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_LI,
  ELEMENT_LINK,
  ELEMENT_TABLE,
  ELEMENT_TR,
  ELEMENT_TD,
  ELEMENT_TH,
} from '@udecode/slate-plugins';
import { compose } from 'rambdax';

const ELEMENT_TAGS = {
  A: el => ({
    type: ELEMENT_LINK,
    url: el.getAttribute('href'),
    title: el.getAttribute('title'),
    rel: el.getAttribute('rel'),
  }),
  H1: () => ({ type: ELEMENT_H1 }),
  H2: () => ({ type: ELEMENT_H2 }),
  H3: () => ({ type: ELEMENT_H3 }),
  H4: () => ({ type: ELEMENT_H4 }),
  H5: () => ({ type: ELEMENT_H5 }),
  H6: () => ({ type: ELEMENT_H6 }),
  LI: () => ({ type: ELEMENT_LI }),
  OL: () => ({ type: ELEMENT_OL }),
  UL: () => ({ type: ELEMENT_UL }),
  P: () => ({ type: ELEMENT_PARAGRAPH }),
  PRE: () => ({ type: ELEMENT_CODE_BLOCK }),
  TABLE: () => ({ type: ELEMENT_TABLE }),
  TR: () => ({ type: ELEMENT_TR }),
  TD: () => ({ type: ELEMENT_TD }),
  TH: () => ({ type: ELEMENT_TH }),
};
const getLinkAttrs = (node) => {
  const attrs = [`href="${node.url}"`];
  if (node.title) {
    attrs.push(`title="${node.title}"`);
  }
  if (node.rel) {
    attrs.push(`rel="${node.rel}"`);
  }
  return attrs.join(' ');
};
const ELEMENT_MARKUP = {
  [ELEMENT_LINK]: (contents, node) => (`<a ${getLinkAttrs(node)}>${contents}</a>`),
  [ELEMENT_H1]: contents => (`<h1>${contents}</h1>`),
  [ELEMENT_H2]: contents => (`<h2>${contents}</h2>`),
  [ELEMENT_H3]: contents => (`<h3>${contents}</h3>`),
  [ELEMENT_H4]: contents => (`<h4>${contents}</h4>`),
  [ELEMENT_H5]: contents => (`<h5>${contents}</h5>`),
  [ELEMENT_H6]: contents => (`<h6>${contents}</h6>`),
  [ELEMENT_LI]: contents => (`<li>${contents}</li>`),
  [ELEMENT_OL]: contents => (`<ol>${contents}</ol>`),
  [ELEMENT_UL]: contents => (`<ul>${contents}</ul>`),
  [ELEMENT_PARAGRAPH]: contents => (`<p>${contents}</p>`),
  [ELEMENT_CODE_BLOCK]: contents => (`<pre>${contents}</pre>`),
  [ELEMENT_TABLE]: contents => (`<table>${contents}</table>`),
  [ELEMENT_TR]: contents => (`<tr>${contents}</tr>`),
  [ELEMENT_TD]: contents => (`<td>${contents}</td>`),
  [ELEMENT_TH]: contents => (`<th>${contents}</th>`),
};
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
  SUB: () => ({ subscript: true }),
  SUP: () => ({ superscript: true }),
};
const TEXT_MARKUP = {
  code: text => (`<code>${text}</code>`),
  strikethrough: text => (`<del>${text}</del>`),
  italic: text => (`<em>${text}</em>`),
  bold: text => (`<strong>${text}</strong>`),
  underline: text => (`<u>${text}</u>`),
  subscript: text => (`<sub>${text}</sub>`),
  superscript: text => (`<sup>${text}</sup>`),
};

/**
 * Add inline attributes to a Slate Node object
 * @param   {object} child  Slate Node object to add attributes to
 * @param   {object} attrs  Attributes to add to Node
 * @returns {object}        Slate Node
 */
const addAttrsToChildren = (child, attrs) => {
  if (child.children) {
    // eslint-disable-next-line no-param-reassign
    child.children = child.children.map((item) => {
      const itemWithAttrs = addAttrsToChildren(item, attrs);
      return { ...itemWithAttrs, ...attrs };
    });
  }
  return child;
};

/**
 * Convert a DOM Node into an array of Slate Node objects
 * @param   {Node} el   DOM Document or Node to convert
 * @returns {array|*}   Array of Slate Nodes or values for building the Slate Nodes
 */
export const deserializeNode = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === 'BR') {
    return '\n';
  }
  const { nodeName } = el;
  let parent = el;
  if (
    nodeName === 'PRE' &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === 'CODE'
  ) {
    parent = el.childNodes[0];
  }
  const children = Array.from(parent.childNodes)
    .map(deserializeNode)
    .flat()
    .filter(n => n);
  // catch for non html string and wrap in P
  if (nodeName === 'BODY' && el.innerHTML === el.innerText) {
    return jsx('element', ELEMENT_TAGS['P'](el), children);
  }
  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    // allow conversion of empty tags
    if (children.length < 1) {
      children.push({ text: '' });
    }
    // wrap text LI contents in P
    if (
      nodeName === 'LI' &&
      children.length === 1 &&
      (
        typeof children[0] === 'string' ||
        children[0].type !== ELEMENT_PARAGRAPH
      )
    ) {
      children[0] = jsx('element', ELEMENT_TAGS['P'](el), children);
    }
    return jsx('element', attrs, children);
  }
  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => {
      if (child.children) {
        return addAttrsToChildren(child, attrs);
      }

      return jsx('text', attrs, child);
    });
  }
  return children;
};

/**
 * Clean a html string
 *  remove whitespace between tags
 *  remove linebreaks and carriage returns
 * @param   {string} html   Markup to clean
 * @returns {string}        Cleaned Markup
 */
const cleanString = (html) => {
  if (!html) return '';
  let str = html.replace(/>\s+</g, '><');
  str = str.replace(/(\r\n|\n|\r)/gm, "");
  return str;
};

/**
 * Remove malformed deserialized blocks
 *  we don't want string or null results from the deserializeNode
 *  and no objects without a type
 * @param   {any} blocks    Result from deserializeNode
 * @returns {array|object}  Cleaned result or empty array
 */
const cleanDeserialized = (blocks) => {
  if (Array.isArray(blocks)) {
    return blocks.filter(block =>
      typeof block === 'object' &&
      block !== null &&
      block.type,
    );
  }
  if (
    typeof blocks === 'object' &&
    blocks !== null &&
    blocks.type
  ) {
    return blocks;
  }
  return [];
};

/**
 * Convert string markup into Slate Node(s) and cleans the results
 * @param   {string} html   Markup to convert
 * @returns {array|object}  Slate Node(s)
 */
export const deserializeString = (html) => {
  const parsed = new DOMParser().parseFromString(
    cleanString(html),
    'text/html',
  );
  return cleanDeserialized(deserializeNode(parsed.body));
};

/**
 * Convert Slate Node into markup
 * @param   {object} node   Slate Node
 * @returns {string}        Converted Markup
 */
export const serializeNode = (node) => {
  const { type, children, text } = node;
  if (text) {
    const textKeys = Object.keys(TEXT_MARKUP);
    let serializedText = text;
    textKeys.forEach((a) => {
      if (node[a]) {
        serializedText = TEXT_MARKUP[a](serializedText);
      }
    });
    return serializedText;
  }
  let contents = '';
  if (type === ELEMENT_LI && children.length === 1) {
    contents = serializeNodes(children[0].children);
  } else {
    contents = serializeNodes(children);
  }
  if (ELEMENT_MARKUP[type]) {
    return ELEMENT_MARKUP[type](contents, node);
  }
  return contents;
};

/**
 * Convert array of Slate Nodes into markup
 * @param   {array} nodes   Slate Nodes
 * @returns {string}        Converted markup
 */
export const serializeNodes = (nodes) => {
  if (!Array.isArray(nodes) || nodes.length < 1) return '';
  const markup = [];
  nodes.forEach((node) => {
    markup.push(serializeNode(node));
  });
  return markup.join('');
};

/**
 * Make sure string param is wrapped on html string
 * @param   {string} str  String Html to wrap
 * @returns {string|*}    Html string
 */
export const wrapWithHtmlString = (str = '') => {
  if (typeof str !== 'string') return '<p></p>';
  const html = str.trim();
  if (/^</.test(html)) {
    return html;
  }
  return `<p>${html}</p>`;
};

export const deserializePlainText = compose(deserializeString, wrapWithHtmlString);
