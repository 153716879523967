import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactEditor, useSlate } from 'slate-react';
import {
  ELEMENT_LINK,
  ToolbarButton,
  someNode,
  upsertLinkAtSelection,
  getAbove,
  getNodes,
  isCollapsed, ELEMENT_UL, ELEMENT_OL, unwrapNodes,
} from '@udecode/slate-plugins';
import { Transforms } from 'slate';

import LinkDialog from '../../dialog/LinkDialog';

const ToolbarLink = ({ allowBlogPost, ...props }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selection, setSelection] = useState(null);
  const [title, setTitle] = useState(null);
  const [path, setPath] = useState(null);
  const [nofollow, setNofollow] = useState(null);
  const editor = useSlate();
  return (
    <>
      <ToolbarButton
        active={someNode(editor, { match: { type: ELEMENT_LINK } })}
        onMouseDown={(e) => {
          e.preventDefault();
          const [linkNode] = getNodes(editor, { match: { type: ELEMENT_LINK } });
          if (linkNode) {
            setPath(linkNode[0].url);
            setTitle(linkNode[0].title);
            setNofollow(linkNode[0]?.rel === 'nofollow');
          }
          if (linkNode) {
            setSelection(linkNode[1]);
          } else {
            setSelection(editor.selection);
          }
          setOpenDialog(true);
          Transforms.deselect(editor);
        }}
        {...props}
      />
      {openDialog && <LinkDialog
        title={title}
        path={path}
        nofollow={nofollow}
        open={openDialog}
        allowBlogPost={allowBlogPost}
        onSelect={(link) => {
          Transforms.select(editor, selection);
          const linkNode = getAbove(editor, { match: { type: ELEMENT_LINK } });
          const insert = typeof linkNode !== 'undefined' && isCollapsed(editor.selection);
          const element = {
            type: ELEMENT_LINK,
            url: link.href,
            title: link.title,
            rel: link.nofollow ? 'nofollow' : '',
            children: insert ? [{ text: link.href }] : [],
          };
          if (insert) {
            Transforms.insertNodes(editor, element);
          } else {
            unwrapNodes(editor, { match: { type: ELEMENT_LINK }, at: editor.selection });
            Transforms.wrapNodes(editor, element, { split: true });
            Transforms.collapse(editor, { edge: 'end' });
          }
          setOpenDialog(false);
          setSelection(null);
          ReactEditor.focus(editor);
        }}
        handleClose={() => {
          setOpenDialog(false);
          setPath(null);
          setTitle(null);
          setNofollow(false);
          setSelection(null);
          ReactEditor.focus(editor);
        }}
      />}
    </>
  );
};

ToolbarLink.propTypes = {
  allowBlogPost: PropTypes.bool,
};

ToolbarLink.defaultProps = {
  allowBlogPost: false,
};

export default ToolbarLink;
