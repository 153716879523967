import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';
import VideoSelector from '../../../ui/media/VideoSelector';
import {
  HERO_VIDEO_JWPLAYER,
  MEDIA_IMAGE,
  VIDEO_JWPLAYER,
  VIDEO_TO_HERO_VIDEO,
} from '../../../../constants/media/media';
import ImageSelector from '../../../ui/media/ImageSelector';

const MEDIA = 'media';

const MediaSettings = (props) => {
  const {
    component,
    componentSetProp: setProp,
  } = props;
  const { id, [MEDIA]: media = [] } = component;
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  useEffect(() => {
    setImage(media.find(({ type }) => type === MEDIA_IMAGE) || null);
    setVideo(media.find(({ type }) => type === HERO_VIDEO_JWPLAYER) || null);
  }, [media]);
  const addMedia = (mediaType, data) => {
    setProp(id, MEDIA, [
      ...media.filter(({ type }) => type !== mediaType),
      {
        type: mediaType,
        data,
      },
    ]);
  };
  const removeMedia = (mediaType) => {
    setProp(id, MEDIA, [
      ...media.filter(({ type }) => type !== mediaType),
    ]);
  };
  return (
    <ContextPanel>
      <ContextPanelHeading>Media settings</ContextPanelHeading>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageSelector
              selectImage={imageData => addMedia(MEDIA_IMAGE, imageData)}
              onRemove={() => removeMedia(MEDIA_IMAGE)}
              image={image?.data || null}
            />
          </Grid>
          <Grid item xs={12}>
            <VideoSelector
              video={video?.data || null}
              validTypes={[VIDEO_JWPLAYER]}
              onSelected={({ provider, video: videoData }) => addMedia(VIDEO_TO_HERO_VIDEO[provider], {
                videoType: provider,
                ...videoData,
              })}
              onRemove={() => removeMedia(HERO_VIDEO_JWPLAYER)}
            />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

MediaSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(MediaSettings);
