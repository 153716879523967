import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';

import DraggableArticle from '../../ui/draggables/DraggableArticle';
import { optimiseImage } from '../../helper/media';

const styles = () => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    height: 60,
    margin: '8px 5px !important',
    boxSizing: 'border-box',
  },
  thumbnail: {
    flexShrink: 1,
    width: 80,
    margin: '-5px 5px -5px -9px',
  },
  title: {
    height: 50,
    display: '-webkit-box',
    lineHeight: 1.2,
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const getImageSrcFromHero = (heroMedia) => {
  if (!heroMedia) return null;
  const imageHero = heroMedia.filter(hero => hero.type === 'image');
  if (imageHero.length > 0) {
    return (imageHero[0].data.url) ? `${optimiseImage(imageHero[0].data.url, { width: 100 })}` : null;
  }
  return null;
};

const processDataObject = article => ({
  label: article.title,
  target_id: article.id,
  target_type: 'node',
  url: article.path,
  headline: article.titleShort !== null ? article.titleShort : article.title,
  lead: article.lead,
  heroImage: article.hero !== null ? article.hero.filter(({ type }) => type === 'image').shift() : null,
  section: article.sections !== null ? article.sections[0] : null,
});

const ArticleDraggable = ({ classes, article }) => {
  const { title, hero } = article;
  const imageSrc = getImageSrcFromHero(hero);
  return (
    <DraggableArticle
      className={classes.root}
      article={processDataObject(article)}
      title={title}
    >
      {imageSrc && <img className={classes.thumbnail} alt={'thumbnail'} src={imageSrc} />}
      <Typography className={classes.title} variant={'body2'} component={'h2'}>{title}</Typography>
    </DraggableArticle>
  );
};

ArticleDraggable.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticleDraggable);
