import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import TextField from '../../ui/TextField';
import Dialog from '../../dialog/common/Dialog';
import VideoJWPlayer from '../../embed/video/VideoJWPlayer';
import MarkupField from '../../ui/MarkupField';
import Button from '../../ui/buttons/Button';
import TagAutocomplete from '../../ui/TagAutocomplete';
import AutocompleteDialog from '../../dialog/autocomplete/AutocompleteDialog';
import { savePlayIncVideo } from '../../../actions/integration/playInc';
import { fetchVocab } from '../../../actions/vocab';
import { AUTHORS, SECTIONS } from '../../../constants/vocab';
import ToggleSwitch from '../../ui/ToggleSwitch';
import VideoDailyMotion from  '../../embed/video/VideoDailyMotion';

const styles = () => ({
  editor: {
    '& > div': {
      minHeight: 150,
    },
  },
});

const getPlaylists = (ids, playlists) => playlists
  .filter(({ playlistId }) => ids.includes(playlistId))
  .map(({ label, playlistId }) => ({ label, name: label, id: playlistId }));

const getBodyCount = (body) => {
  const div = document.createElement('div');
  div.innerHTML = body;
  const text = div.innerText;
  div.innerHTML = '';
  return text.split(' ').filter(str => str !== '').length;
};

const DRAFT = 'draft';
const PUBLISHED = 'published';

const DUGOUT = 'Dugout';
const DAILYMOTION = 'Dailymotion';

const PlayIncVideoEdit = (props) => {
  const {
    classes,
    handleClose,
    video,
    playInc,
    authors,
    sections,
    fetchVocab: onFetch,
    savePlayIncVideo: onSave,
    ...rest
  } = props;
  const { playlists = [] } = playInc;
  const { id, mediaid, mp4, type, dugoutEmbedHash } = video;
  const [open, setOpen] = useState(false);
  const [openSection, setOpenSection] = useState(false);
  const [bodyCount, setBodyCount] = useState(0);
  const [title, setTitle] = useState('');
  const [socialHeadline, setSocialHeadline] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [url, setUrl] = useState('');
  const [body, setBody] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [authorHandle, setAuthorHandle] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [sectionPath, setSectionPath] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [otherPlaylists, setOtherPlaylists] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [social, setSocial] = useState(true);
  const [synd, setSynd] = useState(false);
  const [status, setStatus] = useState(null);
  useEffect(() => {
    setTitle(video?.title || '');
    setSocialHeadline(video?.socialHeadline || '');
    setMetaTitle(video?.metaTitle || '');
    setUrl(video?.url || '');
    setBody(video?.description || '');
    setKeywords(video?.keywords || '');
    setAuthorName(video?.author || '');
    setAuthorHandle(video?.authorTwitterHandle || '');
    setSectionName(video?.sectionName || '');
    setSectionPath(video?.sectionPath || '');
    setSocial(!!video.sendToSocial);
    setSynd(!!video.excludeFromSyndication);
    setStatus(video?.status || null);
    if (video?.primaryPlaylistId) {
      const primaryPlaylist = getPlaylists([video.primaryPlaylistId], playlists);
      setPlaylist(primaryPlaylist.length > 0 ? primaryPlaylist[0] : '');
    } else {
      setPlaylist('');
    }
    if (video?.secondaryPlaylistIds) {
      const secondaryPlaylists = getPlaylists(video.secondaryPlaylistIds.split(','), playlists);
      setOtherPlaylists(secondaryPlaylists.length > 0 ? secondaryPlaylists : []);
    } else {
      setOtherPlaylists([]);
    }
  }, [video, playlists]);
  useEffect(() => {
    setBodyCount(getBodyCount(body));
  }, [body]);
  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query) {
      onFetch({
        q: query,
        vocab: AUTHORS,
        limit: 30,
        offset: 0,
      });
    }
  }, [query, onFetch]);
  const [querySection, setQuerySection] = useState('');
  useEffect(() => {
    if (querySection) {
      onFetch({
        q: querySection,
        vocab: SECTIONS,
        limit: 30,
        offset: 0,
      });
    }
  }, [querySection, onFetch]);
  return (
    <Dialog
      title={`Edit Video article (${status || 'draft'})`}
      maxWidth={'md'}
      handleClose={handleClose}
      margin
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {id && mp4 && type !== DUGOUT && type !== DAILYMOTION && <VideoJWPlayer
            video={{ mediaid, sources: [{ width: 480, file: mp4, type: 'video/mp4' }] }}
          />}
          {/* eslint-disable-next-line max-len */}
          {id && mp4 && type === DUGOUT && dugoutEmbedHash && <div dangerouslySetInnerHTML={{ __html: `<div style="margin:0 auto; min-width:100%; "><div style="position: relative; padding-bottom:56.25%; height: 0; overflow: hidden; "><iframe src="https://embed.dugout.com/v2/?p=${dugoutEmbedHash}" style="width: 100%; min-width: 100%; position: absolute; top:0; left: 0; height: 100%; overflow: hidden; " width="100%" frameborder="0" allowfullscreen="" scrolling="no"></iframe></div></div>` }} />}
          {id && mediaid && type === DAILYMOTION && <VideoDailyMotion
            video={{ id: mediaid }}
          />}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={title || ''}
                onChange={e => setTitle(e.target.value)}
                label={'Headline'}
                margin={'none'}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={socialHeadline || ''}
                onChange={e => setSocialHeadline(e.target.value)}
                label={'Social Headline'}
                margin={'none'}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={metaTitle || ''}
                onChange={e => setMetaTitle(e.target.value)}
                label={'Meta Title'}
                margin={'none'}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={url || ''}
                onChange={e => setUrl(e.target.value)}
                label={'Url'}
                margin={'none'}
                required
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MarkupField
            className={classes.editor}
            label={`Body${bodyCount > 0 ? ` (${bodyCount} words)` : ''}`}
            data={body || ''}
            onChange={value => setBody(value)}
            error={bodyCount < 80 || bodyCount > 100}
          />
        </Grid>
        <Grid item xs={4}>
          <TagAutocomplete
            label={'Playlist'}
            items={playlists.map(
              ({ label, playlistId }) => ({ label, name: label, id: playlistId }),
            )}
            selectedItems={playlist ? [playlist] : []}
            onRemove={() => setPlaylist('')}
            onSelect={item => setPlaylist(item)}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={8}>
          <TagAutocomplete
            label={'Other Playlists'}
            items={playlists.map(
              ({ label, playlistId }) => ({ label, name: label, id: playlistId }),
            )}
            selectedItems={otherPlaylists}
            onRemove={item => setOtherPlaylists(otherPlaylists.filter(
              playlistItem => playlistItem.id !== item.id,
            ))}
            onSelect={item => setOtherPlaylists([...otherPlaylists, item])}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={authorName || ''}
            onChange={e => setAuthorName(e.target.value)}
            label={'Author Name'}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={authorHandle || ''}
            onChange={e => setAuthorHandle(e.target.value)}
            label={'Author Handle'}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant={'outlined'}
            onClick={() => setOpen(true)}
          >Select Author</Button>
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={sectionName || ''}
            onChange={e => setSectionName(e.target.value)}
            label={'Section'}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={sectionPath || ''}
            onChange={e => setSectionPath(e.target.value)}
            label={'Section Path'}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant={'outlined'}
            onClick={() => setOpenSection(true)}
          >Select Section</Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={keywords || ''}
            onChange={e => setKeywords(e.target.value)}
            label={'Keywords'}
            margin={'none'}
          />
        </Grid>
        <Grid item xs={3}>
          <ToggleSwitch
            label={'Send to Social'}
            value={social}
            onChange={e => setSocial(e.target.checked)}
          />
        </Grid>
        <Grid item xs={3}>
          <ToggleSwitch
            label={'Exclude from Syndication'}
            value={synd}
            onChange={e => setSynd(e.target.checked)}
          />
        </Grid>
        <Grid spacing={2} item container direction={'row-reverse'}>
          <Grid item>
            <Button
              variant={'contained'}
              disabled={!title || !url}
              onClick={() => {
                onSave({
                  title,
                  body,
                  socialHeadline,
                  metaTitle,
                  url,
                  authorName,
                  authorHandle,
                  sectionPath,
                  sectionName,
                  playlist,
                  otherPlaylists,
                  keywords,
                  social,
                  synd,
                  status: PUBLISHED,
                }, id);
                handleClose();
              }}
            >Publish</Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              disabled={!title}
              onClick={() => {
                onSave({
                  title,
                  body,
                  socialHeadline,
                  metaTitle,
                  url,
                  authorName,
                  authorHandle,
                  sectionPath,
                  sectionName,
                  playlist,
                  otherPlaylists,
                  keywords,
                  social,
                  synd,
                  status: DRAFT,
                }, id);
                handleClose();
              }}
            >Unpublish</Button>
          </Grid>
        </Grid>
      </Grid>
      {open && <AutocompleteDialog
        handleClose={() => {
          setOpen(false);
        }}
        label={'Author'}
        open={open}
        items={authors?.items || []}
        onChange={value => setQuery(value)}
        onSelect={({ name, twitter }) => {
          setAuthorName(name);
          setAuthorHandle(twitter);
        }}
      />}
      {openSection && <AutocompleteDialog
        handleClose={() => {
          setOpenSection(false);
        }}
        hierarchical
        label={'Section'}
        open={openSection}
        items={sections?.items || []}
        onChange={value => setQuerySection(value)}
        onSelect={({ name, path }) => {
          setSectionName(name);
          setSectionPath(path);
        }}
      />}
    </Dialog>
  );
};

PlayIncVideoEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  fetchVocab: PropTypes.func.isRequired,
  savePlayIncVideo: PropTypes.func.isRequired,
  authors: PropTypes.object,
  sections: PropTypes.object,
  video: PropTypes.object,
  playInc: PropTypes.object,
};

PlayIncVideoEdit.defaultProps = {
  video: {},
  playInc: {},
  authors: {
    items: [],
  },
  sections: {
    items: [],
  },
};

export default withStyles(styles)(connect(
  ({ integration: { playInc }, vocab: { authors, sections } }) => ({ playInc, authors, sections }),
  { fetchVocab, savePlayIncVideo },
)(PlayIncVideoEdit));
