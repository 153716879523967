import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Close';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import ImageSelector from '../../../ui/media/ImageSelector';
import generateKey from '../../../../utils/generateKey';
import FlowCard from '../../../common/layout/FlowCard';
import IconButton from '../../../ui/buttons/IconButton';
import Button from '../../../ui/buttons/Button';
import TextField from '../../../ui/TextField';
import { FIELD_COMPONENTS } from '../../../../constants/layout/layoutFields';

const COMPONENT_KEY = 'carousel';

const styles = theme => ({
  cardItem: {
    paddingTop: 0,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
});

const setCarouselItem = () => ({
  image: null,
  link: '',
  id: generateKey(),
});

const CarouselItem = withStyles(styles)(({
  image, index, onRemove, id, total, classes, onChange, link,
}) => (<FlowCard className={classes.item}>
  <CardHeader
    titleTypographyProps={{ variant: 'subtitle1' }}
    subheaderTypographyProps={{ variant: 'caption' }}
    subheader={index}
    action={
      <Grid container justify="flex-end">
        {total > 1 && <IconButton onClick={() => onRemove(id)}>
          <RemoveIcon />
        </IconButton>}
      </Grid>
    }
  />
  <CardContent className={classes.cardItem}>
    <TextField
      margin="dense"
      defaultValue={link}
      onChange={({ target: { value } }) => onChange({
        id, link: value,
      })}
      label="Link target"
    />
    <ImageSelector
      selectImage={selectedImage => onChange({
        id, image: selectedImage,
      })}
      onRemove={() => onChange({
        id, image: null,
      })}
      label="Add image"
      image={image}
      showEditDialog
    />
  </CardContent>
</FlowCard>));

CarouselItem.defaultProps = {
  image: null,
  link: '',
};

CarouselItem.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  link: PropTypes.string,
  total: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
};

const CarouselField = (props) => {
  const {
    component,
    componentSetProp: setProp,
    localComponents,
    classes,
  } = props;

  const { id: compId } = component;
  const items = component[COMPONENT_KEY] || [];

  useEffect(() => {
    if (items.length === 0) {
      const localComp = localComponents?.[compId] || {};
      if (!Array.isArray(localComp[COMPONENT_KEY])) {
        setProp(
          compId,
          COMPONENT_KEY,
          [setCarouselItem()],
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, compId, setProp]);

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Carousel items</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails className={classes.cardItem} variant={'full'}>
        {items?.map(({ id, ...properties }, index) => (<CarouselItem
          index={index + 1}
          total={items.length}
          id={id}
          key={id}
          {...properties}
          onChange={(data) => {
            setProp(
              compId,
              COMPONENT_KEY,
              items.map((item) => {
                if (item.id === data.id) {
                  return {
                    ...item,
                    ...data,
                  };
                }
                return item;
              }),
            );
          }}
          onRemove={excluded => setProp(
            compId,
            COMPONENT_KEY,
            items.filter(({ id: itemId }) => itemId !== excluded),
          )}
        />))}
        <div>
          <Button
            variant="outlined"
            onClick={() => setProp(compId, COMPONENT_KEY, [...items, setCarouselItem()])}
            startIcon={<AddIcon />}
          >Add item</Button>
        </div>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

CarouselField.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  localComponents: PropTypes.object,
};

CarouselField.defaultProps = {
  localComponents: [],
};

export default withStyles(styles)(connect(
  ({ localState: { [FIELD_COMPONENTS]: localComponents } }) => ({ localComponents }),
  { componentSetProp },
)(CarouselField));
