import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_TWITTER } from './defaults';

const renderElementTwitter = (options) => {
  const { twitter } = setDefaults(options, DEFAULTS_TWITTER);
  return getRenderElement(twitter);
};

export default renderElementTwitter;
