import { setDefaults } from '@udecode/slate-plugins';
import deserializeIframe from './deserializeIframe';
import renderElementIframe from './renderElementIframe';
import { DEFAULTS_IFRAME } from './defaults';

const IframePlugin = (options) => {
  const { iframe } = setDefaults(options, DEFAULTS_IFRAME);
  return ({
    renderElement: renderElementIframe(options),
    deserialize: deserializeIframe(options),
    voidTypes: [iframe.type],
  });
};

export default IframePlugin;
