import React from 'react';
import Grid from '@material-ui/core/Grid';

import { PagePanel, PagePanelContent } from '../common/page/PagePanel';
import FieldLockTextfield from '../fieldLock/form/FieldLockTextfield';
import FieldLockEditor from '../fieldLock/form/FieldLockEditor';
import FieldLockImage from '../fieldLock/form/FieldLockImage';
import {
  FIELD_NAME, FIELD_DESCRIPTION,
  FIELD_HERO_IMAGE, FIELD_THUMBNAIL,
  FIELD_SPEAKERS, FIELD_SPEAKERS_ORDER, FIELD_HOSTS_ORDER, FIELD_HOSTS,
} from '../../constants/event/eventFields';
import FieldLockSortable from '../fieldLock/form/FieldLockSortable';
import EventSpeaker from './eventContent/EventSpeaker';

const EventMain = () => (
  <>
    <Grid item xs={12}>
      <PagePanel>
        <PagePanelContent noHeading>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FieldLockTextfield field={FIELD_NAME} label={'Name'} placeholder={'Type your event\'s name here.'} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems={'flex-start'} wrap={'nowrap'}>
                <Grid item xs={3}>
                  <FieldLockImage
                    field={FIELD_HERO_IMAGE}
                    label={'Add hero image'}
                    hasCaption
                    hasCopyright
                  />
                </Grid>
                <Grid item xs={3}>
                  <FieldLockImage field={FIELD_THUMBNAIL} label={'Add thumbnail'} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PagePanelContent>
      </PagePanel>
    </Grid>
    <Grid item xs={12}>
      <PagePanel>
        <FieldLockEditor field={FIELD_DESCRIPTION} label={'Description'} panel required />
      </PagePanel>
    </Grid>
    <Grid item xs={12}>
      <PagePanel>
        <FieldLockSortable
          field={FIELD_HOSTS}
          fieldOrder={FIELD_HOSTS_ORDER}
          label={'Host(s)'}
          labelSingle={'host'}
          Component={EventSpeaker}
          panel
        />
      </PagePanel>
    </Grid>
    <Grid item xs={12}>
      <PagePanel>
        <FieldLockSortable
          field={FIELD_SPEAKERS}
          fieldOrder={FIELD_SPEAKERS_ORDER}
          label={'Speaker(s)'}
          labelSingle={'speaker'}
          Component={EventSpeaker}
          panel
        />
      </PagePanel>
    </Grid>
  </>
);

export default EventMain;
