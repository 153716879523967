import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import {
  FIELD_BADGE_IMAGE,
  FIELD_BADGE_LINK, FIELD_BADGE_LOGO,
  FIELD_BADGE_PREFIX,
  FIELD_BADGE_TITLE,
} from 'constants/article/articleFields';
import { SPONSOR_PREFIX } from 'constants/vocab';
import { sanitiseTerm } from 'utils/sanitiser';
import { setLocalProp, unsetLocalProp } from 'actions/dataState';
import FieldLockImage from 'components/fieldLock/form/FieldLockImage';
import FieldLockTextfield from 'components/fieldLock/form/FieldLockTextfield';
import FieldLockVocabSelector from 'components/fieldLock/form/FieldLockVocabSelector';
import ToggleSwitch from 'components/ui/ToggleSwitch';
import { VARIANT_CONTEXT } from 'components/ui/TagSelector';

const ArticleCommercialBadge = ({
  setLocalProp: setProp,
  unsetLocalProp: unsetProp,
  badgeImage,
  badgeLink,
  badgePrefix,
  badgeTitle,
  badgeLogo,
}) => {
  const [showBadge, setShowBadge] = useState(false);
  useEffect(() => {
    setShowBadge(badgeLink || badgePrefix || badgeImage || badgeTitle || badgeLogo);
  }, [badgeLink, badgePrefix, badgeImage, badgeTitle, badgeLogo]);

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <ToggleSwitch
            label={'Badge'}
            value={!!showBadge}
            onChange={(e) => {
              setShowBadge(e.target.checked);
              if (!e.target.checked) {
                setProp(FIELD_BADGE_PREFIX, null);
                setProp(FIELD_BADGE_IMAGE, null);
                setProp(FIELD_BADGE_LINK, '');
                setProp(FIELD_BADGE_LOGO, '');
                setProp(FIELD_BADGE_LINK, '');
              }
            }}
          />
        </FormControl>
      </Grid>
      {showBadge && <>
        <Grid item xs={12}>
          <FieldLockVocabSelector
            field={FIELD_BADGE_PREFIX}
            vocab={SPONSOR_PREFIX}
            selectedTerms={badgePrefix ? [badgePrefix] : []}
            label={'Badge prefix'}
            onSelect={([, value]) =>
              setProp(FIELD_BADGE_PREFIX, sanitiseTerm(value))}
            onRemove={() => unsetProp(FIELD_BADGE_PREFIX)}
            hideSuggestions
            variant={VARIANT_CONTEXT}
          />
        </Grid>
        <Grid item xs={12}><FieldLockTextfield field={FIELD_BADGE_TITLE} label={'Badge title'} /></Grid>
        <Grid item xs={12}><FieldLockImage field={FIELD_BADGE_IMAGE} label={'Add badge image'} /></Grid>
        {badgeLogo && <Grid item xs={12}><FieldLockTextfield field={FIELD_BADGE_LOGO} label={'Badge logo'} /></Grid>}
        <Grid item xs={12}><FieldLockTextfield field={FIELD_BADGE_LINK} label={'Badge link'} /></Grid>
      </>}
    </>
  );
};

ArticleCommercialBadge.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  unsetLocalProp: PropTypes.func.isRequired,
  badgeImage: PropTypes.object,
  badgeLink: PropTypes.string,
  badgePrefix: PropTypes.object,
  badgeTitle: PropTypes.string,
  badgeLogo: PropTypes.string,
};

ArticleCommercialBadge.defaultProps = {
  badgePrefix: null,
  badgeImage: null,
  badgeLink: null,
  badgeTitle: null,
  badgeLogo: null,
};

export default connect(
  ({
    dataState: {
      [FIELD_BADGE_IMAGE]: badgeImage,
      [FIELD_BADGE_LINK]: badgeLink,
      [FIELD_BADGE_PREFIX]: badgePrefix,
      [FIELD_BADGE_TITLE]: badgeTitle,
      [FIELD_BADGE_LOGO]: badgeLogo,
    },
  }) => ({ badgeImage, badgeLink, badgePrefix, badgeTitle, badgeLogo }),
  { setLocalProp, unsetLocalProp },
)(ArticleCommercialBadge);

