import PriceComparisonElement from './components/PriceComparisonElement';

export const ELEMENT_PRICE_COMPARISON = 'priceComparison';
export const PROP_TITLE = 'title';
export const PROP_EMBED_ID = 'embedId';

export const DEFAULTS_PRICE_COMPARISON = {
  priceComparison: {
    component: PriceComparisonElement,
    type: ELEMENT_PRICE_COMPARISON,
    rootProps: {
      className: 'slate-price-comparison',
    },
  },
};
