import { ofType } from 'redux-observable';
import { REHYDRATE } from 'redux-persist/es/constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  combineLatest, tap, mapTo,
} from 'rxjs/operators';

import {
  OPEN_NEW_TAB, OPEN_NEW_TAB_SUCCESS,
  SCROLL_TO_TOP,
} from '../../constants/actionTypes';

const onLocationChange = (action$) => {
  const rehydrate$ = action$.pipe(
    ofType(REHYDRATE),
  );
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    combineLatest(rehydrate$, locationChange$ => locationChange$),
    tap(() => window.scrollTo(0, 0)),
    mapTo({
      type: SCROLL_TO_TOP,
    }),
  );
};
export const openOnNewTab = action$ => action$.pipe(
  ofType(OPEN_NEW_TAB),
  tap(({ value }) => {
    try {
      window.open(value);
    } catch (ex) {
      console.error(ex);
    }
  }),
  mapTo({ type: OPEN_NEW_TAB_SUCCESS }),
);


export default onLocationChange;
