import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';

import { saveGallery } from '../../actions/media';

import { PageToolbarButtons } from '../common/page/PageToolbar';
import Button from '../ui/buttons/Button';

import { FIELD_IMAGES_ORDER } from '../../constants/media/galleryFields';

const GalleryControlButtons = ({
  saveGallery: doSave,
  imagesOrder,
  localState,
}) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    setHasChanges(Object.entries(localState).length > 0);
  }, [localState]);
  useEffect(() => {
    if (imagesOrder) {
      setImageCount(imagesOrder.length);
    } else {
      setImageCount(0);
    }
  }, [imagesOrder]);

  return (
    <PageToolbarButtons>
      <Typography variant={'body2'}>Image count: {imageCount}</Typography>
      <Button
        disabled={!hasChanges}
        onClick={() => doSave()}
        variant={'contained'}
        startIcon={<SaveIcon />}
      >Save Gallery</Button>
    </PageToolbarButtons>
  );
};

GalleryControlButtons.propTypes = {
  saveGallery: PropTypes.func.isRequired,
  imagesOrder: PropTypes.array,
  localState: PropTypes.object,
};

GalleryControlButtons.defaultProps = {
  imagesOrder: [],
  localState: {},
};

export default connect(
  ({
    dataState: {
      [FIELD_IMAGES_ORDER]: imagesOrder,
    },
    localState,
  }) => (
    {
      imagesOrder,
      localState,
    }
  ),
  { saveGallery },
)(GalleryControlButtons);
