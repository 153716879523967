import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { capitalize } from '@material-ui/core';

import {
  FIELD_PRICE,
  FIELD_PRICE_COMPARISON,
  FIELD_PRICE_COMPARISON_PROVIDER,
  FIELD_PRODUCT_ID,
  FIELD_PRODUCT_ID_TYPE,
} from '../../../constants/product/productFields';
import { FIELD_PRODUCTS } from '../../../constants/article/articleFields';

import { setItemPropOnLocal } from '../../../actions/dataState';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import TextField from '../../ui/TextField';
import Select from '../../ui/Select';
import PriceComparison from '../../product/PriceComparison';

const ProductPrice = (props) => {
  const {
    product,
    index,
    setItemPropOnLocal: setLocalProp,
    publicationConfig,
  } = props;
  const {
    [FIELD_PRICE]: price,
    [FIELD_PRICE_COMPARISON]: priceComparison,
    [FIELD_PRICE_COMPARISON_PROVIDER]: priceComparisonProvider,
    [FIELD_PRODUCT_ID_TYPE]: productIdType,
    [FIELD_PRODUCT_ID]: productId,
  } = product.data || {};

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Price Comparison</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              value={price || 0}
              inputProps={{
                type: 'number',
                step: '0.01',
              }}
              label={'Price'}
              onChange={e => setLocalProp(FIELD_PRODUCTS, index, FIELD_PRICE, e.target.value)}
              margin={'none'}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={priceComparison || ''}
              label={'Price comparison'}
              onChange={e =>
                setLocalProp(FIELD_PRODUCTS, index, FIELD_PRICE_COMPARISON, e.target.value)
              }
              margin={'none'}
            />
          </Grid>
          <Grid item xs={12} />
          {publicationConfig.priceComparisonProviders &&
          publicationConfig.priceComparisonProviders.length > 0 &&
          <Grid item xs={12}>
            <Select
              value={priceComparisonProvider || publicationConfig.priceComparisonProviders[0]}
              label={'Comparison provider'}
              items={publicationConfig.priceComparisonProviders
                .map(p => ({ id: p, name: capitalize(p) }))}
              onChange={e => setLocalProp(
                FIELD_PRODUCTS,
                index,
                FIELD_PRICE_COMPARISON_PROVIDER,
                e.target.value)}
              margin={'none'}
            />
          </Grid>}
          <Grid item xs={4}>
            <Select
              label={'ID Type'}
              value={productIdType || ''}
              items={[
                'ASIN',
                'EAN',
                'MPN',
                'SKU',
                'UPC',
              ]}
              onChange={e =>
                setLocalProp(FIELD_PRODUCTS, index, FIELD_PRODUCT_ID_TYPE, e.target.value)
              }
              margin={'none'}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              value={productId || ''}
              onChange={e => setLocalProp(FIELD_PRODUCTS, index, FIELD_PRODUCT_ID, e.target.value)}
              label={'Product Id'}
              margin={'none'}
            />
          </Grid>
          {priceComparison && <Grid item xs={12}>
            <PriceComparison link={priceComparison} />
          </Grid>}
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ProductPrice.propTypes = {
  setItemPropOnLocal: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(
  ({ frame: {
    selectedPublication: {
      publicationConfig,
    },
  },
  }) => ({ publicationConfig }),
  { setItemPropOnLocal },
)(ProductPrice);
