import { ofType } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { debounceTime, delay, filter, map, merge, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { ajax } from 'rxjs/ajax';

import {
  SET_DEFAULT_ARTICLE_TYPE,
  SET_DEFAULT_CONTENT_SOURCE,
  SET_DEFAULT_EDITION_SECTION,
  SET_VOCAB,
  TERM_FETCH_SECTION_TREE,
  TERM_FETCH_SECTION_TREE_REJECTED,
  TERM_FETCH_SECTION_TREE_SUCCESS,
  TERM_SECTION_TREE_FLATTENED,
  TERM_SECTION_TREE_MENU,
} from '../../constants/actionTypes';

import apiCatchError, { showSuccessNotification } from '../helper/notification';

import { reformatDataForMenu } from '../helper/utils';
import {
  ARTICLE_TYPE,
  AUTHORS,
  CHAIN,
  CONTENT_SOURCE,
  EDITION_SECTION, EDITION_TEMPLATES, EVENTS, PEOPLE,
  SECTIONS, SPONSOR_PREFIX, TAGS, TEAMS, TOPICS,
  VOCAB_LAYOUTS,
} from '../../constants/vocab';
import { TAXONOMY_EXTRA_FIELDS } from '../../constants/taxonomy';
import {
  VOCAB_DELETE_SUCCESS,
  VOCAB_FETCH,
  VOCAB_FETCH_LAYOUT,
  VOCAB_FETCH_TERM,
  VOCAB_FETCH_TERM_REJECTED,
  VOCAB_FETCH_TERM_SUCCESS,
  VOCAB_SAVE,
  VOCAB_SAVE_REJECTED,
  VOCAB_SAVE_SUCCESS,
  VOCAB_UPDATE_MULTIPLE,
} from '../../constants/actionTypes/vocab';
import { WS_VOCAB_LIST } from '../../constants/actionTypes/ws';
import { serialize } from '../../utils/urlHelper';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

// find default value for content source
export const defaultValueForContentSource = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  withLatestFrom(state$, (a, b) => b),
  filter(({ frame: { selectedPublication: { vocabs } } }) => vocabs.includes(CONTENT_SOURCE)),
  switchMap(({ frame: { selectedPublication: { domain } } }) => {
    const matchInternal = ({ name }) => /^internal$/i.test(name);
    return ajax.getJSON(`/api/vocab/${CONTENT_SOURCE}/${domain}?q=internal`).pipe(
      filter(({ items }) => items?.some(matchInternal)),
      map(({ items }) => ({
        type: SET_DEFAULT_CONTENT_SOURCE,
        value: items.find(matchInternal),
      })),
      apiCatchError(),
    );
  }),
);

export const defaultValueForArticleType = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  withLatestFrom(state$, (a, b) => b),
  filter(({ frame: { selectedPublication: { vocabs } } }) => vocabs.includes(ARTICLE_TYPE)),
  switchMap(({ frame: { selectedPublication: { domain } } }) => {
    const matchNews = ({ name }) => /^news$/i.test(name);
    return ajax.getJSON(`/api/vocab/${ARTICLE_TYPE}/${domain}?q=news`).pipe(
      filter(({ items }) => items?.some(matchNews)),
      map(({ items }) => ({
        type: SET_DEFAULT_ARTICLE_TYPE,
        value: items.find(matchNews),
      })),
      apiCatchError(),
    );
  }),
);

export const defaultValueForEditionSection = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  withLatestFrom(state$, (a, b) => b),
  filter(({ frame: { selectedPublication: { vocabs } } }) => vocabs.includes(EDITION_SECTION)),
  switchMap(({ frame: { selectedPublication: { domain } } }) => {
    const matchNews = ({ name }) => /^news$/i.test(name);
    return ajax.getJSON(`/api/vocab/${EDITION_SECTION}/${domain}?q=news`).pipe(
      filter(({ items }) => items?.some(matchNews)),
      map(({ items }) => ({
        type: SET_DEFAULT_EDITION_SECTION,
        value: items.find(matchNews),
      })),
      apiCatchError(),
    );
  }),
);

export const saveNewTerm = (action$, state$) => action$.pipe(
  ofType(VOCAB_SAVE),
  withLatestFrom(state$),
  switchMap(([{ value: [vocab, data] }, { frame: { selectedPublication: { sectionRoot } } }]) => {
    const extra = {};
    const payload = {
      vid: [
        {
          target_id: vocab,
        },
      ],
      name: [{
        value: data.name,
      }],
    };

    if (vocab === 'sponsor_prefix') {
      payload.field_sponsor_url = [{
        value: data.url,
      }];
    }

    if (vocab === 'sections') {
      let parent = data.parents.length > 0 ? data.parents[0].tid : sectionRoot.id;
      parent = parseInt(parent, 10);
      // if the current term is root section, set parent to zero
      payload.parent = [{
        target_id: parseInt(data.id, 10) !== parseInt(sectionRoot.id, 10) ? parent : 0,
      }];
      if (data.weight) {
        payload.weight = [{
          value: data.weight,
        }];
      }
      payload.field_source_path = {
        value: data.slug.replace(/\s/g, '-').toLowerCase(),
      };
      TAXONOMY_EXTRA_FIELDS.forEach((key) => {
        extra[key] = data[key] || false;
      });
    }

    if (data.titleSEO) {
      extra.titleSEO = data.titleSEO;
    }

    if (data.seoDescription) {
      extra.seoDescription = data.seoDescription;
    }

    if (Object.keys(extra).length > 0) {
      payload.field_term_extra_json = [{
        value: JSON.stringify(extra),
      }];
    }

    if (data.id) {
      return ajax.patch(`/api/term/${vocab}/${data.id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        map(({ response }) => ({
          type: VOCAB_SAVE_SUCCESS,
          value: response,
        })),
        apiCatchError(VOCAB_SAVE_REJECTED),
      );
    }

    return ajax.post(`/api/term/${vocab}`, payload, { 'Content-Type': 'application/json' }).pipe(
      map(({ response }) => ({
        type: VOCAB_SAVE_SUCCESS,
        value: response,
      })),
      apiCatchError(VOCAB_SAVE_REJECTED),
    );
  }),
);

export const fetchTerm = action$ => action$.pipe(
  ofType(VOCAB_FETCH_TERM),
  switchMap(({ value: [vocab, id] }) =>
    ajax.getJSON(`/api/term/${vocab}/${id}`).pipe(
      map((response) => {
        const fieldValue = (field) => {
          try {
            return field[0].value;
          } catch (ex) {
            return null;
          }
        };

        if (vocab === SECTIONS) {
          const {
            name,
            field_term_extra_json: extra,
            field_source_path: sourcePath,
            field_source_name: sourceName,
            path,
          } = response;
          return {
            type: VOCAB_FETCH_TERM_SUCCESS,
            value: {
              extra: extra.length > 0 ? JSON.parse(extra[0].value) : null,
              name: fieldValue(name),
              path: path[0].alias,
              sourceName: fieldValue(sourceName),
              sourcePath: fieldValue(sourcePath),
            },
          };
        }

        if (vocab === AUTHORS) {
          const {
            name,
            description,
            field_term_extra_json: extra,
            field_author_email: email,
            path,
            field_author_facebook: facebook,
            field_author_image: imageUrl,
            field_author_location: location,
            field_author_twitter: twitter,
          } = response;

          return {
            type: VOCAB_FETCH_TERM_SUCCESS,
            value: {
              description: description[0].value,
              email: fieldValue(email),
              facebook: fieldValue(facebook),
              imageUrl: fieldValue(imageUrl),
              location: fieldValue(location),
              twitter: fieldValue(twitter),
              extra: extra && extra.length > 0 ? JSON.parse(extra[0].value) : null,
              name: fieldValue(name),
              path: fieldValue(path),
            },
          };
        }

        const {
          name,
          field_term_extra_json: extra,
        } = response;

        return ({
          type: VOCAB_FETCH_TERM_SUCCESS,
          value: {
            name: fieldValue(name),
            extra: extra && extra.length > 0 ? JSON.parse(extra[0].value) : null,
          },
        });
      }),
      apiCatchError(VOCAB_FETCH_TERM_REJECTED),
    ),
  ),
);

export const updateVocabMultiple = action$ => action$.pipe(
  ofType(VOCAB_UPDATE_MULTIPLE),
  mergeMap(({ value: [vocab, termIds, state] }) => {
    const patches = termIds.map((id) => {
      const payload = {
        vid: [
          {
            target_id: vocab,
          },
        ],
        parent: state.parents.map(({ id: termId, tid: treeTermId }) => ({
          target_id: termId || treeTermId,
          target_type: 'taxonomy_term',
        })),
      };
      return ajax.patch(`/api/term/${vocab}/${id}`, payload, { 'Content-Type': 'application/json' });
    });

    return forkJoin(patches).pipe(
      map(() => ({
        type: VOCAB_SAVE_SUCCESS,
      })),
      apiCatchError(VOCAB_SAVE_REJECTED),
    );
  }),
);

export const termSaveNotification = action$ => action$.pipe(
  ofType(VOCAB_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('This term is successfully saved.')),
);

export const termDeleteNotification = action$ => action$.pipe(
  ofType(VOCAB_DELETE_SUCCESS),
  mergeMap(showSuccessNotification('This term is successfully deleted.')),
);

function flatDeep(arr) {
  return arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val) : val), []);
}

function flatten(data, deep = 0) {
  const items = Object.keys(data);
  const newArr = [];
  items.forEach((key) => {
    newArr.push({ ...data[key], deep });
    if (!Array.isArray(data[key].children)) {
      newArr.push(flatten(data[key].children, deep + 1));
    }
  });
  return newArr;
}

export const flattenedSectionTree = action$ => action$.pipe(
  ofType(TERM_FETCH_SECTION_TREE_SUCCESS),
  map(({ value }) => ({
    type: TERM_SECTION_TREE_FLATTENED,
    value: flatDeep(flatten(value)),
  })),
);

export const sectionTreeMenu = action$ => action$.pipe(
  ofType(TERM_FETCH_SECTION_TREE_SUCCESS),
  map(({ value }) => ({
    type: TERM_SECTION_TREE_MENU,
    value: reformatDataForMenu(value),
  })),
);

export const fetchSectionTree = (action$, state$) => action$.pipe(
  ofType(TERM_FETCH_SECTION_TREE),
  merge(action$.pipe(
    ofType(WS_VOCAB_LIST),
    delay(500), // no reliable solution to wait for the ES after update for now
    filter(({ value: { vocab } }) => vocab === SECTIONS),
  )),
  withLatestFrom(state$, (a, b) => b),
  switchMap(({ frame: { selectedPublication: { domain } } }) => ajax.getJSON(`/api/vocab-tree/sections/${domain}`, { 'Content-Type': 'application/json' }).pipe(
    map(response => ({
      type: TERM_FETCH_SECTION_TREE_SUCCESS,
      value: response,
    })),
    apiCatchError(TERM_FETCH_SECTION_TREE_REJECTED),
  )),
);

/**
 * Map fetch to vocab type specific pipe to switchMap individually
 */
export const fetchVocab = (action$, state$) => action$.pipe(
  ofType(VOCAB_FETCH, WS_VOCAB_LIST),
  withLatestFrom(state$),
  filter(([{ value: { vocab } }, { frame: { selectedPublication } }]) =>
    vocab && selectedPublication?.domain),
  mergeMap(([{ value }]) => of({
    type: `${VOCAB_FETCH}_${value.vocab.toUpperCase()}`,
    value,
  })),
);

const fetchVocabByType = type => (action$, state$) => {
  return action$.pipe(
    ofType(`${VOCAB_FETCH}_${type.toUpperCase()}`),
    debounceTime(500),
    withLatestFrom(state$),
    switchMap(([
      { value: { vocab, noLayout = false, ...params } },
      { frame: { selectedPublication: { domain } }, layout },
    ]) => {
      const actions = [];
      if (
        !noLayout &&
        VOCAB_LAYOUTS[vocab] &&
        layout[VOCAB_LAYOUTS[vocab]] &&
        layout[VOCAB_LAYOUTS[vocab]].length === 0
      ) {
        actions.push({
          type: VOCAB_FETCH_LAYOUT,
          value: vocab,
        });
      }
      const qs = params || null;
      let publication = domain;
      if (qs.publicationSource) {
        publication = qs.publicationSource;
        delete qs.publicationSource;
      }
      const affix = qs ? `?${serialize(qs)}` : '';
      return ajax.getJSON(`/api/vocab/${vocab}/${publication}${affix}`)
        .pipe(
          mergeMap(response => from([{ type: SET_VOCAB, value: [vocab, response] }, ...actions])),
          apiCatchError(),
        );
    }),
  );
};

export const fetchSection = fetchVocabByType(SECTIONS);
export const fetchChain = fetchVocabByType(CHAIN);
export const fetchAuthors = fetchVocabByType(AUTHORS);
export const fetchEvents = fetchVocabByType(EVENTS);
export const fetchPeople = fetchVocabByType(PEOPLE);
export const fetchTeams = fetchVocabByType(TEAMS);
export const fetchTopics = fetchVocabByType(TOPICS);
export const fetchTags = fetchVocabByType(TAGS);
export const fetchArticleType = fetchVocabByType(ARTICLE_TYPE);
export const fetchEditionSection = fetchVocabByType(EDITION_SECTION);
export const fetchEditionTemplates = fetchVocabByType(EDITION_TEMPLATES);
export const fetchContentSource = fetchVocabByType(CONTENT_SOURCE);
export const fetchSponsorPrefix = fetchVocabByType(SPONSOR_PREFIX);
