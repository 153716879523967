import React from 'react';
import PropTypes from 'prop-types';

import { ContextPanelDetailsSection } from '../../../../common/context/ContextPanel';

import TextField from '../../../../ui/TextField';
import ArticleRef from './common/ArticleRef';
import ImageSelector from '../../../../ui/media/ImageSelector';

const FIELD_HEADLINE = 'headline';
const FIELD_IMAGE = 'image';
const FIELD_CAPTION = 'caption';
const FIELD_ARTICLE_REF = 'articleRef';

const ArticleHero = (props) => {
  const {
    component: { headline, image, caption, articleRef },
    delta, setProperty,
  } = props;
  const assignImage = (data) => {
    setProperty({
      delta,
      prop: FIELD_IMAGE,
      value: {
        type: 'image',
        data,
      },
    });
    setProperty({
      delta,
      prop: FIELD_CAPTION,
      value: data?.caption || '',
    });
  };
  return (
    <ContextPanelDetailsSection>
      <TextField
        label="Headline"
        value={headline || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE,
          value: event.target.value,
        })}
      />
      <ImageSelector
        selectImage={assignImage}
        onRemove={() => assignImage(null)}
        label="Add image"
        image={image?.data}
        showEditDialog
      />
      <TextField
        label="Caption"
        value={caption || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_CAPTION,
          value: event.target.value,
        })}
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={value => setProperty({
          delta,
          prop: FIELD_ARTICLE_REF,
          value: value ? {
            title: value.data.title,
            id: value.data.id,
            bundle: value.type,
          } : null,
        })}
      />
    </ContextPanelDetailsSection>
  );
};

ArticleHero.propTypes = {
  component: PropTypes.object.isRequired,
  delta: PropTypes.number.isRequired,
  setProperty: PropTypes.func.isRequired,
};

export default ArticleHero;
