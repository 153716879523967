import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { loginWithMfa } from 'actions/login';
import TextField from 'components/ui/TextField';
import Button from 'components/ui/buttons/Button';

import LoginForm from './common/LoginForm';

const LoginMfa = ({ secret }) => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(loginWithMfa({ code, secret }));
  };
  const handleChange = e => setCode(e.target.value);
  return (
    <LoginForm
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="h2">
            Two-factor authentication
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Enter the verification code from the <strong>Google Authenticator</strong> app.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="code"
            name="code"
            label="Enter 6 digit code"
            value={code}
            onChange={handleChange}
            margin="none"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
          >
            Verify
          </Button>
        </Grid>
      </Grid>
    </LoginForm>
  );
};

LoginMfa.propTypes = {
  secret: PropTypes.string.isRequired,
};

export default LoginMfa;
