import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from './Table';

const styles = theme => ({
  head: {
    display: 'flex',
    width: '100%',
    '& .MuiTableRow-head': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .MuiTableCell-head': {
      '&:first-child': {
        paddingLeft: theme.spacing(9.5),
      },
      '&:last-child': {
        width: 300,
        padding: 0,
      },
    },
  },
});

/**
 * class is a placeholder for the refactor of the menu dnd
 */
const TableHierarchical = (props) => {
  const { classes, headers, hasActions, rows } = props;
  return (<Table>
    {headers.length > 0 && <TableHead className={classes.head}>
      <TableRow>
        {headers.map(header => (<TableHeadCell key={header}>{header}</TableHeadCell>))}
        {hasActions && <TableHeadCell />}
      </TableRow>
    </TableHead>}
  </Table>);
};

TableHierarchical.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array,
  hasActions: PropTypes.bool,
  rows: PropTypes.array.isRequired,
};

TableHierarchical.defaultProps = {
  headers: [],
  hasActions: false,
};

export default withStyles(styles)(TableHierarchical);
