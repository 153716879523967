import React from 'react';
import PropTypes from 'prop-types';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from 'components/ui/TextField';
import { PROPS_SNIPPET, PROP_LOCATION } from '../defaults';
import EmbedWrapper from '../../../../common/EmbedWrapper';
import VoucherIcon from 'assets/icons/VoucherIcon';
import Select from 'components/ui/Select';
import { PROP_AMOUNT } from '../defaults'
const styles = () => ({
  input: {
    fontFamily: 'Consolas,Monaco,Lucida Console,Liberation Mono,Courier New',
    fontSize: 12,
  },
});
const countries = ['UK', 'US'];

const VoucherElement = (props) => {
  const {
    classes, attributes, children, element, className,
  } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title="Voucher" Icon={VoucherIcon} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Snippet"
              InputProps={{
                className: classes.input,
              }}
              multiline
              placeholder={'Insert snippet code here'}
              rows={5}
              ignoreSmartQuote
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROPS_SNIPPET]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROPS_SNIPPET] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={"Location"}
              defaultValue={element[PROP_LOCATION] || 'UK'}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_LOCATION]: e.target.value }, { at: path });
              }}
              items={countries}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={"Amount"}
              defaultValue={'6'}
              type={'number'}
              value={element[PROP_AMOUNT]}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_AMOUNT]: e.target.value }, { at: path });
              }}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

VoucherElement.propTypes = {
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyles(styles)(VoucherElement);
