import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FIELD_TITLE } from '../../../constants/story/storyFields';
import { updateStoryField, onBlurStoryField } from '../../../actions/story';

import TextField from '../../ui/builder/TextField';

const LABEL = 'Title';
const PLACEHOLDER = 'Type the title here';

const Title = (props) => {
  const { value, updateStoryField: updateField, onBlurStoryField: onBlurField } = props;
  return (
    <TextField
      label={LABEL}
      placeholder={PLACEHOLDER}
      value={value}
      onChange={event => updateField(FIELD_TITLE, event.target.value)}
      onBlur={() => onBlurField(FIELD_TITLE, value)}
    />
  );
};

Title.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  onBlurStoryField: PropTypes.func.isRequired,
  value: PropTypes.string,
};

Title.defaultProps = {
  value: '',
};

export default connect(
  ({ story: { [FIELD_TITLE]: value } }) => ({ value }),
  { updateStoryField, onBlurStoryField },
)(Title);
