import { ELEMENT_GALLERY, PROP_GALLERY, PROP_ALIGN } from './defaults';
import { GALLERY } from '../../../../../../constants/editor/dataTypes';

export const convertToGallery = ({ [PROP_ALIGN]: align, ...data }) => ({
  type: ELEMENT_GALLERY,
  [PROP_GALLERY]: {
    ...data,
  },
  [PROP_ALIGN]: align || 'none',
  children: [{ text: '' }],
});

export const convertFromGallery = ({ [PROP_GALLERY]: gallery, [PROP_ALIGN]: align }) => ({
  type: GALLERY,
  data: {
    ...gallery,
    [PROP_ALIGN]: align || 'none',
  },
});
