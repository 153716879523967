import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import RequiredIndicator from './RequiredIndicator';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'relative',
    padding: theme.spacing(4, 2),
    borderRadius: theme.border.radius,
    textAlign: 'center',
    border: `1.5px dashed ${theme.palette.border.primary.main}`,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      '& .MuiTypography-caption': {
        color: theme.palette.primary.main,
      },
    },
  },
  title: {
    width: 120,
    '& svg': {
      width: '100%',
      display: 'block',
      marginBottom: theme.spacing(1),
    },
  },
  offscreen: {
    position: 'absolute',
    visibility: 'hidden',
  },
  helperText: {
    fontSize: 11,
    fontStyle: 'italic',
    position: 'absolute',
    bottom: 0,
    left: 0,
    paddingLeft: theme.spacing(2),
  },
});

const DroppableWrapper = (props) => {
  const { classes, label, icon, helperText, children, required, className, ...rest } = props;
  return (
    <div className={`${className} ${classes.root}`} {...rest}>
      <Typography className={classes.title} variant={'caption'}>
        {icon && icon}
        {label}
        {required && <RequiredIndicator />}
      </Typography>
      {children && <div className={classes.offscreen}>{children}</div>}
      {helperText && <Typography className={classes.helperText}>{helperText}</Typography>}
    </div>);
};

DroppableWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.node,
};

DroppableWrapper.defaultProps = {
  className: '',
  required: false,
  children: null,
  helperText: null,
  icon: null,
};

export default withStyles(styles)(DroppableWrapper);
