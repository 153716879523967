import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { withStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/DeleteOutlined';

import IconButton from '../../../ui/buttons/IconButton';
import { ArrowSortableDown, ArrowSortableUp } from '../../../ui/ArrowSortable';
import { paragraph } from '../../helper/elements';

const styles = theme => ({
  root: {
    borderRadius: 4,
    padding: theme.spacing(1.5),
    margin: theme.spacing(0, -1.5),
    '&:hover': {
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
      '& .actions': {
        visibility: 'visible',
      },
    },
    '& .actions': {
      visibility: 'hidden',
      marginTop: theme.spacing(-0.5),
      marginBottom: theme.spacing(0.5),
    },
    '& .toolbar': {
      marginTop: theme.spacing(-0.5),
      marginBottom: theme.spacing(0.5),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0, 1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const EmbedWrapper = (props) => {
  const {
    classes,
    element,
    children,
    title,
    toolbar,
    Icon,
    ...rest
  } = props;
  const editor = useSlateStatic();
  return (
    <div className={classes.root} {...rest}>
      <Grid container justify={'space-between'}>
        {title && <Typography className={classes.title} variant={'h3'} color={'primary'}>
          {Icon && <Icon className={classes.icon} />}
          {title}
        </Typography>}
        {toolbar && <Grid item className={'toolbar'}>{toolbar}</Grid>}
        <Grid item className={'actions'}>
          <ArrowSortableUp
            onClick={() => {
              const path = ReactEditor.findPath(editor, element);
              if (path[0] > 0) {
                Transforms.moveNodes(editor, { at: path, to: [path[0] - 1] });
                Transforms.deselect(editor);
              }
            }}
          />
          <ArrowSortableDown
            onClick={() => {
              const path = ReactEditor.findPath(editor, element);
              Transforms.moveNodes(editor, { at: path, to: [path[0] + 1] });
              Transforms.deselect(editor);
            }}
          />
          <IconButton
            onClick={() => {
              const path = ReactEditor.findPath(editor, element);
              Transforms.delete(editor, { at: path });
              Transforms.insertNodes(editor, [paragraph], { at: path });
              Transforms.select(editor, path);
              ReactEditor.focus(editor);
            }}
          ><RemoveIcon /></IconButton>
        </Grid>
      </Grid>
      {children}
    </div>
  );
};

EmbedWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  toolbar: PropTypes.node,
  title: PropTypes.string,
  Icon: PropTypes.object,
};

EmbedWrapper.defaultProps = {
  title: '',
  Icon: null,
  toolbar: null,
};

export default withStyles(styles)(EmbedWrapper);
