import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import NotFound from '../layouts/NotFound';
import { hasPermissions, hasFeatures } from '../components/helper/utils';

const AccessRoute = ({ access, requiredFeature, roles, permissions, features, component: Component, ...rest }) =>
  (<Route
    {...rest}
    render={props =>
      (
        (!access || hasPermissions(permissions)(access)) &&
        (!requiredFeature || hasFeatures(features)(requiredFeature) || roles.includes('cms_internal')) &&
        <Component {...props} />
      ) ||
      <NotFound />
    }
  />);

AccessRoute.defaultProps = {
  roles: [],
  features: [],
  access: [],
  permissions: [],
  requiredFeature: '',
  component: null,
};

AccessRoute.propTypes = {
  roles: PropTypes.array,
  features: PropTypes.array,
  component: PropTypes.elementType,
  access: PropTypes.array,
  requiredFeature: PropTypes.string,
  permissions: PropTypes.array,
};

export default connect((
  { login: { user: { roles }, permissions }, frame: { selectedPublication: { features } } },
) => ({ roles, features, permissions }), {})(AccessRoute);
