import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../FieldLockFieldDepricated';
import TextFieldDefault from '../../ui/builder/TextField';
import TextFieldContext from '../../ui/TextField';

export const VARIANT_CONTEXT = 'context';
export const VARIANT_DEFAULT = 'default';

const getTextfieldVarient = (variant) => {
  switch (variant) {
    case VARIANT_CONTEXT:
      return TextFieldContext;
    default:
      return TextFieldDefault;
  }
};

/**
 * @deprecated
 */
const FieldLockTextfieldDepricated = ({
  value,
  label,
  onChange,
  onUnlock,
  field: fieldName,
  variant,
  lockedFields,
  sessionLockedFields,
  ...rest
}) => {
  let input;
  const lock = sessionLockedFields.filter(({ field }) => field === fieldName);
  const toggleValue = lock.length > 0 ? lock[0].value : value;
  const Textfield = getTextfieldVarient(variant);
  return (
    <FieldLockField
      field={fieldName}
      onUnlock={() => {
        onUnlock();
        input.focus();
      }}
    >
      <Textfield
        label={label}
        inputRef={(ref) => {
          input = ref;
        }}
        hasChanges={Object.keys(lockedFields).indexOf(fieldName) !== -1}
        value={toggleValue}
        onChange={onChange}
        {...rest}
      />
    </FieldLockField>
  );
};

FieldLockTextfieldDepricated.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
  lockedFields: PropTypes.object.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  variant: PropTypes.string,
};

FieldLockTextfieldDepricated.defaultProps = {
  variant: VARIANT_DEFAULT,
};

export default connect(
  ({
    fieldLock: { lockedFields, sessionLockedFields },
  }) => ({ lockedFields, sessionLockedFields }),
  {},
)(FieldLockTextfieldDepricated);
