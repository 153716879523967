import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import FlowCard from '../../common/layout/FlowCard';

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2),
    overflow: 'visible',
  },
}))(FlowCard);

export const TableFiltersGroup = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'visible',
  },
}))(Grid);
