import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { createEditor, Transforms } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { equals } from 'rambdax';

import {
  EditablePlugins,
  ParagraphPlugin,
  ExitBreakPlugin,
  SoftBreakPlugin,
  withInlineVoid,
  withMarks,
  pipe,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_CODE_BLOCK,
  ELEMENT_BLOCKQUOTE, withNodeID,
} from '@udecode/slate-plugins';

import EditorToolbarInline, { toolbarInlinePlugins, withToolbarInlinePlugins } from './EditorToolbarInline';
import { paragraph } from './helper/elements';
import withFormatting from './plugins/utils/withFormatting';
import NestedPlugin from './plugins/handlers/nested/NestedPlugin';
import withNested from './plugins/handlers/nested/withNested';

export const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > [data-slate-editor="true"]': {
      fontFamily: theme.typography.fontFamily,
      '& a': {
        color: theme.palette.primary.main,
      },
      '& ul': {
        margin: '1em 0',
      },
      '& ol': {
        margin: '1em 0',
      },
      '& li > p': {
        margin: 0,
      },
      '& > *:first-child': {
        marginTop: 0,
      },
      '& > *:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const emptyState = [paragraph];

const plugins = [
  ParagraphPlugin(),
  ...toolbarInlinePlugins,
  SoftBreakPlugin({
    rules: [
      { hotkey: 'shift+enter' },
      {
        hotkey: 'enter',
        query: {
          allow: [
            ELEMENT_CODE_BLOCK,
            ELEMENT_BLOCKQUOTE,
          ],
        },
      },
    ],
  }),
  ExitBreakPlugin({
    rules: [
      {
        hotkey: 'mod+enter',
      },
      {
        hotkey: 'mod+shift+enter',
        before: true,
      },
      {
        hotkey: 'enter',
        query: {
          start: true,
          end: true,
          allow: [
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_H3,
            ELEMENT_H4,
            ELEMENT_H5,
            ELEMENT_H6,
          ],
        },
        level: 0,
      },
    ],
  }),
];

const withPlugins = [
  withReact,
  withHistory,
  ...withToolbarInlinePlugins,
  withInlineVoid({ plugins }),
  withNodeID(),
  withMarks(),
  withFormatting({ plugins }),
  withNested,
];

const EditorSimple = ({ className, classes, isProduct = false, content, onChange, onFocus, onBlur, nested, id, ...rest }) => {
  const [value, setValue] = useState(emptyState);
  const editor = useMemo(
    () => pipe(createEditor(), ...withPlugins), [],
  );
  // update on redux change
  useEffect(() => {
    if (!equals(content, value)) {
      Transforms.deselect(editor);
      setValue(content && content.length > 0 ? content : emptyState);
    }
  }, [content]);
  return (
    <div className={className ? `${className} ${classes.container}` : classes.container}>
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          if (!equals(newValue, value)) {
            setValue(newValue && newValue.length > 0 ? newValue : emptyState);
            onChange(newValue);
          }
        }}
        {...rest}
      >
        <EditorToolbarInline isProduct={isProduct} />
        <EditablePlugins
          id={id}
          plugins={nested ? [...plugins, NestedPlugin()] : plugins}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </Slate>
    </div>
  );
};

EditorSimple.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  nested: PropTypes.bool,
};

EditorSimple.defaultProps = {
  className: '',
  id: '',
  onFocus: null,
  onBlur: null,
  nested: false,
};

export default withStyles(styles)(EditorSimple);
