import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { capitalize } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";

import {
  FIELD_TITLE,
  FIELD_IMAGE,
  FIELD_BODY,
  FIELD_MODEL,
  FIELD_BRAND,
  FIELD_PRICE,
  FIELD_PRODUCT_ID,
  FIELD_PRODUCT_ID_TYPE,
  FIELD_PRICE_COMPARISON,
  FIELD_PRICE_COMPARISON_PROVIDER,
  FIELD_BEST_FOR,
  FIELD_VENDOR_LINK,
  FIELD_VENDOR_NAME,
  FIELD_RATING,
} from "../../constants/product/productFields";

import SectionTitle from "../common/SectionTitle";
import TextField from "../ui/TextField";
import MarkupField, { TYPE_FULL } from "../ui/MarkupField";
import Select from "../ui/Select";
import ImageSelector from "../ui/media/ImageSelector";
import Button from "../ui/buttons/Button";
import PriceComparison from "./PriceComparison";

import { VARIANT_REDUCED } from "../editor/Editor";
import { showProductField } from "./helper";
import Rating from "../../components/ui/Rating";

const styles = (theme) => ({
  editor: {
    "& > div": {
      minHeight: 200,
    },
  },
  image: {
    minHeight: 202,
  },
  ratingRoot: {
    height: "100%",
  },
  ratingLabel: {
    paddingRight: theme.spacing(1),
  },
});

const ProductForm = (props) => {
  const { classes, inline, product, save, setProp, publicationConfig } = props;
  const {
    [FIELD_TITLE]: title,
    [FIELD_IMAGE]: image,
    [FIELD_BODY]: body,
    [FIELD_PRICE]: price,
    [FIELD_BRAND]: brand,
    [FIELD_MODEL]: model,
    ["information"]: information,
    [FIELD_VENDOR_NAME]: vendorName,
    [FIELD_VENDOR_LINK]: vendorLink,
    [FIELD_PRODUCT_ID]: productId,
    [FIELD_PRODUCT_ID_TYPE]: productIdType,
    [FIELD_PRICE_COMPARISON]: priceComparison,
    [FIELD_PRICE_COMPARISON_PROVIDER]: priceComparisonProvider,
    [FIELD_BEST_FOR]: bestFor,
    [FIELD_RATING]: rating,
  } = product;
  const showBestForField = showProductField(publicationConfig, FIELD_BEST_FOR);
  const showRatingField = showProductField(publicationConfig, FIELD_RATING);
  const [productSpec, setProductSpec] = useState(
    information
      ? information.productSpec
        ? information.productSpec
        : [{ key: "", value: "" }]
      : [{ key: "", value: "" }]
  );
  const [pros, setPros] = useState(
    information
      ? information.pros
        ? information.pros
        : {
            title: "",
            pros: [""],
          }
      : { title: "", pros: [""] }
  );

  const [cons, setCons] = useState(
    information
      ? information.cons
        ? information.cons
        : {
            title: "",
            cons: [""],
          }
      : { title: "", cons: [""] }
  );

  const handleChangeCon = (e, index) => {
    const { value } = e.target;
    let newArr = [...cons.cons];
    newArr[index] = value;
    setCons({ ...cons, cons: newArr });
    const updatedProps = information
      ? { ...information, cons: { cons: newArr, ...cons } }
      : {
          productSpec: [...productSpec],
          pros: { ...pros },
          cons: { cons: newArr },
        };

    // console.log(updatedProps)
    setProp("information", updatedProps);
  };

  const handleRemoveCon = (index) => {
    let newArr = [...cons.cons];
    newArr.splice(index, 1);
    setCons({ ...cons, cons: newArr });
    const updatedProps = information
      ? { ...information, cons: { cons: newArr } }
      : {
          productSpec: [...productSpec],
          pros: { ...pros },
          cons: { cons: newArr },
        };
    setProp("information", updatedProps);
  };

  const handleRemovePro = (index) => {
    let newArr = [...pros.pros];
    newArr.splice(index, 1);
    setPros({ ...pros, pros: newArr });
    const updatedProps = information
      ? { ...information, pros: { pros: newArr } }
      : {
          productSpec: [...productSpec],
          pros: { pros: newArr },
          cons: { cons: cons },
        };
    setProp("information", updatedProps);
  };

  const handleChangePro = (e, index) => {
    const { value } = e.target;
    let newArr = [...pros.pros];
    newArr[index] = value;
    setPros({ ...pros, pros: newArr });
    const updatedProps = information
      ? { ...information, pros: { pros: newArr } }
      : {
          productSpec: [...productSpec],
          cons: { ...cons },
          pros: { pros: { pros: newArr } },
        };
    setProp("information", updatedProps);
  };

  const handleChangeSpec = (e, index) => {
    const { value, name } = e.target;
    let newArr = [...productSpec];
    newArr[index][name] = value;
    setProductSpec(newArr);
    const updatedProps = information
      ? { ...information, productSpec: newArr }
      : { pros: { ...pros }, cons: { ...cons }, productSpec: newArr };
    setProp("information", updatedProps);
  };

  const handleChangeProductState = (e) => {
    const { value } = e.target;
    const updatedProps = information
      ? { ...information, productState: value }
      : {
          productSpec: [...productSpec],
          pros: { ...pros },
          cons: { ...cons },
          productState: value,
        };
    setProp("information", updatedProps);
  };
  const handleRemoveSpec = (index) => {
    const newArr = [...productSpec];
    const updatedArr = newArr.filter((item, i) => i !== index && item);
    setProductSpec(updatedArr);
    const updatedProps = information
      ? { ...information, productSpec: updatedArr }
      : { pros: { ...pros }, cons: { ...cons }, productSpec: updatedArr };
    setProp("information", updatedProps);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          value={title || ""}
          onChange={(e) => setProp(FIELD_TITLE, e.target.value)}
          label={"Product title"}
          margin={"none"}
          required
        />
      </Grid>

      <Grid item xs={3}>
        <SectionTitle variant={"h3"} margin>
          Product image
        </SectionTitle>
        <ImageSelector
          className={classes.image}
          label={"Drag & Drop image or browse"}
          selectImage={(imageData) =>
            setProp(FIELD_IMAGE, { type: "image", data: imageData })
          }
          image={image?.data || null}
          showEditDialog
        />
      </Grid>
      <Grid item xs={9}>
        <SectionTitle variant={"h3"} margin>
          Description
        </SectionTitle>
        <MarkupField
          className={classes.editor}
          data={body || []}
          onChange={(value) => setProp(FIELD_BODY, value)}
          editorVariant={VARIANT_REDUCED}
          type={TYPE_FULL}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          value={vendorName || ""}
          label={"Vendor Name"}
          margin={"none"}
          onChange={(e) => setProp(FIELD_VENDOR_NAME, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={vendorLink || ""}
          label={"Vendor Link"}
          margin={"none"}
          onChange={(e) => setProp(FIELD_VENDOR_LINK, e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h3"}>Pros</Typography>
      </Grid>
      {/*    <Grid item xs={12}>
        <TextField
          value={pro.title || ""}
          label={'Pro title'}
          margin={'none'}
          name="pro"
          onChange={(e) => handleChange(e)}
        />
      </Grid>*/}

      {pros &&
        pros.pros &&
        typeof pros.pros !== "undefined" &&
        pros.pros.map((item, i) => {
          return (
            <>
              <Grid item xs={11}>
                <TextField
                  value={pros ? pros.pros[i] || "" : ""}
                  label={"Pro"}
                  margin={"none"}
                  name="pro"
                  onChange={(e) => handleChangePro(e, i)}
                />
              </Grid>

              <Grid item xs={1}>
                <Button onClick={() => handleRemovePro(i)}> Remove - </Button>
              </Grid>
            </>
          );
        })}

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button onClick={() => setPros({ ...pros, pros: [...pros.pros, ""] })}>
          Add pro +{" "}
        </Button>
      </div>
      <Grid item xs={12}>
        <Typography primary variant={"h3"}>
          Cons
        </Typography>
      </Grid>
      {/*      <Grid item xs={11}>
        <TextField
          value={cons.title || ""}
          label={'Con title'}
          margin={'none'}
          name="information"
          onChange={(e) => handleChangeConTitle(e)}
        />
    </Grid>*/}
      {cons &&
        cons.cons &&
        typeof cons.cons !== "undefined" &&
        cons.cons.map((item, i) => {
          return (
            <>
              <Grid item xs={11}>
                <TextField
                  value={cons ? cons.cons[i] || "" : ""}
                  label={"Con"}
                  margin={"none"}
                  name="information"
                  onChange={(e) => handleChangeCon(e, i)}
                />
              </Grid>
              <Grid item xs={1}>
                <Button onClick={() => handleRemoveCon(i)}>Remove - </Button>
              </Grid>
            </>
          );
        })}
      <Grid item xs={12}>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Button
            onClick={() => setCons({ ...cons, cons: [...cons.cons, ""] })}
          >
            Add con +{" "}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={"h3"}>Product Spec</Typography>
      </Grid>
      {productSpec &&
        typeof productSpec !== "undefined" &&
        productSpec.map((item, i) => {
          return (
            <>
              <Grid item xs={6}>
                <TextField
                  value={productSpec ? productSpec[i].key || "" : ""}
                  label={"Name"}
                  margin={"none"}
                  name="key"
                  onChange={(e) => handleChangeSpec(e, i)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  value={productSpec ? productSpec[i].value || "" : ""}
                  label={"Value"}
                  margin={"none"}
                  name="value"
                  onChange={(e) => handleChangeSpec(e, i)}
                />
              </Grid>

              <Grid item xs={1}>
                {i > 0 && (
                  <Button onClick={() => handleRemoveSpec(i)}>Remove - </Button>
                )}
              </Grid>
            </>
          );
        })}

      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          onClick={() =>
            setProductSpec([...productSpec, { key: "", value: "" }])
          }
        >
          Add product spec item +{" "}
        </Button>
      </div>

      {!inline && (
        <Grid item xs={6}>
          <TextField
            value={brand || ""}
            label={"Brand"}
            onChange={(e) => setProp(FIELD_BRAND, e.target.value)}
            margin={"none"}
          />
        </Grid>
      )}
      {!inline && (
        <Grid item xs={6}>
          <TextField
            value={model || ""}
            label={"Model"}
            onChange={(e) => setProp(FIELD_MODEL, e.target.value)}
            margin={"none"}
          />
        </Grid>
      )}
      {!inline && (
        <Grid item xs={6}>
          <Select
            label={"Product ID Type"}
            value={productIdType || ""}
            items={["ASIN", "EAN", "MPN", "SKU", "UPC"]}
            onChange={(e) => setProp(FIELD_PRODUCT_ID_TYPE, e.target.value)}
            margin={"none"}
          />
        </Grid>
      )}
      {!inline && (
        <Grid item xs={6}>
          <TextField
            value={productId || ""}
            onChange={(e) => setProp(FIELD_PRODUCT_ID, e.target.value)}
            label={"Product Id"}
            margin={"none"}
          />
        </Grid>
      )}
      {!inline && (
        <Grid item xs={1}>
          <TextField
            value={price || 0}
            inputProps={{
              type: "number",
              step: "0.01",
            }}
            label={"Price"}
            margin={"none"}
            onChange={(e) => setProp(FIELD_PRICE, e.target.value)}
          />
        </Grid>
      )}
      {!inline &&
        publicationConfig.priceComparisonProviders &&
        publicationConfig.priceComparisonProviders.length > 0 && (
          <Grid item xs={2}>
            <Select
              value={
                priceComparisonProvider ||
                publicationConfig.priceComparisonProviders[0]
              }
              label={"Comparison provider"}
              items={publicationConfig.priceComparisonProviders.map((p) => ({
                id: p,
                name: capitalize(p),
              }))}
              onChange={(e) =>
                setProp(FIELD_PRICE_COMPARISON_PROVIDER, e.target.value)
              }
              margin={"none"}
            />
          </Grid>
        )}
      {!inline && (
        <Grid item xs={9}>
          <TextField
            value={priceComparison || ""}
            label={"Price comparison"}
            onChange={(e) => setProp(FIELD_PRICE_COMPARISON, e.target.value)}
            margin={"none"}
          />
          <PriceComparison link={priceComparison} />
        </Grid>
      )}
      {showBestForField && (
        <Grid item xs={3}>
          <TextField
            value={bestFor || ""}
            label={"Best for"}
            onChange={(e) => setProp(FIELD_BEST_FOR, e.target.value)}
            margin={"none"}
          />
        </Grid>
      )}
      {showRatingField && (
        <Grid item xs={3}>
          <Grid container alignItems={"center"} className={classes.ratingRoot}>
            <Typography className={classes.ratingLabel} component="legend">
              Rating
            </Typography>
            <Rating
              name="customized-empty"
              value={rating}
              onChange={(event) => {
                setProp(FIELD_RATING, event.target.value);
              }}
              precision={0.5}
              size={"large"}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </Grid>
        </Grid>
      )}
      {save && (
        <Grid item container direction={"row-reverse"}>
          <Button
            variant={"contained"}
            disabled={!title || title.length === 0 || !image || !image.data}
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

ProductForm.propTypes = {
  classes: PropTypes.object.isRequired,
  setProp: PropTypes.func.isRequired,
  product: PropTypes.object,
  save: PropTypes.func,
  inline: PropTypes.bool,
  publicationConfig: PropTypes.object.isRequired,
};

ProductForm.defaultProps = {
  save: null,
  inline: false,
  product: {},
};

export default withStyles(styles)(
  connect(
    ({
      frame: {
        selectedPublication: { publicationConfig },
      },
    }) => ({ publicationConfig }),
    {}
  )(ProductForm)
);
