import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { ContextPanelFieldHeading, ContextPanelDetailsSection } from '../../../../../common/context/ContextPanel';

import TextField from '../../../../../ui/TextField';
import ArticleRef from './ArticleRef';
import ImageSelector from '../../../../../ui/media/ImageSelector';
import MarkupField from '../../../../../ui/MarkupField';

const FIELD_HEADLINE = 'headline';
const FIELD_IMAGE = 'image';
const FIELD_CAPTION = 'caption';
const FIELD_AUTHOR = 'author';
const FIELD_AUTHOR_LOCATION = 'authorLocation';
const FIELD_ARTICLE_REF = 'articleRef';

const styles = () => ({
  articleBody: {
    width: '100%',
  },
});

const Article = (props) => {
  const {
    article: { headline, image, caption, articleRef, body, author, authorLocation },
    delta, componentDelta, prop, mergeProperty, showAuthorField,
    classes, containImage, containBody,
  } = props;

  const assignImage = data => mergeProperty({
    delta: componentDelta,
    prop,
    value: {
      [delta]: {
        [FIELD_IMAGE]: {
          type: 'image',
          data,
        },
      },
    },
  });

  return (
    <ContextPanelDetailsSection>
      <ContextPanelFieldHeading>Article {delta + 1}</ContextPanelFieldHeading>
      <TextField
        label="Headline"
        value={headline || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_HEADLINE]: event.target.value,
            },
          },
        })}
      />
      {containImage && <ImageSelector
        selectImage={assignImage}
        onRemove={() => assignImage(null)}
        label="Add image"
        image={image?.data}
        showEditDialog
      />}
      <TextField
        label={'Caption'}
        value={caption || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_CAPTION]: event.target.value,
            },
          },
        })}
      />
      {showAuthorField && <TextField
        label="Author"
        value={author || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_AUTHOR]: event.target.value,
            },
          },
        })}
      />}
      {showAuthorField && <TextField
        label="Author Location"
        value={authorLocation || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_AUTHOR_LOCATION]: event.target.value,
            },
          },
        })}
      />}
      {containBody && <MarkupField
        label="Body"
        className={classes.articleBody}
        data={body}
        placeholder={''}
        onChange={markup => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              body: markup,
            },
          },
        })}
      />}
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={(ref) => {
          if (ref.data && ref.type) {
            mergeProperty({
              delta: componentDelta,
              prop,
              value: {
                [delta]: {
                  [FIELD_ARTICLE_REF]: {
                    title: ref.data.title,
                    id: ref.data.id,
                    bundle: ref.type,
                  },
                },
              },
            });
          }
        }}
      />
    </ContextPanelDetailsSection>
  );
};

Article.defaultProps = {
  showAuthorField: false,
  containImage: false,
  containBody: false,
};

Article.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  delta: PropTypes.number.isRequired,
  componentDelta: PropTypes.number.isRequired,
  prop: PropTypes.string.isRequired,
  mergeProperty: PropTypes.func.isRequired,
  showAuthorField: PropTypes.bool,
  containImage: PropTypes.bool,
  containBody: PropTypes.bool,
};

export default withStyles(styles)(Article);
