import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { formatWithCommas } from '../../utils/numberHelper';

import DashboardCard, { DashboardCardHeader, DashboardCardContent } from '../common/dashboard/DashboardCard';

const RADIUS = 70;
const STROKE = 14;
const CIRC = 2 * Math.PI * (RADIUS - (STROKE / 2));

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.border.primary.main}`,
    },
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color.primary,
    fontSize: '48px',
    fontWeight: 300,
  },
  percentage: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
    lineHeight: `${RADIUS * 2}px`,
    position: 'relative',
  },
  circle: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    left: `calc(50% - ${RADIUS}px)`,
    '& circle': {
      fill: 'transparent',
      strokeWidth: STROKE,
    },
  },
  circleBackground: {
    '& circle': {
      stroke: theme.palette.secondary.main,
    },
  },
  circleForeground: {
    '& circle': {
      stroke: theme.palette.primary.main,
    },
  },
});

const getPercentage = (matched, unmatched) => (
  matched > 0 ? (100 / ((matched + unmatched) / matched)).toFixed(0) : 0
);

const Circle = ({ className, percent }) => {
  const attrs = percent < 100 ? {
    style: {
      strokeDasharray: CIRC,
      strokeDashoffset: CIRC - (CIRC * (percent / 100)),
    },
  } : {};
  return (
    <svg className={className} height={RADIUS * 2} width={RADIUS * 2}>
      <circle cx={RADIUS} cy={RADIUS} r={RADIUS - (STROKE / 2)} {...attrs} />
    </svg>
  );
};

Circle.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
};

Circle.defaultProps = {
  className: '',
  percent: 100,
};

const MatchedProducts = (props) => {
  const {
    classes, matched, unmatched,
  } = props;
  const [percent, setPercent] = useState(getPercentage(matched, unmatched));
  useEffect(() => {
    setPercent(getPercentage(matched, unmatched));
  }, [matched, unmatched]);
  return (
    <DashboardCard>
      <DashboardCardHeader title={'Matched Products'} />
      <DashboardCardContent>
        <div className={classes.percentage}>
          {percent < 100 && <Circle className={`${classes.circle} ${classes.circleBackground}`} />}
          {percent > 0 && <Circle className={`${classes.circle} ${classes.circleForeground}`} percent={percent} />}
          <span className={classes.number}>{percent}%</span>
        </div>
        <Grid container>
          <Grid item xs={6} className={classes.content}>
            <span className={classes.number}>{formatWithCommas(matched)}</span>
            <Typography variant={'caption'} color={'primary'}>Matched</Typography>
          </Grid>
          <Grid item xs={6} className={classes.content}>
            <span className={classes.number}>{formatWithCommas(unmatched)}</span>
            <Typography variant={'caption'} color={'primary'}>Unmatched</Typography>
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

MatchedProducts.propTypes = {
  classes: PropTypes.object.isRequired,
  matched: PropTypes.number,
  unmatched: PropTypes.number,
};

MatchedProducts.defaultProps = {
  matched: 0,
  unmatched: 0,
};

export default withStyles(styles)(connect(
  ({
    affiliate: { products: { matched, unmatched } },
  }) => ({ matched, unmatched }),
  {},
)(MatchedProducts));
