export const docHeight = () => window.innerHeight || document.documentElement.clientHeight;

export const elementOutOfViewport = (el, offset) => {
  try {
    const rect = el.getBoundingClientRect();
    const height = docHeight();
    return rect.top < 0 ||
      (
        rect.top > 0 &&
        rect.top > height - offset
      ); // reduced the sticky footer/header height
  } catch (ex) {
    return false;
  }
};

export const getOffsetTop = (element) => {
  let offsetTop = 0;
  let el = element;
  while (el) {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }
  return offsetTop;
};

export const parentWithScrollbar = (element, noParent = false) => {
  let el = element;
  while (el && el.parentNode) {
    el = el.parentNode;
    // must contain vertical scrollbar
    if (el.scrollHeight > el.clientHeight) {
      return noParent ? el : el.parentNode;
    }
  }
  return null;
};

export const scrollToView = (el, offset = 50, noParent = false) => {
  if (elementOutOfViewport(el, offset)) {
    const scrollableParent = parentWithScrollbar(el, noParent);
    if (scrollableParent) {
      const distance = getOffsetTop(el) - scrollableParent.scrollTop;
      const intendedYViewportPosition = docHeight() - el.offsetHeight - offset;
      const deltaY = distance - intendedYViewportPosition;
      scrollableParent.scrollTo({
        behavior: 'smooth',
        top: scrollableParent.scrollTop + deltaY,
      });
    }
  }
};

export const snapToView = (el, offset = 0) => {
  if (elementOutOfViewport(el, offset)) {
    const scrollableParent = parentWithScrollbar(el);
    if (scrollableParent) {
      const distance = getOffsetTop(el) - scrollableParent.scrollTop;
      const intendedYViewportPosition = (docHeight() / 2) - el.offsetHeight - offset;
      const deltaY = distance - intendedYViewportPosition;
      scrollableParent.scrollTop = scrollableParent.scrollTop + deltaY;
    }
  }
};
