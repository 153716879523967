import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import KeyPoints from './liveblogBuilder/KeyPoints';
import Title from './liveblogBuilder/Title';
import Status from './liveblogBuilder/Status';
import { FEATURE_HIDE_DEDICATED_LIVEBLOG_KEYPOINT } from '../../constants/features';
import { PagePanel, PagePanelContent } from '../common/page/PagePanel';
import { setLiveblogProperty } from '../../actions/liveblog';
import { SpacerTop } from './liveblogBuilder/SpacerTop';
import { hasFeatures } from '../helper/utils';

const LiveblogBuilder = ({
  setLiveblogProperty: saveProp,
  hideKeypoint,
  keyPoints,
  features,
}) => (
  <PagePanel>
    <PagePanelContent noHeading>
      <Grid container alignItems={'center'} spacing={2}>
        <Grid item xs={12}>
          <Title />
          {!hideKeypoint && !hasFeatures(features)(FEATURE_HIDE_DEDICATED_LIVEBLOG_KEYPOINT) &&
          <SpacerTop>
            <KeyPoints
              keyPoints={keyPoints[0].data.markup}
              onChange={(listItems) => {
                const payload = keyPoints.map(comp => ({
                  ...comp,
                  data: {
                    markup: listItems,
                  },
                }));
                saveProp(['keyPoints', payload]);
              }}
            />
          </SpacerTop>
          }
        </Grid>
        <Grid item xs={6}>
          <Status />
        </Grid>
      </Grid>
    </PagePanelContent>
  </PagePanel>
);

LiveblogBuilder.propTypes = {
  setLiveblogProperty: PropTypes.func.isRequired,
  hideKeypoint: PropTypes.bool,
  keyPoints: PropTypes.string,
  features: PropTypes.array,
};

LiveblogBuilder.defaultProps = {
  hideKeypoint: false,
  keyPoints: '',
  features: [],
};

export default connect(
  ({
    frame: { selectedPublication: { features } },
    liveblog: { keyPoints },
  }) => ({ features, keyPoints }),
  { setLiveblogProperty },
)(LiveblogBuilder);
