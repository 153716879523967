import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setLocalProp } from '../../../../actions/dataState';

import Select from '../../../ui/Select';
import { ContextPanelItem } from '../../../common/context/ContextPanel';
import { FIELD_WORKFLOW } from '../../../../constants/article/articleFields';

const styles = () => ({
  select: {
    width: 'auto',
    margin: 0,
  },
});

const WorkFlowStatus = ({
  classes,
  workflowOptions,
  workflow,
  setLocalProp: setProp,
}) => (
  <ContextPanelItem label={'Workflow'}>
    <Select
      className={classes.select}
      value={workflow}
      onChange={e => setProp(FIELD_WORKFLOW, e.target.value)}
      items={workflowOptions}
    />
  </ContextPanelItem>
);

WorkFlowStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  workflowOptions: PropTypes.array.isRequired,
  workflow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

WorkFlowStatus.defaultProps = {
  workflow: '',
};

export default withStyles(styles)(connect(
  ({ dataState: { [FIELD_WORKFLOW]: workflow }, frame: { workflowOptions } }) =>
    ({ workflow, workflowOptions }),
  { setLocalProp },
)(WorkFlowStatus));
