import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export const styles = () => ({
  root: {
    flexBasis: '100%',
  },
  imageRatio: {
    position: 'relative',
    paddingTop: '56.25%',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      top: 0,
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      verticalAlign: 'bottom',
    },
  },
});

const Image = ({
  classes,
  url,
  noImageRatio,
  ...rest
}) => (
  <Grid className={classes.root} {...rest}>
    <div className={noImageRatio ? '' : classes.imageRatio}>
      <img alt={'Preview'} src={url} />
    </div>
  </Grid>
);

Image.defaultProps = {
  noImageRatio: false,
};

Image.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  noImageRatio: PropTypes.bool,
};

export default withStyles(styles)(Image);

