export const MEDIA_ENTITY_TYPE = 'media';
export const MEDIA_ENTITY_BUNDLE = 'media_bundle';
export const MEDIA_ENTITY_ID = 'mid';

export const MEDIA_IMAGE = 'image';
export const MEDIA_VIDEO = 'video';
export const MEDIA_GALLERY = 'gallery';

export const MEDIA_KEY_SOURCE_IDENTIFIER = 'sourceImage';
export const MEDIA_SOURCE_INTERNAL = 'internal_media';
export const MEDIA_SOURCE_CHP = 'chp';

export const VALID_IMAGE_EXTENSION = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
export const VALID_VIDEO_EXTENSION = ['video/mp4', 'video/mpeg4', 'video/mov'];
export const MAX_IMAGE_SIZE_BYTE = 10000000;

export const INVALID_SIZE = `more than ${Math.floor(MAX_IMAGE_SIZE_BYTE / 1000000)}mb`;
export const INVALID_TYPE = 'invalid file type';

export const VIDEO_DM = 'video-dm';
export const VIDEO_JWPLAYER = 'video-jwplayer';
export const VIDEO_ELEPHANT = 'video-elephant';
export const VIDEO_YOUTUBE = 'video-youtube';
export const VIDEO_YOUTUBE_LEGACY = 'youtube';
export const VIDEO_DUGOUT = 'video-dugout';
export const VIDEO_PLAYINC = 'videoPlayinc';

export const MEDIA_ENTITY_IDS = {
  [VIDEO_DM]: 'dailymotion_id',
  [VIDEO_JWPLAYER]: 'jw_player_id',
  [VIDEO_YOUTUBE]: 'youtube_id',
  [VIDEO_YOUTUBE_LEGACY]: 'youtube_id',
  [VIDEO_DUGOUT]: 'dugout_id',
};

export const IMAGE_MANAGER_CONTEXT = 'gallery-manager';

export const HERO_VIDEO_JWPLAYER = 'jw_player';
export const HERO_VIDEO_YOUTUBE = VIDEO_YOUTUBE;
export const HERO_VIDEO_YOUTUBE_LEGACY = 'youtube';
export const HERO_VIDEO_DUGOUT = 'dugout-video';
export const HERO_VIDEO_DM = 'dailymotion';

export const PROP_LOCAL_CAPTION = 'localCaption';
export const PROP_LOCAL_COPYRIGHT = 'localCopyright';
export const PROP_DATA = 'data';

export const VIDEO_TYPES = {
  [VIDEO_JWPLAYER]: 'JW Player',
  [VIDEO_DUGOUT]: 'Dugout',
  [VIDEO_DM]: 'Daily Motion',
  [VIDEO_YOUTUBE]: 'YouTube',
  [VIDEO_ELEPHANT]: 'Video Elephant',

};

export const HERO_VIDEO_TYPES = [
  HERO_VIDEO_JWPLAYER,
  HERO_VIDEO_YOUTUBE,
  HERO_VIDEO_YOUTUBE_LEGACY,
  HERO_VIDEO_DUGOUT,
  HERO_VIDEO_DM,
];

export const HERO_VIDEO_TO_VIDEO = {
  [HERO_VIDEO_JWPLAYER]: VIDEO_JWPLAYER,
  [HERO_VIDEO_YOUTUBE]: VIDEO_YOUTUBE,
  [HERO_VIDEO_YOUTUBE_LEGACY]: VIDEO_YOUTUBE,
  [HERO_VIDEO_DUGOUT]: VIDEO_DUGOUT,
  [HERO_VIDEO_DM]: VIDEO_DM,
};

export const VIDEO_TO_HERO_VIDEO = {
  [VIDEO_JWPLAYER]: HERO_VIDEO_JWPLAYER,
  [VIDEO_YOUTUBE]: HERO_VIDEO_YOUTUBE,
  [VIDEO_YOUTUBE_LEGACY]: HERO_VIDEO_YOUTUBE,
  [VIDEO_DUGOUT]: HERO_VIDEO_DUGOUT,
  [VIDEO_DM]: HERO_VIDEO_DM,
};

export const HERO_TYPES = {
  [MEDIA_IMAGE]: 'Image',
  [MEDIA_GALLERY]: 'Gallery',
  [MEDIA_VIDEO]: 'Video',
};
