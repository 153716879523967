import React from 'react';
import { useSlate } from 'slate-react';
import {
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ToolbarButton,
} from '@udecode/slate-plugins';
import Tooltip from '@material-ui/core/Tooltip';
import { ContentCopy } from 'mdi-material-ui';
import { serializeNode } from '../helper/serializer';
import { copyToClipboard } from '../../../utils/helper/clipboard';

const ToolbarCopyText = (props) => {
  const editor = useSlate();
  return (
    <>
      <ToolbarButton
        onMouseDown={(e) => {
          e.preventDefault();
          const markup = [];
          editor.children.forEach((block) => {
            switch (block.type) {
              case ELEMENT_H2:
              case ELEMENT_H3:
              case ELEMENT_H4:
              case ELEMENT_H5:
              case ELEMENT_H6:
              case ELEMENT_PARAGRAPH:
              case ELEMENT_UL:
              case ELEMENT_OL:
                markup.push(serializeNode(block));
                break;
              default:
                break;
            }
          });
          copyToClipboard(markup.join('\n'), true);
        }}
        icon={<Tooltip title={'Copy all text'} arrow><ContentCopy /></Tooltip>}
        {...props}
      />
    </>
  );
};

export default ToolbarCopyText;
