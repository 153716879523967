import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TextField from '../../../ui/TextField';

import {
  CONTENT_SOURCE_CUSTOM_SECTION, LABEL_TEXT, LABEL_LINK,
} from '../../constants';


const TEXT_LABEL = 'Label text';
const LINK_LABEL = 'Label link';

const getDefaultValue = (defaultkey, sectionKey) => (comp) => {
  const section = comp[CONTENT_SOURCE_CUSTOM_SECTION];
  if (comp[defaultkey]) {
    return comp[defaultkey];
  }
  if (section && section[sectionKey]) {
    return section[sectionKey];
  }
  return '';
};

const LabelField = (props) => {
  const { component, onChangeProperty, excludeLink } = props;
  const Comps = [
    <TextField
      key={`${component.id}_label`}
      label={TEXT_LABEL}
      placeholder={TEXT_LABEL}
      value={getDefaultValue(LABEL_TEXT, 'name')(component)}
      multiline
      onChange={e => onChangeProperty(component.id, LABEL_TEXT, e.target.value)}
    />,
  ];
  if (excludeLink) return Comps;
  Comps.push(
    <TextField
      key={`${component.id}_link`}
      label={LINK_LABEL}
      placeholder={LINK_LABEL}
      value={getDefaultValue(LABEL_LINK, 'path')(component)}
      onChange={e => onChangeProperty(component.id, LABEL_LINK, e.target.value)}
    />,
  );
  return Comps;
};

LabelField.propTypes = {
  component: PropTypes.object.isRequired,
  onChangeProperty: PropTypes.func.isRequired,
  excludeLink: PropTypes.bool,
};

LabelField.defaultProps = {
  excludeLink: false,
};

export default connect(
  () => ({}),
  {},
)(LabelField);
