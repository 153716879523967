import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockContainer from '../FieldLockContainer';
import ImageSelector from '../../ui/media/ImageSelector';
import { setLocalProp } from '../../../actions/dataState';
import { MEDIA_IMAGE, PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT } from '../../../constants/media/media';
import LocalCaption from '../../ui/LocalCaption';
import { makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';

const FieldLockImage = (props) => {
  const {
    label,
    field,
    required,
    hasCaption,
    hasCopyright,
    children,
    image,
    lockValue,
    setLocalProp: setProp,
  } = props;
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  return (
    <FieldLockContainer field={field} lockValue={lockValue}>
      <ImageSelector
        selectImage={(imageData) => {
          const data = imageData;
          if (hasCaption) {
            data[PROP_LOCAL_CAPTION] = data.caption ? `<p>${data.caption}</p>` : '';
          }
          if (hasCopyright) {
            data[PROP_LOCAL_COPYRIGHT] = data.copyright || '';
          }
          setProp(
            prop,
            {
              type: MEDIA_IMAGE,
              data,
            },
            propChain,
          );
        }}
        onRemove={() => setProp(
          prop,
          {},
          propChain,
        )}
        label={label}
        image={image?.data || null}
        required={required}
        showEditDialog
      />
      {hasCaption && image?.data &&
        <LocalCaption
          data={image.data}
          hideLocalCopyright={!hasCopyright}
          onChange={([localProp, value]) => setProp(
            localProp,
            value,
            [...propChain, prop, 'data'],
          )}
        />
      }
      {children && children}
    </FieldLockContainer>
  );
};

FieldLockImage.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  required: PropTypes.bool,
  hasCaption: PropTypes.bool,
  hasCopyright: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
  image: PropTypes.object,
  lockValue: PropTypes.object,
};

FieldLockImage.defaultProps = {
  label: 'Add image',
  required: false,
  hasCaption: false,
  hasCopyright: false,
  children: null,
  image: null,
  lockValue: null,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  return {
    image: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockImage);
