import { PURGE } from 'redux-persist';
import {
  USER_LOADED,
} from 'constants/actionTypes';
import { USER_SET_MFA_MISSING } from 'constants/actionTypes/user';

const defaultState = {
  user: {
    uid: '',
    name: '',
    image: '',
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOADED:
      return { ...state, user: typeof action.value[0] !== 'undefined' ? action.value[0] : {}};

    case USER_SET_MFA_MISSING:
      // set the mfa user in here rather than in the login object to avoid login issues
      // @todo rework this logic to be simpler
      return  {
        ...state,
        user: {
          ...state.user,
          uid: action.value.uid,
        },
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
