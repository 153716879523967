import { ofType } from 'redux-observable';
import {
  map,
  withLatestFrom,
  switchMap,
  filter,
  mapTo,
  merge,
  tap,
  ignoreElements,
  mergeMap,
  takeUntil,
  zip, takeWhile,
  startWith,
} from 'rxjs/operators';
import { of, forkJoin, interval, from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router';
import moment from 'moment';

import generateKey from 'utils/generateKey';
import { serialize } from 'utils/urlHelper';

import {
  LIVEBLOG_TYPE_POST,
  LIVEBLOG_TYPE_CONTAINER,
  PIN_WEIGHT_KEY, DISPLAY_NAME_KEY, POST_FIELD_AUTHOR,
} from 'constants/liveblog';
import {
  PAGE_ENTER_ARTICLE_CREATE,
  PAGE_ENTER_ARTICLE_EDIT,
  PAGE_ENTER_LIVEBLOG_EDIT,
} from 'constants/actionTypes/route';
import { MARKUP, TWITTER } from 'constants/builder/builder';
import {
  ARTICLE_EDIT_DISPOSE,
} from 'constants/actionTypes/article';
import { WS_LIVEBLOG_CONTAINER, WS_LIVEBLOG_LIST } from 'constants/actionTypes/ws';
import { FIELD_LIVEBLOG } from 'constants/article/articleFields';
import {
  LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST,
  LIVEBLOG_EDIT_POST,
  LIVEBLOG_EDIT_POST_SUCCESS,
  LIVEBLOG_FETCH_CONTAINER_REJECTED,
  LIVEBLOG_FETCH_CONTAINER_SUCCESS,
  LIVEBLOG_FETCH_FUTURE_POSTS_REJECTED,
  LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS,
  LIVEBLOG_FETCH_POSTS,
  LIVEBLOG_FETCH_POSTS_FOR_ARTICLE,
  LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_REJECTED,
  LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS,
  LIVEBLOG_POST_PIN_UPDATE,
  LIVEBLOG_POST_PIN_UPDATE_REJECTED,
  LIVEBLOG_POST_PINNED_SORT,
  LIVEBLOG_POST_PINNED_SORT_REJECTED,
  LIVEBLOG_REMOVE_POST,
  LIVEBLOG_REMOVE_POST_REJECTED,
  LIVEBLOG_REMOVE_POST_SUCCESS,
  LIVEBLOG_SAVE_CONTAINER,
  LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED,
  LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS,
  LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE,
  LIVEBLOG_SAVE_CONTAINER_REJECTED,
  LIVEBLOG_SAVE_CONTAINER_SUCCESS, LIVEBLOG_SAVE_POST,
  LIVEBLOG_SAVE_POST_FAILURE,
  LIVEBLOG_SAVE_POST_SUCCESS,
  LIVEBLOG_SAVE_NO_ACTION_PAYLOAD,
  LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST,
  LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_REJECTED,
  LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_SUCCESS,
  LIVEBLOG_START_WATCH_SCHEDULE_POST,
} from 'constants/actionTypes/liveblog';

import { setLocalProp } from 'actions/dataState';
import { setLiveblogProperty } from 'actions/liveblog';

import apiCatchError, { showSuccessNotification, showWarningNotification } from '../helper/notification';

export const scrollToTheTop = action$ => action$.pipe(
  ofType(LIVEBLOG_EDIT_POST),
  tap(() => window.scroll(0, 0)),
  ignoreElements(),
);

const convertTwitterBlockquoteMarkupToUrl = blockquote =>
  blockquote.match(/https:\/\/twitter\.com.+\/status\/\d+/)[0];

export const saveLiveblogPostData = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_POST),
  withLatestFrom(state$),
  map(([, state]) => state),
  filter(({ liveblog: { currentContainerFromServer } }) => currentContainerFromServer),
  switchMap(({
    liveblog: {
      body, postTitle, currentContainerFromServer, currentEditedPostId, scheduleDateTime, posts,
      displayName,
    },
    login: { user: { uid } },
  }) => {
    const { id: containerId } = currentContainerFromServer.data;
    let extra = {
      [DISPLAY_NAME_KEY]: displayName,
    };
    const payload = {
      name: [{ value: containerId }],
      field_liveblog_container: [{
        target_id: containerId,
        target_type: 'flowz_liveblog',
      }],
      field_title: [
        {
          value: postTitle,
        },
      ],
      user_id: [{
        target_id: uid,
        target_type: 'user',
      }],
      field_post_json: [
        {
          value: JSON.stringify(body.map((comp) => {
            // clean up
            if (comp.type === 'markup') {
              delete comp.editorState;
            }
            return comp;
          })),
        },
      ],
      field_schedule_post_time: [{
        value: scheduleDateTime,
      }],
      bundle: [
        {
          target_id: 'post',
          target_type: 'flowz_liveblog_type',
        },
      ],
      field_extra_json: [{
        value: JSON.stringify(extra),
      }],
    };

    if (currentEditedPostId) {
      payload.id = [{ value: currentEditedPostId }];
      const currentPost = posts.find(({ data: { id } }) => id === currentEditedPostId);
      if (currentPost && !Array.isArray(currentPost.data.extra)) {
        extra = { ...currentPost.data.extra, ...extra };
      }
      payload.field_extra_json = [{
        value: JSON.stringify(extra),
      }];
      return ajax.patch(
        `/api/liveblog/${LIVEBLOG_TYPE_POST}/${currentEditedPostId}`,
        payload,
        { 'Content-Type': 'application/json' },
      ).pipe(
        map(({ response }) => ({
          type: LIVEBLOG_SAVE_POST_SUCCESS,
          value: response,
        })),
        apiCatchError(LIVEBLOG_SAVE_POST_FAILURE),
      );
    }

    return ajax.post(`/api/liveblog/${LIVEBLOG_TYPE_POST}`, payload, { 'Content-Type': 'application/json' }).pipe(
      map(({ response }) => ({
        type: LIVEBLOG_SAVE_POST_SUCCESS,
        value: response,
      })),
      apiCatchError(LIVEBLOG_SAVE_POST_FAILURE),
    );
  }),
);

export const liveblogContainerSaveCheckBlogPost = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  filter(({ liveblog: { body, postTitle } }) => {
    // basic validation, check if the blog post form is empty or not
    if (postTitle.length > 0) return true;

    const [firstComponent] = body;
    if (firstComponent.type === MARKUP && firstComponent.data[MARKUP].length > 0) {
      return true;
    }

    return body.length > 1;
  }),
  mapTo({ type: LIVEBLOG_SAVE_POST }),
);

export const saveEditedLiveblogPostData = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_POST),
  withLatestFrom(state$),
  map(([, state]) => state),
  filter(({ liveblog: { currentEditedPostId } }) => currentEditedPostId),
  switchMap(({ liveblog: { body, currentEditedPostId } }) => {
    const value = {
      id: currentEditedPostId,
      body,
      updatedTime: new Date().toString(),
      author: {
        name: '',
      },
    };
    // make api call here to save the edited post
    return of({
      type: LIVEBLOG_EDIT_POST_SUCCESS,
      value,
    });
  }),
);

export const saveTwitterLiveblogPostData = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST),
  withLatestFrom(state$),
  filter(([, { liveblog: { currentContainerFromServer } }]) => currentContainerFromServer),
  switchMap(([{ value: twitterUrl }, {
    liveblog: {
      currentContainerFromServer,
    },
    login: { user: { uid } },
  }]) => {
    const { id: containerId } = currentContainerFromServer.data;
    const payload = {
      name: [{ value: containerId }],
      field_liveblog_container: [{
        target_id: containerId,
        target_type: 'flowz_liveblog',
      }],
      user_id: [{
        target_id: uid,
        target_type: 'user',
      }],
      field_schedule_post_time: [{
        value: 0,
      }],
      field_post_json: [
        {
          value: JSON.stringify([{
            type: TWITTER,
            data: {
              url: twitterUrl,
            },
          }]),
        },
      ],
      bundle: [
        {
          target_id: 'post',
          target_type: 'flowz_liveblog_type',
        },
      ],
    };

    return ajax.post(`/api/liveblog/${LIVEBLOG_TYPE_POST}`, payload, { 'Content-Type': 'application/json' }).pipe(
      map(({ response }) => ({
        type: LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_SUCCESS,
        value: response,
      })),
      apiCatchError(LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_REJECTED),
    );
  }),
);

export const updateLiveblogPostPin = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_POST_PIN_UPDATE),
  withLatestFrom(state$),
  filter(([
    { value: pinId },
    { liveblog: { posts } }]) => posts.some(({ data: { id } }) => id === pinId),
  ),
  switchMap(([{ value: pinId }, { liveblog: { posts, currentContainerFromServer } }]) => {
    const { id: containerId } = currentContainerFromServer.data;
    const { data } = posts.find(({ data: { id } }) => id === pinId);
    let extra = {};
    let pin = 1;
    if (!Array.isArray(data.extra) && data.extra !== null) {
      extra = data.extra;
      pin = Number.isInteger(parseInt(extra[PIN_WEIGHT_KEY], 10)) ? null : 1;
    }
    const payload = {
      bundle: [
        {
          target_id: 'post',
          target_type: 'flowz_liveblog_type',
        },
      ],
      field_liveblog_container: [{
        target_id: containerId,
      }],
      field_extra_json: [{
        value: JSON.stringify({
          ...extra,
          [PIN_WEIGHT_KEY]: pin,
        }),
      }],
    };

    return ajax.patch(
      `/api/liveblog/${LIVEBLOG_TYPE_POST}/${pinId}`,
      payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mapTo({ type: LIVEBLOG_FETCH_POSTS }),
      apiCatchError(LIVEBLOG_POST_PIN_UPDATE_REJECTED),
    );
  }),
);

export const updatePinSorting = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_POST_PINNED_SORT),
  withLatestFrom(state$, (a, b) => b),
  switchMap(({ liveblog: { posts, currentContainerFromServer } }) => {
    const { id: containerId } = currentContainerFromServer.data;
    const pinPosts = posts
      .filter(({ data: { extra } }) => Number.isInteger(parseInt(extra[PIN_WEIGHT_KEY], 10)));

    const requests = pinPosts.map(({ data: { id, extra } }, index, list) => {
      const payload = {
        bundle: [
          {
            target_id: 'post',
            target_type: 'flowz_liveblog_type',
          },
        ],
        field_liveblog_container: [{
          target_id: containerId,
        }],
        field_extra_json: [{
          value: JSON.stringify({
            ...extra,
            [PIN_WEIGHT_KEY]: list.length - index,
          }),
        }],
      };
      return ajax.patch(
        `/api/liveblog/${LIVEBLOG_TYPE_POST}/${id}`,
        payload,
        { 'Content-Type': 'application/json' },
      ).pipe(
        apiCatchError(),
      );
    });

    return forkJoin(requests).pipe(
      mapTo({ type: LIVEBLOG_FETCH_POSTS }),
      apiCatchError(LIVEBLOG_POST_PINNED_SORT_REJECTED),
    );
  }),
);

const fetchLiveblogPosts = (id, cachedParams) => {
  const requests = [
    ajax.getJSON(`/api/liveblog/one/${id}?${serialize(cachedParams)}`).pipe(
      filter(response => Array.isArray(response.posts)),
      map(response => ({
        type: LIVEBLOG_FETCH_CONTAINER_SUCCESS,
        value: {
          ...response,
          posts: response.posts.map((post => ({
            ...post,
            postJson: post.postJson.map((item) => {
              const component = item;
              if (component.type === TWITTER && /<blockquote/.test(component.data.url)) {
                component.data.url = convertTwitterBlockquoteMarkupToUrl(component.data.url);
              }
              if (!component.id) {
                return {
                  ...component,
                  id: generateKey(),
                };
              }
              return component;
            }),
          }))),
        },
      })),
      apiCatchError(LIVEBLOG_FETCH_CONTAINER_REJECTED),
    ),
    ajax.getJSON(`/api/liveblog/one/${id}?future=true`).pipe(
      filter(response => Array.isArray(response.posts)),
      map(({ posts }) => ({
        type: LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS,
        value: posts,
      })),
      apiCatchError(LIVEBLOG_FETCH_FUTURE_POSTS_REJECTED),
    ),
  ];
  return forkJoin(requests).pipe(
    mergeMap(results => from(results)),
  );
};

export const fetchLiveblogContainer = (action$, state$) => {
  let cachedParams = { cms: true };
  let cachedLiveblogContainerid = null;
  const postUpdated$ = action$.pipe(ofType(
    LIVEBLOG_SAVE_POST_SUCCESS, LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_SUCCESS,
  ));
  const postRemoved$ = action$.pipe(ofType(LIVEBLOG_REMOVE_POST_SUCCESS));
  const containerUpdated$ = action$.pipe(
    ofType(LIVEBLOG_SAVE_CONTAINER_SUCCESS),
  );
  return action$.pipe(
    ofType(LIVEBLOG_FETCH_POSTS),
    zip(action$.pipe(ofType(PAGE_ENTER_LIVEBLOG_EDIT))),
    mergeMap(([action]) =>
      action$.pipe(
        startWith(action),
        ofType(LIVEBLOG_FETCH_POSTS),
        merge(
          postUpdated$,
          postRemoved$,
          containerUpdated$,
          action$.pipe(ofType(WS_LIVEBLOG_LIST, WS_LIVEBLOG_CONTAINER)),
        ),
        withLatestFrom(state$),
        switchMap(([innerAction]) => {
          switch (innerAction.type) {
            case LIVEBLOG_FETCH_POSTS: {
              if (innerAction.value) {
                const [id, param] = innerAction.value;
                cachedParams = param;
                cachedParams.cms = true;
                cachedLiveblogContainerid = id;
                return fetchLiveblogPosts(id, cachedParams);
              } else if (cachedParams && cachedLiveblogContainerid) {
                return fetchLiveblogPosts(cachedLiveblogContainerid, cachedParams);
              }
              return showWarningNotification('No liveblog container id is found');
            }
            case LIVEBLOG_SAVE_CONTAINER_SUCCESS:
              return fetchLiveblogPosts(innerAction.value.id[0].value, cachedParams);
            default:
              return fetchLiveblogPosts(action.value[0], cachedParams);
          }
        }),
      ),
    ),
  );
};

export const setDisplayNameToCurrentUser = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_POST_SUCCESS, LIVEBLOG_FETCH_CONTAINER_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  map(({ login: { user: { name } } }) => setLiveblogProperty([POST_FIELD_AUTHOR, name])),
);

export const fetchAfterPostSave = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => {
    let cachedParams = { cms: true };
    let cacheContainerId = null;
    return action$.pipe(
      ofType(
        LIVEBLOG_FETCH_POSTS,
        LIVEBLOG_SAVE_POST_SUCCESS,
        LIVEBLOG_REMOVE_POST_SUCCESS,
      ),
      merge(action$.pipe(
        ofType(WS_LIVEBLOG_LIST),
        withLatestFrom(state$),
        filter(([
          { value },
          { liveblog: { currentContainerFromServer } },
        ]) => currentContainerFromServer !== null && value === currentContainerFromServer.data.id),
      )),
      withLatestFrom(state$),
      switchMap(([action, { liveblog: { currentContainerFromServer } }]) => {
        if (action.type === LIVEBLOG_FETCH_POSTS) {
          if (action.value) {
            cacheContainerId = action.value[0];
            cachedParams = action.value[1];
            cachedParams.cms = true;
          }
          return fetchLiveblogPosts(cacheContainerId, cachedParams);
        }
        if (currentContainerFromServer) {
          return fetchLiveblogPosts(currentContainerFromServer.data.id, cachedParams);
        }
        // This happens when a new liveblog container is saved from the article
        // (new liveblog state), ignore it as the article will be disposed.
        return of({ type: LIVEBLOG_SAVE_NO_ACTION_PAYLOAD });
      }),
      takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
    );
  }),
);

export const watchFuturePosts = (action$, state$) => action$.pipe(
  zip(
    action$.pipe(ofType(LIVEBLOG_START_WATCH_SCHEDULE_POST)),
    action$.pipe(ofType(LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS)),
  ),
  withLatestFrom(state$, ((action, { liveblog: { futurePosts } }) => futurePosts)),
  filter(posts => posts.length > 0),
  mergeMap((posts) => {
    const futurePosts = posts.map(({ data: { scheduleTime } }) => parseInt(scheduleTime, 10));
    return interval(5000).pipe(
      takeWhile(() => futurePosts.length > 0),
      filter(() => futurePosts.some(futurePostTime => futurePostTime < moment().unix())),
      mapTo({ type: LIVEBLOG_FETCH_POSTS }),
      takeUntil(action$.pipe(ofType(LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST))),
    );
  }),
);

export const fetchLiveblogForKeypointOnArticleComponent = action$ => action$.pipe(
  ofType(LIVEBLOG_FETCH_POSTS_FOR_ARTICLE),
  switchMap((action) => {
    const { id, ...rest } = action.value;
    return ajax.get(`/api/liveblog/one/${id}?${serialize(rest)}`).pipe(
      filter(({ response }) => Array.isArray(response.posts)),
      map(({ response }) => {
        const data = {
          ...response,
          posts: response.posts.map(post => ({
            ...post,
            postJson: post.postJson.map((component) => {
              if (component.type === TWITTER && /<blockquote/.test(component.data.url)) {
                return {
                  ...component,
                  data: {
                    ...component.data,
                    url: convertTwitterBlockquoteMarkupToUrl(component.data.url),
                  },
                };
              }
              return component;
            }),
          })),
        };
        return ({
          type: LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS,
          value: data,
        });
      }),
      apiCatchError(LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_REJECTED),
    );
  }),
);

export const fetchLiveblogContainerError = action$ => action$.pipe(
  ofType(LIVEBLOG_FETCH_CONTAINER_REJECTED),
  mergeMap(showWarningNotification('Liveblog could not be loaded.')),
);

export const redirectLiveblogContainerSaveSuccess = action$ => action$.pipe(
  ofType(LIVEBLOG_SAVE_CONTAINER_SUCCESS),
  map(({ value: { id: [{ value }] } }) => push(`/liveblog/edit/2/${value}`)),
);

export const removeLiveblogPostData = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_REMOVE_POST),
  withLatestFrom(state$),
  filter(() => window.confirm('Please confirm you want to remove this post?')),
  switchMap(([{ value }, { liveblog: { currentContainerFromServer } }]) => {
    const [id, type] = value;
    return ajax({
      url: `/api/liveblog/${type}/${id}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: {
        field_liveblog_container: [{
          target_id: currentContainerFromServer.data.id,
        }],
        bundle: [
          {
            target_id: 'post',
            target_type: 'flowz_liveblog_type',
          },
        ],
      },
    }).pipe(
      map(({ response }) => ({
        type: LIVEBLOG_REMOVE_POST_SUCCESS,
        value: response,
      })),
      apiCatchError(LIVEBLOG_REMOVE_POST_REJECTED),
    );
  }),
);

export const saveLiveblogContainer = (action$, state$) => action$.pipe(
  ofType(
    LIVEBLOG_SAVE_POST, LIVEBLOG_SAVE_CONTAINER, LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE,
  ),
  withLatestFrom(state$),
  filter(([, { liveblog: { currentContainerFromServer } }]) => !currentContainerFromServer),
  switchMap(([action, {
    liveblog: {
      status, name, keyPoints,
    },
  }]) => {
    const payload = {
      field_status: [{ value: status }],
      name: [{ value: name }],
      bundle: [{
        target_id: 'container',
        target_type: 'flowz_liveblog_type',
      }],
      field_key_points: [{
        value: JSON.stringify(keyPoints),
      }],
    };

    let nextSuccessAction = LIVEBLOG_SAVE_CONTAINER_SUCCESS;
    let nextRejectedAction = LIVEBLOG_SAVE_CONTAINER_REJECTED;

    if (
      action.type === LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE
      || action.type === LIVEBLOG_SAVE_POST
    ) {
      nextSuccessAction = LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS;
      nextRejectedAction = LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED;
    }
    return ajax.post(`/api/liveblog/${LIVEBLOG_TYPE_CONTAINER}`, payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => {
        const next = {
          type: nextSuccessAction,
          value: response,
        };
        // make sure to save the liveblog id to article before send success signal
        if (nextSuccessAction === LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS) {
          return from([
            setLocalProp(FIELD_LIVEBLOG, response.id[0].value),
            next,
          ]);
        }
        return of(next);
      }),
      apiCatchError(nextRejectedAction),
    );
  }),
);

export const editLiveblogContainer = (action$, state$) => action$.pipe(
  ofType(LIVEBLOG_SAVE_CONTAINER, LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE),
  withLatestFrom(state$),
  filter(([, { liveblog: { currentContainerFromServer } }]) => currentContainerFromServer),
  switchMap(([action, {
    liveblog: {
      currentContainerFromServer, keyPoints, name, status,
    },
  }]) => {
    const { id } = currentContainerFromServer.data;
    const payload = {
      field_status: [{ value: status }],
      name: [{ value: name }],
      bundle: [{
        target_id: 'container',
        target_type: 'flowz_liveblog_type',
      }],
      field_key_points: [{
        value: JSON.stringify(keyPoints),
      }],
    };
    let nextSuccessAction = LIVEBLOG_SAVE_CONTAINER_SUCCESS;
    let nextRejectedAction = LIVEBLOG_SAVE_CONTAINER_REJECTED;

    if (action.type === LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE) {
      nextSuccessAction = LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS;
      nextRejectedAction = LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED;
    }

    return ajax.patch(
      `/api/liveblog/${LIVEBLOG_TYPE_CONTAINER}/${id}`,
      payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      map(({ response }) => ({
        type: nextSuccessAction,
        value: response,
      })),
      apiCatchError(nextRejectedAction),
    );
  }),
);

export const notificationOnLiveblogSave = action$ => action$.pipe(
  ofType(LIVEBLOG_SAVE_CONTAINER_SUCCESS),
  mergeMap(showSuccessNotification('Liveblog is successfully saved.')),
);

export const notificationOnLiveblogPostSave = action$ => action$.pipe(
  ofType(LIVEBLOG_SAVE_POST_SUCCESS),
  mergeMap(showSuccessNotification('Liveblog post is successfully saved.')),
);
