import React from 'react';
import PropTypes from 'prop-types';
import MediaRatio from '../MediaRatio';
import { youtubeParser } from '../../helper/media';

const YouTubeEmbed = ({ data }) => {
  const youtubeId = data.id || youtubeParser(data.url);
  return (
    <MediaRatio width={'100%'}>
      <iframe
        title={'YouTube embed preview'}
        id="ytplayer"
        type="text/html"
        src={`https://www.youtube.com/embed/${youtubeId}?autoplay=0`}
        frameBorder={0}
      />
    </MediaRatio>
  );
};

YouTubeEmbed.propTypes = {
  data: PropTypes.object.isRequired,
};

export default YouTubeEmbed;

