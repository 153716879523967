import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { FormGroup } from '@material-ui/core';

import { DropZoneDisabled } from '../../../ui/DropZone';
import ArticleDropZoneGroup from './ArticleDropZoneGroup';

import { CONTENT_SOURCE, CONTENT_SOURCE_MANUAL, CONTENT_SOURCE_MANUAL_LIST_SECTION } from '../../constants';

const styles = theme => ({
  root: {
    flexDirection: 'row',
    margin: theme.spacing(1, -0.5, 0, -0.5),
  },
});

const AutoSlots = (props) => {
  const { count } = props;
  const slots = [];
  for (let i = 0; i < count; i += 1) {
    slots.push(<DropZoneDisabled key={`auto-${i}`} />);
  }
  return slots;
};

const ManualSlots = (props) => {
  const { component, manualList, indexes, count } = props;
  const [isActive, setActive] = useState(!!component[CONTENT_SOURCE_MANUAL_LIST_SECTION]);
  useEffect(() => {
    setActive(!!component[CONTENT_SOURCE_MANUAL_LIST_SECTION]);
  }, [component[CONTENT_SOURCE_MANUAL_LIST_SECTION]]);
  const slots = [];
  if (isActive) {
    slots.push(
      <ArticleDropZoneGroup
        key={'manual'}
        indexes={indexes}
        manualList={manualList}
        component={component}
        slotCount={count}
      />,
    );
  } else {
    for (let i = 0; i < count; i += 1) {
      slots.push(<DropZoneDisabled key={`disabled-${i}`} />);
    }
  }
  return slots;
};

const ArticlesFormGroup = (props) => {
  const { classes, component, manualList, indexes, count, position } = props;
  const ArticleSlots = (component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL && manualList)
    ? ManualSlots : AutoSlots;
  return (
    <FormGroup className={classes.root}>
      <ArticleSlots
        component={component}
        manualList={manualList}
        indexes={indexes}
        count={count}
        position={position}
      />
    </FormGroup>
  );
};

ArticlesFormGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  position: PropTypes.number.isRequired,
  manualList: PropTypes.object,
  indexes: PropTypes.array,
};

ArticlesFormGroup.defaultProps = {
  manualList: null,
  indexes: [],
};

export default withStyles(styles)(ArticlesFormGroup);
