import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import DropZoneArticle from '../../ui/dropzones/DropZoneArticle';
import FeedItem from './FeedItem';

const styles = theme => ({
  root: {
    minHeight: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '& .drop-disabled': {
      display: 'none',
    },
  },
});

const FeedList = (props) => {
  const { classes, items, dropProcessor, handleDrop, removeItem } = props;
  return (
    <div className={classes.root}>
      {items.length > 0 && items.map((item, index) => (
        <React.Fragment key={index}>
          <DropZoneArticle
            dropHandler={handleDrop}
            dataProcessor={dropProcessor(items.length - index)}
          ><div /></DropZoneArticle>
          <FeedItem item={item} removeItem={removeItem} />
        </React.Fragment>
      ))}
      <DropZoneArticle
        dropHandler={handleDrop}
        dataProcessor={dropProcessor(0)}
      ><div /></DropZoneArticle>
    </div>
  );
};

FeedList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  dropProcessor: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(FeedList);
