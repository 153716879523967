import { ofType } from 'redux-observable';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import apiCatchError, { showSuccessNotification } from '../helper/notification';

import {
  USER_COMMENTS_FETCH,
  USER_COMMENTS_FETCH_SUCCESS,
  USER_COMMENTS_FETCH_REJECTED,
  USER_COMMENTS_DELETE,
  USER_COMMENTS_DELETE_SUCCESS,
  USER_COMMENTS_DELETE_REJECTED,
  USER_COMMENTS_UNFLAG,
  USER_COMMENTS_UNFLAG_SUCCESS,
  USER_COMMENTS_UNFLAG_REJECTED,
} from '../../constants/actionTypes/service/userComments';
import { queryStringify } from '../../utils/urlHelper';

export const fetchUserComments = (action$, state$) => action$.pipe(
  ofType(USER_COMMENTS_FETCH),
  withLatestFrom(state$),
  switchMap(([, { frame: { selectedPublication: { domain } }, userComments: { filter } }]) => {
    const url = filter
      ? `/api/service/user-comments/${domain}?${queryStringify(filter)}`
      : `/api/service/user-comments/${domain}`;
    return ajax.get(
      url, { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => {
        const {
          Items: items,
          Count: total,
          LastEvaluatedKey: lastKey,
        } = response?.res || {};
        return of({
          type: USER_COMMENTS_FETCH_SUCCESS,
          value: {
            items,
            total,
            lastKey,
          },
        });
      }),
      apiCatchError(USER_COMMENTS_FETCH_REJECTED),
    );
  }),
);

export const unflagUserComment = (action$, state$) => action$.pipe(
  ofType(USER_COMMENTS_UNFLAG),
  withLatestFrom(state$),
  switchMap(([{ value }, { frame: { selectedPublication: { domain } } }]) => ajax.post(
    `/api/service/user-comments/unflag/${domain}}`,
    value,
    { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(() => of({
      type: USER_COMMENTS_UNFLAG_SUCCESS,
      value,
    })),
    apiCatchError(USER_COMMENTS_UNFLAG_REJECTED),
  )),
);

export const unflagUserCommentNotification = action$ => action$.pipe(
  ofType(USER_COMMENTS_UNFLAG_SUCCESS),
  mergeMap(showSuccessNotification('Comment was successfully unflagged.')),
);

export const deleteUserComment = (action$, state$) => action$.pipe(
  ofType(USER_COMMENTS_DELETE),
  withLatestFrom(state$),
  switchMap(([{ value }, { frame: { selectedPublication: { domain } } }]) => ajax.delete(
    `/api/service/user-comments/${domain}?${queryStringify(value)}`,
    { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(() => of({
      type: USER_COMMENTS_DELETE_SUCCESS,
      value,
    })),
    apiCatchError(USER_COMMENTS_DELETE_REJECTED),
  )),
);

export const deleteUserCommentNotification = action$ => action$.pipe(
  ofType(USER_COMMENTS_DELETE_SUCCESS),
  mergeMap(showSuccessNotification('Comment was successfully deleted.')),
);

export const updateUserComment = action$ => action$.pipe(
  ofType(USER_COMMENTS_DELETE_SUCCESS),
  switchMap(() => of({
    type: USER_COMMENTS_FETCH,
  })),
);
