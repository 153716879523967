import { ofType } from 'redux-observable';
import { map, switchMap, debounceTime, withLatestFrom, distinctUntilChanged } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import apiCatchError from 'epics/helper/notification';
import { queryStringify } from 'utils/urlHelper';
import {
  DAILYMOTION_SEARCH,
  DAILYMOTION_SEARCH_REJECTED,
  DAILYMOTION_SEARCH_SUCCESS,
  DAILYMOTION_UPLOAD,
  DAILYMOTION_UPLOAD_SUCCESS,
  DAILYMOTION_UPLOAD_REJECTED,
  DAILYMOTION_FETCH_CHANNELS,
  DAILYMOTION_FETCH_CHANNELS_SUCCESS,
  DAILYMOTION_FETCH_CHANNELS_REJECTED,
} from 'constants/actionTypes/integration/dailymotion';
import { DAILYMOTION_VIDEO } from 'components/embed/DailyMotionLibrary';

export const onSearchVideo = (action$, state$) => action$.pipe(
  ofType(DAILYMOTION_SEARCH),
  debounceTime(750),
  distinctUntilChanged((prev, curr) => prev.value === curr.value),
  withLatestFrom(state$),
  switchMap(([
    { value },
    { frame: { selectedPublication: { publicationConfig: { dailyMotionConfig:  { userId } } } } },
  ]) =>
    ajax.get(`/api/dailymotion/videos/${userId}?${queryStringify(value)}`).pipe(
      map(({ response }) => ({
        type: DAILYMOTION_SEARCH_SUCCESS,
        value: response,
      })),
      apiCatchError(DAILYMOTION_SEARCH_REJECTED),
    ),
  ),
);

export const onFetchChannels = action$ => action$.pipe(
  ofType(DAILYMOTION_FETCH_CHANNELS),
  switchMap(() =>
    ajax.get('/api/dailymotion/channels').pipe(
      map(({ response: { list } }) => ({
        type: DAILYMOTION_FETCH_CHANNELS_SUCCESS,
        value: list || [],
      })),
      apiCatchError(DAILYMOTION_FETCH_CHANNELS_REJECTED),
    ),
  ),
);

export const onUploadVideo = (action$, state$) => action$.pipe(
  ofType(DAILYMOTION_UPLOAD),
  withLatestFrom(state$),
  switchMap(([
    { value: { data: { file, title, channel, tags, forKids }, callback } },
    { frame: { selectedPublication: { publicationConfig: { dailyMotionConfig:  { apiKey, apiSecret } } } } },
  ]) => {
    const fd = new FormData();
    fd.append('video', file[0]);
    fd.append('apiKey', apiKey);
    fd.append('apiSecret', apiSecret);
    fd.append('title', title);
    fd.append('channel', channel);
    fd.append('tags', tags);
    fd.append('forKids', forKids);
    return ajax.post('/api/dailymotion/video', fd, { enctype: 'multipart/form-data' }).pipe(
      map(({ response }) => {
        if (callback) {
          callback({
            url: `${DAILYMOTION_VIDEO}/${response.id}`,
          });
        }
        return ({
          type: DAILYMOTION_UPLOAD_SUCCESS,
          value: response,
        });
      }),
      apiCatchError(DAILYMOTION_UPLOAD_REJECTED),
    );
  }),
);
