import BaseEntity from './common/BaseEntity';

import {
  VIAFOURA_NOTIFICATION_PAYLOAD_FIELDS,
  VIAFOURA_NOTIFICATION_EXTRA_FIELDS,
  VIAFOURA_NOTIFICATION_FIELD_MAPPING,
  FIELD_PUBLICATION,
  FIELD_ARTICLE,
  FIELD_USER,
  FIELD_DATA,
} from '../constants/viafouraNotification/viafouraNotificationFields';

const ENTITY_TYPE = 'flowz_viafoura_log';
const ENTITY_BUNDLE = 'default';

class ViafouraNotificationEntity extends BaseEntity {
  constructor() {
    super(
      ENTITY_TYPE,
      ENTITY_BUNDLE,
      VIAFOURA_NOTIFICATION_FIELD_MAPPING,
      VIAFOURA_NOTIFICATION_PAYLOAD_FIELDS,
      VIAFOURA_NOTIFICATION_EXTRA_FIELDS,
    );
  }

  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_PUBLICATION:
        return data[field] ? [{
          target_id: data[field],
          target_type: 'taxonomy_term',
        }] : [];
      case FIELD_ARTICLE:
        return data[field] ? [{
          target_id: data[field],
          target_type: 'node',
        }] : [];
      case FIELD_USER:
        return data[field] ? [{
          target_id: data[field],
          target_type: 'user',
        }] : [];
      default:
        return super.getPayloadFieldData(data, field);
    }
  }

  getPayloadExtras(data, additional = {}) {
    if (!this.extraFields) {
      return null;
    }
    const extras = {};
    this.extraFields
      .filter(field => Object.keys(data).includes(field) && !!this.dataMapping[field]?.key)
      .forEach((field) => {
        const mapping = this.dataMapping[field];
        extras[mapping.key] = this.getPayloadExtraData(data, field);
      });
    const combined = { ...extras, ...additional };
    if (Object.keys(combined).length === 0) {
      return null;
    }
    return {
      [FIELD_DATA]: [{
        value: JSON.stringify(combined),
      }],
    };
  }
}

export default ViafouraNotificationEntity;
