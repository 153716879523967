export const DIALOG_ON_CLOSE = 'DIALOG_ON_CLOSE';

export const DIALOG_OPEN_PUBLICATION = 'DIALOG_OPEN_PUBLICATION';

export const DIALOG_TOGGLE_ARTICLE_SEARCH = 'DIALOG_TOGGLE_ARTICLE_SEARCH';

export const DIALOG_GALLERY_ON_SEARCH = 'DIALOG_GALLERY_ON_SEARCH';

export const DIALOG_ON_SEARCH_MEDIA = 'DIALOG_ON_SEARCH_MEDIA';
export const DIALOG_ON_EDIT_MEDIA_REQUEST = 'DIALOG_ON_EDIT_MEDIA_REQUEST';
export const DIALOG_ON_UPLOAD_MEDIA = 'DIALOG_ON_UPLOAD_MEDIA';
export const DIALOG_ON_UPLOAD_CHP = 'DIALOG_ON_UPLOAD_CHP';
export const DIALOG_ON_UPLOAD_CHP_SUCCESS = 'DIALOG_ON_UPLOAD_CHP_SUCCESS';
export const DIALOG_ON_UPLOAD_MEDIA_SUCCESS = 'DIALOG_ON_UPLOAD_MEDIA_SUCCESS';
export const DIALOG_ON_UPLOAD_MEDIA_REJECTED = 'DIALOG_ON_UPLOAD_MEDIA_REJECTED';
export const DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS = 'DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS';
export const DIALOG_ON_RESET_EDIT_MEDIA = 'DIALOG_ON_RESET_EDIT_MEDIA';
export const DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED = 'DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED';
export const DIALOG_ON_SEARCH_MEDIA_SUCCESS = 'DIALOG_ON_SEARCH_MEDIA_SUCCESS';
export const DIALOG_ON_SEARCH_MEDIA_REJECTED = 'DIALOG_ON_SEARCH_MEDIA_REJECTED';

export const DIALOG_IMAGE_MEDIA_DISPOSE = 'DIALOG_IMAGE_MEDIA_DISPOSE';
