import {
  catchError,
  combineLatest,
  filter,
  map,
  mapTo,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { ajax } from 'rxjs/ajax';
import { from, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import { REHYDRATE } from 'redux-persist';

import apiCatchError, {
  assignErrorPayload,
  showSuccessNotification,
} from '../helper/notification';

import { ERROR, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import {
  LAYOUT_COMPONENT_IMAGE_SELECTED,
  LAYOUT_DELETE,
  LAYOUT_DELETE_REJECTED,
  LAYOUT_DELETE_SUCCESS,
  LAYOUT_DISPOSE,
  LAYOUT_FETCH,
  LAYOUT_FETCH_REJECTED,
  LAYOUT_FETCH_SUCCESS,
  LAYOUT_SAVE,
  LAYOUT_SAVE_READY,
  LAYOUT_SAVE_REJECTED,
  LAYOUT_SAVE_SUCCESS,
} from '../../constants/actionTypes/layout';
import { PAGE_ENTER_LAYOUT } from '../../constants/actionTypes/route';
import { FIELD_COMPONENTS, FIELD_COMPONENTS_ORDER } from '../../constants/layout/layoutFields';
import LayoutEntity from '../../entities/LayoutEntity';
import { componentSetProp } from '../../actions/layout';
import {
  MANUAL_LIST_FETCH_FROM_LAYOUT,
} from '../../constants/actionTypes/manualList';
import { FIELD_ID } from '../../constants/common/commonFields';
import { VOCAB_LAYOUTS_API } from '../../constants/vocab';
import { getFromState } from '../../utils/stateHelper';
import { getDefaultLayoutFromVocab } from '../../utils/layoutHelper';

const LAYOUT_CONTENT_TYPE = 'layout';

export const disposeNewLayoutOnRehydrate = (action$) => {
  const rehydrate$ = action$.pipe(
    ofType(REHYDRATE),
  );
  return action$.pipe(
    ofType(PAGE_ENTER_LAYOUT),
    filter(({ value: { id } }) => id === 'new'),
    // wait for the state properly rehydrate
    combineLatest(rehydrate$, locationChange$ => locationChange$),
    mapTo({
      type: LAYOUT_DISPOSE,
    }),
  );
};

export const onLayoutEnter = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  withLatestFrom(state$),
  mergeMap(([{ value: { id, term } }, { layout }]) => {
    const getNewId = () => {
      const defaultLayout = getDefaultLayoutFromVocab(layout, term);
      return defaultLayout?.data?.id || 1;
    };
    return of({
      type: LAYOUT_FETCH,
      value: id === 'new' ? getNewId() : id,
    });
  }),
);

export const fetchLayout = action$ => action$.pipe(
  ofType(LAYOUT_FETCH),
  mergeMap(({ value }) => ajax.getJSON(`/api/layout/${value}`).pipe(
    mergeMap(response => of({
      type: LAYOUT_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(LAYOUT_FETCH_REJECTED),
  )),
);

export const buildEntityFromFetchResponse = action$ => action$.pipe(
  ofType(LAYOUT_FETCH_SUCCESS),
  mergeMap(({ value }) => {
    const layout = new LayoutEntity();
    return of({ type: MANUAL_LIST_FETCH_FROM_LAYOUT, value: layout.getPayloadId(value) });
  }),
);

export const validateBeforeSave = (action$, state$) => action$.pipe(
  ofType(LAYOUT_SAVE),
  withLatestFrom(state$),
  mergeMap(([,
    {
      router: { location: { pathname } },
      login: { user: { uid } },
      localState, serverState,
    },
  ]) => {
    const layoutEntity = new LayoutEntity();
    const errors = layoutEntity.getValidationErrors(localState, serverState);

    if (errors.length > 0) {
      return from([
        { type: LAYOUT_SAVE_REJECTED },
        ...errors.map(({ message }) => ({
          type: SHOW_NOTIFICATION,
          value: {
            message,
            variant: ERROR,
          },
        })),
      ]);
    }

    const pathParams = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);

    if (pathParams[1] !== 'new') {
      return ajax.getJSON(`/api/aws/data-state/${LAYOUT_CONTENT_TYPE}/${pathParams[1]}?excludeUid=${uid}`).pipe(
        mergeMap(({ data }) => {
          if (data?.Items) {
            for (let i = 0; i < data.Items.length; i += 1) {
              const { fieldName } = data.Items[i];
              const params = fieldName.split('_');
              if (getFromState(params, localState)) {
                return from([
                  { type: LAYOUT_SAVE_REJECTED },
                  assignErrorPayload('Unable to save layout, your data is out of date. Refresh the page and try again'),
                ]);
              }
            }
          }
          return of({ type: LAYOUT_SAVE_READY });
        }),
        apiCatchError(LAYOUT_SAVE_REJECTED, 'Api Error. Unable to save this layout.'),
      );
    }

    return of({ type: LAYOUT_SAVE_READY });
  }),
  catchError((ex) => {
    // eslint-disable-next-line no-console
    console.log(ex);
    return from([
      { type: LAYOUT_SAVE_REJECTED },
      assignErrorPayload('Unable to save layout, refresh the page and try again'),
    ]);
  }),
);

export const onSaveNewLayout = (action$, state$) => action$.pipe(
  ofType(LAYOUT_SAVE_READY),
  withLatestFrom(state$),
  filter(([, { router: { location: { pathname } }, layout: { selectedTerm } }]) => {
    const pathParams = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);
    return pathParams[1] === 'new' && selectedTerm;
  }),
  mergeMap(([, {
    localState, serverState,
    router: { location: { pathname } },
    frame: { selectedPublication },
    layout: { selectedTerm },
  }]) => {
    const bundle = pathname.match(/\/layout\/([a-z_]+)\/[a-z0-9]+/);

    delete serverState[FIELD_ID];

    const layoutEntity = new LayoutEntity(VOCAB_LAYOUTS_API[bundle[1]]);
    const payload = layoutEntity.getPayloadFromData(localState, serverState, selectedPublication);
    payload.name = [{
      value: `${selectedPublication.name} / ${selectedTerm.name} / Layout`,
    }];
    payload[`field_${VOCAB_LAYOUTS_API[bundle[1]]}`] = [{
      target_id: Number(selectedTerm.id),
      target_type: 'taxonomy_term',
    }];

    return ajax.post(`/api/layout/${bundle[1]}`, payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => {
        const value = typeof response === 'object' ? response : JSON.parse(response);
        return from([
          push(`/layout/${bundle[1]}/${value.id[0].value}`),
          { type: LAYOUT_SAVE_SUCCESS, value },
        ]);
      }),
      apiCatchError(LAYOUT_SAVE_REJECTED, 'Api Error. Unable to save this layout.'),
    );
  }),
);

export const onLayoutSave = (action$, state$) => action$.pipe(
  ofType(LAYOUT_SAVE_READY),
  withLatestFrom(state$),
  filter(([, { serverState, router: { location: { pathname } } }]) => {
    const pathParams = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);
    return serverState[FIELD_ID] && pathParams[1] == serverState[FIELD_ID];
  }),
  mergeMap(([, state]) => {
    const {
      localState, serverState,
      frame: { socketId },
      layout: { orderUpdated },
      router: { location: { pathname } },
    } = state;
    const bundle = pathname.match(/\/layout\/([a-z_]+)\/[a-z0-9]+/);
    const layoutEntity = new LayoutEntity(VOCAB_LAYOUTS_API[bundle[1]]);
    // hack to get orderUpdate in without adding a new field or expanding field_layout_settings
    if (orderUpdated && orderUpdated > 0) {
      const order = localState[FIELD_COMPONENTS_ORDER] || serverState[FIELD_COMPONENTS_ORDER];
      if (localState?.[FIELD_COMPONENTS]?.[order[0]]) {
        localState[FIELD_COMPONENTS][order[0]].updated = orderUpdated;
      } else {
        serverState[FIELD_COMPONENTS][order[0]].updated = orderUpdated;
      }
    }
    const payload = layoutEntity.getPayloadFromData(localState, serverState);
    return ajax.patch(
      `/api/layout/${bundle[1]}/${layoutEntity.getPayloadId(payload)}?socketId=${socketId}`,
      payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => {
        const value = typeof response === 'object' ? response : JSON.parse(response);
        return of({ type: LAYOUT_SAVE_SUCCESS, value });
      }),
      apiCatchError(LAYOUT_SAVE_REJECTED, 'Unable to save layout, refresh the page and try again'),
    );
  }),
);

export const layoutSaveNotification = action$ => action$.pipe(
  ofType(LAYOUT_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Save successful')),
);

export const deleteLayout = action$ => action$.pipe(
  ofType(LAYOUT_DELETE),
  mergeMap(({
    value: {
      type,
      id,
    },
  }) => ajax.delete(`/api/layout/${type}/${id}`, { 'Content-Type': 'application/json' }).pipe(
    map(() => ({
      type: LAYOUT_DELETE_SUCCESS,
    })),
    apiCatchError(LAYOUT_DELETE_REJECTED),
  )),
);

export const selectdImageToSectionComponent = action$ => action$.pipe(
  ofType(LAYOUT_COMPONENT_IMAGE_SELECTED),
  mergeMap(({ value: [componentId, field, image] }) => {
    const { width, height, url } = image.data;
    if (!width || !height) {
      const img = new Image();
      img.src = url;
      return new Observable((observer) => {
        img.onload = () => {
          observer.next(componentSetProp(
            componentId,
            field,
            { type: 'image', data: { ...image.data, width: img.width, height: img.height } },
          ));
          observer.complete();
        };
      });
    }
    return componentSetProp(componentId, field, { type: 'image', data: image.data });
  }),
);
