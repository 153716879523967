import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  debounceTime,
  withLatestFrom,
  distinctUntilChanged,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  FETCH_HERO_JW_PLAYER_VIDEO_DETAIL,
  FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_SUCCESS,
  FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_REJECTED,
} from '../../../constants/actionTypes';

import apiCatchError from '../../helper/notification';
import { VIDEO_ELEPHANT } from '../../../constants/media/media';
import {
  VIDEO_JWPLAYER_SEARCH,
  VIDEO_JWPLAYER_SEARCH_REJECTED,
  VIDEO_JWPLAYER_SEARCH_SUCCESS,
} from '../../../constants/actionTypes/media';

export const onSearchVideo = (action$, state$) => action$.pipe(
  ofType(VIDEO_JWPLAYER_SEARCH),
  debounceTime(750),
  distinctUntilChanged((prev, curr) => prev.value === curr.value),
  withLatestFrom(state$),
  switchMap(([
    { value }, {
      frame: {
        selectedPublication: {
          publicationConfig: { jwPlayerConfig, videoElephantConfig },
        },
      },
    }]) =>
    ajax.post('/api/jwplayer/playlist', {
      ...value,
      jwPlayerConfig: JSON.stringify(
        value.videoType === VIDEO_ELEPHANT ? videoElephantConfig : jwPlayerConfig,
      ),
    }).pipe(
      map(({ response }) => ({
        type: VIDEO_JWPLAYER_SEARCH_SUCCESS,
        value: response,
      })),
      apiCatchError(VIDEO_JWPLAYER_SEARCH_REJECTED),
    )),
);

export const fetchVideoDetailForHero = action$ => action$.pipe(
  ofType(FETCH_HERO_JW_PLAYER_VIDEO_DETAIL),
  switchMap(({ value }) =>
    ajax.getJSON(`/api/jwplayer/video/${value}`).pipe(
      map(response => ({
        type: FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_SUCCESS,
        value: response,
      })),
      apiCatchError(FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_REJECTED),
    )),
);
