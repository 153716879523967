import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {
  ContextPanel, ContextPanelDetails,
} from '../../common/context/ContextPanel';

const styles = theme => ({
  label: {
    paddingTop: theme.spacing(2),
  },
});

const TaxonomyNoContext = ({ classes }) => (<ContextPanel>
  <ContextPanelDetails variant={'full'}>
    <Typography
      className={classes.label}
    >Select a term from the list to display settings here</Typography>
  </ContextPanelDetails>
</ContextPanel>);

TaxonomyNoContext.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(() => ({}), {})(TaxonomyNoContext));
