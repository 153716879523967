import {
  FEED_SET_CONTEXT,
  FEED_SAVE,
  FEED_FETCH_ALL,
  FEED_HANDLE_DROP,
  FEED_TIMELINE_HANDLE_DROP,
  FEED_TIMELINE_ITEM_PROP_UPDATE,
  FEED_TIMELINE_REMOVE_ITEM,
  FEED_TIMELINE_SAVE,
} from '../constants/actionTypes/feed';

export const setFeedContext = (type, data) => ({ type: FEED_SET_CONTEXT, value: { type, data } });
export const saveFeed = value => ({ type: FEED_SAVE, value });
export const fetchFeeds = () => ({ type: FEED_FETCH_ALL });

export const handleFeedDrop = (effect, value) => ({ type: FEED_HANDLE_DROP, value });

export const saveTimelineFeed = value => ({ type: FEED_TIMELINE_SAVE, value });
export const handleTimelineFeedDrop = (effect, value) =>
  ({ type: FEED_TIMELINE_HANDLE_DROP, value });
export const updateTimelineItemProperty = (id, date, key, value) =>
  ({ type: FEED_TIMELINE_ITEM_PROP_UPDATE, value: { id, date, key, value } });
export const removeTimelineItem = (id, date) =>
  ({ type: FEED_TIMELINE_REMOVE_ITEM, value: { id, date } });

