import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Dialog from './common/Dialog';
import Button from '../ui/buttons/Button';
import Checkbox from '../ui/Checkbox';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  listRoot: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  regionLabel: {
    fontSize: 18,
    paddingLeft: 4,
  },
});


const PublicationsDialog = (props) => {
  const {
    classes,
    onSave,
    publications,
    selectedPublications,
    selectedPublication: { id: currentPublicationId },
    userPublications,
    ...rest
  } = props;
  const [selected, setSelected] = useState([]);
  const [active, setActive] = useState([]);
  useEffect(() => {
    setSelected(selectedPublications);
  }, [selectedPublications]);
  useEffect(() => {
    setActive(userPublications.map(({ id }) => id));
  }, [userPublications]);
  return (<Dialog title={`Publications (${selectedPublications.length})`} maxWidth={'md'} {...rest}>
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Checkbox
            checked={selected.length === publications.length}
            indeterminate={
              selected.length > 0 &&
              selected.length < publications.length
            }
            onClick={(e) => {
              const { checked } = e.target;
              if (checked) {
                setSelected([
                  ...publications.map(({ id }) => id).filter(id => !active.includes(id)),
                  ...active,
                ]);
              } else {
                setSelected(selectedPublications.filter(id =>
                  id === currentPublicationId || !active.includes(id),
                ));
              }
            }}
            label={'Select all'}
          />
          <Divider />
          <ul className={[classes.listRoot].join(' ')}>
            {publications.map(({ name, id }) => (<li key={id}>
              <Checkbox
                checked={selected.includes(id)}
                disabled={
                  String(currentPublicationId) === String(id) ||
                  !active.includes(id)
                }
                onChange={(e) => {
                  const { checked } = e.target;
                  if (checked) {
                    setSelected([...selected, id]);
                  } else {
                    setSelected(selected.filter(pubId => pubId !== id));
                  }
                }}
                name={name}
                label={name}
              />
            </li>))}
          </ul>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction={'row-reverse'}>
          <Button
            variant={'contained'}
            onClick={() => {
              onSave([
                currentPublicationId,
                ...selected.filter(id => id !== currentPublicationId),
              ]);
            }}
          >Save</Button>
        </Grid>
      </Grid>
    </div>
  </Dialog>);
};

PublicationsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  publications: PropTypes.array.isRequired,
  selectedPublications: PropTypes.array,
  userPublications: PropTypes.array,
};

PublicationsDialog.defaultProps = {
  selectedPublications: [],
  userPublications: [],
};

export default withStyles(style)(connect(({
  userCurrent: { user: { publications: userPublications } },
  frame: { selectedPublication, publication },
}) => ({ userPublications, selectedPublication, publications: publication }), {
})(PublicationsDialog));
