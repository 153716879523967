import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import TextField from '../ui/TextField';
import Button from '../ui/buttons/Button';

const AdReplacementDialog = (props) => {
  const { vocab, onSave, ...rest } = props;
  const [name, setName] = useState('');
  return (
    <Dialog title={'Add new'} {...rest}>
      <Grid container direction={'column'} spacing={2}>
        <Grid item xs={12}>
          <TextField label={'Title'} onChange={event => setName(event.target.value)} required />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction={'row-reverse'}>
            <Button
              variant={'contained'}
              onClick={() => onSave({ name })}
            >Save</Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

AdReplacementDialog.propTypes = {
  vocab: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AdReplacementDialog;
