import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_GALLERY } from './defaults';

const deserializeGallery = (options) => {
  const { gallery } = setDefaults(options, DEFAULTS_GALLERY);

  return {
    element: getNodeDeserializer({
      type: gallery.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: gallery.type,
          url,
        };
      },
      rules: [
        { className: gallery.rootProps.className },
      ],
      ...options?.gallery?.deserialize,
    }),
  };
};

export default deserializeGallery;
