export const DATASTATE_DISPOSE = 'DATASTATE_DISPOSE';
export const DATASTATE_SET_DATA = 'DATASTATE_SET_DATA';
export const DATASTATE_SET_PROPERTY = 'DATASTATE_SET_PROPERTY';
export const DATASTATE_UNSET_PROPERTY = 'DATASTATE_UNSET_PROPERTY';

export const DATASTATE_SERVER_DISPOSE_DATA = 'DATASTATE_SERVER_DISPOSE_DATA';
export const DATASTATE_SERVER_SET_DATA = 'DATASTATE_SERVER_SET_DATA';
export const DATASTATE_SERVER_SET_PROPERTY = 'DATASTATE_SERVER_SET_PROPERTY';

export const DATASTATE_EXTERNAL_DISPOSE_DATA = 'DATASTATE_EXTERNAL_DISPOSE_DATA';
export const DATASTATE_EXTERNAL_SET_DATA = 'DATASTATE_EXTERNAL_SET_DATA';
export const DATASTATE_EXTERNAL_SET_PROPERTY = 'DATASTATE_EXTERNAL_SET_PROPERTY';
export const DATASTATE_EXTERNAL_UNSET_PROPERTY = 'DATASTATE_EXTERNAL_UNSET_PROPERTY';

// Local Data
export const DATASTATE_LOCAL_DISPOSE = 'DATASTATE_LOCAL_DISPOSE';
export const DATASTATE_LOCAL_SET_DATA = 'DATASTATE_LOCAL_SET_DATA';
export const DATASTATE_LOCAL_SET_PROPERTY = 'DATASTATE_LOCAL_SET_PROPERTY';
export const DATASTATE_LOCAL_UNSET_PROPERTY = 'DATASTATE_LOCAL_UNSET_PROPERTY';

export const DATASTATE_LOCAL_ADD_KEYED_ITEM = 'DATASTATE_LOCAL_ADD_KEYED_ITEM';
export const DATASTATE_LOCAL_REPLACE_KEYED_ITEM = 'DATASTATE_LOCAL_REPLACE_KEYED_ITEM';
export const DATASTATE_LOCAL_REMOVE_KEYED_ITEM = 'DATASTATE_LOCAL_REMOVE_KEYED_ITEM';
export const DATASTATE_LOCAL_SORT_KEYED_ITEMS = 'DATASTATE_LOCAL_SORT_KEYED_ITEMS';

// @todo rework this concept with the above system for correct external ordering
export const DATASTATE_LOCAL_PROP_ADD_ITEM = 'DATASTATE_LOCAL_PROP_ADD_ITEM';
export const DATASTATE_LOCAL_PROP_REMOVE_ITEM = 'DATASTATE_LOCAL_PROP_REMOVE_ITEM';
export const DATASTATE_LOCAL_PROP_SORT_ITEMS = 'DATASTATE_LOCAL_PROP_SORT_ITEMS';
export const DATASTATE_LOCAL_PROP_SET_PROPERTY = 'DATASTATE_LOCAL_PROP_SET_PROPERTY';
