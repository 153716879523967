import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
  },
  video: {
    maxWidth: '100%',
  },
});

const JWPlayerEmbed = ({ data, classes }) => {
  if (!data.sources) return null;
  const sortedSources = data.sources
    .filter(({ width }) => parseInt(width, 10))
    .sort(({ width: widthA }, { width: widthB }) => widthA - widthB);

  let higestRes = null;
  if (sortedSources.length > 0) {
    higestRes = sortedSources[sortedSources.length - 1];
  }

  if (!higestRes) return null;

  return (<Grid container justify={'center'}>
    <Typography variant={'h6'}>ID: {data.key} / Title: {data.title}</Typography>
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <video width={'100%'} controls className={classes.video} controlsList="nodownload">
      <source src={higestRes.file} type={higestRes.type} />
    </video>
  </Grid>)
};

JWPlayerEmbed.defaultProps = {
};

JWPlayerEmbed.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JWPlayerEmbed);

