import {
  USER_COMMENTS_FETCH,
  USER_COMMENTS_DISPOSE,
  USER_COMMENTS_DELETE,
  USER_COMMENTS_UNFLAG,
  USER_COMMENTS_SET_FILTER,
} from '../../constants/actionTypes/service/userComments';

export const fetchUserComments = value => ({ type: USER_COMMENTS_FETCH, value });
export const disposeUserComments = () => ({ type: USER_COMMENTS_DISPOSE });
export const deleteUserComment = value => ({ type: USER_COMMENTS_DELETE, value });
export const unflagUserComment = value => ({ type: USER_COMMENTS_UNFLAG, value });
export const setUserCommentsFilter = value => ({ type: USER_COMMENTS_SET_FILTER, value });
