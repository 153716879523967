import { ofType } from 'redux-observable';
import {
  switchMap,
  withLatestFrom,
  map,
  mergeMap,
  filter,
  distinctUntilChanged,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { forkJoin, from, of } from 'rxjs';

import {
  POPULAR_VOCAB_REJECTED, POPULAR_VOCAB_SUCCESS,
  SET_ARTICLE_SUGGESTION_TAG, SUGGESTIVE_VOCAB_REJECTED, SUGGESTIVE_VOCAB_SUCCESS,
} from '../../constants/actionTypes';
import apiCatchError from '../helper/notification';
import { TOPICS, VOCAB_TERM_SUGGESTION } from '../../constants/vocab';
import { PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT } from '../../constants/actionTypes/route';

export const searchArticleTermSuggestion = (action$, state$) => action$.pipe(
  ofType(SET_ARTICLE_SUGGESTION_TAG),
  distinctUntilChanged((prev, next) =>
    JSON.stringify(prev.value) === JSON.stringify(next.value),
  ),
  withLatestFrom(state$),
  map(([{ value }, { frame: { selectedPublication: { vocabs } } }]) => {
    const entities = value.map(({ name }) => name).join('|');
    return [entities, vocabs || []];
  }),
  filter(([entities, vocabs]) => !!entities && vocabs.length > 0),
  switchMap(([entities, vocabs]) => {
    const requests = vocabs
      .filter(vocab => VOCAB_TERM_SUGGESTION.includes(vocab))
      .map(vocab =>
        ajax.getJSON(`/api/term-check/${vocab}?items=${entities}`).pipe(
          map(response => [vocab, response || []]),
          apiCatchError(SUGGESTIVE_VOCAB_REJECTED)
        ));

    return forkJoin(requests).pipe(
      mergeMap(results => from(results)),
      map(([vocab, results]) => {
        const keys = Object.keys(results);
        if (keys.length > 0) {
          return [vocab, keys
            .map(key => results[key])
            .filter(key => !!key)
            .slice(0, 10)];
        }
        return [vocab, []];
      }),
      map(result => ({ type: SUGGESTIVE_VOCAB_SUCCESS, value: result })),
      apiCatchError(SUGGESTIVE_VOCAB_REJECTED),
    );
  }),
);

export const searchMostPopularTermForCurrentUser = action$ => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT),
  switchMap(() => ajax.getJSON('/api/user-history').pipe(
    mergeMap((response) => {
      const vocabs = Object.keys(response)
        .map((key) => {
          let mostPopular = [];
          if (response[key]) {
            mostPopular = Object.keys(response[key])
              .map(termKey => response[key][termKey].term)
              .slice(0, 3);
          }
          return [key, mostPopular];
        });

      return from(vocabs).pipe(
        filter(([vocab]) => vocab !== TOPICS),
        map(value => ({
          type: POPULAR_VOCAB_SUCCESS,
          value,
        }),
        ),
      );
    }),
  )),
  apiCatchError(POPULAR_VOCAB_REJECTED),
);
