import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ContentCopy } from 'mdi-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { FIELD_PATH, FIELD_URL_KEYWORDS } from 'constants/article/articleFields';

import IconButton from 'components/ui/buttons/IconButton';
import { ContextPanelItem } from 'components/common/context/ContextPanel';
import { copyToClipboard } from 'utils/helper/clipboard';

const styles = theme => ({
  icon: {
    color: theme.typography.color.secondary,
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
  },
});

const getRelativePath = path => {
  const parts = path.split('/');
  parts.shift();
  parts.shift();
  return parts.join('/');
};

const TOOLTIP = 'Copy URL to clipboard';
const TOOLTIP_DISABLED = 'Save changes for updated URL';

const ArticlePath = ({ classes, path, url, keywords }) => {
  return path
    ? (
      <ContextPanelItem label="URL">
        <Tooltip title={keywords ? TOOLTIP_DISABLED : TOOLTIP} arrow>
          <span><IconButton
            className={classes.icon}
            onClick={() => copyToClipboard(`${url}${url.endsWith('/') ? '' : '/'}${getRelativePath(path)}`)}
            color="inherit"
            disabled={!!keywords}
          ><ContentCopy /></IconButton></span>
        </Tooltip>
      </ContextPanelItem>
    )
    : null;
};

ArticlePath.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  path: PropTypes.string,
  keywords: PropTypes.string,
};

ArticlePath.defaultProps = {
  path: null,
  keywords: null,
};

export default withStyles(styles)(connect(
  ({
    dataState: { [FIELD_PATH]: path },
    localState: { [FIELD_URL_KEYWORDS]: keywords },
    frame: { selectedPublication: { url } },
  }) => ({ path, url, keywords }),
  {},
)(ArticlePath));
