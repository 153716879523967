import HtmlElement from './components/HtmlElement';

export const ELEMENT_HTML = 'html';
export const PROP_MARKUP = 'markup';

export const DEFAULTS_HTML = {
  html: {
    component: HtmlElement,
    type: ELEMENT_HTML,
    rootProps: {
      className: 'slate-html',
    },
  },
};
