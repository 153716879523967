import React from 'react';
import PropTypes from 'prop-types';
import ContextBar from '../common/context/ContextBar';
import TaxonomySettings from './contexts/TaxonomySettingsContext';
import NoContext from './contexts/TaxonomyNoContext';
import TaxonomyMultiSelectContext from './contexts/TaxonomyMultiSelectContext';

const TaxonomySidebar = ({ singleTermSelected, afterDelete, multipleTermSelected, vocab }) => {
  if (singleTermSelected) {
    return (<ContextBar>
      <TaxonomySettings vocab={vocab} currentTerm={singleTermSelected} />
    </ContextBar>);
  }

  if (multipleTermSelected.length > 0) {
    return (<ContextBar>
      <TaxonomyMultiSelectContext
        vocab={vocab}
        terms={multipleTermSelected}
        afterDelete={afterDelete}
      />
    </ContextBar>);
  }

  return (<ContextBar><NoContext /></ContextBar>);
};

TaxonomySidebar.defaultProps = {
  singleTermSelected: null,
  multipleTermSelected: null,
};

TaxonomySidebar.propTypes = {
  singleTermSelected: PropTypes.object,
  multipleTermSelected: PropTypes.array,
  vocab: PropTypes.string.isRequired,
  afterDelete: PropTypes.func.isRequired,
};

export default TaxonomySidebar;
