import { deserializeString, serializeNodes } from '../../../../helper/serializer';
import { ELEMENT_IMAGE, PROP_IMAGE, PROP_CAPTION, PROP_COPYRIGHT, PROP_ALIGN } from './defaults';
import { PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT, IMAGE } from '../../../../../../constants/editor/dataTypes';

export const convertToImage = (
  {
    [PROP_LOCAL_CAPTION]: caption,
    [PROP_LOCAL_COPYRIGHT]: copyright,
    [PROP_ALIGN]: align,
    ...data
  },
) => ({
  type: ELEMENT_IMAGE,
  [PROP_IMAGE]: {
    ...data,
  },
  [PROP_CAPTION]: typeof caption !== 'undefined'
    ? deserializeString(caption) : null,
  [PROP_COPYRIGHT]: typeof copyright !== 'undefined'
    ? copyright : null,
  [PROP_ALIGN]: align || 'none',
  children: [{ text: '' }],
});

export const convertFromImage = (
  {
    [PROP_IMAGE]: image,
    [PROP_CAPTION]: caption,
    [PROP_COPYRIGHT]: copyright,
    [PROP_ALIGN]: align,
  },
) => ({
  type: IMAGE,
  data: {
    ...image,
    [PROP_LOCAL_CAPTION]: caption ? serializeNodes(caption) : '',
    [PROP_LOCAL_COPYRIGHT]: copyright || null,
    [PROP_ALIGN]: align || 'none',
  },
});
