import { PURGE } from 'redux-persist/es/constants';

import {
  DATASTATE_SERVER_SET_DATA,
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_SERVER_SET_PROPERTY,
} from '../../constants/actionTypes/dataState';
import { setToState } from '../../utils/stateHelper';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DATASTATE_SERVER_SET_DATA:
      return {
        ...state,
        ...action.value,
      };
    case DATASTATE_SERVER_SET_PROPERTY: {
      const { prop, value, propChain = [] } = action.value;
      return setToState([...propChain, prop], value, state);
    }
    case DATASTATE_SERVER_DISPOSE_DATA:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
