import { PURGE } from 'redux-persist';
import {
  ARTICLE_STATS_LOADED,
  FETCH_CMS_API_VERSION_SUCCESS,


} from '../../constants/actionTypes';
import {LOGIN_SUCCESS, LOGIN_TRANSITION_COMPLETE} from "../../constants/actionTypes/login";

const defaultState = {
  articleStats: [],
  appVersion: process.env.REACT_APP_CURRENT_RELEASE_TAG || 'Not available on this environment',
  apiVersion: '',
  mode: 'development',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        showTransition: true,
      };

    case LOGIN_TRANSITION_COMPLETE:
      return {
        ...state,
        showTransition: false,
      };

    case FETCH_CMS_API_VERSION_SUCCESS: {
      const {
        version, mode,
      } = action.value;
      return {
        ...state,
        apiVersion: version,
        mode,
      };
    }

    case ARTICLE_STATS_LOADED:
      return { ...state, articleStats: action.value };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
