import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';

import { FIELD_IS_LIVE, FIELD_IS_RESPONSIVE, FIELD_THUMBNAIL } from '../../../../constants/story/storyFields';
import ToggleSwitch from '../../../ui/ToggleSwitch';
import { updateStoryField } from '../../../../actions/story';
import ImageField from '../../storyBuilder/templates/common/ImageField';

const Display = (props) => {
  const {
    updateStoryField: updateField,
    isResponsive,
    isLive,
    thumbnail,
  } = props;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Display</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <ToggleSwitch
          label={'Allow Responsive Display'}
          value={isResponsive}
          onChange={e => updateField(FIELD_IS_RESPONSIVE, e.target.checked)}
        />
        <ToggleSwitch
          label={'Enable Live Story'}
          value={isLive}
          onChange={e => updateField(FIELD_IS_LIVE, e.target.checked)}
        />
        <ImageField
          label={'Story Thumbnail'}
          onSelect={value => updateField(FIELD_THUMBNAIL, {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateField(FIELD_THUMBNAIL, null)}
          image={thumbnail}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

Display.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  isResponsive: PropTypes.bool,
  isLive: PropTypes.bool,
  thumbnail: PropTypes.object,
};

Display.defaultProps = {
  isResponsive: false,
  isLive: false,
  thumbnail: null,
};

export default connect(
  ({
    story: {
      [FIELD_IS_RESPONSIVE]: isResponsive,
      [FIELD_IS_LIVE]: isLive,
      [FIELD_THUMBNAIL]: thumbnail,
    },
  }) => ({ isResponsive, isLive, thumbnail }),
  { updateStoryField },
)(Display);
