import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../ui/buttons/Button';


const styles = () => ({
  root: {
    height: 30,
    borderRadius: 0,
    padding: '0 5px',
    textTransform: 'none',
  },
});

const ToolbarTextButton = (props) => {
  const { classes, className, ...rest } = props;
  const btnClasses = [classes.root];
  if (className) {
    btnClasses.push(className);
  }
  return (
    <Button
      color={'default'}
      textOnly
      className={btnClasses.join(' ')}
      {...rest}
    />
  );
};

ToolbarTextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
};

ToolbarTextButton.defaultProps = {
  className: null,
};

export default withStyles(styles)(ToolbarTextButton);
