import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import PlayIcon from '@material-ui/icons/PlayArrow';

import TextField from '../../ui/builder/TextField';
import { PROP_LOCAL_CAPTION } from '../../../constants/media/media';

const styles = theme => ({
  preview: {
    cursor: 'pointer',
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      verticalAlign: 'bottom',
    },
  },
  icon: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    color: theme.palette.background.contrast,
    fontSize: '3rem',
  },
  selectedVideoRoot: {
    maxWidth: '208px',
    width: '100%',
  },
  removeComponent: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 2,
  },
  fields: {
    marginLeft: theme.spacing(1),
  },
});

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const VideoDugout = ({
  classes, data,
}) => {
  const [preview, setPreview] = useState(false);
  return (<Grid container wrap={'nowrap'}>
    {data.mediaid && <div className={classes.selectedVideoRoot}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      {!preview && <div className={classes.preview} onClick={() => setPreview(true)}>
        <img src={data.thumbnail_640} alt="" />
        <PlayIcon className={classes.icon} />
      </div> }
      {preview && <div dangerouslySetInnerHTML={{ __html: data.embed_code.replace(/300px/g, '100%') }} /> }
    </div>}
    {data.mediaid && <Grid className={classes.fields} container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label={'Local Caption'}
          value={
            typeof data[PROP_LOCAL_CAPTION] !== 'undefined' &&
            data[PROP_LOCAL_CAPTION] !== null
              ? data[PROP_LOCAL_CAPTION] : data.title
          }
        />
      </Grid>
    </Grid>}
  </Grid>);
};

VideoDugout.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
};

VideoDugout.defaultProps = {
  data: null,
};

export default withStyles(styles)(VideoDugout);

