import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ContextPanel,
  ContextPanelDetails, ContextPanelHeading, ContextPanelSummary,
} from '../../common/context/ContextPanel';
import { ContextBarBody } from '../../common/context/ContextBar';

import TextField from '../../contexts/form/TextField';
import {
  FIELD_HEADLINE, FIELD_SHORT_HEADLINE, FIELD_HERO_IMAGE,
  FIELD_IS_CARD_STYLE, FIELD_IS_FREE_ARTICLE,
} from '../../../constants/article/articleFields';
import ImageField from '../../contexts/form/ImageField';
import BooleanField from '../../contexts/form/BooleanField';

const getFocusedIndex = (articles, data) => {
  if (
    typeof data !== 'undefined' &&
    data !== null &&
    typeof data.timeline !== 'undefined'
  ) {
    for (let i = 0; i <= articles.length; i += 1) {
      if (articles[i] && data.timeline.date === articles[i].timeline.date) {
        return i;
      }
    }
  }
  return -1;
};

const ArticleOverrides = (props) => {
  const { article, index, onUpdateProp, feedId, focused } = props;
  const { overrides = {} } = article;
  return (
    <ContextPanel
      focused={focused}
      data-type={'article-overrides'}
    >
      <ContextPanelSummary>
        <ContextPanelHeading>Article {index + 1}</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <TextField
          label={'Headline'}
          value={
            typeof overrides[FIELD_HEADLINE] === 'undefined'
              ? article[FIELD_SHORT_HEADLINE] || article[FIELD_HEADLINE]
              : overrides[FIELD_HEADLINE]
          }
          onChange={e => onUpdateProp(
            feedId,
            article.timeline.date,
            FIELD_HEADLINE,
            e.target.value,
          )}
        />
        <ImageField
          defaultValue={article[FIELD_HERO_IMAGE]}
          overrideValue={typeof overrides[FIELD_HERO_IMAGE] !== 'undefined' ? overrides[FIELD_HERO_IMAGE][0] : null}
          onSelect={value => onUpdateProp(
            feedId,
            article.timeline.date,
            FIELD_HERO_IMAGE,
            [
              {
                type: 'image',
                data: value,
              },
            ],
          )}
        />
        <BooleanField
          label={'Card Style'}
          value={typeof overrides[FIELD_IS_CARD_STYLE] !== 'undefined' ? overrides[FIELD_IS_CARD_STYLE] : false}
          onChange={e => onUpdateProp(
            feedId,
            article.timeline.date,
            FIELD_IS_CARD_STYLE,
            e.target.checked,
          )}
        />
        <BooleanField
          label={'Free Article'}
          value={typeof overrides[FIELD_IS_FREE_ARTICLE] !== 'undefined' ? overrides[FIELD_IS_FREE_ARTICLE] : false}
          onChange={e => onUpdateProp(
            feedId,
            article.timeline.date,
            FIELD_IS_FREE_ARTICLE,
            e.target.checked,
          )}
        />
        <TextField
          label={'Section'}
          value={
            typeof overrides.section === 'undefined'
              ? article.section?.name || ''
              : overrides.section
          }
          onChange={e => onUpdateProp(
            feedId,
            article.timeline.date,
            'section',
            e.target.value,
          )}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ArticleOverrides.propTypes = {
  article: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onUpdateProp: PropTypes.func.isRequired,
  feedId: PropTypes.string.isRequired,
  focused: PropTypes.bool.isRequired,
};

const ArticlesOverrides = (props) => {
  const { feedId, feed: { items = [] }, onUpdateProp, data } = props;
  const focusedIndex = getFocusedIndex(items, data);
  const [focus, setFocus] = useState(focusedIndex);
  useEffect(() => {
    if (focus > -1) {
      const target = document.querySelectorAll('[data-type="article-overrides"]')[focus];
      if (typeof target !== 'undefined') {
        let parent = target.parentNode;
        let i = 0;
        while (typeof parent !== 'undefined' && i < 2) {
          parent = parent.parentNode;
          i += 1;
        }
        parent.scrollTop = target.offsetTop;
      }
      setFocus(-1);
    }
  }, [focus]);
  return (
    <ContextBarBody>
      {items && items.map((item, index) => (
        <ArticleOverrides
          key={item.id}
          feedId={feedId}
          article={item}
          index={index}
          onUpdateProp={onUpdateProp}
          focused={focusedIndex === index}
        />
      ))}
    </ContextBarBody>
  );
};

ArticlesOverrides.propTypes = {
  feedId: PropTypes.string.isRequired,
  feed: PropTypes.object.isRequired,
  onUpdateProp: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default ArticlesOverrides;
