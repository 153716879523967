import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  LINK_TITLE, LINK_URL,
} from '../../constants';

import { componentSetProp } from '../../../../actions/layout';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import TextField from '../../../ui/TextField';

const TITLE_LABEL = 'Title';
const LINK_LABEL = 'Url';

const LinkField = (props) => {
  const {
    component, title, titleField, urlField, componentSetProp: setProp,
  } = props;
  return (
    <ContextPanel>
      {title && <ContextPanelSummary>
        <ContextPanelHeading>{title}</ContextPanelHeading>
      </ContextPanelSummary>}
      <ContextPanelDetails variant={'full'}>
        <TextField
          label={TITLE_LABEL}
          placeholder={TITLE_LABEL}
          value={component[titleField]}
          onChange={e => setProp(component.id, titleField, e.target.value)}
        />
        <TextField
          label={LINK_LABEL}
          placeholder={LINK_LABEL}
          value={component[urlField]}
          onChange={e => setProp(component.id, urlField, e.target.value)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

LinkField.propTypes = {
  title: PropTypes.string,
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  titleField: PropTypes.string,
  urlField: PropTypes.string,
};

LinkField.defaultProps = {
  title: '',
  titleField: LINK_TITLE,
  urlField: LINK_URL,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(LinkField);
