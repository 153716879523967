import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import FlowPaper from '../../../common/layout/FlowPaper';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      component={'div'}
    >
      @{suggestion.screenName}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, suggestions) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) return [];

  const [verified, nonVerified] = suggestions.reduce((acc, user) => {
    const [priority, everythingElse] = acc;
    if (user.verified) {
      return [[...priority, user], everythingElse];
    }
    return [priority, [...everythingElse, user]];
  }, [[], []]);

  return inputLength === 0
    ? []
    : [...verified, ...nonVerified.filter((suggestion) => {
      const label = suggestion.label.toLowerCase();
      const size = label.length;
      return size > -1 && label.indexOf(inputValue) !== -1;
    })];
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(1 / 2, 1 / 4),
  },
  divider: {
    height: theme.spacing(2),
  },
});


function IntegrationDownshift(props) {
  const { classes, users, onSelect, onChange } = props;
  const ref = useRef();

  return (
    <div className={classes.root}>
      <Downshift
        onStateChange={({ inputValue }) => onChange(inputValue)}
        onSelect={(value) => {
          onSelect(value);
        }}
        itemToString={item => item && item.label}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          setState,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              autoFocus: true,
              classes,
              ref,
              InputProps: getInputProps({
                placeholder: 'Search user handle',
              }),
              onKeyDown: (event) => {
                if (event.key === 'Enter') {
                  if (highlightedIndex === null) {
                    const { target } = event;
                    setState({
                      selectedItem: {
                        screenName: inputValue,
                        label: inputValue,
                      },
                      isOpen: false,
                      inputValue: null,
                      highlightedIndex: null,
                    });
                    target.value = '';
                  }
                }
              },
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <FlowPaper className={classes.paper}>
                  {users.length > 0 && getSuggestions(inputValue, users)
                    .map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                      }),
                    )}
                  {users.length === 0 && <MenuItem component={'p'}>No user is found</MenuItem>}
                </FlowPaper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
}
IntegrationDownshift.defaultProps = {
  users: [],
};

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(IntegrationDownshift);
