import RelatedArticlesElement from './components/RelatedArticlesElement';

export const ELEMENT_RELATED_ARTICLES = 'relatedArticles';
export const PROP_ARTICLES = 'articles';
export const PROP_ALIGN = 'align';

export const DEFAULTS_RELATED_ARTICLES = {
  relatedArticles: {
    component: RelatedArticlesElement,
    type: ELEMENT_RELATED_ARTICLES,
    rootProps: {
      className: 'slate-relatedArticles',
    },
  },
};
