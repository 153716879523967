import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_IFRAME } from './defaults';

const deserializeIframe = (options) => {
  const { iframe } = setDefaults(options, DEFAULTS_IFRAME);

  return {
    element: getNodeDeserializer({
      type: iframe.type,
      node: (el) => {
        const src = el.getAttribute('src');
        const width = el.getAttribute('width');
        const height = el.getAttribute('height');
        return {
          type: iframe.type,
          src,
          width,
          height,
        };
      },
      rules: [
        { nodeNames: 'IFRAME' },
      ],
      ...options?.iframe?.deserialize,
    }),
  };
};

export default deserializeIframe;
