import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AddIcon from '@material-ui/icons/Add';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Grid from '@material-ui/core/Grid/Grid';

import ManualListLabelDialogSelector from '../components/dialog/ManualListLabelDialogSelector';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import { selectSection, removeManualList, fetchAllManualLists, setLabel } from '../actions/manualList';

import { Table, TableBody, TableHead, TableHeadCell, TableRow, TableCell } from '../components/ui/table/Table';
import IconButton from '../components/ui/buttons/IconButton';
import Button from '../components/ui/buttons/Button';

export const ManualListBreadcrumb = [
  {
    title: 'Manual List Manager',
    slug: 'manual-list',
  },
];

const ManualList = ({
  manualList,
  removeManualList: onRemove,
  selectSection: onSelectSection,
  fetchAllManualLists: onFetchAllManualLists,
  setLabel: onSetLabel,
  push: routeChange,
}) => {
  const [openDialog, openDialogAddSection] = useState(false);
  useEffect(() => {
    onFetchAllManualLists();
  }, []);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={ManualListBreadcrumb}>
        <PageToolbarButtons>
          <Button
            onClick={() => openDialogAddSection(true)}
            variant={'contained'}
            startIcon={<AddIcon />}
          >Add Manual List</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <PageSection>
          <Table>
            <TableHead>
              <TableRow hasBorder>
                <TableHeadCell>Manual lists</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {manualList.filter((
                { manualListSettings }) => manualListSettings?.isDefault ? !manualListSettings?.isDefault : true,
              ).length === 0 &&
                <TableRow><TableCell align={'center'} colspan={2}>No manual lists found</TableCell></TableRow>
              }
              
              {manualList && manualList
                .sort((a, b) => a.data.id > b.data.id)
                .filter(({ manualListSettings }) => manualListSettings?.isDefault ? !manualListSettings?.isDefault : true)
                .map(({ data: { uuid, id, name } }) => {
                  if (name === 'default') {
                    return <></>
                  }
                  if (name === null) {
                    return <></>
                  }
                  return (
                    <TableRow hasActions key={uuid}>
                      <TableCell>{name}</TableCell>
                      <TableCell>
                        <Grid container wrap={'nowrap'} justify={'flex-end'}>
                          <IconButton
                            color={'primary'}
                            onClick={() => routeChange(`/manual-list/${id}`)}
                          >
                            <EditOutlined />
                          </IconButton>
                    {/*  <IconButton
                            color={'primary'}
                            onClick={() => {
                              window.confirm(`Please confirm you want to remove this '${name}' layout`) && onRemove(id);
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        
                          */}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                }
                )
              }
            </TableBody>
          </Table>
        </PageSection>
        {openDialog && <ManualListLabelDialogSelector
          selectSection={onSelectSection}
          handleClose={() => openDialogAddSection(false)}
          onSubmit={(label) => {
            onSetLabel(label);
            openDialogAddSection(false);
          }}
          open={openDialog}
        />}
      </PageContent>
    </Page>
  );
};

ManualList.propTypes = {
  manualList: PropTypes.array.isRequired,
  removeManualList: PropTypes.func.isRequired,
  selectSection: PropTypes.func.isRequired,
  fetchAllManualLists: PropTypes.func.isRequired,
  setLabel: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

ManualList.defaultTypes = {
  isToggle: false,
};

ManualList.defaultProps = {
  selectedComponent: {},

};

export default connect(
  ({ manualList: { manualList } }) => ({ manualList }),
  { selectSection, removeManualList, fetchAllManualLists, setLabel, push },
)(ManualList);
