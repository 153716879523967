import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import Typography from '@material-ui/core/Typography';

import ImageSelector from '../ui/media/ImageSelector';
import { UNSAVE_CHANGES_PROMPT_TEXT } from '../../constants/prompt';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  img: {
    margin: theme.spacing(1, 0),
  },
  spinnerRoot: {
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
});

const FieldAuthor = ({ defaultValue, id, label, onChange, ...rest }) => (
  <FormControl margin="dense" required fullWidth variant={'outlined'}>
    <TextField
      defaultValue={defaultValue}
      variant="outlined"
      id={id}
      label={label}
      onChange={onChange}
      name={id}
      autoComplete="none"
      {...rest}
    />
  </FormControl>
);

FieldAuthor.defaultProps = {
  onChange: () => { },
};

FieldAuthor.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
};

const AuthorForm = (props) => {
  const { classes, author, onSave, uploadBusy } = props;
  const { selectedPublication } = useSelector(state => state.frame);
  const [state, setState] = useState(author);
  const [invalidFacebook, setInValidFacebook] = useState(false);
  const [unSavedChanges, setUnsavedChanges] = useState(false);
  const [invalidSnapchat, setInvalidSnapchat] = useState(false);
  const [invalidInstagram, setInvalidInstagram] = useState(false);
  const [invalidTwitter, setInvalidTwitter] = useState(false);
  const [invalidLinkedin, setInvalidLinkedin] = useState(false);
  const [invalidTiktok, setInvalidTiktok] = useState(false);

  useEffect(() => {
    setUnsavedChanges(false);
    setState({
      ...author,
      ...author.extra,
    });
    return () => {
      setUnsavedChanges(false);
    };
  }, [author]);

  const updateData = prop => ({ target: { value } }) => {
    setUnsavedChanges(true);
    switch (prop) {
      case 'twitter': {
        if (/http|@/.test(value)) {
          setInvalidTwitter(true);
          return;
        }
        setInvalidTwitter(false);
        break;
      }
      case 'snapchat': {
        if (/http/.test(value)) {
          setInvalidSnapchat(true);
          return;
        }
        setInvalidSnapchat(false);
        break;
      }
      case 'instagram': {
        if (/http/.test(value)) {
          setInvalidInstagram(true);
          return;
        }
        setInvalidInstagram(false);
        break;
      }
      case 'facebook': {
        if (/http/.test(value)) {
          setInValidFacebook(true);
          return;
        }
        setInValidFacebook(false);
        break;
      }
      case 'tiktok': {
        if (/http/.test(value)) {
          setInvalidTiktok(true);
          return;
        }
        setInvalidTiktok(false);
        break;
      }
      case 'linkedin': {
        if (/http/.test(value)) {
          setInvalidLinkedin(true);
          return;
        }
        setInvalidLinkedin(false);
        break;
      }
      default:
      // noop
    }
    setState({
      ...state,
      [prop]: value,
    });
  };
  return (<div className={classes.root}>
    <div>
      <Prompt message={UNSAVE_CHANGES_PROMPT_TEXT} when={unSavedChanges} />
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          const save = () => {
            onSave(state);
            setUnsavedChanges(false);
          };
          if (author.name !== state.name) {
            if (window.confirm('Are you sure you wish to update this term?')) {
              save();
            }
          } else {
            save();
          }
        }}
      >
        {state.remoteId && <FieldAuthor
          value={state.remoteId}
          id={'remoteId'}
          label={'Remote ID'}
          disabled
        />}
        <FieldAuthor
          defaultValue={state.name}
          value={state.name || ''}
          id={'name'}
          label={'Name'}
          autoComplete={'off'}
          onChange={updateData('name')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.email || ''}
          id={'email'}
          label={'Email'}
          onChange={updateData('email')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.jobTitle || ''}
          id={'jobTitle'}
          label={'Job Title'}
          onChange={updateData('jobTitle')}
        />
        <FieldAuthor
          defaultValue={''}
          multiline
          value={selectedPublication.domain === 'independentespanol.com' ? state.authorBioOverride || '' : state.description || ''}
          id={'description'}
          label={'Bio'}
          onChange={updateData(selectedPublication.domain === 'independentespanol.com' ? 'authorBioOverride' : 'description')}
        />
        <FieldAuthor
          defaultValue={state.twitter}
          value={state.twitter || ''}
          error={invalidTwitter}
          id={'twitter'}
          label={'Twitter id, no url or @ symbol'}
          onChange={updateData('twitter')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.facebook || ''}
          error={invalidFacebook}
          id={'facebook'}
          label={'Facebook profile name, no url'}
          onChange={updateData('facebook')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.instagram || ''}
          error={invalidInstagram}
          id={'instagram'}
          label={'Instagram profile name, no url'}
          onChange={updateData('instagram')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.snapchat || ''}
          error={invalidSnapchat}
          id={'snapchat'}
          label={'Snapchat profile name, no url'}
          onChange={updateData('snapchat')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.linkedin || ''}
          error={invalidLinkedin}
          id={'linkedin'}
          label={'LinkedIn profile name, no url'}
          onChange={updateData('linkedin')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.tiktok || ''}
          error={invalidTiktok}
          id={'tiktok'}
          label={'Tiktok profile name, no url'}
          onChange={updateData('tiktok')}
        />
        <FieldAuthor
          defaultValue={''}
          value={state.location || ''}
          id={'location'}
          label={'Location'}
          onChange={updateData('location')}
        />
        <Grid className={classes.img}>
          <Typography variant={'body2'}>Profile image:</Typography>
          <ImageSelector
            selectImage={data => setState({ ...state, imageUrl: data.url })}
            onRemove={() => setState({ ...state, imageUrl: '' })}
            image={{
              url: state.imageUrl,
            }}
          />
        </Grid>
        <Grid className={classes.img}>
          <Typography variant={'body2'}>Header image:</Typography>
          <ImageSelector
            selectImage={data => setState({ ...state, headerImageUrl: data.url })}
            onRemove={() => setState({ ...state, headerImageUrl: '' })}
            image={{
              url: state.headerImageUrl,
            }}
          />
        </Grid>
        <Grid container justify={'flex-end'}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type={'submit'}
            disabled={uploadBusy}
          >Save</Button>
        </Grid>
      </form>
    </div>
  </div>);
};

AuthorForm.propTypes = {
  classes: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  uploadBusy: PropTypes.bool.isRequired,
};

export default withStyles(style)(connect(
  ({ vocab: { uploadBusy } }) => ({ uploadBusy }),
)(AuthorForm));
