import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  SHOW_ICON,
  THEME,
} from '../../constants';

import { componentSetProp } from '../../../../actions/layout';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import ToggleSwitch from '../../../ui/ToggleSwitch';
import Select from '../../../ui/Select';

const TOGGLE_LABEL = 'Show Icon';
const THEME_LABEL = 'Theme';

const THEMES = [
  'light',
  'dark',
];

const ChainSettings = (props) => {
  const {
    component, componentSetProp: setProp,
  } = props;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          label={THEME_LABEL}
          defaultValue={THEMES[0]}
          value={component[THEME]}
          onChange={e => setProp(component.id, THEME, e.target.value)}
          items={THEMES}
        />
        <ToggleSwitch
          label={TOGGLE_LABEL}
          value={component[SHOW_ICON]}
          onChange={e => setProp(component.id, SHOW_ICON, e.target.checked)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ChainSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(ChainSettings);
