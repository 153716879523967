import React from 'react';

import Grid from '@material-ui/core/Grid';

import ContextBar from '../common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails, ContextPanelHeading, ContextPanelSummary,
} from '../common/context/ContextPanel';
import {
  FIELD_END_DATE, FIELD_START_DATE,
  FIELD_PRICE, FIELD_PRICE_SUB,
  FIELD_TICKET_URL, FIELD_TICKET_URL_SUB, FIELD_LOCATION,
} from '../../constants/event/eventFields';
import FieldLockDateTime from '../fieldLock/form/FieldLockDateTime';
import FieldLockTextfield from '../fieldLock/form/FieldLockTextfield';

const EventSidebar = () => (
  <ContextBar>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldLockDateTime field={FIELD_START_DATE} label={'Start date'} />
          </Grid>
          <Grid item xs={12}>
            <FieldLockDateTime field={FIELD_END_DATE} label={'End date'} />
          </Grid>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_LOCATION} label={'Location'} />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Price</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_PRICE} label={'Standard price'} />
          </Grid>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_PRICE_SUB} label={'Subscriber price'} />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Ticket</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_TICKET_URL} label={'URL'} />
          </Grid>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_TICKET_URL_SUB} label={'Subscriber URL'} />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  </ContextBar>
);

export default EventSidebar;
