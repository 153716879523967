import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OVERRIDE_TITLE } from 'constants/layout/layout';
import { FEATURE_MANUALLIST_AUTHOR_IMAGE } from 'constants/features';
import { setManualListItemOverrides, setManualListItemSettings } from 'actions/manualList';

import ContextBar, { ContextBarBody } from 'components/common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from 'components/common/context/ContextPanel';
import { createSelectManualListItem } from 'selectors/manualList/manualList';
import TextField from 'components/contexts/form/TextField';
import { hasFeatures } from 'components/helper/utils';
import BooleanField from 'components/contexts/form/BooleanField';
import { USE_AUTHOR_IMAGE } from 'components/layout/constants';

const AUTHOR_IMAGE = USE_AUTHOR_IMAGE.slice(0, 1).toLowerCase() + USE_AUTHOR_IMAGE.slice(1);

const ManualListContext = () => {
  const item = useSelector(createSelectManualListItem);
  const { label, settings, overrides } = item;
  const { position } = useSelector(state => state?.manualList?.context || {});
  const { selectedPublication: { features } } = useSelector(state => state.frame);
  const dispatch = useDispatch();
  const handleChangeTitle = e => dispatch(setManualListItemOverrides(
    position,
    { [OVERRIDE_TITLE]: e.target.value },
  )) ;
  const handleChangeAuthor = e => dispatch(setManualListItemSettings(
    position,
    { [AUTHOR_IMAGE]: e.target.checked },
  )) ;
  return (<ContextBar>
    <ContextBarBody>
      {label && !isNaN(position) && <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Article <span>{position + 1}</span></ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant="full">
          <TextField
            label="Headline"
            value={label}
            overrideValue={overrides?.[OVERRIDE_TITLE]}
            allowEmpty
            onChange={handleChangeTitle}
          />
          {hasFeatures(features)(FEATURE_MANUALLIST_AUTHOR_IMAGE) && <BooleanField
            label="Use author image"
            value={settings?.[AUTHOR_IMAGE] || false}
            onChange={handleChangeAuthor}
          />}
        </ContextPanelDetails>
      </ContextPanel>}
    </ContextBarBody>
  </ContextBar>);
};

export default ManualListContext;
