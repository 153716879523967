import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    '& svg' : {
      width: '100%',
      height: 'auto',
    },
  },
};

const svg = `
<svg xmlns="http://www.w3.org/2000/svg" height="87" width="78.5" viewBox="0 0 87 78.5"><path d="M5.16 69.14C2.46 67.6.22 63.79.2 60.67L0 26.9c-.02-3.11 2.17-6.95 4.86-8.53L34 1.32c2.69-1.57 7.1-1.6 9.82-.06l29.34 16.71c2.7 1.54 4.94 5.36 4.96 8.48l.2 33.76c.02 3.12-2.17 6.96-4.86 8.53L44.32 85.8c-2.69 1.58-7.1 1.6-9.82.06L5.16 69.16" fill="#f47216"/><path d="M53.22 72.14c1.07.64 1.07 1.66 0 2.28l-11.86 6.84a4.3 4.3 0 0 1-3.9-.02L7.86 63.81a4.4 4.4 0 0 1-1.96-3.4l-.03-14.02c0-1.24.87-1.74 1.93-1.1l45.42 26.85M38.18 41.78a4.3 4.3 0 0 0 3.9-.06l10.96-6.62a4.46 4.46 0 0 0 1.94-3.43V18.5c0-1.24-.88-1.74-1.95-1.1L26.64 33.03c-1.06.63-1.05 1.65.04 2.26l11.5 6.48" fill="#fff"/><path d="M53.23 15.55c1.06-.64 1.07-1.7.02-2.35l-11.34-7a4.16 4.16 0 0 0-3.87-.05L7.93 23.7a4.35 4.35 0 0 0-1.95 3.4l.04 13.17a4.4 4.4 0 0 0 1.95 3.41L53.03 70.3c1.07.63 1.95.13 1.95-1.11V56.01a4.4 4.4 0 0 0-1.95-3.4L23.78 35.34c-1.07-.63-1.07-1.66 0-2.3l29.45-17.5" fill="#fff"/></svg>
`;

const BsLogo = ({ classes }) => (<span className={classes.root} dangerouslySetInnerHTML={{ __html: svg }} />);

BsLogo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BsLogo);
