/**
 * Gets instances of pattern from clipboard text,
 * if required returns null if not found else returns text
 *
 * @param   {DataTransfer} clipboardData  Clipboard data
 * @param   {regex} pattern               Pattern to search for
 * @param   {boolean} required            if matches are required return null if not found
 * @param   {boolean} firstOnly           return only first match
 * @return  {string|array|null}           Match(s) if matched else the pasted text or null
 */
export const getPattern = (clipboardData, pattern, required = false, firstOnly = true) => {
  const text = clipboardData.getData('Text');
  const values = text.match(pattern);
  if (!Array.isArray(values)) return required ? null : text;
  return firstOnly ? values[0] : values;
};

const convertHTMLToText = (html) => {
  if (!html || typeof html !== 'string') return '';
  let container = document.createElement('div');
  container.innerHTML = html.replaceAll('</li>', '</li>\n'); // fix li display inline
  const text = container.textContent;
  container = null;
  return text;
};

/**
 * Copies text to the clipboard
 * @param {string} str      text or markup to copy
 * @param {boolean} asText  convert str to text if markup
 */
export const copyToClipboard = (str, asText = false) => {
  const content = asText ? convertHTMLToText(str) : str;
  const el = document.createElement('textarea');
  el.value = content;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
