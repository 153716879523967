import { getInlineTypes } from '@udecode/slate-plugins-core';
import {
  isBlockAboveEmpty,
  deserializeHTMLToDocumentFragment,
  getText,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_PARAGRAPH,
} from '@udecode/slate-plugins';
import { Transforms } from 'slate';
import { reformatQuote } from '../../../helper/formatting';

const getTextBefore = (editor) => {
  const { anchor } = editor.selection;
  if (anchor.offset === 0) return null;
  return getText(editor, {
    anchor: {
      ...anchor,
      offset: anchor.offset - 1,
    },
    focus: anchor,
  });
};

const withFormatting = ({ plugins }) => (editor) => {
  const { insertData, insertText } = editor;

  const preInsert = (fragment) => {
    const inlineTypes = getInlineTypes(plugins);

    const firstNodeType = fragment[0].type;

    // replace the selected node type by the first block type
    if (
      isBlockAboveEmpty(editor) &&
      firstNodeType &&
      !inlineTypes.includes(firstNodeType)
    ) {
      Transforms.setNodes(editor, { type: fragment[0].type });
    }

    // fix copy from google docs orphaning inline elements in last P
    const inlineFragments = fragment.filter(({ type }) => !type || inlineTypes.includes(type));
    const appendedFragments = inlineFragments.length > 0
      ? [{
        type: ELEMENT_PARAGRAPH,
        children: inlineFragments,
      }] : [];

    return [
      ...fragment
        // remove orphaned inline
        .filter(({ type }) => !(!type || inlineTypes.includes(type)))
        // remove empty P
        .filter(({ type, children }) => !(
          type === ELEMENT_PARAGRAPH &&
          !children.map(({ text = '' }) => text).join().trim()
        ))
        // remove empty white space elements
        .filter(({ type, text }) => {
          if (!type && typeof text !== 'undefined') {
            return (!!text.trim());
          }
          return true;
        })
        .map((block) => {
          // trim white space from paragraphs
          if (
            block.type === ELEMENT_PARAGRAPH &&
            typeof block.children[0] !== 'undefined' &&
            typeof block.children[0].text !== 'undefined'
          ) {
            block.children[0].text = block.children[0].text.trimStart();
          }
          const lastIndex = block?.children ? block.children.length - 1 : null;
          if (
            lastIndex !== null &&
            block.type === ELEMENT_PARAGRAPH &&
            typeof block.children[lastIndex] !== 'undefined' &&
            typeof block.children[lastIndex].text !== 'undefined'
          ) {
            block.children[lastIndex].text = block.children[lastIndex].text.trimEnd();
          }
          // H1 are not supported, we convert to H2
          if (block.type === ELEMENT_H1) {
            block.type = ELEMENT_H2;
          }
          return block;
        }),
      ...appendedFragments,
    ];
  };
  const insert = (fragment) => {
    Transforms.insertFragment(editor, fragment);
  };

  editor.insertData = (data) => {
    let html = data.getData('text/html');

    if (html) {
      // fragment selector to fix copy/paste from editor on windows
      const htmlFragment = html.match(/<!--StartFragment-->(.*)<!--EndFragment-->/m);
      if (htmlFragment?.[1]) {
        html = htmlFragment[1];
      }
      // remove linebreaks attempt to avoid double spacing
      html = html.replace(/\s(\r\n|\n|\r)\s/gm, ' '); // remove linebreaks between spaces
      html = html.replace(/\s(\r\n|\n|\r)/gm, ' '); // remove linebreaks after spaces
      html = html.replace(/(\r\n|\n|\r)\s/gm, ' '); // remove linebreaks before spaces
      html = html.replace(/(\r\n|\n|\r)/gm, ' '); // remove linebreaks
      html = html.replace(/<\s*\/?br\s*[/]?>/gi, ''); // remove html linebreaks
      // replace &nbsp; with space
      html = html.replace(/\u00a0/g, ' ');
      html = reformatQuote(html);

      const { body } = new DOMParser().parseFromString(html, 'text/html');
      let fragment = deserializeHTMLToDocumentFragment({
        plugins,
        element: body,
      });

      fragment = preInsert(fragment);

      insert(fragment);
      return;
    }

    insertData(data);
  };

  editor.insertText = (text) => {
    switch (text) {
      case '\'': {
        const before = getTextBefore(editor);
        if (!before || /\s/.test(before)) {
          text = '‘';
        } else {
          text = '’';
        }
        break;
      }
      case '"': {
        const before = getTextBefore(editor);
        if (!before || /\s/.test(before)) {
          text = '“';
        } else {
          text = '”';
        }
        break;
      }
      default:
        break;
    }

    insertText(text);
  };

  return editor;
};

export default withFormatting;
