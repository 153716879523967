import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid/Grid';

import { removeAdConfig, selectSection, selectArticle } from 'actions/ad';

import { DOMAIN_INDY } from 'constants/domainConfig';
import { CLIENT_JPI, CLIENT_TINDLE } from 'constants/env';
import jpiAdconfig from 'constants/adConfig/jpi';
import indyAdconfig from 'constants/adConfig/indy';
import tindleAdconfig from 'constants/adConfig/tindle';

import { getPathFromBreadcrumb } from 'utils/breadcrumbHelper';

import Page from 'components/common/page/Page';
import PageContent from 'components/common/page/PageContent';
import PageSection from 'components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from 'components/common/page/PageToolbar';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from 'components/ui/table/Table';
import IconButton from 'components/ui/buttons/IconButton';
import Button from 'components/ui/buttons/Button';

import SectionAdd from 'components/autocomplete/section/SectionAdd';
import AutocompleteArticleDialog from 'components/dialog/autocomplete/AutocompleteArticleDialog';

export const AdConfigBreadcrumb = [
  {
    title: 'Ad Config',
    slug: 'ad',
  },
];

export const getAdConfig = (domain, client) => {
  switch (true) {
    case client === CLIENT_JPI:
      return jpiAdconfig;
    case domain === DOMAIN_INDY:
      return indyAdconfig;
    case client === CLIENT_TINDLE:
      return tindleAdconfig;
    default:
      return null;
  }
};

const AdConfigList = ({
  adConfig,
  domain, client,
  push: onRouteChange,
  removeAdConfig: onRemoveAdConfig,
  selectSection: onSelectSection,
  selectArticle: onSelectArticle,
}) => {
  const [adConfigs, setAdConfigs] = useState(null);
  const [openSectionDialog, seOpenSectionDialog] = useState(false);
  const [openArticleDialog, setOpenArticleDialog] = useState(false);
  const path = getPathFromBreadcrumb(AdConfigBreadcrumb);
  const notGlobalConfig = (section, article) => !(!section && !article);
  useEffect(() => {
    setAdConfigs(getAdConfig(domain, client));
  }, [client, domain]);
  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={AdConfigBreadcrumb}>
          <PageToolbarButtons>
            <Button
              onClick={() => onRouteChange('/ad/ads-txt')}
              variant={'outlined'}
            >Edit ads.txt</Button>
            <Button
              onClick={() => seOpenSectionDialog(true)}
              variant={'outlined'}
              startIcon={<AddIcon />}
            >Add section</Button>
            <Button
              onClick={() => setOpenArticleDialog(true)}
              variant={'contained'}
              startIcon={<AddIcon />}
            >Add article</Button>
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection>
          <Table>
            <TableHead>
              <TableRow hasBorder>
                <TableHeadCell>Name</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {adConfigs && adConfig.map(({ data: { id, name, section, article } }) => (
                <TableRow hasActions key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <Grid container wrap={'nowrap'} justify={'flex-end'}>
                      <IconButton
                        color={'primary'}
                        onClick={() => onRouteChange(`${path}/${id}`)}
                      >
                        <EditOutlined />
                      </IconButton>
                      {notGlobalConfig(section, article) && <IconButton
                        color={'primary'}
                        onClick={() => {
                          window.confirm(`Please confirm you want to remove ${name} ad config`) && onRemoveAdConfig(id);
                        }}
                      >
                        <DeleteOutlined />
                      </IconButton>}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {!adConfigs &&
                <TableRow><TableCell>Ad Config has not been defined for this publication</TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </PageSection>
      </PageContent>
      <SectionAdd
        selectSection={onSelectSection}
        closeDialog={() => seOpenSectionDialog(false)}
        openDialog={() => seOpenSectionDialog(true)}
        isToggle={openSectionDialog}
        showAddButton={false}
      />
      <AutocompleteArticleDialog
        handleClose={() => setOpenArticleDialog(false)}
        open={openArticleDialog}
        onSelect={onSelectArticle}
      />
    </Page>
  );
};

AdConfigList.propTypes = {
  adConfig: PropTypes.array.isRequired,
  removeAdConfig: PropTypes.func.isRequired,
  selectSection: PropTypes.func.isRequired,
  selectArticle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
};

export default connect(
  ({
    frame: { selectedPublication: { domain }, client },
    ad: { currentConfig, adConfig },
  }) => ({
    domain, currentConfig, adConfig, client,
  }), {
    removeAdConfig, selectSection, selectArticle, push,
  },
)(AdConfigList);
