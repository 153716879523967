import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../ui/buttons/Button';
import { ContextPanelItem } from '../../../common/context/ContextPanel';

import { setLocalProp } from '../../../../actions/dataState';
import PublicationRegionDialog from '../../../dialog/PublicationRegionsDialog';
import { FIELD_PUBLICATIONS } from '../../../../constants/article/articleFields';
import PublicationsDialog from '../../../dialog/PublicationsDialog';

const ArticlePublication = ({
  setLocalProp: setProp, publications, publicationGroupByRegion,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [Dialog, setDialog] = useState(null);
  useEffect(() => {
    if (publicationGroupByRegion && publicationGroupByRegion.length > 0) {
      setDialog(PublicationRegionDialog);
    } else {
      setDialog(PublicationsDialog);
    }
  }, [publicationGroupByRegion]);
  return (
    <ContextPanelItem label={`Publications (${publications.length})`}>
      <Button textOnly onClick={() => setShowDialog(true)}>view</Button>
      {showDialog && <Dialog
        onSave={(value) => {
          setShowDialog(false);
          setProp(FIELD_PUBLICATIONS, value);
        }}
        selectedPublications={publications}
        regions={publicationGroupByRegion}
        open={showDialog}
        handleClose={() => {
          setShowDialog(false);
        }}
      />}
    </ContextPanelItem>
  );
};

ArticlePublication.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  publications: PropTypes.array,
  publicationGroupByRegion: PropTypes.array,
};

ArticlePublication.defaultProps = {
  publications: [],
  publicationGroupByRegion: [],
};

export default connect(
  ({
    dataState: { [FIELD_PUBLICATIONS]: publications },
    frame: { publicationGroupByRegion },
  }) => ({ publications, publicationGroupByRegion }),
  { setLocalProp },
)(ArticlePublication);
