import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { capitalize } from '@material-ui/core';

import { setLocalProp } from '../../../actions/dataState';
import { sanitiseTerm } from '../../../utils/sanitiser';

import { AUTHORS, SECTIONS, VOCAB_SINGULAR } from '../../../constants/vocab';
import {
  FIELD_AUTHOR_INFO,
  FIELD_AUTHORS,
  FIELD_SECTIONS,
} from '../../../constants/article/articleFields';
import FieldLockVocabSelector from '../../fieldLock/form/FieldLockVocabSelector';
import { PagePanel, PagePanelContent } from '../../common/page/PagePanel';
import FieldLockTextfield from '../../fieldLock/form/FieldLockTextfield';

const FIELD_SECTIONS_PRIMARY = 'primary';
const FIELD_SECTIONS_ADDITIONAL = 'additional';

const PrimaryVocabSelector = ({
  fieldName, vocab, selectedTerms, children, required, label, onSelect, onRemove,
}) => (
  <FieldLockVocabSelector
    required={required}
    vocab={vocab}
    terms={selectedTerms}
    label={label || capitalize(VOCAB_SINGULAR[vocab])}
    onSelect={onSelect}
    onRemove={onRemove}
    field={fieldName}
    hierarchical={vocab === SECTIONS}
    hideSuggestions
  >{children && children}</FieldLockVocabSelector>
);

PrimaryVocabSelector.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  vocab: PropTypes.string.isRequired,
  label: PropTypes.string,
  selectedTerms: PropTypes.array,
  children: PropTypes.node,
  required: PropTypes.bool,
};

PrimaryVocabSelector.defaultProps = {
  label: '',
  selectedTerms: [],
  children: null,
  required: false,
};

const TaxonomyPanelPrimary = ({
  sections, authors,
  selectedPublication,
  setLocalProp: setProp,
}) => {
  const [primarySection, setPrimarySection] = useState([]);
  const [additionalSections, setAdditionalSections] = useState([]);
  const { vocabs } = selectedPublication;
  useEffect(() => {
    setPrimarySection(sections.slice(0, 1));
    setAdditionalSections(sections.slice(1));
  }, [sections]);
  return (
    <PagePanel>
      <PagePanelContent noHeading>
        <Grid container spacing={2}>
          {vocabs.includes(SECTIONS) && <Grid item xs={4}>
            <PrimaryVocabSelector
              fieldName={FIELD_SECTIONS_PRIMARY}
              vocab={SECTIONS}
              label={'Section'}
              selectedTerms={primarySection}
              required
              onSelect={([, value]) => setProp(FIELD_SECTIONS, [sanitiseTerm(value), ...additionalSections])}
              onRemove={() => setProp(FIELD_SECTIONS, [null, ...additionalSections])}
            />
          </Grid>}
          {vocabs.includes(SECTIONS) && <Grid item xs={8}>
            <PrimaryVocabSelector
              fieldName={FIELD_SECTIONS_ADDITIONAL}
              vocab={SECTIONS}
              label={'Additional Sections'}
              selectedTerms={additionalSections}
              onSelect={([, value]) => setProp(FIELD_SECTIONS, [
                ...primarySection, ...additionalSections, sanitiseTerm(value),
              ])}
              onRemove={([, value]) => setProp(FIELD_SECTIONS, [
                ...primarySection,
                ...additionalSections.filter(({ id }) => id !== value.id),
              ])}
            />
          </Grid>}
          {vocabs.includes(AUTHORS) && <Grid item xs={12}>
            <PrimaryVocabSelector
              fieldName={FIELD_AUTHORS}
              vocab={AUTHORS}
              selectedTerms={authors}
              onSelect={([, value]) => setProp(FIELD_AUTHORS, [...authors, sanitiseTerm(value)])}
              onRemove={([, value]) => setProp(FIELD_AUTHORS, authors.filter(({ id }) => id !== value.id))}
            >
              <FieldLockTextfield
                key={'authorInfo'}
                margin={'normal'}
                label={'Author(s) information'}
                field={FIELD_AUTHOR_INFO}
                placeholder={'Type author information here'}
              />
            </PrimaryVocabSelector>
          </Grid>}
        </Grid>
      </PagePanelContent>
    </PagePanel>
  );
};

TaxonomyPanelPrimary.propTypes = {
  selectedPublication: PropTypes.object.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  sections: PropTypes.array,
  authors: PropTypes.array,
};

TaxonomyPanelPrimary.defaultProps = {
  sections: [],
  authors: [],
};

export default connect(
  ({
    dataState: { [FIELD_SECTIONS]: sections, [FIELD_AUTHORS]: authors },
    frame: { selectedPublication },
  }) =>
    ({ sections, authors, selectedPublication }),
  {
    setLocalProp,
  },
)(TaxonomyPanelPrimary);

