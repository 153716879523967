import React from 'react';
import Grid from '@material-ui/core/Grid';
import MarkUp from './MarkUp';

const NumberBox = props => (
  <Grid container>
    <MarkUp
      multiMarkup
      placeholder={'Insert content here'}
      extraFields={[
        { key: 'title', label: 'Title' },
      ]}
      {...props}
    />
  </Grid>
);

export default NumberBox;
