import { Text } from 'slate';
import { setDefaults } from '@udecode/slate-plugins';
import { DEFAULTS_SEARCH_REPLACE } from './defaults';

export const decorateSearchReplace = options => ([node, path]) => {
  const { searchReplace, search } = setDefaults(
    options,
    DEFAULTS_SEARCH_REPLACE,
  );

  const ranges = [];

  if (search && Text.isText(node)) {
    const { text } = node;
    const parts = text.split(search);
    let offset = 0;
    parts.forEach((part, i) => {
      if (i !== 0) {
        ranges.push({
          anchor: { path, offset: offset - search.length },
          focus: { path, offset },
          [searchReplace.type]: true,
        });
      }
      offset = offset + part.length + search.length;
    });
  }

  return ranges;
};
