import { setDefaults } from '@udecode/slate-plugins';
import renderElementInfobox from './renderElementInfobox';
import { DEFAULTS_INFOBOX } from './defaults';

const InfoboxPlugin = (options) => {
  const { infobox } = setDefaults(options, DEFAULTS_INFOBOX);
  return ({
    renderElement: renderElementInfobox(options),
    voidTypes: [infobox.type],
  });
};

export default InfoboxPlugin;
