export const CONFIG_FETCH = 'CONFIG_FETCH';
export const CONFIG_FETCH_SUCCESS = 'CONFIG_FETCH_SUCCESS';
export const CONFIG_FETCH_REJECTED = 'CONFIG_FETCH_REJECTED';

export const CONFIG_ENTITY_FETCH = 'CONFIG_ENTITY_FETCH';
export const CONFIG_ENTITY_FETCH_SUCCESS = 'CONFIG_ENTITY_FETCH_SUCCESS';
export const CONFIG_ENTITY_FETCH_REJECTED = 'CONFIG_ENTITY_FETCH_REJECTED';

export const CONFIG_SET = 'CONFIG_SET';

export const CONFIG_ENTITY_SAVE = 'CONFIG_ENTITY_SAVE';
export const CONFIG_ENTITY_SAVE_SUCCESS = 'CONFIG_ENTITY_SAVE_SUCCESS';
export const CONFIG_ENTITY_SAVE_REJECTED = 'CONFIG_ENTITY_SAVE_REJECTED';
