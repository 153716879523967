import React, { useState } from 'react';

import AddIcon from '@material-ui/icons/Add';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import Button from '../components/ui/buttons/Button';

import RedirectListTable from '../components/redirect/RedirectListTable';
import RedirectAddNewDialog from '../components/dialog/RedirectAddNewDialog';

export const SidebarListBreadcrumb = [
  {
    title: 'Redirect Manager',
    slug: 'redirect',
  },
];

const RedirectManager = () => {
  const [open, setOpen] = useState(false);
  return (
    <Page
      fullWidth
      toolbarContent={
        <PageToolbar breadcrumb={SidebarListBreadcrumb}>
          <PageToolbarButtons>
            <Button
              variant={'contained'}
              onClick={() => setOpen(true)}
              startIcon={<AddIcon />}
            >Add new</Button>
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <RedirectListTable />
      </PageContent>
      {open && <RedirectAddNewDialog
        open={open}
        handleClose={() => setOpen(false)}
      />}
    </Page>
  );
};

export default RedirectManager;
