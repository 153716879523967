import React from 'react';
import { Redirect, Route } from 'react-router';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPathFromBreadcrumb } from './utils/breadcrumbHelper';

import Home from './layouts/Dashboard';
import Login from './layouts/Login';
import Logout from './layouts/Logout';
import PasswordReset from './layouts/PasswordReset';
import SidebarList from './layouts/SidebarList';
import LayoutCreate from './layouts/LayoutCreate';
import SidebarCreate from './layouts/SidebarCreate';
import MenuList from './layouts/MenuList';
import UsersList from './layouts/UsersList';
import MenuCreate from './layouts/MenuCreate';
import AdConfigList, { AdConfigBreadcrumb } from './layouts/AdConfigList';
import AdConfigCreate from './layouts/AdConfigCreate';
import ManualList from './layouts/ManualList';
import ArticleCreate from './layouts/ArticleCreate';
import ArticleList, { ArticleListBreadcrumb } from './layouts/ArticleList';
import ArticleTranslateLanding from './layouts/ArticleTranslateLanding';
import LiveblogList from './layouts/LiveblogList';
import LiveblogCreate from './layouts/LiveblogCreate';
import ManualListCreate from './layouts/ManualListCreate';
import NotFound from './layouts/NotFound';
import MediaList from './layouts/MediaList';
import GalleryList from './layouts/GalleryList';
import GalleryCreate from './layouts/GalleryCreate';
import Access from './access/AccessRoute';
import LayoutList from './layouts/LayoutList';
import Preview from './layouts/Preview';
import SitePreview from './layouts/SitePreview';
import EditionList from './layouts/EditionList';
import EditionView from './layouts/EditionDetail';
import EditionArticle from './layouts/EditionArticle';
import EditionBrowse from './layouts/EditionBrowse';
import StoryList from './layouts/StoryList';
import StoryCreate from './layouts/StoryCreate';
import Profile from './layouts/Profile';
import RedirectManager from './layouts/RedirectManager';
import EditionExportSetting from './layouts/EditionExportSetting';
import PremiumAd, { EditorLettersBreadcrumb, AdsReplacementsBreadcrumb } from './layouts/PremiumAd';
import PremiumFeed from './layouts/PremiumFeed';
import AffiliateDashboard from './layouts/AffiliateDashboard';
import EventList from './layouts/EventList';
import EventCreate from './layouts/EventCreate';
import ConfigPage, { ConfigPageBreadcrumb } from './layouts/ConfigPage';

// user generated
import UserEventList from './layouts/UserEventList';
import UserCommentList from './layouts/UserCommentList';

// thirdparty
import PlayIncList from './layouts/integration/PlayIncList';

import { history } from './store';
import {
  FEATURE_ARTICLE_CREATION,
  FEATURE_LIVEBLOGS,
  FEATURE_EDITION,
  FEATURE_STORIES,
  FEATURE_PLAY_INC,
  FEATURE_EVENTS,
  FEATURE_USER_EVENTS,
  FEATURE_USER_COMMENTS,
} from './constants/features';

import { containInternalTranslateConfig } from './components/drawer';
import {
  ACCESS_CONTENT,
  ADMINISTER_USER,
  CREATE_AD_CONFIG,
  CREATE_ARTICLE_CONTENT,
  CREATE_EDITOR_LETTER,
  CREATE_FULL_NAV_MENU,
  CREATE_LIVEBLOG,
  CREATE_MANUAL_LIST,
  CREATE_MEDIA,
  CREATE_REDIRECT,
  CREATE_SIDEBAR,
  EDIT_DAILY_EDITION,
  CREATE_LAYOUT_SECTION,
  CREATE_LAYOUT_AUTHOR,
  CREATE_LAYOUT_TOPIC,
  CREATE_LAYOUT_ARTICLE_TYPE,
  CREATE_LAYOUT_CHAIN,
  TRANSLATION, CREATE_STORY, CREATE_EVENT,
} from './constants/permission';
import { VOCAB_SINGULAR } from './constants/vocab';
import ContactUs from './layouts/ContactUs';
import AdTxt, { AdTxtBreadcrumb } from './layouts/AdsTxt';

const Routes = ({ selectedPublication, pathname }) => {
  const vocab = pathname.split('/').pop();
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/reset-password" component={PasswordReset} />

        <Access access={[CREATE_REDIRECT]} exact path="/contact-us" component={ContactUs} />
        <Access access={[CREATE_REDIRECT]} exact path="/redirect" component={RedirectManager} />
        <Access access={[CREATE_SIDEBAR]} exact path="/sidebar" component={SidebarList} />
        <Access access={[CREATE_SIDEBAR]} exact path="/sidebar/:id" component={SidebarCreate} />
        <Access
          access={[CREATE_SIDEBAR]}
          exact
          path="/sidebar/section/new"
          component={SidebarCreate}
        />

        <Access access={[CREATE_FULL_NAV_MENU]} exact path="/menu" component={MenuList} />
        <Access
          access={[CREATE_FULL_NAV_MENU]}
          exact
          path="/menu/:bundle/:id"
          component={MenuCreate}
        />

        <Access access={[ADMINISTER_USER]} exact path="/users" component={UsersList} />

        <Access access={[CREATE_MANUAL_LIST]} exact path="/manual-list" component={ManualList} />
        <Access
          access={[CREATE_MANUAL_LIST]}
          exact
          path="/manual-list/:id"
          component={ManualListCreate}
        />

        <Route exact path="/layout" render={() => <Redirect to="/layout/sections" />} />
        <Access
          access={[`create layout ${VOCAB_SINGULAR[vocab]}_layout`, `create terms in ${vocab}`]}
          exact
          path="/layout/:type"
          component={LayoutList}
        />
        <Access
          access={[
            CREATE_LAYOUT_SECTION, CREATE_LAYOUT_TOPIC, CREATE_LAYOUT_AUTHOR,
            CREATE_LAYOUT_ARTICLE_TYPE, CREATE_LAYOUT_CHAIN,
          ]}
          exact
          path="/layout/:term/:id"
          component={LayoutCreate}
        />

        <Route exact path="/edition" render={() => <Redirect to="/edition/daily" />} />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/export-setting"
          component={EditionExportSetting}
        />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/:type"
          component={EditionList}
        />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/:type/:editionId"
          component={EditionView}
        />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/:type/:editionId/browse"
          component={EditionBrowse}
        />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/:type/:editionId/:id/browse"
          component={EditionBrowse}
        />
        <Access
          access={[EDIT_DAILY_EDITION]}
          requiredFeature={FEATURE_EDITION}
          exact
          path="/edition/:type/:editionId/:id/:revisionId?"
          component={EditionArticle}
        />

        <Access
          access={[CREATE_AD_CONFIG]}
          exact
          path={getPathFromBreadcrumb(AdConfigBreadcrumb)}
          component={AdConfigList}
        />
        <Access
          access={[CREATE_AD_CONFIG]}
          exact
          path={getPathFromBreadcrumb(AdTxtBreadcrumb)}
          component={AdTxt}
        />
        <Access
          access={[CREATE_AD_CONFIG]}
          exact
          path={`${getPathFromBreadcrumb(AdConfigBreadcrumb)}/:id`}
          component={AdConfigCreate}
        />

        <Access access={[CREATE_EDITOR_LETTER]} exact path={'/premium'} component={PremiumAd} />
        <Access
          access={[CREATE_EDITOR_LETTER]}
          exact
          path={`${EditorLettersBreadcrumb[0].path}/:id?`}
          component={PremiumAd}
        />
        <Access
          access={[CREATE_EDITOR_LETTER]}
          exact
          path={`${AdsReplacementsBreadcrumb[0].path}/:id?`}
          component={PremiumAd}
        />
        <Access
          access={[CREATE_EDITOR_LETTER]}
          exact
          path={'/premium/timeline'}
          component={PremiumFeed}
        />
        <Access
          access={[CREATE_EVENT]}
          requiredFeature={FEATURE_EVENTS}
          exact
          path={'/event'}
          component={EventList}
        />
        <Access
          access={[CREATE_EVENT]}
          requiredFeature={FEATURE_EVENTS}
          exact
          path={'/event/:action'}
          component={EventCreate}
        />
        <Access
          access={[CREATE_EVENT]}
          requiredFeature={FEATURE_EVENTS}
          exact
          path={'/event/:action/:id'}
          component={EventCreate}
        />
        <Access
          access={[CREATE_STORY]}
          requiredFeature={FEATURE_STORIES}
          exact
          path="/story"
          component={StoryList}
        />
        <Access
          access={[CREATE_STORY]}
          requiredFeature={FEATURE_STORIES}
          exact
          path="/story/:action"
          component={StoryCreate}
        />
        <Access
          access={[CREATE_STORY]}
          requiredFeature={FEATURE_STORIES}
          exact
          path="/story/:action/:id"
          component={StoryCreate}
        />

        <Access
          access={[CREATE_LIVEBLOG]}
          requiredFeature={FEATURE_LIVEBLOGS}
          exact
          path="/liveblog"
          component={LiveblogList}
        />
        <Access
          access={[CREATE_LIVEBLOG]}
          requiredFeature={FEATURE_LIVEBLOGS}
          exact
          path="/liveblog/build"
          component={LiveblogCreate}
        />
        <Access
          access={[CREATE_LIVEBLOG]}
          requiredFeature={FEATURE_LIVEBLOGS}
          exact
          path="/liveblog/edit/:step/:id"
          component={LiveblogCreate}
        />
        <Access
          access={[CREATE_LIVEBLOG]}
          requiredFeature={FEATURE_LIVEBLOGS}
          exact
          path="/liveblog/:status"
          component={LiveblogList}
        />

        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path="/article/:action/:nid/:revisionId"
          component={ArticleCreate}
        />
        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path="/article/:action/:nid"
          component={ArticleCreate}
        />
        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path="/article/build"
          component={ArticleCreate}
        />
        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path="/article/preview/:pid"
          component={Preview}
        />
        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path={getPathFromBreadcrumb(ArticleListBreadcrumb)}
          component={ArticleList}
        />

        <Access
          access={[ACCESS_CONTENT]}
          exact
          path={`${getPathFromBreadcrumb(ConfigPageBreadcrumb)}/:type?`}
          component={ConfigPage}
        />

        {selectedPublication.publicationConfig
        && selectedPublication.publicationConfig.translationBaseSource
        && <Access
          access={[TRANSLATION]}
          exact
          path="/translate/:lang"
          component={ArticleTranslateLanding}
        />}
        <Access
          access={[TRANSLATION]}
          exact
          path="/translate/:lang"
          component={ArticleTranslateLanding}
        />
        {containInternalTranslateConfig(selectedPublication.publicationConfig) &&
        <Access
          access={[TRANSLATION]}
          exact
          path="/internal-translation/:lang"
          component={ArticleList}
        />}

        <Access
          access={[CREATE_ARTICLE_CONTENT]}
          requiredFeature={FEATURE_ARTICLE_CREATION}
          exact
          path="/ecommerce"
          component={AffiliateDashboard}
        />

        <Access access={[CREATE_MEDIA]} exact path="/media/image" component={MediaList} />
        <Access access={[CREATE_MEDIA]} exact path="/media/gallery" component={GalleryList} />
        <Access access={[CREATE_MEDIA]} exact path="/media/gallery/:id" component={GalleryCreate} />

        <Access access={[ACCESS_CONTENT]} exact path="/live-browse" component={SitePreview} />
        <Access access={[ACCESS_CONTENT]} exact path="/profile" component={Profile} />

        <Access
          access={[ACCESS_CONTENT]}
          requiredFeature={FEATURE_USER_EVENTS}
          exact
          path="/user-events"
          component={UserEventList}
        />

        <Access
          access={[ACCESS_CONTENT]}
          requiredFeature={FEATURE_USER_COMMENTS}
          exact
          path="/user-comments"
          component={UserCommentList}
        />



        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

Routes.propTypes = {
  selectedPublication: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default connect(({
  frame: { selectedPublication },
  router: { location: { pathname } },
}) => ({
  selectedPublication,
  pathname,
}))(Routes);
