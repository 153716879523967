import moment from 'moment';
import BaseEntity from './common/BaseEntity';

import {
  EVENT_FIELD_MAPPING,
  EVENT_PAYLOAD_FIELDS,
  EVENT_EXTRA_FIELDS,
  FIELD_SPEAKERS,
  FIELD_SPEAKERS_ORDER,
  FIELD_START_DATE,
  FIELD_END_DATE,
  EVENT_REQUIRED_FIELDS,
  SPEAKER_IMAGE,
  FIELD_HOSTS,
  FIELD_HOSTS_ORDER,
} from '../constants/event/eventFields';
import { FIELD_PUBLISHED } from '../constants/common/commonFields';
import { MEDIA_ENTITY_TYPE } from '../constants/media/media';

const ENTITY_TYPE = 'flowz_event';
export const ENTITY_BUNDLE = 'generic_event';

export const SPEAKER_ID = 'id';

class EventEntity extends BaseEntity {
  constructor() {
    super(
      ENTITY_TYPE,
      ENTITY_BUNDLE,
      EVENT_FIELD_MAPPING,
      EVENT_PAYLOAD_FIELDS,
      EVENT_EXTRA_FIELDS,
      EVENT_REQUIRED_FIELDS,
    );
  }
  addFieldData(data, payload) {
    return (field) => {
      switch (field) {
        case FIELD_END_DATE:
        case FIELD_START_DATE:
        case FIELD_PUBLISHED: {
          const fieldData = this.getFieldData(payload, field);
          if (fieldData) {
            data[field] = moment(fieldData).unix();
          }
          return;
        }
        default:
          data[field] = this.getFieldData(payload, field);
      }
    };
  }
  addExtraData(data, extras) {
    return (field) => {
      switch (field) {
        case FIELD_SPEAKERS: {
          const speakers = this.getExtraData(extras, field);
          data[field] = this.constructor.getArrayAsKeyed(speakers, SPEAKER_ID);
          data[FIELD_SPEAKERS_ORDER] = this.constructor.getArrayOrder(speakers, SPEAKER_ID);
          return;
        }
        case FIELD_HOSTS: {
          const speakers = this.getExtraData(extras, field);
          data[field] = this.constructor.getArrayAsKeyed(speakers, SPEAKER_ID);
          data[FIELD_HOSTS_ORDER] = this.constructor.getArrayOrder(speakers, SPEAKER_ID);
          return;
        }
        default:
          data[field] = this.getExtraData(extras, field);
      }
    };
  }
  getPayloadExtraData(data, field) {
    switch (field) {
      case FIELD_SPEAKERS:
        return this.constructor.getOrderedArray(
          data[field],
          data[FIELD_SPEAKERS_ORDER],
          (value, id) => ({
            [SPEAKER_ID]: id,
            ...value,
          }),
        );
      case FIELD_HOSTS:
        return this.constructor.getOrderedArray(
          data[field],
          data[FIELD_HOSTS_ORDER],
          (value, id) => ({
            [SPEAKER_ID]: id,
            ...value,
          }),
        );
      default:
        return data[field];
    }
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_END_DATE:
      case FIELD_START_DATE:
      case FIELD_PUBLISHED: {
        if (data[field]) {
          return super.getPayloadFieldData(data, field);
        }
        return null;
      }
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
  // @todo references only accepting 1 file on API
  // getMediaReferences(dataState) {
  //   const references = [];
  //   if (dataState[FIELD_HOSTS]) {
  //     Object.values(dataState[FIELD_HOSTS]).forEach((speaker) => {
  //       if (speaker[SPEAKER_IMAGE]?.data?.mid) {
  //         references.push({
  //           target_id: speaker[SPEAKER_IMAGE].data.mid,
  //           target_type: MEDIA_ENTITY_TYPE,
  //         });
  //       }
  //     });
  //   }
  //   if (dataState[FIELD_SPEAKERS]) {
  //     Object.values(dataState[FIELD_SPEAKERS]).forEach((speaker) => {
  //       if (speaker[SPEAKER_IMAGE]?.data?.mid) {
  //         references.push({
  //           target_id: speaker[SPEAKER_IMAGE].data.mid,
  //           target_type: MEDIA_ENTITY_TYPE,
  //         });
  //       }
  //     });
  //   }
  //   return references;
  // }
  getPayloadFromData(data, serverData, publication, publish) {
    // publish / unpublish
    if (publish && !data[FIELD_PUBLISHED]) {
      data[FIELD_PUBLISHED] = moment().unix();
    }
    if (!publish) {
      data[FIELD_PUBLISHED] = 0;
    }
    // force start and end date to now if not set
    if (!data[FIELD_START_DATE] && !serverData[FIELD_START_DATE]) {
      data[FIELD_START_DATE] = moment().unix();
    }
    if (!data[FIELD_END_DATE] && !serverData[FIELD_END_DATE]) {
      data[FIELD_END_DATE] = moment().unix();
    }
    return super.getPayloadFromData(data, serverData, publication);
  }
}

export default EventEntity;
