import HorizontalRuleElement from './components/HorizontalRuleElement';

export const ELEMENT_HR = 'horizontalRule';

export const DEFAULTS_HR = {
  horizontalRule: {
    component: HorizontalRuleElement,
    type: ELEMENT_HR,
    rootProps: {
      className: 'slate-horizontalRule',
    },
  },
};
