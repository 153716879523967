import BaseEntity from './common/BaseEntity';

import {
  CONFIG_PAYLOAD_FIELDS,
  CONFIG_FIELD_MAPPING,
  FIELD_DEV,
  FIELD_UAT,
  FIELD_PROD,
} from 'constants/config/configFields';

export const ENTITY_TYPE = 'publication_config';
export const ENTITY_BUNDLE = 'cms';

class ConfigEntity extends BaseEntity {
  constructor() {
    super(
      ENTITY_TYPE,
      ENTITY_BUNDLE,
      CONFIG_FIELD_MAPPING,
      CONFIG_PAYLOAD_FIELDS,
    );
  }
  getFieldData(payload, field) {
    const mapping = this.dataMapping[field];
    switch (field) {
      case FIELD_DEV:
      case FIELD_UAT:
      case FIELD_PROD: {
        try {
          return JSON.parse(payload[mapping.key][0].value);
        } catch (e) {
          return this.getFieldDefault(field);
        }
      }
      default:
        return super.getFieldData(payload, field);
    }
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_DEV:
      case FIELD_UAT:
      case FIELD_PROD:
        return [{ value: JSON.stringify(data[field]) }];
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
}

export default ConfigEntity;
