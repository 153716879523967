import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';

import { onReplaceTerm } from '../../../../actions/edition';

import Select from '../../../ui/Select';
import { FIELD_EDITION_TEMPLATES } from '../../../../constants/edition/editionFields';
import { EDITION_TEMPLATES } from '../../../../constants/vocab';

const EditionTypeSelector = (props) => {
  const {
    templates,
    vocab,
    onReplaceTerm: replaceTerm,
  } = props;
  const template = templates.length > 0 ? templates[0] : { id: '' };
  return (
    <FormControl>
      <Select
        label={'Article type'}
        value={template.id}
        onChange={e => replaceTerm(
          [FIELD_EDITION_TEMPLATES, vocab.items.find(item => item.id === e.target.value)]
        )}
        items={vocab.items || []}
        onBackground
      />
    </FormControl>
  );
};

EditionTypeSelector.propTypes = {
  templates: PropTypes.array.isRequired,
  onReplaceTerm: PropTypes.func.isRequired,
  vocab: PropTypes.object.isRequired,
};

export default connect(
  ({
    edition: { [FIELD_EDITION_TEMPLATES]: templates },
    vocab: { [EDITION_TEMPLATES]: vocab },
  }) => ({ templates, vocab }),
  { onReplaceTerm },
)(EditionTypeSelector);
