import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setArticleEditionProperty } from '../../../../actions/edition';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';
import TextField from '../../../ui/TextField';
import Select from '../../../ui/Select';
import { FIELD_HEADLINE, FIELD_TEMPLATE } from '../../../../constants/edition/editionFields';
import { SECTION_TEMPLATES, TEMPLATE_TWO_STORY } from '../../../../constants/edition/editionTemplates';

const SectionSettings = (props) => {
  const {
    template,
    headline,
    setArticleEditionProperty: setProperty,
  } = props;

  useEffect(() => {
    if (template === '') {
      setProperty([FIELD_TEMPLATE, TEMPLATE_TWO_STORY]);
    }
  }, []);
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Section Properties</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          label={'Template'}
          value={template}
          onChange={event => setProperty([FIELD_TEMPLATE, event.target.value])}
          items={SECTION_TEMPLATES}
        />
        <TextField
          label={'Section Headline'}
          value={headline}
          onChange={event => setProperty([FIELD_HEADLINE, event.target.value])}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

SectionSettings.propTypes = {
  template: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
};

export default connect(
  ({ edition: { template, headline } }) => ({ template, headline }),
  { setArticleEditionProperty },
)(SectionSettings);
