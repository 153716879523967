import { ofType } from 'redux-observable';
import {
  flatMap,
  map,
  merge,
  withLatestFrom,
  mergeMap,
  take,
  concat,
  mapTo,
  startWith,
  filter,
  switchMap,
} from 'rxjs/operators';

import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router';
import apiCatchError from '../helper/notification';
import accessControl from '../helper/accessControl';

import { AdConfigCreateBreadcrumb } from '../../layouts/AdConfigCreate';
import { getPathFromBreadcrumb } from '../../utils/breadcrumbHelper';

import {
  AD_ASSIGN_SECTION, AD_ASSIGN_ARTICLE,

} from '../../constants/actionTypes';
import { CMS_ADMIN_ROLE } from '../../constants/access';
import { PAGE_ENTER_AD, PAGE_ENTER_AD_CREATE } from '../../constants/actionTypes/route';
import { DIALOG_TOGGLE_ARTICLE_SEARCH } from '../../constants/actionTypes/dialog';
import { LAYOUT_FETCH_REJECTED } from '../../constants/actionTypes/layout';
import {
  AD_CONFIG_FETCH_LOADED,
  AD_CONFIG_REMOVE, AD_CONFIG_REMOVE_COMPLETED,
  AD_CONFIG_SAVE,
  AD_CONFIG_SELECT_ARTICLE,
  AD_CONFIG_SELECT_SECTION, AD_CONFIG_SERVER_RESET, AD_CONFIG_SET, AD_CREATE_FETCH_LOADED,
} from '../../constants/actionTypes/ad';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const assignedArticleToAdConfig = action$ => action$.pipe(
  ofType(AD_CONFIG_SELECT_ARTICLE),
  mergeMap(action => of({
    type: AD_ASSIGN_SECTION,
    value: {},
  }).pipe(
    startWith({
      type: AD_ASSIGN_ARTICLE,
      value: action.value,
    }),
  ),
  ),
);

export const closeSectionDialog = action$ => action$.pipe(
  ofType(AD_CONFIG_SELECT_SECTION, AD_CONFIG_SELECT_ARTICLE),
  mapTo({
    type: DIALOG_TOGGLE_ARTICLE_SEARCH,
    value: false,
  }),
);

export const assignedSectionToAdConfig = action$ => action$.pipe(
  ofType(AD_CONFIG_SELECT_SECTION),
  mergeMap(action => of({
    type: AD_ASSIGN_ARTICLE,
    value: {},
  }).pipe(
    startWith({
      type: AD_ASSIGN_SECTION,
      value: action.value,
    }),
  )),
);

export const createNewAdConfig = action$ => action$.pipe(
  ofType(AD_ASSIGN_ARTICLE, AD_ASSIGN_SECTION),
  mapTo(push(getPathFromBreadcrumb(AdConfigCreateBreadcrumb))),
);

export const resetAdConfigFromServer = action$ => action$.pipe(
  ofType(AD_ASSIGN_ARTICLE, AD_ASSIGN_SECTION),
  mapTo({
    type: AD_CONFIG_SERVER_RESET,
  }),
);

export const pageEnterCreate = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_AD_CREATE),
  withLatestFrom(state$),
  map(([, state]) => state),
  switchMap(({ router: { location: { pathname } } }) => {
    const [id] = pathname.match(/[0-9]+$/g);
    return ajax.getJSON(`/api/revenue/${id}`).pipe(
      flatMap(response => of({
        type: AD_CREATE_FETCH_LOADED,
        value: response,
      })),
      apiCatchError(LAYOUT_FETCH_REJECTED),
    );
  }),
);

export const adConfigOnCreateNew = (action$, state$) => action$.pipe(
  ofType(AD_CONFIG_SAVE),
  withLatestFrom(state$),
  map(([, state]) => state),
  filter(({ ad: { adConfigFromServer } }) => !adConfigFromServer.id),
  switchMap(({
    ad: { currentConfig, selectedSection, selectedArticle }, frame: { selectedPublication },
  }) => {
    const payload = {
      bundle: [
        {
          target_id: 'ad_config',
          target_type: 'flowz_ad_type',
        },
      ],
      field_description: [
        {
          value: '[wip]',
        },
      ],
      field_ad_config_json: [
        {
          value: JSON.stringify(currentConfig),
        },
      ],
      field_publication: [
        {
          target_id: selectedPublication.id,
        },
      ],
    };

    if (selectedArticle.target_id) {
      payload.name = [{
        value: `${selectedPublication.name} / ${selectedArticle.label}`,
      }];
      payload.field_article = [{
        target_id: selectedArticle.target_id,
      }];
    } else if (selectedSection.id) {
      payload.name = [{
        value: `${selectedPublication.name} / ${selectedSection.name}`,
      }];
      payload.field_section = [{
        target_id: selectedSection.id,
      }];
    }

    return ajax.post('/api/revenue', payload, { 'Content-Type': 'application/json' }).pipe(
      flatMap(({ response }) => of({
        type: AD_CREATE_FETCH_LOADED,
        value: response,
      })),
      concat(of(push('/ad'))),
      apiCatchError(LAYOUT_FETCH_REJECTED),
    );
  }),
);

export const adConfigOnEditSave = (action$, state$) => action$.pipe(
  ofType(AD_CONFIG_SAVE),
  withLatestFrom(state$),
  map(([, state]) => state),
  filter(({ ad: { adConfigFromServer } }) => adConfigFromServer.id),
  switchMap(({ ad: { currentConfig, adConfigFromServer: { id: [{ value }] } } }) => ajax.patch(`/api/revenue/${value}`, {
    bundle: [
      {
        target_id: 'ad_config',
        target_type: 'flowz_ad_type',
      },
    ],
    field_ad_config_json: [
      {
        value: JSON.stringify(currentConfig),
      },
    ],
  }, { 'Content-Type': 'application/json' }).pipe(
    flatMap(({ response }) => of({
      type: AD_CREATE_FETCH_LOADED,
      value: response,
    })),
    concat(of(push('/ad'))),
    apiCatchError(LAYOUT_FETCH_REJECTED),
  )),
);

export const resetCurrentConfig = action$ => action$.pipe(
  ofType(AD_CONFIG_SELECT_SECTION, AD_CONFIG_SELECT_ARTICLE),
  mergeMap(() => of({
    type: AD_CONFIG_SET,
    value: {},
  })),
);

export const onAdCreateConfigLoaded = (action$, state$) => action$.pipe(
  ofType(AD_CREATE_FETCH_LOADED),
  withLatestFrom(state$),
  mergeMap(([, state]) => {
    const [{ value }] = state.ad.adConfigFromServer.field_ad_config_json;
    const configFromServer = JSON.parse(value);
    return of({
      type: AD_CONFIG_SET,
      value: typeof configFromServer === 'object' && configFromServer !== null
        ? configFromServer
        : {},
    });
  }),
);

export const adConfigRemove = action$ => action$.pipe(
  ofType(AD_CONFIG_REMOVE),
  switchMap(action => ajax.delete(`/api/revenue/ad_config/${action.value}`).pipe(
    flatMap(() => of({
      type: AD_CONFIG_REMOVE_COMPLETED,
    })),
    apiCatchError(LAYOUT_FETCH_REJECTED),
  )),
);

const getAdList = (action$, state$) => {
  const selectPub$ = action$.pipe(
    ofType(PUBLICATION_SELECTED),
    accessControl(state$, [CMS_ADMIN_ROLE]),
  );
  const adConfigCompleted$ = action$.pipe(
    ofType(AD_CONFIG_REMOVE_COMPLETED),
  );
  return action$.pipe(
    ofType(PAGE_ENTER_AD),
    merge(selectPub$, adConfigCompleted$),
    withLatestFrom(state$),
    map(([, state]) => state),
    switchMap((state) => {
      const { selectedPublication } = state.frame;
      return ajax.getJSON(`/api/revenue/ad_config/${selectedPublication.domain}`).pipe(
        flatMap(response => of({
          type: AD_CONFIG_FETCH_LOADED,
          value: response,
        })),
        apiCatchError(LAYOUT_FETCH_REJECTED),
      );
    }),
  );
};

export default getAdList;
