import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveOutlined';

import LiveblogDialogSelector from '../../../dialog/LiveblogSelectorDialog';
import { setLocalProp } from '../../../../actions/dataState';

import { setContext } from '../../../../actions/page';
import { CONTEXT_DEFAULT, CONTEXT_LIVEBLOG } from '../../../../constants/contexts';

import Button from '../../../ui/buttons/Button';
import {
  PagePanel,
  PagePanelActions,
  PagePanelHeading,
  PagePanelSection,
  PagePanelContent,
} from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';

import LiveblogBuilderPostsTabs from './LiveblogPosts';
import LiveblogKeyPoints from './LiveblogKeyPoints';
import LiveblogMeta from './LiveblogMeta';
import LiveblogPostEditor from '../../../liveblog/LiveblogPostEditor';
import { FIELD_LIVEBLOG } from '../../../../constants/article/articleFields';

const LiveblogEditor = ({
  liveblog, body,
  setLocalProp: setProp,
  setContext: setArticleContext,
}) => {
  const [dialogList, setDialogList] = useState(false);
  useEffect(() => {
    const onIntersection = (entries) => {
      Object.entries(entries).forEach(([, entry]) => {
        if (entry.isIntersecting) {
          setArticleContext(CONTEXT_LIVEBLOG);
        } else {
          setArticleContext(CONTEXT_DEFAULT);
        }
      });
    };
    const observer = new IntersectionObserver(onIntersection, {
      threshold: 0,
      rootMargin: '0px 0px -80% 0px',
    });
    observer.observe(document.querySelector('#liveblogEditor'));
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <PagePanel id={'liveblogEditor'}>
      <PagePanelSection>
        <PagePanelHeading>
          <SectionTitle>Liveblog</SectionTitle>
          <PagePanelActions>
            {!liveblog && <Button
              size={'large'}
              onClick={() => setDialogList(true)}
              startIcon={<AddIcon />}
            >Add existing liveblog</Button>}
            {liveblog && <Button
              size={'large'}
              onClick={() => setProp(FIELD_LIVEBLOG, null)}
              startIcon={<RemoveIcon />}
            >Remove liveblog</Button>
            }
          </PagePanelActions>
        </PagePanelHeading>
        <PagePanelContent>
          <LiveblogMeta />
        </PagePanelContent>
      </PagePanelSection>
      <PagePanelSection>
        <PagePanelHeading>
          <SectionTitle>Key points</SectionTitle>
        </PagePanelHeading>
        <PagePanelContent>
          <LiveblogKeyPoints />
        </PagePanelContent>
      </PagePanelSection>
      <PagePanelSection>
        <PagePanelHeading>
          <SectionTitle>New post</SectionTitle>
        </PagePanelHeading>
        <PagePanelContent>
          <LiveblogPostEditor body={body} />
        </PagePanelContent>
      </PagePanelSection>
      <PagePanelSection>
        <LiveblogBuilderPostsTabs
          embedded
          containerId={liveblog}
          key={1}
        />
      </PagePanelSection>
      {dialogList && <LiveblogDialogSelector
        handleClose={() => setDialogList(false)}
        open={dialogList}
        selectLiveblog={(data) => {
          setProp(FIELD_LIVEBLOG, data.id);
          setDialogList(false);
        }}
      />}
    </PagePanel>
  );
};

LiveblogEditor.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  body: PropTypes.array,
  liveblog: PropTypes.number,
};

LiveblogEditor.defaultProps = {
  body: [],
  liveblog: null,
};

export default connect(
  ({
    liveblog: { body },
    dataState: { [FIELD_LIVEBLOG]: liveblog },
  }) => ({ liveblog, body }),
  {
    setContext,
    setLocalProp,
  },
)(LiveblogEditor);

