import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { resetBody } from '../../../actions/edition';
import { PagePanel } from '../../common/page/PagePanel';

const style = theme => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(4),
  },
  frame: {
    width: '100%',
    height: '100%',
  },
});

const processBodyData = body => body.map((component, index) => (
  {
    ...component,
    delta: index,
  }
));

const SectionMain = ({
  classes,
  articleId,
  environment,
  editionDetail,
  domain,
  template,
  body,
  resetBody: onDisposeComponent,
}) => {
  const [pwampLoaded, setPwampLoaded] = useState(false);
  useEffect(() => {
    const iframe = document.querySelector('#edition-preview');
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage({ event: 'updateEdition', data: { body: processBodyData(body), template } }, '*');
    }
  }, [body, template, pwampLoaded]);

  useEffect(() => {
    const iframe = document.querySelector('#edition-preview');
    if (iframe) {
      window.addEventListener('message', (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'PWAMP_PREVIEW_LOADED') {
            setPwampLoaded(true);
          }
        } catch (ex) {
          // console.error(ex);
        }
      }, false);
    }
    return () => {
      onDisposeComponent();
    };
  }, []);

  const containerIdParam = editionDetail ? `containerId=${editionDetail.id}` : '';

  const base = process.env.REACT_APP_ENVIRONMENT_WEB || environment.web.replace(/\/$/, '');

  return (
    <PagePanel className={classes.root}>
      <iframe
        id={'edition-preview'}
        className={classes.frame}
        title={'pwmap-preview'}
        src={`${base}/edition/section/${articleId}?${containerIdParam}&domain=${domain}&preview`}
        frameBorder={'0'}
      />
    </PagePanel>
  );
};

SectionMain.defaultProps = {
  editionDetail: null,
};

SectionMain.propTypes = {
  classes: PropTypes.object.isRequired,
  articleId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  domain: PropTypes.string.isRequired,
  environment: PropTypes.object.isRequired,
  editionDetail: PropTypes.object,
  template: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  resetBody: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(
  ({
    frame: { selectedPublication: { domain, environment } },
    edition: { template, body, editionDetail },
  }) => ({ domain, environment, template, body, editionDetail }),
  { resetBody },
)(SectionMain));
