import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import Public from '@material-ui/icons/Public';

import { getOGdata } from '../../../../../../actions/editor';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';

import { PROP_URL, PROP_TITLE, PROP_IMAGE, PROP_DESCRIPTION } from '../defaults';

const ArticleLinkElement = (props) => {
  const { attributes, children, element, className, getOGdata: getData } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Article Link'} Icon={Public} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin={'none'}
              placeholder={'Insert URL here'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_URL]: e.target.value }, { at: path });
                getData(e.target.value, ({ title, image, description }) => {
                  Transforms.setNodes(
                    editor,
                    {
                      [PROP_TITLE]: title,
                      [PROP_IMAGE]: image,
                      [PROP_DESCRIPTION]: description,
                    },
                    { at: path },
                  );
                });
              }}
              value={element[PROP_URL] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

ArticleLinkElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  getOGdata: PropTypes.func.isRequired,
};

export default connect(
  () => {},
  { getOGdata },
)(ArticleLinkElement);
