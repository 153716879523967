import React from 'react';
import PropTypes from 'prop-types';
import TagSelector from '../../ui/TagSelector';

const VocabField = (props) => {
  const {
    label,
    vocab,
    selectedTerms,
    onSelect,
    onRemove,
    limit,
    hierarchical,
    ...rest
  } = props;
  return (
    <TagSelector
      label={label}
      placeholder={`Search ${label}`}
      selectedTerms={selectedTerms.filter(Boolean)}
      vocab={vocab}
      onSelect={onSelect}
      onRemove={onRemove}
      limit={limit}
      hierarchical={hierarchical}
      {...rest}
    />
  );
};

VocabField.propTypes = {
  label: PropTypes.string.isRequired,
  vocab: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  limit: PropTypes.number,
  hierarchical: PropTypes.bool,
  selectedTerms: PropTypes.array,
};

VocabField.defaultProps = {
  selectedTerms: [],
  limit: -1,
  hierarchical: false,
};

export default VocabField;
