import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import SelectInput from '@material-ui/core/Select';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import RequiredIndicator from "./RequiredIndicator";

const styles = theme => ({
  control: {
    width: '100%',
  },
  label: {
    padding: '0 5px',
    marginLeft: -5,
    background: theme.palette.background.contrast,
  },
  changes: {
    color: theme.palette.secondary.main,
  },
  default: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
  },
  primary: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.dark,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.dark,
      },
    },
  },
  secondary: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.dark,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.dark,
      },
    },
  },
  hasIcon: {
    '& .MuiSelect-root': {
      paddingLeft: theme.spacing(4.5),
    },
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1.5)}px`,
  },
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  onBackground: {
    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      background: theme.palette.background.default,
    },
  },
});

const Select = ({
  classes, className, icon, items, margin, variant, color, label, required, emptyValue, hasChanges,
  MenuProps, onBackground,
  ...rest
}) => {
  const controlClasses = [classes.control];
  const labelClasses = [classes.label];
  if (className) {
    controlClasses.push(className);
  }
  if (icon) {
    controlClasses.push(classes.hasIcon);
  }
  if (hasChanges) {
    labelClasses.push(classes.changes);
  }
  if (onBackground) {
    labelClasses.push(classes.onBackground);
  }
  return (
    <FormControl className={controlClasses.join(' ')} margin={margin}>
      {label &&
        <InputLabel className={labelClasses.join(' ')} shrink variant={variant}>
          {required ? <>{label}<RequiredIndicator /></> : label}
        </InputLabel>
      }
      {icon && <div className={classes.iconWrapper}>{icon}</div>}
      <SelectInput
        variant={variant}
        className={color ? classes[color] : classes.default}
        displayEmpty
        {...rest}
        MenuProps={{
          ...MenuProps,
          className: classes.menu,
        }}
      >
        {emptyValue && <MenuItem value={''}>{emptyValue}</MenuItem>}
        {!Array.isArray(items) && Object.entries(items).map(([key, value]) => (
          <MenuItem key={key} value={key}>{value}</MenuItem>
        ))}
        {Array.isArray(items) && items.map(value => (
          typeof value === 'object'
            ? <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
            : <MenuItem key={value} value={value}>{value}</MenuItem>
        ))}
      </SelectInput>
    </FormControl>
  );
};

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  MenuProps: PropTypes.object,
  icon: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  emptyValue: PropTypes.string,
  hasChanges: PropTypes.bool,
  onBackground: PropTypes.bool,
  required: PropTypes.bool,
};

Select.defaultProps = {
  className: null,
  icon: null,
  color: '',
  label: '',
  margin: 'dense',
  variant: 'outlined',
  emptyValue: null,
  hasChanges: false,
  onBackground: false,
  MenuProps: {},
  required: false,
};

export default withStyles(styles)(Select);
