import { PURGE } from 'redux-persist/es/constants';
import {
  PRODUCT_LIST_FETCH,
  PRODUCT_LIST_FETCH_REJECTED,
  PRODUCT_LIST_FETCH_SUCCESS,
  PRODUCT_LIST_DISPOSE,
} from '../../constants/actionTypes/product';

const defaultState = {
  products: [],
  total: 0,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCT_LIST_FETCH:
      return {
        ...state,
        isFetching: true,
      };

    case PRODUCT_LIST_FETCH_SUCCESS: {
      const { products, paging: { total } } = action.value;
      return {
        ...state,
        products,
        total,
        isFetching: false };
    }

    case PRODUCT_LIST_FETCH_REJECTED:
    case PRODUCT_LIST_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
