import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EditOutlined from '@material-ui/icons/EditOutlined';

import { Table, TableRow, TableCell, TableHead, TableBody, TableHeadCell } from '../../ui/table/Table';
import IconButton from '../../ui/buttons/IconButton';
import { unscheduleExport, scheduleExport } from '../../../actions/edition';
import TimeForm from './TimeForm';

const styles = {
  root: {

  },
};

const ListTime = ({
  classes, list, deleteTimeEntry: onDelete, saveExportTime: onSave,
}) => {
  const [editId, setEditId] = useState(null);

  return (<Grid container>
    <Table>
      <TableHead>
        <TableRow>
          {[
            'Name', 'Type', 'Changes only', 'Create on publish', 'Version', 'Create at time', 'Action',
          ]
            .map(label => <TableHeadCell key={label}>{ label }</TableHeadCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {list && list.map(({ data: {
          title, id, bundle, changesOnly, createOnPublish, createAtTime, editionVersion,
          changesSinceTime,
        }}) => (<>
          <TableRow key={id}>
            <TableCell>{ title }</TableCell>
            <TableCell>{ bundle }</TableCell>
            <TableCell>{ changesOnly ? `Yes - since: ${changesSinceTime || ''}` : 'No' }</TableCell>
            <TableCell>{ createOnPublish ? 'Yes' : 'No' }</TableCell>
            <TableCell>{ editionVersion }</TableCell>
            <TableCell>{ createAtTime }</TableCell>
            <TableCell align={'right'}>
              <IconButton onClick={() => setEditId(id)}><EditOutlined /></IconButton>
              { /*<IconButton*/ }
              { /*  onClick={() => {*/ }
              { /*    window.confirm('Please confirm you want to remove this item')*/ }
              { /*    && onDelete(id);*/ }
              { /*  }}*/ }
              { /*><DeleteOutlined /></IconButton>*/ }
            </TableCell>
          </TableRow>
          {editId === id && <TableRow><TableCell colSpan={7}>
            <TimeForm
              data={{
                id,
                name: title,
                bundle,
                createOnPublish,
                createAtTime,
                version: editionVersion,
                changesOnly,
                changesSinceTime,
              }}
              onSubmit={(state) => {
                onSave(state);
                setEditId(null);
              }}
            /></TableCell></TableRow>}
        </>)) }
      </TableBody>
    </Table>
  </Grid>);
};

ListTime.defaultProps = {
};

ListTime.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  deleteTimeEntry: PropTypes.func.isRequired,
  saveExportTime: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  ({ edition: { exportSettingTimeList: list } }) => ({ list }),
  { deleteTimeEntry: unscheduleExport, saveExportTime: scheduleExport },
)(ListTime));

