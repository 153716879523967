import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { resetEditionPreviewClick } from '../../../actions/edition';
import { scrollToEl } from '../../helper/dom';

import ContextBar from '../../common/context/ContextBar';
import SectionSettings from './sectionContext/SectionSettings';
import TemplateContents from './sectionContext/TemplateContents';

const SidebarSection = (props) => {
  const {
    componentClick,
    resetEditionPreviewClick: resetClick,
  } = props;

  useEffect(() => {
    if (componentClick > -1) {
      scrollToEl(
        document.querySelectorAll('[data-type="edition-component"]'),
        document.querySelector('[data-type="edition-context"]'),
        componentClick,
      );
      resetClick();
    }
  }, [componentClick]);

  return (
    <ContextBar data-type={'edition-context'}>
      <SectionSettings />
      <TemplateContents />
    </ContextBar>
  );
};

SidebarSection.propTypes = {
  componentClick: PropTypes.number.isRequired,
  resetEditionPreviewClick: PropTypes.func.isRequired,
};

export default connect(
  ({ edition: { componentClick } }) => ({ componentClick }),
  { resetEditionPreviewClick },
)(SidebarSection);
