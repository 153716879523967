import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import { fetchBookingAmenities } from '../../actions/integration/booking';

import Autocomplete from '../ui/SelectAutocomplete';
import { queryStringify } from '../../utils/urlHelper';

import Dialog from './common/Dialog';

const style = theme => ({
  wrapper: {
    minHeight: 300,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
});

const AUTOCOMPLETE_PATH = '/json/autocomplete';

const processFetchHotelsResult = (result) => {
  const { result: hotels } = result;
  if (typeof hotels === 'undefined' || hotels.length < 1) {
    return [];
  }
  return hotels.map(hotel => ({
    label: hotel.name,
    value: hotel.id,
  }));
};
const fetchHotels = (apiEndpoint, username, password) => inputValue =>
  new Promise((resolve, reject) => {
    const params = {
      language: 'en',
      text: encodeURIComponent(inputValue),
    };
    if (inputValue.length > 3) {
      fetch(
        `${apiEndpoint}${AUTOCOMPLETE_PATH}?${queryStringify(params)}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
          },
        })
        .then(res => res.json())
        .then(
          (result) => {
            resolve(processFetchHotelsResult(result));
          },
          (error) => {
            reject(error);
          },
        );
    } else {
      reject('String must be 3 characters in length');
    }
  });

const BookingDialog = (props) => {
  const {
    classes,
    hotel,
    bookingConfig,
    onChangeHotel,
    ...rest
  } = props;
  const { apiEndpoint, username, password } = bookingConfig;

  return (<Dialog title={'Find a hotel'} maxWidth={'md'} {...rest}>
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {apiEndpoint && username && password && <Autocomplete
          value={hotel ? { label: hotel.name, value: hotel.id } : null}
          defaultOptions={false}
          loadOptions={fetchHotels(apiEndpoint, username, password)}
          onChange={onChangeHotel}
        />}
        {(!apiEndpoint || !username || !password) &&
          <Typography variant={'body2'}>Booking.com config not found.</Typography>
        }
      </div>
    </div>
  </Dialog>);
};

BookingDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  bookingConfig: PropTypes.object.isRequired,
  onChangeHotel: PropTypes.func.isRequired,
  hotel: PropTypes.object,
};

BookingDialog.defaultProps = {
  hotel: null,
};

export default withStyles(style)(connect(
  ({
    frame: {
      selectedPublication: {
        publicationConfig: { bookingConfig = {} },
      },
    },
  }) => ({ bookingConfig }),
  { },
)(BookingDialog));
