import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { CONFIG_COMMERCIAL_CAMPAIGNS } from 'constants/config/config';
import { allDistinct } from 'utils/helper/array';
import Button from 'components/ui/buttons/Button';

import ConfigPanel from '../ConfigPanel';
import ConfigField from '../ConfigField';

const CommercialCampaign = ({ edit, config, setConfig, errors }) => (
  <Grid container spacing={2}>
    {errors && errors.length > 0 && <Grid item xs={12}>
      <Typography color="error" variant="body1">{errors[0].message}</Typography>
    </Grid>}
    {config && config.length > 0 && config.map((campaign, i) =>
      <Grid key={`${CONFIG_COMMERCIAL_CAMPAIGNS}-${i}`} item xs={12}>
        <ConfigField
          edit={edit}
          onChange={e => setConfig(config.map((value, index) => index === i ? e.target.value : value))}
          value={campaign}
        /></Grid>,
    )}
    {!edit && (!config || config.length < 1) && <Grid item xs={12}>
      <Typography variant="body1">no campaigns available</Typography>
    </Grid>}
    {edit && <Grid item>
      <Button
        variant={'outlined'}
        onClick={() => {
          if (config) {
            setConfig([...config, '']);
          } else {
            setConfig(['']);
          }
        }}
        startIcon={<AddIcon />}
      >Add new campaign</Button>
    </Grid>}
  </Grid>
);

CommercialCampaign.propTypes = {
  edit: PropTypes.bool.isRequired,
  setConfig: PropTypes.func.isRequired,
  config: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
};

const validateConfig = config => {
  if (!allDistinct(config)) {
    return ([{ message: 'All campaigns must be unique.' }]);
  }
};

const CommercialCampaignPanel = () => (
  <ConfigPanel
    title="Commercial Campaigns"
    prop={CONFIG_COMMERCIAL_CAMPAIGNS}
    Component={CommercialCampaign}
    validateConfig={validateConfig}
  />
);

export default CommercialCampaignPanel;
