import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../ui/TextField';
import {
  MAX_LENGTH_STAND_FIRST,
} from '../../../../../constants/article/article';

const LeadField = (props) => {
  const { overrideValue, onChange } = props;
  const leadText = typeof overrideValue === 'string' && overrideValue.length > 0 ? overrideValue : '';
  const [lead, setLead] = useState(leadText);
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(leadText.length <= MAX_LENGTH_STAND_FIRST);
  }, [leadText]);
  return (
    <TextField
      label={`(${lead.length}/${MAX_LENGTH_STAND_FIRST} characters)`}
      placeholder={''}
      multiline
      rows={3}
      defaultValue={leadText}
      error={!valid}
      onChange={(event) => {
        onChange(event);
        setLead(event.target.value);
      }}
    />
  );
};

LeadField.propTypes = {
  onChange: PropTypes.func.isRequired,
  overrideValue: PropTypes.string,
};

LeadField.defaultProps = {
  overrideValue: null,
};

export default LeadField;
