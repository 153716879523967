import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Image from '../embed/Image';

import {
  H2, H3, H4, H5, H6,
  LISTBULLETED, LISTNUMBERED,
  MARKUP, TABLE,
  HR,
  IMAGE,
} from '../../constants/editor/dataTypes';

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
  },
});

const EditorPreview = (props) => {
  const { classes, content } = props;
  return content.map(({ type, data }) => {
    switch (type) {
      case H2:
      case H3:
      case H4:
      case H5:
      case H6:
      case LISTBULLETED:
      case LISTNUMBERED:
      case TABLE:
      case MARKUP:
        return <Typography variant={'body2'} dangerouslySetInnerHTML={{ __html: data.markup }} />;
      case HR:
        return <hr />;
      case IMAGE:
        return <Image data={data} />;
      default:
        return <Typography variant={'body2'} className={classes.error}>Unknown type component {type}</Typography>;
    }
  });
};

EditorPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.array,
};

EditorPreview.defaultProps = {
  content: [],
};

export default withStyles(styles)(EditorPreview);
