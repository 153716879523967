import { PURGE } from 'redux-persist';
import {
  SET_VOCAB,
  TERM_AUTHOR_SAVED,
  TERM_LIST_AUTHOR_LOADED,
  TERM_SECTION_TREE_FLATTENED,
  TERM_SECTION_TREE_MENU,
  ON_CHANGE_SECTION_TREE,
  SET_WORKFLOW_GENERIC,
  SET_DEFAULT_CONTENT_SOURCE,
  POPULAR_VOCAB_SUCCESS,
  SUGGESTIVE_VOCAB_SUCCESS,
  SET_DEFAULT_EDITION_SECTION, SET_DEFAULT_ARTICLE_TYPE,
} from '../../constants/actionTypes';

import {
  VOCAB_CURRENT_TERM_DISPOSE,
  VOCAB_FETCH_TERM_REJECTED,
  VOCAB_FETCH_TERM_SUCCESS,
} from '../../constants/actionTypes/vocab';

const pugpigTemplateOptions = [
  'news',
  'voices',
  'section2',
  'editorial',
  // 'brief',
  'review',
  'score',
  // 'explainer',
].map(type => [type, type]);

const initVocab = {
  items: [],
  total: 0,
};

const defaultState = {
  pugpig_templates: {
    ...initVocab,
    total: pugpigTemplateOptions.length,
    items: pugpigTemplateOptions.map(([id, name]) => ({ id, name })),
  },
  pugpig_section: initVocab,
  sponsor_prefix: initVocab,
  authors: initVocab,
  chain: initVocab,
  events: initVocab,
  tags: initVocab,
  people: initVocab,
  sections: initVocab,
  teams: initVocab,
  topics: initVocab,
  article_type: initVocab,
  content_source: initVocab,
  sectionTree: null,
  sectionTreeMenu: null,
  uploadBusy: false,
  workflowGeneric: null,
  defaultContentSource: null,
  defaultArticleType: null,
  defaultEditionSection: null,
  popular: {},
  related: {},
  currentTermFromAPI: null,
};

const getAuthorField = datas => prop => datas[prop].length > 0 && datas[prop][0].value || '';

export default (state = defaultState, action) => {
  switch (action.type) {
    case VOCAB_CURRENT_TERM_DISPOSE:
    case VOCAB_FETCH_TERM_REJECTED:
      return {
        ...state,
        currentTermFromAPI: null,
      };

    case VOCAB_FETCH_TERM_SUCCESS:
      return {
        ...state,
        currentTermFromAPI: action.value,
      };

    case SET_DEFAULT_EDITION_SECTION:
      return { ...state, defaultEditionSection: action.value };

    case SET_DEFAULT_CONTENT_SOURCE:
      return { ...state, defaultContentSource: action.value };

    case SET_DEFAULT_ARTICLE_TYPE:
      return { ...state, defaultArticleType: action.value };

    case SET_WORKFLOW_GENERIC:
      return { ...state, workflowGeneric: action.value };

    case ON_CHANGE_SECTION_TREE:
    case TERM_SECTION_TREE_MENU:
      return { ...state, sectionTreeMenu: action.value };

    case TERM_SECTION_TREE_FLATTENED:
      return { ...state, sectionTree: action.value };

    case TERM_AUTHOR_SAVED:
      return {
        ...state,
        authors: {
          ...state.authors,
          items: state.authors.items.map((author) => {
            const current = action.value;
            if (Number(author.id) === current.tid[0].value) {
              const lense = getAuthorField(current);
              return {
                ...author,
                path: current.path[0].alias,
                name: lense('name'),
                email: lense('field_author_email'),
                imageUrl: lense('field_author_image'),
                twitter: lense('field_author_twitter'),
                location: lense('field_author_location'),
                facebook: lense('field_author_facebook'),
                description: lense('description'),
                jobTitle: lense('field_author_title'),
              };
            }
            return author;
          }),
        },
      };

    case SET_VOCAB: {
      const [key, data] = action.value;
      return {
        ...state,
        [key]: {
          ...data,
        },
      };
    }

    case TERM_LIST_AUTHOR_LOADED:
      return { ...state, authors: action.value };

    case POPULAR_VOCAB_SUCCESS: {
      const [vocab, terms] = action.value;
      return { ...state, popular: { ...state.popular, [vocab]: terms } };
    }

    case SUGGESTIVE_VOCAB_SUCCESS: {
      const [vocab, terms] = action.value;
      return { ...state, related: { ...state.related, [vocab]: terms } };
    }

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
