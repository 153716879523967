import { PURGE } from 'redux-persist';

import {
  PLAYINC_FETCH_VIDEOS,
  PLAYINC_FETCH_VIDEOS_FAILURE,
  PLAYINC_FETCH_VIDEOS_SUCCESS,
  PLAYINC_DISPOSE_VIDEOS,
  PLAYINC_FETCH_PLAYLISTS_SUCCESS, PLAYINC_SAVE_VIDEO_SUCCESS,
} from '../../constants/actionTypes/integration/playInc';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PLAYINC_FETCH_VIDEOS: {
      return {
        ...state,
        isFetchingVideos: true,
      };
    }
    case PLAYINC_FETCH_VIDEOS_SUCCESS: {
      const { videos = [], total = 0 } = action.value;
      return {
        ...state,
        videos,
        totalVideos: total,
        isFetchingVideos: false,
      };
    }
    case PLAYINC_FETCH_VIDEOS_FAILURE:
      return {
        ...state,
        isFetchingVideos: false,
      };
    case PLAYINC_SAVE_VIDEO_SUCCESS:
      return {
        ...state,
        videos: state.videos.map(video =>
          (video.mediaid === action.value.mediaid ? action.value : video),
        ),
      };
    case PLAYINC_FETCH_PLAYLISTS_SUCCESS: {
      const { playlists = [] } = action.value;
      return {
        ...state,
        playlists,
      };
    }
    case PLAYINC_DISPOSE_VIDEOS:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
