
export const VIAFOURA_NOTIFICATIONS_FETCH = 'VIAFOURA_NOTIFICATIONS_FETCH';
export const VIAFOURA_NOTIFICATIONS_DISPOSE = 'VIAFOURA_NOTIFICATIONS_DISPOSE';
export const VIAFOURA_NOTIFICATION_DISPOSE = 'VIAFOURA_NOTIFICATION_DISPOSE';
export const VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS = 'VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS';
export const VIAFOURA_NOTIFICATIONS_FETCH_REJECTED = 'VIAFOURA_NOTIFICATIONS_FETCH_REJECTED';

export const VIAFOURA_NOTIFICATION_SAVE = 'VIAFOURA_NOTIFICATION_SAVE';
export const VIAFOURA_NOTIFICATION_SAVE_SUCCESS = 'VIAFOURA_NOTIFICATION_SAVE_SUCCESS';
export const VIAFOURA_NOTIFICATION_SAVE_REJECTED = 'VIAFOURA_NOTIFICATION_SAVE_REJECTED';

export const VIAFOURA_NOTIFICATION_SEND = 'VIAFOURA_NOTIFICATION_SEND';
export const VIAFOURA_NOTIFICATION_SEND_READY = 'VIAFOURA_NOTIFICATION_SEND_READY';
export const VIAFOURA_NOTIFICATION_SEND_SUCCESS = 'VIAFOURA_NOTIFICATION_SEND_SUCCESS';
export const VIAFOURA_NOTIFICATION_SEND_REJECTED = 'VIAFOURA_NOTIFICATION_SEND_REJECTED';
