import React from 'react';
import PropTypes from 'prop-types';

import MarkupField from '../../../../ui/MarkupField';
import TextField from '../../../../ui/TextField';
import Select from '../../../../ui/Select';
import ArticleRef from './common/ArticleRef';
import ImageSelector from '../../../../ui/media/ImageSelector';

const FIELD_HEADLINE = 'headline';
const FIELD_SUBHEADLINE = 'subheadline';
const FIELD_HEADLINE_SIZE = 'headlineSize';
const FIELD_SUBHEADLINE_SIZE = 'subheadlineSize';
const FIELD_BODY = 'body';
const FIELD_CAPTION = 'caption';
const FIELD_CAPSULE = 'capsule';
const FIELD_AUTHOR = 'author';
const FIELD_AUTHOR_LOCATION = 'authorLocation';
const FIELD_ARTICLE_REF = 'articleRef';
const FIELD_IMAGE = 'image';

const SIZES = [
  'normal',
  'small',
  'large',
];

// @todo ArticleX1 should extend TextSplash as is basically a TextSplash with an image
const ArticleX1 = (props) => {
  const {
    component: {
      image, caption, headline, headlineSize, subheadline, subheadlineSize,
      body, author, authorLocation, articleRef, capsule,
    },
    setProperty, delta,
  } = props;
  const assignImage = (data) => {
    setProperty({
      delta,
      prop: FIELD_IMAGE,
      value: {
        type: 'image',
        data,
      },
    });
    setProperty({
      delta,
      prop: FIELD_CAPTION,
      value: data?.caption || '',
    });
  };
  return (
    <>
      <ImageSelector
        selectImage={assignImage}
        onRemove={() => assignImage(null)}
        label="Add image"
        image={image?.data}
        showEditDialog
      />
      <TextField
        label="Caption"
        value={caption || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_CAPTION,
          value: event.target.value,
        })}
      />
      <TextField
        label="Headline"
        value={headline || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE,
          value: event.target.value,
        })}
      />
      <Select
        label="Headline Size"
        value={headlineSize || SIZES[0]}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE_SIZE,
          value: event.target.value,
        })}
        items={SIZES}
      />
      <MarkupField
        label="Subheadline"
        data={subheadline || ''}
        placeholder={''}
        onChange={markup => setProperty({
          delta,
          prop: FIELD_SUBHEADLINE,
          value: markup,
        })}
      />
      <Select
        label="Subheadline Size"
        value={subheadlineSize || SIZES[0]}
        onChange={event => setProperty({
          delta,
          prop: FIELD_SUBHEADLINE_SIZE,
          value: event.target.value,
        })}
        items={SIZES}
      />
      <TextField
        label="Author"
        value={author || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_AUTHOR,
          value: event.target.value,
        })}
      />
      <TextField
        label="Author Location"
        value={authorLocation || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_AUTHOR_LOCATION,
          value: event.target.value,
        })}
      />
      <TextField
        label="Capsule"
        value={capsule || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_CAPSULE,
          value: event.target.value,
        })}
      />
      <MarkupField
        label="Body"
        data={body || ''}
        placeholder={''}
        onChange={markup => setProperty({
          delta,
          prop: FIELD_BODY,
          value: markup,
        })}
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={value => setProperty({
          delta,
          prop: FIELD_ARTICLE_REF,
          value: value ? {
            title: value.data.title,
            id: value.data.id,
            bundle: value.type,
          } : null,
        })}
      />
    </>
  );
};

ArticleX1.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default ArticleX1;
