import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { CONTEXT_IMAGE } from '../../constants/contexts';
import GalleryEdit from './contexts/GalleryEdit';
import ImageEdit from './contexts/ImageEdit';

const GallerySidebar = ({
  context,
  ...rest
}) => {
  switch (context?.type) {
    case CONTEXT_IMAGE:
      return <ImageEdit {...rest} />;
    default:
      return <GalleryEdit {...rest} />;
  }
};

GallerySidebar.propTypes = {
  context: PropTypes.object.isRequired,
};

export default connect(
  ({ gallery: { context } }) => ({ context }),
  {},
)(GallerySidebar);
