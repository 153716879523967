import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../FieldLockField';
import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';
import { setLocalProp } from '../../../actions/dataState';
import DateTimePicker from '../../ui/DateTimePicker';

const FieldLockDateTime = ({
  value,
  label,
  lockValue,
  field,
  hasChanges,
  disabled,
  setLocalProp: setProp,
  ...rest
}) => {
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  return (
    <FieldLockField field={field} lockValue={lockValue}>
      <DateTimePicker
        label={label}
        hasChanges={hasChanges}
        date={value}
        onChange={date => setProp(prop, date.unix(), propChain)}
        {...rest}
      />
    </FieldLockField>
  );
}

FieldLockDateTime.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  value: PropTypes.number,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLockDateTime.defaultProps = {
  value: null,
  lockValue: null,
  hasChanges: false,
  disabled: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockDateTime);
