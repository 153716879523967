import {
  ELEMENT_BUTTON_LINK,
  PROP_TITLE,
  PROP_URL,
  PROP_HEADING,
  PROP_DESCRIPTION
} from './defaults';
import { BUTTON_LINK } from '../../../../../constants/editor/dataTypes';

export const convertToButtonLink = data => ({
  type: ELEMENT_BUTTON_LINK,
  [PROP_TITLE]: data[PROP_TITLE] || '',
  [PROP_URL]: data[PROP_URL] || '',
  [PROP_HEADING]: data[PROP_HEADING] || '',
  [PROP_DESCRIPTION]: data[PROP_DESCRIPTION] || '',
  children: [{ text: '' }],
});

export const convertFromButtonLink = data => ({
  type: BUTTON_LINK,
  data: {
    [PROP_TITLE]: data[PROP_TITLE] || '',
    [PROP_URL]: data[PROP_URL] || '',
    [PROP_HEADING]: data[PROP_HEADING] || '',
    [PROP_DESCRIPTION]: data[PROP_DESCRIPTION] || '',
  },
});
