import { switchMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import apiCatchError from '../helper/notification';
import {
  FORGOTTEN_PASSWORD_REQUEST_FAILURE,
  FORGOTTEN_PASSWORD_REQUEST,
  FORGOTTEN_PASSWORD_REQUEST_SUCCESS,
} from 'constants/actionTypes/login';

const onSubmitForgottenPassword = action$=> {
  return action$.pipe(
    ofType(FORGOTTEN_PASSWORD_REQUEST),
    switchMap(({ value: email }) => {
      if (email) {
        const payload = {
          mail: email,
        };
        return ajax.post(
          'api/user/lost-password',
          payload,
          { 'Content-Type': 'application/json' },
        ).pipe(
          map(() => ({
            type: FORGOTTEN_PASSWORD_REQUEST_SUCCESS,
            value: 'Password reset email has been sent.',
          })),
          apiCatchError(FORGOTTEN_PASSWORD_REQUEST_FAILURE, 'Failed to send password reset.'),
        );
      }
      return of({
        type: FORGOTTEN_PASSWORD_REQUEST_FAILURE,
        value: 'Please enter a valid email.',
      });
    }),
  );
};

export default onSubmitForgottenPassword;

