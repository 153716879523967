import {
  ADS_TXT_PAGE_LOADED,
  ADS_TXT_SET_PROPERTY,
  SAVE_ADS_TXT,
  ADS_TXT_PAGE_DISPOSE,
} from '../constants/staticPage/adsTxt';

export const setAdsTxt = value => ({ type: ADS_TXT_SET_PROPERTY, value });
export const adsTxtPageLoaded = value => ({ type: ADS_TXT_PAGE_LOADED, value });
export const adsTxtPageDispose = () => ({ type: ADS_TXT_PAGE_DISPOSE });
export const saveAdsTxt = () => ({ type: SAVE_ADS_TXT });
