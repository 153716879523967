import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_BLOCKQUOTE, PROP_MARKUP, PROP_CITATION, PROP_ALIGN } from './defaults';
import { BLOCKQUOTE } from '../../../../../constants/editor/dataTypes';

export const convertToBlockquote = data => ({
  type: ELEMENT_BLOCKQUOTE,
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  [PROP_CITATION]: data[PROP_CITATION] || '',
  [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  children: [{ text: '' }],
});

export const convertFromBlockquote = data => ({
  type: BLOCKQUOTE,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP]
      ? `<blockquote>${serializeNodes(data[PROP_MARKUP])}</blockquote>`
      : null,
    [PROP_CITATION]: data[PROP_CITATION] || '',
    [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  },
});
