import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TagSelector from '../../ui/TagSelector';
import { FIELD_AUTHORS } from '../../../constants/story/storyFields';
import { AUTHORS } from '../../../constants/vocab';
import { addToStoryField, removeFromStoryField } from '../../../actions/story';

const LABEL = 'Author(s)';
const PLACEHOLDER = 'Search authors';
const VOCAB = AUTHORS;

const Authors = (props) => {
  const {
    values,
    addToStoryField: addTerm,
    removeFromStoryField: removeTerm,
  } = props;
  return (
    <TagSelector
      label={LABEL}
      placeholder={PLACEHOLDER}
      selectedTerms={values.filter(Boolean)}
      vocab={VOCAB}
      onSelect={value => addTerm(FIELD_AUTHORS, value[1])}
      onRemove={value => removeTerm(FIELD_AUTHORS, value[1])}
    />
  );
};

Authors.propTypes = {
  addToStoryField: PropTypes.func.isRequired,
  removeFromStoryField: PropTypes.func.isRequired,
  values: PropTypes.array,
};

Authors.defaultProps = {
  values: [],
};

export default connect(
  ({ story: { [FIELD_AUTHORS]: values } }) => ({ values }),
  { addToStoryField, removeFromStoryField },
)(Authors);
