export const SET_ADDRESS_PROP = 'SET_ADDRESS_PROP';
export const SET_GROUP_PROP = 'SET_GROUP_PROP';
export const APPEND_CONTACT_GROUP = 'APPEND_CONTACT_GROUP';
export const APPEND_CONTACT_ITEM = 'APPEND_CONTACT_ITEM';
export const SAVE_CONTACT_US = 'SAVE_CONTACT_US';
export const SAVE_CONTACT_US_SUCCESS = 'SAVE_CONTACT_US_SUCCESS';
export const FETCH_CONTACT_US_SUCCESS = 'FETCH_CONTACT_US_SUCCESS';
export const SAVE_CONTACT_US_REJECTED = 'SAVE_CONTACT_US_REJECTED';
export const CONTACT_US_PAGE_LOADED = 'CONTACT_US_PAGE_LOADED';
export const CONTACT_US_SET_PROPERTY = 'CONTACT_US_SET_PROPERTY';
export const REMOVE_CONTACT_GROUP = 'REMOVE_CONTACT_GROUP';
export const SORT_CONTACT = 'SORT_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';

export const SET_STATIC_PAGE_TYPES = 'SET_STATIC_PAGE_TYPES';
