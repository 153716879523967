// video types are identical so import/export here
export { VIDEO_DM, VIDEO_DUGOUT, VIDEO_JWPLAYER, VIDEO_YOUTUBE } from '../media/media';

export const H2 = 'H2';
export const H3 = 'H3';
export const H4 = 'H4';
export const H5 = 'H5';
export const H6 = 'H6';
export const LISTBULLETED = 'listbulleted';
export const LISTNUMBERED = 'listnumbered';
export const HR = 'HR';
export const MARKUP = 'markup';
export const HTML = 'html';
export const IFRAME = 'iframe';
export const INFOBOX = 'infobox';
export const FAQ = 'faq';
export const NUMBERBOX = 'numberbox';
export const TWITTER = 'twitter';
export const TIKTOK = 'tiktok';
export const FACEBOOK = 'facebook';
export const INSTAGRAM = 'instagram';
export const GALLERY = 'gallery';
export const IMAGE = 'image';
export const RELATED = 'related';
export const STORY = 'story';
export const PRICE_COMPARISON = 'price-comparison';
export const BLOCKQUOTE = 'blockquote';
export const TABLE = 'table';
export const ARTICLE_LINK = 'article-link';
export const HIDE_SHOW = 'hide-show';
export const PARALLAX = 'parallax';
export const BUTTON_LINK = 'button';
export const OPTA = 'opta';
export const VOUCHER = 'voucher';

// third party integration
export const EMBED_BOOKING = 'embed-booking';

// converter const @todo deprecate and remove for v2
export const PROP_LOCAL_CAPTION = 'localCaption';
export const PROP_LOCAL_COPYRIGHT = 'localCopyright';
export const PROP_ALIGN = 'align';
export const PROP_LINKS = 'links';
export const PROP_URL = 'url';
export const PROP_VIDEOTYPE = 'videoType';
