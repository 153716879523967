import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MediaRatio from '../MediaRatio';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
  },
  videoRoot: {
    textAlign: 'center',
    width: '100%',
  },
});

const VideoDailyMotion = ({
  // eslint-disable-next-line camelcase
  video: { id, title, url },
  classes,
}) => {
  let foundId;
  // free text url
  if (url) {
    const { pathname } = new URL(url);
    const paths = pathname.split('/');
    foundId = paths[paths.length - 1];
  }
  if (!foundId && id) {
    foundId = id;
  }
  if (!foundId) return <Typography variant={'h6'}>Video DailyMotion id not found</Typography>;

  return (
    <div className={classes.root}>
      <Typography variant={'h6'}>{title}</Typography>
      <div className={classes.videoRoot}>
        <MediaRatio width={'100%'}>
          <iframe
            title={'Daily Motion preview video'}
            frameBorder="0"
            src={`https://www.dailymotion.com/embed/video/${foundId}`}
            allowFullScreen
            allow="autoplay"
          />
        </MediaRatio>
      </div>
    </div>
  );
};

VideoDailyMotion.propTypes = {
  video: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

VideoDailyMotion.defaultProps = {
  video: {
    id: null,
  },
};

export default withStyles(styles)(VideoDailyMotion);

