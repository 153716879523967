import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Title from '../../../liveblog/liveblogBuilder/Title';
import Status from '../../../liveblog/liveblogBuilder/Status';

const styles = theme => ({
  title: {
    marginRight: theme.spacing(4),
  },
  status: {
    flexShrink: 0,
    margin: 0,
    padding: theme.spacing(0, 0, 1),
  },
});

const LiveblogMeta = ({ classes }) => (
  <Grid container justify={'space-between'} alignItems={'center'} wrap={'nowrap'}>
    <Title className={classes.title} />
    <Status className={classes.status} showLabel={false} />
  </Grid>
);

LiveblogMeta.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LiveblogMeta);
