import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_HTML } from './defaults';

const deserializeHtml = (options) => {
  const { html } = setDefaults(options, DEFAULTS_HTML);

  return {
    element: getNodeDeserializer({
      type: html.type,
      node: (el) => {
        const markup = el.innerHTML;
        return {
          type: html.type,
          markup,
        };
      },
      rules: [
        { className: html.rootProps.className },
      ],
      ...options?.html?.deserialize,
    }),
  };
};

export default deserializeHtml;
