import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import capitalize from '@material-ui/core/utils/capitalize';
import Typography from '@material-ui/core/Typography';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';
import PageSection from '../components/common/page/PageSection';

import { PDF_WIDTH, PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE } from '../constants/preview';

import { enterArticleEditionCreation, editionDetailFetch } from '../actions/edition';

import PreviewUI from '../components/ui/components/PreviewUI';
import { EditionListBreadcrumb } from './EditionList';
import Loading from './Loading';

const styles = theme => ({
  frame: {
    width: '100%',
  },
  previewRoot: {
    height: `calc(100% - ${theme.spacing(4) * 2}px)`,
    flexDirection: 'column',
    display: 'flex',
    maxWidth: PDF_WIDTH,
  },
  previewRootMobile: {
    height: `calc(100% - ${theme.spacing(4) * 2}px)`,
    flexDirection: 'column',
    display: 'flex',
    maxWidth: 400,
  },
  busy: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexDirection: 'column',
    flex: 1,
  },
});

const EditionBrowse = ({
  classes,
  editionDetailFetch: editionFetch,
  match,
  selectedPublication,
  push: goto,
  editionDetail,
  query,
}) => {
  const { params: { id, type, editionId } } = match;
  const element = useRef();
  const frontEndUrl = process.env.REACT_APP_ENVIRONMENT_WEB || selectedPublication.environment.web.replace(/\/$/, '');
  const articleId = typeof id === 'undefined'
    ? (editionDetail && editionDetail.pages && editionDetail.pages[0] && editionDetail.pages[0].data.id) || null
    : id;
  const previewMode = (query.mode && query.mode === PREVIEW_MODE_MOBILE)
    ? PREVIEW_MODE_MOBILE
    : PREVIEW_MODE_DESKTOP;

  const breadcrumb = [
    ...EditionListBreadcrumb,
    {
      title: capitalize(type),
      slug: type,
    },
    {
      title: 'Edition',
      slug: editionId,
    },
    {
      title: 'Article',
      slug: articleId,
    },
    {
      title: 'Browse',
      slug: 'browse',
    },
  ];
  const Toolbar = (
    <PageToolbar breadcrumb={breadcrumb}>
      <Grid container justify={'center'} alignItems={'center'}>
        <PreviewUI
          active={previewMode}
          mobileToggle={() => goto(`/edition/${type}/${editionId}/${articleId}/browse?mode=${PREVIEW_MODE_MOBILE}`)}
          desktopToggle={() => goto(`/edition/${type}/${editionId}/${articleId}/browse?mode=${PREVIEW_MODE_DESKTOP}`)}
        />
      </Grid>
    </PageToolbar>
  );

  useEffect(() => {
    if (
      (editionDetail && parseInt(editionDetail.id, 10) !== parseInt(editionId, 10)) ||
      !editionDetail
    ) {
      editionFetch(editionId);
    }
  }, [editionDetail, editionId, editionFetch]);

  if (!articleId) {
    return (<Page fullWidth toolbarContent={Toolbar}><Typography className={classes.busy} align={'center'}>Loading...</Typography></Page>);
  }

  const rootClassName = (previewMode === PREVIEW_MODE_MOBILE)
    ? classes.previewRootMobile
    : classes.previewRoot;

  function resizeCrossDomainIframe() {
    window.addEventListener('message', (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'PWAMP_LOADED_HEIGHT') {
          const height = data.value;
          element.current.height = `${height}px`;
        }
      } catch (ex) {
        // console.error(ex);
      }
    }, false);
  }

  if (!editionDetail) {
    return (<Loading />);
  }

  let previousId;
  let nextId;
  let templateType = 'article';
  editionDetail.pages.forEach(({ type: entityType, data }, index) => {
    if (data.id === parseInt(articleId, 10)) {
      const previousPage = editionDetail.pages[index - 1];
      const nextPage = editionDetail.pages[index + 1];
      if (previousPage) {
        previousId = previousPage.data.id;
      }
      if (nextPage) {
        nextId = nextPage.data.id;
      }
      switch (true) {
        case /article|single_image/.test(entityType):
          templateType = 'article';
          break;
        case /section/.test(entityType):
          templateType = 'section';
          break;
        default:
          console.warn('Unhandled template type...');
      }
    }
  });

  return (
    <Page toolbarContent={Toolbar}>
      <PageContent className={rootClassName} direction={'row'}>
        <Grid item xs={12}>
          <Grid container justify={'space-between'}>
            <Grid item>
              {previousId &&
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => goto(`/edition/${type}/${editionId}/${previousId}/browse?mode=${previewMode}&containerId=${editionDetail.id}`)}
                >Previous</Button>
              }
            </Grid>
            <Grid item>
              {nextId &&
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => goto(`/edition/${type}/${editionId}/${nextId}/browse?mode=${previewMode}&containerId=${editionDetail.id}`)}
                >Next</Button>
              }
            </Grid>
          </Grid>
        </Grid>
        <PageSection>
          <iframe
            className={classes.frame}
            title={'pwmap-preview'}
            ref={element}
            src={`${frontEndUrl}/edition/${templateType}/${articleId}?live-browse&containerId=${editionDetail.id}`}
            // src={`http://localhost:8020/edition/${templateType}/${articleId}?containerId=${editionDetail.id}&pdf&singlePage=1&domain=independent.co.uk`}
            // height={800}
            onLoad={resizeCrossDomainIframe()}
            frameBorder={'0'}
            scrolling={'no'}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

EditionBrowse.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  editionDetailFetch: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  editionDetail: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
};

EditionBrowse.defaultProps = {
};

export default withStyles(styles)(connect(
  ({
    frame: {
      selectedPublication,
    },
    edition: { editionDetail },
    router: { location: { query } },
  }) =>
    ({ selectedPublication, editionDetail, query }),
  {
    enterArticleEditionCreation,
    editionDetailFetch,
    push,
  })(EditionBrowse));
