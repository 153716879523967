import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';

import { updateTimelineItemProperty, saveTimelineFeed, fetchFeeds } from '../actions/feed';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import AdMenuControlBar from '../components/ad/AdMenuControlBar';
import Button from '../components/ui/buttons/Button';
import ArticleTimelineFeed from '../components/feed/ArticleTimelineFeed';
import ArticleFeedContext from '../components/feed/ArticleFeedContext';

const DAY_LIMIT = 10;
const FEED_ID = 'premiumTimeline';

export const PremiumFeedBreadcrumb = [
  {
    title: 'Premium',
    slug: 'premium',
  },
  {
    title: 'Timeline',
    slug: 'timeline',
  },
];

const PremiumFeed = (props) => {
  const {
    premiumTimeline,
    updateTimelineItemProperty: onUpdateProp,
    saveTimelineFeed: handleSave,
    fetchFeeds: doFetchFeeds,
  } = props;

  useEffect(() => {
    doFetchFeeds();
  }, []);

  return (
    <Page
      fullWidth
      toolbarContent={
        <PageToolbar breadcrumb={PremiumFeedBreadcrumb}>
          <Grid container justify={'center'} alignItems={'center'}>
            <AdMenuControlBar />
          </Grid>
          <PageToolbarButtons>
            <Button
              variant={'contained'}
              onClick={() => handleSave(FEED_ID)}
              startIcon={<SaveIcon />}
            >Save feed</Button>
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <Grid item xs={12}>
          <ArticleTimelineFeed
            id={FEED_ID}
            feed={premiumTimeline}
            limit={DAY_LIMIT}
            includeTomorrow
          />
        </Grid>
      </PageContent>
      <ArticleFeedContext id={FEED_ID} onUpdateProp={onUpdateProp} />
    </Page>
  );
};

PremiumFeed.propTypes = {
  updateTimelineItemProperty: PropTypes.func.isRequired,
  saveTimelineFeed: PropTypes.func.isRequired,
  fetchFeeds: PropTypes.func.isRequired,
  premiumTimeline: PropTypes.object,
};

PremiumFeed.defaultProps = {
  premiumTimeline: {
    items: [],
  },
};

export default connect(
  ({ feed: { premiumTimeline } }) => ({ premiumTimeline }),
  { updateTimelineItemProperty, saveTimelineFeed, fetchFeeds },
)(PremiumFeed);
