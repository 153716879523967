import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Tab, Tabs } from '../ui/Tabs';
import DailyMotionUrl from './DailyMotionUrl';
import DailyMotionLibrary from './DailyMotionLibrary';
import DailyMotionUpload from "./DailyMotionUpload";

const styles = theme => ({
  root: {
    minHeight: 400,
    margin: theme.spacing(2, 0),
  },
  tabs: {
    margin: theme.spacing(0, -1),
    width: `calc(100% + ${theme.spacing(2)}px)`,
    maxWidth: 'unset',
    flexBasis: 'unset',
    padding: `${theme.spacing(0, 0, 1)} !important`,
  },
});

const TAB_URL = 'Url';
const TAB_LIBRARY = 'Library';
const TAB_UPLOAD = 'Upload';

const DailyMotionTab = ({ active, ...rest }) => {
  switch (active) {
    case TAB_LIBRARY:
      return <DailyMotionLibrary {...rest} />;
    case TAB_UPLOAD:
      return (<DailyMotionUpload {...rest} />);
    default:
      return <DailyMotionUrl {...rest} />;
  }
};

DailyMotionTab.propTypes = {
  active: PropTypes.string.isRequired,
};

const DailyMotionForm = ({ classes, config, onSelect }) => {
  const [activeTab, setActiveTab] = useState(TAB_URL);
  const tabs = [TAB_URL];
  if (config?.userId) {
    tabs.push(TAB_LIBRARY);
  }
  if (config?.apiKey && config?.apiSecret) {
    tabs.push(TAB_UPLOAD);
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {tabs.length > 1 && <Grid item xs={12} className={classes.tabs}>
          <Tabs
            value={activeTab}
            onChange={(e, val) => setActiveTab(val)}
          >
            {tabs.map(key => (
              <Tab key={key} value={key} label={key} />
            ))}
          </Tabs>
        </Grid>}
        <DailyMotionTab active={activeTab} onSelect={onSelect} />
      </Grid>
    </div>
  );
};

DailyMotionForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

export default withStyles(styles)(DailyMotionForm);

