import { ofType } from 'redux-observable';
import { switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { from, of } from 'rxjs';

import apiCatchError, {
  showSuccessNotification,
} from '../helper/notification';
import {
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_REJECTED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_REJECTED,
  PROFILE_PERMISSION_SUCCESSFUL, PROFILE_PERMISSION_REJECTED, UPDATE_PROFILE_RELOGIN,
} from 'constants/actionTypes';
import {
  MFA_FETCH_QR,
  MFA_FETCH_QR_FAILURE,
  MFA_FETCH_QR_SUCCESS,
  USER_SET_MFA_FAILURE, USER_SET_MFA_SUCCESS, MFA_VERIFY,
  MFA_VERIFY_FAILURE,
  MFA_VERIFY_SUCCESS,
} from 'constants/actionTypes/user';
import {LOGIN_SUCCESS} from "../../constants/actionTypes/login";

export const getUserProfile = action$ => action$.pipe(
  ofType(LOGIN_SUCCESS),
  switchMap(({ value }) =>
    ajax.getJSON(`/api/user/${value.uid}`).pipe(
      map(response => ({
        type: PROFILE_FETCH_SUCCESS,
        value: {
          name: response[0].name,
          email: response[0].mail,
          uid: response[0].uid,
          imageUrl: response[0]?.image || '',
        },
      })),
      apiCatchError(PROFILE_FETCH_REJECTED),
    )),
);

export const getUserPermissions = action$ => action$.pipe(
  ofType(LOGIN_SUCCESS),
  switchMap(({ value }) =>
    ajax.getJSON(`/api/user/permissions/${value.uid}`).pipe(
      map(response => ({
        type: PROFILE_PERMISSION_SUCCESSFUL,
        value: response,
      })),
      apiCatchError(PROFILE_PERMISSION_REJECTED),
    )),
);

export const getQRforMfa = action$ => action$.pipe(
  ofType(MFA_FETCH_QR),
  switchMap(() =>
    ajax.getJSON('/api/mfa').pipe(
      map(response => ({
        type: MFA_FETCH_QR_SUCCESS,
        value: response,
      })),
      apiCatchError(MFA_FETCH_QR_FAILURE),
    )),
);

export const updateUserWithMfa = (action$, state$) => action$.pipe(
  ofType(MFA_VERIFY_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([, { userCurrent: { user }, login: { secret, user: loginUser } }]) => {
    const payload = {
      field_mfa_data: [{
        value: JSON.stringify({ 'secret': secret }),
      }],

    };
    return ajax.patch(
      `/api/user/${user?.uid || loginUser?.uid }`,
      payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => from([
        { type: USER_SET_MFA_SUCCESS },
        { type: UPDATE_PROFILE_SUCCESS, value: response },
      ])),
      apiCatchError(USER_SET_MFA_FAILURE, 'Failed to update MFA, please try again.'),
    );
  }),
);

export const verifyMfaForUser = (action$, state$) => action$.pipe(
  ofType(MFA_VERIFY),
  withLatestFrom(state$),
  switchMap(([{ value: payload }, { login: { secret } }]) => {
    return (
      ajax.post('/api/verify-mfa', { ...payload, secret }, { 'Content-Type': 'application/json' }).pipe(
        map(response => ({
          type: MFA_VERIFY_SUCCESS,
          value: response.response,
        })),
        apiCatchError(MFA_VERIFY_FAILURE, 'Invalid authentication token entered.'),
      ));
  }),
);

export const displaySuccessNotificationAfterMFAUpdate = action$ => action$.pipe(
  ofType(USER_SET_MFA_SUCCESS),
  mergeMap(showSuccessNotification('Two factor authentication enabled.')),
);

export const displaySuccessNotificationAfterProfileUpdate = action$ => action$.pipe(
  ofType(UPDATE_PROFILE_SUCCESS),
  mergeMap(showSuccessNotification('Profile updated.')),
);

export const updateProfile = (action$, state$) => action$.pipe(
  ofType(UPDATE_PROFILE),
  withLatestFrom(state$),
  switchMap(([{ value }, { login: { user } }]) => {
    const { uid } = value;
    const payload = Object.entries(value)
      .filter(([, data]) => !!data)
      .reduce((acc, [key, data]) => {
        switch (key) {
          case 'imageUrl': {
            return {
              ...acc,
              field_user_image_url: [{ value: data }],
            };
          }
          case 'userName': {
            return {
              ...acc,
              name: [{ value: data }],
            };
          }
          case 'mail': {
            return {
              ...acc,
              mail: [{ value: data }],
            };
          }
          case 'passwordNew': {
            const props = acc.pass ? acc.pass[0] : {};
            return {
              ...acc,
              pass: [{ ...props, value: data }],
            };
          }
          case 'passwordCurrent': {
            const props = acc.pass ? acc.pass[0] : {};
            return {
              ...acc,
              pass: [{ ...props, existing: data }],
            };
          }
          default:
            console.warn(`No handler is found for this ${key}`);
            return acc;
        }
      }, {});

    return ajax.patch(
      `/api/user/${uid}`, payload, { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => {
        const successAction = { type: UPDATE_PROFILE_SUCCESS, value: response };
        if (payload.pass[0].value) {
          return from([
            successAction,
            {
              type: UPDATE_PROFILE_RELOGIN,
              value: {
                username: user.name,
                password: payload.pass[0].value,
              },
            },
          ]);
        }
        return of(successAction);
      }),
      apiCatchError(UPDATE_PROFILE_REJECTED),
    );
  }),
);
