import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import ScheduleIcon from '@material-ui/icons/Schedule';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { saveStory, deleteStory, updateStoryField, requestStoryPreview } from 'actions/story';

import { PageToolbarButtons } from 'components/common/page/PageToolbar';
import Button from 'components/ui/buttons/Button';
import SchedulerControl from 'components/ui/components/SchedulerControl';
import IconButton from 'components/ui/buttons/IconButton';
import { getIsPublished } from 'components/helper/scheduler';

import { FIELD_PUBLISH, FIELD_SCHEDULED, FIELD_TITLE, FIELD_UNPUBLISH } from 'constants/story/storyFields';
import { PRODUCTION } from 'constants/env';

import { slugify } from 'utils/urlHelper';

const styles = theme => ({
  drawer: {
    maxWidth: 450,
    minWidth: 450,
    padding: theme.spacing(3),
    '&>.MuiGrid-container': {
      marginBottom: theme.spacing(4),
    },
  },
  moreAction: {
    marginRight: theme.spacing(-2),
  },
  scheduled: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiButton-label': {
      width: 190,
      whiteSpace: 'normal',
      lineHeight: '1.1em',
    },
  },
});

const StoryControlButtons = (props) => {
  const {
    classes,
    title,
    scheduled,
    publish,
    unpublish,
    storyFromServer: raw,
    saveStory: doSave,
    deleteStory: doDelete,
    updateStoryField: updateField,
    requestStoryPreview: requestPreview,
    roles,
    selectedPublication,
    mode,
  } = props;
  const { isBusy } = useSelector((state) => state.story);
  const [isPublished, setIsPublished] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [publishDrawer, setPublishDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setIsPublished(getIsPublished(publish, unpublish));
  }, [publish, unpublish]);

  useEffect(() => {
    const nowTimestamp = moment().unix();
    setIsScheduled(scheduled > nowTimestamp);
  }, [scheduled]);

  const storyId = raw.id && raw.id.length > 0 ? raw.id[0].value : null;

  const openLive = () => {
    const alias = raw?.path?.[0]?.alias || `/visual-story/${slugify(title)}-st${storyId}.html`;
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url : selectedPublication.environment.web;
    window.open(`${frontEndUrl.replace(/\/$/, '')}${alias}`, '_blank');
    return false;
  };

  const buttons = [];

  if (!isPublished) {
    buttons.push({
      title: 'Save',
      onClick: () => doSave(isPublished),
      startIcon: <SaveIcon />,
      disabled: isBusy,
    });
  }

  if (!isPublished || isScheduled) {
    buttons.push({
      title: isScheduled ? `Scheduled to publish ${moment.unix(scheduled).format('MMM D, HH:mm')}` : 'Schedule',
      onClick: () => setPublishDrawer(true),
      startIcon: <ScheduleIcon />,
      variant: 'outlined',
      color: isScheduled ? 'secondary' : 'primary',
      className: isScheduled ? classes.scheduled : '',
      disabled: isBusy,
    });
  }

  buttons.push(
    {
      title: isPublished ? 'Update' : 'Publish',
      onClick: () => doSave(true),
      startIcon: <PublishIcon />,
      variant: 'contained',
      disabled: isBusy,
    },
  );

  return (
    <PageToolbarButtons>
      {!isPublished && <Button
        onClick={requestPreview}
        startIcon={<OpenInBrowserIcon />}
      >Preview</Button>}
      {isPublished && <Button
        onClick={() => openLive()}
        startIcon={<VisibilityIcon />}
      >Live Story</Button>}
      {buttons.map(button => (<Button key={button.title} {...button}>{button.title}</Button>))}
      <IconButton
        className={classes.moreAction}
        onClick={(event) => {
          if (!raw || !roles.some(role => role === 'cms_admin')) {
            return;
          }
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVert style={{ fill: '#DADADA' }} />
      </IconButton>
      <Menu
        id={'long-menu'}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {isPublished && <MenuItem
          onClick={() => {
            doSave(!isPublished);
            setAnchorEl(null);
          }}
        >Unpublish</MenuItem>}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            if (window.confirm('Please confirm you want delete this story?')) {
              doDelete();
            }
          }}
        >Delete</MenuItem>
      </Menu>
      <Drawer
        anchor={'right'}
        open={publishDrawer}
        onClose={() => setPublishDrawer(false)}
      >
        <div className={classes.drawer}>
          <Grid container>
            <SchedulerControl
              scheduleDateTime={scheduled}
              onSubmit={(timestamp) => {
                updateField(FIELD_SCHEDULED, timestamp);
                doSave(true);
                setPublishDrawer(false);
              }}
            />
          </Grid>
        </div>
      </Drawer>
    </PageToolbarButtons>
  );
};

StoryControlButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  saveStory: PropTypes.func.isRequired,
  deleteStory: PropTypes.func.isRequired,
  updateStoryField: PropTypes.func.isRequired,
  requestStoryPreview: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  title: PropTypes.string,
  scheduled: PropTypes.number,
  publish: PropTypes.number,
  unpublish: PropTypes.number,
  storyFromServer: PropTypes.object,
  roles: PropTypes.array,
};

StoryControlButtons.defaultProps = {
  title: '',
  scheduled: 0,
  publish: 0,
  unpublish: 0,
  storyFromServer: null,
  roles: [],
};

export default withStyles(styles)(connect(
  ({
    story: {
      [FIELD_TITLE]: title,
      [FIELD_SCHEDULED]: scheduled,
      [FIELD_PUBLISH]: publish,
      [FIELD_UNPUBLISH]: unpublish,
      storyFromServer,
    },
    login: { user: { roles } },
    frame: { selectedPublication },
    dashboard: { mode },
  }) => (
    {
      title,
      scheduled,
      publish,
      unpublish,
      storyFromServer,
      roles,
      selectedPublication,
      mode,
    }
  ),
  { saveStory, deleteStory, updateStoryField, requestStoryPreview },
)(StoryControlButtons));
