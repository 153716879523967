import React, { useState } from "react";
import { connect } from "react-redux";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import Fab from "@material-ui/core/Fab";
import { push } from "connected-react-router";
import axios from "axios";

export const MemoQ = connect(() => ({}), { push })((props) => {
  const { onClick, classes, imported, id, push: routeChange } = props;
  const [loading, setLoading] = useState(false);
  const handleMemoQ = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/memoq/queue-translation/${id}/independentespanol.com`
      );

      console.log("data", data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        margin: 8,
        position: "relative",
      }}
    >
      <Fab
        disabled={loading}
        onClick={() => {
          if (imported) {
            routeChange(`/article/edit/${id}`);
          } else {
            handleMemoQ();
            setLoading(true);
          }
        }}
      >
        <SubtitlesIcon />
      </Fab>
      {/*    {loading && <CircularProgress size={68} />} */}
    </div>
  );
});
