import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
    '& twitter-widget': {
      width: '100%!important',
    },
  },
});


const TwitterEmbed = ({ classes, data, id }) => {
  const rootElement = useRef();
  useEffect(() => {
    let twitterId;
    if (data.url && /^https/.test(data.url)) {
      const url = new URL(data.url);
      const found = url.pathname.match(/(\d)+$/);
      if (found) {
        twitterId = found[0];
      }
    } else if (data.id) {
      twitterId = data.id;
    }

    if (twitterId) {
      const options = {};
      if (!data.isConversation) {
        options.conversation = 'none';
      }
      options.width = rootElement.current.clientWidth;
      // TODO: extract this to epic, follow facebook
      window.twttr && window.twttr.widgets.createTweet(
        twitterId,
        document.getElementById(id),
        options,
      );
    }
  }, [data.url]);

  return (<Grid ref={rootElement} container className={classes.root} justify={'center'}><div id={id} /></Grid>);
};

TwitterEmbed.defaultProps = {
  data: null,
  bodyMarkupFocus: null,
};

TwitterEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
};

export default withStyles(styles)(TwitterEmbed);

