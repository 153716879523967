import { PURGE } from 'redux-persist';
import {
  DAILYMOTION_UPLOAD,
  DAILYMOTION_UPLOAD_SUCCESS,
  DAILYMOTION_UPLOAD_REJECTED,
} from 'constants/actionTypes/integration/dailymotion';

const defaultState = {
  isUploading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DAILYMOTION_UPLOAD:
      return {
        ...state,
        isUploading: true,
      };

    case DAILYMOTION_UPLOAD_SUCCESS:
    case DAILYMOTION_UPLOAD_REJECTED:
      return {
        ...state,
        isUploading: false,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
