import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import VocabTable from '../table/vocab/VocabTable';

import { VOCAB_SINGULAR, VOCAB_PLURAL } from '../../constants/vocab';
import VocabTag from '../ui/Tag';
import RecommendedTagRoot, { VocabSelectorRoot } from '../ui/RecommendedTagRoot';

const tableHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'path', label: 'Path' },
];
/* eslint-disable react/prop-types, react/display-name */
const cellFormat = [
  { id: 'name', display: ({ name, remoteId }) => <span title={remoteId ? `Author id: ${remoteId}` : ''}>{name}</span> },
  { id: 'path', display: ({ path, remoteId }) => <span title={remoteId ? `Author id: ${remoteId}` : ''}>{path}</span> },
];
/* eslint-enable react/prop-types, react/display-name */

const AddSectionDialogSelector = (props) => {
  const {
    classes, selectVocab, activeVocab, vocabs, restrictVocab, selectedTerms, popular, related, ...rest
  } = props;
  const [currentVocab, setCurrentVocab] = useState(activeVocab);
  const [suggestiveTerms, setSuggestiveTerms] = useState([]);

  useEffect(() => {
    const popularTerms = popular[currentVocab] || [];
    const relatedTerms = related[currentVocab] || [];
    const terms = [...popularTerms, ...relatedTerms]
      .filter(x => !!x)
      .reduce((acc, term) => {
        if (acc.some(({ id }) => id === term.id)) {
          return acc;
        }
        acc.push(term);
        return acc;
      }, []);
    setSuggestiveTerms(terms);
  }, [currentVocab, popular, related]);
  const suggestItems = suggestiveTerms
    .filter(({ id: relatedId }) => !selectedTerms.find(({ id }) => relatedId === id));

  const title = currentVocab ? `Choose ${VOCAB_SINGULAR[currentVocab]}` : 'Select type';

  return (
    <Dialog title={title} {...rest}>
      <div>
        {!activeVocab && <VocabSelectorRoot>
          <Select
            value={currentVocab}
            displayEmpty
            onChange={e => setCurrentVocab(e.target.value)}
          >
            <MenuItem
              value={''}
              disabled
            >Select a type</MenuItem>
            {vocabs
              .filter(value => (restrictVocab.length > 0 ? restrictVocab.includes(value) : true))
              .map(value => (
                <MenuItem
                  key={value}
                  value={value}
                >{VOCAB_PLURAL[value]}</MenuItem>
              ))
            }
          </Select>
        </VocabSelectorRoot>}
        {currentVocab && suggestItems?.length > 0 && <RecommendedTagRoot>
          <Typography component={'h3'}>Recommended {VOCAB_PLURAL[currentVocab]}:</Typography>
          <Grid container>
            {suggestItems
              .map(item => (<VocabTag
                key={`related${item.id}`}
                onClick={() => selectVocab(item)}
                label={item.name}
              />))
            }
          </Grid>
        </RecommendedTagRoot>}
        {currentVocab && <VocabTable
          headers={tableHeaders}
          activeVocab={currentVocab}
          data={cellFormat}
          onClickRow={(item) => {
            selectVocab(item);
          }}
          showActions
        />}
      </div>
    </Dialog>
  );
};

AddSectionDialogSelector.defaultProps = {
  activeVocab: '',
  selectedTerms: [],
  restrictVocab: [],
  vocabs: [],
};

AddSectionDialogSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  selectVocab: PropTypes.func.isRequired,
  popular: PropTypes.object.isRequired,
  related: PropTypes.object.isRequired,
  vocabs: PropTypes.array,
  activeVocab: PropTypes.string,
  restrictVocab: PropTypes.array,
  selectedTerms: PropTypes.array,
};

export default connect(
  ({
    frame: { selectedPublication: { vocabs } },
    vocab: { popular, related },
  }) => ({ vocabs, popular, related })
)(AddSectionDialogSelector);
