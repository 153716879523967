import React from 'react';
import PropTypes from 'prop-types';

import ImageSelector from '../../ui/media/ImageSelector';

const ImageField = (props) => {
  const {
    onRemove,
    onSelect,
    overrideValue,
  } = props;
  const image = overrideValue;
  return (
    <ImageSelector
      label={'Add override image'}
      selectImage={onSelect}
      onRemove={onRemove}
      image={image !== null ? image.data : image}
      showEditDialog
    />
  );
};

ImageField.propTypes = {
  overrideValue: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ImageField.defaultProps = {
  overrideValue: null,
};

export default ImageField;
