import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from '@material-ui/core';

import { componentSetProp } from '../../../../actions/layout';
import { PLAYLIST_ID } from '../../constants';
import Select from '../../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import { fetchTVPlaylist } from '../../../../actions/media';

const TVPlaylistOptions = (props) => {
  const {
    component,
    publicationConfig,
    tvOptions,
    componentSetProp: setProp,
    fetchTVPlaylist: onFetchOption,
  } = props;

  const endpointUrl = publicationConfig?.videoComponentConfig?.videoPlaylistEndpoint || null;
  const settings = publicationConfig.videoComponentConfig?.settings;

  useEffect(() => {
    const options = Array.isArray(tvOptions) ? tvOptions : Object.entries(tvOptions);
    if (options.length === 0) {
      onFetchOption(endpointUrl);
    } else if (options.length > 0 && component[PLAYLIST_ID] === '') {
      setProp(component.id, PLAYLIST_ID, options[0][0]);
    }
  }, [tvOptions]);

  if (!endpointUrl) {
    return null;
  }
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Playlists</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          displayEmpty
          label={'Select playlist'}
          labelId={`tvPlaylist${component.id}`}
          value={component[PLAYLIST_ID]}
          emptyValue={'Select playlist'}
          onChange={e => setProp(component.id, PLAYLIST_ID, e.target.value)}
          items={tvOptions}
        />
        {settings && Object.entries(settings).map(([key, { label, options }]) => (
          <Select
            key={key}
            displayEmpty
            label={label}
            labelId={`tvPlaylistSetting${key}`}
            value={component[key]}
            emptyValue={'Please select'}
            onChange={e => setProp(component.id, key, e.target.value)}
            items={options?.map(id => ({ name: capitalize(id), id }))}
          />
        ))}
      </ContextPanelDetails>
    </ContextPanel>
  );
};

TVPlaylistOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  fetchTVPlaylist: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object,
  tvOptions: PropTypes.array,
};

TVPlaylistOptions.defaultProps = {
  publicationConfig: {},
  tvOptions: [],
};

export default connect(
  ({
    frame: { selectedPublication: { publicationConfig } },
    layout: { tvOptions },
  }) => ({ publicationConfig, tvOptions }),
  { componentSetProp, fetchTVPlaylist },
)(TVPlaylistOptions);
