import { ofType } from 'redux-observable';
import {
  combineLatest, delay,
  filter,
  flatMap,
  map,
  mapTo,
  merge,
  mergeMap, switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { ajax } from 'rxjs/ajax';
import { of, EMPTY } from 'rxjs';
import { REHYDRATE } from 'redux-persist/es/constants';

import {
  COMPONENT_SIDEBAR_LOADED,
  SIDEBAR_SAVE,
  SECTION_SIDEBAR_SELECTED,
  SELECT_SIDEBAR_COMPONENT,
  SIDEBAR_COMPONENTS,
  SIDEBAR_SAVE_REJECTED,
  REMOVE_SIDEBAR_SECTION_COMPLETED,
  SIDEBAR_FETCH_REJECTED,
  SIDEBAR_FETCH_LOADED,
  LAYOUT_SIDEBAR_SECTIONS_LOADED,
  CURRENT_SIDEBAR_RESET,
  REMOVE_SIDEBAR_SECTION,
  LAYOUT_SIDEBAR_PAGE_ONLOAD,
  ADD_SIDEBAR_COMPONENT, SIDEBAR_SAVE_SUCCESS,
} from '../../constants/actionTypes';
import { PAGE_ENTER_SIDEBAR } from '../../constants/actionTypes/route';
import { ERROR_SAVE_MESSAGE_RESET } from '../../constants/actionTypes/notification';
import { AD_SOURCE, MARKUP } from '../../components/layout/constants';

import apiCatchError, {
  showSuccessNotification,
} from '../helper/notification';
import generateKey from '../../utils/generateKey';
import { Ad } from '../../constants/components';
import { componentPropOnChange } from '../../actions/sidebar';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const hideErrorMessage = action$ => action$.pipe(
  ofType(SIDEBAR_FETCH_REJECTED),
  delay(3000),
  mapTo({
    type: ERROR_SAVE_MESSAGE_RESET,
  }),
);

export const onNewSidebarEnter = (action$) => {
  const rehydrate$ = action$.pipe(
    ofType(REHYDRATE),
  );
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(({ payload: { location: { pathname } } }) => /\/sidebar\/section\/new$/.test(pathname)),
    // wait for the state properly rehydrate
    combineLatest(rehydrate$, locationChange$ => locationChange$),
    mapTo({
      type: CURRENT_SIDEBAR_RESET,
    }),
  );
};

export const onRemoveSidebarLayout = action$ => action$.pipe(
  ofType(REMOVE_SIDEBAR_SECTION),
  mergeMap(({ value }) => ajax.delete(`/api/layout/sidebar_layout/${value}`, { 'Content-Type': 'application/json' }).pipe(
    map(() => ({
      type: REMOVE_SIDEBAR_SECTION_COMPLETED,
    })),
    apiCatchError(),
  )),
);

export const onFetchSidebar = action$ => action$.pipe(
  ofType(LOCATION_CHANGE),
  filter(({ payload: { location: { pathname } } }) => /\/sidebar\/([0-9]+)$/.test(pathname)),
  switchMap((({ payload: { location: { pathname } } }) => {
    const [id] = pathname.match(/[0-9]+$/g);
    return ajax.getJSON(`/api/layout/${id}`).pipe(
      flatMap(response => of({
        type: SIDEBAR_FETCH_LOADED,
        value: response,
      })),
      apiCatchError(),
    );
  }
  )),
);

export const assignSidebarComponent = (action$, state$) => action$.pipe(
  ofType(SIDEBAR_FETCH_LOADED),
  withLatestFrom(state$),
  // eslint-disable-next-line camelcase
  flatMap(([, { sidebar: { currentSidebarLayout: { field_layout_settings } } }]) => {
    const components = JSON.parse(field_layout_settings[0].value);
    return of({
      type: SIDEBAR_COMPONENTS,
      value: components.map(comp => ({
        id: generateKey(),
        ...comp,
      })),
    });
  }),
);

export const onPatchSidebar = (action$, state$) => action$.pipe(
  ofType(SIDEBAR_SAVE),
  withLatestFrom(state$),
  filter(([, { sidebar: { currentSidebarLayout } }]) => currentSidebarLayout.id),
  switchMap(([, state]) => {
    const { currentSidebarLayout, selectedComponents } = state.sidebar;
    const { value } = currentSidebarLayout.id[0];
    return ajax.patch(`/api/layout/sidebar_layout/${value}`, {
      bundle: [
        {
          target_id: 'sidebar_layout',
          target_type: 'layout_type',
        },
      ],
      field_layout_settings: [{
        value: JSON.stringify(selectedComponents),
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: SIDEBAR_SAVE_SUCCESS }),
      apiCatchError(SIDEBAR_SAVE_REJECTED),
    );
  }),
);

export const onSaveNewLayout = (action$, state$) => action$.pipe(
  ofType(SIDEBAR_SAVE),
  withLatestFrom(state$),
  filter(([, { sidebar: { currentSidebarLayout } }]) => !currentSidebarLayout.id),
  switchMap(([, state]) => {
    const { selectedComponents, selectedSection } = state.sidebar;
    const { selectedPublication } = state.frame;
    return ajax.post('/api/layout/sidebar_layout/', {
      bundle: [
        {
          target_id: 'sidebar_layout',
          target_type: 'layout_type',
        },
      ],
      field_section: [
        {
          target_id: selectedSection.id,
        },
      ],
      field_publication: [
        {
          target_id: selectedPublication.id,
        },
      ],
      field_layout_settings: [{
        value: JSON.stringify(selectedComponents),
      }],
      name: [{
        value: `${selectedPublication.name} / ${selectedSection.name} / Sidebar`,
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: SIDEBAR_SAVE_SUCCESS }),
      apiCatchError(SIDEBAR_SAVE_REJECTED),
    );
  }),
);

export const checkSidebarComponent = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_SIDEBAR),
  withLatestFrom(state$),
  mergeMap(([, { frame: { selectedPublication: { domain } } }]) => ajax.get(`/api/components/sidebar/${domain}`).pipe(
    map(({ response }) => ({
      type: COMPONENT_SIDEBAR_LOADED,
      value: Object.keys(response)
        .map((key) => {
          const base = {
            type: key,
            name: response[key],
            id: key,
          };
          if (key.toLowerCase() === MARKUP) {
            return {
              ...base,
              [MARKUP]: '',
            };
          }
          return base;
        }),
    })),
    apiCatchError(),
  )),
);

export const assignComponentFromBackend = (action$, state$) => {
  const compSidebarLoaded$ = action$.pipe(
    ofType(COMPONENT_SIDEBAR_LOADED),
  );
  return action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(({ payload: { location: { pathname } } }) => /\/sidebar\/section\/new$/.test(pathname)),
    combineLatest(compSidebarLoaded$),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      try {
        const layout = JSON.parse(
          state.sidebar.currentSidebarLayout.field_layout_settings[0].value,
        );
        if (Array.isArray(layout)) {
          return of({
            type: SIDEBAR_COMPONENTS,
            value: layout.map(comp => ({
              id: generateKey(),
              ...comp,
            })),
          });
        }
        return EMPTY;
      } catch (e) {
        return EMPTY;
      }
    }),
  );
};

export const selectSidebarComponent = (action$, state$) => action$.pipe(
  ofType(SELECT_SIDEBAR_COMPONENT),
  map(({ value }) => ({
    type: SIDEBAR_COMPONENTS,
    value: [...state$.value.sidebar.selectedComponents, Object.assign({}, value, {
      id: value.type + Math.random(),
    })],
  }),
  ),
);

export const onSelectSectionRedirect = action$ => action$.pipe(
  ofType(SECTION_SIDEBAR_SELECTED),
  mapTo(push('/sidebar/section/new')),
);

export const onFetchSidebarLanding = (action$, state$) => {
  const selectPub$ = action$.pipe(
    ofType(PUBLICATION_SELECTED),
  );
  const layoutSectionDeletion$ = action$.pipe(
    ofType(REMOVE_SIDEBAR_SECTION_COMPLETED),
  );
  return action$.pipe(
    ofType(LAYOUT_SIDEBAR_PAGE_ONLOAD),
    mergeMap(action => of(action).pipe(merge(layoutSectionDeletion$, selectPub$))),
    withLatestFrom(state$, (a, b) => b),
    mergeMap(({ frame: { selectedPublication: { domain } } }) =>
      ajax.getJSON(`/api/layout/sidebar/${domain}`).pipe(
        flatMap(response => of({
          type: LAYOUT_SIDEBAR_SECTIONS_LOADED,
          value: response,
        })),
        apiCatchError(),
      ),
    ),
  );
};

export const assignDefaultAdSource = (action$, state$) => action$.pipe(
  ofType(ADD_SIDEBAR_COMPONENT),
  filter(({ value }) => value?.component?.type === Ad),
  withLatestFrom(state$),
  filter(([, { frame: { selectedPublication: { publicationConfig } } }]) =>
    Array.isArray(publicationConfig.adSources) && publicationConfig.adSources.length > 0,
  ),
  map(([{ value: { index } }, {
    frame: { selectedPublication: { publicationConfig: { adSources } } },
    sidebar: { selectedComponents },
  }]) => componentPropOnChange([
    selectedComponents[index].id, AD_SOURCE, adSources[0],
  ])),
);

export const sidebarSavedNotification = action$ => action$.pipe(
  ofType(SIDEBAR_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Sidebar is successfully saved.')),
);

export const redirectToSidebarListPage = action$ => action$.pipe(
  ofType(SIDEBAR_SAVE_SUCCESS),
  mapTo(push('/sidebar')),
);
