import IframeElement from './components/IframeElement';

export const ELEMENT_IFRAME = 'iframe';
export const PROP_SRC = 'src';
export const PROP_WIDTH = 'width';
export const PROP_HEIGHT = 'height';

export const DEFAULTS_IFRAME = {
  iframe: {
    component: IframeElement,
    type: ELEMENT_IFRAME,
    rootProps: {
      className: 'slate-iframe',
    },
  },
};
