import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextFieldBase from '@material-ui/core/TextField';

import RequiredIndicator from './RequiredIndicator';
import { reformatQuote } from '../helper/formatting';
import { createTestId } from '../../utils/helper'

const styles = theme => ({
  input: {
    background: theme.palette.background.contrast,
    borderRadius: theme.border.radius,
  },
  changes: {
    '& .MuiFormLabel-root:not(.Mui-focused)': {
      color: theme.palette.secondary.main,
    },
  },
});

const TextField = (props) => {
  const {
    classes, className, autoComplete, hasChanges, label, required, fullWidth, margin, variant,
    ignoreSmartQuote, ...rest
  } = props;

  const testId = createTestId(props)
  const inputClasses = [classes.input];
  if (className) {
    inputClasses.push(className);
  }
  if (hasChanges) {
    inputClasses.push(classes.changes);
  }
  return (
    <TextFieldBase
      className={inputClasses.join(' ')}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      autoComplete={autoComplete}
      data-testid={testId}
      onKeyUp={(e) => {
        e.persist();
        try {
          if (!ignoreSmartQuote) {
            const caretStart = e.target.selectionStart;
            const caretEnd = e.target.selectionEnd;
            e.target.value = reformatQuote(e.target.value);
            e.target.setSelectionRange(caretStart, caretEnd);
            if (rest.onKeyUp) {
              rest.onKeyUp(e);
            }
          }
        } catch (ex) {
          // console.error(ex);
        }
      }}
      label={required ? <>{label}<RequiredIndicator /></> : label}
      {...rest}
    />
  );
};

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  hasChanges: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  ignoreSmartQuote: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.string,
};

TextField.defaultProps = {
  margin: 'normal',
  variant: 'outlined',
  label: '',
  className: null,
  autoComplete: 'off',
  hasChanges: false,
  required: false,
  fullWidth: true,
  ignoreSmartQuote: false,
};

export default withStyles(styles)(TextField);
