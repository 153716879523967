import { PURGE } from 'redux-persist';
import { arrayMove } from 'react-sortable-hoc';
import { mergeDeepRight } from 'rambdax';
import {
  UPDATE_LOCAL_CAPTION_EDITION_COMPONENT,
  ARTICLE_EDITION_NOTE_SAVE,
  ARTICLE_EDITION_NOTE_DELETE,

} from '../../constants/actionTypes';
import generateKey from '../../utils/generateKey';
import {
  EDITION_ARTICLE_ADD_VOCAB,
  ADD_MORE_EDITION_ARTICLE_COMPONENT,
  DROP_ARTICLE_EDITION_COMPONENT,
  EDITION_DETAIL_NEW,
  EDITION_DETAIL_FETCH_SUCCESS,
  EDITION_LIST_DRAFT_FETCH_SUCCESS,
  EDITION_LIST_FETCH_SUCCESS,
  EDITION_PAGE_RE_ORDER,
  EDITION_PREVIEW_COMPONENT_CLICK,
  EDITION_PREVIEW_COMPONENT_HOVER,
  MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY,
  REMOVE_ARTICLE_EDITION_HERO_IMAGE,
  REMOVE_ARTICLE_EDITION_VOCAB,
  REMOVE_EDITION_ARTICLE_COMPONENT,
  RESET_ARTICLE_EDITION_CREATION,
  SAVE_EDITION_ARTICLE_COMPONENT,
  SET_ARTICLE_EDITION_COMPONENT_PROPERTY,
  SET_ARTICLE_EDITION_PROPERTY,
  SET_FOCUS_EDITION_ARTICLE_COMPONENT,
  SET_IMAGE_HERO_ARTICLE_EDITION,
  SORT_EDITION_ARTICLE_COMPONENT,
  EDITION_DETAIL_UPDATE,
  ADD_RELATED_ARTICLE_EDITION,
  REMOVE_RELATED_ARTICLE_EDITION,
  SORT_RELATED_ARTICLE_EDITION,
  CHANGE_RELATED_ARTICLE_PROPERTY,
  EDITION_SECTION_BODY_DISPOSE,
  EDITION_ARTICLE_ENTER_PAGE,
  EDITION_BODY_SET_COMPONENT_PROPERTY,
  ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY,
  EDITION_ARTICLE_REPLACE_VOCAB,
  EDITION_DETAIL_SAVE_SUCCESS,
  EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS, EDITION_EXPORT_FTP_LIST_SUCCESS, EDITION_FTP_LOG_SUCCESS, EDITION_FTP_LOG_DISPOSE,
} from '../../constants/actionTypes/edition';
import { PAGE_ENTER_EDITION_ARTICLE } from '../../constants/actionTypes/route';
import { FIELD_AUTHOR_INFO } from '../../constants/article/articleFields';
import { FIELD_TEMPLATE } from '../../constants/edition/editionFields';
import { injectMarkupAfterEmbedComponent, injectComponent, initComp } from '../helper/editor';
import { FOCUS_POSITION_END, FOCUS_POSITION_START } from '../../constants/article/article';
import { FIELD_LOCAL_CAPTION, FIELD_LOCAL_COPYRIGHT } from '../../constants/common/commonFields';

// @todo move edition article fields into own object
export const defaultState = {
  paging: {
    total: 0,
    limit: 10,
    offset: 0,
  },
  pagingDraft: {
    daily: {
      total: 0,
      limit: 10,
      offset: 0,
    },
    special: {
      total: 0,
      limit: 10,
      offset: 0,
    },
  },
  exportSettingFTPList: [],
  exportSettingTimeList: [],
  editionList: [],
  editionDraftList: {},
  editionDetail: null,
  awayScore: '',
  awayTeam: '',
  awayScorers: '',
  homeScore: '',
  homeTeam: '',
  homeScorers: '',
  body: [initComp],
  relatedArticle: [],
  bodyMarkupFocus: null,
  articleEditionFromServer: null,
  pugpig_templates: [],
  pugpig_section: [],
  content_source: [],
  authors: [],
  headline: '',
  subHeadline: '',
  slug: '',
  notes: [],
  heroImage: {
    id: generateKey(),
    data: {},
  },
  starRating: 0,
  [FIELD_AUTHOR_INFO]: '',
  [FIELD_TEMPLATE]: '',
  pageType: null,
  componentHover: -1,
  componentClick: -1,
  workflow: [],
  logs: [],
  logsEditions: {},
  logsExports: {},
  logsTotal: 0,
};

const newEdition = { id: null, pages: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case EDITION_FTP_LOG_DISPOSE: {
      return {
        ...state,
        logs: defaultState.logs,
        logsTotal: defaultState.logsTotal,
      };
    }

    case EDITION_FTP_LOG_SUCCESS: {
      const { total, items, editions, exports } = action.value;
      return {
        ...state,
        logs: items,
        logsTotal: total,
        logsEditions: editions,
        logsExports: exports,
      };
    }

    case EDITION_EXPORT_FETCH_SCHEDULED_SUCCESS: {
      return {
        ...state,
        exportSettingTimeList: action.value,
      };
    }

    case EDITION_EXPORT_FTP_LIST_SUCCESS: {
      return {
        ...state,
        exportSettingFTPList: action.value,
      };
    }

    case ARTICLE_EDITION_NOTE_DELETE: {
      return {
        ...state,
        notes: state.notes.filter(({ id }) => id !== action.value),
      };
    }

    case ARTICLE_EDITION_NOTE_SAVE: {
      if (action.value.id) {
        return {
          ...state,
          notes: state.notes.map((note) => {
            if (note.id === action.value.id) {
              return action.value;
            }
            return note;
          }),
        };
      }
      return {
        ...state,
        notes: [...state.notes, {
          ...action.value,
          id: generateKey(),
        }],
      };
    }

    case CHANGE_RELATED_ARTICLE_PROPERTY: {
      const [index, key, text] = action.value;
      return {
        ...state,
        relatedArticle: state.relatedArticle.map((article, i) => {
          if (i === index) {
            return {
              ...article,
              [key]: text,
            };
          }
          return article;
        }),
      };
    }
    case ADD_RELATED_ARTICLE_EDITION: {
      return {
        ...state,
        relatedArticle: [...state.relatedArticle, action.value],
      };
    }

    case REMOVE_RELATED_ARTICLE_EDITION: {
      return {
        ...state,
        relatedArticle: state.relatedArticle.filter((x, index) => index !== action.value),
      };
    }

    case SORT_RELATED_ARTICLE_EDITION: {
      const [prevIndex, nextIndex] = action.value;
      return {
        ...state,
        relatedArticle: arrayMove(state.relatedArticle, prevIndex, nextIndex),
      };
    }

    case ON_CHANGE_EDITION_HERO_IMAGE_PROPERTY: {
      const [key, data] = action.value;
      return {
        ...state,
        heroImage: {
          ...state.heroImage,
          data: {
            ...state.heroImage.data,
            [key]: data,
          },
        },
      };
    }

    case SET_IMAGE_HERO_ARTICLE_EDITION: {
      return {
        ...state,
        heroImage: {
          ...state.heroImage,
          data: {
            ...action.value,
            [FIELD_LOCAL_CAPTION]: action.value?.caption
              ? `<p>${action.value.caption}</p>` : '',
            [FIELD_LOCAL_COPYRIGHT]: action.value?.copyright || '',
          },
        },
      };
    }

    case REMOVE_ARTICLE_EDITION_HERO_IMAGE: {
      return {
        ...state,
        heroImage: defaultState.heroImage,
      };
    }

    case REMOVE_ARTICLE_EDITION_VOCAB: {
      const [vocab, data] = action.value;
      return {
        ...state,
        [vocab]: state[vocab].filter(({ id }) => id !== data.id),
      };
    }

    case EDITION_ARTICLE_ADD_VOCAB: {
      const [vocab, data] = action.value;
      return {
        ...state,
        [vocab]: [...state[vocab], data],
      };
    }

    case EDITION_ARTICLE_REPLACE_VOCAB: {
      const [vocab, data] = action.value;
      return {
        ...state,
        [vocab]: [data],
      };
    }

    case REMOVE_EDITION_ARTICLE_COMPONENT: {
      const key = 'body';
      if (state[key].length === 1) {
        return { ...state, [key]: defaultState[key] };
      }
      return { ...state,
        [key]: state[key].filter((item, i) => i !== action.value),
        bodyMarkupFocus: action.value - 1,
      };
    }

    case SET_ARTICLE_EDITION_PROPERTY: {
      const [key, value] = action.value;
      return {
        ...state,
        [key]: value,
      };
    }

    case SORT_EDITION_ARTICLE_COMPONENT: {
      const key = 'body';
      const focus = 'bodyMarkupFocus';

      return {
        ...state,
        [key]: arrayMove(state[key], action.value[0], action.value[1]),
        [focus]: action.value[1],
      };
    }

    case DROP_ARTICLE_EDITION_COMPONENT: {
      const [position, type] = action.value;
      const key = 'body';
      const focus = 'bodyMarkupFocus';

      if (position === state[key].length) {
        return {
          ...state,
          [key]: [
            ...state[key].slice(0, position),
            ...injectMarkupAfterEmbedComponent(type, initComp),
          ],
          [focus]: position,
        };
      }
      return {
        ...state,
        [key]: [
          ...state[key].slice(0, position),
          ...injectComponent(type, initComp),
          ...state[key].slice(position),
        ],
        [focus]: position,
      };
    }
    case SET_FOCUS_EDITION_ARTICLE_COMPONENT: {
      let direction;
      if (state.bodyMarkupFocus > action.value) {
        direction = FOCUS_POSITION_END;
      } else if (state.bodyMarkupFocus < action.value) {
        direction = FOCUS_POSITION_START;
      }
      if (direction) {
        return {
          ...state,
          bodyMarkupFocusPosition: direction,
          bodyMarkupFocus: action.value,
        };
      }
      return {
        ...state,
        bodyMarkupFocus: action.value,
      };
    }

    case ADD_MORE_EDITION_ARTICLE_COMPONENT: {
      let position;
      let markup;
      let component = {
        ...initComp,
        id: generateKey(),
      };
      if (Array.isArray(action.value)) {
        position = action.value[0];
        markup = action.value[1];
        component = { ...initComp, data: { ...markup } };
      } else {
        position = action.value;
      }

      return { ...state,
        body: [
          ...state.body.slice(0, position + 1),
          component,
          ...state.body.slice(position + 1),
        ],
        bodyMarkupFocus: action.value + 1,
      };
    }

    case UPDATE_LOCAL_CAPTION_EDITION_COMPONENT: {
      const [id, data] = action.value;
      return { ...state,
        body: state.body.map((comp) => {
          if (comp.id === id) {
            return {
              ...comp,
              data: {
                ...comp.data,
                [FIELD_LOCAL_CAPTION]: data,
              },
            };
          }
          return comp;
        }) };
    }

    case SAVE_EDITION_ARTICLE_COMPONENT: {
      const [data, pos] = action.value;
      const key = 'body';
      return { ...state,
        [key]: state[key].map((comp, i) => {
          if (i === pos) {
            return { ...comp,
              editorState: {
                ...comp.data.editorState,
                setSelectionPosition: null,
              },
              data: {
                ...comp.data,
                ...data,
              },
            };
          }
          return comp;
        }) };
    }

    case EDITION_PAGE_RE_ORDER: {
      const [prev, next] = action.value;
      return {
        ...state,
        editionDetail: {
          ...state.editionDetail,
          pages: arrayMove(state.editionDetail.pages, prev, next),
        },
      };
    }

    case EDITION_DETAIL_FETCH_SUCCESS: {
      return { ...state, editionDetail: action.value.data };
    }

    case EDITION_DETAIL_SAVE_SUCCESS: {
      if (action.value.changed) {
        return {
          ...state,
          editionDetail: {
            ...state.editionDetail,
            changed: action.value.changed,
          },
        };
      }
      return state;
    }

    case EDITION_DETAIL_NEW: {
      return { ...state, editionDetail: newEdition };
    }

    case EDITION_DETAIL_UPDATE: {
      const { prop, value } = action.value;
      return {
        ...state,
        editionDetail: {
          ...state.editionDetail,
          [prop]: value,
        },
      };
    }

    case EDITION_LIST_DRAFT_FETCH_SUCCESS: {
      const { paging: pagingDraft, pugpig_container: list, type } = action.value;
      return {
        ...state,
        pagingDraft: {
          ...state.pagingDraft,
          [type]: pagingDraft,
        },
        editionDraftList: {
          ...state.editionDraftList,
          [type]: list,
        },
      };
    }

    case EDITION_LIST_FETCH_SUCCESS: {
      const { paging, pugpig_container: list } = action.value;
      return { ...state, paging, editionList: list };
    }

    case EDITION_BODY_SET_COMPONENT_PROPERTY: {
      const [id, key, value] = action.value;
      return {
        ...state,
        body: state.body.map((comp) => {
          const { id: compId } = comp;
          if (id === compId) {
            return {
              ...comp,
              data: {
                ...comp.data,
                [key]: value,
              },
            };
          }
          return comp;
        }),
      };
    }

    case SET_ARTICLE_EDITION_COMPONENT_PROPERTY: {
      const { delta, prop, value } = action.value;
      const body = { ...state.body };
      return {
        ...state,
        body: Object.values({
          ...body,
          [delta]: {
            ...body[delta],
            [prop]: value,
          },
        }),
      };
    }

    case MERGE_ARTICLE_EDITION_COMPONENT_PROPERTY: {
      const { delta, prop, value } = action.value;
      const body = { ...state.body };
      const existing = typeof body[delta][prop] !== 'undefined' ? body[delta][prop] : {};
      return {
        ...state,
        body: Object.values({
          ...body,
          [delta]: {
            ...body[delta],
            [prop]: mergeDeepRight(existing, value),
          },
        }),
      };
    }

    case EDITION_PREVIEW_COMPONENT_HOVER:
      return {
        ...state,
        componentHover: action.value,
      };

    case EDITION_PREVIEW_COMPONENT_CLICK:
      return {
        ...state,
        componentClick: action.value,
      };

    case EDITION_ARTICLE_ENTER_PAGE:
    case PAGE_ENTER_EDITION_ARTICLE: {
      // dont purge editionDetail, for perceived performance
      return {
        ...defaultState,
        editionDetail: state.editionDetail,
      };
    }

    case EDITION_SECTION_BODY_DISPOSE:
      return {
        ...defaultState,
        body: defaultState.body,
      };

    case RESET_ARTICLE_EDITION_CREATION:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
