import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Dialog from 'components/dialog/common/Dialog';
import { searchArticle } from 'actions/article';
import Button from 'components/ui/buttons/Button';
import ListTable from 'components/table/ListTable';
import { TableBody, TableRow, TableCell } from 'components/ui/table/Table';

const ProductLinkDialog = (props) => {
  const {
    articles,
    onSelect,
    searchArticle: onSearch,
    allowArticle,
    title,
    path,
    nofollow,
    products,
    ...rest
  } = props;
  const [titleVal, setTitle] = useState(title || '');
  const isEdit = !!path;

  return (<Dialog title={isEdit ? 'Edit Product link' : 'Insert Product link'} maxWidth="md" {...rest}>
    <Grid container xs={12}>
      {products && products.map((item, i) => {
        return (
          <Button onClick={() => setTitle(item.data.id)} key={i}>
            {item.data.title}
          </Button>
        )
      })}
      <Grid item xs={12} container justify="flex-end">
        <Button
          disabled={!titleVal}
          onClick={() => {
            onSelect({ productId: titleVal });
          }}
          startIcon={<AddIcon />}
        >{isEdit ? 'Update Link' : 'Add Product link'}</Button>
      </Grid>
    </Grid>
  </Dialog>);
};

ProductLinkDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  searchArticle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  allowArticle: PropTypes.bool,
  articles: PropTypes.array,
  title: PropTypes.string,
  path: PropTypes.string,
  nofollow: PropTypes.bool,
};

ProductLinkDialog.defaultProps = {
  allowArticle: true,
  articles: [],
  title: null,
  path: null,
  nofollow: false,
};

export default connect(
  ({ autocomplete: { articles } }) => ({ articles }),
  { searchArticle },
)(ProductLinkDialog);
