import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const DetailList = ({ items }) => (
  <Grid container spacing={1}>
    {items.map(([label, value]) => (<>
      <Grid item xs={6}><Typography variant={'caption'}><strong>{label}</strong></Typography></Grid>
      <Grid item xs={6}><Typography variant={'caption'}>{value}</Typography></Grid>
    </>))}
  </Grid>
);

DetailList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default DetailList;

