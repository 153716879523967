import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { getHighlightRanges } from '../helper';

import ToolbarSearch from './ToolbarSearch';
import ToolbarReplace from './ToolbarReplace';
import Separator from '../../../../toolbar/Separator';
import { snapToView } from '../../../../../../utils/domHelper';

const selectNextInstance = (selected, ranges, editor) => {
  if (selected > 0) {
    // without timesout the deselect Transform is never processed and ToolbarBalloon isn't moved
    // @todo depricate and remove after replacing ToolbarBalloon with custom implementation
    setTimeout(() => {
      // setSelection straight doesn't correctly focus and setSelection
      ReactEditor.focus(editor);
      const domEl = ReactEditor.toDOMRange(editor, ranges[selected - 1])
        .commonAncestorContainer;
      snapToView(domEl);
      Transforms.select(editor, ranges[selected - 1].anchor);
      Transforms.setSelection(
        editor,
        ranges[selected - 1],
      );
    }, 30);
  } else {
    Transforms.deselect(editor);
  }
};

const ToolbarSearchReplace = ({ search, setSearch }) => {
  const editor = useSlate();
  const [ranges, setRanges] = useState([]);
  const [selected, setSelected] = useState(0);
  useEffect(() => {
    setRanges(getHighlightRanges(editor.children, search));
  }, [search]);
  useEffect(() => {
    selectNextInstance(selected, ranges, editor);
  }, [selected]);
  return (
    <>
      <ToolbarSearch
        key={'search'}
        value={search}
        onChange={e => setSearch(e.target.value)}
        resultsCount={ranges.length}
        selected={selected}
        onNext={() => {
          Transforms.deselect(editor);
          setSelected(selected + 1 > ranges.length ? 1 : selected + 1);
        }}
        onPrev={() => {
          Transforms.deselect(editor);
          setSelected(selected - 1 < 1 ? ranges.length : selected - 1);
        }}
      />
      <Separator small />
      <ToolbarReplace
        key={'replace'}
        disabled={!search || ranges.length === 0}
        onReplace={(replace) => {
          const selectedIndex = selected < 1 ? 1 : selected;
          const range = ranges.splice(selectedIndex - 1, 1);
          setRanges(ranges);
          ReactEditor.focus(editor);
          Transforms.select(editor, range[0].anchor);
          Transforms.setSelection(
            editor,
            range[0],
          );
          Transforms.insertText(editor, replace);
          if (ranges.length === 0) {
            setSelected(0);
          } else if (selectedIndex >= ranges.length + 1) {
            setSelected(1);
          } else if (selected === 0) {
            setSelected(1);
          } else {
            selectNextInstance(selectedIndex, ranges, editor);
          }
        }}
        onReplaceAll={(replace) => {
          const offsets = {};
          const offset = replace.length - search.length;
          ranges.forEach((range) => {
            const path = range.anchor.path;
            let aOffset = range.anchor.offset;
            let fOffset = range.focus.offset;
            if (offset !== 0) {
              const coord = path.join(':');
              if (offsets[coord]) {
                aOffset += offsets[coord];
                fOffset += offsets[coord];
                offsets[coord] += offset;
              } else {
                offsets[coord] = offset;
              }
            }
            ReactEditor.focus(editor);
            Transforms.select(editor, { path, offset: aOffset });
            Transforms.setSelection(
              editor,
              {
                anchor: { path, offset: aOffset },
                focus: { path, offset: fOffset },
              },
            );
            Transforms.insertText(editor, replace);
          });
          setRanges([]);
          setSelected(0);
          setSearch('');
        }}
      />
    </>
  );
};

ToolbarSearchReplace.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default ToolbarSearchReplace;
