import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';

import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import Loader from '../ui/Loader';
import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '../ui/table/Table';
import MediaRatio from './MediaRatio';
import Duration from './common/Duration';
import Button from '../ui/buttons/Button';
import { searchDailyMotionVideo } from '../../actions/integration/dailymotion';

const style = theme => ({
  paginationRoot: {
    flexBasis: '100%',
  },
  table: {
    margin: theme.spacing(0, -2),
  },
});

export const DAILYMOTION_VIDEO = 'https://www.dailymotion.com/video';
const pageSizeOpts = [12, 24, 60];

// @todo Mostly a duplication of the JWPlayerList, could be made into a generic component
const DailyMotionLibrary = ({
  classes,
  onSelect,
  dailyMotion,
  isDailyMotionSearching,
  searchDailyMotionVideo: onSearch,
}) => {
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(pageSizeOpts[0]);
  const [query, setQuery] = useState('');
  const { list, total } = dailyMotion;

  useEffect(() => {
    const params = {
      page: page + 1,
      limit: sizePerPage,
    };
    if (query) {
      params.search = query;
    }
    onSearch(params);
  }, [page, sizePerPage, query, onSearch]);
  return (
    <>
      <Grid item xs={12}>
        <TextSearchFilter
          placeholder="Search by title"
          defaultValue={query}
          onSubmit={(textQuery) => {
            setQuery(textQuery);
            setPage(0);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.table}>
          {isDailyMotionSearching && <Loader />}
          <Table>
            <TableHead>
              <TableRow hasBorder>
                {['Video', 'Title', 'Channel', ''].map(
                  label => (<TableHeadCell key={label}>{label}</TableHeadCell>),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length === 0 && !isDailyMotionSearching && <TableRow>
                <TableCell colSpan={4} align="center">No videos found</TableCell>
              </TableRow>}
              {list.length > 0 && list.map(({ id, title, duration, thumbnail_120_url: src }) => (
                <TableRow key={id} hasActions>
                  <TableCell>
                    <MediaRatio>
                      <img
                        src={src}
                        alt={title}
                      />
                    </MediaRatio>
                  </TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    <Typography noWrap><Duration duration={duration} /></Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => onSelect({ url: `${DAILYMOTION_VIDEO}/${id}` })}
                      variant="contained"
                    >Select</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {total > sizePerPage && <TablePagination
            classes={{ root: classes.paginationRoot }}
            rowsPerPageOptions={pageSizeOpts}
            component={'div'}
            count={total}
            rowsPerPage={sizePerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={(e, i) => setPage(i)}
            onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
          />}
        </div>
      </Grid>
    </>
  );
};

DailyMotionLibrary.propTypes = {
  searchDailyMotionVideo: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  dailyMotion: PropTypes.object.isRequired,
  isDailyMotionSearching: PropTypes.bool.isRequired,
};

export default withStyles(style)(connect(
  ({ media: { dailyMotion, isDailyMotionSearching } }) => ({ dailyMotion, isDailyMotionSearching }),
  { searchDailyMotionVideo },
)(DailyMotionLibrary));
