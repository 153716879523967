import { PURGE } from 'redux-persist';

import {
  EVENT_LIST_FETCH,
  EVENT_LIST_FETCH_SUCCESS,
  EVENT_LIST_FETCH_REJECTED,
  EVENT_LIST_DISPOSE,
} from '../../constants/actionTypes/event';

const defaultState = {
  events: [],
  total: 0,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EVENT_LIST_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case EVENT_LIST_FETCH_SUCCESS: {
      const { items = [], total = 0 } = action.value;
      return {
        ...state,
        events: items,
        total,
        isFetching: false,
      };
    }
    case EVENT_LIST_FETCH_REJECTED:
      return {
        ...state,
        isFetching: false,
      };
    case EVENT_LIST_DISPOSE:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
