import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import EditOutlined from '@material-ui/icons/EditOutlined';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import RemoveIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid/Grid';
import { Dashboard } from '@material-ui/icons';

import { Table, TableBody, TableRow, TableCell, TablePagination } from '../../ui/table/Table';

import TableHeader from '../TableHeader';
import TableToolbar from '../vocab/VocabTableToolbar';
import { setPage, setPageRows } from '../../../actions/table';
import PageSection from '../../common/page/PageSection';

const styles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  selectHover: {
    cursor: 'pointer',
  },
});

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array
    .map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => (order === 'desc' ?
  (a, b) => desc(a, b, orderBy) :
  (a, b) => -desc(a, b, orderBy));

const headers = [
  { id: 'name', label: 'Name', orderable: true },
  { id: 'roles', label: 'Roles' },
  { id: 'status', label: 'Status' },
  { id: 'access', label: 'Last login', orderable: true },
  { id: 'action', label: '', align: 'right' },
];

/**
 * @depricated replace with a generic table
 * @todo setup a generic table from this component
 */
function UserTable(props) {
  const {
    classes, data, cellFormat, setPage, setPageRows,
    onClickRow, checked,
    order, orderBy, page, rowsPerPage, actions, showActions } = props;
  const [filterBy, setFilterBy] = useState('');

  const filteredData = data.filter(el => el.name.toLowerCase().includes(filterBy.toLowerCase()));
  return (
    <>
      <PageSection>
        <TableToolbar setFilterBy={setFilterBy} />
      </PageSection>
      <PageSection>
        <Table
          aria-labelledby={'tableTitle'}
          size={'medium'}
        >
          <TableHeader
            order={order}
            orderBy={orderBy}
            rowCount={filteredData.length || 0}
            headers={headers}
            hasBorder
          />
          <TableBody>
            {stableSort(filteredData, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow
                  tabIndex={-1}
                  key={row.id}
                  onClick={onClickRow}
                  hasActions={(showActions && actions.length > 0)}
                >
                  {cellFormat.map(cell =>
                    (<TableCell key={cell.id} align="left">
                      {cell.display(row)}
                    </TableCell>),
                  )}
                  {showActions && actions.length > 0 && <TableCell align={'right'}>
                    <Grid container wrap={'nowrap'} justify={'flex-end'}>
                      {actions.map((action) => {
                        switch (action.type) {
                          case 'checkbox':
                            return (
                              <Checkbox
                                key={action.type}
                                color={'primary'}
                                checked={checked.includes(row.id)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                                onChange={(e) => {
                                  action.action([e.target.checked, row]);
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              />);
                          case 'layout':
                            return (
                              <IconButton
                                color={'primary'}
                                key={action.type}
                                onClick={(e) => {
                                  action.action(row);
                                  e.stopPropagation();
                                }}
                              >
                                <Dashboard />
                              </IconButton>);
                          case 'select':
                            return (
                              <IconButton
                                key={action.type}
                                className={classes.link}
                                onClick={() => (actions.length === 1 ? null : action.action(row))}
                              >
                                <SaveOutlined className={classes.icon} />
                              </IconButton>);
                          case 'edit':
                            return (
                              <IconButton
                                key={action.type}
                                className={classes.link}
                                onClick={(e) => {
                                  action.action(row);
                                  e.stopPropagation();
                                }}
                              >
                                <EditOutlined className={classes.icon} />
                              </IconButton>);
                          case 'delete':
                            return (
                              <IconButton
                                key={action.type}
                                className={classes.link}
                                onClick={(e) => {
                                  action.action(row);
                                  e.stopPropagation();
                                }}
                              >
                                <RemoveIcon className={classes.icon} />
                              </IconButton>);
                          default:
                            return null;
                        }
                      })}
                    </Grid>
                  </TableCell>}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={setPage}
          onChangeRowsPerPage={event => setPageRows(event.target.value)}
        />
      </PageSection>
    </>
  );
}


UserTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  checked: PropTypes.array,
  cellFormat: PropTypes.array,
  actions: PropTypes.array,
  setPage: PropTypes.func.isRequired,
  setPageRows: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  filterBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  showActions: PropTypes.bool,
  onClickRow: PropTypes.func,
};

UserTable.defaultProps = {
  data: [],
  checked: [],
  cellFormat: [],
  actions: [],
  showActions: true,
  onClickRow: null,
};

export default withStyles(styles)(connect(
  ({ table: { order, orderBy, filterBy, page, rowsPerPage } }) =>
    ({ order, orderBy, filterBy, page, rowsPerPage }),
  { setPage, setPageRows },
)(UserTable));
