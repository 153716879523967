export const reformatDataForMenu = (data) => {
  const items = Object.keys(data);
  const newArr = [];
  items.forEach((key) => {
    if (!Array.isArray(data[key].children)) {
      newArr.push({
        ...data[key],
        title: data[key].name,
        expanded: false,
        children: reformatDataForMenu(data[key].children),
      });
    } else {
      newArr.push({ ...data[key], title: data[key].name });
    }
  });
  return newArr;
};

export const reformatTranslationSection = (data, list = [], deep = 0) => {
  const items = Object.keys(data);
  const newArr = list;
  items.forEach((key) => {
    const { children, ...rest } = data[key];
    if (rest.sourcePath) {
      newArr.push({ ...rest, deep, title: data[key].sourceName });
    }
    if (!Array.isArray(children)) {
      reformatTranslationSection(children, newArr, deep + 1);
    }
  });
  return newArr;
};

export const reformatDataForLayoutTree = (data) => {
  const items = Object.keys(data);
  const newArr = [];
  items.forEach((key) => {
    if (!Array.isArray(data[key].children)) {
      const layout = data[key].layout || {
        data: {
          section: data[key].section,
        },
      };
      newArr.push({
        ...layout.data,
        title: layout.data.section.name,
        tid: layout.data.id,
        expanded: true,
        children: reformatDataForLayoutTree(data[key].children),
      });
    } else {
      newArr.push({
        ...data[key].layout.data, title: data[key].layout.data.section.name, children: [],
      });
    }
  });
  return newArr;
};
