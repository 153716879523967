import React from 'react';
import { connect } from 'react-redux';
import Loader from '../ui/Loader';

export default connect(({ media: { isUploading } }) => ({ isUploading }), {})(({ isUploading }) => {
  if (isUploading) {
    return <Loader />;
  }
  return null;
});
