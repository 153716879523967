import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_VOUCHER } from './defaults';

const renderElementVoucher = (options) => {
  const { voucher } = setDefaults(options, DEFAULTS_VOUCHER);
  return getRenderElement(voucher);
};

export default renderElementVoucher;
