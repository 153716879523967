import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_HR } from './defaults';

const deserializeHorizontalRule = (options) => {
  const { horizontalRule } = setDefaults(options, DEFAULTS_HR);
  return {
    element: getNodeDeserializer({
      type: horizontalRule.type,
      node: () => ({
        type: horizontalRule.type,
      }),
      rules: [
        { nodeNames: 'HR' },
      ],
      ...options?.horizontalRule?.deserialize,
    }),
  };
};

export default deserializeHorizontalRule;
