import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import AvatarNumbered from '../../ui/AvatarNumbered';

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const StoryEmbed = (props) => {
  const {
    data, id: componentId,
  } = props;
  return (
    <Grid container spacing={2} direction={'column'}>
      {data.stories && <Grid item>
        {data.stories.map((story, index) => (<ListItem index={index} key={`story-${componentId}-${story.id}`}>
          <ListItemAvatar>
            <AvatarNumbered>{index + 1}</AvatarNumbered>
          </ListItemAvatar>
          <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={story.label} />
        </ListItem>))}
      </Grid>}
    </Grid>
  );
};

StoryEmbed.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object,
};

StoryEmbed.defaultProps = {
  data: {},
};

export default StoryEmbed;
