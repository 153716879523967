import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Autocomplete from '../../../../../ui/SelectAutocomplete';

const getOptionsFromPages = (pages) => {
  return pages.map(page => (
    {
      label: page.data.title,
      value: page.data.id,
    }
  ));
};

const getPageFromId = (pages, id) => {
  const page = pages.find(page => page.data.id === id);
  return page || null;
};

const getOptionFromPage = (page) => {
  if (page) {
    return {
      label: page.data.title,
      value: page.data.id,
    };
  }
  return null;
};

const ArticleRef = (props) => {
  const { id, editionDetail, label, onChange } = props;
  const { pages = [] } = editionDetail !== null ? editionDetail : {};
  return (
    <Autocomplete
      label={label}
      value={getOptionFromPage(getPageFromId(pages, id))}
      defaultOptions={getOptionsFromPages(pages)}
      onChange={value => onChange(getPageFromId(pages, value.value))}
    />
  );
};

ArticleRef.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  editionDetail: PropTypes.object,
  label: PropTypes.string,
};

ArticleRef.defaultProps = {
  id: null,
  editionDetail: {
    pages: [],
  },
  label: 'Linked Article',
};

export default connect(
  ({ edition: { editionDetail } }) => ({ editionDetail }),
  {},
)(ArticleRef);
