import InstagramElement from './components/InstagramElement';

export const ELEMENT_INSTAGRAM = 'instagram';
export const PROP_URL = 'url';

export const INSTAGRAM_PATTERN =  /^http[s]?:\/\/(www\.)?instagram\.com\/(tv|p)\/[^/]*/;

export const DEFAULTS_INSTAGRAM = {
  instagram: {
    component: InstagramElement,
    type: ELEMENT_INSTAGRAM,
    rootProps: {
      className: 'slate-instagram',
    },
  },
};
