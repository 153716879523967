import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { map, filter, mergeMap, withLatestFrom, debounceTime } from 'rxjs/operators';

import apiCatchError from '../helper/notification';
import {
  ARTICLE_AUTOCOMPLETE,
  ARTICLE_AUTOCOMPLETE_SUCCESS,
  ARTICLE_AUTOCOMPLETE_FAILURE,
} from 'constants/actionTypes/article';
import {
  STORY_AUTOCOMPLETE,
  STORY_AUTOCOMPLETE_SUCCESS,
} from 'constants/actionTypes/story';

export const autocompleteArticles = (action$, state$) => action$.pipe(
  ofType(ARTICLE_AUTOCOMPLETE),
  filter(action => action.value && action.value.length > 2),
  debounceTime(500),
  withLatestFrom(state$),
  mergeMap(([action, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/article/search/${domain}?q=${encodeURIComponent(action.value)}&redirect=0`).pipe(
      map(response => ({
        type: ARTICLE_AUTOCOMPLETE_SUCCESS,
        value: response,
      })),
      apiCatchError(ARTICLE_AUTOCOMPLETE_FAILURE),
    ),
  ),
);

export const autocompleteStories = (action$, state$) => action$.pipe(
  ofType(STORY_AUTOCOMPLETE),
  filter(action => action.value && action.value.length > 2),
  debounceTime(500),
  withLatestFrom(state$),
  mergeMap(([action, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(
      `/api/story-list/default/${domain}?q=${encodeURIComponent(action.value)}&limit=10&offset=0&status=1`,
    ).pipe(
      map(response => ({
        type: STORY_AUTOCOMPLETE_SUCCESS,
        value: response,
      })),
      apiCatchError(),
    ),
  ),
);
