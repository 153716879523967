import ButtonLinkElement from './components/ButtonLinkElement';

export const ELEMENT_BUTTON_LINK = 'buttonLink';
export const PROP_TITLE = 'title';
export const PROP_HEADING = 'heading';
export const PROP_DESCRIPTION = 'description';
export const PROP_URL = 'url';

export const DEFAULTS_BUTTON_LINK = {
  buttonLink: {
    component: ButtonLinkElement,
    type: ELEMENT_BUTTON_LINK,
    rootProps: {
      className: 'slate-button-link',
    },
  },
};
