import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Close';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { setStoryContext, dropBookendArticle, removeBookendArticle } from '../../../../actions/story';

import {
  CONTEXT_ARTICLE,
  CONTEXT_ARTICLE_SELECTOR,
} from '../../../../constants/contexts';

import IconButton from '../../../ui/buttons/IconButton';
import DropZoneArticle from '../../../ui/dropzones/DropZoneArticle';
import DraggableArticle from '../../../ui/draggables/DraggableArticle';
import { OVERRIDE_TITLE } from '../../../../constants/layout/layout';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    '& .remove-article': {
      display: 'none',
      padding: 0,
      margin: '12px 0',
    },
    '&:hover .remove-article': {
      display: 'block',
    },
  },
  dropzone: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.border.secondary.main, 0.6),
    overflow: 'hidden',
    '&.drop-enabled': {
      borderStyle: 'dashed',
      borderColor: '#000',
    },
  },
  dropzoneEmpty: {
    borderColor: fade(theme.palette.border.secondary.main, 0.3),
  },
  articleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    margin: '-2px -5px !important',
    padding: '2px 5px !important',
    border: '0 none !important',
  },
  articleTitle: {
    maxWidth: '100%',
    flexGrow: 1,
    height: 42,
    lineHeight: '21px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  articleIndex: {
    display: 'flex',
    alignItems: 'center',
    margin: '-2px 6px -2px -5px',
    padding: 5,
    background: theme.palette.background.default,
    color: theme.palette.grey.main,
  },
  icon: {
    width: 20,
    height: 20,
  },
});

const EmptyArticleContent = ({ classes, setContext }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <div role="form" className={classes.articleWrapper} onClick={() => setContext(CONTEXT_ARTICLE_SELECTOR)} />
);

EmptyArticleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  setContext: PropTypes.func.isRequired,
};

const ArticleContent = (props) => {
  const {
    classes, article, removeAction, index, setContext,
  } = props;
  const { overrides = {} } = article;
  const source = {
    index,
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      role="form"
      className={classes.wrapper}
      onClick={() => setContext(
        CONTEXT_ARTICLE,
        { targetIndex: index },
      )}
    >
      <DraggableArticle
        article={article}
        source={source}
        className={classes.articleWrapper}
      >
        <Typography className={classes.articleIndex} variant={'body2'}>{index + 1}</Typography>
        <Typography className={classes.articleTitle} variant={'body2'}>{overrides[OVERRIDE_TITLE] || article.label}</Typography>
        <IconButton className={'remove-article'} onClick={() => removeAction(index)}>
          <RemoveIcon className={classes.icon} />
        </IconButton>
      </DraggableArticle>
    </div>
  );
};

ArticleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  removeAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setContext: PropTypes.func.isRequired,
};

ArticleContent.defaultProps = {};

const getContents = (article) => {
  if (typeof article === 'undefined' || article === null || typeof article.target_id === 'undefined') return EmptyArticleContent;
  return ArticleContent;
};

const articleDataProcessor = index => ({ article, source }) => ({
  item: article,
  source,
  index,
});

const ArticleDropZone = (props) => {
  const {
    classes, index, article,
    dropBookendArticle: dropArticle,
    removeBookendArticle: removeArticle,
    setStoryContext: setContext,
  } = props;
  const Content = getContents(article);
  const dropzoneClasses = [classes.dropzone];
  if (
    article === null ||
    typeof article === 'undefined' ||
    typeof article.target_id === 'undefined'
  ) {
    dropzoneClasses.push(classes.dropzoneEmpty);
  }
  return (
    <DropZoneArticle
      dropHandler={dropArticle}
      dataProcessor={articleDataProcessor(index)}
      className={dropzoneClasses.join((' '))}
    >
      <Content
        classes={classes}
        article={article}
        removeAction={removeArticle}
        index={index}
        setContext={setContext}
      />
    </DropZoneArticle>
  );
};

ArticleDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object,
  index: PropTypes.number.isRequired,
  dropBookendArticle: PropTypes.func.isRequired,
  removeBookendArticle: PropTypes.func.isRequired,
  setStoryContext: PropTypes.func.isRequired,
};

ArticleDropZone.defaultProps = {
  article: null,
};

export default withStyles(styles)(connect(
  () => ({}),
  {
    dropBookendArticle,
    removeBookendArticle,
    setStoryContext,
  },
)(ArticleDropZone));
