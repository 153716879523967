import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';
import Select from '../../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

const CATEGORY = 'category';

const TaboolaOptions = (props) => {
  const {
    component,
    componentSetProp: setProp,
  } = props;

  const items = [
    'Asia',
    'Brexit',
    'Business',
    'Climate',
    'Cryptocurrency',
    'Culture',
    'Football',
    'Lifestyle',
    'Sport',
    'US',
    'US Politics',
    'UK Politics',
    'Voices',
    'World',
  ];

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          displayEmpty
          label={'Category'}
          labelId={`category${component.id}`}
          value={component[CATEGORY] || ''}
          emptyValue={'All'}
          onChange={e => setProp(component.id, CATEGORY, e.target.value)}
          items={items}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

TaboolaOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(TaboolaOptions);
