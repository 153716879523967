import { PURGE } from 'redux-persist';

import {
  FIELD_TITLE,
  FIELD_AUTHORS,
  FIELD_PAGES,
  FIELD_BOOKEND,
} from '../../constants/story/storyFields';
import {
  STORY_ADD_PAGE,
  STORY_REMOVE_PAGE,
  STORY_REORDER_PAGE,
  STORY_UPDATE_PAGE_PROP,
  STORY_UPDATE_FIELD,
  STORY_SET_CONTEXT,
  STORY_DISPOSE,
  STORY_FETCH,
  STORY_FETCH_SUCCESS,
  STORY_BOOKEND_ARTICLE_DROP,
  STORY_BOOKEND_ARTICLE_REMOVE,
  STORY_SAVE,
  STORY_SAVE_SUCCESS,
  STORY_SAVE_REJECTED,
  STORY_SAVE_READY,
} from '../../constants/actionTypes/story';
import { CONTEXT_STORY } from '../../constants/contexts';
import generateKey from '../../utils/generateKey';

const defaultState = {
  [FIELD_TITLE]: '',
  [FIELD_AUTHORS]: [],
  [FIELD_PAGES]: [],
  [FIELD_BOOKEND]: {},
  context: { type: CONTEXT_STORY },
  storyFromServer: {},
  isBusy: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STORY_ADD_PAGE: {
      const { page, delta } = action.value;
      const pageInstance = { ...page, id: generateKey() };
      return {
        ...state,
        [FIELD_PAGES]: [
          ...state[FIELD_PAGES].slice(0, delta),
          pageInstance,
          ...state[FIELD_PAGES].slice(delta),
        ],
      };
    }
    case STORY_REMOVE_PAGE: {
      return {
        ...state,
        [FIELD_PAGES]: state[FIELD_PAGES].filter(({ id }) => id !== action.value),
      };
    }
    case STORY_REORDER_PAGE: {
      const { oldIndex, newIndex } = action.value;
      const page = state[FIELD_PAGES].find((value, index) => index === oldIndex);
      const pages = state[FIELD_PAGES].filter((value, index) => index !== oldIndex);
      return {
        ...state,
        [FIELD_PAGES]: [
          ...pages.slice(0, newIndex),
          page,
          ...pages.slice(newIndex),
        ],
      };
    }
    case STORY_UPDATE_PAGE_PROP: {
      const { id, prop, value } = action.value;
      return {
        ...state,
        [FIELD_PAGES]: state[FIELD_PAGES].map((page) => {
          if (page.id === id) {
            return {
              ...page,
              [prop]: value,
            };
          }
          return page;
        }),
      };
    }
    case STORY_UPDATE_FIELD: {
      const { field, value } = action.value;
      return {
        ...state,
        [field]: value,
      };
    }
    case STORY_FETCH_SUCCESS:
      return {
        ...state,
        storyFromServer: action.value,
      };
    case STORY_SET_CONTEXT:
      return {
        ...state,
        context: action.value,
      };
    case STORY_BOOKEND_ARTICLE_DROP: {
      const { item, source, index } = action.value;
      const articles = typeof state[FIELD_BOOKEND].articles !== 'undefined'
        ? state[FIELD_BOOKEND].articles : [];
      if (source && typeof source.index !== 'undefined') {
        articles.splice(source.index, 1);
      }
      return {
        ...state,
        [FIELD_BOOKEND]: {
          ...state[FIELD_BOOKEND],
          articles: [
            ...articles.slice(0, index),
            item,
            ...articles.slice(index),
          ],
        },
      };
    }
    case STORY_BOOKEND_ARTICLE_REMOVE:
      return {
        ...state,
        [FIELD_BOOKEND]: {
          ...state[FIELD_BOOKEND],
          articles: state[FIELD_BOOKEND].articles.filter((item, index) => index !== action.value),
        },
      };
    case STORY_SAVE_READY:
      return {
        ...state,
        isBusy: true,
      };
    case STORY_SAVE_REJECTED:
    case STORY_SAVE_SUCCESS:
      return {
        ...state,
        isBusy: false,
      };
    case STORY_FETCH:
    case STORY_DISPOSE:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
