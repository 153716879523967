import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import Image from '@material-ui/icons/Image';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import ImageSelector from '../../../../../../ui/media/ImageSelector';
import TextField from '../../../../../../ui/TextField';
import MarkupField from '../../../../../../ui/MarkupField';

import { PROP_IMAGE, PROP_CAPTION, PROP_COPYRIGHT, PROP_ALIGN } from '../defaults';
import AlignmentToggle from '../../../../common/AlignmentToggle';
import { deserializeString } from '../../../../../helper/serializer';

const ImageElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  const image = element[PROP_IMAGE] || {};
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Image'}
        Icon={Image}
        contentEditable={false}
        element={element}
        toolbar={<AlignmentToggle
          align={element[PROP_ALIGN] || null}
          onSelect={(value) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_ALIGN]: value }, { at: path });
          }}
        />}
      >
        <Grid container spacing={1}>
          <Grid item xs={image.url ? 2 : 12}>
            <ImageSelector
              label={'Drag & Drop image or browse'}
              selectImage={(imageData) => {
                const path = ReactEditor.findPath(editor, element);
                const data = {
                  [PROP_IMAGE]: imageData,
                  [PROP_COPYRIGHT]: imageData.copyright || '',
                  [PROP_CAPTION]: imageData.caption ? deserializeString(`<p>${imageData.caption}</p>`) : '',
                };
                Transforms.setNodes(editor, data, { at: path });
              }}
              onClose={() => {
                const path = ReactEditor.findPath(editor, element);
                ReactEditor.focus(editor);
                Transforms.setSelection(editor, path);
              }}
              image={image}
              showEditDialog
            />
          </Grid>
          {image.url && <Grid item container xs={10} spacing={2}>
            <Grid item xs={12}>
              <MarkupField
                label={'Caption'}
                data={element[PROP_CAPTION]}
                onChange={(value) => {
                  const path = ReactEditor.findPath(editor, element);
                  Transforms.setNodes(
                    editor,
                    { [PROP_CAPTION]: Array.isArray(value) ? value : deserializeString(value) },
                    { at: path },
                  );
                }}
                nested
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={'Copyright'}
                value={element[PROP_COPYRIGHT]}
                onChange={(e) => {
                  const path = ReactEditor.findPath(editor, element);
                  Transforms.setNodes(editor, { [PROP_COPYRIGHT]: e.target.value }, { at: path });
                }}
                margin={'none'}
              />
            </Grid>
          </Grid>}
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

ImageElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default ImageElement;
