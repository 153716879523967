import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BookingIcon from '../../../../../../../assets/icons/BookingIcon';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import BookingDialog from '../../../../../../dialog/BookingDialog';
import Button from '../../../../../../ui/buttons/Button';
import TextField from 'components/ui/TextField';
import { PROP_HOTEL } from '../defaults';

const BookingElement = (props) => {
  const { attributes, children, element, className } = props;
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();
  console.log({ element })
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Booking.com Embed'} Icon={BookingIcon} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {element[PROP_HOTEL] && element[PROP_HOTEL].name && <Typography varient={'body2'}>{element[PROP_HOTEL].name}</Typography>}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="URL"
              placeholder={'Insert url'}
              rows={5}
              ignoreSmartQuote
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { url: e.target.value }, { at: path });
              }}
              defaultValue={element.url || ''}
            />
          </Grid>
          <Grid item container justify={'center'}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<AddCircleOutlineIcon />}
            >{element[PROP_HOTEL] && element[PROP_HOTEL].id ? 'Change Hotel' : 'Add Hotel'}</Button>
          </Grid>
        </Grid>
        {open && <BookingDialog
          open={open}
          hotel={element[PROP_HOTEL] || {}}
          onChangeHotel={(value) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              { [PROP_HOTEL]: { name: value.label, id: value.value } },
              { at: path },
            );
          }}
          handleClose={() => setOpen(false)}
        />}
      </EmbedWrapper>
      {children}
    </div>
  );
};

BookingElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default BookingElement;
