import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '../ui/TextField';
import Button from '../ui/buttons/Button';
import { youtubeParser } from '../helper/media';

const styles = theme => ({
  root: {
    minHeight: 400,
    margin: theme.spacing(2, 0),
  },
  buttonRoot: {
    margin: theme.spacing(2, 0),
  },
});

const YouTubeForm = ({ classes, onSelect }) => {
  const [url, setUrl] = useState('');
  return (<Grid container className={classes.root} direction={'column'}>
    <TextField onChange={event => setUrl(event.target.value)} label={'Youtube URL'} />
    <Grid container justify={'flex-end'} className={classes.buttonRoot}>
      <Button
        disabled={!youtubeParser(url)}
        onClick={() => onSelect(youtubeParser(url))}
        variant={'contained'}
      >Select</Button>
    </Grid>
  </Grid>);
};

YouTubeForm.defaultProps = {
};

YouTubeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  {}
)(YouTubeForm));

