export const DAILYMOTION_FETCH_CHANNELS = 'DAILYMOTION_FETCH_CHANNELS';
export const DAILYMOTION_FETCH_CHANNELS_SUCCESS = 'DAILYMOTION_FETCH_CHANNELS_SUCCESS';
export const DAILYMOTION_FETCH_CHANNELS_REJECTED = 'DAILYMOTION_FETCH_CHANNELS_REJECTED';

export const DAILYMOTION_SEARCH = 'DAILYMOTION_SEARCH';
export const DAILYMOTION_SEARCH_SUCCESS = 'DAILYMOTION_SEARCH_SUCCESS';
export const DAILYMOTION_SEARCH_REJECTED = 'DAILYMOTION_SEARCH_REJECTED';

export const DAILYMOTION_UPLOAD = 'DAILYMOTION_UPLOAD';
export const DAILYMOTION_UPLOAD_PROGRESS = 'DAILYMOTION_UPLOAD_PROGRESS';
export const DAILYMOTION_UPLOAD_SUCCESS = 'DAILYMOTION_UPLOAD_SUCCESS';
export const DAILYMOTION_UPLOAD_REJECTED = 'DAILYMOTION_UPLOAD_REJECTED';
