import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { PagePanel, PagePanelContent, PagePanelHeading } from '../common/page/PagePanel';
import SectionTitle from '../common/SectionTitle';

import { setStoryContext } from '../../actions/story';
import { CONTEXT_ARTICLE } from '../../constants/contexts';

import Title from './storyBuilder/Title';
import Authors from './storyBuilder/Authors';
import Section from './storyBuilder/Section';
import Pages from './storyBuilder/Pages';
import Bookend from './storyBuilder/Bookend';
import URLKeywords from './storyBuilder/URLKeywords';
import MetaTitle from './storyBuilder/MetaTitle';

const StoryMain = (props) => {
  const {
    setStoryContext: setContext,
  } = props;
  return (
    <>
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelHeading>
            <SectionTitle>Settings</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Title />
              </Grid>
              <Grid item xs={6}>
                <URLKeywords />
              </Grid>
              <Grid item xs={6}>
                <MetaTitle />
              </Grid>
            </Grid>
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelContent noHeading>
            <Section />
            <Authors />
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <SectionTitle>Pages</SectionTitle>
        <Pages />
      </Grid>
      {/* bookends disabled */ false && <Grid item xs={12}>
        <PagePanel onClick={() => setContext(CONTEXT_ARTICLE)}>
          <PagePanelHeading>
            <SectionTitle>Bookend</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <Bookend />
          </PagePanelContent>
        </PagePanel>
      </Grid>}
    </>
  );
};

StoryMain.propTypes = {
  setStoryContext: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { setStoryContext },
)(StoryMain);
