import { TEMPLATE_DEFAULTS } from '../../constants/edition/editionTemplates';
import generateKey from '../../utils/generateKey';
import { STRAPLINE } from '../../constants/edition/editionComponents';

export const getDefaultTemplateBody = (template, body) => {
  const templateDefault = TEMPLATE_DEFAULTS[template];
  if (typeof templateDefault === 'undefined') return [];
  return templateDefault.map((component) => {
    const matchedComponent = body.find(({ type }) => type === component.type && type !== STRAPLINE);
    return matchedComponent || {
      ...component,
      id: generateKey(),
    };
  });
};
