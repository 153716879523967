import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OpenInBrowserIcon from "@material-ui/icons/OpenInNew";
import IconButton from "./IconButton";
import { PRODUCTION } from "../../../constants/env";

function isURL(str) {
  try {
    const pattern = new RegExp(
      "http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+"
    );
    return pattern.test(str);
  } catch (error) {
    console.error("Error checking URL:", error);
    return false;
  }
}

const OpenInNewTabButton = ({ selectedPublication, target, mode }) => {
  const frontEndUrl =
    mode === PRODUCTION
      ? selectedPublication.url
      : selectedPublication.environment.web;

  const parsedTarget = isURL(target)
    ? target
    : `${frontEndUrl.replace(/\/$/, "")}${target}`;
  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(parsedTarget, "_blank");
      }}
    >
      <OpenInBrowserIcon />
    </IconButton>
  );
};

OpenInNewTabButton.defaultProps = {};

OpenInNewTabButton.propTypes = {
  target: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  selectedPublication: PropTypes.object.isRequired,
};

export default connect(
  ({ frame: { selectedPublication }, dashboard: { mode } }) => ({
    selectedPublication,
    mode,
  }),
  {}
)(OpenInNewTabButton);
