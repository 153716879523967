// Common entity fields
export const FIELD_ID = 'id';
export const FIELD_NAME = 'name';
export const FIELD_PATH = 'path';
export const FIELD_CREATED = 'created';
export const FIELD_UPDATED = 'changed';
export const FIELD_PUBLISHED = 'published';
export const FIELD_STATUS = 'status';
export const FIELD_WORKFLOW = 'workflow';
// Common API fields
export const API_FIELD_WORKFLOW = 'field_workflow_generic';
export const API_FIELD_EXTRA_DATA = 'field_extra_json';
export const API_FIELD_PUBLICATION = 'field_publication';
export const API_FIELD_MEDIA = 'field_media_references';
// Media Captioning
export const FIELD_LOCAL_CAPTION = 'localCaption';
export const FIELD_LOCAL_COPYRIGHT = 'localCopyright';
export const FIELD_HERO_IMAGE_LOCAL_CAPTION = 'heroImageLocalCaption';
export const FIELD_HERO_IMAGE_LOCAL_COPYRIGHT = 'heroImageLocalCopyright';
export const FIELD_FYI = 'fyi';
export const FIELD_HERO_VIDEO_LOCAL_CAPTION = 'heroVideoLocalCaption';
export const FIELD_HERO_VIDEO_LOCAL_COPYRIGHT = 'heroVideoLocalCopyright';
