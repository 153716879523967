import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { PagePanel, PagePanelContent } from './PagePanel';

const PageSection = ({ children, includePadding }) => (
  <Grid item xs={12}>
    <PagePanel>
      <PagePanelContent
        noPadding={!includePadding}
        noHeading={includePadding}
      >
        {children}
      </PagePanelContent>
    </PagePanel>
  </Grid>
);

PageSection.propTypes = {
  children: PropTypes.node.isRequired,
  includePadding: PropTypes.bool,
};

PageSection.defaultProps = {
  includePadding: false,
};

export default PageSection;
