import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_GALLERY } from './defaults';

const renderElementGallery = (options) => {
  const { gallery } = setDefaults(options, DEFAULTS_GALLERY);
  return getRenderElement(gallery);
};

export default renderElementGallery;
