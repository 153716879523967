import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import { ARTICLE_COMPONENTS, AD_COMPONENTS, ALL_COMPONENTS } from '../../../constants/components';

import ComponentDraggable from './componentSelector/ComponentDraggable';

import { ContextBarBody } from '../../common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../common/context/ContextPanel';
import { FIELD_COMPONENTS_ORDER } from '../../../constants/layout/layoutFields';

const ComponentSelectorTitle = ({ children }) => (
  <Typography variant={'subtitle1'} component={'h2'}>{children}</Typography>
);

ComponentSelectorTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

const ComponentSelector = (props) => {
  const { components, externalOrder } = props;
  return (
    <ContextBarBody>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Article Components</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {components.filter(({ id }) => ARTICLE_COMPONENTS.includes(id)).map(comp => (
            <ComponentDraggable
              disabled={!!externalOrder}
              key={comp.id}
              component={comp}
            />
          ))}
        </ContextPanelDetails>
      </ContextPanel>
      {components.filter(({ id }) => AD_COMPONENTS.includes(id)).length > 0 && <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Ad Components</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {components.filter(({ id }) => AD_COMPONENTS.includes(id)).map(comp => (
            <ComponentDraggable
              disabled={!!externalOrder}
              key={comp.id}
              component={comp}
            />
          ))}
        </ContextPanelDetails>
      </ContextPanel>}
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Other</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {components.filter(({ id }) => !ALL_COMPONENTS.includes(id)).map(comp => (
            <ComponentDraggable
              disabled={!!externalOrder}
              key={comp.id}
              component={comp}
            />
          ))}
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBarBody>
  );
};

ComponentSelector.propTypes = {
  components: PropTypes.array.isRequired,
  externalOrder: PropTypes.array,
};

ComponentSelector.defaultProps = {
  externalOrder: null,
};

export default connect(
  ({
    layout: { components },
    externalState: { [FIELD_COMPONENTS_ORDER]: externalOrder },
  }) => ({ components, externalOrder }),
  { },
)(ComponentSelector);
