/* global instgrm */
import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import { initEmbed } from '../../../actions/liveblog';
import {INSTAGRAM} from "../../../constants/builder/builder";

const styles = theme => ({
  root: {
  },
});

const InstagramEmbed = ({ classes, data, id, initEmbed }) => {
  useEffect(() => {
    initEmbed([INSTAGRAM, id]);
    if (typeof instgrm !== 'undefined') {
      instgrm.Embeds.process();
    }
  }, [data.html]);
  return (<Grid container justify={'center'}><div id={id} dangerouslySetInnerHTML={{ __html: data.html }} /></Grid>);
};

InstagramEmbed.defaultProps = {
  data: null,
  bodyMarkupFocus: null,
};

InstagramEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  id: PropTypes.string.isRequired,
  initEmbed: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(() => ({}), { initEmbed })(InstagramEmbed));

