import {
  ELEMENT_LI,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
} from '@udecode/slate-plugins';

export const paragraph = {
  type: ELEMENT_PARAGRAPH,
  children: [{ text: '' }],
};

export const ul = {
  type: ELEMENT_UL,
  children: [{
    type: ELEMENT_LI,
    children: [{
      type: ELEMENT_PARAGRAPH,
      children: [{ text: '' }],
    }],
  }],
};
