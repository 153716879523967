import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../FieldLockField';
import ToggleSwitch from '../../ui/ToggleSwitch';
import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';
import { setLocalProp } from '../../../actions/dataState';

const FieldLockToggle = ({
  value,
  label,
  lockValue,
  field,
  hasChanges,
  disabled,
  setLocalProp: setProp,
  ...rest
}) => (
  <FieldLockField field={field} lockValue={lockValue}>
    <ToggleSwitch
      label={label}
      disabled={disabled}
      hasChanges={hasChanges}
      value={value}
      onChange={e => setProp(field, e.target.checked)}
      {...rest}
    />
  </FieldLockField>
);

FieldLockToggle.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  value: PropTypes.bool,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLockToggle.defaultProps = {
  value: false,
  lockValue: null,
  hasChanges: false,
  disabled: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: !!getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockToggle);
