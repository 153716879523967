import { switchMap, mergeMap, withLatestFrom, mapTo, map, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';

import {
  AUTHENTICATE_USER,
  USER_AUTHENTICATION_SUCCESS, UPDATE_PROFILE_RELOGIN,


} from 'constants/actionTypes';
import { ERROR, SHOW_NOTIFICATION } from 'constants/actionTypes/notification';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';
import {
  USER_SET_MFA_REQUIRED,
  USER_LOGIN_MFA,
  USER_SET_MFA_MISSING,
  USER_SET_MFA_SUCCESS
} from 'constants/actionTypes/user';
import { LOGIN_COMPLETE, LOGIN_FAILURE, LOGIN, LOGIN_SUCCESS } from 'constants/actionTypes/login';

import apiCatchError from '../helper/notification';

export const reloginAfterPasswordChange = action$ => action$.pipe(
  ofType(UPDATE_PROFILE_RELOGIN),
  switchMap(({ value: { username, password } }) => {
    // do login side effect here
    if (username && password) {
      return ajax.post('/api/login', {
        username, password,
      }).pipe(
        map(({ response }) => ({
          type: LOGIN_SUCCESS,
          value: response,
        })),
        apiCatchError(LOGIN_FAILURE, 'Invalid login credential.'),
      );
    }
    return of({
      type: LOGIN_FAILURE,
      value: 'Invalid credentials.',
    });
  }),
);

export const loginUser = action$ => action$.pipe(
  ofType(LOGIN),
  switchMap(({ value: { username, password } }) => {
    // do login side effect here
    if (username && password) {
      return ajax.post('/api/login', {
        username, password,
      }).pipe(
        map(({ response }) => (
          {
            type: response?.development
              ? LOGIN_SUCCESS
              : response?.mfa ? USER_SET_MFA_REQUIRED : USER_SET_MFA_MISSING,
            value: response,
          })),
        apiCatchError(LOGIN_FAILURE, 'Invalid login credential.'),
      );
    }
    return of({
      type: LOGIN_FAILURE,
      value: 'Invalid credentials.',
    });
  }),
);

export const loginAfterMfaEnabled = action$ => action$.pipe(
  ofType(USER_SET_MFA_MISSING),
  mergeMap(({ value }) => action$.pipe(
    ofType(USER_SET_MFA_SUCCESS),
    map(() => ({
      type: LOGIN_SUCCESS,
      value,
    })),
    takeUntil(action$.pipe(ofType(LOGIN_SUCCESS))),
  )),
);

export const verifyLoginWithMfa = action$ => action$.pipe(
  ofType(USER_SET_MFA_REQUIRED),
  mergeMap(({ value }) => action$.pipe(
    ofType(USER_LOGIN_MFA),
    switchMap(({ value: { code, secret } }) => ajax.post('/api/verify-mfa', {
      code, secret,
    }, { 'Content-Type': 'application/json' }).pipe(
      map(() => ({
        type: LOGIN_SUCCESS,
        value,
      })),
      apiCatchError(LOGIN_FAILURE, 'Invalid authentication token entered.'),
    )),
    takeUntil(action$.pipe(ofType(LOGIN_SUCCESS))),
  )),
);

export const authenticateUser = action$ => action$.pipe(
  ofType(AUTHENTICATE_USER),
  switchMap(({ value }) => {
    const [username, password] = value;
    if (username && password) {
      return ajax.post('/api/login', {
        username, password,
      }).pipe(
        map(({ response }) => ({
          type: USER_AUTHENTICATION_SUCCESS,
          value: response,
        })),
        apiCatchError(ERROR, 'Invalid login credential.'),
      );
    }
    return of({
      type: SHOW_NOTIFICATION,
      value: {
        message: 'Invalid login credential.',
        variant: ERROR,
      },
    });
  }),
);

export const triggerPublicationSelection = action$ => action$.pipe(
  ofType(LOGIN_SUCCESS),
  mergeMap(() => action$.pipe(ofType(PUBLICATION_SELECTED))),
  mapTo({
    type: LOGIN_COMPLETE,
  }),
);
