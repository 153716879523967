import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  EVENT_DISPOSE,
  EVENT_FETCH,
  EVENT_FETCH_SUCCESS,
  EVENT_INIT,
  EVENT_SAVE_SUCCESS,
} from '../../constants/actionTypes/event';
import {
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_SERVER_SET_DATA,
} from '../../constants/actionTypes/dataState';
import EventEntity from '../../entities/EventEntity';

export const clearServerStateOnFetch = action$ => action$.pipe(
  ofType(EVENT_FETCH),
  map(() => ({ type: DATASTATE_SERVER_DISPOSE_DATA })),
);

export const setServerStateOnSuccess = (action$, state$) => action$.pipe(
  ofType(EVENT_FETCH_SUCCESS),
  withLatestFrom(state$),
  filter(([,
    { router: { location: { pathname } } },
  ]) => /\/event\/[a-z_]+\/([a-z0-9]+)/.test(pathname)),
  mergeMap(([
    { value },
  ]) => {
    const entity = new EventEntity();
    const data = entity.getDataFromPayload(value);
    return from([
      { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: data },
    ]);
  }),
);

export const disposeState = action$ => action$.pipe(
  ofType(EVENT_INIT, EVENT_DISPOSE),
  mergeMap(() => from([
    { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
    { type: DATASTATE_SERVER_DISPOSE_DATA },
  ])),
);

export const handleSaveSuccess = action$ => action$.pipe(
  ofType(EVENT_SAVE_SUCCESS),
  mergeMap(({ value }) => {
    const entity = new EventEntity();
    const data = entity.getDataFromPayload(value);
    return from([
      { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: data },
    ]);
  }),
);
