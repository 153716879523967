import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_BOOKING } from './defaults';

const renderElementBooking = (options) => {
  const { booking } = setDefaults(options, DEFAULTS_BOOKING);
  return getRenderElement(booking);
};

export default renderElementBooking;
