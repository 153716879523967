import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '../ui/TextField';
import ImageSelector from '../ui/media/ImageSelector';
import TextButton from '../ui/buttons/TextButton';
import { saveAdReplacement, updateAdReplacementProperty } from '../../actions/ad';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
});

const AdReplacementForm = ({
  classes, data,
  saveAdReplacement: onSave,
  updateAdReplacementProperty: updateProp,
}) => {
  return (<Grid className={classes.root} container spacing={2}>
    <Grid item xs={12}>
      <TextField
        margin="none"
        value={data.name || ''}
        onChange={e => updateProp({ id: data.id, key: 'name', data: e.target.value })} />
    </Grid>
    <Grid item xs={12}>
      <ImageSelector
        label="MPU"
        selectImage={imageData => updateProp({ id: data.id, key: 'MPU', data: { type: 'image', data: imageData } })}
        onRemove={() => updateProp({ id: data.id, key: 'MPU', data: null })}
        image={data.MPU?.data}
      />
    </Grid>
    <Grid item xs={12}>
      <ImageSelector
        label="DMPU"
        selectImage={imageData => updateProp({ id: data.id, key: 'DMPU', data: { type: 'image', data: imageData } })}
        onRemove={() => updateProp({ id: data.id, key: 'DMPU', data: null })}
        image={data.DMPU?.data}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        margin="none"
        value={data.link || ''}
        label="Link"
        onChange={(e) => updateProp({ id: data.id, key: 'link', data: e.target.value })}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        margin="none"
        value={data.category || ''}
        label="Category"
        onChange={(e) => updateProp({ id: data.id, key: 'category', data: e.target.value })}
      />
    </Grid>
    <Grid item container direction="row-reverse" xs={12}>
      <Grid item>
        <TextButton size="large" onClick={() => onSave(data)}>Submit</TextButton>
      </Grid>
    </Grid>
  </Grid>);
};

AdReplacementForm.defaultProps = {
};

AdReplacementForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  saveAdReplacement: PropTypes.func.isRequired,
  updateAdReplacementProperty: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({ }),
  { saveAdReplacement, updateAdReplacementProperty },
)(AdReplacementForm));

