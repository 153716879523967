import {
  RESET_PASSWORD_INPUT_CHANGE, USER_UPDATE_PASSWORD,
  RESET_PASSWORD_INIT, RESET_PASSWORD_FAILED } from '../constants/actionTypes';

export const onChange = (input, value) => ({type: RESET_PASSWORD_INPUT_CHANGE, value, input});
export const onSubmit = (e) => {
  e.preventDefault();
  return ({type: USER_UPDATE_PASSWORD})
}
export const onError = (value) => ({type: RESET_PASSWORD_FAILED, value});
export const onInit  = (input, value) => ({type: RESET_PASSWORD_INIT, value, input});


