import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const style = {
  container: {
    width: '100%',
    height: '100vh',
  },
  iframe: {
    border: '0 none',
    width: '100vw',
    height: '100vw',
  },
};

const Preview = (props) => {
  const {
    classes,
    match: { params: { pid } },
    selectedPublication,
  } = props;
  return (
    <div className={classes.container}>
      <iframe className={classes.iframe} title={'preview'} src={`${selectedPublication.environment.web}preview/${pid}`} />
    </div>
  );
};

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  selectedPublication: PropTypes.object.isRequired,
};

export default withStyles(style)(connect(
  ({ frame: { selectedPublication } }) => ({ selectedPublication }),
  {},
)(Preview));
