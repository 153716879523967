import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FIELD_PRODUCTS } from '../../constants/article/articleFields';

import { getArrayFromStates } from '../../utils/stateHelperDepricated';
import { setContext } from '../../actions/page';

import ContextBar, { ContextBarHeader, ContextClose } from '../common/context/ContextBar';
import ProductDetails from './productSidebar/ProductDetails';
import ProductPrice from './productSidebar/ProductPrice';
import ProductAffiliate from './productSidebar/ProductAffiliate';

import { CONTEXT_DEFAULT } from '../../constants/contexts';

const ProductSidebar = (props) => {
  const {
    index,
    localProducts,
    serverProducts,
    externalProducts,
    setContext: setSidebarContext,
  } = props;
  const products = getArrayFromStates(serverProducts, localProducts, externalProducts);
  return (
    <ContextBar>
      <ContextBarHeader>
        <ContextClose onClick={() => setSidebarContext(CONTEXT_DEFAULT)} />
      </ContextBarHeader>
      <ProductDetails product={products[index] || {}} index={index} />
      <ProductPrice product={products[index] || {}} index={index} />
      <ProductAffiliate product={products[index] || {}} index={index} />
    </ContextBar>
  );
};

ProductSidebar.propTypes = {
  setContext: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  localProducts: PropTypes.array,
  serverProducts: PropTypes.array,
  externalProducts: PropTypes.array,
};

ProductSidebar.defaultProps = {
  localProducts: [],
  serverProducts: [],
  externalProducts: [],
};

export default connect(
  ({
    frame: { context: { data: index } },
    localState: { [FIELD_PRODUCTS]: localProducts },
    serverState: { [FIELD_PRODUCTS]: serverProducts },
    externalState: { [FIELD_PRODUCTS]: externalProducts },
  }) => ({ index, localProducts, serverProducts, externalProducts }),
  { setContext },
)(ProductSidebar);
