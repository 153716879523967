import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Dialog from './common/Dialog';
import LiveblogPostSelector from '../liveblog/LiveblogPostSelector';
import AutoCompleteGeneric from '../autocomplete/AutoComplete';
import { searchArticle } from '../../actions/article';
import Button from '../ui/buttons/Button';
import TextField from '../ui/TextField';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minHeight: '60vh',
  },
  compRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&> div': {
      width: '100%',
    },
  },
  externalItemRoot: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
  },
});

const TAB_BLOGPOST = 'blogpost';
const TAB_MANUAL = 'manual';
const TAB_ARTICLE = 'article';

const TABS = [
  { label: 'Blogpost', value: TAB_BLOGPOST },
  { label: 'Manual url', value: TAB_MANUAL },
  { label: 'Article', value: TAB_ARTICLE },
];

const KeyPointDialog = (props) => {
  const {
    classes,
    articles,
    onSelect,
    onSearchArticle: onChange,
    path,
    ...rest
  } = props;
  const [pathVal, setPath] = useState(path);
  const [tab, setTab] = useState(path ? TAB_MANUAL : TAB_BLOGPOST);
  return (
    <Dialog
      maxWidth={'md'}
      tabs={TABS}
      tab={tab}
      setTab={setTab}
      {...rest}
    >
      <Grid className={classes.root} spacing={2}>
        {tab === TAB_ARTICLE && <Grid item xs={12}>
          <AutoCompleteGeneric
            placeholder={'Search for an article'}
            items={articles}
            onSelect={({ url }) => {
              onSelect(url);
            }}
            onChange={onChange}
          />
        </Grid>}
        {tab === TAB_BLOGPOST && <Grid item xs={12}>
          <LiveblogPostSelector
            onSelect={(post) => {
              onSelect(`#post-${post.data.id}`);
            }}
          />
        </Grid>}
        {tab === TAB_MANUAL && <Grid item xs={12}>
          <TextField
            id={'Path'}
            label={'Path'}
            value={pathVal || ''}
            onChange={e => setPath(e.target.value)}
          />
          <Grid container justify={'flex-end'}>
            <Button
              disabled={!pathVal}
              onClick={() => {
                onSelect(pathVal);
              }}
              startIcon={<AddIcon />}
            >Add path</Button>
          </Grid>
        </Grid>}
      </Grid>
    </Dialog>
  );
};

KeyPointDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onSearchArticle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  articles: PropTypes.array,
  path: PropTypes.string,
};

KeyPointDialog.defaultProps = {
  articles: [],
  path: null,
};

export default withStyles(style)(connect(
  ({ autocomplete: { articles } }) => ({ articles }),
  { onSearchArticle: searchArticle },
)(KeyPointDialog));
