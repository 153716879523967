import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_DEFAULT,
  CONTENT_SOURCE_OPTION_ITEMS,
  CONTENT_SOURCE_MANUAL, CONTENT_SOURCE_CUSTOM,
} from '../../constants';

import { setLayoutContext, componentSetProp } from '../../../../actions/layout';
import { CONTEXT_ARTICLE_SELECTOR, CONTEXT_COMPONENT } from '../../../../constants/contexts';
import ManualListSelector from './ManualListSelector';
import CustomListSelector from './CustomListSelector';

import Select from '../../../ui/Select';

const styles = () => ({
  select: {
    marginTop: '1px',
    marginBottom: '-1px',
    fontSize: '0.9rem',
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
});

const ArticleSourceField = (props) => {
  const {
    component, classes,
    componentSetProp: setProp,
    setLayoutContext: setContext,
  } = props;
  const source = component[CONTENT_SOURCE] || CONTENT_SOURCE_DEFAULT;
  return (
    <>
      <FormControl>
        <Select
          value={source}
          onChange={(e) => {
            setProp(component.id, CONTENT_SOURCE, e.target.value);
            if (e.target.value === CONTENT_SOURCE_MANUAL) {
              setContext(CONTEXT_ARTICLE_SELECTOR);
            } else {
              setContext(CONTEXT_COMPONENT, component);
            }
          }}
          className={classes.select}
          items={CONTENT_SOURCE_OPTION_ITEMS}
        />
      </FormControl>
      {source === CONTENT_SOURCE_MANUAL &&
      <ManualListSelector component={component} />
      }
      {source === CONTENT_SOURCE_CUSTOM &&
      <CustomListSelector component={component} />
      }
    </>
  );
};

ArticleSourceField.propTypes = {
  component: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { setLayoutContext, componentSetProp },
)(ArticleSourceField));
