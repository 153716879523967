import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Close';

import IconButton from '../../ui/buttons/IconButton';
import MarkUp from './MarkUp';

const styles = {
  options: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

const Blockquote = props => (
  <Grid container>
    <MarkUp
      multiMarkup
      extraFields={[
        { key: 'cite', label: 'Citation', position: 'end' },
      ]}
      {...props}
    />
    {props.liveblog && <IconButton
      className={props.classes.options}
      onClick={() => props.removeArticleComponent(props.position)}
    ><RemoveIcon /></IconButton>}
  </Grid>
);

Blockquote.defaultProps = {
  liveblog: false,
};

Blockquote.propTypes = {
  classes: PropTypes.object.isRequired,
  removeArticleComponent: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  liveblog: PropTypes.bool,
};

export default withStyles(styles)(Blockquote);

