import { API_FIELD_WORKFLOW, FIELD_NAME, FIELD_WORKFLOW } from '../common/commonFields';

export const FIELD_IMAGES = 'images';
export const FIELD_IMAGES_ORDER = 'imagesOrder';
export const API_FIELD_IMAGES = 'field_gallery_json';

export const GALLERY_PAYLOAD_FIELDS = [
  FIELD_NAME,
  FIELD_WORKFLOW,
  FIELD_IMAGES,
];

export const GALLERY_FIELD_MAPPING = [
  [FIELD_NAME, FIELD_NAME, 'Name'],
  [FIELD_WORKFLOW, API_FIELD_WORKFLOW, 'Workflow'],
  [FIELD_IMAGES, API_FIELD_IMAGES, 'Images'],
];
