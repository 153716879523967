import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_IMAGE } from './defaults';

const deserializeImage = (options) => {
  const { image } = setDefaults(options, DEFAULTS_IMAGE);

  return {
    element: getNodeDeserializer({
      type: image.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: image.type,
          url,
        };
      },
      rules: [
        { className: image.rootProps.className },
      ],
      ...options?.image?.deserialize,
    }),
  };
};

export default deserializeImage;
