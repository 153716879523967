import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, fade } from '@material-ui/core/styles';
import IconButtonBase from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { noop } from 'rxjs';

const FADE = 0.3;

const styles = theme => ({
  root: {
    padding: '0.25em',
  },
  variant: {
    padding: '6px',
    minWidth: '0',
  },
  primaryActive: {
    backgroundColor: fade(theme.palette.primary.main, FADE),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, FADE),
    },
  },
  secondaryActive: {
    backgroundColor: fade(theme.palette.secondary.main, FADE),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.main, FADE),
    },
  },
  defaultActive: {
    backgroundColor: fade(theme.typography.color.primary, FADE),
    '&:hover': {
      backgroundColor: fade(theme.typography.color.primary, FADE),
    },
  },
});

const IconButton = (props) => {
  const { classes, className, variant, children, active, color, ...rest } = props;
  const btnClass = [];
  if (className) {
    btnClass.push(className);
  }
  if (active) {
    btnClass.push(classes[`${color}Active`]);
  }
  if (variant !== null) {
    btnClass.push(classes.variant);
    return (
      <Button
        variant={variant}
        className={btnClass.join(' ')}
        color={color}
        aria-label={props['aria-label']}
        {...rest}
      >{children}</Button>
    );
  }
  btnClass.push(classes.root);
  return (
    <IconButtonBase
      className={btnClass.join(' ')}
      aria-label={props['aria-label']}
      color={color}
      {...rest}
    >{children}</IconButtonBase>
  );
};

IconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  active: PropTypes.bool,
  'aria-label': PropTypes.string,
};

IconButton.defaultProps = {
  className: null,
  color: 'primary',
  variant: null,
  'aria-label': null,
  onClick: noop,
  active: false,
};

export default withStyles(styles)(IconButton);
