import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_CUSTOM,
  CONTENT_SOURCE_CUSTOM_SECTION,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
} from '../components/layout/constants';
import { FIELD_OVERRIDES, OVERRIDE_HERO } from '../constants/layout/layout';

/**
 * @file
 * Data Sanitise functions to avoid logic data being added to redux stores
 */

/**
 * Sanitise a term object for safe data to store
 * @param   {object} term
 * @return  {{path: *, name: *, id: *}}
 */
export const sanitiseTerm = (term) => {
  const { name, path, id } = term;
  return ({
    name,
    path,
    id,
  });
};

/**
 * Sanitise a Layout Component
 * @param   {object} component
 * @return  {object}
 */
export const sanitiseLayoutComponent = (component) => {
  const sanitised = { ...component };
  delete sanitised.name; // the name is replaced in the CMS so this data is useless
  delete sanitised.manualListIndex;
  delete sanitised.manualArticleIndexes;
  switch (sanitised[CONTENT_SOURCE]) {
    case CONTENT_SOURCE_MANUAL:
      delete sanitised[CONTENT_SOURCE_CUSTOM_SECTION];
      break;
    case CONTENT_SOURCE_CUSTOM:
      delete sanitised[CONTENT_SOURCE_MANUAL_LIST_SECTION];
      break;
    default:
      delete sanitised[CONTENT_SOURCE_CUSTOM_SECTION];
      delete sanitised[CONTENT_SOURCE_MANUAL_LIST_SECTION];
      break;
  }
  return sanitised;
};

/**
 * Sanitise a layout article
 * @param  {object} article
 * @return {object}
 */
export const sanitiseLayoutArticle = (article) => {
  const sanitised = { ...article };
  if (sanitised?.[FIELD_OVERRIDES]?.[OVERRIDE_HERO] === null) {
    delete sanitised[FIELD_OVERRIDES][OVERRIDE_HERO];
  }
  return sanitised;
};

/**
 * Sanitise a Dugout Video
 * @param   {object} video
 * @return  {{duration, embed_hash, isodate, link, description, mediaid, title}}
 */
export const sanitiseVideoDugout = ({
  mediaid, title, description, duration, link, isodate,
  // eslint-disable-next-line camelcase
  embed_hash, publisher_name, publisher_logo, competition, player,
}) => ({
  mediaid,
  title,
  description,
  duration,
  link,
  isodate,
  embed_hash,
  publisher_name,
  publisher_logo,
  competition,
  player,
});
