import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import Select from '../../../ui/Select';
import ImageField from './common/ImageField';
import { FieldWrapper } from './common/GridWrapper';

const PROMPT_LABEL = 'Prompt';
const ANSWER_LABEL = 'Correct Answer';
const OPT1_LABEL = 'Option 1';
const OPT2_LABEL = 'Option 2';
const OPT3_LABEL = 'Option 3';
const OPT4_LABEL = 'Option 4';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA';

const ANSWER_OPTIONS = {
  1: OPT1_LABEL,
  2: OPT2_LABEL,
  3: OPT3_LABEL,
  4: OPT4_LABEL,
};

const TemplateQuiz = (props) => {
  const {
    id, prompt, answer, image, options, attachment, attachmentCTA,
    updateStoryPageProp: updateProp,
  } = props;
  while (options.length < 4) {
    options.push('');
  }
  // @todo rework with loop for options
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={'Add cover image'}
          onSelect={value => updateProp(id, 'image', {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateProp(id, 'image', null)}
          image={image}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={PROMPT_LABEL}
          value={prompt}
          onChange={e => updateProp(id, 'prompt', e.target.value)}
        />
        <TextField
          label={OPT1_LABEL}
          value={options[0]}
          onChange={e => updateProp(id, 'options', [
            e.target.value,
            options[1],
            options[2],
            options[3],
          ])}
        />
        <TextField
          label={OPT2_LABEL}
          value={options[1]}
          onChange={e => updateProp(id, 'options', [
            options[0],
            e.target.value,
            options[2],
            options[3],
          ])}
        />
        <TextField
          label={OPT3_LABEL}
          value={options[2]}
          onChange={e => updateProp(id, 'options', [
            options[0],
            options[1],
            e.target.value,
            options[3],
          ])}
        />
        <TextField
          label={OPT4_LABEL}
          value={options[3]}
          onChange={e => updateProp(id, 'options', [
            options[0],
            options[1],
            options[2],
            e.target.value,
          ])}
        />
        <Select
          label={ANSWER_LABEL}
          value={answer}
          onChange={e => updateProp(id, 'answer', e.target.value)}
          items={ANSWER_OPTIONS}
          margin={'normal'}
        />
        <TextField
          label={ATTACHMENT_LABEL}
          placeholder={ATTACHMENT_PLACEHOLDER}
          value={attachment}
          onChange={e => updateProp(id, 'attachment', e.target.value)}
        />
        <TextField
          label={ATTACHMENT_CTA_LABEL}
          placeholder={ATTACHMENT_CTA_PLACEHOLDER}
          value={attachmentCTA}
          onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
        />
      </FieldWrapper>
    </Grid>
  );
};

TemplateQuiz.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  answer: PropTypes.string,
  options: PropTypes.array,
  image: PropTypes.object,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplateQuiz.defaultProps = {
  prompt: '',
  options: [],
  image: null,
  answer: '1',
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplateQuiz);
