import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/KeyboardArrowRight';

import Dialog from './common/Dialog';
import TextField from '../ui/TextField';
import ToggleSwitch from '../ui/ToggleSwitch';
import Accordion from '../ui/Accordion';
import Button from '../ui/buttons/Button';

import { editUser, saveUser, sendPassword, setSelectedUser } from '../../actions/users';
import { ROLES } from '../../constants/user/user';

const toggleRole = (checked, role, roles) => {
  if (checked) {
    return [
      ...roles,
      role,
    ];
  }
  return roles.filter(r => r !== role);
};

const styles = () => ({
  accordion: {
    '&:last-child': {
      borderRadius: 0,
    },
    '&:first-child': {
      borderRadius: 0,
    },
  },
});

const UserEditDialog = (props) => {
  const {
    publication, errorMessage, submittingForm, onSave, classes,
    selectedUser: user,
    setSelectedUser: setUser,
    editUser: doEdit,
    saveUser: doSave,
    sendPassword: resendPassword,
    ...rest
  } = props;
  const [availablePublications, setPublications] = useState([]);
  const [invalidPublications, setInvalidPublications] = useState(true);
  const [invalidRoles, setInvalidRoles] = useState(true);
  const [invalidForm, setInvalidForm] = useState(true);
  const checks = [invalidPublications, invalidRoles];
  useEffect(() => {
    const publicationIds = new Set(user.publications.map(({ id }) => id));
    setPublications(publication.filter(({ id }) => !publicationIds.has(id)));
  }, [publication, user.publications]);
  useEffect(() => {
    setInvalidPublications(!Array.isArray(user.publications) || user.publications.length < 1);
  }, [user.publications]);
  useEffect(() => {
    setInvalidRoles(!Array.isArray(user.roles) || user.roles.length < 1);
  }, [user.roles]);
  useEffect(() => setInvalidForm(checks.some(Boolean)), checks);
  return (
    <Dialog
      title={user.uid ? 'Edit user' : 'Add new user'}
      fullScreen={false}
      maxWidth={'sm'}
      margin
      {...rest}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          doSave(onSave);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={'Username'}
              value={user.name || ''}
              onChange={e => doEdit('name', e.target.value)}
              margin={'none'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Email'}
              value={user.mail || ''}
              onChange={e => doEdit('mail', e.target.value)}
              margin={'none'}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h3'}>Roles</Typography>
            {ROLES.map(({ name, displayName }) => (
              <ToggleSwitch
                key={name}
                label={displayName}
                labelPosition={'end'}
                value={user.roles && user.roles.indexOf(name) !== -1}
                onChange={e => doEdit('roles', toggleRole(e.target.checked, name, user.roles || []))}
                compact
              />
            ))}
            {invalidRoles && <Grid item xs={12}>
              <Typography variant={'body2'} color={'error'}>Please select a role.</Typography>
            </Grid>}
          </Grid>
          {user.uid && <Grid item xs={12}>
            <Typography variant={'h3'}>Status</Typography>
            <ToggleSwitch
              label={'Active'}
              value={user.status === '1'}
              labelPosition={'end'}
              onChange={e => doEdit('status', e.target.checked ? '1' : '0')}
              compact
            />
          </Grid>}
          <Grid item xs={12}>
            <Accordion
              title={'Publications'}
              className={classes.accordion}
            >
              <Grid container spacing={2} justify={'center'}>
                <Grid item xs={6}>
                  <Typography variant={'h4'}>Available</Typography>
                  <List dense>
                    {availablePublications.length > 1 && <ListItem
                      role={'listitem'}
                      button
                      onClick={() => {
                        doEdit('publications', publication);
                      }}
                    >
                      <ListItemText primary={'Add all'} />
                      <AddIcon />
                    </ListItem>}
                    {availablePublications.map(pub => (
                      <ListItem
                        key={pub.name}
                        role={'listitem'}
                        button
                        onClick={() => {
                          doEdit('publications', user.publications.concat([pub]).sort((a, b) => a.name.localeCompare(b.name)));
                        }}
                      >
                        <ListItemText primary={pub.name} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant={'h4'}>Assigned</Typography>
                  <List dense>
                    {user.publications && user.publications.map(pub => (
                      <ListItem
                        key={pub.name}
                        role={'listitem'}
                        button
                        onClick={() => {
                          doEdit('publications', user.publications.filter(p => p !== pub));
                        }}
                      >
                        <ListItemText primary={pub.name} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Accordion>
            {invalidPublications && <Grid item xs={12}>
              <Typography variant={'body2'} color={'error'}>Please select a publication.</Typography>
            </Grid>}
          </Grid>
          {errorMessage && <Grid item xs={12}>
            <Typography variant={'body2'} color={'error'}>
              {errorMessage}
            </Typography>
          </Grid>}
          <Grid item xs={12}>
            <Grid container justify={'flex-end'}>
              {user.uid && <Button onClick={resendPassword}>Resend password</Button>}
              <Button
                disabled={submittingForm || invalidForm}
                variant={'contained'}
                type={'submit'}
              >{user.uid ? 'Update user' : 'Add new user'}</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

UserEditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object,
  errorMessage: PropTypes.string,
  submittingForm: PropTypes.bool,
  publication: PropTypes.array,
  onSave: PropTypes.func,
  setSelectedUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  sendPassword: PropTypes.func.isRequired,
};

UserEditDialog.defaultProps = {
  selectedUser: {},
  errorMessage: '',
  submittingForm: false,
  publication: [],
  onSave: null,
};

export default withStyles(styles)(connect(
  ({
    frame: { publication },
    userEdit: { selectedUser, errorMessage, submittingForm },
  }) => ({ publication, selectedUser, errorMessage, submittingForm }),
  { setSelectedUser, editUser, saveUser, sendPassword },
)(UserEditDialog));
