import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  MARKUP,
} from '../../constants';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import TextField from '../../../ui/TextField';

const MARKUP_LABEL = 'Markup';

const MarkupSettings = (props) => {
  const { component, title, componentPropOnChange } = props;
  return (
    <ContextPanel>
      {title && <ContextPanelSummary>
        <ContextPanelHeading>{title}</ContextPanelHeading>
      </ContextPanelSummary>}
      <ContextPanelDetails variant={'full'}>
        <TextField
          label={title ? '' : MARKUP_LABEL}
          placeholder={''}
          value={component[MARKUP]}
          multiline
          rows={15}
          ignoreSmartQuote
          onChange={e => componentPropOnChange(component.id, MARKUP, e.target.value)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

MarkupSettings.propTypes = {
  title: PropTypes.string,
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

MarkupSettings.defaultProps = {
  title: '',
};

export default connect(
  () => ({}),
  { },
)(MarkupSettings);
