import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { fetchArticle, disposeFetchArticle } from '../../actions/article';
import DashboardCard, { DashboardCardHeader, DashboardCardContent } from '../common/dashboard/DashboardCard';
import { Table, TableBody, TableRow, TableCell } from '../ui/table/Table';
import ThumbnailImage from '../table/content/ThumbnailImage';
import { formatTimeAgo } from '../../utils/dateHelper';

const RecentArticles = (props) => {
  const {
    articles, limit, selectedPublication,
    fetchArticle: onFetchArticle,
    disposeFetchArticle: onDisposeFetchArticle,
    push: goto,
  } = props;

  useEffect(() => {
    if (selectedPublication.name) {
      onFetchArticle({
        limit,
        offset: 0,
        q: '',
      });
    }
    return () => {
      onDisposeFetchArticle();
    };
  }, [selectedPublication, onFetchArticle, onDisposeFetchArticle]);
  return (
    <DashboardCard>
      <DashboardCardHeader
        title={'Recently updated articles'}
      />
      <DashboardCardContent noPadding>
        {articles.length > 0 && <Table>
          <TableBody>
            {articles.slice(0, limit).map(({ titleShort, id, hero, updated }) => (
              <TableRow
                key={id}
                onClick={() => goto(`/article/edit/${id}`)}
              >
                <TableCell narrow><ThumbnailImage hero={hero} size={'small'} /></TableCell>
                <TableCell narrow>{titleShort}</TableCell>
                <TableCell narrow noWrap>{formatTimeAgo(updated)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
      </DashboardCardContent>
    </DashboardCard>
  );
};

RecentArticles.propTypes = {
  articles: PropTypes.array,
  limit: PropTypes.number,
  selectedPublication: PropTypes.object.isRequired,
  fetchArticle: PropTypes.func.isRequired,
  disposeFetchArticle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

RecentArticles.defaultProps = {
  articles: [],
  limit: 5,
};

export default connect(
  ({
    frame: { selectedPublication },
    article: { articles, totalArticle },
  }) =>
    ({ articles, totalArticle, selectedPublication }),
  { push, fetchArticle, disposeFetchArticle },
)(RecentArticles);
