import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    minHeight: '14px',
  },
  ol: {
    flexWrap: 'nowrap',
  },
  link: {
    ...theme.typography.h4,
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

const Breadcrumb = (props) => {
  const { classes, breadcrumb } = props;
  let path = '';
  const lastCrumb = breadcrumb[breadcrumb.length - 1];
  return (
    <div className={classes.root}>
      <Breadcrumbs separator="›" classes={{ root: classes.breadcrumbs, ol: classes.ol }} aria-label="breadcrumb">
        {breadcrumb.map((crumb, index) => {
          if (index !== breadcrumb.length - 1) {
            path += `/${crumb.slug}`;
            return <Link className={classes.link} key={path} color={'inherit'} to={path}>{crumb.title}</Link>;
          }
        })}
      </Breadcrumbs>
      {typeof lastCrumb !== 'undefined' && <Typography variant={'h1'} aria-current={'page'}>{lastCrumb.title}</Typography>}
    </div>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumb: PropTypes.array.isRequired,
};

export default withStyles(styles)(Breadcrumb);
