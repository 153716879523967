import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_RELATED_ARTICLES } from './defaults';

const renderElementRelatedArticles = (options) => {
  const { relatedArticles } = setDefaults(options, DEFAULTS_RELATED_ARTICLES);
  return getRenderElement(relatedArticles);
};

export default renderElementRelatedArticles;
