import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FilterListIcon from '@material-ui/icons/FilterList';

const ListFilterInput = ({ onChange, label, name, activeVocab }) => {
  const input = useRef();

  useEffect(() => {
    if (input.current && activeVocab) {
      input.current.focus();
    }
  }, [input, activeVocab]);

  return (
    <FormControl>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input
        inputRef={input}
        aria-label={label}
        id={name}
        type={'text'}
        onChange={onChange}
        autoComplete={'off'}
        endAdornment={
          <InputAdornment position={'end'}>
            <FilterListIcon />
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

ListFilterInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  activeVocab: PropTypes.string,
};

ListFilterInput.defaultProps = {
  activeVocab: null,
  label: 'Filter',
  name: 'filter-table',
};

export default ListFilterInput;
