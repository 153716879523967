import FaqElement from './components/FaqElement';

export const ELEMENT_FAQ = 'faq';
export const PROP_TITLE = 'title';
export const PROP_SUBHEADING = 'subHeadline';
export const PROP_MARKUP = 'markup';
export const PROP_ALIGN = 'align';

export const DEFAULTS_FAQ = {
  faq: {
    component: FaqElement,
    type: ELEMENT_FAQ,
    rootProps: {
      className: 'slate-infobox',
    },
  },
};
