import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AccessVocab = ({ vocabs, vocab, children }) => (<React.Fragment>
  {vocabs.includes(vocab) && children}
</React.Fragment>);

AccessVocab.defaultProps = {
  vocabs: [],
};

AccessVocab.propTypes = {
  vocabs: PropTypes.array,
  children: PropTypes.node.isRequired,
  vocab: PropTypes.string.isRequired,
};

export default connect((
  { frame: { selectedPublication: { vocabs } } },
) => ({ vocabs }), {})(AccessVocab);
