import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


import Menu from '@material-ui/core/Menu';
import MoreVert from '@material-ui/icons/MoreVert';

import { TableRow, TableHeadCell, TableHead, TableSortLabel } from '../ui/table/Table';
import IconButton from '../ui/buttons/IconButton';

import { setOrderBy } from '../../actions/table';

const TableHeader = ({
  headers,
  actions,
  order,
  orderBy,
  setOrderBy: onSetOrderBy,
  hasBorder,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <TableHead>
      <TableRow hasBorder={hasBorder}>
        {headers.map(({ id, label, align, orderable }, i, arr) => (
          <TableHeadCell
            key={id}
            align={align || (actions && arr.length - 1 === i) ? 'right' : 'left'}
            sortDirection={orderBy === id ? order : false}
          >
            {order && orderable && <TableSortLabel
              active={orderBy === id}
              direction={order}
              onClick={() => onSetOrderBy(id)}
            >
              {label}
            </TableSortLabel>}
            {(!order || !orderable) && <span>{label}</span>}
            {actions && arr.length - 1 === i && <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreVert />
            </IconButton>}
          </TableHeadCell>
        ))}
        {actions && <Menu
          id={'action-menu'}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {actions}
        </Menu>}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  setOrderBy: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  actions: PropTypes.node,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  hasBorder: PropTypes.bool,
};

TableHeader.defaultProps = {
  order: null,
  orderBy: null,
  actions: null,
  hasBorder: false,
};

export default (connect(() => ({}), {
  setOrderBy,
})(TableHeader));
