import { FACEBOOK, INSTAGRAM, TWITTER, TIKTOK } from '../constants/editor/dataTypes';
import { isValidUrl as isFBValidUrl } from '../components/editor/plugins/elements/social/facebook/helper';
import { isValidUrl as isInstaValidUrl } from '../components/editor/plugins/elements/social/instagram/helper';
import { isValidUrl as isTwitterValidUrl } from '../components/editor/plugins/elements/social/twitter/helper';
import { isValidUrl as isTiktokValidUrl } from '../components/editor/plugins/elements/social/tiktok/helper';
import { VIDEO_YOUTUBE_LEGACY } from '../constants/media/media';
import generateKey from './generateKey';

const isSocialUrlValid = (type, url) => {
  switch (type) {
    case TIKTOK:
      return isTiktokValidUrl(url);
    case TWITTER:
      return isTwitterValidUrl(url);
    case INSTAGRAM:
      return isInstaValidUrl(url);
    case FACEBOOK:
      return isFBValidUrl(url);
    default:
      return false;
  }
};

export const containsInvalidSocialEmbedComponent = components =>
  components.some(({ type, data }) =>
    [FACEBOOK, TWITTER, INSTAGRAM, TIKTOK].includes(type) &&
    (!data?.url || !(data?.url && isSocialUrlValid(type, data.url))));

export const preprocessEditorComponents = body => body.map((comp) => {
  const component = comp;
  // legacy twitter embed (imported from api) has no url, make it
  if (component.type === TWITTER && component.data.id && !component.data.url) {
    component.data.url = `https://twitter.com/user/status/${comp.data.id}`;
  }
  if (component.type === VIDEO_YOUTUBE_LEGACY && component.data.id && !component.data.url) {
    component.data.url = `https://www.youtube.com/watch?v=${component.data.id}`;
  }
  return { ...component, id: component.id || generateKey() };
});
