import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { CMS_DATE_FORMAT } from '../../constants/common';

const style = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  title: {
    margin: '0',
    color: theme.palette.primary.main,
  },
  list: {
    '& .MuiListItem-gutters': {
      padding: 0,
    },
    '& $divider:last-of-type': {
      display: 'none',
    },
  },
  notifications: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const ViafouraNotificationList = (props) => {
  const {
    classes,
    users,
    viafouraLogs,
  } = props;

  if (viafouraLogs.length === 0) return null;

  return (
    <Grid item xs={12} className={classes.notifications} >
      <Divider className={classes.divider} />
      <Typography variant={'h2'} className={classes.title}>Notifications</Typography>
      <List className={classes.list}>
        {[...viafouraLogs].map((notification) => {
          const logUser = users.find(u => u.uid === notification.user.toString()) || {};
          return [
            <React.Fragment key={`${notification.time}`}>
              <ListItem alignItems={'flex-start'} className={classes.row}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <ListItemAvatar>
                        <Avatar alt={logUser.name} src={logUser.image} />
                      </ListItemAvatar>
                      <Grid item>
                        <Typography variant={'body1'}>{logUser.name}</Typography>
                        <Typography variant={'caption'}>{moment.unix(`${notification.time}`).format(
                          CMS_DATE_FORMAT)}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <ListItemText
                        primary={notification.data.title}
                        secondary={notification.name}
                      />
                      <Typography variant={'caption'}>{notification.data.trackingTag}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider component={'li'} className={classes.divider} />
            </React.Fragment>,
          ];
        })}
      </List>
    </Grid>
  );
};

ViafouraNotificationList.defaultProps = {
  viafouraLogs: [],
  users: [],
};

ViafouraNotificationList.propTypes = {
  classes: PropTypes.object.isRequired,
  viafouraLogs: PropTypes.array,
  users: PropTypes.array,
};

export default withStyles(style)(connect(({
  viafouraNotificationsList: { viafouraLogs },
  usersList: { users },
}) => ({
  viafouraLogs,
  users,
}), {
})(ViafouraNotificationList));
