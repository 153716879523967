import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dropZone: {
    width: '100%',
    border: `1px dashed ${theme.palette.border.secondary.dark}`,
    padding: '2px 5px',
    boxSizing: 'border-box',
    height: '50px',
    '&.disabled': {
      borderColor: theme.palette.border.secondary.main,
      margin: '5px',
      width: 'calc(25% - 10px)',
      '&:nth-child(-n+4)': {
        flexGrow: 1,
      },
    },
    '&.drop-over.drop-enabled': {
      borderColor: '#000',
      background: theme.palette.background.paleBlue,
    },
  },
});

export const DropZoneDisabled = withStyles(styles)(({ classes }) => (
  <div className={`${classes.dropZone} disabled`} />
));

// @todo check data requirements, should just be id
const DropZone = ({ classes, disabled, className, children, type, dropHandler, dataProcessor, data, ...rest }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: type,
    drop: (object, monitor) => ({
      handler: dropHandler,
      data: dataProcessor(object),
    }),
    canDrop: () => !disabled,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  const classNames = [classes.dropZone];
  if (isOver) {
    classNames.push('drop-over');
  }
  if (canDrop) {
    classNames.push('drop-enabled');
  } else {
    classNames.push('drop-disabled');
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')} ref={drop} {...rest}>{children}</div>
  );
};

DropZone.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  data: PropTypes.object,
  classes: PropTypes.object.isRequired,
  dropHandler: PropTypes.func.isRequired,
  dataProcessor: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
};

DropZone.defaultProps = {
  className: null,
  disabled: false,
  data: {},
};

export default withStyles(styles)(DropZone);
