import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import Dialog from '../common/Dialog';

import AutoCompleteGeneric from 'components/autocomplete/AutoComplete';
import Tag from 'components/ui/Tag';
import Button from 'components/ui/buttons/Button';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    flexBasis: '300px',
    minHeight: 300,
  },
  selectedTitle: {
    marginTop: theme.spacing(2),
  },
  selected: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
});

const AutocompleteDialog = ({
  classes,
  label,
  items,
  onSelect,
  onChange,
  handleClose,
  multiple,
  hierarchical,
  ...rest
}) => {
  const [selected, setSelected] = useState([]);
  const removeSelected = articleId => setSelected(selected.filter(({ id }) => id !== articleId));
  const addSelected = article => setSelected([...selected, article]);
  const handleAdd = (selectedItems) => {
    if (!multiple) {
      for (const selectedItem of selectedItems) {
        onSelect(selectedItem);
      }
    } else {
      onSelect(selectedItems);
    }
    handleClose();
  };
  return (
    <Dialog
      title={`Find ${label}`}
      maxWidth="md"
      fullScreen={false}
      handleClose={handleClose}
      {...rest}
    >
      <div className={classes.root}>
        <AutoCompleteGeneric
          placeholder={`Search ${label}`}
          items={items}
          onChange={onChange}
          onSelect={article => addSelected(article)}
          hierarchical={hierarchical}
        />
        {selected.length > 0 && <>
          <Typography variant="h3" color="primary" className={classes.selectedTitle}>Selected {label}</Typography>
          <div className={classes.selected}>
            {selected.map(({ label: title, id, name }) => (
              <Tag
                key={id}
                label={title || name}
                onRemove={() => removeSelected(id)}
                onClick={() => removeSelected(id)}
              />
            ))}
            <div className={classes.buttons}>
              <Button
                onClick={() => handleAdd(selected)}
              >Add {label}</Button>
            </div>
          </div>
        </>}
      </div>
    </Dialog>
  );
};

AutocompleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  hierarchical: PropTypes.bool,
};

AutocompleteDialog.defaultProps = {
  label: 'content',
  multiple: false,
  hierarchical: false,
};

export default withStyles(style)(AutocompleteDialog);
