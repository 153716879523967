import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.h2.fontSize,
    borderRadius: '100%',
  },
  buttonRoot: {
    padding: 0,
    minWidth: 'auto',
  },
  listRoot: {
    padding: 0,
    minWidth: 180,
    boxShadow: theme.customShadows.list,
  },
  textRoot: {
    minWidth: 80,
  },
  listItemDense: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    borderLeft: `4px solid ${theme.palette.background.contrast}`,
    '&:hover': {
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.background.hover,
    },
    '&.active': {
      borderLeft: '0 none',
      backgroundColor: theme.palette.background.paleBlue,
      '&:hover': {
        borderLeft: '0 none',
        backgroundColor: theme.palette.background.paleBlue,
      }
    },
    '&.Mui-selected': {
      borderLeft: '0 none',
      backgroundColor: theme.palette.background.paleBlue,
      '&:hover': {
        borderLeft: '0 none',
        backgroundColor: theme.palette.background.paleBlue,
      }
    },
  },
  menuRoot: {
    position: 'absolute',
    top: '100%',
    right: 0,
    display: 'none',
    backgroundColor: theme.palette.common.white,
  },
  showMenuRoot: {
    display: 'block',
  },
  navIconRoot: {
    minWidth: 40,
  },
}));

const Account = (props) => {
  const { user, push: routeChange } = props;
  const initial = user.name.split(' ').map(name => name.slice(0, 1));

  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(null);

  return (<ClickAwayListener onClickAway={() => setShowMenu(false)}>
    <div className={classes.root}>
      <Button
        aria-haspopup="true"
        color="inherit"
        onClick={() => setShowMenu(!showMenu)}
        classes={{
          root: classes.buttonRoot,
        }}
      >
        <Avatar variant="square" className={classes.avatar}>{initial}</Avatar>
      </Button>
      <div className={[classes.menuRoot, showMenu ? classes.showMenuRoot : ''].join(' ')}>
        <Grow in={showMenu}>
          <List
            component="nav"
            classes={{
              root: classes.listRoot,
            }}
            dense
          >
            <ListItem classes={{ dense: classes.listItemDense }}button onClick={() => routeChange('/profile')}>
              <ListItemIcon classes={{ root: classes.navIconRoot }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText classes={{ root: classes.textRoot }} primary={'Account'} />
            </ListItem>
            <ListItem classes={{ dense: classes.listItemDense }}button component="a" href="/logout">
              <ListItemIcon classes={{ root: classes.navIconRoot }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText classes={{ root: classes.textRoot }} primary={'Logout'} />
            </ListItem>
          </List>
        </Grow>
      </div>
    </div>
  </ClickAwayListener>
  );
};

export default connect(
  ({ login: { user }, userCurrent }) => ({ user, userCurrent }),
  { push })(Account);

Account.propTypes = {
  user: PropTypes.object,
};

Account.defaultProps = {
  user: {},
  userCurrent: {
    user: {
      name: '',
      image: '',
    },
  },
};
