import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { capitalize } from '@material-ui/core';
import Dashboard from '@material-ui/icons/Dashboard';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';

import {
  vocabSave,
  vocabDelete,
  fetchSectionTree,
  fetchVocabLayout,
} from '../actions/vocab';
import { setTermForLayout } from '../actions/layout';
import { SECTIONS, VOCAB_LAYOUTS, VOCAB_SINGULAR, VOCAB_PLURAL, AUTHORS } from '../constants/vocab';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtonsStatic } from '../components/common/page/PageToolbar';

import VocabTable from '../components/table/vocab/VocabTable';
import FlatTermAddNewDialog from '../components/dialog/FlatTermAddNewDialog';
import TaxonomySidebar from '../components/taxonomy/TaxonomySidebar';
import Button from '../components/ui/buttons/Button';
import ButtonSelect from '../components/ui/buttons/ButtonSelect';
import IconButton from '../components/ui/buttons/IconButton';
import AccessToggle from '../access/AccessToggle';
import { hasFeatures } from '../components/helper/utils';
import { FEATURE_AUTHOR_PROFILE } from '../constants/features';
import { getDefaultLayoutFromVocab } from '../utils/layoutHelper';

const tableHeaders = [
  { id: 'name', label: 'Name' },
  { id: 'path', label: 'Path' },
  { id: 'layout', label: 'Layout' },
];

const styles = theme => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    width: 100,
  },
  layout: {
    padding: 0,
    '.term:hover &': {
      display: 'none',
    },
  },
  layoutAction: {
    marginLeft: theme.spacing(-1.5),
    display: 'none',
    '.term:hover &': {
      display: 'flex',
    },
  },
});

export const TaxonomyLandingBreadcrumb = [
  {
    title: 'Layouts',
    slug: 'layout',
  },
];

const initial = {
  singleTermSelected: null,
  multipleTermSelected: [],
};

const SINGLE_TERM = 'SINGLE_TERM';
const MULTIPLE_TERM_ADD = 'MULTIPLE_TERM_ADD';
const MULTIPLE_TERM_REMOVE = 'MULTIPLE_TERM_REMOVE';
const RESET = 'RESET';

function reducer(state, { type, value }) {
  switch (type) {
    case SINGLE_TERM:
      return {
        ...state,
        singleTermSelected: value,
        multipleTermSelected: [],
      };
    case MULTIPLE_TERM_REMOVE:
      return {
        ...state,
        multipleTermSelected: state.multipleTermSelected.filter(item => item !== value),
        singleTermSelected: null,
      };
    case MULTIPLE_TERM_ADD:
      if (state.multipleTermSelected.includes(value)) {
        return state;
      }
      return {
        ...state,
        multipleTermSelected: [...state.multipleTermSelected, value],
        singleTermSelected: null,
      };
    case RESET:
      return initial;
    default:
      return state;
  }
}

const filterCells = (id, items, type, features) => {
  const hasAuthorProfile = hasFeatures(features)(FEATURE_AUTHOR_PROFILE);
  switch (id) {
    case 'path':
      return (Array.isArray(items) && items[0] && items[0].path);
    case 'layout':
      return type === AUTHORS && hasAuthorProfile ? false : !!VOCAB_LAYOUTS[type];
    default:
      return true;
  }
};

const LayoutList = (props) => {
  const {
    classes, vocab, vocabs, layout, features, publicationId,
    vocabSave: onSave,
    vocabDelete: onDelete,
    setTermForLayout: setTerm,
    push: routeChange,
    match: { params: { type } },
    fetchSectionTree: onFetchSectionTree,
    fetchVocabLayout: onFetchVocabLayout,
    permissions,
  } = props;

  const [addNewDialog, setAddNewDialog] = useState(false);
  const [defaultLayout, setDefaultLayout] = useState(null);

  const [state, dispatch] = useReducer(reducer, initial);

  useEffect(() => {
    if (type === SECTIONS) {
      // needed to load parent tree section
      onFetchSectionTree();
    }
    onFetchVocabLayout(type);
  }, [onFetchSectionTree, onFetchVocabLayout, type, publicationId]);

  useEffect(() => {
    setDefaultLayout(getDefaultLayoutFromVocab(layout, type));
  }, [layout, type]);

  const setSingleTerm = (term) => {
    dispatch({
      type: SINGLE_TERM,
      value: term,
    });
  };
  const setMultipleTerm = ([checked, data]) => {
    dispatch({
      type: checked ? MULTIPLE_TERM_ADD : MULTIPLE_TERM_REMOVE,
      value: data.id,
    });
  };

  const cellFormat = [
    { id: 'name', display: ({ name }) => <span>{name}</span> },
    { id: 'path', display: ({ path }) => <span>{path}</span> },
    {
      id: 'layout',
      display: term => (<div className={classes.actions}>
        <IconButton className={classes.layout}>
          {term?.layout ? <Dashboard /> : <DashboardOutlined />}
        </IconButton>
        <Button
          className={classes.layoutAction}
          startIcon={term?.layout ? <EditIcon /> : <AddIcon />}
          onClick={() => {
            if (term?.layout?.data?.id) {
              routeChange(`/layout/${type}/${term.layout.data.id}`);
            } else {
              // @todo refactor how term selection works this should be done with the type directly
              setTerm(term);
              routeChange(`/layout/${type}/new`);
            }
          }}
        >{term?.layout ? 'Edit Layout' : 'Add Layout'}</Button>
      </div>),
    },
  ];

  const actions = [
    {
      type: 'delete',
      action: ({ id, name }) => {
        if (window.confirm(`Please confirm you want to delete ${name}`)) {
          onDelete([type, [id]]);
        }
      },
    },
    { type: 'config' },
  ];

  const types = vocabs
    .filter(vocab => vocab !== 'article_type' && !!VOCAB_SINGULAR[vocab])
    .filter((item) => {
      // admin always can create a term
      if (permissions.includes(`create terms in ${item}`)) {
        return true;
      }
      // any other roles, check layout vocab view access
      return permissions.includes(`view any layout ${VOCAB_SINGULAR[item].replace(' ', '_')}_layout`);
    })
    .map(vocab => ({
      link: `/layout/${vocab}`,
      title: capitalize(VOCAB_PLURAL[vocab] || ''),
    }));
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={[...TaxonomyLandingBreadcrumb, {
        title: capitalize(VOCAB_PLURAL[type]),
      }]}
      >
        <Grid container justify={'center'} alignItems={'center'}>
          <Typography variant={'body2'}>Manage</Typography>
          <ButtonSelect active={capitalize(VOCAB_PLURAL[type])} items={types} />
        </Grid>
        <PageToolbarButtonsStatic>
          <AccessToggle restrictedTo={`create terms in ${type}`}>
            {defaultLayout && <Button
              onClick={() => routeChange(`/layout/${type}/${defaultLayout.data.id}`)}
              variant={'outlined'}
              startIcon={<EditIcon />}
            >Edit default layout</Button>}
            <Button
              onClick={() => setAddNewDialog(true)}
              variant={'contained'}
              startIcon={<AddIcon />}
            >New {VOCAB_SINGULAR[type]}</Button>
          </AccessToggle>
        </PageToolbarButtonsStatic>
      </PageToolbar>}
    >
      <PageContent>
        <VocabTable
          pageStyle
          selectedTermId={state.singleTermSelected?.id}
          activeVocab={type}
          checked={state.multipleTermSelected}
          onClickRow={setSingleTerm}
          onSelect={setMultipleTerm}
          headers={tableHeaders.filter(({ id }) => filterCells(id, vocab[type].items, type, features))}
          data={cellFormat.filter(({ id }) => filterCells(id, vocab[type].items, type, features))}
          actions={actions}
        />
      </PageContent>
      <AccessToggle restrictedTo={`create terms in ${type}`}>
        <TaxonomySidebar
          vocab={type}
          afterDelete={() => {
            dispatch({
              type: RESET,
            });
          }}
          {...state}
        />
      </AccessToggle>
      {addNewDialog && <FlatTermAddNewDialog
        open={addNewDialog}
        vocab={type}
        onSave={(payload) => {
          onSave(payload);
          setAddNewDialog(false);
        }}
        handleClose={() => setAddNewDialog(false)}
      />}
    </Page>
  );
};

LayoutList.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  publicationId: PropTypes.number.isRequired,
  vocab: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  vocabDelete: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setTermForLayout: PropTypes.func.isRequired,
  fetchSectionTree: PropTypes.func.isRequired,
  fetchVocabLayout: PropTypes.func.isRequired,
  vocabs: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  vocabSave: PropTypes.func.isRequired,
  features: PropTypes.array,
};

LayoutList.defaultProps = {
  features: [],
};

export default withStyles(styles)(connect(
  ({
    vocab, layout,
    frame: { selectedPublication: { vocabs, features, id: publicationId } },
    login: { permissions },
  }) => ({ permissions, vocab, vocabs, layout, features, publicationId }),
  { vocabSave, vocabDelete, push, setTermForLayout, fetchSectionTree, fetchVocabLayout },
)(LayoutList));
