import { setDefaults } from '@udecode/slate-plugins';
import deserializeTiktok from './deserializeTiktok';
import renderElementTiktok from './renderElementTiktok';
import { DEFAULTS_TIKTOK } from './defaults';

const TiktokPlugin = (options) => {
  const { tiktok } = setDefaults(options, DEFAULTS_TIKTOK);
  return ({
    renderElement: renderElementTiktok(options),
    deserialize: deserializeTiktok(options),
    voidTypes: [tiktok.type],
  });
};

export default TiktokPlugin;
