import { PURGE } from 'redux-persist';
import { DIALOG_GALLERY_ON_SEARCH } from '../../constants/actionTypes/dialog';
import {
  GALLERY_CONTEXT_RESET,
  GALLERY_CONTEXT_SET,
  GALLERY_DISPOSE, GALLERY_SAVE, GALLERY_SAVE_REJECTED, GALLERY_SAVE_SUCCESS, GALLERY_SEARCH,
  GALLERY_SEARCH_REJECTED, GALLERY_SEARCH_SUCCESS,
} from '../../constants/actionTypes/media';
import { CONTEXT_DEFAULT } from '../../constants/contexts';

const defaultState = {
  galleries: [],
  isGallerySearching: false,
  context: {
    type: CONTEXT_DEFAULT,
    data: {},
  },
  isBusy: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GALLERY_SEARCH:
    case DIALOG_GALLERY_ON_SEARCH:
      return {
        ...state,
        isGallerySearching: true,
      };

    case GALLERY_SEARCH_REJECTED:
      return {
        ...state,
        isGallerySearching: false,
      };

    case GALLERY_SEARCH_SUCCESS:
      return {
        ...state,
        galleries: action.value,
        isGallerySearching: false,
      };

    case GALLERY_CONTEXT_SET:
      return { ...state,
        context: action.value,
      };

    case GALLERY_CONTEXT_RESET:
      return { ...state,
        context: defaultState.context,
      };

    case GALLERY_SAVE:
      return {
        ...state,
        isBusy: true,
      };

    case GALLERY_SAVE_REJECTED:
    case GALLERY_SAVE_SUCCESS:
      return {
        ...state,
        isBusy: false,
      };

    case GALLERY_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
