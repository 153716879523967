import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import QuestionAnswerContent from './QuestionAnswerContent';
import FieldLockContainer from '../../../fieldLock/FieldLockContainer';
import { FIELD_QUESTION_ANSWERS } from '../../../../constants/article/articleFields';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../../selectors/fieldLock/fieldLock';
import Button from '../../../ui/buttons/Button';
import { addToLocalProp } from '../../../../actions/dataState';
import generateKey from '../../../../utils/generateKey';
import { PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';

/**
 * @todo Allow for individual Q&A locking
 */
const QuestionAnswerEditor = ({
  value, lockValue, hasChanges, addToLocalProp: addToLocal,
}) => (
  <FieldLockContainer field={FIELD_QUESTION_ANSWERS} lockValue={lockValue}>
    <PagePanelHeading>
      <SectionTitle hasChanges={hasChanges}>Questions & Answers</SectionTitle>
    </PagePanelHeading>
    <Grid container spacing={3}>
      {value.map((item, index) =>
        (
          <Grid item xs={12} key={item.id}>
            <QuestionAnswerContent
              index={index}
              {...item}
            />
          </Grid>
        ),
      )}
      <Grid item container justify={'center'} spacing={2}>
        <Grid item>
          <Button
            variant={'outlined'}
            onClick={() => addToLocal(
              FIELD_QUESTION_ANSWERS,
              {
                question: '',
                body: [],
                id: generateKey(),
              },
            )}
            startIcon={<AddIcon />}
          >Add new Q&A</Button>
        </Grid>
      </Grid>
    </Grid>
  </FieldLockContainer>
);

QuestionAnswerEditor.propTypes = {
  addToLocalProp: PropTypes.func.isRequired,
  value: PropTypes.array,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
};

QuestionAnswerEditor.defaultProps = {
  value: [],
  lockValue: null,
  hasChanges: false,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, { field: FIELD_QUESTION_ANSWERS }),
    lockValue: getFieldLockValue(state, { field: FIELD_QUESTION_ANSWERS }),
    hasChanges: getFieldHasChanges(state, { field: FIELD_QUESTION_ANSWERS }),
  };
};

export default connect(
  mapStateToProps,
  { addToLocalProp },
)(QuestionAnswerEditor);

