import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { FIELD_BRAND, FIELD_MODEL } from '../../../constants/product/productFields';
import { FIELD_PRODUCTS } from '../../../constants/article/articleFields';

import { setItemPropOnLocal } from '../../../actions/dataState';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import TextField from '../../ui/TextField';

const ProductDetails = (props) => {
  const {
    product,
    index,
    setItemPropOnLocal: setLocalProp,
  } = props;
  const {
    [FIELD_BRAND]: brand,
    [FIELD_MODEL]: model,
  } = product.data || {};
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Product Details</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              value={brand || ''}
              label={'Brand'}
              onChange={e => setLocalProp(FIELD_PRODUCTS, index, FIELD_BRAND, e.target.value)}
              margin={'none'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={model || ''}
              label={'Model'}
              onChange={e => setLocalProp(FIELD_PRODUCTS, index, FIELD_MODEL, e.target.value)}
              margin={'none'}
            />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ProductDetails.propTypes = {
  setItemPropOnLocal: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(
  () => ({}),
  { setItemPropOnLocal },
)(ProductDetails);
