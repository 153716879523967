import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/Close';
import EditOutlined from '@material-ui/icons/EditRounded';
import { PinOutline } from 'mdi-material-ui';
import Typography from '@material-ui/core/Typography';

import {
  LIVEBLOG_TYPE_POST,
} from '../../../constants/liveblog';

import {
  editLiveblogPost,
  removeLiveblogPost,
  startWatchSchedulePost,
  disposeWatchSchedulePost,
  liveblogPostPinUpdate,
} from '../../../actions/liveblog';

import LiveblogItem from '../LiveblogPost';
import IconButton from '../../ui/buttons/IconButton';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../common/context/ContextPanel';

const ScheduledPosts = ({
  futurePosts,
  removeLiveblogPost: remove,
  editLiveblogPost: edit,
  startWatchSchedulePost: startWatch,
  disposeWatchSchedulePost: disposeWatch,
  liveblogPostPinUpdate: onPinUpdate,
}) => {
  useEffect(() => {
    startWatch();
    return () => {
      disposeWatch();
    };
  }, [futurePosts]);

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Scheduled Posts</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        {futurePosts.length > 0 && futurePosts
          .sort((a, b) => a?.data?.scheduleTime - b?.data?.scheduleTime)
          .map(post =>
            (<LiveblogItem
              key={post.data.id}
              post={post}
              action={() => (<div>
                <IconButton onClick={() => edit(post.data.id)}><EditOutlined /></IconButton>
                <IconButton
                  onClick={() => remove([post.data.id, LIVEBLOG_TYPE_POST])}
                ><RemoveIcon /></IconButton>
                <IconButton
                  onClick={() => onPinUpdate(post.data.id)}
                ><PinOutline /></IconButton>
              </div>)}
            />))}
        {futurePosts.length === 0 && <Typography variant={'body1'}>No scheduled posts</Typography>}
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ScheduledPosts.propTypes = {
  editLiveblogPost: PropTypes.object.isRequired,
  removeLiveblogPost: PropTypes.object.isRequired,
  startWatchSchedulePost: PropTypes.func.isRequired,
  disposeWatchSchedulePost: PropTypes.func.isRequired,
  liveblogPostPinUpdate: PropTypes.func.isRequired,
  futurePosts: PropTypes.array,
};

ScheduledPosts.defaultProps = {
  futurePosts: [],
};

export default connect(
  ({ liveblog: { futurePosts } }) =>
    ({ futurePosts }),
  {
    editLiveblogPost,
    removeLiveblogPost,
    startWatchSchedulePost,
    disposeWatchSchedulePost,
    liveblogPostPinUpdate,
  },
)(ScheduledPosts);

