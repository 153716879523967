import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import LinkIcon from '@material-ui/icons/Link';

import {
  CONTENT_SOURCE_MANUAL_LIST_SECTION, MANUAL_LIST_COLORS,
} from '../../constants';

import Button from '../../../ui/buttons/Button';
import { getArticleCountFromType } from '../../../../utils/layoutHelper';

const styles = theme => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    paddingRight: 28,
    border: '1px solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linked: {
    borderColor: theme.palette.primary.main,
    '& > .link-icon': {
      display: 'block',
    },
    '&:hover': {
      '& > .link-icon': {
        display: 'none',
      },
      '& > .link-off-icon': {
        display: 'block',
      },
    },
    '&.list-index-0': {
      borderColor: MANUAL_LIST_COLORS[0],
    },
    '&.list-index-1': {
      borderColor: MANUAL_LIST_COLORS[1],
    },
    '&.list-index-2': {
      borderColor: MANUAL_LIST_COLORS[2],
    },
    '&.list-index-3': {
      borderColor: MANUAL_LIST_COLORS[3],
    },
    '&.list-index-4': {
      borderColor: MANUAL_LIST_COLORS[4],
    },
    '&.list-index-5': {
      borderColor: MANUAL_LIST_COLORS[5],
    },
    '&.list-index-6': {
      borderColor: MANUAL_LIST_COLORS[6],
    },
    '&.list-index-7': {
      borderColor: MANUAL_LIST_COLORS[7],
    },
    '&.list-index-8': {
      borderColor: MANUAL_LIST_COLORS[8],
    },
    '&.list-index-9': {
      borderColor: MANUAL_LIST_COLORS[9],
    },
  },
  unlinked: {
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.main,
      background: fade(theme.palette.primary.main, 0.3),
      '&.list-index-0': {
        borderColor: MANUAL_LIST_COLORS[0],
        background: fade(MANUAL_LIST_COLORS[0], 0.3),
      },
      '&.list-index-1': {
        borderColor: MANUAL_LIST_COLORS[1],
        background: fade(MANUAL_LIST_COLORS[1], 0.3),
      },
      '&.list-index-2': {
        borderColor: MANUAL_LIST_COLORS[2],
        background: fade(MANUAL_LIST_COLORS[2], 0.3),
      },
      '&.list-index-3': {
        borderColor: MANUAL_LIST_COLORS[3],
        background: fade(MANUAL_LIST_COLORS[3], 0.3),
      },
      '&.list-index-4': {
        borderColor: MANUAL_LIST_COLORS[4],
        background: fade(MANUAL_LIST_COLORS[4], 0.3),
      },
      '&.list-index-5': {
        borderColor: MANUAL_LIST_COLORS[5],
        background: fade(MANUAL_LIST_COLORS[5], 0.3),
      },
      '&.list-index-6': {
        borderColor: MANUAL_LIST_COLORS[6],
        background: fade(MANUAL_LIST_COLORS[6], 0.3),
      },
      '&.list-index-7': {
        borderColor: MANUAL_LIST_COLORS[7],
        background: fade(MANUAL_LIST_COLORS[7], 0.3),
      },
      '&.list-index-8': {
        borderColor: MANUAL_LIST_COLORS[8],
        background: fade(MANUAL_LIST_COLORS[8], 0.3),
      },
      '&.list-index-9': {
        borderColor: MANUAL_LIST_COLORS[9],
        background: fade(MANUAL_LIST_COLORS[9], 0.3),
      },
    },
  },
  button: {
    '&.list-index-0': {
      borderColor: MANUAL_LIST_COLORS[0],
      color: MANUAL_LIST_COLORS[0],
    },
    '&.list-index-1': {
      borderColor: MANUAL_LIST_COLORS[1],
      color: MANUAL_LIST_COLORS[1],
    },
    '&.list-index-2': {
      borderColor: MANUAL_LIST_COLORS[2],
      color: MANUAL_LIST_COLORS[2],
    },
    '&.list-index-3': {
      borderColor: MANUAL_LIST_COLORS[3],
      color: MANUAL_LIST_COLORS[3],
    },
    '&.list-index-4': {
      borderColor: MANUAL_LIST_COLORS[4],
      color: MANUAL_LIST_COLORS[4],
    },
    '&.list-index-5': {
      borderColor: MANUAL_LIST_COLORS[5],
      color: MANUAL_LIST_COLORS[5],
    },
    '&.list-index-6': {
      borderColor: MANUAL_LIST_COLORS[6],
      color: MANUAL_LIST_COLORS[6],
    },
    '&.list-index-7': {
      borderColor: MANUAL_LIST_COLORS[7],
      color: MANUAL_LIST_COLORS[7],
    },
    '&.list-index-8': {
      borderColor: MANUAL_LIST_COLORS[8],
      color: MANUAL_LIST_COLORS[8],
    },
    '&.list-index-9': {
      borderColor: MANUAL_LIST_COLORS[9],
      color: MANUAL_LIST_COLORS[9],
    },
  },
});

export const ManualListUI = withStyles(styles)(connect(
  ({ layout: { selectedManualListIndex } }) => ({ selectedManualListIndex }),
  {},
)(({ selectedManualListIndex, component, sourceId, classes }) => (
  (
    typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id === sourceId
  )
    ? ''
    : (
      <Button
        className={
          selectedManualListIndex > -1
            ? `${classes.button} list-index-${selectedManualListIndex}`
            : classes.button
        }
        startIcon={<LinkIcon />}
        variant={'outlined'}
        size={'small'}
      >Link</Button>
    )
)));

const ManualListTarget = (props) => {
  const {
    classes, component, sourceId,
    setLink, getCount,
    selectedManualListIndex,
  } = props;
  const articleCount = getArticleCountFromType(component.type);
  if (articleCount > 0) {
    const hasSource = typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id === sourceId;
    const rootClasses = [classes.root];
    if (hasSource) {
      rootClasses.push(classes.linked);
    } else {
      rootClasses.push(classes.unlinked);
    }
    if (selectedManualListIndex > -1) {
      rootClasses.push(`list-index-${selectedManualListIndex}`);
    }
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        role="button"
        tabIndex="0"
        data-target={'manual-list'}
        className={rootClasses.join(' ')}
        onClick={() => {
          if (!hasSource) {
            setLink({ component, sourceId });
            getCount(sourceId);
          }
        }}
      />
    );
  }
  return [];
};

ManualListTarget.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  sourceId: PropTypes.number.isRequired,
  setLink: PropTypes.func.isRequired,
  getCount: PropTypes.func.isRequired,
  selectedManualListIndex: PropTypes.number.isRequired,
};

export default withStyles(styles)(connect(
  ({ layout: { selectedManualListIndex } }) => ({ selectedManualListIndex }),
  {},
)(ManualListTarget));
