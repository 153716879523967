import React, { useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PAGE_TYPE_ARTICLE } from '../../../constants/edition/edition';
import { PDF_WIDTH, PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE } from '../../../constants/preview';

const styles = theme => ({
  root: {
    height: '100%',
    padding: theme.spacing(4),
  },
  frame: {
    display: 'block',
    height: '100%',
    margin: '0 auto',
    background: '#fff',
  },
  [PREVIEW_MODE_DESKTOP]: {
    minWidth: PDF_WIDTH + 15,
    overflowY: 'scroll',
  },
  [PREVIEW_MODE_MOBILE]: {
    width: 400,
  },
});

// @todo rework with ref instead of id, as is more on pattern
const EditionPreview = (props) => {
  const {
    classes, className, domain, environment, type, articleId, previewMode, edition,
    revisionId, revisionList,
  } = props;
  const {
    headline: title, subHeadline: subheadline, body, heroImage, slug, authors,
    authorInfo, relatedArticle, editionDetail, pugpig_section: sections, starRating,
    awayScore, awayTeam, awayScorers, homeScore, homeTeam, homeScorers, pugpig_templates: templates,
  } = edition;
  const iframeRef = useRef();

  const containerIdParam = editionDetail ? `containerId=${editionDetail.id}` : '';

  const base = process.env.REACT_APP_ENVIRONMENT_WEB || environment.web.replace(/\/$/, '');
  const previewPath = `${base}/edition/${type === 'section' ? 'section' : 'article'}/${articleId}?${containerIdParam}&domain=${domain}&preview`;

  const templateType = typeof templates[0] !== 'undefined' ? templates[0].id : '';

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && !revisionId) {
      iframe.contentWindow.postMessage({
        event: 'updateData',
        data: {
          article: {
            data: {
              title,
              subheadline,
              templateType,
              body,
              hero: [
                heroImage,
              ],
              slug,
              sections,
              authors,
              extra: {
                authorInfo,
                relatedArticle,
                starRating,
                awayScore,
                awayTeam,
                awayScorers,
                homeScore,
                homeTeam,
                homeScorers,
              },
            },
          },
        },
      }, '*');
    }
  }, [
    title, subheadline, body, heroImage, slug, authors, authorInfo,
    relatedArticle, sections, starRating, templates,
    awayScore, awayTeam, awayScorers, homeScore, homeTeam, homeScorers,
  ]);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe && revisionId && revisionList.length > 0) {
      const revision = revisionList.find(({ revision_id: id }) => id === revisionId);

      if (!revision) return false;

      try {
        const { payload } = revision;
        const getTextValue = node => key => (node[key].length > 0 ? node[key][0].value : '');
        const getTermValue = node => key => (node[key].length > 0 ? node[key][0].pwamp : []);
        const nodeValue = getTextValue(payload);
        const nodeTerm = getTermValue(payload);
        const hero = nodeTerm('field_pugpig_hero_image');
        const extra = nodeValue('field_pugpig_extra_json');
        const templateTypeField = nodeValue('field_pugpig_template_type');
        const data = {
          event: 'updateData',
          data: {
            article: {
              data: {
                title: nodeValue('name'),
                subheadline: nodeValue('field_pugpig_subheadline'),
                body: JSON.parse(nodeValue('field_pugpig_body_json')),
                hero: hero ? [hero] : [],
                slug: nodeValue('field_pugpig_slug'),
                sections: nodeTerm('field_pugpig_home_section'),
                authors: nodeTerm('field_pugpig_author'),
                templateType: typeof templateTypeField !== 'undefined'
                  ? templateTypeField
                  : '',
                extra: {
                  authorInfo: extra.authorInfo,
                  relatedArticle: extra.relatedArticle,
                  starRating: extra.starRating,
                  awayScore: extra.awayScore,
                  awayTeam: extra.awayTeam,
                  awayScorers: extra.awayScorers,
                  homeScore: extra.homeScore,
                  homeTeam: extra.homeTeam,
                  homeScorers: extra.homeScorers,
                },
              },
            },
          },
        };
        // TODO: handle this in elegant way
        setTimeout(() => {
          iframe.contentWindow.postMessage(data, '*');
        }, 1000);
      } catch (ex) {
        console.error(ex);
      }
    }
  }, [revisionId, revisionList, iframeRef]);

  return (
    <div className={`${classes.root} ${className}`}>
      <iframe
        ref={iframeRef}
        id={'edition-preview'}
        className={`${classes.frame} ${classes[previewMode]}`}
        title={'edition-preview'}
        src={previewPath}
        frameBorder={'0'}
      />
    </div>
  );
};

EditionPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  articleId: PropTypes.number.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  previewMode: PropTypes.string,
  domain: PropTypes.string.isRequired,
  environment: PropTypes.object.isRequired,
  edition: PropTypes.object.isRequired,
  revisionList: PropTypes.array.isRequired,
  revisionId: PropTypes.string,
};

EditionPreview.defaultProps = {
  className: '',
  type: PAGE_TYPE_ARTICLE,
  previewMode: PREVIEW_MODE_DESKTOP,
  revisionId: null,
};

export default withStyles(styles)(connect(
  ({
    router: { location: { pathname } },
    revision: { list: revisionList },
    frame: { selectedPublication: { domain, environment } },
    edition,
  }) =>
    ({ domain, environment, edition, revisionList, pathname }),
  {},
)(EditionPreview));
