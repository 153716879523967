import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVert from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import UndoIcon from '@material-ui/icons/Undo';

import SchedulerControl from '../ui/components/SchedulerControl';
import EditionSelectorDialog from '../dialog/EditionSelectorDialog';

import {
  setPreviewMode, resetPreviewId, saveArticle, deleteArticle, cloneArticle, saveToRevision,
} from '../../actions/article';
import { exportToEdition } from '../../actions/edition';
import { REVISION_OFFLINE, REVISION_PUBLISHED } from '../../constants/revision';
import { onSelectRevision, deleteOfflineRevision, updateOfflineRevision } from '../../actions/revision';
import { PageToolbarButtons } from '../common/page/PageToolbar';
import Button from '../ui/buttons/Button';

import IconButton from '../ui/buttons/IconButton';
import { showNotification } from '../../actions/notification';
import { ERROR } from '../../constants/actionTypes/notification';
import { PREVIEW_MODE_DESKTOP } from '../../constants/preview';
import PublicationDialogSelector from '../dialog/PublicationSelectorDialog';
import { PRODUCTION } from '../../constants/env';
import {
  CMS_SERVER_TIMESTAMP,
  FIELD_IS_PUBLISHED, FIELD_NID,
  FIELD_PATH,
  FIELD_SCHEDULE_DATE,
} from '../../constants/article/articleFields';
import { makeGetIsArticleValid } from '../../selectors/article/article';
import { disposeLocal, setLocalProp } from '../../actions/dataState';
import { CMS_ADMIN_ROLE, CMS_EDITOR_ROLE } from '../../constants/access';
import { hasFeatures } from '../helper/utils';
import { FEATURE_EDITION } from '../../constants/features';
import { CLIENT_TINDLE } from 'constants/env';
import { useSelector } from 'react-redux';
const styles = theme => ({
  drawer: {
    maxWidth: 450,
    minWidth: 450,
    padding: theme.spacing(3),
    '&>.MuiGrid-container': {
      marginBottom: theme.spacing(4),
    },
  },
  moreAction: {
    marginRight: theme.spacing(-2),
  },
  scheduled: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    '& .MuiButton-label': {
      width: 190,
      whiteSpace: 'normal',
      lineHeight: '1.1em',
    },
  },
});

const ControlButtons = (props) => {
  const {
    classes,
    saveArticle: doSaveAction,
    deleteArticle: onDelete,
    setLocalProp: setProp,
    setPreviewMode: onSetPreviewMode,
    resetPreviewId: onResetPreviewId,
    cloneArticle: onCloneArticle,
    saveToRevision: onSaveToRevision,
    exportToEdition: onExport,
    onSelectRevision: selectRevision,
    deleteOfflineRevision: deleteOffline,
    showNotification: onShowNotification,
    updateOfflineRevision: onUpdateOffline,
    disposeLocal: discardChanges,
    nid,
    alias,
    previewId,
    isPublished,
    roles,
    revisionStatus,
    revisions,
    scheduleDateTime,
    serverTimestamp,
    localState,
    selectedPublication,
    isValid,
    mode,
  } = props;
  const { client } = useSelector(state => state.frame);
  const [hasChanges, setHasChanges] = useState(false);
  const [publishDrawer, setPublishDrawer] = useState(false);
  const [exportToEditionOpen, setExportToEditionOpen] = useState(false);
  const [publicationDialogOpen, setPublicationDialogOpen] = useState(false);
  const [hasOffline, setHasOffline] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOffline, setIsOffline] = useState(revisionStatus === REVISION_OFFLINE);
  const open = Boolean(anchorEl);
  const hasFeature = hasFeatures(selectedPublication?.features || []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setHasOffline(revisions.some(({ status }) => status === REVISION_OFFLINE));
  }, [revisions]);

  useEffect(() => {
    if (previewId) {
      const {
        domain, environment: { web },
      } = selectedPublication;
      const base = process.env.REACT_APP_ENVIRONMENT_WEB || web.replace(/\/$/, '');
      window.open(`${base}/preview/${previewId}?domain=${domain}`, '_blank');
      onResetPreviewId();
    }
  }, [onResetPreviewId, previewId, selectedPublication]);

  useEffect(() => {
    setIsOffline(revisionStatus === REVISION_OFFLINE);
  }, [revisionStatus]);

  useEffect(() => {
    setHasChanges(Object.entries(localState).length > 0);
  }, [localState]);

  const notScheduled = scheduleDateTime > serverTimestamp;
  const handleSaveArticle = () => {
    if (isOffline) {
      onUpdateOffline();
    } else {
      doSaveAction();
    }
  };
  const buttons = [];

  if (isPublished && !notScheduled) {
    if (!hasOffline) {
      buttons.push({
        title: 'Save offline',
        onClick: () => onSaveToRevision(),
        startIcon: <SaveIcon />,
      });
    } else {
      buttons.push({
        title: isOffline ? 'Go to live' : 'Go to offline',
        onClick: () => {
          // find the most recent revision by status key
          const mostRecentRevisionByStatus = key =>
            revisions.find(({ status }) => status === key);
          if (isOffline) {
            return selectRevision(mostRecentRevisionByStatus(REVISION_PUBLISHED));
          }
          return selectRevision(mostRecentRevisionByStatus(REVISION_OFFLINE));
        },
        startIcon: <ExitToAppIcon />,
      });
      if (isOffline) {
        buttons.push(
          {
            title: 'Delete',
            onClick: () => window.confirm('Are you sure you want to delete the offline revision') && deleteOffline(),
            startIcon: <DeleteOutlinedIcon />,
          },
          {
            title: 'Save',
            onClick: handleSaveArticle,
            startIcon: <SaveIcon />,
          },
        );
      }
    }
  } else if (!isPublished) {
    buttons.push({
      title: 'Save',
      onClick: handleSaveArticle,
      startIcon: <SaveIcon />,
    });
  }

  if (!isPublished || notScheduled) {
    buttons.push({
      title: scheduleDateTime > serverTimestamp
        ? `Scheduled to publish ${moment.unix(scheduleDateTime).format('MMM D, HH:mm')}`
        : 'Schedule',
      onClick: () => setPublishDrawer(true),
      startIcon: <ScheduleIcon />,
      variant: 'outlined',
      color: scheduleDateTime > serverTimestamp ? 'secondary' : 'primary',
      className: scheduleDateTime > serverTimestamp ? classes.scheduled : '',
    });
  }

  buttons.push(
    {
      title: isPublished && !isOffline ? 'Update' : 'Publish',
      onClick: () => doSaveAction(true),
      startIcon: <PublishIcon />,
      variant: 'contained',
    },
  );

  const liveArticleAction = () => {
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url
      : selectedPublication.environment.web;
    window.open(`${frontEndUrl.replace(/\/$/, '')}${alias}`, '_blank');
    return false;
  };

  return (
    <>
      <PageToolbarButtons>
        {hasChanges && <Button
          onClick={() => { window.confirm('All your changes to this article will be removed') && discardChanges(); }}
          startIcon={<UndoIcon />}
        >Discard changes</Button>}
        {!isValid && <Tooltip title={'Enter Headline, Section and Hero Image to preview'}><span>
          <Button
            disabled
            onClick={() => onSetPreviewMode(PREVIEW_MODE_DESKTOP)}
            startIcon={<OpenInBrowserIcon />}
          >Preview</Button>
        </span></Tooltip>}
        {isValid && <Button
          onClick={() => onSetPreviewMode(PREVIEW_MODE_DESKTOP)}
          startIcon={<OpenInBrowserIcon />}
        >Preview</Button>}
        {isPublished && !notScheduled && alias && <Button
          onClick={() => liveArticleAction()}
          startIcon={<VisibilityIcon />}
        >Live article</Button>}
        {buttons.map(button => (<Button key={button.title} {...button}>{button.title}</Button>))}
        {!isOffline && <IconButton
          className={classes.moreAction}
          onClick={(event) => {
            if (!nid || !roles.some(role => role === CMS_ADMIN_ROLE || role === CMS_EDITOR_ROLE)) {
              return;
            }
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert style={{ fill: '#DADADA' }} />
        </IconButton>}
        <Menu
          id={'long-menu'}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          {isPublished && <MenuItem
            onClick={() => {
              doSaveAction(true, true);
              setAnchorEl(null);
            }}
          >Update (silent)</MenuItem>}
          {roles.some(role => role === CMS_ADMIN_ROLE) && <MenuItem
            onClick={() => {
              setPublicationDialogOpen(true);
              setAnchorEl(null);
            }}
          >Clone</MenuItem>}
          {roles.some(role => role === CMS_ADMIN_ROLE) && isPublished && <MenuItem
            onClick={() => {
              doSaveAction(!isPublished);
              setAnchorEl(null);
            }}
          >Unpublish</MenuItem>}

          {client === CLIENT_TINDLE && roles.some(role => role === CMS_EDITOR_ROLE) && isPublished && <MenuItem
            onClick={() => {
              doSaveAction(!isPublished);
              setAnchorEl(null);
            }}
          >Unpublish</MenuItem>}

          {hasFeature(FEATURE_EDITION) && roles.some(role => role === CMS_ADMIN_ROLE) && <MenuItem
            onClick={() => {
              if (hasChanges) {
                onShowNotification({
                  message: 'This article has unsaved changes. Save before exporting.',
                  variant: ERROR,
                });
              } else {
                setExportToEditionOpen(true);
              }
              setAnchorEl(null);
            }}
          >Export to edition</MenuItem>}
          {roles.some(role => role === CMS_ADMIN_ROLE) && <MenuItem
            onClick={() => {
              handleClose();
              if (window.confirm('Please confirm you want delete this article?')) {
                onDelete();
                setAnchorEl(null);
              }
            }}
          >Delete</MenuItem>}
        </Menu>
      </PageToolbarButtons>
      {publicationDialogOpen && <PublicationDialogSelector
        open={publicationDialogOpen}
        title={'Select publication target'}
        handleClose={() => setPublicationDialogOpen(false)}
        onClose={() => setPublicationDialogOpen(false)}
        onSelect={(target) => {
          onCloneArticle(target.id);
          setPublicationDialogOpen(false);
        }}
      />}
      {exportToEditionOpen && <EditionSelectorDialog
        open={exportToEditionOpen}
        onClose={() => setExportToEditionOpen(false)}
        handleClose={() => setExportToEditionOpen(false)}
        onSubmit={(data) => {
          onExport({ ...data, articleId: nid });
          setExportToEditionOpen(false);
        }}
      />}
      <Drawer
        anchor={'right'}
        open={publishDrawer}
        onClose={() => setPublishDrawer(false)}
      >
        <div className={classes.drawer}>
          <Grid container>
            <SchedulerControl
              scheduleDateTime={scheduleDateTime}
              onSubmit={(timestamp) => {
                setProp(FIELD_SCHEDULE_DATE, timestamp);
                doSaveAction(true);
                setPublishDrawer(false);
              }}
            />
          </Grid>
        </div>
      </Drawer>
    </>
  );
};

ControlButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  saveArticle: PropTypes.func.isRequired,
  deleteArticle: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  exportToEdition: PropTypes.func.isRequired,
  setPreviewMode: PropTypes.func.isRequired,
  cloneArticle: PropTypes.func.isRequired,
  resetPreviewId: PropTypes.func.isRequired,
  saveToRevision: PropTypes.func.isRequired,
  revisionStatus: PropTypes.number.isRequired,
  onSelectRevision: PropTypes.func.isRequired,
  deleteOfflineRevision: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  updateOfflineRevision: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  disposeLocal: PropTypes.func.isRequired,
  localState: PropTypes.object,
  alias: PropTypes.string,
  nid: PropTypes.string,
  revisions: PropTypes.array,
  previewId: PropTypes.string,
  isValid: PropTypes.bool,
  isPublished: PropTypes.bool,
  scheduleDateTime: PropTypes.number,
  serverTimestamp: PropTypes.number,
  mode: PropTypes.string,
  roles: PropTypes.array,
};

ControlButtons.defaultProps = {
  revisions: [],
  previewId: '',
  isValid: false,
  isPublished: false,
  alias: null,
  nid: null,
  scheduleDateTime: null,
  mode: '',
  localState: null,
  serverTimestamp: null,
  roles: [],
};

const mapStateToProps = (state) => {
  const getIsArticleValid = makeGetIsArticleValid();
  return {
    isValid: getIsArticleValid(state),
    isPublished: state.dataState?.[FIELD_IS_PUBLISHED],
    alias: state.dataState?.[FIELD_PATH],
    nid: state.dataState?.[FIELD_NID],
    scheduleDateTime: state.dataState?.[FIELD_SCHEDULE_DATE],
    serverTimestamp: state.dataState?.[CMS_SERVER_TIMESTAMP],
    previewId: state.article?.previewId,
    revisionStatus: state.article?.revisionStatus,
    roles: state.login?.user?.roles,
    revisions: state.revision?.list,
    localState: state.localState,
    selectedPublication: state.frame?.selectedPublication,
    mode: state.dashboard?.mode,
  };
};

export default withStyles(styles)(connect(
  mapStateToProps,
  {
    saveArticle,
    deleteArticle,
    setLocalProp,
    exportToEdition,
    setPreviewMode,
    resetPreviewId,
    cloneArticle,
    saveToRevision,
    onSelectRevision,
    deleteOfflineRevision,
    showNotification,
    updateOfflineRevision,
    disposeLocal,
  },
)(ControlButtons));
