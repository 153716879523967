import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const styles = theme => ({
  dateWrapper: {
    display: 'flex',
  },
  datePicker: {
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
    },
    '& .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(0.5),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRightWidth: 0,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  },
  timePicker: {
    marginTop: theme.spacing(1),
    width: `calc(100% + ${theme.spacing(2.5)})`,
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 4px 4px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeftWidth: 0,
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: theme.spacing(0.5),
    },
  },
  icon: {
    width: theme.spacing(2),
  },
});

const DEFAULT_DATE = moment().unix();

const DateTimePicker = (props) => {
  const { classes, label, date, onChange } = props;
  const [selectedDate, setSelectedDate] = useState(moment.unix(DEFAULT_DATE));
  useEffect(() => {
    if (date > 0) {
      setSelectedDate(moment.unix(date));
    }
  }, [date]);
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.dateWrapper}>
        <DatePicker
          className={classes.datePicker}
          margin={'normal'}
          inputVariant={'outlined'}
          label={label}
          value={selectedDate}
          format={'DD-MM-YY'}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <TimePicker
          className={classes.timePicker}
          margin={'normal'}
          inputVariant={'outlined'}
          value={selectedDate}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <CalendarTodayOutlinedIcon className={classes.icon} color={'primary'} />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

DateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  date: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

DateTimePicker.defaultProps = {
  label: '',
};

export default withStyles(styles)(DateTimePicker);
