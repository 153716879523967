export const AD_CONFIG_SET = 'AD_CONFIG_SET';
export const AD_CONFIG_SET_PROPERTY = 'AD_CONFIG_SET_PROPERTY';
export const AD_CONFIG_SELECT_ARTICLE = 'AD_CONFIG_SELECT_ARTICLE';
export const AD_CONFIG_SELECT_SECTION = 'AD_CONFIG_SELECT_SECTION';
export const AD_CONFIG_REMOVE = 'AD_CONFIG_REMOVE';
export const AD_CONFIG_REMOVE_COMPLETED = 'AD_CONFIG_REMOVE_COMPLETED';
export const AD_CONFIG_SAVE = 'AD_CONFIG_SAVE';
export const AD_CONFIG_FETCH_LOADED = 'AD_CONFIG_FETCH_LOADED';
export const AD_CREATE_FETCH_LOADED = 'AD_CREATE_FETCH_LOADED'; // @todo investigate can likely be replaced for above
export const AD_CONFIG_SERVER_RESET = 'AD_CONFIG_SERVER_RESET';
