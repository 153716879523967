import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import ImageSelector from './ImageSelector';

export const INSERT_PREPEND = 'start';
export const INSERT_APPEND = 'end';

const ImageMultiSelector = ({ label, helperText, onSelect, ...rest }) => {
  const [position, setPosition] = useState(INSERT_PREPEND);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageSelector
          label={label}
          helperText={helperText}
          selectImage={images => onSelect(images, position)}
          multi
          {...rest}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl component={'fieldset'}>
          <FormLabel component={'legend'}>Insert position</FormLabel>
          <RadioGroup
            aria-label={'position'}
            name={'position'}
            value={position}
            onChange={e => setPosition(e.target.value)}
          >
            <FormControlLabel value={INSERT_PREPEND} control={<Radio />} label={'Front'} />
            <FormControlLabel value={INSERT_APPEND} control={<Radio />} label={'End'} />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

ImageMultiSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
};

ImageMultiSelector.defaultProps = {
  label: 'Add image(s)',
  helperText: 'Drag image from desktop or chp, or click to add from dialog (png or jpeg only)',
};

export default ImageMultiSelector;
