export const debounce = (fn, time) => {
  let timeout;

  return function dispose(...args) {
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};
export const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

export const compose = (...fns) => pipe.apply(this, fns.reverse());

export const sortAscending = (a, b) => a - b;
