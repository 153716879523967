import React, { useEffect, useState } from 'react';
import {connect, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { FlagOutline } from 'mdi-material-ui';
import Grid from '@material-ui/core/Grid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import ListTable from '../components/table/ListTable';
import {
  deleteUserComment,
  fetchUserComments,
  unflagUserComment,
  setUserCommentsFilter,
} from '../actions/service/userComments';
import TableHeader from '../components/table/TableHeader';
import { TableBody, TableCell, TablePagination, TableRow } from '../components/ui/table/Table';
import IconButton from '../components/ui/buttons/IconButton';
import moment from 'moment';
import { CMS_DATE_FORMAT } from '../constants/common';
import OpenInNewTab from "../components/ui/buttons/OpenInNewTabButton";
import {PRODUCTION} from "../constants/env";

export const EventListBreadcrumb = [
  {
    title: 'Comments',
    slug: 'user-comments',
  },
];

const UserCommentList = ({
  isFetching, items, filter, lastKey, publicationId,
  fetchUserComments: fetchList,
  deleteUserComment: deleteComment,
  unflagUserComment: unflagComment,
  setUserCommentsFilter: setFilter,
}) => {
  const [keyHistory, setKeyHistory] = useState([]);
  const [page, setPage] = useState(0);
  const headers = ['Author', 'Comment', 'Timestamp', 'Flag Count', ''];

  const queryData = [
    filter,
    publicationId,
  ];

  const getFetchData = () => filter || {};

  useEffect(() => {
    if (lastKey && keyHistory.length > 0) {
      setPage(1);
    } else if (lastKey) {
      setPage(0);
    } else {
      setPage(2);
    }
  }, [lastKey, keyHistory]);

  return (
    <Page toolbarContent={<PageToolbar breadcrumb={EventListBreadcrumb} />}>
      <PageContent>
        <PageSection>
          <ListTable
            getFetchData={getFetchData}
            isFetching={isFetching}
            fetchList={fetchList}
            queryData={queryData}
          >
            <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
            <TableBody>
              {items.length === 0 && <TableRow>
                <TableCell colSpan={headers.length} align={'center'}>No comments found</TableCell>
              </TableRow>}
              {items.length > 0 && items
                .map(item => {
                  const { author, body, users, timestamp, id, articleUrl } = item;
                  return (
                    <TableRow key={`${id}_${timestamp}`} hasActions>
                      <TableCell>{author}</TableCell>
                      <TableCell>{body}</TableCell>
                      <TableCell>{moment.unix(timestamp/1000).format(CMS_DATE_FORMAT)}</TableCell>
                      <TableCell>{users?.length || 1}</TableCell>
                      <TableCell>
                        <Grid spacing={2} container wrap={'nowrap'} justify={'flex-end'}>
                          <Grid item>
                            <IconButton
                              onClick={() =>
                                window.confirm('Please confirm you want to unflag this comment')
                                && unflagComment({ timestamp, id })
                              }
                            >
                              <FlagOutline />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            <OpenInNewTab target={articleUrl} />
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() =>
                                window.confirm('Please confirm you want to delete this comment')
                                && deleteComment({ timestamp, id })
                              }
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </ListTable>
          {(lastKey || keyHistory.length > 0) && <TablePagination
            noCaption
            rowsPerPageOptions={[]}
            rowsPerPage={1}
            count={3}
            page={page}
            component={'nav'}
            backIconButtonProps={{
              'aria-label': 'Previous',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next',
            }}
            onChangePage={(e, i) => {
              if (i < page) {
                keyHistory.pop();
                setKeyHistory(keyHistory);
                setFilter(keyHistory?.[0] || {});
              } else {
                setFilter(lastKey);
                keyHistory.push(lastKey);
                setKeyHistory(keyHistory);
              }
            }}
          />}
        </PageSection>
      </PageContent>
    </Page>
  );
};

UserCommentList.propTypes = {
  publicationId: PropTypes.number.isRequired,
  fetchUserComments: PropTypes.func.isRequired,
  deleteUserComment: PropTypes.func.isRequired,
  unflagUserComment: PropTypes.func.isRequired,
  setUserCommentsFilter: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  lastKey: PropTypes.object,
};

UserCommentList.defaultProps = {
  lastKey: null,
};

export default connect(
  ({
    frame: { selectedPublication: { id: publicationId } },
    userComments: { isFetching, items, lastKey },
  }) => ({ isFetching, items, lastKey, publicationId }),
  { deleteUserComment, fetchUserComments, unflagUserComment, setUserCommentsFilter },
)(UserCommentList);
