import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';

import { getOGdata } from '../../../actions/editor';

import TextField from '../../ui/builder/TextField';

import ArticleLinkPreview from '../../liveblog/liveblogBuilder/ArticleLinkPreview';

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center',
    width: '100%',
  },
});

const ArticleLink = ({
  classes, articleLinkURLChange: onURLChange, data, onChange, onFocus, context,
  position,
}) => {
  useEffect(() => {
    if (data.url) {
      onURLChange([context, position, data.url]);
    }
  }, [data.url]);
  return (<Grid container direction={'column'}>
    <FormGroup className={classes.root}>
      <TextField
        className={classes.input}
        defaultValue={data.url}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={'Insert URL here'}
        onFocus={onFocus}
      />
    </FormGroup>
    <ArticleLinkPreview {...data} />
  </Grid>);
};

ArticleLink.defaultProps = {
  data: { markup: '' },
  bodyMarkupFocus: null,
};

ArticleLink.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  articleLinkURLChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(() => ({}), { articleLinkURLChange: getOGdata })(ArticleLink));

