import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_FACEBOOK } from './defaults';

const deserializeFacebook = (options) => {
  const { facebook } = setDefaults(options, DEFAULTS_FACEBOOK);

  return {
    element: getNodeDeserializer({
      type: facebook.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: facebook.type,
          url,
        };
      },
      rules: [
        { className: facebook.rootProps.className },
      ],
      ...options?.facebook?.deserialize,
    }),
  };
};

export default deserializeFacebook;
