export const FIELD_TITLE = 'title';
export const FIELD_IMAGE = 'image';
export const FIELD_BODY = 'body';
export const FIELD_PRICE = 'price';
export const FIELD_BRAND = 'brand';
export const FIELD_MODEL = 'model';
export const FIELD_BEST_FOR = 'bestFor';
export const FIELD_RATING = 'rating';
export const FIELD_VENDOR_NAME = 'vendorName';
export const FIELD_VENDOR_LINK = 'vendorLink';
export const FIELD_PRODUCT_ID = 'productId';
export const FIELD_PRODUCT_ID_TYPE = 'productIdType';
export const FIELD_PRICE_COMPARISON = 'priceComparison';
export const FIELD_PRICE_COMPARISON_PROVIDER = 'priceComparisonProvider';

export const PRODUCT_PAYLOAD_FIELDS = [
  FIELD_TITLE,
  FIELD_IMAGE,
  FIELD_BODY,
  FIELD_PRICE,
  FIELD_VENDOR_NAME,
  FIELD_VENDOR_LINK,
  FIELD_PRICE_COMPARISON,
];

export const PRODUCT_EXTRA_FIELDS = [
  FIELD_BRAND,
  FIELD_MODEL,
  FIELD_BEST_FOR,
  FIELD_PRODUCT_ID,
  FIELD_PRODUCT_ID_TYPE,
  FIELD_RATING,
  FIELD_PRICE_COMPARISON_PROVIDER,
  "information"
];

// Non data fields for API logic
export const FIELD_PRODUCT_STATS = 'stats';

export const PRODUCT_API_FIELDS = [
  FIELD_PRODUCT_STATS,
];

export const PRODUCT_FIELD_MAPPING = [
  [FIELD_TITLE, 'name', 'Title'],
  [FIELD_IMAGE, 'field_image', 'Image'],
  [FIELD_BODY, 'field_description', 'Description'],
  [FIELD_PRICE, 'field_price', 'Price'],
  [FIELD_PRICE_COMPARISON, 'field_price_comparison', 'Price Comparison'],
  [FIELD_VENDOR_NAME, 'field_vendor', 'Vendor'],
  [FIELD_VENDOR_LINK, 'field_link', 'Vendor Link'],
  [FIELD_BRAND, FIELD_BRAND, 'Brand'],
  [FIELD_MODEL, FIELD_MODEL, 'Model'],
  [FIELD_BEST_FOR, FIELD_BEST_FOR, 'Best For'],
  [FIELD_RATING, FIELD_RATING, 'Rating'],
  [FIELD_PRODUCT_ID, FIELD_PRODUCT_ID, 'Product Id'],
  ["information", "information"],
  [FIELD_PRODUCT_ID_TYPE, FIELD_PRODUCT_ID_TYPE, 'Product Id Type'],
  [FIELD_PRICE_COMPARISON_PROVIDER, FIELD_PRICE_COMPARISON_PROVIDER, 'Price Comparison Provider'],
  [FIELD_PRODUCT_STATS, 'productStats', 'Link Checker Results'],
];
