import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { formatWithCommas } from '../../utils/numberHelper';

import DashboardCard, { DashboardCardHeader, DashboardCardContent } from '../common/dashboard/DashboardCard';
import ProductGfx from '../../assets/ProductGfx';
import ProductArticleGfx from '../../assets/ProductArticleGfx';

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '&:first-child': {
      borderRight: `1px solid ${theme.palette.border.primary.main}`,
    },
  },
  number: {
    fontFamily: theme.typography.fontFamily,
    color: theme.typography.color.primary,
    fontSize: '48px',
    fontWeight: 300,
  },
  graphicProducts: {
    width: 80,
    margin: `${theme.spacing(1)}px auto`,
  },
  graphicArticles: {
    width: 64,
    margin: `0 auto ${theme.spacing(1)}px auto`,
  },
});

const Products = (props) => {
  const {
    classes,
    totalProducts,
    totalArticles,
  } = props;
  return (
    <DashboardCard>
      <DashboardCardHeader title={'Products'} />
      <DashboardCardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} className={classes.content}>
            <div className={classes.graphicProducts}><ProductGfx /></div>
            <span className={classes.number}>{formatWithCommas(totalProducts)}</span>
            <Typography variant={'caption'} color={'primary'}>Products</Typography>
          </Grid>
          <Grid item xs={6} className={classes.content}>
            <div className={classes.graphicArticles}><ProductArticleGfx /></div>
            <span className={classes.number}>{formatWithCommas(totalArticles)}</span>
            <Typography variant={'caption'} color={'primary'}>Product Articles</Typography>
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  totalProducts: PropTypes.number,
  totalArticles: PropTypes.number,
};

Products.defaultProps = {
  totalProducts: 0,
  totalArticles: 0,
};

export default withStyles(styles)(connect(
  ({
    affiliate: { products: { total: totalProducts }, productArticles: totalArticles },
  }) => ({ totalProducts, totalArticles }),
  {},
)(Products));
