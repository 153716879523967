import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TiktokIcon = props => (
  <SvgIcon {...props} viewBox={'0 0 512 512'}>
    <path d="M267 181.7c0 177.6 0 180.9-2 189.2-3.7 15.1-13.4 30.5-25.5 40.4-7.6 6.1-21.9 13.1-31.2 15.1-9 2-23.6 2-32.8.1-3.9-.8-11.5-3.7-17.3-6.5-17.8-8.8-31.3-24.3-37.8-43.5-3.6-10.6-4.5-28.5-2-39.8 2-9 7.7-21 13.9-29.4 8.9-11.9 25.2-22.8 40.7-26.9 8.6-2.4 29.1-2.5 37.3-.2l5.7 1.6V197.1l-3.7-.8c-2.1-.4-11.7-.8-21.3-.8-20.1.1-32.9 2.1-50.8 8.1-24.1 8.1-42.6 19.8-61.7 38.9-18.9 18.8-29.7 35.8-37.9 59.4-6.7 19.1-8 27.9-8 51.6 0 17.9.3 22.4 2.2 30.8 7.1 31.7 21 57.5 43.2 79.7 22.7 22.6 49.7 37.2 82 44.1 14.3 3.1 45.3 3.4 60.5.5 70.9-13.2 124.3-71.4 130.4-142.2.5-5.5.6-52.5.3-104.3l-.5-94.3 6.4 4.7c30.3 22.3 70.8 36.4 112.2 39.1l10.7.7v-84l-10.7-.6c-25.7-1.4-51.4-12-72.7-29.9-18.4-15.4-31.2-34.6-39.1-58.1-3.2-9.7-6.5-27.4-6.5-35.1V1h-84v180.7z"/>
  </SvgIcon>
);

export default TiktokIcon;
