import React from 'react';
import PropTypes from 'prop-types';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

import ButtonGroup from '../../../ui/buttons/ButtonGroup';
import IconButton from '../../../ui/buttons/IconButton';

const NONE = 'none';
const LEFT = 'left';
const CENTER = 'center';
const RIGHT = 'right';

const getToggleValue = (current, value) => {
  if (current === value) {
    return NONE;
  }
  return value;
};

const AlignmentToggle = ({
  disableCenter, align, onSelect,
}) => (
  <ButtonGroup variant={'text'} color={'default'}>
    <IconButton
      active={align === LEFT}
      color={'primary'}
      onClick={() => onSelect(getToggleValue(align, LEFT))}
      disableRipple
    ><FormatAlignLeftIcon /></IconButton>
    {!disableCenter && <IconButton
      active={align === CENTER}
      color={'primary'}
      onClick={() => onSelect(getToggleValue(align, CENTER))}
      disableRipple
    ><FormatAlignCenterIcon /></IconButton>}
    <IconButton
      active={align === RIGHT}
      color={'primary'}
      onClick={() => onSelect(getToggleValue(align, RIGHT))}
      disableRipple
    ><FormatAlignRightIcon /></IconButton>
  </ButtonGroup>
);

AlignmentToggle.propTypes = {
  onSelect: PropTypes.func.isRequired,
  align: PropTypes.string,
  disableCenter: PropTypes.bool,
};

AlignmentToggle.defaultProps = {
  disableCenter: false,
  align: NONE,
};

export default AlignmentToggle;
