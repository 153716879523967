import { setDefaults } from '@udecode/slate-plugins';
import renderElementParallax from './renderElementParallax';
import { DEFAULTS_PARALLAX } from './defaults';

const ParallaxPlugin = (options) => {
  const { parallax } = setDefaults(options, DEFAULTS_PARALLAX);
  return ({
    renderElement: renderElementParallax(options),
    voidTypes: [parallax.type],
  });
};

export default ParallaxPlugin;
