import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';

import TextButton from '../../ui/buttons/TextButton';
import DroppableWrapper from './LiveblogWrapper';

const LiveblogEmpty = connect(() => ({}))(({setImageDialogOpen, id, currentComponentLiveblogRequest}) => {
  return (<DroppableWrapper>
    <Typography>Please <TextButton
      variant={'contained'}
      size={'small'}
      onClick={() => {
        currentComponentLiveblogRequest(id);
        setImageDialogOpen(true);
      }}
    >Select</TextButton> a live blog from the library</Typography>
  </DroppableWrapper>)
});

export default LiveblogEmpty;
