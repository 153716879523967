import {
  ARTICLEHERO, ARTICLEX1,
  ARTICLEX2,
  HEADSHOTSX4,
  POSTER,
  SPORT_TITLE,
  STRAPLINE,
  TEXTSPLASH,
} from './editionComponents';
import {
  FIELD_ARTICLE_REF, FIELD_AUTHOR_IMAGE, FIELD_AUTHOR_NAME, FIELD_TITLE,
} from './editionFields';
import { IMAGE } from '../builder/builder';

export const TEMPLATE_POSTER = 'Poster Front Page';
export const TEMPLATE_ONE_STORY = 'One Story Front Page';
export const TEMPLATE_TWO_STORY = 'Two Story Front Page';
export const TEMPLATE_SPORT_SPLASH = 'Sport Splash';
export const TEMPLATE_SPORT = 'Sport';
export const SECTION_TEMPLATES = {
  [TEMPLATE_POSTER]: 'Poster Front Page',
  [TEMPLATE_ONE_STORY]: 'One Story Front Page',
  [TEMPLATE_TWO_STORY]: 'Two Story Front Page',
  [TEMPLATE_SPORT_SPLASH]: 'Sport Splash',
  [TEMPLATE_SPORT]: 'Sport',
};
const BaseImageData = {
  type: IMAGE,
};

export const HEADSHOTSX4_ARTICLE = {
  [FIELD_AUTHOR_NAME]: 'Author Name',
  [FIELD_AUTHOR_IMAGE]: {
    ...BaseImageData,
    data: {
      url: 'https://via.placeholder.com/150x100?text=3x2',
      alt: 'Fill the author image',
    },
  },
  [FIELD_TITLE]: 'Headline',
  [FIELD_ARTICLE_REF]: null,
};
const TEXT_SPLASH_DATA = {
  headline: 'Placeholder headline',
  subHeadline: 'Placeholder headline',
  author: 'Author Name',
  authorLocation: 'Author Location',
  body: 'Placeholder body',
};

export const TEMPLATE_DEFAULTS = {
  [TEMPLATE_POSTER]: [
    { type: POSTER, name: 'Poster' },
  ],
  [TEMPLATE_ONE_STORY]: [
    { type: STRAPLINE, name: 'Strapline' },
    {
      type: HEADSHOTSX4,
      name: 'Four headshots',
      headshots: [
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
      ],
    },
    {
      type: ARTICLEX1,
      name: 'One article',
      caption: 'Placeholder caption',
      body: '<p>Content here</p>',
      author: 'Author Name',
      authorLocation: 'Author Location',
      headline: 'Placeholder headline',
      image: {
        ...BaseImageData,
        data: {
          url: 'https://via.placeholder.com/750x250?text=3x2',
        },
      },
    },
  ],
  [TEMPLATE_TWO_STORY]: [
    { type: STRAPLINE, name: 'Strapline' },
    {
      type: HEADSHOTSX4,
      name: 'Skyline',
      headshots: [
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
        HEADSHOTSX4_ARTICLE,
      ],
    },
    {
      type: ARTICLEHERO,
      name: 'Picture Article',
      headline: 'Placeholder headline',
      caption: 'Placeholder caption',
      image: {
        ...BaseImageData,
        data: {
          url: 'https://via.placeholder.com/750x250?text=3x2',
          alt: 'Fill the hero image',
        },
      },
    },
    { type: TEXTSPLASH, name: 'Splash', ...TEXT_SPLASH_DATA },
  ],
  [TEMPLATE_SPORT_SPLASH]: [
    {
      type: STRAPLINE,
      name: 'Strapline',
      headline: 'Headline text',
      headlinePrefix: 'Headline prefix',
      articleRef: null,
    },
    SPORT_TITLE,
    {
      type: POSTER,
      name: 'Poster',
      image: {
        ...BaseImageData,
        data: {
          url: 'https://via.placeholder.com/2130x2500?text=4.26x5',
          alt: 'Poster image',
        },
      },
    },
  ],
  [TEMPLATE_SPORT]: [
    {
      type: STRAPLINE,
      name: 'Strapline',
      headline: 'Headline text',
      headlinePrefix: 'Headline prefix',
      articleRef: null,
    },
    SPORT_TITLE,
    {
      type: ARTICLEX2,
      name: 'Two articles',
      articles: [
        {
          headline: 'Headline 1',
          caption: 'Hero image caption',
          image: {
            type: 'image',
            data: {
              url: 'https://via.placeholder.com/800x1000?text=4x5',
            },
          },
          articleRef: null,
        },
        {
          headline: 'Headline 2',
          author: 'Author 2',
          body: '<p>Article 2 body text</p>',
        },
      ],
    },
    { type: TEXTSPLASH, name: 'Text Splash', data: TEXT_SPLASH_DATA },
  ],
};
