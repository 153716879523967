import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';


import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';

import PublishingStatus from '../../../contexts/content/PublishingStatus';
import { FIELD_SCHEDULED, FIELD_PUBLISH, FIELD_UNPUBLISH } from '../../../../constants/story/storyFields';

const Status = (props) => {
  const {
    scheduled,
    publish,
    unpublish,
  } = props;
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    const nowTimestamp = moment().unix();
    setIsPublished(publish < nowTimestamp && unpublish > nowTimestamp);
  }, [publish, unpublish]);

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Status</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <PublishingStatus scheduled={scheduled} isPublished={isPublished} />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

Status.propTypes = {
  scheduled: PropTypes.number,
  publish: PropTypes.number,
  unpublish: PropTypes.number,
};

Status.defaultProps = {
  scheduled: 0,
  publish: 0,
  unpublish: 0,
};

export default connect(
  ({
    story: { [FIELD_SCHEDULED]: scheduled, [FIELD_PUBLISH]: publish, [FIELD_UNPUBLISH]: unpublish },
  }) => ({ scheduled, publish, unpublish }),
  {},
)(Status);
