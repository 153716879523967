import { createSelector } from 'reselect';

import {
  FIELD_HEADLINE,
  FIELD_HERO_GALLERY, FIELD_HERO_IMAGE, FIELD_HERO_VIDEO,
  FIELD_SECTIONS,
} from '../../constants/article/articleFields';
import {emptyObject} from "../../utils/helper";

export const getHeadline = state => state.dataState?.[FIELD_HEADLINE] || null;
export const getSections = state => state.dataState?.[FIELD_SECTIONS] || null;
export const getHeroImage = state => state.dataState?.[FIELD_HERO_IMAGE] || null;
export const getHeroGallery = state => state.dataState?.[FIELD_HERO_GALLERY] || null;
export const getHeroVideo = state => state.dataState?.[FIELD_HERO_VIDEO] || null;

export const makeGetIsArticleValid = () => createSelector(
  [getHeadline, getSections, getHeroImage, getHeroGallery, getHeroVideo],
  (headline, sections, heroImage, heroGallery, heroVideo) => {
    const heroes = [];
    if (heroImage?.data?.mid) {
      heroes.push(heroImage.data);
    }
    if (heroVideo?.data?.mid) {
      heroes.push(heroVideo.data);
    }
    if (heroGallery?.data?.mid) {
      heroes.push(heroGallery.data);
    }
    return (headline && headline.length > 0 && sections?.[0] && heroes.length > 0);
  },
);

export const makeGetDisplayHeroBadge = type => createSelector(
  [getHeroImage, getHeroGallery, getHeroVideo],
  (heroImage, heroGallery, heroVideo) => {
    const validVideo = heroVideo?.data && !emptyObject(heroVideo.data);
    const validGallery = heroGallery?.data && !emptyObject(heroGallery.data)
      && /published/i.test(heroGallery.data.workflow.name) && !validVideo;
    switch (type) {
      case FIELD_HERO_VIDEO:
        return validVideo;
      case FIELD_HERO_GALLERY:
        return validGallery;
      case FIELD_HERO_IMAGE:
        return heroImage?.data && !emptyObject(heroImage.data) && !validGallery && !validVideo;
      default:
        console.warn('Unknown type for badge');
        return false;
    }
  },
);

export const makeGetSectionToggleDisabled = field => createSelector(
  [getSections],
  sections => (Array.isArray(sections)
    ? sections.filter(section => !!section).some(({ extra }) => extra && extra[field])
    : false
  ),
);
