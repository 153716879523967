import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import LiveblogEmpty from './LiveblogEmpty';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
  },
  liveblogContainer: {
    padding: theme.spacing(2),
  },
  removeComponent: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  active: {
    borderColor: theme.palette.primary.main,
  },
});


const Liveblog = ({
  classes, onEnter, setLiveblogDialogOpen, id, currentComponentLiveblogRequest, data,
}) => {
  const [activeComponent, setActiveComponent] = useState(false);
  useEffect(() => {
    const watchEnter = (e) => {
      if (activeComponent && e.keyCode === 13) {
        setActiveComponent(false);
        onEnter();
      }
    };
    const watchClick = (e) => {
      if (e.target.id !== `image${id}`) {
        setActiveComponent(false);
      }
    };
    document.querySelector('body').addEventListener('click', watchClick);
    document.querySelector('body').addEventListener('keypress', watchEnter);
    return () => {
      document.querySelector('body').removeEventListener('keypress', watchEnter);
      document.querySelector('body').removeEventListener('keypress', watchClick);
    };
  }, [activeComponent]);

  const clsRoots = [classes.root];
  if (activeComponent) {
    clsRoots.push(classes.active);
  }

  return (<Grid className={clsRoots.join(' ')} onClick={() => setActiveComponent(true)}>
    {!data.id && <LiveblogEmpty
      currentComponentLiveblogRequest={currentComponentLiveblogRequest}
      setImageDialogOpen={setLiveblogDialogOpen}
      id={id}
    />
    }
    {data.id && <div className={classes.liveblogContainer} >
      <Typography variant={'h5'}>{data.id} - {data.name}</Typography>
    </div>}
  </Grid>);
};

Liveblog.defaultProps = {
  data: null,
  bodyMarkupFocus: null,
};

Liveblog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
  setLiveblogDialogOpen: PropTypes.func.isRequired,
  currentComponentLiveblogRequest: PropTypes.func.isRequired,
  onEnter: PropTypes.func.isRequired,

};

export default withStyles(styles)(Liveblog);

