import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  CONTEXT_STORY, CONTEXT_PAGE_SELECTOR,
  CONTEXT_ARTICLE, CONTEXT_ARTICLE_SELECTOR,
} from '../../constants/contexts';
import { setStoryContext } from '../../actions/story';

import ContextBar, { ContextBarHeader } from '../common/context/ContextBar';
import { Tab, Tabs } from '../ui/Tabs';

import StorySettings from './storySidebar/StorySettings';
import StoryPageSelector from './storySidebar/StoryPageSelector';
import ArticleSelector from '../contexts/ArticleSelector';
import StoryBookend from './storySidebar/StoryBookend';
import Error from '../contexts/Error';

const contexts = [
  CONTEXT_STORY,
  CONTEXT_PAGE_SELECTOR,
];

const bookendContexts = [
  CONTEXT_ARTICLE,
  CONTEXT_ARTICLE_SELECTOR,
];

const getComponentFromContext = (type) => {
  switch (type) {
    case CONTEXT_STORY:
      return StorySettings;
    case CONTEXT_PAGE_SELECTOR:
      return StoryPageSelector;
    case CONTEXT_ARTICLE_SELECTOR:
      return ArticleSelector;
    case CONTEXT_ARTICLE:
      return StoryBookend;
    default:
      return Error;
  }
};

getComponentFromContext.defaultProps = {
  data: {},
};

const StorySidebar = ({ type, setStoryContext: setContext }) => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const ContextComponent = getComponentFromContext(type);
  return (
    <ContextBar>
      <ContextBarHeader>
        {contexts.indexOf(type) !== -1 && <Tabs
          value={contexts.indexOf(type)}
          onChange={(e, val) => {
            setContext(contexts[val]);
          }}
          indicatorColor={'primary'}
          textColor={'primary'}
          variant={'fullWidth'}
        >
          <Tab label={'Story'} />
          <Tab label={'Pages'} />
        </Tabs>}
        {bookendContexts.indexOf(type) !== -1 && <Tabs
          value={bookendContexts.indexOf(type)}
          onChange={(e, val) => {
            setContext(bookendContexts[val]);
          }}
          indicatorColor={'primary'}
          textColor={'primary'}
          variant={'fullWidth'}
        >
          <Tab label={'Bookend'} />
          <Tab label={'Articles'} />
        </Tabs>}
      </ContextBarHeader>
      <ContextComponent
        page={page}
        query={query}
        setQuery={setQuery}
        setPage={setPage}
      />
    </ContextBar>
  );
};

StorySidebar.propTypes = {
  setStoryContext: PropTypes.func.isRequired,
  type: PropTypes.string,
};

StorySidebar.defaultProps = {
  type: CONTEXT_STORY,
};

export default connect(
  ({ story: { context: { type } } }) => ({ type }),
  { setStoryContext },
)(StorySidebar);
