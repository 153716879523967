import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';
import MarkupField from '../../../../../ui/MarkupField';

import { PROP_MARKUP, PROP_SHOW_PROMPT, PROP_HIDE_PROMPT } from '../defaults';

const HideAndShowElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Hide and Show'}
        Icon={VisibilityOff}
        contentEditable={false}
        element={element}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MarkupField
              label={'Content'}
              data={element[PROP_MARKUP] || null}
              onChange={(value) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_MARKUP]: value }, { at: path });
              }}
              nested
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Show prompt'}
              margin={'none'}
              placeholder={'Insert show prompt'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_SHOW_PROMPT]: e.target.value }, { at: path });
              }}
              value={element[PROP_SHOW_PROMPT] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Hide prompt'}
              margin={'none'}
              placeholder={'Insert hide prompt'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_HIDE_PROMPT]: e.target.value }, { at: path });
              }}
              value={element[PROP_HIDE_PROMPT] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

HideAndShowElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default HideAndShowElement;
