import React from 'react';
import PropTypes from 'prop-types';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import MarkupField from '../../../ui/MarkupField';

const DESCRIPTION = 'description';

const DescriptionSettings = (props) => {
  const { component, title, componentPropOnChange } = props;
  return (
    <ContextPanel>
      {title && <ContextPanelSummary>
        <ContextPanelHeading>{title}</ContextPanelHeading>
      </ContextPanelSummary>}
      <ContextPanelDetails variant={'full'}>
        <MarkupField
          label={'Description'}
          data={component[DESCRIPTION] || ''}
          onChange={(value) => componentPropOnChange(component.id, DESCRIPTION, value)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

DescriptionSettings.propTypes = {
  title: PropTypes.string,
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

DescriptionSettings.defaultProps = {
  title: 'Settings',
};

export default DescriptionSettings;
