import React from 'react';

import { ContextBarBody } from '../../common/context/ContextBar';

const StoryBookend = () => (
  <ContextBarBody>
  </ContextBarBody>
);

export default StoryBookend;
