export const WS_CONNECT = 'WS_CONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_RETRY_EXHAUSTED = 'WS_RETRY_EXHAUSTED';
export const WS_CLOSED = 'WS_CLOSED';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_PING_SERVER = 'WS_PING_SERVER';
export const WS_SEND_MESSAGE = 'WS_SEND_MESSAGE';
export const WS_MESSAGE_SENT = 'WS_MESSAGE_SENT';
export const WS_ID = 'WS_ID';

export const WS_ARTICLE_LIST = 'WS_ARTICLE_LIST';
export const WS_LIVEBLOG_LIST = 'WS_LIVEBLOG_LIST';
export const WS_STORY_LIST = 'WS_STORY_LIST';
export const WS_VOCAB_LIST = 'WS_VOCAB_LIST';
export const WS_PRODUCT_LIST = 'WS_PRODUCT_LIST';
export const WS_EVENT_LIST = 'WS_EVENT_LIST';
export const WS_LIVEBLOG_CONTAINER = 'WS_LIVEBLOG_CONTAINER';
export const WS_LAYOUT = 'WS_LAYOUT';
