import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';

const styles = () => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexDirection: 'column',
    flex: 1,
  },
});

const Loading = ({ classes, message, children }) => (
  <Page>
    <PageContent>
      <Grid item xs={12}>
        <Typography className={classes.loading} align={'center'}>{message}</Typography>
        {children}
      </Grid>
    </PageContent>
  </Page>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  message: PropTypes.string,
};

Loading.defaultProps = {
  children: null,
  message: 'Loading...',
};

export default withStyles(styles)(Loading);
