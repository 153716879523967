import { ofType } from 'redux-observable';
import {
  debounceTime,
  filter, map, mapTo, merge, mergeMap, switchMap, takeUntil, withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import apiCatchError, { showSuccessNotification } from '../helper/notification';
import { ERROR, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import {
  PRODUCT_COMPARISON_REJECTED, PRODUCT_COMPARISON_REQUEST, PRODUCT_COMPARISON_SUCCESS,
  PRODUCT_FETCH, PRODUCT_FETCH_SUCCESS, PRODUCT_FETCH_REJECTED,
  PRODUCT_LIST_FETCH, PRODUCT_LIST_FETCH_REJECTED, PRODUCT_LIST_FETCH_SUCCESS, PRODUCT_LIST_DISPOSE,
  PRODUCT_SAVE, PRODUCT_SAVE_SUCCESS, PRODUCT_SAVE_READY,
  PRODUCT_AFFILIATE_LINK_CHECK, PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS,
  PRODUCT_AFFILIATE_LINK_CHECK_REJECTED, PRODUCT_SAVE_REJECTED,
} from '../../constants/actionTypes/product';
import { WS_PRODUCT_LIST } from '../../constants/actionTypes/ws';

import { queryStringify } from '../../utils/urlHelper';
import { saveProductRequest } from './helper';
import { ARTICLE_PRODUCTS_SAVE_HANDLED } from '../../constants/actionTypes/article';
import { CONTEXT_DISPOSE } from '../../constants/actionTypes';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const fetchProduct = action$ => action$.pipe(
  ofType(PRODUCT_FETCH),
  switchMap(({ value }) => ajax.getJSON(`/api/product/${value}`).pipe(
    map(response => ({ type: PRODUCT_FETCH_SUCCESS, value: response })),
    apiCatchError(PRODUCT_FETCH_REJECTED),
  )),
);

export const fetchProductList = (action$, state$) => {
  let cachedParams;
  return action$.pipe(
    ofType(PRODUCT_LIST_FETCH),
    switchMap(action => of(action).pipe(
      merge(action$.pipe(
        ofType(WS_PRODUCT_LIST, PUBLICATION_SELECTED),
        filter(() => !!cachedParams),
        map(() => ({
          type: PRODUCT_LIST_FETCH,
          value: cachedParams,
        })),
        takeUntil(action$.pipe(ofType(PRODUCT_LIST_DISPOSE))),
      )),
      takeUntil(action$.pipe(ofType(PRODUCT_LIST_DISPOSE))),
      withLatestFrom(state$),
      switchMap(([
        { value },
        { frame: { selectedPublication: { domain } } },
      ]) => {
        cachedParams = value;
        return ajax.getJSON(`/api/products/${domain}?${queryStringify(value)}`).pipe(
          map(response => ({ type: PRODUCT_LIST_FETCH_SUCCESS, value: response })),
          apiCatchError(PRODUCT_LIST_FETCH_REJECTED),
        );
      }),
    )),
  );
};

export const checkAffiliateProductLink = (action$, state$) => action$.pipe(
  ofType(PRODUCT_AFFILIATE_LINK_CHECK),
  debounceTime(500),
  withLatestFrom(state$),
  switchMap(([
    { value: link },
    { frame: { selectedPublication: { domain } } },
  ]) => ajax.getJSON(`/api/product-link-check/${domain}?link=${link}`).pipe(
    mergeMap(response =>
      of({ type: PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS, value: { link, data: response } }),
    ),
    apiCatchError(PRODUCT_AFFILIATE_LINK_CHECK_REJECTED),
  )),
);

export const requestProductCompare = (action$, state$) => action$.pipe(
  ofType(PRODUCT_COMPARISON_REQUEST),
  withLatestFrom(state$),
  filter(([, { frame: { selectedPublication: { publicationConfig } } }]) =>
    !!publicationConfig.m101,
  ),
  mergeMap(([
    { value: link },
    { frame: { selectedPublication: { publicationConfig: { m101 } } } },
  ]) => {
    const payload = {
      uri: `${m101.api}/sites/${m101.shopId}/compare/prices/${m101.market}/by/plainlink`,
      apiKey: m101.key,
      link,
    };
    return ajax.post('/api/price-comparison', payload, { 'Content-Type': 'application/json' }).pipe(
      map(({ response }) => ({
        type: PRODUCT_COMPARISON_SUCCESS,
        value: {
          link,
          items: response,
        },
      })),
    );
  }),
  apiCatchError(PRODUCT_COMPARISON_REJECTED),
);

export const validateSaveProduct = (action$, state$) => action$.pipe(
  ofType(PRODUCT_SAVE),
  withLatestFrom(state$, (a, b) => b),
  map(({ product: { title } }) => {
    if (title === '') {
      return {
        type: SHOW_NOTIFICATION,
        value: {
          message: 'Missing product title, please fill it in.',
          variant: ERROR,
        },
      };
    }
    return {
      type: PRODUCT_SAVE_READY,
    };
  }),
);

export const saveProduct = (action$, state$) => action$.pipe(
  ofType(PRODUCT_SAVE_READY),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({ localState, serverState, frame: { selectedPublication: { publicationConfig } },
  }) => saveProductRequest(localState, serverState, publicationConfig)
    .pipe(
      map(({ response }) => ({ type: PRODUCT_SAVE_SUCCESS, value: response })),
      apiCatchError(PRODUCT_SAVE_REJECTED),
    ),
  ),
);

export const productSavedNotification = action$ => action$.pipe(
  ofType(PRODUCT_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Product is successfully saved.')),
);

export const disposeSidebarContextAfterProductSave = action$ => action$.pipe(
  ofType(ARTICLE_PRODUCTS_SAVE_HANDLED),
  mapTo({ type: CONTEXT_DISPOSE }),
);
