import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import DialogContent from '@material-ui/core/DialogContent/DialogContent';

import Dialog from './common/Dialog';
import ImageForm from '../embed/ImageForm';

import { onResetEditMediaRequest } from '../../actions/dialog';

const ImageEditDialog = (props) => {
  const {
    onSaveImage, mediaId, canEdit, file,
    onResetEditMediaRequest: resetEditedImage,
    ...rest
  } = props;

  // clear the edited image on dialog dispose.
  useEffect(() => () => resetEditedImage(), [resetEditedImage]);

  let dialogTitle = 'Add new image';
  if (mediaId) {
    dialogTitle = canEdit ? 'Edit image' : 'Image details';
  }

  return (
    <Dialog title={dialogTitle} maxWidth="sm" {...rest} >
      <DialogContent>
        <Grid item xs={12}>
          <ImageForm
            file={file}
            mediaId={mediaId}
            canEdit={canEdit}
            onSaveImage={onSaveImage}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

ImageEditDialog.propTypes = {
  onSaveImage: PropTypes.func.isRequired,
  onResetEditMediaRequest: PropTypes.func.isRequired,
  mediaId: PropTypes.number,
  canEdit: PropTypes.bool,
  file: PropTypes.object,
};

ImageEditDialog.defaultProps = {
  mediaId: null,
  file: null,
  canEdit: false,
};

export default connect(() => ({}), { onResetEditMediaRequest })(ImageEditDialog);
