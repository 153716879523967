import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import FeedList from './components/FeedList';
import { handleTimelineFeedDrop, removeTimelineItem } from '../../actions/feed';

const getDayItems = (dayStart, items) => ({
  date: dayStart,
  items: items.filter(
    item => item.timeline.date >= dayStart.valueOf() && item.timeline.date < moment(dayStart).add(1, 'days').valueOf(),
  ),
});

const articleDataProcessor = (date, id) => index => ({ article, source }) => ({
  id,
  date,
  item: article,
  source,
  index,
});

const ArticleTimelineFeed = (props) => {
  const {
    id, feed, limit, includeTomorrow,
    handleTimelineFeedDrop: handleDrop,
    removeTimelineItem: removeItem,
  } = props;
  const { items = [] } = feed;
  const now = moment().startOf('day');
  const days = [];
  // @todo handle limit = 0
  if (limit > 0) {
    for (let i = 0; i < limit; i += 1) {
      days.push(getDayItems(moment(now).add(i * -1, 'days'), items));
    }
  }
  return (
    <>
      {includeTomorrow && <div key={moment(now).add(1, 'days').unix()}>
        <Typography variant={'h2'}>{moment(now).add(1, 'days').format('ll')}</Typography>
        <FeedList
          items={getDayItems(moment(now).add(1, 'days'), items).items}
          dropProcessor={articleDataProcessor(moment(now).add(1, 'days').valueOf(), id)}
          handleDrop={handleDrop}
          removeItem={item => removeItem(id, item.timeline.date)}
        />
      </div>}
      {days.map(({ date, items: dayItems }) => (
        <div key={date.unix()}>
          <Typography variant={'h2'}>{date.format('ll')}</Typography>
          <FeedList
            items={dayItems}
            dropProcessor={articleDataProcessor(date.valueOf(), id)}
            handleDrop={handleDrop}
            removeItem={item => removeItem(id, item.timeline.date)}
          />
        </div>
      ))}
    </>
  );
};

ArticleTimelineFeed.propTypes = {
  id: PropTypes.string.isRequired,
  feed: PropTypes.object.isRequired,
  handleTimelineFeedDrop: PropTypes.func.isRequired,
  removeTimelineItem: PropTypes.func.isRequired,
  limit: PropTypes.number,
  includeTomorrow: PropTypes.bool,
};

ArticleTimelineFeed.defaultProps = {
  limit: 0,
  includeTomorrow: false,
};

export default connect(
  () => ({}),
  { handleTimelineFeedDrop, removeTimelineItem },
)(ArticleTimelineFeed);
