import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import ImageField from './common/ImageField';
import { FieldWrapper } from './common/GridWrapper';
import Select from '../../../ui/Select';

const PROMPT_LABEL = 'Prompt';
const OPT1_LABEL = 'Option 1';
const OPT2_LABEL = 'Option 2';
const OPT3_LABEL = 'Option 3';
const OPT4_LABEL = 'Option 4';
const RESULT_LABEL = 'Result Category';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA'

const RESULT_OPTIONS = {
  0: 'Category 1',
  1: 'Category 2',
  2: 'Category 3',
  3: 'Category 4',
};

const TemplatePoll = (props) => {
  const {
    id, prompt, image, options, results, attachment, attachmentCTA,
    updateStoryPageProp: updateProp,
  } = props;
  while (options.length < 4) {
    options.push('');
  }
  while (results.length < 4) {
    results.push('');
  }
  // @todo rework with loop for options
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={'Add cover image'}
          onSelect={value => updateProp(id, 'image', {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateProp(id, 'image', null)}
          image={image}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={PROMPT_LABEL}
          value={prompt}
          onChange={e => updateProp(id, 'prompt', e.target.value)}
        />
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label={OPT1_LABEL}
              value={options[0]}
              onChange={e => updateProp(id, 'options', [
                e.target.value,
                options[1],
                options[2],
                options[3],
              ])}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label={RESULT_LABEL}
              value={results[0]}
              onChange={e => updateProp(id, 'results', [
                e.target.value,
                results[1],
                results[2],
                results[3],
              ])}
              items={RESULT_OPTIONS}
              margin={'normal'}
              emptyValue={'none'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label={OPT2_LABEL}
              value={options[1]}
              onChange={e => updateProp(id, 'options', [
                options[0],
                e.target.value,
                options[2],
                options[3],
              ])}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label={RESULT_LABEL}
              value={results[1]}
              onChange={e => updateProp(id, 'results', [
                results[0],
                e.target.value,
                results[2],
                results[3],
              ])}
              items={RESULT_OPTIONS}
              margin={'normal'}
              emptyValue={'none'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label={OPT3_LABEL}
              value={options[2]}
              onChange={e => updateProp(id, 'options', [
                options[0],
                options[1],
                e.target.value,
                options[3],
              ])}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label={RESULT_LABEL}
              value={results[2]}
              onChange={e => updateProp(id, 'results', [
                results[0],
                results[1],
                e.target.value,
                results[3],
              ])}
              items={RESULT_OPTIONS}
              margin={'normal'}
              emptyValue={'none'}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label={OPT4_LABEL}
              value={options[3]}
              onChange={e => updateProp(id, 'options', [
                options[0],
                options[1],
                options[2],
                e.target.value,
              ])}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label={RESULT_LABEL}
              value={results[3]}
              onChange={e => updateProp(id, 'results', [
                results[0],
                results[1],
                results[2],
                e.target.value,
              ])}
              items={RESULT_OPTIONS}
              margin={'normal'}
              emptyValue={'none'}
            />
          </Grid>
        </Grid>
        <TextField
          label={ATTACHMENT_LABEL}
          placeholder={ATTACHMENT_PLACEHOLDER}
          value={attachment}
          onChange={e => updateProp(id, 'attachment', e.target.value)}
        />
        <TextField
          label={ATTACHMENT_CTA_LABEL}
          placeholder={ATTACHMENT_CTA_PLACEHOLDER}
          value={attachmentCTA}
          onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
        />
      </FieldWrapper>
    </Grid>
  );
};

TemplatePoll.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  options: PropTypes.array,
  results: PropTypes.array,
  image: PropTypes.object,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplatePoll.defaultProps = {
  prompt: '',
  options: [],
  results: [],
  image: null,
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplatePoll);
