import {
  ADD_MORE_TRANSLATION_MARKUP,
  REMOVE_ARTICLE_TRANSLATION_COMPONENT,
  SAVE_TRANSLATION_ARTICLE_COMPONENT,
  SET_FOCUS_TRANSLATION_COMPONENT_INDEX,
  SWITCH_BODY_TRANSLATION_COMPONENT,
  TRANSLATE_ARTICLE_REQUEST,
  TRANSLATE_INTERNAL_ARTICLE_REQUEST,
  TRANSLATION_LIST_PAGE_LOADED,
  TRANSLATION_LIST_REQUEST,
} from '../constants/actionTypes';

export const addTranslationMoreMarkup = value => ({ type: ADD_MORE_TRANSLATION_MARKUP, value });
export const removeTranslationArticleComponent = value => ({ type: REMOVE_ARTICLE_TRANSLATION_COMPONENT, value });
export const saveTranslationMarkupData = value => ({ type: SAVE_TRANSLATION_ARTICLE_COMPONENT, value });
export const setTranslationFocusComponentIndex = value => ({ type: SET_FOCUS_TRANSLATION_COMPONENT_INDEX, value });
export const switchTranslationBodyComponent = value => ({ type: SWITCH_BODY_TRANSLATION_COMPONENT, value });
export const fetchList = value => ({ type: TRANSLATION_LIST_REQUEST, value });
export const translateArticle = value => ({ type: TRANSLATE_ARTICLE_REQUEST, value });
export const translateInternalArticle = value => ({ type: TRANSLATE_INTERNAL_ARTICLE_REQUEST, value });
export const translationPageLoaded = () => ({ type: TRANSLATION_LIST_PAGE_LOADED });

