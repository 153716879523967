import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Import } from 'mdi-material-ui';

import { EDITION_TYPES, EDITION_IMPORT } from '../../constants/edition/edition';

import ButtonSelect from '../ui/buttons/ButtonSelect';
import Button from '../ui/buttons/Button';
import { PageToolbarButtonsStatic } from '../common/page/PageToolbar';
import AccessToggle from '../../access/AccessToggle';
import { CREATE_DAILY_EDITION } from '../../constants/permission';

const EditionListControlBar = (props) => {
  const { type, push: routeChange } = props;
  const types = Object.entries(EDITION_TYPES).map(([editionType, editionTypeTitle]) => (
    {
      link: `/edition/${editionType}`,
      title: editionTypeTitle,
    }
  ));
  return (
    <>
      <Grid container justify={'center'} alignItems={'center'}>
        <Typography variant={'body2'}>Manage</Typography><ButtonSelect active={EDITION_TYPES[type]} items={types} />
      </Grid>
      <AccessToggle restrictedTo={CREATE_DAILY_EDITION}>
        <PageToolbarButtonsStatic>
          <Button
            onClick={() => routeChange(`/edition/${EDITION_IMPORT}`)}
            variant={'outlined'}
            startIcon={<Import />}
          >Import Edition</Button>
          <Button
            onClick={() => routeChange(`/edition/${type}/new`)}
            variant={'contained'}
            startIcon={<AddIcon />}
          >Add Edition</Button>
        </PageToolbarButtonsStatic>
      </AccessToggle>
    </>
  );
};

EditionListControlBar.propTypes = {
  type: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { push },
)(EditionListControlBar);
