import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';

import TwitterEmbed from '../../../liveblog/liveblogBuilder/TwitterEmbed';
import Loader from '../../../ui/Loader';
import TextButton from '../../../ui/buttons/TextButton';
import { appendTwitterToBody, saveTwitterPostData } from '../../../../actions/liveblog';

const style = theme => ({
  root: {
    position: 'relative',
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const TwitterExplorerList = ({
  classes,
  twits,
  loading,
  appendTwitterToBody: onAppendTwitter,
  saveTwitterPostData: onSaveTwitterPost,
}) => (<List className={classes.root}>
  { loading && <Loader /> }
  { twits && twits.map(item => (<ListItem className={classes.item} key={item.id_str}>
    <Grid container>
      <Grid xs={12}>
        <TwitterEmbed id={item.id} data={{ id: item.id_str }} />
      </Grid>
      <Grid xs={12}>
        <Grid container justify={'space-between'}>
          <TextButton onClick={() => onAppendTwitter(`https://twitter.com/${item.user.screen_name}/status/${item.id_str}`)}>Insert</TextButton>
          <TextButton
            onClick={() => {
              onSaveTwitterPost(`https://twitter.com/${item.user.screen_name}/status/${item.id_str}`);
            }}
          >Publish</TextButton>
        </Grid>
      </Grid>
    </Grid>
  </ListItem>)) }
</List>)

TwitterExplorerList.defaultProps = {
  loading: false,
};

TwitterExplorerList.propTypes = {
  classes: PropTypes.object.isRequired,
  twits: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  appendTwitterToBody: PropTypes.func.isRequired,
  saveTwitterPostData: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(({
  twitter: { twits, loading },
}) => ({ twits, loading }),
{ appendTwitterToBody, saveTwitterPostData })(TwitterExplorerList));
