import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ImportExport from '@material-ui/icons/Translate';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { green } from '@material-ui/core/colors';
import { push } from 'connected-react-router';

import moment from 'moment';
import { fetchArticle, disposeFetchArticle } from '../../../actions/article';
import { fetchList, translateArticle, translationPageLoaded } from '../../../actions/translation';
import ArticleSearch from './ArticleSearchKeyUp';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  heading: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  searchRoot: {
    padding: theme.spacing(2),
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  pageLabel: {
    padding: theme.spacing(0, 1),
    color: theme.palette.grey.main,
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  imported: {
    color: green[800],
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  loadingRoot: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});

const Updated = withStyles({
  root: {
    whiteSpace: 'nowrap',
  },
})(({ timestamp, classes }) => <span className={classes.root}>{moment.unix(timestamp).format('lll')}</span>);

Updated.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

export const ImportAction = withStyles(styles)(connect(() => ({}), { push })((props) => {
  const { onClick, classes, imported, id, push: routeChange } = props;
  const [loading, setLoading] = useState(false);
  return (<div className={classes.wrapper}>
    <Fab
      classes={{ root: imported ? classes.imported : '' }}
      onClick={() => {
        if (imported) {
          routeChange(`/article/edit/${id}`);
        } else {
          onClick();
          setLoading(true);
        }
      }}
    >
      <ImportExport />
    </Fab>
    {loading && <CircularProgress size={68} className={classes.fabProgress} />}
  </div>);
}));

const ArticleListTranslation = (props) => {
  const {
    classes, articles, sections,
    fetchList: onFetchArticleList,
    translationPageLoaded: onLoad,
    translateArticle: onTranslateArticle,
    loading,
    publicationConfig,
  } = props;

  const [page, setPage] = useState(0);
  const [sourceId, setSourceId] = useState(null);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (sourceId) {
      onFetchArticleList({
        sourceId,
        base: publicationConfig.translationBaseSource,
        page,
        query,
      });
    }
  }, [sourceId, page, query]);

  useEffect(() => {
    if (sections.length > 1) {
      setSourceId(sections[0].sourceId);
    }
  }, [sections]);

  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Paper>
      <Grid className={classes.searchRoot} container justify={'space-between'} alignItems={'center'}>
        <FormControl className={classes.formControl}>
          { sourceId && <Select
            defaultValue={sourceId}
            label={'By Section'}
            onChange={(e) => {
              setSourceId(e.target.value);
              setPage(0);
            }}
          >
            { sections.filter(({ sourceId: legacyTermId }) => legacyTermId).map(({ sourceName, sourceId: legacyTermId, deep }) =>
              (<MenuItem key={legacyTermId} value={legacyTermId}>{ deep > 0 && Array.from(' -- '.repeat(deep - 1))} {sourceName}</MenuItem>))}
          </Select> }
        </FormControl>
        <ArticleSearch
          placeholder={'By Headline / Body'}
          onSubmit={q => setQuery(q)}
        />
      </Grid>
      <Divider />
      <div className={classes.loadingRoot}>
        { loading && <LinearProgress className={classes.loading} color={'primary'} /> }
      </div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {['Title', 'Authors', 'Section', 'Translate'].map(col =>
              <TableCell key={col} classes={{ root: classes.heading }} variant={'head'}>{col}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {articles.length === 0 && <TableRow><TableCell component="th" scope="row" colSpan={3}>No article is found</TableCell></TableRow>}
          {articles.length > 0 && articles.map(({
            headline, nid, section, authors, pwampId, imported, alias, id,
          }) => (
            <TableRow key={nid}>
              <TableCell component="th" scope="row">
                <a
                  href={`${publicationConfig.translationBaseSource}/${alias}`}
                  className={classes.link}
                  target="_blank"
                  rel="noreferrer"
                >{headline}</a>
              </TableCell>
              <TableCell component="th" scope="row">
                {authors}
              </TableCell>
              <TableCell component="th" scope="row">
                <span className={classes.noWrap}>{section}</span>
              </TableCell>
              <TableCell component="th" scope="row">
                <ImportAction id={id} imported={imported} onClick={() => onTranslateArticle(pwampId)} />
              </TableCell>
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
      <Grid container direction={'row'} justify={'flex-end'} alignItems={'center'}>
        <Grid item>
          { page > 0 && <IconButton onClick={() => setPage(page - 1)}>
            <NavigateBefore />
          </IconButton>}
        </Grid>
        <Grid item>
          <Typography className={classes.pageLabel} variant={'body2'}>Page {page + 1}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setPage(page + 1)}><NavigateNext /></IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

ArticleListTranslation.defaultProps = {
  totalArticle: 0,
};

ArticleListTranslation.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchList: PropTypes.func.isRequired,
  translateArticle: PropTypes.func.isRequired,
  translationPageLoaded: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  articles: PropTypes.array.isRequired,
  sections: PropTypes.array.isRequired,
};

ArticleListTranslation.defaultTypes = {
};

export default withStyles(styles)(connect(
  ({
    translation: { articles, sections, loading },
    frame: { selectedPublication: { publicationConfig } },
  }) => ({ publicationConfig, articles, sections, loading }),
  { fetchArticle, disposeFetchArticle, fetchList, translateArticle, translationPageLoaded },
)(ArticleListTranslation));
