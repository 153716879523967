import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';

import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, .3)',
    zIndex: 101,
  },
  label: {
    background: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(0.5),
    position: 'absolute',
    right: 0,
    borderRadius: 16,
    fontSize: 11,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '14px',
    marginLeft: 4,
    cursor: 'pointer',
  },
});

const getFormattedTimestamp = (timestamp) => {
  moment.locale();
  const time = moment.unix(timestamp);
  return time.isBefore(moment().subtract(1, 'day')) ? time.fromNow() : time.format('LTS');
};

const ComponentLockOverlay = ({ lock, classes, onUnlock, className, isNew }) => (
  <div className={className ? `${className} ${classes.root}` : classes.root}>
    {lock && <Typography component={'span'} variant={'body2'} className={classes.label}>
      Locked by: {lock.user.name}, {getFormattedTimestamp(lock.timestamp)}<ClearIcon
        onClick={() => {
          if (isNew) {
            // eslint-disable-next-line no-alert
            window.confirm(`This component has been added by ${lock.user.name} so cannot be unlocked, to remove please unlock the layout.`);
          } else {
            window.confirm(`Please confirm you want to unlock this component, all changes by ${lock.user.name} will be removed`) && onUnlock();
          }
        }}
        className={classes.icon}
      />
    </Typography>}
  </div>
);

ComponentLockOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  lock: PropTypes.object.isRequired,
  onUnlock: PropTypes.func.isRequired,
  className: PropTypes.string,
  isNew: PropTypes.bool,
};

ComponentLockOverlay.defaultProps = {
  className: null,
  isNew: false,
};

export default withStyles(styles)(ComponentLockOverlay);

