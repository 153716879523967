import { PURGE } from 'redux-persist';
import moment from 'moment';
import 'moment-timezone';

import { PREVIEW_MODE_MOBILE } from 'constants/preview';

import { CONTEXT_ARTICLE_SELECTOR } from 'constants/contexts';

import {
  APP_LOAD,
  ARTICLE_DRAG_START,
  ARTICLE_DRAG_END,
} from 'constants/actionTypes';
import {
  LAYOUT_TERM_SELECTED,
  LAYOUT_SELECT_MANUAL_LIST,
  LAYOUT_PREVIEW_MODE_SET,
  LAYOUT_PREVIEW_UNDOCK,
  LAYOUT_PREVIEW_DOCK,
  LAYOUT_FETCH_PREVIEW_SUCCESS,
  LAYOUT_FETCH_REJECTED,
  LAYOUT_CONTEXT_SET_FOCUS,
  LAYOUT_CONTEXT_SET_TARGET_INDEX,
  LAYOUT_CONTEXT_RESET,
  LAYOUT_CONTEXT_SET,
  LAYOUT_COMPONENT_DRAG_END,
  LAYOUT_COMPONENT_DRAG_START, LAYOUT_COMPONENTS_LOADED,
  LAYOUT_PREVIEW_SET_OVERRIDE, LAYOUT_PREVIEW_SET_PROP, LAYOUT_SAVE_REJECTED, LAYOUT_SAVE, LAYOUT_SAVE_SUCCESS,
} from 'constants/actionTypes/layout';
import {
  MANUAL_LIST_CLEAR_ITEM,
  MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED, MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS,
  MANUAL_LIST_INSERT_ITEM,
  MANUAL_LIST_REPLACE_ITEM,
} from 'constants/actionTypes/manualList';
import {
  PAGE_ENTER_LAYOUT, PAGE_LEAVE_LAYOUT,
} from 'constants/actionTypes/route';
import { VOCAB_FETCH_LAYOUT_SUCCESS } from 'constants/actionTypes/vocab';

import { PLAYLIST_FETCH_SUCCESS } from 'constants/actionTypes/media';

const defaultState = {
  components: [],
  error: [],
  preview: {},
  previewProp: {},
  previewOverride: {},
  selectedTerm: {},
  selectedManualList: -1,
  selectedManualListIndex: -1,
  context: {
    type: CONTEXT_ARTICLE_SELECTOR,
    data: {},
  },
  previewHidden: false,
  previewDocked: true,
  previewMode: PREVIEW_MODE_MOBILE,
  tvOptions: [],
  isBusy: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_ENTER_LAYOUT: // reset redux on page enter that are not always set
      return {
        ...state,
        isBusy: false,
        preview: defaultState.preview,
        previewProp: defaultState.previewProp,
        previewOverride: defaultState.previewOverride,
        selectedManualList: defaultState.selectedManualList,
      };

    case LAYOUT_COMPONENTS_LOADED:
      return { ...state, components: action.value };

    case APP_LOAD:
      return { ...state, error: defaultState.error };

    case LAYOUT_FETCH_REJECTED:
      return { ...state, error: [action.value] };

    case VOCAB_FETCH_LAYOUT_SUCCESS: {
      const { key, value } = action.value;
      return { ...state, [key]: value };
    }

    // selectedTerm used for new layouts
    case LAYOUT_TERM_SELECTED: {
      return { ...state, selectedTerm: action.value };
    }

    // @todo replace this with a more robust system
    case MANUAL_LIST_CLEAR_ITEM:
    case MANUAL_LIST_REPLACE_ITEM:
    case MANUAL_LIST_INSERT_ITEM:
      return {
        ...state,
        orderUpdated: moment().unix(),
      };

    case LAYOUT_PREVIEW_SET_PROP:
      return {
        ...state,
        previewProp: action.value,
      };

    case LAYOUT_PREVIEW_SET_OVERRIDE:
      return {
        ...state,
        previewOverride: action.value,
      };

    case LAYOUT_FETCH_PREVIEW_SUCCESS:
      return { ...state,
        preview: action.value,
      };

    case PAGE_LEAVE_LAYOUT:
    case LAYOUT_PREVIEW_DOCK:
      return { ...state,
        previewDocked: true,
      };

    case LAYOUT_PREVIEW_UNDOCK:
      return { ...state,
        previewDocked: false,
      };

    case LAYOUT_PREVIEW_MODE_SET:
      return { ...state,
        previewMode: action.value,
      };

    case LAYOUT_CONTEXT_SET:
      return { ...state,
        context: action.value,
      };

    case LAYOUT_CONTEXT_RESET:
      return { ...state,
        context: defaultState.context,
      };

    case LAYOUT_CONTEXT_SET_TARGET_INDEX:
      return { ...state,
        context: {
          ...state.context,
          data: {
            ...state.context.data,
            targetIndex: action.value,
          },
        },
      };

    case LAYOUT_CONTEXT_SET_FOCUS:
      return { ...state,
        context: {
          ...state.context,
          data: {
            ...state.context.data,
            focus: action.value,
          },
        },
      };

    case LAYOUT_COMPONENT_DRAG_START:
    case ARTICLE_DRAG_START:
      return { ...state,
        previewHidden: true,
      };

    case LAYOUT_COMPONENT_DRAG_END:
    case ARTICLE_DRAG_END:
      return { ...state,
        previewHidden: false,
      };

    case LAYOUT_SELECT_MANUAL_LIST:
      return {
        ...state,
        selectedManualList: action.value.listId,
        selectedManualListIndex: action.value.index,
      };

    case LAYOUT_SAVE:
      return {
        ...state,
        isBusy: true,
      };

    case MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS:
    case MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED:
    case LAYOUT_SAVE_SUCCESS:
    case LAYOUT_SAVE_REJECTED:
      return {
        ...state,
        isBusy: false,
      };

    // @todo move to intergration reducer
    case PLAYLIST_FETCH_SUCCESS:
      return {
        ...state,
        tvOptions: action.value,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
