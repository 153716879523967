import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  merge,
  mergeMap,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { equals } from 'rambdax';
import { ajax } from 'rxjs/ajax';
import moment from 'moment';
import { push } from 'connected-react-router';

import {
  STORY_UPDATE_FIELD,
  STORY_ADD_TO_FIELD, STORY_REMOVE_FROM_FIELD, STORY_ON_BLUR_FIELD,
  STORY_LIST_FETCH, STORY_LIST_DISPOSE, STORY_LIST_FETCH_SUCCESS, STORY_LIST_FETCH_REJECTED,
  STORY_SAVE, STORY_SAVE_READY, STORY_SAVE_NOT_READY, STORY_SAVE_SUCCESS, STORY_SAVE_REJECTED,
  STORY_DELETE, STORY_DELETE_SUCCESS, STORY_DELETE_REJECTED,
  STORY_FETCH, STORY_FETCH_SUCCESS, STORY_FETCH_REJECTED,
  STORY_REQUEST_PREVIEW, STORY_REQUEST_PREVIEW_SUCCESS, STORY_REQUEST_PREVIEW_REJECTED,
} from '../../constants/actionTypes/story';
import { ERROR_DATA_MISMATCH } from '../../constants/actionTypes/error';
import {
  FIELD_TITLE,
  FIELD_PAGES,
  FIELD_AUTHORS,
  FIELD_FIRST_PUBLISHED, FIELD_CREATED, FIELD_UNPUBLISH,
  FIELD_API_PUBLISH, FIELD_API_UNPUBLISH,
  FIELD_SEO_HEADLINE, FIELD_URL_KEYWORDS,
} from '../../constants/story/storyFields';
import { sanitiseTerm } from '../../utils/sanitiser';
import apiCatchError, { assignErrorPayload, showSuccessNotification } from '../helper/notification';
import { getStoryPayload, getStoryFields } from './storyProcessors';
import { PRODUCTION } from '../../constants/env';
import { WS_STORY_LIST } from '../../constants/actionTypes/ws';
import { serialize } from '../../utils/urlHelper';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const UNIX_MAX_TIME = 2147483647;

const sanitiseDataByField = (field, data) => {
  switch (field) {
    case FIELD_AUTHORS:
      return sanitiseTerm(data);
    default:
      return data;
  }
};

const syncStoryField = (source, target) => (action$, state$) => action$.pipe(
  ofType(STORY_ON_BLUR_FIELD),
  withLatestFrom(state$),
  filter(([{ value: { field } }, { story }]) =>
    field === source &&
    !story[target] &&
    Object.keys(story.storyFromServer).length === 0,
  ),
  switchMap(
    ([{ value: { value } }]) => of({ type: STORY_UPDATE_FIELD, value: { field: target, value } }),
  ),
);

export const syncSEOHeadline = syncStoryField(FIELD_TITLE, FIELD_SEO_HEADLINE);
export const syncURLKeywords = syncStoryField(FIELD_TITLE, FIELD_URL_KEYWORDS);

export const updateFieldOnAdd = (action$, state$) => action$.pipe(
  ofType(STORY_ADD_TO_FIELD),
  withLatestFrom(state$),
  mergeMap(([{ value: { field, value } }, { story }]) => {
    if (Array.isArray(story[field])) {
      return of({
        type: STORY_UPDATE_FIELD,
        value: { field, value: [...story[field], sanitiseDataByField(field, value)] },
      });
    }
    return of({
      type: ERROR_DATA_MISMATCH,
      value: { type: STORY_ADD_TO_FIELD, field },
    });
  }),
);

export const updateFieldOnRemove = (action$, state$) => action$.pipe(
  ofType(STORY_REMOVE_FROM_FIELD),
  withLatestFrom(state$),
  mergeMap(([{ value: { field, value } }, { story }]) => {
    if (Array.isArray(story[field])) {
      const data = sanitiseDataByField(field, value);
      return of({
        type: STORY_UPDATE_FIELD,
        value: {
          field,
          value: story[field].filter(fieldValue =>
            !equals(sanitiseDataByField(field, fieldValue), data),
          ),
        },
      });
    }
    return of({
      type: ERROR_DATA_MISMATCH,
      value: { type: STORY_REMOVE_FROM_FIELD, field },
    });
  }),
);

export const validateSaveStory = (action$, state$) => action$.pipe(
  ofType(STORY_SAVE),
  withLatestFrom(state$),
  mergeMap(([{ value }, { story }]) => {
    const errors = [];
    if (!story[FIELD_TITLE]) {
      errors.push(assignErrorPayload('Please add a title to this story.'));
    }
    if (!story[FIELD_PAGES] || story[FIELD_PAGES].length < 1) {
      errors.push(assignErrorPayload('Please add a page to this story.'));
    }
    if (errors.length > 0) {
      errors.push({
        type: STORY_SAVE_NOT_READY,
        value,
      });
      return from(errors);
    }
    return of({
      type: STORY_SAVE_READY,
      value,
    });
  }),
);

export const saveStory = (action$, state$) => action$.pipe(
  ofType(STORY_SAVE_READY),
  withLatestFrom(state$),
  mergeMap(([{ value: isPublished }, { story, frame: { selectedPublication } }]) => {
    const { storyFromServer } = story;
    const payload = getStoryPayload(story, selectedPublication);
    const nowTimestamp = moment().unix();
    if (storyFromServer?.id?.length > 0) {
      const {
        id: [{ value: storyId }],
        [FIELD_API_PUBLISH]: published,
      } = storyFromServer;
      payload.id = [{
        value: storyId,
      }];
      if (isPublished) {
        if (!published || published.length < 1) {
          payload[FIELD_FIRST_PUBLISHED] = [{ value: nowTimestamp }];
          payload[FIELD_API_PUBLISH] = payload[FIELD_FIRST_PUBLISHED];
          payload[FIELD_API_UNPUBLISH] = [{ value: UNIX_MAX_TIME }];
        } else if (typeof story[FIELD_UNPUBLISH] === 'undefined' || story[FIELD_UNPUBLISH] < nowTimestamp) {
          payload[FIELD_API_UNPUBLISH] = [{ value: UNIX_MAX_TIME }];
        }
      }
      if (!isPublished) {
        payload[FIELD_API_UNPUBLISH] = [{ value: nowTimestamp }];
      }
      return ajax.patch(`/api/story/${storyId}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => of({
          type: STORY_SAVE_SUCCESS,
          value: response,
        })),
        apiCatchError(STORY_SAVE_REJECTED),
      );
    }
    payload[FIELD_CREATED] = [{ value: nowTimestamp }];
    if (isPublished) {
      payload[FIELD_FIRST_PUBLISHED] = [{ value: nowTimestamp }];
      payload[FIELD_API_PUBLISH] = payload[FIELD_FIRST_PUBLISHED];
      payload[FIELD_API_UNPUBLISH] = [{ value: UNIX_MAX_TIME }];
    }
    return ajax.post('/api/story', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => of({
        type: STORY_SAVE_SUCCESS,
        value: response,
      })),
      apiCatchError(STORY_SAVE_REJECTED),
    );
  }),
);

export const redirectAfterStorySaved = (action$, state$) => action$.pipe(
  ofType(STORY_SAVE_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([
    {
      value: response,
    },
    {
      router: { location: { pathname } },
    },
  ]) => {
    const {
      id: [{ value: storyId }],
    } = response;
    if (pathname === `/story/edit/${storyId}`) {
      return of({
        type: STORY_FETCH_SUCCESS,
        value: response,
      });
    }
    return of(push(`/story/edit/${storyId}`));
  }),
);

export const notificationAfterStorySaved = action$ => action$.pipe(
  ofType(STORY_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Story is successfully saved.')),
);

export const deleteStory = (action$, state$) => action$.pipe(
  ofType(STORY_DELETE),
  withLatestFrom(state$),
  mergeMap(([, { story: { storyFromServer } }]) => {
    if (storyFromServer?.id?.length > 0) {
      const { id: [{ value: storyId }] } = storyFromServer;
      return ajax.delete(`/api/story/${storyId}`, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => of({
          type: STORY_DELETE_SUCCESS,
          value: response,
        })),
        apiCatchError(STORY_DELETE_REJECTED),
      );
    }
    return of({
      type: STORY_DELETE_REJECTED,
    });
  }),
);

export const redirectAfterStoryDeleted = action$ => action$.pipe(
  ofType(STORY_DELETE_SUCCESS),
  map(() => push('/story/')),
);

export const notificationAfterStoryDeleted = action$ => action$.pipe(
  ofType(STORY_DELETE_SUCCESS),
  mergeMap(showSuccessNotification('Story has been successfully deleted.')),
);

export const fetchStory = action$ => action$.pipe(
  ofType(STORY_FETCH),
  switchMap(({ value: storyId }) => ajax.get(
    `/api/story/${storyId}`, { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(({ response }) => of({
      type: STORY_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(STORY_FETCH_REJECTED),
  )),
);

export const setFieldsFromStoryFetch = action$ => action$.pipe(
  ofType(STORY_FETCH_SUCCESS),
  mergeMap(({ value: payload }) => {
    const actions = [];
    const fields = getStoryFields(payload);
    Object.entries(fields).forEach(([field, value]) => {
      actions.push({
        type: STORY_UPDATE_FIELD,
        value: {
          field,
          value,
        },
      });
    });
    return from(actions);
  }),
);

export const fetchStoryList = (action$, state$) => {
  let cachedParams;
  return (
    action$.pipe(
      ofType(STORY_LIST_FETCH),
      switchMap(action => of(action).pipe(
        merge(action$.pipe(
          ofType(WS_STORY_LIST, PUBLICATION_SELECTED),
          filter(() => !!cachedParams),
          map(() => ({
            type: STORY_LIST_FETCH,
            value: cachedParams,
          })),
          takeUntil(action$.pipe(ofType(STORY_LIST_DISPOSE))),
        )),
        takeUntil(action$.pipe(ofType(STORY_LIST_DISPOSE))),
        withLatestFrom(state$),
        debounceTime(500),
        switchMap(([
          { value },
          { frame: { selectedPublication: { domain } } },
        ]) => {
          cachedParams = value;
          return ajax.get(
            `/api/story-list/default/${domain}?${serialize({ ...value })}`,
            { 'Content-Type': 'application/json' },
          ).pipe(
            mergeMap(({ response }) => of({
              type: STORY_LIST_FETCH_SUCCESS,
              value: response,
            })),
            apiCatchError(STORY_LIST_FETCH_REJECTED),
          );
        }),
      )),
    )
  );
};

export const requestPreview = (action$, state$) => action$.pipe(
  ofType(STORY_REQUEST_PREVIEW),
  withLatestFrom(state$),
  switchMap(([, { story, frame: { selectedPublication } }]) => {
    const payload = getStoryPayload(story, selectedPublication);
    return ajax.post('/api/preview/story', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => of({
        type: STORY_REQUEST_PREVIEW_SUCCESS,
        value: response,
      })),
      apiCatchError(STORY_REQUEST_PREVIEW_REJECTED),
    );
  }),
);

export const openPreviewInNew = (action$, state$) => action$.pipe(
  ofType(STORY_REQUEST_PREVIEW_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([
    { value: { preview_id: previewId } },
    {
      frame: { selectedPublication },
      dashboard: { mode },
    },
  ]) => {
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url
      : selectedPublication.environment.web;
    window.open(`${frontEndUrl.replace(/\/$/, '')}/preview/${previewId}`, '_blank');
    return of({ type: 'test' });
  }),
);
