import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { capitalize } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { EditorLettersBreadcrumb, AdsReplacementsBreadcrumb } from '../../layouts/PremiumAd';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginLeft: '0.2em',
  },
  button: {
    background: '0 none !important',
    textTransform: 'inherit',
    fontWeight: 'normal',
    padding: '4px !important',
    '& .MuiButton-endIcon': {
      marginLeft: 2,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
  },
};

const LayoutTermSwitch = ({ classes, active }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const [editorsLetter] = EditorLettersBreadcrumb;
  const [adPlacement] = AdsReplacementsBreadcrumb;

  const options = [
    adPlacement, editorsLetter, { title: 'Timeline', path: '/premium/timeline' },
  ];

  const selected = options.find(({ path }) => active.startsWith(path));

  return (<div className={classes.root}>
    <Button
      aria-controls={'term-switch'}
      aria-haspopup={'true'}
      onClick={event => setAnchorEl(event.currentTarget)}
      className={classes.button}
      endIcon={<ArrowDropDownIcon />}
    >
      {capitalize(selected.title)}
    </Button>
    <Menu
      id={'term-switch'}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {options.map(({ path, title }) =>
        (<Link
          key={path}
          className={classes.link}
          to={path}
          onClick={() => setAnchorEl(null)}
        ><MenuItem>{title}</MenuItem></Link>))}
    </Menu>
  </div>);
};

LayoutTermSwitch.defaultProps = {
};

LayoutTermSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  {},
)(LayoutTermSwitch));

