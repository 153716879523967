import { setDefaults } from '@udecode/slate-plugins';
import deserializeVideo from './deserializeVideo';
import renderElementVideo from './renderElementVideo';
import { DEFAULTS_VIDEO } from './defaults';

const VideoPlugin = (options) => {
  const { video } = setDefaults(options, DEFAULTS_VIDEO);
  return ({
    renderElement: renderElementVideo(options),
    deserialize: deserializeVideo(options),
    voidTypes: [video.type],
  });
};

export default VideoPlugin;
