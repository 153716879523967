import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

import { productPriceCompare } from '../../actions/product';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '../ui/table/Table';

const PriceComparison = ({
  link,
  priceComparisonResult,
  productPriceCompare: onCompareRequest,
}) => {
  const [comparisonResult, setComparisonResult] = useState([]);
  useEffect(() => {
    if (link) {
      onCompareRequest(link);
    }
  }, [link]);

  useEffect(() => {
    setComparisonResult(priceComparisonResult[link] || []);
  }, [priceComparisonResult]);

  return (
    <Table>
      <TableHead noFill>
        <TableRow>
          <TableHeadCell noPadding>Merchant</TableHeadCell>
          <TableHeadCell noPadding>Sale price</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {comparisonResult.length > 0 && comparisonResult.map(item => (<TableRow key={item.merchant.name}>
          <TableCell noPadding><Link target={'_blank'} href={item.deeplink}>{item.merchant.name}</Link></TableCell>
          <TableCell noPadding>{item.salePrice} {item.currency}</TableCell>
        </TableRow>))}
        {comparisonResult.length === 0 && <TableRow>
          <TableCell colSpan={2} align={'center'} noPadding>No merchants found</TableCell>
        </TableRow>}
      </TableBody>
    </Table>
  );
};

PriceComparison.propTypes = {
  link: PropTypes.string,
  productPriceCompare: PropTypes.func.isRequired,
  priceComparisonResult: PropTypes.object.isRequired,
};

PriceComparison.defaultProps = {
  link: null,
};

export default connect(
  ({ product: { priceComparisonResult } }) => ({ priceComparisonResult }),
  { productPriceCompare },
)(PriceComparison);
