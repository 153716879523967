import { PURGE } from 'redux-persist';

import {
  BOOKING_FETCH_AMENITIES_SUCCESS,
} from '../../constants/actionTypes/integration/booking';

const defaultState = {
  hotelAmenities: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case BOOKING_FETCH_AMENITIES_SUCCESS: {
      const { hotelId, amenities } = action.value;
      return {
        ...state,
        hotelAmenities: {
          ...state.hotelAmenities,
          [hotelId]: amenities,
        },
      };
    }

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
