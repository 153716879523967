import { filter, withLatestFrom, map } from 'rxjs/operators';

const accessControl = (state$, allowedRoles) => (action) => {
  return action.pipe(
    withLatestFrom(state$),
    filter(([, {login: {user: {roles}}}]) => !!roles),
    map(([, {login: {user: {roles}}}]) => roles),
    filter((roles) => roles.some(r => allowedRoles.includes(r))),
  )
}
export default accessControl;
