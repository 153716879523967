import ArticleLinkElement from './components/ArticleLinkElement';

export const ELEMENT_ARTICLE_LINK = 'articleLink';
export const PROP_URL = 'url';
export const PROP_TITLE = 'title';
export const PROP_IMAGE = 'image';
export const PROP_DESCRIPTION = 'description';

export const DEFAULTS_ARTICLE_LINK = {
  articleLink: {
    component: ArticleLinkElement,
    type: ELEMENT_ARTICLE_LINK,
    rootProps: {
      className: 'slate-articleLink',
    },
  },
};
