import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_IMAGE } from './defaults';

const renderElementImage = (options) => {
  const { image } = setDefaults(options, DEFAULTS_IMAGE);
  return getRenderElement(image);
};

export default renderElementImage;
