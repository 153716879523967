import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import Dialog from './common/Dialog';
import Button from '../ui/buttons/Button';
import Checkbox from '../ui/Checkbox';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  listRoot: {
    listStyle: 'none',
    paddingLeft: 0,
  },
  listRootInner: {
    paddingLeft: 20,
    marginBottom: 20,
  },
  regionLabel: {
    fontSize: 18,
    paddingLeft: 4,
  },
});


const PublicationRegionsDialog = (props) => {
  const {
    classes,
    onSave,
    selectedPublications,
    regions,
    selectedPublication: { id: currentPublicationId },
    ...rest
  } = props;
  const [selected, setSelected] = useState({});
  const [publicationOptionCount, setCount] = useState(0);

  useEffect(() => {
    const options = regions.reduce((acc, { publications }) => [...acc, ...publications], [])
      .reduce((acc, { id }) => ({ ...acc, [id]: selectedPublications.includes(id) }), {});
    setCount(Object.keys(options).length);
    setSelected(options);
  }, [regions]);
  const getSelectedCount = () => Object.values(selected).filter(Boolean).length;

  return (<Dialog title={`Publications (${getSelectedCount()})`} maxWidth={'md'} {...rest}>
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Checkbox
            checked={publicationOptionCount === getSelectedCount()}
            indeterminate={getSelectedCount() > 0 && getSelectedCount() < publicationOptionCount}
            onClick={(e) => {
              const { checked } = e.target;
              const newState = Object.keys(selected).reduce((acc, key) => {
                acc[key] = checked;
                if (key === String(currentPublicationId)) {
                  acc[key] = true;
                }
                return acc;
              }, {});
              setSelected(newState);
            }}
            label={'Select all'}
          />
          <Divider />
          <ul className={classes.listRoot}>
            {regions.map(({ region, publications }) => {
              const myIds = publications.map(({ id }) => id);
              const isPresent = id => Boolean(selected[id]);
              const checkedAll = myIds.every(isPresent);
              const halfChecked = !checkedAll && myIds.some(isPresent);
              return (<li key={region}>
                <Checkbox
                  checked={checkedAll}
                  indeterminate={halfChecked}
                  name={region}
                  onClick={(e) => {
                    const { checked } = e.target;
                    const newState = {
                      ...selected,
                      ...myIds.reduce((acc, next) => {
                        let nextState = checked;
                        if (String(currentPublicationId) === next) {
                          nextState = true;
                        }
                        return { ...acc, [next]: nextState };
                      }, {}),
                    };
                    setSelected(newState);
                  }}
                  id={region}
                  label={region}
                />
                <ul className={[classes.listRoot, classes.listRootInner].join(' ')}>
                  {publications.map(({ publication, id }) => (<li key={id}>
                    <Checkbox
                      checked={!!selected[id]}
                      disabled={String(currentPublicationId) === String(id)}
                      onChange={(e) => {
                        const { checked } = e.target;
                        setSelected({
                          ...selected,
                          [id]: checked,
                        });
                      }}
                      name={publication}
                      label={publication}
                    />
                  </li>))}</ul>
              </li>);
            })}
          </ul>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction={'row-reverse'}>
          <Button
            variant={'contained'}
            onClick={() => {
              const selectedIds = Object.entries(selected)
                .filter(([, val]) => Boolean(val))
                .map(([key]) => key);
              // preserve order for the canonical publication
              onSave([
                currentPublicationId,
                ...selectedIds.filter(id => id !== currentPublicationId),
              ]);
            }}
          >Save</Button>
        </Grid>
      </Grid>
    </div>
  </Dialog>);
};

PublicationRegionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  regions: PropTypes.array,
  selectedPublications: PropTypes.array,
};

PublicationRegionsDialog.defaultProps = {
  regions: [],
  publications: [],
};

export default withStyles(style)(connect(({
  login: { user },
  frame: { selectedPublication },
}) => ({ user, selectedPublication }), {
})(PublicationRegionsDialog));
