import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Container, Typography } from '@material-ui/core';

import Loader from '../../ui/Loader';

const Wrapper = withStyles({
  root: {
    position: 'absolute',
    zIndex: 10001,
    maxWidth: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})(props => <Container disableGutters {...props} />);

const PageBusyIndicator = ({
  classes, disableInteraction, message, ...rest
}) => (disableInteraction
  ? (
    <Wrapper {...rest}>
      <Loader />
      {message && <Typography
        className={classes.message}
        variant={'h2'}
        align={'center'}
      >{message}</Typography>}
    </Wrapper>
  )
  : <Loader />
);

PageBusyIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  disableInteraction: PropTypes.bool,
  message: PropTypes.string,
};

PageBusyIndicator.defaultProps = {
  disableInteraction: false,
  message: '',
};

export default withStyles(theme => ({
  message: {
    margin: theme.spacing(2, 0),
  },
}))(PageBusyIndicator);
