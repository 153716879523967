import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import RemoveIcon from '@material-ui/icons/Close';

import VideoDailyMotion from '../../embed/video/VideoDailyMotion';
import VideoDugout from '../../embed/video/VideoDugout';
import VideoJWPlayer from '../../embed/video/VideoJWPlayer';
import VideoYoutube from '../../embed/video/VideoYoutube';

import DroppableWrapperMedia from '../DroppableWrapper';
import VideosDialogSelector from '../../dialog/VideoSelectorDialog';
import { VIDEO_DM, VIDEO_DUGOUT, VIDEO_JWPLAYER, VIDEO_YOUTUBE } from '../../../constants/media/media';
import IconButton from '../buttons/IconButton';

const styles = () => ({
  root: {
    position: 'relative',
  },
  removeAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  video: {
    borderRadius: 4,
    overflow: 'hidden',
    width: '100%',
  },
});

const VideoSelected = (props) => {
  const { publicationConfig, video } = props;
  switch (video.videoType) {
    case VIDEO_DM:
      if (typeof publicationConfig.dailyMotionConfig !== 'undefined') {
        return <VideoDailyMotion video={video} />;
      }
      break;
    case VIDEO_DUGOUT:
      if (typeof publicationConfig.dugoutToken !== 'undefined') {
        return <VideoDugout video={video} />;
      }
      break;
    case VIDEO_JWPLAYER:
      if (typeof publicationConfig.jwPlayerConfig !== 'undefined') {
        return <VideoJWPlayer video={video} />;
      }
      break;
    case VIDEO_YOUTUBE:
      return <VideoYoutube video={video} />;
    default:
      return <Typography variant={'body2'}>Player for {video.videoType} not found</Typography>;
  }
  return <Typography variant={'body2'}>Config missing for {video.videoType} player</Typography>;
};

VideoSelected.propTypes = {
  publicationConfig: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
};

const VideoSelector = (props) => {
  const {
    classes, video, label, required, publicationConfig, onSelected, onClose, onRemove, validTypes, disabled,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Grid container className={classes.root}>
      {!video && <DroppableWrapperMedia
        label={label}
        icon={<VideoLibraryIcon />}
        onClick={() => {
          setDialogOpen(true);
        }}
        required={required}
      />}
      {video && <div className={classes.video}>
        <VideoSelected publicationConfig={publicationConfig} video={video} />
      </div>}
      {video && !disabled && <div className={classes.removeAction}>
        {onRemove && <IconButton
          onClick={(e) => {
            onRemove();
            e.preventDefault();
            e.stopPropagation();
          }}
          color={'primary'}
        >
          <RemoveIcon />
        </IconButton>}
      </div>}
      {dialogOpen && <VideosDialogSelector
        handleClose={() => {
          setDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        open={dialogOpen}
        validTypes={validTypes}
        selectedVideo={([provider, data, extra]) => {
          onSelected({ provider, video: data, extra });
          setDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
      />}
    </Grid>
  );
};

VideoSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  onSelected: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  label: PropTypes.string,
  video: PropTypes.object,
  required: PropTypes.bool,
  validTypes: PropTypes.array,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
};

VideoSelector.defaultProps = {
  label: 'Add video',
  video: null,
  required: false,
  validTypes: [],
  onClose: null,
  onRemove: null,
  disabled: false,
};

export default withStyles(styles)(connect(
  ({ frame: { selectedPublication: { publicationConfig } } }) => ({ publicationConfig }),
  {},
)(VideoSelector));
