import React from 'react';
import PropTypes from 'prop-types';

import {
  ContextPanelDetailsSection, ContextPanelFieldHeading,
} from '../../../../../common/context/ContextPanel';

import TextField from '../../../../../ui/TextField';
import ArticleRef from './ArticleRef';
import ImageSelector from '../../../../../ui/media/ImageSelector';
import {
  FIELD_ARTICLE_REF,
  FIELD_AUTHOR_IMAGE, FIELD_AUTHOR_NAME,
  FIELD_TITLE,
} from '../../../../../../constants/edition/editionFields';

const Headshot = (props) => {
  const {
    article: { title, authorName, authorImage, articleRef },
    delta, componentDelta, prop, mergeProperty,
  } = props;

  const assignImage = data => mergeProperty({
    delta: componentDelta,
    prop,
    value: {
      [delta]: {
        [FIELD_AUTHOR_IMAGE]: {
          type: 'image',
          data,
        },
      },
    },
  });
  return (
    <ContextPanelDetailsSection>
      <ContextPanelFieldHeading>Article {delta + 1}</ContextPanelFieldHeading>
      <ImageSelector
        selectImage={assignImage}
        onRemove={() => assignImage(null)}
        label="Add image"
        image={authorImage?.data}
        showEditDialog
      />
      <TextField
        label="Author"
        value={authorName || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_AUTHOR_NAME]: event.target.value,
            },
          },
        })}
      />
      <TextField
        label="Headline"
        value={title || ''}
        onChange={event => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_TITLE]: event.target.value,
            },
          },
        })}
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={ref => mergeProperty({
          delta: componentDelta,
          prop,
          value: {
            [delta]: {
              [FIELD_ARTICLE_REF]: {
                title: ref.data.title,
                id: ref.data.id,
                bundle: ref.type,
              },
            },
          },
        })}
      />
    </ContextPanelDetailsSection>
  );
};

Headshot.propTypes = {
  article: PropTypes.object.isRequired,
  delta: PropTypes.number.isRequired,
  componentDelta: PropTypes.number.isRequired,
  prop: PropTypes.string.isRequired,
  mergeProperty: PropTypes.func.isRequired,
};

export default Headshot;
