import { ofType } from 'redux-observable';
import {
  flatMap,
  switchMap,
  withLatestFrom,
  map,
  filter,
  merge,
  takeUntil,
  debounceTime,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
  ARTICLE_LIST_LOADED,
  ARTICLE_LIST_DRAFT_LOADED,
  ARTICLE_LIST_REVIEW_LOADED,
  ARTICLE_LIST_PUBLISHED_LOADED,
  ARTICLE_LIST_REJECTED,
  FETCH_ARTICLE_DRAFT,
  FETCH_ARTICLE_REVIEW,
  FETCH_ARTICLE_PUBLISHED,
  DISPOSE_FETCH_ARTICLE_DRAFT,
  DISPOSE_FETCH_ARTICLE_REVIEW,
  DISPOSE_FETCH_ARTICLE_PUBLISHED,
} from '../../constants/actionTypes';

import apiCatchError from '../helper/notification';

import { ARTICLE_LIST_DISPOSE, ARTICLE_LIST_FETCH } from '../../constants/actionTypes/article';
import { WS_ARTICLE_LIST } from '../../constants/actionTypes/ws';
import {serialize} from "../../utils/urlHelper";
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const fetchArticles = (action$, state$, actionCalled, actionResponse, disposedAction) => {
  let cachedParams;
  return action$.pipe(
    ofType(actionCalled),
    switchMap(action => of(action).pipe(
      merge(action$.pipe(
        ofType(WS_ARTICLE_LIST, PUBLICATION_SELECTED),
        filter(() => !!cachedParams),
        map(() => ({
          type: actionCalled,
          value: cachedParams,
        })),
        takeUntil(action$.pipe(ofType(disposedAction))),
      )),
      takeUntil(action$.pipe(ofType(disposedAction))),
      withLatestFrom(state$),
      debounceTime(500),
      switchMap(([
        innerAction,
        { frame: { selectedPublication: { domain, publicationConfig }, publication } },
      ]) => {
        cachedParams = innerAction.value;
        const checkImportedStatus = !!innerAction.value.publicationSource;
        let prefixId = '';
        const {
          publicationSource,
          ...params
        } = innerAction.value;
        const filterByDomain = publicationSource || domain;
        if (checkImportedStatus) {
          try {
            prefixId += 'TF';
            const targetPublication = publication
              .find(({ domain: currentDomain }) => currentDomain === publicationSource);
            prefixId = `TF${targetPublication.name}To${publicationConfig.country}`;
          } catch (ex) {
            console.error(ex);
          }
        }
        if (params?.status === 1) {
          // update end time, exclude the scheduled article
          params.end = Math.floor(new Date().getTime() / 1000);
        }
        return ajax.get(`/api/article/${filterByDomain}?${serialize({ ...params })}`, { 'Content-Type': 'application/json' }).pipe(
          flatMap(({ response }) => of({
            type: actionResponse,
            value: response,
          })),
          apiCatchError(ARTICLE_LIST_REJECTED),
        );
      }),
    )),
  );
};

export const getArticles = (action$, state$) =>
  fetchArticles(action$, state$, ARTICLE_LIST_FETCH, ARTICLE_LIST_LOADED, ARTICLE_LIST_DISPOSE);

export const getDraftArticles = (action$, state$) =>
  fetchArticles(
    action$, state$, FETCH_ARTICLE_DRAFT, ARTICLE_LIST_DRAFT_LOADED, DISPOSE_FETCH_ARTICLE_DRAFT,
  );

export const getReviewArticles = (action$, state$) =>
  fetchArticles(
    action$, state$, FETCH_ARTICLE_REVIEW, ARTICLE_LIST_REVIEW_LOADED, DISPOSE_FETCH_ARTICLE_REVIEW,
  );

export const getPublishedArticles = (action$, state$) =>
  fetchArticles(
    action$, state$, FETCH_ARTICLE_PUBLISHED, ARTICLE_LIST_PUBLISHED_LOADED,
    DISPOSE_FETCH_ARTICLE_PUBLISHED,
  );

