import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { saveConfig, setConfig } from 'actions/config';

import { PagePanel, PagePanelActions, PagePanelContent, PagePanelHeading } from 'components/common/page/PagePanel';
import SectionTitle from 'components/common/SectionTitle';
import IconButton from 'components/ui/buttons/IconButton';

const ConfigPanel = ({ title, prop, config, Component, children, validateConfig, saveConfig: save }) => {
  const [edit, setEdit] = useState(false);
  const [state, setState] = useState(config);
  const [errors, setErrors] = useState([]);
  const onEdit = () => {
    setEdit(true);
  };
  const onSave = () => {
    setEdit(false);
    save(prop, state);
  };
  const onCancel = () => {
    setEdit(false);
    setState(config);
  };
  useEffect(() => {
    setState(config);
  }, [config]);
  useEffect(() => {
    if (validateConfig && state) {
      setErrors(validateConfig(state));
    }
  }, [validateConfig, state]);
  return (
    <PagePanel>
      <PagePanelHeading>
        <SectionTitle>{title}</SectionTitle>
        <PagePanelActions withMargin>
          {!edit && <IconButton onClick={onEdit}><EditIcon /></IconButton>}
          {edit && <IconButton disabled={(errors && errors.length > 0)} onClick={onSave}><SaveIcon /></IconButton>}
          {edit && <IconButton onClick={onCancel}><CancelOutlinedIcon /></IconButton>}
        </PagePanelActions>
      </PagePanelHeading>
      <PagePanelContent>
        <Component edit={edit} config={state} setConfig={setState} errors={errors} />
        {children}
      </PagePanelContent>
    </PagePanel>
  );
};

ConfigPanel.propTypes = {
  title: PropTypes.string.isRequired,
  Component: PropTypes.node.isRequired,
  prop: PropTypes.string.isRequired,
  saveConfig: PropTypes.func.isRequired,
  setConfig: PropTypes.func.isRequired,
  config: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  validateConfig: PropTypes.func,
  children: PropTypes.node,
};

ConfigPanel.defaultProps = {
  validateConfig: null,
  children: null,
};

const mapStateToProps = ({ config }, { prop }) => ({
  config: config[prop],
});

export default connect(
  mapStateToProps,
  { saveConfig, setConfig },
)(ConfigPanel);
