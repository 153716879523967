import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  setArticleEditionProperty, setEditionLockField,
} from '../../../../actions/edition';

import { FIELD_SLUG } from '../../../../constants/edition/editionFields';
import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfieldDepricated';

const SectionSlug = ({
  slug,
  setArticleEditionProperty: setProperty,
  setEditionLockField: lockField,
}) => (
  <FieldLockTextfield
    label={'Slug (optional)'}
    field={FIELD_SLUG}
    onUnlock={() => lockField([FIELD_SLUG, slug])}
    value={slug}
    onChange={event => setProperty([FIELD_SLUG, event.target.value])}
  />
);

SectionSlug.propTypes = {
  slug: PropTypes.string.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default connect(
  ({ edition: { slug } }) => ({ slug }),
  { setArticleEditionProperty, setEditionLockField },
)(SectionSlug);

