import { MARKUP, TEXT_ENABLED_COMPONENT } from '../../constants/builder/builder';
import generateKey from '../../utils/generateKey';

export const initComp = {
  type: MARKUP,
  data: { markup: '' },
};

export const injectMarkupAfterEmbedComponent = (type, seed, defaultComponent = initComp) => {
  const nextComponent = {
    ...seed,
    id: generateKey(),
    type,
  };
  if (!TEXT_ENABLED_COMPONENT.has(type)) {
    return [
      nextComponent,
      {
        ...defaultComponent,
        id: generateKey(),
      },
    ];
  }
  return [nextComponent];
};

export const injectComponent = (type, seed) => {
  const nextComponent = {
    ...seed,
    id: generateKey(),
    type,
  };
  return [nextComponent];
};
