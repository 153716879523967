import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
  searchRevision,
} from '../../../../actions/revision';

import RevisionDialog from '../../../dialog/RevisionDialog';
import { REVISION_ARTICLE } from '../../../../constants/revision';
import { ContextPanelItem } from '../../../common/context/ContextPanel';
import Button from '../../../ui/buttons/Button';
import { FIELD_NID } from '../../../../constants/article/articleFields';

const ArticleRevisionStatus = ({
  searchRevision: onSearchRevision, revisionList, nid,
}) => {
  const [revisionShow, setRevisionShow] = useState(false);

  useEffect(() => {
    if (nid) {
      onSearchRevision({
        id: nid,
        type: REVISION_ARTICLE,
      });
    }
  }, [nid]);

  return (<ContextPanelItem label={'Revisions'}>
    {revisionList.length > 0 &&
    <Button caption textOnly onClick={() => setRevisionShow(true)}>View</Button>}
    {revisionList.length === 0 && <Typography variant={'caption'}>None</Typography>}
    {revisionShow && <RevisionDialog
      open={revisionShow}
      handleClose={() => {
        setRevisionShow(false);
      }}
    />}
  </ContextPanelItem>);
};

ArticleRevisionStatus.propTypes = {
  searchRevision: PropTypes.func.isRequired,
  revisionList: PropTypes.array.isRequired,
  nid: PropTypes.number,
};

ArticleRevisionStatus.defaultProps = {
  nid: null,
};

export default connect(
  ({
    dataState: { [FIELD_NID]: nid },
    revision: { list: revisionList },
  }) => ({ revisionList, nid }),
  { searchRevision },
)(ArticleRevisionStatus);

