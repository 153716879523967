import { PURGE } from 'redux-persist/es/constants';

import {
  REVISION_COMPARE_REJECTED,
  REVISION_COMPARE_SUCCESS,
  REVISION_LIST_DISPOSE,
  REVISION_ON_SEARCH_SUCCESS,
} from '../../constants/revision';

import { PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT } from '../../constants/actionTypes/route';
import { ARTICLE_DELETE_SUCCESS } from '../../constants/actionTypes/article';

const defaultState = {
  list: [],
  diff: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REVISION_COMPARE_REJECTED:
      return {
        ...state,
        diff: defaultState.diff,
      };

    case REVISION_COMPARE_SUCCESS:
      return {
        ...state,
        diff: action.value,
      };

    case REVISION_ON_SEARCH_SUCCESS:
      return { ...state, list: action.value };

    case PAGE_ENTER_ARTICLE_EDIT:
    case ARTICLE_DELETE_SUCCESS:
    case REVISION_LIST_DISPOSE:
    case PAGE_ENTER_ARTICLE_CREATE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
