import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import {
  addManualListItem, saveManualList,
} from 'actions/manualList';

import Page from 'components/common/page/Page';
import PageContent from 'components/common/page/PageContent';
import PageToolbar, { PageToolbarButtons } from 'components/common/page/PageToolbar';

import ManualListItems from 'components/manualList/ManualListItems';
import AutocompleteArticleDialog from 'components/dialog/autocomplete/AutocompleteArticleDialog';
import Button from 'components/ui/buttons/Button';

import { ManualListBreadcrumb } from './ManualList';
import ManualListContext from "../components/manualList/ManualListContext";

const ManualListCreate = ({
  match: { params: { id } },
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const handleSave = () => dispatch(saveManualList());
  const handleSelect = value => {
    dispatch(addManualListItem(value));
    setOpenDialog(false);
  };
  const breadcrumb = [
    ...ManualListBreadcrumb,
    {
      title: 'Edit manual list',
      slug: id,
    },
  ];
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <PageToolbarButtons>
          <Button
            onClick={() => setOpenDialog(true)}
            variant="outlined"
            startIcon={<AddIcon />}
          >Add item</Button>
          <Button
            onClick={handleSave}
            variant="contained"
            startIcon={<SaveIcon />}
          >Save</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <ManualListItems />
        <AutocompleteArticleDialog
          handleClose={() => setOpenDialog(false)}
          open={openDialog}
          onSelect={handleSelect}
        />
      </PageContent>
      <ManualListContext />
    </Page>
  );
};

ManualListCreate.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ManualListCreate;
