import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import AdPageSwitch from './AdPageSwitch';
import { FEATURE_PREMIUM_AD_REPLACEMENT } from '../../constants/features';
import { hasFeatures } from '../helper/utils';

const AdMenuControlBar = (props) => {
  const { pathname, features } = props;
  if (!hasFeatures(features)(FEATURE_PREMIUM_AD_REPLACEMENT)) return null;
  return (
    <>
      <Grid container justify={'center'} alignItems={'center'}>
        <Typography variant={'body2'}>Manage</Typography><AdPageSwitch active={pathname} />
      </Grid>
    </>
  );
};

AdMenuControlBar.propTypes = {
  pathname: PropTypes.string.isRequired,
  features: PropTypes.array.isRequired,
};

export default connect(
  ({
    router: { location: { pathname } },
    frame: { selectedPublication: { features } },
  }) => ({ pathname, features }),
  { },
)(AdMenuControlBar);
