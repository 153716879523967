import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import capitalize from '@material-ui/core/utils/capitalize';
import { push } from 'connected-react-router';
import { Prompt } from 'react-router-dom';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

import Loading from './Loading';

import ControlButtons from '../components/edition/article/ControlButtons';

import ArticleMain from '../components/edition/article/ArticleMain';
import Sidebar from '../components/edition/article/Sidebar';
import SingleImageMain from '../components/edition/article/SingleImageMain';
import SidebarSingleImage from '../components/edition/article/SidebarSingleImage';
import SectionMain from '../components/edition/article/SectionMain';
import EditionPreview from '../components/edition/preview/EditionPreview';

import {
  editionDetailFetch,
  enterArticleEditionCreation,
  onEnterArticleCreation,
  setArticleEditionProperty,
  editionArticleEnterPage,
  editionDisposePage,
} from '../actions/edition';

import { EditionListBreadcrumb } from './EditionList';
import {
  PAGE_TYPE_ARTICLE,
  PAGE_TYPE_SINGLE_IMAGE,
  PAGE_TYPE_SECTION,
  PAGE_TYPE_ARTICLE_CUSTOM,
} from '../constants/edition/edition';
import { PDF_WIDTH, PREVIEW_MODE_DESKTOP, PREVIEW_MODE_NONE } from '../constants/preview';
import SidebarSection from '../components/edition/article/SidebarSection';
import EditionPreviewWindow from '../components/edition/preview/EditionPreviewWindow';

import { UNSAVE_CHANGES_PROMPT_TEXT } from '../constants/prompt';

const styles = theme => ({
  root: {
    maxWidth: 900,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
  },
  rootDesktop: {
    maxWidth: PDF_WIDTH - theme.spacing(4),
    height: '100%',
  },
  rootMobile: {
    maxWidth: 400,
    height: '100%',
  },
  previewPageContent: {
    height: `calc(100% - ${theme.spacing(4) * 2}px)`,
    display: 'flex',
    maxWidth: PDF_WIDTH - theme.spacing(4),
  },
  previewNone: {
    display: 'none',
  },
  previewDesktop: {
    display: 'flex',
  },
  previewMobile: {
    display: 'flex',
  },
  containerPreviewNone: {
    display: 'flex',
  },
  containerPreviewDesktop: {
    display: 'none',
  },
  containerPreviewMobile: {
    display: 'none',
  },
  previewPopout: {
    padding: 0,
  },
});

const EditionArticle = ({
  classes,
  unsavedEditionPage,
  enterArticleEditionCreation: onEnter,
  onEnterArticleCreation: onEnterArticle,
  editionDetailFetch: fetchDetail,
  editionEnterPage: onEnterPage,
  editionDisposePage: onDisposePage,
  edition,
  match,
  revisionList,
}) => {
  const { params: { id: articleId, type, editionId, revisionId } } = match;
  const { pageType } = edition;
  const [previewMode, setPreviewMode] = useState(PREVIEW_MODE_NONE);
  const [popoutIsOpen, setPopoutIsOpen] = useState(false);

  useEffect(() => {
    onEnterPage();
    if (!/\d+/.test(articleId)) {
      onEnterArticle();
    }
    if (/\d+/.test(articleId)) {
      onEnter(articleId);
    }
    return () => {
      onDisposePage();
    };
  }, [articleId]);

  useEffect(() => {
    if (
      pageType === PAGE_TYPE_SECTION ||
      pageType === PAGE_TYPE_ARTICLE
    ) {
      fetchDetail(editionId);
    }
    if (pageType === PAGE_TYPE_ARTICLE_CUSTOM) {
      setPreviewMode(PREVIEW_MODE_DESKTOP);
    }
    if (pageType === PAGE_TYPE_SECTION) {
      setPreviewMode(PREVIEW_MODE_DESKTOP);
    }
  }, [pageType, editionId]);

  useEffect(() => {
    // go to preview
    if (revisionId && revisionList.some(({ revision_id: id }) => id === revisionId)) {
      if (revisionId) {
        setPreviewMode(PREVIEW_MODE_DESKTOP);
      }
    } else { // no match found, go to article
      setPreviewMode(pageType === PAGE_TYPE_SECTION ? PREVIEW_MODE_DESKTOP : PREVIEW_MODE_NONE);
    }
  }, [revisionId, revisionList]);

  const breadcrumb = [
    ...EditionListBreadcrumb,
    {
      title: capitalize(type),
      slug: type,
    },
    {
      title: 'Edition',
      slug: editionId,
    },
    {
      title: pageType ? `Edit ${pageType.replace(/-/g, ' ')}` : '',
      slug: articleId,
    },
  ];
  const previewClass = (pageType === PAGE_TYPE_SECTION || popoutIsOpen)
    ? PREVIEW_MODE_NONE : previewMode;
  if (!pageType) {
    return (<Loading />);
  }
  const rootClasses = [classes.root];
  if (pageType === PAGE_TYPE_SECTION) {
    rootClasses.push(classes[`root${capitalize(previewMode)}`]);
  }
  const isEditMode = previewClass === PREVIEW_MODE_NONE;
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <ControlButtons
          revisionId={revisionId}
          type={pageType}
          previewMode={previewMode}
          popoutToggle={() => setPopoutIsOpen(!popoutIsOpen)}
        />
      </PageToolbar>}
    >
      <Prompt when={!!unsavedEditionPage} message={UNSAVE_CHANGES_PROMPT_TEXT} />
      <PageContent
        className={
          pageType === PAGE_TYPE_SECTION || previewClass !== PREVIEW_MODE_NONE
            ? classes.previewPageContent
            : ''
        }
      >
        {isEditMode && pageType === PAGE_TYPE_ARTICLE && <ArticleMain />}
        {isEditMode && pageType === PAGE_TYPE_SINGLE_IMAGE && <SingleImageMain />}
        {isEditMode && pageType === PAGE_TYPE_SECTION && <SectionMain articleId={articleId} />}
        <EditionPreview
          className={classes[`preview${capitalize(previewClass)}`]}
          articleId={articleId}
          revisionId={revisionId}
          previewMode={previewMode}
        />
      </PageContent>
      {pageType === PAGE_TYPE_ARTICLE && <Sidebar />}
      {pageType === PAGE_TYPE_SINGLE_IMAGE && <SidebarSingleImage />}
      {pageType === PAGE_TYPE_SECTION && <SidebarSection />}
      {popoutIsOpen && <EditionPreviewWindow
        edition={edition}
        onClose={() => setPopoutIsOpen(false)}
      >
        <EditionPreview
          className={classes.previewPopout}
          articleId={articleId}
          type={pageType}
          previewMode={previewMode === PREVIEW_MODE_NONE ? PREVIEW_MODE_DESKTOP : previewMode}
        />
      </EditionPreviewWindow>}
    </Page>
  );
};

EditionArticle.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  enterArticleEditionCreation: PropTypes.func.isRequired,
  editionEnterPage: PropTypes.func.isRequired,
  editionDisposePage: PropTypes.func.isRequired,
  onEnterArticleCreation: PropTypes.func.isRequired,
  editionDetailFetch: PropTypes.func.isRequired,
  revisionList: PropTypes.array.isRequired,
  edition: PropTypes.object.isRequired,
  unsavedEditionPage: PropTypes.bool.isRequired,
};

EditionArticle.defaultProps = {
  selectedRange: null,
};

export default withStyles(styles)(connect(
  ({
    article: { selectedRange },
    edition,
    stateHistory: { unsavedEditionPage },
    router: { location: { pathname } },
    revision: { list: revisionList },
  }) =>
    ({ selectedRange, edition, unsavedEditionPage, pathname, revisionList }),
  {
    enterArticleEditionCreation,
    onEnterArticleCreation,
    setArticleEditionProperty,
    editionDetailFetch,
    editionEnterPage: editionArticleEnterPage,
    editionDisposePage,
    push,
  },
)(EditionArticle));
