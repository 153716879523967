import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setArticleEditionProperty } from '../../../../actions/edition';
import { ContextPanelItem } from '../../../common/context/ContextPanel';
import Select from '../../../ui/Select';

const style = () => ({
  select: {
    width: 'auto',
    margin: 0,
  },
});

const WorkFlowStatus = ({
  classes,
  workflowOptions,
  setArticleEditionProperty: changeWorkflow,
  selectedWorkflow,
}) => {
  useEffect(() => {
    if (selectedWorkflow.length === 0 && workflowOptions.length > 0) {
      changeWorkflow(['workflow', [workflowOptions[0]]]);
    }
  }, [workflowOptions, selectedWorkflow]);

  const selectedId = selectedWorkflow.length > 0 ? selectedWorkflow[0].id : null;

  return (<ContextPanelItem label={'Workflow'}>
    <Select
      className={classes.select}
      value={selectedId}
      onChange={event => changeWorkflow(['workflow', [workflowOptions.find(({ id }) => id === event.target.value)]])}
      items={workflowOptions}
    />
  </ContextPanelItem>);
};

WorkFlowStatus.defaultProps = {
  data: '',
  selectedWorkflow: null,
};

WorkFlowStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  workflowOptions: PropTypes.array.isRequired,
  selectedWorkflow: PropTypes.number,
};


export default withStyles(style)(connect(
  ({ edition: { workflow: selectedWorkflow }, frame: { workflowOptions } }) =>
    ({ selectedWorkflow, workflowOptions }),
  { setArticleEditionProperty },
)(WorkFlowStatus));
