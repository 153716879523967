import { ofType } from 'redux-observable';
import { filter, flatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
  FEED_FETCH_ALL,
  FEED_FETCH_ALL_LOADED,
  FEED_FETCH_ALL_REJECTED,
  FEED_TIMELINE_SAVE,
  FEED_SAVE,
  FEED_SAVE_SUCCESS,
  FEED_SAVE_REJECTED,
} from '../../constants/actionTypes/feed';

import apiCatchError, { showSuccessNotification } from '../helper/notification';

export const fetchFeeds = (action$, state$) => {
  return action$.pipe(
    ofType(FEED_FETCH_ALL),
    withLatestFrom(state$),
    map(([, state]) => state),
    switchMap(({ frame: { selectedPublication: { domain } } }) =>
      ajax.getJSON(`/api/manual-list/json_list/${domain}`).pipe(
        flatMap(response => of({
          type: FEED_FETCH_ALL_LOADED,
          value: response,
        })),
        apiCatchError(FEED_FETCH_ALL_REJECTED),
      ),
    ),
  );
};

export const onSaveFeed = (action$, state$) => action$.pipe(
  ofType(FEED_TIMELINE_SAVE, FEED_SAVE),
  withLatestFrom(state$),
  filter(([{ value }, { feed }]) => typeof feed[value].id === 'undefined'),
  switchMap(([{ value }, state]) => {
    const { selectedPublication } = state.frame;
    const feed = state.feed[value];
    const { items = [], id, ...rest } = feed;
    return ajax.post('/api/manual-list', {
      bundle: [
        {
          target_id: 'json_list',
          target_type: 'flowz_manual_list_type',
        },
      ],
      field_publication: [
        {
          target_id: selectedPublication.id,
        },
      ],
      field_manual_list_json: [{
        value: JSON.stringify({
          ...rest,
          items,
        }),
      }],
      name: [{
        value,
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      flatMap(({ response }) => of({
        type: FEED_SAVE_SUCCESS,
        value: { response },
      })),
      apiCatchError(FEED_SAVE_REJECTED),
    );
  }),
);

export const onUpdateFeed = (action$, state$) => action$.pipe(
  ofType(FEED_TIMELINE_SAVE, FEED_SAVE),
  withLatestFrom(state$),
  filter(([{ value }, { feed }]) => typeof feed[value].id !== 'undefined'),
  switchMap(([{ value }, state]) => {
    const feed = state.feed[value];
    const { items = [], id, ...rest } = feed;
    return ajax.patch(`/api/manual-list/${id}`, {
      bundle: [
        {
          target_id: 'json_list',
          target_type: 'flowz_manual_list_type',
        },
      ],
      field_manual_list_json: [{
        value: JSON.stringify({
          ...rest,
          items,
        }),
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      flatMap(({ response }) => of({
        type: FEED_SAVE_SUCCESS,
        value: { response },
      })),
      apiCatchError(FEED_SAVE_REJECTED),
    );
  }),
);

export const feedSaveNotification = action$ => action$.pipe(
  ofType(FEED_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Save successful')),
);
