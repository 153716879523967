import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const styles = () => ({
  root: {
    // borderRadius: 25,
    boxShadow: 'none',
  },
});

/**
 * @deprecated replace with Button
 * @todo replace with Button
 */
const TextButton = (props) => {
  const { classes, className, children, onClick, disabled, size, color, variant, ...rest } = props;
  const btnClasses = [classes.root];
  if (className) {
    btnClasses.push(className);
  }
  return (
    <Button
      color={color}
      variant={variant}
      size={size}
      className={btnClasses.join(' ')}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >{children}</Button>
  );
};

TextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
};

TextButton.defaultProps = {
  className: null,
  size: 'small',
  color: 'primary',
  variant: 'contained',
  disabled: false,
};

export default withStyles(styles)(TextButton);
