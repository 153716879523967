export const STORY_ADD_PAGE = 'STORY_ADD_PAGE';
export const STORY_REMOVE_PAGE = 'STORY_REMOVE_PAGE';
export const STORY_REORDER_PAGE = 'STORY_REORDER_PAGE';
export const STORY_UPDATE_PAGE_PROP = 'STORY_UPDATE_PAGE_PROP';

export const STORY_UPDATE_FIELD = 'STORY_UPDATE_FIELD';
export const STORY_ADD_TO_FIELD = 'STORY_ADD_TO_FIELD';
export const STORY_REMOVE_FROM_FIELD = 'STORY_REMOVE_FROM_FIELD';
export const STORY_ON_BLUR_FIELD = 'STORY_ON_BLUR_FIELD';

export const STORY_SET_CONTEXT = 'STORY_SET_CONTEXT';

export const STORY_FETCH = 'STORY_FETCH';
export const STORY_FETCH_SUCCESS = 'STORY_FETCH_SUCCESS';
export const STORY_FETCH_REJECTED = 'STORY_FETCH_REJECTED';
export const STORY_DISPOSE = 'STORY_DISPOSE';
export const STORY_SAVE = 'STORY_SAVE';
export const STORY_SAVE_READY = 'STORY_SAVE_READY';
export const STORY_SAVE_NOT_READY = 'STORY_SAVE_NOT_READY';
export const STORY_SAVE_SUCCESS = 'STORY_SAVE_SUCCESS';
export const STORY_SAVE_REJECTED = 'STORY_SAVE_REJECTED';
export const STORY_DELETE = 'STORY_DELETE';
export const STORY_DELETE_SUCCESS = 'STORY_DELETE_SUCCESS';
export const STORY_DELETE_REJECTED = 'STORY_DELETE_REJECTED';

export const STORY_REQUEST_PREVIEW = 'STORY_REQUEST_PREVIEW';
export const STORY_REQUEST_PREVIEW_SUCCESS = 'STORY_REQUEST_PREVIEW_SUCCESS';
export const STORY_REQUEST_PREVIEW_REJECTED = 'STORY_REQUEST_PREVIEW_REJECTED';

export const STORY_LIST_FETCH = 'STORY_LIST_FETCH';
export const STORY_LIST_DISPOSE = 'STORY_LIST_DISPOSE';
export const STORY_LIST_FETCH_SUCCESS = 'STORY_LIST_FETCH_SUCCESS';
export const STORY_LIST_FETCH_REJECTED = 'STORY_LIST_FETCH_REJECTED';

export const STORY_BOOKEND_ARTICLE_DROP = 'STORY_BOOKEND_ARTICLE_DROP';
export const STORY_BOOKEND_ARTICLE_REMOVE = 'STORY_BOOKEND_ARTICLE_REMOVE';

// Autocomplete
export const STORY_AUTOCOMPLETE = 'STORY_AUTOCOMPLETE';
export const STORY_AUTOCOMPLETE_SUCCESS = 'STORY_AUTOCOMPLETE_SUCCESS';
