import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid/Grid';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import SectionAdd from '../components/autocomplete/section/SectionAdd';
import { removeSectionSidebar, selectSection, sidebarLayoutLoadPage } from '../actions/sidebar';
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeadCell } from '../components/ui/table/Table';
import Button from '../components/ui/buttons/Button';
import IconButton from '../components/ui/buttons/IconButton';

export const SidebarListBreadcrumb = [
  {
    title: 'Sidebar Manager',
    slug: 'sidebar',
  },
];

const SidebarList = ({
  sidebarSection,
  removeSectionSidebar: onRemoveSectionSidebar,
  selectSection: onSelectSection,
  sidebarLayoutLoadPage: onSidebarLayoutLoadPage,
  push: onRouteChange,
}) => {
  const [sectionDialogOpen, setSectionDialogOpen] = useState(false);
  useEffect(() => {
    onSidebarLayoutLoadPage();
  }, []);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={SidebarListBreadcrumb}>
        <PageToolbarButtons>
          <Button
            onClick={() => setSectionDialogOpen(true)}
            variant={'contained'}
            startIcon={<AddIcon />}
          >Add section</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <PageSection>
          <Table>
            <TableHead>
              <TableRow hasBorder>
                <TableHeadCell>Sidebars</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {sidebarSection.map(({ data: { id, name, section } }) => (
                <TableRow hasActions key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    <Grid container wrap={'nowrap'} justify={'flex-end'}>
                      <IconButton
                        color={'primary'}
                        onClick={() => onRouteChange(`/sidebar/${id}`)}
                      >
                        <EditOutlined />
                      </IconButton>
                      {section && <IconButton
                        color={'primary'}
                        onClick={() => {
                          window.confirm(`Please confirm you want to remove this ${name} sidebar layout`) && onRemoveSectionSidebar(id);
                        }}
                      >
                        <DeleteOutlined />
                      </IconButton>}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PageSection>
        <SectionAdd
          showAddButton={false}
          selectSection={onSelectSection}
          openDialog={() => setSectionDialogOpen(true)}
          closeDialog={() => setSectionDialogOpen(false)}
          isToggle={sectionDialogOpen}
        />
      </PageContent>
    </Page>
  );
};

SidebarList.propTypes = {
  removeSectionSidebar: PropTypes.func.isRequired,
  selectSection: PropTypes.func.isRequired,
  sidebarLayoutLoadPage: PropTypes.func.isRequired,
  sidebarSection: PropTypes.array,
  push: PropTypes.func.isRequired,
};

SidebarList.defaultTypes = {
  isToggle: false,
};

SidebarList.defaultProps = {
  selectedComponent: {},
  sidebarSection: [],
};

export default connect(
  ({ sidebar: { sidebarSection } }) => ({ sidebarSection }),
  { removeSectionSidebar, selectSection, sidebarLayoutLoadPage, push },
)(SidebarList);
