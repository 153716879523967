import { PURGE } from 'redux-persist';
import {
  REDIRECT_TO_LOGIN,
  RESET_PASSWORD_SUCCESS,
  PROFILE_FETCH_SUCCESS,
  PROFILE_PERMISSION_SUCCESSFUL,
  UPDATE_PROFILE_SUCCESS,

} from 'constants/actionTypes';
import {
  USER_SET_MFA_REQUIRED,
  MFA_FETCH_QR_SUCCESS, USER_SET_MFA_MISSING,
} from 'constants/actionTypes/user';
import {
  FORGOTTEN_PASSWORD_REQUEST_FAILURE,
  FORGOTTEN_PASSWORD_REQUEST_SUCCESS, LOGIN_COMPLETE,
  LOGIN_FAILURE, LOGIN_SUCCESS,
  LOGIN_SET_CONTEXT, LOGIN_RESET_CONTEXT, LOGIN_TRANSITION_COMPLETE,
} from 'constants/actionTypes/login';
import { LOGIN_CONTEXT_LOGIN, LOGIN_CONTEXT_MFA, LOGIN_CONTEXT_MFA_ENABLE } from 'constants/login';

const defaultState = {
  context: LOGIN_CONTEXT_LOGIN,
  mfa: {},
  qr: '',
  secret: '',
  failureMessage: '',
  successMessage: '',
  user: {
    name: '',
    email: '',
    mfa: '',
  },
  permissions: [],
  complete: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_SET_CONTEXT:
      return {
        ...state,
        context: action.value,
      };

    case LOGIN_RESET_CONTEXT:
      return {
        ...state,
        context: defaultState.context,
      };

    case PROFILE_PERMISSION_SUCCESSFUL:
      return {
        ...state,
        permissions: action.value,
      };

    case UPDATE_PROFILE_SUCCESS: {
      const {
        name: [{ value: name }],
        mail: [{ value: email }],
        field_mfa_data: [{ value: mfa }],
      } = action.value;
      return {
        ...state,
        user: {
          ...state.user,
          name,
          email,
          mfa,
        },
      };
    }

    case MFA_FETCH_QR_SUCCESS:
      return {
        ...state,
        qr: action.value.qr,
        secret: action.value.secretCode,
      };

    case USER_SET_MFA_REQUIRED:
      return {
        ...state,
        context: LOGIN_CONTEXT_MFA,
        mfa: action.value.mfa,
      };

    case USER_SET_MFA_MISSING:
      return  {
        ...state,
        context: LOGIN_CONTEXT_MFA_ENABLE,
      };

    case PROFILE_FETCH_SUCCESS: {
      const { email, imageUrl } = action.value;
      return {
        ...state,
        user: {
          ...state.user,
          email,
          imageUrl,
        },
      };
    }

    case REDIRECT_TO_LOGIN:
      return { ...state, user: defaultState.user, complete: false };

    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.value,
        failureMessage: defaultState.failureMessage,
        successMessage: defaultState.successMessage,
        mfa: defaultState.mfa,
      };

    case LOGIN_COMPLETE:
      return { ...state, complete: true };

    case LOGIN_TRANSITION_COMPLETE:
      return {
        ...state,
        context: defaultState.context,
      };

    case LOGIN_FAILURE:
      return { ...state, failureMessage: action.value };

    case FORGOTTEN_PASSWORD_REQUEST_SUCCESS:
      return { ...state, successMessage: 'Password reset email has been sent.' };

    case FORGOTTEN_PASSWORD_REQUEST_FAILURE:
      return { ...state, failureMessage: action.value };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, successMessage: action.value };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
