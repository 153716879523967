import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

import { onComponentDragStart, onComponentDragEnd } from '../../../actions/layout';
import { handleDropComponent } from '../../../actions/sidebar';

import Component from './Component';
import ComponentDropZone from '../../layout/components/common/ComponentDropZone';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const SortableItem = SortableElement(props => <Component {...props} />);

const ComponentContainer = withStyles(styles)(({
  items, disabled, classes, highlighted, handleDropComponent: onDrop, ...rest
}) => (
  <div>
    {items.map((value, index) => ([
      <ComponentDropZone handleDropComponent={onDrop} delta={index} key={`dropzone${value.id}`} />,
      <SortableItem
        disabled={disabled}
        key={value.id}
        position={index}
        isHighlighted={(index === highlighted)}
        index={index}
        component={value}
        {...rest}
      />,
    ]))}
    <ComponentDropZone handleDropComponent={onDrop} delta={items.length} />
  </div>
));

const SortableList = SortableContainer(props => <ComponentContainer {...props} />);


const SelectedComponents = (props) => {
  const {
    classes,
    selectedComponents,
    focused, focusComponent, highlighted,
    moveComponent, removeComponent,
    setPreviewFocus, setPreviewHighlight,
    onComponentDragStart: startDrag, onComponentDragEnd: endDrag,
    sessionLocked, sessionLockedComponents,
    handleDropComponent: onDrop,
  } = props;

  useEffect(() => {
    if (focused > -1) {
      const target = document.querySelectorAll('[data-type="layout-component"]')[focused];
      if (typeof target !== 'undefined') {
        let parent = target.parentNode;
        let i = 0;
        while (typeof parent !== 'undefined' && i < 2) {
          parent = parent.parentNode;
          i += 1;
        }
        parent.scrollTop = target.offsetTop;
      }
      focusComponent(-1);
    }
  }, [focused]);

  return (<div className={classes.root}>
    <SortableList
      distance={1}
      useDragHandle
      disabled={!!sessionLocked}
      items={selectedComponents}
      removeComponent={removeComponent}
      highlighted={highlighted}
      onSortStart={() => startDrag()}
      onSortEnd={({ oldIndex, newIndex }) => {
        endDrag();
        moveComponent(oldIndex, newIndex);
      }}
      lockedComponents={sessionLockedComponents}
      setPreviewFocus={setPreviewFocus}
      setPreviewHighlight={setPreviewHighlight}
      handleDropComponent={onDrop}
    />
  </div>);
};

SelectedComponents.propTypes = {
  selectedComponents: PropTypes.array.isRequired,
  focused: PropTypes.number.isRequired,
  focusComponent: PropTypes.func.isRequired,
  highlighted: PropTypes.number.isRequired,
  moveComponent: PropTypes.func.isRequired,
  removeComponent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onComponentDragStart: PropTypes.func.isRequired,
  onComponentDragEnd: PropTypes.func.isRequired,
  setPreviewFocus: PropTypes.func.isRequired,
  setPreviewHighlight: PropTypes.func.isRequired,
  handleDropComponent: PropTypes.func.isRequired,
  sessionLocked: PropTypes.object.isRequired,
  sessionLockedComponents: PropTypes.array.isRequired,
};

export default withStyles(styles)(connect(
  () => ({ }),
  { onComponentDragStart, onComponentDragEnd, handleDropComponent },
)(SelectedComponents));
