import {
  VIDEO_DUGOUT,
  VIDEO_JWPLAYER,
  MEDIA_ENTITY_IDS, VIDEO_DM,
} from '../../constants/media/media';

export const getMediaId = type => MEDIA_ENTITY_IDS[type] || null;

export const getMediaName = (type, data) => {
  switch (type) {
    case VIDEO_DM: {
      const { pathname } = new URL(data.url);
      const paths = pathname.split('/');
      return paths[paths.length - 1];
    }
    case VIDEO_DUGOUT:
      return data.embed_hash;
    case VIDEO_JWPLAYER:
      return data.mediaid;
    default:
      return data.id;
  }
};

export const getMediaExtras = (type, data) => {
  switch (type) {
    case VIDEO_DUGOUT:
      return {
        field_extra_media_info: [{
          value: JSON.stringify(data),
        }],
      };
    case VIDEO_JWPLAYER:
      return {
        field_extra_media_info: JSON.stringify(data),
      };
    default:
      return {};
  }
};
