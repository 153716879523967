import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const styles = () => ({
  root: {
  },
  frame: {
    display: 'block',
    width: '100%',
  },
});

const getLayoutPath = (layout, path, pathname) => {
  const bundle = pathname.match(/\/layout\/([a-z_]+)\/[a-z0-9]+/);
  if (!bundle) return '/';
  const { selectedTerm } = layout;
  if (selectedTerm?.path) {
    return `/${selectedTerm.path.replace(path, '').replace(/^\//, '')}`;
  }
  return '/';
};

const processPreviewData = preview => (
  {
    ...preview,
    components: preview.components.map((component, index) => (
      {
        ...component,
        delta: index,
      }
    )),
  }
);

// @todo rework with ref instead of id, as is more on pattern
const LayoutPreview = (props) => {
  const {
    classes, className, domain, environment, sectionRoot, layout, previewMode,
    focused, highlighted, hydrated, setHydrated, pathname,
  } = props;
  const { preview, previewProp, previewOverride } = layout;

  let path = '';

  if (sectionRoot && sectionRoot.sourcePath) {
    path = sectionRoot.sourcePath;
  }

  const base = process.env.REACT_APP_ENVIRONMENT_WEB || environment.web.replace(/\/$/, '');
  const previewPath = `${base}${getLayoutPath(layout, path, pathname)}?domain=${domain}&preview&previewMode=${previewMode}`;

  useEffect(() => {
    if (focused > -1) {
      const iframe = document.querySelector('#layout-preview');
      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({ event: 'click', data: focused }, '*');
      }
    }
  }, [focused]);

  useEffect(() => {
    if (highlighted > -1) {
      const iframe = document.querySelector('#layout-preview');
      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({ event: 'hover', data: highlighted }, '*');
      }
    }
  }, [highlighted]);

  useEffect(() => {
    if (typeof preview.components !== 'undefined') {
      const iframe = document.querySelector('#layout-preview');
      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({ event: 'reload', data: processPreviewData(preview) }, '*');
      }
    }
  }, [preview]);

  useEffect(() => {
    const iframe = document.querySelector('#layout-preview');
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage({ event: 'updateProp', data: previewProp }, '*');
    }
  }, [previewProp]);

  useEffect(() => {
    const iframe = document.querySelector('#layout-preview');
    if (iframe?.contentWindow) {
      iframe.contentWindow.postMessage({ event: 'updateOverride', data: previewOverride }, '*');
    }
  }, [previewOverride]);

  useEffect(() => {
    if (hydrated) {
      const iframe = document.querySelector('#layout-preview');
      if (iframe?.contentWindow) {
        if (typeof preview.components !== 'undefined') {
          iframe.contentWindow.postMessage({ event: 'reload', data: processPreviewData(preview) }, '*');
        }
        iframe.contentWindow.postMessage({ event: 'updateProp', data: previewProp }, '*');
        iframe.contentWindow.postMessage({ event: 'updateOverride', data: previewOverride }, '*');
      }
      setHydrated(false);
    }
  }, [hydrated]);

  return (
    <div className={`${classes.root} ${className}`}>
      <iframe id={'layout-preview'} className={classes.frame} title={'layout-preview'} src={previewPath} frameBorder={'0'} />
    </div>
  );
};

LayoutPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  domain: PropTypes.string.isRequired,
  environment: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  hydrated: PropTypes.bool.isRequired,
  setHydrated: PropTypes.func.isRequired,
  focused: PropTypes.number.isRequired,
  highlighted: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  previewMode: PropTypes.string,
  sectionRoot: PropTypes.object,
};

LayoutPreview.defaultProps = {
  className: '',
  previewMode: 'docked',
  sectionRoot: null,
};

export default withStyles(styles)(connect(
  ({
    frame: { selectedPublication: { domain, environment, sectionRoot } },
    router: { location: { pathname } },
    layout,
  }) =>
    ({ domain, environment, sectionRoot, layout, pathname }),
  {},
)(LayoutPreview));

