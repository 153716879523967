import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line react/display-name
const ViafouraIcon = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props} viewBox={'0 0 384 384'}>
    <path d="M308.9 119.8l-.3.6s-15.8 27.7-16 28c-1.2 1.8-3.2 3-5.6 3-2.7 0-5.1-1.7-6.1-4l-15-27.1c-.6-1-.9-2.1-.9-3.3 0-3.7 3-6.7 6.7-6.7h31.2c3.7-.1 6.7 2.9 6.7 6.6 0 1.1-.2 2.1-.7 2.9zm-34.8 53.5c.6 1 .9 2.1.9 3.3 0 1.2-.3 2.3-.9 3.3L244.4 231l-.2.3c-1.2 1.9-3.3 3.1-5.7 3.1-2.9 0-5.4-1.8-6.3-4.4l-62.8-109.9c-.6-1-.9-2.1-.9-3.3 0-3.7 3-6.7 6.7-6.7h58.9c2.8.1 5 1.6 6.1 3.8l33.9 59.4zm-47.9 83c.6 1 .9 2.1.9 3.3 0 1.2-.3 2.4-.9 3.4l-29.7 51.2c-1.2 1.9-3.3 3.2-5.7 3.2-2.4 0-4.5-1.3-5.7-3.2l-.2-.3v-.1L73.6 120.3c-.6-1-.9-2.2-.9-3.4 0-3.7 3-6.7 6.7-6.7h59c2.4 0 4.5 1.4 5.7 3.3l82.1 142.8zM191.8 0C85.9 0 0 85.9 0 191.8s85.9 191.8 191.8 191.8 191.8-85.9 191.8-191.8S297.7 0 191.8 0z" fillRule="nonzero" />
  </SvgIcon>
));

export default ViafouraIcon;
