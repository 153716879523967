import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { setLocalProp } from '../../../../actions/dataState';

import {
  FIELD_LIVEBLOG_KEYPOINTS,
  FIELD_LIVEBLOG_KEYPOINTS_TITLE,
} from '../../../../constants/article/articleFields';

import KeyPoints from '../../../liveblog/liveblogBuilder/KeyPoints';
import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfield';
import {
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../../selectors/fieldLock/fieldLock';
import FieldLockContainer from '../../../fieldLock/FieldLockContainer';

const LiveblogKeyPoints = ({
  keypoints, lockValue,
  setLocalProp: setProp,
}) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <FieldLockTextfield field={FIELD_LIVEBLOG_KEYPOINTS_TITLE} label={'Key points title'} />
    </Grid>
    <Grid item xs={12}>
      <FieldLockContainer field={FIELD_LIVEBLOG_KEYPOINTS} lockValue={lockValue}>
        <KeyPoints
          keyPoints={keypoints}
          onChange={value => setProp(FIELD_LIVEBLOG_KEYPOINTS, value)}
        />
      </FieldLockContainer>
    </Grid>
  </Grid>
);

LiveblogKeyPoints.propTypes = {
  keypoints: PropTypes.string,
  lockValue: PropTypes.object,
  setLocalProp: PropTypes.func.isRequired,
};

LiveblogKeyPoints.defaultProps = {
  keypoints: '',
  lockValue: null,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  return {
    keypoints: getFieldValue(state, { field: FIELD_LIVEBLOG_KEYPOINTS }),
    lockValue: getFieldLockValue(state, { field: FIELD_LIVEBLOG_KEYPOINTS }),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(LiveblogKeyPoints);
