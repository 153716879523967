import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import green from '@material-ui/core/colors/green';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Table, TableCell, TableHead, TableBody, TableRow } from '../ui/table/Table';

import Dialog from './common/Dialog';

import { revertArticle } from '../../actions/article';
import { editionRevert } from '../../actions/edition';
import {
  onSelectRevision,
  onPreviewRevision,
  compareRevision,
} from '../../actions/revision';
import {
  REVISION_REVERT_WARNING,
} from '../../constants/article/article';
import {
  REVISION_NOT_PUBLISHED,
  REVISION_OFFLINE,
  REVISION_PUBLISHED,
  REVISION_SCHEDULED,
} from '../../constants/revision';
import ButtonWarning from '../ui/buttons/ButtonWarning';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    '& .jsondiffpatch-node li': {
      display: 'flex',
    },
    '& .jsondiffpatch-delta pre': {
      whiteSpace: 'break-spaces',
    },
  },
  preview: {
    marginRight: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  compareTable: {
    wordWrap: 'break-word',
    tableLayout: 'fixed',
  },
  activeRevision: {
    background: fade(green[100], 0.5),
  },
});

const RevisionDialog = (props) => {
  const {
    classes,
    list,
    diff,
    onSelectRevision: onSelect,
    onPreviewRevision: onPreviewRequest,
    compareRevision: sendCompareIndex,
    revertArticle: revertArticleRevision,
    editionRevert: revertEditionRevision,
    push: goto,
    editedRevisionId,
    pathname,
    ...rest
  } = props;
  const [compare, setCompare] = useState([]);

  const activeRevision = (() => {
    let found = null;
    return (status, revisionId) => {
      if (editedRevisionId === revisionId) {
        found = true;
        return true;
      }
      if (status !== REVISION_OFFLINE && !found) {
        found = true;
        return true;
      }
      return false;
    };
  })();

  const publishedArticleIndex = list.findIndex(({ status }) => status === REVISION_PUBLISHED);

  useEffect(() => {
    sendCompareIndex(compare);
  }, [compare, sendCompareIndex]);

  const today = moment().unix();
  const getStatus = (status) => {
    switch (status) {
      case REVISION_PUBLISHED:
        return 'Published';
      case REVISION_NOT_PUBLISHED:
        return 'Not published';
      case REVISION_OFFLINE:
        return 'Offline';
      case REVISION_SCHEDULED:
        return 'Scheduled';
      default:
        return 'unknown';
    }
  };
  return (
    <Dialog title={'Revisions'} maxWidth={'md'} {...rest}>
      <div className={classes.root}>
        <Paper>
          {diff.length > 0 && <Table className={classes.compareTable}>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell width={'70%'}>Changes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diff.map(([key, changes]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>{changes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          }
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  {['Time', 'Updated by', 'Status', 'Compare', 'Action'].map((col, index, myCollection) =>
                    <TableCell key={col} variant={'head'} align={index + 1 === myCollection.length ? 'right' : 'left'}>{col}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {list.length === 0 && <TableRow><TableCell component="th" scope="row" colSpan={3}>No revision is found</TableCell></TableRow>}
                {list.length > 0 && list.map((revision, index) => {
                  const { time, payload, user, status, revision_id: revisionId } = revision;
                  let entityStatus = status;
                  const { field_article_display_date: articleDisplayDate } = payload;
                  const active = activeRevision(entityStatus, revisionId);
                  let publishDate = moment.unix(time);
                  // show scheduled status accordingly
                  if (active && articleDisplayDate && articleDisplayDate.length > 0) {
                    const [{ value: displayDate }] = articleDisplayDate;
                    publishDate = moment(displayDate);
                    if (publishDate.unix() > today) {
                      entityStatus = REVISION_SCHEDULED;
                    }
                  }
                  return (
                    <TableRow key={time} className={active ? classes.activeRevision : ''}>
                      <TableCell component="td" scope="row">
                        { publishDate.format('lll') }
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {user.name}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        { getStatus(entityStatus) }
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={list.length === 1}
                          onClick={(e) => {
                            if (e.target.checked) {
                              const values = [...compare, index].slice(-2);
                              setCompare(values);
                            } else {
                              const values = compare.filter(x => x !== index);
                              setCompare(values);
                            }
                          }}
                          checked={compare.includes(index)}
                          value={index}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" align={'right'}>
                        { !active && <>
                          <Button
                            onClick={() => {
                              // TIDE ARTICLE
                              if (payload.bundle) {
                                rest.handleClose();
                                goto(`${pathname}/${revisionId}`);
                                return;
                              }
                              // WEB ARTICLE
                              onPreviewRequest(payload);
                            }}
                            variant={'contained'}
                            size={'small'}
                            className={classes.preview}
                          >
                            <VisibilityIcon className={classes.preview} />{'View'}
                          </Button>
                          {(status === REVISION_OFFLINE) && <Button
                            onClick={() => {
                              onSelect(revision);
                              rest.handleClose();
                            }}
                            variant={'contained'}
                            color={'primary'}
                            size={'small'}
                          >Load</Button>}
                          {(status !== REVISION_OFFLINE && index !== publishedArticleIndex) && <ButtonWarning
                            onClick={() => {
                              if (window.confirm(REVISION_REVERT_WARNING)) {
                                rest.handleClose();
                                if (payload.bundle) {
                                  return revertEditionRevision(payload);
                                }
                                return revertArticleRevision(payload);
                              }
                            }}
                            variant={'contained'}
                            size={'small'}
                          >Revert</ButtonWarning>}
                        </>}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      </div>
    </Dialog>
  );
};

RevisionDialog.defaultProps = {
  list: [],
};

RevisionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectRevision: PropTypes.func.isRequired,
  onPreviewRevision: PropTypes.func.isRequired,
  compareRevision: PropTypes.func.isRequired,
  revertArticle: PropTypes.func.isRequired,
  editionRevert: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  diff: PropTypes.array.isRequired,
  list: PropTypes.array,
  pathname: PropTypes.string.isRequired,
  editedRevisionId: PropTypes.string.isRequired,
};

export default withStyles(style)(connect(({
  revision: { list, diff },
  article: { editedRevisionId },
  router: { location: { pathname } },
}) => ({ list, diff, pathname, editedRevisionId }), {
  onSelectRevision,
  onPreviewRevision,
  compareRevision,
  push,
  revertArticle,
  editionRevert,
})(RevisionDialog));
