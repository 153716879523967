export const INIT_FIELD_LOCKING = 'INIT_FIELD_LOCKING';
export const DISPOSE_FIELD_LOCKING = 'DISPOSE_FIELD_LOCKING';
export const FIELD_LOCKING_DISPOSED = 'FIELD_LOCKING_DISPOSED';

export const SET_FIELD_LOCK = 'SET_FIELD_LOCK';
export const ADD_FIELD_LOCK = 'ADD_FIELD_LOCK';
export const REMOVE_FIELD_LOCK = 'REMOVE_FIELD_LOCK';

export const SESSION_LOCK_FIELD = 'SESSION_LOCK_FIELD';
export const SESSION_LOCK_FIELD_REQUEST = 'SESSION_LOCK_FIELD_REQUEST';
export const SESSION_UNLOCK_FIELD = 'SESSION_UNLOCK_FIELD';
export const SESSION_UNLOCK_FIELD_REQUEST = 'SESSION_UNLOCK_FIELD_REQUEST';

export const FIELD_LOCK_FIELD = 'FIELD_LOCK_FIELD';
export const FIELD_UNLOCK_FIELD = 'FIELD_UNLOCK_FIELD';

export const FIELD_LOCK_INIT = 'FIELD_LOCK_INIT';
export const FIELD_LOCK_DISPOSE = 'FIELD_LOCK_DISPOSE';

export const FIELD_LOCK_SUBSCRIBE = 'FIELD_LOCK_SUBSCRIBE';
export const FIELD_LOCK_UNSUBSCRIBE = 'FIELD_LOCK_UNSUBSCRIBE';

export const WS_FIELD_LOCK_REFRESH = 'WS_FIELD_LOCK_REFRESH';
export const WS_FIELD_LOCK_SET = 'WS_FIELD_LOCK_SET';
export const WS_FIELD_LOCK_UNSET = 'WS_FIELD_LOCK_UNSET';
export const FIELD_LOCK_SET = 'FIELD_LOCK_SET';
export const FIELD_LOCK_UNSET = 'FIELD_LOCK_UNSET';
export const FIELD_LOCK_REFRESH = 'FIELD_LOCK_REFRESH';
