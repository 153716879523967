import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '../../../ui/Select';

import {
  fetchScoreBoardCompetition,
  disposeScoreBoardCompetition,
  setScoreBoardProperty,
} from '../../../../actions/scoreboard';
import { setLocalProp } from '../../../../actions/dataState';
import {
  FIELD_SCOREBOARD_COMPETITION,
  FIELD_SCOREBOARD_MATCH,
  FIELD_SCOREBOARD_SEASON,
} from '../../../../constants/article/articleFields';

const noValue = 'Choose competition';

const Competition = ({
  competitions,
  selectedCompetition,
  disposeScoreBoardCompetition: onDispose,
  setLocalProp: setProp,
  setScoreBoardProperty: setScoreboardProp,
  fetchScoreBoardCompetition: onFetch,
}) => {
  useEffect(() => {
    if (competitions.length === 0) {
      onFetch();
    }
    return () => {
      onDispose();
    };
  }, []);

  if (competitions.length === 0) {
    return <Typography varient={'caption'}>Loading...</Typography>;
  }
  const items = competitions.reduce((acc, next) => ({
    ...acc,
    [next.id]: next.name,
  }), { [noValue]: noValue });

  return (
    <Grid container alignItems={'center'}>
      <Grid item xs={4}>
        <Typography variant={'h3'}>Competition</Typography>
      </Grid>
      <Grid item xs={8}>
        <Select
          value={selectedCompetition || noValue}
          items={items}
          onChange={(e) => {
            const competitionId = e.target.value;
            if (competitionId !== noValue) {
              const competition = competitions.find(({ id }) => id === competitionId);
              const tournament = competition
                .tournamentCalendar[competition.tournamentCalendar.length - 1];
              setProp(FIELD_SCOREBOARD_COMPETITION, competitionId);
              setProp(FIELD_SCOREBOARD_SEASON, tournament.id);
              setProp(FIELD_SCOREBOARD_MATCH, '');
              setScoreboardProp(['competitionMatches', []]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

Competition.propTypes = {
  competitions: PropTypes.array.isRequired,
  fetchScoreBoardCompetition: PropTypes.func.isRequired,
  disposeScoreBoardCompetition: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  setScoreBoardProperty: PropTypes.func.isRequired,
  selectedCompetition: PropTypes.string.isRequired,
};

export default connect(
  ({
    scoreboard: { competitions },
    dataState: { [FIELD_SCOREBOARD_COMPETITION]: selectedCompetition },
  }) => ({ competitions, selectedCompetition }),
  {
    fetchScoreBoardCompetition,
    disposeScoreBoardCompetition,
    setLocalProp,
    setScoreBoardProperty,
  },
)(Competition);

