import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';

import ScheduleIcon from '@material-ui/icons/Schedule';
import PublishIcon from '@material-ui/icons/Publish';

import SchedulerControl from './liveblogBuilder/SchedulerControl';

import {
  saveLiveblogPost,
  setLiveblogProperty,
} from 'actions/liveblog';
import { POST_FIELD_AUTHOR, POST_FIELD_BODY, POST_FIELD_TITLE } from 'constants/liveblog';

import TextField from 'components/ui/TextField';
import Button from 'components/ui/buttons/Button';
import Editor, { VARIANT_POST } from 'components/editor/Editor';
import PageBusyIndicator from 'components/common/page/PageBusyIndicator';

const styles = theme => ({
  root: {
    position: "relative",
  },
  drawer: {
    maxWidth: 400,
    minWidth: 400,
    padding: theme.spacing(3),
    '&>.MuiGrid-container': {
      marginBottom: theme.spacing(4),
    },
  },
  editor: {
    minHeight: '150px',
    margin: theme.spacing(2, -3),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  busy: {
    left: theme.spacing(-3),
    right: theme.spacing(-3),
    bottom: theme.spacing(-3),
    top: 0,
    height: 'auto',
    width: `calc(100% + ${theme.spacing(6)}px)`,
    maxWidth: 'inherit',
  },
});

const LiveblogPostEditor = ({ classes, body }) => {
  const { isBusy, postTitle, displayName } = useSelector(state => state.liveblog);
  const [publishDrawer, setPublishDrawer] = useState(false);
  const dispatch = useDispatch();

  const handleChangeTitle = e => dispatch(setLiveblogProperty([POST_FIELD_TITLE, e.target.value]));
  const handleChangeAuthor = e => dispatch(setLiveblogProperty([POST_FIELD_AUTHOR, e.target.value]));
  const handleChangeBody = value => dispatch(setLiveblogProperty([POST_FIELD_BODY, value]));

  const handleSave = () => dispatch(saveLiveblogPost());

  return (
    <Grid className={classes.root} container alignItems="center" justify="space-between">
      {isBusy && <PageBusyIndicator className={classes.busy} disableInteraction message="Saving..." />}
      <Grid item xs={12}>
        <TextField
          label="Post Title"
          value={postTitle}
          onChange={handleChangeTitle}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Author"
          value={displayName}
          onChange={handleChangeAuthor}
        />
      </Grid>
      <Grid item xs={12}>
        <Editor
          className={classes.editor}
          content={body}
          onChange={handleChangeBody}
          variant={VARIANT_POST}
        />
      </Grid>
      <Grid item xs={12} className={classes.buttonWrapper}>
        <Button
          className={classes.button}
          variant="outlined"
          onClick={() => setPublishDrawer(true)}
          startIcon={<ScheduleIcon />}
        >Schedule</Button>
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleSave}
          startIcon={<PublishIcon />}
        >Post</Button>
      </Grid>
      <Drawer anchor={'right'} open={publishDrawer} onClose={() => setPublishDrawer(false)}>
        <div className={classes.drawer}>
          <Grid container>
            <SchedulerControl
              saveDate={() => {
                handleSave();
                setPublishDrawer(false);
              }}
            />
          </Grid>
        </div>
      </Drawer>
    </Grid>
  );
};

LiveblogPostEditor.defaultProps = {
  body: [],
};

LiveblogPostEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  body: PropTypes.array,
};

export default withStyles(styles)(LiveblogPostEditor);

