import { ofType } from 'redux-observable'
import { tap, withLatestFrom, mapTo, filter } from 'rxjs/operators'
import * as Sentry from '@sentry/react';

import {
  APP_LOAD,
  SENTRY_USER_IS_SET,
} from '../../constants/actionTypes';
import {LOGIN_COMPLETE} from "../../constants/actionTypes/login";

export const setUserForSentry = (action$, state$) => action$.pipe(
  ofType(LOGIN_COMPLETE, APP_LOAD),
  withLatestFrom(state$, (a, b) => b),
  filter(({ login }) => login?.user?.email),
  tap(({ login: { user: { email } } }) => Sentry.setUser({ email })),
  mapTo({ type: SENTRY_USER_IS_SET }),
);
