import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

const styles = () => ({
  iframe: {
    maxWidth: 600,
    margin: '0 auto',
  },
});

const Iframe = (props) => {
  const { classes, data: { url } } = props;
  return (<Grid container>
    <Grid item className={classes.iframe}>
      <iframe title={url} src={url} width={600} height={400} />
    </Grid>
  </Grid>);
};

Iframe.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(Iframe);
