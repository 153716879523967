import FacebookElement from './components/FacebookElement';

export const ELEMENT_FACEBOOK = 'facebook';
export const PROP_URL = 'url';

export const FACEBOOK_PATTERN = /^http[s]?:\/\/(www\.)?facebook\.com\/\w+\/(posts|videos)\/\w+/;

export const DEFAULTS_FACEBOOK = {
  facebook: {
    component: FacebookElement,
    type: ELEMENT_FACEBOOK,
    rootProps: {
      className: 'slate-facebook',
    },
  },
};
