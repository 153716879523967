import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';

import ArticleNotes from './articleBuilder/ArticleNotes';
import EditionRevisionStatus from './EditionRevisionStatus';
import WorkFlowStatus from './articleBuilder/WorkFlowStatus';

const styles = () => ({
  panel: {
    overflow: 'visible',
  },
});

const ArticleStatus = ({ classes }) => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Status</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails className={classes.panel}>
      <WorkFlowStatus />
      <EditionRevisionStatus />
      <ArticleNotes />
    </ContextPanelDetails>
  </ContextPanel>
);

ArticleStatus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArticleStatus);

