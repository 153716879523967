import { mergeDeepRight } from 'rambdax';

import { keyFieldsForPayload, keyFieldsForData } from './helper';
import defaultConfig from './defaultConfig';
import {
  HERO_VIDEO_DM, HERO_VIDEO_DUGOUT, HERO_VIDEO_JWPLAYER, HERO_VIDEO_YOUTUBE, MEDIA_ENTITY_TYPE,
  MEDIA_GALLERY, MEDIA_IMAGE, MEDIA_VIDEO,
} from '../../constants/media/media';

class BaseEntity {
  constructor(
    entityType,
    entityBundle,
    fieldMapping,
    payloadFields,
    extraFields = null,
    requiredFields = [],
    config = {},
  ) {
    this.entityType = entityType;
    this.entityBundle = entityBundle;
    this.payloadFields = payloadFields;
    this.extraFields = extraFields;
    this.requiredFields = requiredFields;
    this.fieldMapping = fieldMapping;
    this.payloadMapping = keyFieldsForPayload(fieldMapping);
    this.dataMapping = keyFieldsForData(fieldMapping);
    this.config = mergeDeepRight(defaultConfig, config);
    // include mapping for the idField
    if (this.config.idField) {
      this.dataMapping[this.config.idField] = {
        key: this.config.idField,
      };
    }
  }
  static getArrayAsKeyed(array, id = defaultConfig.idField, sanitise = value => value) {
    if (!Array.isArray(array)) { return {}; }
    return array.reduce((acc, item) =>
      ({ ...acc, [item[id]]: sanitise(item, id) }), {});
  }
  static getArrayOrder(array, id = defaultConfig.idField) {
    if (!Array.isArray(array)) { return []; }
    return array.map(({ [id]: itemId }) => itemId);
  }
  static getOrderedArray(keyedArray, order, sanitise = value => value) {
    if (!Array.isArray(order) || !keyedArray) { return []; }
    return order
      .filter(id => !!keyedArray[id])
      .map(id => sanitise(keyedArray[id], id));
  }
  getFieldDefault() {
    return '';
  }
  addFieldData(data, payload) {
    return (field) => {
      data[field] = this.getFieldData(payload, field);
    };
  }
  getFieldData(payload, field) {
    const mapping = this.dataMapping[field];
    return (
      typeof payload[mapping.key] !== 'undefined' &&
      payload[mapping.key][0] &&
      payload[mapping.key][0].target_id !== 0
        ? payload[mapping.key][0].value
          || payload[mapping.key][0].pwamp
          || payload[mapping.key][0].target_id
          || this.getFieldDefault(field)
        : this.getFieldDefault(field)
    );
  }
  getExtras(payload) {
    if (!this.config.extrasField) {
      return null;
    }
    try {
      return JSON.parse(payload[this.config.extrasField][0].value);
    } catch (e) {
      return null;
    }
  }
  addExtraData(data, extras) {
    return (field) => {
      data[field] = this.getExtraData(extras, field);
    };
  }
  getExtraData(extras, field) {
    const mapping = this.dataMapping[field];
    return extras[mapping.key] || this.getFieldDefault(field);
  }
  getDataFromPayload(payload, exclude = []) {
    if (!payload) {
      return null;
    }
    const data = {};
    if (this.config.idField) {
      data[this.config.idField] = this.getFieldData(payload, this.config.idField);
      exclude.push(this.config.idField);
    }
    this.payloadFields
      .filter(field => !exclude.includes(field) && !!this.dataMapping[field])
      .forEach(this.addFieldData(data, payload));
    if (this.extraFields) {
      const extras = this.getExtras(payload);
      if (extras) {
        this.extraFields
          .filter(field =>
            !exclude.includes(field) &&
            !!this.dataMapping[field] &&
            Object.prototype.hasOwnProperty.call(extras, this.dataMapping[field].key))
          .forEach(this.addExtraData(data, extras));
      }
    }
    return data;
  }
  getPayloadBase(serverData, publication) {
    const payload = {
      bundle: [{
        target_id: this.entityBundle,
        target_type: this.entityType,
      }],
    };
    if (this.config.idField && serverData[this.config.idField]) {
      const mapping = this.dataMapping[this.config.idField];
      payload[mapping.key] = this.getPayloadFieldData(serverData, this.config.idField);
    }
    if (this.config.publicationField && publication) {
      payload[this.config.publicationField] = [{
        target_id: publication.id,
        target_type: 'taxonomy_term',
      }];
    }
    return payload;
  }
  getPayloadFieldDataReference(value) {
    switch (value.type) {
      case HERO_VIDEO_JWPLAYER:
      case HERO_VIDEO_YOUTUBE:
      case HERO_VIDEO_DUGOUT:
      case HERO_VIDEO_DM:
      case MEDIA_VIDEO:
      case MEDIA_GALLERY:
      case MEDIA_IMAGE:
        if (!value?.data?.mid) {
          return null;
        }
        return [{
          target_type: MEDIA_ENTITY_TYPE,
          target_id: value.data.mid,
        }];
      default:
        return [{ value }];
    }
  }
  getPayloadFieldData(data, field) {
    const value = data[field];
    if (
      typeof value === 'object' &&
      value !== null &&
      value.type
    ) {
      return this.getPayloadFieldDataReference(value);
    }
    return [{ value }];
  }
  getPayloadFields(data) {
    const payload = {};
    this.payloadFields
      .filter(field => Object.keys(data).includes(field) && !!this.dataMapping[field]?.key)
      .forEach((field) => {
        const mapping = this.dataMapping[field];
        payload[mapping.key] = this.getPayloadFieldData(data, field);
      });
    return payload;
  }
  getPayloadExtraData(data, field) {
    return data[field];
  }
  getPayloadExtras(data, additional = {}) {
    if (!this.config.extrasField || !this.extraFields) {
      return null;
    }
    const extras = {};
    this.extraFields
      .filter(field => Object.keys(data).includes(field) && !!this.dataMapping[field]?.key)
      .forEach((field) => {
        const mapping = this.dataMapping[field];
        extras[mapping.key] = this.getPayloadExtraData(data, field);
      });
    const combined = { ...extras, ...additional };
    if (Object.keys(combined).length === 0) {
      return null;
    }
    return {
      [this.config.extrasField]: [{
        value: JSON.stringify(combined),
      }],
    };
  }
  getMediaReferences(dataState) {
    // this function is empty in the base, each entity will require bespoke logic
    return null;
  }
  getPayloadMediaReferences(dataState) {
    if (this.config.mediaField) {
      const references = this.getMediaReferences(dataState);
      if (references) {
        return {
          [this.config.mediaField]: references,
        };
      }
    }
    return {};
  }
  getPayloadFromData(data, serverData = {}, publication = null) {
    const dataState = mergeDeepRight(serverData, data);
    return ({
      ...this.getPayloadBase(serverData, publication),
      ...this.getPayloadFields(data, serverData),
      // extras object needs to send full object instead of just changes
      ...this.getPayloadExtras(dataState),
      // media references depend on the full dataState
      ...this.getPayloadMediaReferences(dataState),
    });
  }
  getPayloadId(payload) {
    if (!this.config.idField) {
      return false;
    }
    return typeof payload[this.config.idField] !== 'undefined' && payload[this.config.idField][0]
      ? payload[this.config.idField][0].value
      : false;
  }
  getValidationErrors(data, serverData, fields = null) {
    const dataState = mergeDeepRight(serverData, data);
    const requiredFields = fields || this.requiredFields;
    return requiredFields
      .filter(field => !dataState[field]) // required fields must be truthy
      .map((field) => {
        const { label } = this.dataMapping[field];
        return ({
          field,
          message: `${label} is required`,
        });
      }) || false;
  }
}

export default BaseEntity;
