import { ofType } from 'redux-observable';
import {map, switchMap, debounceTime, withLatestFrom, distinctUntilChanged} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import apiCatchError from '../../helper/notification';
import {
  VIDEO_DUGOUT_SEARCH,
  VIDEO_DUGOUT_SEARCH_REJECTED,
  VIDEO_DUGOUT_SEARCH_SUCCESS,
} from '../../../constants/actionTypes/media';

export const onSearchVideo = (action$, state$) => action$.pipe(
  ofType(VIDEO_DUGOUT_SEARCH),
  debounceTime(750),
  distinctUntilChanged((prev, curr) => prev.value === curr.value),
  withLatestFrom(state$),
  switchMap(([
    { value },
    { frame: { selectedPublication: { publicationConfig: { dugoutToken, dugoutParams } } } },
  ]) =>
    ajax.post('/api/dugout/videos', {
      ...value,
      token: dugoutToken,
      ...dugoutParams,
    }).pipe(
      map(({ response }) => {
        if (typeof response === 'string') {
          throw new Error('Dugout API is failing');
        }
        return ({
          type: VIDEO_DUGOUT_SEARCH_SUCCESS,
          value: response,
        });
      }),
      apiCatchError(VIDEO_DUGOUT_SEARCH_REJECTED),
    ),
  ),
);
