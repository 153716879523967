import { PURGE } from 'redux-persist';
import {
  SAVE_INITIAL_HISTORY_EDITION,
  SAVE_INITIAL_HISTORY_EDITION_DETAIL,
  SET_UNSAVED_EDITION_DETAIL,
  SET_UNSAVED_EDITION_PAGE,
} from '../../constants/actionTypes';
import {
  ARTICLE_EDITION_EDIT_READY, CREATE_ARTICLE_EDITION_SUCCESS, EDITION_DETAIL_SAVE_SUCCESS,
  ENTER_ARTICLE_EDITION_CREATION, ON_ENTER_ARTICLE_EDITION_CREATION, SAVE_ARTICLE_EDITION_SUCCESS,
} from '../../constants/actionTypes/edition';

const defaultState = {
  initialEdition: '',
  initialEditionDetail: '',
  unsavedEditionPage: false,
  unsavedEditionDetail: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ON_ENTER_ARTICLE_EDITION_CREATION:
    case ENTER_ARTICLE_EDITION_CREATION:
    case SAVE_ARTICLE_EDITION_SUCCESS:
    case CREATE_ARTICLE_EDITION_SUCCESS:
    case ARTICLE_EDITION_EDIT_READY:
      return {
        ...state,
        unsavedEditionPage: false,
      };

    case SET_UNSAVED_EDITION_PAGE:
      return { ...state, unsavedEditionPage: action.value };

    case SAVE_INITIAL_HISTORY_EDITION_DETAIL: {
      return { ...state, initialEditionDetail: action.value };
    }

    case EDITION_DETAIL_SAVE_SUCCESS: {
      return { ...state, unsavedEditionDetail: false };
    }

    case SET_UNSAVED_EDITION_DETAIL:
      return { ...state, unsavedEditionDetail: action.value };

    case SAVE_INITIAL_HISTORY_EDITION:
      return { ...state, initialEdition: action.value };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
