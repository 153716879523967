import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { COMPONENT_NAMES } from '../../../../constants/components';

import DraggableComponent from '../../../ui/draggables/DraggableComponent';

const styles = () => ({
  root: {
  },
});

function ComponentDraggable(props) {
  const { classes, component, disabled } = props;
  return (
    <DraggableComponent className={classes.root} component={component} disabled={disabled}>
      <Typography variant={'body2'} component={'h2'}>{COMPONENT_NAMES[component.type] || component.name}</Typography>
    </DraggableComponent>
  );
}

ComponentDraggable.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

ComponentDraggable.defaultPros = {
  disabled: false,
}

export default withStyles(styles)(ComponentDraggable);
