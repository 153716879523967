import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import PublishingStatus from '../../../contexts/content/PublishingStatus';
import {
  CMS_SERVER_TIMESTAMP,
  FIELD_IS_PUBLISHED,
  FIELD_SCHEDULE_DATE,
} from '../../../../constants/article/articleFields';

const ArticlePublishingStatus = ({ scheduleDateTime, serverTimestamp, isPublished }) => (
  <PublishingStatus
    scheduled={parseInt(scheduleDateTime, 10)}
    isPublished={isPublished}
    serverTimestamp={serverTimestamp}
  />
);

ArticlePublishingStatus.propTypes = {
  scheduleDateTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  serverTimestamp: PropTypes.number,
  isPublished: PropTypes.bool,
};

ArticlePublishingStatus.defaultProps = {
  scheduleDateTime: null,
  serverTimestamp: null,
  isPublished: false,
};

export default connect(
  ({
    dataState: {
      [FIELD_SCHEDULE_DATE]: scheduleDateTime,
      [FIELD_IS_PUBLISHED]: isPublished,
      [CMS_SERVER_TIMESTAMP]: serverTimestamp,
    },
  }) => ({ scheduleDateTime, isPublished, serverTimestamp }),
  {},
)(ArticlePublishingStatus);

