import { ajax } from 'rxjs/ajax';
import { FIELD_PRICE_COMPARISON_PROVIDER } from '../../constants/product/productFields';
import ProductEntity from '../../entities/ProductEntity';

export const saveProductRequest = (localState, serverState, publicationConfig) => {
  if (!localState[FIELD_PRICE_COMPARISON_PROVIDER] &&
    publicationConfig.priceComparisonProviders &&
    publicationConfig.priceComparisonProviders.length > 0) {
    localState[FIELD_PRICE_COMPARISON_PROVIDER] = publicationConfig.priceComparisonProviders[0];
  }
  const product = new ProductEntity();
  const payload = product.getPayloadFromData(localState, serverState);
  const payloadId = product.getPayloadId(payload);
  if (payloadId) {
    return ajax.patch(
      `/api/product/${payloadId}`,
      payload,
      { 'Content-Type': 'application/json' },
    );
  }
  return ajax.post('/api/product', payload, { 'Content-Type': 'application/json' });
};
