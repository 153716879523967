import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import AdMenuControlBar from '../components/ad/AdMenuControlBar';
import PremiumAdSidebar from '../components/ad/PremiumAdSidebar';
import AdReplacementDialog from '../components/dialog/AdReplacementDialog';

import {
  saveAdReplacement,
  saveEditorsLetter,
  fetchEditorsLetter,
  fetchAdReplacement,
} from '../actions/ad';
import PremiumAdTable from '../components/ad/PremiumAdTable';
import { PREMIUM_AD_REPLACEMENT, PREMIUM_EDITORS_LETTER } from '../constants/premium';
import Button from '../components/ui/buttons/Button';
import AccessToggle from '../access/AccessToggle';
import { CREATE_PREMIUM_AD } from '../constants/permission';

export const EditorLettersBreadcrumb = [
  {
    title: 'Editor\'s Letter',
    path: '/premium/editors-letter',
  },
];

export const AdsReplacementsBreadcrumb = [
  {
    title: 'Ad Replacements',
    path: '/premium/ad-replacements',
  },
];

const PremiumAd = ({
  saveEditorsLetter: saveLetter,
  saveAdReplacement: saveReplacement,
  fetchEditorsLetter: onFetchLetters,
  fetchAdReplacement: onFetchAdReplacement,
  editorsLetter,
  adReplacements,
  pathname,
}) => {
  const [openAddNew, setAddNew] = useState(false);
  const [bundle, setBundle] = useState(PREMIUM_EDITORS_LETTER);
  const matched = pathname.match(/\d+$/);

  const selectedId = matched && matched[0];
  let items = editorsLetter;
  let onSave = saveLetter;
  if (pathname.indexOf(AdsReplacementsBreadcrumb[0].path) > -1) {
    if (bundle !== PREMIUM_AD_REPLACEMENT) {
      setBundle(PREMIUM_AD_REPLACEMENT);
    }
    items = adReplacements;
    onSave = saveReplacement;
  } else if (bundle !== PREMIUM_EDITORS_LETTER) {
    setBundle(PREMIUM_EDITORS_LETTER);
  }

  useEffect(() => {
    if (bundle === PREMIUM_EDITORS_LETTER) {
      onFetchLetters();
    } else {
      onFetchAdReplacement();
    }
  }, [bundle]);

  return (
    <Page
      toolbarContent={
        <PageToolbar
          breadcrumb={[
            {
              title: 'Premium',
              slug: 'premium',
            },
            bundle === PREMIUM_AD_REPLACEMENT
              ? AdsReplacementsBreadcrumb[0]
              : EditorLettersBreadcrumb[0],
          ]}
        >
          <Grid container justify={'center'} alignItems={'center'}>
            <AccessToggle restrictedTo={CREATE_PREMIUM_AD}>
              <AdMenuControlBar />
            </AccessToggle>
          </Grid>
          <PageToolbarButtons>
            {bundle === PREMIUM_AD_REPLACEMENT && <Button
              variant={'contained'}
              onClick={() => setAddNew(true)}
              startIcon={<AddIcon />}
            >Add new</Button>}
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection>
          <PremiumAdTable selectedId={selectedId} bundle={bundle} items={items} />
        </PageSection>
      </PageContent>
      <PremiumAdSidebar
        bundle={bundle}
        selected={items.find(({ data: { id } }) => id === selectedId)}
      />
      {openAddNew && <AdReplacementDialog
        open={openAddNew}
        onSave={(state) => {
          onSave({ ...state, addNew: true });
          setAddNew(false);
        }}
        handleClose={() => setAddNew(false)}
      />}
    </Page>
  );
};

PremiumAd.propTypes = {
  saveEditorsLetter: PropTypes.func.isRequired,
  saveAdReplacement: PropTypes.func.isRequired,
  fetchEditorsLetter: PropTypes.func.isRequired,
  fetchAdReplacement: PropTypes.func.isRequired,
  editorsLetter: PropTypes.array.isRequired,
  adReplacements: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default connect(
  ({
    ad: { editorsLetter, adReplacements },
    router: { location: { pathname } },
  }) => ({ editorsLetter, pathname, adReplacements }),
  { saveEditorsLetter, fetchEditorsLetter, fetchAdReplacement, saveAdReplacement },
)(PremiumAd);
