import { ofType } from 'redux-observable';
import { switchMap, withLatestFrom, map, filter } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  FETCH_SCORE_BOARD_COMPETITION,
  FETCH_SCORE_BOARD_MATCH,
  SCORE_BOARD_COMPETITION_REJECTED,
  SCORE_BOARD_COMPETITION_SUCCESS, SCORE_BOARD_MATCH_REJECTED, SCORE_BOARD_MATCH_SUCCESS,
} from '../../constants/actionTypes';
import apiCatchError from '../helper/notification';

export const fetchCompetition = (action$, state$) => action$.pipe(
  ofType(FETCH_SCORE_BOARD_COMPETITION),
  withLatestFrom(state$, (a, state) => state),
  switchMap(({
    frame: { selectedPublication: { publicationConfig: { optaApiToken } } },
  }) => ajax.getJSON(`/api/opta/tournament-calendar?optaApiToken=${optaApiToken}`).pipe(
    map(({ competition }) => ({
      type: SCORE_BOARD_COMPETITION_SUCCESS,
      value: competition,
    })),
    apiCatchError(SCORE_BOARD_COMPETITION_REJECTED),
  )),
);

export const fetchMatches = (action$, state$) => action$.pipe(
  ofType(FETCH_SCORE_BOARD_MATCH),
  withLatestFrom(state$),
  switchMap(([{ value: seasonId }, {
    frame: { selectedPublication: { publicationConfig: { optaApiToken } } },
  }]) => ajax.getJSON(`/api/opta/matches?optaApiToken=${optaApiToken}&seasonId=${seasonId}`).pipe(
    filter(response => response && response.match),
    map(({ match }) => ({
      type: SCORE_BOARD_MATCH_SUCCESS,
      value: match,
    })),
    apiCatchError(SCORE_BOARD_MATCH_REJECTED),
  )),
);
