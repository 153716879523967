import React from "react";
import Grid from "@material-ui/core/Grid";

import {
  FIELD_CANONICAL_URL,
  FIELD_HREFLANG,
  FIELD_HREFLANG_URL,
  FIELD_SEO_DESCRIPTION,
  SELF_FIELD_HREFLANG,
} from "../../../constants/article/articleFields";
import { MAX_LENGTH_SEO_DESCRIPTION } from "../../../constants/article/article";

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from "../../common/context/ContextPanel";

import FieldLockTextfield from "../../fieldLock/form/FieldLockTextfield";

const SEOSettings = () => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>SEO Settings</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails variant={"full"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FieldLockTextfield
            field={FIELD_SEO_DESCRIPTION}
            label={"SEO description"}
            maxLength={MAX_LENGTH_SEO_DESCRIPTION}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLockTextfield
            field={FIELD_CANONICAL_URL}
            label={"Canonical URL"}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLockTextfield
            field={SELF_FIELD_HREFLANG}
            label={"Self hreflang Region"}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLockTextfield
            field={FIELD_HREFLANG_URL}
            label={"Alternate hreflang URL"}
          />
        </Grid>
        <Grid item xs={12}>
          <FieldLockTextfield
            field={FIELD_HREFLANG}
            label={"Alternate hreflang Region"}
          />
        </Grid>
      </Grid>
    </ContextPanelDetails>
  </ContextPanel>
);

export default SEOSettings;
