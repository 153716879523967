import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { blurHeadlineField } from '../../../../actions/article';
import { FIELD_HEADLINE } from '../../../../constants/article/articleFields';

import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfield';
import { RECOMMENDED_LENGTH_HEADLINE } from '../../../../constants/article/article';

const Headline = ({
  headline,
  blurHeadlineField: onBlur,
}) => {
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(headline.length <= RECOMMENDED_LENGTH_HEADLINE);
  }, [headline]);
  const classes = makeStyles(theme => ({
    root: {
      '& .MuiFormHelperText-root': {
        color: theme.palette.secondary.main,
      },
    },
  }))();
  return (
    <>
      <FieldLockTextfield
        required
        field={FIELD_HEADLINE}
        label="Headline"
        placeholder="Type your headline here."
        helperText={!valid ? `Recommended headline length is ${RECOMMENDED_LENGTH_HEADLINE} characters or fewer` : ''}
        onBlur={() => onBlur()}
        className={classes.root}
      />
    </>
  );
};

Headline.propTypes = {
  blurHeadlineField: PropTypes.func.isRequired,
  headline: PropTypes.string,
};

Headline.defaultProps = {
  headline: '',
};

export default connect(
  ({
    dataState: {
      [FIELD_HEADLINE]: headline,
    },
  }) => ({ headline }),
  {
    blurHeadlineField,
  },
)(Headline);
