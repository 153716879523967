import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import exifr from 'exifr';

import TextField from '../ui/TextField';

import {
  VALID_IMAGE_EXTENSION,
} from '../../constants/media/media';

import { onEditMediaRequest, uploadMedia, disposePreviousMedia } from '../../actions/dialog';

import ImageCropper from './ImageCropper';
import TextButton from '../ui/buttons/TextButton';
import ToggleSwitch from '../ui/ToggleSwitch';
import GenericWorkflowSelector from './common/WorkflowSelector';
import { assignIptc } from '../../epics/utility/assignIptc';
import { fetchWorkflowGeneric } from '../../actions/vocab';
import { CMS_DATE_FORMAT } from '../../constants/common';
import { optimiseImage } from '../helper/media';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    height: '100%',
  },
  previewRoot: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    '& > img': {
      objectFit: 'contain',
      verticalAlign: 'bottom',
    },
  },
  buttonCta: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
  image: {
    maxWidth: '100%',
    margin: '0 auto',
  },
  offscreen: {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
    visibility: 'hidden',
  },
  replaceImage: {
    margin: theme.spacing(1, 0),
  },
  helpText: {
    fontSize: 11,
    fontStyle: 'italic',
    marginTop: theme.spacing(0.5),
  },
  notice: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
});

const getResizedImage = (src) => {
  if (!src) {
    return '';
  }
  return optimiseImage(src, { width: 600 });
};

const ImagePreview = (props) => {
  const {
    classes, url, title, image, croppedUrl, useCrop, onComplete, onReset,
    defaultCropRatio, base64Image,
  } = props;
  if (useCrop) {
    return (
      <ImageCropper
        src={croppedUrl || base64Image || url || (image instanceof File ? URL.createObjectURL(image) : '')}
        onComplete={onComplete}
        onReset={onReset}
        defaultCropRatio={defaultCropRatio}
      />
    );
  }
  return (
    <img
      className={classes.image}
      src={base64Image || getResizedImage(url) || (image instanceof File ? URL.createObjectURL(image) : '')}
      alt={title || (image ? image.name : '')}
    />
  );
};

ImagePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  croppedUrl: PropTypes.string.isRequired,
  defaultCropRatio: PropTypes.string.isRequired,
  useCrop: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  base64Image: PropTypes.string,
  image: PropTypes.object,
};

ImagePreview.defaultProps = {
  image: null,
  base64Image: '',
};

const replaceTxt = 'Replace';
const saveTxt = 'Save';

const setTempImage = (img, states, setUploadedImage, setBase64Image, setStates) => {
  // handle chp binary
  if (img?.binary) {
    setUploadedImage(img.binary);
    setBase64Image(`data:${img.binary.mimetype};base64,${Buffer.from(img.binary.body).toString('base64')}`);
    setStates({
      ...states,
      title: img?.data?.headline || '',
      alt: img?.data?.headline || '',
      copyright: img?.data?.provider || '',
      description: img?.data?.caption || '',
      extra: {
        chp_object_id: img?.data?.objectId || '',
        chp_gid: img?.data?.gid || '',
        chp_xurn: img?.data?.xurn || '',
      },
    });
  } else {
    if (img instanceof File) {
      setUploadedImage(img);
      setStates({
        ...states,
        title: img.name || '',
      });
      exifr.parse(img, { iptc: true })
        .then((metadata) => {
          if (metadata) {
            setStates(assignIptc(states, metadata, img.name));
          }
        })
        // eslint-disable-next-line no-console
        .catch(ex => console.error(ex));
    }
  }
};

// @todo refactor and simplify
const ImageForm = (props) => {
  const {
    classes, onSaveImage, mediaId, imageOnEdit, imageOnCrop, workflowGeneric,
    uploadMedia: onUploadMedia, disposePreviousMedia: onDispose, isUploading,
    file, canEdit,
    fetchWorkflowGeneric: fetchWorkflow, onUploaded,
    selectedPublication, onEditMediaRequest: editRequest,
  } = props;

  if (!Array.isArray(workflowGeneric)) {
    fetchWorkflow();
  }

  const published = workflowGeneric?.find(({ key }) => key === 'PUBLISHED');

  const defaultState = {
    created: 0,
    url: '',
    title: '',
    caption: '',
    alt: '',
    copyright: '',
    description: '',
    notes: '',
    agency_id: '',
    cmsUser: {
      name: '',
    },
    workflow: published ? published.id : null,
  };
  const [uploadedImage, setUploadedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const [states, setStates] = useState(defaultState);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedUrl, setCroppedImageUrl] = useState('');
  const [disableEdit, setDisableEdit] = useState(true);
  const [submitButtonText, setSubmitButtonText] = useState('upload');
  const [useCrop, setUseCrop] = useState(false);
  const [hasUpload, setHasUpload] = useState(null);
  const replacementInput = useRef();

  useEffect(() => {
    if (!mediaId || canEdit) {
      setDisableEdit(false);
    }
  }, [mediaId, canEdit]);

  useEffect(() => {
    if (mediaId) {
      editRequest({
        id: mediaId,
        bundle: 'remote_image',
      });
      setSubmitButtonText(saveTxt);
    }
  }, [mediaId, editRequest]);

  useEffect(() => {
    if (file) {
      setTempImage(file, states, setUploadedImage, setBase64Image, setStates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (mediaId && imageOnEdit && imageOnEdit.length > 0) {
      try {
        const image = imageOnEdit.find(({ data: { mid } }) => mid === mediaId);
        setStates(defaultState);
        if (image?.data) {
          setStates(image.data);
          image.data.workflow = parseInt(image.data.workflow.id, 10);
        }
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId, imageOnEdit]);

  useEffect(() => {
    if (mediaId && imageOnCrop?.url) {
      setCroppedImageUrl(imageOnCrop.url);
    }
  }, [mediaId, imageOnCrop]);

  useEffect(() => () => onDispose(mediaId), [mediaId, onDispose]);
  useEffect(() => {
    if (onUploaded && !isUploading && hasUpload) {
      onUploaded();
    }
    if (isUploading) {
      setHasUpload(true);
    }
  }, [onUploaded, hasUpload, isUploading]);

  const readOnlyFields = states.extra && Object.keys(states.extra);
  const showUploadInput = !mediaId && !uploadedImage;

  return (
    <div className={classes.root}>
      <div className={classes.previewRoot}>
        {showUploadInput && <input
          type={'file'}
          accept={VALID_IMAGE_EXTENSION.join(',')}
          name={'image'}
          id={'image'}
          onChange={(e) => {
            const img = e.target.files[0];
            setTempImage(img, states, setUploadedImage, setBase64Image, setStates);
          }}
        />}
        <ImagePreview
          classes={classes}
          useCrop={useCrop}
          onComplete={(image) => {
            if (mediaId) {
              onUploadMedia([image], true);
            } else {
              setCroppedImage(image);
            }
          }}
          onReset={() => {
            if (mediaId) {
              setCroppedImageUrl('');
            } else {
              setCroppedImage(null);
            }
          }}
          image={croppedImage || uploadedImage}
          base64Image={base64Image}
          croppedUrl={croppedUrl}
          defaultCropRatio={selectedPublication.publicationConfig?.defaultCropRatio || '4:3'}
          {...states}
        />
      </div>
      {isUploading &&
        <Typography className={classes.notice} color={'primary'} variant={'body1'}>Uploading...</Typography>
      }
      {!isUploading && <>
        <Grid
          container
          className={classes.replaceImage}
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          {!disableEdit && <Grid item>
            <ToggleSwitch
              label={'Crop Image'}
              value={useCrop}
              onChange={e => setUseCrop(e.target.checked)}
            />
          </Grid>}
          <Grid item>
            <input
              type={'file'}
              className={classes.offscreen}
              accept={VALID_IMAGE_EXTENSION.join(',')}
              ref={replacementInput}
              name={'imageReplacement'}
              id={'imageReplacement'}
              onChange={e => {
                if (e.target.files.length > 0) {
                  onUploadMedia(e.target.files);
                }
              }}
            />
          </Grid>
        </Grid>
        <TextField
          id={'title'}
          label={'Title'}
          required
          disabled={disableEdit}
          error={!states.title}
          value={states.title || ''}
          className={classes.textField}
          onChange={e => setStates({ ...states, title: e.target.value })}
          margin={'normal'}
        />
        <TextField
          id={'caption'}
          label={'Caption'}
          disabled={disableEdit}
          value={states.caption || ''}
          className={classes.textField}
          onChange={e => setStates({ ...states, caption: e.target.value })}
          margin={'normal'}
        />
        <TextField
          id={'alt-text'}
          label={'Alt text'}
          disabled={disableEdit}
          value={states.alt || ''}
          className={classes.textField}
          onChange={e => setStates({ ...states, alt: e.target.value })}
          margin={'normal'}
        />
        <TextField
          id={'copyright'}
          label={'Copyright'}
          error={!states.copyright}
          disabled={disableEdit}
          required
          value={states.copyright || ''}
          className={classes.textField}
          onChange={e => setStates({ ...states, copyright: e.target.value })}
          margin={'normal'}
        />
        <TextField
          id={'description'}
          label={'Description'}
          rows={3}
          multiline
          disabled={disableEdit}
          required
          error={!states.description}
          className={classes.textField}
          value={states.description || ''}
          onChange={e => setStates({ ...states, description: e.target.value })}
          margin={'normal'}
        />
        <TextField
          id={'image-notes'}
          label={'Notes'}
          multiline
          disabled={disableEdit}
          rowsMax={'4'}
          className={classes.textField}
          value={states.notes || ''}
          onChange={e => setStates({ ...states, notes: e.target.value })}
          margin={'normal'}
        />
        {!disableEdit && <GenericWorkflowSelector
          onChange={workflow => setStates({ ...states, workflow })}
          selectedValue={states.workflow}
          margin={'normal'}
        />}
        {states.cmsUser.name && <TextField
          label={'Uploaded by'}
          className={classes.textField}
          value={states.cmsUser.name}
          margin={'normal'}
          disabled
        />}
        {Number(states.created) > 0 && <TextField
          label={'Uploaded time'}
          className={classes.textField}
          value={moment.unix(Number(states.created)).format(CMS_DATE_FORMAT)}
          margin={'normal'}
          disabled
        />}
        {readOnlyFields && readOnlyFields.length > 0 && readOnlyFields
          .filter(key => typeof states.extra[key] === 'string' || typeof states.extra[key] === 'number')
          .map(key => (<TextField
            disabled
            key={key}
            id={key}
            label={key.replace(/_/g, ' ')}
            className={classes.textField}
            value={states.extra[key] || ''}
            margin={'normal'}
          />))
        }
      </>}
      {!disableEdit && !isUploading && <div className={classes.buttonCta}>
        <TextButton
          disabled={!(states.copyright && states.title && states.description)}
          size={'large'}
          onClick={() => {
            const proceed = () => {
              const imageObject = {
                ...states,
                image: croppedImage || uploadedImage,
                url: imageOnCrop?.url || states.url,
              };
              if (imageOnCrop?.imageSize ) {
                imageObject.extra = {
                  ...states.extra,
                  imageSize: imageOnCrop.imageSize,
                };
              }
              onSaveImage([imageObject, !!mediaId]);
              setStates(defaultState);
            };
            if (submitButtonText === replaceTxt) {
              if (window.confirm('This will replace all' +
              ' instances of this' +
              ' image - are you sure' +
              ' you wish to proceed?')) {
                proceed();
              }
            } else if (submitButtonText === saveTxt) {
              if (window.confirm('These changes will' +
                ' affect' +
                ' all' +
                ' instances of this image globally: are you sure you wish to save changes?')) {
                proceed();
              }
            } else {
              proceed();
            }
          }}
        >{submitButtonText}</TextButton>
      </div>}
    </div>
  );
};

ImageForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSaveImage: PropTypes.func.isRequired,
  onEditMediaRequest: PropTypes.func.isRequired,
  uploadMedia: PropTypes.func.isRequired,
  disposePreviousMedia: PropTypes.func.isRequired,
  fetchWorkflowGeneric: PropTypes.func.isRequired,
  imageOnEdit: PropTypes.array.isRequired,
  imageOnCrop: PropTypes.array.isRequired,
  workflowGeneric: PropTypes.array.isRequired,
  isUploading: PropTypes.bool.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  mediaId: PropTypes.number,
  file: PropTypes.object,
  onUploaded: PropTypes.func,
};

ImageForm.defaultProps = {
  canEdit: false,
  mediaId: null,
  file: null,
  onUploaded: null,
};

export default withStyles(style)(connect(
  ({
    media: { imageOnEdit, imageOnCrop, isUploading },
    vocab: { workflowGeneric },
    frame: { selectedPublication },
  }) =>
    ({ imageOnEdit, imageOnCrop, workflowGeneric, isUploading, selectedPublication }),
  { onEditMediaRequest, uploadMedia, disposePreviousMedia, fetchWorkflowGeneric },
)(ImageForm));
