import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';
import TextField from '../../../ui/TextField';
import { changeHandler } from './helper';

const SRC = 'src';

const IFrameSettings = (props) => {
  const {
    component,
    componentPropOnChange,
  } = props;
  const { id } = component;
  return (
    <ContextPanel>
      <ContextPanelHeading>iFrame settings</ContextPanelHeading>
      <ContextPanelDetails variant={'full'}>
        <TextField
          margin={'dense'}
          defaultValue={component[SRC] || ''}
          onChange={changeHandler(data => componentPropOnChange(id, SRC, data))}
          label={'src'}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

IFrameSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentPropOnChange: componentSetProp },
)(IFrameSettings);
