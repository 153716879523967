import { FIELD_PUBLISHED } from '../common/commonFields';

export const FIELD_NAME = 'name';
export const FIELD_START_DATE = 'startDate';
export const FIELD_END_DATE = 'endDate';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_HERO_IMAGE = 'heroImage';
export const FIELD_THUMBNAIL = 'thumbnail';
export const FIELD_PRICE = 'price';
export const FIELD_PRICE_SUB = 'priceSub';
export const FIELD_TICKET_URL = 'ticketUrl';
export const FIELD_TICKET_URL_SUB = 'ticketUrlSub';
export const FIELD_LOCATION = 'location';

// Speakers
export const FIELD_SPEAKERS = 'speakers';
export const FIELD_SPEAKERS_ORDER = 'speakersOrder';
export const SPEAKER_NAME = 'name';
export const SPEAKER_ROLE = 'role';
export const SPEAKER_IMAGE = 'image';
export const FIELD_HOSTS = 'hosts';
export const FIELD_HOSTS_ORDER = 'hostsOrder';

export const EVENT_PAYLOAD_FIELDS = [
  FIELD_NAME,
  FIELD_START_DATE,
  FIELD_END_DATE,
  FIELD_PUBLISHED,
];

export const EVENT_EXTRA_FIELDS = [
  FIELD_DESCRIPTION,
  FIELD_HERO_IMAGE,
  FIELD_THUMBNAIL,
  FIELD_PRICE,
  FIELD_PRICE_SUB,
  FIELD_TICKET_URL,
  FIELD_TICKET_URL_SUB,
  FIELD_SPEAKERS,
  FIELD_HOSTS,
  FIELD_LOCATION,
];

export const EVENT_REQUIRED_FIELDS = [
  FIELD_NAME,
  FIELD_DESCRIPTION,
  FIELD_HERO_IMAGE,
  FIELD_THUMBNAIL,
  FIELD_PRICE,
  FIELD_TICKET_URL,
];

export const EVENT_FIELD_MAPPING = [
  [FIELD_NAME, 'name', 'name'],
  [FIELD_START_DATE, 'field_start_date', 'Start Date'],
  [FIELD_END_DATE, 'field_end_date', 'End Date'],
  [FIELD_PRICE, 'field_price', 'Price'],
  [FIELD_DESCRIPTION, FIELD_DESCRIPTION, 'Description'],
  [FIELD_HERO_IMAGE, FIELD_HERO_IMAGE, 'Hero Image'],
  [FIELD_THUMBNAIL, FIELD_THUMBNAIL, 'Thumbnail'],
  [FIELD_PRICE, FIELD_PRICE, 'Price'],
  [FIELD_PRICE_SUB, FIELD_PRICE_SUB, 'Price (Subscriber)'],
  [FIELD_TICKET_URL, FIELD_TICKET_URL, 'Ticket URL'],
  [FIELD_TICKET_URL_SUB, FIELD_TICKET_URL_SUB, 'Ticket URL (Subscriber)'],
  [FIELD_SPEAKERS, FIELD_SPEAKERS, 'Speaker(s)'],
  [FIELD_HOSTS, FIELD_HOSTS, 'Host(s)'],
  [FIELD_LOCATION, FIELD_LOCATION, 'Location'],
  [FIELD_PUBLISHED, 'field_published', 'Published Date'],
];
