import { from } from 'rxjs';
import { ofType } from 'redux-observable';
import { withLatestFrom, switchMap, map, filter, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { capitalize } from '@material-ui/core';

import {
  CONTACT_US_PAGE_LOADED, CONTACT_US_SET_PROPERTY, FETCH_CONTACT_US_SUCCESS,
  SAVE_CONTACT_US, SAVE_CONTACT_US_REJECTED,
  SAVE_CONTACT_US_SUCCESS,
  SET_STATIC_PAGE_TYPES,
} from '../../constants/staticPage/contactUs';
import apiCatchError, { showSuccessNotification } from '../helper/notification';
import {
  ADS_TXT_PAGE_LOADED, ADS_TXT_SET_CURRENT_ID, ADS_TXT_SET_PROPERTY,
  FETCH_ADS_TXT_PAGE_SUCCESS,
  SAVE_ADS_TXT,
} from '../../constants/staticPage/adsTxt';
import { MARKUP } from '../../constants/builder/builder';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const findContactUsPage = ({ name }) => /contact us/i.test(name);
const findAdsTxtPage = ({ name }) => /ads txt/i.test(name);
const pageTypeValidator = (type) => {
  switch (type) {
    case SAVE_CONTACT_US:
      return findContactUsPage;
    case SAVE_ADS_TXT:
      return findAdsTxtPage;
    default:
      console.warn('pageTypeValidator no matched action');
      return () => false;
  }
};
const setAdsTxtJSONFormat = content => [{ type: MARKUP, data: { [MARKUP]: content } }];
const getAdsTxtFROMJSON = (data) => {
  try {
    return data[0].data[MARKUP];
  } catch (x) {
    return '';
  }
};

export const saveStaticPage = (action$, state$) => action$.pipe(
  ofType(SAVE_CONTACT_US, SAVE_ADS_TXT),
  withLatestFrom(state$),
  filter(([{ type }, { staticPage: { pageTypes } }]) =>
    pageTypes.some(pageTypeValidator(type))),
  switchMap(([
    { type },
    { contactUs, ad, staticPage: { pageTypes }, frame: { selectedPublication } },
  ]) => {
    let pageId;
    let content = '';

    const { domain, id: publicationId } = selectedPublication;
    const pageType = pageTypes.find(pageTypeValidator(type));
    switch (type) {
      case SAVE_CONTACT_US: {
        const { pageId: id, ...rest } = contactUs;
        pageId = id;
        content = JSON.stringify(rest);
        break;
      }
      // ads txt
      default: {
        pageId = ad.adsTxtId;
        content = JSON.stringify(setAdsTxtJSONFormat(ad.adsTxtContent));
      }
    }
    const payload = {
      name: [{
        value: `${capitalize(pageType.name)} - ${domain}`,
      }],
      field_page_json: [{
        value: content,
      }],
      field_page_type: [{
        target_id: pageType.id,
      }],
      bundle: [{
        target_id: 'basic',
      }],
    };
    if (pageId) {
      return ajax.patch(`/api/static-page/${pageId}`, payload, { 'Content-Type': 'application/json' }).pipe(
        map(({ response }) => ({ type: SAVE_CONTACT_US_SUCCESS, value: response })),
        apiCatchError(SAVE_CONTACT_US_REJECTED),
      );
    }
    payload.field_publications = [{
      target_id: publicationId,
    }];
    return ajax.post('/api/static-page', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => from([
        { type: SAVE_CONTACT_US_SUCCESS, value: response },
        { type: ADS_TXT_SET_CURRENT_ID, value: response.id[0].value },
      ])),
      apiCatchError(SAVE_CONTACT_US_REJECTED),
    );
  }),
);

export const showNotificationAfterSave = action$ => action$.pipe(
  ofType(SAVE_CONTACT_US_SUCCESS),
  mergeMap(showSuccessNotification('Saved successfully')),
);

export const fetchPageTypes = (action$, state$) => action$.pipe(
  ofType(CONTACT_US_PAGE_LOADED, ADS_TXT_PAGE_LOADED),
  withLatestFrom(state$, (x, state) => state),
  filter(({ staticPage: { pageTypes } }) => pageTypes?.length === 0),
  switchMap(({ frame: { selectedPublication } }) => {
    const { domain } = selectedPublication;
    return ajax.getJSON(`/api/static-page/${domain}/page_type`).pipe(
      filter(body => Array.isArray(body?.items)),
      map(({ items }) => ({ type: SET_STATIC_PAGE_TYPES, value: items })),
      apiCatchError(),
    );
  }),
);

export const fetchContactUsPage = (action$, state$) => action$.pipe(
  ofType(
    CONTACT_US_PAGE_LOADED,
    SET_STATIC_PAGE_TYPES,
    PUBLICATION_SELECTED,
  ),
  withLatestFrom(state$, (x, state) => state),
  filter(({ staticPage: { pageTypes } }) =>
    pageTypes?.length > 0 && pageTypes.find(findContactUsPage),
  ),
  switchMap(({ frame: { selectedPublication }, staticPage: { pageTypes } }) => {
    const { domain } = selectedPublication;
    const contactUs = pageTypes.find(findContactUsPage);
    return ajax.getJSON(`/api/static-page/${domain}/basic?page_type=${contactUs.id}`).pipe(
      filter(body => Array.isArray(body?.items) && body.items.length > 0),
      map(({ items }) => ({ type: FETCH_CONTACT_US_SUCCESS, value: items[0] })),
      apiCatchError(),
    );
  }),
);

export const fetchAdsTxtPage = (action$, state$) => action$.pipe(
  ofType(
    ADS_TXT_PAGE_LOADED,
    SET_STATIC_PAGE_TYPES,
    PUBLICATION_SELECTED,
  ),
  withLatestFrom(state$, (x, state) => state),
  filter(({ staticPage: { pageTypes } }) =>
    pageTypes?.length > 0 && pageTypes.find(findAdsTxtPage),
  ),
  switchMap(({ frame: { selectedPublication }, staticPage: { pageTypes } }) => {
    const { domain } = selectedPublication;
    const adsTxt = pageTypes.find(findAdsTxtPage);
    return ajax.getJSON(`/api/static-page/${domain}/basic?page_type=${adsTxt.id}`).pipe(
      filter(body => Array.isArray(body?.items) && body.items.length > 0),
      mergeMap(({ items }) => from([
        { type: ADS_TXT_SET_CURRENT_ID, value: items[0].id },
        { type: ADS_TXT_SET_PROPERTY, value: getAdsTxtFROMJSON(items[0].data) },
      ])),
      apiCatchError(),
    );
  }),
);

export const setContactUsState = action$ => action$.pipe(
  ofType(FETCH_CONTACT_US_SUCCESS),
  mergeMap(({ value }) => {
    const { id, data } = value;
    const actions = [{
      type: CONTACT_US_SET_PROPERTY,
      value: ['pageId', id],
    }];
    if (data) {
      Object.entries(data).forEach(([key, val]) => {
        actions.push({
          type: CONTACT_US_SET_PROPERTY,
          value: [key, val],
        });
      });
    }
    return from(actions);
  }),
);
