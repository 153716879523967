import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';


import DropZoneComponent from '../../../ui/dropzones/DropZoneComponent';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0 none',
    height: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '& svg': {
      display: 'none',
    },
    '&.drop-enabled': {
      border: `1px dashed ${theme.palette.border.secondary.dark}`,
    },
    '&.drop-over': {
      '& svg': {
        display: 'block',
      },
    },
  },
});

const processComponentData = delta => component => ({ component, delta });

const ComponentDropZone = (props) => {
  const { classes, delta, handleDropComponent } = props;
  return (
    <DropZoneComponent
      dataProcessor={processComponentData(delta)}
      dropHandler={handleDropComponent}
      className={classes.root}
    >
      <PlayForWorkIcon />
    </DropZoneComponent>
  );
};

ComponentDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDropComponent: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default withStyles(styles)(ComponentDropZone);

