import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import {
  Table, TableHead, TableCell, TableHeadCell, TableRow, TableBody, TablePagination,
} from '../ui/table/Table';
import ThumbnailImage from '../table/content/ThumbnailImage';

import Dialog from './common/Dialog';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';

import {
  fetchProductList,
} from '../../actions/product';

const style = theme => ({
  table: {
    margin: theme.spacing(0, -3),
  },
});

const START_PAGE = 0;
const SIZE_PER_PAGE = 10;

const ProductSelectorDialog = (props) => {
  const {
    classes,
    productOnSearch: onSearch,
    products,
    total,
    onSelectProduct,
    ...rest
  } = props;
  const [page, setPage] = useState(START_PAGE);
  const [sizePerPage, setSizePerPage] = useState(SIZE_PER_PAGE);
  const [q, setQuery] = useState('');

  useEffect(() => {
    onSearch({
      q,
      limit: sizePerPage,
      offset: sizePerPage * page,
    });
  }, [page, sizePerPage, q]);

  return (
    <Dialog title={'Choose a product'} maxWidth={'md'} {...rest}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={6}>
              <TextSearchFilter
                placeholder={'Search products'}
                defaultValue={q}
                onSubmit={(textQuery) => {
                  setQuery(textQuery);
                  setPage(START_PAGE);
                  setSizePerPage(SIZE_PER_PAGE);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow hasBorder>
                  {['Image', 'Title', 'Vendor', 'Price', ''].map(col =>
                    <TableHeadCell key={col}>{col}</TableHeadCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {products.length === 0 && <TableRow>
                  <TableCell colSpan={5}>No product is found</TableCell>
                </TableRow>}
                {products.length > 0 && products.map(({ data }) => {
                  const { id, title, price, vendorName, vendorLink, image } = data;
                  return (
                    <TableRow key={id} hasActions>
                      <TableCell>
                        {image.data && <ThumbnailImage image={image} size={'small'} />}
                      </TableCell>
                      <TableCell>
                        {title}
                      </TableCell>
                      <TableCell>
                        <a href={vendorLink} target="_blank" rel="noreferrer">{vendorName}</a>
                      </TableCell>
                      <TableCell>
                        {price}
                      </TableCell>
                      <TableCell align={'right'} action>
                        <Button onClick={() => onSelectProduct(data)} variant={'contained'} color={'primary'} size={'small'}>Select</Button>
                      </TableCell>
                    </TableRow>
                  );
                })
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component={'div'}
              count={total}
              rowsPerPage={sizePerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={(e, i) => setPage(i + 1)}
              onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

ProductSelectorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  productOnSearch: PropTypes.func.isRequired,
  onSelectProduct: PropTypes.func.isRequired,
  products: PropTypes.array,
  total: PropTypes.number,
};

ProductSelectorDialog.defaultProps = {
  products: [],
  total: 0,
};

export default withStyles(style)(connect(
  ({
    productList: { products, total },
  }) => ({ products, total }),
  { productOnSearch: fetchProductList },
)(ProductSelectorDialog));
