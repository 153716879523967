import { setDefaults } from '@udecode/slate-plugins';
import renderElementMoney from './renderElementMoney';
import { DEFAULTS_MONEY } from './defaults';

const MoneyPlugin = (options) => {
  const { money } = setDefaults(options, DEFAULTS_MONEY);
  return ({
    renderElement: renderElementMoney(options),
    voidTypes: [money.type],
  });
};

export default MoneyPlugin;
