import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_FACEBOOK } from './defaults';

const renderElementFacebook = (options) => {
  const { facebook } = setDefaults(options, DEFAULTS_FACEBOOK);
  return getRenderElement(facebook);
};

export default renderElementFacebook;
