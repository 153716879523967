import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '../../ui/builder/TextField';
import { setLiveblogProperty } from '../../../actions/liveblog';

const styles = {
  input: {
    fontSize: 22,
  },
};

const Title = (props) => {
  const { classes, setLiveblogProperty: setProp, name, className } = props;
  return (
    <TextField
      value={name}
      className={[classes.input, className].filter(str => str !== '').join(' ')}
      label={'Title'}
      placeholder={'Type your liveblog title here'}
      onChange={event => setProp(['name', event.target.value])}
    />
  );
};

Title.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  setLiveblogProperty: PropTypes.func.isRequired,
};

Title.defaultProps = {
  name: '',
  className: '',
};

export default withStyles(styles)(connect(
  ({ liveblog: { name } }) => ({ name }),
  { setLiveblogProperty },
)(Title));
