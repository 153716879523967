import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FIELD_SEO_HEADLINE } from '../../../constants/story/storyFields';
import { updateStoryField } from '../../../actions/story';

import TextField from '../../ui/builder/TextField';

const LABEL = 'Meta Title';
const PLACEHOLDER = 'Type the meta title here';

const MetaTitle = (props) => {
  const { value, updateStoryField: updateField } = props;
  return (
    <TextField
      label={LABEL}
      placeholder={PLACEHOLDER}
      value={value}
      onChange={event => updateField(FIELD_SEO_HEADLINE, event.target.value)}
    />
  );
};

MetaTitle.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  value: PropTypes.string,
};

MetaTitle.defaultProps = {
  value: '',
};

export default connect(
  ({ story: { [FIELD_SEO_HEADLINE]: value } }) => ({ value }),
  { updateStoryField },
)(MetaTitle);
