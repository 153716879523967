import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import LaunchIcon from '@material-ui/icons/Launch';
import GetAppIcon from '@material-ui/icons/GetApp';

import ToggleSwitch from '../ui/ToggleSwitch';

import { updateEditionDetail } from '../../actions/edition';

import { FIELD_TITLE } from '../../constants/edition/editionFields';

import Button from '../ui/buttons/Button';
import TextField from '../ui/builder/TextField';
import { PREVIEW_MODE_DESKTOP } from '../../constants/preview';
import DropdownFilter from '../ui/DropdownFilter';
import { CMS_DATE_FORMAT } from '../../constants/common';

const styles = theme => ({
  fill: {
    width: '100%',
  },
  fields: {
    marginBottom: theme.spacing(1),
  },
  previewImage: {
    height: 155,
    '& img': {
      height: '100%',
      width: 'auto',
    },
  },
  helper: {
    fontSize: '8px',
    margin: `-3px 0 0 ${theme.spacing(1)}px`,
  },
  changed: {
    display: 'block',
    textAlign: 'right',
    marginTop: theme.spacing(1),
  },
  download: {
    display: 'block',
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none',
    },
    '& .MuiButton-label': {
      lineHeight: '14px',
    },
  },
});

const HELP_TEXT = 'The internal key for the edition used in the URLs. ' +
  'It must be unique, and it should not be changed once published.';

const getPrefixEditionKey = domain => `${domain.split('.').reverse().join('.')}.issue.`;

const EditionDetailForm = (
  {
    classes, type, editionDetail, push: onRouteChange, updateEditionDetail: updateDetail,
    environment: { web }, onChangeSectionFilter, sectionFilter, workflowOptions,
    onChangeWorkflowFilter, workflowFilter, domain, webAuthentication,
  },
) => {
  let imageScreenshot = `${web.replace(/\/$/, '')}/edition/screenshot/${editionDetail.key}.jpg`;

  if (webAuthentication) {
    imageScreenshot = imageScreenshot.replace(/(https:\/\/)/, `$1${webAuthentication}@`);
  }
  const previewMode = PREVIEW_MODE_DESKTOP;
  const [availableSections, setAvailableSections] = useState([]);

  const defaultOpt = { id: '-1', name: 'All' };

  const workflowOpts = [defaultOpt, ...workflowOptions];
  const prefixKey = getPrefixEditionKey(domain);
  useEffect(() => {
    const sections = editionDetail.pages
      .filter(({ data: { sections: pageSections } }) => !!pageSections && pageSections.length > 0)
      .reduce((acc, { data: { sections: [section] } }) => {
        if (!acc.some(({ id }) => id === section.id)) {
          return [...acc, section];
        }
        return acc;
      }, [defaultOpt])
      .sort((a, b) => {
        const sectionA = a.name.toUpperCase();
        const sectionB = b.name.toUpperCase();
        return (sectionA < sectionB) ? -1 : (sectionA > sectionB) ? 1 : 0;
      });
    setAvailableSections(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editionDetail]);

  return (
    <Grid container wrap={'nowrap'} spacing={2}>
      {editionDetail.id && <Grid item>
        <div className={classes.previewImage}>
          <img alt={editionDetail.key} src={imageScreenshot} />
        </div>
      </Grid>}
      <Grid item className={classes.fill}>
        <Grid container spacing={2} className={classes.fields}>
          <Grid item xs={8}>
            <TextField
              label={'Title'}
              multiline={false}
              value={editionDetail.title ? editionDetail.title : ''}
              onChange={
                event => updateDetail({
                  prop: FIELD_TITLE,
                  value: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label={'Edition date'}
              type={'date'}
              InputLabelProps={{ shrink: true }}
              multiline={false}
              value={
                editionDetail.editionDate
                  ? moment.unix(editionDetail.editionDate).format('YYYY-MM-DD')
                  : ''
              }
              onChange={(event) => {
                const { value } = event.target;
                const date = new Date(value);
                return updateDetail({
                  prop: 'editionDate',
                  value: date.getTime() / 1000,
                });
              }}
            />
          </Grid>
        </Grid>
        <TextField
          label={'Key'}
          multiline={false}
          value={editionDetail?.key?.replace(prefixKey, '') || ''}
          InputProps={{
            startAdornment: <InputAdornment position="start">{prefixKey}</InputAdornment>,
          }}
          onChange={
            event => updateDetail({
              prop: 'key',
              value: prefixKey + event.target.value,
            })
          }
        />
        <FormHelperText className={classes.helper}>{HELP_TEXT}</FormHelperText>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={4}>
            <DropdownFilter
              onChange={onChangeSectionFilter}
              label={'Section'}
              id={'filterSection'}
              options={availableSections}
              value={sectionFilter}
            />
          </Grid>
          <Grid item xs={4}>
            <DropdownFilter
              onChange={onChangeWorkflowFilter}
              label={'Workflow'}
              id={'filterWorkflow'}
              options={workflowOpts}
              value={workflowFilter}
            />
          </Grid>
          <Grid item xs={4}>
            <ToggleSwitch
              label={'Subscribers only'}
              value={typeof editionDetail.paid !== 'undefined' ? editionDetail.paid : true}
              onChange={
                event => updateDetail({
                  prop: 'paid',
                  value: event.target.checked,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {editionDetail.id && <Grid item>
        <Grid container wrap={'nowrap'}>
          <Link
            download={`${editionDetail.key}.jpg`}
            className={classes.download}
            href={`/api/edition-screenshot/download?imageUrl=${imageScreenshot}`}
          ><Button variant={'outlined'} startIcon={<GetAppIcon />}>Download<br />front
            page</Button></Link>
          <Button
            variant={'contained'}
            onClick={() =>
              // eslint-disable-next-line max-len
              onRouteChange(`/edition/${type}/${editionDetail.id}/browse?mode=${previewMode}&containerId=${editionDetail.id}`)
            }
            startIcon={<LaunchIcon />}
          >Preview</Button>
        </Grid>
        <Typography variant={'caption'} className={classes.changed}>Last
          saved: {moment.unix(editionDetail.changed).format(CMS_DATE_FORMAT)}</Typography>
      </Grid>}
    </Grid>
  );
};

EditionDetailForm.defaultProps = {
  webAuthentication: null,
};

EditionDetailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  editionDetail: PropTypes.object.isRequired,
  environment: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  updateEditionDetail: PropTypes.func.isRequired,
  onChangeSectionFilter: PropTypes.func.isRequired,
  onChangeWorkflowFilter: PropTypes.func.isRequired,
  sectionFilter: PropTypes.string.isRequired,
  workflowFilter: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  webAuthentication: PropTypes.string,
  workflowOptions: PropTypes.array.isRequired,
};

export default withStyles(styles)(connect(
  ({
    frame: {
      selectedPublication: {
        environment, domain, publicationConfig: { webAuthentication },
      },
      workflowOptions,
    },
  }) =>
    ({ environment, workflowOptions, domain, webAuthentication }),
  { push, updateEditionDetail },
)(EditionDetailForm));
