import { ofType } from 'redux-observable';
import {
  map, mergeMap, filter,
  withLatestFrom, takeUntil, throttleTime,
} from 'rxjs/operators';
import { of, from } from 'rxjs';

import sendMessage from '../../utils/sendMessage';

import {
  INIT_FIELD_LOCKING,
  FIELD_LOCK_SUBSCRIBE,
  SET_FIELD_LOCK, DISPOSE_FIELD_LOCKING,
} from '../../constants/actionTypes/fieldLock';
import {
  SET_ARTICLE_EDITION_PROPERTY,
  SET_EDITION_FIELD_LOCK,
  ARTICLE_EDITION_EDIT_READY,
  SET_IMAGE_HERO_ARTICLE_EDITION,
  REMOVE_ARTICLE_EDITION_HERO_IMAGE,
  SAVE_EDITION_ARTICLE_COMPONENT,
  ADD_RELATED_ARTICLE_EDITION,
  CHANGE_RELATED_ARTICLE_PROPERTY,
  REMOVE_ARTICLE_EDITION_VOCAB,
  EDITION_ARTICLE_ADD_VOCAB,
  EDITION_ARTICLE_ENTER_PAGE, EDITION_DISPOSE_PAGE,
} from '../../constants/actionTypes/edition';

import { CONTENT_TYPE_EDITION } from '../../constants/edition/edition';
import {
  LOCKABLE_FIELDS,
  FIELD_HERO_IMAGE,
  FIELD_BODY,
  FIELD_RELATED_ARTICLE,
} from '../../constants/edition/editionFields';
import { EDITION_TEMPLATES } from '../../constants/vocab';

const getFieldFromType = (type) => {
  switch(type) {
    case SET_IMAGE_HERO_ARTICLE_EDITION:
    case REMOVE_ARTICLE_EDITION_HERO_IMAGE:
      return FIELD_HERO_IMAGE;
    default:
      return '';
  }
};

const getValueFromType = (type, value) => {
  switch (type) {
    case SET_IMAGE_HERO_ARTICLE_EDITION:
      return { data: value };
    default:
      return null;
  }
};

export const initAndSubscribeToEditionLock = (action$, state$) => action$.pipe(
  ofType(ARTICLE_EDITION_EDIT_READY, EDITION_ARTICLE_ENTER_PAGE),
  mergeMap(action => of(action).pipe(
    withLatestFrom(state$),
    filter(([, { edition: { articleEditionFromServer } }]) => (!!articleEditionFromServer)),
    mergeMap(([,
      {
        edition: { articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id: type }] } },
        login: { user: { name, uid, mail } },
      },
    ]) => {
      const actions = [
        {
          type: INIT_FIELD_LOCKING,
          value: {
            contentType: `${CONTENT_TYPE_EDITION}_${type}`,
            contentId: id,
          },
        },
      ];
      actions.push(sendMessage({
        type: FIELD_LOCK_SUBSCRIBE,
        value: {
          contentType: `${CONTENT_TYPE_EDITION}_${type}`,
          contentId: id,
          field: null,
          user: { name, uid, mail },
        },
      }));
      return from(actions);
    }),
  )),
);

export const disposeEditionLock = action$ => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(EDITION_DISPOSE_PAGE),
    map(() => ({ type: DISPOSE_FIELD_LOCKING })),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const selfLockEditionField = (action$, state$) => action$.pipe(
  ofType(SET_ARTICLE_EDITION_PROPERTY, SET_EDITION_FIELD_LOCK),
  withLatestFrom(state$),
  filter(([{ value: [field, , isFetched] }, { edition: { articleEditionFromServer } }]) => {
    return !!articleEditionFromServer &&
    LOCKABLE_FIELDS.indexOf(field) !== -1 &&
    (typeof isFetched === 'undefined' || isFetched === false);
  }),
  map(([{ value: [field, value] }, {
    edition: { articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id: type }] } },
  }]) => ({
    type: SET_FIELD_LOCK,
    value: {
      contentType: `${CONTENT_TYPE_EDITION}_${type}`,
      contentId: id,
      field,
      value,
    },
  })),
);

const getVocabValue = (type, stateValue, value) => {
  const [vocab, data] = value;
  switch (type) {
    case EDITION_ARTICLE_ADD_VOCAB:
      return (vocab === EDITION_TEMPLATES) ? [data] : [...stateValue, data];
    case REMOVE_ARTICLE_EDITION_VOCAB:
      return stateValue.filter(({ id }) => id !== data.id);
    default:
      return data;
  }
};

export const selfLockEditionVocabField = (action$, state$) => action$.pipe(
  ofType(EDITION_ARTICLE_ADD_VOCAB, REMOVE_ARTICLE_EDITION_VOCAB),
  withLatestFrom(state$),
  filter(([{ value: [field] }, { edition: { articleEditionFromServer } }]) => {
    return !!articleEditionFromServer && LOCKABLE_FIELDS.indexOf(field) !== -1;
  }),
  map(([{ type, value }, {
    edition,
  }]) => {
    const { articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id }] } } = edition;
    const [field] = value;
    return {
      type: SET_FIELD_LOCK,
      value: {
        contentType: `${CONTENT_TYPE_EDITION}_${target_id}`,
        contentId: id,
        field,
        value: getVocabValue(type, edition[field], value),
      },
    };
  }),
);

export const selfLockEditionRelatedField = (action$, state$) => action$.pipe(
  ofType(ADD_RELATED_ARTICLE_EDITION),
  withLatestFrom(state$),
  filter(([, { edition: { articleEditionFromServer } }]) => (!!articleEditionFromServer)),
  map(([, {
    edition: { relatedArticle, articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id: type }] } },
  }]) => ({
    type: SET_FIELD_LOCK,
    value: {
      contentType: `${CONTENT_TYPE_EDITION}_${type}`,
      contentId: id,
      field: FIELD_RELATED_ARTICLE,
      value: relatedArticle,
    },
  })),
);

export const selfLockEditionRelatedChangeField = (action$, state$) => action$.pipe(
  ofType(CHANGE_RELATED_ARTICLE_PROPERTY),
  withLatestFrom(state$),
  filter(([, { edition: { articleEditionFromServer } }]) => (!!articleEditionFromServer)),
  map(([{ value: [index, key, text] }, {
    edition: { relatedArticle, articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id: type }] } },
  }]) => ({
    type: SET_FIELD_LOCK,
    value: {
      contentType: `${CONTENT_TYPE_EDITION}_${type}`,
      contentId: id,
      field: FIELD_RELATED_ARTICLE,
      value: relatedArticle.map((article, i) => {
        if (i === index) {
          return {
            ...article,
            [key]: text,
          };
        }
        return article;
      }),
    },
  })),
);

export const selfLockEditionBodyField = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(SAVE_EDITION_ARTICLE_COMPONENT),
    withLatestFrom(state$),
    throttleTime(1000),
    filter(([, { edition: { articleEditionFromServer } }]) => (!!articleEditionFromServer)),
    map(([{ value: [data, pos] }, { edition }]) => {
      const { articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id: type }] } } = edition;
      return {
        type: SET_FIELD_LOCK,
        value: {
          contentType: `${CONTENT_TYPE_EDITION}_${type}`,
          contentId: id,
          field: FIELD_BODY,
          value: edition[FIELD_BODY].map((comp, i) => {
            if (i === pos) {
              return {
                ...comp,
                data: { ...comp.data, ...data },
              };
            }
            return comp;
          }),
        },
      };
    }),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);

export const selfLockEditionMediaField = (action$, state$) => action$.pipe(
  ofType(INIT_FIELD_LOCKING),
  mergeMap(() => action$.pipe(
    ofType(
      SET_IMAGE_HERO_ARTICLE_EDITION,
      REMOVE_ARTICLE_EDITION_HERO_IMAGE,
    ),
    withLatestFrom(state$),
    filter(([, { edition: { articleEditionFromServer } }]) => !!articleEditionFromServer),
    map(([{ type, value }, { edition }]) => {
      const { articleEditionFromServer: { id: [{ value: id }], bundle: [{ target_id }] } } = edition;
      return {
        type: SET_FIELD_LOCK,
        value: {
          contentType: `${CONTENT_TYPE_EDITION}_${target_id}`,
          contentId: id,
          field: getFieldFromType(type),
          value: getValueFromType(type, value),
        },
      };
    }),
    takeUntil(action$.pipe(ofType(DISPOSE_FIELD_LOCKING))),
  )),
);
