import { PURGE } from 'redux-persist';

import {
  STORY_LIST_FETCH,
  STORY_LIST_FETCH_SUCCESS,
  STORY_LIST_DISPOSE,
} from '../../constants/actionTypes/story';

const defaultState = {
  stories: [],
  total: 0,
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STORY_LIST_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case STORY_LIST_FETCH_SUCCESS: {
      const { items = [], total = 0 } = action.value;
      return {
        ...state,
        stories: items,
        total,
        isFetching: false,
      };
    }
    case STORY_LIST_DISPOSE:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
