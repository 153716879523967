export const MARK_SEARCH_REPLACE = 'search_replace';

export const DEFAULTS_SEARCH_REPLACE = {
  searchReplace: {
    type: MARK_SEARCH_REPLACE,
    rootProps: {
      as: 'span',
      className: 'slate-search-replace',
      styles: {
        root: {
          backgroundColor: '#fff59d',
        },
      },
    },
  },
};
