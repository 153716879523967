import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { updateProfile } from 'actions/users';

import Button from 'components/ui/buttons/Button';
import TextField from 'components/ui/TextField';
import MFADialog from 'components/dialog/MfaDialog';

const InputRow = ({ onChange, ...rest }) => (
  <TextField
    defaultValue={''}
    onChange={e => {
      onChange(e.target.value);
    }}
    {...rest}
  />
);

InputRow.propTypes = {
  onChange: PropTypes.func.isRequired,
};

// @todo refactor and simplify, the inputRow mapping is unneccessarily complex
const Profile = () => {
  const { mfa } = useSelector(state => state.login?.user || {});
  // @todo simplify userCurrent object, unnecessarily nested
  const { uid, mail, name } = useSelector(state => state.userCurrent?.user || {});
  const [userName, setUsername] = useState(name);
  const [email, setMail] = useState(mail);
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [passwordNew, setPasswordNew] = useState('');
  const [open, setOpen] = useState(false);
  const [passValid, setPassValid] = useState(true);
  const dispatch = useDispatch();
  const handleSubmit = () => dispatch(updateProfile({
    mail: email,
    userName,
    uid,
    passwordCurrent,
    passwordNew,
  }));

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (open && mfa) {
      setOpen(false);
    }
  }, [mfa, open]);

  return (
    <>
      <form>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Typography color={'primary'} variant={'h2'}>Details</Typography>
            {[{
              label: 'Username',
              value: userName,
              onChange: setUsername,
              required: true,
            }, {
              label: 'Email',
              value: email,
              onChange: setMail,
              required: true,
            }].map(InputRow)}
          </Grid>
          <Grid item xs={6}>
            <Typography color={'primary'} variant={'h2'}>Password</Typography>
            {[{
              label: 'Current password',
              value: passwordCurrent,
              onChange: (pass) => {
                setPassValid(!!pass);
                setPasswordCurrent(pass);
              },
              type: 'password',
              required: true,
              helperText: passValid ? '' : 'Current password is required.',
              error: !passValid,
            }, {
              label: 'New password',
              value: passwordNew,
              onChange: setPasswordNew,
              type: 'password',
              required: false,
            }].map(InputRow)}
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between" direction="row">
              <Grid item>
                {!mfa && <Button
                  onClick={handleOpen}
                  variant={'outlined'}
                >Set up two factor authentication</Button>}
                {mfa && <Typography >Two factor authentication enabled</Typography>}
              </Grid>
              <Grid item>
                <Button onClick={handleSubmit} variant="contained">Update</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {open && <MFADialog
        open={open}
        onClose={handleClose}
      />}
    </>
  );
};

export default Profile;
