import {
  DISPOSE_SCORE_BOARD_COMPETITION,
  FETCH_SCORE_BOARD_COMPETITION, FETCH_SCORE_BOARD_MATCH, SET_SCOREBOARD_PROPERTY,
} from '../constants/actionTypes';

export const fetchScoreBoardCompetition = () => ({
  type: FETCH_SCORE_BOARD_COMPETITION,
});

export const disposeScoreBoardCompetition = () => ({
  type: DISPOSE_SCORE_BOARD_COMPETITION,
});

export const fetchScoreBoardMatch = value => ({
  type: FETCH_SCORE_BOARD_MATCH,
  value,
});

export const setScoreBoardProperty = value => ({
  type: SET_SCOREBOARD_PROPERTY,
  value,
});
