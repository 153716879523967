export const PAGE_ENTER = 'PAGE_ENTER';
export const PAGE_ENTER_SIDEBAR = 'PAGE_ENTER_SIDEBAR';
export const PAGE_ENTER_MANUAL_LIST = 'PAGE_ENTER_MANUAL_LIST';
export const PAGE_ENTER_AD = 'PAGE_ENTER_AD';
export const PAGE_ENTER_MANUAL_LIST_EDIT = 'PAGE_ENTER_MANUAL_LIST_EDIT';
export const PAGE_ENTER_AD_CREATE = 'PAGE_ENTER_AD_CREATE';
export const PAGE_ENTER_USERS = 'PAGE_ENTER_USERS';
export const PAGE_ENTER_LAYOUT = 'PAGE_ENTER_LAYOUT';
export const PAGE_LEAVE_LAYOUT = 'PAGE_LEAVE_LAYOUT';
export const PAGE_ENTER_ARTICLE = 'PAGE_ENTER_ARTICLE';
export const PAGE_ENTER_ARTICLE_CREATE = 'PAGE_ENTER_ARTICLE_CREATE';
export const PAGE_ENTER_LIVEBLOG_EDIT = 'PAGE_ENTER_LIVEBLOG_EDIT';
export const PAGE_ENTER_LIVEBLOG_BUILD = 'PAGE_ENTER_LIVEBLOG_BUILD';
export const PAGE_ENTER_LIVEBLOG_LIST = 'PAGE_ENTER_LIVEBLOG_LIST';
export const PAGE_ENTER_ARTICLE_EDIT = 'PAGE_ENTER_ARTICLE_EDIT';
export const PAGE_ENTER_ARTICLE_EDIT_LOADED = 'PAGE_ENTER_ARTICLE_EDIT_LOADED';
export const PAGE_ENTER_DASHBOARD = 'PAGE_ENTER_DASHBOARD';
export const PAGE_ENTER_EDITION_ARTICLE = 'PAGE_ENTER_EDITION_ARTICLE';
