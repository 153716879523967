import { setDefaults } from '@udecode/slate-plugins';
import deserializeHorizontalRule from './deserializeHorizontalRule';
import renderElementHorizontalRule from './renderElementHorizontalRule';
import { DEFAULTS_HR } from './defaults';

const HorizontalRulePlugin = (options) => {
  const { horizontalRule } = setDefaults(options, DEFAULTS_HR);
  return ({
    renderElement: renderElementHorizontalRule(options),
    deserialize: deserializeHorizontalRule(options),
    voidTypes: [horizontalRule.type],
  });
};

export default HorizontalRulePlugin;
