import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  onRemoveTerm,
  onReplaceTerm,
  onSelectTerm,
  setEditionLockField,
} from '../../../../actions/edition';

import { PagePanel, PagePanelContent, PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';
import {
  AUTHORS,
  EDITION_SECTION,
  VOCAB_PLURAL,
  VOCAB_SINGULAR,
} from '../../../../constants/vocab';
import { FIELD_AUTHORS, FIELD_EDITION_SECTIONS } from '../../../../constants/edition/editionFields';

import generateKey from '../../../../utils/generateKey';
import AuthorInfo from './AuthorInformation';
import SectionSlug from './SectionSlug';
import FieldLockVocabSelector from '../../../fieldLock/form/FieldLockVocabSelectorDepricated';

const styles = theme => ({
  [`wrapper-${EDITION_SECTION}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    '&>div:first-child': {
      width: `calc(34% - ${theme.spacing(0.5)}px)`,
    },
    '&>div:last-child': {
      width: `calc(66% - ${theme.spacing(0.5)}px)`,
      '& .MuiFormControl-marginDense': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },
  [`wrapper-${AUTHORS}`] : {},
});

const SectionPanel = (props) => {
  const {
    classes, sections, authors, vocabs,
    onSelectTerm: onSelect,
    onReplaceTerm: onReplace,
    onRemoveTerm: onRemove,
    setEditionLockField: lockField,
  } = props;
  const taxomomyList = [
    [EDITION_SECTION, sections, FIELD_EDITION_SECTIONS, [
      { id: generateKey(), component: SectionSlug },
    ]],
    [AUTHORS, authors, FIELD_AUTHORS, [
      { id: generateKey(), component: AuthorInfo },
    ]],
  ];
  const taxonomyOptions = [...vocabs];

  return (
    <PagePanel>
      <PagePanelHeading>
        <SectionTitle>Section & Authors</SectionTitle>
      </PagePanelHeading>
      <PagePanelContent>
        {taxomomyList
          .filter(([vocab]) => taxonomyOptions.includes(vocab))
          .map(([vocab, selectedTerms, field, extraFields]) => (
            <div
              key={vocab}
              className={classes[`wrapper-${vocab}`]}
            >
              <FieldLockVocabSelector
                required={[EDITION_SECTION].includes(vocab)}
                field={field}
                onUnlock={() => {
                  lockField([field, selectedTerms]);
                }}
                extraFields={extraFields}
                vocab={vocab}
                selectedTerms={selectedTerms}
                label={
                  capitalize([EDITION_SECTION].includes(vocab)
                    ? VOCAB_SINGULAR[vocab]
                    : VOCAB_PLURAL[vocab])
                }
                onRemove={onRemove}
                onSelect={[EDITION_SECTION].includes(vocab) ? onReplace : onSelect}
                limit={[EDITION_SECTION].includes(vocab) ? 1 : -1}
                showExtras={[EDITION_SECTION].includes(vocab)}
              />
            </div>
          ))
        }
      </PagePanelContent>
    </PagePanel>
  );
};

SectionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  sections: PropTypes.array.isRequired,
  authors: PropTypes.array.isRequired,
  vocabs: PropTypes.array.isRequired,
  onSelectTerm: PropTypes.func.isRequired,
  onReplaceTerm: PropTypes.func.isRequired,
  onRemoveTerm: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  ({
    edition: {
      pugpig_section: sections, authors,
    },
    frame: { selectedPublication: { vocabs } },
  }) => ({ sections, authors, vocabs }),
  { onReplaceTerm, onSelectTerm, onRemoveTerm, setEditionLockField },
)(SectionPanel));
