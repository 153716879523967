import {
  green,
  amber,
  deepOrange,
  cyan,
  deepPurple,
  pink,
  lime,
  orange,
  brown,
  indigo,
} from '@material-ui/core/colors';

export const AD_ALIGNMENT = 'AdAlignment';
export const AD_SOURCE = 'AdSource';
export const TABOOLA_EXCLUDE_ARTICLES = 'taboolaExcludeArticles';
export const CONTENT_SOURCE = 'ContentSource';
export const USE_AUTHOR_IMAGE = 'UseAuthorImage';
export const NEWSLETTER_TARGET = 'NewsletterTarget';
export const PLAYLIST_ID = 'PlaylistId';
export const SERVICE = 'service';
export const LABEL_LINK = 'LabelLink';
export const LABEL_TEXT = 'LabelText';
export const LINK_TITLE = 'linkTitle';
export const LINK_URL = 'linkUrl';
export const EXTRA_TITLE = 'extraTitle';
export const EXTRA_URL = 'extraUrl';
export const AUTHOR = 'author';
export const BIOGRAPHY = 'biography';
export const COMMERCIAL_BADGE_IMAGE = 'commercialBadgeImage';
export const COMMERCIAL_BADGE_LINK = 'commercialBadgeLink';
export const COMMERCIAL_BADGE_TERM = 'commercialBadgeTerm';
export const VIDEO = 'video';
export const MARKUP = 'markup';
export const BUTTON_DISPLAY = 'buttonDisplay';

export const THEME = 'theme';
export const SHOW_ICON = 'showIcon';

export const PINNED_ARTICLES = 'pinnedArticles';

export const CONTENT_SOURCE_DEFAULT = 'Default';
export const CONTENT_SOURCE_CUSTOM = 'Custom';
export const CONTENT_SOURCE_MANUAL = 'ManualList';
export const CONTENT_SOURCE_LABEL_DEFAULT = 'Auto';
export const CONTENT_SOURCE_LABEL_CUSTOM = 'Custom';
export const CONTENT_SOURCE_LABEL_MANUAL = 'Manual';

export const CONTENT_SOURCE_OPTIONS = [
  CONTENT_SOURCE_DEFAULT, CONTENT_SOURCE_CUSTOM, CONTENT_SOURCE_MANUAL,
];

export const CONTENT_SOURCE_OPTION_ITEMS = {
  [CONTENT_SOURCE_DEFAULT]: CONTENT_SOURCE_LABEL_DEFAULT,
  [CONTENT_SOURCE_CUSTOM]: CONTENT_SOURCE_LABEL_CUSTOM,
  [CONTENT_SOURCE_MANUAL]: CONTENT_SOURCE_LABEL_MANUAL,
};

export const CONTENT_SOURCE_CUSTOM_SECTION = 'ContentSourceCustomSection';
export const CONTENT_SOURCE_VOCAB = 'ContentSourceVocab';
export const CONTENT_SOURCE_CUSTOM_CHAIN = 'ContentSourceCustomChain';
export const CONTENT_SOURCE_MANUAL_LIST_SECTION = 'ContentSourceManualListSection';

export const MANUAL_LIST_COLORS = [
  green[400],
  amber[400],
  deepPurple[400],
  deepOrange[400],
  cyan[400],
  pink[400],
  lime[400],
  orange[400],
  brown[400],
  indigo[400],
];

export const AUTHOR_HIGHLIGHT = 'AuthorHighlight';

export const LABEL_TEXT_DEFAULT_VALUE = '';
export const LABEL_LINK_DEFAULT_VALUE = '';

export const AD_ALIGNMENT_VALUE2 = 'Right';


export const DEFAULT_COMPONENT_PROP = [
  [LABEL_LINK, LABEL_LINK_DEFAULT_VALUE],
  [LABEL_TEXT, LABEL_TEXT_DEFAULT_VALUE],
];

export const DEFAULT_ARTICLE_COMPONENT_PROP = [
  ...DEFAULT_COMPONENT_PROP,
  [CONTENT_SOURCE, CONTENT_SOURCE_DEFAULT],
];

export const DEFAULT_ARTICLE_MPU_COMPONENT_PROP = [
  ...DEFAULT_ARTICLE_COMPONENT_PROP,
  [AD_ALIGNMENT, AD_ALIGNMENT_VALUE2],
];

export const EDITORS_PICK_PRIORITY_OPTION = [
  { id: 'trending', name: 'Trending' },
  { id: 'editorsChoice', name: 'Editor\'s Choice' },
];
