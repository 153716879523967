import React from 'react';
import PropTypes from 'prop-types';

import ImageSelector from '../../../../ui/media/ImageSelector';

const ImageField = (props) => {
  const {
    image, label, onSelect, onRemove,
  } = props;
  return (
    <ImageSelector
      selectImage={onSelect}
      onRemove={onRemove}
      label={label}
      image={image !== null ? image.data : image}
      showEditDialog
    />
  );
};

ImageField.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.object,
  label: PropTypes.string,
};

ImageField.defaultProps = {
  image: null,
  label: 'Add image',
};

export default ImageField;
