import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import ImageField from './common/ImageField';
import { FieldWrapper } from './common/GridWrapper';

const PROMPT_LABEL = 'Prompt';
const OPT1_LABEL = 'Option 1';
const OPT2_LABEL = 'Option 2';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA';

const TemplateBinaryPoll = (props) => {
  const {
    id, prompt, image, options, attachment, attachmentCTA,
    updateStoryPageProp: updateProp,
  } = props;
  while (options.length < 2) {
    options.push('');
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={'Add cover image'}
          onSelect={value => updateProp(id, 'image', {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateProp(id, 'image', null)}
          image={image}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={PROMPT_LABEL}
          value={prompt}
          onChange={e => updateProp(id, 'prompt', e.target.value)}
        />
        <TextField
          label={OPT1_LABEL}
          value={options[0]}
          onChange={e => updateProp(id, 'options', [
            e.target.value,
            options[1],
          ])}
        />
        <TextField
          label={OPT2_LABEL}
          value={options[1]}
          onChange={e => updateProp(id, 'options', [
            options[0],
            e.target.value,
          ])}
        />
        <TextField
          label={ATTACHMENT_LABEL}
          placeholder={ATTACHMENT_PLACEHOLDER}
          value={attachment}
          onChange={e => updateProp(id, 'attachment', e.target.value)}
        />
        <TextField
          label={ATTACHMENT_CTA_LABEL}
          placeholder={ATTACHMENT_CTA_PLACEHOLDER}
          value={attachmentCTA}
          onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
        />
      </FieldWrapper>
    </Grid>
  );
};

TemplateBinaryPoll.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  options: PropTypes.array,
  image: PropTypes.object,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplateBinaryPoll.defaultProps = {
  prompt: '',
  options: [],
  image: null,
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplateBinaryPoll);
