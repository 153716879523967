import React from 'react';
import PropTypes from 'prop-types';

import ArticleRef from './common/ArticleRef';
import DroppableImage from '../../../../ui/media/ImageSelector';

const FIELD_IMAGE = 'image';
const FIELD_ARTICLE_REF = 'articleRef';

const Poster = (props) => {
  const {
    component: { image, articleRef },
    setProperty, delta,
  } = props;

  const assignImage = data =>
    setProperty({
      delta,
      prop: FIELD_IMAGE,
      value: {
        type: 'image',
        data,
      },
    });

  return (
    <>
      <DroppableImage
        selectImage={assignImage}
        onRemove={() => assignImage(null)}
        label="Poster image"
        image={image?.data}
        showEditDialog
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={value => setProperty({
          delta,
          prop: FIELD_ARTICLE_REF,
          value: value ? {
            title: value.data.title,
            id: value.data.id,
            bundle: value.type,
          } : null,
        })}
      />
    </>
  );
};

Poster.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default Poster;
