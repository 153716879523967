import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_HR } from './defaults';

const renderElementHorizontalRule = (options) => {
  const { horizontalRule } = setDefaults(options, DEFAULTS_HR);
  return getRenderElement(horizontalRule);
};

export default renderElementHorizontalRule;
