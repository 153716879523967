import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Page from '../components/common/page/Page';
import PageToolbar from '../components/common/page/PageToolbar';
import PageContent from '../components/common/page/PageContent';
import RecentArticle from '../components/dashboard/RecentArticles';
import { fetchCMSAPIVersion } from '../actions/dashboard';
import { loginTransitionComplete } from '../actions/login';
import LoginBackground from '../components/login/LoginBackground';

const Dashboard = (
  {
    apiVersion,
    appVersion,
    showTransition,
    fetchCMSAPIVersion: onFetchAPIVersion,
    loginTransitionComplete: transitionComplete,
  },
) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    onFetchAPIVersion();
    setShow(true);
  });

  useEffect(() => {
    if (apiVersion && appVersion) {
      console.info('APP VERSION: ', appVersion);
      console.info('CMS API VERSION: ', apiVersion);
    }
  }, [apiVersion, appVersion]);
  return (
    <>
      {showTransition && <LoginBackground
        inState={show}
        leave
        onEntered={transitionComplete}
      />}
      <Page toolbarContent={<PageToolbar breadcrumb={[{ title: 'Dashboard' }]} />}>
        <PageContent direction={'row'}>
          <Grid item xs={4}>
            <RecentArticle size={4} />
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={4} />
        </PageContent>
      </Page>
    </>
  );
};

Dashboard.propTypes = {
  fetchCMSAPIVersion: PropTypes.func.isRequired,
  loginTransitionComplete: PropTypes.func.isRequired,
  apiVersion: PropTypes.string.isRequired,
  appVersion: PropTypes.string.isRequired,
  showTransition: PropTypes.bool,
};

Dashboard.defaultProps = {
  articleStats: [],
  showTransition: false,
};

export default connect(
  ({
    dashboard: { apiVersion, appVersion, showTransition },
  }) => ({ apiVersion, appVersion, showTransition }),
  { fetchCMSAPIVersion, loginTransitionComplete },
)(Dashboard);
