import React from 'react';
import PropTypes from 'prop-types';

import Toolbar from '@material-ui/core/Toolbar';
import withStyles from '@material-ui/core/styles/withStyles';

export const VARIANT_DEFAULT = 'default';
export const VARIANT_DENSE = 'dense';

const styles = () => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dense: {
    justifyContent: 'flex-end',
  },
});

function TableToolbar({ classes, className, children, variant }) {
  const toolbarClasses = [classes.toolbar];
  if (className) toolbarClasses.push(className);
  if (variant === VARIANT_DENSE) toolbarClasses.push(classes[VARIANT_DENSE]);
  return (
    <Toolbar className={toolbarClasses.join(' ')}>
      {children}
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

TableToolbar.defaultProps = {
  className: null,
  variant: VARIANT_DEFAULT,
};

export default withStyles(styles)(TableToolbar);
