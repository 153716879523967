export const LAYOUT_COMPONENTS_LOADED = 'LAYOUT_COMPONENTS_LOADED';

export const LAYOUT_SAVE = 'LAYOUT_SAVE';
export const LAYOUT_SAVE_READY = 'LAYOUT_SAVE_READY';
export const LAYOUT_SAVE_SUCCESS = 'LAYOUT_SAVE_SUCCESS';
export const LAYOUT_SAVE_REJECTED = 'LAYOUT_SAVE_REJECTED';

export const LAYOUT_FETCH = 'LAYOUT_FETCH';
export const LAYOUT_FETCH_SUCCESS = 'LAYOUT_FETCH_SUCCESS';
export const LAYOUT_FETCH_REJECTED = 'LAYOUT_FETCH_REJECTED';

export const LAYOUT_DISPOSE = 'LAYOUT_DISPOSE';

export const LAYOUT_DELETE = 'LAYOUT_DELETE';
export const LAYOUT_DELETE_SUCCESS = 'LAYOUT_DELETE_SUCCESS';
export const LAYOUT_DELETE_REJECTED = 'LAYOUT_DELETE_REJECTED';

export const LAYOUT_FETCH_PREVIEW = 'LAYOUT_FETCH_PREVIEW';
export const LAYOUT_FETCH_PREVIEW_SUCCESS = 'LAYOUT_FETCH_PREVIEW_SUCCESS';
export const LAYOUT_FETCH_PREVIEW_REJECTED = 'LAYOUT_FETCH_PREVIEW_REJECTED';
export const LAYOUT_PREVIEW_SET_OVERRIDE = 'LAYOUT_PREVIEW_SET_OVERRIDE';
export const LAYOUT_PREVIEW_SET_PROP = 'LAYOUT_PREVIEW_SET_PROP';
export const LAYOUT_PREVIEW_DOCK = 'LAYOUT_PREVIEW_DOCK';
export const LAYOUT_PREVIEW_UNDOCK = 'LAYOUT_PREVIEW_UNDOCK';
export const LAYOUT_PREVIEW_MODE_SET = 'LAYOUT_PREVIEW_MODE_SET';

export const LAYOUT_TERM_SELECTED = 'LAYOUT_TERM_SELECTED';
export const LAYOUT_SELECT_MANUAL_LIST = 'LAYOUT_SELECT_MANUAL_LIST';
export const LAYOUT_SELECT_MANUAL_LIST_GET_COUNT = 'LAYOUT_SELECT_MANUAL_LIST_GET_COUNT';
export const LAYOUT_SET_MANUAL_LIST_DEFAULT = 'LAYOUT_SET_MANUAL_LIST_DEFAULT';
export const LAYOUT_SET_MANUAL_LIST_LINK = 'LAYOUT_SET_MANUAL_LIST_LINK';

export const LAYOUT_ADD_COMPONENT = 'LAYOUT_ADD_COMPONENT';
export const LAYOUT_REMOVE_COMPONENT = 'LAYOUT_REMOVE_COMPONENT';
export const LAYOUT_SORT_COMPONENT = 'LAYOUT_SORT_COMPONENT';
export const LAYOUT_COMPONENT_DRAG_START = 'LAYOUT_COMPONENT_DRAG_START';
export const LAYOUT_COMPONENT_DRAG_END = 'LAYOUT_COMPONENT_DRAG_END';
export const LAYOUT_COMPONENT_SET_PROPERTY = 'LAYOUT_COMPONENT_SET_PROPERTY';

export const LAYOUT_COMPONENT_IMAGE_SELECTED = 'LAYOUT_COMPONENT_IMAGE_SELECTED';

export const LAYOUT_CONTEXT_SET = 'LAYOUT_CONTEXT_SET';
export const LAYOUT_CONTEXT_RESET = 'LAYOUT_CONTEXT_RESET';
export const LAYOUT_CONTEXT_SET_TARGET_INDEX = 'LAYOUT_CONTEXT_SET_TARGET_INDEX';
export const LAYOUT_CONTEXT_SET_FOCUS = 'LAYOUT_CONTEXT_SET_FOCUS';
