export const PAGE_COVER = 'cover';
export const PAGE_IMAGE = 'image';
export const PAGE_TEXT = 'text';

export const PAGE_POLL_BINARY = 'pollBinary';
export const PAGE_POLL = 'poll';
export const PAGE_QUIZ = 'quiz';
export const PAGE_RESULTS = 'results';

export const PAGE_TYPES = [
  PAGE_COVER,
  PAGE_IMAGE,
  PAGE_TEXT,
];

export const INTERACTIVE_TYPES = [
  PAGE_POLL_BINARY,
  PAGE_POLL,
  PAGE_QUIZ,
  PAGE_RESULTS,
];

export const PAGE_CONFIG = {
  [PAGE_COVER]: {
    type: PAGE_COVER,
    title: 'Cover',
  },
  [PAGE_IMAGE]: {
    type: PAGE_IMAGE,
    title: 'Image Page',
  },
  [PAGE_TEXT]: {
    type: PAGE_TEXT,
    title: 'Text Page',
  },
  [PAGE_POLL_BINARY]: {
    type: PAGE_POLL_BINARY,
    title: 'Binary Poll Page',
  },
  [PAGE_POLL]: {
    type: PAGE_POLL,
    title: 'Poll Page',
  },
  [PAGE_QUIZ]: {
    type: PAGE_QUIZ,
    title: 'Quiz Page',
  },
  [PAGE_RESULTS]: {
    type: PAGE_RESULTS,
    title: 'Results Page',
  },
};
