import { createSelector } from 'reselect';

import { FIELD_MANUAL_LISTS, PROP_MANUAL_INDEXES } from 'constants/layout/layoutFields';

import { getComponent, getComponentFromContext } from '../layout/layout';
import { getComponentList } from 'utils/manualListHelper';
import { CONTENT_SOURCE_MANUAL_LIST_SECTION } from 'components/layout/constants';

const getManualList = (state, props) =>
  state.dataState?.[FIELD_MANUAL_LISTS]?.[props.manualListId] || null;
const getManualLists = state => state.dataState?.[FIELD_MANUAL_LISTS] || null;
const getManualIndexes = state => state.dataState?.[PROP_MANUAL_INDEXES] || null;
const getManualListLockedData = (state, manualListId) =>
  state.externalState?.[FIELD_MANUAL_LISTS]?.[manualListId]
    ? state.fieldLock?.[`${FIELD_MANUAL_LISTS}_${manualListId}`] || null
    : null;
const getManualListItems = state => state.manualList?.manualListArticles || null;
const getContextPosition = state => typeof state.manualList?.context?.position !== 'undefined'
  ? state.manualList.context.position
  : null;

export const createSelectManualListItem = createSelector(
  [getManualListItems, getContextPosition],
  (items, position) => items && position !== null && items[position] ? items[position] : {},
);

export const makeGetManualList = () => createSelector(
  [getManualList],
  manualList => manualList,
);

export const makeGetManualListFromComponent = () => createSelector(
  [getManualLists, getComponent],
  (manualLists, component) => getComponentList(manualLists, component),
);

export const makeGetManualListFromContext = () => createSelector(
  [getManualLists, getComponentFromContext],
  (manualLists, component) => getComponentList(manualLists, component),
);

export const makeGetManualListLockFromComponent = (state) => createSelector(
  [getManualLists, getComponent],
  (manualLists, component) => {
    const manualList = getComponentList(manualLists, component);
    return manualList?.id
      ? getManualListLockedData(state, manualList.id)
      : null;
  },
);

export const makeGetManualIndexesFromComponent = () => createSelector(
  [getManualLists, getComponent, getManualIndexes],
  (manualLists, component, manualListIndexes) => {
    const manualList = getComponentList(manualLists, component);
    return {
      manualIndexes: manualList
        ? manualListIndexes?.[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id]?.[component.id]
        : null,
      manualListIndex: manualListIndexes && manualList ? Object
        .keys(manualListIndexes)
        .indexOf(component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id.toString()) : -1,
    };
  },
);

export const makeGetManualIndexesFromContext = () => createSelector(
  [getComponentFromContext, getManualIndexes],
  (component, manualListIndexes) => (component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id
    ? manualListIndexes?.[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id]?.[component.id]
    : null),
);
