import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import { AUTHORS } from '../constants/vocab';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import TableFilters, { TableFiltersGroup } from '../components/ui/table/TableFilters';
import DatePickerFilter from '../components/ui/table/filters/DatePickerFilter';
import StatusFilter from '../components/ui/table/filters/StatusFilter';
import VocabFilter from '../components/ui/table/filters/VocabFilter';
import TextSearchFilter from '../components/ui/table/filters/TextSearchFilter';
import StoryListTable from '../components/story/storyList/StoryListTable';

export const StoryListBreadcrumb = [
  {
    title: 'Visual Stories',
    slug: 'story',
  },
];

const StoryList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [author, setFilterAuthor] = useState('');
  const [status, setStatus] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={StoryListBreadcrumb} />
      }
    >
      <PageContent>
        <Grid item xs={12}>
          <TableFilters>
            <TableFiltersGroup>
              <TextSearchFilter
                placeholder={'Search by Title / Body'}
                onSubmit={textQuery => setSearchQuery(textQuery)}
              />
              <VocabFilter
                placeholder={'Search by Author'}
                vocab={AUTHORS}
                onSelect={selectedAuthor => setFilterAuthor(selectedAuthor)}
              />
              <StatusFilter
                onChange={setStatus}
                value={status}
                margin={'none'}
              />
              <DatePickerFilter
                toDate={toDate}
                fromDate={fromDate}
                onChangeFromDate={date => setFromDate(date)}
                onChangeToDate={date => setToDate(date)}
              />
            </TableFiltersGroup>
          </TableFilters>
        </Grid>
        <PageSection>
          <StoryListTable
            textQuery={searchQuery}
            status={status}
            fromDate={fromDate ? fromDate.unix() : null}
            toDate={toDate ? toDate.unix() : null}
            author={author}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

export default StoryList;
