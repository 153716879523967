import React from 'react';

import Grid from '@material-ui/core/Grid';

import CommercialCampaignPanel from './CommercialCampaignPanel';

const CommercialComponent = () => (
  <>
    <Grid item xs={12}><CommercialCampaignPanel /></Grid>
  </>
);

export default CommercialComponent;
