import OptaElement from './components/OptaElement';

export const ELEMENT_OPTA = 'opta';
export const PROP_EMBED = 'embed';
export const PROP_EMBED_AMP = 'embedAmp';

export const DEFAULTS_OPTA = {
  opta: {
    component: OptaElement,
    type: ELEMENT_OPTA,
    rootProps: {
      className: 'slate-opta',
    },
  },
};
