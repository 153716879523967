import { ELEMENT_PRICE_COMPARISON, PROP_TITLE, PROP_EMBED_ID } from './defaults';
import { PRICE_COMPARISON } from '../../../../../../constants/editor/dataTypes';

export const convertToPriceComparison = data => ({
  type: ELEMENT_PRICE_COMPARISON,
  [PROP_TITLE]: data[PROP_TITLE] || '',
  [PROP_EMBED_ID]: data[PROP_EMBED_ID] || '',
  children: [{ text: '' }],
});

export const convertFromPriceComparison = data => ({
  type: PRICE_COMPARISON,
  data: {
    [PROP_TITLE]: data[PROP_TITLE] || '',
    [PROP_EMBED_ID]: data[PROP_EMBED_ID] || '',
  },
});
