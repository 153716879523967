import {
  STORY_LIST_DISPOSE, STORY_LIST_FETCH,
  STORY_ADD_PAGE, STORY_REMOVE_PAGE, STORY_REORDER_PAGE, STORY_UPDATE_PAGE_PROP,
  STORY_UPDATE_FIELD, STORY_ADD_TO_FIELD, STORY_REMOVE_FROM_FIELD, STORY_ON_BLUR_FIELD,
  STORY_SET_CONTEXT,
  STORY_SAVE, STORY_DELETE, STORY_FETCH, STORY_DISPOSE,
  STORY_BOOKEND_ARTICLE_DROP, STORY_BOOKEND_ARTICLE_REMOVE,
  STORY_REQUEST_PREVIEW, STORY_AUTOCOMPLETE,
} from '../constants/actionTypes/story';

export const addStoryPage = (effect, { component, delta }) =>
  ({ type: STORY_ADD_PAGE, value: { page: component, delta } });
export const removeStoryPage = value => ({ type: STORY_REMOVE_PAGE, value });
export const reorderStoryPage = (oldIndex, newIndex) =>
  ({ type: STORY_REORDER_PAGE, value: { oldIndex, newIndex } });
export const updateStoryPageProp = (id, prop, value) =>
  ({ type: STORY_UPDATE_PAGE_PROP, value: { id, prop, value } });

export const updateStoryField = (field, value) =>
  ({ type: STORY_UPDATE_FIELD, value: { field, value } });
export const addToStoryField = (field, value) =>
  ({ type: STORY_ADD_TO_FIELD, value: { field, value } });
export const removeFromStoryField = (field, value) =>
  ({ type: STORY_REMOVE_FROM_FIELD, value: { field, value } });
export const onBlurStoryField = (field, value) =>
  ({ type: STORY_ON_BLUR_FIELD, value: { field, value } });

export const setStoryContext = (type, data = null) =>
  ({ type: STORY_SET_CONTEXT, value: { type, data } });

export const fetchStory = value => ({ type: STORY_FETCH, value });
export const disposeStory = () => ({ type: STORY_DISPOSE });
export const saveStory = value => ({ type: STORY_SAVE, value });
export const deleteStory = () => ({ type: STORY_DELETE });

export const fetchStoryList = value => ({ type: STORY_LIST_FETCH, value });
export const disposeStoryList = value => ({ type: STORY_LIST_DISPOSE, value });

export const dropBookendArticle = (effect, data) =>
  ({ type: STORY_BOOKEND_ARTICLE_DROP, value: data });
export const removeBookendArticle = value => ({ type: STORY_BOOKEND_ARTICLE_REMOVE, value });

export const requestStoryPreview = () => ({ type: STORY_REQUEST_PREVIEW });

export const searchStories = value => ({ type: STORY_AUTOCOMPLETE, value });
