import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Toolbar } from '@udecode/slate-plugins';

import { Tab, TabExtras, Tabs } from '../ui/Tabs';

import ToolbarConfig, { VARIANT_DEFAULT } from './toolbar/ToolbarConfig';
import ToolbarButtons from './toolbar/ToolbarButtons';
import ToolbarSpecialChar from './toolbar/ToolbarSpecialChar';
import ToolbarCopyText from './toolbar/ToolbarCopyText';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.contrast,
    position: 'sticky',
    top: 0,
    borderRadius: '4px 4px 0 0',
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    zIndex: 100,
    '& .slate-Toolbar': {
      backgroundColor: theme.palette.background.default,
      minHeight: 30,
    },
  },
  count: {
    height: '21px',
  },
  button: {
    height: 30,
    width: 30,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  separator: {
    height: 30,
  },
});

const EditorToolbar = (props) => {
  const {
    classes, wordCount, conditions, variant, decorators, clearDecorators,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const config = new ToolbarConfig(variant);
  const groups = config.getGroups();
  return (
    <div className={classes.root}>
      {groups && <Tabs
        value={activeTab}
        onChange={(e, val) => {
          setActiveTab(val);
          if (clearDecorators) clearDecorators();
        }}
        indicatorColor={'primary'}
        textColor={'primary'}
        size={'small'}
      >
        {groups.map(group => (
          <Tab key={group} label={group} size={'small'} />
        ))}
        {wordCount !== null && <TabExtras>
          <Grid item className={classes.count}>
            <Typography variant={'caption'} display={'inline'}>Word count: </Typography>
            <Typography variant={'caption'} display={'inline'} color={'primary'}>{wordCount}</Typography>
          </Grid>
        </TabExtras>}
      </Tabs>}
      <Toolbar className={classes.bar}>
        <ToolbarButtons
          classes={classes}
          elements={groups ? config.getElementsByGroup(groups[activeTab]) : config.getElements()}
          conditions={conditions}
          decorators={decorators}
          after={
            groups[activeTab] === 'Text'
              ? [
                <ToolbarSpecialChar key={'specialChars'} />,
                <ToolbarCopyText key={'copyText'} className={classes.button} />,
              ] : []
          }
        />
      </Toolbar>
    </div>
  );
};

EditorToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  wordCount: PropTypes.number,
  conditions: PropTypes.array,
  variant: PropTypes.string,
  decorators: PropTypes.object,
  clearDecorators: PropTypes.func,
};

EditorToolbar.defaultProps = {
  wordCount: null,
  conditions: [],
  decorators: {},
  clearDecorators: null,
  variant: VARIANT_DEFAULT,
};

export default withStyles(styles)(EditorToolbar);
