import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ToggleSwitch from '../../ui/ToggleSwitch';
import Select from '../../ui/Select';
import { DOMAIN_INDY } from '../../../constants/domainConfig';
import { INDY_ADPARTNERS } from '../../../constants/adConfig/indy';

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
});

const getAdPartners = (domain) => {
  switch (true) {
    case domain === DOMAIN_INDY:
      return INDY_ADPARTNERS;
    default:
      return [];
  }
};

const AdConfigElement = ({ type, data, onChange, value, domain }) => {
  switch (type) {
    case 'range': {
      const { start = 1, length = 10, step = 1 } = data;
      return (<Select
        value={value}
        onChange={e => onChange(e.target.value)}
        emptyValue={'none'}
        items={[...Array(length).keys()].map(i => (i + start) * step)}
      />);
    }
    case 'partner':
      return (<Select
        value={value}
        onChange={e => onChange(e.target.value)}
        emptyValue={'none'}
        items={getAdPartners(domain)}
      />);
    default:
      return (<ToggleSwitch
        value={value}
        onChange={e => onChange(e.target.checked)}
      />);
  }
};

AdConfigElement.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  type: PropTypes.string,
  data: PropTypes.object,
};

AdConfigElement.defaultProps = {
  type: 'toggle',
  data: {},
};

const AdConfigTab = (props) => {
  const { classes, config, fields, domain, type: configType, setProp } = props;
  return (
    <Grid className={classes.root} container spacing={1}>
      {fields.map(({ title, tile, type, data }, index) => (
        <>
          {index !== 0 && <Grid item xs={12}><Divider light /></Grid> }
          <Grid item xs={12}>
            <Grid container justify={'space-between'} alignItems={'center'}>
              <Grid item><Typography variant={'body1'}>{title}</Typography></Grid>
              <Grid item>
                <AdConfigElement
                  type={type}
                  data={data}
                  value={config[tile] || ''}
                  domain={domain}
                  onChange={value => setProp(configType, tile, value)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

AdConfigTab.propTypes = {
  classes: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  domain: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  setProp: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdConfigTab);
