import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockContainer from '../FieldLockContainer';
import { setLocalProp } from '../../../actions/dataState';
import { MEDIA_GALLERY, PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT } from '../../../constants/media/media';
import LocalCaption from '../../ui/LocalCaption';
import GallerySelector from '../../ui/media/GallerySelector';
import { makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';

const FieldLockGallery = (props) => {
  const {
    label,
    field,
    required,
    hasCaption,
    hasCopyright,
    children,
    gallery,
    lockValue,
    setLocalProp: setProp,
  } = props;
  const prop = Array.isArray(field) ? field.shift() : field;
  const propChain = Array.isArray(field) ? field : [];
  return (
    <FieldLockContainer field={field} lockValue={lockValue}>
      <GallerySelector
        gallery={gallery?.data || null}
        label={label}
        required={required}
        onSelect={(galleryData) => {
          const data = galleryData;
          if (hasCaption) {
            data[PROP_LOCAL_CAPTION] = data.caption ? `<p>${data.caption}</p>` : '';
          }
          if (hasCopyright) {
            data[PROP_LOCAL_COPYRIGHT] = data.copyright || '';
          }
          setProp(
            prop,
            {
              type: MEDIA_GALLERY,
              data,
            },
            propChain,
          );
        }}
        onRemove={() => setProp(
          prop,
          {},
          propChain,
        )}
      />
      {hasCaption && gallery?.data &&
        <LocalCaption
          data={gallery.data}
          hideLocalCopyright={!hasCopyright}
          onChange={([localProp, value]) => setProp(
            localProp,
            value,
            [...propChain, prop, 'data'],
          )}
        />
      }
      {children && children}
    </FieldLockContainer>
  );
};

FieldLockGallery.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  required: PropTypes.bool,
  hasCaption: PropTypes.bool,
  hasCopyright: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
  gallery: PropTypes.object,
  lockValue: PropTypes.object,
};

FieldLockGallery.defaultProps = {
  label: 'Add gallery',
  required: false,
  hasCaption: false,
  hasCopyright: false,
  children: null,
  gallery: null,
  lockValue: null,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  return {
    gallery: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockGallery);
