import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { getMfa, verifyMfa } from 'actions/login';

import TextField from 'components/ui/TextField';
import Button from 'components/ui/buttons/Button';

const List = withStyles(() => ({
  root: {
    padding: '0 0 0 1em',
    margin: 0,
  },
}))(({ classes, children }) => <ol className={classes.root}>{children}</ol>);

const MfaForm = () => {
  const { qr } = useSelector((state) => state.login);
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const handleSubmit= e => {
    e.preventDefault();
    dispatch(verifyMfa({ code }));
  };
  const handleChange = (e) => setCode(e.target.value);
  useEffect(() => {
    dispatch(getMfa());
  }, [dispatch]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="div">
            You need to set up two-factor authentication.<br/>
            Each time you log into the CMS you will be asked to provide a verification code.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <li>
              <Typography variant="subtitle1" component="div">
                Download the <strong>Google Authenticator</strong> app from your phone’s app store.
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: 130, marginTop: 10, marginBottom: 10 }}
                      alt="google play"
                      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                    />
                  </a>
                </Grid>
                <Grid item>
                  <a
                    href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: 130, marginTop: 9, marginBottom: 10 }}
                      alt="iOS App Store"
                      src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                    />
                  </a>
                </Grid>
              </Grid>
            </li>
            <li>
              <Typography variant="subtitle1" component="div">
                Open the <strong>Google Authenticator</strong> app, click the <strong>+</strong> icon and scan the QR code below.
              </Typography>
              <Grid container justifyContent="center">
                <Grid item>
                  {qr && <img
                    style={{ marginLeft: -15 }}
                    src={qr}
                    alt="QR Code"
                  />}
                </Grid>
              </Grid>
            </li>
            <li>
              <Typography variant="subtitle1" component="div">
                Enter the verification code from the <strong>Google Authenticator</strong> app.
              </Typography>
            </li>
          </List>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="code"
              name="code"
              label="Enter 6 digit code"
              onChange={handleChange}
              value={code}
              margin="none"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth>Enable</Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MfaForm;
