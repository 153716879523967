export const VOCAB_FETCH_LAYOUT = 'VOCAB_FETCH_LAYOUT';
export const VOCAB_FETCH_WORKFLOW_GENERIC = 'VOCAB_FETCH_WORKFLOW_GENERIC';
export const VOCAB_FETCH_LAYOUT_SUCCESS = 'VOCAB_FETCH_LAYOUT_SUCCESS';
export const VOCAB_FETCH_LAYOUT_REJECTED = 'VOCAB_FETCH_LAYOUT_REJECTED';
export const VOCAB_FETCH = 'VOCAB_FETCH';
export const VOCAB_CURRENT_TERM_DISPOSE = 'VOCAB_CURRENT_TERM_DISPOSE';
export const VOCAB_FETCH_TERM = 'VOCAB_FETCH_TERM';
export const VOCAB_FETCH_TERM_SUCCESS = 'VOCAB_FETCH_TERM_SUCCESS';
export const VOCAB_FETCH_TERM_REJECTED = 'VOCAB_FETCH_TERM_REJECTED';

export const VOCAB_SAVE = 'VOCAB_SAVE';
export const VOCAB_SAVE_SUCCESS = 'VOCAB_SAVE_SUCCESS';
export const VOCAB_SAVE_REJECTED = 'VOCAB_SAVE_REJECTED';
export const VOCAB_DELETE = 'VOCAB_DELETE';
export const VOCAB_DELETE_SUCCESS = 'VOCAB_DELETE_SUCCESS';
export const VOCAB_DELETE_REJECTED = 'VOCAB_DELETE_REJECTED';
export const VOCAB_UPDATE_MULTIPLE = 'VOCAB_UPDATE_MULTIPLE';
