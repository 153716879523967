import { setDefaults } from '@udecode/slate-plugins';
import renderElementOpta from './renderElementOpta';
import { DEFAULTS_OPTA } from './defaults';

const OptaPlugin = (options) => {
  const { opta } = setDefaults(options, DEFAULTS_OPTA);
  return ({
    renderElement: renderElementOpta(options),
    voidTypes: [opta.type],
  });
};

export default OptaPlugin;
