import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';

import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import PreviewIcon from '@material-ui/icons/Visibility';
import { PencilOff } from 'mdi-material-ui';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import {
  fetchGallery, disposeGallery, initGallery, setGalleryContext, resetGalleryContext, saveMediaEdit,
} from '../actions/media';

import { GalleryListBreadcrumb } from './GalleryList';
import { UNSAVE_CHANGES_PROMPT_TEXT } from '../constants/prompt';
import UploadBusyIndicator from '../components/media/UploadBusyIndicator';
import GallerySidebar from '../components/gallery/GallerySidebar';
import GalleryControlButtons from '../components/gallery/GalleryContolButtons';
import { MEDIA_ENTITY_ID } from '../constants/media/media';
import { FIELD_IMAGES, FIELD_IMAGES_ORDER } from '../constants/media/galleryFields';
import AvatarNumbered from '../components/ui/AvatarNumbered';
import ButtonGroup from '../components/ui/buttons/ButtonGroup';
import IconButton from '../components/ui/buttons/IconButton';
import TableSortable from '../components/ui/table/TableSortable';
import ThumbnailImage from '../components/table/content/ThumbnailImage';
import { CONTEXT_IMAGE } from '../constants/contexts';
import { sortLocalKeyed, removeLocalKeyed } from '../actions/dataState';
import PageBusyIndicator from '../components/common/page/PageBusyIndicator';
import ImageEditDialog from '../components/dialog/ImageEditDialog';
import { INSERT_PREPEND } from '../components/ui/media/ImageMultiSelector';

const GalleryCreate = ({
  localState,
  mid,
  images,
  imagesOrder,
  context,
  isBusy,
  initGallery: doInit,
  fetchGallery: doFetch,
  disposeGallery: doDispose,
  setGalleryContext: setContext,
  resetGalleryContext: resetContext,
  sortLocalKeyed: sortKeyed,
  removeLocalKeyed: removeKeyed,
  saveMediaEdit: saveMedia,
  match: { params: { id } },
}) => {
  const breadcrumb = [
    ...GalleryListBreadcrumb,
    {
      title: mid ? 'Edit gallery' : 'Create gallery',
      slug: mid || 'create',
    },
  ];
  const [previewImage, setPreviewImage] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [selected, setSelected] = useState(null);
  const [display, setDisplay] = useState(false);
  const [scrollTrigger, setScrollTrigger] = useState(-1);
  const scrollRef = useRef();
  useEffect(() => {
    doInit();
    doFetch(id);
    return () => {
      doDispose();
    };
  }, [doDispose, doFetch, doInit, id]);
  useEffect(() => {
    setHasChanges(Object.entries(localState).length > 0);
  }, [localState]);
  useEffect(() => {
    if (context?.type === CONTEXT_IMAGE && context?.data?.mid) {
      setSelected(context.data.mid);
    } else {
      setSelected(null);
    }
  }, [context]);
  useEffect(() => {
    if (scrollRef.current) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [scrollRef]);
  useEffect(() => {
    setScrollTrigger(-1);
  }, [scrollTrigger]);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <GalleryControlButtons />
      </PageToolbar>}
      scroll
    >
      {isBusy && <PageBusyIndicator disableInteraction message={'Saving...'} />}
      <UploadBusyIndicator />
      <PageContent scroll scrollRef={scrollRef}>
        <Prompt when={!!hasChanges && !isBusy} message={UNSAVE_CHANGES_PROMPT_TEXT} />
        <PageSection>
          {display && <TableSortable
            headers={['#', 'Image', 'Title', 'Caption', '']}
            rows={imagesOrder
              .map(imageId => images?.[imageId])
              .filter(image => !!image?.mid)
              .map((
                { mid: imageId, title, url, caption },
                index,
              ) => ([
                <AvatarNumbered key={`avatar-${index}`}>{index + 1}</AvatarNumbered>,
                <ThumbnailImage key={`thumbnail-${index}`} image={{ data: { url } }} />,
                title,
                caption,
                <ButtonGroup key={`actions-${index}`}>
                  <IconButton
                    onClick={() => {
                      if (window.confirm('Please confirm if you want to remove this image')) {
                        removeKeyed(FIELD_IMAGES, FIELD_IMAGES_ORDER, imageId);
                      }
                    }}
                  ><DeleteOutlined /></IconButton>
                  {selected !== imageId && <IconButton
                    onClick={() => setContext(CONTEXT_IMAGE, { mid: imageId })}
                  ><EditIcon /></IconButton>}
                  {selected === imageId && <IconButton
                    onClick={() => resetContext()}
                  ><PencilOff /></IconButton>}
                  <IconButton
                    onClick={() => setPreviewImage(imageId)}
                  >
                    <PreviewIcon />
                  </IconButton>
                </ButtonGroup>,
              ]))
            }
            selected={imagesOrder.indexOf(selected)}
            onReorder={([oldIndex, newIndex]) => sortKeyed(FIELD_IMAGES_ORDER, oldIndex, newIndex)}
            onClick={(e, i) => {
              const imageId = imagesOrder?.[i] || null;
              if (imageId && selected !== imageId) {
                setContext(CONTEXT_IMAGE, { mid: imageId });
              }
              if (imageId && selected === imageId) {
                resetContext();
              }
            }}
            hasActions
            enableSorting
            getContainer={() => scrollRef.current.container}
            scrollTrigger={scrollTrigger}
          />}
          {previewImage && <ImageEditDialog
            handleClose={() => {
              setPreviewImage(null);
            }}
            onSaveImage={(image) => {
              setPreviewImage(null);
              saveMedia(image);
            }}
            open={previewImage}
            mediaId={previewImage}
            canEdit
          />}
        </PageSection>
      </PageContent>
      <GallerySidebar onAdd={(position) => {
        if (position === INSERT_PREPEND) {
          setScrollTrigger(0);
        } else {
          setScrollTrigger(1);
        }
      }} />
    </Page>
  );
};

GalleryCreate.propTypes = {
  match: PropTypes.object.isRequired,
  initGallery: PropTypes.func.isRequired,
  fetchGallery: PropTypes.func.isRequired,
  disposeGallery: PropTypes.func.isRequired,
  setGalleryContext: PropTypes.func.isRequired,
  resetGalleryContext: PropTypes.func.isRequired,
  sortLocalKeyed: PropTypes.func.isRequired,
  removeLocalKeyed: PropTypes.func.isRequired,
  saveMediaEdit: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  localState: PropTypes.object,
  mid: PropTypes.number,
  images: PropTypes.object,
  imagesOrder: PropTypes.array,
  isBusy: PropTypes.bool,
};

GalleryCreate.defaultProps = {
  localState: {},
  mid: null,
  images: {},
  imagesOrder: [],
  isBusy: false,
};

export default connect(
  ({
    localState,
    dataState: {
      [MEDIA_ENTITY_ID]: mid,
      [FIELD_IMAGES]: images,
      [FIELD_IMAGES_ORDER]: imagesOrder,
    },
    gallery: { context, isBusy },
  }) => ({
    localState,
    mid,
    images,
    imagesOrder,
    context,
    isBusy,
  }),
  {
    initGallery,
    fetchGallery,
    disposeGallery,
    setGalleryContext,
    resetGalleryContext,
    sortLocalKeyed,
    removeLocalKeyed,
    saveMediaEdit,
  },
)(GalleryCreate);
