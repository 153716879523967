import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import AvatarNumbered from '../../ui/AvatarNumbered';

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const RelatedArticle = ({
  data, id: componentId,
}) => (
  <Grid container spacing={2} direction={'column'}>
    {data.links && data.links.length > 0 && <Grid item>
      {data.links.map(({ title, id: articleId }, index) => (
        <ListItem key={`related-${componentId}-${articleId}`} index={index}>
          <ListItemAvatar>
            <AvatarNumbered>{index + 1}</AvatarNumbered>
          </ListItemAvatar>
          <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={title} />
        </ListItem>
      ))}
    </Grid>}
  </Grid>
);

RelatedArticle.defaultProps = {
  data: null,
};

RelatedArticle.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default RelatedArticle;

