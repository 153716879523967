import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_NUMBERBOX } from './defaults';

const renderElementNumberbox = (options) => {
  const { numberbox } = setDefaults(options, DEFAULTS_NUMBERBOX);
  return getRenderElement(numberbox);
};

export default renderElementNumberbox;
