import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
  },
});

const VideoJWPlayer = ({
  classes,
  video,
}) => {
  const { mediaid, sources, mp4 } = video;
  if (!mediaid || (!sources && !mp4)) return <Typography variant="h6">Video JWPlayer id not found</Typography>;
  return (
    <div className={classes.root}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        width="100%"
        controls
        controlsList="nodownload"
      >
        {sources && sources.filter(({ width }) => width === 480).map(source =>
          <source key={source.file} src={source.file} type={source.type} />)}
        {!sources && <source src={mp4} type="video/mp4" />}
      </video>
    </div>
  );
};

VideoJWPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  video: PropTypes.object.isRequired,
};

VideoJWPlayer.defaultProps = {
  video: {
    mediaid: null,
  },
};

export default withStyles(styles)(VideoJWPlayer);

