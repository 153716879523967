import {
  EVENT_LIST_DISPOSE, EVENT_LIST_FETCH,
  EVENT_SAVE, EVENT_DELETE, EVENT_FETCH, EVENT_DISPOSE,
  EVENT_REQUEST_PREVIEW, EVENT_INIT,
} from '../constants/actionTypes/event';

export const fetchEvent = value => ({ type: EVENT_FETCH, value });
export const initEvent = () => ({ type: EVENT_INIT });
export const disposeEvent = () => ({ type: EVENT_DISPOSE });
export const saveEvent = value => ({ type: EVENT_SAVE, value });
export const deleteEvent = () => ({ type: EVENT_DELETE });
export const previewEvent = () => ({ type: EVENT_REQUEST_PREVIEW });

export const fetchEventList = value => ({ type: EVENT_LIST_FETCH, value });
export const disposeEventList = value => ({ type: EVENT_LIST_DISPOSE, value });
