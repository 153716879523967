import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import QuestionAnswerEditor from './articleContent/questionAnswerBuilder/QuestionAnswerEditor';
import ProductPanel from './articleContent/ProductPanel';
import MediaPanel from './articleContent/MediaPanel';
import LiveblogEditor from './articleContent/liveblogBuilder/LiveblogEditor';
import { checkArticleType, hasScoreboard } from '../helper/utils';
import {
  ARTICLE_TYPE_REGEX_FEATURE,
  ARTICLE_TYPE_REGEX_LIVEBLOG,
  ARTICLE_TYPE_REGEX_PRODUCT,
  ARTICLE_TYPE_REGEX_QA,
} from '../../constants/article/article';

import {
  REVISION_OFFLINE,
} from '../../constants/revision';

import ArticleTypeSelector from './articleContent/articleBuilder/ArticleTypeSelector';
import HeadlinePanel from './articleContent/HeadlinePanel';
import BreakingNewsToggle from './articleContent/articleBuilder/BreakingNewsToggle';
import TaxonomyPanelPrimary from './articleContent/TaxonomyPanelPrimary';
import TaxonomyPanelSecondary from './articleContent/TaxonomyPanelSecondary';
import BodyPanel from './articleContent/BodyPanel';
import ProductVerdictEditor from './articleContent/productPanel/ProductVerdict';
import ScoreBoard from './articleContent/scoreboard/ScoreBoard';

import NoticeBoard from '../notice/NoticeBoard';
import { PagePanel } from '../common/page/PagePanel';
import { FIELD_ARTICLE_TYPE, FIELD_SECTIONS, FIELD_VIDEO_PROVIDER } from '../../constants/article/articleFields';
import { getFromState } from '../../utils/stateHelper';
import { PLAYINC_PROVIDER } from '../integration/playInc/PlayIncVideoSelectorList';

const style = () => ({
  root: {
    position: 'relative',
  },
});

const ArticleMain = ({
  classes,
  dataState,
  revisionStatus,
}) => {
  const type = getFromState(FIELD_ARTICLE_TYPE, dataState);
  const sections = getFromState(FIELD_SECTIONS, dataState);
  const videoProvider = getFromState(FIELD_VIDEO_PROVIDER, dataState);
  const isPlayInc = videoProvider === PLAYINC_PROVIDER;
  const isType = checkArticleType(type);
  const isQa = isType(ARTICLE_TYPE_REGEX_QA);
  const isProduct = isType(ARTICLE_TYPE_REGEX_PRODUCT);
  const isLiveblog = isType(ARTICLE_TYPE_REGEX_LIVEBLOG);
  const isFeature = isType(ARTICLE_TYPE_REGEX_FEATURE);
  const notices = [];
  if (revisionStatus === REVISION_OFFLINE) {
    notices.push('Offline Revision');
  }
  return (
    <Grid container className={classes.root} direction={'column'} wrap={'nowrap'} spacing={4}>
      {(notices.length > 0) &&
        <NoticeBoard
          legal={''}
          notices={notices.length > 0 ? [{ notice: notices.join(' | ') }] : []}
        />
      }
      <Grid item xs={12}>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={4}>
            <ArticleTypeSelector disabled={isPlayInc} />
          </Grid>
          <BreakingNewsToggle />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <HeadlinePanel isProduct={isProduct} isFeature={isFeature} />
      </Grid>
      <Grid item xs={12}>
        <TaxonomyPanelPrimary />
      </Grid>
      {hasScoreboard(type, sections?.[0] || null) &&
        <Grid item xs={12}>
          <ScoreBoard />
        </Grid>
      }
      <Grid item xs={12}>
        <MediaPanel />
      </Grid>
      <Grid item xs={12}>
        <BodyPanel  isProduct={isProduct}/>
      </Grid>
      <Grid item xs={12}>
        <TaxonomyPanelSecondary />
      </Grid>
      {isQa && <Grid item xs={12}><PagePanel><QuestionAnswerEditor /></PagePanel></Grid>}
      {isLiveblog && <Grid item xs={12}><LiveblogEditor /></Grid>}
      {isProduct && <>
        <Grid item xs={12}><ProductPanel /></Grid>
        <Grid item xs={12}><ProductVerdictEditor /></Grid>
      </>}
    </Grid>
  );
};

ArticleMain.propTypes = {
  classes: PropTypes.object.isRequired,
  revisionStatus: PropTypes.number.isRequired,
  dataState: PropTypes.object,
};

ArticleMain.defaultProps = {
  dataState: null,
};

export default withStyles(style)(connect(
  ({ dataState,
    article: {
      revisionStatus,
    },
  }) => ({
    revisionStatus, dataState,
  }),
  {},
)(ArticleMain));
