import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: 25,
    },
  },
};

const svg = strokeColour => `
<svg width="111" height="27" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111 27"><g fill="none" fill-rule="evenodd"><path d="M0 25.51V1.61C0 1.08.43.64.94.64H17.1c.47 0 .87.4.87.88 0 .47-.4.88-.87.88H1.88v10.52h13.6c.47 0 .86.4.86.88s-.4.88-.86.88H1.88v10.82c0 .52-.43.96-.94.96A.96.96 0 0 1 0 25.5zm23.72-.14V1.45c0-.5.44-.95.94-.95.5 0 .94.44.94.95v23.11h14.14c.47 0 .87.4.87.88s-.4.88-.87.88H24.66a.96.96 0 0 1-.94-.95zm60.1.4l-8.46-23.8c-.07-.19-.11-.33-.11-.48 0-.51.47-.99.98-.99.5 0 .86.44 1 .88l7.71 22.12 7.2-22.27c.14-.4.4-.73.9-.73h.07c.47 0 .76.33.9.73l7.2 22.27 7.74-22.23c.18-.48.47-.77.9-.77.48 0 .95.48.95.95 0 .15-.08.33-.15.52l-8.46 23.8c-.18.48-.47.84-.98.84h-.07c-.5 0-.83-.36-.98-.84L93 4.2l-7.16 21.57c-.14.48-.47.84-.97.84h-.07c-.51 0-.8-.33-.98-.84zM58.28 0c7.67 0 12.7 6.2 12.7 13.2v.08c0 7-5.1 13.27-12.77 13.27-7.66 0-12.69-6.2-12.69-13.2v-.07c0-7 5.1-13.28 12.76-13.28zm-.07 1.72c-6.25 0-10.74 5.14-10.74 11.48v.08c0 6.34 4.56 11.55 10.81 11.55 6.26 0 10.74-5.13 10.74-11.48v-.07c0-6.35-4.55-11.56-10.8-11.56z" fill="${strokeColour}" fill-rule="nonzero"/><g stroke="#E7792B" stroke-linecap="round" stroke-width="2.12"><path d="M58.28 8.57v9.49M62.96 13.38H53.6"/></g></g></svg>
`;

const Logo = ({ classes, strokeColour }) => (<span className={`${classes.root} logo-wrapper`} dangerouslySetInnerHTML={{ __html: svg(strokeColour) }} />);

Logo.defaultProps = {
  strokeColour: grey[800],
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  strokeColour: PropTypes.string,
};

export default withStyles(styles)(Logo);
