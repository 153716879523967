import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  TITLE,
  STRAPLINE,
  HEADSHOTSX4,
  ARTICLEHERO,
  ARTICLEX1,
  ARTICLEX2,
  POSTER,
  TEXTSPLASH,
} from '../../../../constants/edition/editionComponents';

import Title from './components/Title';
import Strapline from './components/Strapline';
import HeadshotsX4 from './components/HeadshotsX4';
import ArticleX1 from './components/ArticleX1';
import ArticleX2 from './components/ArticleX2';
import Poster from './components/Poster';
import TextSplash from './components/TextSplash';
import ArticleHero from './components/ArticleHero';

import {
  setArticleEditionComponentProperty,
  mergeArticleEditionComponentProperty,
} from '../../../../actions/edition';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';

const getFormFromType = (type) => {
  switch (type) {
    case TITLE:
      return Title;
    case STRAPLINE:
      return Strapline;
    case HEADSHOTSX4:
      return HeadshotsX4;
    case ARTICLEX1:
      return ArticleX1;
    case ARTICLEX2:
      return ArticleX2;
    case POSTER:
      return Poster;
    case TEXTSPLASH:
      return TextSplash;
    case ARTICLEHERO:
      return ArticleHero;
    default:
      return null;
  }
};

const TemplateComponent = (props) => {
  const { component, setProperty, mergeProperty, delta } = props;
  const Form = getFormFromType(component.type);
  return (
    <ContextPanel data-type={'edition-component'}>
      <ContextPanelSummary>
        <ContextPanelHeading>
          {(Form && component.name) || `type: ${component.type} unknown`}
        </ContextPanelHeading>
      </ContextPanelSummary>
      {Form && <ContextPanelDetails variant={'full'}>
        <Form
          component={component}
          setProperty={setProperty}
          mergeProperty={mergeProperty}
          delta={delta}
        />
      </ContextPanelDetails>}
    </ContextPanel>
  );
};

TemplateComponent.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  mergeProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

const TemplateContents = (props) => {
  const {
    body,
    setArticleEditionComponentProperty: setProperty,
    mergeArticleEditionComponentProperty: mergeProperty,
  } = props;
  return body
    .filter(({ type }) => (typeof type !== 'undefined'))
    .map((component, index) => (
      <TemplateComponent
        key={`component-${index}`}
        component={component}
        mergeProperty={mergeProperty}
        setProperty={setProperty}
        delta={index}
      />
    ));
};

TemplateContents.propTypes = {
  body: PropTypes.array,
  setArticleEditionComponentProperty: PropTypes.func.isRequired,
  mergeArticleEditionComponentProperty: PropTypes.func.isRequired,
};

TemplateContents.defaultProps = {
  body: [],
};

export default connect(
  ({ edition: { body } }) => ({ body }),
  {
    setArticleEditionComponentProperty,
    mergeArticleEditionComponentProperty,
  },
)(TemplateContents);
