import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_VIDEO } from './defaults';

const renderElementVideo = (options) => {
  const { video } = setDefaults(options, DEFAULTS_VIDEO);
  return getRenderElement(video);
};

export default renderElementVideo;
