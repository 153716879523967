import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  PLAYINC_FETCH_VIDEOS,
  PLAYINC_FETCH_VIDEOS_SUCCESS,
  PLAYINC_FETCH_VIDEOS_FAILURE,
  PLAYINC_FETCH_PLAYLISTS,
  PLAYINC_FETCH_PLAYLISTS_SUCCESS,
  PLAYINC_FETCH_PLAYLISTS_FAILURE,
  PLAYINC_SAVE_VIDEO,
  PLAYINC_SAVE_VIDEO_SUCCESS,
  PLAYINC_SAVE_VIDEO_FAILURE,
} from '../../constants/actionTypes/integration/playInc';

import apiCatchError from '../helper/notification';
import { queryStringify } from '../../utils/urlHelper';

export const fetchPlayIncVideos = (action$, state$) => action$.pipe(
  ofType(PLAYINC_FETCH_VIDEOS),
  withLatestFrom(state$),
  switchMap(([
    { value }, { frame: { selectedPublication: { publicationConfig: { playInc } } } },
  ]) => {
    const { endpoint, domain, bearerToken } = playInc;
    return ajax.get(`${endpoint}/api/${domain}/videos?${queryStringify(value)}`, {
      Authorization: `Bearer ${bearerToken}`,
    }).pipe(
      map(({ response }) => ({
        type: PLAYINC_FETCH_VIDEOS_SUCCESS,
        value: response,
      })),
      apiCatchError(PLAYINC_FETCH_VIDEOS_FAILURE),
    );
  }),
);

export const fetchPlayIncPlaylists = (action$, state$) => action$.pipe(
  ofType(PLAYINC_FETCH_PLAYLISTS),
  withLatestFrom(state$),
  switchMap(([
    , { frame: { selectedPublication: { publicationConfig: { playInc } } } },
  ]) => {
    const { endpoint, domain, bearerToken } = playInc;
    return ajax.get(`${endpoint}/api/${domain}/playlists?show_hidden=true`, {
      Authorization: `Bearer ${bearerToken}`,
    }).pipe(
      map(({ response }) => ({
        type: PLAYINC_FETCH_PLAYLISTS_SUCCESS,
        value: response,
      })),
      apiCatchError(PLAYINC_FETCH_PLAYLISTS_FAILURE),
    );
  }),
);

const getPayload = (data) => {
  const payload = {};
  if (data?.title) payload.title = data.title;
  if (data?.body) payload.description = data.body;
  if (data?.authorName) payload.author = data.authorName;
  if (data?.authorHandle) payload.authorTwitterHandle = data.authorHandle;
  if (data?.sectionName) payload.sectionName = data.sectionName;
  if (data?.sectionPath) payload.sectionPath = data.sectionPath;
  if (data?.keywords) payload.keywords = data.keywords;
  if (data?.socialHeadline) payload.socialHeadline = data.socialHeadline;
  if (data?.metaTitle) payload.metaTitle = data.metaTitle;
  if (data?.url) payload.url = data.url;
  if (data?.playlist?.id) {
    payload.primaryPlaylistId = data.playlist.id;
  }
  if (data?.otherPlaylists && data.otherPlaylists.length > 0) {
    payload.secondaryPlaylistIds = data.otherPlaylists.map(({ id }) => id).join(',');
  }
  payload.sendToSocial = !!data.social;
  payload.excludeFromSyndication = !!data.synd;
  if (data?.status) payload.status = data.status;
  return payload;
};

export const savePlayIncVideo = (action$, state$) => action$.pipe(
  ofType(PLAYINC_SAVE_VIDEO),
  withLatestFrom(state$),
  switchMap(([
    { value: { data, id } }, { frame: { selectedPublication: { publicationConfig: { playInc } } } },
  ]) => {
    const { endpoint, domain, bearerToken } = playInc;
    return ajax.put(`${endpoint}/api/${domain}/video/${id}`, getPayload(data), {
      Authorization: `Bearer ${bearerToken}`,
    }).pipe(
      map(({ response }) => ({
        type: PLAYINC_SAVE_VIDEO_SUCCESS,
        value: response,
      })),
      apiCatchError(PLAYINC_SAVE_VIDEO_FAILURE),
    );
  }),
);
