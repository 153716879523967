/* global instgrm */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import TextField from '../../ui/builder/TextField';

import { VIDEO_DM, VIDEO_YOUTUBE, VIDEO_YOUTUBE_LEGACY } from '../../../constants/media/media';
import { FACEBOOK, IFRAME, INSTAGRAM, TWITTER } from '../../../constants/builder/builder';

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center',
    width: '100%',
  },
  attributeContainer: {
    marginTop: theme.spacing(1),
  },
});

const ExternalEmbed = ({
  classes, data, type, addMoreMarkup, position, onFocus, onBlur, saveMarkupData, bodyMarkupFocus,
  removeArticleComponent, onChange,
}) => {
  const [readyToRemoveTheComponent, setReadyToRemoveTheComponent] = useState(false);
  const [valid, setValid] = useState(true);
  const focus = bodyMarkupFocus === position;

  let $input;

  useEffect(() => {
    if (focus && $input) $input.focus();
    return () => {};
  }, []);

  useEffect(() => {
    if (data.type === INSTAGRAM && data.html) {
      // TODO: make preview on editor works?
      // instgrm && instgrm.Embeds.process()
    }
    return () => {};
  }, [data.type, data.html]);

  let pattern;
  switch (type) {
    case VIDEO_DM:
      pattern = /^https?:\/\//;
      break;
    case INSTAGRAM:
      pattern = /\/p\/([a-zA-Z0-9]+)/;
      break;
    case VIDEO_YOUTUBE_LEGACY:
    case VIDEO_YOUTUBE:
      pattern = /^https?:\/\/(www.)?youtu/;
      break;
    case IFRAME:
      pattern = /^https?:\/\//;
      break;
    case TWITTER:
      pattern = /status\/([0-9]+)/;
      break;
    case FACEBOOK:
      pattern = /posts|videos\/([0-9]+)/;
      break;
    default:
      pattern = '';
  }

  return (<>
    <FormGroup className={classes.root}>
      <TextField
        inputRef={input => ($input = input)}
        defaultValue={data.url}
        onChange={(e) => {
          const isValid = pattern.test(e.target.value);
          if (isValid) {
            onChange(e.target.value);
          }
          // convert blockquote embed to url status
          if (type === TWITTER && /<blockquote/.test(e.target.value)) {
            const url = e.target.value.match(/https:\/\/twitter\.com.+\/status\/\d+/);
            $input.value = url[0];
            onChange(url[0]);
          }
          setValid(isValid);
        }}
        error={!valid}
        onKeyUp={(e) => {
          switch (e.keyCode) {
            case 8:
              if (readyToRemoveTheComponent) {
                removeArticleComponent(position);
              }
              setReadyToRemoveTheComponent(e.target.value === '');
              break;
            case 13: {
              addMoreMarkup(position);
              break;
            }
            default:
              // noop
          }
        }}
        placeholder={`Paste ${type} url here`}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {type === TWITTER &&
        <FormControlLabel
          control={
            <Checkbox
              checked={data.isConversation}
              onChange={e => saveMarkupData([{ isConversation: e.target.checked }, position])}
            />
          }
          label="include parent tweet"
        />
      }
    </FormGroup>
    {type === IFRAME &&
      <Grid container spacing={2} className={classes.attributeContainer}>
        <Grid item xs={2}>
          <TextField
            label="width"
            value={data.width}
            onChange={e => saveMarkupData([{ width: e.target.value }, position])}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="height"
            value={data.height}
            onChange={e => saveMarkupData([{ height: e.target.value }, position])}
          />
        </Grid>
      </Grid>
    }
  </>);
};

ExternalEmbed.defaultProps = {
  data: { twitter: '' },
  bodyMarkupFocus: null,
};

ExternalEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  addMoreMarkup: PropTypes.func.isRequired,
  saveMarkupData: PropTypes.func.isRequired,
  data: PropTypes.object,
  position: PropTypes.number.isRequired,
  bodyMarkupFocus: PropTypes.number,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  removeArticleComponent: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(ExternalEmbed);

