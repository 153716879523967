import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import withScrolling from 'react-dnd-scrolling';

import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import capitalize from '@material-ui/core/utils/capitalize';
import UndoIcon from '@material-ui/icons/Undo';

import {
  onLayoutSave,
  onLayoutPageEnter, onLayoutPageLeave,
  dockLayoutPreview, undockLayoutPreview,
} from '../actions/layout';

import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_HIDDEN } from '../constants/preview';

import Page from './common/Frame';
import LayoutPreview from '../components/layout/preview/LayoutPreview';
import LayoutPreviewWindow from '../components/layout/preview/LayoutPreviewWindow';
import PageHeadingToolbar, { PageToolbarButtonsStatic } from '../components/common/page/PageToolbar';
import PreviewUI, { getPreviewClass } from '../components/layout/preview/LayoutPreviewUI';
import LayoutContext from '../components/layout/LayoutContext';
import LockButton from '../components/layout/lock/LockButton';
import Button from '../components/ui/buttons/Button';
import { UNSAVE_CHANGES_PROMPT_TEXT } from '../constants/prompt';
import UploadBusyIndicator from '../components/media/UploadBusyIndicator';
import { FIELD_TERM } from '../constants/layout/layoutFields';
import LayoutComponents from '../components/layout/LayoutComponents';
import { disposeLocal } from '../actions/dataState';
import PageBusyIndicator from '../components/common/page/PageBusyIndicator';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainWrapper: {
    flexGrow: 1,
  },
  mainContainer: {
    display: 'flex',
    height: 'calc(100vh - 61px)',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '.preview-mode-mobile &': {
      flexDirection: 'row-reverse',
    },
    '.preview-undocked &': {
      flexDirection: 'column',
    },
    '.preview-mode-desktop.preview-hidden &': {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('xxl')]: {
      flexDirection: 'row-reverse',
      '.preview-mode-desktop.preview-hidden &': {
        flexDirection: 'row-reverse',
      },
    },
  },
  componentContainer: {
    flex: 1,
    overflowY: 'scroll',
    padding: theme.spacing(2),
    [theme.breakpoints.up('xxl')]: {
      padding: 0,
    },
    '& > div': {
      maxWidth: 968,
      margin: '0 auto',
    },
  },
  previewContainer: {
    flex: 1.5,
    borderTop: `0.5px solid ${theme.palette.border.secondary.main}`,
    overflow: 'hidden',
    '.preview-undocked &': {
      flex: 0,
      flexBasis: '48px',
      borderTop: `0.5px solid ${theme.palette.border.secondary.main}`,
      borderRight: '0 none',
    },
    '.preview-mode-desktop.preview-hidden &': {
      flex: 0,
      flexBasis: '48px',
      borderTop: `0.5px solid ${theme.palette.border.secondary.main}`,
      borderRight: '0 none',
    },
    '.preview-mode-mobile &': {
      flex: 0,
      flexBasis: '375px',
      borderTop: '0 none',
      borderRight: `0.5px solid ${theme.palette.border.secondary.main}`,
    },
    [theme.breakpoints.up('xxl')]: {
      flex: 0,
      flexBasis: '1000px',
      borderTop: '0 none',
      borderRight: `0.5px solid ${theme.palette.border.secondary.main}`,
      '.preview-mode-desktop.preview-hidden &': {
        flex: 0,
        flexBasis: '1000px',
        borderTop: '0 none',
        borderRight: `0.5px solid ${theme.palette.border.secondary.main}`,
      },
    },
  },
  preview: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& iframe': {
      flex: 1,
    },
  },
  previewPopout: {
    '& iframe': {
      height: '100vh',
    },
  },
  previewHidden: {
    visibility: 'hidden',
    [theme.breakpoints.up('xxl')]: {
      visibility: 'visible',
    },
  },
});

const ScrollWrapper = withScrolling('div');

const LayoutManager = ({
  classes,
  dataState,
  localState,
  selectedManualList,
  selectedTerm,
  error,
  isBusy,
  onLayoutSave: saveLayout,
  onLayoutPageEnter: onEnterPage,
  onLayoutPageLeave: onLeavePage,
  previewDocked, previewHidden, previewMode,
  dockLayoutPreview: dockPreview,
  undockLayoutPreview: undockPreview,
  match: { params: { term, id } },
  layoutContext,
  push: goto,
  disposeLocal: discardChanges,
}) => {
  const [componentFocus, setComponentFocus] = useState(-1);
  const [componentHighlight, setComponentHighlight] = useState(-1);
  const [previewFocus, setPreviewFocus] = useState(-1);
  const [previewHighlight, setPreviewHighlight] = useState(-1);
  const [previewHydrated, setPreviewHydrated] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);
  const [sectionTitle, setSectionTitle] = useState('New');

  const handleMessage = (messageEvent) => {
    if (messageEvent.data && !!messageEvent.data.event) {
      const { data: { event, target } } = messageEvent;
      switch (event) {
        case 'hydrated':
          setPreviewHydrated(true);
          break;
        case 'hover':
          if (typeof target !== 'undefined') {
            setComponentHighlight(target);
          }
          break;
        case 'click':
          if (typeof target !== 'undefined') {
            setComponentFocus(target);
          }
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (id === 'new' && !selectedTerm) {
      goto(`/layout/${term}`);
    }
    onEnterPage(term, id);
    window.addEventListener('message', handleMessage);
    return () => {
      onLeavePage();
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  // @todo refactor only require FIELD_TERM
  useEffect(() => {
    const layoutTerm = dataState[FIELD_TERM];
    if (layoutTerm?.name) {
      setSectionTitle(layoutTerm.name);
    } else {
      setSectionTitle('New');
    }
  }, [dataState[FIELD_TERM]]);
  // @todo refactor to use selector
  useEffect(() => {
    setHasChanges(Object.entries(localState).length > 0);
  }, [localState]);

  const breadcrumb = [
    {
      title: 'Layout Manager',
      slug: 'layout',
    },
    {
      title: capitalize(term),
      slug: term,
    },
    {
      title: sectionTitle,
      slug: id,
    },
  ];

  const previewClasses = [classes.previewContainer];
  if (
    (previewHidden && previewMode === PREVIEW_MODE_DESKTOP) ||
    previewMode === PREVIEW_MODE_HIDDEN
  ) {
    previewClasses.push(classes.previewHidden);
  }

  const popoutToggle = () => (previewDocked ? undockPreview() : dockPreview());

  return (
    <Page
      fullWidth
      toolbarContent={
        <PageHeadingToolbar breadcrumb={breadcrumb}>
          <Grid container justify={'center'} alignItems={'center'}>
            <PreviewUI />
          </Grid>
          <PageToolbarButtonsStatic>
            <LockButton />
            <Button
              onClick={popoutToggle}
              startIcon={previewDocked ? <OpenInBrowserIcon /> : <CallToActionIcon />}
            >Preview</Button>
            {hasChanges && id !== 'new' && <Button
              onClick={() => { window.confirm('All your changes to this layout will be removed') && discardChanges(); }}
              startIcon={<UndoIcon />}
            >Discard changes</Button>}
            <Button
              disabled={error.length > 0 || (!hasChanges && id !== 'new')}
              variant={'contained'}
              onClick={saveLayout}
              startIcon={<SaveIcon />}
            >Save layout</Button>
          </PageToolbarButtonsStatic>
        </PageHeadingToolbar>
      }
    >
      {isBusy && <PageBusyIndicator disableInteraction message={'Saving...'} />}
      <UploadBusyIndicator />
      <Prompt when={!!hasChanges && !isBusy} message={UNSAVE_CHANGES_PROMPT_TEXT} />
      {error.length > 0 && error.map(message => (
        <Typography key={`${message}`} variant={'body2'} color={'error'}>{message}</Typography>
      ))}
      <div className={`${classes.root} ${getPreviewClass(previewMode)} ${(previewDocked) ? 'preview-docked' : 'preview-undocked'} ${(previewHidden) ? 'preview-hidden' : ''}`}>
        <div className={classes.mainWrapper}>
          <div className={classes.mainContainer}>
            <ScrollWrapper className={classes.componentContainer} data-type={'layout-components'}>
              <LayoutComponents
                focusComponent={setComponentFocus}
                focused={componentFocus}
                highlighted={componentHighlight}
                setPreviewFocus={setPreviewFocus}
                setPreviewHighlight={setPreviewHighlight}
              />
            </ScrollWrapper>
            <div className={previewClasses.join(' ')}>
              {previewDocked && <LayoutPreview
                className={classes.preview}
                focused={previewFocus}
                highlighted={previewHighlight}
                hydrated={previewHydrated}
                setHydrated={setPreviewHydrated}
              />}
              {!previewDocked && <LayoutPreviewWindow
                onComponentClick={setComponentFocus}
                onComponentHover={setComponentHighlight}
                focused={previewFocus}
                highlighted={previewHighlight}
              >
                <LayoutPreview
                  className={classes.previewPopout}
                  previewMode={'popout'}
                  focused={previewFocus}
                  highlighted={previewHighlight}
                  hydrated={previewHydrated}
                  setHydrated={setPreviewHydrated}
                />
              </LayoutPreviewWindow>}
            </div>
          </div>
        </div>
        <LayoutContext
          selectedManualList={selectedManualList}
          context={layoutContext}
        />
      </div>
    </Page>
  );
};

LayoutManager.propTypes = {
  classes: PropTypes.object.isRequired,
  onLayoutSave: PropTypes.func.isRequired,
  onLayoutPageEnter: PropTypes.func.isRequired,
  onLayoutPageLeave: PropTypes.func.isRequired,
  selectedManualList: PropTypes.number.isRequired,
  previewDocked: PropTypes.bool.isRequired,
  previewHidden: PropTypes.bool.isRequired,
  previewMode: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  dockLayoutPreview: PropTypes.func.isRequired,
  undockLayoutPreview: PropTypes.func.isRequired,
  layoutContext: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  disposeLocal: PropTypes.func.isRequired,
  dataState: PropTypes.object,
  localState: PropTypes.object,
  error: PropTypes.array,
  selectedTerm: PropTypes.object,
  isBusy: PropTypes.bool,
};

LayoutManager.defaultProps = {
  dataState: {},
  localState: {},
  error: [],
  selectedTerm: null,
  isBusy: false,
};

export default withStyles(styles)(connect(
  ({
    localState,
    dataState,
    layout: {
      context: layoutContext,
      selectedManualList,
      selectedTerm,
      error,
      previewDocked,
      previewMode,
      previewHidden,
      isBusy,
    },
  }) => ({
    localState,
    dataState,
    layoutContext,
    selectedManualList,
    selectedTerm,
    error,
    previewDocked,
    previewMode,
    previewHidden,
    isBusy,
  }),
  {
    dockLayoutPreview,
    undockLayoutPreview,
    onLayoutSave,
    onLayoutPageEnter,
    onLayoutPageLeave,
    push,
    disposeLocal,
  },
)(LayoutManager));
