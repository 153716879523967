import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  FIELD_ARTICLE_TYPE,
  FIELD_LOCATION_ADDRESS,
  FIELD_LOCATION_LINK,
} from '../../../constants/article/articleFields';
import { DOMAIN_ES } from '../../../constants/domainConfig';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import FieldLockTextfield from '../../fieldLock/form/FieldLockTextfield';

// @todo move domain logic into domain config
const ArticleLocation = (props) => {
  const {
    domain, articleType,
  } = props;
  if (
    domain !== DOMAIN_ES ||
    (articleType && !/review/i.test(articleType.name))
  ) {
    return null;
  }
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Location</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <FieldLockTextfield field={FIELD_LOCATION_ADDRESS} label={'Address'} />
        <FieldLockTextfield field={FIELD_LOCATION_LINK} label={'Link'} />
      </ContextPanelDetails>
    </ContextPanel>);
};

ArticleLocation.propTypes = {
  domain: PropTypes.string.isRequired,
  articleType: PropTypes.object,
};

ArticleLocation.defaultProps = {
  articleType: {},
};

export default connect(
  ({
    dataState: { [FIELD_ARTICLE_TYPE]: articleType },
    frame: { selectedPublication: { domain } },
  }) => ({ domain, articleType }),
  {},
)(ArticleLocation);
