import { PURGE } from 'redux-persist';
import { CONFIG_ENTITY_FETCH_SUCCESS } from 'constants/actionTypes/config';

const defaultState = {
  default: {},
  publication: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIG_ENTITY_FETCH_SUCCESS: {
      if (action?.value?.isDefault) {
        return {
          ...state,
          default: action?.value?.data || {},
        };
      }
      return {
        ...state,
        publication: action?.value?.data || {},
      };
    }

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
