import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Select from '../ui/Select';
import TextButton from '../ui/buttons/TextButton';

const ASPECT_RATIOS = {
  '4:3': 4 / 3,
  '3:4': 3 / 4,
  '3:2': 3 / 2,
  '2:3': 2 / 3,
  Free: 0,
};

const styles = () => ({
  select: {
    minWidth: 100,
  },
  ctrl: {
    display: 'flex',
    padding: '10px 0',
    '& > *': {
      // marginLeft: 5,
    },
  },
});

const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = Math.ceil(crop.width * scaleX);
  canvas.height = Math.ceil(crop.height * scaleY);
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    Math.ceil(crop.width * scaleX),
    Math.ceil(crop.height * scaleY),
    0,
    0,
    Math.ceil(crop.width * scaleX),
    Math.ceil(crop.height * scaleY),
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));
        return;
      }
      resolve({
        src: window.URL.createObjectURL(blob),
        file: new File([blob], fileName, { type: 'image/jpg' }),
      });
    }, 'image/jpeg', 1);
  });
};

const ImageCropper = (props) => {
  const { classes, src, onComplete, onReset, defaultCropRatio } = props;
  const INITIAL_CROP = {
    aspect: ASPECT_RATIOS[defaultCropRatio],
  };
  const [cropState, setCrop] = useState(INITIAL_CROP);
  const [image, setImage] = useState();
  const [cropImage, setCropImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(false);
  const [croppedFile, setCroppedFile] = useState(null);

  const regex = /\/([^/]*)\.[\w]*$/;
  const match = regex.exec(src);
  const fileName = match !== null ? `${match[1]}.jpg` : 'newFile.jpg';

  const makeClientCrop = async (crop) => {
    if (image && crop.width && crop.height && cropState.width && cropState.height) {
      try {
        const croppedImg = await getCroppedImg(
          image,
          cropState,
          fileName,
        );
        setCropImage(croppedImg.src);
        setCroppedFile(croppedImg.file);
      } catch (ex) {
        console.error(ex);
      }
    }
  };

  return (
    <>
      <div >
        <Alert severity="info">Click and drag over image to make crop selection</Alert>
      </div>
      <ReactCrop
        crossorigin={'anonymous'}
        crop={cropState}
        onChange={crop => setCrop(crop)}
        onImageLoaded={img => setImage(img)}
        onComplete={crop => makeClientCrop(crop)}
        src={croppedImage || src}
      />
      <Grid container justify={'space-between'} alignItems={'center'} spacing={2}>
        <Grid item xs={3}>
          <Select
            className={classes.select}
            label={'Aspect Ratio'}
            value={getKeyByValue(ASPECT_RATIOS, cropState.aspect)}
            items={Object.keys(ASPECT_RATIOS)}
            onChange={event => setCrop({
              ...cropState,
              aspect: ASPECT_RATIOS[event.target.value],
            })}
          />
        </Grid>
        <Grid item xs={9}>
          <div className={classes.ctrl}>
            {croppedImage && <TextButton
              onClick={() => {
                onReset();
                setCroppedImage(null);
              }}
            >Reset Crop</TextButton>}
            {croppedFile && cropState.width > 0 && cropState.height > 0 && <TextButton
              onClick={() => {
                setCroppedImage(cropImage);
                setCrop(INITIAL_CROP);
                onComplete(croppedFile);
              }}
            >Apply Crop</TextButton>}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

ImageCropper.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  defaultCropRatio: PropTypes.string,
  onComplete: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

ImageCropper.defaultProps = {
  defaultCropRatio: '4:3',
};

export default withStyles(styles)(ImageCropper);
