import { ELEMENT_VISUAL_STORY, PROP_STORIES, PROP_BUTTON_DISPLAY } from './defaults';
import { STORY } from '../../../../../../constants/editor/dataTypes';

export const convertToVisualStory = data => ({
  type: ELEMENT_VISUAL_STORY,
  [PROP_STORIES]: data[PROP_STORIES] || '',
  [PROP_BUTTON_DISPLAY]: data[PROP_BUTTON_DISPLAY] || false,
  children: [{ text: '' }],
});

export const convertFromVisualStory = data => ({
  type: STORY,
  data: {
    [PROP_STORIES]: data[PROP_STORIES] || '',
    [PROP_BUTTON_DISPLAY]: data[PROP_BUTTON_DISPLAY] || false,
  },
});
