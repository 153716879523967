export const REVISION_ON_SEARCH = 'REVISION_ON_SEARCH';
export const REVISION_ON_SEARCH_SUCCESS = 'REVISION_ON_SEARCH_SUCCESS';
export const REVISION_ON_SEARCH_REJECTED = 'REVISION_ON_SEARCH_REJECTED';
export const REVISION_ON_SELECT = 'REVISION_ON_SELECT';
export const REVISION_ON_REQUEST_PREVIEW = 'REVISION_ON_REQUEST_PREVIEW';
export const REVISION_OFFLINE_DELETE = 'REVISION_OFFLINE_DELETE';
export const REVISION_OFFLINE_DELETED = 'REVISION_OFFLINE_DELETED';
export const REVISION_OFFLINE_REJECTED = 'REVISION_OFFLINE_REJECTED';
export const REVISION_LIST_DISPOSE = 'REVISION_LIST_DISPOSE';
export const REVISION_COMPARE = 'REVISION_COMPARE';
export const UPDATE_OFFLINE_REVISION = 'UPDATE_OFFLINE_REVISION';
export const UPDATE_OFFLINE_REVISION_READY = 'UPDATE_OFFLINE_REVISION_READY';
export const UPDATE_OFFLINE_REVISION_SUCCESS = 'UPDATE_OFFLINE_REVISION_SUCCESS';
export const UPDATE_OFFLINE_REVISION_REJECTED = 'UPDATE_OFFLINE_REVISION_REJECTED';
export const REVISION_COMPARE_SUCCESS = 'REVISION_COMPARE_SUCCESS';
export const REVISION_COMPARE_REJECTED = 'REVISION_COMPARE_REJECTED';
export const REVISION_EDITION = 'collection';
export const REVISION_ARTICLE = 'node';

export const REVISION_NOT_PUBLISHED = 0;
export const REVISION_PUBLISHED = 1;
export const REVISION_OFFLINE = 2;
export const REVISION_SCHEDULED = 3;
