import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  EXTRA_TITLE, EXTRA_URL, TABOOLA_EXCLUDE_ARTICLES,
} from '../constants';

import {
  NewsLetterSignup,
  ReadMore,
  More,
  AuthorHighlight,
  Title,
  VideoShowcase,
  Voucher,
  Markup,
  HeroPlusMarkup,
  VisualStories,
  AD_COMPONENTS,
  Video,
  CHAIN_COMPONENTS,
  TrendingEditorsPickPlusDMPU,
  AboutSponsor,
  HeroPlusCarousel,
  VideoPlaylist,
  ImageGradient,
  CommentHighlight,
  HeroPlusImageAndVideo,
  iFrame,
  Media,
  Description,
  Services,
  Taboola,
  VideoHubPlaylist,
} from 'constants/components';

import { CONTEXT_ARTICLE_SELECTOR } from 'constants/contexts';
import { DOMAIN_ES, DOMAIN_INDY } from 'constants/domainConfig';
import { FIELD_COMPONENTS_ORDER } from 'constants/layout/layoutFields';

import {
  setLayoutContextTarget,
  setLayoutContext,
  componentSetProp,
} from 'actions/layout';

import { ContextBarBody } from 'components/common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from 'components/common/context/ContextPanel';
import ToggleSwitch from 'components/ui/ToggleSwitch';

import { makeGetComponentFromContext } from 'selectors/layout/layout';
import {
  makeGetManualIndexesFromContext,
  makeGetManualListFromContext,
} from 'selectors/manualList/manualList';

import { getArticleCountFromType } from 'utils/layoutHelper';

import LabelField from './componentProperties/LabelField';
import NewsletterOption from './componentProperties/NewsletterOptions';
import VoucherSetting from './componentProperties/VoucherSettings';
import LinkField from './componentProperties/LinkField';
import ArticlesOverrides from './componentProperties/ArticlesOverrides';
import AuthorOptions from './componentProperties/AuthorOptions';
import CommercialBadgeOptions from './componentProperties/CommercialBadgeOptions';
import VideoField from './componentProperties/VideoField';
import MarkupSettings from './componentProperties/MarkupSettings';
import AdSettings from './componentProperties/AdSettings';
import VisualStoriesOptions from './componentProperties/VisualStoriesOptions';
import TVPlaylistOptions from './componentProperties/TVPlaylistOptions';
import ChainSettings from './componentProperties/ChainSettings';
import EditorsPickOptions from './componentProperties/EditorsPickOptions';
import PlayIncComponentSettings from '../../integration/playInc/PlayIncComponentSettings';
import IFrameSettings from './componentProperties/IFrameSettings';
import CarouselField from './componentProperties/CarouselField';
import VideoPlaylistSettings from './componentProperties/VideoPlaylistSettings';
import ImageGradientSettings from './componentProperties/ImageGradientSettings';
import CommentHighlightSettings from './componentProperties/CommentHighlightSettings';
import MediaSettings from './componentProperties/MediaSettings';
import HeroPlusImageAndVideoSettings from './componentProperties/HeroPlusImageAndVideoSettings';
import ServicesOptions from './componentProperties/ServicesOptions';
import DescriptionSettings from './componentProperties/DescriptionSettings';
import TaboolaOptions from './componentProperties/TaboolaOptions';

// @todo review and replace with generic inputs
const ContextFields = (props) => {
  const {
    component, manualList, manualIndexes, data,
    onChangeProperty, domain, publicationConfig,
  } = props;
  const fields = [];
  switch (component.type) {
    case Title:
      if (domain === DOMAIN_ES) {
        fields.push(<LinkField
          title={'Extra'}
          key={'extra'}
          component={component}
          titleField={EXTRA_TITLE}
          urlField={EXTRA_URL}
        />);
      }
      fields.push(<CommercialBadgeOptions
        key={'commercialBadge'}
        component={component}
      />);
      break;
    case VideoHubPlaylist:
    case AboutSponsor:
      fields.push(<CommercialBadgeOptions
        key={'commercialBadge'}
        component={component}
      />);
      break;
    case VisualStories:
      fields.push(<VisualStoriesOptions
        key={'visualStories'}
        component={component}
      />);
      break;
    case Voucher:
      fields.push(<VoucherSetting
        key={'voucher'}
        component={component}
      />);
      break;
    case Video:
      if (publicationConfig?.playInc?.endpoint && publicationConfig?.playInc?.domain) {
        fields.push(<PlayIncComponentSettings
          key={'playinc'}
          component={component}
        />);
      } else {
        fields.push(<TVPlaylistOptions
          key={'tv'}
          component={component}
        />);
      }
      break;
    case NewsLetterSignup:
      fields.push(<NewsletterOption
        key={'newsletter'}
        component={component}
      />);
      break;
    case ReadMore:
    case More:
      fields.push(<LinkField
        title={'Read more link'}
        key={'readmoreLink'}
        component={component}
      />);
      break;
    case AuthorHighlight:
      fields.push(<AuthorOptions
        key={'authorOptions'}
        component={component}
      />);
      break;
    case VideoShowcase:
      fields.push(<VideoField
        key={'video'}
        component={component}
      />);
      break;
    case Media:
      fields.push(<MediaSettings
        key={'media'}
        component={component}
      />);
      break;
    case HeroPlusMarkup:
    case Markup:
      fields.push(<MarkupSettings
        title={'Markup'}
        key={'markup'}
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    case HeroPlusCarousel:
      fields.push(<CarouselField
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    case CommentHighlight:
      fields.push(<CommentHighlightSettings
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    case HeroPlusImageAndVideo:
      fields.push(<HeroPlusImageAndVideoSettings
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    case VideoPlaylist:
      fields.push(<VideoPlaylistSettings
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    case ImageGradient:
      fields.push(<ImageGradientSettings component={component} />);
      break;
    case iFrame:
      fields.push(<IFrameSettings component={component} />);
      break;
    case Services:
      fields.push(<ServicesOptions component={component} />);
      break;
    case Description:
      fields.push(<DescriptionSettings
        component={component}
        componentPropOnChange={onChangeProperty}
      />);
      break;
    case Taboola:
      fields.push(<TaboolaOptions component={component} />);
      break;
    default:
      break;
  }
  switch (true) {
    case CHAIN_COMPONENTS.includes(component.type):
      fields.push(<ChainSettings
        key={'chain'}
        component={component}
      />);
      break;
    default:
      break;
  }
  if (
    component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION]
  ) {
    fields.push(<ArticlesOverrides
      key={component.id}
      component={component}
      manualList={manualList}
      manualIndexes={manualIndexes}
      data={data}
    />);
  }
  return fields;
};

const ContextProperties = (props) => {
  const { component, onChangeProperty, domain } = props;
  const articleCount = getArticleCountFromType(component.type);
  const properties = [];
  if (AD_COMPONENTS.includes(component.type)) {
    properties.push(<AdSettings
      onChangeProperty={onChangeProperty}
      key={'adSettings'}
      component={component}
    />);
  }
  if (component.type === TrendingEditorsPickPlusDMPU) {
    properties.push(<EditorsPickOptions
      key={component.type}
      component={component}
    />);
  }
  if (domain === DOMAIN_INDY) {
    if (articleCount > 0) {
      properties.push(<ToggleSwitch
        key={'taboolaToggle'}
        label={'Enable Taboola Exclusion'}
        value={component?.[TABOOLA_EXCLUDE_ARTICLES] || false}
        onChange={e => onChangeProperty(component.id, TABOOLA_EXCLUDE_ARTICLES, e.target.checked)}
      />);
    }
  }
  if (domain === DOMAIN_INDY) {
    if (articleCount > 0) {
      properties.push(<ToggleSwitch
        key={'hideComponentFromWeb'}
        label={'Hide from web'}
        value={component?.['hideComponentFromWeb'] || false}
        onChange={e => onChangeProperty(component.id, 'hideComponentFromWeb', e.target.checked)}
      />);
    }
  }
  return properties;
};

const ComponentProperties = (props) => {
  const {
    data,
    component,
    manualList,
    manualIndexes,
    externalComponents,
    domain,
    publicationConfig,
    setLayoutContextTarget: setTarget,
    setLayoutContext: setContext,
    componentSetProp: setProp,
  } = props;
  const { targetIndex } = data;

  // @todo move to selector
  useEffect(() => {
    if (externalComponents.includes(data.id)) {
      setContext(CONTEXT_ARTICLE_SELECTOR);
    }
  }, [data.id, externalComponents, setContext]);

  useEffect(() => {
    if (typeof targetIndex === 'undefined') {
      const container = document.querySelector('#contextBody');
      if (container) {
        container.scrollTop = 0;
      }
      setTarget(-1);
    }
  }, [setTarget, targetIndex]);

  if (!component) {
    return null;
  }

  return (
    <ContextBarBody id={'contextBody'}>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Component Properties</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          <LabelField onChangeProperty={setProp} component={component} />
          <ContextProperties
            onChangeProperty={setProp}
            component={component}
            domain={domain}
          />
        </ContextPanelDetails>
      </ContextPanel>
      <ContextFields
        onChangeProperty={setProp}
        component={component}
        manualList={manualList}
        manualIndexes={manualIndexes}
        data={data}
        domain={domain}
        publicationConfig={publicationConfig}
      />
    </ContextBarBody>
  );
};

ComponentProperties.propTypes = {
  data: PropTypes.object.isRequired,
  setLayoutContextTarget: PropTypes.func.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  publicationConfig: PropTypes.object,
  component: PropTypes.object,
  manualList: PropTypes.object,
  manualIndexes: PropTypes.array,
  externalComponents: PropTypes.array,
};

ComponentProperties.defaultProps = {
  component: {},
  manualList: {},
  manualIndexes: null,
  publicationConfig: {},
  externalComponents: [],
};

const mapStateToProps = (state, props) => {
  const getComponentFromContext = makeGetComponentFromContext();
  const getManualListFromContext = makeGetManualListFromContext();
  const getManualIndexesFromContext = makeGetManualIndexesFromContext();
  return {
    component: getComponentFromContext(state),
    manualList: getManualListFromContext(state, props),
    manualIndexes: getManualIndexesFromContext(state, props),
    externalComponents: state.externalState[FIELD_COMPONENTS_ORDER],
    data: state.layout.context.data,
    selectedComponents: state.layout.selectedComponents,
    domain: state.frame.selectedPublication.domain,
    publicationConfig: state.frame.selectedPublication.publicationConfig,
  };
};

export default connect(
  mapStateToProps,
  { setLayoutContextTarget, setLayoutContext, componentSetProp },
)(ComponentProperties);
