import { ofType } from 'redux-observable';
import { filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';

import {
  GALLERY_FETCH,
  GALLERY_FETCH_REJECTED,
  GALLERY_FETCH_SUCCESS,
  GALLERY_SAVE, GALLERY_SAVE_READY,
  GALLERY_SAVE_REJECTED,
  GALLERY_SAVE_SUCCESS,
} from '../../constants/actionTypes/media';
import apiCatchError, { assignErrorPayload, showSuccessNotification } from '../helper/notification';
import GalleryEntity from '../../entities/media/GalleryEntity';
import { MEDIA_ENTITY_ID } from '../../constants/media/media';
import { DATASTATE_LOCAL_DISPOSE } from '../../constants/actionTypes/dataState';

export const fetchGallery = action$ => action$.pipe(
  ofType(GALLERY_FETCH),
  filter(({ value }) => Number.isInteger(Number(value))),
  switchMap(({ value }) => ajax.getJSON(`/api/media/${value}`).pipe(
    mergeMap(response => of({
      type: GALLERY_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(GALLERY_FETCH_REJECTED),
  )),
);

export const validateSaveGallery = (action$, state$) => action$.pipe(
  ofType(GALLERY_SAVE),
  withLatestFrom(state$),
  switchMap(([ action, {
    localState, serverState,
  }]) => {
    const entity = new GalleryEntity();
    const errors = entity.getValidationErrors(localState, serverState);
    if (errors.length > 0) {
      return from([
        { type: GALLERY_SAVE_REJECTED },
        ...errors.map(({ message }) => assignErrorPayload(message)),
      ]);
    }
    return of({ ...action, type: GALLERY_SAVE_READY });
  }),
  apiCatchError(GALLERY_SAVE_REJECTED),
);

export const saveGallery = (action$, state$) => action$.pipe(
  ofType(GALLERY_SAVE_READY),
  withLatestFrom(state$),
  switchMap(([, {
    localState, serverState,
  }]) => {
    const entity = new GalleryEntity();
    const payload = entity.getPayloadFromData(localState, serverState);
    const mid = payload?.[MEDIA_ENTITY_ID]?.[0]?.value;
    if (mid) {
      return ajax.patch(`/api/media/${mid}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => of({ type: GALLERY_SAVE_SUCCESS, value: response })),
      );
    }
    return ajax.post('/api/media', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => of({ type: GALLERY_SAVE_SUCCESS, value: response })),
    );
  }),
  apiCatchError(GALLERY_SAVE_REJECTED),
);

export const redirectOnEventSaved = (action$, state$) => action$.pipe(
  ofType(GALLERY_SAVE_SUCCESS),
  withLatestFrom(state$),
  filter(([, {
    router: { location: { pathname } },
  }]) => /^\/media\/gallery\/create/.test(pathname)),
  mergeMap(([
    { value: {
      mid: [{ value: galleryId }],
    } },
  ]) => action$.pipe(
    ofType(DATASTATE_LOCAL_DISPOSE), // wait until disposal
    map(() => push(`/media/gallery/${galleryId}`)),
    take(1),
  )),
);

export const notificationAfterSave = action$ => action$.pipe(
  ofType(GALLERY_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Gallery is successfully saved.')),
);
