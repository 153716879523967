import { ELEMENT_FACEBOOK, PROP_URL, FACEBOOK_PATTERN } from './defaults';
import { FACEBOOK } from '../../../../../../constants/editor/dataTypes';

export const convertToFacebook = data => ({
  type: ELEMENT_FACEBOOK,
  [PROP_URL]: data[PROP_URL] || '',
  children: [{ text: '' }],
});

export const convertFromFacebook = data => ({
  type: FACEBOOK,
  data: {
    [PROP_URL]: data[PROP_URL] || '',
  },
});

export const isValidUrl = string => FACEBOOK_PATTERN.test(string);
export const getValidUrl = (string) => {
  const matches = string.match(FACEBOOK_PATTERN);
  return matches[0] || '';
};
