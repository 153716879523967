import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import MarkupField from './MarkupField';
import TextField from './builder/TextField';
import { PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT } from '../../constants/media/media';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
});

const LocalCaption = ({ classes, data, editable, onChange, hideLocalCopyright }) => {
  const { localCaption, localCopyright } = data;
  return (
    <Grid className={classes.root} container spacing={2}>
      <Grid item xs={12}>
        <MarkupField
          data={localCaption || ''}
          disabled={!editable}
          label={'Local caption'}
          onChange={(markup) => {
            if (!(!localCaption && markup === '<p></p>')) {
              onChange([PROP_LOCAL_CAPTION, markup === '<p></p>' ? '' : markup]);
            }
          }}
        />
      </Grid>
      {!hideLocalCopyright && <Grid item xs={12}>
        <TextField
          label={'Local Copyright'}
          value={localCopyright || ''}
          disabled={!editable}
          onChange={(e) => {
            onChange([PROP_LOCAL_COPYRIGHT, e.target.value]);
          }}
        />
      </Grid>}
    </Grid>
  );
};

LocalCaption.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  hideLocalCopyright: PropTypes.bool,
};

LocalCaption.defaultProps = {
  editable: true,
  hideLocalCopyright: false,
};

export default withStyles(styles)(LocalCaption);
