import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { IMAGE } from '../../../constants/builder/builder';
import { optimiseImage } from '../../helper/media';

const styles = () => ({
  img: {
    height: 80,
    width: 120,
    objectFit: 'cover',
  },
  imgSmall: {
    height: 40,
    width: 60,
    objectFit: 'cover',
  },
});

const getImageClass = (size, classes) => {
  switch (size) {
    case 'small':
      return classes.imgSmall;
    default:
      return classes.img;
  }
};

const getImageHeight = (size) => {
  switch (size) {
    case 'small':
      return 40;
    default:
      return 80;
  }
};

const ThumbnailImage = ({ image, hero, size, classes }) => {
  const img = image || (hero && hero.find(({ type }) => type === IMAGE)) || null;
  if (!img?.data?.url) return null;
  return (
    <img
      className={getImageClass(size, classes)}
      alt={img.data.title}
      src={`${optimiseImage(img.data.url, { height: getImageHeight(size) })}`}
    />
  );
};

ThumbnailImage.propTypes = {
  image: PropTypes.object,
  hero: PropTypes.array,
  size: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

ThumbnailImage.defaultProps = {
  image: null,
  hero: null,
  size: 'normal',
};

export default withStyles(styles)(ThumbnailImage);
