import { ofType } from 'redux-observable';
import {
  tap, ignoreElements, first, mergeMap, filter,
  distinctUntilChanged, debounceTime, switchMap
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import jsLoader from '../utility/jsLoader';

import {
  INIT_EMBED,
  INIT_FB_EMBED,
} from '../../constants/actionTypes';

import { INSTAGRAM } from '../../constants/builder/builder';
import {
  ARTICLE_LINK_GET_OG_DATA,
  ARTICLE_LINK_GET_OG_DATA_FAILURE,
  ARTICLE_LINK_GET_OG_DATA_SUCCESS,
} from '../../constants/actionTypes/editor';
import apiCatchError from '../helper/notification';
export const fbEmbed = (action$) => {
  const loadSdk$ = from(jsLoader(['https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2'])).pipe(
    first()
  );
  return action$.pipe(
    ofType(INIT_FB_EMBED),
    mergeMap(() => {
      return loadSdk$
    }),
    tap(() => {
      window.FB.init({
        appId: '1902579140002064',
        xfbml      : true,
        version    : 'v3.3'
      });
    }),
    ignoreElements()
  )
};

export const instagramPreviewEmbed = (action$) => {
  const loadSdk$ = from(jsLoader(['//www.instagram.com/embed.js']))
    .pipe(first());

  return action$.pipe(
    ofType(INIT_EMBED),
    filter(({value}) => value === INSTAGRAM),
    mergeMap(() => loadSdk$),
    tap(() => {
      window.FB.init({
        appId: '1902579140002064',
        xfbml      : true,
        version    : 'v3.3'
      });
    }),
    ignoreElements()
  )
};

export const fetchOGDataForArticleLink = action$ => action$.pipe(
  ofType(ARTICLE_LINK_GET_OG_DATA),
  debounceTime(500),
  distinctUntilChanged((prev, curr) => prev.value === curr.value),
  switchMap(({ value: { url, callback } }) =>
    ajax.getJSON(`/api/url/og?url=${url}`).pipe(
      mergeMap((data) => {
        const og = {
          image: data.ogImage,
          title: data.ogTitle,
          description: data.ogDescription,
        };
        callback(og);
        return of({
          type: ARTICLE_LINK_GET_OG_DATA_SUCCESS,
        });
      }),
      apiCatchError(ARTICLE_LINK_GET_OG_DATA_FAILURE),
    ),
  ),
);
