import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextFieldBase from '../../ui/TextField';

const TextField = (props) => {
  const { label, value, overrideValue, onChange, maxLength, allowEmpty } = props;
  const headline = typeof overrideValue === 'string' && (overrideValue.length > 0 || allowEmpty)
    ? overrideValue : value;
  const [valid, setValid] = useState(true);
  useEffect(() => {
    setValid(maxLength === -1 || headline.length <= maxLength);
  }, [headline]);
  return (
    <TextFieldBase
      error={!valid}
      label={maxLength > -1 ? `${label}  (${headline.length}/${maxLength} characters)` : label}
      value={headline}
      onChange={(event) => {
        onChange(event);
      }}
      multiline
    />
  );
};

TextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  overrideValue: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  allowEmpty: PropTypes.bool,
};

TextField.defaultProps = {
  value: '',
  label: '',
  overrideValue: null,
  maxLength: -1,
  allowEmpty: false,
};

export default TextField;
