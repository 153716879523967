import { ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router';

import { CLONE_EDITION_SUCCESS, CLONE_PAGE_EDITION } from '../../constants/actionTypes/edition';

import { showErrorNotification, showSuccessNotification } from '../helper/notification';

export const clone = action$ => action$.pipe(
  ofType(CLONE_PAGE_EDITION),
  switchMap(({ value: { containerId, pageId, editionType } }) =>
    ajax.getJSON(`/api/edition/clone/${containerId}/${pageId}`).pipe(
      map(response => ({
        type: CLONE_EDITION_SUCCESS,
        value: {
          ...response,
          containerId,
          editionType,
        },
      })),
      catchError(showErrorNotification('Unable to clone this article')),
    ),
  ),
);

export const redirectToThePage = action$ => action$.pipe(
  ofType(CLONE_EDITION_SUCCESS),
  map(
    ({ value: { containerId, editionType, clonedId } }) =>
      push(`/edition/${editionType}/${containerId}/${clonedId}`),
  ),
);

export const showSuccessMessage = action$ => action$.pipe(
  ofType(CLONE_EDITION_SUCCESS),
  mergeMap(showSuccessNotification('Page is successfully cloned')),
);
