import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import FlowPaper from '../common/layout/FlowPaper';

import { fetchVocab } from '../../actions/vocab';
import TextField from '../ui/builder/TextField';
import { getSuggestions } from './helper';

const styles = theme => ({
  root: {
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(1 / 2, 1 / 4),
  },
  divider: {
    height: theme.spacing(2),
  },
  hierarchicalItem: {
    position: 'relative',
    '.menu-item-children > &': {
      '&:before': {
        content: '""',
        display: 'block',
        width: theme.spacing(1),
        height: '0',
        borderTop: `1px solid ${theme.palette.grey.light}`,
        position: 'absolute',
        top: '18px',
        left: '0',
      },
    },
  },
  hierarchicalChildren: {
    marginLeft: theme.spacing(2),
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      width: 0,
      position: 'absolute',
      top: 0,
      bottom: '18px',
      left: 0,
      borderLeft: `1px solid ${theme.palette.grey.light}`,
    },
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;
  return (
    <TextField
      multiline={false}
      InputProps={{
        inputRef: ref,
        startAdornment: (
          <InputAdornment position={'start'}>
            <SearchIcon color={'primary'} />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...other}
    />
  );
}

const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
  const isHighlighted = highlightedIndex === index;
  const key = suggestion.name ? 'name' : 'path';
  const isSelected = (selectedItem ? selectedItem[key] : '').indexOf(suggestion[key]) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion[key]}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion[key]}
    </MenuItem>
  );
};

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const renderHierarchicalSuggestion = ({
  classes, suggestion, itemProps, getItemProps,
}) => {
  const { children } = suggestion;
  return (
    <div className={`${classes.hierarchicalItem} menu-item`}>
      <MenuItem
        {...itemProps}
        key={suggestion.name}
        component={'div'}
      >
        {suggestion.name}
      </MenuItem>
      {children && Object.values(children).length > 0 && <div className={`${classes.hierarchicalChildren} menu-item-children`}>
        {Object.values(children).map(child => renderHierarchicalSuggestion({
          classes,
          suggestion: child,
          itemProps: getItemProps({ item: child }),
          getItemProps,
        }))}
      </div>}
    </div>
  );
};

renderHierarchicalSuggestion.propTypes = {
  classes: PropTypes.object.isRequired,
  itemProps: PropTypes.object.isRequired,
  getItemProps: PropTypes.func.isRequired,
  suggestion: PropTypes.shape({ name: PropTypes.string, children: PropTypes.object }).isRequired,
};

const dialogStyles = theme => ({
  ...styles(theme),
  root: {
    flexGrow: 1,
    height: 50,
  },
});

function IntegrationDownshift(props) {
  const { classes, className, vocabState, onSelect, placeholder, fetchVocab: onFetch,
    autoFocus, vocab, onChange, publicationSource, hierarchical, margin } = props;
  const items = vocabState[vocab].items;
  const [query, setQuery] = useState('');
  const rootClasses = [classes.root];
  if (className) {
    rootClasses.push(className);
  }
  useEffect(() => {
    if (query) {
      onFetch({
        noLayout: true,
        q: query,
        publicationSource,
        vocab,
      });
    }
  }, [query]);
  return (
    <div className={rootClasses.join(' ')}>
      <Downshift onSelect={onSelect} itemToString={item => item && (item.name || item.label || item.path)}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          selectedItem,
          clearSelection,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              autoFocus,
              margin,
              InputProps: getInputProps({
                placeholder,
                onChange: (e) => {
                  if (e.target.value === '') {
                    clearSelection();
                  }
                  onChange(e.target.value);
                  setQuery(e.target.value);
                },
              }),
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <FlowPaper className={classes.paper}>
                  {items && getSuggestions(items, hierarchical).map((suggestion, index) =>
                    hierarchical
                      ? renderHierarchicalSuggestion({
                        classes,
                        suggestion,
                        itemProps: getItemProps({ item: suggestion }),
                        getItemProps,
                      })
                      : renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem,
                      }),
                  )}
                </FlowPaper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
}

IntegrationDownshift.defaultProps = {
  placeholder: 'Search section by path',
  className: null,
  autoFocus: false,
  hierarchical: false,
  onChange: () => {},
  publicationSource: null,
  margin: 'none',
};

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  vocabState: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  fetchVocab: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  vocab: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  hierarchical: PropTypes.bool,
  publicationSource: PropTypes.string,
  margin: PropTypes.string,
};

const AutoComplete = connect(
  ({ vocab: vocabState }) => ({ vocabState }),
  { fetchVocab },
)(IntegrationDownshift);

export const InlineAutocomplete = withStyles(styles)(AutoComplete);
export default withStyles(dialogStyles)(AutoComplete);
