import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import YouTubeEmbed from './YouTubeEmbed';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
  },
});

const VideoYoutube = ({
  classes,
  video,
}) => {
  if (!video.id && !video.url) return <Typography variant={'h6'}>Video Youtube id or url not found</Typography>;
  return (
    <div className={classes.root}>
      <YouTubeEmbed data={video} />
    </div>
  );
};

VideoYoutube.propTypes = {
  classes: PropTypes.object.isRequired,
  video: PropTypes.object,
};

VideoYoutube.defaultProps = {
  video: {
    id: null,
    url: null,
  },
};

export default withStyles(styles)(VideoYoutube);

