import { ELEMENT_IFRAME, PROP_SRC, PROP_WIDTH, PROP_HEIGHT } from './defaults';
import { PROP_URL, IFRAME } from '../../../../../constants/editor/dataTypes';

export const convertToIframe = data => ({
  type: ELEMENT_IFRAME,
  [PROP_SRC]: data[PROP_URL] || '',
  [PROP_WIDTH]: data[PROP_WIDTH] || '',
  [PROP_HEIGHT]: data[PROP_HEIGHT] || '',
  children: [{ text: '' }],
});

export const convertFromIframe = data => ({
  type: IFRAME,
  data: {
    [PROP_URL]: data[PROP_SRC] || '',
    [PROP_WIDTH]: data[PROP_WIDTH] || '',
    [PROP_HEIGHT]: data[PROP_HEIGHT] || '',
  },
});
