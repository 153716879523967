import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { fetchVocab } from '../actions/vocab';
import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import ArticleListTable from '../components/article/articleList/ArticleListTable';
import TableFilters, { TableFiltersGroup } from '../components/ui/table/TableFilters';
import DropdownFilter from '../components/ui/DropdownFilter';
import TextSearchFilter from '../components/ui/table/filters/TextSearchFilter';
import DatePickerFilter from '../components/ui/table/filters/DatePickerFilter';
import StatusFilter from '../components/ui/table/filters/StatusFilter';
import VocabFilter from '../components/ui/table/filters/VocabFilter';
import { AUTHORS, SECTIONS, ARTICLE_TYPE, CONTENT_SOURCE } from '../constants/vocab';
import { HERO_TYPES } from '../constants/media/media';
import AccessVocab from '../access/AccessVocab';

export const ArticleListBreadcrumb = [
  {
    title: 'Article list',
    slug: 'article',
  },
];

const ArticleList = (props) => {
  const {
    workflowOptions,
    fetchVocab: fetch,
    articleTypes,
    publicationConfig,
    pathname,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const [author, setFilterAuthor] = useState('');
  const [section, setFilterSection] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [workflow, setWorkflow] = useState('');
  const [contentSource, setContentSource] = useState('');
  const [articleType, setArticleType] = useState('');
  const [mediaType, setMediaType] = useState('');
  const [status, setStatus] = useState('');

  // hack to fix autocomplete bug, always fetch article type
  useEffect(() => {
    fetch({
      vocab: ARTICLE_TYPE,
    });
  }, []);

  const translationRoute = /internal-translation/.test(pathname);
  const publicationSource = translationRoute &&
    Array.isArray(publicationConfig.internalTranslationSourceDomain)
    ? publicationConfig.internalTranslationSourceDomain[0] : null;

  return (
    <Page toolbarContent={<PageToolbar breadcrumb={ArticleListBreadcrumb} />}>
      <PageContent>
        <Grid item xs={12}>
          <TableFilters>
            <TableFiltersGroup>
              <TextSearchFilter
                placeholder={'Search by Title / Body'}
                onSubmit={textQuery => setSearchQuery(textQuery)}
              />
              <VocabFilter
                placeholder={'Search by Author'}
                publicationSource={publicationSource}
                vocab={AUTHORS}
                onSelect={selectedAuthor => setFilterAuthor(selectedAuthor)}
              />
              <VocabFilter
                placeholder={'Search by Section'}
                publicationSource={publicationSource}
                vocab={SECTIONS}
                onSelect={text => setFilterSection(text)}
                hierarchical
              />
              <DatePickerFilter
                disablePast={status === 'scheduled'}
                toDate={toDate}
                fromDate={fromDate}
                onChangeFromDate={date => setFromDate(date)}
                onChangeToDate={date => setToDate(date)}
              />
            </TableFiltersGroup>
            <TableFiltersGroup>
              <DropdownFilter
                onChange={setArticleType}
                label={'type'}
                id={'filterArticle'}
                options={articleTypes}
                value={articleType}
                includeAll
              />
              <DropdownFilter
                onChange={setMediaType}
                label={'media type'}
                id={'filterMedia'}
                options={Object.entries(HERO_TYPES).map(([id, name]) => ({ id, name }))}
                value={mediaType}
                includeAll
              />
              <DropdownFilter
                onChange={setWorkflow}
                label={'workflow'}
                id={'filterWorkflow'}
                options={workflowOptions}
                value={workflow}
                includeAll
              />
              <AccessVocab vocab={CONTENT_SOURCE}>
                <VocabFilter
                  placeholder={'Content source'}
                  publicationSource={publicationSource}
                  vocab={CONTENT_SOURCE}
                  onSelect={selected => setContentSource(selected)}
                  margin="dense"
                />
              </AccessVocab>
              <StatusFilter
                onChange={setStatus}
                value={status}
              />
            </TableFiltersGroup>
          </TableFilters>
        </Grid>
        <PageSection>
          <ArticleListTable
            textQuery={searchQuery}
            contentSource={contentSource?.id || null}
            workflow={workflow}
            status={status}
            fromDate={fromDate ? fromDate.unix() : null}
            toDate={toDate ? toDate.unix() : null}
            author={author}
            section={section}
            articleType={articleType}
            mediaType={mediaType}
            translationMode={translationRoute}
            publicationSource={publicationSource}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

ArticleList.propTypes = {
  workflowOptions: PropTypes.array,
  fetchVocab: PropTypes.func.isRequired,
  articleTypes: PropTypes.array.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
};

ArticleList.defaultProps = {
  workflowOptions: [],
};

export default connect(({
  frame: { workflowOptions, selectedPublication: { publicationConfig } },
  vocab: { article_type: { items: articleTypes } },
  router: { location: { pathname } },
}) => ({ workflowOptions, articleTypes, publicationConfig, pathname }),
{ fetchVocab },
)(ArticleList);
