import React from 'react';

import { PagePanel } from '../../common/page/PagePanel';

import SubHeadline from './articleBuilder/SubHeadline';
import FieldLockEditor from '../../fieldLock/form/FieldLockEditor';
import { FIELD_BODY } from '../../../constants/article/articleFields';

const BodyPanel = ({ isProduct }) => (
  <PagePanel>
    <SubHeadline />
    <FieldLockEditor isProduct={isProduct} field={FIELD_BODY} label={'Body'} panel required />
  </PagePanel>
);

export default BodyPanel;
