import React from 'react';
import Grid from '@material-ui/core/Grid';

import Page from '../components/common/page/Page';
import { PageContentWrapper } from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

import LivePreview from '../components/common/LivePreview';
import { PREVIEW_MODE_DESKTOP } from '../constants/preview';

const SitePreview = () => (
  <Page toolbarContent={<PageToolbar />}>
    <PageContentWrapper>
      <LivePreview path={'/'} previewMode={PREVIEW_MODE_DESKTOP} />
    </PageContentWrapper>
  </Page>
);

export default SitePreview;
