import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { AUTHORS, SECTIONS } from '../../constants/vocab';

import Page from '../../components/common/page/Page';
import PageContent from '../../components/common/page/PageContent';
import PageSection from '../../components/common/page/PageSection';
import PageToolbar from '../../components/common/page/PageToolbar';

import { disposePlayIncPlaylists, fetchPlayIncPlaylists } from '../../actions/integration/playInc';

import TableFilters, { TableFiltersGroup } from '../../components/ui/table/TableFilters';
import TextSearchFilter from '../../components/ui/table/filters/TextSearchFilter';
import VocabFilter from '../../components/ui/table/filters/VocabFilter';
import DatePickerFilter from '../../components/ui/table/filters/DatePickerFilter';

import PlayIncVideoTable from '../../components/integration/playInc/PlayIncVideoTable';
import DropdownFilter from '../../components/ui/DropdownFilter';
import ToggleSwitch from '../../components/ui/ToggleSwitch';

const PlayIncList = (props) => {
  const {
    playlists,
    selectedPublication,
    fetchPlayIncPlaylists: fetchPlaylists,
    disposePlayIncPlaylists: disposePlaylists,
  } = props;
  const PlayIncBreadcrumb = [
    {
      title: selectedPublication?.publicationConfig?.playInc?.title || 'Play Inc',
      slug: 'playinc',
    },
  ];
  const [searchQuery, setSearchQuery] = useState('');
  const [isVarticle, setIsVarticle] = useState(false);
  const [author, setAuthor] = useState('');
  const [section, setSection] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

/*   useEffect(() => {
    fetchPlaylists();
    return () => {
      disposePlaylists();
    };
  }, []); */
  
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={PlayIncBreadcrumb} />}
    >
      <PageContent>
        <Grid item xs={12}>
          <TableFilters>
            <TableFiltersGroup>
              <TextSearchFilter
                placeholder={'Search by Title'}
                onSubmit={textQuery => setSearchQuery(textQuery)}
              />
              <VocabFilter
                placeholder={'Search by Author'}
                vocab={AUTHORS}
                onSelect={selectedAuthor => setAuthor(selectedAuthor)}
              />
              <VocabFilter
                placeholder={'Search by Section'}
                vocab={SECTIONS}
                onSelect={selectedSection => setSection(selectedSection)}
              />
              <DatePickerFilter
                toDate={toDate}
                fromDate={fromDate}
                onChangeFromDate={date => setFromDate(date)}
                onChangeToDate={date => setToDate(date)}
                disableTime
              />
            </TableFiltersGroup>
            <TableFiltersGroup>
              <DropdownFilter
                onChange={setPlaylist}
                label={'playlist'}
                options={
                  playlists.map(({ playlistId, label }) => ({ id: playlistId, name: label }))
                }
                value={playlist}
                includeAll
              />
              <ToggleSwitch
                label={'Is Video article'}
                value={isVarticle}
                onChange={(e) => {
                  setIsVarticle(e.target.checked);
                }}
              />
              <Grid xs={9} />
            </TableFiltersGroup>
          </TableFilters>
        </Grid>
        <PageSection>
          <PlayIncVideoTable
            searchQuery={searchQuery}
            isVarticle={isVarticle}
            author={author}
            section={section}
            playlist={playlist}
            fromDate={fromDate}
            toDate={toDate}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

PlayIncList.propTypes = {
  fetchPlayIncPlaylists: PropTypes.func.isRequired,
  disposePlayIncPlaylists: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object,
  playlists: PropTypes.array,
};

PlayIncList.defaultProps = {
  selectedPublication: {},
  playlists: [],
};

export default connect(
  ({
    integration: { playInc: { playlists } = {} },
    frame: { selectedPublication },
  }) => ({ selectedPublication, playlists }),
  { fetchPlayIncPlaylists, disposePlayIncPlaylists },
)(PlayIncList);
