import { ELEMENT_ARTICLE_LINK, PROP_URL, PROP_TITLE, PROP_IMAGE, PROP_DESCRIPTION } from './defaults';
import { ARTICLE_LINK } from '../../../../../constants/editor/dataTypes';

export const convertToArticleLink = data => ({
  type: ELEMENT_ARTICLE_LINK,
  [PROP_URL]: data[PROP_URL] || '',
  [PROP_TITLE]: data[PROP_TITLE] || '',
  productId: data.productId || '',
  [PROP_IMAGE]: data[PROP_IMAGE] || '',
  [PROP_DESCRIPTION]: data[PROP_DESCRIPTION] || '',
  children: [{ text: '' }],
});

export const convertFromArticleLink = data => ({
  type: ARTICLE_LINK,
  data: {
    [PROP_URL]: data[PROP_URL] || '',
    productId: data.productId || '',
    [PROP_TITLE]: data[PROP_TITLE] || '',
    [PROP_IMAGE]: data[PROP_IMAGE] || '',
    [PROP_DESCRIPTION]: data[PROP_DESCRIPTION] || '',
  },
});
