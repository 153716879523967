import { mergeDeepRight } from 'rambdax';

import BaseEntity from './common/BaseEntity';

import {
  COMPONENT_NAMES,
  ArticleX1, ArticleX2, ArticleX3, ArticleX4, ArticleX8,
  HeroPlus4Articles, HeroPlus4ArticlesNC, HeroPlus6Articles,
  MostPopular, MostPopularINews, NewsLetterSignup, ReadMore, Title,
  ArticleX4PlusDMPU, ArticleX6PlusDMPU, ArticleX8PlusDMPU, HeroPlusDMPU,
} from '../constants/components';

import {
  DEFAULT_ARTICLE_COMPONENT_PROP, DEFAULT_COMPONENT_PROP, DEFAULT_ARTICLE_MPU_COMPONENT_PROP,
  CONTENT_SOURCE, CONTENT_SOURCE_CUSTOM, CONTENT_SOURCE_CUSTOM_SECTION, CONTENT_SOURCE_MANUAL,
} from '../components/layout/constants';

import {
  FIELD_CHANGED,
  FIELD_COMPONENTS,
  FIELD_COMPONENTS_ORDER, FIELD_MANUAL_LISTS,
  FIELD_TERM,
  LAYOUT_FIELD_MAPPING,
  LAYOUT_PAYLOAD_FIELDS, LAYOUT_TERM_FIELDS,
} from '../constants/layout/layoutFields';

import { sanitiseLayoutComponent } from '../utils/sanitiser';

import { FIELD_OVERRIDES, FIELD_PINNED, OVERRIDE_LEAD } from '../constants/layout/layout';
import { getArticleMinCountFromType, processManualArticleIndexes } from '../utils/layoutHelper';
import { MAX_LENGTH_STAND_FIRST } from '../constants/article/article';
import { getComponentList, getListArticles, processManualLists } from '../utils/manualListHelper';

const ENTITY_TYPE = 'layout_type';
const ENTITY_BUNDLE = '_layout';

export const COMPONENT_ID = 'id';
export const MANUAL_INDEXES = 'manualArticleIndexes';
const API_FIELD_MANUAL_LISTS = 'manual_lists';
const API_FIELD_CHANGED = 'client_changed_time';

class LayoutEntity extends BaseEntity {
  constructor(type) {
    super(
      ENTITY_TYPE,
      `${type}${ENTITY_BUNDLE}`,
      LAYOUT_FIELD_MAPPING,
      LAYOUT_PAYLOAD_FIELDS,
    );
    this.dataMapping[FIELD_TERM].key = `field_${type}`;
    this.type = type;
  }
  static getComponents(components) {
    return BaseEntity.getArrayAsKeyed(components, COMPONENT_ID, sanitiseLayoutComponent);
  }
  static getComponentsOrder(components) {
    return BaseEntity.getArrayOrder(components, COMPONENT_ID);
  }
  static getOrderedComponents(components, order) {
    return BaseEntity.getOrderedArray(components, order, sanitiseLayoutComponent);
  }
  // @todo review and remove if able
  static setComponentDefaults(components) {
    return components.map((comp) => {
      if (comp?.[FIELD_PINNED]) {
        comp[FIELD_PINNED] = Object.entries(comp[FIELD_PINNED])
          .filter(([, value]) => !!value)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        if (comp[FIELD_PINNED].length === 0) {
          delete comp[FIELD_PINNED];
        }
      }
      switch (comp.type) {
        case ArticleX1:
        case ArticleX2:
        case ArticleX3:
        case ArticleX4:
        case ArticleX8:
        case HeroPlus4Articles:
        case HeroPlus4ArticlesNC:
        case HeroPlus6Articles:
          DEFAULT_ARTICLE_COMPONENT_PROP
            .filter(([key]) => !comp[key])
            .forEach(([key, value]) => comp[key] = value);
          return comp;
        case MostPopular:
        case MostPopularINews:
        case NewsLetterSignup:
        case ReadMore:
        case Title:
          DEFAULT_COMPONENT_PROP
            .filter(([key]) => !comp[key])
            .forEach(([key, value]) => comp[key] = value);
          return comp;
        case ArticleX4PlusDMPU:
        case ArticleX6PlusDMPU:
        case ArticleX8PlusDMPU:
        case HeroPlusDMPU:
          DEFAULT_ARTICLE_MPU_COMPONENT_PROP
            .filter(([key]) => !comp[key])
            .forEach(([key, value]) => comp[key] = value);
          return comp;
        default:
          return comp;
      }
    });
  }
  getFieldData(payload, field) {
    switch (field) {
      case FIELD_TERM: {
        if (!this.type) { // if type is empty try each term type
          for (let i = 0; i <= LAYOUT_TERM_FIELDS.length; i += 1) {
            if (payload[LAYOUT_TERM_FIELDS[i]]) {
              this.dataMapping[FIELD_TERM].key = LAYOUT_TERM_FIELDS[i];
              return super.getFieldData(payload, field);
            }
          }
          return this.getFieldDefault(field);
        }
        return super.getFieldData(payload, field);
      }
      default:
        return super.getFieldData(payload, field);
    }
  }
  addFieldData(data, payload) {
    return (field) => {
      switch (field) {
        case FIELD_COMPONENTS: {
          const mapping = this.dataMapping[field];
          try {
            const components = JSON.parse(payload[mapping.key][0].value);
            data[field] = this.constructor.getComponents(components);
            data[FIELD_COMPONENTS_ORDER] = this.constructor.getComponentsOrder(components);
          } catch (e) {
            data[field] = {};
            data[FIELD_COMPONENTS_ORDER] = [];
          }
          return;
        }
        default:
          data[field] = this.getFieldData(payload, field);
      }
    };
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_COMPONENTS:
        return [{
          value: JSON.stringify(
            this.constructor.setComponentDefaults(
              this.constructor.getOrderedComponents(data[field], data[FIELD_COMPONENTS_ORDER]),
            ),
          ),
        }];
      case FIELD_TERM:
        return [{
          target_id: Number(data[field].id),
          target_type: 'taxonomy_term',
        }];
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
  getValidationErrors(data, serverData) {
    const errors = super.getValidationErrors(data, serverData);
    const dataState = mergeDeepRight(serverData, data);
    // @todo this works, but can be simplified with the manual indexes from the dataState
    const orderedComponents = processManualArticleIndexes(this.constructor.getOrderedComponents(
      dataState[FIELD_COMPONENTS],
      dataState[FIELD_COMPONENTS_ORDER],
    ));
    orderedComponents.forEach((component) => {
      switch (component[CONTENT_SOURCE]) {
        case CONTENT_SOURCE_CUSTOM: {
          const articleCount = getArticleMinCountFromType(component.type);
          if (!component[CONTENT_SOURCE_CUSTOM_SECTION] && articleCount > 0) {
            errors.push({
              field: FIELD_COMPONENTS,
              message: `${COMPONENT_NAMES[component.type] || component.name} requires a custom list`,
            });
          }
          break;
        }
        case CONTENT_SOURCE_MANUAL: {
          const articleCount = getArticleMinCountFromType(component.type);
          const manualList = getComponentList(dataState[FIELD_MANUAL_LISTS], component);
          if (!manualList && articleCount > 0) {
            errors.push({
              field: FIELD_COMPONENTS,
              message: `${COMPONENT_NAMES[component.type] || component.name} requires a manual list`,
            });
          } else {
            const articles = getListArticles(manualList);
            const invalid = {
              empty: 0,
              lead: 0,
            };
            if (component[MANUAL_INDEXES]) {
              component[MANUAL_INDEXES].forEach((index) => {
                const article = articles[index];
                if (!article) {
                  if (component.type === "VideoHubPlaylist") {
                    return;
                  } else {
                    invalid.empty += 1;
                    return;
                  }
                }
                if (
                  article?.[FIELD_OVERRIDES]?.[OVERRIDE_LEAD] &&
                  article[FIELD_OVERRIDES][OVERRIDE_LEAD].length > MAX_LENGTH_STAND_FIRST
                ) {
                  invalid.lead += 1;
                }
              });
            }
            if (component[FIELD_PINNED]) {
              Object.values(component[FIELD_PINNED]).filter(value => !!value).forEach((article) => {
                if (
                  article?.[FIELD_OVERRIDES]?.[OVERRIDE_LEAD] &&
                  article[FIELD_OVERRIDES][OVERRIDE_LEAD].length > MAX_LENGTH_STAND_FIRST
                ) {
                  invalid.lead += 1;
                }
              });
            }
            if (invalid.empty > 0) {
              errors.push({
                field: FIELD_COMPONENTS,
                message: `${COMPONENT_NAMES[component.type] || component.name} require more articles`,
              });
            }
            if (invalid.lead > 0) {
              errors.push({
                field: FIELD_COMPONENTS,
                message: `${COMPONENT_NAMES[component.type] || component.name}: Standfirst exceeds character limit on ${invalid.lead} article${invalid.lead > 1 ? 's' : ''}`,
              });
            }
          }
          break;
        }
        default:
          break;
      }
    });
    return errors;
  }
  getPayloadFromData(data, serverData, publication) {
    // when creating a payload the changed time is for serverside validation only
    this.dataMapping[FIELD_CHANGED].key = API_FIELD_CHANGED;
    const dataState = mergeDeepRight(serverData, data);
    if (dataState[FIELD_COMPONENTS]) {
      Object.entries(dataState[FIELD_COMPONENTS]).forEach(([componentId, component]) => {
        if (component[FIELD_PINNED]) {
          Object.keys(component[FIELD_PINNED]).forEach((index) => {
            if (
              data[FIELD_COMPONENTS]?.[componentId]?.[FIELD_PINNED]?.[index]?.target_id &&
              serverData[FIELD_COMPONENTS]?.[componentId]?.[FIELD_PINNED]?.[index]?.target_id !==
              data[FIELD_COMPONENTS]?.[componentId]?.[FIELD_PINNED]?.[index]?.target_id
            ) {
              dataState[FIELD_COMPONENTS][componentId][FIELD_PINNED][index] =
                data[FIELD_COMPONENTS][componentId][FIELD_PINNED][index];
            }
          });
        }
      });
      data[FIELD_COMPONENTS] = dataState[FIELD_COMPONENTS];
      data[FIELD_COMPONENTS_ORDER] = dataState[FIELD_COMPONENTS_ORDER];
    }
    const payload = super.getPayloadFromData(data, serverData, publication);
    const manualLists = data[FIELD_MANUAL_LISTS] && Object.entries(data[FIELD_MANUAL_LISTS]).map(([id, manualList]) => (serverData?.[FIELD_MANUAL_LISTS]?.[id]
      ? mergeDeepRight(serverData[FIELD_MANUAL_LISTS][id], manualList)
      : manualList));
    if (manualLists && manualLists.length > 0) {
      payload[API_FIELD_MANUAL_LISTS] = processManualLists(
        Object.values(dataState[FIELD_COMPONENTS]),
        manualLists,
      );
    }
    return payload;
  }
}

export default LayoutEntity;
