import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Loader from '../ui/Loader';
import { Table, TablePagination } from '../ui/table/Table';
import { removeEmptyQueryValue } from '../../utils/urlHelper';

const ListTable = (props) => {
  const {
    isFetching, fetchList, getFetchData, queryData,
    total, rowsPerPageOptions,
    children,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const updateList = (resetPagination) => {
    if (resetPagination) {
      setRowsPerPage(rowsPerPageOptions[0]);
      setPage(0);
    }
    fetchList(removeEmptyQueryValue(getFetchData(page, rowsPerPage)));
  };

  useEffect(() => {
    updateList();
  }, [page, rowsPerPage]);

  useEffect(() => {
    updateList(true);
  }, queryData);

  return (
    <>
      {isFetching && <Loader />}
      <Table>
        {children}
      </Table>
      {total > rowsPerPage && <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component={'nav'}
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next',
        }}
        onChangePage={(e, i) => setPage(i)}
        onChangeRowsPerPage={e => setRowsPerPage(e.target.value)}
      />}
    </>
  );
};

ListTable.propTypes = {
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchList: PropTypes.func.isRequired,
  getFetchData: PropTypes.func.isRequired,
  queryData: PropTypes.array,
  total: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

ListTable.defaultProps = {
  queryData: [],
  total: 0,
  rowsPerPageOptions: [20, 40, 100],
};

export default ListTable;
