import VoucherElement from './components/VoucherElement';

export const ELEMENT_VOUCHER = 'voucher';
export const PROPS_SNIPPET = 'snippet';
export const PROP_LOCATION = 'location';
export const PROP_AMOUNT = 'amount';


export const DEFAULTS_VOUCHER = {
  voucher: {
    component: VoucherElement,
    type: ELEMENT_VOUCHER,
  },
};
