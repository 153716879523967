import { serialize } from '../../utils/urlHelper';

export const youtubeParser = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url?.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
};

export const optimiseImage = (url, params) =>
  `${url}${/\?/.test(url) ? '&' : '?'}${serialize(params)}`;
