import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import Typography from '@material-ui/core/Typography';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import GallerySelector from '../../../../../../ui/media/GallerySelector';

import { PROP_GALLERY, PROP_ALIGN } from '../defaults';
import AlignmentToggle from '../../../../common/AlignmentToggle';

const GalleryElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  const gallery = element[PROP_GALLERY] || {};
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Gallery'}
        Icon={PhotoLibrary}
        contentEditable={false}
        element={element}
        toolbar={<AlignmentToggle
          align={element[PROP_ALIGN] || null}
          onSelect={(value) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_ALIGN]: value }, { at: path });
          }}
        />}
      >
        <Grid container spacing={1}>
          <Grid item xs={gallery.gallery ? 2 : 12}>
            <GallerySelector
              onSelect={(galleryData) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_GALLERY]: galleryData }, { at: path });
              }}
              onClose={() => {
                const path = ReactEditor.findPath(editor, element);
                ReactEditor.focus(editor);
                Transforms.setSelection(editor, path);
              }}
              gallery={gallery}
            />
          </Grid>
          {gallery.gallery && <Grid item container xs={10} spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'body1'}>{gallery.title}</Typography>
            </Grid>
          </Grid>}
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

GalleryElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default GalleryElement;
