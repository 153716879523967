export const FIELD_NAME = 'name';
export const FIELD_COMPONENTS = 'components';
export const FIELD_COMPONENTS_ORDER = 'componentsOrder';
export const FIELD_TERM = 'term';
export const FIELD_MANUAL_LISTS = 'manualLists';
export const FIELD_CHANGED = 'changed';

export const PROP_MANUAL_INDEXES = 'manualIndexes';

export const LAYOUT_TERM_FIELDS = [
  'field_section',
  'field_author',
  'field_topic',
];

export const LAYOUT_PAYLOAD_FIELDS = [
  FIELD_NAME,
  FIELD_TERM,
  FIELD_COMPONENTS,
  FIELD_CHANGED,
];

export const LAYOUT_FIELD_MAPPING = [
  [FIELD_NAME, FIELD_NAME, 'Name'],
  [FIELD_CHANGED, FIELD_CHANGED, 'Changed'],
  [FIELD_COMPONENTS, 'field_layout_settings', 'Layout Components'],
  [FIELD_TERM, 'field_section', 'Term'], // 'field_section' is replaced depending on term type in LayoutEntity
];
