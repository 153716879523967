import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import moment from 'moment';

import Dialog from './common/Dialog';

import {
  editionListDraftLoad,
} from '../../actions/edition';

import { EDITION_DAILY, EDITION_SPECIAL } from '../../constants/edition/edition';
import AutoCompleteDialog from '../autocomplete/index';
import { EDITION_SECTION } from '../../constants/vocab';
import AutoCompleteGeneric from '../autocomplete/AutoComplete';
import RequiredIndicator from '../ui/RequiredIndicator';
import Tag from '../ui/Tag';
import { fetchVocab } from '../../actions/vocab';

const style = theme => ({
  fieldRoot: {
    display: 'flex',
    position: 'relative',
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2),
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
  },
  autoCompleteRoot: {
    position: 'relative',
    minHeight: 30,
    paddingBottom: theme.spacing(2),
  },
  autoCompleteInner: {
    position: 'absolute',
    width: '100%',
  },
});

const ExportToEdition = (props) => {
  const {
    classes,
    editionListDraftLoad: onDraftFetch,
    onSubmit,
    editionDraftList,
    sections,
    articleSections,
    fetchFlatVocab: findMatchSection,
    defaultEditionSection,
    hideSection,
    dialogTitle,
    submitButtonText,
    ...rest
  } = props;

  const tomorrow = moment().add(1, 'days');
  const [dailyQuery, setDailyQuery] = useState(tomorrow.format('dddd DD MMMM YYYY'));
  const [specialQuery, setSpecialQuery] = useState('');
  const [autoSelectOnce, setAutoSelectOnce] = useState(false);
  const [cancelSectionAutoPopulate, setCancelSectionAutoPopulate] = useState(false);

  const [validSection, setValidSection] = useState(true);
  const [validEdition, setValidEdition] = useState(true);

  const [dailyEdition, setDailyEdition] = useState(null);
  const [specialEdition, setSpecialEdition] = useState(null);
  const [section, setSection] = useState(null);

  useEffect(() => {
    // try find the match edition section section
    if (articleSections.length > 0) {
      findMatchSection({
        q: articleSections[0].name,
        vocab: EDITION_SECTION,
      });
    }
    if (defaultEditionSection) {
      setSection(defaultEditionSection);
    }
  }, []);

  useEffect(() => {
    if (!cancelSectionAutoPopulate && articleSections.length > 0 && sections.length > 0) {
      const matchSection = sections
        .find(({ name }) => name.toLowerCase() === articleSections[0].name.toLowerCase());
      if (matchSection) {
        setSection(matchSection);
      }
    }
  }, [sections]);

  useEffect(() => {
    if (dailyQuery) {
      onDraftFetch({
        type: EDITION_DAILY,
        q: dailyQuery,
      });
    }
  }, [dailyQuery]);

  useEffect(() => {
    if (specialQuery) {
      onDraftFetch({
        type: EDITION_SPECIAL,
        q: specialQuery,
      });
    }
  }, [specialQuery]);

  const dailyOptions = editionDraftList[EDITION_DAILY] || [];
  const specialOptions = editionDraftList[EDITION_SPECIAL] || [];
  useEffect(() => {
    if (!autoSelectOnce && dailyEdition === null && specialEdition === null) {
      // auto select today's date (only once)
      dailyOptions.forEach(({ data }) => {
        const { editionDate } = data;
        const date = moment.unix(editionDate);
        if (tomorrow.diff(date, 'days') === 0) {
          setDailyEdition({
            ...data,
            label: data.title,
          });
        }
        setAutoSelectOnce(true);
      });
    }
  }, [editionDraftList]);

  const onSubmitForm = () => {
    const id = dailyEdition?.id || specialEdition?.id;
    if (!id) {
      setValidEdition(false);
    }
    if (!section) {
      setValidSection(false);
    }
    if (id && section) {
      onSubmit({
        containerId: id,
        sectionId: section.id,
      });
    }
  };

  return (<Dialog title={dialogTitle} {...rest}>
    <Grid container direction={'column'}>
      { !hideSection && <>
        <Grid item>
          <FormControl fullWidth className={classes.formControl}>
            <Typography component={'p'} variant={'body2'} className={classes.label}>Section<RequiredIndicator /></Typography>
            {!section && <div className={classes.autoCompleteRoot}>
              <div className={classes.autoCompleteInner}>
                <AutoCompleteDialog
                  onChange={() => setCancelSectionAutoPopulate(true)}
                  placeholder={'Search section'}
                  vocab={EDITION_SECTION}
                  onSelect={(term) => {
                    if (term) {
                      setSection(term);
                      setValidSection(true);
                    }
                  }}
                />
              </div>
            </div>}
          </FormControl>
        </Grid>
        {section && <Grid container>
          <Tag label={section.name} onRemove={() => setSection(null)} />
        </Grid>}
        {!validSection && <Grid item>
          <Typography color={'error'}>Please select an edition section</Typography>
        </Grid>}
      </>}

      {!specialEdition && <Grid item className={classes.fieldRoot}>
        <FormControl error fullWidth className={classes.formControl}>
          <Typography component={'p'} variant={'body2'} className={classes.label}>Daily edition<RequiredIndicator /></Typography>
          {!dailyEdition && <AutoCompleteGeneric
            placeholder={'Search by edition name'}
            items={dailyOptions.map(({ data: { title, id } }) => ({ label: title, id }))}
            onChange={text => setDailyQuery(text)}
            onSelect={(selection) => {
              setDailyEdition(selection);
              setSpecialEdition(null);
              setValidEdition(true);
            }}
          />}
        </FormControl>
      </Grid>}
      {dailyEdition && <Grid container>
        <Tag label={dailyEdition.label} onRemove={() => setDailyEdition(null)} />
      </Grid>}
      {!(specialEdition || dailyEdition) && <Grid container justify={'center'}>
        <Grid item>
          <Typography component={'span'} variant={'body2'}>OR</Typography>
        </Grid>
      </Grid>}
      {!dailyEdition && <Grid item>
        <FormControl fullWidth className={classes.formControl}>
          <Typography component={'p'} variant={'body2'} className={classes.label}>Special edition<RequiredIndicator /></Typography>
          {!specialEdition && <AutoCompleteGeneric
            placeholder={'Search by edition name'}
            items={specialOptions.map(({ data: { title, id } }) => ({ label: title, id }))}
            onChange={text => setSpecialQuery(text)}
            onSelect={(selection) => {
              setSpecialEdition(selection);
              setDailyEdition(null);
              setValidEdition(true);
            }}
          />}
        </FormControl>
      </Grid>}
      {specialEdition && <Grid container>
        <Tag label={specialEdition.label} onRemove={() => setSpecialEdition(null)} />
      </Grid>}
      {!validEdition && <Grid item>
        <Typography color={'error'}>Please assign an edition (daily or special edition)</Typography>
      </Grid>}
      <Grid item className={classes.buttonRoot}>
        <Grid container justify={'flex-end'}>
          <Grid item>
            <Button variant={'contained'} color={'primary'} onClick={onSubmitForm}>{submitButtonText}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Dialog>);
};

ExportToEdition.defaultProps = {
  editionDraftList: {},
  sections: [],
  articleSections: [],
  defaultEditionSection: null,
  hideSection: false,
  dialogTitle: 'Export to edition',
  submitButtonText: 'Export',
};

ExportToEdition.propTypes = {
  hideSection: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  editionListDraftLoad: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchFlatVocab: PropTypes.func.isRequired,
  editionDraftList: PropTypes.object,
  sections: PropTypes.array,
  articleSections: PropTypes.array,
  defaultEditionSection: PropTypes.object,
  dialogTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export default withStyles(style)(connect(
  ({
    article: { sections: articleSections },
    edition: { editionDraftList },
    vocab: { pugpig_section: { items: sections }, defaultEditionSection },
  }) => ({ editionDraftList, articleSections, sections, defaultEditionSection }),
  { editionListDraftLoad, fetchFlatVocab: fetchVocab },
)(ExportToEdition));
