import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { EventListBreadcrumb } from './EventList';
import { fetchEvent, disposeEvent, initEvent } from '../actions/event';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

import EventMain from '../components/event/EventMain';
import EventSidebar from '../components/event/EventSidebar';
import EventControlButtons from '../components/event/EventControlButtons';

const EventCreate = (props) => {
  const {
    match: { params: { id, action } },
    fetchEvent: doFetch,
    disposeEvent: doDispose,
    initEvent: doInit,
  } = props;
  const breadcrumb = [
    ...EventListBreadcrumb,
    {
      title: /edit|preview/.test(action) ? 'Edit Event' : 'New Event',
    },
  ];

  useEffect(() => {
    doInit();
    if (id) {
      doFetch(id);
    }
    return () => {
      doDispose();
    };
  }, [id]);

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <EventControlButtons />
        </PageToolbar>
      }
    >
      <PageContent direction={'row'}>
        <EventMain />
      </PageContent>
      <EventSidebar />
    </Page>
  );
};

EventCreate.propTypes = {
  match: PropTypes.object.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  disposeEvent: PropTypes.func.isRequired,
  initEvent: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { fetchEvent, disposeEvent, initEvent },
)(EventCreate);
