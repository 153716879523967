import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const styles = theme => ({
  root: {
  },
  noSideGutter: {
    padding: theme.spacing(0),
  },
  topGutterOnly: {
    padding: theme.spacing(2, 0, 0, 0),
  },
});

function TabPanel(props) {
  const { classes, children, value, index, gutter, topGutterOnly, ...other } = props;
  let cls = '';
  if (!gutter) {
    cls = classes.noSideGutter;
  } else if (topGutterOnly) {
    cls = classes.topGutterOnly;
  }
  return (
    <Typography
      className={classes.root}
      component={'div'}
      role={'tabpanel'}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box className={cls} p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
  gutter: PropTypes.bool,
  topGutterOnly: PropTypes.bool,
};

TabPanel.defaultProps = {
  children: null,
  gutter: true,
  topGutterOnly: false,
};

export default withStyles(styles)(connect(
  () => ({}),
  {},
)(TabPanel));

