import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ClearIcon from '@material-ui/icons/Clear';

import TextField from '../ui/TextField';
import TextButton from '../ui/buttons/TextButton';
import { saveEditorsLetter, updateEditorsLetterProperty } from '../../actions/ad';
import { AUTHORS } from '../../constants/vocab';
import { InlineAutocomplete } from '../autocomplete';
import { deserializeString, serializeNodes } from '../editor/helper/serializer';
import EditorSimple from '../editor/EditorSimple';

import Tag from '../ui/Tag';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  authorRoot: {
    marginTop: theme.spacing(1),
  },
});

const EditorsLetterForm = ({
  classes, data, saveEditorsLetter: onSave,
  updateEditorsLetterProperty: updateProp,
}) => (
  <Grid className={classes.root} container direction={'column'}>
    <Grid item>
      <TextField
        defaultValue={''}
        label={'Title'}
        value={data.name}
        onChange={e => updateProp({ id: data.id, key: 'name', data: e.target.value })}
      />
    </Grid>
    <Grid item>
      <TextField
        defaultValue={''}
        label={'Heading'}
        value={data.heading}
        onChange={e => updateProp({ id: data.id, key: 'heading', data: e.target.value })}
      />
    </Grid>
    <Grid item>
      <TextField label={'Link'} value={data.link} onChange={e => updateProp({ id: data.id, key: 'link', data: e.target.value })} />
    </Grid>
    <Grid item>
      <InlineAutocomplete
        placeholder={'Search author'}
        classes={classes.authors}
        vocab={AUTHORS}
        onSelect={(selectedAuthor) => {
          updateProp({ id: data.id, key: 'author', data: selectedAuthor });
        }}
      />
      {data.author && <Grid className={classes.authorRoot} container xs={10}>
        <Tag
          selected
          onClickIcon={() => updateProp({ id: data.id, key: 'author', data: null })}
          label={data.author.name}
          Icon={ClearIcon}
        />
      </Grid>}
    </Grid>
    <Grid item>
      <EditorSimple
        placeholder={'Body'}
        content={data.body ? deserializeString(data.body) : null}
        onChange={nodes => {
          const markup = serializeNodes(nodes);
          updateProp({ id: data.id, key: 'body', data: markup });
        }}
      />
    </Grid>
    <Grid container direction={'row-reverse'}>
      <Grid item>
        <TextButton size={'large'} onClick={() => onSave(data)}>Submit</TextButton>
      </Grid>
    </Grid>
  </Grid>
);

EditorsLetterForm.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  saveEditorsLetter: PropTypes.func.isRequired,
  updateEditorsLetterProperty: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({ }),
  { saveEditorsLetter, updateEditorsLetterProperty },
)(EditorsLetterForm));

