import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import TextField from 'components/ui/TextField';

// @todo handle label with non edit mode
const ConfigField = ({ edit, value, label, onChange }) => {
  if (!edit) {
    return  <Typography variant="body1">{value}</Typography>;
  }
  return <TextField
    margin="none"
    value={value}
    label={label}
    onChange={onChange}
  />;
};

ConfigField.propTypes = {
  edit: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

ConfigField.defaultProps = {
  label: '',
};

export default ConfigField;
