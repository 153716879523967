import { ELEMENT_VOUCHER, PROPS_SNIPPET, PROP_LOCATION, PROP_AMOUNT } from './defaults';
import { VOUCHER } from '../../../../../../constants/editor/dataTypes';

export const convertToVoucher = data => ({
  type: ELEMENT_VOUCHER,
  [PROP_LOCATION]: data[PROP_LOCATION] || '',
  [PROPS_SNIPPET]: data[PROPS_SNIPPET] || '',
  [PROP_LOCATION]: data[PROP_LOCATION] || 'UK',
  [PROP_AMOUNT]: data[PROP_AMOUNT] || "6",
  children: [{ text: '' }],
});

export const convertFromVoucher = data => ({
  type: VOUCHER,
  data: {
    [PROP_LOCATION]: data[PROP_LOCATION] || '',
    [PROPS_SNIPPET]: data[PROPS_SNIPPET] || '',
    [PROP_LOCATION]: data[PROP_LOCATION] || 'UK',
    [PROP_AMOUNT]: data[PROP_AMOUNT] || "6",
  },
});
