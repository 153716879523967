import { setDefaults } from '@udecode/slate-plugins';
import renderElementButtonLink from './renderElementButtonLink';
import { DEFAULTS_BUTTON_LINK } from './defaults';

const ButtonLinkPlugin = (options) => {
  const { buttonLink } = setDefaults(options, DEFAULTS_BUTTON_LINK);
  return ({
    renderElement: renderElementButtonLink(options),
    voidTypes: [buttonLink.type],
  });
};

export default ButtonLinkPlugin;
