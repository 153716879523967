import VisualStoryElement from './components/VisualStoryElement';

export const ELEMENT_VISUAL_STORY = 'visualStory';
export const PROP_STORIES = 'stories';
export const PROP_BUTTON_DISPLAY = 'buttonDisplay';

export const DEFAULTS_VISUAL_STORY = {
  visualStory: {
    component: VisualStoryElement,
    type: ELEMENT_VISUAL_STORY,
    rootProps: {
      className: 'slate-visualStory',
    },
  },
};
