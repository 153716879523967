import { keyFieldsForAPI, keyFieldsForCMS } from "../helper";
import {
  FIELD_HERO_IMAGE_LOCAL_CAPTION,
  FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
  FIELD_HERO_VIDEO_LOCAL_CAPTION,
  FIELD_HERO_VIDEO_LOCAL_COPYRIGHT,
} from "../common/commonFields";

export const FIELD_NID = "nid";

export const FIELD_HEADLINE = "headline";
export const FIELD_SUB_HEADLINE = "subHeadline";
export const FIELD_FYI = "fyi";
export const FIELD_SHORT_HEADLINE = "shortHeadline";
export const FIELD_SOCIAL_HEADLINE = "socialHeadline";
export const FIELD_SEO_HEADLINE = "seoHeadline";
export const FIELD_BODY = "body";
export const FIELD_URL_KEYWORDS = "urlKeywords";
export const FIELD_SOCIAL_IMAGE = "socialImage";
export const FIELD_PRIORITY = "editorPriority";
export const FIELD_WORKFLOW = "workflow";
export const FIELD_UNPUBLISHED_DATE = "unPublishDateTime";
export const FIELD_SCHEDULE_DATE = "scheduleDateTime";
export const FIELD_VANITY_DATE = "vanityDateTime";
export const FIELD_PATH = "path";
export const FIELD_PATH_ORIGINAL = "originalUrl";
export const FIELD_REMOTE_ID = "remoteId";
export const FIELD_IS_PUBLISHED = "isPublished";
export const FIELD_IS_SENSITIVE = "isSensitive";
export const FIELD_IS_PREMIUM = "isPremium";
export const FIELD_IS_HIDDEN_FROM_LAYOUT = "isHideFromLayoutPages";
export const FIELD_IS_EMBARGO = "isEmbargo";
export const FIELD_HIDE_COMMENTING = "isHideCommenting";
export const FIELD_NOTES = "notes";
export const FIELD_PUBLICATIONS = "publications";
export const FIELD_ATEX = "isAtex";
export const FIELD_CHANGED = "changed";
export const FIELD_STRUCTURED = "structuredData";

export const FIELD_SECTIONS = "sections";
export const FIELD_SECTIONS_PRIMARY = "sectionsPrimary";
export const FIELD_SECTIONS_ADDITIONAL = "sectionsAdditional";
export const FIELD_AUTHORS = "authors";
export const FIELD_TOPICS = "topics";
export const FIELD_TAGS = "tags";
export const FIELD_CHAIN = "chain";
export const FIELD_EVENTS = "events";
export const FIELD_PEOPLE = "people";
export const FIELD_TEAMS = "teams";
export const FIELD_CONTENT_SOURCE = "contentSource";
export const FIELD_ARTICLE_TYPE = "articleType";

export const FIELD_PRODUCTS = "products";

// hero
export const FIELD_HERO = "hero";
export const FIELD_HERO_IMAGE = "heroImage";
export const FIELD_HERO_VIDEO = "heroVideo";
export const FIELD_HERO_GALLERY = "heroGallery";

export const FIELD_BADGE_PREFIX = "sponsorPrefix"; // :( JP call it sponsor, Indy call it badge
export const FIELD_BADGE_LINK = "badgeLink";
export const FIELD_BADGE_IMAGE = "badgeImage"; // badgeImage is listed as a field and an extra
export const FIELD_BADGE_LOGO = "badgeLogo"; // badgeLogo used by JP external CMS, data imported as a string url
export const FIELD_BADGE_TITLE = "badgeTitle";

export const CMS_SERVER_TIMESTAMP = "cmsServerTimestamp";
export const FIELD_SERVER_TIMESTAMP = "cms_server_timestamp";

export const ARTICLE_PAYLOAD_FIELDS = [
  FIELD_ARTICLE_TYPE,
  FIELD_UNPUBLISHED_DATE,
  FIELD_PATH,
  FIELD_PATH_ORIGINAL,
  FIELD_REMOTE_ID,
  FIELD_HEADLINE,
  FIELD_SUB_HEADLINE,
  FIELD_SHORT_HEADLINE,
  FIELD_SOCIAL_HEADLINE,
  FIELD_SEO_HEADLINE,
  FIELD_URL_KEYWORDS,
  FIELD_HERO,
  FIELD_SOCIAL_IMAGE,
  FIELD_SECTIONS,
  FIELD_AUTHORS,
  FIELD_TOPICS,
  FIELD_CHAIN,
  FIELD_EVENTS,
  FIELD_PEOPLE,
  FIELD_TEAMS,
  FIELD_TAGS,
  FIELD_CONTENT_SOURCE,
  FIELD_PRODUCTS,
  FIELD_BODY,
  FIELD_IS_PUBLISHED,
  FIELD_IS_SENSITIVE,
  FIELD_SCHEDULE_DATE,
  FIELD_WORKFLOW,
  FIELD_NOTES,
  FIELD_ATEX,
  FIELD_IS_PREMIUM,
  FIELD_PRIORITY,
  FIELD_HIDE_COMMENTING,
  FIELD_VANITY_DATE,
  FIELD_PUBLICATIONS,
  FIELD_BADGE_IMAGE,
  FIELD_BADGE_PREFIX,
  FIELD_BADGE_LINK,
  FIELD_BADGE_TITLE,
  FIELD_BADGE_LOGO,
  FIELD_IS_HIDDEN_FROM_LAYOUT,
  CMS_SERVER_TIMESTAMP,
  // FIELD_IS_EMBARGO,
];

// extras
export const FIELD_SEO_DESCRIPTION = "seoDescription";
export const FIELD_FEATURE_TITLE = "featureTitle";
export const FIELD_CANONICAL_URL = "canonicalUrl";
export const SELF_FIELD_HREFLANG = "selfHreflangRegion";
export const FIELD_HREFLANG = "alternateHreflangRegion";
export const FIELD_HREFLANG_URL = "alternateHrefLangUrl";
export const FIELD_URL_REDIRECT = "urlRedirect";
export const FIELD_AUTHOR_INFO = "authorInfo";
export const FIELD_VERDICT = "verdict";
export const FIELD_QUESTION_ANSWERS = "questionAnswers";
export const FIELD_IS_BREAKING_NEWS = "isBreakingNews";
export const FIELD_STAR_RATING = "starRating";
export const FIELD_HIDE_NEWSLETTER_SIGNUP = "isHideNewsletterSignUp";
export const FIELD_LUXURY_COMMERCIAL = "backgroundColour";
export const FIELD_DISABLE_AFFILIATE = "isDisableAffiliate";
export const FIELD_DISABLE_AUTO_GALLERY = "isDisableAutoGallery";
export const FIELD_SHOW_SPONSORED = "isShowSponsoredCapsule";
export const FIELD_IS_HIDDEN_FROM_APPLE = "isHideFromAppleNews";
export const FIELD_IS_HIDDEN_FROM_VIDEO = "isHideFromNewsNow";
export const FIELD_IS_HIDDEN_FROM_FACEBOOK = "isHideFromFIA";
export const FIELD_IS_HIDDEN_FROM_AMP = "isHideFromAMP";
export const FIELD_HIDE_DISPLAY_ADS = "isHideDisplayAds";
export const FIELD_HIDE_HARDCODED_ADS = "isHideHardCodedAds";
export const FIELD_HIDE_PREROLL_ADS = "isHidePreRollAds";
export const FIELD_DISABLE_ALL_ADS = "isDisableAllAds";
export const FIELD_DISABLE_IMPORT_UPDATE = "isDisableImportUpdate";
export const FIELD_DISPLAY_DATE_PREF = "displayDatePreference";

export const FIELD_COMPARE_STATE = "compareState";
export const FIELD_SCOREBOARD_COMPETITION = "selectedCompetition";
export const FIELD_SCOREBOARD_SEASON = "selectedSeason";
export const FIELD_SCOREBOARD_MATCH = "selectedMatch";
export const FIELD_LIVEBLOG = "liveblog";
export const FIELD_LIVEBLOG_KEYPOINTS = "liveblogKeypoints";
export const FIELD_LIVEBLOG_KEYPOINTS_TITLE = "liveblogKeypointsTitle";
export const FIELD_COMMERCIAL_CAMPAIGN = "commercialCampaign";
export const FIELD_SILENT_UPDATE = "silentUpdate";
export const FIELD_AUTO_VIDEO = "autoVideo";
export const FIELD_VIDEO_PROVIDER = "videoProvider";

export const FIELD_PIXELS_AI_PLAYLIST = "pixelsAiPlaylist";
export const FIELD_PIXELS_AI_ID = "pixelsAiId";

// ES
export const FIELD_LOCATION_ADDRESS = "locationAddress";
export const FIELD_LOCATION_LINK = "loactionLink";

// API FIELD
export const FIELD_LAST_UPDATED = "field_article_updated";

export const ARTICLE_EXTRA_FIELDS = [
  FIELD_FEATURE_TITLE,
  FIELD_IS_BREAKING_NEWS,
  FIELD_FYI,
  FIELD_HERO_IMAGE_LOCAL_CAPTION,
  FIELD_HERO_IMAGE_LOCAL_COPYRIGHT,
  FIELD_HERO_VIDEO_LOCAL_CAPTION,
  FIELD_HERO_VIDEO_LOCAL_COPYRIGHT,
  FIELD_AUTHOR_INFO,
  FIELD_HIDE_NEWSLETTER_SIGNUP,
  FIELD_DISABLE_AUTO_GALLERY,
  FIELD_DISABLE_AFFILIATE,
  FIELD_DISABLE_IMPORT_UPDATE,
  FIELD_DISABLE_ALL_ADS,
  FIELD_HIDE_DISPLAY_ADS,
  FIELD_HIDE_HARDCODED_ADS,
  FIELD_HIDE_PREROLL_ADS,
  FIELD_URL_REDIRECT,
  FIELD_SEO_DESCRIPTION,
  FIELD_CANONICAL_URL,
  FIELD_STAR_RATING,
  SELF_FIELD_HREFLANG,
  FIELD_LOCATION_ADDRESS,
  FIELD_LOCATION_LINK,
  FIELD_HREFLANG_URL,
  FIELD_HREFLANG,
  FIELD_IS_HIDDEN_FROM_VIDEO,
  FIELD_IS_HIDDEN_FROM_FACEBOOK,
  FIELD_IS_HIDDEN_FROM_AMP,
  FIELD_IS_HIDDEN_FROM_APPLE,
  FIELD_SHOW_SPONSORED,
  FIELD_DISPLAY_DATE_PREF,
  FIELD_COMPARE_STATE,
  FIELD_COMMERCIAL_CAMPAIGN,
  FIELD_QUESTION_ANSWERS,
  FIELD_LIVEBLOG,
  FIELD_LIVEBLOG_KEYPOINTS,
  FIELD_LIVEBLOG_KEYPOINTS_TITLE,
  FIELD_VERDICT,
  FIELD_LUXURY_COMMERCIAL,
  FIELD_SCOREBOARD_COMPETITION,
  FIELD_SCOREBOARD_SEASON,
  FIELD_SCOREBOARD_MATCH,
  FIELD_SILENT_UPDATE,
  FIELD_AUTO_VIDEO,
  FIELD_VIDEO_PROVIDER,
  FIELD_STRUCTURED,
  FIELD_PIXELS_AI_PLAYLIST,
  FIELD_PIXELS_AI_ID,
];

export const ARTICLE_SENSITIVE_FIELDS = [
  FIELD_HIDE_COMMENTING,
  FIELD_HIDE_DISPLAY_ADS,
  FIELD_HIDE_HARDCODED_ADS,
  FIELD_HIDE_PREROLL_ADS,
];

// Timeline overrides
export const FIELD_IS_CARD_STYLE = "isCard";
export const FIELD_IS_FREE_ARTICLE = "isFreeArticle";

export const ARTICLE_FIELD_MAPPING = [
  [FIELD_HEADLINE, "title", "Headline"],
  [FIELD_PUBLICATIONS, "field_publications", "Publications"],
  [FIELD_SUB_HEADLINE, "field_lead", "Standfirst"],
  [FIELD_SEO_HEADLINE, "field_seo_headline", "Meta title"],
  [FIELD_SHORT_HEADLINE, "field_short_headline", "Short headline"],
  [FIELD_SOCIAL_HEADLINE, "field_social_headline", "Social headline"],
  [FIELD_BODY, "field_body_json", "Body"],
  [FIELD_HERO, "field_hero_media", "Hero media"],
  [FIELD_SOCIAL_IMAGE, "field_og_image", "Social image"],
  [FIELD_PRIORITY, "field_priority", "Editorial priority"],
  [FIELD_IS_PUBLISHED, "status", "Status"],
  [
    FIELD_IS_HIDDEN_FROM_LAYOUT,
    "field_hide_from_layout_pages",
    "Hide from layout pages",
  ],
  [FIELD_IS_PREMIUM, "field_premium", "Premium article"],
  [FIELD_SECTIONS, "field_sections", "Section(s)"],
  [FIELD_SECTIONS_PRIMARY, "", "Section"], // merged on save to FIELD_SECTIONS
  [FIELD_SECTIONS_ADDITIONAL, "", "Additional Section(s)"], // merged on save to FIELD_SECTIONS
  [FIELD_AUTHORS, "field_authors", "Author(s)"],
  [FIELD_IS_SENSITIVE, "field_sensitive", "Sensitive article"],
  [FIELD_ATEX, "field_send_to_atex", "Send to ATEX"],
  [FIELD_BADGE_IMAGE, "field_sponsor_image", "Badge"],
  [FIELD_BADGE_PREFIX, "field_sponsor_prefix", "Prefix"],
  [FIELD_BADGE_LINK, "field_sponsor_url", "Link"],
  [FIELD_BADGE_TITLE, "field_sponsor_name", "Title"],
  [FIELD_BADGE_LOGO, "field_sponsor_logo", "Logo"],
  [FIELD_NOTES, "field_notes", "Notes"],
  [FIELD_URL_KEYWORDS, "field_url_keywords", "URL keywords"],
  [FIELD_WORKFLOW, "field_workflow", "Workflow"],
  [FIELD_IS_EMBARGO, "field_embargo", "Embargo article"],
  [FIELD_HIDE_COMMENTING, "field_commenting", "Hide comments"],
  [FIELD_DISABLE_AFFILIATE, FIELD_DISABLE_AFFILIATE, "Hide affiliate"],
  [FIELD_DISABLE_IMPORT_UPDATE, FIELD_DISABLE_IMPORT_UPDATE, "Disable Import"],
  [FIELD_UNPUBLISHED_DATE, "field_article_un_publish", "Unpublish date"],
  [FIELD_IS_HIDDEN_FROM_VIDEO, "isHideFromNewsNow"],
  [FIELD_SCHEDULE_DATE, "field_article_display_date", "Schedule Date"],
  [FIELD_VANITY_DATE, "field_article_vanity_date", "Vanity Date"],
  [FIELD_PATH, "path", "Path"],
  [FIELD_PATH_ORIGINAL, "field_original_url", "Original Path"],
  [FIELD_REMOTE_ID, "field_remote_id", "Remote ID"],
  [FIELD_QUESTION_ANSWERS, FIELD_QUESTION_ANSWERS, "Q & A"],
  [FIELD_VERDICT, FIELD_VERDICT, "Verdict"],
  [FIELD_PRODUCTS, "field_products", "Product"],
  [FIELD_COMPARE_STATE, FIELD_COMPARE_STATE, FIELD_COMPARE_STATE],
  [FIELD_STAR_RATING, FIELD_STAR_RATING, "Star rating"],

  [FIELD_IS_BREAKING_NEWS, FIELD_IS_BREAKING_NEWS, "Breaking news"],
  [
    FIELD_HIDE_NEWSLETTER_SIGNUP,
    FIELD_HIDE_NEWSLETTER_SIGNUP,
    "Hide newsletter sign up",
  ],
  [SELF_FIELD_HREFLANG, SELF_FIELD_HREFLANG, "selfHreflangRegion"],
  [FIELD_HREFLANG, FIELD_HREFLANG, "alternateHreflangRegion"],
  [FIELD_HREFLANG_URL, FIELD_HREFLANG_URL, "alternateHrefLangUrl"],
  [FIELD_LUXURY_COMMERCIAL, FIELD_LUXURY_COMMERCIAL, "Luxury commercial"],
  [FIELD_URL_REDIRECT, FIELD_URL_REDIRECT, "URL redirect"],
  [FIELD_FEATURE_TITLE, FIELD_FEATURE_TITLE, "Feature title"],
  [
    FIELD_DISABLE_AUTO_GALLERY,
    FIELD_DISABLE_AUTO_GALLERY,
    "Disable auto-gallery",
  ],
  [FIELD_SHOW_SPONSORED, FIELD_SHOW_SPONSORED, "Show sponsored capsule"],
  [
    FIELD_IS_HIDDEN_FROM_APPLE,
    FIELD_IS_HIDDEN_FROM_APPLE,
    "Hide from Apple News",
  ],
  [
    FIELD_IS_HIDDEN_FROM_FACEBOOK,
    FIELD_IS_HIDDEN_FROM_FACEBOOK,
    "Hide from Facebook IA",
  ],
  [FIELD_IS_HIDDEN_FROM_AMP, FIELD_IS_HIDDEN_FROM_AMP, "Hide from AMP"],
  [FIELD_HIDE_DISPLAY_ADS, FIELD_HIDE_DISPLAY_ADS, "Hide display ads"],
  [FIELD_HIDE_HARDCODED_ADS, FIELD_HIDE_HARDCODED_ADS, "Hide hardcoded ads"],
  [FIELD_HIDE_PREROLL_ADS, FIELD_HIDE_PREROLL_ADS, "Hide pre-roll ads"],
  [FIELD_DISPLAY_DATE_PREF, FIELD_DISPLAY_DATE_PREF, "Display date"],
  [FIELD_FYI, "fyi"],
  [FIELD_AUTHOR_INFO, FIELD_AUTHOR_INFO, "Author information"],
  [FIELD_CANONICAL_URL, FIELD_CANONICAL_URL, "Cannonical Url"],
  [FIELD_SEO_DESCRIPTION, FIELD_SEO_DESCRIPTION, "SEO Description"],
  [FIELD_DISABLE_ALL_ADS, FIELD_DISABLE_ALL_ADS, "Disable all ads"],
  [FIELD_LIVEBLOG, FIELD_LIVEBLOG, "Liveblog"],
  [FIELD_LIVEBLOG_KEYPOINTS, FIELD_LIVEBLOG_KEYPOINTS, "Liveblog Keypoints"],
  [
    FIELD_LIVEBLOG_KEYPOINTS_TITLE,
    FIELD_LIVEBLOG_KEYPOINTS_TITLE,
    "Liveblog Keypoints Title",
  ],
  [FIELD_TOPICS, "field_topics", "Topics"],
  [FIELD_CHAIN, "field_chain", "Chain"],
  [FIELD_EVENTS, "field_events", "Events"],
  [FIELD_PEOPLE, "field_people", "People"],
  [FIELD_TEAMS, "field_teams", "Teams"],
  [FIELD_TAGS, "field_tags", "Tags"],
  [FIELD_CONTENT_SOURCE, "field_content_source", "Content source"],
  [FIELD_ARTICLE_TYPE, "field_article_type", "Article type"],
  [FIELD_SILENT_UPDATE, FIELD_SILENT_UPDATE, "Update was silent"],
  [FIELD_STRUCTURED, FIELD_STRUCTURED, "Structured Data"],
  [FIELD_AUTO_VIDEO, FIELD_AUTO_VIDEO, "Video auto replacement"],
  [FIELD_VIDEO_PROVIDER, FIELD_VIDEO_PROVIDER, "Video Provider"],
  [
    FIELD_SCOREBOARD_COMPETITION,
    FIELD_SCOREBOARD_COMPETITION,
    "Scoreboard Competition",
  ],
  [FIELD_SCOREBOARD_SEASON, FIELD_SCOREBOARD_SEASON, "Scoreboard Season"],
  [FIELD_SCOREBOARD_MATCH, FIELD_SCOREBOARD_MATCH, "Scoreboard Match"],
  [FIELD_PIXELS_AI_PLAYLIST, FIELD_PIXELS_AI_PLAYLIST, "Playlist"],
  [FIELD_PIXELS_AI_ID, FIELD_PIXELS_AI_ID, "Pixels AI ID"],
  ["", "field_tags", "Tags"], // field_tags not used in CMS
  [FIELD_HERO_IMAGE, "", "Hero Image"], // Hero Image no used in API
  [CMS_SERVER_TIMESTAMP, "", "Server time"], // server current time
  [FIELD_HERO_VIDEO, "", "Hero Video"], // Hero Video no used in API
  [FIELD_HERO_GALLERY, "", "Hero Gallery"], // Hero Gallery no used in API
  // INDY & ES ONLY
  [FIELD_COMMERCIAL_CAMPAIGN, FIELD_COMMERCIAL_CAMPAIGN, "Commercial Campaign"],
  // ES ONLY
  [FIELD_LOCATION_ADDRESS, FIELD_LOCATION_ADDRESS, "Location Address"],
  [FIELD_LOCATION_LINK, FIELD_LOCATION_LINK, "Location Link"],
];
export const ARTICLE_FIELD_MAPPING_CMS_KEYED = keyFieldsForCMS(
  ARTICLE_FIELD_MAPPING
);
export const ARTICLE_FIELD_MAPPING_API_KEYED = keyFieldsForAPI(
  ARTICLE_FIELD_MAPPING
);
