import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import TaxonomyForm from '../TaxonomyForm';
import AuthorForm from '../AuthorForm';
import { vocabSave, termAuthorSave, fetchVocabTerm, disposeVocabTerm } from '../../../actions/vocab';

const TaxonomySettingsContext = ({
  currentTerm, vocab, termSimpleSave: onSave, termAuthorSave: onAuthorSave,
  fetchVocabTerm: fetchTheLatest, disposeVocabTerm: dispose, currentTermFromAPI,
}) => {
  // make sure the current term is the latest (fetch from api)
  useEffect(() => {
    fetchTheLatest([vocab, currentTerm.id]);
    return () => {
      dispose();
    };
  }, [currentTerm]);
  const term = {
    ...currentTerm,
    ...currentTermFromAPI,
  };
  return (<ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Settings</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails variant={'full'}>
      { vocab !== 'authors' && <TaxonomyForm onSave={onSave} term={term} vocab={vocab} /> }
      { vocab === 'authors' && <AuthorForm author={term} onSave={onAuthorSave} />}
    </ContextPanelDetails>
  </ContextPanel>);
};

TaxonomySettingsContext.defaultProps = {
  currentTermFromAPI: null,
};

TaxonomySettingsContext.propTypes = {
  currentTerm: PropTypes.object.isRequired,
  vocab: PropTypes.string.isRequired,
  currentTermFromAPI: PropTypes.object,
  termSimpleSave: PropTypes.func.isRequired,
  termAuthorSave: PropTypes.func.isRequired,
  fetchVocabTerm: PropTypes.func.isRequired,
  disposeVocabTerm: PropTypes.func.isRequired,
};

export default connect(
  ({ vocab: { currentTermFromAPI } }) => ({ currentTermFromAPI }),
  {
    termSimpleSave: vocabSave, termAuthorSave, fetchVocabTerm, disposeVocabTerm,
  })(TaxonomySettingsContext);
