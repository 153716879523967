import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
});

const BadgeRoot = ({ classes, children }) =>
  (<Grid container spacing={1} className={classes.root}>{children}</Grid>);

BadgeRoot.defaultProps = {
};

BadgeRoot.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(BadgeRoot);

