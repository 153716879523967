import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel } from '@material-ui/core';
import { deserializePlainText, serializeNodes } from '../editor/helper/serializer';

import EditorSimple from '../editor/EditorSimple';
import EditorFull, { VARIANT_POST } from '../editor/Editor';

export const TYPE_MARKUP = 'markup';
export const TYPE_FULL = 'full';

const styles = theme => ({
  control: {
    width: '100%',
    background: theme.palette.background.contrast,
  },
  label: {
    padding: '0 5px',
    marginLeft: -5,
    background: theme.palette.background.contrast,
    '&.focused': {
      color: theme.palette.primary.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  outlined: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.border.radius,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.focused': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      margin: '-1px',
    },
    '&.error': {
      borderColor: theme.palette.error.main,
    },
  },
  markup: {
    padding: '14px',
  },
  full: {},
});

const MarkupField = (props) => {
  const {
    classes, className, variant, label, data, placeholder, onChange, type, editorVariant,
    error, ...rest
  } = props;
  const Editor = type === TYPE_FULL ? EditorFull : EditorSimple;
  const controlClasses = [classes.control];
  if (className) {
    controlClasses.push(className);
  }
  const [isActive, setActive] = useState(!!data || !!placeholder);
  const [hasFocus, setFocus] = useState(false);
  const labelClasses = [];
  const fieldClasses = [classes[type], classes[variant]];
  if (isActive) {
    labelClasses.push(classes.label);
  }
  if (hasFocus) {
    labelClasses.push('focused');
    fieldClasses.push('focused');
  }
  if (error) {
    fieldClasses.push('error');
    labelClasses.push('error');
  }
  return (
    <FormControl className={controlClasses.join(' ')}>
      {label && <InputLabel
        className={labelClasses.join(' ')}
        shrink={isActive}
        variant={variant}
      >{label}</InputLabel>}
      <Editor
        className={fieldClasses.join(' ')}
        content={typeof data === 'string' ? deserializePlainText(data) : data}
        onFocus={() => {
          setActive(true);
          setFocus(true);
        }}
        onBlur={() => {
          setActive(!!data);
          setFocus(false);
        }}
        onChange={(value) => {
          if (typeof data === 'string') {
            onChange(serializeNodes(value));
          } else {
            onChange(value);
          }
        }}
        placeholder={placeholder}
        variant={editorVariant}
        {...rest}
      />
    </FormControl>
  );
};

MarkupField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  editorVariant: PropTypes.string,
  error: PropTypes.bool,
};

MarkupField.defaultProps = {
  className: null,
  data: '',
  label: '',
  placeholder: '',
  variant: 'outlined',
  type: TYPE_MARKUP,
  editorVariant: VARIANT_POST,
  error: false,
};

export default withStyles(styles)(MarkupField);
