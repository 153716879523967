import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { push } from "connected-react-router";

import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid/Grid";
import EditIcon from "@material-ui/icons/Edit";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import { fetchArticle, disposeFetchArticle } from "../../../actions/article";
import {
  fetchViafouraNotifications,
  disposeViafouraNotification,
} from "../../../actions/viafouraNotification";
import { translateInternalArticle } from "../../../actions/translation";
import ThumbnailImage from "../../table/content/ThumbnailImage";
import OpenInNewTab from "../../ui/buttons/OpenInNewTabButton";
import IconButton from "../../ui/buttons/IconButton";
import { TableBody, TableRow, TableCell } from "../../ui/table/Table";
import { ImportAction } from "./ArticleListTranslation";
import ViafouraIcon from "../../../assets/icons/ViafouraIcon";
import ListTable from "../../table/ListTable";
import TableHeader from "../../table/TableHeader";
import { CREATE_VIAFOURA_NOTIFICATION } from "../../../constants/permission";
import { FIELD_ARTICLE } from "../../../constants/viafouraNotification/viafouraNotificationFields";
import { setLocalProp } from "../../../actions/dataState";
import {
  getScheduledEndDate,
  getScheduledStartDate,
  getScheduledDisplayStatus,
  getScheduledDisplayDate,
  getIsPublished,
  getPublishDisplayDate,
} from "../../helper/scheduler";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { setHeader } from "../../../actions/table";
import ViafouraNotificationDialog from "../../dialog/ViafouraNotificationDialog";
import { hasPermissions } from "../../helper/utils";
import { CLIENT_STANDARD, CLIENT_TINDLE } from "../../../constants/env";
import { MemoQ } from "./MemoQ";

const ArticleListTable = (props) => {
  const {
    totalArticle,
    articles,
    textQuery,
    workflow,
    articleType,
    mediaType,
    status,
    fromDate,
    toDate,
    section,
    author,
    contentSource,
    isArticleFetching,
    publicationSource,
    push: routeChange,
    translationMode,
    translateInternalArticle: startTranslateImport,
    pathname,
    fetchArticle: fetchList,
    disposeFetchArticle: disposeList,
    fetchViafouraNotifications: fetchNotifications,
    disposeViafouraNotification: disposeNotification,
    setLocalProp: setProp,
    setHeader: setTableHeader,
    headers: tableHeaders,
    permissions,
    publicationConfig,
  } = props;

  const queryData = [
    contentSource,
    textQuery,
    workflow,
    articleType,
    mediaType,
    status,
    fromDate,
    toDate,
    section,
    author,
    pathname,
  ];

  const getFetchData = (page, rowsPerPage) => ({
    publicationSource: translationMode ? publicationSource : null,
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    q: textQuery,
    start: getScheduledStartDate(status, fromDate),
    end: getScheduledEndDate(status, toDate),
    workflow,
    status,
    heroType: mediaType,
    tids: [section?.id, author?.id, articleType, contentSource]
      .filter((x) => !!x)
      .join("|"),
  });
  const { client } = useSelector((state) => state.frame);
  useEffect(
    () => () => {
      disposeList();
    },
    []
  );

  const accessViafoura =
    publicationConfig.viafoura !== undefined &&
    hasPermissions(permissions)(CREATE_VIAFOURA_NOTIFICATION);
  const [showNotificationsDialog, setShowNotificationsDialog] = useState(false);

  const headers = [
    "Image",
    "Headline",
    "Section",
    "Author",
    "Status",
    "Workflow",
    "Id",
  ];
  if (tableHeaders?.sourceId) {
    headers.push("Source Id");
  }
  if (tableHeaders?.notificationCount) {
    headers.push("Notifications");
  }
  if (tableHeaders?.redirectCount) {
    headers.push("Redirect");
  }
  headers.push("Updated/Scheduled");
  headers.push("");

  return (
    <ListTable
      isFetching={isArticleFetching}
      total={totalArticle}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={fetchList}
    >
      <TableHeader
        headers={headers.map((header) => ({ id: header, label: header }))}
        hasBorder
        actions={[
          <MenuItem key={"sourceIdAction"} onClick={() => {}}>
            <ToggleSwitch
              label={"Source Id"}
              value={!!tableHeaders?.sourceId}
              onChange={(e) => setTableHeader({ sourceId: e.target.checked })}
            />
          </MenuItem>,
          <React.Fragment key={"viafouraAction"}>
            {accessViafoura && (
              <MenuItem onClick={() => {}}>
                <ToggleSwitch
                  label={"Notifications"}
                  value={!!tableHeaders?.notificationCount}
                  onChange={(e) =>
                    setTableHeader({ notificationCount: e.target.checked })
                  }
                />
              </MenuItem>
            )}
            <MenuItem onClick={() => {}}>
              <ToggleSwitch
                label={"Redirect"}
                value={!!tableHeaders?.redirectCount}
                onChange={(e) =>
                  setTableHeader({ redirectCount: e.target.checked })
                }
              />
            </MenuItem>
            ,
          </React.Fragment>,
        ]}
      />
      <TableBody>
        {articles.length === 0 && (
          <TableRow>
            <TableCell colSpan={headers.length} align={"center"}>
              No articles found
            </TableCell>
          </TableRow>
        )}
        {articles.length > 0 &&
          articles
            .filter((article) => article && article.type)
            .sort((a, b) => {
              if (status !== "scheduled") return 0;
              return a.publish - b.publish;
            })
            .map(
              ({
                id,
                authors,
                hero,
                status: articleStatus,
                title,
                sections,
                path,
                workflow: articleWorkflow,
                updated,
                publish,
                imported,
                remoteId,
                viafoura,
                articleRedirects,
              }) => (
                <TableRow key={id} hasActions={!translationMode}>
                  <TableCell>
                    <ThumbnailImage hero={hero} />
                  </TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>
                    {sections.map(({ name }) => name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {Array.isArray(authors) &&
                      authors.map(({ name }) => name).join(", ")}
                  </TableCell>
                  <TableCell>
                    {getScheduledDisplayStatus(articleStatus, publish)}
                  </TableCell>
                  <TableCell>{articleWorkflow}</TableCell>
                  <TableCell>{id}</TableCell>
                  {tableHeaders?.sourceId && <TableCell>{remoteId}</TableCell>}
                  {tableHeaders?.notificationCount && (
                    <TableCell>{viafoura?.count}</TableCell>
                  )}
                  {tableHeaders?.redirectCount && (
                    <TableCell align="center">
                      {articleRedirects ? <SwapHorizIcon /> : ""}
                    </TableCell>
                  )}
                  {client === CLIENT_TINDLE ? (
                    <TableCell>
                      {getPublishDisplayDate(articleStatus, publish)}
                    </TableCell>
                  ) : (
                    <TableCell>
                      {getScheduledDisplayDate(articleStatus, updated, publish)}
                    </TableCell>
                  )}
                  <TableCell>
                    <Grid container wrap={"nowrap"} justify={"flex-end"}>
                      {translationMode && (
                        <MemoQ
                          id={id}
                          imported={imported}
                          onClick={() => startTranslateImport(id)}
                        />
                      )}
                      {translationMode && (
                        <ImportAction
                          id={id}
                          imported={imported}
                          onClick={() => startTranslateImport(id)}
                        />
                      )}
                      {!translationMode && (
                        <>
                          <IconButton
                            onClick={() => routeChange(`/article/edit/${id}`)}
                          >
                            <EditIcon />
                          </IconButton>
                          {articleStatus && <OpenInNewTab target={path} />}
                          {accessViafoura && (
                            <IconButton
                              disabled={
                                getScheduledDisplayStatus(
                                  articleStatus,
                                  publish
                                ) !== "Published"
                              }
                              onClick={() => {
                                setShowNotificationsDialog(true);
                                setProp(FIELD_ARTICLE, id);
                                fetchNotifications();
                              }}
                            >
                              <ViafouraIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              )
            )}
      </TableBody>
      {accessViafoura && showNotificationsDialog && (
        <ViafouraNotificationDialog
          open={showNotificationsDialog}
          handleClose={() => {
            disposeNotification();
            setShowNotificationsDialog(false);
          }}
        />
      )}
    </ListTable>
  );
};

ArticleListTable.propTypes = {
  fetchArticle: PropTypes.func.isRequired,
  fetchViafouraNotifications: PropTypes.func.isRequired,
  disposeViafouraNotification: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  setHeader: PropTypes.func.isRequired,
  articles: PropTypes.array,
  textQuery: PropTypes.string,
  contentSource: PropTypes.string,
  workflow: PropTypes.string,
  articleType: PropTypes.string,
  mediaType: PropTypes.string,
  status: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  section: PropTypes.string,
  author: PropTypes.string,
  totalArticle: PropTypes.number,
  isArticleFetching: PropTypes.bool,
  publicationSource: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  translateInternalArticle: PropTypes.func.isRequired,
  disposeFetchArticle: PropTypes.func.isRequired,
  translationMode: PropTypes.bool,
  headers: PropTypes.object,
  permissions: PropTypes.array.isRequired,
  publicationConfig: PropTypes.object.isRequired,
};

ArticleListTable.defaultProps = {
  textQuery: "",
  workflow: "",
  articleType: "",
  mediaType: "",
  status: "",
  fromDate: "",
  toDate: "",
  contentSource: "",
  section: "",
  author: "",
  articles: [],
  totalArticle: 0,
  isArticleFetching: false,
  publicationSource: null,
  translationMode: false,
  headers: {},
};

export default connect(
  ({
    table: { headers },
    article: { articles, totalArticle, isArticleFetching },
    router: {
      location: { pathname },
    },
    login: { permissions },
    frame: {
      selectedPublication: { publicationConfig },
    },
  }) => ({
    articles,
    headers,
    totalArticle,
    isArticleFetching,
    pathname,
    permissions,
    publicationConfig,
  }),
  {
    push,
    fetchArticle,
    translateInternalArticle,
    disposeFetchArticle,
    setHeader,
    fetchViafouraNotifications,
    disposeViafouraNotification,
    setLocalProp,
  }
)(ArticleListTable);
