export const CREATE_ARTICLE_CONTENT = 'create article content';
export const ARTICLE_LIST = 'access content api protected';
export const ACCESS_CONTENT = 'access content';
export const CREATE_LIVEBLOG = 'create flowz_liveblog container';
export const CREATE_MEDIA = 'access flowz media api';
export const CREATE_DAILY_EDITION = 'create flowz_collection pugpig_container';
export const EDIT_DAILY_EDITION = 'access flowz_collection api';
export const TRANSLATION = 'flowz translate translate articles';
export const CREATE_LAYOUT_SECTION = 'create layout section_layout';
export const CREATE_LAYOUT_TOPIC = 'create layout topic_layout';
export const CREATE_LAYOUT_AUTHOR = 'create layout author_layout';
export const CREATE_LAYOUT_ARTICLE_TYPE = 'create layout article_type_layout';
export const CREATE_LAYOUT_CHAIN = 'create layout chain_layout';
export const CREATE_SIDEBAR = 'create layout sidebar_layout';
export const CREATE_FULL_NAV_MENU = 'create flowz_menu full_nav';
export const ADMINISTER_USER = 'administer users';
export const CREATE_MANUAL_LIST = 'create flowz_manual_list section';
export const CREATE_AD_CONFIG = 'create flowz_ad ad_config';
export const CREATE_EDITOR_LETTER = 'create flowz_ad premium_letter';
export const CREATE_PREMIUM_AD = 'create flowz_ad premium_ad';
export const CREATE_REDIRECT = 'create flowz_redirect 404';
export const CREATE_STATIC_PAGE = 'create flowz_static_page basic';
export const CREATE_STORY = 'create flowz_visual_story default';
export const CREATE_VIAFOURA_NOTIFICATION = 'create flowz_viafoura_log default';
// export const CREATE_EVENT = 'create flowz_event generic_event';
export const CREATE_EVENT = ACCESS_CONTENT; // @todo added for testing
