import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  AFFILIATE_FETCH_DASHBOARD,
  AFFILIATE_FETCH_DASHBOARD_SUCCESS,
  AFFILIATE_FETCH_DASHBOARD_FAILURE,
  AFFILIATE_CHECK_DOMAIN,
  AFFILIATE_CHECK_DOMAIN_SUCCESS,
  AFFILIATE_CHECK_DOMAIN_FAILURE,
} from '../../constants/actionTypes/affilate';
import apiCatchError from '../helper/notification';

export const fetchAffiliateDashboard = (action$, state$) => action$.pipe(
  ofType(AFFILIATE_FETCH_DASHBOARD),
  withLatestFrom(state$),
  switchMap(([, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/affilates/${domain}`).pipe(
      mergeMap(response => of({
        type: AFFILIATE_FETCH_DASHBOARD_SUCCESS,
        value: response,
      })),
      apiCatchError(AFFILIATE_FETCH_DASHBOARD_FAILURE),
    ),
  ),
);

export const searchAffiliateDomain = (action$, state$) => action$.pipe(
  ofType(AFFILIATE_CHECK_DOMAIN),
  withLatestFrom(state$),
  switchMap(([{ value }, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/affilates/domain-search/${domain}?q=${value}`).pipe(
      mergeMap(response => of({
        type: AFFILIATE_CHECK_DOMAIN_SUCCESS,
        value: Object.values(response),
      })),
      apiCatchError(AFFILIATE_CHECK_DOMAIN_FAILURE),
    ),
  ),
);
