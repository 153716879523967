import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import SectionTitle from '../SectionTitle';

const CONTEXT_PADDING = 3;

export const ContextPanel = withStyles(theme => ({
  root: {
    flexBasis: '100%',
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:last-child': {
      borderBottom: '0 none',
    },
  },
  expanded: {
    margin: '0 !important',
    '&:before': {
      opacity: '1 !important',
    },
  },
  noHeading: {
    paddingTop: theme.spacing(CONTEXT_PADDING),
  },
  focused: {
    backgroundColor: theme.palette.background.paleBlue,
  },
}))((props) => {
  const { noHeading, focused, classes, ...passThru } = props;
  const { noHeading: noHeadingStyle, focused: focusedStyle, ...passStyles } = classes;
  const classArray = [];
  if (noHeading) {
    classArray.push(noHeadingStyle);
  }
  if (focused) {
    classArray.push(focusedStyle);
  }
  return (<Accordion className={classArray.join(' ')} expanded elevation={0} classes={passStyles} {...passThru} />);
});

export const ContextPanelSummary = withStyles(theme => ({
  root: {
    padding: 0,
    minHeight: 0,
  },
  content: {
    margin: 0,
  },
  expandIcon: {
    marginRight: 0,
    padding: `${theme.spacing(0.5, 1)} !important`,
    transform: 'rotate(0deg)',
    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
  expanded: {
    minHeight: 'auto !important',
    margin: '0 !important',
    padding: 0,
  },
}))(props => <AccordionSummary {...props} />);

export const ContextPanelHeading = withStyles(theme => ({
  heading: {
    padding: theme.spacing(CONTEXT_PADDING),
    flex: 1,
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  noPadding: {
    padding: 0,
  },
  appendButton: {
    '& .MuiIconButton-root': {
      float: 'right',
    },
  },
}))((props) => {
  const { classes, noPadding, hasChanges, required, children, appendButton } = props;
  const headingClasses = [classes.heading];
  if (noPadding) headingClasses.push(classes.noPadding);
  if (appendButton) headingClasses.push(classes.appendButton);
  return (<SectionTitle className={headingClasses.join(' ')} required={required} hasChanges={hasChanges}>{children}</SectionTitle>);
});

export const ContextPanelHeadingButton = withStyles(theme => ({
  heading: {
    flex: 1,
    '& .MuiButton-root': {
      width: '100%',
      padding: theme.spacing(CONTEXT_PADDING),
      borderRadius: 0,
    },
    '& .MuiButton-label': {
      justifyContent: 'space-between',
    },
  },
}))((props) => {
  const { classes, children } = props;
  return (<div className={classes.heading}>{children}</div>);
});

export const ContextPanelFieldHeading = withStyles(theme => ({
  heading: {
    padding: theme.spacing(1, 0, 1, 0),
  },
}))(({ classes, children }) =>
  <Typography varient={'h3'} className={classes.heading}>{children}</Typography>);

export const ContextPanelFormLabel = withStyles(theme => ({
  heading: {
    padding: theme.spacing(1, 0, 1, 0),
  },
}))(({ classes, children }) =>
  <FormLabel className={classes.heading}>{children}</FormLabel>);

const ContextPanelDetailsDefault = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, CONTEXT_PADDING, CONTEXT_PADDING, CONTEXT_PADDING),
    flexDirection: 'column',
    maxHeight: 360,
    overflow: 'auto',
  },
}))(AccordionDetails);

const ContextPanelDetailsFull = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, CONTEXT_PADDING, CONTEXT_PADDING, CONTEXT_PADDING),
    flexDirection: 'column',
  },
}))(AccordionDetails);

export const ContextPanelDetails = (props) => {
  const { variant, ...rest } = props;
  return (variant === 'full') ? <ContextPanelDetailsFull {...rest} /> : <ContextPanelDetailsDefault {...rest} />;
};

ContextPanelDetails.propTypes = {
  variant: PropTypes.string,
};

ContextPanelDetails.defaultProps = {
  variant: 'default',
};

export const ContextPanelDetailsSection = withStyles(theme => ({
  root: {
    margin: theme.spacing(0, -CONTEXT_PADDING),
    padding: theme.spacing(1, CONTEXT_PADDING, CONTEXT_PADDING),
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:last-child': {
      borderBottom: '0 none',
    },
  },
}))(({ classes, children }) =>
  <div className={classes.root}>{children}</div>);

const ContextLabel = withStyles(theme => ({
  root: {
    paddingRight: theme.spacing(0.5),
  },
}))(({ classes, children }) => <Typography variant={'body2'}>{children}</Typography>);

export const ContextPanelItem = withStyles(theme => ({
  root: {
    minHeight: 38,
    margin: theme.spacing(0.1, 0),
    '&:first-child': {
      marginTop: theme.spacing(-1.5),
    },
    '&:last-child': {
      marginBottom: theme.spacing(-1.5),
    },
  },
}))(({ classes, label, children }) => (
  <Grid className={classes.root} container justify={'space-between'} alignItems={'center'}>
    <ContextLabel>{label}</ContextLabel>
    {children}
  </Grid>
));
