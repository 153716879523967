import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '../ui/TextField';

import { setAdsTxt } from '../../actions/staticPage';

const styles = theme => ({});

const AdsTxtInput = (props) => {
  const { classes, adsTxtContent, setAdsTxt: onChange } = props;
  return (<Grid container>
    <Grid item xs={12}>
      <TextField
        value={adsTxtContent}
        onChange={e => onChange(e.target.value)}
        multiline
        rows={80}
      />
    </Grid>
  </Grid>);
};

AdsTxtInput.propTypes = {
  classes: PropTypes.object.isRequired,
  setAdsTxt: PropTypes.func.isRequired,
  adsTxtContent: PropTypes.string.isRequired,
};

export default withStyles(styles)(connect(
  ({ ad: { adsTxtContent } }) => ({ adsTxtContent }), {
    setAdsTxt,
  })(AdsTxtInput));
