import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { getArticleSuggestions } from '../../actions/article';
import { fetchVocab } from '../../actions/vocab';

import AutoComplete from '../autocomplete/AutoComplete';
import Tag from './Tag';
import { makeGetPopular, makeGetRelated, makeGetVocab } from '../../selectors/vocab/vocab';

export const VARIANT_CONTEXT = 'context';
export const VARIANT_DEFAULT = 'default';

const styles = theme => ({
  [VARIANT_DEFAULT]: {},
  [VARIANT_CONTEXT]: {
    '& .MuiOutlinedInput-root': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& .MuiInputAdornment-root': {
      whiteSpace: 'normal',
    },
    '& .MuiInputBase-inputAdornedStart': {
      paddingBottom: theme.spacing(2),
    },
  },
});

// @todo make suggestions non-article specific move function out of generic component
const TagSelector = (props) => {
  const {
    classes, placeholder, vocab, items, popular, related, selectedTerms, onSelect, onRemove,
    hierarchical, hideSuggestions, variant, sections,
    getArticleSuggestions: getSuggestions, fetchVocab: onFetch,
    ...rest
  } = props;
  const [suggestedTerms, setSuggestedTerms] = useState([]);
  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query) {
      onFetch({
        q: query,
        vocab,
        limit: 30,
        offset: 0,
      });
    }
  }, [query]);
  useEffect(() => {
    if (!hideSuggestions) {
      const terms = [...popular, ...related]
        .filter(x => !!x)
        .reduce((acc, term) => {
          if (acc.some(({ id }) => id === term.id)) {
            return acc;
          }
          acc.push(term);
          return acc;
        }, []);
      setSuggestedTerms(
        terms.filter(({ id: relatedId }) => !selectedTerms.find(({ id }) => relatedId === id)),
      );
    }
  }, [vocab, popular, related, selectedTerms, hideSuggestions]);
  return (
    <AutoComplete
      className={classes[variant]}
      placeholder={placeholder}
      items={items}
      onChange={value => setQuery(value)}
      onSelect={item => onSelect([vocab, item])}
      onClick={() => !hideSuggestions && getSuggestions()}
      suggestedTags={!hideSuggestions ? suggestedTerms : []}
      hierarchical={hierarchical}
      startIcon={Array.isArray(selectedTerms) && selectedTerms.filter(term => !!term).length > 0
        ? selectedTerms.filter(term => !!term).map(term => (
          <Tag
            key={term.id}
            label={term.name}
            onClick={() => onRemove([vocab, term])}
            onRemove={() => onRemove([vocab, term])}
          />
        )) : null
      }
      {...rest}
    />
  );
};

TagSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  vocab: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  getArticleSuggestions: PropTypes.func.isRequired,
  fetchVocab: PropTypes.func.isRequired,
  sections: PropTypes.object.isRequired,
  items: PropTypes.array,
  popular: PropTypes.array,
  related: PropTypes.array,
  selectedTerms: PropTypes.array,
  placeholder: PropTypes.string,
  hierarchical: PropTypes.bool,
  hideSuggestions: PropTypes.bool,
  variant: PropTypes.string,
};

TagSelector.defaultProps = {
  placeholder: 'Search',
  selectedTerms: [],
  items: [],
  popular: [],
  related: [],
  hierarchical: false,
  hideSuggestions: false,
  variant: VARIANT_DEFAULT,
};

const mapStateToProps = (state, props) => {
  const getVocab = makeGetVocab();
  const getPopular = makeGetPopular();
  const getRelated = makeGetRelated();
  return {
    items: getVocab(state, props),
    popular: getPopular(state, props),
    related: getRelated(state, props),
    sections: state.vocab.sections,
  };
};

export default withStyles(styles)(connect(
  mapStateToProps,
  { getArticleSuggestions, fetchVocab },
)(TagSelector));
