import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_BUTTON_LINK } from './defaults';

const renderElementButtonLink = (options) => {
  const { buttonLink } = setDefaults(options, DEFAULTS_BUTTON_LINK);
  return getRenderElement(buttonLink);
};

export default renderElementButtonLink;
