import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Close';
import { fade } from '@material-ui/core/styles/colorManipulator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import {
  handleRelatedLinksItemDrop,
  clearRelatedLinksListItem,
  handlePinnedRelatedLinksItemDrop,
  clearPinnedRelatedLinksListItem,
} from '../../../../actions/manualList';
import { setLayoutContext } from '../../../../actions/layout';

import { CONTEXT_ARTICLE_SELECTOR, CONTEXT_COMPONENT } from '../../../../constants/contexts';

import IconButton from '../../../ui/buttons/IconButton';
import DropZoneArticle from '../../../ui/dropzones/DropZoneArticle';
import DraggableArticle from '../../../ui/draggables/DraggableArticle';
import { OVERRIDE_RELATED_LINKS, OVERRIDE_RELATED_LINKS_PINNED } from '../../../../constants/layout/layout';
import { ARTICLE_ID } from '../../../../entities/ManualListEntity';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  ui: {
    display: 'flex',
    justifyContent: 'center',
    width: '15%',
  },
  button: {
    height: '36px',
  },
  related: {
    width: '85%',
  },
  zone: {
    height: '30px',
    marginTop: '2px',
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.border.secondary.main, 0.6),
    overflow: 'hidden',
    '&.drop-enabled': {
      borderStyle: 'dashed',
      borderColor: '#000',
    },
    cursor: 'pointer',
  },
  zoneEmpty: {
    borderColor: fade(theme.palette.border.secondary.main, 0.3),
  },
  drag: {

  },
  articleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    margin: '-2px -5px !important',
    padding: '2px 5px !important',
    border: '0 none !important',
    '& .remove-related-article': {
      padding: 0,
      display: 'none',
    },
    '&:hover .remove-related-article': {
      display: 'block',
    },
  },
  articleTitle: {
    maxWidth: '100%',
  },
  icon: {
    width: 15,
    height: 15,
  },
});

const EmptyRelatedContent = ({ classes, setContext }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <div
    role="form"
    className={classes.articleWrapper}
    onClick={() => setContext(CONTEXT_ARTICLE_SELECTOR)}
  />
);

EmptyRelatedContent.propTypes = {
  classes: PropTypes.object.isRequired,
  setContext: PropTypes.func.isRequired,
};

const RelatedContent = ({
  classes, article, removeAction, setContext, index, itemIndex, listId, articleId, component, pinned,
}) => {
  const source = {
    field: pinned ? OVERRIDE_RELATED_LINKS_PINNED : OVERRIDE_RELATED_LINKS,
    listId,
    articleId,
    index,
    itemId: article[ARTICLE_ID],
    componentId: component.id,
    itemIndex,
  };
  return (
    <DraggableArticle
      article={article}
      source={source}
      className={classes.articleWrapper}
      onClick={() => setContext(
        CONTEXT_COMPONENT,
        { ...component, targetIndex: itemIndex },
      )}
    >
      <Typography className={classes.articleTitle} noWrap variant={'body2'}>{article.headline}</Typography>
      <IconButton className={'remove-related-article'} onClick={removeAction}>
        <RemoveIcon className={classes.icon} />
      </IconButton>
    </DraggableArticle>
  );
};

RelatedContent.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  removeAction: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  listId: PropTypes.number.isRequired,
  articleId: PropTypes.string.isRequired,
  itemIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  pinned: PropTypes.bool.isRequired,
};

const getContents = (article) => {
  if (typeof article === 'undefined' || !article) return EmptyRelatedContent;
  return RelatedContent;
};

const relatedLinksDataProcessor = (listId, articleId, itemIndex, index, component, limit) => ({ article, source }) => ({
  component,
  listId,
  articleId,
  item: article,
  itemIndex,
  index,
  source,
  limit,
});

const RelatedDropZone = (props) => {
  const {
    classes, article, listId, articleId, itemIndex, index, onDrop, removeAction, setContext, component, pinned,
    length,
  } = props;
  const Contents = getContents(article);
  const zoneClasses = [classes.zone];
  if (typeof article === 'undefined' || !article) {
    zoneClasses.push(classes.zoneEmpty);
  }
  return (
    <DropZoneArticle
      className={zoneClasses.join(' ')}
      dropHandler={onDrop}
      dataProcessor={relatedLinksDataProcessor(listId, articleId, itemIndex, index, component, length)}
    >
      <Contents
        classes={classes}
        removeAction={removeAction}
        setContext={setContext}
        article={article}
        listId={listId}
        index={index}
        itemIndex={itemIndex}
        component={component}
        pinned={pinned}
        articleId={articleId}
      />
    </DropZoneArticle>
  );
};

RelatedDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,
  articleId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  removeAction: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  pinned: PropTypes.bool.isRequired,
  length: PropTypes.number.isRequired,
  article: PropTypes.object,
  listId: PropTypes.number,
};

RelatedDropZone.defaultProps = {
  article: null,
  listId: null,
};

const RelatedDropZoneCollapsed = (props) => {
  const { classes, listId, articleId, itemIndex, onDrop, count, length, setContext, component } = props;
  return (
    <DropZoneArticle
      className={classes.zone}
      dropHandler={onDrop}
      dataProcessor={relatedLinksDataProcessor(listId, articleId, itemIndex, 0, component, length)}
      onClick={() => setContext(
        CONTEXT_COMPONENT,
        { ...component, targetIndex: itemIndex },
      )}
    >
      <Typography variant={'body2'}>Related links: {count}/{length}</Typography>
    </DropZoneArticle>
  );
};

RelatedDropZoneCollapsed.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  onDrop: PropTypes.func.isRequired,
  setContext: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,
  listId: PropTypes.number,
  articleId: PropTypes.string,
};

RelatedDropZoneCollapsed.defaultProps = {
  itemIndex: null,
  articleId: null,
  listId: null,
};

const ArticleRelatedLinks = (props) => {
  const {
    classes, overrides, listId, articleId, itemIndex, length, component, pinned, index: position,
    handleRelatedLinksItemDrop: onDrop,
    clearRelatedLinksListItem: clearItem,
    handlePinnedRelatedLinksItemDrop: pinnedDrop,
    clearPinnedRelatedLinksListItem: pinnedClear,
    setLayoutContext: setContext,
  } = props;
  const [links, setLinks] = useState([]);
  const [linkCount, setlinkCount] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setIsExpanded(false);
  }, [pinned]);
  useEffect(() => {
    const linksClone = overrides[OVERRIDE_RELATED_LINKS]
      ? [...overrides[OVERRIDE_RELATED_LINKS]]
      : [];
    setlinkCount(linksClone.filter(link => !!link).length);
    while (linksClone.length !== length) {
      if (linksClone.length < length) {
        linksClone.push(null);
      }
      if (linksClone.length > length) {
        linksClone.pop();
      }
    }
    setLinks(linksClone.map((link, index) => (<RelatedDropZone
      classes={classes}
      article={link}
      index={index}
      listId={listId}
      articleId={articleId}
      itemIndex={pinned ? position : itemIndex}
      onDrop={pinned ? pinnedDrop : onDrop}
      removeAction={() => (pinned
        ? pinnedClear(component, position, index)
        : clearItem(listId, articleId, index)
      )}
      length={length}
      setContext={setContext}
      component={component}
      pinned={pinned}
      key={`related-${listId}-${itemIndex}-${index}`}
    />)));
  }, [overrides[OVERRIDE_RELATED_LINKS], pinned, articleId, listId, position]);
  return (
    <div className={classes.root}>
      <div className={classes.ui}>
        <IconButton className={classes.button} onClick={() => setIsExpanded(!isExpanded)}>
          {!isExpanded && <KeyboardArrowDownIcon />}
          {isExpanded && <KeyboardArrowUpIcon />}
        </IconButton>
      </div>
      <div className={classes.related}>
        {!isExpanded && <RelatedDropZoneCollapsed
          setIsExpanded={setIsExpanded}
          classes={classes}
          count={linkCount > length ? length : linkCount}
          length={length}
          listId={listId}
          articleId={articleId}
          itemIndex={pinned ? position : itemIndex}
          onDrop={pinned ? pinnedDrop : onDrop}
          setContext={setContext}
          component={component}
        />}
        {isExpanded && links}
      </div>
    </div>
  );
};

ArticleRelatedLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  overrides: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  handleRelatedLinksItemDrop: PropTypes.func.isRequired,
  clearRelatedLinksListItem: PropTypes.func.isRequired,
  handlePinnedRelatedLinksItemDrop: PropTypes.func.isRequired,
  clearPinnedRelatedLinksListItem: PropTypes.func.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
  pinned: PropTypes.bool,
  index: PropTypes.number,
  itemIndex: PropTypes.number,
  listId: PropTypes.number,
  articleId: PropTypes.string,
};

ArticleRelatedLinks.defaultProps = {
  pinned: false,
  index: -1,
  listId: null,
  itemIndex: null,
  articleId: null,
};

export default withStyles(styles)(connect(
  () => ({}),
  {
    handleRelatedLinksItemDrop,
    clearRelatedLinksListItem,
    handlePinnedRelatedLinksItemDrop,
    clearPinnedRelatedLinksListItem,
    setLayoutContext,
  },
)(ArticleRelatedLinks));
