import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { getClassName } from '../../utils/propHelper';

const styles = {
  root: {},
  mediaWrapper: {
    position: 'relative',
    paddingTop: '62.5%',
    width: '100%',
    '&>*': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  square: {
    paddingTop: '100%',
  },
};

const MediaRatio = ({ classes, width, children, square }) => (<div style={{ width }}>
  <div
    className={getClassName({
      [classes.mediaWrapper]: true,
      [classes.square]: Boolean(square),
    })}
  >
    {children}
  </div>
</div>);

MediaRatio.defaultProps = {
  width: 180,
  square: false,
};

MediaRatio.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  square: PropTypes.bool,
};

export default withStyles(styles)(MediaRatio);
