import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';

const FlowCard = ({ children, ...rest }) =>
  (<Card square {...rest}>{children}</Card>);

FlowCard.defaultProps = {
  children: null,
};

FlowCard.propTypes = {
  children: PropTypes.node,
};

export default FlowCard;

