import generateKey from 'utils/generateKey';
import { PROP_IMAGE } from '../../article-link/defaults';
import { PROP_TITLE, PROP_EMBED_ID, ELEMENT_MONEY } from './defaults';


export const initialMoneyItem = () => ({
  id: generateKey(),
  [PROP_IMAGE]: null,
  partnerName: null,
  partnerAttributeOne: null,
  partnerAttributeTwo: null,
  partnerAttributeThree: null,
  ratingOne: null,
  ratingTwo: null,
  ratingThree: null,
  partnerCtaText: null,
  partnerCtaLink: null,
  partnerCtaSiteInfo: null,
  disclaimer: null
});

const deserializeParallaxItem = data => ({
  id: data.id || generateKey(),
  [PROP_IMAGE]: data[PROP_IMAGE] || null,
  partnerName: data.partnerName ? data.partnerName : null,
  partnerAttributeOne: data.partnerAttributeOne || null,
  partnerAttributeTwo: data.partnerAttributeTwo || null,
  partnerAttributeThree: data.partnerAttributeThree || null,
  ratingOne: data.ratingOne || null,
  ratingTwo: data.ratingTwo || null,
  ratingThree: data.ratingThree || null,
  partnerCtaText: data.partnerCtaText || null,
  partnerCtaLink: data.partnerCtaLink || null,
  partnerCtaSiteInfo: data.partnerCtaSiteInfo || null,
  disclaimer: data.disclaimer || null
});

const serializeParallaxItem = data => ({
  id: data.id || generateKey(),
  [PROP_IMAGE]: data[PROP_IMAGE] || null,
  partnerName: data.partnerName ? data.partnerName : null,
  partnerAttributeOne: data.partnerAttributeOne || null,
  partnerAttributeTwo: data.partnerAttributeTwo || null,
  partnerAttributeThree: data.partnerAttributeThree || null,
  ratingOne: data.ratingOne || null,
  ratingTwo: data.ratingTwo || null,
  ratingThree: data.ratingThree || null,
  partnerCtaText: data.partnerCtaText || null,
  partnerCtaLink: data.partnerCtaLink || null,
  partnerCtaSiteInfo: data.partnerCtaSiteInfo || null,
  disclaimer: data.disclaimer || null
});
export const convertToMoney = data => ({
  type: ELEMENT_MONEY,
  items: data.items?.map(deserializeParallaxItem) || [deserializeParallaxItem({})],
  children: [{ text: '' }],
});

export const convertFromMoney = data => ({
  type: ELEMENT_MONEY,
  data: {
    items: data.items?.map(serializeParallaxItem) || [serializeParallaxItem({})],

  },
});
