export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const SUCCESS = 'success';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const APP_CRASHED = 'APP_CRASHED';
export const APP_CRASH_CLOSE = 'APP_CRASH_CLOSE';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const ERROR_SAVE_MESSAGE_RESET = 'ERROR_SAVE_MESSAGE_RESET';
