import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeGetDisplayHeroBadge } from '../../../../selectors/article/article';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, 0.5),
  },
});

const HeroBadge = ({ classes, displayBadge }) => {
  if (!displayBadge) return null;
  return (
    <Grid item><Typography variant={'caption'} className={classes.root}>Hero</Typography></Grid>
  );
};

HeroBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  displayBadge: PropTypes.bool,
};

HeroBadge.defaultProps = {
  displayBadge: false,
};

const mapStateToProps = (state, props) => {
  const getDisplayHeroBadge = makeGetDisplayHeroBadge(props.type);
  return {
    displayBadge: getDisplayHeroBadge(state),
  };
};

export default withStyles(styles)(connect(
  mapStateToProps,
  {},
)(HeroBadge));

