import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import {
  DatePicker,
} from '@material-ui/pickers';

const style = theme => ({
  pickerRoot: {
    marginLeft: -18,
  },
  selectedDate: {
    padding: theme.spacing(2),
  },
  selectedTime: {
    padding: theme.spacing(2),
  },
});

const DEFAULT_SCHEDULE_DATE = moment().add(2, 'hours').unix();

const Scheduler = ({ classes, onChange, dateTime, id }) => {
  const [selectedDate, setSelectedDate] = useState(moment.unix(DEFAULT_SCHEDULE_DATE));
  const [invalidTime, setInvalidTime] = useState(false);
  const handleDateChange = (date) => {
    onChange(date.unix());
  };

  useEffect(() => {
    if (dateTime > 0) {
      setSelectedDate(moment.unix(dateTime));
    }
  }, [dateTime]);

  return (<Grid container wrap={'nowrap'} >
    <Grid container direction={'row'}>
      <Grid item>
        <div className={classes.pickerRoot}>
          <DatePicker
            margin={'normal'}
            id={'mui-pickers-date'}
            label={'Date'}
            variant={'static'}
            disableToolbar
            openTo={'date'}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
      </Grid>
      <Grid item>
        <div className={classes.selectedDate}>
          <Typography>{moment(selectedDate).format('DD MMM YYYY')}</Typography>
        </div>
        <div className={classes.selectedTime}>
          <FormControl >
            <TextField
              type={'time'}
              error={invalidTime}
              placeholder={'HH:mm'}
              defaultValue={moment.unix(dateTime || DEFAULT_SCHEDULE_DATE).format('HH:mm')}
              onChange={(e) => {
                try {
                  const [hour, minute] = e.target.value.split(':');
                  const validTimePattern = /\d{2}/;
                  if (validTimePattern.test(hour) && validTimePattern.test(minute)) {
                    selectedDate.set({
                      hour: parseInt(hour, 10),
                      minute: parseInt(minute, 10),
                    });
                    handleDateChange(selectedDate)
                    setInvalidTime(false)
                  } else {
                    setInvalidTime(true)
                  }
                } catch (ex) {
                  console.error(ex);
                }
              }}
            />
          </FormControl>
        </div>
      </Grid>
    </Grid>
  </Grid>);
};

Scheduler.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dateTime: PropTypes.number,
};

Scheduler.defaultProps = {
  dateTime: 0,
};

export default withStyles(style)(Scheduler);
