import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Modal from '@material-ui/core/Modal';

import { closeAppCrashAlert } from '../../../actions/page';
import Button from '../../ui/buttons/ButtonWarning';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  alertText: {
    margin: theme.spacing(2, 0),
  }
});

function getModalStyle() {
  const top = 50 ;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalAppCrash = (props) => {
  const {
    classes,
    showAppCrashDialog,
    closeAppCrashAlert: closeAlert,
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal
      open={showAppCrashDialog}
      onClose={() => closeAlert()}
    >
      <div style={modalStyle} className={classes.paper}>
        <Typography className={classes.alertText} variant={'body1'}>Please refresh your browser to continue editing.</Typography>
        <div>
          <Button size="small" onClick={() => closeAlert()}>Close</Button>
        </div>
      </div>
    </Modal>
  );
};

ModalAppCrash.propTypes = {
  classes: PropTypes.object.isRequired,
  closeAppCrashAlert: PropTypes.func.isRequired,
  showAppCrashDialog: PropTypes.bool,
};

ModalAppCrash.defaultProps = {
  showAppCrashDialog: false,
};

export default withStyles(styles)(connect(
  ({
    frame: { showAppCrashDialog },
  }) => ({
    showAppCrashDialog,
  }), { closeAppCrashAlert }
)(ModalAppCrash));
