import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialTabs from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import blueGrey from '@material-ui/core/colors/blueGrey';

export const Tabs = withStyles(theme => ({
  root: {
    display: 'block',
    width: '100%',
    '&:after': {
      display: 'block',
      content: '""',
      height: '2px',
      width: '100%',
      marginTop: '-6px',
      background: blueGrey[100],
    },
  },
  header: {
    width: '100%',
    margin: theme.spacing(-2),
    '& .MuiTabs-indicator': {
      height: '100%',
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  small: {
    minHeight: 0,
  },
  large: {
    minHeight: theme.spacing(6),
    '& .MuiTab-root': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontSize: theme.typography.h2.fontSize,
    },
  },
}))(({ size, header, classes, ...rest }) => (
  <MaterialTabs
    classes={{ root: header ? classes.header : classes.root }}
    className={classes[size]}
    {...rest}
  />
));

Tabs.propTypes = {
  indicatorColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
  header: PropTypes.bool,
};

Tabs.defaultProps = {
  indicatorColor: 'primary',
  textColor: 'primary',
  size: 'small',
  header: false,
};

export const Tab = withStyles(theme => ({
  root: {
    minWidth: 'unset',
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    lineHeight: '44px',
    minWidth: 'unset',
    color: theme.palette.primary.contrastText,
    '&.Mui-selected': {
      position: 'relative',
      zIndex: 1,
    },
  },
  small: {
    minHeight: theme.spacing(4),
    '& .MuiTab-wrapper': {
      ...theme.typography.h5,
    },
  },
}))(({ size, header, classes, ...rest }) => (
  <MaterialTab
    classes={{ root: header ? classes.header : classes.root }}
    className={classes[size]}
    {...rest}
  />
));

export const TabExtras = withStyles(theme => ({
  root: {
    margin: theme.spacing(0, 1),
  },
}))(({ classes, children }) => (
  <Grid
    container
    justify={'flex-end'}
    alignItems={'center'}
    classes={{ root: classes.root }}
  >{children}</Grid>
));
