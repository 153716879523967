import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import ImageList from '../embed/ImageList';
import ImageForm from '../embed/ImageForm';
import Button from '../ui/buttons/Button';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  content: {
    marginTop: 0,
  },
});

const TAB_LIBRARY = 0;
const TAB_ADDNEW = 1;

const TABS = [
  {
    label: 'Image library',
    value: TAB_LIBRARY,
  },
  {
    label: 'Add new image',
    value: TAB_ADDNEW,
  },
];

const ImageLibraryDialog = (props) => {
  const { classes, selectImage, onSaveNewImage, uploadOnly, ...rest } = props;
  const [tab, setTab] = useState(uploadOnly ? TAB_ADDNEW : TAB_LIBRARY);
  return (
    <Dialog
      tabs={uploadOnly ? [] : TABS}
      tab={tab}
      setTab={setTab}
      maxWidth="md"
      title={uploadOnly ? 'Add image' : ''}
      {...rest}
    >
      {tab === TAB_LIBRARY && <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12} />
        <ImageList
          action={data => (<Button
            onClick={() => {
              if (data.copyright) {
                return selectImage(data);
              }
              if (window.confirm(
                'The image you have selected has no credit: are you sure you wish to use it?',
              )) {
                selectImage(data);
              }
            }}
            variant={'contained'}
          >Select</Button>)}
        />
      </Grid>}
      {tab === TAB_ADDNEW && <div className={classes.root}>
        <ImageForm
          onSaveImage={onSaveNewImage}
          onUploaded={() => rest.handleClose()}
        />
      </div>}
    </Dialog>
  );
};

ImageLibraryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  selectImage: PropTypes.func.isRequired,
  onSaveNewImage: PropTypes.func.isRequired,
  temporaryFiles: PropTypes.object,
  uploadOnly: PropTypes.bool,
};

ImageLibraryDialog.defaultProps = {
  items: [],
  temporaryFiles: null,
  uploadOnly: false,
};

export default withStyles(style)(ImageLibraryDialog);
