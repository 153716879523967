import { ELEMENT_OPTA, PROP_EMBED_AMP, PROP_EMBED } from './defaults';
import { OPTA } from '../../../../../../constants/editor/dataTypes';

export const convertToOpta = data => ({
  type: ELEMENT_OPTA,
  [PROP_EMBED]: data[PROP_EMBED] || '',
  [PROP_EMBED_AMP]: data[PROP_EMBED_AMP] || '',
  children: [{ text: '' }],
});

export const convertFromOpta = data => ({
  type: OPTA,
  data: {
    [PROP_EMBED]: data[PROP_EMBED] || '',
    [PROP_EMBED_AMP]: data[PROP_EMBED_AMP] || '',
  },
});
