import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

import TextField from '../ui/TextField';
import Dialog from '../dialog/common/Dialog';
import DateTimePicker from '../ui/DateTimePicker';
import Select from '../ui/Select';
import { USER_EVENTS_CATEGORIES } from '../../layouts/UserEventList';
import Button from '../ui/buttons/Button';
import { saveUserEvent } from '../../actions/service/userEvents';
import PreviewImage from '../media/PreviewImage';
import MarkupField from "../ui/MarkupField";

const UserEventsDialog = ({
  saveUserEvent: saveEvent,
  handleClose,
  data,
  domain,
  ...rest
}) => {
  const [startDate, setStartDate] = useState(moment().unix());
  const [endDate, setEndDate] = useState(moment().unix());
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [ageRestriction, setAgeRestriction] = useState('');
  const [price, setPrice] = useState(0);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [img, setImg] = useState('');
  const [description, setDescription] = useState('');
  useEffect(() => {
    setStartDate(data?.startDate ? data.startDate / 1000 : moment().unix());
    setEndDate(data?.endDate ? data.endDate / 1000 : moment().unix());
    setName(data?.name || '');
    setLocation(data?.location || '');
    setCategory(data?.category || '');
    setAgeRestriction(data?.ageRestrictionVal || '');
    setPrice(data?.price || 0);
    setPhone(data?.phone || '');
    setEmail(data?.email || '');
    setWebsite(data?.website || '');
    setImg(data?.img || '');
    setDescription(data?.description || '');
  }, [data]);
  const getData = () => ({
    img,
    startDate: startDate * 1000,
    endDate: endDate * 1000,
    name,
    location,
    category,
    ageRestriction: !!ageRestriction,
    ageRestrictionVal: ageRestriction,
    price: price ? price : '',
    freeEvent: !price,
    phone,
    email,
    website,
    description,
    publication: data?.publication || domain,
    createdAt: data?.createdAt || moment().unix() * 1000,
    userId: data?.userId || '',
    uuid: data?.uuid || '',
  });
  return (
    <Dialog
      title={'Edit event'}
      maxWidth={'md'}
      handleClose={handleClose}
      margin
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {img && <PreviewImage src={img} />}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateTimePicker
                label="Start date"
                date={startDate}
                onChange={date => setStartDate(date.unix())}
                {...rest}
              />
            </Grid>
            <Grid item xs={6}>
              <DateTimePicker
                label="End date"
                date={endDate}
                onChange={date => setEndDate(date.unix())}
                {...rest}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={name}
                onChange={e => setName(e.target.value)}
                label="Event name"
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={location}
                onChange={e => setLocation(e.target.value)}
                label="Location"
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Category"
                items={USER_EVENTS_CATEGORIES}
                value={category}
                emptyValue="None"
                onChange={e => setCategory(e.target.value)}
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                label="Age restriction"
                items={['18+', '15+']}
                value={ageRestriction}
                emptyValue="None"
                onChange={e => setAgeRestriction(e.target.value)}
                margin="none"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={price}
                onChange={e => setPrice(e.target.value)}
                label="Price (£)"
                margin="none"
                helperText={'use 0 for free event'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MarkupField
            label="Description"
            data={description || ''}
            onChange={value => setDescription(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={phone}
            onChange={e => setPhone(e.target.value)}
            label="Contact (Phone)"
            margin="none"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            label="Contact (Email)"
            margin="none"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={website}
            onChange={e => setWebsite(e.target.value)}
            label="Website"
            margin="none"
          />
        </Grid>
        <Grid spacing={2} item container direction={'row-reverse'}>
          <Grid item>
            <Button
              variant={'contained'}
              onClick={() => {
                saveEvent(getData());
                handleClose();
              }}
            >save</Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

UserEventsDialog.propTypes = {
  saveUserEvent: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  domain: PropTypes.string.isRequired,
};

export default connect(
  ({
    frame: { selectedPublication: { domain } },
  }) => ({ domain }),
  { saveUserEvent },
)(UserEventsDialog);
