import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import { Filter } from 'mdi-material-ui';
import Select from './Select';
import { getClassName } from '../../utils/propHelper';

const styles = theme => ({
  formControl: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    '&:not(last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  noFlex: {
    flex: 'inherit',
  },
  icon: {
    width: 20,
  },
});

const DropdownFilter = ({
  classes, onChange, options, value, label, id, includeAll, noFlex, ...rest
}) => (
  <FormControl
    className={getClassName([
      classes.formControl,
      noFlex ? classes.noFlex : '',
    ])}
  >
    <Select
      label={label}
      labelId={id}
      value={value}
      defaultValue={''}
      onChange={e => onChange(e.target.value)}
      items={includeAll ? [{ id: '', name: 'All' }, ...options] : options}
      icon={<Filter className={classes.icon} color={'primary'} />}
      {...rest}
    />
  </FormControl>
);

DropdownFilter.defaultProps = {
  options: [],
  includeAll: false,
  noFlex: false,
};

DropdownFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  includeAll: PropTypes.bool,
  noFlex: PropTypes.bool,
};

export default withStyles(styles)(DropdownFilter);

