import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { componentSetProp } from 'actions/layout';

import Button from 'components/ui/buttons/Button';
import IconButton from 'components/ui/buttons/IconButton';
import {
  ArrowSortableDown,
  ArrowSortableList,
  ArrowSortableListItem,
  ArrowSortableUp,
} from 'components/ui/ArrowSortable';
import VisualStorySelectorDialog from 'components/dialog/VisualStorySelectorDialog';

import { SOURCE, SOURCE_MANUAL } from './VisualStoriesSource';

const STORIES = 'stories';

const VisualStoriesContents = ({
  component,
  componentSetProp: setProp,
}) => {
  const {
    [SOURCE]: source,
    [STORIES]: stories = [],
  } = component;

  const [open, setOpen] = useState(false);

  return (
    <Grid container spacing={2}>
      {source === SOURCE_MANUAL && stories.length > 0 && <Grid item xs={12}>
        <ArrowSortableList>
          {stories.map(({ title }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ArrowSortableListItem index={index} key={`varticle-${index}`} indexAvatar>
              <Typography variant={'body1'}>{title}</Typography>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    setProp(
                      component.id,
                      STORIES,
                      stories.filter((point, i) => i !== index),
                    );
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
                {index < stories.length - 1 && <ArrowSortableDown
                  onClick={() => {
                    setProp(
                      component.id,
                      STORIES,
                      arrayMove(stories, index, index + 1),
                    );
                  }}
                />}
                {index > 0 && <ArrowSortableUp
                  onClick={() => {
                    setProp(
                      component.id,
                      STORIES,
                      arrayMove(stories, index, index - 1),
                    );
                  }}
                />}
              </ListItemSecondaryAction>
            </ArrowSortableListItem>
          ))}
        </ArrowSortableList>
      </Grid>}
      {source === SOURCE_MANUAL && <Grid item container justify={'center'}>
        <Button
          onClick={() => setOpen(true)}
          startIcon={<AddCircleOutlineIcon />}
        >Add visual stories</Button>
      </Grid>}
      {open && <VisualStorySelectorDialog
        handleClose={() => {
          setOpen(false);
        }}
        onSelect={(selected) => {
          setProp(
            component.id,
            STORIES,
            [
              ...stories,
              ...selected,
            ],
          );
          setOpen(false);
        }}
        open={open}
      />}
    </Grid>
  );
};

VisualStoriesContents.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(VisualStoriesContents);
