import { setDefaults } from '@udecode/slate-plugins';
import renderElementArticleLink from './renderElementArticleLink';
import { DEFAULTS_ARTICLE_LINK } from './defaults';

const ArticleLinkPlugin = (options) => {
  const { articleLink } = setDefaults(options, DEFAULTS_ARTICLE_LINK);
  return ({
    renderElement: renderElementArticleLink(options),
    voidTypes: [articleLink.type],
  });
};

export default ArticleLinkPlugin;
