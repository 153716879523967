import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

import { FIELD_PAGES } from '../../../constants/story/storyFields';
import { addStoryPage, reorderStoryPage } from '../../../actions/story';

import Page from './Page';
import ComponentDropZone from '../../layout/components/common/ComponentDropZone';

const styles = theme =>({
  root: {
    borderTop: `0.5px solid ${theme.palette.border.primary.main}`,
    borderBottom: `0.5px solid ${theme.palette.border.primary.main}`,
  },
});

const SortableItem = SortableElement(props => <Page {...props} />);

const ComponentContainer = withStyles(styles)(({
  classes, pages, onDrop,
}) => (
  <div className={classes.root}>
    {pages.map((value, index) => ([
      <ComponentDropZone handleDropComponent={onDrop} delta={index} key={`dropzone${value.id}`} />,
      <SortableItem
        key={value.id}
        index={index}
        data={value}
      />,
    ]))}
    <ComponentDropZone handleDropComponent={onDrop} delta={pages.length} />
  </div>
));

const SortableList = SortableContainer(props => <ComponentContainer {...props} />);

const Pages = (props) => {
  const { pages, addStoryPage: addPage, reorderStoryPage: reorderPage } = props;
  return (
    <SortableList
      distance={1}
      useDragHandle
      pages={pages}
      onDrop={addPage}
      onSortEnd={({ oldIndex, newIndex }) => {
        reorderPage(oldIndex, newIndex);
      }}
    />
  );
};

Pages.propTypes = {
  addStoryPage: PropTypes.func.isRequired,
  reorderStoryPage: PropTypes.func.isRequired,
  pages: PropTypes.array,
};

Pages.defaultProps = {
  pages: [],
};

export default connect(
  ({ story: { [FIELD_PAGES]: pages } }) => ({ pages }),
  { addStoryPage, reorderStoryPage },
)(Pages);
