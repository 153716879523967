import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { checkAffiliateDomain, disposeCheckAffiliateDomain } from '../../actions/affiliate';

import DashboardCard, { DashboardCardHeader, DashboardCardContent } from '../common/dashboard/DashboardCard';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';

const DomainChecker = (props) => {
  const {
    domainResults, domainResultsFetching,
    checkAffiliateDomain: checkDomain,
    disposeCheckAffiliateDomain: disposeDomain,
  } = props;
  const [query, setQuery] = useState('');
  const [partners, setPartners] = useState([]);
  //
  useEffect(() => {
    if (query) {
      checkDomain(query);
    } else {
      disposeDomain();
    }
    return () => disposeDomain();
  }, [query]);
  useEffect(() => {
    setPartners([...new Set(domainResults.map(item => item.network))]);
  }, [domainResults]);
  return (
    <DashboardCard>
      <DashboardCardHeader title={'Domain Checker'} />
      <DashboardCardContent>
        <Grid container direction={'column'} spacing={3}>
          <Grid item>
            <TextSearchFilter
              placeholder={'Search by domain'}
              onSubmit={value => setQuery(value)}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h3'} color={'primary'}>Affiliate Partners</Typography>
              </Grid>
              {partners.length < 1 && domainResultsFetching && <Grid item xs={12}>
                <Typography variant={'body2'}>Searching partners...</Typography>
              </Grid>}
              {partners.length < 1 && !domainResultsFetching && query && <Grid item xs={12}>
                <Typography variant={'body2'}>No partners found</Typography>
              </Grid>}
              {partners.length > 0 && partners.map(partner => (<Grid key={partner} item xs={4}>
                <Typography variant={'body2'}>{partner}</Typography>
              </Grid>))}
            </Grid>
          </Grid>
        </Grid>
      </DashboardCardContent>
    </DashboardCard>
  );
};

DomainChecker.propTypes = {
  checkAffiliateDomain: PropTypes.func.isRequired,
  disposeCheckAffiliateDomain: PropTypes.func.isRequired,
  domainResults: PropTypes.array,
  domainResultsFetching: PropTypes.bool,
};

DomainChecker.defaultProps = {
  domainResults: [],
  domainResultsFetching: false,
};

export default connect(
  ({
    affiliate: { domainResults, domainResultsFetching },
  }) => ({ domainResults, domainResultsFetching }),
  { checkAffiliateDomain, disposeCheckAffiliateDomain },
)(DomainChecker);
