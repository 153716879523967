import { ofType } from 'redux-observable';
import {
  switchMap,
  withLatestFrom,
  combineLatest,
  map,
  filter,
  mergeMap,
  take,
  debounceTime,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import moment from 'moment';

import {
  ARTICLE_STATS_LOADED,
  ARTICLE_LIST_REJECTED,
  ARTICLE_STAT_FREQUENCY_CHANGE,
  FETCH_CMS_API_VERSION,
  FETCH_CMS_API_VERSION_SUCCESS,
  FETCH_CMS_API_VERSION_REJECTED,
} from '../../constants/actionTypes';
import apiCatchError from '../helper/notification';
import { PAGE_ENTER_DASHBOARD } from '../../constants/actionTypes/route';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const fetchArticleStats = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_DASHBOARD),
  combineLatest(action$.pipe(ofType(PUBLICATION_SELECTED), take(1))),
  withLatestFrom(state$),
  debounceTime(500),
  switchMap(([, { frame: { selectedPublication: { domain } } }]) => ajax.get(`/api/articles-per-day/${domain}`).pipe(
    mergeMap(({ response }) => of({
      type: ARTICLE_STATS_LOADED,
      value: response.map(item => ({ ...item, x: moment(item.x).format('DD-MM-YY') })),
    })),
    apiCatchError(ARTICLE_LIST_REJECTED),
  )),
);

export const fetchCMSAPIVersion = action$ => action$.pipe(
  ofType(FETCH_CMS_API_VERSION),
  switchMap(() => ajax.getJSON('/api/version').pipe(
    map(data => ({ type: FETCH_CMS_API_VERSION_SUCCESS, value: data })),
    apiCatchError(FETCH_CMS_API_VERSION_REJECTED),
  )),
);

export const articleStatsOnChange = (action$, state$) => action$.pipe(
  ofType(ARTICLE_STAT_FREQUENCY_CHANGE),
  withLatestFrom(state$),
  switchMap(([action, { frame: { selectedPublication: { domain } } }]) => ajax.get(`/api/articles-per-${action.value}/${domain}`).pipe(
    mergeMap(({ response }) => of({
      type: ARTICLE_STATS_LOADED,
      value: response.map(item => ({ ...item, x: moment(item.x).format('DD-MM-YY') })),
    })),
    apiCatchError(ARTICLE_LIST_REJECTED),
  )),
);

export default fetchArticleStats;
