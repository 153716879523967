import { PURGE } from 'redux-persist';
import {
  ADD_MORE_TRANSLATION_MARKUP,
  REMOVE_ARTICLE_TRANSLATION_COMPONENT,
  SAVE_TRANSLATION_ARTICLE_COMPONENT,
  SET_FOCUS_TRANSLATION_COMPONENT_INDEX,
  SET_TRANSLATED_ARTICLE_BODY,
  SWITCH_BODY_TRANSLATION_COMPONENT,
  TRANSLATION_FETCH_SECTION_SUCCESS, TRANSLATION_LIST_REQUEST,
  TRANSLATION_LIST_REQUEST_SUCCESS,
  TRANSLATION_LIST_REQUEST_REJECTED,
} from '../../constants/actionTypes';
import generateKey from '../../utils/generateKey';
import { initComp } from '../helper/editor';


const defaultState = {
  body: [],
  bodyMarkupFocusTranslation: 0,
  articles: [],
  sections: [],
  loading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TRANSLATION_FETCH_SECTION_SUCCESS:
      return { ...state, sections: action.value };

    case TRANSLATION_LIST_REQUEST_REJECTED:
      return { ...state,
        loading: false,
      };

    case TRANSLATION_LIST_REQUEST:
      return { ...state,
        loading: true,
      };

    case TRANSLATION_LIST_REQUEST_SUCCESS:
      return { ...state,
        articles: action.value,
        loading: false,
      };

    case SET_TRANSLATED_ARTICLE_BODY:
      return { ...state, body: action.value };

    case SET_FOCUS_TRANSLATION_COMPONENT_INDEX:
      return { ...state, bodyMarkupFocusTranslation: action.value };

    case REMOVE_ARTICLE_TRANSLATION_COMPONENT:
      if (state.body.length === 1) {
        return { ...state };
      }
      return { ...state, body: state.body.filter((item, i) => i !== action.value) };

    case SWITCH_BODY_TRANSLATION_COMPONENT: {
      const [id, type] = action.value;
      return { ...state,
        body: state.body.map((comp) => {
          if (comp.id === id) {
            return { ...comp, type };
          }
          return comp;
        }) };
    }

    case SAVE_TRANSLATION_ARTICLE_COMPONENT: {
      const [data, pos] = action.value;
      return { ...state,
        body: state.body.map((comp, i) => {
          if (i === pos) {
            return { ...comp, data: { ...comp.data, ...data } };
          }
          return comp;
        }) };
    }

    case ADD_MORE_TRANSLATION_MARKUP:
      return { ...state,
        body: [
          ...state.body.slice(0, action.value + 1),
          { ...initComp, id: generateKey() },
          ...state.body.slice(action.value + 1),
        ],
        bodyMarkupFocusTranslation: action.value + 1 };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
