import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import GetAppIcon from '@material-ui/icons/GetApp';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import capitalize from '@material-ui/core/utils/capitalize';
import { Prompt } from 'react-router-dom';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import PublishIcon from '@material-ui/icons/Publish';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SaveIcon from '@material-ui/icons/Save';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { FilterRemove } from 'mdi-material-ui';
import LaunchIcon from '@material-ui/icons/Launch';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import Loading from './Loading';

import EditionDetailForm from '../components/edition/EditionDetailForm';
import IconButton from '../components/ui/buttons/IconButton';
import Button from '../components/ui/buttons/Button';
import ButtonGroup from '../components/ui/buttons/ButtonGroup';
import TableSortable from '../components/ui/table/TableSortable';
import AvatarNumbered from '../components/ui/AvatarNumbered';

import {
  editionDetailFetch, editionDetailNew,
  saveEditionDetail,
  reOrderEditionPages,
  editionDetailEnter,
  editionDetailDispose,
  removePageFromContainer, pingEditionApp,
} from '../actions/edition';

import { EditionListBreadcrumb } from './EditionList';
import { PREVIEW_MODE_DESKTOP } from '../constants/preview';
import { UNSAVE_CHANGES_PROMPT_TEXT } from '../constants/prompt';
import { EDITION_ACTION_PUBLISH, EDITION_ACTION_UPDATE } from '../constants/actionTypes/edition';
import { CMS_DATE_FORMAT } from '../constants/common';

import ManualFileDownloadDialog from '../components/dialog/ManualFileDownloadDialog.js';

const styles = theme => ({
  summaryRow: {
    marginBottom: theme.spacing(-3),
  },
  summaryTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

const humanFriendlyPageType = type => type
  .split('_')
  .filter(word => !/pugpig|page/.test(word))
  .map(capitalize)
  .join(' ');

const EditionDetail = (props) => {
  const {
    classes,
    push: routeChange,
    unsavedEditionDetail,
    publicationConfig,
    match: { params: { editionId, type } },
    editionDetailNew: newEdition,
    editionDetailFetch: fetchDetail,
    saveEditionDetail: onSave,
    removePageFromContainer: onRemove,
    pingEditionApp: onPingEditionApp,
    reOrderEditionPages: onReorder,
    editionDetailDispose: onDisposePage,
    editionDetailEnter: onEnterPage,
    editionDetail,
  } = props;
  const previewMode = PREVIEW_MODE_DESKTOP;
  const filterAll = '-1';
  const [sectionFilter, setSectionFilter] = useState(filterAll);
  const [workflowFilter, setWorkflowFilter] = useState(filterAll);

  const [manualFileDownloadData, setManualFileDownloadData] = useState(null);

  useEffect(() => {
    onEnterPage();
    if (editionId !== 'new') {
      fetchDetail(editionId);
    } else {
      newEdition();
    }

    return () => {
      onDisposePage();
    };
  }, [editionId, onDisposePage, onEnterPage, fetchDetail, newEdition]);

  if (!editionDetail?.pages) {
    return (<Loading />);
  }

  const { key, pages: editionPages = [] } = editionDetail;
  const breadcrumb = [
    ...EditionListBreadcrumb,
    {
      title: capitalize(type),
      slug: type,
    },
    {
      title: (editionId === 'new') ? 'New edition' : 'Edit edition',
      slug: editionId,
    },
  ];

  const filteredActive = sectionFilter !== filterAll || workflowFilter !== filterAll;

  const filteredPages = editionPages
    .filter(({ data: { sections } }) => {
      if (sectionFilter !== filterAll) {
        return sections && sections.some(({ id }) => id === sectionFilter);
      }
      return true;
    })
    .filter(({ data: { workflow } }) => {
      if (workflowFilter !== filterAll) {
        return workflow?.id === workflowFilter;
      }
      return true;
    });

  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={breadcrumb}>
        <PageToolbarButtons>
          {publicationConfig.editionAppUpdateURL && <Button
            onClick={() => typeof publicationConfig.editionAppUpdateURL === "string" ? onPingEditionApp(publicationConfig.editionAppUpdateURL.replace(/\{edition_id\}/, key)) : publicationConfig.editionAppUpdateURL.map((item) => {
              onPingEditionApp(item.replace(/\{edition_id\}/, key))
            })}
            startIcon={<PublishIcon />}
          >Update app</Button>}
          <Button
            onClick={() =>
              onSave({ type, status: editionDetail.status, method: EDITION_ACTION_UPDATE })
            }
            variant={'outlined'}
            startIcon={<SaveIcon />}
          >Save</Button>
          <Button
            variant={'contained'}
            onClick={() =>
              onSave({ type, status: (editionDetail.status ? 0 : 1), method: EDITION_ACTION_PUBLISH })
            }
            startIcon={editionDetail.status ? <VisibilityOffIcon /> : <PublishIcon />}
          >{editionDetail.status ? 'Unpublish' : 'Publish'}</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <Prompt when={!!unsavedEditionDetail} message={UNSAVE_CHANGES_PROMPT_TEXT} />
        <PageSection includePadding>
          <EditionDetailForm
            type={type}
            editionDetail={editionDetail}
            sectionFilter={sectionFilter}
            workflowFilter={workflowFilter}
            onChangeSectionFilter={setSectionFilter}
            onChangeWorkflowFilter={setWorkflowFilter}
          />
        </PageSection>
        <Grid item container justify={'space-between'} alignItems={'center'} className={classes.summaryRow}>
          <Grid item className={classes.summaryTitle}>
            <Typography variant={'h2'} color={'primary'}>Edition&apos;s page ({filteredActive ? `${filteredPages.length} of ${editionPages.length}` : filteredPages.length})</Typography>
          </Grid>
          <Grid item>
            {filteredActive && <Typography variant={'body2'} color={'default'}>You cannot rearrange the page order while filter is applied</Typography>}
          </Grid>
          {editionDetail.id && <Grid item>
            <ButtonGroup>
              {filteredActive && <Button
                startIcon={<FilterRemove color="default">remove</FilterRemove>}
                onClick={() => {
                  setWorkflowFilter(filterAll);
                  setSectionFilter(filterAll);
                }}
              >Clear filter</Button>}
              <Button
                startIcon={<AddCircleOutlineIcon color="default">add</AddCircleOutlineIcon>}
                onClick={() => routeChange(`/edition/${type}/${editionId}/article`)}
              >Add article</Button>
              <Button
                startIcon={<AddCircleOutlineIcon color="default">add</AddCircleOutlineIcon>}
                onClick={() => routeChange(`/edition/${type}/${editionId}/section`)}
              >Add front</Button>
              <Button
                startIcon={<CropPortraitIcon color="default">add</CropPortraitIcon>}
                onClick={() => routeChange(`/edition/${type}/${editionId}/single-image`)}
              >Add single image</Button>
            </ButtonGroup>
          </Grid>}
        </Grid>
        <PageSection>
          <TableSortable
            headers={['#', 'Page', 'Type', 'Workflow', 'Section', 'Modified', '']}
            rows={filteredPages.map((
              { type: pageType, data: { id: pageId, title, sections, changed, workflow } },
              index,
            ) => (
              [
                <AvatarNumbered key={`avatar-${index}`}>{index + 1}</AvatarNumbered>,
                title,
                humanFriendlyPageType(pageType),
                workflow ? workflow.name : '',
                sections ? sections.map(({ name }) => name).join(', ') : '',
                moment.unix(parseInt(changed, 10)).format(CMS_DATE_FORMAT),
                <ButtonGroup key={`actions-${index}`}>
                  <IconButton onClick={() => {
                    window.confirm('Please confirm if you want to remove this page') && onRemove(pageId);
                  }}
                  ><DeleteOutlined /></IconButton>
                  <IconButton onClick={() => routeChange(`/edition/${type}/${editionId}/${pageId}`)}><EditIcon /></IconButton>
                  <IconButton
                    onClick={() => routeChange(`/edition/${type}/${editionId}/${pageId}/browse/?mode=${previewMode}&containerId=${editionId}`)}
                  ><LaunchIcon /></IconButton>
                  <IconButton onClick={() =>
                    setManualFileDownloadData({
                      editionId,
                      pageId,
                      pageType,
                    })}>
                    <GetAppIcon /></IconButton>
                </ButtonGroup>,
              ]
            ))}
            onReorder={onReorder}
            hasActions
            enableSorting={!filteredActive}
          />
        </PageSection>
        {manualFileDownloadData &&
          <ManualFileDownloadDialog
            editionId={manualFileDownloadData.editionId}
            pageId={manualFileDownloadData.pageId.toString()}
            pageType={manualFileDownloadData.pageType}
            handleClose={() => setManualFileDownloadData(null)}
          />
        }
      </PageContent>
    </Page>
  );
};

EditionDetail.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  editionDetailNew: PropTypes.func.isRequired,
  editionDetailFetch: PropTypes.func.isRequired,
  saveEditionDetail: PropTypes.func.isRequired,
  reOrderEditionPages: PropTypes.func.isRequired,
  editionDetailDispose: PropTypes.func.isRequired,
  editionDetailEnter: PropTypes.func.isRequired,
  removePageFromContainer: PropTypes.func.isRequired,
  pingEditionApp: PropTypes.func.isRequired,
  editionDetail: PropTypes.object,
  publicationConfig: PropTypes.object.isRequired,
  unsavedEditionDetail: PropTypes.bool.isRequired,
};

EditionDetail.defaultProps = {
  editionDetail: null,
};

export default withStyles(styles)(connect(
  ({
    edition: { editionDetail },
    stateHistory: { unsavedEditionDetail },
    frame: { selectedPublication: { publicationConfig } },
  }) =>
    ({ editionDetail, publicationConfig, unsavedEditionDetail }),
  {
    push,
    editionDetailNew,
    editionDetailFetch,
    saveEditionDetail,
    reOrderEditionPages,
    removePageFromContainer,
    pingEditionApp,
    editionDetailEnter,
    editionDetailDispose,
  },
)(EditionDetail));
