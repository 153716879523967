import {
  ELEMENT_TABLE,
  TablePlugin,
} from '@udecode/slate-plugins';

import { ELEMENT_BLOCKQUOTE } from '../plugins/elements/blockquote/defaults';
import { ELEMENT_HR } from '../plugins/elements/horizontal-rule/defaults';
import { ELEMENT_INFOBOX } from '../plugins/elements/infobox/defaults';
import { ELEMENT_NUMBERBOX } from '../plugins/elements/numberbox/defaults';
import { ELEMENT_RELATED_ARTICLES } from '../plugins/elements/related-articles/defaults';
import { ELEMENT_IMAGE } from '../plugins/elements/media/image/defaults';
import { ELEMENT_GALLERY } from '../plugins/elements/media/gallery/defaults';
import { ELEMENT_VIDEO } from '../plugins/elements/media/video/defaults';
import { ELEMENT_TWITTER } from '../plugins/elements/social/twitter/defaults';
import { ELEMENT_FACEBOOK } from '../plugins/elements/social/facebook/defaults';
import { ELEMENT_INSTAGRAM } from '../plugins/elements/social/instagram/defaults';
import { ELEMENT_IFRAME } from '../plugins/elements/iframe/defaults';
import { ELEMENT_HTML } from '../plugins/elements/html/defaults';
import { ELEMENT_PARALLAX } from '../plugins/elements/parallax/defaults';
import { ELEMENT_ARTICLE_LINK } from '../plugins/elements/article-link/defaults';
import { ELEMENT_VISUAL_STORY } from '../plugins/elements/embed/visual-story/defaults';
import { ELEMENT_BOOKING } from '../plugins/elements/embed/booking/defaults';
import { ELEMENT_HIDE_AND_SHOW } from '../plugins/elements/hide-and-show/defaults';
import { ELEMENT_BUTTON_LINK } from '../plugins/elements/button-link/defaults';
import { ELEMENT_PRICE_COMPARISON } from '../plugins/elements/embed/price-comparison/defaults';
import { ELEMENT_TIKTOK } from '../plugins/elements/social/tiktok/defaults';
import { ELEMENT_OPTA } from '../plugins/elements/embed/opta/defaults';
import { ELEMENT_VOUCHER } from '../plugins/elements/embed/voucher/defaults';
import { MARK_SEARCH_REPLACE } from '../plugins/widgets/search-replace/defaults';

import TwitterPlugin from '../plugins/elements/social/twitter/TwitterPlugin';
import FacebookPlugin from '../plugins/elements/social/facebook/FacebookPlugin';
import InstagramPlugin from '../plugins/elements/social/instagram/InstagramPlugin';
import TiktokPlugin from '../plugins/elements/social/tiktok/TiktokPlugin';
import InfoboxPlugin from '../plugins/elements/infobox/InfoboxPlugin';
import BlockquotePlugin from '../plugins/elements/blockquote/BlockquotePlugin';
import NumberboxPlugin from '../plugins/elements/numberbox/NumberboxPlugin';
import HorizontalRulePlugin from '../plugins/elements/horizontal-rule/HorizontalRulePlugin';
import RelatedArticlesPlugin from '../plugins/elements/related-articles/RelatedArticlesPlugin';
import ImagePlugin from '../plugins/elements/media/image/ImagePlugin';
import VideoPlugin from '../plugins/elements/media/video/VideoPlugin';
import GalleryPlugin from '../plugins/elements/media/gallery/GalleryPlugin';
import IframePlugin from '../plugins/elements/iframe/IframePlugin';
import HtmlPlugin from '../plugins/elements/html/HtmlPlugin';
import ArticleLinkPlugin from '../plugins/elements/article-link/ArticleLinkPlugin';
import VisualStoryPlugin from '../plugins/elements/embed/visual-story/VisualStoryPlugin';
import BookingPlugin from '../plugins/elements/embed/booking/BookingPlugin';
import HideAndShowPlugin from '../plugins/elements/hide-and-show/HideAndShowPlugin';
import SearchReplacePlugin from '../plugins/widgets/search-replace/SearchReplacePlugin';
import ParallaxPlugin from '../plugins/elements/parallax/parallaxPlugin';
import ButtonLinkPlugin from '../plugins/elements/button-link/ButtonLinkPlugin';
import PriceComparisonPlugin from '../plugins/elements/embed/price-comparison/PriceComparisonPlugin';
import OptaPlugin from '../plugins/elements/embed/opta/OptaPlugin';
import VoucherPlugin from '../plugins/elements/embed/voucher/VoucherPlugin';
import MoneyPlugin from '../plugins/elements/embed/money/MoneyPlugin';
import { decorateSearchReplace } from '../plugins/widgets/search-replace/decorateSearchReplace';

import withTwitter from '../plugins/elements/social/twitter/withTwitter';
import withInstagram from '../plugins/elements/social/instagram/withInstagram';
import withFacebook from '../plugins/elements/social/facebook/withFacebook';
import withTiktok from '../plugins/elements/social/tiktok/withTiktok';
import FaqPlugin from '../plugins/elements/faq/faqPlugin';
import { ELEMENT_FAQ } from '../plugins/elements/faq/defaults';
import { ELEMENT_MONEY } from '../plugins/elements/embed/money/defaults';

export const VARIANT_DEFAULT = 'default';
export const VARIANT_POST = 'post';
export const VARIANT_REDUCED = 'reduced';
export const VARIANT_EDITION = 'edition';

const VARIANTS = [
  VARIANT_DEFAULT,
  VARIANT_POST,
  VARIANT_REDUCED,
  VARIANT_EDITION,
];

const PLUGINS = {
  [ELEMENT_BLOCKQUOTE]: { plugin: BlockquotePlugin },
  [ELEMENT_HR]: { plugin: HorizontalRulePlugin },
  [ELEMENT_TABLE]: { plugin: TablePlugin },
  [ELEMENT_INFOBOX]: { plugin: InfoboxPlugin },
  [ELEMENT_PARALLAX]: { plugin: ParallaxPlugin },
  [ELEMENT_NUMBERBOX]: { plugin: NumberboxPlugin },
  [ELEMENT_RELATED_ARTICLES]: { plugin: RelatedArticlesPlugin },
  [ELEMENT_IMAGE]: { plugin: ImagePlugin },
  [ELEMENT_GALLERY]: { plugin: GalleryPlugin },
  [ELEMENT_VIDEO]: { plugin: VideoPlugin },
  [ELEMENT_TWITTER]: { plugin: TwitterPlugin, withPlugin: withTwitter() },
  [ELEMENT_FACEBOOK]: { plugin: FacebookPlugin, withPlugin: withFacebook() },
  [ELEMENT_INSTAGRAM]: { plugin: InstagramPlugin, withPlugin: withInstagram() },
  [ELEMENT_TIKTOK]: { plugin: TiktokPlugin, withPlugin: withTiktok() },
  [ELEMENT_IFRAME]: { plugin: IframePlugin },
  [ELEMENT_HTML]: { plugin: HtmlPlugin },
  [ELEMENT_ARTICLE_LINK]: { plugin: ArticleLinkPlugin },
  [ELEMENT_VISUAL_STORY]: { plugin: VisualStoryPlugin },
  [ELEMENT_BOOKING]: { plugin: BookingPlugin },
  [ELEMENT_HIDE_AND_SHOW]: { plugin: HideAndShowPlugin },
  [ELEMENT_BUTTON_LINK]: { plugin: ButtonLinkPlugin },
  [ELEMENT_PRICE_COMPARISON]: { plugin: PriceComparisonPlugin },
  [ELEMENT_MONEY]: { plugin: MoneyPlugin },
  [ELEMENT_OPTA]: { plugin: OptaPlugin },
  [ELEMENT_FAQ]: { plugin: FaqPlugin },
  [ELEMENT_VOUCHER]: { plugin: VoucherPlugin },

  [MARK_SEARCH_REPLACE]: { plugin: SearchReplacePlugin, decorator: decorateSearchReplace },
};

const CONDITIONALS = [
  ELEMENT_TABLE,
];

const ELEMENTS = {
  [VARIANT_DEFAULT]: {
    Text: [
      ELEMENT_BLOCKQUOTE,
      ELEMENT_HR,
      ELEMENT_TABLE,
      ELEMENT_INFOBOX,
      ELEMENT_NUMBERBOX,
      ELEMENT_RELATED_ARTICLES,
      ELEMENT_BUTTON_LINK,
    ],
    Media: [
      ELEMENT_IMAGE,
      ELEMENT_GALLERY,
      ELEMENT_VIDEO,
      ELEMENT_PARALLAX,
    ],
    Social: [
      ELEMENT_TWITTER,
      ELEMENT_FACEBOOK,
      ELEMENT_INSTAGRAM,
      ELEMENT_TIKTOK,
    ],
    Embed: [
      ELEMENT_IFRAME,
      ELEMENT_HTML,
      ELEMENT_VISUAL_STORY,
      ELEMENT_PRICE_COMPARISON,
      ELEMENT_BOOKING,
      ELEMENT_OPTA,
      ELEMENT_VOUCHER,
      ELEMENT_FAQ,
      ELEMENT_MONEY
    ],
    Search: [
      MARK_SEARCH_REPLACE,
    ],
  },
  [VARIANT_POST]: {
    Text: [
      ELEMENT_BLOCKQUOTE,
    ],
    Media: [
      ELEMENT_IMAGE,
      ELEMENT_VIDEO,
    ],
    Social: [
      ELEMENT_TWITTER,
      ELEMENT_FACEBOOK,
      ELEMENT_INSTAGRAM,
      ELEMENT_TIKTOK,
    ],
    Embed: [
      ELEMENT_IFRAME,
      ELEMENT_HTML,
      ELEMENT_ARTICLE_LINK,
    ],
    Search: [
      MARK_SEARCH_REPLACE,
    ],
  },
  [VARIANT_REDUCED]: {
    Text: [
      ELEMENT_BLOCKQUOTE,
      ELEMENT_HR,
      ELEMENT_TABLE,
      ELEMENT_INFOBOX,
      ELEMENT_NUMBERBOX,
      ELEMENT_RELATED_ARTICLES,
    ],
    Social: [
      ELEMENT_TWITTER,
      ELEMENT_FACEBOOK,
      ELEMENT_INSTAGRAM,
      ELEMENT_TIKTOK,
    ],
    Search: [
      MARK_SEARCH_REPLACE,
    ],
  },
  [VARIANT_EDITION]: {
    Text: [
      ELEMENT_BLOCKQUOTE,
      ELEMENT_HR,
      ELEMENT_INFOBOX,
      ELEMENT_NUMBERBOX,
      ELEMENT_HIDE_AND_SHOW,
    ],
    Media: [
      ELEMENT_IMAGE,
    ],
    Embed: [
      ELEMENT_HTML,
    ],
    Search: [
      MARK_SEARCH_REPLACE,
    ],
  },
};

export default class ToolbarConfig {
  constructor(variant) {
    this.variant = VARIANTS.includes(variant) ? variant : VARIANT_DEFAULT;
    this.elements = ELEMENTS[this.variant];
  }
  getPlugins() {
    const elements = this.getElements();
    return Object.entries(PLUGINS)
      .filter(([element, data]) => elements.includes(element) && data.plugin)
      .map(([, data]) => data.plugin());
  }
  getDecorators(options) {
    const elements = this.getElements();
    return Object.entries(PLUGINS)
      .filter(([element, data]) => elements.includes(element) && data.decorator)
      .map(([, data]) => data.decorator(options));
  }
  getWithPlugins() {
    const elements = this.getElements();
    return Object.entries(PLUGINS)
      .filter(([element, data]) => elements.includes(element) && data.withPlugin)
      .map(([, data]) => data.withPlugin);
  }
  getConditionals() {
    const elements = this.getElements();
    return CONDITIONALS.filter(element => elements.includes(element));
  }
  getElements() {
    if (Array.isArray(this.elements)) {
      return this.elements;
    }
    const elements = [];
    if (typeof this.elements === 'object' && this.elements !== null) {
      Object.values(this.elements).forEach(group => elements.push(...group));
    }
    return elements;
  }
  getElementsByGroup(group) {
    if (
      typeof this.elements === 'object' &&
      this.elements !== null &&
      this.elements[group]
    ) {
      return this.elements[group];
    }
    return [];
  }
  getGroups() {
    if (typeof this.elements === 'object' && this.elements !== null) {
      return Object.keys(this.elements);
    }
    return null;
  }
}
