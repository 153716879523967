import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  startWith,
  merge,
  switchMap,
  mergeMap,
  map,
  withLatestFrom,
  flatMap,
  filter,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';


import apiCatchError from '../helper/notification';
import accessControl from '../helper/accessControl';

import {
  USERS_LIST_LOADED,
  USER_SAVE_EDIT,
  USER_SAVE_EDIT_SUCCESS,
  USER_SAVE_EDIT_FAILED,
  USER_LOADED,
  USERS_LIST_FAILED,
  SEND_PASSWORD_RESET,
  PASSWORD_RESET_SENT,
  PASSWORD_RESET_FAILED,

} from '../../constants/actionTypes';

import { CMS_INTERNAL_ROLE, CMS_ADMIN_ROLE } from '../../constants/access';
import { PAGE_ENTER_USERS } from '../../constants/actionTypes/route';
import { SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const getUser = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  withLatestFrom(state$),
  map(([, state]) => state),
  filter(({ frame: { selectedPublication: { domain } } }) => !!domain),
  switchMap(({ login: { user: { uid } }, frame: { selectedPublication: { domain } } }) => ajax.get(`/api/users/${domain}?uid=${uid}`, { 'Content-Type': 'application/json' }).pipe(
    flatMap(({ response }) => of({
      type: USER_LOADED,
      value: response,
    })),
    apiCatchError(USERS_LIST_FAILED),
  )),
);

export const getUsersList = (action$, state$) => {
  const selectPub$ = action$.pipe(
    ofType(PUBLICATION_SELECTED),
  );
  return action$.pipe(
    ofType(PAGE_ENTER_USERS),
    merge(selectPub$),
    accessControl(state$, [CMS_INTERNAL_ROLE, CMS_ADMIN_ROLE]),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const { selectedPublication } = state.frame;
      return ajax.getJSON(`/api/users/${selectedPublication.domain}`).pipe(
        map(value => ({
          type: USERS_LIST_LOADED,
          value,
        })),
        apiCatchError(USERS_LIST_FAILED),
      );
    }),
  );
};

export const sendPasswordReset = (action$, state$) => action$.pipe(
  ofType(SEND_PASSWORD_RESET),
  withLatestFrom(state$),
  mergeMap(([, { userEdit: { selectedUser } }]) => {
    const payload = {
      mail: selectedUser.mail,
    };
    return ajax.post('api/user/lost-password',
      payload,
      { 'Content-Type': 'application/json' }).pipe(
      map(value => ({
        type: PASSWORD_RESET_SENT,
        value,
      })),
      map(() => ({
        type: SHOW_NOTIFICATION,
        value: {
          message: 'Set password email sent to user',
          variant: 'success',
        },
      }),
      ),
      apiCatchError(PASSWORD_RESET_FAILED),
    );
  }),
);

export const saveUser = (action$, state$) => action$.pipe(
  ofType(USER_SAVE_EDIT),
  withLatestFrom(state$),
  filter(([, { login: { user: { roles } } }]) =>
    !!roles &&
    roles.some(r => [CMS_INTERNAL_ROLE, CMS_ADMIN_ROLE].includes(r)),
  ),
  mergeMap(([{ value: { callback } }, { userEdit: { selectedUser } }]) => {
    const payload = {
      name: [{
        value: selectedUser.name,
      }],
      mail: [{
        value: selectedUser.mail,
      }],
      status: [{
        // newly created users are always active
        value: true,
      }],
      roles: selectedUser.roles.map(role =>
        ({ target_id: role }),
      ),
      field_publications: selectedUser.publications.map(userPub =>
        ({ target_id: userPub.id }),
      ),
    };
    const uid = selectedUser.uid;
    if (uid) {
      // set status for user update
      payload.status = [{ value: selectedUser.status === '1' }];
      return ajax.patch(
        `/api/user/${uid}`,
        payload,
        { 'Content-Type': 'application/json' },
      ).pipe(
        mergeMap(() => {
          if (callback) {
            callback();
          }
          return of({
            type: USER_SAVE_EDIT_SUCCESS,
            value: {},
          }).pipe(
            startWith({
              type: SHOW_NOTIFICATION,
              value: {
                message: 'User updated',
                variant: 'success',
              },
            }),
          );
        }),
        apiCatchError(USER_SAVE_EDIT_FAILED),
      );
    }
    return ajax.post(
      '/api/user/create',
      payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(() => {
        if (callback) {
          callback();
        }
        return of({
          type: USER_SAVE_EDIT_SUCCESS,
          value: {},
        }).pipe(
          startWith({
            type: SHOW_NOTIFICATION,
            value: {
              message: 'User created',
              variant: 'success',
            },
          }),
        );
      }),
      apiCatchError(USER_SAVE_EDIT_FAILED),
    );
  }),
);


export const redirectOnSaveSuccess = action$ => action$.pipe(
  ofType(USER_SAVE_EDIT_SUCCESS),
  map(() => ({
    type: PAGE_ENTER_USERS,
  })),
);

