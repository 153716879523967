import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import RemoveIcon from '@material-ui/icons/Close';
import SettingsIcon from '@material-ui/icons/Settings';

import IconButton from '../../../ui/buttons/IconButton';

import {
  setLayoutContext,
} from '../../../../actions/sidebar';

import { CONTEXT_COMPONENT } from '../../../../constants/contexts';
import { FIELD_COMPONENTS_ORDER } from '../../../../constants/layout/layoutFields';

const styles = () => ({
  root: {
    display: 'flex',
    margin: '0',
  },
});

const ComponentUI = withStyles(styles)(({
  classes, component, position, disabled, externalOrder,
  setLayoutContext: setContextFunc,
  removeComponent: removeFunc,
}) => (
  <div className={`${classes.root} component-ui`}>
    <IconButton
      disabled={!!externalOrder || disabled}
      onClick={() =>
        window.confirm('Please confirm you want to remove this component') && removeFunc(position)
      }
    ><RemoveIcon /></IconButton>
    <IconButton
      disabled={disabled}
      onClick={() => setContextFunc(CONTEXT_COMPONENT, component)}
    ><SettingsIcon /></IconButton>
  </div>
));

export default connect(
  ({ externalState: { [FIELD_COMPONENTS_ORDER]: externalOrder } }) => ({ externalOrder }),
  {
    setLayoutContext,
  },
)(ComponentUI);
