import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const style = theme => ({
  selectOutlineLabel: {
    background: theme.palette.background.contrast,
  },
});

const disableCurrentAndItsChildren = (list, selectedId, id) => {
  if (!selectedId) {
    return false;
  }
  const recursiveCheckParent = (currentTermId, targetId) => {
    if (currentTermId === targetId) {
      return true;
    }
    const term = list.find(({ tid }) => tid === targetId);
    if (!term) return false;
    return term.parents.some(tid => recursiveCheckParent(currentTermId, tid));
  };
  return recursiveCheckParent(selectedId, id);
};

const TaxonomyParentSelect = withStyles(style)(({
  classes, value, id, label, onChange, items, currentTerm,
  ...rest
}) => {
  const [query, setQuery] = useState('');
  const [filtered, setFiltered] = useState(items);
  useEffect(() => {
    if (query) {
      const filteredItems = items.filter(({ name }) => {
        const pattern = new RegExp(query, 'i');
        return pattern.test(name);
      });
      setFiltered(filteredItems);
    } else {
      setFiltered(items);
    }
  }, [query, items]);

  return (<Grid container>
    <Grid item xs={12}>
      <FormControl margin={'normal'} fullWidth variant={'outlined'}>
        <Input
          placeholder={'Filter term'}
          onKeyUp={(e) => {
            setQuery(e.target.value);
          }}
          endAdornment={
            <InputAdornment position={'end'}>
              <IconButton
                aria-label={'Search'}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl FormControl margin={'normal'} fullWidth variant={'outlined'}>
        <InputLabel className={classes.selectOutlineLabel} shrink htmlFor={id}>{label}</InputLabel>
        <Select
          defaultValue={''}
          value={value}
          variant={'outlined'}
          id={id}
          name={id}
          onChange={onChange}
          autoComplete={'off'}
          {...rest}
        >
          {filtered && filtered.map(({ name, tid, deep, path }) => {
            let dash = '';
            if (deep > 0) {
              for (let i = 0; i < deep; i += 1) {
                dash += '- ';
              }
            }
            return (<option
              disabled={disableCurrentAndItsChildren(filtered, currentTerm?.id || null, tid)}
              key={tid}
              value={tid}
              title={path}
            >{dash}{name}</option>);
          })}
        </Select>
      </FormControl>
    </Grid>
  </Grid>);
});

TaxonomyParentSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default TaxonomyParentSelect;
