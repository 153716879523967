import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from './common/Dialog';

import { fetchAllManualLists } from '../../actions/manualList';

const style = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const ManualListDialogSelector = (props) => {
  const {
    classes, manualList, selectSection,
    fetchAllManualLists: fetchManualLists,
    ...rest
  } = props;
  useEffect(() => {
    fetchManualLists();
  }, []);
  return (
    <Dialog title={'Select manual list'} {...rest}>
      <div className={classes.root}>
        <List component={'nav'}>
          {manualList
            .sort((a, b) => a.data.id > b.data.id)
            .filter((
              { manualListSettings }) => manualListSettings?.isDefault ? !manualListSettings?.isDefault : true)
            .map(({ data }) => {
              const { name, uuid } = data;
              if (name === 'default') {
                return <></>
              }
              if (name === null) {
                return <></>
              }
              return (
                <ListItem button key={uuid} onClick={() => selectSection(data)}>
                  <ListItemText primary={name} />
                </ListItem>
              );
            })
          }
        </List>
      </div>
    </Dialog>
  );
};

ManualListDialogSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  manualList: PropTypes.array.isRequired,
  selectSection: PropTypes.func.isRequired,
  fetchAllManualLists: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(
  ({ manualList: { manualList } }) => ({ manualList }),
  { fetchAllManualLists },
)(ManualListDialogSelector));
