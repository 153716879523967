import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import ContextBar from '../../common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails, ContextPanelHeading, ContextPanelSummary,
} from '../../common/context/ContextPanel';
import { FIELD_NAME, FIELD_WORKFLOW } from '../../../constants/common/commonFields';
import FieldLockTextfield from '../../fieldLock/form/FieldLockTextfield';
import FieldLockWorkflow from '../../embed/form/FieldLockWorkflow';
import ImageMultiSelector, { INSERT_PREPEND } from '../../ui/media/ImageMultiSelector';
import { addLocalKeyed } from '../../../actions/dataState';
import { FIELD_IMAGES, FIELD_IMAGES_ORDER } from '../../../constants/media/galleryFields';
import { MEDIA_ENTITY_ID } from '../../../constants/media/media';

const GalleryEdit = ({
  imagesOrder,
  onAdd,
  addLocalKeyed: addKeyed,
}) => (
  <ContextBar>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Gallery settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldLockTextfield field={FIELD_NAME} label={'Gallery name'} />
          </Grid>
          <Grid item xs={12}>
            <FieldLockWorkflow field={FIELD_WORKFLOW} />
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Add Image(s)</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <ImageMultiSelector
          onSelect={(data, position) => {
            const images = Array.isArray(data) ? data : [data];
            images.forEach(image => addKeyed(
              FIELD_IMAGES,
              FIELD_IMAGES_ORDER,
              position === INSERT_PREPEND ? 0 : imagesOrder.length,
              image[MEDIA_ENTITY_ID],
              image,
            ));
            if (onAdd) {
              onAdd(position);
            }
          }}
        />
      </ContextPanelDetails>
    </ContextPanel>
  </ContextBar>
);

GalleryEdit.propTypes = {
  addLocalKeyed: PropTypes.func.isRequired,
  imagesOrder: PropTypes.array,
  onAdd: PropTypes.func,
};

GalleryEdit.defaultProps = {
  imagesOrder: [],
  onAdd: null,
};

export default connect(
  ({ dataState: { [FIELD_IMAGES_ORDER]: imagesOrder } }) => ({ imagesOrder }),
  { addLocalKeyed },
)(GalleryEdit);
