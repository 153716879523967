import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';
import Select from '../../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';

const HERO_ALIGNMENT = 'heroAlignment';
const LABEL_ALIGNMENT = 'Hero Alignment';

const items = [
  'Right',
  'Left',
];

const CommentHighlightSettings = (props) => {
  const {
    component,
    componentPropOnChange,
  } = props;
  return (
    <ContextPanel>
      <ContextPanelHeading>Settings</ContextPanelHeading>
      <ContextPanelDetails variant={'full'}>
        <Select
          label={LABEL_ALIGNMENT}
          value={component[HERO_ALIGNMENT] || items[0]}
          items={items}
          onChange={(e) => {
            componentPropOnChange(component.id, HERO_ALIGNMENT, e.target.value);
          }}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

CommentHighlightSettings.defaultProps = {
  disabledAlignmentOption: false,
};

CommentHighlightSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentPropOnChange: componentSetProp },
)(CommentHighlightSettings);
