import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ComponentProperties from './context/ComponentProperties';
import ComponentSelector from './context/ComponentSelector';
import Error from '../../contexts/Error';

import {
  CONTEXT_COMPONENT,
  CONTEXT_COMPONENT_SELECTOR,
} from '../../../constants/contexts';
import ContextBar from '../../common/context/ContextBar';
import ContextUI from './context/ContextUI';

const getComponentFromContext = (context) => {
  const { type } = context;
  switch (type) {
    case CONTEXT_COMPONENT:
      return ComponentProperties;
    case CONTEXT_COMPONENT_SELECTOR:
      return ComponentSelector;
    default:
      return Error;
  }
};

getComponentFromContext.defaultProps = {
  data: {},
};

const LayoutContext = (props) => {
  const { context } = props;
  const ContextComponent = getComponentFromContext(context);
  return (<ContextBar>
    <ContextUI {...context} />
    <ContextComponent {...context} />
  </ContextBar>);
};

LayoutContext.defaultProps = {
  selectedManualList: -1,
};

LayoutContext.propTypes = {
  context: PropTypes.object.isRequired,
  selectedManualList: PropTypes.number,
  selectComponentManualList: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  {},
)(LayoutContext);
