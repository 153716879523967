import { ofType } from 'redux-observable';
import { filter, flatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import { VOCAB_LAYOUTS, VOCAB_LAYOUTS_API } from '../../constants/vocab';
import apiCatchError from '../helper/notification';
import {
  VOCAB_FETCH_LAYOUT,
  VOCAB_FETCH_LAYOUT_REJECTED,
  VOCAB_FETCH_LAYOUT_SUCCESS,
} from '../../constants/actionTypes/vocab';
import { LAYOUT_FETCH_SUCCESS, LAYOUT_FETCH_REJECTED } from '../../constants/actionTypes/layout';
import { WS_LAYOUT } from '../../constants/actionTypes/ws';

export const handleLayoutListUpdate = (action$, state$) => action$.pipe(
  ofType(VOCAB_FETCH_LAYOUT),
  mergeMap(({ value: fetchVocab }) => action$.pipe(
    ofType(WS_LAYOUT),
    filter(({ value: { vocab, id } }) => vocab === fetchVocab && typeof id !== 'undefined'),
    withLatestFrom(state$),
    mergeMap(([{ value: { vocab } }, { frame: { selectedPublication: { domain } } }]) =>
      ajax.getJSON(`/api/layout/${VOCAB_LAYOUTS_API[vocab]}/${domain}`).pipe(
        flatMap(response => of({
          type: VOCAB_FETCH_LAYOUT_SUCCESS,
          value: {
            key: VOCAB_LAYOUTS[vocab],
            value: response,
          },
        })),
        apiCatchError(VOCAB_FETCH_LAYOUT_REJECTED),
      ),
    )),
  ),
);

export const handleLayoutUpdate = (action$, state$) => action$.pipe(
  ofType(WS_LAYOUT),
  withLatestFrom(state$),
  filter(([{ value: { id } }, { serverState }]) => (
    serverState?.id &&
    typeof id !== 'undefined' &&
    serverState.id.toString() === id.toString()
  )),
  mergeMap(([{ value: { id } }]) => ajax.getJSON(`/api/layout/${id}`).pipe(
    flatMap(response => of({
      type: LAYOUT_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(LAYOUT_FETCH_REJECTED),
  )),
);

