import React from 'react';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import Profile from '../components/profile/Profile';

const ProfilePage = () => (
  <Page fullWidth toolbarContent={<PageToolbar breadcrumb={[{ title: 'My account', slug: '/' }]} />}>
    <PageContent>
      <PageSection includePadding>
        <Profile />
      </PageSection>
    </PageContent>
  </Page>
);

export default ProfilePage;
