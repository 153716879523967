import { ofType } from 'redux-observable';
import { map, switchMap, merge, mergeMap, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import { PAGE_ENTER_LIVEBLOG_LIST } from '../../constants/actionTypes/route';

import apiCatchError from '../helper/notification';

import {WS_LIVEBLOG_CONTAINER} from "../../constants/actionTypes/ws";
import {serialize} from "../../utils/urlHelper";
import {
  LIVEBLOG_ARCHIVED_FETCH_LIST,
  LIVEBLOG_ARCHIVED_FETCH_LIST_REJECTED, LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS,
  LIVEBLOG_DISPOSE_LIST,
  LIVEBLOG_ENTER_LIST,
  LIVEBLOG_FETCH_LIST,
  LIVEBLOG_FETCH_LIST_REJECTED, LIVEBLOG_FETCH_LIST_SUCCESS,
  LIVEBLOG_ON_SEARCH,
  LIVEBLOG_ON_SEARCH_REJECTED,
  LIVEBLOG_ON_SEARCH_SUCCESS
} from "../../constants/actionTypes/liveblog";

export const onEnterLiveblogList = action$ => action$.pipe(
  ofType(PAGE_ENTER_LIVEBLOG_LIST),
  map(() => ({ type: LIVEBLOG_ENTER_LIST })),
);

export const fetchLiveblogList = (action$) => {
  return action$.pipe(
    ofType(LIVEBLOG_ENTER_LIST),
    mergeMap(() => {
      let cachedParams = {};
      return action$.pipe(
        ofType(LIVEBLOG_FETCH_LIST),
        merge(action$.pipe(
          ofType(WS_LIVEBLOG_CONTAINER),
          takeUntil(action$.pipe(ofType(LIVEBLOG_DISPOSE_LIST))),
        )),
        takeUntil(action$.ofType(LIVEBLOG_DISPOSE_LIST)),
        switchMap((action) => {
          if (action.value && action.type === LIVEBLOG_FETCH_LIST) {
            cachedParams = action.value;
          }
          return ajax.get(`/api/liveblogs?${serialize(cachedParams)}`).pipe(
            map(({ response }) => ({
              type: LIVEBLOG_FETCH_LIST_SUCCESS,
              value: response,
            })),
            apiCatchError(LIVEBLOG_FETCH_LIST_REJECTED),
          );
        }),
      );
    }),
  );
};

export const fetchArchivedLiveblogList = (action$) => {
  return action$.pipe(
    ofType(LIVEBLOG_ENTER_LIST),
    mergeMap(() => {
      let cachedParams = {};
      return action$.pipe(
        ofType(LIVEBLOG_ARCHIVED_FETCH_LIST),
        merge(action$.pipe(
          ofType(WS_LIVEBLOG_CONTAINER),
          takeUntil(action$.pipe(ofType(LIVEBLOG_DISPOSE_LIST))),
        )),
        takeUntil(action$.ofType(LIVEBLOG_DISPOSE_LIST)),
        switchMap((action) => {
          if (action.value && action.type === LIVEBLOG_ARCHIVED_FETCH_LIST) {
            cachedParams = action.value;
          }
          return ajax.get(`/api/liveblogs?${serialize(cachedParams)}`).pipe(
            map(({ response }) => ({
              type: LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS,
              value: response,
            })),
            apiCatchError(LIVEBLOG_ARCHIVED_FETCH_LIST_REJECTED),
          );
        }),
      );
    }),
  );
};

export const searchLiveblogList = action$ => action$.pipe(
  ofType(LIVEBLOG_ON_SEARCH),
  switchMap(action => ajax.get(`/api/liveblogs?q=${action.value}`).pipe(
    map(({ response }) => ({
      type: LIVEBLOG_ON_SEARCH_SUCCESS,
      value: response,
    })),
    apiCatchError(LIVEBLOG_ON_SEARCH_REJECTED),
  )),
);
