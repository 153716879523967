import React from 'react';

import ContextBar from '../common/context/ContextBar';
import TwitterExplorer from './articleContent/liveblogBuilder/TwitterExplorer';

const LiveblogSidebar = () => (<ContextBar>
  <TwitterExplorer />
</ContextBar>);

LiveblogSidebar.defaultProps = {
};

LiveblogSidebar.propTypes = {
};

export default LiveblogSidebar;
