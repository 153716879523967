import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  message: {
    margin: theme.spacing(0),
  },
});

const LoginMessage = ({ classes, children, color, ...rest }) => (
  <Typography className={classes.message} variant="body2" color={color} {...rest}>
    <strong>{children}</strong>
  </Typography>
);

LoginMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

LoginMessage.defaultProps = {
  color: 'primary',
};

export default withStyles(styles)(LoginMessage);
