import { PURGE } from 'redux-persist';
import { arrayMove } from 'react-sortable-hoc';

import {
  APPEND_CONTACT_GROUP,
  APPEND_CONTACT_ITEM,
  CONTACT_US_SET_PROPERTY,
  REMOVE_CONTACT,
  REMOVE_CONTACT_GROUP,
  SAVE_CONTACT_US_SUCCESS,
  SET_ADDRESS_PROP,
  SET_GROUP_PROP, SORT_CONTACT,
} from '../../constants/staticPage/contactUs';
import generateKey from '../../utils/generateKey';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const initialContact = {
  title: '',
  name: '',
  phone: '',
  twitter: '',
  facebook: '',
  email: '',
  id: generateKey(),
};

const initialContactGroup = {
  title: '',
  contacts: [initialContact],
  id: generateKey(),
};

const defaultState = {
  address: {
    street: '',
    postcode: '',
    city: '',
    country: '',
    phone: '',
    email: '',
    facebook: '',
    twitter: '',
    map: '',
  },
  contactGroup: [initialContactGroup],
  pageId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case REMOVE_CONTACT_GROUP: {
      const { groupId } = action.value;
      return {
        ...state,
        contactGroup: state.contactGroup.filter(group => group.id !== groupId),
      };
    }
    case REMOVE_CONTACT: {
      const { groupId, contactId } = action.value;
      return {
        ...state,
        contactGroup: state.contactGroup.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              contacts: group.contacts.filter(contact => contact.id !== contactId),
            };
          }
          return group;
        }),
      };
    }

    case SORT_CONTACT: {
      const { groupId, newIndex, oldIndex } = action.value;
      return {
        ...state,
        contactGroup: state.contactGroup.map((group) => {
          if (group.id === groupId) {
            return {
              ...group,
              contacts: arrayMove(group.contacts, oldIndex, newIndex),
            };
          }
          return group;
        }),
      };
    }

    case SET_GROUP_PROP: {
      const { groupId, contactId, key, value } = action.value;
      return {
        ...state,
        contactGroup: state.contactGroup.map((group) => {
          if (group.id === groupId) {
            // change to group level
            if (!contactId) {
              return {
                ...group,
                [key]: value,
              };
            }
            // change to contact
            return {
              ...group,
              contacts: group.contacts.map((contact) => {
                if (contactId === contact.id) {
                  return {
                    ...contact,
                    [key]: value,
                  };
                }
                return contact;
              }),
            };
          }
          return group;
        }),
      };
    }

    case SET_ADDRESS_PROP: {
      const [key, value] = action.value;
      return {
        ...state,
        address: {
          ...state.address,
          [key]: value,
        },
      };
    }

    case APPEND_CONTACT_ITEM: {
      const id = action.value;
      return {
        ...state,
        contactGroup: state.contactGroup.map((group) => {
          if (group.id === id) {
            return {
              ...group,
              contacts: group.contacts.concat({ ...initialContact, id: generateKey() }),
            };
          }
          return group;
        }),
      };
    }

    case SAVE_CONTACT_US_SUCCESS:
      return {
        ...state,
        pageId: action.value.id[0].value,
      };

    case APPEND_CONTACT_GROUP:
      return {
        ...state,
        contactGroup: state.contactGroup.concat({ ...initialContactGroup, id: generateKey() }),
      };

    case CONTACT_US_SET_PROPERTY: {
      const [key, value] = action.value;
      return {
        ...state,
        [key]: value,
      };
    }

    case PUBLICATION_SELECTED:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
