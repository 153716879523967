import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { setItemPropOnLocal, removeFromLocalProp } from '../../../../actions/dataState';

import IconButton from '../../../ui/buttons/IconButton';
import TextField from '../../../ui/TextField';
import Editor from '../../../editor/Editor';
import { FIELD_QUESTION_ANSWERS } from '../../../../constants/article/articleFields';
import SectionTitle from '../../../common/SectionTitle';

const style = theme => ({
  wrapper: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.border.primary.main}`,
    },
  },
  editor: {
    margin: theme.spacing(2, -3, 0),
  },
  actions: {
    textAlign: 'right',
    visibility: 'hidden',
    '.qna:hover &': {
      visibility: 'visible',
    },
  },
});

const QuestionAnswerContent = ({
  classes,
  body,
  index,
  question,
  setItemPropOnLocal: setProp,
  removeFromLocalProp: removeProp,
}) => (
  <div className={`${classes.wrapper} qna`}>
    <Grid container spacing={2}>
      <Grid item xs={10}><SectionTitle>{index + 1}</SectionTitle></Grid>
      <Grid item xs={2} className={classes.actions}>
        <IconButton
          onClick={() => removeProp(FIELD_QUESTION_ANSWERS, index)}
        ><DeleteOutlinedIcon /></IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={question}
          onChange={e => setProp(FIELD_QUESTION_ANSWERS, index, 'question', e.target.value, true)}
          label={'Question'}
        />
      </Grid>
      <Grid item xs={12}>
        <Editor
          className={classes.editor}
          content={body}
          onChange={value => setProp(FIELD_QUESTION_ANSWERS, index, 'body', value, true)}
        />
      </Grid>
    </Grid>
  </div>
);

QuestionAnswerContent.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setItemPropOnLocal: PropTypes.func.isRequired,
  removeFromLocalProp: PropTypes.func.isRequired,
  question: PropTypes.string,
  body: PropTypes.array,
};

QuestionAnswerContent.defaultProps = {
  question: '',
  body: [],
};

export default withStyles(style)(connect(
  ({
    frame: { selectedPublication },
  }) => ({ selectedPublication }),
  { setItemPropOnLocal, removeFromLocalProp },
)(QuestionAnswerContent));
