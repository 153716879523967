import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Grid from '@material-ui/core/Grid/Grid';

import IconButton from './buttons/IconButton';
import AvatarNumbered from './AvatarNumbered';

export const ArrowSortableList = props => (<List {...props} />);
export const ArrowSortableListItem = withStyles(theme => ({
  root: {
    borderRadius: theme.border.radius,
    border: `1px solid ${theme.border.color}`,
  },
  container: {
    background: theme.palette.background.contrast,
    listStyle: 'none',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
    '& .MuiListItem-secondaryAction': {
      paddingRight: 16,
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'none',
    },
    '&:hover': {
      '& .MuiListItemSecondaryAction-root': {
        display: 'flex',
      },
      '& .MuiListItem-secondaryAction': {
        paddingRight: 136,
      },
    },
  },
}))(({ children, index, indexAvatar, ...rest }) => (
  <ListItem {...rest} index={index}>
    {indexAvatar && <ListItemAvatar>
      <AvatarNumbered>{index + 1}</AvatarNumbered>
    </ListItemAvatar>}
    {children}
  </ListItem>
));
export const ArrowSortableListItemContent = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))(props => (<Grid {...props} container spacing={2} />));
export const ArrowSortableDown = props => (<IconButton {...props}><ArrowDownward /></IconButton>);
export const ArrowSortableUp = props => (<IconButton {...props}><ArrowUpward /></IconButton>);
