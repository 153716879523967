export const DND_TYPE_COMPONENT = 'component';

export const ArticleX1 = 'ArticleX1';
export const ArticleX2 = 'ArticleX2';
export const CompactArticleX2 = 'CompactArticleX2';
export const ArticleX3 = 'ArticleX3';
export const ArticleX3Small = 'ArticleX3Small';
export const ArticleX4Small = 'ArticleX4Small';
export const ArticleX3Insider = 'ArticleX3Insider';
export const ArticleX4 = 'ArticleX4';
export const ArticleX4Insider = 'ArticleX4Insider';
export const ArticleX6 = 'ArticleX6';
export const ArticleX8 = 'ArticleX8';
export const ArticleX9 = 'ArticleX9';
export const ArticleX12 = 'ArticleX12';
export const HeroArticle = 'HeroArticle';
export const HeroPlusCarousel = 'HeroCarousel';
export const HeroPlus2Articles = 'HeroPlus2Articles';
export const HeroPlus2ArticlesNewsletter = 'HeroPlus2ArticlesNewsletter';
export const HeroPlus3Articles = 'HeroPlus3Articles';
export const HeroPlus4Articles = 'HeroPlus4Articles';
export const HeroPlus5Articles = 'HeroPlus5Articles';
export const HeroPlus6Articles = 'HeroPlus6Articles';
export const HeroPlus8Articles = 'HeroPlus8Articles';
export const HeroCustomPlus2ArticlesPlusDMPU = 'HeroCustomPlus2ArticlesPlusDMPU';
export const HeroX2Plus4Articles = 'HeroX2Plus4Articles';
export const HeroPlus1ArticlePlusRightmove = 'HeroPlus1ArticlePlusRightmove';
export const HeadShotX3 = 'HeadShotX3';
export const LiveNowX3 = 'LiveNowX3';
export const LiveNowX4 = 'LiveNowX4';
export const BreakingNewsTicker = 'BreakingNewsTicker';
export const BreakingNewsTickerJPIM = 'BreakingNewsTickerJPIM';
export const HeroPlusMarkup = 'HeroPlusMarkup';
export const HeroPlusImageAndVideo = 'HeroPlusImageAndVideo';
export const TrendingEditorsPickPlusDMPU = 'TrendingEditorsPickPlusDMPU';

export const NewsBanner = 'NewsBanner';
export const ArticleX2PlusQuoteHero = 'ArticleX2PlusQuoteHero';
export const ImageGradient = 'ImageGradient';
export const HeroPlus4ArticlesWithImages = "HeroPlus4ArticlesWithImages";
export const CommentHighlight = 'CommentHighlight';
export const VideoShowcase = 'VideoShowcase';
export const VideoHubPlaylist = 'VideoHubPlaylist';
export const PortraitArticle = 'PortraitArticle';
export const VIP = 'VIP';
export const CarouselX6 = 'CarouselX6';
export const CarouselX12 = 'CarouselX12';
export const VoucherX12 = 'VoucherX12';
export const Voucher = 'Voucher';
export const FeaturedArtistX1 = 'FeaturedArtistX1';
export const FeaturedGalleryX11 = 'FeaturedGalleryX11';
export const Quote = 'Quote';

export const AuthorHighlight = 'AuthorHighlight';
export const MostPopular = 'MostPopular';
export const TrendingEditorsPick = 'TrendingEditorsPick';
export const MostPopularINews = 'MostPopularINews';
export const Indy100 = 'indy100';
export const NewsLetterSignup = 'NewsLetterSignup';
export const Video = 'Video';
export const VideoPlaylist = 'VideoPlaylist';
export const ReadMore = 'ReadMore';
export const More = 'More';
export const Title = 'Title';
export const AboutSponsor = 'AboutSponsor';
export const Markup = 'Markup';
export const VisualStories = 'VisualStories';
export const iFrame = 'iFrame';
export const Media = 'Media';
export const Description = 'Description';
export const Services = 'Services';
export const HeroPlus6ArticlesWithImages = "HeroPlus6ArticlesWithImages";
export const ChartbeatFeed = 'ChartbeatFeed';
export const ArticleX2PlusMPU = 'ArticleX2PlusMPU';
export const ArticleX3PlusMPU = 'ArticleX3PlusMPU';
export const ArticleX4PlusMPU = 'ArticleX4PlusMPU';
export const ArticleX6PlusMPU = 'ArticleX6PlusMPU';
export const ArticleX3PlusDMPU = 'ArticleX3PlusDMPU';
export const ArticleX4PlusDMPU = 'ArticleX4PlusDMPU';
export const ArticleX6PlusDMPU = 'ArticleX6PlusDMPU';
export const ArticleX8PlusDMPU = 'ArticleX8PlusDMPU';
export const ArticleX4PlusTeaser = 'ArticleX4PlusTeaser';
export const ArticleX3PlusTeaser = 'ArticleX3PlusTeaser';
export const HeroPlusDMPU = 'HeroPlusDMPU';
export const HeroX2Plus2Articles = 'HeroX2Plus2Articles';
export const HeroPlus2ArticlesPlusDMPU = 'HeroPlus2ArticlesPlusDMPU';
export const HeroPlus3ArticlesPlusMPU = 'HeroPlus3ArticlesPlusMPU';
export const HeroPlus4ArticlesPlusDMPU = 'HeroPlus4ArticlesPlusDMPU';
export const HeroPlus5ArticlesPlusDMPU = 'HeroPlus5ArticlesPlusDMPU';
export const HeroPlus7ArticlesPlusDMPU = 'HeroPlus7ArticlesPlusDMPU';
export const HeroPlus6ArticlesPlusDMPU = 'HeroPlus6ArticlesPlusDMPU';
export const HeroPlus6ArticlesTeaser = 'HeroPlus6ArticlesTeaser';
export const HeroX2Plus2ArticlesPlusDMPU = 'HeroX2Plus2ArticlesPlusDMPU';
export const HeroPlusDMPUPlusManualListX7 = 'HeroPlusDMPUPlusManualListX7';
export const Ad = 'Ad';
export const AdRow = 'AdRow';
export const AdRowBillboard = 'AdRowBillboard';
export const Taboola = 'Taboola';
export const AdRowOutbrain = 'AdRowOutbrain';
export const Banner = 'Banner'
export const ArticleX4NC = 'ArticleX4NC';
export const HeroPlus4ArticlesNC = 'HeroPlus4ArticlesNC';
export const Chain = 'Chain';

export const CHAIN_COMPONENTS = [
  HeroPlus4ArticlesNC,
];

export const ARTICLE_COMPONENTS = [
  ChartbeatFeed,
  ArticleX1,
  ArticleX2,
  ArticleX3,
  ArticleX3Small,
  ArticleX3Insider,
  ArticleX4,
  ArticleX4Small,
  ArticleX4Insider,
  ArticleX6,
  HeroPlus6ArticlesWithImages,
  ArticleX8,
  ArticleX9,
  ArticleX12,
  HeroPlus4ArticlesWithImages,
  BreakingNewsTicker,
  BreakingNewsTickerJPIM,
  CompactArticleX2,
  HeroArticle,
  HeroX2Plus2Articles,
  HeroPlus2Articles,
  HeroPlus2ArticlesNewsletter,
  HeroPlus3Articles,
  HeroPlus4Articles,
  HeroPlus6Articles,
  HeroPlus8Articles,
  HeroPlus5Articles,
  HeroX2Plus4Articles,
  HeroPlusCarousel,
  HeadShotX3,
  NewsBanner,
  ArticleX2PlusQuoteHero,
  CarouselX6,
  CarouselX12,
  VIP,
  LiveNowX3,
  LiveNowX4,
  HeroPlusMarkup,
  VideoHubPlaylist,
  HeroPlus1ArticlePlusRightmove,
  HeroPlus4ArticlesNC,
  ArticleX4NC,
  HeroCustomPlus2ArticlesPlusDMPU,
  Quote,
];
export const AD_COMPONENTS = [
  ArticleX2PlusMPU,
  ArticleX3PlusMPU,
  ArticleX3PlusTeaser,
  ArticleX3PlusDMPU,
  ArticleX4PlusDMPU,
  ArticleX4PlusTeaser,
  ArticleX6PlusDMPU,
  ArticleX8PlusDMPU,
  HeroPlusDMPU,
  HeroPlus2ArticlesPlusDMPU,
  HeroPlus3ArticlesPlusMPU,
  HeroPlus4ArticlesPlusDMPU,
  HeroPlus5ArticlesPlusDMPU,
  HeroPlus6ArticlesPlusDMPU,
  HeroPlus7ArticlesPlusDMPU,
  HeroPlus6ArticlesTeaser,
  HeroX2Plus2ArticlesPlusDMPU,
  HeroPlusDMPUPlusManualListX7,
  ArticleX3PlusMPU,
  ArticleX4PlusMPU,
  ArticleX6PlusMPU,
  Ad,
  AdRow,
  AdRowOutbrain,
  AdRowBillboard,
  TrendingEditorsPickPlusDMPU,
];

export const ALL_COMPONENTS = [...ARTICLE_COMPONENTS, ...AD_COMPONENTS];

export const ARTICLE_COUNT_MAX = {
  [ArticleX1]: 1,
  [Banner]: 1,
  [ArticleX2]: 2,
  [CompactArticleX2]: 2,
  [ChartbeatFeed]: 5,
  [ArticleX3]: 3,
  [HeroPlus4ArticlesWithImages]: 5,
  [HeroPlus6ArticlesWithImages]: 7,
  [ArticleX3Small]: 3,
  [ArticleX3Insider]: 3,
  [ArticleX4]: 4,
  [ArticleX4Small]: 4,
  [ArticleX4Insider]: 4,
  [ArticleX6]: 6,
  [ArticleX8]: 8,
  [ArticleX9]: 9,
  [ArticleX12]: 12,
  [BreakingNewsTicker]: 6,
  [BreakingNewsTickerJPIM]: 1,
  [HeroArticle]: 1,
  [HeroPlusCarousel]: 1,
  [HeroX2Plus2Articles]: 4,
  [HeroPlus2ArticlesNewsletter]: 3,
  [HeroPlus2Articles]: 3,
  [HeroPlus3Articles]: 4,
  [HeroPlus4Articles]: 5,
  [HeroPlus5Articles]: 6,
  [HeroPlus6Articles]: 7,
  [HeroPlus8Articles]: 9,
  [HeroX2Plus4Articles]: 6,
  [HeroCustomPlus2ArticlesPlusDMPU]: 3,
  [HeroPlus6ArticlesPlusDMPU]: 7,
  [HeadShotX3]: 3,
  [ArticleX2PlusMPU]: 2,
  [ArticleX3PlusMPU]: 3,
  [ArticleX4PlusMPU]: 4,
  [ArticleX6PlusMPU]: 6,
  [ArticleX3PlusDMPU]: 3,
  [ArticleX3PlusTeaser]: 3,
  [ArticleX4PlusDMPU]: 4,
  [ArticleX6PlusDMPU]: 6,
  [ArticleX8PlusDMPU]: 8,
  [ArticleX4PlusTeaser]: 4,
  [HeroPlusDMPU]: 1,
  [HeroPlus2ArticlesPlusDMPU]: 3,
  [HeroPlus3ArticlesPlusMPU]: 4,
  [HeroPlus4ArticlesPlusDMPU]: 5,
  [HeroPlus5ArticlesPlusDMPU]: 6,
  [HeroPlus7ArticlesPlusDMPU]: 8,
  [HeroPlus6ArticlesTeaser]: 7,
  [HeroX2Plus2ArticlesPlusDMPU]: 4,
  [HeroPlusDMPUPlusManualListX7]: 8,
  [NewsBanner]: 1,
  [ArticleX2PlusQuoteHero]: 3,
  [VIP]: 1,
  [CommentHighlight]: 1,
  [CarouselX6]: 6,
  [CarouselX12]: 12,
  [LiveNowX3]: 3,
  [LiveNowX4]: 4,
  [HeroPlusMarkup]: 1,
  [TrendingEditorsPick]: 5,
  [TrendingEditorsPickPlusDMPU]: 5,
  [HeroPlus1ArticlePlusRightmove]: 2,
  [HeroPlus4ArticlesNC]: 5,
  [ArticleX4NC]: 4,
  [VideoHubPlaylist]: 8,
  [Quote]: 1,
};

export const ARTICLE_COUNT_MIN = {
  ...ARTICLE_COUNT_MAX,
  // now override for component minimum is not the same as maximum
  BreakingNewsTicker: 1,
  LiveNowX3: 1,
  VideoHubPlaylist: 1,
};

export const COMPONENT_NAMES = {
  [AdRowBillboard]: 'Ad Row Billboard',
  [HeroPlus6ArticlesWithImages]: "Hero + Six Articles with images",
  [ArticleX1]: 'Article',
  [HeroPlus4ArticlesWithImages]: "Hero + Four Articles with images",
  [ArticleX2]: 'Two Articles',
  [BreakingNewsTickerJPIM]: 'Breaking News Ticker',
  [CompactArticleX2]: 'Two Articles - Compact',
  [ArticleX3]: 'Three Articles',
  [ArticleX3Small]: 'Three Articles - Small',
  [ArticleX4Small]: 'Four Articles - Small',
  [ArticleX3Insider]: 'Three Articles - Insider',
  [ArticleX4]: 'Four Articles',
  [ArticleX4Insider]: 'Four Articles - Insider',
  [ArticleX6]: 'Six Articles',
  [ArticleX8]: 'Eight Articles',
  [ArticleX9]: 'Nine Articles',
  [ArticleX12]: 'Twelve Articles',
  [HeroArticle]: 'Hero',
  [Banner]: 'Banner',
  [HeroPlus2ArticlesNewsletter]: 'Hero + Two Articles + Newsletter',
  [HeroPlus2Articles]: 'Hero + Two Articles',
  [HeroPlusCarousel]: 'Hero + Carousel',
  [HeroPlus3Articles]: 'Hero + Three Articles',
  [HeroPlus4Articles]: 'Hero + Four Articles',
  [HeroPlus5Articles]: 'Hero + Five Articles',
  [HeroPlus6Articles]: 'Hero + Six Articles',
  [HeroPlus8Articles]: 'Hero + Eight Articles',
  [HeroCustomPlus2ArticlesPlusDMPU]: 'Hero Custom + Two Articles + DMPU',
  [HeroX2Plus4Articles]: 'Two Heroes + Four Articles',
  [HeroPlusMarkup]: 'Hero + Markup',
  [HeroPlusImageAndVideo]: 'Hero + Image and Video',
  [HeadShotX3]: 'Head Shots',
  [NewsBanner]: 'News Banner',
  [ArticleX2PlusQuoteHero]: 'Two Articles + Quote (hero)',
  [CommentHighlight]: 'Comment Highlight',
  [VideoShowcase]: 'Video Showcase',
  [VideoPlaylist]: 'Video Playlist',
  [VideoHubPlaylist]: 'Video hub playlist',
  [PortraitArticle]: 'Portrait Article',
  [VIP]: 'VIP',
  [AboutSponsor]: 'About Sponsor',
  [CarouselX6]: 'Six Article Carousel',
  [CarouselX12]: 'Twelve Article Carousel',
  [VoucherX12]: 'Vouchers',
  [FeaturedArtistX1]: 'Featured Artist',
  [FeaturedGalleryX11]: 'Featured Gallery',
  [MostPopular]: 'Most Popular',
  [TrendingEditorsPick]: 'Trending + Editor\'s Pick',
  [TrendingEditorsPickPlusDMPU]: 'Trending + Editor\'s Pick + DMPU',
  [MostPopularINews]: 'Most Popular INews',
  [NewsLetterSignup]: 'Newsletter Signup',
  [ReadMore]: 'Read More',
  [More]: 'More',
  [Title]: 'Title',
  [ImageGradient]: 'Image Gradient',
  [ArticleX2PlusMPU]: 'Two Articles + MPU',
  [ArticleX3PlusMPU]: 'Three Articles + MPU',
  [ArticleX4PlusMPU]: 'Four Articles + MPU',
  [ArticleX6PlusMPU]: 'Six Articles + MPU',
  [ArticleX3PlusDMPU]: 'Three Articles + DMPU',
  [ArticleX4PlusDMPU]: 'Four Articles + DMPU',
  [ArticleX6PlusDMPU]: 'Six Articles + DMPU',
  [ArticleX8PlusDMPU]: 'Eight Articles + DMPU',
  [ArticleX4PlusTeaser]: 'Four Articles + Teaser',
  [ArticleX3PlusTeaser]: 'Three Articles + Teaser',
  [HeroPlusDMPU]: 'Hero + DMPU',
  [HeroPlus2ArticlesPlusDMPU]: 'Hero + Two Articles + DMPU',
  [HeroPlus3ArticlesPlusMPU]: 'Hero + Three Articles + MPU',
  [HeroPlus4ArticlesPlusDMPU]: 'Hero + Four Articles + DMPU',
  [HeroPlus5ArticlesPlusDMPU]: 'Hero + Five Articles + DMPU',
  [HeroPlus6ArticlesPlusDMPU]: 'Hero + Six Articles + DMPU',
  [HeroPlus7ArticlesPlusDMPU]: 'Hero + Seven Articles + DMPU',
  [HeroPlus6ArticlesTeaser]: 'Hero + Six Articles + Teaser',
  [HeroX2Plus2ArticlesPlusDMPU]: 'Two Heroes + Two Articles + DMPU',
  [HeroPlusDMPUPlusManualListX7]: 'Hero + DMPU + List',
  [HeroPlus1ArticlePlusRightmove]: 'Hero + One Article + RightMove',
  [HeroPlus4ArticlesNC]: 'Hero + Four Articles',
  [ArticleX4NC]: 'Four Articles',
  [Media]: 'Media Showcase',
};
