import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
  },
});

const JWPLAYER_CDN = '//cdn.jwplayer.com/players/';

const DugoutIframe = ({ embedHash, mediaid, playerId}) => (
  <div style={{ margin: '0 auto', minWidth: '100%' }}>
    <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
      <iframe
        title={embedHash}
        src={playerId ? `${JWPLAYER_CDN}${mediaid}-${playerId}.html` : `https://embed.dugout.com/v2/?p=${embedHash}`}
        style={{ width: '300px', minWidth: '100%', position: 'absolute', top: 0, left: 0, height: '100%', overflow: 'hidden' }}
        width="100%"
        frameBorder="0"
        allowFullScreen
        scrolling="no"
      />
    </div>
  </div>
);

DugoutIframe.propTypes = {
  embedHash: PropTypes.string.isRequired,
  mediaid: PropTypes.string.isRequired,
  playerId: PropTypes.string,
};

DugoutIframe.defaultProps = {
  playerId: null,
};

const VideoDugout = ({
  classes,
  publicationConfig,
  // eslint-disable-next-line camelcase
  video,
}) => {
  const { mediaid, embed_hash: embedHash } = video;
  if (!mediaid || !embedHash) return <Typography variant={'h6'}>Video Dugout id not found</Typography>;
  return (
    <div className={classes.root}>
      <DugoutIframe playerId={publicationConfig?.dugoutPlayerId || ''} embedHash={embedHash} mediaid={mediaid} />
    </div>
  );
};

VideoDugout.propTypes = {
  classes: PropTypes.object.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  video: PropTypes.object,
};

VideoDugout.defaultProps = {
  video: {
    mediaid: null,
    embed_hash: null,
  },
};

export default withStyles(styles)(connect(
  ({ frame: { selectedPublication: { publicationConfig } } }) => ({ publicationConfig }),
)(VideoDugout));
