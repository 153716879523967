import React, { useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { arrayMove } from 'react-sortable-hoc';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LinkIcon from '@material-ui/icons/Link';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { ArrowSortableDown, ArrowSortableList, ArrowSortableListItem, ArrowSortableUp } from '../../ui/ArrowSortable';
import Button from '../../ui/buttons/Button';
import KeyPointDialog from '../../dialog/KeyPointDialog';
import IconButton from '../../ui/buttons/IconButton';
import TextField from '../../ui/TextField';

const styles = theme => ({
  noLink: {},
  withLink: {
    '& .MuiInputBase-root': {
      color: theme.palette.primary.main,
    },
  },
  hide: {
    display: 'none',
  },
});

const getNodeLi = (node) => {
  if (node.nodeName === 'LI') {
    return [node];
  }
  if (node.childNodes && node.childNodes.length > 0) {
    return Array.from(node.childNodes)
      .map(getNodeLi)
      .flat();
  }
  return [];
};

const extractHref = (markup) => {
  const matches = markup.match(/href="(.*)"/);
  return (matches && matches[1]) || '';
};

const convertFromMarkup = (markup) => {
  const parsed = new DOMParser().parseFromString(
    markup,
    'text/html',
  );
  const liNodes = getNodeLi(parsed);
  return liNodes.map(liNode => ({
    title: liNode.innerText,
    path: extractHref(liNode.innerHTML),
  }));
};

const convertToMarkup = points => (
  `<ul>${points.map(point => (point.path ? `<li><a href="${point.path}">${point.title}</a></li>` : `<li>${point.title}</li>`)).join('')}</ul>`
);

const KeyPoints = ({
  classes,
  keyPoints,
  onChange,
}) => {
  const [selected, setSelected] = useState(null);
  const [focus, setFocus] = useState(0);
  const [refs, setRefs] = useState([]);
  const [open, setOpen] = useState(false);
  const points = convertFromMarkup(keyPoints);
  const pointsLength = points.length;
  useEffect(() => {
    setRefs(
      Array(pointsLength + 1).fill().map((_, i) => refs[i] || createRef()),
    );
    if (refs[focus] && refs[focus].current) {
      refs[focus].current.focus();
    }
  }, [pointsLength]);
  return (
    <Grid container spacing={2}>
      {points.length > 0 && <Grid item xs={12}>
        <ArrowSortableList>
          {points.map(({ title, path }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ArrowSortableListItem index={index} key={`keyPoint-${index}`} indexAvatar>
              <TextField
                className={path ? classes.withLink : classes.noLink}
                inputRef={refs[index]}
                margin={'none'}
                variant={'standard'}
                InputProps={{ disableUnderline: true }}
                value={title}
                onChange={(e) => {
                  points[index].title = e.target.value;
                  onChange(convertToMarkup(points));
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    points.splice(index + 1, 0, { title: '', path: '' });
                    setFocus(index + 1);
                    onChange(convertToMarkup(points));
                  }
                }}
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    onChange(convertToMarkup(points.filter((point, i) => i !== index)));
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setSelected(index);
                    setOpen(true);
                  }}
                >
                  <LinkIcon />
                </IconButton>
                {index < points.length - 1 && <ArrowSortableDown
                  onClick={() => {
                    onChange(convertToMarkup(arrayMove(points, index, index + 1)));
                  }}
                />}
                {index > 0 && <ArrowSortableUp
                  onClick={() => {
                    onChange(convertToMarkup(arrayMove(points, index, index - 1)));
                  }}
                />}
              </ListItemSecondaryAction>
            </ArrowSortableListItem>
          ))}
        </ArrowSortableList>
      </Grid>}
      <Grid item container justify={'center'}>
        <Button
          onClick={() => {
            points.push({ title: '', path: '' });
            setFocus(points.length - 1);
            onChange(convertToMarkup(points));
          }}
          startIcon={<AddCircleOutlineIcon />}
        >Add key point</Button>
      </Grid>
      {open && <KeyPointDialog
        path={selected ? points[selected].path : ''}
        open={open}
        onSelect={(path) => {
          points[selected] = { ...points[selected], path };
          onChange(convertToMarkup(points));
          setOpen(false);
          setSelected(null);
        }}
        handleClose={() => {
          setOpen(false);
          setSelected(null);
        }}
      />}
    </Grid>
  );
};

KeyPoints.propTypes = {
  classes: PropTypes.object.isRequired,
  keyPoints: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(KeyPoints);
