import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { addLocalKeyed, removeLocalKeyed, sortLocalKeyed } from '../../../actions/dataState';

import { ArrowSortableDown, ArrowSortableList, ArrowSortableListItem, ArrowSortableUp } from '../../ui/ArrowSortable';
import Button from '../../ui/buttons/Button';

import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';
import FieldLockContainer from '../FieldLockContainer';
import FieldLockPanelSection from '../FieldLockPanelSection';
import IconButton from '../../ui/buttons/IconButton';
import generateKey from '../../../utils/generateKey';

const FieldLockSortable = ({
  field, fieldOrder, label, labelSingle, required, panel,
  order, orderLock, orderChanges, Component,
  addLocalKeyed: addItem, removeLocalKeyed: removeItem, sortLocalKeyed: sortItems,
}) => {
  const Wrapper = panel ? FieldLockPanelSection : FieldLockContainer;
  return (
    <Wrapper
      field={fieldOrder}
      label={label}
      lockValue={orderLock}
      required={required}
      hasChanges={orderChanges}
    >
      <Grid container spacing={2}>
        {order.length > 0 && <Grid item xs={12}>
          <ArrowSortableList>
            {order.map((itemId, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ArrowSortableListItem index={index} key={`${fieldOrder}-${index}`} indexAvatar>
                <Component itemId={itemId} field={field} index={index} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => removeItem(field, fieldOrder, itemId)}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                  {index < order.length - 1 && <ArrowSortableDown
                    onClick={() => sortItems(fieldOrder, index, index + 1)}
                  />}
                  {index > 0 && <ArrowSortableUp
                    onClick={() => sortItems(fieldOrder, index, index - 1)}
                  />}
                </ListItemSecondaryAction>
              </ArrowSortableListItem>
            ))}
          </ArrowSortableList>
        </Grid>}
        <Grid item xs={12}>
          <Button
            onClick={() => addItem(field, fieldOrder, order.length, generateKey(), {})}
            startIcon={<AddCircleOutlineIcon />}
          >Add {labelSingle || label}</Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

FieldLockSortable.propTypes = {
  addLocalKeyed: PropTypes.func.isRequired,
  removeLocalKeyed: PropTypes.func.isRequired,
  sortLocalKeyed: PropTypes.func.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  fieldOrder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  Component: PropTypes.node.isRequired,
  order: [],
  orderLock: PropTypes.object,
  orderChanges: PropTypes.bool,
  label: PropTypes.string,
  labelSingle: PropTypes.string,
  panel: PropTypes.bool,
  required: PropTypes.bool,
};

FieldLockSortable.defaultProps = {
  label: '',
  labelSingle: '',
  order: [],
  orderLock: null,
  orderChanges: false,
  panel: false,
  required: false,
};

const mapStateToProps = (state, props) => {
  const { fieldOrder } = props;
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    order: getFieldValue(state, { field: fieldOrder }),
    orderLock: getFieldLockValue(state, { field: fieldOrder }),
    orderChanges: getFieldHasChanges(state, { field: fieldOrder }),
  };
};

export default connect(
  mapStateToProps,
  { addLocalKeyed, removeLocalKeyed, sortLocalKeyed },
)(FieldLockSortable);
