import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HeroImage from './articleBuilder/HeroImage';
import {
  ContextPanel, ContextPanelDetails,
} from '../../common/context/ContextPanel';
import { PagePanel } from '../../common/page/PagePanel';

const style = theme => ({
  root: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
});

const SingleImageMain = ({
  classes,
}) => (
  <PagePanel className={classes.root}>
    <ContextPanel>
      <ContextPanelDetails variant={'full'}>
        <HeroImage noImageRatio />
      </ContextPanelDetails>
    </ContextPanel>
  </PagePanel>
);

SingleImageMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(connect(() => ({}), { })(SingleImageMain));
