import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import {
  ToolbarButton,
  someNode,
  getPreventDefaultHandler,
} from '@udecode/slate-plugins';
import insertEmbed from '../plugins/transforms/insertEmbed';

const ToolbarInsert = ({ type, ...props }) => {
  const editor = useSlate();
  const transform = insertEmbed({ type, children: [{ text: '' }] });
  return (
    <ToolbarButton
      active={someNode(editor, { match: { type } })}
      onMouseDown={getPreventDefaultHandler(transform, editor, props)}
      {...props}
    />
  );
};

ToolbarInsert.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ToolbarInsert;
