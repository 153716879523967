import { PURGE } from 'redux-persist';
import { ARTICLE_AUTOCOMPLETE_SUCCESS } from '../../constants/actionTypes/article';
import { STORY_AUTOCOMPLETE_SUCCESS } from '../../constants/actionTypes/story';

const defaultState = {
  articles: [],
  authors: [],
  badgePrefix: [],
  stories: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PURGE:
      return defaultState;

    case ARTICLE_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        articles: action.value,
      };

    case STORY_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        stories: action.value.items || [],
      };

    default:
      return state;
  }
};
