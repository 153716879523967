import { Transforms } from 'slate';

/**
 * This is a hack to fix an issue with nested editors where adding a char to end of the first line
 * would reset the curor to point [0,0] this adds a whitespace bumper so we can correctly set
 * the selection
 * @param editor
 * @return {*}
 */
const withNested = (editor) => {
  const { apply, normalizeNode } = editor;
  let insertOp = null;
  let addingBumper = false;

  editor.apply = (operation) => {
    if (
      operation.type === 'insert_text' &&
      operation.path?.[0] === 0 &&
      operation.path?.[1] === 0 &&
      !addingBumper
    ) {
      insertOp = operation;
      return apply(operation);
    }
    insertOp = null;
    addingBumper = false;
    return apply(operation);
  };


  editor.normalizeNode = ([currentNode, currentPath]) => {
    if (currentPath.length === 0 && insertOp !== null) {
      const text = currentNode.children?.[0]?.children?.[0]?.text || null;
      if (text && text.length === insertOp.offset + 1) {
        const currentOp = { ...insertOp };
        addingBumper = true;
        Transforms.insertText(editor, ' ', { at: {
          anchor: {
            path: currentOp.path,
            offset: currentOp.offset + 1,
          },
          focus: {
            path: currentOp.path,
            offset: currentOp.offset + 1,
          },
        } });
        Transforms.setSelection(editor, {
          anchor: {
            path: currentOp.path,
            offset: currentOp.offset + 1,
          },
          focus: {
            path: currentOp.path,
            offset: currentOp.offset + 1,
          },
        });
      }
      insertOp = null;
    }
    return normalizeNode([currentNode, currentPath]);
  };

  return editor;
};

export default withNested;
