import React from 'react';
import PropTypes from 'prop-types';

const HorizontalRuleElement = (props) => {
  const { attributes, children, className } = props;
  return (
    <div className={className} {...attributes}>
      <div contentEditable={false}><hr /></div>
      {children}
    </div>
  );
};

HorizontalRuleElement.propTypes = {
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default HorizontalRuleElement;
