import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';

import { saveAdConfig, setAdConfigProp } from '../actions/ad';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';
import Button from '../components/ui/buttons/Button';

import { AdConfigBreadcrumb, getAdConfig } from './AdConfigList';
import { Tab, Tabs } from '../components/ui/Tabs';
import AdConfigTab from '../components/ad/adConfig/AdConfigTab';

export const AdConfigCreateBreadcrumb = [
  ...AdConfigBreadcrumb,
  {
    title: 'Create new config',
    slug: 'create',
  },
];

const AdConfigCreate = (props) => {
  const {
    currentConfig,
    adConfigFromServer,
    domain, client,
    saveAdConfig: onSaveAdConfig,
    setAdConfigProp: setProp,
  } = props;
  const [activeTab, setActiveTab] = useState(false);
  const [adConfig, setAdConfig] = useState(null);
  let breadcrumb = AdConfigCreateBreadcrumb;
  if (Object.keys(adConfigFromServer).length > 0) {
    const { id, name } = adConfigFromServer;
    breadcrumb = [
      ...AdConfigBreadcrumb,
      {
        title: name[0].value,
        slug: id[0].value,
      },
    ];
  }
  useEffect(() => {
    setAdConfig(getAdConfig(domain, client));
  }, [domain]);
  useEffect(() => {
    if (adConfig) {
      setActiveTab(Object.keys(adConfig)[0]);
    } else {
      setActiveTab(false);
    }
  }, [adConfig]);
  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <PageToolbarButtons>
            <Button
              onClick={() => onSaveAdConfig()}
              variant={'contained'}
              startIcon={<SaveIcon />}
            >Save</Button>
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection>
          {adConfig && <Tabs
            value={activeTab}
            onChange={(e, val) => setActiveTab(val)}
          >
            {Object.keys(adConfig).map(key => (
              <Tab key={key} value={key} label={key.replace('_', ' ')} />
            ))}
          </Tabs>}
          <AdConfigTab
            type={activeTab}
            config={currentConfig?.[activeTab] || []}
            fields={adConfig?.[activeTab] || []}
            domain={domain}
            setProp={setProp}
          />
        </PageSection>
      </PageContent>
    </Page>
  );
};

AdConfigCreate.propTypes = {
  saveAdConfig: PropTypes.func.isRequired,
  setAdConfigProp: PropTypes.func.isRequired,
  currentConfig: PropTypes.array.isRequired,
  adConfigFromServer: PropTypes.object.isRequired,
  domain: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
};

export default connect(
  ({
    ad: { currentConfig, adConfigFromServer },
    frame: { selectedPublication: { domain }, client },
  }) => ({ currentConfig, adConfigFromServer, domain, client }),
  { saveAdConfig, setAdConfigProp },
)(AdConfigCreate);
