import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_HTML } from './defaults';

const renderElementHtml = (options) => {
  const { html } = setDefaults(options, DEFAULTS_HTML);
  return getRenderElement(html);
};

export default renderElementHtml;
