import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line react/display-name
const BookingIconNoFill = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props} viewBox={'0 0 3.036 3.037'}>
    <path d="M1.113 2.524h-.51v-.61c0-.13.05-.2.162-.214h.35a.38.38 0 0 1 .41.411c0 .26-.157.415-.41.415zM.602.875v-.16c0-.14.06-.208.19-.216h.262c.224 0 .36.134.36.36 0 .17-.092.37-.35.37h-.46zm1.164.61l-.092-.052.08-.07c.094-.08.25-.262.25-.575 0-.48-.372-.79-.947-.79h-.73a.32.32 0 0 0-.309.317v2.72H1.07c.64 0 1.052-.348 1.052-.888 0-.29-.133-.54-.358-.665" />
    <path d="M2.288 2.67c0-.203.163-.367.365-.367s.367.164.367.367-.164.367-.367.367-.365-.164-.365-.367" />
  </SvgIcon>
));

export default BookingIconNoFill;
