const ADCONFIG_DESKTOP = [
  { title: 'Top Banner', tile: 'top_banner' },
  { title: 'Gutter ads', tile: 'gtl/gtr' },
  { title: 'Skins', tile: 'skin' },
  { title: 'In-Gallery MPUs', tile: 'gallery_mpu', type: 'range', data: { start: 3, length: 8 } },
  { title: 'Gallery MPU - Sidebar', tile: 'gallery_mpu_sb' },
  { title: 'Video Ad', tile: 'hero_player' },
  { title: 'Partner Ads', tile: 'partner_ad' },
  { title: 'Live Blog MPUs', tile: 'blog_mpu', type: 'range', data: { start: 3, length: 8 } },
  { title: 'Outstream 1', tile: 'thirdparty01' },
  { title: 'Outstream 2', tile: 'thirdparty02' },
  { title: 'Rich Media', tile: 'thirdparty_05' },
  { title: 'Out of page', tile: 'out-of-page' },
  { title: 'Header Native Unit', tile: 'headerNative', type: 'partner' },
  { title: 'Mid Article Native Unit ', tile: 'midNative', type: 'partner' },
  { title: 'Affiliate Widget', tile: 'affiliate', type: 'partner' },
];

const ADCONFIG_MOBILE = [
  { title: 'MPU1', tile: 'mpu1' },
  { title: 'In-Gallery MPUs', tile: 'gallery_mpu', type: 'range', data: { start: 3, length: 8 } },
  { title: 'Video Ad', tile: 'hero_player' },
  { title: 'Partner Ads', tile: 'partner_ad' },
  { title: 'Live Blog MPUs', tile: 'blog_mpu', type: 'range', data: { start: 3, length: 8 } },
  { title: 'Outstream 1', tile: 'thirdparty01' },
  { title: 'Outstream 2', tile: 'thirdparty02' },
  { title: 'Bottom MPUs', tile: 'bottom_mpu', type: 'range', data: { start: 3, length: 8 } },
  { title: 'Bottom Banner', tile: 'bottom_banner' },
  { title: 'Gallery Mobile Banner', tile: 'gallery_banner' },
  { title: 'Rich Media', tile: 'thirdparty_05' },
  { title: 'Out of page', tile: 'out-of-page' },
  { title: 'Header Native Unit', tile: 'headerNative', type: 'partner' },
  { title: 'Mid Article Native Unit ', tile: 'midNative', type: 'partner' },
  { title: 'Affiliate Widget', tile: 'affiliate', type: 'partner' },
];

export const INDY_ADPARTNERS = [
  'Fresh8',
  'Apester',
  'Amazon Prime',
  'Amazon Music',
  'Apple TV+',
];

export default {
  desktop: ADCONFIG_DESKTOP,
  desktopLite: ADCONFIG_DESKTOP,
  mobile: ADCONFIG_MOBILE,
  mobileLite: ADCONFIG_MOBILE,
};
