import { ofType } from 'redux-observable';
import { of, from, EMPTY } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { filter, mergeMap, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';
import {
  ARTICLE_EDIT_DISPOSE,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_SAVE_SUCCESS,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_SET_DEFAULTS,
} from '../../constants/actionTypes/article';
import {
  ON_BLUR_HEADLINE_FIELD,
} from '../../constants/actionTypes';
import {
  DATASTATE_SERVER_DISPOSE_DATA, DATASTATE_SERVER_SET_DATA,
  DATASTATE_LOCAL_DISPOSE, DATASTATE_LOCAL_SET_DATA, DATASTATE_LOCAL_SET_PROPERTY,
  DATASTATE_EXTERNAL_DISPOSE_DATA,
} from '../../constants/actionTypes/dataState';
import ArticleEntity from '../../entities/ArticleEntity';
import {
  ARTICLE_SENSITIVE_FIELDS,
  FIELD_ARTICLE_TYPE, FIELD_BODY, FIELD_CONTENT_SOURCE,
  FIELD_DISABLE_ALL_ADS, FIELD_DISPLAY_DATE_PREF,
  FIELD_HEADLINE, FIELD_HERO_GALLERY,
  FIELD_HERO_IMAGE, FIELD_HERO_VIDEO,
  FIELD_IS_BREAKING_NEWS,
  FIELD_IS_SENSITIVE,
  FIELD_LIVEBLOG,
  FIELD_LIVEBLOG_KEYPOINTS,
  FIELD_LIVEBLOG_KEYPOINTS_TITLE,
  FIELD_NID, FIELD_PRIORITY,
  FIELD_PRODUCTS, FIELD_PUBLICATIONS, FIELD_SECTIONS,
  FIELD_SEO_HEADLINE,
  FIELD_SHORT_HEADLINE,
  FIELD_SOCIAL_HEADLINE,
  FIELD_URL_KEYWORDS, FIELD_VANITY_DATE, FIELD_VIDEO_PROVIDER, FIELD_WORKFLOW,
} from '../../constants/article/articleFields';
import { FIELD_PRODUCT_STATS, FIELD_VENDOR_LINK } from '../../constants/product/productFields';
import { PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER } from '../../constants/actionTypes/product';
import { PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT } from '../../constants/actionTypes/route';
import { setLocalProp, setServerData } from '../../actions/dataState';
import {
  ARTICLE_TYPE_REGEX_LIVEBLOG,
  ARTICLE_TYPE_REGEX_SERVICE,
  DISPLAY_DATE_UPDATED, DISPLAY_DATE_VANITY,
} from '../../constants/article/article';
import { getFromStates } from '../../utils/stateHelper';
import { setArticleDataDefaults } from './articleProcessors';
import { MEDIA_IMAGE } from '../../constants/media/media';
import { API_FIELD_EXTRA_DATA } from '../../constants/common/commonFields';
import { PLAYINC_PROVIDER } from '../../components/integration/playInc/PlayIncVideoSelectorList';
import { ARTICLE_TYPE } from '../../constants/vocab';
import { saveMediaImage } from '../../actions/media';
import apiCatchError, { assignNotification } from '../helper/notification';
import { MEDIA_IMAGE_SAVE_CALLBACK } from '../../constants/actionTypes/media';
import { deserializeString } from '../../components/editor/helper/serializer';
import { serialize } from '../../components/editor/helper/converter';

export const setServerStateOnSuccess = (action$, state$) => action$.pipe(
  ofType(ARTICLE_FETCH_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([{ value }, state]) => {
    const article = new ArticleEntity();
    const articleData = article.getDataFromPayload(value);
    setArticleDataDefaults(articleData, state);
    // Hack for products @todo deprecate and remove in v1.6
    if (value[API_FIELD_EXTRA_DATA]) {
      try {
        const extras = JSON.parse(value[API_FIELD_EXTRA_DATA][0].value);
        if (Array.isArray(extras[FIELD_PRODUCTS]) && extras[FIELD_PRODUCTS].length > 0) {
          articleData[FIELD_PRODUCTS] = extras[FIELD_PRODUCTS];
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Could not parse extras');
      }
    }
    const actions = [
      { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: articleData },
    ];
    if (articleData[FIELD_PRODUCTS] && articleData[FIELD_PRODUCTS].length > 0) {
      const affiliateLinkStats = articleData[FIELD_PRODUCTS]
        .reduce((acc, { data: { [FIELD_VENDOR_LINK]: link, [FIELD_PRODUCT_STATS]: stats } }) => ({
          ...acc,
          [link]: stats,
        }), {});
      actions.push({ type: PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER, value: affiliateLinkStats });
    }
    // HACK FOR REVISION @todo remove once new revision system worked
    if (value.localState) {
      actions.push({
        type: DATASTATE_LOCAL_SET_DATA,
        value: value.localState,
      });
    }
    return from(actions);
  }),
);

export const disposeState = action$ => action$.pipe(
  ofType(
    PAGE_ENTER_ARTICLE_EDIT, ARTICLE_DELETE_SUCCESS, ARTICLE_EDIT_DISPOSE,
  ),
  mergeMap(() => from([
    { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
    { type: DATASTATE_SERVER_DISPOSE_DATA },
  ])),
);

export const handleStateOnSave = action$ => action$.pipe(
  ofType(ARTICLE_SAVE_SUCCESS),
  mergeMap(({ value }) => {
    const article = new ArticleEntity();
    const data = article.getDataFromPayload(value);
    return from([
      { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: data },
    ]);
  }),
);

export const clearArticleDataOnNewArticle = action$ => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => from([
    { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
    { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
    { type: DATASTATE_EXTERNAL_DISPOSE_DATA },
  ])),
);

export const setArticleDefaults = (action$, state$) => action$.pipe(
  ofType(ARTICLE_SET_DEFAULTS),
  withLatestFrom(state$),
  mergeMap(([, {
    vocab: {
      defaultContentSource,
      defaultArticleType,
    },
    frame: { workflowOptions = [], selectedPublication = {} },
  }]) => from([
    setServerData({
      [FIELD_PUBLICATIONS]: [selectedPublication?.id] || null,
      [FIELD_CONTENT_SOURCE]: defaultContentSource ? [defaultContentSource] : null,
      [FIELD_ARTICLE_TYPE]: defaultArticleType || null,
      [FIELD_WORKFLOW]: workflowOptions?.[0]?.id || null,
      [FIELD_PRIORITY]: 3,
      [FIELD_DISPLAY_DATE_PREF]: DISPLAY_DATE_UPDATED,
    }),
  ])),
);

export const setLocalOnArticleTypeChange = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop, value } }) => prop === FIELD_ARTICLE_TYPE && !!value?.name),
    withLatestFrom(state$),
    mergeMap(([{ value: { value } }, { localState, serverState }]) => {
      const actions = [];
      if (getFromStates(FIELD_LIVEBLOG, localState, serverState)) {
        actions.push(setLocalProp(FIELD_LIVEBLOG, ''));
      }
      if (getFromStates(FIELD_LIVEBLOG_KEYPOINTS, localState, serverState)) {
        actions.push(setLocalProp(FIELD_LIVEBLOG_KEYPOINTS, ''));
      }
      if (getFromStates(FIELD_LIVEBLOG_KEYPOINTS_TITLE, localState, serverState)) {
        actions.push(setLocalProp(FIELD_LIVEBLOG_KEYPOINTS_TITLE, ''));
      }
      switch (true) {
        case ARTICLE_TYPE_REGEX_LIVEBLOG.test(value.name):
          return EMPTY;
        case ARTICLE_TYPE_REGEX_SERVICE.test(value.name):
          actions.push(setLocalProp(FIELD_DISABLE_ALL_ADS, true));
          return from(actions);
        default:
          return actions.length > 0 ? from(actions) : EMPTY;
      }
    }),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

export const setLocalOnDisplayDateVanity = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop, value } }) =>
      prop === FIELD_DISPLAY_DATE_PREF && value === DISPLAY_DATE_VANITY),
    withLatestFrom(state$),
    filter(([, { localState, serverState }]) =>
      !getFromStates(FIELD_VANITY_DATE, localState, serverState)),
    mergeMap(() => of(setLocalProp(FIELD_VANITY_DATE, moment().unix()))),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

export const setBreakingNewsImage = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop, value } }) => prop === FIELD_IS_BREAKING_NEWS && !!value),
    withLatestFrom(state$),
    filter(([, {
      dataState: { [FIELD_HERO_IMAGE]: heroImage },
      frame: { breakingNewsImage } },
    ]) => !heroImage?.data?.mid && breakingNewsImage),
    mergeMap(([, { frame: { breakingNewsImage } }]) =>
      of(setLocalProp(FIELD_HERO_IMAGE, {
        type: MEDIA_IMAGE,
        data: breakingNewsImage,
      })),
    ),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

// This has been disabled, pending removal
// export const setVideoSectionOnVideoSet = (action$, state$) => action$.pipe(
//   ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
//   mergeMap(() => action$.pipe(
//     ofType(DATASTATE_LOCAL_SET_PROPERTY),
//     filter(({ value: { prop, value } }) => prop === FIELD_HERO_VIDEO && !!value && value !== {}),
//     withLatestFrom(state$),
//     filter(([, {
//       localState, serverState,
//       frame: { selectedPublication: { publicationConfig: { videoSection } } },
//     }]) => {
//       const sections = getFromStates(FIELD_SECTIONS, localState, serverState) || [];
//       return (!!videoSection &&
//         !sections.find(vocab => !!vocab && vocab.id.toString() === videoSection.id.toString())
//       );
//     }),
//     mergeMap(([, {
//       localState, serverState,
//       frame: { selectedPublication: { publicationConfig: { videoSection } } },
//     }]) => {
//       const sections = getFromStates(FIELD_SECTIONS, localState, serverState) || [];
//       return of(setLocalProp(
//         FIELD_SECTIONS,
//         sections.length === 0
//           ? [null, videoSection]
//           : [...sections, videoSection],
//       ));
//     }),
//     takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
//   )),
// );

// This has been disabled, pending removal
// export const removeVideoSectionOnRemoveVideo = (action$, state$) => action$.pipe(
//   ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
//   mergeMap(() => action$.pipe(
//     ofType(DATASTATE_LOCAL_SET_PROPERTY),
//     filter(({ value: { prop, value } }) => prop === FIELD_HERO_VIDEO && value === {}),
//     withLatestFrom(state$),
//     filter(([, {
//       localState, serverState,
//       frame: { selectedPublication: { publicationConfig: { videoSection } } },
//     }]) => {
//       const rawSections = getFromStates(FIELD_SECTIONS, localState, serverState) || [];
//       const sections = [...rawSections];
//       sections.shift();
//       return (!!videoSection &&
//         sections.find(vocab => !!vocab && vocab.id.toString() === videoSection.id.toString())
//       );
//     }),
//     mergeMap(([, {
//       localState, serverState,
//       frame: { selectedPublication: { publicationConfig: { videoSection } } },
//     }]) => {
//       const sections = getFromStates(FIELD_SECTIONS, localState, serverState) || [];
//       return of(setLocalProp(
//         FIELD_SECTIONS,
//         sections.filter(({ id }) => id !== videoSection.id),
//       ));
//     }),
//     takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
//   )),
// );

export const removeKeypointsOnLiveblogChange = action$ => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop } }) => prop === FIELD_LIVEBLOG),
    mergeMap(() => from([
      setLocalProp(
        FIELD_LIVEBLOG_KEYPOINTS,
        [],
      ),
      setLocalProp(
        FIELD_LIVEBLOG_KEYPOINTS_TITLE,
        '',
      ),
    ])),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

export const setVideoProviderKey = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop } }) => prop === FIELD_HERO_VIDEO),
    withLatestFrom(state$),
    switchMap(([
      { value: { value } },
      {
        vocab: { [ARTICLE_TYPE]: articleType },
        dataState: { [FIELD_HERO_IMAGE]: heroImage },
      },
    ]) => {
      const actions = [
        setLocalProp(
          FIELD_VIDEO_PROVIDER,
          value?.data?.providerKey || '',
        ),
      ];
      if (value?.data?.providerKey === PLAYINC_PROVIDER) {
        const videoHub = articleType.items.find(({ name }) => /^video hub$/i.test(name));
        if (videoHub) {
          actions.push(setLocalProp(
            FIELD_ARTICLE_TYPE,
            videoHub,
          ));
        }
        if (value.data.title) {
          actions.push(setLocalProp(
            FIELD_HEADLINE,
            value.data.title,
          ));
        }
        if (value.data.socialHeadline) {
          actions.push(setLocalProp(
            FIELD_SOCIAL_HEADLINE,
            value.data.title,
          ));
        }
        if (value.data.description) {
          actions.push(setLocalProp(
            FIELD_BODY,
            serialize(deserializeString(value.data.description)),
          ));
        }
        if (!heroImage?.data && value?.data?.image && value?.data?.mid) {
          return ajax.post('/api/media/arraybuffer', { url: value?.data?.image })
            .pipe(
              mergeMap(({ response }) => {
                actions.push(assignNotification('Uploading hero image from video'));
                actions.push(saveMediaImage([{
                  image: response,
                  title: `jwplayer-thumbnail-${value.data.mid}.jpg`,
                  alt: `jwplayer-thumbnail-${value.data.mid}.jpg`,
                  copyright: '',
                  description: 'Uploaded from JWPlayer',
                }]));
                actions.push({
                  type: MEDIA_IMAGE_SAVE_CALLBACK,
                  value: data => setLocalProp(
                    FIELD_HERO_IMAGE,
                    {
                      type: MEDIA_IMAGE,
                      data,
                    },
                  ),
                });
                return from(actions);
              }),
              apiCatchError(),
            );
        }
      }
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

export const setHeroImageOnHeroGallerySet = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    withLatestFrom(state$),
    filter(([
      { value: { prop } },
      { dataState: { [FIELD_HERO_IMAGE]: heroImage } },
    ]) => prop === FIELD_HERO_GALLERY && !heroImage?.data),
    switchMap(([{ value: { value } }]) => from([
      assignNotification('Setting hero image from gallery'),
      setLocalProp(
        FIELD_HERO_IMAGE,
        value?.data?.gallery?.[0] || {},
      ),
    ])),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);

const syncOnHeadlineBlur = (sourceKey, targetKey) => (action$, state$) => action$.pipe(
  ofType(ON_BLUR_HEADLINE_FIELD),
  withLatestFrom(state$, (a, b) => b),
  filter(({ localState, serverState }) => !localState[targetKey] && !serverState[FIELD_NID]),
  switchMap(({ localState }) => of(setLocalProp(targetKey, localState[sourceKey]))),
);

export const autoPopulateSEOHeadline = syncOnHeadlineBlur(FIELD_HEADLINE, FIELD_SEO_HEADLINE);
export const autoPopulateShortHeadline = syncOnHeadlineBlur(FIELD_HEADLINE, FIELD_SHORT_HEADLINE);
export const autoPopulateSocialHeadline = syncOnHeadlineBlur(FIELD_HEADLINE, FIELD_SOCIAL_HEADLINE);
export const autoPopulateURLKeyword = syncOnHeadlineBlur(FIELD_HEADLINE, FIELD_URL_KEYWORDS);

export const setLocalOnArticleSensitivity = action$ => action$.pipe(
  ofType(PAGE_ENTER_ARTICLE_EDIT, PAGE_ENTER_ARTICLE_CREATE),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop } }) => prop === FIELD_IS_SENSITIVE),
    mergeMap(({ value: { value } }) =>
      from(ARTICLE_SENSITIVE_FIELDS.map(prop => setLocalProp(prop, value)))),
    takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
  )),
);
