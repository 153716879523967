import { PURGE } from 'redux-persist';
import {
  AD_ASSIGN_SECTION,
  AD_ASSIGN_ARTICLE,
  EDITORS_LETTER_SUCCESS,
  AD_REPLACEMENT_SUCCESS,
  AD_REPLACEMENT_UPDATE_PROPERTY,
  EDITORS_LETTER_UPDATE_PROPERTY,
} from '../../constants/actionTypes';
import {
  ADS_TXT_PAGE_DISPOSE,
  ADS_TXT_SET_CURRENT_ID,
  ADS_TXT_SET_PROPERTY,
} from '../../constants/staticPage/adsTxt';
import {
  AD_CONFIG_FETCH_LOADED,
  AD_CONFIG_SERVER_RESET,
  AD_CONFIG_SET,
  AD_CONFIG_SET_PROPERTY, AD_CREATE_FETCH_LOADED,
} from '../../constants/actionTypes/ad';

const defaultState = {
  ads: [],
  currentConfig: {},
  adConfig: [],
  adConfigFromServer: {},
  selectedSection: {},
  selectedArticle: {},
  editorsLetter: [],
  adReplacements: [],
  adsTxtContent: '',
  adsTxtId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EDITORS_LETTER_SUCCESS:
      return {
        ...state,
        editorsLetter: action.value,
      };

    case EDITORS_LETTER_UPDATE_PROPERTY: {
      const { key, id, data } = action.value;
      return {
        ...state,
        editorsLetter: state.editorsLetter.map((item) => {
          if (item.data.id === id) {
            return {
              ...item,
              data: {
                ...item.data,
                [key]: data,
              },
            };
          }
          return item;
        }),
      };
    }

    case AD_REPLACEMENT_UPDATE_PROPERTY: {
      const { key, id, data } = action.value;
      return {
        ...state,
        adReplacements: state.adReplacements.map((item) => {
          if (item.data.id === id) {
            return {
              ...item,
              data: {
                ...item.data,
                [key]: data,
              },
            };
          }
          return item;
        }),
      };
    }

    case ADS_TXT_SET_CURRENT_ID: {
      return {
        ...state,
        adsTxtId: action.value,
      };
    }

    case ADS_TXT_SET_PROPERTY:
      return {
        ...state,
        adsTxtContent: action.value,
      };

    case AD_REPLACEMENT_SUCCESS:
      return {
        ...state,
        adReplacements: action.value,
      };

    case AD_ASSIGN_SECTION:
      return { ...state, selectedSection: action.value };

    case AD_ASSIGN_ARTICLE:
      return { ...state, selectedArticle: action.value };

    case AD_CREATE_FETCH_LOADED:
      return { ...state, adConfigFromServer: action.value };

    case AD_CONFIG_SERVER_RESET:
      return { ...state, adConfigFromServer: defaultState.adConfigFromServer };

    case AD_CONFIG_FETCH_LOADED:
      return { ...state, adConfig: action.value };

    case AD_CONFIG_SET_PROPERTY: {
      const { type, prop, value } = action.value;
      return {
        ...state,
        currentConfig: {
          ...state.currentConfig,
          [type]: {
            ...(state.currentConfig[type] || {}),
            [prop]: value,
          },
        },
      };
    }

    case AD_CONFIG_SET:
      return { ...state, currentConfig: action.value };

    case ADS_TXT_PAGE_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
