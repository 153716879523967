import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NotesDialog from '../../../dialog/NotesDialog';

import Button from '../../../ui/buttons/Button';
import { ContextPanelItem } from '../../../common/context/ContextPanel';

import { FIELD_NOTES } from '../../../../constants/article/articleFields';

import { setLocalProp } from '../../../../actions/dataState';
import generateKey from '../../../../utils/generateKey';

const ArticleNotes = ({
  setLocalProp: setProp, notes,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  return (<ContextPanelItem label={`Notes (${notes.length})`}>
    <Button caption textOnly onClick={() => setShowDialog(true)}>View</Button>
    {showDialog && <NotesDialog
      notes={notes}
      onSave={(newNote) => {
        if (newNote.id) {
          setProp(FIELD_NOTES, notes.map((note) => {
            if (note.id === newNote.id) {
              return newNote;
            }
            return note;
          }));
        } else {
          setProp(FIELD_NOTES, [
            ...notes,
            {
              ...newNote,
              id: generateKey(),
            },
          ]);
        }
      }}
      onDelete={noteId => setProp(FIELD_NOTES, notes.filter(({ id }) => id !== noteId))}
      open={showDialog}
      handleClose={() => {
        setShowDialog(false);
      }}
    />}
  </ContextPanelItem>);
};

ArticleNotes.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  notes: PropTypes.array,
};

ArticleNotes.defaultProps = {
  notes: [],
};

export default connect(
  ({
    dataState: { [FIELD_NOTES]: notes },
  }) => ({ notes }),
  { setLocalProp },
)(ArticleNotes);

