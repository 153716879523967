import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Close';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import DroppableWrapperMedia from '../DroppableWrapper';
import GalleryDialog from '../../dialog/GalleryLibraryDialog';
import IconButton from '../buttons/IconButton';
import ImagePreview from './ImagePreview';
import { optimiseImage } from '../../helper/media';

const styles = () => ({
  root: {
    position: 'relative',
  },
  image: {
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    },
  },
  removeAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
});

const GallerySelector = (props) => {
  const { classes, label, gallery, required, onSelect, onRemove, onClose } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <Grid container className={classes.root}>
      {!gallery?.gallery && <DroppableWrapperMedia
        icon={<PhotoLibraryIcon />}
        label={label}
        required={required}
        onClick={() => {
          setDialogOpen(true);
        }}
      />}
      {gallery?.gallery && gallery.gallery.length > 0 && <div className={classes.image}>
        <ImagePreview
          url={optimiseImage(gallery.gallery[0].data.url, { width: 200 })}
          onClick={() => {
            setDialogOpen(true);
          }}
        />
        <div className={classes.removeAction}>
          {onRemove && <IconButton
            onClick={(e) => {
              onRemove();
              e.preventDefault();
              e.stopPropagation();
            }}
            color={'primary'}
          >
            <RemoveIcon />
          </IconButton>}
        </div>
      </div>}
      {dialogOpen && <GalleryDialog
        handleClose={() => {
          setDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        open={setDialogOpen}
        onSelectGallery={(data) => {
          onSelect(data);
          setDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
      />}
    </Grid>
  );
};

GallerySelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  onRemove: PropTypes.func,
  onClose: PropTypes.func,
  gallery: PropTypes.object,
  required: PropTypes.bool,
};

GallerySelector.defaultProps = {
  gallery: null,
  onRemove: null,
  onClose: null,
  label: 'Add gallery',
  required: false,
};

export default withStyles(styles)(GallerySelector);
