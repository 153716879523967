import {
  map,
  mergeMap,
  filter,
  withLatestFrom,
  concat,
  combineLatest,
  switchMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { capitalize } from '@material-ui/core';

import {
  STATE_PURGED,
  REDIRECT_TO_LOGIN,
  SET_BREAKING_NEWS_IMAGE,
  BREAKING_NEWS_REQUEST_REJECTED,
  SET_WORKFLOW_GENERIC,
  SET_ARTICLE_WORKFLOW,
} from '../../constants/actionTypes';

import apiCatchError from '../helper/notification';
import { VOCAB_FETCH_WORKFLOW_GENERIC } from '../../constants/actionTypes/vocab';
import {
  CLIENT_SET_ENV,
  PUBLICATION_BY_REGION_LOADED,
  PUBLICATION_BY_REGION_REJECTED,
  PUBLICATION_LOADED, PUBLICATION_SELECTED,
} from '../../constants/actionTypes/publication';
import {
  CONFIG_FETCH_REJECTED,
  CONFIG_FETCH_SUCCESS,
  CONFIG_ENTITY_SAVE,
  CONFIG_ENTITY_SAVE_SUCCESS,
  CONFIG_ENTITY_SAVE_REJECTED, CONFIG_FETCH,
} from '../../constants/actionTypes/config';
import { LOGIN_FAILURE, LOGIN_SUCCESS } from 'constants/actionTypes/login';

export const getClientEnvVars = (action$, state$) => action$.pipe(
  ofType(LOGIN_SUCCESS),
  withLatestFrom(state$),
  mergeMap(() =>
    ajax.getJSON('/api/client').pipe(
      map(response => ({ type: CLIENT_SET_ENV, value: response })),
      apiCatchError(),
    ),
  ),
);

export const loadPublication = action$ => action$.pipe(
  ofType(LOGIN_SUCCESS),
  mergeMap(() =>
    ajax.getJSON('/api/publication').pipe(
      map(response => ({ type: PUBLICATION_LOADED, value: response })),
      apiCatchError(),
    ),
  ),
);

export const loadPublicationByRegion = action$ => action$.pipe(
  ofType(LOGIN_SUCCESS),
  mergeMap(() =>
    ajax.getJSON('/api/regions').pipe(
      map(response => ({
        type: PUBLICATION_BY_REGION_LOADED,
        value: response,
      })),
      apiCatchError(PUBLICATION_BY_REGION_REJECTED),
    ),
  ),
);

export const loadGenericWorkflow = (action$, state$) => action$.pipe(
  ofType(LOGIN_SUCCESS, VOCAB_FETCH_WORKFLOW_GENERIC),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({ login: { user: { roles } } }) =>
    ajax.getJSON('/api/workflow/generic').pipe(
      map(response => ({
        type: SET_WORKFLOW_GENERIC,
        value: response
          .filter(item => roles.includes('cms_admin') || !/hide/i.test(item.name))
          .map((item => ({
            key: item.name.toUpperCase(),
            label: capitalize(item.name),
            id: parseInt(item.id, 10),
          }))),
      })),
      apiCatchError(),
    ),
  ),
);

export const loadWorkflow = action$ => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  switchMap(() =>
    ajax.getJSON('/api/workflow/article').pipe(
      map(response => ({
        type: SET_ARTICLE_WORKFLOW, value: response,
      })),
      apiCatchError(),
    ),
  ),
);

export const checkPublicationNotEmpty = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_LOADED),
  withLatestFrom(state$),
  filter(([, { frame: { publication } }]) => publication.length === 0),
  mergeMap(() => of({ type: REDIRECT_TO_LOGIN }).pipe(
    concat(of({
      type: LOGIN_FAILURE,
      value: 'Unable to load any publications for this account. Please contact your administrator.',
    }).pipe(
      combineLatest(action$.pipe(ofType(STATE_PURGED)), action => action),
    )),
  )),
);

export const addDefaultPublication = (action$, state$) => action$.pipe(
  ofType(PUBLICATION_LOADED),
  withLatestFrom(state$),
  filter(([, state]) =>
    state.frame.publication.length > 0 && typeof state.frame.selectedPublication.name === 'undefined'),
  map(([, { frame: { publication } }]) => publication),
  map(publication => ({
    type: PUBLICATION_SELECTED,
    value: publication[0],
  })),
);

export const fetchBreakingNewsImage = action$ => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  filter(({ value: { publicationConfig: { breakingNewsMID } } }) => !!breakingNewsMID),
  switchMap(({ value: { publicationConfig: { breakingNewsMID } } }) =>
    ajax.getJSON(`/api/media/search?id=${breakingNewsMID}&bundle=remote_image`).pipe(
      map(response => ({ type: SET_BREAKING_NEWS_IMAGE, value: response[0] && response[0].data })),
      apiCatchError(BREAKING_NEWS_REQUEST_REJECTED),
    ),
  ),
);

export const fetchPublicationConfig = action$ => action$.pipe(
  ofType(PUBLICATION_SELECTED),
  map(() => ({ type: CONFIG_FETCH })),
);
