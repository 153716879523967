import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TablePagination from '@material-ui/core/TablePagination';
import EditOutlined from '@material-ui/icons/EditRounded';
import RemoveIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { Pin, PinOutline, DragVertical } from 'mdi-material-ui';

import { LIVEBLOG_TYPE_POST } from '../../constants/liveblog';

import {
  fetchLiveblogPosts,
  editLiveblogPost,
  removeLiveblogPost,
  liveblogPostPinUpdate,
} from '../../actions/liveblog';


import LiveblogItem from './LiveblogPost';
import LiveblogEditor from './LiveblogPostEditor';

import IconButton from '../ui/buttons/IconButton';
import { isPinned } from '../../utils/helper';

import { PagePanel, PagePanelContent, PagePanelHeading } from '../common/page/PagePanel';
import SectionTitle from '../common/SectionTitle';

const sizePerPage = 10;

const LiveblogPosts = ({
  posts,
  postTotal,
  body,
  removeLiveblogPost: onRemove,
  editLiveblogPost: onEdit,
  liveblogPostPinUpdate: onPinUpdate,
  fetchLiveblogPosts: fetchPosts,
  containerId,
}) => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchPosts([containerId, {
      posts: sizePerPage,
      offset: page * sizePerPage,
    }]);
  }, [page, sizePerPage]);

  return (
    <>
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelHeading>
            <SectionTitle>New Post</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <LiveblogEditor
              body={body}
            />
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SectionTitle>Posts</SectionTitle>
          </Grid>
          <Grid item xs={12}>
            {posts.filter(({ data: { scheduleTime } }) =>
              scheduleTime < moment().unix())
              .map(post => (<LiveblogItem
                key={post.data.id}
                post={post}
                action={() => (<div>
                  <IconButton onClick={() => onEdit(post.data.id)}><EditOutlined /></IconButton>
                  <IconButton
                    onClick={() => onRemove([post.data.id, LIVEBLOG_TYPE_POST])}
                  ><RemoveIcon /></IconButton>
                  <IconButton
                    onClick={() => onPinUpdate(post.data.id)}
                  >{ isPinned(post.data.extra) ? <Pin /> : <PinOutline />}</IconButton>
                  { isPinned(post.data.extra) && <IconButton><DragVertical /></IconButton> }
                </div>)}
              />))}
            {postTotal > sizePerPage && <TablePagination
              rowsPerPageOptions={[sizePerPage]}
              component="nav"
              count={postTotal}
              rowsPerPage={sizePerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next',
              }}
              onChangePage={(e, i) => setPage(i)}
            /> }
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

LiveblogPosts.propTypes = {
  containerId: PropTypes.number.isRequired,
  removeLiveblogPost: PropTypes.func.isRequired,
  editLiveblogPost: PropTypes.func.isRequired,
  fetchLiveblogPosts: PropTypes.func.isRequired,
  liveblogPostPinUpdate: PropTypes.func.isRequired,
  posts: PropTypes.array,
  postTotal: PropTypes.number,
  body: PropTypes.array,
};

LiveblogPosts.defaultProps = {
  posts: [],
  postTotal: 0,
  body: [],
};

export default connect(
  ({ liveblog: { posts, body, postTotal } }) => ({ posts, body, postTotal }),
  {
    removeLiveblogPost,
    editLiveblogPost,
    fetchLiveblogPosts,
    liveblogPostPinUpdate,
  },
)(LiveblogPosts);
