import { createSelector } from 'reselect';

import { FIELD_COMPONENTS } from '../../constants/layout/layoutFields';

export const getComponent = (state, props) =>
  state.dataState?.[FIELD_COMPONENTS]?.[props.componentId] || {};
export const getComponentFromContext = state => (state.layout?.context?.data?.id
  ? state.dataState?.[FIELD_COMPONENTS]?.[state.layout.context.data.id] || {}
  : {});
export const getComponentLockedData = (state, props) =>
  state.externalState?.[FIELD_COMPONENTS]?.[props.componentId]
    ? state.fieldLock?.[`${FIELD_COMPONENTS}_${props.componentId}`] || null
    : null;

export const makeGetComponent = () => createSelector(
  [getComponent],
  component => component,
);

export const makeGetComponentFromContext = () => createSelector(
  [getComponentFromContext],
  component => component,
);

export const makeGetComponentProp = prop => createSelector(
  [getComponent],
  component => component[prop],
);

export const makeGetComponentLockedData = () => createSelector(
  [getComponentLockedData],
  lockData => lockData,
);
