export const CONTENT_TYPE_ARTICLE = 'article';

export const FOCUS_POSITION_START = 'start';
export const FOCUS_POSITION_END = 'end';

export const DISPLAY_DATE_VANITY = 'vanityDate';
export const DISPLAY_DATE_UPDATED = 'lastUpdatedDate';
export const DISPLAY_DATE_PUBLISHED = 'publishedDate';
export const DISPLAY_DATE_PREFERENCE_OPTS = {
  [DISPLAY_DATE_UPDATED]: 'Last updated date',
  [DISPLAY_DATE_PUBLISHED]: 'Published date',
  [DISPLAY_DATE_VANITY]: 'Vanity date',
};

export const EDITORIAL_PRIORITY_OPTS = [1, 2, 3, 4, 5];

export const LANG_RUSSIA = 'ru';
export const LANG_ENGLISH = 'en';
export const COMPARE_MODE = 'compare';

export const ARTICLE_TYPE_REGEX_QA = new RegExp('explainer|question');
export const ARTICLE_TYPE_REGEX_PRODUCT = new RegExp('product');
export const ARTICLE_TYPE_REGEX_LIVEBLOG = new RegExp('liveblog');
export const ARTICLE_TYPE_REGEX_FEATURE = new RegExp('feature', 'i');
export const ARTICLE_TYPE_REGEX_SERVICE = new RegExp('service', 'i');
export const ARTICLE_TYPE_REGEX_REVIEW = new RegExp('review', 'i');
export const ARTICLE_TYPE_REGEX_COMMERCIAL = new RegExp('commercial', 'i');
export const ARTICLE_TYPE_REGEX_VIDEO_HUB = new RegExp('videohub', 'i');
export const ARTICLE_TYPE_REGEX_LUXURY = new RegExp("luxury");

export const RECOMMENDED_LENGTH_HEADLINE = 110;
export const MAX_LENGTH_SHORT_HEADLINE = 70;
export const MAX_LENGTH_SEO_DESCRIPTION = 160;
export const MAX_LENGTH_STAND_FIRST = 280;
export const MAX_LENGTH_SUB_HEADLINE = 2048;
export const MAX_LENGTH_SOCIAL_HEADLINE = 85;

export const REVISION_REVERT_WARNING = 'This revision will be set as the live version of this article, ' +
  'and will be published if this article is published. Any offline changes will be removed.';
export const INVALID_SOCIAL_EMBED_WARNING = 'Social embed has an invalid or missing url.';
export const REDIRECT_LOOP_DETECTED_ERROR_MESSAGE = 'An article cannot redirect to itself, please change url.';
export const NOTIFICATION_ARTICLE_SAVE_FAILURE = 'Unable to save this article. Please reload this page and ' +
  'try again. If error still persist, please notify administrator.';
