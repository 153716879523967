import { ofType } from 'redux-observable';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import apiCatchError, { showSuccessNotification } from '../helper/notification';

import {
  USER_EVENTS_FETCH,
  USER_EVENTS_FETCH_SUCCESS,
  USER_EVENTS_FETCH_REJECTED,
  USER_EVENTS_DELETE,
  USER_EVENTS_DELETE_SUCCESS,
  USER_EVENTS_DELETE_REJECTED, USER_EVENTS_SAVE, USER_EVENTS_SAVE_SUCCESS, USER_EVENTS_SAVE_REJECTED,
} from '../../constants/actionTypes/service/userEvents';
import { queryStringify } from '../../utils/urlHelper';

export const fetchUserEvents = (action$, state$) => action$.pipe(
  ofType(USER_EVENTS_FETCH),
  withLatestFrom(state$),
  switchMap(([, { frame: { selectedPublication: { domain } }, userEvents: { filter } }]) => {
    const url = filter
      ? `/api/service/user-events/${domain}?${queryStringify(filter)}`
      : `/api/service/user-events/${domain}`;
    return ajax.get(
      url, { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => {
        const {
          Items: items,
          Count: total,
          LastEvaluatedKey: lastKey,
        } = response?.res || {};
        return of({
          type: USER_EVENTS_FETCH_SUCCESS,
          value: {
            items,
            total,
            lastKey,
          },
        });
      }),
      apiCatchError(USER_EVENTS_FETCH_REJECTED),
    );
  }),
);

export const deleteUserEvent = (action$, state$) => action$.pipe(
  ofType(USER_EVENTS_DELETE),
  withLatestFrom(state$),
  switchMap(([{ value }, { frame: { selectedPublication: { domain } } }]) => ajax.delete(
    `/api/service/user-events/${domain}/${value.createdAt}`, { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(() => of({
      type: USER_EVENTS_DELETE_SUCCESS,
      value,
    })),
    apiCatchError(USER_EVENTS_DELETE_REJECTED),
  )),
);

export const deleteUserEventNotification = action$ => action$.pipe(
  ofType(USER_EVENTS_DELETE_SUCCESS),
  mergeMap(showSuccessNotification('Event is successfully deleted.')),
);

export const updateUserEvent = action$ => action$.pipe(
  ofType(USER_EVENTS_DELETE_SUCCESS, USER_EVENTS_SAVE_SUCCESS),
  switchMap(() => of({
    type: USER_EVENTS_FETCH,
  })),
);

export const saveUserEvent = action$ => action$.pipe(
  ofType(USER_EVENTS_SAVE),
  switchMap(({ value }) => ajax.put(
    '/api/service/user-events', value, { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(() => of({
      type: USER_EVENTS_SAVE_SUCCESS,
      value,
    })),
    apiCatchError(USER_EVENTS_SAVE_REJECTED),
  )),
);

export const saveUserEventNotification = action$ => action$.pipe(
  ofType(USER_EVENTS_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Event is successfully saved.')),
);
