import { PURGE } from 'redux-persist';
import {
  SET_ARTICLE_WORKFLOW,
  APP_LOAD,
  SET_BREAKING_NEWS_IMAGE,
  CONTEXT_SET, CONTEXT_DISPOSE,
} from '../../constants/actionTypes';
import { WS_ID } from '../../constants/actionTypes/ws';
import {
  APP_CRASH_CLOSE,
  APP_CRASHED,
  REMOVE_NOTIFICATION,
  SHOW_NOTIFICATION,
} from '../../constants/actionTypes/notification';
import { CONTEXT_DEFAULT } from '../../constants/contexts';
import {
  CLIENT_SET_ENV,
  PUBLICATION_BY_REGION_LOADED,
  PUBLICATION_LOADED, PUBLICATION_SELECTED
} from "../../constants/actionTypes/publication";

const defaultState = {
  client: null,
  selectedPublication: {},
  publication: [],
  publicationGroupByRegion: [],
  workflowOptions: [],
  chain: [],
  notifications: [],
  autoSaved: false,
  socketId: null,
  breakingNewsImage: null,
  showAppCrashDialog: false,
  context: {
    type: CONTEXT_DEFAULT,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_CRASH_CLOSE:
      return { ...state, showAppCrashDialog: false };

    case APP_CRASHED:
      return { ...state, showAppCrashDialog: true };

    case WS_ID:
      return { ...state, socketId: action.value };

    case SET_ARTICLE_WORKFLOW:
      return { ...state, workflowOptions: action.value };

    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(({ key }) => key !== action.value),
      };

    case SHOW_NOTIFICATION:
      if (
        state.notifications.some(({ message }) => message === action.value.message)
      ) return state;
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.value,
            key: new Date().getTime() + Math.random(),
          },
        ],
      };

    case SET_BREAKING_NEWS_IMAGE:
      return { ...state, breakingNewsImage: action.value };

    case PUBLICATION_SELECTED:
      return {
        ...state,
        selectedPublication: {
          ...action.value,
          features: action.value?.features || [],
          vocabs: action.value?.vocabs || ['sections'],
        },
      };

    case PUBLICATION_BY_REGION_LOADED:
      return { ...state, publicationGroupByRegion: action.value };

    case PUBLICATION_LOADED:
      return { ...state, publication: action.value };

    case CLIENT_SET_ENV:
      return {
        ...state,
        ...action.value,
      };

    case APP_LOAD:
      return { ...state, notification: defaultState.notification };

    case CONTEXT_SET:
      return { ...state, context: action.value };

    case CONTEXT_DISPOSE:
      return { ...state, context: defaultState.context };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
