import { PURGE } from 'redux-persist';
import {
  DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS,
} from 'constants/actionTypes';
import {
  DIALOG_IMAGE_MEDIA_DISPOSE, DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED,
  DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS,
  DIALOG_ON_RESET_EDIT_MEDIA,
  DIALOG_ON_SEARCH_MEDIA,
  DIALOG_ON_SEARCH_MEDIA_REJECTED,
  DIALOG_ON_SEARCH_MEDIA_SUCCESS, DIALOG_ON_UPLOAD_CHP, DIALOG_ON_UPLOAD_CHP_SUCCESS,
  DIALOG_ON_UPLOAD_MEDIA,
  DIALOG_ON_UPLOAD_MEDIA_REJECTED,
  DIALOG_ON_UPLOAD_MEDIA_SUCCESS,
} from 'constants/actionTypes/dialog';
import {
  CHP_FETCH_MEDIA,
  CHP_FETCH_MEDIA_REJECTED,
  CHP_FETCH_MEDIA_SUCCESS,
  VIDEO_DUGOUT_SEARCH,
  VIDEO_DUGOUT_SEARCH_REJECTED,
  VIDEO_DUGOUT_SEARCH_SUCCESS,
  VIDEO_JWPLAYER_SEARCH,
  VIDEO_JWPLAYER_SEARCH_REJECTED,
  VIDEO_JWPLAYER_SEARCH_SUCCESS,
  GALLERY_FETCH,
  MEDIA_CREATE_REJECTED,
  MEDIA_CREATE_SUCCESS,
  MEDIA_EDIT_REJECTED,
  MEDIA_EDIT_SUCCESS,
  MEDIA_IMAGE_SAVE,
  MEDIA_IMAGE_SAVE_EDIT,
} from 'constants/actionTypes/media';
import { PAGE_ENTER_ARTICLE_CREATE, PAGE_ENTER_ARTICLE_EDIT_LOADED } from 'constants/actionTypes/route';
import {
  DAILYMOTION_SEARCH,
  DAILYMOTION_SEARCH_REJECTED,
  DAILYMOTION_SEARCH_SUCCESS,
  DAILYMOTION_FETCH_CHANNELS_SUCCESS,
} from 'constants/actionTypes/integration/dailymotion';

const defaultState = {
  images: [],
  imageOnEdit: [],
  imageOnCrop: {},
  dugout: { playlist: [], total: 0 },
  jwplayer: { list: [], total: 0, offset: 0, limit: 10 },
  dailyMotion: { list: [], total: 0 },
  dailyMotionChannels: [],
  isDailyMotionSearching: false,
  isJWPlayerSearching: false,
  isDugoutSearching: false,
  isImageSearching: false,
  isUploading: false,
};

// @todo refactor isSearching logic having multiple bools seems excessive
export default (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_ENTER_ARTICLE_EDIT_LOADED:
    case PAGE_ENTER_ARTICLE_CREATE:
    case DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED:
    case GALLERY_FETCH: {
      return {
        ...state,
        isUploading: false,
      };
    }

    case DAILYMOTION_SEARCH: {
      return {
        ...state,
        isDailyMotionSearching: true,
      };
    }

    case DAILYMOTION_SEARCH_REJECTED: {
      return {
        ...state,
        isDailyMotionSearching: false,
      };
    }

    case DAILYMOTION_SEARCH_SUCCESS: {
      const { list = [], total = 0 } = action.value;
      return {
        ...state,
        isDailyMotionSearching: false,
        dailyMotion: {
          list,
          total,
        },
      };
    }

    case DAILYMOTION_FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        dailyMotionChannels: action.value,
      };

    case VIDEO_DUGOUT_SEARCH: {
      return {
        ...state,
        isDugoutSearching: true,
      };
    }

    case VIDEO_DUGOUT_SEARCH_REJECTED: {
      return {
        ...state,
        isDugoutSearching: false,
      };
    }

    case VIDEO_DUGOUT_SEARCH_SUCCESS: {
      const { playlist = [], total = 0 } = action.value;
      return {
        ...state,
        isDugoutSearching: false,
        dugout: {
          playlist,
          total,
        },
      };
    }

    case VIDEO_JWPLAYER_SEARCH: {
      return {
        ...state,
        isJWPlayerSearching: true,
      };
    }

    case VIDEO_JWPLAYER_SEARCH_REJECTED: {
      return {
        ...state,
        isJWPlayerSearching: false,
      };
    }

    case VIDEO_JWPLAYER_SEARCH_SUCCESS: {
      const {
        limit, offset, total, videos,
      } = action.value;
      return {
        ...state,
        isJWPlayerSearching: false,
        jwplayer: {
          ...state.jwplayer,
          list: videos,
          limit,
          offset,
          total,
        },
      };
    }

    case DIALOG_IMAGE_MEDIA_DISPOSE:
      return {
        ...state,
        imageOnEdit: action.value ?
          state.imageOnEdit.filter(({ data: { mid } }) => mid === action.value) : defaultState.imageOnEdit,
        imageOnCrop: defaultState.imageOnCrop,
      };

    case CHP_FETCH_MEDIA_SUCCESS:
    case CHP_FETCH_MEDIA_REJECTED:
    case MEDIA_EDIT_SUCCESS:
    case MEDIA_EDIT_REJECTED:
    case MEDIA_CREATE_SUCCESS:
    case MEDIA_CREATE_REJECTED:
    case DIALOG_ON_UPLOAD_MEDIA_REJECTED: {
      return {
        ...state,
        isUploading: false,
      };
    }

    case CHP_FETCH_MEDIA:
    case MEDIA_IMAGE_SAVE:
    case MEDIA_IMAGE_SAVE_EDIT:
    case DIALOG_ON_UPLOAD_CHP:
    case DIALOG_ON_UPLOAD_MEDIA: {
      return {
        ...state,
        isUploading: true,
      };
    }

    case DIALOG_ON_UPLOAD_CHP_SUCCESS: {
      const [currentImage] = state.imageOnEdit;
      const {
        url,
        headline,
        provider,
        caption,
        objectId,
        xurn,
        gid,
      } = action.value;
      return {
        ...state,
        isUploading: false,
        imageOnEdit: [
          {
            ...currentImage,
            data: {
              ...currentImage.data,
              url,
              title: headline,
              alt: headline,
              copyright: provider,
              description: caption,
              notes: '',
              extra: {
                chp_object_id: objectId,
                chp_gid: gid,
                chp_xurn: xurn,
              },
            },
          },
        ],
      };
    }

    case DIALOG_ON_UPLOAD_MEDIA_SUCCESS: {
      const [currentImage] = state.imageOnEdit;
      return {
        ...state,
        isUploading: false,
        imageOnEdit: [
          {
            ...currentImage,
            data: {
              ...currentImage.data,
              url: action.value,
            },
          },
        ],
      };
    }

    case DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS:
      return {
        ...state,
        isUploading: false,
        imageOnCrop: action.value,
      };

    case DIALOG_ON_RESET_EDIT_MEDIA:
      return {
        ...state,
        imageOnEdit: defaultState.imageOnEdit,
        imageOnCrop: defaultState.imageOnCrop,
      };

    case DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS:
      return {
        ...state,
        isUploading: false,
        imageOnEdit: [
          ...state.imageOnEdit,
          ...action.value,
        ],
      };

    case DIALOG_ON_SEARCH_MEDIA:
      return {
        ...state,
        isImageSearching: true,
      };

    case DIALOG_ON_SEARCH_MEDIA_REJECTED:
      return {
        ...state,
        isImageSearching: false,
      };

    case DIALOG_ON_SEARCH_MEDIA_SUCCESS:
      return {
        ...state,
        images: action.value,
        isImageSearching: false,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
