import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextSearchFilter from '../../ui/table/filters/TextSearchFilter';
import ListTable from '../../table/ListTable';
import TableHeader from '../../table/TableHeader';
import { TableBody, TableCell, TableRow } from '../../ui/table/Table';
import ThumbnailImage from '../../table/content/ThumbnailImage';
import Button from '../../ui/buttons/Button';
import { fetchPlayIncVideos } from '../../../actions/integration/playInc';
import { CMS_DATE_FORMAT } from '../../../constants/common';

const styles = theme => ({
  table: {
    margin: theme.spacing(0, -2),
  },
});

const hasLimit = limit => limit !== null && !isNaN(limit);

export const PLAYINC_PROVIDER = 'pixel_ai';
export const PLAYINC_FIELD_PLAYLIST = 'primaryPlaylistId';

const PlayIncVideoSelectorList = ({
  classes,
  onSelect,
  videos,
  totalVideos,
  isFetchingVideos,
  limit,
  showVarticles,
  fetchPlayIncVideos: fetch,
}) => {
  const [selected, setSelected] = useState([]);
  const [query, setQuery] = useState('');
  const queryData = [
    query,
  ];
  const headers = ['Image', 'Title', 'Published', ''];
  useEffect(() => {
    if (hasLimit(limit)) {
      onSelect(selected);
    }
  }, [selected]);
  const getFetchData = (page, rowsPerPage) => ({
    title: query,
    status: showVarticles ? 'published' : 'draft',
    limit: rowsPerPage,
    offset: rowsPerPage * page,
  });
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextSearchFilter
              placeholder={'Search video articles'}
              defaultValue={query}
              onSubmit={(textQuery) => {
                setQuery(textQuery);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.table}>
          <ListTable
            isFetching={isFetchingVideos}
            total={totalVideos}
            queryData={queryData}
            getFetchData={getFetchData}
            fetchList={fetch}
            rowsPerPageOptions={[10, 20, 30]}
          >
            <TableHeader
              headers={headers.map(header => ({ id: header, label: header }))}
              hasBorder
            />
            <TableBody>
              {videos.length === 0 && <TableRow>
                <TableCell colSpan={headers.length} align={'center'}>No videos found</TableCell>
              </TableRow>}
              {videos.length > 0 && videos
                .map((video) => {
                  const { id, image, title, pubdate } = video;
                  video.providerKey = PLAYINC_PROVIDER;
                  return (
                    <TableRow key={id} hasActions  selected={!!selected[id]}>
                      <TableCell>{image &&
                        <ThumbnailImage image={{ data: { url: image, title: title || '' } }} size={'small'} />
                      }</TableCell>
                      <TableCell>{title || ''}</TableCell>
                      <TableCell>{pubdate ? moment.unix(pubdate).format(CMS_DATE_FORMAT) : ''}</TableCell>
                      <TableCell>
                        <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
                          {hasLimit(limit) && <Button
                            onClick={() => {
                              if (!selected[id]) {
                                setSelected({
                                  ...selected,
                                  [id]: title,
                                });
                              } else {
                                delete selected[id];
                                setSelected({ ...selected });
                              }
                            }}
                            variant={'contained'}
                            disabled={
                              !selected[id] &&
                              limit > -1 &&
                              Object.entries(selected).length >= limit
                            }
                          >{selected[id] ? 'Deselect' : 'Select'}</Button>}
                          {!hasLimit(limit) && <Button
                            onClick={() => onSelect(video)}
                            variant={'contained'}
                          >Select</Button>}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </ListTable>
        </div>
      </Grid>
    </Grid>
  );
};

PlayIncVideoSelectorList.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  fetchPlayIncVideos: PropTypes.func.isRequired,
  videos: PropTypes.array,
  totalVideos: PropTypes.number,
  isFetchingVideos: PropTypes.bool,
  limit: PropTypes.number,
  showVarticles: PropTypes.bool,
};

PlayIncVideoSelectorList.defaultProps = {
  videos: [],
  totalVideos: 0,
  isFetchingVideos: false,
  limit: null,
  showVarticles: false,
};

export default withStyles(styles)(connect(
  ({
    integration: { playInc: { videos, isFetchingVideos, totalVideos } = {} },
  }) => ({ videos, isFetchingVideos, totalVideos }),
  { fetchPlayIncVideos },
)(PlayIncVideoSelectorList));
