const translation = {
  en: {
    article: {
      related: 'Related article',
    },
  },
  ru: {
    article: {
      related: 'Читайте также',
    },
  },
};

export default translation;
