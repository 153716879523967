import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

import { unlockFieldLock } from '../../actions/fieldLock';
import FieldLockOverlay from './FieldLockOverlay';

const styles = () => ({
  lock: {
    position: 'relative',
    '&:first-child > *': {
      marginTop: 0,
    },
    '&:last-child > *': {
      marginBottom: 0,
    },
  },
});

const FieldLockField = (props) => {
  const {
    classes, fullWidth, children, margin,
    onUnlock, className, lockValue, field,
    unlockFieldLock: unlock,
    ...rest
  } = props;
  return (
    <FormControl margin={margin} fullWidth={fullWidth} className={classes.lock} {...rest}>
      {children}
      {lockValue && <FieldLockOverlay
        className={className}
        lock={lockValue}
        onUnlock={() => {
          unlock(field);
          if (onUnlock) {
            onUnlock();
          }
        }}
      />}
    </FormControl>
  );
};

FieldLockField.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  unlockFieldLock: PropTypes.func.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  margin: PropTypes.string,
  lockValue: PropTypes.object,
  onUnlock: PropTypes.func,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

FieldLockField.defaultProps = {
  margin: 'none',
  fullWidth: true,
  className: null,
  lockValue: null,
  onUnlock: null,
};

export default withStyles(styles)(connect(
  () => ({}),
  { unlockFieldLock },
)(FieldLockField));
