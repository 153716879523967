import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from '../../ui/Select';
import { fetchWorkflowGeneric } from '../../../actions/vocab';

const WorkflowSelector = ({
  selectedValue, workflowGeneric, onChange, showAllOption, fetchWorkflowGeneric: fetchWorkflow,
  ...rest
}) => {
  useEffect(() => {
    if (!Array.isArray(workflowGeneric)) {
      fetchWorkflow();
    }
  }, [workflowGeneric]);

  if (!Array.isArray(workflowGeneric)) {
    return null;
  }

  const options = workflowGeneric.reduce((acc, next) => ({ ...acc, [next.id]: next.label }), {});
  if (showAllOption) {
    options.all = 'All';
  }
  return (<Select
    value={selectedValue}
    onChange={(e) => {
      if (e.target.value) {
        onChange(e.target.value);
      }
    }}
    label={'Workflow'}
    items={options}
    {...rest}
  />);
};

WorkflowSelector.propTypes = {
  workflowGeneric: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  fetchWorkflowGeneric: PropTypes.func.isRequired,
  showAllOption: PropTypes.bool,
};

WorkflowSelector.defaultProps = {
  showAllOption: false,
};

export default connect(
  ({ vocab: { workflowGeneric } }) => ({ workflowGeneric }),
  { fetchWorkflowGeneric },
)(WorkflowSelector);

