import {
  TWITTER_ADD_KEYWORD,
  TWITTER_ADD_USER_HANDLE, TWITTER_ON_DISPOSE, TWITTER_ON_LOAD, TWITTER_REMOVE_KEYWORD,
  TWITTER_REMOVE_USER_HANDLE, TWITTER_SEARCH_USER,
} from '../constants/actionTypes';

export const searchUser = value => ({ type: TWITTER_SEARCH_USER, value });
export const addUser = value => ({ type: TWITTER_ADD_USER_HANDLE, value });
export const removeUser = value => ({ type: TWITTER_REMOVE_USER_HANDLE, value });
export const addKeyword = value => ({ type: TWITTER_ADD_KEYWORD, value });
export const removeKeyword = value => ({ type: TWITTER_REMOVE_KEYWORD, value });
export const twitterOnLoad = value => ({ type: TWITTER_ON_LOAD, value });
export const twitterDispose = value => ({ type: TWITTER_ON_DISPOSE, value });
