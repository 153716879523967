import * as React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';
import {
  isMarkActive,
  ToolbarButton,
  toggleMark,
  getPreventDefaultHandler,
} from '@udecode/slate-plugins';
import { Transforms } from 'slate';

/**
 * Toolbar button to toggle the mark of the leaves in selection.
 * copied from slate-plugins as isMarkActive does not fail gracefully when there are nested editors
 */
const ToolbarMark = ({ type, clear, ...props }) => {
  const editor = useSlate();
  let isActive;
  try {
    isActive = isMarkActive(editor, type);
  } catch (e) {
    Transforms.deselect(editor);
    isActive = false;
  }
  return (
    <ToolbarButton
      active={isActive}
      onMouseDown={getPreventDefaultHandler(toggleMark, editor, type, clear)}
      {...props}
    />
  );
};

ToolbarMark.propTypes = {
  type: PropTypes.string.isRequired,
  clear: PropTypes.string,
};

ToolbarMark.defaultProps = {
  clear: null,
};

export default ToolbarMark;
