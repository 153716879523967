import { ofType } from 'redux-observable';
import { switchMap, map, mapTo, withLatestFrom, merge, filter, takeUntil, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  FETCH_REDIRECT_LIST,
  FETCH_REDIRECT_LIST_REJECTED,
  FETCH_REDIRECT_LIST_SUCCESS,
  REMOVE_REDIRECT_ITEM, REMOVE_REDIRECT_ITEM_REJECTED, REMOVE_REDIRECT_ITEM_SUCESS,
  SAVE_REDIRECT,
  SAVE_REDIRECT_REJECTED,
  SAVE_REDIRECT_SUCCESS,
} from '../../constants/redirect';

import apiCatchError from '../helper/notification';
import { serialize } from '../../utils/urlHelper';
import {of} from "rxjs";
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const saveRedirectItem = (action$, state$) => action$.pipe(
  ofType(SAVE_REDIRECT),
  withLatestFrom(state$),
  switchMap(([
    { value: { source, destination, id } },
    { frame: { selectedPublication: { id: publicationId, name } } },
  ]) => {
    const payload = {
      name: [{
        value: `${name} ${source} ${destination}`,
      }],
      bundle: [{
        target_id: 'pre_content',
        target_type: 'flowz_redirect_type',
      }],
      field_source_path: [{
        value: source,
      }],
      field_destination_path: [{
        value: destination,
      }],
      field_publications: [{
        target_type: 'taxonomy_term',
        target_id: publicationId,
      }],
    };
    if (id) {
      payload.id = [{
        value: id,
      }];
      return ajax.patch(`/api/redirect/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mapTo({ type: SAVE_REDIRECT_SUCCESS }),
        apiCatchError(SAVE_REDIRECT_REJECTED),
      );
    }
    return ajax.post('/api/redirect', payload, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: SAVE_REDIRECT_SUCCESS }),
      apiCatchError(SAVE_REDIRECT_REJECTED),
    );
  }),
);

export const fetchRedirectList = (action$, state$) => {
  let cachedParam = {};
  return action$.pipe(
    ofType(FETCH_REDIRECT_LIST),
    switchMap(action => of(action).pipe(
      merge(action$.pipe(
        ofType(PUBLICATION_SELECTED, SAVE_REDIRECT_SUCCESS, REMOVE_REDIRECT_ITEM_SUCESS),
        filter(() => !!cachedParam),
        map(() => ({
          type: FETCH_REDIRECT_LIST,
          value: cachedParam,
        })),
        takeUntil(action$.pipe(ofType(LOCATION_CHANGE))),
      )),
      withLatestFrom(state$),
      debounceTime(500),
      takeUntil(action$.pipe(ofType(LOCATION_CHANGE))),
      switchMap(([{ value }, { frame: { selectedPublication: { domain } } }]) => {
        if (value) {
          cachedParam = {
            ...value,
            publication: domain,
          };
        }
        return ajax.getJSON(`/api/redirects/pre-content?${serialize(cachedParam)}`).pipe(
          map(response => ({ type: FETCH_REDIRECT_LIST_SUCCESS, value: response })),
          apiCatchError(FETCH_REDIRECT_LIST_REJECTED),
        );
      }),
    )),
  );
};

export const removeItem = action$ => action$.pipe(
  ofType(REMOVE_REDIRECT_ITEM),
  switchMap(({ value }) =>
    ajax.delete(`/api/redirect/${value}`).pipe(
      map(response => ({ type: REMOVE_REDIRECT_ITEM_SUCESS, value: response })),
      apiCatchError(REMOVE_REDIRECT_ITEM_REJECTED),
    ),
  ),
);
