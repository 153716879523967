import React from 'react';
import PropTypes from 'prop-types';

import { PagePanelContent, PagePanelHeading, PagePanelSection } from '../common/page/PagePanel';
import FieldLockContainer from './FieldLockContainer';
import SectionTitle from '../common/SectionTitle';

const FieldLockPanelSection = ({
  field, lockValue, hasChanges, required, label, children, noPadding,
}) => (
  <PagePanelSection>
    <FieldLockContainer field={field} lockValue={lockValue}>
      {label && <PagePanelHeading>
        <SectionTitle hasChanges={hasChanges} required={required}>{label}</SectionTitle>
      </PagePanelHeading>}
      <PagePanelContent noPadding={noPadding}>
        {children}
      </PagePanelContent>
    </FieldLockContainer>
  </PagePanelSection>
);

FieldLockPanelSection.propTypes = {
  children: PropTypes.node.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  label: PropTypes.string,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  required: PropTypes.bool,
  noPadding: PropTypes.bool,
};

FieldLockPanelSection.defaultProps = {
  label: '',
  lockValue: null,
  hasChanges: false,
  required: false,
  noPadding: false,
};

export default FieldLockPanelSection;
