import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, NavLink as NavLinkBase } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import NewArticleIcon from '@material-ui/icons/NoteAdd';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import VisualStoryIcon from '@material-ui/icons/DynamicFeed';
import DashboardIcon from '@material-ui/icons/ViewComfy';
import TranslateIcon from '@material-ui/icons/Translate';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import EventIcon from '@material-ui/icons/Event';
import grey from '@material-ui/core/colors/grey';
import CommentIcon from '@material-ui/icons/Comment';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import {
  PictureInPicture as PictureInPictureIcon,
  FormatListBulleted,
  ContactMail as ContactUsIcon,
  WebAsset,
  ListAlt as ListAltIcon,
  Menu as MenuIcon,
  Receipt,
  Dashboard as LayoutIcon,
  SupervisorAccount as UsersIcon,
  Stars as PremiumIcon,
  LibraryBooks,
} from '@material-ui/icons';
import { TooltipTextOutline, CreditCardOutline, CogOutline } from 'mdi-material-ui';

import {
  FEATURE_LIVEBLOGS,
  FEATURE_ARTICLE_CREATION,
  FEATURE_EDITION,
  FEATURE_PREMIUM_AD_REPLACEMENT,
  FEATURE_STORIES,
  FEATURE_CONTACT_US,
  FEATURE_EVENTS,
  FEATURE_USER_EVENTS,
  FEATURE_USER_COMMENTS,
  FEATURE_PLAY_INC,
} from '../../constants/features';

import { drawerLoaded } from '../../actions/drawer';

import Logo from '../header/Logo';

import { headerHeight } from '../header';
import {
  ACCESS_CONTENT,
  ADMINISTER_USER,
  CREATE_AD_CONFIG,
  CREATE_ARTICLE_CONTENT,
  CREATE_STORY,
  CREATE_EDITOR_LETTER,
  CREATE_FULL_NAV_MENU,
  CREATE_LIVEBLOG,
  CREATE_MANUAL_LIST,
  CREATE_MEDIA,
  CREATE_REDIRECT,
  CREATE_SIDEBAR,
  EDIT_DAILY_EDITION,
  CREATE_LAYOUT_SECTION,
  TRANSLATION,
  ARTICLE_LIST,
  CREATE_STATIC_PAGE,
  CREATE_EVENT,
} from '../../constants/permission';
import { hasFeatures } from '../helper/utils';
import Stamp from '../header/Stamp';

export const drawerWidth = 160;
export const drawerCollapseWidth = 74;

const style = theme => ({
  root: {
    position: 'relative',
  },
  gap: {
    minHeight: '49px',
  },
  listItemIcon: {
    minWidth: '66px',
    position: 'relative',
    marginRight: theme.spacing(1),
    justifyContent: 'center',
    '& .icon': {
      fontSize: '1.2em',
    },
    '& svg': {
      fontSize: '1.4em',
      fill: grey[800],
    },
    '.expanded &': {
      minWidth: '44px',
      marginRight: 0,
    },
  },
  drawerRoot: {
    padding: 0,
  },
  link: {
    color: theme.typography.color.secondary,
    borderBottom: `1px solid ${theme.palette.border.primary.light}`,
    borderLeft: `4px solid ${theme.palette.background.contrast}`,
    textDecoration: 'none',
    padding: '1px 0 0',
    minHeight: 38,
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%',
    position: 'relative',
    '&:hover': {
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.background.hover,
      '&:before': {
        opacity: 1,
      },
    },
    '& svg': {
      marginLeft: '-2px',
    },
    '&.active': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
      },
      '& svg': {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
  createBtn: {
    backgroundColor: theme.palette.common.white,
  },
  extendedCreateBtn: {
    textTransform: 'none',
    backgroundColor: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
  },
  plusIcon: {
    marginRight: theme.spacing(1),
  },
  createLink: {
    paddingLeft: 0,
    '&:before': {
      display: 'none',
    },
  },
  gutters: {
    padding: 0,
  },
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  logo: {
    display: 'flex',
    height: headerHeight,
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.2)',
  },
  stamp: {
    justifyContent: 'flex-start',
    paddingLeft: 25,
    width: theme.spacing(6),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    borderWidth: 0,
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.26)',
  },
  drawerOpen: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
    width: drawerCollapseWidth,
    '& h2': {
      visibility: 'hidden',
    },
  },
  paperAnchorDockedLeft: {
    zIndex: 0,
  },
  drawerHeader: {
    padding: theme.spacing(2, 1, 1, 1),
  },
  drawerContainer: {
    width: drawerWidth,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
  },
});

const browseMenu = [
  [WebAsset, '/live-browse', 'Browse', [ACCESS_CONTENT], FEATURE_ARTICLE_CREATION],
  [FormatListBulleted, '/article', 'Articles', [ARTICLE_LIST], FEATURE_ARTICLE_CREATION],
  [VisualStoryIcon, '/story', 'Stories', [CREATE_STORY], FEATURE_STORIES],
  [EventIcon, '/event', 'Events', [CREATE_EVENT], FEATURE_EVENTS],
  [EventIcon, '/user-events', 'Events', [ACCESS_CONTENT], FEATURE_USER_EVENTS],
  [CommentIcon, '/user-comments', 'Comments', [ACCESS_CONTENT], FEATURE_USER_COMMENTS],
  [TooltipTextOutline, '/liveblog', 'Live blogs', [CREATE_LIVEBLOG], FEATURE_LIVEBLOGS],
  [PhotoAlbumIcon, '/media/image', 'Media', [CREATE_MEDIA]],
  [LibraryBooks, '/edition/daily', 'Editions', [EDIT_DAILY_EDITION], FEATURE_EDITION],
  [CreditCardOutline, '/ecommerce', 'eCommerce', [ACCESS_CONTENT], FEATURE_ARTICLE_CREATION],
  [OndemandVideoIcon, '/playinc', 'Video articles', [ACCESS_CONTENT], FEATURE_PLAY_INC],
];

const createMenu = [
  [NewArticleIcon, '/article/build', 'Article', [CREATE_ARTICLE_CONTENT], FEATURE_ARTICLE_CREATION],
  [LibraryAddIcon, '/story/build', 'Story', [CREATE_STORY], FEATURE_STORIES],
  [TranslateIcon, '/translate', 'Translation', [TRANSLATION]],
  [TranslateIcon, '/internal-translation', 'Translation', [TRANSLATION]],
];

const organiseMenu = [
  [LayoutIcon, '/layout/sections', 'Layouts', [CREATE_LAYOUT_SECTION]],
  [Receipt, '/sidebar', 'Sidebars', [CREATE_SIDEBAR]],
  [MenuIcon, '/menu', 'Menus', [CREATE_FULL_NAV_MENU]],
  [UsersIcon, '/users', 'Users', [ADMINISTER_USER]],
  [ListAltIcon, '/manual-list', 'Manual lists', [CREATE_MANUAL_LIST]],
  [PictureInPictureIcon, '/ad', 'Ads', [CREATE_AD_CONFIG]],
  [ContactUsIcon, '/contact-us', 'Contact us', [CREATE_STATIC_PAGE], FEATURE_CONTACT_US],
  [PremiumIcon, '/premium/editors-letter', 'Premium', [CREATE_EDITOR_LETTER], FEATURE_PREMIUM_AD_REPLACEMENT],
  [SwapHorizIcon, '/redirect', 'Redirects', [CREATE_REDIRECT]],
  [CogOutline, '/config', 'Config', [ACCESS_CONTENT]],
];

const accessByPermission = permissions =>
  ([, , , access]) => permissions?.some(role => access.includes(role));

const accessFeatures = selectedPublication =>
  ([, , , , feature]) =>
    !feature || hasFeatures(selectedPublication.features)(feature);

export const containTranslateConfig = ({ translationBaseSource }) => !!translationBaseSource;
export const containInternalTranslateConfig = publicationConfig =>
  !!(publicationConfig?.internalTranslationSourceDomain);

const NavLink = props => <NavLinkBase {...props} data-testid={`nav-to-${props.to}`} />;

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
};

const Sidebar = ({
  classes, isExpanded, drawerLoaded: onDrawerLoaded, selectedPublication,
  permissions,
}) => {
  const drawerClasses = [
    classes.root, classes.drawer, isExpanded ? classes.drawerOpen : classes.drawerClose,
  ];
  const LogoClasses = [
    classes.logo, isExpanded ? classes.logo : classes.stamp,
  ];

  const { publicationConfig } = selectedPublication;

  useEffect(() => {
    onDrawerLoaded();
  });

  const hasAccess = accessByPermission(permissions);
  const hasFeature = accessFeatures(selectedPublication);

  const create = createMenu
    .filter(hasAccess)
    .filter(hasFeature)
    .filter(([, path]) => {
      if (!/^\/translate/.test(path)) {
        return true;
      }
      return containTranslateConfig(publicationConfig);
    })
    .filter(([, path]) => {
      if (!/^\/internal-translation/.test(path)) {
        return true;
      }
      return containInternalTranslateConfig(publicationConfig);
    })
    .map((data) => {
      const [a, path, ...rest] = data;
      if (!/^\/translate|internal-translation/.test(path)) {
        return data;
      }
      return [a, `${path}/${publicationConfig.lang}`, ...rest];
    });

  const browse = browseMenu
    .filter(hasAccess)
    .filter(hasFeature)
    .map((data) => {
      const modifiedData = [...data];

      return modifiedData;
    });
  const organise = organiseMenu
    .filter(hasAccess)
    .filter(hasFeature);

  return (<Drawer
    id={'sidebarDrawer'}
    variant={'permanent'}
    className={drawerClasses.join(' ')}
    classes={{
      paper: isExpanded ? classes.drawerOpen : classes.drawerClose,
      paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
    }}
    open={isExpanded}
  >
    <div className={classes.drawerContainer}>
      <Link to={'/'} className={LogoClasses.join(' ')}>
        {isExpanded ? <Logo /> : <Stamp />}
      </Link>
      <List className={classes.drawerRoot}>
        <ListItem button disableGutters classes={{ root: classes.listItemRoot }}>
          <NavLink to={'/'} exact className={[classes.link].join(' ')}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}><DashboardIcon /></ListItemIcon>
            <ListItemText disableTypography><Typography
              variant={'body1'}
            >Dashboard</Typography></ListItemText>
          </NavLink>
        </ListItem>
      </List>
      {create.length > 0 &&
      <Typography className={classes.drawerHeader} variant={'h4'}>Create</Typography>}
      {create.length > 0 && <List className={classes.drawerRoot}>
        {create
          .map(([Icon, target, label]) => (
            <ListItem key={target} button disableGutters classes={{ root: classes.listItemRoot }}>
              <NavLink to={target} exact activeClassName={'active'} className={classes.link}>
                {isExpanded && <ListItemIcon classes={{ root: classes.listItemIcon }}>
                  <Icon />
                </ListItemIcon>}
                {!isExpanded &&
                <Tooltip title={label} placement="right">
                  <ListItemIcon classes={{ root: classes.listItemIcon }}><Icon /></ListItemIcon>
                </Tooltip>
                }
                <ListItemText disableTypography><Typography
                  variant={'body1'}
                >{label}</Typography></ListItemText>
              </NavLink>
            </ListItem>
          ))}
      </List>}
      {browse.length > 0 &&
      <Typography className={classes.drawerHeader} variant={'h4'}>Browse</Typography>}
      {browse.length > 0 && <List className={classes.drawerRoot}>
        {browse
          .map(([Icon, target, label]) => (
            <ListItem key={target} button disableGutters classes={{ root: classes.listItemRoot }}>
              <NavLink to={target} exact activeClassName={'active'} className={classes.link}>
                {isExpanded &&
                <ListItemIcon classes={{ root: classes.listItemIcon }}><Icon /></ListItemIcon>
                }
                {!isExpanded &&
                <Tooltip title={label} placement="right">
                  <ListItemIcon classes={{ root: classes.listItemIcon }}><Icon /></ListItemIcon>
                </Tooltip>
                }
                <ListItemText disableTypography><Typography
                  variant={'body1'}
                >{label}</Typography></ListItemText>
              </NavLink>
            </ListItem>
          ))}
      </List>}
      {organise.length > 0 &&
      <Typography className={classes.drawerHeader} variant={'h4'}>Organise</Typography>}
      {organise.length > 0 && <List className={classes.drawerRoot}>
        {organise
          .map(([Icon, target, label]) => (
            <ListItem key={target} button disableGutters classes={{ root: classes.listItemRoot }}>
              <NavLink to={target} exact activeClassName={'active'} className={classes.link}>
                {isExpanded &&
                <ListItemIcon classes={{ root: classes.listItemIcon }}><Icon /></ListItemIcon>
                }
                {!isExpanded &&
                <Tooltip title={label} placement="right">
                  <ListItemIcon classes={{ root: classes.listItemIcon }}><Icon /></ListItemIcon>
                </Tooltip>
                }
                <ListItemText disableTypography><Typography
                  variant={'body1'}
                >{label}</Typography></ListItemText>
              </NavLink>
            </ListItem>
          ))}
      </List>}
    </div>
  </Drawer>);
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  drawerLoaded: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
};

export default withStyles(style)(connect(({
  drawer: { isExpanded },
  frame: { selectedPublication },
  login: { permissions },
}) => ({ isExpanded, selectedPublication, permissions }), { drawerLoaded })(Sidebar));
