import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PinIcon = props => (
  <SvgIcon {...props} viewBox={'0 0 508.096 508.096'}>
    <path d="M501.925 126.048l-120-120c-4-4-8-6-12.4-6-6.8 0-12 4.8-14.4 12.8l-5.6 18.8c-2.8 10-11.6 24.8-19.2 32.4l-97.6 97.2c-7.6 7.6-22 16.4-32 19.6l-56.4 18.4c-6.4 2-10.8 6.4-12 11.6-1.2 5.2.8 10.8 5.6 15.6l56 56.4-120.8 120.8-.4.4-72 97.6c-1.2 1.6-.8 3.6.4 5.2.8.8 1.6 1.2 2.8 1.2.8 0 1.6-.4 2.4-.8l97.6-72 .4-.4 120.8-120.8 56.4 56.8c4 4 8.4 6.4 12.4 6.4 6.8 0 12-5.2 14.8-12.8l18.4-56.8c3.2-10 12.4-24.8 19.6-32.4l97.6-97.6c7.6-7.6 22-16.4 32.4-19.2l18.8-5.6c6.4-2 10.8-6 12.4-11.2.8-4.8-1.2-10.8-6-15.6z"/>
  </SvgIcon>
);

export default PinIcon;
