import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import { Twitter } from 'mdi-material-ui';

import { getPattern } from '../../../../../../../utils/helper/clipboard';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import TextField from '../../../../../../ui/TextField';
import ToggleSwitch from '../../../../../../ui/ToggleSwitch';

import { PROP_URL, PROP_CONVERSATION } from '../defaults';

const PATTERN = /http[s]?:\/\/(www\.)?twitter\.com\/[^/]*\/status\/\d+/;
const VALID = /^http[s]?:\/\/(www\.)?twitter\.com\/[^/]*\/status\/\d+$/;

const TwitterElement = (props) => {
  const { attributes, children, element, className } = props;
  const [valid, setValid] = useState(true);
  const [value, setValue] = useState('');
  const editor = useSlateStatic();
  const setUrl = (url) => {
    setValue(url);
    setValid(VALID.test(url));
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { [PROP_URL]: url }, { at: path });
  };
  useEffect(() => {
    if (element[PROP_URL] !== value) {
      setValue(element[PROP_URL]);
    }
  }, [element[PROP_URL]]);
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Twitter'} Icon={Twitter} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin={'none'}
              placeholder={'Paste twitter url here'}
              onClick={e => e.stopPropagation()}
              onChange={e => setUrl(e.target.value)}
              onPaste={(e) => {
                e.preventDefault();
                setUrl(getPattern(e.clipboardData, PATTERN));
              }}
              value={value}
              error={!valid}
              helperText={!valid ? 'URL is invalid' : ''}
            />
          </Grid>
          <Grid item>
            <ToggleSwitch
              margin={'none'}
              labelPosition={'after'}
              label={'include parent tweet'}
              value={element[PROP_CONVERSATION]}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(
                  editor,
                  { [PROP_CONVERSATION]: e.target.checked },
                  { at: path },
                );
              }}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

TwitterElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default TwitterElement;
