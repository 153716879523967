import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import AutoComplete from '../autocomplete/AutoComplete';
import Tag from './Tag';

const TagAutocomplete = (props) => {
  const {
    placeholder, items, selectedItems, onSelect, onRemove, hierarchical,
    ...rest
  } = props;
  const [filteredItems, setFilteredItems] = useState([]);
  const handleChange = (value) => {
    if (value) {
      setFilteredItems(items.filter(
        option =>
          value &&
          option.label
            .toLowerCase()
            .includes(value.toLowerCase()),
      ));
    }
  };
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);
  return (
    <AutoComplete
      placeholder={placeholder}
      items={filteredItems}
      onChange={value => handleChange(value)}
      onSelect={item => onSelect(item)}
      hierarchical={hierarchical}
      startIcon={selectedItems.length > 0
        ? selectedItems.map(item => (
          <Tag
            key={item.id}
            label={item.name}
            onClick={() => onRemove(item)}
            onRemove={() => onRemove(item)}
          />
        )) : null
      }
      {...rest}
    />
  );
};

TagAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  placeholder: PropTypes.string,
  hierarchical: PropTypes.bool,
};

TagAutocomplete.defaultProps = {
  placeholder: 'Search',
  items: [],
  selectedItems: [],
  hierarchical: false,
};

export default TagAutocomplete;
