import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';

import SortableMenuTree from '../components/menu/SortableMenuTree';
import menuOnChange, {
  menuOnEdit, menuOnRemove, selectSection, closeMenuDialog, openMenuDialog, saveMenu, selectChain,
  fetchMenu,
} from '../actions/menu';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import MenuItemDialog from '../components/dialog/MenuItemDialog';
import Button from '../components/ui/buttons/Button';

import { MenuListBreadcrumb } from './MenuList';
import TableHierarchical from '../components/ui/table/TableHierarchical';

const MenuCreate = ({
  currentMenuTree, menuOnChange, menuOnRemove, menuOnEdit, saveMenu: onSaveMenu,
  dialogMenuOpen, chain, sections, openMenuDialog: onDialogOpen, selectSection: onSelectSection,
  selectChain: onSelectChain, closeMenuDialog, match: { params: { id, bundle }},
  fetchMenu: onFetchMenu,
}) => {
  useEffect(() => {
    onFetchMenu(id);
  }, []);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={[...MenuListBreadcrumb, { title: 'Edit menu', slug: bundle }]}>
        <PageToolbarButtons>
          <Button
            onClick={onDialogOpen}
            variant={'outlined'}
            startIcon={<AddIcon />}
          >Add item</Button>
          <Button
            onClick={() => onSaveMenu()}
            variant={'contained'}
            startIcon={<SaveIcon />}
          >Save Menu</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <PageSection>
          <TableHierarchical
            headers={['Title', 'Path']}
            rows={currentMenuTree.menu}
            hasActions
          />
          <SortableMenuTree
            treeData={currentMenuTree.menu}
            onChange={menuOnChange}
            onEdit={menuOnEdit}
            onRemove={menuOnRemove}
          />
        </PageSection>
      </PageContent>
      {dialogMenuOpen && <MenuItemDialog
        handleClose={closeMenuDialog}
        open={dialogMenuOpen}
        selectSection={onSelectSection}
        selectChain={onSelectChain}
        sections={sections}
        chain={chain}
      />}
    </Page>
  );
};

MenuCreate.propTypes = {
  currentMenuTree: PropTypes.object.isRequired,
  menuOnChange: PropTypes.func.isRequired,
  menuOnRemove: PropTypes.func.isRequired,
  menuOnEdit: PropTypes.func.isRequired,
  saveMenu: PropTypes.func.isRequired,
  openMenuDialog: PropTypes.func.isRequired,
  dialogMenuOpen: PropTypes.bool.isRequired,
  closeMenuDialog: PropTypes.func.isRequired,
  fetchMenu: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
  chain: PropTypes.array.isRequired,
};

MenuCreate.defaultProps = {
  selectedComponent: {},
  sidebarSection: [],
};

export default connect(
  ({
    menu: { currentMenuTree, dialogMenuOpen },
    vocab: { chain, sections },
  }) => ({ currentMenuTree, dialogMenuOpen, sections, chain }),
  {
    menuOnChange,
    menuOnEdit,
    menuOnRemove,
    saveMenu,
    openMenuDialog,
    selectSection,
    selectChain,
    closeMenuDialog,
    fetchMenu,
  },
)(MenuCreate);
