import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { arrayMove } from 'react-sortable-hoc';
import { ReactEditor } from 'slate-react';
import { Transforms } from 'slate';

import Grid from '@material-ui/core/Grid';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import RemoveIcon from '@material-ui/icons/DeleteOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import { PencilOff } from 'mdi-material-ui';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { PROP_ARTICLES } from '../defaults';
import { ArrowSortableDown, ArrowSortableListItem, ArrowSortableUp } from 'components/ui/ArrowSortable';
import IconButton from 'components/ui/buttons/IconButton';
import TextField from 'components/ui/TextField';

const RelatedArticlesItem = ({ index, article, editor, element }) => {
  const [edit, setEdit] = useState(false);
  return (
    <ArrowSortableListItem index={index} key={`story-${article.id}`} indexAvatar>
      {!edit && <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={article.title} />}
      {edit && <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Title"
            value={article.title}
            onChange={e => {
              const path = ReactEditor.findPath(editor, element);
              Transforms.setNodes(
                editor,
                {
                  [PROP_ARTICLES]: element[PROP_ARTICLES]
                    .map((articleData, i) => i === index ? { ...articleData, title: e.target.value } : articleData),
                },
                { at: path },
              );
            }}
          />
        </Grid>
      </Grid>}
      <ListItemSecondaryAction>
        {!edit && <IconButton
          onClick={() => setEdit(true)}
        >
          <EditIcon />
        </IconButton>}
        {edit && <IconButton
          onClick={() => setEdit(false)}
        >
          <PencilOff />
        </IconButton>}
        <IconButton
          onClick={() => {
            window.open(`/article/edit/${article.id}`, '_blank');
          }}
        >
          <OpenInBrowserIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              {
                [PROP_ARTICLES]: element[PROP_ARTICLES]
                  .filter(({ id }) => id !== article.id),
              },
              { at: path },
            );
          }}
        >
          <RemoveIcon />
        </IconButton>
        {index < element[PROP_ARTICLES].length - 1 && <ArrowSortableDown
          onClick={() => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              { [PROP_ARTICLES]: arrayMove(element[PROP_ARTICLES], index, index + 1) },
              { at: path },
            );
          }}
        />}
        {index > 0 && <ArrowSortableUp
          onClick={() => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(
              editor,
              { [PROP_ARTICLES]: arrayMove(element[PROP_ARTICLES], index, index - 1) },
              { at: path },
            );
          }}
        />}
      </ListItemSecondaryAction>
    </ArrowSortableListItem>
  );
};

RelatedArticlesItem.propTypes = {
  index: PropTypes.number.isRequired,
  article: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
};

export default RelatedArticlesItem;
