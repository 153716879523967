import TiktokElement from './components/TiktokElement';

export const ELEMENT_TIKTOK = 'tiktok';
export const PROP_URL = 'url';

export const TIKTOK_PATTERN = /http[s]?:\/\/(www\.)?tiktok\.com\/[^/]*\/video\/\d+/;
export const TIKTOK_VALID = /^http[s]?:\/\/(www\.)?tiktok\.com\/[^/]*\/video\/\d+/;

export const DEFAULTS_TIKTOK = {
  tiktok: {
    component: TiktokElement,
    type: ELEMENT_TIKTOK,
    rootProps: {
      className: 'slate-tiktok',
    },
  },
};
