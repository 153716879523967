import { ELEMENT_TIKTOK, PROP_URL, TIKTOK_PATTERN, TIKTOK_VALID } from './defaults';
import { TIKTOK } from '../../../../../../constants/editor/dataTypes';

export const convertToTiktok = data => ({
  type: ELEMENT_TIKTOK,
  [PROP_URL]: data[PROP_URL] || '',
  children: [{ text: '' }],
});

export const convertFromTiktok = data => ({
  type: TIKTOK,
  data: {
    [PROP_URL]: data[PROP_URL] || '',
  },
});

export const isValidUrl = string => TIKTOK_VALID.test(string);
export const getValidUrl = (string) => {
  const matches = string.match(TIKTOK_PATTERN);
  return matches[0] || '';
};
