export const USER_COMMENTS_FETCH = 'USER_COMMENTS_FETCH';
export const USER_COMMENTS_FETCH_SUCCESS = 'USER_COMMENTS_FETCH_SUCCESS';
export const USER_COMMENTS_FETCH_REJECTED = 'USER_COMMENTS_FETCH_REJECTED';
export const USER_COMMENTS_DISPOSE = 'USER_COMMENTS_DISPOSE';

export const USER_COMMENTS_DELETE = 'USER_COMMENTS_DELETE';
export const USER_COMMENTS_DELETE_SUCCESS = 'USER_COMMENTS_DELETE_SUCCESS';
export const USER_COMMENTS_DELETE_REJECTED = 'USER_COMMENTS_DELETE_REJECTED';

export const USER_COMMENTS_UNFLAG = 'USER_COMMENTS_UNFLAG';
export const USER_COMMENTS_UNFLAG_SUCCESS = 'USER_COMMENTS_UNFLAG_SUCCESS';
export const USER_COMMENTS_UNFLAG_REJECTED = 'USER_COMMENTS_UNFLAG_REJECTED';

export const USER_COMMENTS_SET_FILTER = 'USER_COMMENTS_SET_FILTER';
