import { PURGE } from 'redux-persist/es/constants';

import {
  PRODUCT_COMPARISON_DISPOSE, PRODUCT_COMPARISON_SUCCESS,
  PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS, PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE,
  PRODUCT_DISPOSE, PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER,
} from '../../constants/actionTypes/product';

const defaultState = {
  priceComparisonResult: {},
  linkCheckResult: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case PRODUCT_COMPARISON_SUCCESS: {
      const { link, items } = action.value;
      return {
        ...state,
        priceComparisonResult: {
          ...state.priceComparisonResult,
          [link]: items,
        },
      };
    }

    case PRODUCT_COMPARISON_DISPOSE:
      return {
        ...state,
        priceComparisonResult: Object.keys(state.priceComparisonResult)
          .filter(key => key !== action.value)
          .reduce((acc, key) => ({
            ...acc,
            [key]: state.priceComparisonResult[key],
          }), {}),
      };

    case PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS: {
      const { link, data } = action.value;
      return {
        ...state,
        linkCheckResult: {
          ...state.linkCheckResult,
          [link]: data,
        },
      };
    }

    case PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE:
      return {
        ...state,
        linkCheckResult: defaultState.linkCheckResult,
      };

    case PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER:
      return {
        ...state,
        linkCheckResult: action.value,
      };

    case PRODUCT_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
