import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import Select from '../../../ui/Select';
import TextField from '../../../ui/TextField';

const SNIPPET = 'Snippet';
const LOCATION = 'Location';
const AMOUNT = 'Amount';

const countries = ['UK', 'US'];

const VoucherSettings = (props) => {
  const {
    component,
    componentSetProp: setProp,
    publicationConfig,
  } = props;
  const defaultAmount = publicationConfig.voucherComponentDefaultValue || 7;
  const defaultCountry = countries[0];
  useEffect(() => {
    if (!component[LOCATION]) {
      setProp(component.id, LOCATION, defaultCountry);
    }
    if (!component[AMOUNT]) {
      setProp(component.id, AMOUNT, defaultAmount);
    }
  }, [component]);
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Voucher Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <FormControl margin={'normal'}>
          <TextField
            label={SNIPPET}
            defaultValue={''}
            value={component[SNIPPET]}
            onChange={e => setProp(component.id, SNIPPET, e.target.value)}
          />
        </FormControl>
        <FormControl>
          <Select
            label={LOCATION}
            defaultValue={defaultCountry}
            value={component[LOCATION]}
            onChange={e => setProp(component.id, LOCATION, e.target.value)}
            items={countries}
          />
        </FormControl>
        <FormControl>
          <TextField
            label={AMOUNT}
            defaultValue={''}
            type={'number'}
            value={component[AMOUNT]}
            onChange={e => setProp(component.id, AMOUNT, e.target.value)}
          />
        </FormControl>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

VoucherSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object,
};

VoucherSettings.defaultProps = {
  publicationConfig: null,
};

export default connect(
  ({ frame: { selectedPublication: { publicationConfig } } }) => ({ publicationConfig }),
  { componentSetProp },
)(VoucherSettings);
