import NumberboxElement from './components/NumberboxElement';

export const ELEMENT_NUMBERBOX = 'numberbox';
export const PROP_TITLE = 'title';
export const PROP_MARKUP = 'markup';
export const PROP_ALIGN = 'align';

export const DEFAULTS_NUMBERBOX = {
  numberbox: {
    component: NumberboxElement,
    type: ELEMENT_NUMBERBOX,
    rootProps: {
      className: 'slate-numberbox',
    },
  },
};
