import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  AD_COMPONENTS, Markup, MostPopular, Indy100, VisualStories,
} from '../../../../constants/components';

import {
  setLayoutContext,
  componentPropOnChange,
} from '../../../../actions/sidebar';
import {
  ContextPanel,
  ContextPanelDetails,
} from '../../../common/context/ContextPanel';

import LabelField from '../../../layout/contexts/componentProperties/LabelField';
import AdSettings from '../../../layout/contexts/componentProperties/AdSettings';
import { ContextBarBody } from '../../../common/context/ContextBar';
import MarkupField from '../../../layout/contexts/componentProperties/MarkupSettings';
import VocabField from '../../../contexts/form/VocabField';
import { SECTIONS } from '../../../../constants/vocab';
import {
  FIELD_SECTION,
  FIELD_FEED_SOURCE,
  FIELD_DISPLAY_AS_BUTTONS,
} from '../../../../constants/sidebar/sidebarComponents';
import { sanitiseTerm } from '../../../../utils/sanitiser';
import Select from '../../../ui/Select';
import ToggleSwitch from '../../../ui/ToggleSwitch';

const ContextFields = (props) => {
  const { component, onChangeProperty } = props;
  const fields = [];
  switch (component.type) {
    case Markup:
      fields.push(<MarkupField
        title={'Markup'}
        key={'markup'}
        componentPropOnChange={onChangeProperty}
        component={component}
      />);
      break;
    default:
      break;
  }
  return fields;
};

const ContextProperties = (props) => {
  const { component, onChangeProperty } = props;
  const properties = [];
  switch (true) {
    case AD_COMPONENTS.includes(component.type):
      properties.push(<AdSettings
        disabledAlignmentOption
        key={'adSettings'}
        component={component}
        onChangeProperty={onChangeProperty}
      />);
      break;
    case component.type === MostPopular:
      properties.push(<VocabField
        label={'Section'}
        vocab={SECTIONS}
        selectedTerms={component[FIELD_SECTION] ? [component[FIELD_SECTION]] : []}
        limit={1}
        onSelect={value => onChangeProperty([
          component.id, FIELD_SECTION, sanitiseTerm(value[1]),
        ])}
        onRemove={() => onChangeProperty([
          component.id, FIELD_SECTION, null,
        ])}
      />);
      break;
    case component.type === VisualStories:
      properties.push(<ToggleSwitch
        label={'Display as buttons'}
        value={!!component[FIELD_DISPLAY_AS_BUTTONS]}
        onChange={e => onChangeProperty([
          component.id, FIELD_DISPLAY_AS_BUTTONS, e.target.checked,
        ])}
      />);
      break;
    case component.type === Indy100: {
      const indy100Types = [
        'indy100 Most Popular',
        'indy100 Conversations',
      ];
      properties.push(<Select
        label={'Feed source'}
        items={indy100Types}
        value={component[FIELD_FEED_SOURCE] || indy100Types[0]}
        onChange={(e) => {
          const value = e.target.value;
          onChangeProperty([
            component.id, FIELD_FEED_SOURCE, value,
          ]);
        }}
      />);
      break;
    }
    default:
      break;
  }
  return properties;
};

const NoComponentSelected = withStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}))(Typography);

const FieldRoot = withStyles(theme => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}))(Grid);


const ComponentProperties = (props) => {
  const {
    data,
    selectedComponents,
    componentPropOnChange: onChangeProperty,
  } = props;
  useEffect(() => {
    if (data?.targetIndex) {
      const container = document.querySelector('#contextBody');
      if (container) {
        container.scrollTop = 0;
      }
    }
  }, [data]);

  if (!data) {
    return (<ContextBarBody id={'contextBody'}>
      <ContextPanel>
        <ContextPanelDetails variant={'full'}>
          <NoComponentSelected component={'p'}>Please select a component to change the setting</NoComponentSelected>
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBarBody>);
  }

  // get the selectedComponent component to ensure data integrity
  const component = selectedComponents.find(comp => comp.id === data.id);

  if (!component) {
    return null;
  }

  return (
    <ContextBarBody id={'contextBody'}>
      <ContextPanel>
        <ContextPanelDetails variant={'full'}>
          <FieldRoot>
            <LabelField
              onChangeProperty={(id, field, value) => onChangeProperty([id, field, value])}
              component={component}
            />
            <ContextProperties onChangeProperty={onChangeProperty} component={component} />
          </FieldRoot>
        </ContextPanelDetails>
      </ContextPanel>
      <ContextFields
        onChangeProperty={onChangeProperty}
        component={component}
        data={data}
      />
    </ContextBarBody>
  );
};

ComponentProperties.propTypes = {
  selectedComponents: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

export default connect(
  ({
    sidebar: { selectedComponents, context: { data } },
  }) => ({ selectedComponents, data }),
  { setLayoutContext, componentPropOnChange },
)(ComponentProperties);
