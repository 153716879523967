import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';

import { selectComponent, removeComponent, moveComponent, selectSection, onSidebarSave } from '../actions/sidebar';


import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import Components from '../components/sidebar/components';
import Button from '../components/ui/buttons/Button';

import { SidebarListBreadcrumb } from './SidebarList';
import LayoutContext from '../components/sidebar/components/LayoutContext';

const SidebarCreate = ({
  removeComponent,
  moveComponent,
  selectedComponents,
  onSidebarSave,
  error,
  errorSaveRejected,
  sidebarContext,
  match: { params: { id } },
}) => (
  <Page
    toolbarContent={<PageToolbar breadcrumb={[...SidebarListBreadcrumb, { title: 'Edit sidebar', slug: id }]}>
      <PageToolbarButtons>
        <Button
          onClick={onSidebarSave}
          variant={'contained'}
          startIcon={<SaveIcon />}
        >Save</Button>
      </PageToolbarButtons>
    </PageToolbar>}
  >
    <PageContent>
      <Grid item xs={12}>
        {error.length > 0 && error.map(message => (
          <Typography key={`${message}`} variant={'body2'} color={'error'}>{message}</Typography>
        ))}
        {errorSaveRejected.length > 0 && errorSaveRejected.map(message => (
          <Typography key={`${message}`} variant={'body2'} color={'error'}>{message}</Typography>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Components
          selectedComponents={selectedComponents}
          removeComponent={removeComponent}
          moveComponent={moveComponent}
        />
      </Grid>
    </PageContent>
    <LayoutContext context={sidebarContext} />
  </Page>
);

SidebarCreate.propTypes = {
  removeComponent: PropTypes.func.isRequired,
  moveComponent: PropTypes.func.isRequired,
  onSidebarSave: PropTypes.func.isRequired,
  selectedComponents: PropTypes.array.isRequired,
  error: PropTypes.array,
  errorSaveRejected: PropTypes.array,
  match: PropTypes.object.isRequired,
  sidebarContext: PropTypes.object.isRequired,
};

SidebarCreate.defaultProps = {
  selectedComponent: {},
  error: [],
  errorSaveRejected: [],
};

export default connect(
  ({
    sidebar: {
      selectedComponent, selectedComponents, components, sections, error, errorSaveRejected,
      context: sidebarContext,
    } }) =>
    ({
      selectedComponent,
      selectedComponents,
      components,
      sections,
      error,
      errorSaveRejected,
      sidebarContext,
    }),
  { selectComponent, removeComponent, moveComponent, selectSection, onSidebarSave },
)(SidebarCreate);
