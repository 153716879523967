import MoneyElement from './components/MoneyElement';

export const ELEMENT_MONEY = 'money';
export const PROP_TITLE = 'title';
export const PROP_EMBED_ID = 'embedId';

export const DEFAULTS_MONEY = {
  money: {
    component: MoneyElement,
    type: ELEMENT_MONEY,
    rootProps: {
      className: 'slate-price-comparison',
    },
  },
};
