import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { capitalize } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Close';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { SortableHandle } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';

import DebounceInputOnChange from '../ui/DebounceInputOnChange';
import DragHandle from '../ui/DragHandle';
import FlowCard from '../common/layout/FlowCard';
import IconButton from '../ui/buttons/IconButton';

export const ContactHandle = SortableHandle(withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.contrast,
  },
}))(DragHandle));

const ContactItemForm = ({ onChange, onRemove, contact, cardIndex }) => (<Grid item xs={4}>
  <FlowCard>
    <CardHeader
      titleTypographyProps={{ variant: 'subtitle1' }}
      subheaderTypographyProps={{ variant: 'caption' }}
      title={cardIndex + 1}
      action={
        <Grid container justify={'flex-end'}>
          <IconButton onClick={onRemove}>
            <RemoveIcon />
          </IconButton>
          <ContactHandle />
        </Grid>
      }
    />
    <CardContent>
      <Grid container spacing={2}>
        {Object.entries(contact)
          .filter(([key]) => key !== 'id')
          .map(([key, value]) => (<Grid key={key} item xs={12}>
            <DebounceInputOnChange
              key={key}
              label={capitalize(key)}
              defaultValue={value}
              onChange={data => onChange({
                key,
                value: data,
              })}
            /></Grid>))}
      </Grid>
    </CardContent>
  </FlowCard>
</Grid>);

ContactItemForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  cardIndex: PropTypes.number.isRequired,
};

export default ContactItemForm;
