const ADCONFIG_DESKTOP = [
  {
    title: 'Top Banner (970x250,728x90)',
    tile: 'top_banner',
  },
  {
    title: 'MPU1 Position Override',
    tile: 'mpu1_pos',
    type: 'range',
    data: {
      start: 1,
      length: 8,
    },
  },
  {
    title: 'MPU Paragraph Frequency',
    tile: 'paragraph_freq',
    type: 'range',
    data: {
      start: 2,
      length: 8,
    },
  },
  {
    title: 'MPU Article Paragraph Maximum Size',
    tile: 'paragraph_size',
    type: 'range',
  },
  {
    title: 'MPU Liveblog Post Frequency',
    tile: 'liveblog_post_freq',
    type: 'range',
    data: {
      start: 2,
      length: 8,
    },
  },
  {
    title: 'MPU Liveblog Maximum Size',
    tile: 'liveblog_post_size',
    type: 'range',
  },
  {
    title: 'Right Hand Side (300x600 + 300x250)',
    tile: 'sidebar',
  },
  {
    title: 'Video (400x300v)',
    tile: 'video',
  },
  {
    title: 'Native (1x2) Article Page',
    tile: 'native',
  },
  {
    title: 'Native (1x2) Section',
    tile: 'native_section',
  },
  {
    title: 'Teads (2x1)',
    tile: 'teads',
  },
  {
    title: 'Wallpaper (2x2)',
    tile: 'wallpaper',
  },
  {
    title: 'SeedTag (2x3)',
    tile: 'seedtag',
  },
  {
    title: 'Ad Slot (3x3)',
    tile: 'ad_slot',
  },
  {
    title: 'Section Row Billboard',
    tile: 'ad_row_billboard',
  },
  {
    title: 'On Scroll (4x4)',
    tile: 'on_scroll',
  },
  {
    title: 'OutBrain Feed',
    tile: 'outbrain_feed',
  },
  {
    title: 'OutBrain Sidebar ',
    tile: 'outbrain_sidebar',
  },
  {
    title: 'Outbrain Mid Article ',
    tile: 'outbrain_mid',
  },
  {
    title: 'Teads slot one',
    tile: 'teads_adslot_1',
  },
  {
    title: 'Teads slot two',
    tile: 'teads_adslot_2',
  },
  {
    title: 'Teads1 position override',
    tile: 'teads_override_adslot_1',
    type: 'range',
  },
  {
    title: 'Teads2 position override',
    tile: 'teads_override_adslot_2',
    type: 'range',
  },
];

const ADCONFIG_MOBILE = [
  {
    title: 'Top Banner (320x50)',
    tile: 'top_banner',
  },
  {
    title: 'MPU1 Position Override',
    tile: 'mpu1_pos',
    type: 'range',
    data: {
      start: 1,
      length: 8,
    },
  },
  {
    title: 'MPU Paragraph Frequency',
    tile: 'paragraph_freq',
    type: 'range',
    data: {
      start: 2,
      length: 8,
    },
  },
  {
    title: 'MPU Article Paragraph Maximum Size',
    tile: 'paragraph_size',
    type: 'range',
  },
  {
    title: 'MPU Liveblog Post Frequency',
    tile: 'liveblog_post_freq',
    type: 'range',
    data: {
      start: 2,
      length: 8,
    },
  },
  {
    title: 'MPU Liveblog Maximum Size',
    tile: 'liveblog_post_size',
    type: 'range',
  },
  {
    title: 'Bottom Banner (320x50)',
    tile: 'bottom_banner',
  },
  {
    title: 'Native (1x2) Article Page',
    tile: 'native',
  },
  {
    title: 'Native (1x2) Section',
    tile: 'native_section',
  },
  {
    title: 'Teads (2x1)',
    tile: 'teads',
  },
  {
    title: 'Wallpaper (2x2)',
    tile: 'wallpaper',
  },
  {
    title: 'SeedTag (2x3)',
    tile: 'seedtag',
  },
  {
    title: 'Ad Slot (3x3)',
    tile: 'ad_slot',
  },
  {
    title: 'Section Row Billboard',
    tile: 'ad_row_billboard',
  },
  {
    title: 'Teads slot one',
    tile: 'teads_adslot_1',
  },
  {
    title: 'Teads slot two',
    tile: 'teads_adslot_2',
  },
  {
    title: 'Teads1 position override',
    tile: 'teads_override_adslot_1',
    type: 'range',
  },
  {
    title: 'Teads2 position override',
    tile: 'teads_override_adslot_2',
    type: 'range',
  },
];

const ADCONFIG_AMP = [
  {
    title: 'Top Banner',
    tile: 'top_banner',
  },
  {
    title: 'MPU Paragraph Frequency',
    tile: 'paragraph_freq',
    type: 'range',
    data: {
      start: 2,
      length: 8,
    },
  },
  {
    title: 'MPU Article Paragraph Maximum Size',
    tile: 'paragraph_size',
    type: 'range',
  },
  {
    title: 'Video (400x300v)',
    tile: 'video',
  },
  {
    title: 'Bottom Banner (320x50)',
    tile: 'bottom_banner',
  },
  {
    title: 'Native (300x250) Article Page',
    tile: 'native',
  },
  {
    title: 'Teads (300x1)',
    tile: 'teads',
  },
  {
    title: 'OutBrain Feed',
    tile: 'outbrain_feed',
  },
  {
    title: 'Outbrain Mid Article ',
    tile: 'outbrain_mid',
  },
];

export default {
  desktop: ADCONFIG_DESKTOP,
  desktopAdlite: ADCONFIG_DESKTOP,
  mobile: ADCONFIG_MOBILE,
  mobileAdlite: ADCONFIG_MOBILE,
  amp: ADCONFIG_AMP,
  ampAdlite: ADCONFIG_AMP,
};
