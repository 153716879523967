import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Prompt } from 'react-router';

import Button from 'components/ui/buttons/Button';
import ToggleSwitch from 'components/ui/ToggleSwitch';
import Select from 'components/ui/Select';
import Autocomplete from 'components/ui/SelectAutocomplete';

import { FIELD_HIDE_COMMENTING, FIELD_HIDE_NEWSLETTER_SIGNUP } from 'constants/article/articleFields';
import SEODescription from 'components/article/articleSidebar/fields/SEODescription';
import { SECTIONS, SPONSOR_PREFIX, TAGS, TOPICS } from 'constants/vocab';
import {
  FIELD_COMMERCIAL_CAMPAIGN,
  FIELD_DISABLE_AFFILIATE_SECTION,
  FIELD_IS_COMMERCIAL,
  FIELD_IS_DISABLE_ALL_SLOTS,
  FIELD_IS_DISABLE_SPONSORED_CAPSULE, FIELD_TAKEOVER,
} from 'constants/taxonomy';
import { UNSAVE_CHANGES_PROMPT_TEXT } from 'constants/prompt';
import TaxonomyParentSelect from './TaxonomyParentSelect';
import { CONFIG_COMMERCIAL_CAMPAIGNS } from 'constants/config/config';

const style = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const Field = ({ defaultValue, id, label, onChange, ...rest }) => (
  <FormControl margin={'normal'} required fullWidth variant={'outlined'}>
    <TextField
      defaultValue={defaultValue}
      variant={'outlined'}
      id={id}
      label={label}
      onChange={onChange}
      name={id}
      autoComplete={'off'}
      {...rest}
    />
  </FormControl>
);

Field.propTypes = {
  defaultValue: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

Field.defaultProps = {
  defaultValue: '',
};

const defaultState = {
  seoDescription: '',
  titleSEO: '',
  [FIELD_HIDE_COMMENTING]: false,
  [FIELD_HIDE_NEWSLETTER_SIGNUP]: false,
  [FIELD_DISABLE_AFFILIATE_SECTION]: false,
  [FIELD_IS_COMMERCIAL]: false,
  [FIELD_IS_DISABLE_ALL_SLOTS]: false,
  [FIELD_IS_DISABLE_SPONSORED_CAPSULE]: false,
  [FIELD_TAKEOVER]: '',
  parents: [],
};

function reducer(state, [key, value]) {
  switch (key) {
    case FIELD_IS_COMMERCIAL:
      return {
        ...state,
        [key]: value,
        [FIELD_IS_DISABLE_ALL_SLOTS]: false,
        [FIELD_IS_DISABLE_SPONSORED_CAPSULE]: false,
      };
    default:
      return {
        ...state,
        [key]: value,
      };
  }
}

const TaxonomyForm = ({
  classes,
  term,
  vocab,
  sectionTree,
  onSave,
  addNew,
  sectionRoot,
  commercialCampaigns,
}) => {
  const [invalidTitle, setInvalidTitle] = useState(false);
  const [invalidSEODesc, setInvalidSEODesc] = useState(false);
  const [invalidForm, setInvalidForm] = useState(true);
  const [unSavedChanges, setUnSavedChanges] = useState(false);
  const [invalidSlug, setInvalidSlug] = useState(false);
  const [showSEOFields, setShowSEOFields] = useState([SECTIONS, TAGS, TOPICS].includes(vocab));
  const [showSlug, setShowSlug] = useState([SECTIONS].includes(vocab));
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...term,
    ...term.extra,
  });

  const updateState = (key, eventKey = 'value') =>
    (event) => {
      dispatch([key, event.target[eventKey]]);
      setUnSavedChanges(true);
    };

  useEffect(() => {
    setShowSEOFields([SECTIONS, TAGS, TOPICS].includes(vocab));
    setShowSlug([SECTIONS].includes(vocab));
    if (term.tid || term.id) {
      setUnSavedChanges(false);
      const initial = {
        ...defaultState,
        ...term,
        ...term.extra,
      };
      if (vocab === SECTIONS && sectionTree) {
        const myterm = sectionTree.find(({ tid }) => parseInt(tid) === term.id);
        let myParents = [];
        if (myterm && myterm.parents.length > 0) {
          myParents = sectionTree.filter(({ tid }) => {
            return myterm.parents.includes(tid)
          });
        }
        console.log({ myParents })
        initial.id = term.tid || term.id; // ES has no tid, but section tree has :(
        initial.parents = myParents;
        if (term.path) {
          const paths = term.path.split('/');
          initial.originalSlug = paths[paths.length - 1];
          initial.slug = paths[paths.length - 1];
        }
      }
      Object.keys(initial).forEach(key => dispatch([key, initial[key]]));
    }

    return () => {
      setUnSavedChanges(false);
    };
  }, [sectionTree, term, vocab]);

  useEffect(() => {
    setInvalidSlug(!state.slug || !/^[a-z0-9]([\s\-a-z0-9])*$/.test(state.slug));
  }, [state.slug]);

  useEffect(() => {
    setInvalidTitle(!state.name);
  }, [state.name]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    const checks = [invalidTitle];
    if (showSEOFields) {
      checks.push(invalidSEODesc);
    }
    if (showSlug) {
      checks.push(invalidSlug);
    }
    setInvalidForm(checks.some(Boolean));
  }
  , [invalidSEODesc, invalidSlug, invalidTitle, showSEOFields, showSlug]);

  const isRootTerm = Number(sectionRoot.id) === Number(state.id);
  return (<div className={classes.root}>
    <div>
      <Prompt message={UNSAVE_CHANGES_PROMPT_TEXT} when={unSavedChanges} />
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          if (addNew) return onSave([vocab, state]);

          if (vocab === SECTIONS) {
            // section only check for the slug
            if (state.originalSlug !== state.slug || term.name !== state.name) {
              if (window.confirm('Are you sure you wish to rename this term?')) {
                onSave([vocab, state]);
              }
            } else {
              onSave([vocab, state]);
            }
            return null;
          }

          // non section
          if (term.name !== state.name) {
            if (window.confirm('Are you sure you wish to' +
              ' rename this term?')) {
              onSave([vocab, state]);
            }
          } else {
            onSave([vocab, state]);
          }
          setUnSavedChanges(false);
        }}
      >
        <Field
          value={state.name}
          id={'name'}
          label={'Name'}
          onChange={updateState('name')}
          onBlur={() => {
            if (!state.slug && state.name) {
              dispatch([
                'slug',
                state.name
                  .toLowerCase()
                  .replace(/[^A-Za-z0-9-]+/g, '-'),
              ]);
              setUnSavedChanges(true);
            }
          }}
        />
        {vocab === SPONSOR_PREFIX && <Field value={state.url} id={'url'} label={'Url'} onChange={updateState('url')} />}
        {vocab === SECTIONS &&
          <>
            {!isRootTerm &&
              <Field
                error={state.slug && invalidSlug}
                helperText={state.slug && invalidSlug ? 'Must consist of numbers, letters and hyphens' : ''}
                value={state.slug}
                id={'path'}
                label={'Path'}
                onChange={updateState('slug')}
              />
            }
            {!isRootTerm &&
              <TaxonomyParentSelect
                id={'parent'}
                label={'Parent'}
                currentTerm={state}
                value={state.parents.map(({ tid }) => tid)}
                onChange={(e) => {
                  const { options } = e.target;
                  const values = [];
                  for (let i = 0, l = options.length; i < l; i += 1) {
                    if (options[i].selected) {
                      values.push(options[i].value);
                    }
                  }
                  dispatch(['parents', sectionTree.filter(({ tid }) => values.includes(tid))]);
                }}
                items={sectionTree}
                multiple
                native
                inputProps={{
                  id: 'select-multiple-native',
                }}
              />
            }
            <Field defaultValue={term.weight || ''} id={'weight'} label={'weight'} onChange={updateState('weight')} />
            <ToggleSwitch
              label={'Hide comments'}
              value={state[FIELD_HIDE_COMMENTING]}
              onChange={updateState(FIELD_HIDE_COMMENTING, 'checked')}
            />
            <ToggleSwitch
              label={'Disable Affiliates'}
              value={state[FIELD_DISABLE_AFFILIATE_SECTION]}
              onChange={updateState(FIELD_DISABLE_AFFILIATE_SECTION, 'checked')}
            />
            <ToggleSwitch
              label={'Hide newsletter'}
              value={state[FIELD_HIDE_NEWSLETTER_SIGNUP]}
              onChange={updateState(FIELD_HIDE_NEWSLETTER_SIGNUP, 'checked')}
            />
            <ToggleSwitch
              label={'Commercial'}
              value={state[FIELD_IS_COMMERCIAL]}
              onChange={updateState(FIELD_IS_COMMERCIAL, 'checked')}
            />
            {state[FIELD_IS_COMMERCIAL] && <>
              <ToggleSwitch
                label={'Disable all ad slots'}
                value={state[FIELD_IS_DISABLE_ALL_SLOTS]}
                onChange={updateState(FIELD_IS_DISABLE_ALL_SLOTS, 'checked')}
              />
              <ToggleSwitch
                label={'Disable sponsored capsule'}
                value={state[FIELD_IS_DISABLE_SPONSORED_CAPSULE]}
                onChange={updateState(FIELD_IS_DISABLE_SPONSORED_CAPSULE, 'checked')}
              />
            </>}
            <Autocomplete
              label={'Commercial Campaign'}
              value={
                state[FIELD_COMMERCIAL_CAMPAIGN]
                  ? {
                    label: state[FIELD_COMMERCIAL_CAMPAIGN],
                    value: state[FIELD_COMMERCIAL_CAMPAIGN],
                  }
                  : ''
              }
              defaultOptions={commercialCampaigns.map(campaign => ({
                label: campaign,
                value: campaign,
              }))}
              onChange={(value) => {
                dispatch([FIELD_COMMERCIAL_CAMPAIGN, value.value]);
                setUnSavedChanges(true);
              }}
            />
          </>}
        {showSEOFields && <>
          <Select
            label={'Takeover'}
            value={state[FIELD_TAKEOVER]}
            onChange={updateState(FIELD_TAKEOVER, 'value')}
            emptyValue={'None'}
            items={{
              fullscreen: 'Full Screen',
              fullwidth: 'Full Width',
              column: 'Column',
            }}
          />
          <Field
            defaultValue={''}
            id={'titleSEO'}
            value={state.titleSEO || ''}
            label={'SEO title'}
            onChange={updateState('titleSEO')}
          />
          <SEODescription
            multiline
            rows={3}
            onInvalidChange={setInvalidSEODesc}
            onChange={text => dispatch(['seoDescription', text])}
            value={state.seoDescription || ''}
          />
        </>}
        <Grid container justify={'flex-end'}>
          <Button
            type={'submit'}
            variant={'contained'}
            disabled={invalidForm}
          >{term.name ? 'Update' : 'Save'}</Button>
        </Grid>
      </form>
    </div>
  </div>);
};

TaxonomyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  sectionTree: PropTypes.object.isRequired,
  sectionRoot: PropTypes.object.isRequired,
  term: PropTypes.object.isRequired,
  vocab: PropTypes.string.isRequired,
  commercialCampaigns: PropTypes.array,
  addNew: PropTypes.bool,
};

TaxonomyForm.defaultProps = {
  addNew: false,
  commercialCampaigns: [],
};

export default withStyles(style)(connect(
  ({
    config: { [CONFIG_COMMERCIAL_CAMPAIGNS]: commercialCampaigns },
    vocab: { sectionTree },
    frame: { selectedPublication: { sectionRoot } },
  }) => ({ commercialCampaigns, sectionTree, sectionRoot }),
)(TaxonomyForm));
