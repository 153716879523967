import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroupBase from '@material-ui/core/ButtonGroup';

const styles = theme => ({
  clear: {
    '& .MuiButton-root': {
      borderRight: '0 none !important',
      marginLeft: theme.spacing(1),
    },
  },
});

const ButtonGroup = (props) => {
  const { classes, className, color, children, variant, ...rest } = props;
  const btnClasses = [];
  if (className) {
    btnClasses.push(className);
  }
  if (variant === 'clear') {
    btnClasses.push(classes.clear);
  }
  return (
    <ButtonGroupBase
      variant={variant === 'clear' ? 'text' : variant}
      color={color}
      className={btnClasses.join(' ')}
      {...rest}
    >{children}</ButtonGroupBase>
  );
};

ButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

ButtonGroup.defaultProps = {
  className: null,
  color: 'primary',
  variant: 'clear',
};

export default withStyles(styles)(ButtonGroup);
