import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';

import { setFeedContext } from '../../../actions/feed';

import {
  CONTEXT_ARTICLE,
} from '../../../constants/contexts';

import DraggableArticle from '../../ui/draggables/DraggableArticle';
import DragHandle from '../../ui/DragHandle';
import IconButton from '../../ui/buttons/IconButton';
import { FIELD_HEADLINE, FIELD_HERO_IMAGE } from '../../../constants/article/articleFields';
import { optimiseImage } from '../../helper/media';

const styles = theme => ({
  root: {
    display: 'flex',
    margin: `${theme.spacing(1)}px 0 !important`,
    alignItems: 'center',
    background: theme.palette.background.contrast,
    overflow: 'hidden',
  },
  thumbnail: {
    width: 150,
    height: 70,
    flexGrow: 0,
    margin: `-5px ${theme.spacing(2)}px -5px -8px`,
    objectFit: 'cover',
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    display: 'none',
    flexGrow: 0,
    alignSelf: 'stretch',
    alignItems: 'center',
    '.feed-item:hover &': {
      display: 'flex',
    },
  },
  handle: {
    margin: `-5px -8px -5px ${theme.spacing(1)}px`,
    height: '100%',
    padding: '5px 0',
  },
  button: {},
});

const getImageSrcFromHero = (heroImage) => {
  if (!heroImage || typeof heroImage === 'undefined') return null;
  return (heroImage?.data?.url) ? `${optimiseImage(heroImage.data.url, { width: 100 })}` : null;
};

const FeedItem = (props) => {
  const { classes, item, selectedPublication, setFeedContext: setContext, removeItem } = props;
  const { overrides = {} } = item;
  const imageSrc = getImageSrcFromHero(
    overrides[FIELD_HERO_IMAGE]
      ? overrides[FIELD_HERO_IMAGE][0]
      : item.heroImage,
  );
  return (
    <DraggableArticle
      article={item}
      source={item}
      className={`${classes.root} feed-item`}
    >
      {imageSrc && <img className={classes.thumbnail} alt={'thumbnail'} src={imageSrc} />}
      <Typography className={classes.title} variant={'body2'}>{overrides[FIELD_HEADLINE] || item.headline}</Typography>
      <div className={classes.actions}>
        <IconButton
          onClick={() => removeItem(item)}
          className={classes.button}
        ><DeleteOutlinedIcon /></IconButton>
        <IconButton
          onClick={() => window.open(`${selectedPublication.url}${item.url}`, '_blank')}
          className={classes.button}
        ><OpenInBrowserIcon /></IconButton>
        <IconButton
          onClick={() => setContext(CONTEXT_ARTICLE, item)}
          className={classes.button}
        ><SettingsIcon /></IconButton>
        <DragHandle className={classes.handle} />
      </div>
    </DraggableArticle>
  );
};

FeedItem.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  setFeedContext: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(
  ({
    frame: {
      selectedPublication,
    },
  }) => ({ selectedPublication }),
  { setFeedContext },
)(FeedItem));
