import { from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import {
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  CONFIG_FETCH,
  CONFIG_FETCH_SUCCESS,
  CONFIG_FETCH_REJECTED,
  CONFIG_ENTITY_FETCH,
  CONFIG_ENTITY_FETCH_SUCCESS,
  CONFIG_ENTITY_FETCH_REJECTED,
  CONFIG_ENTITY_SAVE,
  CONFIG_ENTITY_SAVE_SUCCESS,
  CONFIG_ENTITY_SAVE_REJECTED,
} from 'constants/actionTypes/config';
import { fetchConfig } from 'actions/config';
import apiCatchError, { showSuccessNotification } from '../helper/notification';

import ConfigEntity from 'entities/ConfigEntity';
import { FIELD_PROD } from '../../constants/config/configFields';

export const fetchPublicationConfig = (action$, state$) => action$.pipe(
  ofType(CONFIG_FETCH),
  withLatestFrom(state$),
  mergeMap(([, { frame: { selectedPublication: { domain } } } ]) =>
    ajax.getJSON(`/api/publication-config/${domain}`).pipe(
      map(response => ({ type: CONFIG_FETCH_SUCCESS, value: response })),
      apiCatchError(CONFIG_FETCH_REJECTED),
    ),
  ),
);

export const fetchConfigEntityOnSuccess = action$ => action$.pipe(
  ofType(CONFIG_FETCH_SUCCESS),
  filter(({ value: { configIds } }) => configIds && configIds.length === 2),
  mergeMap(({ value: { configIds } }) => from([
    ...configIds.map((configId, i) => fetchConfig(configId, i === 0)),
  ])),
);

export const fetchConfigEntity = action$ => action$.pipe(
  ofType(CONFIG_ENTITY_FETCH),
  mergeMap(({ value: { id, isDefault } }) =>
    ajax.getJSON(`/api/config/${id}`).pipe(
      mergeMap(response => {
        const entity = new ConfigEntity();
        const data = entity.getDataFromPayload(response);
        return of({ type: CONFIG_ENTITY_FETCH_SUCCESS, value: { data, isDefault } });
      }),
      apiCatchError(CONFIG_ENTITY_FETCH_REJECTED),
    ),
  ),
);

// @todo this will need to be able to handle default or publication and override logic
export const saveConfig = (action$, state$) => action$.pipe(
  ofType(CONFIG_ENTITY_SAVE),
  withLatestFrom(state$),
  mergeMap(([{ prop, value }, { configEntity } ]) => {
    // @todo add logic for default vs publication here
    const { publication } = configEntity;
    if (prop) {
      // @todo add logic for default or env override logic here
      publication[FIELD_PROD][prop] = value;
    }
    const entity = new ConfigEntity();
    const payload = entity.getPayloadFromData(publication);
    return ajax.patch(`/api/config/${publication.id}`, payload, { 'Content-Type': 'application/json' }).pipe(
      map(response => ({ type: CONFIG_ENTITY_SAVE_SUCCESS, value: response })),
      apiCatchError(CONFIG_ENTITY_SAVE_REJECTED),
    );
  }),
);

export const fetchConfigOnSave = action$ => action$.pipe(
  ofType(CONFIG_ENTITY_SAVE_SUCCESS),
  map(() => ({ type: CONFIG_FETCH })),
);

export const configSavedNotification = action$ => action$.pipe(
  ofType(CONFIG_ENTITY_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Config is successfully saved.')),
);
