import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'rxjs';

import TextField from '../../../ui/TextField';
import { MAX_LENGTH_SEO_DESCRIPTION } from '../../../../constants/article/article';

const SEODescription = ({ value, onChange, onInvalidChange, ...rest }) => {
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(value.length > MAX_LENGTH_SEO_DESCRIPTION);
  }, [value]);

  useEffect(() => {
    // parent need this value
    onInvalidChange(invalid);
  }, [invalid]);

  return (<TextField
    error={invalid}
    label={`SEO description (${value.length}/${MAX_LENGTH_SEO_DESCRIPTION})`}
    value={value}
    helperText={invalid && `SEO description has over ${MAX_LENGTH_SEO_DESCRIPTION} characters`}
    onChange={e => onChange(e.target.value)}
    {...rest}
  />);
};

SEODescription.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onInvalidChange: PropTypes.func,
};

SEODescription.defaultProps = {
  onInvalidChange: noop,
};

export default SEODescription;
