export const MFA_FETCH_QR = 'MFA_FETCH_QR';
export const MFA_FETCH_QR_FAILURE = 'MFA_FETCH_QR_FAILURE';
export const MFA_FETCH_QR_SUCCESS = 'MFA_FETCH_QR_SUCCESS';
export const MFA_VERIFY = 'MFA_VERIFY';
export const MFA_VERIFY_FAILURE = 'MFA_VERIFY_FAILURE';
export const MFA_VERIFY_SUCCESS = 'MFA_VERIFY_SUCCESS';

export const USER_SET_MFA_SUCCESS = 'USER_SET_MFA_SUCCESS';
export const USER_SET_MFA_FAILURE = 'USER_SET_MFA_FAILURE';

export const USER_SET_MFA_REQUIRED = 'USER_SET_MFA_REQUIRED';
export const USER_SET_MFA_MISSING = 'USER_SET_MFA_MISSING';

export const USER_LOGIN_MFA = 'USER_LOGIN_MFA';
