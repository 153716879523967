import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_BLOCKQUOTE } from './defaults';

const deserializeBlockquote = (options) => {
  const { blockquote } = setDefaults(options, DEFAULTS_BLOCKQUOTE);

  return {
    element: getNodeDeserializer({
      type: blockquote.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: blockquote.type,
          url,
        };
      },
      rules: [
        { className: blockquote.rootProps.className },
      ],
      ...options?.blockquote?.deserialize,
    }),
  };
};

export default deserializeBlockquote;
