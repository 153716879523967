import React from 'react';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';

import WorkFlowStatus from './fields/WorkFlowStatus';
import ArticlePublishingStatus from './fields/ArticlePublishingStatus';
import ArticleRevisionStatus from './fields/ArticleRevisionStatus';
import LastUpdatedStatus from './fields/LastUpdatedStatus';
import ArticleNotes from './fields/ArticleNotes';
import AccessFeature from '../../../access/AccessFeature';
import FieldLockToggle from '../../fieldLock/form/FieldLockToggle';
import { FEATURE_ATEX_TOGGLE_ON } from '../../../constants/features';
import { FIELD_ATEX } from '../../../constants/article/articleFields';
import ArticlePath from './fields/ArticlePath';

const ArticleStatus = () => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Status</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails>
      <LastUpdatedStatus />
      <ArticlePublishingStatus />
      <WorkFlowStatus />
      <ArticleRevisionStatus />
      <ArticleNotes />
      <AccessFeature restrictedTo={FEATURE_ATEX_TOGGLE_ON}>
        <FieldLockToggle field={FIELD_ATEX} label={'Send to ATEX'} />
      </AccessFeature>
      <ArticlePath />
    </ContextPanelDetails>
  </ContextPanel>);

export default ArticleStatus;

