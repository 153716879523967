import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mergeDeepRight } from 'rambdax';

import { saveProduct } from '../../actions/product';
import { setLocalProp } from '../../actions/dataState';

import Dialog from './common/Dialog';
import ProductForm from '../product/ProductForm';

const ProductEditDialog = (props) => {
  const {
    handleClose,
    localState,
    serverState,
    externalState,
    setLocalProp: setProp,
    saveProduct: save,
    ...rest
  } = props;
  const product = mergeDeepRight(serverState, localState, externalState);
  return (
    <Dialog
      title={'Edit product'}
      maxWidth={'md'}
      handleClose={handleClose}
      margin
      {...rest}
    >
      <ProductForm
        setProp={setProp}
        save={() => {
          save();
          handleClose();
        }}
        product={product}
      />
    </Dialog>
  );
};

ProductEditDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  localState: PropTypes.object.isRequired,
  serverState: PropTypes.object.isRequired,
  externalState: PropTypes.object.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  saveProduct: PropTypes.func.isRequired,
};

export default connect(
  ({ localState, serverState, externalState }) =>
    ({ localState, serverState, externalState }),
  { setLocalProp, saveProduct },
)(ProductEditDialog);
