import { PURGE } from 'redux-persist';
import {
  HOVER_SIDEBAR,
  TOGGLE_SIDEBAR,
} from '../../constants/actionTypes';

const defaultState = {
  alwaysExpanded: true,
  isExpanded: true,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case HOVER_SIDEBAR:
      return { ...state, isExpanded: action.value};

    case TOGGLE_SIDEBAR:
      return { ...state, alwaysExpanded: !state.alwaysExpanded, isExpanded: !state.alwaysExpanded};

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
