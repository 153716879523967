import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { requestFTPLog, disposeFTPLog } from '../../../actions/edition';
import { Table, TableBody, TableCell, TableHead, TableRow, TableHeadCell, TablePagination } from '../../ui/table/Table';
import { CMS_DATE_FORMAT_PRECICSE } from '../../../constants/common';

const pageOpts = [10, 50, 100];

const Logs = ({
  logsTotal,
  requestFTPLog: onRequestLog,
  disposeFTPLog: onDispose,
  logsEditions, logsExports,
  logs,
}) => {
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(pageOpts[0]);

  useEffect(() => () => {
    onDispose();
  }, []);
  useEffect(() => {
    onRequestLog({
      limit: sizePerPage,
      offset: sizePerPage * page,
    });
  }, [page, sizePerPage]);

  return (<Grid container direction={'column'}>
    <Table>
      <TableHead>
        <TableHeadCell>Collection ID</TableHeadCell>
        <TableHeadCell>Edition</TableHeadCell>
        <TableHeadCell>Export</TableHeadCell>
        <TableHeadCell>Created at</TableHeadCell>
        <TableHeadCell>Method</TableHeadCell>
        <TableHeadCell>Status</TableHeadCell>
        <TableHeadCell>Log</TableHeadCell>
      </TableHead>
      <TableBody>
        {logs && logs.map(({
          lid,
          collection_id: collectionID,
          edition_id: editionID,
          export_id: exportId,
          created_at: createdAt,
          method, status, log }) =>
          (<TableRow key={lid}>
            <TableCell>{collectionID}</TableCell>
            <TableCell>{logsEditions?.[editionID]?.data?.title || ''}</TableCell>
            <TableCell>{logsExports?.[exportId]?.data?.title || ''}</TableCell>
            <TableCell>{moment.unix(createdAt).format(CMS_DATE_FORMAT_PRECICSE)}</TableCell>
            <TableCell>{method}</TableCell>
            <TableCell>{status}</TableCell>
            <TableCell>{log}</TableCell>
          </TableRow>),
        )}
      </TableBody>
    </Table>
    <TablePagination
      rowsPerPageOptions={pageOpts}
      component={'div'}
      count={logsTotal}
      rowsPerPage={sizePerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onChangePage={(e, i) => setPage(i)}
      onChangeRowsPerPage={(e) => {
        setPage(0);
        setSizePerPage(e.target.value);
      }}
    />
    <Grid item />
  </Grid>);
};

Logs.propTypes = {
  requestFTPLog: PropTypes.func.isRequired,
  disposeFTPLog: PropTypes.func.isRequired,
  logs: PropTypes.array.isRequired,
  logsEditions: PropTypes.object.isRequired,
  logsExports: PropTypes.object.isRequired,
  logsTotal: PropTypes.array.isRequired,
};

export default connect(
  ({
    edition: { logs, logsTotal, logsEditions, logsExports },
  }) => ({ logs, logsTotal, logsEditions, logsExports }),
  { requestFTPLog, disposeFTPLog },
)(Logs);

