import { ReactEditor } from 'slate-react';
import { scrollToView } from '../../../../utils/domHelper';
import { PARALLAX } from '../../../../constants/editor/dataTypes';

const EXCLUDE_FROM_FOCUS = [PARALLAX];

const withFocus = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([currentNode, currentPath]) => {
    try {
      if (currentPath) {
        const domEl = ReactEditor.toDOMNode(editor, currentNode);
        if (
          !EXCLUDE_FROM_FOCUS.includes(currentNode.type) &&
          domEl.hasAttribute('data-slate-node') &&
          !domEl.hasAttribute('data-slate-editor')
        ) {
          // If domEL is a slate node but not the editor attempt to scroll
          scrollToView(domEl, 75);
        }
      }
      return normalizeNode([currentNode, currentPath]);
    } catch (e) {
      return normalizeNode([currentNode, currentPath]);
    }
  };

  return editor;
};

export default withFocus;
