import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setLocalProp } from '../../../actions/dataState';

import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../selectors/fieldLock/fieldLock';
import FieldLockContainer from '../../fieldLock/FieldLockContainer';
import FieldLockPanelSection from '../FieldLockPanelSection';
import Editor from '../../editor/Editor';

const FieldLockEditor = ({
  value, field, lockValue, hasChanges, panel, label, required,
  setLocalProp: setProp, isProduct
}) => {
  const Wrapper = panel ? FieldLockPanelSection : FieldLockContainer;
  return (
    <Wrapper
      field={field}
      label={label}
      lockValue={lockValue}
      required={required}
      hasChanges={hasChanges}
      noPadding
    >
      <Editor
        content={value}
        onChange={content => setProp(field, content)}
        showWordCount
        isProduct={isProduct}
      />
    </Wrapper>
  );
};

FieldLockEditor.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  panel: PropTypes.bool,
  required: PropTypes.bool,
};

FieldLockEditor.defaultProps = {
  value: '',
  label: '',
  lockValue: null,
  hasChanges: false,
  panel: false,
  required: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockEditor);
