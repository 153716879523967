import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { FIELD_ARTICLE_TYPE, FIELD_STRUCTURED } from 'constants/article/articleFields';
import {
  ITEM_REVIEWED,
  ITEM_REVIEWED_TYPES,
  PROP_ARTIST,
  PROP_AUTHOR,
  PROP_EPISODE_NO, PROP_LOCATION,
  PROP_NAME,
  PROP_SEASON,
  PROP_SERIES, PROP_START_DATE,
  PROP_TYPE,
} from 'constants/structuredData';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from 'components/common/context/ContextPanel';
import FieldLockTextfield from 'components/fieldLock/form/FieldLockTextfield';
import FieldLockSelect from 'components/fieldLock/form/FieldLockSelect';
import FieldLockDateTime from "../../fieldLock/form/FieldLockDateTime";

const StructuredData = ({ articleType, structured }) => {
  const [reviewType, setReviewType] = useState(structured?.[ITEM_REVIEWED]?.[PROP_TYPE] || null);
  useEffect(() => {
    if (
      structured?.[ITEM_REVIEWED]?.[PROP_TYPE] &&
      structured[ITEM_REVIEWED][PROP_TYPE] !== reviewType
    ) {
      setReviewType(structured[ITEM_REVIEWED][PROP_TYPE]);
    }
  }, [structured]);
  if (!articleType || (articleType && !/review/i.test(articleType.name))) {
    return null;
  }
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Structured Data</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}><Typography variant="h3">Reviewed Item</Typography></Grid>
          <Grid item xs={12}>
            <FieldLockTextfield required label="Name" field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_NAME]} />
          </Grid>
          <Grid item xs={12}><FieldLockSelect
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_TYPE]}
            items={ITEM_REVIEWED_TYPES}
            label={'Type'}
            required
          /></Grid>
          {reviewType === 'Event' && <Grid item xs={12}><FieldLockTextfield
            label="Location"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_LOCATION]}
          /></Grid>}
          {reviewType === 'Event' && <Grid item xs={12}><FieldLockDateTime
            label="Start Date"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_START_DATE]}
          /></Grid>}
          {reviewType === 'Book' && <Grid item xs={12}><FieldLockTextfield
            label="Author"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_AUTHOR]}
          /></Grid>}
          {(reviewType === 'MusicAlbum' || reviewType === 'MusicRecording' ) && <Grid item xs={12}><FieldLockTextfield
            label="Artist"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_ARTIST]}
          /></Grid>}
          {reviewType === 'TVEpisode' && <Grid item xs={12}><FieldLockTextfield
            label="Episode Number"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_EPISODE_NO]}
          /></Grid>}
          {(reviewType === 'TVEpisode') && <Grid item xs={12}><FieldLockTextfield
            label="Season"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_SEASON]}
          /></Grid>}
          {(reviewType === 'TVEpisode' || reviewType === 'TVSeason' ) && <Grid item xs={12}><FieldLockTextfield
            label="Series"
            field={[FIELD_STRUCTURED, ITEM_REVIEWED, PROP_SERIES]}
          /></Grid>}
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

StructuredData.propTypes = {
  articleType: PropTypes.object,
  structured: PropTypes.object,
};

StructuredData.defaultProps = {
  articleType: {},
  structured: {},
};

export default connect(
  ({
    dataState: { [FIELD_ARTICLE_TYPE]: articleType, [FIELD_STRUCTURED]: structured },
  }) => ({ articleType, structured }),
  {},
)(StructuredData);
