import {
  TERM_AUTHOR_ONSAVE,
  TERM_FETCH_SECTION_TREE,
  ON_CHANGE_SECTION_TREE,

} from '../constants/actionTypes';
import {
  VOCAB_DELETE, VOCAB_SAVE,
  VOCAB_FETCH, VOCAB_FETCH_LAYOUT, VOCAB_UPDATE_MULTIPLE,
  VOCAB_FETCH_WORKFLOW_GENERIC, VOCAB_FETCH_TERM, VOCAB_CURRENT_TERM_DISPOSE,
} from '../constants/actionTypes/vocab';

export const termAuthorSave = value => ({ type: TERM_AUTHOR_ONSAVE, value });

export const vocabMultipleUpdate = value => ({ type: VOCAB_UPDATE_MULTIPLE, value });
export const vocabSave = value => ({ type: VOCAB_SAVE, value });
export const vocabDelete = value => ({ type: VOCAB_DELETE, value });

export const fetchSectionTree = value => ({ type: TERM_FETCH_SECTION_TREE, value });
export const onChangeSectionTree = value => ({ type: ON_CHANGE_SECTION_TREE, value });

export const fetchVocab = value => ({ type: VOCAB_FETCH, value });
export const fetchVocabTerm = value => ({ type: VOCAB_FETCH_TERM, value });
export const disposeVocabTerm = () => ({ type: VOCAB_CURRENT_TERM_DISPOSE });
export const fetchVocabLayout = value => ({ type: VOCAB_FETCH_LAYOUT, value });
export const fetchWorkflowGeneric = () => ({ type: VOCAB_FETCH_WORKFLOW_GENERIC });
