import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import ImageField from './common/ImageField';
import { FieldWrapper } from './common/GridWrapper';

const PROMPT_LABEL = 'Prompt';
const CAT1_LABEL = 'Category 1';
const CAT2_LABEL = 'Category 2';
const CAT3_LABEL = 'Category 3';
const CAT4_LABEL = 'Category 4';
const THRESHOLD_LABEL = 'Threshold';
const TEXT_LABEL = 'Text';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA';

const processThreshold = (value) => {
  const processedValue = parseInt(value, 10);
  if (isNaN(processedValue)) return '';
  if (processedValue <= 0) return 0;
  if (processedValue >= 100) return 100;
  return processedValue;
};

const TemplateResults = (props) => {
  const {
    id, prompt, image, images, thresholds, categories, text, attachment, attachmentCTA,
    updateStoryPageProp: updateProp,
  } = props;
  while (categories.length < 4) {
    categories.push('');
  }
  while (thresholds.length < 4) {
    thresholds.push('');
  }
  while (text.length < 4) {
    text.push('');
  }
  while (images.length < 4) {
    images.push(null);
  }
  // @todo rework with loop for categories
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={'Add cover image'}
          onSelect={value => updateProp(id, 'image', {
            type: 'image',
            data: value,
          })}
          onRemove={() => updateProp(id, 'image', null)}
          image={image}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={PROMPT_LABEL}
          value={prompt}
          onChange={e => updateProp(id, 'prompt', e.target.value)}
        />
      </FieldWrapper>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={`Add image for ${CAT1_LABEL}`}
          onSelect={value => updateProp(id, 'images', [
            {
              type: 'image',
              data: value,
            },
            images[1],
            images[2],
            images[3],
          ])}
          onRemove={() => updateProp(id, 'images', [
            null,
            images[1],
            images[2],
            images[3],
          ])}
          image={images[0] || null}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={CAT1_LABEL}
          value={categories[0]}
          onChange={e => updateProp(id, 'categories', [
            e.target.value,
            categories[1],
            categories[2],
            categories[3],
          ])}
        />
        <TextField
          label={TEXT_LABEL}
          value={text[0]}
          multiline
          onChange={e => updateProp(id, 'text', [
            e.target.value,
            text[1],
            text[2],
            text[3],
          ])}
        />
        <TextField
          label={THRESHOLD_LABEL}
          value={thresholds[0]}
          onChange={e => updateProp(id, 'thresholds', [
            processThreshold(e.target.value),
            thresholds[1],
            thresholds[2],
            thresholds[3],
          ])}
        />
      </FieldWrapper>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={`Add image for ${CAT2_LABEL}`}
          onSelect={value => updateProp(id, 'images', [
            images[0],
            {
              type: 'image',
              data: value,
            },
            images[2],
            images[3],
          ])}
          onRemove={() => updateProp(id, 'images', [
            images[0],
            null,
            images[2],
            images[3],
          ])}
          image={images[1] || null}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={CAT2_LABEL}
          value={categories[1]}
          onChange={e => updateProp(id, 'categories', [
            categories[0],
            e.target.value,
            categories[2],
            categories[3],
          ])}
        />
        <TextField
          label={TEXT_LABEL}
          value={text[1]}
          multiline
          onChange={e => updateProp(id, 'text', [
            text[0],
            e.target.value,
            text[2],
            text[3],
          ])}
        />
        <TextField
          label={THRESHOLD_LABEL}
          value={thresholds[1]}
          onChange={e => updateProp(id, 'thresholds', [
            thresholds[0],
            processThreshold(e.target.value),
            thresholds[2],
            thresholds[3],
          ])}
        />
      </FieldWrapper>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={`Add image for ${CAT3_LABEL}`}
          onSelect={value => updateProp(id, 'images', [
            images[0],
            images[1],
            {
              type: 'image',
              data: value,
            },
            images[3],
          ])}
          onRemove={() => updateProp(id, 'images', [
            images[0],
            images[1],
            null,
            images[3],
          ])}
          image={images[2] || null}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={CAT3_LABEL}
          value={categories[2]}
          onChange={e => updateProp(id, 'categories', [
            categories[0],
            categories[1],
            e.target.value,
            categories[3],
          ])}
        />
        <TextField
          label={TEXT_LABEL}
          value={text[2]}
          multiline
          onChange={e => updateProp(id, 'text', [
            text[0],
            text[1],
            e.target.value,
            text[3],
          ])}
        />
        <TextField
          label={THRESHOLD_LABEL}
          value={thresholds[2]}
          onChange={e => updateProp(id, 'thresholds', [
            thresholds[0],
            thresholds[1],
            processThreshold(e.target.value),
            thresholds[3],
          ])}
        />
      </FieldWrapper>
      <Grid item xs={3}>
        <ImageField
          id={id}
          label={`Add image for ${CAT4_LABEL}`}
          onSelect={value => updateProp(id, 'images', [
            images[0],
            images[1],
            images[2],
            {
              type: 'image',
              data: value,
            },
          ])}
          onRemove={() => updateProp(id, 'images', [
            images[0],
            images[1],
            images[2],
            null,
          ])}
          image={images[3] || null}
        />
      </Grid>
      <FieldWrapper item xs={9}>
        <TextField
          label={CAT4_LABEL}
          value={categories[3]}
          onChange={e => updateProp(id, 'categories', [
            categories[0],
            categories[1],
            categories[2],
            e.target.value,
          ])}
        />
        <TextField
          label={TEXT_LABEL}
          value={text[3]}
          multiline
          onChange={e => updateProp(id, 'text', [
            text[0],
            text[1],
            text[2],
            e.target.value,
          ])}
        />
        <TextField
          label={THRESHOLD_LABEL}
          value={thresholds[3]}
          onChange={e => updateProp(id, 'thresholds', [
            thresholds[0],
            thresholds[1],
            thresholds[2],
            processThreshold(e.target.value),
          ])}
        />
      </FieldWrapper>
      <Grid item xs={12}>
        <TextField
          label={ATTACHMENT_LABEL}
          placeholder={ATTACHMENT_PLACEHOLDER}
          value={attachment}
          onChange={e => updateProp(id, 'attachment', e.target.value)}
        />
        <TextField
          label={ATTACHMENT_CTA_LABEL}
          placeholder={ATTACHMENT_CTA_PLACEHOLDER}
          value={attachmentCTA}
          onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

TemplateResults.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  prompt: PropTypes.string,
  image: PropTypes.object,
  categories: PropTypes.array,
  images: PropTypes.array,
  thresholds: PropTypes.array,
  text: PropTypes.array,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplateResults.defaultProps = {
  prompt: '',
  image: {},
  categories: [],
  images: [],
  thresholds: [],
  text: [],
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplateResults);
