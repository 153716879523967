import { keyFieldsForAPI, keyFieldsForCMS } from '../helper';

export const FIELD_TITLE = 'title';
export const FIELD_SEO_HEADLINE = 'seoHeadline';
export const FIELD_URL_KEYWORDS = 'urlKeywords';
export const FIELD_AUTHORS = 'authors';
export const FIELD_PAGES = 'pages';
export const FIELD_IS_RESPONSIVE = 'isResponsive';
export const FIELD_IS_LIVE = 'isLive';
export const FIELD_SCHEDULED = 'scheduled';
export const FIELD_BOOKEND = 'bookend';
export const FIELD_SHOW_BOOKEND = 'showBookend';
export const FIELD_THUMBNAIL = 'thumbnail';
export const FIELD_SECTION = 'section';

export const FIELD_UPDATED = 'updated';
export const FIELD_PUBLISH = 'publish';
export const FIELD_UNPUBLISH = 'unpublish';

// API only fields
export const FIELD_EXTRA_DATA = 'field_story_json';
export const FIELD_PUBLICATION = 'field_publication';
export const FIELD_CREATED = 'field_created';
export const FIELD_FIRST_PUBLISHED = 'field_published';
export const FIELD_API_PUBLISH = 'field_display';
export const FIELD_API_UNPUBLISH = 'field_unpublish';

export const PAYLOAD_FIELD_KEYS = [
  FIELD_TITLE,
  FIELD_AUTHORS,
  FIELD_UPDATED,
  FIELD_PUBLISH,
  FIELD_UNPUBLISH,
  FIELD_SECTION,
  FIELD_SEO_HEADLINE,
  FIELD_URL_KEYWORDS,
];

export const EXTRA_FIELD_KEYS = [
  FIELD_PAGES,
  FIELD_SHOW_BOOKEND,
  FIELD_BOOKEND,
  FIELD_IS_RESPONSIVE,
  FIELD_IS_LIVE,
  FIELD_THUMBNAIL,
];

export const STORY_FIELD_MAPPING = [
  [FIELD_TITLE, 'name', 'title'],
  [FIELD_AUTHORS, 'field_authors', 'authors'],
  [FIELD_SECTION, 'field_section', 'section'],
  [FIELD_UPDATED, 'field_updated', 'updated'],
  [FIELD_SEO_HEADLINE, 'field_seo_headline', 'Meta title'],
  [FIELD_URL_KEYWORDS, 'field_url_keywords', 'URL keywords'],
  [FIELD_PUBLISH, FIELD_API_PUBLISH, 'scheduled for display'],
  [FIELD_UNPUBLISH, FIELD_API_UNPUBLISH, 'scheduled for unpublishing'],
  [FIELD_PAGES, FIELD_PAGES, 'pages'],
  [FIELD_SHOW_BOOKEND, FIELD_SHOW_BOOKEND, 'show bookend'],
  [FIELD_BOOKEND, FIELD_BOOKEND, 'bookend'],
  [FIELD_IS_RESPONSIVE, FIELD_IS_RESPONSIVE, 'responsive display'],
  [FIELD_IS_LIVE, FIELD_IS_LIVE, 'live story'],
  [FIELD_THUMBNAIL, FIELD_THUMBNAIL, 'thumbnail'],
  [FIELD_IS_RESPONSIVE, FIELD_IS_RESPONSIVE, 'responsive display'],
  [FIELD_IS_LIVE, FIELD_IS_LIVE, 'live story'],
];

export const STORY_FIELD_MAPPING_CMS_KEYED = keyFieldsForCMS(STORY_FIELD_MAPPING);
export const STORY_FIELD_MAPPING_API_KEYED = keyFieldsForAPI(STORY_FIELD_MAPPING);
