import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import ComponentLockOverlay from './ComponentLockOverlay';
import { COMPONENT_ID } from '../../../entities/LayoutEntity';
import { FIELD_COMPONENTS, FIELD_MANUAL_LISTS } from '../../../constants/layout/layoutFields';
import { unlockFieldLock } from '../../../actions/fieldLock';

const styles = () => ({
  lock: {
    position: 'relative',
  },
});

const ComponentLock = (props) => {
  const {
    classes, children, className, component, lockData, isNew, manualList, listLocked,
    unlockFieldLock: unlock,
    ...rest
  } = props;
  return (
    <div className={classes.lock} {...rest}>
      {children}
      {lockData?.user && <ComponentLockOverlay
        className={className}
        lock={lockData}
        isNew={isNew}
        onUnlock={() => {
          unlock(listLocked
            ? `${FIELD_MANUAL_LISTS}_${manualList.id}`
            : `${FIELD_COMPONENTS}_${component[COMPONENT_ID]}`
          );
          // @todo lock field to user to override other user
        }}
      />}
    </div>
  );
};

ComponentLock.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  component: PropTypes.object.isRequired,
  unlockFieldLock: PropTypes.func.isRequired,
  className: PropTypes.string,
  lockData: PropTypes.object,
  isNew: PropTypes.bool,
  manualList: PropTypes.object,
  listLocked: PropTypes.bool,
};

ComponentLock.defaultProps = {
  lockData: null,
  className: null,
  isNew: false,
  manualList: null,
  listLocked: false,
};

export default withStyles(styles)(connect(
  () => ({}),
  { unlockFieldLock },
)(ComponentLock));
