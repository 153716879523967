import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_HIDE_AND_SHOW, PROP_MARKUP, PROP_HIDE_PROMPT, PROP_SHOW_PROMPT } from './defaults';
import { HIDE_SHOW } from '../../../../../constants/editor/dataTypes';

export const convertToHideAndShow = data => ({
  type: ELEMENT_HIDE_AND_SHOW,
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  [PROP_HIDE_PROMPT]: data[PROP_HIDE_PROMPT] || '',
  [PROP_SHOW_PROMPT]: data[PROP_SHOW_PROMPT] || '',
  children: [{ text: '' }],
});

export const convertFromHideAndShow = data => ({
  type: HIDE_SHOW,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP] ? serializeNodes(data[PROP_MARKUP]) : null,
    [PROP_HIDE_PROMPT]: data[PROP_HIDE_PROMPT] || '',
    [PROP_SHOW_PROMPT]: data[PROP_SHOW_PROMPT] || '',
  },
});
