import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { setLocalProp } from '../../../actions/dataState';

import Rating from '../../ui/Rating';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import { FIELD_ARTICLE_TYPE, FIELD_STAR_RATING } from '../../../constants/article/articleFields';

// @todo add fieldLocking logic
const StarRating = ({ setLocalProp: setProp, starRating, articleType }) => {
  if (!articleType || (articleType && !/review/i.test(articleType.name))) {
    return null;
  }
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading required>Star rating</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <Rating
          value={starRating}
          onReset={() => setProp('starRating', 0)}
          onChange={event => setProp('starRating', event.target.value)}
          precision={0.5}
          size={'large'}
          emptyIcon={<StarBorderIcon fontSize={'inherit'} />}
        />
      </ContextPanelDetails>
    </ContextPanel>);
};

StarRating.propTypes = {
  starRating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setLocalProp: PropTypes.func.isRequired,
  articleType: PropTypes.object,
};

StarRating.defaultProps = {
  articleType: {},
  starRating: 0,
};

export default connect(
  ({
    dataState: { [FIELD_STAR_RATING]: starRating, [FIELD_ARTICLE_TYPE]: articleType },
  }) => ({ starRating, articleType }),
  { setLocalProp },
)(StarRating);

