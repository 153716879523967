import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CONTEXT_ARTICLE_SELECTOR,
  CONTEXT_COMPONENT,
  CONTEXT_COMPONENT_SELECTOR,
} from '../../constants/contexts';

import ArticleSelector from '../contexts/ArticleSelector';
import ComponentProperties from './contexts/ComponentProperties';
import ComponentSelector from './contexts/ComponentSelector';
import Error from '../contexts/Error';

import ContextBar, { ContextBarBody } from '../common/context/ContextBar';
import ContextUI from './contexts/common/ContextUI';
import Button from '../ui/buttons/Button';
import { ContextPanel, ContextPanelDetails } from '../common/context/ContextPanel';
import { selectComponentManualList } from '../../actions/layout';

const getComponentFromContext = (context) => {
  const { type } = context;
  switch (type) {
    case CONTEXT_ARTICLE_SELECTOR:
      return ArticleSelector;
    case CONTEXT_COMPONENT:
      return ComponentProperties;
    case CONTEXT_COMPONENT_SELECTOR:
      return ComponentSelector;
    default:
      return Error;
  }
};

getComponentFromContext.defaultProps = {
  data: {},
};

const LayoutContext = (props) => {
  const { context, selectedManualList, selectComponentManualList: selectList, ...rest } = props;
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  if (selectedManualList > -1) {
    return (<ContextBar>
      <ContextBarBody>
        <ContextPanel noHeading>
          <ContextPanelDetails variant={'full'}>
            <Button
              variant={'outlined'}
              onClick={() => selectList(-1)}
            >Exit Component linking</Button>
          </ContextPanelDetails>
        </ContextPanel>
      </ContextBarBody>
    </ContextBar>);
  }
  const ContextComponent = getComponentFromContext(context);
  return (<ContextBar>
    <ContextUI {...context} />
    <ContextComponent
      {...context}
      page={page}
      query={query}
      setQuery={setQuery}
      setPage={setPage}
      {...rest}
    />
  </ContextBar>);
};

LayoutContext.propTypes = {
  context: PropTypes.object.isRequired,
  selectedManualList: PropTypes.number.isRequired,
  selectComponentManualList: PropTypes.func.isRequired,
};

export default connect(
  ({ layout: { selectedManualList, context } }) => ({ selectedManualList, context }),
  { selectComponentManualList },
)(LayoutContext);
