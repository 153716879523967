import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    lineHeight: `${theme.spacing(3)}px`,
    boxShadow: 'none',
    whiteSpace: 'nowrap',
  },
  normal: {
    padding: theme.spacing(1, 2),
  },
  small: {
    padding: theme.spacing(0, 1),
  },
  large: {
    padding: theme.spacing(2),
  },
  textOnly: {
    minWidth: 0,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'none',
  },
  noTransform: {
    textTransform: 'none',
  },
});

const Button = (props) => {
  const {
    classes, className, children, onClick, color, variant, textOnly, caption, size, noTransform,
    ...rest
  } = props;
  const btnClasses = [classes.root];
  if (className) {
    btnClasses.push(className);
  }
  if (!textOnly && ['small', 'normal', 'large'].includes(size)) {
    btnClasses.push(classes[size]);
  }
  if (textOnly) {
    btnClasses.push(classes.textOnly);
  }
  if (caption) {
    btnClasses.push(classes.caption);
  }
  if (noTransform) {
    btnClasses.push(classes.noTransform);
  }
  return (
    <ButtonBase
      color={color}
      variant={variant}
      className={btnClasses.join(' ')}
      onClick={onClick}
      {...rest}
    >{children}</ButtonBase>
  );
};

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  textOnly: PropTypes.bool,
  caption: PropTypes.bool,
  noTransform: PropTypes.bool,
};

Button.defaultProps = {
  className: null,
  color: 'primary',
  variant: 'text',
  size: 'normal',
  textOnly: false,
  caption: false,
  noTransform: false,
};

export default withStyles(styles)(Button);
