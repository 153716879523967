import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { ChevronRight } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Account from './Account';

import PublicationSelector from './PublicationSelector';
import { toggle } from '../../actions/drawer';
import { maxWidthSidebar } from '../../constants/frame';
import { drawerCollapseWidth, drawerWidth } from '../drawer';

export const headerHeight = 64;

const style = theme => ({
  appbarRoot: {
    backgroundColor: theme.palette.common.white,
    height: headerHeight,
    justifyContent: 'center',
    boxShadow: '0 3px 3px -2px rgba(0, 0, 0, 0.16)',
    marginLeft: theme.spacing(7) + 1,
    width: `calc(100% - ${drawerCollapseWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '.expanded &': {
      marginLeft: drawerWidth + 1,
      width: `calc(100% - ${drawerWidth + 1}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  toolBar: {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: headerHeight,
  },
  divider: {
    margin: theme.spacing(0, 3),
    width: 1,
    height: 40,
    backgroundColor: theme.palette.primary.main,
  },
  menuButton: {
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    left: '-12px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '.expanded &': {
      transform: 'rotate(180deg)',
    },
  },
  toolbarContent: {
    flex: 1,
    height: '100%',
  },
  account: {
    display: 'flex',
    flexBasis: `calc(${maxWidthSidebar} - ${theme.spacing(5)}px)`,
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    marginRight: theme.spacing(2),
  },
});

const Header = ({ classes, toggle: onToogle, toolbarContent }) => (
  <AppBar classes={{ root: classes.appbarRoot }} color={'default'} position={'fixed'}>
    <Toolbar className={classes.toolBar}>
      <IconButton id="expandDrawer" className={classes.menuButton} onClick={onToogle}>
        <ChevronRight className={classes.menuIcon} style={{ fill:'#fff' }}/>
      </IconButton>
      <div className={classes.toolbarContent}>{ toolbarContent }</div>
      <div className={classes.divider} />
      <div className={classes.account}>
        <PublicationSelector />
        <Account />
      </div>
    </Toolbar>
  </AppBar>
);

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  toolbarContent: PropTypes.node,
  toggle: PropTypes.func.isRequired,
};

Header.defaultProps = {
  toolbarContent: null,
};

export default withStyles(style)(connect(() => ({}), { toggle })(Header));
