import { EMPTY, from, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  catchError,
  debounceTime,
  filter,
  map, mapTo,
  merge,
  mergeMap,
  switchMap, take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import {
  EVENT_FETCH,
  EVENT_FETCH_SUCCESS,
  EVENT_FETCH_REJECTED,
  EVENT_LIST_DISPOSE,
  EVENT_LIST_FETCH,
  EVENT_LIST_FETCH_REJECTED,
  EVENT_LIST_FETCH_SUCCESS,
  EVENT_SAVE,
  EVENT_SAVE_NOT_READY,
  EVENT_SAVE_READY,
  EVENT_SAVE_REJECTED,
  EVENT_SAVE_SUCCESS,
  EVENT_REQUEST_PREVIEW,
  EVENT_REQUEST_PREVIEW_SUCCESS,
  EVENT_REQUEST_PREVIEW_REJECTED, EVENT_DELETE, EVENT_DELETE_SUCCESS, EVENT_DELETE_REJECTED,
} from '../../constants/actionTypes/event';
import { WS_EVENT_LIST } from '../../constants/actionTypes/ws';
import { serialize } from '../../utils/urlHelper';
import apiCatchError, { assignErrorPayload, showSuccessNotification } from '../helper/notification';

import EventEntity, { ENTITY_BUNDLE } from '../../entities/EventEntity';
import { DATASTATE_LOCAL_DISPOSE } from '../../constants/actionTypes/dataState';
import { PRODUCTION } from '../../constants/env';
import { FIELD_ID } from '../../constants/common/commonFields';
import { FIELD_NAME } from '../../constants/event/eventFields';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

export const fetchEvent = action$ => action$.pipe(
  ofType(EVENT_FETCH),
  switchMap(({ value }) => ajax.get(
    `/api/event/${value}`,
    { 'Content-Type': 'application/json' },
  ).pipe(
    mergeMap(({ response }) => of({
      type: EVENT_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(EVENT_FETCH_REJECTED),
  )),
);

export const fetchEventList = (action$, state$) => {
  let cachedParams;
  return (
    action$.pipe(
      ofType(EVENT_LIST_FETCH),
      switchMap(action => of(action).pipe(
        merge(action$.pipe(
          ofType(WS_EVENT_LIST, PUBLICATION_SELECTED),
          filter(() => !!cachedParams),
          map(() => ({
            type: EVENT_LIST_FETCH,
            value: cachedParams,
          })),
          takeUntil(action$.pipe(ofType(EVENT_LIST_DISPOSE))),
        )),
        takeUntil(action$.pipe(ofType(EVENT_LIST_DISPOSE))),
        withLatestFrom(state$),
        debounceTime(500),
        switchMap(([
          { value },
          { frame: { selectedPublication: { domain } } },
        ]) => {
          cachedParams = value;
          return ajax.get(`/api/event-list/${ENTITY_BUNDLE}/${domain}?${serialize({ ...value })}`, { 'Content-Type': 'application/json' }).pipe(
            mergeMap(({ response }) => of({
              type: EVENT_LIST_FETCH_SUCCESS,
              value: response,
            })),
            apiCatchError(EVENT_LIST_FETCH_REJECTED),
          );
        }),
      )),
    )
  );
};

export const validateSaveEvent = (action$, state$) => action$.pipe(
  ofType(EVENT_SAVE),
  withLatestFrom(state$),
  mergeMap(([{ value }, { localState, serverState }]) => {
    const entity = new EventEntity();
    // if no value (article is save for draft) then only validate FIELD_NAME
    const errors = entity.getValidationErrors(localState, serverState, value ? null : [FIELD_NAME]);
    if (errors.length > 0) {
      return from([
        { type: EVENT_SAVE_NOT_READY, value },
        ...errors.map(({ message }) => assignErrorPayload(message)),
      ]);
    }
    return of({
      type: EVENT_SAVE_READY,
      value,
    });
  }),
);

export const saveArticle = (action$, state$) => action$.pipe(
  ofType(EVENT_SAVE_READY),
  withLatestFrom(state$),
  switchMap(([
    { value },
    {
      localState,
      serverState,
      frame: { selectedPublication, socketId },
    },
  ]) => {
    const entity = new EventEntity();
    const payload = entity.getPayloadFromData(
      localState,
      serverState,
      selectedPublication,
      value,
    );
    const id = payload?.id?.[0]?.value;
    if (id) {
      delete payload.id;
      delete payload.path;
      return ajax.patch(`/api/event/${id}?socketId=${socketId}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => of({
          type: EVENT_SAVE_SUCCESS,
          value: response,
        })),
        catchError((ex) => {
          console.log(ex);
          return from([
            { type: EVENT_SAVE_REJECTED },
            assignErrorPayload('Api Error. Unable to save this event.'),
          ]);
        }),
      );
    }
    return ajax.post('/api/event', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => of({
        type: EVENT_SAVE_SUCCESS,
        value: response,
      })),
      catchError((ex) => {
        console.log(ex);
        return from([
          { type: EVENT_SAVE_REJECTED },
          assignErrorPayload('Api Error. Unable to save this event.'),
        ]);
      }),
    );
  }),
  catchError((ex) => {
    console.log(ex);
    return from([
      { type: EVENT_SAVE_REJECTED },
      assignErrorPayload('Api Error. Unable to save this event.'),
    ]);
  }),
);

export const redirectOnEventSaved = (action$, state$) => action$.pipe(
  ofType(EVENT_SAVE_SUCCESS),
  withLatestFrom(state$),
  filter(([, {
    router: { location: { pathname } },
  }]) => /^\/event\/build/.test(pathname)),
  mergeMap(([
    { value: {
      id: [{ value: eventId }],
    } },
  ]) => action$.pipe(
    ofType(DATASTATE_LOCAL_DISPOSE), // wait until disposal
    map(() => push(`/event/edit/${eventId}`)),
    take(1),
  )),
);

export const eventSavedNotification = action$ => action$.pipe(
  ofType(EVENT_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Event is successfully saved.')),
);

export const deleteEvent = (action$, state$) => action$.pipe(
  ofType(EVENT_DELETE),
  withLatestFrom(state$, (action, state) => state),
  switchMap(({ dataState: { [FIELD_ID]: id } }) =>
    ajax.delete(`/api/event/${id}`).pipe(
      mapTo({ type: EVENT_DELETE_SUCCESS }),
      apiCatchError(EVENT_DELETE_REJECTED),
    )),
);

export const redirectAfterDeleteSuccess = action$ => action$.pipe(
  ofType(EVENT_DELETE_SUCCESS),
  mapTo(push('/event')),
);

export const requestPreview = (action$, state$) => action$.pipe(
  ofType(EVENT_REQUEST_PREVIEW),
  withLatestFrom(state$),
  switchMap(([, { dataState, frame: { selectedPublication } }]) => {
    const entity = new EventEntity();
    const payload = entity.getPayloadFromData(dataState, dataState, selectedPublication);
    delete payload[FIELD_ID];
    return ajax.post('/api/preview/event', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => of({
        type: EVENT_REQUEST_PREVIEW_SUCCESS,
        value: response,
      })),
      apiCatchError(EVENT_REQUEST_PREVIEW_REJECTED),
    );
  }),
);

export const openPreviewInNew = (action$, state$) => action$.pipe(
  ofType(EVENT_REQUEST_PREVIEW_SUCCESS),
  withLatestFrom(state$),
  mergeMap(([
    { value: { preview_id: previewId } },
    {
      frame: { selectedPublication },
      dashboard: { mode },
    },
  ]) => {
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url
      : selectedPublication.environment.web;
    window.open(`${frontEndUrl.replace(/\/$/, '')}/preview/${previewId}`, '_blank');
    return EMPTY;
  }),
);
