import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';
import ImageSelector from '../../../ui/media/ImageSelector';
import TextField from '../../../ui/TextField';
import { changeHandler } from './helper';

const VIDEO = 'video';
const IMAGE = 'image';
const PLAYLIST_URL = 'playlistUrl';
const PLAYLIST_DESCRIPTION = 'description';
const IMAGE_LINK = 'imageLink';

const styles = theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
  },
});

const HeroPlusImageAndVideoSettings = (props) => {
  const {
    component,
    componentPropOnChange,
    classes,
  } = props;
  const { id } = component;
  return (
    <ContextPanel>
      <ContextPanelHeading>Image + Video settings</ContextPanelHeading>
      <ContextPanelDetails variant="full">
        <TextField
          defaultValue={component[PLAYLIST_URL] || ''}
          onChange={changeHandler(value => componentPropOnChange(id, PLAYLIST_URL, value))}
          label="Primary video url"
          margin="dense"
        />
        <TextField
          margin="dense"
          defaultValue={component[PLAYLIST_DESCRIPTION] || ''}
          multiline
          rows={3}
          onChange={changeHandler(text => componentPropOnChange(id, PLAYLIST_DESCRIPTION, text))}
          label="Description"
        />
        <TextField
          margin="dense"
          defaultValue={component[IMAGE_LINK] || ''}
          onChange={changeHandler(data => componentPropOnChange(id, IMAGE_LINK, data))}
          label="Image link"
        />
        <FormControl className={classes.wrapper}>
          <ImageSelector
            selectImage={
              // eslint-disable-next-line no-unused-vars
              ({ cmsUser, workflow, ...rest }) => componentPropOnChange(id, IMAGE, { ...rest })
            }
            onRemove={() => componentPropOnChange(id, IMAGE, null)}
            label="Add image"
            image={component[IMAGE]}
            showEditDialog
          />
        </FormControl>
        <FormControl className={classes.wrapper}>
          <TextField
            margin="dense"
            defaultValue={component[VIDEO] || ''}
            onChange={changeHandler(data => componentPropOnChange(id, VIDEO, data))}
            label="Secondary video url"
          />
        </FormControl>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

HeroPlusImageAndVideoSettings.defaultProps = {
  disabledAlignmentOption: false,
};

HeroPlusImageAndVideoSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { componentPropOnChange: componentSetProp },
)(HeroPlusImageAndVideoSettings));
