import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_VISUAL_STORY } from './defaults';

const renderElementVisualStory = (options) => {
  const { visualStory } = setDefaults(options, DEFAULTS_VISUAL_STORY);
  return getRenderElement(visualStory);
};

export default renderElementVisualStory;
