import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const FlowPaper = ({ children, ...rest }) =>
  (<Paper square {...rest}>{children}</Paper>);

FlowPaper.propTypes = {
  children: PropTypes.node,
};

FlowPaper.defaultProps = {
  children: null,
};

export default FlowPaper;
