import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import { componentSetProp } from 'actions/layout';
import Select from 'components/ui/Select';

const styles = () => ({
  select: {
    width: 'auto',
    marginTop: '1px',
    marginBottom: '-1px',
    fontSize: '0.9rem',
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
});

export const SOURCE = 'source';

export const SOURCE_AUTO = 'auto';
export const SOURCE_MANUAL = 'manual';

const VisualStoriesSource = ({
  component, classes,
  componentSetProp: setProp,
}) => (
  <Select
    value={component[SOURCE] || SOURCE_AUTO}
    onChange={e => setProp(component.id, SOURCE, e.target.value)}
    className={classes.select}
    items={{ [SOURCE_AUTO]: 'Auto', [SOURCE_MANUAL]: 'Manual' }}
  />
);

VisualStoriesSource.propTypes = {
  component: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { componentSetProp },
)(VisualStoriesSource));
