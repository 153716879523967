import { decorateSearchReplace } from './decorateSearchReplace';

/**
 * Simulates the search replace decorator function on a single node iterating over children
 * @param   {Node} node       Slate node to check
 * @param   {array} path      path of the node
 * @param   {string} search   value to search for
 * @returns {array}           array of ranges that match
 */
const getHighlightRangesFromNode = (node, path, search) => {
  const ranges = [];
  if (node.children && node.children.length > 0) {
    node.children.forEach((child, index) =>
      ranges.push(...getHighlightRangesFromNode(child, [...path, index], search)),
    );
  }
  const getRanges = decorateSearchReplace({ search });
  ranges.push(...getRanges([node, path]));
  return ranges;
};

/**
 * Simulates the decorator function to get the ranges from a list of nodes,
 * this is required as decorator data is not stored on the Editor.
 * @param   {array} nodes     array of Slate nodes to check
 * @param   {string} search   value to search for
 * @returns {array}           array of ranges that match
 */
export const getHighlightRanges = (nodes, search) => {
  const ranges = [];
  nodes.forEach((node, index) =>
    ranges.push(...getHighlightRangesFromNode(node, [index], search)),
  );
  return ranges;
};
