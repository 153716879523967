import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import Scheduler from '../../ui/components/Scheduler';

import { setLiveblogProperty } from '../../../actions/liveblog';
import TextButton from '../../ui/buttons/TextButton';

const SchedulerControl = ({
  scheduleDateTime, publish, setLiveblogProperty: setProperty, saveDate,
}) => {
  const defaultScheduleTime = moment().add(2, 'hours').unix();
  return (<MuiPickersUtilsProvider utils={MomentUtils}>
    <FormControl component="fieldset">
      <FormLabel component="legend">Schedule blog post</FormLabel>
      {publish && <Scheduler
        onChange={(timestamp) => { setProperty(['scheduleDateTime', timestamp]); }}
        dateTime={scheduleDateTime}
      />
      }
    </FormControl>
    <Grid container justify={'center'}>
      <TextButton
        size={'large'}
        onClick={() => {
          if (scheduleDateTime === 0) {
            setProperty(['scheduleDateTime', defaultScheduleTime]);
          }
          saveDate();
        }}
      >Schedule post</TextButton>
    </Grid>
  </MuiPickersUtilsProvider>);
};

SchedulerControl.propTypes = {
  scheduleDateTime: PropTypes.number.isRequired,
  publish: PropTypes.bool,
  setLiveblogProperty: PropTypes.func.isRequired,
  saveDate: PropTypes.func.isRequired,
};

SchedulerControl.defaultProps = {
  publish: true,
};

export default connect(
  ({
    liveblog: { scheduleDateTime },
  }) => ({ scheduleDateTime }),
  { setLiveblogProperty },
)(SchedulerControl);
