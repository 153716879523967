import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AccordionBase from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    borderLeft: '0 none',
    borderRight: '0 none',
    borderBottom: '0 none',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
  },
  summary: {
    margin: 0,
    minHeight: 48,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    '&.Mui-expanded': {
      margin: 0,
      minHeight: 48,
    },
    '& .MuiAccordionSummary-content': {
      margin: theme.spacing(2, 0),
      '&.Mui-expanded': {
        margin: theme.spacing(2, 0),
      },
    },
  },
});

const Accordion = (props) => {
  const { classes, title, variant, children, ...rest } = props;
  return (
    <AccordionBase
      className={classes.root}
      variant={variant}
      {...rest}
    >
      {title && <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant={'h3'}>{title}</Typography>
      </AccordionSummary>}
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </AccordionBase>
  );
};

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  variant: PropTypes.string,
};

Accordion.defaultProps = {
  title: '',
  variant: 'outlined',
};

export default withStyles(styles)(Accordion);
