import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_PARAGRAPH,
  ELEMENT_UL,
  ELEMENT_OL,
  ELEMENT_TABLE,
} from '@udecode/slate-plugins';
import { ELEMENT_HR } from '../plugins/elements/horizontal-rule/defaults';
import { ELEMENT_INFOBOX } from '../plugins/elements/infobox/defaults';
import { ELEMENT_NUMBERBOX } from '../plugins/elements/numberbox/defaults';
import { ELEMENT_BLOCKQUOTE } from '../plugins/elements/blockquote/defaults';
import { ELEMENT_VIDEO } from '../plugins/elements/media/video/defaults';
import { ELEMENT_IMAGE } from '../plugins/elements/media/image/defaults';
import { ELEMENT_GALLERY } from '../plugins/elements/media/gallery/defaults';
import { ELEMENT_TWITTER } from '../plugins/elements/social/twitter/defaults';
import { ELEMENT_INSTAGRAM } from '../plugins/elements/social/instagram/defaults';
import { ELEMENT_TIKTOK } from '../plugins/elements/social/tiktok/defaults';
import { ELEMENT_FACEBOOK } from '../plugins/elements/social/facebook/defaults';
import { ELEMENT_HTML } from '../plugins/elements/html/defaults';
import { ELEMENT_IFRAME } from '../plugins/elements/iframe/defaults';
import { ELEMENT_RELATED_ARTICLES } from '../plugins/elements/related-articles/defaults';
import { ELEMENT_VISUAL_STORY } from '../plugins/elements/embed/visual-story/defaults';
import { ELEMENT_BOOKING } from '../plugins/elements/embed/booking/defaults';
import { ELEMENT_ARTICLE_LINK } from '../plugins/elements/article-link/defaults';
import { ELEMENT_HIDE_AND_SHOW } from '../plugins/elements/hide-and-show/defaults';
import { ELEMENT_PARALLAX } from '../plugins/elements/parallax/defaults';
import { ELEMENT_BUTTON_LINK } from '../plugins/elements/button-link/defaults';
import { ELEMENT_PRICE_COMPARISON } from '../plugins/elements/embed/price-comparison/defaults';
import { ELEMENT_OPTA } from '../plugins/elements/embed/opta/defaults';
import { ELEMENT_VOUCHER } from '../plugins/elements/embed/voucher/defaults';
import {
  H2, H3, H4, H5, H6,
  LISTBULLETED, LISTNUMBERED,
  INFOBOX, NUMBERBOX, BLOCKQUOTE,
  TWITTER, FACEBOOK, INSTAGRAM, TIKTOK,
  MARKUP, TABLE,
  HR,
  HTML, IFRAME,
  IMAGE, GALLERY,
  VIDEO_DM, VIDEO_DUGOUT, VIDEO_JWPLAYER, VIDEO_YOUTUBE,
  RELATED, STORY,
  EMBED_BOOKING, PRICE_COMPARISON,
  ARTICLE_LINK, BUTTON_LINK,
  HIDE_SHOW, PARALLAX, OPTA, VOUCHER, FAQ,
} from '../../../constants/editor/dataTypes';
import { convertFromImage, convertToImage } from '../plugins/elements/media/image/helper';
import { convertFromGallery, convertToGallery } from '../plugins/elements/media/gallery/helper';
import { convertFromVideo, convertToVideo } from '../plugins/elements/media/video/helper';
import { convertFromInfobox, convertToInfobox } from '../plugins/elements/infobox/helper';
import { convertFromFaq, convertToFaq } from '../plugins/elements/faq/helper';

import { convertFromNumberbox, convertToNumberbox } from '../plugins/elements/numberbox/helper';
import { convertFromFacebook, convertToFacebook } from '../plugins/elements/social/facebook/helper';
import { convertFromInstagram, convertToInstagram } from '../plugins/elements/social/instagram/helper';
import { convertFromTwitter, convertToTwitter } from '../plugins/elements/social/twitter/helper';
import { convertFromTiktok, convertToTiktok } from '../plugins/elements/social/tiktok/helper';
import { convertFromHR, convertToHR } from '../plugins/elements/horizontal-rule/helper';
import { convertFromHtml, convertToHtml } from '../plugins/elements/html/helper';
import { convertFromIframe, convertToIframe } from '../plugins/elements/iframe/helper';
import { convertFromRelatedArticles, convertToRelatedArticles } from '../plugins/elements/related-articles/helper';
import { convertFromVisualStory, convertToVisualStory } from '../plugins/elements/embed/visual-story/helper';
import { convertFromBooking, convertToBooking } from '../plugins/elements/embed/booking/helper';
import { convertFromBlockquote, convertToBlockquote } from '../plugins/elements/blockquote/helper';
import { convertFromArticleLink, convertToArticleLink } from '../plugins/elements/article-link/helper';
import { convertFromHideAndShow, convertToHideAndShow } from '../plugins/elements/hide-and-show/helper';
import { convertFromParallax, convertToParallax } from '../plugins/elements/parallax/helper';
import { convertFromButtonLink, convertToButtonLink } from '../plugins/elements/button-link/helper';
import { convertFromPriceComparison, convertToPriceComparison } from '../plugins/elements/embed/price-comparison/helper';
import { convertFromOpta, convertToOpta } from '../plugins/elements/embed/opta/helper';
import { convertFromVoucher, convertToVoucher } from '../plugins/elements/embed/voucher/helper';

import { deserializeString, serializeNode } from './serializer';
import { ELEMENT_FAQ } from '../plugins/elements/faq/defaults';
import { ELEMENT_MONEY } from '../plugins/elements/embed/money/defaults';
import { convertFromMoney, convertToMoney } from '../plugins/elements/embed/money/helper';

const TYPE_MAPPING = {
  [ELEMENT_H1]: H2,
  [ELEMENT_H2]: H2,
  [ELEMENT_H3]: H3,
  [ELEMENT_H4]: H4,
  [ELEMENT_H5]: H5,
  [ELEMENT_H6]: H6,
  [ELEMENT_PARAGRAPH]: MARKUP,
  [ELEMENT_UL]: LISTBULLETED,
  [ELEMENT_OL]: LISTNUMBERED,
  [ELEMENT_TABLE]: TABLE,
};

/**
 * Converts from Flow JSON to Slate JSON format
 * @param components array
 * @returns array
 */
export const deserialize = (components) => {
  const blocks = [];
  if (!Array.isArray(components)) return blocks;
  components.forEach(({ type, data = {} }) => {
    switch (type) {
      case H2:
      case H3:
      case H4:
      case H5:
      case H6:
      case LISTBULLETED:
      case LISTNUMBERED:
      case TABLE:
      case MARKUP: {
        const deserialized = deserializeString(data.markup);
        if (Array.isArray((deserialized)) && deserialized.length > 0) {
          blocks.push(...deserialized);
        }
        break;
      }
      case PARALLAX:
        blocks.push(convertToParallax(data));
        break;
      case INFOBOX:
        blocks.push(convertToInfobox(data));
        break;
      case FAQ:
        blocks.push(convertToFaq(data));
        break;
      case NUMBERBOX:
        blocks.push(convertToNumberbox(data));
        break;
      case IMAGE:
        blocks.push(convertToImage(data));
        break;
      case GALLERY:
        blocks.push(convertToGallery(data));
        break;
      case VIDEO_DM:
      case VIDEO_DUGOUT:
      case VIDEO_YOUTUBE:
      case VIDEO_JWPLAYER:
        blocks.push(convertToVideo(type, data));
        break;
      case RELATED:
        blocks.push(convertToRelatedArticles(data));
        break;
      case STORY:
        blocks.push(convertToVisualStory(data));
        break;
      case FACEBOOK:
        blocks.push(convertToFacebook(data));
        break;
      case INSTAGRAM:
        blocks.push(convertToInstagram(data));
        break;
      case TWITTER:
        blocks.push(convertToTwitter(data));
        break;
      case TIKTOK:
        blocks.push(convertToTiktok(data));
        break;
      case IFRAME:
        blocks.push(convertToIframe(data));
        break;
      case HTML:
        blocks.push(convertToHtml(data));
        break;
      case HR:
        blocks.push(convertToHR());
        break;
      case EMBED_BOOKING:
        blocks.push(convertToBooking(data));
        break;
      case OPTA:
        blocks.push(convertToOpta(data));
        break;
      case VOUCHER:
        blocks.push(convertToVoucher(data));
        break;
      case PRICE_COMPARISON:
        blocks.push(convertToPriceComparison(data));
        break;
      case ELEMENT_MONEY:
        blocks.push(convertToMoney(data));
        break;
      case BLOCKQUOTE:
        blocks.push(convertToBlockquote(data));
        break;
      case ARTICLE_LINK:
        blocks.push(convertToArticleLink(data));
        break;
      case HIDE_SHOW:
        blocks.push(convertToHideAndShow(data));
        break;
      case BUTTON_LINK:
        blocks.push(convertToButtonLink(data));
        break;
      default:
        console.warn(`No converter is found for :${type}`);
        break;
    }
  });
  return blocks;
};

/**
 * Converts from Slate JSON into Flow JSON format
 * @param blocks array
 * @returns array
 */
export const serialize = (blocks) => {
  const components = [];
  blocks.forEach((block) => {
    switch (block.type) {
      case ELEMENT_H1:
      case ELEMENT_H2:
      case ELEMENT_H3:
      case ELEMENT_H4:
      case ELEMENT_H5:
      case ELEMENT_H6:
      case ELEMENT_PARAGRAPH:
      case ELEMENT_UL:
      case ELEMENT_OL:
      case ELEMENT_TABLE:
        components.push({
          type: TYPE_MAPPING[block.type],
          data: {
            markup: serializeNode(block),
          },
        });
        break;
      case ELEMENT_VIDEO:
        components.push(convertFromVideo(block));
        break;
      case ELEMENT_IMAGE:
        components.push(convertFromImage(block));
        break;
      case ELEMENT_GALLERY:
        components.push(convertFromGallery(block));
        break;
      case ELEMENT_FACEBOOK:
        components.push(convertFromFacebook(block));
        break;
      case ELEMENT_INSTAGRAM:
        components.push(convertFromInstagram(block));
        break;
      case ELEMENT_TWITTER:
        components.push(convertFromTwitter(block));
        break;
      case ELEMENT_TIKTOK:
        components.push(convertFromTiktok(block));
        break;
      case ELEMENT_HR:
        components.push(convertFromHR());
        break;
      case ELEMENT_PARALLAX:
        components.push(convertFromParallax(block));
        break;
      case ELEMENT_INFOBOX:
        components.push(convertFromInfobox(block));
        break;
      case ELEMENT_FAQ:
        components.push(convertFromFaq(block));
        break;
      case ELEMENT_NUMBERBOX:
        components.push(convertFromNumberbox(block));
        break;
      case ELEMENT_BLOCKQUOTE:
        components.push(convertFromBlockquote(block));
        break;
      case ELEMENT_HTML:
        components.push(convertFromHtml(block));
        break;
      case ELEMENT_IFRAME:
        components.push(convertFromIframe(block));
        break;
      case ELEMENT_RELATED_ARTICLES:
        components.push(convertFromRelatedArticles(block));
        break;
      case ELEMENT_VISUAL_STORY:
        components.push(convertFromVisualStory(block));
        break;
      case ELEMENT_BOOKING:
        components.push(convertFromBooking(block));
        break;
      case ELEMENT_OPTA:
        components.push(convertFromOpta(block));
        break;
      case ELEMENT_VOUCHER:
        components.push(convertFromVoucher(block));
        break;
      case ELEMENT_PRICE_COMPARISON:
        components.push(convertFromPriceComparison(block));
        break;
      case ELEMENT_ARTICLE_LINK:
        components.push(convertFromArticleLink(block));
        break;
      case ELEMENT_HIDE_AND_SHOW:
        components.push(convertFromHideAndShow(block));
        break;
      case ELEMENT_BUTTON_LINK:
        components.push(convertFromButtonLink(block));
        break;
      case ELEMENT_MONEY:
        components.push(convertFromMoney(block));
        break;
      default:
        console.warn(`No serializer is found for ${block.type}`);
        break;
    }
  });
  return components;
};
