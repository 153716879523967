import moment from 'moment';
import { CMS_DATE_FORMAT } from '../../constants/common';

const TIMESTAMP_END = 2147483647;

export const getScheduledStatus = status => ((status === 'scheduled') ? 1 : status);
export const getScheduledStartDate = (status, start) => ((status === 'scheduled') ? moment().unix() : start);
export const getScheduledEndDate = (status, end) => ((status === 'scheduled' && (end === '' || end < moment().unix()))
  ? TIMESTAMP_END : end);
export const getScheduledDisplayStatus = (status, publishDate) => {
  const now = moment().unix();
  if (status && publishDate > now) {
    return 'Scheduled';
  }
  return status ? 'Published' : 'Unpublished';
};
export const getScheduledDisplayDate = (status, updatedDate, publishDate) => {
  const now = moment().unix();
  if (publishDate > now) {
    return moment.unix(publishDate).format(CMS_DATE_FORMAT);
  }
  return moment.unix(updatedDate).format(CMS_DATE_FORMAT);
};
export const getPublishDisplayDate = (status, publishDate) => {
  return moment.unix(publishDate).format(CMS_DATE_FORMAT);
};
export const getIsPublished = (publishDate, unpublishDate = 0) => {
  if (!publishDate) {
    return false;
  }
  const nowTimestamp = moment().unix();
  if (unpublishDate) {
    return (publishDate < nowTimestamp && unpublishDate > nowTimestamp);
  }
  return publishDate < nowTimestamp;
};
