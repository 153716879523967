import { createSelector } from 'reselect';
import { getFromState } from '../../utils/stateHelper';

export const getFieldValue = (state, props) => getFromState(props.field, state.dataState);
export const getFieldLockValue = (state, props) => (Array.isArray(props.field)
  ? state.fieldLock[props.field.join('_')]
  : state.fieldLock[props.field]);
export const getFieldHasChanges = (state, props) => typeof getFromState(props.field, state.localState) !== 'undefined';
export const getUserId = state => state?.login?.user?.uid || null;

export const makeGetFieldValue = () => createSelector(
  [getFieldValue],
  value => value,
);

export const makeGetFieldLockValue = () => createSelector(
  [getFieldLockValue, getUserId],
  (value, uid) => (value?.user?.uid && value.user.uid !== uid ? value : null),
);

export const makeGetFieldHasChanges = () => createSelector(
  [getFieldHasChanges],
  value => value,
);
