import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '../../ui/builder/TextField';

const styles = theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center',
    width: '100%',
  },
  input: {
    fontFamily: 'Consolas,Monaco,Lucida Console,Liberation Mono,Courier New',
    fontSize: 12,
  },
});

const HTMLEmbed = ({
  classes, data, onChange, onFocus,
}) => (<FormGroup className={classes.root}>
  <TextField
    multiline
    rows={5}
    ignoreSmartQuote
    defaultValue={data.markup}
    onChange={(e) => {
      onChange(e.target.value);
    }}
    InputProps={{
      className: classes.input,
    }}
    placeholder={'Insert HTML code here'}
    onFocus={onFocus}
  />
</FormGroup>);

HTMLEmbed.defaultProps = {
  data: { markup: '' },
  bodyMarkupFocus: null,
};

HTMLEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

export default withStyles(styles)(HTMLEmbed);

