import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';

import {
  onComponentDragStart,
  onComponentDragEnd,
  handleDropComponent,
  sortComponent,
} from '../../actions/layout';

import Component from './components/Component';
import ComponentDropZone from './components/common/ComponentDropZone';
import { FIELD_COMPONENTS_ORDER } from '../../constants/layout/layoutFields';

const SortableItem = SortableElement(props => <Component {...props} />);

const ComponentContainer = ({
  items, highlighted, onDropComponent,
  localComponents, serverComponents, externalComponents,
  ...rest
}) => (
  <div>
    {items.map((componentId, index) => ([
      <ComponentDropZone handleDropComponent={onDropComponent} delta={index} key={`dropzone-${componentId}-${index}`} />,
      <SortableItem
        key={`${componentId}-${index}`}
        index={index}
        position={index}
        componentId={componentId}
        highlighted={(index === highlighted)}
        updated={localComponents.includes(componentId)}
        isNew={
          externalComponents.includes(componentId) &&
          !serverComponents.includes(componentId)
        }
        {...rest}
      />,
    ]))}
    <ComponentDropZone handleDropComponent={onDropComponent} delta={items.length} />
  </div>
);

ComponentContainer.propTypes = {
  items: PropTypes.array.isRequired,
  highlighted: PropTypes.number.isRequired,
  onDropComponent: PropTypes.func.isRequired,
  localComponents: PropTypes.array.isRequired,
  serverComponents: PropTypes.array.isRequired,
  externalComponents: PropTypes.array.isRequired,
};

const SortableList = SortableContainer(props => <ComponentContainer {...props} />);

const LayoutComponents = (props) => {
  const {
    components, disabled,
    focused, focusComponent, highlighted,
    setPreviewFocus, setPreviewHighlight,
    localComponents, serverComponents, externalComponents,
    onComponentDragStart: startDrag,
    onComponentDragEnd: endDrag,
    handleDropComponent: onDrop,
    sortComponent: sort,
  } = props;
  useEffect(() => {
    if (focused > -1) {
      const target = document.querySelectorAll('[data-type="layout-component"]')[focused];
      if (typeof target !== 'undefined') {
        let parent = target.parentNode;
        while (typeof parent !== 'undefined' && parent.getAttribute('data-type') !== 'layout-components') {
          parent = parent.parentNode;
        }
        if (typeof parent !== 'undefined') {
          parent.scrollTop = target.offsetTop;
        }
      }
      focusComponent(-1);
    }
  }, [focused]);
  return (
    <SortableList
      distance={1}
      useDragHandle
      disabled={disabled}
      items={components}
      highlighted={highlighted}
      onSortStart={() => startDrag()}
      onSortEnd={({ oldIndex, newIndex }) => {
        endDrag();
        sort(oldIndex, newIndex);
      }}
      setPreviewFocus={setPreviewFocus}
      setPreviewHighlight={setPreviewHighlight}
      onDropComponent={onDrop}
      localComponents={localComponents}
      serverComponents={serverComponents}
      externalComponents={externalComponents}
    />
  );
};

LayoutComponents.propTypes = {
  focused: PropTypes.number.isRequired,
  focusComponent: PropTypes.func.isRequired,
  highlighted: PropTypes.number.isRequired,
  onComponentDragStart: PropTypes.func.isRequired,
  onComponentDragEnd: PropTypes.func.isRequired,
  setPreviewFocus: PropTypes.func.isRequired,
  setPreviewHighlight: PropTypes.func.isRequired,
  handleDropComponent: PropTypes.func.isRequired,
  sortComponent: PropTypes.func.isRequired,
  components: PropTypes.array,
  localComponents: PropTypes.array,
  serverComponents: PropTypes.array,
  externalComponents: PropTypes.array,
  disabled: PropTypes.bool,
};

LayoutComponents.defaultProps = {
  components: [],
  localComponents: [],
  serverComponents: [],
  externalComponents: [],
  disabled: false,
};

export default connect(
  ({
    dataState: { [FIELD_COMPONENTS_ORDER]: components },
    localState: { [FIELD_COMPONENTS_ORDER]: localComponents },
    serverState: { [FIELD_COMPONENTS_ORDER]: serverComponents },
    externalState: { [FIELD_COMPONENTS_ORDER]: externalComponents },
  }) => ({
    components,
    localComponents,
    serverComponents,
    externalComponents,
    disabled: !!externalComponents,
  }),
  {
    onComponentDragStart,
    onComponentDragEnd,
    handleDropComponent,
    sortComponent,
  },
)(LayoutComponents);
