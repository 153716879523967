export const LOGOUT = 'LOGOUT';

export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST';
export const FORGOTTEN_PASSWORD_REQUEST_SUCCESS = 'FORGOTTEN_PASSWORD_REQUEST_SUCCESS';
export const FORGOTTEN_PASSWORD_REQUEST_FAILURE = 'FORGOTTEN_PASSWORD_REQUEST_FAILURE';

export const LOGIN_SET_CONTEXT = 'LOGIN_SET_CONTEXT';
export const LOGIN_RESET_CONTEXT = 'LOGIN_RESET_CONTEXT';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const LOGIN_TRANSITION_COMPLETE = 'LOGIN_TRANSITION_COMPLETE';
