import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Page from 'components/common/page/Page';
import PageContent from 'components/common/page/PageContent';
import PageToolbar from 'components/common/page/PageToolbar';
import ButtonSelect from 'components/ui/buttons/ButtonSelect';

import CommercialComponent from 'components/config/commercial';

export const ConfigPageBreadcrumb = [
  {
    title: 'Config',
    slug: 'config',
  },
];

const ConfigContent = ({ type, ...rest }) => {
  switch (type) {
    case 'commercial':
      return <CommercialComponent {...rest} />;
    default:
      return <Typography variant="body1">Config not available</Typography>;
  }
};

ConfigContent.propTypes = {
  type: PropTypes.string.isRequired,
};

const ConfigPage = ({
  match: { params: { type } },
}) => {
  const types = [];
  types.push({
    type: 'commercial',
    link: '/config/commercial',
    title: 'Commercial',
  });
  const [active, setActive] = useState(types[0]);
  useEffect(() => {
    const activeType = types.find(value => value.type === type);
    if (activeType) {
      setActive(activeType);
    } else {
      setActive(types[0]);
    }
  }, [type]);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={ConfigPageBreadcrumb}>
        <Grid container justify="center" alignItems="center">
          <Typography variant="body2">Manage</Typography><ButtonSelect active={active.title} items={types} />
        </Grid>
      </PageToolbar>}
    >
      <PageContent>
        <ConfigContent type={active.type} />
      </PageContent>
    </Page>
  );
};

export default ConfigPage;
