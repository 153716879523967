import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import TiktokIcon from '../../../../../../../assets/icons/TiktokIcon';

import { getPattern } from '../../../../../../../utils/helper/clipboard';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import TextField from '../../../../../../ui/TextField';

import { PROP_URL, TIKTOK_VALID, TIKTOK_PATTERN } from '../defaults';

const TiktokElement = (props) => {
  const { attributes, children, element, className } = props;
  const [valid, setValid] = useState(true);
  const [value, setValue] = useState('');
  const editor = useSlateStatic();
  const setUrl = (url) => {
    setValue(url);
    setValid(TIKTOK_VALID.test(url));
    const path = ReactEditor.findPath(editor, element);
    Transforms.setNodes(editor, { [PROP_URL]: url }, { at: path });
  };
  useEffect(() => {
    if (element[PROP_URL] !== value) {
      setValue(element[PROP_URL]);
    }
  }, [element[PROP_URL]]);
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Tiktok'} Icon={TiktokIcon} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin={'none'}
              placeholder={'Paste tiktok url here'}
              onClick={e => e.stopPropagation()}
              onChange={e => setUrl(e.target.value)}
              onPaste={(e) => {
                e.preventDefault();
                setUrl(getPattern(e.clipboardData, TIKTOK_PATTERN));
              }}
              value={value}
              error={!valid}
              helperText={!valid ? 'URL is invalid' : ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

TiktokElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default TiktokElement;
