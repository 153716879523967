import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import RemoveIcon from '@material-ui/icons/DeleteOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import IconButton from '../../../ui/buttons/IconButton';
import Button from '../../../ui/buttons/Button';

import {
  setLayoutContext, setLayoutPreviewMode, selectComponentManualList, componentManualListSetDefault,
  removeComponent,
} from '../../../../actions/layout';
import { CONTEXT_COMPONENT } from '../../../../constants/contexts';
import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_HIDDEN } from '../../../../constants/preview';

import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  MANUAL_LIST_COLORS,
} from '../../constants';
import {FIELD_COMPONENTS_ORDER} from "../../../../constants/layout/layoutFields";

const styles = () => ({
  root: {
    display: 'flex',
    margin: '0',
    '& .MuiIconButton-label svg': {
      width: '0.8em',
      height: '0.8em',
    },
    '& .list-index-0': {
      color: MANUAL_LIST_COLORS[0],
    },
    '& .list-index-1': {
      color: MANUAL_LIST_COLORS[1],
    },
    '& .list-index-2': {
      color: MANUAL_LIST_COLORS[2],
    },
    '& .list-index-3': {
      color: MANUAL_LIST_COLORS[3],
    },
    '& .list-index-4': {
      color: MANUAL_LIST_COLORS[4],
    },
    '& .list-index-5': {
      color: MANUAL_LIST_COLORS[5],
    },
    '& .list-index-6': {
      color: MANUAL_LIST_COLORS[6],
    },
    '& .list-index-7': {
      color: MANUAL_LIST_COLORS[7],
    },
    '& .list-index-8': {
      color: MANUAL_LIST_COLORS[8],
    },
    '& .list-index-9': {
      color: MANUAL_LIST_COLORS[9],
    },
  },
});

const ComponentUI = withStyles(styles)(({
  classes, component, disabled, manualListIndex,
  previewMode, externalOrder,
  setLayoutContext: setContextFunc,
  removeComponent: remove,
  selectComponentManualList: selectList,
  setLayoutPreviewMode: setPreviewMode,
  componentManualListSetDefault: setDefaultLink,
}) => (
  <div className={`${classes.root} component-ui`}>
    {
      typeof component[CONTENT_SOURCE] !== 'undefined' &&
      component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
      manualListIndex > -1 &&
      <Button
        size={'small'}
        className={`list-index-${manualListIndex}`}
        onClick={() => {
          setDefaultLink(component);
        }}
        startIcon={<LinkOffIcon />}
      >Unlink</Button>
    }
    {
      typeof component[CONTENT_SOURCE] !== 'undefined' &&
      component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
      <IconButton
        disabled={disabled}
        data-target={'manualList'}
        onClick={() => {
          if (previewMode === PREVIEW_MODE_DESKTOP) {
            setPreviewMode(PREVIEW_MODE_HIDDEN);
          }
          selectList(component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id, manualListIndex);
        }}
      >
        <LinkIcon data-target={'manualList'} />
      </IconButton>
    }
    <IconButton
      disabled={!!externalOrder || disabled}
      onClick={() =>
        window.confirm('Please confirm you want to remove this component') && remove(component.id)
      }
    ><RemoveIcon /></IconButton>
    <IconButton
      disabled={disabled}
      onClick={() => setContextFunc(CONTEXT_COMPONENT, component)}
    ><SettingsIcon /></IconButton>
  </div>
));

export default connect(
  ({
    layout: { previewMode },
    externalState: { [FIELD_COMPONENTS_ORDER]: externalOrder },
  }) => ({ previewMode, externalOrder }),
  {
    setLayoutContext,
    setLayoutPreviewMode,
    selectComponentManualList,
    componentManualListSetDefault,
    removeComponent,
  },
)(ComponentUI);
