import { PURGE } from 'redux-persist';
import {
  USERS_LIST_LOADED,
} from '../../constants/actionTypes';

const defaultState = {
  users: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USERS_LIST_LOADED:
      return { ...state, users: action.value};

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
