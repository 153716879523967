import React, { useEffect, useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

import TextField from '../../ui/TextField';
import TextButton from '../../ui/buttons/TextButton';
import Select from '../../ui/Select';
import { EDITION_EXPORT_FORMAT, EDITION_EXPORT_TRANSPORT } from '../../../constants/edition/edition';


const styles = theme => ({
  root: {
    margin: theme.spacing(3),
  },
});

const initialState = {
  name: '',
  server: '',
  username: '',
  password: '',
  transportMethod: Object.keys(EDITION_EXPORT_TRANSPORT)[0],
  serverDirectoryPath: '',
  format: [],
  editionTime: [],
};

function reducer(state, action) {
  return {
    ...state,
    [action[0]]: action[1],
  };
}

const FTPForm = ({ classes, onSubmit, exportSettingTimeList, data }) => {
  const [state, dispatch] = useReducer(reducer, data || initialState);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(key => dispatch([key, data[key]]));
    }
  }, [data]);
  const timeOptions = exportSettingTimeList
    .reduce((acc, { data: { id, title } }) => ({ ...acc, [id]: title }), {});
  return (<Grid container direction={'column'}>
    <Grid item className={classes.root}>
      <form
        onSubmit={(e) => {
          onSubmit(state);
          e.preventDefault();
          Object.keys(initialState).forEach(key => dispatch([key, initialState[key]]));
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              value={state.name}
              label={'Name'}
              required
              onChange={(e) => dispatch(['name', e.target.value])}
            />
            <TextField
              value={state.server}
              label={'Server'}
              required
              onChange={e => dispatch(['server', e.target.value])}
            />
            <TextField
              label={'Username'}
              value={state.username}
              required
              onChange={e => dispatch(['username', e.target.value])}
            />
            <TextField
              label={'Password'}
              value={state.password}
              required
              onChange={e => dispatch(['password', e.target.value])}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Server Directory Path'}
              value={state.serverDirectoryPath}
              onChange={e => dispatch(['serverDirectoryPath', e.target.value])}
            />
            <FormControl fullWidth>
              <Select
                value={state.transportMethod}
                items={EDITION_EXPORT_TRANSPORT}
                label={'Transport method'}
                onChange={e => dispatch(['transportMethod', e.target.value])}
              />
            </FormControl>
            <FormControl fullWidth>
              <Select
                value={state.format}
                items={EDITION_EXPORT_FORMAT}
                label={'Format'}
                required
                multiple
                onChange={e => dispatch(['format', e.target.value])}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography>Editions:</Typography>
              <Select
                value={state.editionTime.map(String)}
                items={timeOptions}
                label={'Format'}
                required
                multiple
                onChange={e => dispatch(['editionTime', e.target.value])}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify={'flex-end'}>
          <TextButton type={'submit'}>Submit</TextButton>
        </Grid>
      </form>
    </Grid>
  </Grid>);
};

FTPForm.defaultProps = {
  data: null,
};

FTPForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  exportSettingTimeList: PropTypes.array.isRequired,
  data: PropTypes.object,
};

export default withStyles(styles)(connect(
  ({ edition: { exportSettingTimeList }}) => ({exportSettingTimeList}),
  {},
)(FTPForm));

