export const SECTIONS = 'sections';
export const CHAIN = 'chain';
export const AUTHORS = 'authors';
export const EVENTS = 'events';
export const PEOPLE = 'people';
export const TEAMS = 'teams';
export const TOPICS = 'topics';
export const TAGS = 'tags';
export const ARTICLE_TYPE = 'article_type';
export const EDITION_SECTION = 'pugpig_section';
export const EDITION_TEMPLATES = 'pugpig_templates';
export const CONTENT_SOURCE = 'content_source';
export const SPONSOR_PREFIX = 'sponsor_prefix';

export const VOCAB_SINGULAR = {
  [SECTIONS]: 'section',
  [CHAIN]: 'chain',
  [AUTHORS]: 'author',
  [EVENTS]: 'event',
  [PEOPLE]: 'person',
  [TEAMS]: 'team',
  [TAGS]: 'tag',
  [TOPICS]: 'topic',
  [ARTICLE_TYPE]: 'article type',
  [EDITION_SECTION]: 'edition section',
  [EDITION_TEMPLATES]: 'edition template',
  [CONTENT_SOURCE]: 'content source',
  [SPONSOR_PREFIX]: 'sponsor prefix',
};

export const VOCAB_PLURAL = {
  [SECTIONS]: 'sections',
  [CHAIN]: 'chains',
  [AUTHORS]: 'authors',
  [EVENTS]: 'events',
  [PEOPLE]: 'people',
  [TAGS]: 'tags',
  [TEAMS]: 'teams',
  [TOPICS]: 'topics',
  [ARTICLE_TYPE]: 'article types',
  [EDITION_SECTION]: 'edition sections',
  [EDITION_TEMPLATES]: 'edition templates',
  [CONTENT_SOURCE]: 'content sources',
  [SPONSOR_PREFIX]: 'Sponsor prefixes',
};

export const VOCAB_LAYOUTS = {
  [SECTIONS]: 'layoutSection',
  [CHAIN]: 'layoutChain',
  [AUTHORS]: 'layoutAuthor',
  [EVENTS]: 'layoutEvent',
  [PEOPLE]: 'layoutPeople',
  [TEAMS]: 'layoutTeam',
  [TOPICS]: 'layoutTopic',
};

export const VOCAB_LAYOUTS_API = {
  [SECTIONS]: 'section',
  [CHAIN]: 'chain',
  [AUTHORS]: 'author',
  [EVENTS]: 'event',
  [PEOPLE]: 'people',
  [TEAMS]: 'team',
  [TOPICS]: 'topic',
};

export const VOCAB_TERM_SUGGESTION = [
  TOPICS, TAGS, AUTHORS, SECTIONS,
];
