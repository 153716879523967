import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import EditOutlined from '@material-ui/icons/EditRounded';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { DragVertical, PinOffOutline, PinOutline } from 'mdi-material-ui';


import { editLiveblogPost, liveblogPostPinUpdate, removeLiveblogPost } from '../../actions/liveblog';
import { DISPLAY_NAME_KEY, LIVEBLOG_TYPE_POST } from '../../constants/liveblog';

import FlowPaper from '../common/layout/FlowPaper';
import InstagramEmbed from './liveblogBuilder/InstagramEmbed';
import TwitterEmbed from './liveblogBuilder/TwitterEmbed';
import FacebookEmbed from './liveblogBuilder/FacebookEmbed';
import YouTubeEmbed from '../embed/video/YouTubeEmbed';
import JWPlayerEmbed from './liveblogBuilder/JWPlayerEmbed';
import VideoDugout from '../embed/video/VideoDugout';
import ArticleLinkPreview from './liveblogBuilder/ArticleLinkPreview';
import {
  VIDEO_DM,
  VIDEO_DUGOUT,
  VIDEO_JWPLAYER,
  VIDEO_YOUTUBE,
  VIDEO_YOUTUBE_LEGACY,
} from '../../constants/media/media';
import Iframe from '../embed/Iframe';
import {
  ARTICLE_LINK, BLOCKQUOTE,
  FACEBOOK,
  H2,
  H3,
  H4,
  HTML,
  IFRAME,
  IMAGE,
  INSTAGRAM, LISTBULLETED,
  LISTNUMBERED, MARKUP,
  TWITTER,
} from '../../constants/builder/builder';
import IconButton from '../ui/buttons/IconButton';
import Button from '../ui/buttons/Button';
import { isPinned } from '../../utils/helper';
import { SortableHandle } from '../ui/SortableComponents';
import VideoDailyMotion from '../embed/video/VideoDailyMotion';

const styles = theme => ({
  liveblogPaper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  liveblogPaperEmbed: {
    margin: theme.spacing(1),
  },
  headerPost: {
    flex: 1,
  },
  error: {
    color: 'red',
  },
  imgContainer: {
    textAlign: 'center',
    '&>img': {
      maxWidth: '100%',
    },
  },
  title: {
    margin: '1em 0',
  },
  markup: {
    '& blockquote': {
      overflow: 'hidden',
      padding: theme.spacing(0, 2),
      fontStyle: 'italic',
      borderLeft: '5px solid #ccc',
      margin: 0,
    },
  },
  icon: {
    color: theme.typography.body2.color,
    marginRight: 4,
  },
  iconSpacing: {
    marginLeft: theme.spacing(4),
  },
});

const LiveblogPost = ({
  classes, post, embedded, pinnable,
  liveblogPostPinUpdate: updatePin,
  removeLiveblogPost: removePost,
  editLiveblogPost: editPost,
  action: Action, // liveblog post dialog is using custom action.
}) => {
  const { data: { id, title, changed, scheduleTime, extra }, postJson } = post;
  const displayName = extra[DISPLAY_NAME_KEY] || '';

  const changedTime = parseInt(changed, 10);
  const scheduledTime = parseInt(scheduleTime, 10);

  const displayTime = moment.unix(scheduledTime > changedTime ? scheduledTime : changedTime).format('MMM Do' +
    ' Y, h:mm:ss a');

  const wrapperClasses = [classes.liveblogPaper];
  if (embedded) {
    wrapperClasses.push(classes.liveblogPaperEmbed);
  }

  const SortHandle = SortableHandle(() => (<IconButton><DragVertical /></IconButton>));

  return (
    <FlowPaper className={wrapperClasses.join(' ')}>
      <Grid container justify={'space-between'} alignItems={'center'}>
        <Grid item container className={classes.headerPost}>
          <PersonIcon className={classes.icon} />
          <Typography variant={'body2'}>{displayName}</Typography>
          <CalendarTodayOutlinedIcon className={[classes.icon, classes.iconSpacing].join(' ')} />
          <Typography variant={'body2'}>{displayTime}</Typography>
        </Grid>
        <Grid item>
          {pinnable && <Button
            variant={'text'}
            onClick={() => updatePin(id)}
            startIcon={isPinned(extra) ? <PinOffOutline /> : <PinOutline />}
          >{isPinned(extra) ? 'unpin' : 'pin'}</Button>}
          {pinnable && isPinned(extra) && <SortHandle />}
        </Grid>
      </Grid>
      <div>
        {title && <Typography className={classes.title} variant={'body1'}>{title}</Typography>}
        <Typography component={'div'} variant={'body1'}>
          {postJson && postJson.map(({ data, id, type }) => {
            switch (type) {
              case H2:
              case H3:
              case H4:
              case MARKUP:
              case BLOCKQUOTE:
              case LISTNUMBERED:
              case LISTBULLETED:
              case HTML:
                return (<div
                  key={id}
                  className={classes.markup}
                  dangerouslySetInnerHTML={{ __html: data.markup }}
                />);
              case ARTICLE_LINK:
                return <ArticleLinkPreview key={id} id={id} {...data} />;
              case IMAGE:
                return (<div key={id} className={classes.imgContainer}>
                  <img alt={data.title || ''} src={`${data.url}?width=800`} />
                </div>);
              case FACEBOOK:
                return <FacebookEmbed key={id} id={id} data={data} />;
              case INSTAGRAM:
                return <InstagramEmbed key={id} id={id} data={data} />;
              case VIDEO_DUGOUT:
                return <VideoDugout key={id} id={id} video={data} />;
              case VIDEO_DM:
                return <VideoDailyMotion key={id} id={id} video={data} />;
              case VIDEO_JWPLAYER:
                return <JWPlayerEmbed key={id} id={id} data={data} />;
              case VIDEO_YOUTUBE_LEGACY:
              case VIDEO_YOUTUBE:
                return <YouTubeEmbed key={id} id={id} data={data} />;
              case IFRAME:
                return <Iframe key={id} id={id} data={data} />;
              case TWITTER:
                return <TwitterEmbed key={id} id={id} data={data} />;
              default:
                return <div key={id} className={classes.error}>Unhandled component: {type}</div>;
            }
          })}
        </Typography>
      </div>
      <Grid container justify={'flex-end'} alignItems={'center'}>
        {Action && <Action />}
        {!Action && <>
          <Button
            onClick={() => removePost([id, LIVEBLOG_TYPE_POST])}
            startIcon={<DeleteOutlinedIcon />}
          >Delete</Button>
          <Button startIcon={<EditOutlined />} onClick={() => editPost(id)}>Edit</Button>
        </>}
      </Grid>
    </FlowPaper>
  );
};

LiveblogPost.defaultProps = {
  embedded: false,
  pinnable: true,
  action: null,
};

LiveblogPost.propTypes = {
  classes: PropTypes.object.isRequired,
  liveblogPostPinUpdate: PropTypes.func.isRequired,
  removeLiveblogPost: PropTypes.func.isRequired,
  editLiveblogPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  embedded: PropTypes.bool,
  pinnable: PropTypes.bool,
  action: PropTypes.func,
};

export default withStyles(styles)(connect(
  () => ({}),
  {
    liveblogPostPinUpdate,
    removeLiveblogPost,
    editLiveblogPost,
  },
)(LiveblogPost));

