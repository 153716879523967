import { setDefaults } from '@udecode/slate-plugins';
import renderElementVoucher from './renderElementVoucher';
import { DEFAULTS_VOUCHER } from './defaults';

const VoucherPlugin = (options) => {
  const { voucher } = setDefaults(options, DEFAULTS_VOUCHER);
  return ({
    renderElement: renderElementVoucher(options),
    voidTypes: [voucher.type],
  });
};

export default VoucherPlugin;
