import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistStore, persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import {
  syncTranslationWithStore,
} from 'react-redux-i18n';

import storage from 'redux-persist/lib/storage';

import rootEpic from './epics';
import reducer from './reducers';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'router',
    'dialog',
    'notifications',
    'twitter',
    'liveblog',
    'article',
    'edition',
    'translation',
    'stateHistory',
    'contactUs',
  ],
};

const epicMiddleware = createEpicMiddleware();

export default function configureStore() {
  const persistedReducer = persistReducer(persistConfig, reducer(history));
  const composeEnhancer = compose(
    applyMiddleware(routerMiddleware(history), epicMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() || compose,
  );

  const store = createStore(
    persistedReducer,
    composeEnhancer,
  );

  const persistor = persistStore(store);
  syncTranslationWithStore(store);

  epicMiddleware.run((action$, stores) => rootEpic(action$, stores));


  return { store, persistor };
}
