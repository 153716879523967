import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(.5, 1, .5, 1.5),
    borderRadius: '20px',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100px',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(.5),
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px'
  },
  closeBtn: {
    color: theme.palette.primary.contrastText,
    width: '20px'
  }
});

const TagItem = ({classes, label, onClose}) =>
  (<div className={classes.root} title={label}><Typography className={classes.label}>{label}</Typography><CloseIcon className={classes.closeBtn} onClick={onClose} /></div>)

TagItem.defaultProps = {
};

TagItem.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(style)(TagItem);
