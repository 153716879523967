export const getWordCount = (blocks) => {
  let count = 0;
  blocks.forEach((block) => {
    if (block.text && block.text.trim()) {
      count += block.text.trim().split(' ').length;
    }
    if (Array.isArray(block.children) && block.children.length > 0) {
      count += getWordCount(block.children);
    }
  });
  return count;
};
