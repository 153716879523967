import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { requestForgottenPassword, resetLoginContext } from 'actions/login';

import TextField from 'components/ui/TextField';
import Button from 'components/ui/buttons/Button';
import LoginForm from './common/LoginForm';
import LoginMessage from './common/LoginMessage';


const LoginPassword = () => {
  const { failureMessage, successMessage } = useSelector((state) => state.login);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(requestForgottenPassword(email));
  };
  const handleContextReset = () => dispatch(resetLoginContext());
  const handleChange = e => setEmail(e.target.value);
  return (
    <LoginForm onSubmit={handleSubmit}>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <TextField
            id="email"
            margin="none"
            label="Email"
            name="email"
            autoComplete="off"
            value={email}
            onChange={handleChange}
          />
        </Grid>
        {(failureMessage || successMessage) && <Grid item xs={12}>
          {failureMessage && <LoginMessage color="error">{failureMessage}</LoginMessage>}
          {successMessage && <LoginMessage>{successMessage}</LoginMessage>}
        </Grid>}
        <Grid item>
          <Button textOnly noTransform onClick={handleContextReset}>
            Return to login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
          >
            Send reset
          </Button>
        </Grid>
      </Grid>
    </LoginForm>
  );
};

export default LoginPassword;
