import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import {initFBEmbed} from '../../../actions/liveblog'

const styles = (theme) => ({
  root: {
  },
});


const FacebookEmbed = ({classes, data, initFBEmbed}) => {
  useEffect(() => {
    initFBEmbed();
  }, [data.url]);
  return (<Grid container justify={'center'}>
    <div className="fb-post" data-href={data.url} data-width="500" /></Grid>)
};

FacebookEmbed.defaultProps = {
  data: null,
  bodyMarkupFocus: null,
};

FacebookEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  initFBEmbed: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(() => ({}), {initFBEmbed})(FacebookEmbed));

