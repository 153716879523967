import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_TWITTER } from './defaults';

const deserializeTwitter = (options) => {
  const { twitter } = setDefaults(options, DEFAULTS_TWITTER);

  return {
    element: getNodeDeserializer({
      type: twitter.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        const isConversation = el.getAttribute('data-slate-checked');
        return {
          type: twitter.type,
          url,
          isConversation,
        };
      },
      rules: [
        { className: twitter.rootProps.className },
      ],
      ...options?.twitter?.deserialize,
    }),
  };
};

export default deserializeTwitter;
