import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Dialog from './common/Dialog';

const chars = [
  '!', '&quot;', '#', '$', '%', '&amp;', "'", '(', ')', '*', '+', '-', '.', '/',
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':', ';',
  '&lt;', '=', '&gt;', '?', '@',
  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
  '[', ']', '^', '_', '`',
  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p',
  'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
  '{', '|', '}', '~',
  '&euro;', '&lsquo;', '&rsquo;', '&ldquo;', '&rdquo;', '&ndash;', '&mdash;', '&iexcl;', '&cent;', '&pound;',
  '&curren;', '&yen;', '&brvbar;', '&sect;', '&uml;', '&copy;', '&ordf;', '&laquo;', '&not;', '&reg;', '&macr;',
  '&deg;', '&sup2;', '&sup3;', '&acute;', '&micro;', '&para;', '&middot;', '&cedil;', '&sup1;', '&ordm;', '&raquo;',
  '&frac14;', '&frac12;', '&frac34;', '&iquest;', '&Agrave;', '&Aacute;', '&Acirc;', '&Atilde;', '&Auml;', '&Aring;',
  '&AElig;', '&Ccedil;', '&Egrave;', '&Eacute;', '&Ecirc;', '&Euml;', '&Igrave;', '&Iacute;', '&Icirc;', '&Iuml;',
  '&ETH;', '&Ntilde;', '&Ograve;', '&Oacute;', '&Ocirc;', '&Otilde;', '&Ouml;', '&times;', '&Oslash;', '&Ugrave;',
  '&Uacute;', '&Ucirc;', '&Uuml;', '&Yacute;', '&THORN;', '&szlig;', '&agrave;', '&aacute;', '&acirc;', '&atilde;',
  '&auml;', '&aring;', '&aelig;', '&ccedil;', '&egrave;', '&eacute;', '&ecirc;', '&euml;', '&igrave;', '&iacute;',
  '&icirc;', '&iuml;', '&eth;', '&ntilde;', '&ograve;', '&oacute;', '&ocirc;', '&otilde;', '&ouml;', '&divide;',
  '&oslash;', '&ugrave;', '&uacute;', '&ucirc;', '&uuml;', '&yacute;', '&thorn;', '&yuml;', '&OElig;', '&oelig;',
  '&#372;', '&#374', '&#373', '&#375;', '&sbquo;', '&#8219;', '&bdquo;', '&hellip;', '&trade;', '&#9658;', '&bull;',
  '&rarr;', '&rArr;', '&hArr;', '&diams;', '&asymp;', '\u2605', '\u2606',
];

const style = theme => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
    minHeight: '80vh',
    flexWrap: 'wrap',
  },
  item: {
    padding: theme.spacing(0.5),
    cursor: 'pointer',
  },
});


function toUnicode(theString) {
  let unicodeString = '';
  for (let i = 0; i < theString.length; i++) {
    let theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = `0${theUnicode}`;
    }
    unicodeString += theUnicode;
  }
  return String.fromCodePoint(parseInt(unicodeString, 16));
}

const Character = (props) => {
  const { char, itemCls, onClick } = props;
  const span$ = useRef(null);
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  return (<span
    ref={span$}
    role={'button'}
    onClick={() => {
      onClick(toUnicode(span$.current.innerHTML));
    }}
    tabIndex="0"
    className={itemCls}
    dangerouslySetInnerHTML={{ __html: char }}
  />);
};

const SpecialCharactersDialog = (props) => {
  const { classes, onSelect, ...rest } = props;

  return (<Dialog title={'Insert special character'} maxWidth={'xs'} {...rest}>
    <div className={classes.root}>
      {chars.map((char, i) =>
        (<Character
          key={`item${i}`}
          char={char}
          itemCls={classes.item}
          onClick={onSelect}
        />),
      )}
    </div>
  </Dialog>);
};

SpecialCharactersDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

SpecialCharactersDialog.propTypes = {
};

export default withStyles(style)(connect(
  () => ({ }),
  { },
)(SpecialCharactersDialog));
