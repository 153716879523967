import moment from 'moment';

const Duration = ({ duration: durationInSecond }) => {
  const duration = moment.duration(parseInt(durationInSecond, 10), 'seconds');
  const inUtc = moment.utc(duration.as('milliseconds'));
  switch (true) {
    case durationInSecond < 60:
      return `${moment.utc(duration.as('milliseconds')).format('s')} seconds`;
    case durationInSecond >= 60 || durationInSecond < 3600:
      return `${inUtc.format('m')}m ${inUtc.format('s')}s`;
    default:
      return `${inUtc.format('h')}h ${inUtc.format('m')}m ${inUtc.format('s')}s`;
  }
};

export default Duration;
