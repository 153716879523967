import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { curry } from 'rambdax';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import Grid from '@material-ui/core/Grid';
import ParallaxIcon from '@material-ui/icons/ArtTrack';

import EmbedWrapper from '../../../common/EmbedWrapper';
import MarkupField from '../../../../../ui/MarkupField';

import { PROP_MARKUP, PROP_IMAGE } from '../defaults';
import ImageSelector from '../../../../../ui/media/ImageSelector';
import { initialParallaxItem } from '../helper';
import Button from '../../../../../ui/buttons/Button';
import AvatarNumbered from '../../../../../ui/AvatarNumbered';
import IconButton from '../../../../../ui/buttons/IconButton';

const ParallaxElement = (props) => {
  const { attributes, children, element, className } = props;
  const [items, setItem] = useState(element.items || [initialParallaxItem()]);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const setParallaxProperty = curry((id, key, value) => {
    const itemsWithImage = items.map((parallax) => {
      if (parallax.id === id) {
        return {
          ...parallax,
          [key]: value,
        };
      }
      return parallax;
    });
    setItem(itemsWithImage);
    Transforms.setNodes(editor, { items: itemsWithImage }, { at: path });
  });
  console.log({ element })
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Parallax'}
        Icon={ParallaxIcon}
        contentEditable={false}
        element={element}
      >
        <Grid spacing={2}>
          {items.map((item, index) => (<Grid
            key={item.id}
            item
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Grid container justify={'space-between'} alignItems={'center'}>
                <AvatarNumbered>{index + 1}</AvatarNumbered>
                {items.length > 1 && <IconButton
                  onClick={() => {
                    if (window.confirm(`Are you sure you want to remove parallax no ${index + 1}`)) {
                      setItem(items.filter(({ id }) => id !== item.id));
                    }
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ImageSelector
                label={'Drag & Drop image or browse'}
                selectImage={setParallaxProperty(item.id, PROP_IMAGE)}
                onClose={() => {
                  ReactEditor.focus(editor);
                  Transforms.setSelection(editor, path);
                }}
                onRemove={setParallaxProperty(item.id, PROP_IMAGE)}
                image={item[PROP_IMAGE]}
                showEditDialog
              />
            </Grid>
            <Grid item xs={12}>
              <MarkupField
                label={'Content'}
                data={item[PROP_MARKUP]}
                onChange={setParallaxProperty(item.id, PROP_MARKUP)}
                nested
              />
            </Grid>
          </Grid>))}
          <Grid item xs={12}>
            <Grid spacing={2} container justify={'flex-end'}>
              <Grid item>
                <Button
                  variant={'outlined'}
                  onClick={() => setItem([...items, initialParallaxItem()])}
                  startIcon={<AddIcon />}
                >Add another item</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

ParallaxElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default ParallaxElement;
