import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TagItem from '../../../common/TagItem';
import {
  removeUser, addUser, searchUser,
} from '../../../../actions/twitter';
import AutoCompleteUser from './TwitterAutoCompleteUser';

const style = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputAddMoreTagRoot: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginBottom: '20px',
  },
  inputAddMoreTag: {
    width: '100%',
    '& input': {
      paddingRight: '80px',
    },
  },
});

const TwitterExplorerAddUser = ({
  classes,
  users,
  userSuggestion,
  addUser: onAddUser,
  removeUser: onRemoveUser,
  searchUser: onSearchUser,
}) => {
  return (<div className={classes.root}>
    <div className={classes.inputAddMoreTagRoot}>
      <AutoCompleteUser
        users={userSuggestion}
        onChange={onSearchUser}
        onSelect={(selected) => {
          if (selected) {
            const foundDupe = users
              .some(user => user.toLowerCase() === selected.screenName.toLowerCase());
            if (!foundDupe) {
              onAddUser(selected.screenName);
            }
          }
        }}
      />
    </div>
    <div className={classes.tags}>{
      users.map(item => (<TagItem
        key={item}
        label={item}
        onClose={() => onRemoveUser(item)}
      />))}
    </div>
  </div>);
};

TwitterExplorerAddUser.defaultProps = {
};

TwitterExplorerAddUser.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  userSuggestion: PropTypes.array.isRequired,
  addUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(({
  twitter: { users, userSuggestion },
}) => ({
  users, userSuggestion,
}),
{
  addUser,
  removeUser,
  searchUser,
})(TwitterExplorerAddUser));
