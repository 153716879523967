import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../ui/TextField';
import ToggleSwitch from '../../../../ui/ToggleSwitch';

const FIELD_TITLE = 'title';
const FIELD_INCLUDE_LOGO = 'includeLogo';

const Title = (props) => {
  const {
    component: { title, includeLogo },
    setProperty, delta,
  } = props;
  return (
    <>
      <TextField
        label={'Title'}
        value={title || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_TITLE,
          value: event.target.value,
        })}
      />
      <ToggleSwitch
        label={'Include Logo'}
        value={includeLogo || false}
        onChange={event => setProperty({
          delta,
          prop: FIELD_INCLUDE_LOGO,
          value: event.target.checked,
        })}
      />
    </>
  );
};

Title.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default Title;
