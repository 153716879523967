import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Typography from '@material-ui/core/Typography';

import { Table, TableRow, TableCell, TableHead, TableBody, TableHeadCell } from '../../ui/table/Table';
import IconButton from '../../ui/buttons/IconButton';
import { deleteFTPEntry, saveExportFTP, fetchExportSettingFTPList } from '../../../actions/edition';
import FTPForm from './FTPForm';
import { EDITION_EXPORT_FORMAT } from '../../../constants/edition/edition';

const styles = {
  root: {

  },
};

const ListTime = ({
  classes, list, deleteFTPEntry: onDelete, saveExportFTP: onSave, fetchExportSettingFTPList: onFetch,
}) => {
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    onFetch();
  }, []);
  return (<Grid container>
    <Table>
      <TableHead>
        <TableRow>
          { [
            'Name', 'Server', 'Format', 'Edition Time', 'Action',
          ]
            .map(label => <TableHeadCell key={label}>{ label }</TableHeadCell>) }
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(list) && list.map(({ data: {
          title, id, server, formats, editions, user, password, methods, serverPath,
        }}) => (<>
          <TableRow key={id}>
            <TableCell>{ title }</TableCell>
            <TableCell>{ server }</TableCell>
            <TableCell>{ formats.map(key => <Typography
              key={`${id}-format-${key}`}
              variant={'body2'}
              component={'div'}
            >{ EDITION_EXPORT_FORMAT[key] }</Typography>) }</TableCell>
            <TableCell>{ editions.map(({ data: { title: editionTitle } }, index) => <Typography
              key={`${id}-title-${index}`}
              variant={'body2'}
              component={'div'}
            >{ editionTitle }</Typography>) }</TableCell>
            <TableCell align={'right'}>
              <IconButton onClick={() => setEditId(id)}><EditOutlined /></IconButton>
              <IconButton
                onClick={() => {
                  window.confirm('Please confirm you want to remove this item')
                  && onDelete(id);
                }}
              ><DeleteOutlined /></IconButton>
            </TableCell>
          </TableRow>
          {editId === id && <TableRow><TableCell colSpan={7}>
            <FTPForm
              data={{
                id,
                name: title,
                server,
                username: user,
                password,
                transportMethod: [methods],
                serverDirectoryPath: serverPath,
                format: formats,
                editionTime: editions.map(({ data: { id } }) => id),
              }}
              onSubmit={(state) => {
                onSave(state);
                setEditId(null);
              }}
            />
          </TableCell></TableRow>}
        </>))}
      </TableBody>
    </Table>
  </Grid>);
};

ListTime.defaultProps = {
};

ListTime.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  deleteFTPEntry: PropTypes.func.isRequired,
  saveExportFTP: PropTypes.func.isRequired,
  fetchExportSettingFTPList: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  ({ edition: { exportSettingFTPList: list } }) => ({ list }),
  { deleteFTPEntry, saveExportFTP, fetchExportSettingFTPList },
)(ListTime));

