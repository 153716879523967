import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_INSTAGRAM } from './defaults';

const deserializeInstagram = (options) => {
  const { instagram } = setDefaults(options, DEFAULTS_INSTAGRAM);

  return {
    element: getNodeDeserializer({
      type: instagram.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: instagram.type,
          url,
        };
      },
      rules: [
        { className: instagram.rootProps.className },
      ],
      ...options?.instagram?.deserialize,
    }),
  };
};

export default deserializeInstagram;
