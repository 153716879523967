import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_HIDE_AND_SHOW } from './defaults';

const renderElementHideAndShow = (options) => {
  const { hideAndShow } = setDefaults(options, DEFAULTS_HIDE_AND_SHOW);
  return getRenderElement(hideAndShow);
};

export default renderElementHideAndShow;
