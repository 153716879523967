import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_INFOBOX, PROP_MARKUP, PROP_TITLE, PROP_SUBHEADING, PROP_ALIGN } from './defaults';
import { INFOBOX } from '../../../../../constants/editor/dataTypes';

export const convertToInfobox = data => ({
  type: ELEMENT_INFOBOX,
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  [PROP_TITLE]: data[PROP_TITLE] || '',
  [PROP_SUBHEADING]: data[PROP_SUBHEADING] || '',
  [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  children: [{ text: '' }],
});

export const convertFromInfobox = data => ({
  type: INFOBOX,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP] ? serializeNodes(data[PROP_MARKUP]) : null,
    [PROP_TITLE]: data[PROP_TITLE] || '',
    [PROP_SUBHEADING]: data[PROP_SUBHEADING] || '',
    [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  },
});
