import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import MediaRatio from '../../embed/MediaRatio';

const styles = theme => ({
  notFoundRoot: {
    maxWidth: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
  },
  previewRoot: {
    maxWidth: 400,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.border.secondary.main}`,
  },
});

const ArticleLinkPreview = withStyles(styles)(({ image, classes, title, url }) => {
  if (!url) return null;

  if (!title && !image) {
    return (<div className={classes.notFoundRoot}>
      <Typography align={'center'} component={'p'}>No OG image and OG title are found</Typography>
    </div>);
  }
  return (<div className={classes.previewRoot}>
    <MediaRatio width={400}>
      {image && image.url && <img alt={title || ''} src={image.url} />}
    </MediaRatio>
    {title && <a href={url} target="_blank" rel="noreferrer"><Typography component={'h4'}>{title}</Typography></a>}
  </div>);
});

export default ArticleLinkPreview;
