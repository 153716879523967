import {
  USER_COMMENTS_FETCH,
  USER_COMMENTS_FETCH_SUCCESS,
  USER_COMMENTS_FETCH_REJECTED,
  USER_COMMENTS_DISPOSE,
  USER_COMMENTS_SET_FILTER,
} from '../../constants/actionTypes/service/userComments';

import fetchable, { defaultState as fetchableState } from '../abstract/fetchable';

const defaultState = {
  ...fetchableState,
  filter: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_COMMENTS_SET_FILTER:
      return {
        ...state,
        filter: action.value,
      };
    default:
      return fetchable(
        USER_COMMENTS_FETCH,
        USER_COMMENTS_FETCH_SUCCESS,
        USER_COMMENTS_FETCH_REJECTED,
        USER_COMMENTS_DISPOSE,
      )(state, action);
  }
};

