import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { setLoginContext, submitLogin } from 'actions/login';

import { LOGIN_CONTEXT_PASSWORD } from 'constants/login';

import TextField from 'components/ui/TextField';
import Button from 'components/ui/buttons/Button';
import LoginForm from './common/LoginForm';
import LoginMessage from './common/LoginMessage';
import Grid from '@material-ui/core/Grid';

const defaultState = {
  email: '',
  password: '',
};

const LoginContent = () => {
  const { failureMessage, successMessage } = useSelector((state) => state.login);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState(defaultState);
  const dispatch = useDispatch();
  const handleToggleShowPassword = () => setShowPassword(!showPassword);
  const handleSetContext = () => dispatch(setLoginContext(LOGIN_CONTEXT_PASSWORD));
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(submitLogin(state));
  };
  const handleChange = prop => e => setState({
    ...state,
    [prop]: e.target.value,
  });
  return (
    <LoginForm
      onSubmit={handleSubmit}
    >
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <TextField
            id="username"
            margin="none"
            name="username"
            label="Username"
            value={state.username}
            onChange={handleChange('username')}
            autoComplete="username"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="password"
            margin="none"
            name="password"
            label="Password"
            onChange={handleChange('password')}
            type={showPassword ? 'text' : 'password'}
            value={state.password}
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {(failureMessage || successMessage) && <Grid item xs={12}>
          {failureMessage && <LoginMessage color="error">{failureMessage}</LoginMessage>}
          {successMessage && <LoginMessage>{successMessage}</LoginMessage>}
        </Grid>}
        <Grid item>
          <Button textOnly noTransform onClick={handleSetContext}>
            Forgotten password?
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </LoginForm>
  );
};

export default LoginContent;
