import { PURGE } from 'redux-persist';
import {
  ARTICLE_LIST_DRAFT_LOADED,
  ARTICLE_LIST_LOADED,
  ARTICLE_LIST_PUBLISHED_LOADED,
  ARTICLE_LIST_REJECTED,
  ARTICLE_LIST_REVIEW_LOADED,
  ARTICLE_PREVIEW_REQUEST,
  ARTICLE_PREVIEW_SUCCESS,
  RESET_PREVIEW_ID,
  SET_PREVIEW_MODE,
} from '../../constants/actionTypes';

import {
  REVISION_OFFLINE_DELETED,
  REVISION_ON_SEARCH_REJECTED,
  REVISION_PUBLISHED, UPDATE_OFFLINE_REVISION,
  UPDATE_OFFLINE_REVISION_REJECTED, UPDATE_OFFLINE_REVISION_SUCCESS,
} from '../../constants/revision';

import { PREVIEW_MODE_DESKTOP } from '../../constants/preview';

import {
  PAGE_ENTER_ARTICLE_CREATE,
  PAGE_ENTER_ARTICLE_EDIT,
} from '../../constants/actionTypes/route';
import {
  ARTICLE_EDIT_DISPOSE,
  ARTICLE_LIST_FETCH,
  ARTICLE_REVISION_REJECTED,
  ARTICLE_REVISION_REVERT,
  ARTICLE_REVISION_REVERT_SUCCESS,
  ARTICLE_REVISION_SAVE,
  ARTICLE_REVISION_SET_STATUS,
  ARTICLE_SET_ACTIVE_EDITED_REVISION,
  ARTICLE_SET_TRANSLATION_SOURCE_ALIAS,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_REVISION_OFFLINE_SAVED,
  ARTICLE_SAVE,
  ARTICLE_UNPUBLISH_SUCCESS, ARTICLE_SAVE_SUCCESS, ARTICLE_SAVE_REJECTED,
} from '../../constants/actionTypes/article';

export const defaultState = {
  articles: [],
  totalArticle: 0,
  articlesDraft: [],
  totalArticleDraft: 0,
  articlesReview: [],
  totalArticleReview: 0,
  articlesPublished: [],
  totalArticlePublished: 0,
  originalUrl: null,
  previewMode: PREVIEW_MODE_DESKTOP,
  previewId: null,
  isArticleFetching: false,
  revisionStatus: REVISION_PUBLISHED,
  isBusy: false,
  editedRevisionId: null,
  internalTranslatedSourceURL: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case ARTICLE_SET_TRANSLATION_SOURCE_ALIAS: {
      return {
        ...state,
        internalTranslatedSourceURL: action.value,
      };
    }

    case ARTICLE_LIST_FETCH:
      return {
        ...state,
        isArticleFetching: true,
      };

    case ARTICLE_LIST_LOADED:
      return { ...state,
        articles: action.value.items,
        totalArticle: parseInt(action.value.total, 10),
        isArticleFetching: false,
      };

    case ARTICLE_LIST_REJECTED:
      return {
        ...state,
        isArticleFetching: false,
      };

    case ARTICLE_LIST_DRAFT_LOADED:
      return { ...state,
        articlesDraft: action.value.items,
        totalArticleDraft: parseInt(action.value.total, 10),
      };

    case ARTICLE_LIST_REVIEW_LOADED:
      return { ...state,
        articlesReview: action.value.items,
        totalArticleReview: parseInt(action.value.total, 10),
      };

    case ARTICLE_LIST_PUBLISHED_LOADED:
      return { ...state,
        articlesPublished: action.value.items,
        totalArticlePublished: parseInt(action.value.total, 10),
      };

    case RESET_PREVIEW_ID:
    case ARTICLE_PREVIEW_REQUEST:
      return { ...state,
        previewId: defaultState.previewId,
      };

    case ARTICLE_PREVIEW_SUCCESS:
      return { ...state, previewId: action.value };

    case SET_PREVIEW_MODE: {
      return { ...state, previewMode: action.value };
    }

    case REVISION_OFFLINE_DELETED: {
      return {
        ...state,
        revisionStatus: defaultState.revisionStatus,
        editedRevisionId: defaultState.editedRevisionId,
      };
    }

    case ARTICLE_SET_ACTIVE_EDITED_REVISION:
      return {
        ...state,
        editedRevisionId: action.value,
      };

    case ARTICLE_REVISION_SET_STATUS:
      return {
        ...state,
        revisionStatus: action.value,
      };

    case ARTICLE_SAVE:
    case ARTICLE_REVISION_REVERT:
    case ARTICLE_REVISION_SAVE:
    case UPDATE_OFFLINE_REVISION:
      return {
        ...state,
        isBusy: true,
      };

    case ARTICLE_UNPUBLISH_SUCCESS:
    case ARTICLE_SAVE_SUCCESS:
    case ARTICLE_SAVE_REJECTED:
    case ARTICLE_REVISION_OFFLINE_SAVED:
    case ARTICLE_REVISION_REVERT_SUCCESS:
    case REVISION_ON_SEARCH_REJECTED:
    case ARTICLE_REVISION_REJECTED:
    case UPDATE_OFFLINE_REVISION_SUCCESS:
    case UPDATE_OFFLINE_REVISION_REJECTED:
      return {
        ...state,
        isBusy: false,
      };

    case PAGE_ENTER_ARTICLE_EDIT:
    case ARTICLE_DELETE_SUCCESS:
    case PAGE_ENTER_ARTICLE_CREATE:
    case ARTICLE_EDIT_DISPOSE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
