import { PURGE } from 'redux-persist';
import { arrayMove } from 'react-sortable-hoc';

import {
  MANUAL_LIST_ALL_LOADED,
  MANUAL_LIST_FETCH_LOADED,
  MANUAL_LIST_FROM_SERVER,
  MANUAL_LIST_LABEL_ADDED,


} from 'constants/actionTypes';
import { OVERRIDE_SHOW_LEAD, OVERRIDE_SHOW_RELATED_LINKS } from 'constants/layout/layout';
import {
  MANUAL_LIST_SET_ITEM_SETTINGS,
  MANUAL_LIST_ADD_ITEM,
  MANUAL_LIST_CREATE,
  MANUAL_LIST_REORDER_ITEM,
  MANUAL_LIST_REMOVE_ITEM,
  MANUAL_LIST_SET_CONTEXT,
  MANUAL_LIST_SET_ITEM_OVERRIDES,
} from 'constants/actionTypes/manualList';

const defaultState = {
  currentManualList: null,
  manualList: [],
  manualListArticles: [],
  selectedLabel: '',
  isDefault: false,
  context: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MANUAL_LIST_SET_CONTEXT:
      return { ...state,
        context: action.value,
      };

    case MANUAL_LIST_SET_ITEM_SETTINGS: {
      const { pos, data } = action.value;
      return {
        ...state,
        manualListArticles: state.manualListArticles.map((article, index) => {
          if (index === pos) {
            return {
              ...article,
              settings: {
                ...article.settings,
                ...data,
              },
            };
          }
          return article;
        }),
      };
    }

    case MANUAL_LIST_SET_ITEM_OVERRIDES: {
      const { pos, data } = action.value;
      return {
        ...state,
        manualListArticles: state.manualListArticles.map((article, index) => {
          if (index === pos) {
            return {
              ...article,
              overrides: {
                ...article.overrides,
                ...data,
              },
            };
          }
          return article;
        }),
      };
    }

    case MANUAL_LIST_FETCH_LOADED: {
      const { isDefault, articles } =
        typeof action.value.field_manual_list_json !== 'undefined' ?
          JSON.parse(action.value.field_manual_list_json[0].value) : {};
      return {
        ...state,
        currentManualList: action.value,
        manualListArticles: articles || defaultState.manualListArticles,
        isDefault,
      };
    }

    case MANUAL_LIST_FROM_SERVER:
      return {
        ...state, manualListArticles: action.value,
      };

    case MANUAL_LIST_ALL_LOADED:
      return { ...state, manualList: action.value };

    case MANUAL_LIST_REMOVE_ITEM:
      return {
        ...state,
        manualListArticles: state.manualListArticles
          // eslint-disable-next-line camelcase
          .filter(({ target_id }) => target_id !== action.value),
      };

    case MANUAL_LIST_REORDER_ITEM:
      return {
        ...state,
        manualListArticles: arrayMove(state.manualListArticles, action.value[0], action.value[1]),
      };

    case MANUAL_LIST_ADD_ITEM: {
      const listItem = {
        ...action.value,
        overrides: {
          [OVERRIDE_SHOW_LEAD]: true,
          [OVERRIDE_SHOW_RELATED_LINKS]: true,
        },
      };
      return { ...state, manualListArticles: [...state.manualListArticles, listItem] };
    }

    case MANUAL_LIST_LABEL_ADDED:
      return {
        ...state,
        selectedLabel: action.value,
        manualListArticles: defaultState.manualListArticles,
        currentManualList: defaultState.currentManualList,
      };

    case MANUAL_LIST_CREATE:
      return { ...state,
        selectedLabel: action.value.label,
        isDefault: action.value.isDefault || false,
        manualListArticles: action.value.articles || defaultState.manualListArticles,
        currentManualList: defaultState.currentManualList,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
