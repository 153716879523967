import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';

import { setLocalProp } from '../../../../actions/dataState';
import { fetchVocab } from '../../../../actions/vocab';
import { ARTICLE_TYPE } from '../../../../constants/vocab';
import { FIELD_ARTICLE_TYPE } from '../../../../constants/article/articleFields';

import Select from '../../../ui/Select';

const ArticleTypeSelector = ({
  articleType, articleTypes, vocabs, disabled,
  fetchVocab: onFetch, setLocalProp: setProp,
}) => {
  useEffect(() => {
    onFetch({
      vocab: ARTICLE_TYPE,
      q: '',
    });
  }, []);

  if (!vocabs.includes(ARTICLE_TYPE) || articleTypes?.length === 0) return null;

  return (
    <FormControl fullWidth>
      <Select
        label="Article type"
        data-testid="article-type"
        value={articleType?.id || ''}
        onChange={(e) => {
          if (e.target.value) {
            const type = articleTypes.find(({ id }) => id === e.target.value);
            if (type) {
              setProp(FIELD_ARTICLE_TYPE, type);
            }
          }
        }}
        items={articleTypes}
        disabled={disabled}
        onBackground
      />
    </FormControl>
  );
};

ArticleTypeSelector.propTypes = {
  fetchVocab: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  articleTypes: PropTypes.array,
  vocabs: PropTypes.array,
  articleType: PropTypes.object,
  disabled: PropTypes.bool,
};

ArticleTypeSelector.defaultProps = {
  articleTypes: [],
  vocabs: [],
  articleType: null,
  disabled: false,
};

export default connect(
  ({
    dataState: { [FIELD_ARTICLE_TYPE]: articleType },
    vocab: { [ARTICLE_TYPE]: { items: articleTypes } },
    frame: { selectedPublication: { vocabs } },
  }) => ({ articleType, articleTypes, vocabs }),
  { setLocalProp, fetchVocab },
)(ArticleTypeSelector);
