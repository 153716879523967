import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { createEditor, Transforms } from 'slate';
import { Slate, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import { equals } from 'rambdax';

import {
  EditablePlugins,
  ParagraphPlugin,
  ExitBreakPlugin,
  SoftBreakPlugin,
  withInlineVoid,
  withTrailingNode,
  withNodeID,
  withMarks,
  pipe,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_CODE_BLOCK,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_TD,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_LI,
  someNode,
} from '@udecode/slate-plugins';

import { deserialize, serialize } from './helper/converter';
import { paragraph } from './helper/elements';

import EditorToolbarInline, { toolbarInlinePlugins, withToolbarInlinePlugins } from './EditorToolbarInline';
import EditorToolbar from './EditorToolbar';
import ToolbarConfig, { VARIANT_DEFAULT } from './toolbar/ToolbarConfig';
import withFocus from './plugins/utils/withFocus';
import withFormatting from './plugins/utils/withFormatting';
import { getWordCount } from './helper/utils';

export { VARIANT_POST, VARIANT_DEFAULT, VARIANT_REDUCED, VARIANT_EDITION } from './toolbar/ToolbarConfig';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > [data-slate-editor="true"]': {
      fontFamily: theme.typography.fontFamily,
      padding: theme.spacing(3),
      '& a': {
        color: theme.palette.primary.main,
      },
      '& ul': {
        margin: '1em 0',
      },
      '& ol': {
        margin: '1em 0',
      },
      '& li > p': {
        margin: 0,
      },
      '& > *:first-child': {
        marginTop: 0,
      },
      '& > *:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const emptyState = [paragraph];

const Editor = ({ classes, className, content, variant, onChange, showWordCount, isProduct = false }) => {
  const [value, setValue] = useState(emptyState);
  const [conditions, setConditions] = useState([]);
  const [serialized, setSerialized] = useState(null);
  const [wordCount, setWordCount] = useState(showWordCount ? 0 : null);

  // use ToolbarConfig to get additional required plugins and editor memo 'with' functions
  const toolbarConfig = new ToolbarConfig(variant);
  const plugins = [
    ParagraphPlugin(),
    ...toolbarConfig.getPlugins(),
    ...toolbarInlinePlugins,
    SoftBreakPlugin({
      rules: [
        { hotkey: 'shift+enter' },
        {
          hotkey: 'enter',
          query: {
            allow: [
              ELEMENT_CODE_BLOCK,
              ELEMENT_BLOCKQUOTE,
              ELEMENT_TD,
            ],
          },
        },
      ],
    }),
    ExitBreakPlugin({
      rules: [
        {
          hotkey: 'mod+enter',
        },
        {
          hotkey: 'mod+shift+enter',
          before: true,
        },
        {
          hotkey: 'enter',
          query: {
            start: true,
            end: true,
            allow: [
              ELEMENT_H1,
              ELEMENT_H2,
              ELEMENT_H3,
              ELEMENT_H4,
              ELEMENT_H5,
              ELEMENT_H6,
            ],
          },
          level: 0,
        },
      ],
    }),
  ];
  const withPlugins = [
    withReact,
    withHistory,
    ...withToolbarInlinePlugins,
    withInlineVoid({ plugins }),
    withTrailingNode({
      type: ELEMENT_PARAGRAPH,
      level: 0,
      allow: [
        ELEMENT_LI,
      ],
    }),
    withNodeID(),
    withMarks(),
    withFormatting({ plugins }),
    ...toolbarConfig.getWithPlugins(),
    withFocus,
  ];
  const editorRef = useRef();
  const editor = useMemo(
    () => pipe(createEditor(), ...withPlugins), [],
  );

  // setup states for decorators
  const [search, setSearch] = useState('');
  const clearDecorators = () => {
    setSearch('');
  };
  const decorateDep = [search];
  const decorateProps = {
    search, setSearch,
  };
  const decorators = toolbarConfig.getDecorators({ search });
  // deselect onscroll
  // useEffect(() => {
  //   const editorEl = editorRef.current;
  //   const parent = parentWithScrollbar(editorEl);
  //   if (parent) {
  //     const onScroll = () => {
  //       if (
  //         editor.selection &&
  //         JSON.stringify(editor.selection.anchor) !== JSON.stringify(editor.selection.focus)
  //       ) {
  //         ReactEditor.blur(editor);
  //       }
  //     };
  //     parent.addEventListener('scroll', onScroll);
  //     return () => parent.removeEventListener('scroll', onScroll);
  //   }
  // }, [editorRef]);
  // update on redux change
  useEffect(() => {
    if (!equals(content, serialized)) {
      Transforms.deselect(editor);
      const deserialized = deserialize(content);
      setValue(deserialized.length > 0 ? deserialized : emptyState);
      setSerialized(deserialized.length > 0 ? serialize(deserialized) : serialize(emptyState));
      if (showWordCount) {
        setWordCount(deserialized.length > 0 ? getWordCount(deserialized) : 0);
      }
    }
  }, [content, showWordCount]);

  return (
    <div
      ref={editorRef}
      className={className ? `${classes.container} ${className}` : classes.container}
      id="wysiwyg"
    >
      <Slate
        editor={editor}
        value={value}
        onChange={(newValue) => {
          // set conditionals for toolbar interaction
          const newConditions = [];
          toolbarConfig.getConditionals().forEach((type) => {
            if (someNode(editor, { match: { type: ELEMENT_TABLE } })) {
              newConditions.push(type);
            }
          });
          setConditions(newConditions);
          // set value and handle change if diff
          const serializedValue = serialize(newValue);
          if (!equals(serializedValue, serialized)) {
            setSerialized(serializedValue);
            onChange(serializedValue);
            setValue(newValue.length > 0 ? newValue : emptyState);
            if (showWordCount) {
              setWordCount(newValue.length > 0 ? getWordCount(newValue) : 0);
            }
          }
        }}
      >
        <EditorToolbar
          wordCount={wordCount}
          conditions={conditions}
          variant={variant}
          decorators={decorateProps}
          clearDecorators={clearDecorators}
        />
        <EditorToolbarInline isProduct={isProduct} />
        <EditablePlugins
          plugins={plugins}
          decorate={decorators}
          decorateDeps={decorateDep}
          renderLeafDeps={decorateDep}
        />
      </Slate>
    </div>
  );
};

Editor.propTypes = {
  classes: PropTypes.object.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
  showWordCount: PropTypes.bool,
};

Editor.defaultProps = {
  className: '',
  variant: VARIANT_DEFAULT,
  showWordCount: false,
};

export default withStyles(styles)(Editor);
