import { filter, map, mergeMap, withLatestFrom, take } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { arrayMove } from 'react-sortable-hoc';
import moment from 'moment';

import {
  LAYOUT_ADD_COMPONENT,
  LAYOUT_COMPONENT_SET_PROPERTY,
  LAYOUT_DISPOSE,
  LAYOUT_FETCH,
  LAYOUT_FETCH_SUCCESS,
  LAYOUT_REMOVE_COMPONENT,
  LAYOUT_SAVE_SUCCESS,
  LAYOUT_SET_MANUAL_LIST_DEFAULT,
  LAYOUT_SET_MANUAL_LIST_LINK,
  LAYOUT_SORT_COMPONENT,
} from '../../constants/actionTypes/layout';
import {
  MANUAL_LIST_CLEAR_ITEM,
  MANUAL_LIST_CREATE, MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED, MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS,
  MANUAL_LIST_FETCH_SUCCESS,
  MANUAL_LIST_INSERT_ITEM,
  MANUAL_LIST_ITEM_SET_PROPERTY,
  MANUAL_LIST_ITEM_UNSET_PROPERTY,
  MANUAL_LIST_PIN_ITEM,
  MANUAL_LIST_PINNED_SET_PROPERTY,
  MANUAL_LIST_PINNED_UNSET_PROPERTY,
  MANUAL_LIST_REPLACE_ITEM,
  MANUAL_LIST_SAVE_SUCCESS,
  MANUAL_LIST_UNPIN_ITEM, RELATED_LINKS_CLEAR_ITEM,
  RELATED_LINKS_INSERT_ITEM, RELATED_LINKS_PINNED_CLEAR_ITEM,
  RELATED_LINKS_PINNED_INSERT_ITEM,
  RELATED_LINKS_PINNED_REPLACE_ITEM,
  RELATED_LINKS_REPLACE_ITEM,
} from '../../constants/actionTypes/manualList';
import {
  DATASTATE_EXTERNAL_DISPOSE_DATA,
  DATASTATE_LOCAL_ADD_KEYED_ITEM,
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
  DATASTATE_LOCAL_REPLACE_KEYED_ITEM,
  DATASTATE_LOCAL_SET_PROPERTY,
  DATASTATE_LOCAL_SORT_KEYED_ITEMS,
  DATASTATE_LOCAL_UNSET_PROPERTY,
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_SERVER_SET_DATA, DATASTATE_SET_DATA, DATASTATE_SET_PROPERTY,
} from '../../constants/actionTypes/dataState';
import {
  FIELD_COMPONENTS,
  FIELD_COMPONENTS_ORDER,
  FIELD_MANUAL_LISTS,
  FIELD_TERM,
  PROP_MANUAL_INDEXES,
} from '../../constants/layout/layoutFields';
import { FIELD_ARTICLES, FIELD_ARTICLES_ORDER, FIELD_UPDATED } from '../../constants/manualList/manualListFields';
import {
  FIELD_OVERRIDES,
  FIELD_PINNED, OVERRIDE_HERO,
  OVERRIDE_RELATED_LINKS,
  OVERRIDE_RELATED_LINKS_PINNED, OVERRIDE_SHOW_LEAD, OVERRIDE_SHOW_RELATED_LINKS,
  OVERRIDE_UPDATED,
} from '../../constants/layout/layout';
import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
} from '../../components/layout/constants';

import LayoutEntity, { COMPONENT_ID } from '../../entities/LayoutEntity';
import ManualListEntity, { ARTICLE_ID } from '../../entities/ManualListEntity';

import { buildComponentInstance, getComponentArticleCount, getComponentDefaultIndexes } from '../../utils/layoutHelper';
import { getFromState, getFromStates, getPropState } from '../../utils/stateHelper';
import { getListObjectFromData } from '../../utils/manualListHelper';
import { showErrorNotification } from '../helper/notification';

import { componentSetProp, setTermForLayout } from '../../actions/layout';
import { setDataStateProp, setLocalProp, setServerProp, unsetDataStateProp } from '../../actions/dataState';

export const clearServerStateOnFetch = action$ => action$.pipe(
  ofType(LAYOUT_FETCH),
  map(() => ({ type: DATASTATE_SERVER_DISPOSE_DATA })),
);

export const setServerStateOnSuccess = (action$, state$) => action$.pipe(
  ofType(LAYOUT_FETCH_SUCCESS),
  withLatestFrom(state$),
  filter(([,
    { router: { location: { pathname } } },
  ]) => /\/layout\/[a-z_]+\/([a-z0-9]+)/.test(pathname)),
  mergeMap(([
    { value },
    { router: { location: { pathname } } },
  ]) => {
    const path = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);
    const layout = new LayoutEntity();
    const payload = layout.getDataFromPayload(value);
    const actions = path[1] !== 'new'
      ? [setTermForLayout(payload[FIELD_TERM])]
      : [];
    return from([
      { type: DATASTATE_SERVER_SET_DATA, value: payload },
      ...actions,
    ]);
  }),
);

export const clearStatesOnDispose = action$ => action$.pipe(
  ofType(LAYOUT_DISPOSE),
  mergeMap(() => from([
    { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
    { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
    { type: DATASTATE_EXTERNAL_DISPOSE_DATA },
  ])),
);

export const handleStateOnSaveSuccess = action$ => action$.pipe(
  ofType(LAYOUT_SAVE_SUCCESS),
  mergeMap(({ value }) => {
    const actions = [
      { type: DATASTATE_LOCAL_DISPOSE, quiet: !!value },
    ];
    if (value) {
      const layout = new LayoutEntity();
      const payload = layout.getDataFromPayload(value);
      const { manualLists = {} } = value;
      actions.push({
        type: DATASTATE_SERVER_SET_DATA,
        value: {
          ...payload,
          [FIELD_MANUAL_LISTS]: !manualLists.error
            ? Object.entries(manualLists).reduce((acc, [id, list]) => ({
              ...acc,
              [id]: getListObjectFromData(list),
            }), {})
            : {},
        },
      });
      if (payload?.[FIELD_TERM]) {
        actions.push(setTermForLayout(payload?.[FIELD_TERM]));
      }
    }
    return from(actions);
  }),
);

export const handleStateOnFetchSuccess = action$ => action$.pipe(
  ofType(LAYOUT_FETCH_SUCCESS),
  mergeMap(({ value }) => action$.pipe(
    ofType(MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS),
    mergeMap(({ value: manualLists }) => {
      const layout = new LayoutEntity();
      const payload = layout.getDataFromPayload(value);
      const actions = [
        {
          type: DATASTATE_SERVER_SET_DATA,
          value: {
            ...payload,
            [FIELD_MANUAL_LISTS]: Object.entries(manualLists).reduce((acc, [id, list]) => ({
              ...acc,
              [id]: getListObjectFromData(list),
            }), {}),
          },
        },
      ];
      if (payload?.[FIELD_TERM]) {
        actions.push(setTermForLayout(payload?.[FIELD_TERM]));
      }
      return from(actions);
    }),
    take(1),
  )),
);

export const handleStateOnFetchRejected = action$ => action$.pipe(
  ofType(LAYOUT_FETCH_SUCCESS),
  mergeMap(({ value }) => action$.pipe(
    // to avoid content flicker we don't trigger until we get layout
    ofType(MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED),
    mergeMap(() => {
      const layout = new LayoutEntity();
      const payload = layout.getDataFromPayload(value);
      return from([
        {
          type: DATASTATE_SERVER_SET_DATA,
          value: payload,
        },
        setTermForLayout(payload?.[FIELD_TERM] || {}),
      ]);
    }),
    take(1),
  )),
);

export const handleLayoutComponentAdd = action$ => action$.pipe(
  ofType(LAYOUT_ADD_COMPONENT),
  mergeMap(({ value: { component, index } }) => {
    const componentInstance = buildComponentInstance(component);
    return of({
      type: DATASTATE_LOCAL_ADD_KEYED_ITEM,
      value: {
        prop: FIELD_COMPONENTS,
        key: componentInstance.id,
        item: componentInstance,
        orderProp: FIELD_COMPONENTS_ORDER,
        orderIndex: index,
      },
    });
  }),
);

export const handleLayoutComponentRemove = action$ => action$.pipe(
  ofType(LAYOUT_REMOVE_COMPONENT),
  mergeMap(({ value }) => of({
    type: DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
    value: {
      prop: FIELD_COMPONENTS,
      key: value,
      orderProp: FIELD_COMPONENTS_ORDER,
    },
  })),
);

export const handleLayoutComponentSort = action$ => action$.pipe(
  ofType(LAYOUT_SORT_COMPONENT),
  mergeMap(({ value: { oldIndex, newIndex } }) => of({
    type: DATASTATE_LOCAL_SORT_KEYED_ITEMS,
    value: {
      prop: FIELD_COMPONENTS_ORDER,
      oldIndex,
      newIndex,
    },
  })),
);

export const setStateOnSetProperty = action$ => action$.pipe(
  ofType(LAYOUT_COMPONENT_SET_PROPERTY),
  mergeMap(({ value: { componentId, prop, value } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_COMPONENTS, componentId],
      prop,
      value,
    },
  })),
);

export const setManualListFromServer = action$ => action$.pipe(
  ofType(MANUAL_LIST_FETCH_SUCCESS),
  mergeMap(({ value }) => {
    const manualList = new ManualListEntity();
    return of(setServerProp(
      manualList.getPayloadId(value),
      manualList.getDataFromPayload(value),
      [FIELD_MANUAL_LISTS],
    ));
  }),
);

export const setManualListOnSave = action$ => action$.pipe(
  ofType(MANUAL_LIST_SAVE_SUCCESS),
  filter(({ value: { targetComponent } }) => !!targetComponent),
  mergeMap(({ value: { response } }) => {
    const manualList = new ManualListEntity();
    return of(setServerProp(
      manualList.getPayloadId(response),
      manualList.getDataFromPayload(response),
      [FIELD_MANUAL_LISTS],
    ));
  }),
);

export const unsetManualListOnClear = action$ => action$.pipe(
  ofType(MANUAL_LIST_CLEAR_ITEM),
  mergeMap(({ value: { listId, itemId } }) => of({
    type: DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
    value: {
      propChain: [FIELD_MANUAL_LISTS, listId],
      prop: FIELD_ARTICLES,
      key: itemId,
      orderProp: FIELD_ARTICLES_ORDER,
    },
  })),
);

export const setPropertyOnManualListItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_ITEM_SET_PROPERTY),
  mergeMap(({ value: { listId, itemId, prop, value } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, listId, FIELD_ARTICLES, itemId, FIELD_OVERRIDES],
      prop,
      value,
    },
  })),
);

export const unsetPropertyOnManualListItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_ITEM_UNSET_PROPERTY),
  filter(({ value: { prop } }) => prop !== OVERRIDE_HERO),
  mergeMap(({ value: { listId, itemId, prop } }) => of({
    type: DATASTATE_LOCAL_UNSET_PROPERTY,
    value: [FIELD_MANUAL_LISTS, listId, FIELD_ARTICLES, itemId, FIELD_OVERRIDES, prop],
  })),
);

export const unsetHeroPropertyOnManualListItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_ITEM_UNSET_PROPERTY),
  filter(({ value: { prop } }) => prop === OVERRIDE_HERO),
  mergeMap(({ value: { listId, itemId, prop } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, listId, FIELD_ARTICLES, itemId, FIELD_OVERRIDES],
      prop,
      value: null,
    },
  })),
);

export const setManualListPinnedItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_PIN_ITEM),
  mergeMap(({ value: { article, componentId, index, listId } }) => from([{
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_COMPONENTS, componentId, FIELD_PINNED],
      prop: index,
      value: article,
    },
  }, {
    type: MANUAL_LIST_CLEAR_ITEM,
    value: {
      listId,
      itemId: article[ARTICLE_ID],
    },
  }])),
);

export const unsetManualListPinnedItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_UNPIN_ITEM),
  mergeMap(({ value: { article, componentId, listId, itemIndex, index } }) => from([{
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_COMPONENTS, componentId, FIELD_PINNED],
      prop: index,
      value: null,
    },
  }, {
    type: MANUAL_LIST_INSERT_ITEM,
    value: {
      listId,
      item: article,
      itemIndex,
    },
  }])),
);

export const setPropertyOnManualListPinnedItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_PINNED_SET_PROPERTY),
  mergeMap(({ value: { componentId, index, prop, value } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_COMPONENTS, componentId, FIELD_PINNED, index, FIELD_OVERRIDES],
      prop,
      value,
    },
  })),
);

export const unsetPropertyOnManualListPinnedItem = action$ => action$.pipe(
  ofType(MANUAL_LIST_PINNED_UNSET_PROPERTY),
  mergeMap(({ value: { componentId, index, prop } }) => of({
    type: DATASTATE_LOCAL_UNSET_PROPERTY,
    value: [FIELD_COMPONENTS, componentId, FIELD_PINNED, index, FIELD_OVERRIDES, prop],
  })),
);

const getActionForSourceRemoval = (source, target, localState, serverState) => {
  if (!source) return null;
  switch (source.field) {
    case OVERRIDE_RELATED_LINKS: {
      // when source and target are from the same collection removal is not required
      if (
        target.field === OVERRIDE_RELATED_LINKS &&
        target.listId === source.listId &&
        target.articleId === source.articleId
      ) {
        return null;
      }
      const propChain = [
        FIELD_MANUAL_LISTS,
        source.listId,
        FIELD_ARTICLES,
        source.articleId,
        FIELD_OVERRIDES,
      ];
      const current = getFromStates(
        [...propChain, OVERRIDE_RELATED_LINKS],
        localState,
        serverState,
      );
      return {
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: current.filter((link, index) => index !== source.index),
        },
      };
    }
    case OVERRIDE_RELATED_LINKS_PINNED: {
      if (
        target.field === OVERRIDE_RELATED_LINKS_PINNED &&
        target.itemId === source.itemId &&
        target.componentId === source.componentId
      ) {
        return null;
      }
      const propChain = [
        FIELD_COMPONENTS,
        source.componentId,
        FIELD_PINNED,
        source.itemIndex,
        FIELD_OVERRIDES,
      ];
      const current = getFromStates(
        [...propChain, OVERRIDE_RELATED_LINKS],
        localState,
        serverState,
      );
      return {
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: current.filter((link, index) => index !== source.index),
        },
      };
    }
    default: {
      // when source and target are from the same collection removal is not required
      if (!target.field && !source.field && target.listId === source.listId) {
        return null;
      }
      return {
        type: DATASTATE_LOCAL_REMOVE_KEYED_ITEM,
        value: {
          propChain: [FIELD_MANUAL_LISTS, source.listId],
          prop: FIELD_ARTICLES,
          key: source.itemId,
          orderProp: FIELD_ARTICLES_ORDER,
        },
      };
    }
  }
};

export const setManualListStateOnInsertReplace = (action$, state$) => action$.pipe(
  ofType(MANUAL_LIST_INSERT_ITEM, MANUAL_LIST_REPLACE_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { type, value: { listId, item, itemIndex, source } },
    { localState, serverState },
  ]) => {
    // @todo Add manual list update logic to API when saved
    // updated: moment().unix(),
    const actions = [];
    if (
      !source ||
      source?.field === OVERRIDE_RELATED_LINKS ||
      source?.listId !== listId
    ) {
      // Inserting from a different list, related or new
      actions.push({
        type: type === MANUAL_LIST_INSERT_ITEM
          ? DATASTATE_LOCAL_ADD_KEYED_ITEM
          : DATASTATE_LOCAL_REPLACE_KEYED_ITEM,
        value: {
          propChain: [FIELD_MANUAL_LISTS, listId],
          prop: FIELD_ARTICLES,
          key: item[ARTICLE_ID],
          item: {
            ...item,
            overrides: {
              ...item.overrides,
              [OVERRIDE_SHOW_LEAD]: item.overrides && typeof item.overrides[OVERRIDE_SHOW_LEAD] !== 'undefined'
                ? item.overrides[OVERRIDE_SHOW_LEAD] : true,
              [OVERRIDE_SHOW_RELATED_LINKS]: item.overrides && typeof item.overrides[OVERRIDE_SHOW_RELATED_LINKS] !== 'undefined'
                ? item.overrides[OVERRIDE_SHOW_RELATED_LINKS] : true,
            },
          },
          orderProp: FIELD_ARTICLES_ORDER,
          orderIndex: itemIndex,
        },
      });
    } else {
      // Inserting from the same collection is treated as sorting
      actions.push({
        type: DATASTATE_LOCAL_SORT_KEYED_ITEMS,
        value: {
          propChain: [FIELD_MANUAL_LISTS, listId],
          prop: FIELD_ARTICLES_ORDER,
          oldIndex: source.itemIndex,
          newIndex: itemIndex,
        },
      });
    }
    const removalAction = getActionForSourceRemoval(
      source,
      { listId, itemIndex, itemId: item[ARTICLE_ID] },
      localState, serverState,
    );
    if (removalAction) {
      actions.push(removalAction);
    }
    return from(actions);
  }),
);

export const setRelatedLinkOnManualListPinnedItem = (action$, state$) => action$.pipe(
  ofType(RELATED_LINKS_PINNED_INSERT_ITEM, RELATED_LINKS_PINNED_REPLACE_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { type, value: { component, itemIndex, index, item, source } },
    { localState, serverState, externalState },
  ]) => {
    const propChain = [FIELD_COMPONENTS, component.id, FIELD_PINNED, itemIndex, FIELD_OVERRIDES];
    if (getFromState([...propChain, OVERRIDE_RELATED_LINKS], externalState)) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = getFromState([...propChain, OVERRIDE_RELATED_LINKS], localState)
      || getFromState([...propChain, OVERRIDE_RELATED_LINKS], serverState) || [];
    const actions = [];
    if (
      !source ||
      source?.field !== OVERRIDE_RELATED_LINKS_PINNED ||
      source?.componentId !== component.id ||
      source?.itemIndex !== itemIndex
    ) {
      actions.push({
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: [
            ...current.slice(0, index),
            item,
            ...current.slice(type === RELATED_LINKS_PINNED_REPLACE_ITEM ? index + 1 : index),
          ],
        },
      });
    } else {
      actions.push({
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: arrayMove(current, source.index, index),
        },
      });
    }
    const removalAction = getActionForSourceRemoval(
      source,
      {
        componentId: component.id,
        itemIndex,
        itemId: item[ARTICLE_ID],
        field: OVERRIDE_RELATED_LINKS_PINNED,
      },
      localState, serverState,
    );
    if (removalAction) {
      actions.push(removalAction);
    }
    return from(actions);
  }),
);

export const removeRelatedLinkOnManualListPinnedItem = (action$, state$) => action$.pipe(
  ofType(RELATED_LINKS_PINNED_CLEAR_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { value: { component, itemIndex, index } },
    { localState, serverState, externalState },
  ]) => {
    const propChain = [FIELD_COMPONENTS, component.id, FIELD_PINNED, itemIndex, FIELD_OVERRIDES];
    if (getFromState([...propChain, OVERRIDE_RELATED_LINKS], externalState)) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = getFromState([...propChain, OVERRIDE_RELATED_LINKS], localState)
      || getFromState([...propChain, OVERRIDE_RELATED_LINKS], serverState) || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        propChain,
        prop: OVERRIDE_RELATED_LINKS,
        value: current.filter((item, i) => i !== index),
      },
    });
  }),
);

export const setRelatedLinkOnManualListItem = (action$, state$) => action$.pipe(
  ofType(RELATED_LINKS_INSERT_ITEM, RELATED_LINKS_REPLACE_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { type, value: { listId, item, itemIndex, articleId, index, source } },
    { localState, serverState, externalState },
  ]) => {
    const propChain = [FIELD_MANUAL_LISTS, listId, FIELD_ARTICLES, articleId, FIELD_OVERRIDES];
    if (getFromState([...propChain, OVERRIDE_RELATED_LINKS], externalState)) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = getFromState([...propChain, OVERRIDE_RELATED_LINKS], localState)
      || getFromState([...propChain, OVERRIDE_RELATED_LINKS], serverState) || [];
    const actions = [];
    if (
      !source ||
      source?.field !== OVERRIDE_RELATED_LINKS ||
      source?.listId !== listId ||
      source?.articleId !== articleId
    ) {
      actions.push({
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: [
            ...current.slice(0, index),
            item,
            ...current.slice(type === RELATED_LINKS_REPLACE_ITEM ? index + 1 : index),
          ],
        },
      });
    } else {
      actions.push({
        type: DATASTATE_LOCAL_SET_PROPERTY,
        value: {
          propChain,
          prop: OVERRIDE_RELATED_LINKS,
          value: arrayMove(current, source.index, index),
        },
      });
    }
    const removalAction = getActionForSourceRemoval(
      source,
      { listId, itemIndex, articleId, itemId: item[ARTICLE_ID], field: OVERRIDE_RELATED_LINKS },
      localState, serverState,
    );
    if (removalAction) {
      actions.push(removalAction);
    }
    return from(actions);
  }),
);

export const removeRelatedLinkOnManualListItem = (action$, state$) => action$.pipe(
  ofType(RELATED_LINKS_CLEAR_ITEM),
  withLatestFrom(state$),
  mergeMap(([
    { value: { listId, articleId, index } },
    { localState, serverState, externalState },
  ]) => {
    const propChain = [FIELD_MANUAL_LISTS, listId, FIELD_ARTICLES, articleId, FIELD_OVERRIDES];
    if (getFromState([...propChain, OVERRIDE_RELATED_LINKS], externalState)) {
      return showErrorNotification('Error: This content is locked by another user.')();
    }
    const current = getFromState([...propChain, OVERRIDE_RELATED_LINKS], localState)
      || getFromState([...propChain, OVERRIDE_RELATED_LINKS], serverState) || [];
    return of({
      type: DATASTATE_LOCAL_SET_PROPERTY,
      value: {
        propChain,
        prop: OVERRIDE_RELATED_LINKS,
        value: current.filter((item, i) => i !== index),
      },
    });
  }),
);

// Timestamps on changes
export const setLayoutUpdatedOnManualListChange = action$ => action$.pipe(
  ofType(
    MANUAL_LIST_INSERT_ITEM,
    MANUAL_LIST_REPLACE_ITEM,
    MANUAL_LIST_CLEAR_ITEM,
    LAYOUT_SET_MANUAL_LIST_DEFAULT,
    LAYOUT_SET_MANUAL_LIST_LINK,
  ),
  mergeMap(() => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      prop: FIELD_UPDATED,
      value: moment().unix(),
    },
  })),
);

// @todo handle updated timestamp on API
export const setUpdatedOnManualListPropSet = action$ => action$.pipe(
  ofType(DATASTATE_LOCAL_SET_PROPERTY),
  filter(({ value: { propChain, prop } }) => (
    propChain?.[0] === FIELD_MANUAL_LISTS &&
    propChain?.[1] &&
    !(propChain.length === 2 && prop === FIELD_UPDATED) &&
    (!propChain[4] || propChain[4] !== FIELD_OVERRIDES)
  )),
  mergeMap(({ value: { propChain } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, propChain[1]],
      prop: FIELD_UPDATED,
      value: moment().unix(),
    },
  })),
);

// @todo handle updated timestamp on API
export const setUpdatedOnManualListPropUnset = action$ => action$.pipe(
  ofType(DATASTATE_LOCAL_UNSET_PROPERTY),
  filter(({ value: propChain }) => (
    propChain?.[0] === FIELD_MANUAL_LISTS &&
    propChain.length > 2 &&
    propChain[2] !== FIELD_UPDATED &&
    (!propChain[4] || propChain[4] !== FIELD_OVERRIDES)
  )),
  mergeMap(({ value: propChain }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, propChain[1]],
      prop: FIELD_UPDATED,
      value: moment().unix(),
    },
  })),
);

// @todo handle updated timestamp on API
export const setUpdatedOnManualListOverrideSet = action$ => action$.pipe(
  ofType(DATASTATE_LOCAL_SET_PROPERTY),
  filter(({ value: { propChain, prop } }) => (
    propChain?.[0] === FIELD_MANUAL_LISTS &&
    propChain?.[1] &&
    propChain?.[2] === FIELD_ARTICLES &&
    propChain?.[3] &&
    propChain?.[4] === FIELD_OVERRIDES &&
    !(propChain.length === 5 && prop === OVERRIDE_UPDATED)
  )),
  mergeMap(({ value: { propChain } }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, propChain[1], FIELD_ARTICLES, propChain[3], FIELD_OVERRIDES],
      prop: OVERRIDE_UPDATED,
      value: moment().unix(),
    },
  })),
);

// @todo handle updated timestamp on API
export const setUpdatedOnManualListOverrideUnset = action$ => action$.pipe(
  ofType(DATASTATE_LOCAL_UNSET_PROPERTY),
  filter(({ value: propChain }) => (
    propChain?.[0] === FIELD_MANUAL_LISTS &&
    propChain?.[1] &&
    propChain?.[2] === FIELD_ARTICLES &&
    propChain?.[3] &&
    propChain?.[4] === FIELD_OVERRIDES &&
    propChain.length > 5
  )),
  mergeMap(({ value: propChain }) => of({
    type: DATASTATE_LOCAL_SET_PROPERTY,
    value: {
      propChain: [FIELD_MANUAL_LISTS, propChain[1], FIELD_ARTICLES, propChain[3], FIELD_OVERRIDES],
      prop: FIELD_UPDATED,
      value: moment().unix(),
    },
  })),
);

// MANUAL COMPONENT LINKING

export const setLinkedListToDefault = (actions$, state$) => actions$.pipe(
  ofType(LAYOUT_SET_MANUAL_LIST_DEFAULT),
  withLatestFrom(state$),
  mergeMap(([
    { value: component },
    { localState, serverState, dataState: { [PROP_MANUAL_INDEXES]: manualIndexes } },
  ]) => {
    const actions = [];
    const articles = [];
    if (component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id) {
      const indexes =
        manualIndexes?.[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id]?.[component.id] ||
        getComponentDefaultIndexes(component);
      const currentProps = [FIELD_MANUAL_LISTS, component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id];
      const list = getFromStates(
        currentProps,
        localState,
        serverState,
      );
      const itemIds = indexes.map(index =>
        list[FIELD_ARTICLES][list[FIELD_ARTICLES_ORDER][index]]?.[ARTICLE_ID] || null)
        .filter(itemId => !!itemId);
      articles.push(...itemIds.map(itemId => list[FIELD_ARTICLES][itemId]));
      actions.push(setLocalProp(
        FIELD_ARTICLES,
        Object.keys(list[FIELD_ARTICLES])
          .filter(id => !itemIds.includes(id))
          .reduce((acc, id) => ({
            ...acc,
            [id]: list[FIELD_ARTICLES][id],
          }), {}),
        currentProps,
      ));
      actions.push(setLocalProp(
        FIELD_ARTICLES_ORDER,
        list[FIELD_ARTICLES_ORDER].filter(id => !itemIds.includes(id)),
        currentProps,
      ));
    }
    return from([
      {
        type: MANUAL_LIST_CREATE,
        value: {
          label: 'default',
          isDefault: true,
          callback: (response) => {
            const props = [FIELD_MANUAL_LISTS, response.id[0].value];
            return [
              componentSetProp(
                component[COMPONENT_ID],
                CONTENT_SOURCE_MANUAL_LIST_SECTION,
                {
                  name: response.name[0].value,
                  id: response.id[0].value,
                  uuid: response.uuid[0].value,
                },
              ),
              setLocalProp(
                FIELD_ARTICLES,
                articles.reduce((acc, article) => ({
                  ...acc,
                  [article[ARTICLE_ID]]: article,
                }), {}),
                props,
              ),
              setLocalProp(
                FIELD_ARTICLES_ORDER,
                Object.values(articles).map(({ [ARTICLE_ID]: id }) => id),
                props,
              ),
            ];
          },
        },
      },
      ...actions,
    ]);
  }),
);

export const setListToLinkedList = (actions$, state$) => actions$.pipe(
  ofType(LAYOUT_SET_MANUAL_LIST_LINK),
  withLatestFrom(state$),
  mergeMap(([
    { value: { component, sourceId } },
    { localState, serverState, dataState: { [PROP_MANUAL_INDEXES]: manualIndexes } },
  ]) => {
    const actions = [];
    const currentProps = component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id
      ? [FIELD_MANUAL_LISTS, component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id]
      : null;
    const newProps = [FIELD_MANUAL_LISTS, sourceId];
    const list = currentProps
      ? getFromStates(
        currentProps,
        localState,
        serverState,
      ) : null;
    const newList = getFromStates(
      newProps,
      localState,
      serverState,
    );
    if (!Array.isArray(newList[FIELD_ARTICLES_ORDER])) {
      newList[FIELD_ARTICLES_ORDER] = [];
    }
    if (list && list[FIELD_ARTICLES_ORDER].length > 0) {
      const indexes =
        manualIndexes?.[component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id]?.[component.id] ||
        getComponentDefaultIndexes(component);
      const itemIds = indexes
        .map(index =>
          list[FIELD_ARTICLES]?.[list[FIELD_ARTICLES_ORDER][index]]?.[ARTICLE_ID] || null)
        .filter(itemId => !!itemId);
      const components = LayoutEntity.getOrderedArray(
        getPropState([FIELD_COMPONENTS], localState, serverState),
        localState[FIELD_COMPONENTS_ORDER] || serverState[FIELD_COMPONENTS_ORDER],
      );
      let count = 0;
      for (let i = 0; i < components.length; i += 1) {
        if (components[i].id === component.id) {
          break;
        }
        if (
          components[i][CONTENT_SOURCE_MANUAL_LIST_SECTION] &&
          components[i][CONTENT_SOURCE_MANUAL_LIST_SECTION].id === sourceId
        ) {
          count += getComponentArticleCount(components[i]);
        }
      }
      actions.push(setLocalProp(
        FIELD_ARTICLES,
        {
          ...newList[FIELD_ARTICLES],
          ...itemIds.reduce((acc, id) => ({
            ...acc,
            [id]: list[FIELD_ARTICLES][id],
          }), {}),
        },
        newProps,
      ));
      actions.push(setLocalProp(
        FIELD_ARTICLES_ORDER,
        [
          ...newList[FIELD_ARTICLES_ORDER].slice(0, count),
          ...itemIds,
          ...newList[FIELD_ARTICLES_ORDER].slice(count),
        ],
        newProps,
      ));
      actions.push(setLocalProp(
        FIELD_ARTICLES,
        Object.keys(list[FIELD_ARTICLES])
          .filter(id => !itemIds.includes(id))
          .reduce((acc, id) => ({
            ...acc,
            [id]: list[FIELD_ARTICLES][id],
          }), {}),
        currentProps,
      ));
      actions.push(setLocalProp(
        FIELD_ARTICLES_ORDER,
        list[FIELD_ARTICLES_ORDER].filter(id => !itemIds.includes(id)),
        currentProps,
      ));
    }
    return from([
      componentSetProp(component.id, CONTENT_SOURCE_MANUAL_LIST_SECTION, { id: sourceId }),
      componentSetProp(component.id, CONTENT_SOURCE, CONTENT_SOURCE_MANUAL, true),
      ...actions,
    ]);
  }),
);

// SET MANUAL COMPONENT ARTICLE INDEXES

const setIndexesOnLinkedComponents = (dataState, componentId = null, manualListId = null) => {
  if (!componentId && !manualListId) {
    return EMPTY;
  }
  const { [FIELD_COMPONENTS]: components, [FIELD_COMPONENTS_ORDER]: order } = dataState;
  const listId = manualListId
    || components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id;
  const listComponents = Object.values(components)
    .filter(component => (
      component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
      component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === listId
    ) || component.id === componentId);
  if (listComponents.length <= 1) {
    return of(unsetDataStateProp([PROP_MANUAL_INDEXES, listId]));
  }
  const counts = listComponents
    .map(component => ({ id: component.id, count: getComponentArticleCount(component) }))
    .reduce((acc, { id, count }) =>
      ({ ...acc, [id]: count }), {});
  let offset = 0;
  const returnValue = order
    .filter(id => !!counts[id])
    .reduce((acc, id) => {
      const range = [...Array(counts[id]).keys()].map(i => i + offset);
      offset += counts[id];
      return ({ ...acc, [id]: range });
    }, {});
  return of(setDataStateProp(
    listId,
    returnValue,
    [PROP_MANUAL_INDEXES],
  ));
};

export const setManualIndexesOnComponentArticlePinning = (action$, state$) => action$.pipe(
  ofType(MANUAL_LIST_PIN_ITEM, MANUAL_LIST_UNPIN_ITEM),
  withLatestFrom(state$),
  filter(([{ value: { componentId } }, { dataState: { [FIELD_COMPONENTS]: components } }]) => (
    components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id &&
    Object.values(components).find(component =>
      component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
      component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id ===
        components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id &&
      component?.id !== componentId)
  )),
  mergeMap(([{ value: { componentId } }, { dataState }]) =>
    setIndexesOnLinkedComponents(dataState, componentId)),
);

export const setManualIndexesOnComponentReorder = (action$, state$) => action$.pipe(
  ofType(LAYOUT_SORT_COMPONENT),
  withLatestFrom(state$),
  filter(([
    { value: { oldIndex } },
    { dataState: { [FIELD_COMPONENTS]: components, [FIELD_COMPONENTS_ORDER]: order } },
  ]) => {
    const componentId = order[oldIndex];
    return (
      components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id &&
      Object.values(components).find(component =>
        component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
        component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id ===
        components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id &&
        component?.id !== componentId)
    );
  }),
  mergeMap(([
    { value: { oldIndex } },
    { dataState: { [FIELD_COMPONENTS_ORDER]: order } },
  ]) => action$.pipe(
    ofType(DATASTATE_SET_PROPERTY),
    filter(({ value: { prop } }) => prop === FIELD_COMPONENTS_ORDER),
    withLatestFrom(state$),
    mergeMap(([, { dataState }]) => {
      const componentId = order[oldIndex];
      return setIndexesOnLinkedComponents(dataState, componentId);
    }),
    take(1),
  )),
);

export const setManualIndexesOnSourceUpdate = (action$, state$) => action$.pipe(
  ofType(LAYOUT_COMPONENT_SET_PROPERTY),
  withLatestFrom(state$),
  // check state components for linked
  filter(([
    { value: { componentId, prop, value } },
    { dataState: { [FIELD_COMPONENTS]: components } },
  ]) => (
    prop === CONTENT_SOURCE &&
    value !== CONTENT_SOURCE_MANUAL &&
    components?.[componentId]?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL
  )),
  mergeMap(([{ value: { componentId } }, { dataState }]) =>
    setIndexesOnLinkedComponents(dataState, componentId)),
);

export const setManualIndexesOnIdUpdate = (action$, state$) => action$.pipe(
  ofType(LAYOUT_COMPONENT_SET_PROPERTY),
  filter(({ value: { prop } }) => prop === CONTENT_SOURCE_MANUAL_LIST_SECTION),
  withLatestFrom(state$),
  filter(([
    { value: { value: { id } } },
    { dataState: { [FIELD_COMPONENTS]: components } },
  ]) => (
    Object.values(components).find(component =>
      component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
      component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === id)
  )),
  mergeMap(([{ value: { value: { id } } }, { dataState }]) =>
    setIndexesOnLinkedComponents(dataState, null, id)),
);

export const setManualIndexesOnDataSet = action$ => action$.pipe(
  ofType(DATASTATE_SET_DATA),
  filter(({ value }) => value[FIELD_COMPONENTS] && value[FIELD_COMPONENTS_ORDER]),
  mergeMap(({
    value: { [FIELD_COMPONENTS]: components, [FIELD_COMPONENTS_ORDER]: order },
  }) => {
    const lists = {};
    Object.values(components)
      .filter(component => (
        component?.[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
        component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id))
      .forEach((component) => {
        if (typeof lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id] === 'undefined') {
          lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id] = {};
        }
        lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id][component.id] =
          getComponentArticleCount(component);
      });
    const linkedLists = Object.entries(lists)
      .filter(([, comps]) => Object.entries(comps).length > 1);
    if (linkedLists.length < 1) {
      return EMPTY;
    }
    const returnValue = linkedLists
      .reduce((acc, [listId, counts]) => {
        let offset = 0;
        return ({
          ...acc,
          [listId]: order
            .filter(id => !!counts[id])
            .reduce((listAcc, id) => {
              const range = [...Array(counts[id]).keys()].map(i => i + offset);
              offset += counts[id];
              return ({ ...listAcc, [id]: range });
            }, {}),
        });
      }, {});
    return of(setDataStateProp(
      PROP_MANUAL_INDEXES,
      returnValue,
    ));
  }),
);

// @todo investigate dispose local dispose data logic might be faulty
