import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { PagePanel, PagePanelContent, PagePanelHeading } from 'components/common/page/PagePanel';
import SectionTitle from 'components/common/SectionTitle';

import Headline from './articleBuilder/Headline';

import FieldLockTextfield from 'components/fieldLock/form/FieldLockTextfield';
import {
  FIELD_FEATURE_TITLE,
  FIELD_SEO_HEADLINE,
  FIELD_SHORT_HEADLINE,
  FIELD_SOCIAL_HEADLINE,
  FIELD_URL_KEYWORDS,
} from 'constants/article/articleFields';
import { MAX_LENGTH_SHORT_HEADLINE, MAX_LENGTH_SOCIAL_HEADLINE } from 'constants/article/article';
import FYI from './articleBuilder/FYI';

const HeadlinePanel = ({ isFeature, isProduct }) => (
  <PagePanel>
    <PagePanelHeading>
      <SectionTitle>Headline</SectionTitle>
    </PagePanelHeading>
    <PagePanelContent>
      <Grid container spacing={2}>
        <Grid item xs={12}><Headline /></Grid>
        <Grid item xs={6}>
          <FieldLockTextfield
            field={FIELD_SHORT_HEADLINE}
            label="Short headline"
            placeholder="Type your short headline for mobile here."
            maxLength={MAX_LENGTH_SHORT_HEADLINE}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldLockTextfield
            field={FIELD_SOCIAL_HEADLINE}
            label="Social headline"
            placeholder="Type your headline for social platforms here."
            maxLength={MAX_LENGTH_SOCIAL_HEADLINE}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldLockTextfield
            field={FIELD_URL_KEYWORDS}
            label="URL keywords"
            placeholder="Type your URL keywords here."
            spellCheck="false"
          />
        </Grid>
        <Grid item xs={6}>
          <FieldLockTextfield
            field={FIELD_SEO_HEADLINE}
            label="Meta title"
            placeholder="Type your meta title here."
          />
        </Grid>
        {isFeature && <Grid item xs={6}>
          <FieldLockTextfield
            field={FIELD_FEATURE_TITLE}
            label="Feature title"
            placeholder="Type your feature title here."
          />
        </Grid>}
        {isProduct &&
          <FYI isProduct={isProduct} />}
      </Grid>
    </PagePanelContent>
  </PagePanel>
);

HeadlinePanel.propTypes = {
  isFeature: PropTypes.bool,
  isProduct: PropTypes.bool
};

HeadlinePanel.defaultProps = {
  isFeature: false,
  isProduct: false
};

export default HeadlinePanel;

