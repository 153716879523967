import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { checkAffiliateLink } from '../../actions/product';
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  icon: {
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '1em',
    },
  },
  iconWarning: {
    color: theme.palette.secondary.main,
    '& svg': {
      fontSize: '1em',
    },
  },
});

export const islinkValid = ({ affiliate_match, link_http_code, out_of_stock }) => (
  link_http_code === 200 && out_of_stock === 0 && affiliate_match === 1
);

const AffiliateLinkCheck = (props) => {
  const {
    classes,
    link,
    linkCheckResult,
    checkAffiliateLink: checkLink,
  } = props;
  const [affiliateStats, setAffiliateStats] = useState({});
  const [message, setMessage] = useState('Add an affiliate link to convert');
  useEffect(() => {
    setAffiliateStats(linkCheckResult[link] || {});
  }, [link, linkCheckResult]);
  useEffect(() => {
    if (link) {
      checkLink(link);
    }
  }, [link]);
  useEffect(() => {
    if (!link) {
      setMessage('Add an affiliate link to convert');
    } else if (islinkValid(affiliateStats)) {
      setMessage('Affiliate link has been converted');
    } else if (affiliateStats.link_http_code === 0) {
      setMessage('External website blocks check');
    } else if (affiliateStats.link_http_code !== 200) {
      setMessage(`Link returned a ${affiliateStats.link_http_code} error code`);
    } else if (affiliateStats.out_of_stock === 1) {
      setMessage('Product is out of stock');
    } else {
      setMessage('Affilicate link could not be converted');
    }
  }, [link, affiliateStats]);
  return (
    <Grid container spacing={2} alignItems={'flex-end'}>
      <Grid item xs={2}>
        {islinkValid(affiliateStats) && <span className={classes.icon}>
          <CheckIcon />
        </span>}
        {!islinkValid(affiliateStats) && <span className={classes.iconWarning}>
          <ReportProblemOutlinedIcon />
        </span>}
      </Grid>
      <Grid item xs={10}>
        <Typography variant={'body1'}>{message}</Typography>
      </Grid>
    </Grid>
  );
};

AffiliateLinkCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAffiliateLink: PropTypes.func.isRequired,
  linkCheckResult: PropTypes.object,
  link: PropTypes.string,
};

AffiliateLinkCheck.defaultProps = {
  link: '',
  linkCheckResult: {},
};

export default withStyles(styles)(connect(
  ({
    product: { linkCheckResult },
  }) => ({ linkCheckResult }),
  { checkAffiliateLink },
)(AffiliateLinkCheck));
