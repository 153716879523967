import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { setArticleEditionProperty } from '../../../../actions/edition';
import {
  ContextPanel, ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import Rating from '../../../ui/Rating';

const StarRating = ({ setArticleEditionProperty: setProp, starRating, template }) => {
  if (template.length === 0 || !/review/i.test(template[0].name)) {
    return null;
  }
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Star rating</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <Rating
          value={starRating}
          onReset={() => setProp(['starRating', 0])}
          onChange={event => setProp(['starRating', event.target.value])}
          precision={0.5}
          size={'large'}
          setArticleProperty={setProp}
          emptyIcon={<StarBorderIcon fontSize="inherit" />}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

StarRating.defaultProps = {
  template: [],
};

StarRating.propTypes = {
  starRating: PropTypes.number.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  template: PropTypes.array,
};

export default connect(
  ({ edition: { starRating, pugpig_templates: template } }) => ({ starRating, template }),
  { setArticleEditionProperty },
)(StarRating);

