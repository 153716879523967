import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import TextField from '../../ui/builder/TextField';
import { PROP_LOCAL_CAPTION } from '../../../constants/media/media';

const styles = theme => ({
  removeComponent: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 2,
  },
  container: {
    position: 'relative',
  },
  video: {
    maxWidth: '208px',
    verticalAlign: 'bottom',
  },
  fields: {
    marginLeft: theme.spacing(1),
  },
});

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const VideoJWPlayer = ({
  classes,
  data,
}) => {
  return (
    <Grid container wrap={'nowrap'}>
      {data.mediaid && <div className={classes.container}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          controls
          className={classes.video}
          controlsList={'nodownload'}
        >
          {data.sources && data.sources.filter(({ width }) => width === 480).map(source =>
            <source key={source.file} src={source.file} type={source.type} />)}
        </video>
      </div>}
      {data.sources && <Grid className={classes.fields} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={'Local Caption'}
            value={
              typeof data[PROP_LOCAL_CAPTION] !== 'undefined' &&
              data[PROP_LOCAL_CAPTION] !== null
                ? data[PROP_LOCAL_CAPTION] : data.title
            }
          />
        </Grid>
      </Grid>}
    </Grid>
  );
};

VideoJWPlayer.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
};

VideoJWPlayer.defaultProps = {
  id: null,
  data: null,
};

export default withStyles(styles)(VideoJWPlayer);

