import { setDefaults } from '@udecode/slate-plugins';
import deserializeTwitter from './deserializeTwitter';
import renderElementTwitter from './renderElementTwitter';
import { DEFAULTS_TWITTER } from './defaults';

const TwitterPlugin = (options) => {
  const { twitter } = setDefaults(options, DEFAULTS_TWITTER);
  return ({
    renderElement: renderElementTwitter(options),
    deserialize: deserializeTwitter(options),
    voidTypes: [twitter.type],
  });
};

export default TwitterPlugin;
