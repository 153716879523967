import {
  filter,
  flatMap,
  map,
  mapTo,
  merge,
  withLatestFrom,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { push } from 'connected-react-router';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';

import {
  MANUAL_LIST_ALL_LOADED,
  MANUAL_LIST_ALL_REJECTED,
  MANUAL_LIST_FETCH_LOADED,
  REMOVE_MANUAL_LIST,
  REMOVE_MANUAL_LIST_COMPLETED,
  MANUAL_LIST_FETCH_ALL,
  MANUAL_LIST_LABEL_ADDED,
} from '../../constants/actionTypes';
import {
  MANUAL_LIST_FETCH,
  MANUAL_LIST_FETCH_SUCCESS,
  MANUAL_LIST_FETCH_REJECTED,
  MANUAL_LIST_SAVE,
  MANUAL_LIST_SAVE_SUCCESS,
  MANUAL_LIST_SAVE_REJECTED,
  MANUAL_LIST_CREATE,
  MANUAL_LIST_FETCH_FROM_LAYOUT,
  MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS,
  MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED,
} from '../../constants/actionTypes/manualList';
import { PAGE_ENTER_MANUAL_LIST_EDIT } from '../../constants/actionTypes/route';

import apiCatchError from '../helper/notification';

export const onSelectSectionRedirect = action$ => action$.pipe(
  ofType(MANUAL_LIST_LABEL_ADDED),
  mapTo(push('/manual-list/create')),
);

export const onRemoveManualList = action$ => action$.pipe(
  ofType(REMOVE_MANUAL_LIST),
  switchMap(({ value }) => ajax.delete(`/api/manual-list/${value}`, { 'Content-Type': 'application/json' }).pipe(
    map(() => ({
      type: REMOVE_MANUAL_LIST_COMPLETED,
    })),
    apiCatchError(),
  )),
);

export const onCreateManualList = action$ => action$.pipe(
  ofType(MANUAL_LIST_CREATE),
  filter(({ value: { callback } }) => typeof callback !== 'undefined'),
  map(({ value: { callback } }) => ({
    type: MANUAL_LIST_SAVE,
    value: { disableRedirect: true, callback },
  })),
);

export const onPatchManualList = (action$, state$) => action$.pipe(
  ofType(MANUAL_LIST_SAVE),
  withLatestFrom(state$),
  filter(([, { manualList: { currentManualList } }]) => currentManualList),
  switchMap(([{ value: action }, state]) => {
    const { manualListArticles, currentManualList } = state.manualList;
    return ajax.patch(`/api/manual-list/${currentManualList.id[0].value}`, {
      bundle: [
        {
          target_id: 'section',
          target_type: 'flowz_manual_list_type',
        },
      ],
      field_manual_list_json: [{
        value: JSON.stringify({
          articles: manualListArticles,
        }),
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => {
        if (action?.callback) {
          const { callback } = action;
          return from([
            {
              type: MANUAL_LIST_SAVE_SUCCESS,
              value: { response },
            },
            ...callback(response),
          ]);
        }
        return of(
          {
            type: MANUAL_LIST_SAVE_SUCCESS,
            value: { response },
          },
          push('/manual-list'),
        );
      }),
      apiCatchError(MANUAL_LIST_SAVE_REJECTED),
    );
  }),
);

export const onSaveManualList = (action$, state$) => action$.pipe(
  ofType(MANUAL_LIST_SAVE),
  withLatestFrom(state$),
  filter(([, { manualList: { currentManualList } }]) => !currentManualList),
  switchMap(([{ value: action }, state]) => {
    const { manualListArticles, selectedLabel, isDefault } = state.manualList;
    const { selectedPublication } = state.frame;
    return ajax.post('/api/manual-list', {
      bundle: [
        {
          target_id: 'section',
          target_type: 'flowz_manual_list_type',
        },
      ],
      field_publication: [
        {
          target_id: selectedPublication.id,
        },
      ],
      field_manual_list_json: [{
        value: JSON.stringify({
          isDefault,
          articles: manualListArticles,
        }),
      }],
      name: [{
        value: `${selectedLabel}`,
      }],
    }, { 'Content-Type': 'application/json' }).pipe(
      flatMap(({ response }) => {
        if (action?.callback) {
          const { callback } = action;
          return from([
            {
              type: MANUAL_LIST_SAVE_SUCCESS,
              value: { response },
            },
            ...callback(response),
          ]);
        }
        return of(push('/manual-list'));
      }),
      apiCatchError(MANUAL_LIST_SAVE_REJECTED),
    );
  }),
);

export const fetchManualListFromRoute = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_MANUAL_LIST_EDIT),
  withLatestFrom(state$),
  map(([, state]) => state),
  switchMap((({ router: { location: { pathname } } }) => {
    const [id] = pathname.match(/[0-9]+$/g);
    return ajax.getJSON(`/api/manual-list/${id}`).pipe(
      flatMap(response => of({
        type: MANUAL_LIST_FETCH_LOADED,
        value: response,
      })),
      apiCatchError(),
    );
  })),
);

export const fetchManualList = action$ => action$.pipe(
  ofType(MANUAL_LIST_FETCH),
  mergeMap(({ value: { id } }) => ajax.getJSON(`/api/manual-list/${id}`).pipe(
    mergeMap(response => of({
      type: MANUAL_LIST_FETCH_SUCCESS,
      value: response,
    })),
    apiCatchError(MANUAL_LIST_FETCH_REJECTED),
  )),
);

export const fetchAllManualLists = (action$, state$) => {
  const layoutSectionDeletion$ = action$.pipe(
    ofType(REMOVE_MANUAL_LIST_COMPLETED),
  );
  return action$.pipe(
    ofType(MANUAL_LIST_FETCH_ALL),
    merge(layoutSectionDeletion$),
    withLatestFrom(state$),
    map(([, state]) => state),
    switchMap(({ frame: { selectedPublication: { domain } } }) =>
      ajax.getJSON(`/api/manual-list/section/${domain}`).pipe(
        flatMap(response => of({
          type: MANUAL_LIST_ALL_LOADED,
          value: response,
        })),
        apiCatchError(MANUAL_LIST_ALL_REJECTED),
      ),
    ),
  );
};

export const fetchManualListsForLayout = action$ => action$.pipe(
  ofType(MANUAL_LIST_FETCH_FROM_LAYOUT),
  mergeMap(({ value: id }) => ajax.getJSON(`/api/layout-lists/${id}`).pipe(
    mergeMap(response => of({
      type: MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS,
      value: response,
    })),
    apiCatchError(MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED),
  )),
);
