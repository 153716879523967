import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

import Loading from './Loading';
import LivePreview from '../components/common/LivePreview';
import PreviewUI from '../components/ui/components/PreviewUI';

import { onPreviewRevision } from '../actions/revision';
import { revertArticle } from '../actions/article';
import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE } from '../constants/preview';
import { REVISION_REVERT_WARNING } from '../constants/article/article';
import ButtonWarning from '../components/ui/buttons/ButtonWarning';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  buttonWrapper: {
    width: 'auto',
    flexShrink: 0,
  },
  user: {
    padding: theme.spacing(2, 0, 0),
    marginBottom: theme.spacing(-3),
  },
});

const ArticleRevisionView = (props) => {
  const {
    urlParams: { nid, action },
    push: goto,
    classes,
    breadcrumb,
    revision: { payload, user, time },
    previewId,
    onPreviewRevision: previewRevision,
    revertArticle: revert,
  } = props;

  const [previewMode, setPreviewMode] = useState(PREVIEW_MODE_DESKTOP);

  useEffect(() => {
    previewRevision(payload);
  }, [payload]);

  if (!previewId || previewId === '') {
    return <Loading />;
  }

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <Grid container justify={'center'} alignItems={'center'}>
            <PreviewUI
              active={previewMode}
              desktopToggle={() => {
                setPreviewMode(PREVIEW_MODE_DESKTOP);
              }}
              mobileToggle={() => {
                setPreviewMode(PREVIEW_MODE_MOBILE);
              }}
            />
          </Grid>
          <Grid className={classes.buttonWrapper} container justify={'flex-end'} alignItems={'center'}>
            <Button variant={'contained'} onClick={() => goto(`/article/${action}/${nid}`)}>Go to Live</Button>
            <ButtonWarning
              variant={'contained'}
              onClick={() => {
                if (window.confirm(REVISION_REVERT_WARNING)) {
                  revert(payload);
                }
              }}
            >Revert</ButtonWarning>
          </Grid>
        </PageToolbar>
      }
    >
      <PageContent>
        <Grid item xs={12}>
          <Grid container justify={'center'} alignItems={'center'} className={classes.user}>
            <Typography variant={'h4'}>Created by {user.name} at {moment.unix(time).format('lll')}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LivePreview path={`/preview/${previewId}`} previewMode={previewMode} />
        </Grid>
      </PageContent>
    </Page>
  );
};

ArticleRevisionView.propTypes = {
  classes: PropTypes.object.isRequired,
  breadcrumb: PropTypes.object.isRequired,
  urlParams: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  revision: PropTypes.object.isRequired,
  previewId: PropTypes.string.isRequired,
  onPreviewRevision: PropTypes.func.isRequired,
  revertArticle: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  ({ article: { previewId } }) => ({ previewId }),
  { onPreviewRevision, push, revertArticle },
)(ArticleRevisionView));
