import React from 'react';
import PropTypes from 'prop-types';

import { CONTENT_TYPE_ARTICLE } from '../../../constants/article/article';

import DropZone from '../DropZone';

const DropZoneArticle = (props) => {
  const { children, dropHandler, dataProcessor, ...rest } = props;
  return (
    <DropZone
      type={CONTENT_TYPE_ARTICLE}
      dropHandler={dropHandler}
      dataProcessor={dataProcessor}
      {...rest}
    >
      {children}
    </DropZone>
  );
};

DropZoneArticle.propTypes = {
  children: PropTypes.node.isRequired,
  dropHandler: PropTypes.func.isRequired,
  dataProcessor: PropTypes.func.isRequired,
};

export default DropZoneArticle;
