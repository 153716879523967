import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  BUTTON_DISPLAY,
} from '../../constants';

import { componentSetProp } from '../../../../actions/layout';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import ToggleSwitch from '../../../ui/ToggleSwitch';

const TOGGLE_LABEL = 'Display as Buttons';

const VisualStoriesOptions = (props) => {
  const {
    component, componentSetProp: setProp,
  } = props;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <ToggleSwitch
          label={TOGGLE_LABEL}
          value={component[BUTTON_DISPLAY]}
          onChange={e => setProp(component.id, BUTTON_DISPLAY, e.target.checked)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

VisualStoriesOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(VisualStoriesOptions);
