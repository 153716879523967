import { ofType } from "redux-observable";
import { from, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import {
  catchError,
  filter,
  map,
  mapTo,
  merge,
  mergeMap,
  concatMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
  toArray,
} from "rxjs/operators";

import {
  ARTICLE_PREVIEW_REJECTED,
  ARTICLE_PREVIEW_SUCCESS,
  CLONE_ARTICLE_REQUEST,
  CLONE_ARTICLE_REQUEST_REJECTED,
  OPEN_NEW_TAB,
  PREVIEW_ARTICLE_READY,
  SET_PREVIEW_MODE,
} from "../../constants/actionTypes";

import apiCatchError, { assignErrorPayload } from "../helper/notification";
import {
  PAGE_ENTER_ARTICLE_EDIT,
  PAGE_ENTER_ARTICLE_EDIT_LOADED,
} from "../../constants/actionTypes/route";
import {
  FIELD_ARTICLE_TYPE,
  FIELD_AUTHORS,
  FIELD_BODY,
  FIELD_HEADLINE,
  FIELD_HERO_VIDEO,
  FIELD_IS_HIDDEN_FROM_FACEBOOK,
  FIELD_IS_PUBLISHED,
  FIELD_NID,
  FIELD_PATH,
  FIELD_PIXELS_AI_ID,
  FIELD_PIXELS_AI_PLAYLIST,
  FIELD_PRODUCTS,
  FIELD_SECTIONS,
  FIELD_SEO_HEADLINE,
  FIELD_SERVER_TIMESTAMP,
  FIELD_SOCIAL_HEADLINE,
  FIELD_URL_KEYWORDS,
  FIELD_VERDICT,
  FIELD_VIDEO_PROVIDER,
} from "../../constants/article/articleFields";
import {
  ARTICLE_WS_UPDATE,
  ARTICLE_REVISION_REVERT_SUCCESS,
  ARTICLE_EDIT_DISPOSE,
  ARTICLE_FETCH_SUCCESS,
  ARTICLE_PRODUCTS_SAVE_HANDLED,
  ARTICLE_UNPUBLISH_SUCCESS,
  ARTICLE_SAVE_SUCCESS,
  ARTICLE_SAVE_REJECTED,
  ARTICLE_DELETE_REJECTED,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_DELETE,
  ARTICLE_SAVE_READY,
  ARTICLE_SAVE,
  ARTICLE_VIDEOHUB_HANDLED,
} from "../../constants/actionTypes/article";
import { FIELD_ID } from "../../constants/common/commonFields";
import { WS_ARTICLE_LIST } from "../../constants/actionTypes/ws";
import ArticleEntity from "../../entities/ArticleEntity";
import { getArrayChangesOnLocal } from "../../utils/stateHelperDepricated";
import { saveProductRequest } from "../product/helper";
import ProductEntity from "../../entities/ProductEntity";
import { setItemPropOnLocal } from "../../actions/dataState";
import {
  ERROR,
  SHOW_NOTIFICATION,
} from "../../constants/actionTypes/notification";
import {
  LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED,
  LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS,
  LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE,
  LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE_SKIPPED,
} from "../../constants/actionTypes/liveblog";
import { REVISION_OFFLINE } from "../../constants/revision";
import { getState } from "../../utils/stateHelper";
import { FEATURE_PLAY_INC } from "../../constants/features";
import { PROP_DATA } from "../../constants/media/media";
import { deserialize } from "../../components/editor/helper/converter";
import { serializeNodes } from "../../components/editor/helper/serializer";
import {
  PLAYINC_FIELD_PLAYLIST,
  PLAYINC_PROVIDER,
} from "../../components/integration/playInc/PlayIncVideoSelectorList";
import { hasFeatures } from "../../components/helper/utils";
import { slugify } from "../../utils/urlHelper";

const idRegex = /\/(\d+)$/;

// @todo refactor and simplify
export const fetchArticle = (action$, state$) =>
  action$.pipe(
    ofType(PAGE_ENTER_ARTICLE_EDIT, ARTICLE_REVISION_REVERT_SUCCESS),
    switchMap((action) =>
      of(action).pipe(
        merge(
          action$.pipe(
            ofType(WS_ARTICLE_LIST),
            withLatestFrom(state$),
            filter(
              ([
                { value },
                {
                  dataState: { [FIELD_NID]: currentId },
                },
              ]) => parseInt(currentId, 10) === parseInt(value, 10)
            ),
            map(([wsAction]) => wsAction)
          )
        ),
        takeUntil(action$.pipe(ofType(ARTICLE_EDIT_DISPOSE))),
        withLatestFrom(state$),
        filter(
          ([
            ,
            {
              frame: {
                selectedPublication: { vocabs },
              },
            },
          ]) => !!vocabs
        ),
        switchMap(([previousAction, state]) => {
          const {
            router: {
              location: { pathname },
            },
            frame: {
              selectedPublication: { domain },
            },
          } = state;
          const [, id] = pathname.match(idRegex);
          return ajax.get(`/api/article/one/${id}?publication=${domain}`).pipe(
            mergeMap((data) => {
              const { response, xhr } = data;
              const serverTime =
                new Date(xhr.getResponseHeader("date")).getTime() / 1000;
              const article = {
                ...response,
                [FIELD_SERVER_TIMESTAMP]: [{ value: Math.floor(serverTime) }],
              };
              return from([
                {
                  type:
                    previousAction.type === WS_ARTICLE_LIST
                      ? ARTICLE_WS_UPDATE
                      : PAGE_ENTER_ARTICLE_EDIT_LOADED,
                  value: article,
                },
                {
                  type: ARTICLE_FETCH_SUCCESS,
                  value: article,
                },
              ]);
            }),
            apiCatchError()
          );
        })
      )
    )
  );

export const deleteArticle = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_DELETE),
    withLatestFrom(state$, (action, state) => state),
    switchMap(({ dataState: { [FIELD_NID]: nid } }) =>
      ajax
        .delete(`/api/article/${nid}`)
        .pipe(
          mapTo({ type: ARTICLE_DELETE_SUCCESS }),
          apiCatchError(ARTICLE_DELETE_REJECTED)
        )
    )
  );

export const validateArticleBeforeSave = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_SAVE),
    withLatestFrom(state$),
    switchMap(
      ([
        action,
        {
          frame: { selectedPublication },
          localState,
          serverState,
          liveblog,
        },
      ]) => {
        const articleEntity = new ArticleEntity();
        const errors = articleEntity.getValidationErrors(
          localState,
          serverState,
          action?.value?.publish,
          liveblog,
          selectedPublication
        );
        if (errors.length > 0) {
          return from([
            { type: ARTICLE_SAVE_REJECTED },
            ...errors.map(({ message }) => assignErrorPayload(message)),
          ]);
        }
        return of({ ...action, type: ARTICLE_SAVE_READY });
      }
    )
  );

export const saveLiveblogContainerFromArticle = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_SAVE_READY),
    withLatestFrom(state$, (a, b) => b),
    map(({ liveblog: { name, status, currentContainerFromServer } }) => {
      // create a new container
      if (!currentContainerFromServer && name !== "") {
        return { type: LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE };
      }
      if (currentContainerFromServer) {
        const {
          data: { name: originalNameValue, status: originalStatusValue },
        } = currentContainerFromServer;
        // only update the container if there is any state change
        if (originalNameValue !== name || status !== originalStatusValue) {
          return { type: LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE };
        }
      }
      return { type: LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE_SKIPPED };
    })
  );

export const saveProductsFromArticle = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_SAVE_READY),
    mergeMap((initiator) => {
      if (initiator.type === ARTICLE_SAVE_READY) {
        return action$.pipe(
          ofType(
            LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE_SKIPPED,
            LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS,
            LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED
          ),
          take(1),
          mapTo(initiator)
        );
      }
      return of(initiator);
    }),
    withLatestFrom(state$, (a, b) => b),
    mergeMap(
      ({
        localState,
        serverState,
        frame: {
          selectedPublication: { publicationConfig },
        },
        article: { revisionStatus },
      }) => {
        const dataState =
          revisionStatus === REVISION_OFFLINE
            ? getState(localState, serverState)
            : localState;
        if (dataState[FIELD_PRODUCTS]) {
          const products =
            revisionStatus === REVISION_OFFLINE
              ? dataState[FIELD_PRODUCTS].map((item, index) => ({
                  item,
                  index,
                }))
              : getArrayChangesOnLocal(
                  serverState[FIELD_PRODUCTS] || [],
                  localState[FIELD_PRODUCTS]
                );
          if (products && products.length > 0) {
            // save product one at a time (avoid api intermittently throwing 500 error)
            const sources$ = from(products).pipe(
              concatMap(({ item }) =>
                saveProductRequest(
                  item.data,
                  item.data,
                  publicationConfig
                ).pipe(
                  map(({ response }) => response),
                  catchError(() => of(null))
                )
              ),
              take(products.length),
              toArray()
            );
            return sources$.pipe(
              mergeMap((responses) => {
                // all product successfully saved
                if (responses.every(Boolean)) {
                  return from([
                    ...products.map(({ index }, i) => {
                      const response = responses[i];
                      const productEntity = new ProductEntity();
                      return setItemPropOnLocal(
                        FIELD_PRODUCTS,
                        index,
                        FIELD_ID,
                        productEntity.getPayloadId(response)
                      );
                    }),
                    { type: ARTICLE_PRODUCTS_SAVE_HANDLED },
                  ]);
                }
                return from([
                  { type: ARTICLE_SAVE_REJECTED },
                  assignErrorPayload(
                    "Unable to save one of the product, please try again"
                  ),
                ]);
              })
            );
          }
        }
        return of({ type: ARTICLE_PRODUCTS_SAVE_HANDLED });
      }
    )
  );

// START VIDEO HUB HACK, this will be removed once playInc is fully depricated
const getVideoHubPayload = (data) => {
  const payload = {};
  if (data?.[FIELD_NID]) payload.flowId = data[FIELD_NID];
  if (data?.[FIELD_HEADLINE]) payload.title = data[FIELD_HEADLINE];
  if (data?.[FIELD_BODY])
    payload.description = serializeNodes(deserialize(data[FIELD_BODY]));
  if (data?.[FIELD_AUTHORS]?.[0]?.name)
    payload.author = data[FIELD_AUTHORS][0].name;
  if (data?.[FIELD_AUTHORS]?.[0]?.twitter) {
    payload.authorTwitterHandle = data[FIELD_AUTHORS][0].twitter;
  }
  if (data?.[FIELD_SECTIONS]?.[0]?.name)
    payload.sectionName = data[FIELD_SECTIONS][0].name;
  if (data?.[FIELD_SECTIONS]?.[0]?.path)
    payload.sectionPath = data[FIELD_SECTIONS][0].path;
  if (data?.[FIELD_URL_KEYWORDS]) payload.keywords = data[FIELD_URL_KEYWORDS];
  if (data?.[FIELD_SOCIAL_HEADLINE])
    payload.socialHeadline = data[FIELD_SOCIAL_HEADLINE];
  if (data?.[FIELD_SEO_HEADLINE]) payload.metaTitle = data[FIELD_SEO_HEADLINE];
  if (data?.[FIELD_URL_KEYWORDS]) payload.keywords = data[FIELD_URL_KEYWORDS];
  if (data?.[FIELD_URL_KEYWORDS])
    payload.url = slugify(data[FIELD_URL_KEYWORDS]);
  payload.primaryPlaylistId = "news";
  if (data?.[FIELD_PIXELS_AI_PLAYLIST]) {
    payload.primaryPlaylistId = data[FIELD_PIXELS_AI_PLAYLIST];
  }
  payload.sendToSocial = !data?.[FIELD_IS_HIDDEN_FROM_FACEBOOK];
  payload.excludeFromSyndication = false;
  payload.status = data?.[FIELD_IS_PUBLISHED] ? "published" : "draft";
  return payload;
};

// END VIDEO HUB HACK, this will be removed once playInc is fully depricated

export const saveArticle = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_SAVE_READY),
    mergeMap((initiator) =>
      action$.pipe(
        ofType(ARTICLE_PRODUCTS_SAVE_HANDLED),
        take(1),
        mapTo(initiator)
      )
    ),
    withLatestFrom(state$),
    switchMap(([initiator, state]) => {
      const {
        frame: { selectedPublication, socketId },
        article: { revisionStatus },
        localState,
        serverState,
      } = state;
      const publish = initiator?.value?.publish;
      const articleEntity = new ArticleEntity();
      const basePayload =
        revisionStatus === REVISION_OFFLINE
          ? articleEntity.getPayloadFromData(
              getState(localState, serverState),
              serverState,
              selectedPublication,
              initiator.value.silent
            )
          : articleEntity.getPayloadFromData(
              localState,
              serverState,
              selectedPublication,
              initiator.value.silent
            );

      const nid = basePayload?.nid?.[0]?.value;
      const wasPublished = !!basePayload?.status?.[0]?.value;
      const isPublished =
        typeof publish !== "undefined" ? publish : wasPublished;
      const nextAction =
        isPublished === false && wasPublished === true
          ? ARTICLE_UNPUBLISH_SUCCESS
          : ARTICLE_SAVE_SUCCESS;

      basePayload.status = [{ value: isPublished }];

      if (nid) {
        return ajax
          .get(`/api/article/one/${nid}`, {
            "Content-Type": "application/json",
          })
          .pipe(
            mergeMap(({ response }) => {
              const field_extra_json = response?.field_extra_json?.[0]?.value;
              const pasredExtraJson = field_extra_json
                ? JSON.parse(field_extra_json)
                : {};

              const payloadExtraJSON =
                basePayload?.field_extra_json?.[0]?.value;
              const parsedPayloadExtraJSON = payloadExtraJSON
                ? JSON.parse(payloadExtraJSON)
                : {};
              const updatedPayload = {
                ...basePayload,
                field_extra_json: [
                  {
                    value: JSON.stringify({
                      ...pasredExtraJson,
                      ...parsedPayloadExtraJSON,
                    }),
                  },
                ],
              };

              return ajax
                .patch(
                  `/api/article/${nid}?socketId=${socketId}`,
                  updatedPayload,
                  {
                    "Content-Type": "application/json",
                  }
                )
                .pipe(
                  mergeMap(({ response }) =>
                    of({
                      type: nextAction,
                      value: response,
                    })
                  ),
                  apiCatchError(
                    ARTICLE_SAVE_REJECTED,
                    "Api Error. Unable to save this article."
                  )
                );
            }),
            apiCatchError(
              ARTICLE_SAVE_REJECTED,
              "Api Error. Unable to save this article."
            )
          );
      } else {
        return ajax
          .post("/api/article", basePayload, {
            "Content-Type": "application/json",
          })
          .pipe(
            mergeMap(({ response }) =>
              of({
                type: nextAction,
                value: response,
              })
            ),
            apiCatchError(
              ARTICLE_SAVE_REJECTED,
              "Api Error. Unable to save this article."
            )
          );
      }
    }),
    apiCatchError(
      ARTICLE_SAVE_REJECTED,
      "Api Error. Unable to save this article."
    )
  );

export const cloneArticle = (action$, state$) =>
  action$.pipe(
    ofType(CLONE_ARTICLE_REQUEST),
    withLatestFrom(state$),
    filter(
      ([
        ,
        {
          dataState: { [FIELD_NID]: nid },
        },
      ]) => !!nid
    ),
    switchMap(
      ([
        { value: publicationId },
        {
          dataState: { [FIELD_NID]: nid },
        },
      ]) =>
        ajax
          .getJSON(
            `/api/article/clone/${nid}?publicationTarget=${publicationId}`
          )
          .pipe(
            mergeMap(({ clonedId }) =>
              of({
                type: OPEN_NEW_TAB,
                value: `/article/edit/${clonedId}`,
              })
            ),
            apiCatchError(CLONE_ARTICLE_REQUEST_REJECTED)
          )
    )
  );

export const validateBeforePreviewArticle = (action$, state$) =>
  action$.pipe(
    ofType(SET_PREVIEW_MODE),
    withLatestFrom(state$),
    filter(
      ([
        ,
        {
          router: {
            location: { pathname },
          },
          article: { previewId },
        },
      ]) => /^\/article/.test(pathname) && !previewId
    ),
    switchMap(
      ([
        ,
        {
          frame: { selectedPublication },
          dataState,
          liveblog,
        },
      ]) => {
        const articleEntity = new ArticleEntity();
        const errors = articleEntity.getValidationErrors(
          dataState,
          dataState,
          false,
          liveblog,
          selectedPublication
        );
        if (errors.length > 0) {
          return from(
            errors.map(({ message }) => ({
              type: SHOW_NOTIFICATION,
              value: {
                message,
                variant: ERROR,
              },
            }))
          );
        }
        return of({
          type: PREVIEW_ARTICLE_READY,
        });
      }
    )
  );

export const requestPreviewUrl = (action$, state$) =>
  action$.pipe(
    ofType(PREVIEW_ARTICLE_READY),
    withLatestFrom(state$, (a, b) => b),
    switchMap(({ frame: { selectedPublication }, dataState }) => {
      const articleEntity = new ArticleEntity();
      const payload = articleEntity.getPayloadFromData(
        dataState,
        dataState,
        selectedPublication
      );

      payload.overrides = {};
      if (dataState?.[FIELD_ARTICLE_TYPE]?.name === "Product") {
        payload.overrides.products = dataState[FIELD_PRODUCTS];
        if (
          Array.isArray(dataState[FIELD_VERDICT]) &&
          dataState[FIELD_VERDICT].length > 0
        ) {
          payload.overrides.extra = {
            verdict: dataState[FIELD_VERDICT],
          };
        }
      }

      return ajax
        .post("/api/preview", payload, { "Content-Type": "application/json" })
        .pipe(
          map(({ response }) => ({
            type: ARTICLE_PREVIEW_SUCCESS,
            value: response.preview_id,
          })),
          apiCatchError(ARTICLE_PREVIEW_REJECTED)
        );
    })
  );
