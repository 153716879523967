import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchArticle } from 'actions/article';

import AutocompleteDialog from './AutocompleteDialog';

const AutocompleteArticleDialog = (props) => {
  const { articles } = useSelector(state => state.autocomplete);
  const dispatch = useDispatch();
  const handleSearch = value => dispatch(searchArticle(value));
  return (
    <AutocompleteDialog
      label="articles"
      items={articles}
      onChange={handleSearch}
      {...props}
    />
  );
};

export default AutocompleteArticleDialog;
