import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'rxjs';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DialogBase from '@material-ui/core/Dialog';
import DialogTitleBase from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { getClassName } from '../../../utils/propHelper';

import { closeDialog } from '../../../actions/dialog';
import { Tab, Tabs } from '../../ui/Tabs';

const style = theme => ({
  root: {
    overflow: 'visible',
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderRadius: '4px 4px 0 0',
  },
  content: {
    minHeight: 400,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  margin: {
    padding: theme.spacing(2),
  },
});

const Dialog = ({
  classes,
  handleClose,
  children,
  maxWidth,
  title,
  tabs,
  tab,
  setTab,
  margin,
  actions,
  closeDialog: close,
  ...rest
}) => (
  <DialogBase
    fullWidth
    maxWidth={maxWidth}
    onClose={() => {
      close();
      handleClose();
    }}
    classes={{ paperScrollPaper: classes.root }}
    {...rest}
  >
    <DialogTitleBase disableTypography className={classes.title}>
      {title && tabs.length < 1 && <Typography variant={'h2'}>{title}</Typography>}
      {tabs.length > 0 && <Tabs header value={tab} onChange={(e, current) => setTab(current)}>
        {tabs.map(({ label, value }) => (<Tab key={value} header label={label} value={value} />))}
      </Tabs>}
      <IconButton
        aria-label={'close'}
        className={classes.closeButton}
        onClick={() => {
          close();
          handleClose();
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitleBase>
    <DialogContent className={getClassName([classes.content, margin ? classes.margin : ''])}>
      {children}
    </DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </DialogBase>
);

Dialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.array,
  tab: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  setTab: PropTypes.func,
  margin: PropTypes.bool,
  actions: PropTypes.node,
};

Dialog.defaultProps = {
  maxWidth: 'sm',
  title: '',
  tabs: [],
  tab: '',
  setTab: noop,
  margin: false,
  actions: null,
};

export default withStyles(style)(connect(
  () => ({}),
  { closeDialog },
)(Dialog));
