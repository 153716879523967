import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { fetchAffiliateDashboard } from '../actions/affiliate';

import Page from '../components/common/page/Page';
import PageToolbar from '../components/common/page/PageToolbar';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';

import { Tab, Tabs } from '../components/ui/Tabs';
import TableFilters, { TableFiltersGroup } from '../components/ui/table/TableFilters';
import TextSearchFilter from '../components/ui/table/filters/TextSearchFilter';
import DatePickerFilter from '../components/ui/table/filters/DatePickerFilter';
import DropdownFilter from '../components/ui/DropdownFilter';

import Products from '../components/affiliate/Products';
import ConvertedAffiliates from '../components/affiliate/MatchedProducts';
import Partners from '../components/affiliate/DomainChecker';
import ProductArticlesTable from '../components/affiliate/ProductArticlesTable';
import ProductsTable from '../components/affiliate/ProductsTable';

const AffiliateDashboard = (props) => {
  const {
    total,
    fetchAffiliateDashboard: fetch,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  useEffect(() => {
    if (total === 0) {
      fetch();
    }
  }, [total]);
  return (
    <Page toolbarContent={<PageToolbar breadcrumb={[{ title: 'eCommerce' }]} />}>
      <PageContent direction={'row'}>
        <Grid item xs={4}>
          <Products />
        </Grid>
        <Grid item xs={4}>
          <ConvertedAffiliates />
        </Grid>
        <Grid item xs={4}>
          <Partners />
        </Grid>
        <Grid item xs={12}>
          <PageSection>
            <Tabs
              value={activeTab}
              onChange={(e, current) => {
                setSearchQuery('');
                setFromDate(null);
                setToDate(null);
                setActiveTab(current);
              }}
            >
              <Tab value={0} label={'Articles'} />
              <Tab value={1} label={'Products'} />
            </Tabs>
            <TableFilters elevation={0}>
              <TableFiltersGroup>
                <TextSearchFilter
                  placeholder={activeTab === 0 ? 'Search by Title / Body' : 'Search by Title'}
                  onSubmit={textQuery => setSearchQuery(textQuery)}
                />
                {activeTab === 1 && <DropdownFilter
                  onChange={setErrorCode}
                  label={'Errors'}
                  options={[
                    { id: 'errors', name: 'Errors Only' },
                  ]}
                  value={errorCode}
                  margin={'none'}
                  noFlex
                  includeAll
                />}
                <DatePickerFilter
                  toDate={toDate}
                  fromDate={fromDate}
                  onChangeFromDate={date => setFromDate(date)}
                  onChangeToDate={date => setToDate(date)}
                />
              </TableFiltersGroup>
            </TableFilters>
            {activeTab === 0 && <ProductArticlesTable
              textQuery={searchQuery}
              fromDate={fromDate ? fromDate.unix() : null}
              toDate={toDate ? toDate.unix() : null}
            />}
            {activeTab === 1 && <ProductsTable
              textQuery={searchQuery}
              errorCode={errorCode}
              fromDate={fromDate ? fromDate.unix() : null}
              toDate={toDate ? toDate.unix() : null}
            />}
          </PageSection>
        </Grid>
      </PageContent>
    </Page>
  );
};

AffiliateDashboard.propTypes = {
  fetchAffiliateDashboard: PropTypes.func.isRequired,
  total: PropTypes.number,
};

AffiliateDashboard.defaultProps = {
  total: 0,
};

export default connect(
  ({ affiliate: { products: { total } } }) => ({ total }),
  { fetchAffiliateDashboard },
)(AffiliateDashboard);
