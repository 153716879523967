import { ofType } from 'redux-observable';
import { debounceTime, filter, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of } from 'rxjs';

import { PAGE_ENTER_LAYOUT } from '../../constants/actionTypes/route';
import { DATASTATE_LOCAL_SET_PROPERTY } from '../../constants/actionTypes/dataState';
import { FIELD_COMPONENTS, FIELD_COMPONENTS_ORDER, FIELD_MANUAL_LISTS } from '../../constants/layout/layoutFields';
import { getFromState, getPropState } from '../../utils/stateHelper';
import {
  FIELD_LOCK_DISPOSE,
  FIELD_LOCK_FIELD,
  FIELD_LOCK_INIT,
  FIELD_LOCK_SET,
  FIELD_UNLOCK_FIELD,
  WS_FIELD_LOCK_UNSET,
} from '../../constants/actionTypes/fieldLock';
import { INFO, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import { CONTENT_SOURCE_MANUAL_LIST_SECTION } from '../../components/layout/constants';
import { FIELD_UPDATED } from '../../constants/manualList/manualListFields';
import { FIELD_ID } from '../../constants/common/commonFields';
import { CONTENT_TYPE_LAYOUT } from '../../constants/layout/layout';
import { unlockFieldLock } from '../../actions/fieldLock';

export const subscribeOnLayoutEnter = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  withLatestFrom(state$),
  filter(([, { router: { location: { pathname } } }]) => {
    const pathParams = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);
    return pathParams[1] !== 'new';
  }),
  mergeMap(([, {
    router: { location: { pathname } },
  }]) => {
    const pathParams = pathname.match(/\/layout\/[a-z_]+\/([a-z0-9]+)/);
    return of({
      type: FIELD_LOCK_INIT,
      value: {
        contentType: CONTENT_TYPE_LAYOUT,
        contentId: pathParams[1],
      },
    });
  }),
);

export const LockLayoutComponent = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    withLatestFrom(state$),
    filter(([{ value: { propChain, noUpdate } }]) => (
      propChain &&
      propChain[0] === FIELD_COMPONENTS &&
      !noUpdate
    )),
    debounceTime(100),
    mergeMap(([{ value: { prop, propChain } }, { localState, serverState }]) => {
      const componentId = propChain.length > 1 ? propChain[1] : prop;
      const fieldName = `${FIELD_COMPONENTS}_${componentId}`;
      const actions = [{
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      }];
      const components = getPropState([FIELD_COMPONENTS], localState, serverState);
      if (components?.[componentId]?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id) {
        const listId = components[componentId][CONTENT_SOURCE_MANUAL_LIST_SECTION].id;
        actions.push(
          ...Object
            .entries(components)
            .filter(([id, component]) =>
              component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === listId &&
              componentId !== id,
            )
            .map(([id]) => ({
              type: FIELD_LOCK_FIELD,
              value: { fieldName: `${FIELD_COMPONENTS}_${id}` },
            })),
        );
      }
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const LockLayoutComponentFromList = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    withLatestFrom(state$),
    filter(([{ value: { propChain, noUpdate } }]) => (
      propChain &&
      propChain[0] === FIELD_MANUAL_LISTS &&
      propChain.length < 4 &&
      !noUpdate
    )),
    mergeMap(([{ value: { prop, propChain } }, { localState, serverState }]) => {
      const listId = propChain.length > 1 ? propChain[1] : prop;
      const fieldName = `${FIELD_MANUAL_LISTS}_${listId}`;
      const actions = [{
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      }];
      const components = getPropState([FIELD_COMPONENTS], localState, serverState);
      actions.push(
        ...Object
          .entries(components)
          .filter(([, component]) => component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === listId)
          .map(([id]) => ({
            type: FIELD_LOCK_FIELD,
            value: { fieldName: `${FIELD_COMPONENTS}_${id}` },
          })),
      );
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const LockLayoutComponentFromListProperty = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    withLatestFrom(state$),
    filter(([{ value: { propChain, noUpdate } }]) => (
      propChain &&
      propChain[0] === FIELD_MANUAL_LISTS &&
      propChain.length > 3 &&
      !noUpdate
    )),
    debounceTime(100),
    mergeMap(([{ value: { propChain } }, { localState, serverState }]) => {
      const listId = propChain[1];
      const fieldName = `${FIELD_MANUAL_LISTS}_${listId}`;
      const actions = [{
        type: FIELD_LOCK_FIELD,
        value: { fieldName },
      }];
      const components = getPropState([FIELD_COMPONENTS], localState, serverState);
      actions.push(
        ...Object
          .entries(components)
          .filter(([, component]) => component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === listId)
          .map(([id]) => ({
            type: FIELD_LOCK_FIELD,
            value: { fieldName: `${FIELD_COMPONENTS}_${id}` },
          })),
      );
      return from(actions);
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const LockLayoutProperty = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY),
    filter(({ value: { prop, propChain, noUpdate } }) => (
      prop !== FIELD_UPDATED &&
      (!propChain || propChain.length === 0) &&
      !noUpdate
    )),
    debounceTime(100),
    mergeMap(({ value: { prop } }) => of({
      type: FIELD_LOCK_FIELD,
      value: { fieldName: prop },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const layoutUnlockManualListOnUnlock = (action$, state$) => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(FIELD_UNLOCK_FIELD),
    filter(({ value: { fieldName, quiet } }) => fieldName.startsWith(FIELD_COMPONENTS) && !quiet),
    withLatestFrom(state$),
    mergeMap(([
      { value: { fieldName } },
      {
        externalState, serverState,
      },
    ]) => {
      const propChain = fieldName.split('_');
      const components = getPropState([FIELD_COMPONENTS], externalState, serverState);
      const componentState = getFromState(propChain, externalState);
      if (componentState?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id) {
        const listId = componentState[CONTENT_SOURCE_MANUAL_LIST_SECTION].id;
        const actions = [
          unlockFieldLock([FIELD_MANUAL_LISTS, componentState[CONTENT_SOURCE_MANUAL_LIST_SECTION].id].join('_')),
        ];
        actions.push(
          ...Object
            .entries(components)
            .filter(([id, component]) =>
              component?.[CONTENT_SOURCE_MANUAL_LIST_SECTION]?.id === listId &&
              componentState[FIELD_ID] !== id,
            )
            .map(([id]) => (unlockFieldLock(`${FIELD_COMPONENTS}_${id}`, true))),
        );
        return from(actions);
      }
      return EMPTY;
    }),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutLockComponent = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(FIELD_LOCK_SET),
    filter(({ value: { fieldName, isNew } }) =>
      isNew &&
      fieldName.startsWith(FIELD_COMPONENTS) &&
      fieldName !== FIELD_COMPONENTS_ORDER,
    ),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `Layout component has been locked by ${name}`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutComponentUnlock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(WS_FIELD_LOCK_UNSET),
    filter(({ value: { fieldName } }) =>
      fieldName.startsWith(FIELD_COMPONENTS) &&
      fieldName !== FIELD_COMPONENTS_ORDER,
    ),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `Layout component has been unlocked by ${name}, your local changes have been removed.`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutLock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(FIELD_LOCK_SET),
    filter(({ value: { fieldName, isNew } }) => isNew && fieldName === FIELD_COMPONENTS_ORDER),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `Layout has been locked by ${name}`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);

export const notifyOnLayoutUnlock = action$ => action$.pipe(
  ofType(FIELD_LOCK_INIT),
  filter(({ value: { contentType } }) => contentType === CONTENT_TYPE_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(WS_FIELD_LOCK_UNSET),
    filter(({ value: { fieldName } }) => fieldName === FIELD_COMPONENTS_ORDER),
    mergeMap(({ value: { user: { name } } }) => of({
      type: SHOW_NOTIFICATION,
      value: {
        message: `Layout has been unlocked by ${name}, your local changes have been removed.`,
        variant: INFO,
      },
    })),
    takeUntil(action$.pipe(ofType(FIELD_LOCK_DISPOSE))),
  )),
);
