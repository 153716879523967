import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { FIELD_VENDOR_LINK } from '../../../constants/product/productFields';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import AffiliateLinkCheck from '../../product/AffiliateLinkCheck';

const ProductAffiliate = (props) => {
  const {
    product,
    index,
  } = props;
  const {
    [FIELD_VENDOR_LINK]: link,
  } = product.data || {};
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Affiliate Link</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <AffiliateLinkCheck
          link={link || ''}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ProductAffiliate.propTypes = {
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default connect(
  () => ({}),
  {},
)(ProductAffiliate);
