import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { CLIENT_STANDARD, CLIENT_JPI } from '../../../constants/env';

import { CMS_DATE_FORMAT } from '../../../constants/common';

import {
  fetchPlayIncVideos,
  disposePlayIncVideos,
} from '../../../actions/integration/playInc';
import ThumbnailImage from '../../table/content/ThumbnailImage';
import IconButton from '../../ui/buttons/IconButton';
import { TableBody, TableRow, TableCell } from '../../ui/table/Table';

import ListTable from '../../table/ListTable';
import TableHeader from '../../table/TableHeader';
import PlayIncVideoEdit from './PlayIncVideoEdit';

const getPlaylistLabel = (id, playlists) => {
  const playlist = playlists.find(({ playlistId }) => playlistId === id);
  return playlist?.label || '';
};

const PlayIncVideoTable = (props) => {
  const {
    searchQuery,
    isVarticle,
    author,
    section,
    playlist,
    fromDate,
    toDate,
    videos,
    playlists,
    totalVideos,
    isFetchingVideos,
    fetchPlayIncVideos: fetchVideos,
    disposePlayIncVideos: disposeVideos,
  } = props;

  const { client } = useSelector(state => state.frame);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const queryData = [
    searchQuery,
    isVarticle,
    author,
    section,
    playlist,
    fromDate,
    toDate,
  ];
  const getFetchData = (page, rowsPerPage) => ({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    title: searchQuery,
    is_varticle: isVarticle,
    author: author?.name || '',
    section: section?.name || '',
    primary_playlist: playlist,
    start_date: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
    end_date: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
  });

  useEffect(() => {
    return () => {
      disposeVideos();
    };
  }, []);

  const headers = [
    'Image',
    'Title',
    'Playlist',
    'Author',
    'Video Article',
    'Media Id',
    'Status',
    'Updated',
  ];

  if (client !== CLIENT_STANDARD && client !== CLIENT_JPI) {
    // add edit button column
    headers.push('')
  }

  return (
    <ListTable
      isFetching={isFetchingVideos}
      total={totalVideos}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={fetchVideos}
    >
      <TableHeader
        headers={headers.map((header) => ({ id: header, label: header }))}
        hasBorder
      />
      <TableBody>
        {videos.length === 0 && (
          <TableRow>
            <TableCell colSpan={headers.length} align={'center'}>
              No videos found
            </TableCell>
          </TableRow>
        )}
        {videos.length > 0 &&
          videos.map((video) => {
            const {
              id,
              image,
              title,
              author: videoAuthor,
              primaryPlaylistId,
              isVideoArticle,
              updated,
              status,
            } = video;
            return (
              <TableRow key={id} hasActions>
                <TableCell>
                  {image && (
                    <ThumbnailImage
                      image={{ data: { url: image, title: title || '' } }}
                    />
                  )}
                </TableCell>
                <TableCell>{title || ''}</TableCell>
                <TableCell>
                  {primaryPlaylistId
                    ? getPlaylistLabel(primaryPlaylistId, playlists)
                    : ''}
                </TableCell>
                <TableCell>{videoAuthor || ''}</TableCell>
                <TableCell>{isVideoArticle ? 'Yes' : 'No'}</TableCell>
                <TableCell>{id || ''}</TableCell>
                <TableCell>{status || ''}</TableCell>
                <TableCell>
                  {updated ? moment.unix(updated).format(CMS_DATE_FORMAT) : ''}
                </TableCell>
                {client !== CLIENT_STANDARD && client !== CLIENT_JPI && (
                  <TableCell>
                    <Grid container wrap={'nowrap'} justify={'flex-end'}>
                      <IconButton
                        onClick={() => {
                          setSelectedVideo(video);
                          setOpenDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
      </TableBody>
      {openDialog && (
        <PlayIncVideoEdit
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          video={selectedVideo}
        />
      )}
    </ListTable>
  );
};

PlayIncVideoTable.propTypes = {
  fetchPlayIncVideos: PropTypes.func.isRequired,
  disposePlayIncVideos: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  isVarticle: PropTypes.bool.isRequired,
  author: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
  playlist: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  videos: PropTypes.array,
  playlists: PropTypes.array,
  totalVideos: PropTypes.number,
  isFetchingVideos: PropTypes.bool,
};

PlayIncVideoTable.defaultProps = {
  videos: [],
  playlists: [],
  totalVideos: 0,
  isFetchingVideos: false,
};

export default connect(
  ({
    integration: {
      playInc: { videos, isFetchingVideos, totalVideos, playlists } = {},
    },
  }) => ({ videos, isFetchingVideos, totalVideos, playlists }),
  { fetchPlayIncVideos, disposePlayIncVideos }
)(PlayIncVideoTable);
