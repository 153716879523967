import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import SortableTree, { changeNodeAtPath, getVisibleNodeCount } from 'react-sortable-tree';
import SortableTreeMinimalTheme from 'react-sortable-tree-theme-minimal';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import IconButton from '../ui/buttons/IconButton';
import TextField from '../ui/builder/TextField';
import DragHandle from '../ui/DragHandle';

const style = theme => ({
  root: {
    minHeight: 400,
    marginLeft: -45,
    '& .ReactVirtualized__Grid': {
      overflow: 'visible !important',
    },
    '& .rstcustom__node': {
      '&:hover': {
        zIndex: 100,
        overflow: 'visible',
      },
    },
    '& .rstcustom__highlight': {
      background: '0 none',
    },
    '& .rstcustom__nodeContent': {
      width: '100%',
    },
    '& .rstcustom__collapseButton':{
      width: theme.spacing(8),
      padding: 0,
      marginLeft: 45,
      transform: 'translate(0, -50%) scaleY(0.5)',
    },
    '& .rstcustom__expandButton':{
      width: theme.spacing(8),
      padding: 0,
      marginLeft: 45,
      transform: 'translate(0, -50%) scaleX(0.5)',
    },
    '& .rstcustom__rowWrapper': {
      padding: '0 0 1px',
      '&:hover': {
        opacity: 1,
      },
    },
    '& .rstcustom__row': {
      position: 'relative',
      width: '100%',
      '&:before': {
        marginLeft: 45,
      },
      '&:hover .rstcustom__rowToolbar': {
        visibility: 'visible',
      },
      '&:hover .rstcustom__rowTitle .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'solid',
      },
    },
    '& .rstcustom__rowTitle': {
      display: 'flex',
      '& > *': {
        marginRight: theme.spacing(4),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderStyle: 'none',
      },
    },
    '& .rstcustom__rowToolbar': {
      width: 300 - theme.spacing(4),
      flexShrink: 0,
      justifyContent: 'flex-end',
      alignItems: 'center',
      visibility: 'hidden',
    },
    '& .rstcustom__rowContents': {
      padding: theme.spacing(1, 2, 1, 8),
      boxShadow: 'none',
      '&:hover': {
        boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
      },
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1, 0, 3),
  },
  buttonMargin: {
    marginRight: theme.spacing(1),
  },
  addBtn: {
    boxShadow: 'none',
  },
  handle: {
    height: 75,
    margin: '0 -18px 0 12px',
  },
});

const SortableMenuTree = ({
  treeData,
  onChange,
  classes,
  onRemove,
}) => (<div >
  <div className={classes.root} style={{ height: getVisibleNodeCount({ treeData }) * 80 + 20 }}>
    {treeData.length > 0 && <SortableTree
      theme={SortableTreeMinimalTheme}
      treeData={treeData}
      rowHeight={72}
      onChange={onChange}
      generateNodeProps={rowInfo => ({
        title: (
          <>
            <TextField
              value={rowInfo.node.title}
              onChange={(event) => {
                const newTitle = event.target.value;
                onChange(changeNodeAtPath({
                  treeData,
                  path: rowInfo.path,
                  getNodeKey: ({ treeIndex }) => treeIndex,
                  newNode: { ...rowInfo.node, title: newTitle },
                }));
              }}
            />
            <TextField
              value={rowInfo.node.path}
              onChange={(event) => {
                const newPath = event.target.value;
                onChange(changeNodeAtPath({
                  treeData,
                  path: rowInfo.path,
                  getNodeKey: ({ treeIndex }) => treeIndex,
                  newNode: { ...rowInfo.node, path: newPath },
                }));
              }}
            />
          </>
        ),
        buttons: [
          <IconButton key={`del${rowInfo.path.join('')}`} aria-label="Remove" onClick={() => {
            console.log('>>>> ', 'remove');
            onRemove(rowInfo)
          }}>
            <DeleteOutlinedIcon />
          </IconButton>,
          <DragHandle key={`handle${rowInfo.path.join('')}`} className={classes.handle} />,
        ],
      })}
    />}
  </div>
</div>);

SortableMenuTree.propTypes = {
  treeData: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(connect(
  () => ({}),
  {},
)(SortableMenuTree));
