export const FIELD_NAME = 'name';
export const FIELD_USER = 'user';
export const FIELD_ARTICLE = 'article';
export const FIELD_PUBLICATION = 'publication';

export const FIELD_TRACKING_TAG = 'trackingTag';
export const FIELD_URL = 'url';
export const FIELD_IMAGE = 'image';
export const FIELD_TITLE = 'title';
export const FIELD_TIME = 'time';
export const FIELD_DATA = 'field_data';

export const VIAFOURA_NOTIFICATION_PAYLOAD_FIELDS = [
  FIELD_NAME,
  FIELD_USER,
  FIELD_ARTICLE,
  FIELD_PUBLICATION,
  FIELD_TIME,
  FIELD_DATA,
];

export const VIAFOURA_NOTIFICATION_EXTRA_FIELDS = [
  FIELD_TITLE,
  FIELD_IMAGE,
  FIELD_URL,
  FIELD_TRACKING_TAG,
];

export const VIAFOURA_NOTIFICATION_FIELD_MAPPING = [
  [FIELD_NAME, 'name', 'Name'],
  [FIELD_USER, 'field_user', 'User'],
  [FIELD_ARTICLE, 'field_article', 'Article'],
  [FIELD_PUBLICATION, 'field_publication', 'Publication'],
  [FIELD_TIME, 'field_time', 'Time'],
  [FIELD_DATA, 'field_data', 'Data'],
  [FIELD_TITLE, FIELD_TITLE, 'Title'],
  [FIELD_IMAGE, FIELD_IMAGE, 'Image'],
  [FIELD_URL, FIELD_URL, 'Url'],
  [FIELD_TRACKING_TAG, FIELD_TRACKING_TAG, 'Tracking Tag'],
];
