import { Transforms } from 'slate';
import {
  getAbove,
  ELEMENT_TD,
  ELEMENT_TH,
} from '@udecode/slate-plugins';

const toggleTableHead = (editor) => {
  const cell = getAbove(editor, { match: { type: [ELEMENT_TD, ELEMENT_TH] } });
  if (cell) {
    Transforms.setNodes(editor, {
      ...cell[0],
      type: cell[0].type === ELEMENT_TD ? ELEMENT_TH : ELEMENT_TD,
    }, { at: cell[1] });
  }
};

export default toggleTableHead;
