import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Headline from './articleBuilder/Headline';
import ArticleContent from './articleBuilder/ArticleContent';
import HeroImage from './articleBuilder/HeroImage';
import SubHeadline from './articleBuilder/SubHeadline';
import ScorePanel from './articleBuilder/ScorePanel';
import SectionPanel from './articleBuilder/SectionPanel';
import RelatedArticle from './articleBuilder/RelatedArticle';
import NoticeBoard from '../../notice/NoticeBoard';
import { PagePanel, PagePanelContent, PagePanelHeading } from '../../common/page/PagePanel';
import SectionTitle from '../../common/SectionTitle';
import SourcePanel from './articleBuilder/SourcePanel';
import EditionTypeSelector from './articleBuilder/EditionTypeSelector';

const ArticleMain = ({
  notes,
}) => {
  const legal = notes.filter(({ type }) => type === 'legal');
  return (
    <>
      {legal.length > 0 && <NoticeBoard legal={'This article contains a legal note'} />}
      <Grid item xs={12}>
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={4}>
            <EditionTypeSelector />
          </Grid>
          <Grid item xs={8} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelHeading>
            <SectionTitle>Headline & Standfirst</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Headline />
              </Grid>
              <Grid item xs={12}>
                <SubHeadline />
              </Grid>
            </Grid>
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelHeading>
            <SectionTitle>Media</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <HeroImage />
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <SectionPanel />
      </Grid>
      <ScorePanel />
      <Grid item xs={12}>
        <PagePanel>
          <PagePanelHeading>
            <SectionTitle>Body</SectionTitle>
          </PagePanelHeading>
          <PagePanelContent>
            <ArticleContent />
          </PagePanelContent>
        </PagePanel>
      </Grid>
      <Grid item xs={12}>
        <SourcePanel />
      </Grid>
      <Grid item xs={12}>
        <RelatedArticle />
      </Grid>
    </>
  );
};

ArticleMain.propTypes = {
  notes: PropTypes.array.isRequired,
};

export default connect(
  ({ edition: { notes } }) => ({ notes }),
  {},
)(ArticleMain);
