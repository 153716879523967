import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import TextField from '../ui/TextField';
import Button from '../ui/buttons/Button';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { queryStringify } from 'utils/urlHelper';

const DailyMotionUrl = ({ onSelect }) => {
  const [url, setUrl] = useState('');
  const [newUrl, setNewUrl] = useState('');
  const handleSetUrl = async () => {
    try {
      const id = newUrl.split('/')[4].split('?')[0]
      const body = {
        "videoid": id
      }
      const res = await axios.post('https://videohub.playinc.uk.com/api/nationalworld/video',
        body,
        {
          headers: {
            Authorization: `Bearer 11595e6f2dcc2c8595686bb7c2a7a52827c8f486`,
          },
        }

      )
      console.log({ res })
      setNewUrl('')
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <Grid item xs={12}>
        <TextField margin="none" onChange={event => setUrl(event.target.value)} label="Daily Motion URL" />
      </Grid>
      <Grid item container justify="flex-end">

        <Button
          disabled={!url}
          onClick={() => onSelect({ url })}
          variant="contained"
        >Select</Button>
        <br />
        <Grid item xs={12}>
          <br />
          <TextField value={newUrl} margin="none" onChange={event => setNewUrl(event.target.value)} label="Daily Motion Video hub" />
          <br />
        </Grid>
        <Button
          disabled={!newUrl}
          onClick={() => handleSetUrl()}
          variant="contained"
        >UPLOAD VIDEO HUB</Button>
      </Grid>
    </>
  );
};

DailyMotionUrl.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default DailyMotionUrl;
