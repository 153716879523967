import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    minHeight: '100%',
  },
  frame: {
    width: '100%',
    height: '100%',
  },
  frameRoot: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '800px',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
});

const TranslatedOriginPreview = (props) => {
  const { classes, originalUrl, internalTranslatedSourceURL } = props;

  const src = internalTranslatedSourceURL || originalUrl;

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <div className={classes.frameRoot}>
          <iframe className={classes.frame} title={'english-preview'} src={`${src}?amp`} frameBorder={'0'} />
        </div>
      </Grid>
    </Grid>
  );
};

TranslatedOriginPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  originalUrl: PropTypes.string.isRequired,
  internalTranslatedSourceURL: PropTypes.string.isRequired,
};

TranslatedOriginPreview.defaultProps = {
};

export default withStyles(styles)(connect(
  ({ article: { originalUrl, internalTranslatedSourceURL } }) => ({ originalUrl, internalTranslatedSourceURL }),
  {},
)(TranslatedOriginPreview));

