import { mergeDeepRight } from 'rambdax';
import BaseEntity from '../common/BaseEntity';

import {
  GALLERY_FIELD_MAPPING,
  GALLERY_PAYLOAD_FIELDS,
  FIELD_IMAGES, FIELD_IMAGES_ORDER,
} from '../../constants/media/galleryFields';
import { MEDIA_ENTITY_ID, MEDIA_ENTITY_TYPE, MEDIA_IMAGE } from '../../constants/media/media';
import { FIELD_NAME, FIELD_WORKFLOW } from '../../constants/common/commonFields';

export const ENTITY_BUNDLE = 'gallery';

class GalleryEntity extends BaseEntity {
  constructor() {
    super(
      MEDIA_ENTITY_TYPE,
      ENTITY_BUNDLE,
      GALLERY_FIELD_MAPPING,
      GALLERY_PAYLOAD_FIELDS,
      [],
      [FIELD_NAME],
      {
        idField: MEDIA_ENTITY_ID,
      },
    );
  }
  addFieldData(data, payload) {
    return (field) => {
      switch (field) {
        case FIELD_IMAGES: {
          try {
            const mapping = this.dataMapping[field];
            const raw = JSON.parse(payload[mapping.key][0].value);
            if (Array.isArray(raw)) {
              const images = raw.map(({ data: imageData }) => imageData).filter(imageData => !!imageData);
              data[field] = this.constructor.getArrayAsKeyed(images, MEDIA_ENTITY_ID);
              data[FIELD_IMAGES_ORDER] = this.constructor.getArrayOrder(images, MEDIA_ENTITY_ID);
            }
          } catch (e) {
            console.log(e);
          }
          return;
        }
        default:
          data[field] = this.getFieldData(payload, field);
      }
    };
  }
  getPayloadFieldData(data, field) {
    switch (field) {
      case FIELD_IMAGES: {
        const images = this.constructor.getOrderedArray(
          data[field],
          data[FIELD_IMAGES_ORDER],
        );
        return images && images.length > 0
          ? [{
            value: JSON.stringify(
              images
                .filter(imageData => !!imageData?.mid)
                .map(imageData => ({ type: MEDIA_IMAGE, data: imageData })),
            ),
          }]
          : [{ value: '' }];
      }
      case FIELD_WORKFLOW:
        return data[field] ? [{ target_id: data[field] }] : null;
      default:
        return super.getPayloadFieldData(data, field);
    }
  }
  getPayloadFromData(data, serverData, publication) {
    const dataState = mergeDeepRight(serverData, data);
    if (dataState[FIELD_IMAGES]) {
      data[FIELD_IMAGES] = dataState[FIELD_IMAGES];
      data[FIELD_IMAGES_ORDER] = dataState[FIELD_IMAGES_ORDER];
    }
    return super.getPayloadFromData(data, serverData, publication);
  }
}

export default GalleryEntity;
