import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Plus } from 'mdi-material-ui';
import Fab from '@material-ui/core/Fab';

import VocabSelectorDialog from '../../dialog/VocabSelectorDialog';
import { SECTIONS } from '../../../constants/vocab';

const style = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  },
  componentList: {
    position: 'absolute',
    bottom: '100%',
  },
  addBtn: {
    boxShadow: 'none',
  },
});

const ComponentSelector = ({
  openDialog, isToggle, classes, closeDialog, selectSection, showAddButton,
}) => (
  <div className={classes.root}>
    { isToggle && <VocabSelectorDialog
      handleClose={() => closeDialog(false)}
      open={isToggle}
      activeVocab={SECTIONS}
      selectVocab={selectSection}
    /> }
    {showAddButton &&
      <Fab
        onClick={openDialog}
        size={'small'}
        color={'primary'}
        aria-label="Add"
        className={classes.addBtn}
      >
        <Plus />
      </Fab>
    }
  </div>
);

ComponentSelector.defaultProps = {
  showAddButton: true,
};
ComponentSelector.propTypes = {
  isToggle: PropTypes.bool.isRequired,
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectSection: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  showAddButton: PropTypes.bool,
};

export default withStyles(style)(ComponentSelector);
