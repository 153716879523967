import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../ui/TextField';
import MarkupField from '../../../../ui/MarkupField';
import Select from '../../../../ui/Select';
import ArticleRef from './common/ArticleRef';

const FIELD_HEADLINE = 'headline';
const FIELD_SUBHEADLINE = 'subheadline';
const FIELD_HEADLINE_SIZE = 'headlineSize';
const FIELD_SUBHEADLINE_SIZE = 'subheadlineSize';
const FIELD_BODY = 'body';
const FIELD_AUTHOR = 'author';
const FIELD_AUTHOR_LOCATION = 'authorLocation';
const FIELD_CAPSULE = 'capsule';
const FIELD_ARTICLE_REF = 'articleRef';

const SIZES = [
  'normal',
  'small',
  'large',
];

const TextSplash = (props) => {
  const {
    component: {
      headline, headlineSize, subheadline, subheadlineSize, body, capsule, author, authorLocation,
      articleRef,
    },
    setProperty, delta,
  } = props;

  return (
    <>
      <TextField
        label={'Headline'}
        value={headline || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE,
          value: event.target.value,
        })}
      />
      <Select
        label={'Headline Size'}
        value={headlineSize || SIZES[0]}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE_SIZE,
          value: event.target.value,
        })}
        items={SIZES}
      />
      <MarkupField
        label={'Subheadline'}
        data={subheadline || ''}
        placeholder={''}
        toolbar={['bold', 'italic', 'underline', 'bulletedlist']}
        onChange={markup => setProperty({
          delta,
          prop: FIELD_SUBHEADLINE,
          value: markup,
        })}
      />
      <Select
        label={'Subheadline Size'}
        value={subheadlineSize || SIZES[0]}
        onChange={event => setProperty({
          delta,
          prop: FIELD_SUBHEADLINE_SIZE,
          value: event.target.value,
        })}
        items={SIZES}
      />
      <TextField
        label={'Author'}
        value={author || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_AUTHOR,
          value: event.target.value,
        })}
      />
      <TextField
        label={'Author Location'}
        value={authorLocation || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_AUTHOR_LOCATION,
          value: event.target.value,
        })}
      />
      <TextField
        label={'Capsule'}
        value={capsule || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_CAPSULE,
          value: event.target.value,
        })}
      />
      <MarkupField
        label={'Body'}
        data={body || ''}
        placeholder={''}
        onChange={markup => setProperty({
          delta,
          prop: FIELD_BODY,
          value: markup,
        })}
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={value => setProperty({
          delta,
          prop: FIELD_ARTICLE_REF,
          value: value ? {
            title: value.data.title,
            id: value.data.id,
            bundle: value.type,
          } : null,
        })}
      />
    </>
  );
};

TextSplash.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default TextSplash;
