import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Fade from '@material-ui/core/Fade';

import FlowPaper from '../common/layout/FlowPaper';
import TextField from '../ui/TextField';
import Tag from '../ui/Tag';
import { getSuggestions } from './helper';

const renderInput = (props) => {
  const { InputProps, classes, ref, startIcon, ...other } = props;
  const { value, ...otherInputProps } = InputProps;
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        startAdornment: startIcon ? (
          <InputAdornment position={'start'}>{startIcon}</InputAdornment>
        ) : null,
        value: value || '',
        ...otherInputProps,
      }}
      {...other}
    />
  );
};

renderInput.propTypes = {
  InputProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  ref: PropTypes.object.isRequired,
  startIcon: PropTypes.node,
};

renderInput.defaultProps = {
  startIcon: null,
};

const renderSuggestion = ({
  suggestion, itemProps,
}) => (
  <MenuItem
    {...itemProps}
    key={suggestion.id}
    component={'div'}
  >
    {suggestion.label || suggestion.name}
  </MenuItem>
);

renderSuggestion.propTypes = {
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const renderHierarchicalSuggestion = ({
  classes, suggestion, itemProps, getItemProps,
}) => {
  const { children } = suggestion;
  return (
    <div key={suggestion.id} className={`${classes.hierarchicalItem} menu-item`}>
      <MenuItem
        {...itemProps}
        key={suggestion.name}
        component={'div'}
      >
        {suggestion.name}
      </MenuItem>
      {children && children.length > 0 && <div className={`${classes.hierarchicalChildren} menu-item-children`}>
        {children.map(child => renderHierarchicalSuggestion({
          classes,
          suggestion: child,
          itemProps: getItemProps({ item: child }),
          getItemProps,
        }))}
      </div>}
    </div>
  );
};

renderHierarchicalSuggestion.propTypes = {
  classes: PropTypes.object.isRequired,
  itemProps: PropTypes.object.isRequired,
  getItemProps: PropTypes.func.isRequired,
  suggestion: PropTypes.shape({
    name: PropTypes.string,
    children: PropTypes.object,
  }).isRequired,
};

const styles = theme => ({
  root: {},
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 110,
    left: 0,
    right: 0,
  },
  paperPadded: {
    padding: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(1 / 2, 1 / 4),
  },
  inputRoot: {
    '& .MuiInputAdornment-positionStart': {
      flexWrap: 'wrap',
      height: '100%',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(-0.5),
      paddingTop: 11,
      paddingBottom: 10,
      maxHeight: 'inherit',
      '& > *': {
        marginBottom: theme.spacing(0.5),
      },
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    flexShrink: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  hierarchicalItem: {
    position: 'relative',
    '.menu-item-children > &': {
      '&:before': {
        content: '""',
        display: 'block',
        width: theme.spacing(1),
        height: '0',
        borderTop: `1px solid ${theme.palette.grey.light}`,
        position: 'absolute',
        top: '18px',
        left: '0',
      },
    },
  },
  hierarchicalChildren: {
    marginLeft: theme.spacing(2),
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      width: 0,
      position: 'absolute',
      top: 0,
      bottom: '18px',
      left: 0,
      borderLeft: `1px solid ${theme.palette.grey.light}`,
    },
  },
});

function AutoComplete(props) {
  const {
    classes, className, items, onSelect, onChange, onClick, placeholder, startIcon,
    label, suggestedTags, hierarchical, ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (items.length > 0) {
      setSuggestions(getSuggestions(items, hierarchical));
    } else {
      setSuggestions([]);
    }
  }, [items]);
  const ref = useRef();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="searchbox"
      tabIndex="0"
      className={[className, classes.root].filter(value => !!value).join(' ')}
      onClick={onClick}
    >
      <Downshift
        onStateChange={({ inputValue, type }) => {
          if (type === Downshift.stateChangeTypes.changeInput) {
            onChange(inputValue);
          }
        }}
        onSelect={(item, { clearSelection, clearItems, closeMenu }) => {
          if (item) {
            onSelect(item);
            clearSelection();
            clearItems();
            closeMenu();
          }
        }}
        itemToString={item => item && (item.label || item.name)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          inputValue,
          isOpen,
          clearSelection,
        }) => (
          <div className={classes.container}>
            {renderInput({
              label,
              fullWidth: true,
              classes,
              ref,
              startIcon,
              InputProps: getInputProps({
                placeholder,
                onFocus: () => setIsFocused(true),
                onBlur: () => {
                  clearSelection();
                  setIsFocused(false);
                },
              }),
              ...rest,
            })}
            <div {...getMenuProps()}>
              {isOpen && <FlowPaper className={classes.paper}>
                {suggestions && suggestions.length > 0 &&
                  inputValue && inputValue.length > 0 &&
                  suggestions.map(suggestion =>
                    (hierarchical
                      ? renderHierarchicalSuggestion({
                        classes,
                        suggestion,
                        itemProps: getItemProps({ item: suggestion }),
                        getItemProps,
                      })
                      : renderSuggestion({
                        suggestion,
                        itemProps: getItemProps({ item: suggestion }),
                      })),
                  )}
                {suggestions.length === 0 && <MenuItem component={'p'}>No item is found</MenuItem>}
              </FlowPaper>}
              {!isOpen && suggestedTags.length > 0 &&
                <Fade in={isFocused}><FlowPaper className={`${classes.paper} ${classes.paperPadded}`}>
                  {suggestedTags.map(tag => (<Tag
                    label={tag.name}
                    key={tag.id}
                    onClick={() => {
                      ref.current.focus();
                      onSelect(tag);
                    }}
                  />))}
                </FlowPaper></Fade>
              }
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  startIcon: PropTypes.node,
  label: PropTypes.string,
  suggestedTags: PropTypes.array,
  hierarchical: PropTypes.bool,
  className: PropTypes.string,
};

AutoComplete.defaultProps = {
  placeholder: 'Search',
  startIcon: <SearchIcon color={'primary'} />,
  label: null,
  suggestedTags: [],
  hierarchical: false,
  onClick: null,
  className: '',
};

export default withStyles(styles)(AutoComplete);
