import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FIELD_URL_KEYWORDS } from '../../../constants/story/storyFields';
import { updateStoryField } from '../../../actions/story';

import TextField from '../../ui/builder/TextField';

const LABEL = 'URL Keywords';
const PLACEHOLDER = 'Type the keywords here';

const URLKeywords = (props) => {
  const { value, updateStoryField: updateField } = props;
  return (
    <TextField
      label={LABEL}
      placeholder={PLACEHOLDER}
      value={value}
      onChange={event => updateField(FIELD_URL_KEYWORDS, event.target.value)}
    />
  );
};

URLKeywords.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  value: PropTypes.string,
};

URLKeywords.defaultProps = {
  value: '',
};

export default connect(
  ({ story: { [FIELD_URL_KEYWORDS]: value } }) => ({ value }),
  { updateStoryField },
)(URLKeywords);
