import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { productPriceCompare } from '../../../../actions/product';

import Image from '../../../embed/Image';
import EditorPreview from '../../../editor/EditorPreview';
import DetailList from '../../../common/DetailList';

import SectionTitle from '../../../common/SectionTitle';
import {
  FIELD_BRAND,
  FIELD_IMAGE,
  FIELD_MODEL,
  FIELD_PRICE,
  FIELD_TITLE,
  FIELD_VENDOR_NAME,
  FIELD_VENDOR_LINK,
  FIELD_BODY, FIELD_PRICE_COMPARISON,
} from '../../../../constants/product/productFields';

import { islinkValid } from '../../../product/AffiliateLinkCheck';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(0, -3),
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.border.primary.main}`,
    },
    '&:hover': {
      background: theme.palette.background.hover,
    },
  },
  actions: {
    textAlign: 'right',
    visibility: 'hidden',
    '.product:hover &': {
      visibility: 'visible',
    },
  },
  image: {
    maxHeight: 250,
    width: '100%',
  },
  icon: {
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '1em',
    },
  },
  iconWarning: {
    color: theme.palette.secondary.main,
    '& svg': {
      fontSize: '1em',
    },
  },
});

const Product = (props) => {
  const {
    classes,
    index,
    product: {
      [FIELD_TITLE]: title,
      [FIELD_PRICE]: price,
      [FIELD_BRAND]: brand,
      [FIELD_MODEL]: model,
      [FIELD_VENDOR_NAME]: vendorName,
      [FIELD_VENDOR_LINK]: vendorLink,
      [FIELD_IMAGE]: image,
      [FIELD_BODY]: body,
      [FIELD_PRICE_COMPARISON]: priceComparison,
    },
    linkCheckResult,
    priceComparisonResult,
    actions,
    productPriceCompare: onCompareRequest,
  } = props;
  const [affiliateStats, setAffiliateStats] = useState({});
  const [comparisonResult, setComparisonResult] = useState([]);
  useEffect(() => {
    setAffiliateStats(linkCheckResult[vendorLink] || {});
  }, [vendorLink, linkCheckResult]);
  useEffect(() => {
    if (priceComparison) {
      onCompareRequest(priceComparison);
    }
  }, [onCompareRequest, priceComparison]);
  useEffect(() => {
    setComparisonResult(priceComparisonResult[priceComparison] || []);
  }, [priceComparisonResult, priceComparison]);
  return (
    <div className={`${classes.wrapper} product`}>
      <Grid container spacing={2}>
        <Grid item xs={actions ? 10 : 12}>
          <SectionTitle>{index + 1} - {title}</SectionTitle>
        </Grid>
        {actions && <Grid item xs={2} className={classes.actions}>
          {actions}
        </Grid>}
        <Grid item xs={3}>
          {image && image.data && <Image className={classes.image} data={image.data} /> }
        </Grid>
        <Grid item xs={7}>
          <SectionTitle variant={'h3'} margin>Description</SectionTitle>
          {body && <EditorPreview content={body} />}
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle variant={'h3'} margin>Info</SectionTitle>
              <DetailList
                items={[
                  ['Brand', brand || ''],
                  ['Model', model || ''],
                  ['Vendor', vendorName || ''],
                  ['Price', `£${price || '0.00'}`],
                ]}
              />
            </Grid>
            <Grid item xs={10}>
              <SectionTitle variant={'h3'}>Affiliate Link</SectionTitle>
            </Grid>
            <Grid item xs={2}>
              {islinkValid(affiliateStats) && <span className={classes.icon}>
                <CheckIcon />
              </span>}
              {!islinkValid(affiliateStats) && <span className={classes.iconWarning}>
                <ReportProblemOutlinedIcon />
              </span>}
            </Grid>
            <Grid item xs={10}>
              <SectionTitle variant={'h3'}>Price Comparison</SectionTitle>
            </Grid>
            <Grid item xs={2}>
              {comparisonResult.length > 0 && <span className={classes.icon}>
                <CheckIcon />
              </span>}
              {comparisonResult.length === 0 && <span className={classes.iconWarning}>
                <CloseIcon />
              </span>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Product.propTypes = {
  productPriceCompare: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  actions: PropTypes.node,
  linkCheckResult: PropTypes.object,
  priceComparisonResult: PropTypes.object,
};

Product.defaultProps = {
  actions: null,
  linkCheckResult: {},
  priceComparisonResult: {},
};

export default withStyles(styles)(connect(
  ({
    product: { linkCheckResult, priceComparisonResult },
  }) => ({ linkCheckResult, priceComparisonResult }),
  { productPriceCompare },
)(Product));
