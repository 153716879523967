import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import * as jsondiffpatch from 'jsondiffpatch';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';

import { compose, pipe } from '../components/helper/functional';
import BodyComponent from '../components/builder/body/BodyComponent';
import { exportedStore, THEME } from '../index';
import ImageWrap from '../components/media/Image';
import {
  ARTICLE_FIELD_MAPPING_API_KEYED,
  ARTICLE_EXTRA_FIELDS,
} from '../constants/article/articleFields';
import { EXTRA_FIELD_KEYS as EDITION_EXTRA_FIELD_KEYS } from '../constants/edition/editionFields';
import { IMAGE, LISTBULLETED, RELATED_ARTICLE } from '../constants/builder/builder';
import { defaultState as articleDefaultState } from '../reducers/article/article';
import { defaultState as editionDefaultState } from '../reducers/edition/edition';
import { PAGE_TYPE_ARTICLE, PAGE_TYPE_SINGLE_IMAGE } from '../constants/edition/edition';

const TYPE_BOOL = 'boolean';
const TYPE_NUMBER = 'number';
const TYPE_STRING = 'string'; // dont need diff text change comparation
const TYPE_TEXT = 'text';
const TYPE_TAXONOMY_TERM = 'taxonomy_term';
const TYPE_MEDIA = 'media';
const TYPE_ARRAY = 'array';
const TYPE_DATE = 'date';
const TYPE_JSON = 'json';

const SKIP_FIELD = [
  'changed', 'field_article_updated', 'field_extra_json', 'path', 'field_sections_with_parents',
  'field_pugpig_publish_date', 'field_pugpig_updated_time',
  'field_article_created', 'field_article_publish', 'field_article_display_date',
];
const modifyPayloadField = list => list.map(([key, field]) => {
  switch (key) {
    case 'field_commenting':
      // reverse commenting value as the label on the UI is Hide Commenting.
      return [key, [{
        value: !field[0].value,
      }]];
    default:
      return [key, field];
  }
});

const modifyPayloadObject = pipe(
  Object.entries,
  modifyPayloadField,
  Object.fromEntries,
);

const isDate = field => field[0] && field[0].value && field[0].format
  && moment(field[0].value, field[0].format, true).isValid();

const isBool = (field) => {
  if (typeof field === 'boolean') return true;
  return field[0] && field[0].value && typeof field[0].value === 'boolean';
};

const isNumber = field =>
  field[0] && field[0].value && typeof field[0].value === 'number';

const isText = field => field[0] && field[0].value && typeof field[0].value === 'string';

const isString = field => typeof field === 'string';

const isTaxonomyTerm = field =>
  field[0] && field[0].target_type && field[0].target_type === TYPE_TAXONOMY_TERM;

const isMedia = field =>
  field[0] && field[0].target_type && field[0].target_type === TYPE_MEDIA;

const isJSONAsString = (field) => {
  if (isText(field)) {
    try {
      const data = JSON.parse(field[0].value);
      // skip note field
      if (data.length > 0 && data[0].note) {
        return false;
      }
      return Array.isArray(data);
    } catch (ex) {
      return false;
    }
  }
  return false;
};

const fieldType = (prev, next) => {
  if (isBool(prev) || isBool((next))) {
    return TYPE_BOOL;
  }

  if (isNumber(prev) || isNumber(next)) {
    return TYPE_NUMBER;
  }

  if (isDate(prev) || isDate(next)) {
    return TYPE_DATE;
  }

  if (isString(prev) || isString(next)) {
    return TYPE_STRING;
  }

  if (isJSONAsString(prev) || isJSONAsString(next)) {
    return TYPE_JSON;
  }

  if (isText(prev) || isText(next)) {
    return TYPE_TEXT;
  }

  if (isTaxonomyTerm(prev) || isTaxonomyTerm(next)) {
    return TYPE_TAXONOMY_TERM;
  }

  if (isMedia(prev) || isMedia(next)) {
    return TYPE_MEDIA;
  }

  if (Array.isArray(prev) || Array.isArray(next)) {
    return TYPE_ARRAY;
  }

  return null;
};

const diffArrayPatch = (left, right) => {
  const diffpatcher = jsondiffpatch.create({});
  const delta = diffpatcher.diff(left, right);
  return (<div
    dangerouslySetInnerHTML={{
      __html: jsondiffpatch.formatters.html.format(delta, left),
    }}
  />);
};

const diffText = (before, after) =>
  diffArrayPatch(before.toString(), after.toString());

const processBasic = (prev, next) => {
  const findValue = (field) => {
    let val = '';
    if (!Array.isArray(field)) { // can be boolean or number, string
      val = field;
    } else if (field.length > 0) {
      val = field[0].value;
    }
    return val;
  };
  const convertBoolText = (text) => {
    const value = text.toString();
    if (/true|false/.test(text)) {
      return value === 'true' ? 'On' : 'Off';
    }
    return value;
  };
  const process = compose(convertBoolText, findValue);
  const before = process(prev);
  const after = process(next);

  if (before === after) return null;

  return diffText(before, after);
};

const processDate = (prev, next) => {
  const before = prev.length > 0 ? moment(prev[0].value).format('lll') : null;
  const after = next.length > 0 ? moment(next[0].value).format('lll') : null;

  if (before === after) return null;
  return diffText(before, after);
};

const processText = (prev, next) => {
  const left = prev.length > 0 ? prev[0].value : '';
  const right = next.length > 0 ? next[0].value : '';

  if (left === right) return null;

  return diffText(left, right);
};

const containPwampProp = term => term.pwamp;

const processTaxonomyTerm = (prev, next) => {
  if (JSON.stringify(prev) === JSON.stringify(next)) return null;
  const getTaxonomyRepresentative = ({ pwamp: { name } }) =>
    name;

  const left = prev.length > 0 ? prev.filter(containPwampProp).map(getTaxonomyRepresentative) : [];
  const right = next.length > 0 ? next.filter(containPwampProp).map(getTaxonomyRepresentative) : [];
  return diffArrayPatch(left, right);
};

const processMedia = (prev, next) => {
  if (JSON.stringify(prev) === JSON.stringify(next)) return null;

  const getMediaRepresentative = ({ target_id: id, pwamp: { type } }) =>
    `Media type: ${type} with id: ${id}`;

  const left = prev.length > 0 ? prev.filter(containPwampProp).map(getMediaRepresentative) : [];
  const right = next.length > 0 ? next.filter(containPwampProp).map(getMediaRepresentative) : [];

  return diffArrayPatch(left, right);
};

const processArray = (prev, next) => {
  if (JSON.stringify(prev) === JSON.stringify(next)) return null;

  const left = prev.length > 0 ? prev.join(', ') : '';
  const right = next.length > 0 ? next.join(', ') : '';

  return diffText(left, right);
};

const processBody = (prev, next) => {
  const valid = list => Array.isArray(list) && list.length > 0;
  let left = valid(prev) ? prev[0].value : '[]';
  let right = valid(next) ? next[0].value : '[]';
  if (left === right) return null;

  left = JSON.parse(left);
  right = JSON.parse(right);

  const convertComponent = component => ReactDOMServer.renderToStaticMarkup(
    <MuiThemeProvider theme={THEME}>
      <Provider store={exportedStore}>
        {component}
      </Provider>
    </MuiThemeProvider>);

  const removeClassAttr = str => str.replace(/\s?class="[^"]*"/g, '');

  const converter = compose(
    removeClassAttr,
    convertComponent,
  );

  const getComponentAsHtml = (comp, index) => {
    switch (comp.type) {
      case IMAGE: {
        const { workflow, ...restProp } = comp.data;
        return converter(
          <ImageWrap {...restProp} caption={comp.data.title} src={comp.data.url} />,
        );
      }
      case RELATED_ARTICLE: {
        // simplify related article preview
        const component = {
          type: LISTBULLETED,
          data: {
            markup: `<ul>${comp.data.links.map(article => `<li><a href="${article.href}">${article.title}</a></li>`)}</ul>`,
          },
        };
        return converter(<BodyComponent data={component} position={index} onlyMainComponent />);
      }
      default:
        return converter(<BodyComponent data={comp} position={index} onlyMainComponent />);
    }
  };

  left = left.map(getComponentAsHtml);
  right = right.map(getComponentAsHtml);

  return diffArrayPatch(left, right);
};

const compare = (indexes, revisions) => {
  const rows = [];
  const [a, b] = indexes;
  const { payload: previousPayload } = revisions[a];
  const { payload: nextPayload } = revisions[b];

  let prevExtra = previousPayload.field_extra_json?.length > 0 ?
    JSON.parse(previousPayload.field_extra_json[0].value) : {};
  let nextExtra = nextPayload.field_extra_json?.length > 0 ?
    JSON.parse(nextPayload.field_extra_json[0].value) : {};

  // make sure the payload has default value, otherwise the comparison wont work. Need to be in
  // pair
  let extraDefaultValues = ARTICLE_EXTRA_FIELDS.reduce((acc, key) => ({
    ...acc,
    [key]: articleDefaultState[key],
  }), {});

  if (nextPayload.field_pugpig_extra_json?.length > 0) {
    prevExtra = JSON.parse(nextPayload.field_pugpig_extra_json[0].value);
    // load edition extra instead of article
    const { bundle: [{ target_id: bundleName }] } = previousPayload;
    let pageType;
    if (bundleName === 'pugpig_single_image') {
      pageType = PAGE_TYPE_SINGLE_IMAGE;
    } else if (bundleName === 'pugpig_article') {
      pageType = PAGE_TYPE_ARTICLE;
    }
    if (pageType) {
      extraDefaultValues = EDITION_EXTRA_FIELD_KEYS[pageType].reduce((acc, key) => ({
        ...acc,
        [key]: editionDefaultState[key],
      }), {});
    }
  }

  if (nextPayload.field_pugpig_extra_json?.length > 0) {
    nextExtra = JSON.parse(nextPayload.field_pugpig_extra_json[0].value);
  }

  const prevMergedFields = modifyPayloadObject({
    ...extraDefaultValues, ...previousPayload, ...prevExtra,
  });
  const nextMergedFields = modifyPayloadObject({
    ...extraDefaultValues, ...nextPayload, ...nextExtra,
  });

  Object.keys(prevMergedFields)
    .filter(key => !SKIP_FIELD.includes(key))
    .forEach((key) => {
      if (nextMergedFields[key]) { // has the pair?
        const prevProp = prevMergedFields[key];
        const nextProp = nextMergedFields[key];
        switch (fieldType(prevProp, nextProp)) {
          case TYPE_DATE: {
            const result = processDate(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_STRING:
          case TYPE_NUMBER:
          case TYPE_BOOL: {
            const result = processBasic(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_TEXT: {
            const result = processText(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_TAXONOMY_TERM: {
            const result = processTaxonomyTerm(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_MEDIA: {
            const result = processMedia(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_ARRAY: {
            const result = processArray(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          case TYPE_JSON: {
            const result = processBody(prevProp, nextProp);
            rows.push(result ? [key, result] : null);
            break;
          }
          default:
            console.warn(`Unhandled field type ${key}`);
            rows.push(null);
        }
      }
    });
  return rows
    .filter(x => x)
    .map(([key, ...rest]) => {
      if (ARTICLE_FIELD_MAPPING_API_KEYED[key]) {
        return [ARTICLE_FIELD_MAPPING_API_KEYED[key].label, ...rest];
      }
      return [key, ...rest];
    });
};

export default compare;
