import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line react/display-name
const VoucherIcon = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props} viewBox={'0 0 75 73'}>
    <path d="M74.4 0.149994L45.2 73H29.9L0.699951 0.149994H14.3C15.7666 0.149994 16.95 0.499992 17.85 1.19999C18.75 1.86666 19.4333 2.73333 19.9 3.79999L34 42.8C34.6666 44.5 35.3166 46.3667 35.95 48.4C36.5833 50.4 37.1833 52.5 37.75 54.7C38.2166 52.5 38.7333 50.4 39.3 48.4C39.9 46.3667 40.5333 44.5 41.2 42.8L55.2 3.79999C55.5666 2.86666 56.2166 2.03333 57.15 1.29999C58.1166 0.533327 59.3 0.149994 60.7 0.149994H74.4Z" fill="black" />
  </SvgIcon>
));

export default VoucherIcon;
