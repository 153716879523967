import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import GalleryItems from '../embed/GalleryList';

import { gallerySearch } from '../../actions/dialog';


const style = () => ({
  content: {
    marginTop: 0,
  },
});

const GalleryLibraryDialog = (props) => {
  const { classes, gallerySearch: onGallerySearch, onSelectGallery, ...rest } = props;

  useEffect(() => {
    onGallerySearch({
      bundle: 'gallery',
      q: '',
      limit: 10,
      offset: 0,
    });
  }, []);

  return (
    <Dialog title={'Gallery library'} maxWidth={'md'} {...rest}>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12} />
        <GalleryItems
          onSearchGallery={onGallerySearch}
          onSelectGallery={onSelectGallery}
        />
      </Grid>
    </Dialog>
  );
};

GalleryLibraryDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  gallerySearch: PropTypes.func.isRequired,
  onSelectGallery: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(
  () => ({}),
  { gallerySearch },
)(GalleryLibraryDialog));
