import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AddIcon from '@material-ui/icons/Add';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';

import UserTable from '../components/table/user/UserTable';
import UserEditDialog from '../components/dialog/UserEditDialog';
import { setSelectedUser } from '../actions/users';
import Button from '../components/ui/buttons/Button';

const printDate = (date) => {
  if (date === '0') return 'Never';
  return moment
    .unix(date)
    .format('DD MMMM, YYYY');
};

const cellFormat = [
  { id: 'names', display: user => user.name },
  { id: 'roles', display: user => user.roles.filter(r => r !== 'cms_internal' && r !== 'authenticated').join(',\n') },
  { id: 'status', display: user => (Number(user.status) ? 'Active' : 'Blocked') },
  { id: 'date', display: user => printDate(user.access) },
];

export const UsersListBreadcrumb = [
  {
    title: 'User Manager',
    slug: 'users',
  },
];

const UsersList = ({
  users, selectedPublication, setSelectedUser: setUser,
}) => {
  const [open, setOpen] = useState(false);
  const actions = [
    {
      type: 'edit',
      action: (user) => {
        setUser(user);
        setOpen(true);
      },
    },
  ];
  const handleAddUser = () => setUser({ name: '', publications: [selectedPublication], roles: [] });
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={UsersListBreadcrumb}>
        <PageToolbarButtons>
          <Button
            onClick={() => {
              handleAddUser();
              setOpen(true);
            }}
            variant={'contained'}
            startIcon={<AddIcon />}
          >Add user</Button>
        </PageToolbarButtons>
      </PageToolbar>}
    >
      <PageContent>
        <UserTable data={users} cellFormat={cellFormat} actions={actions} />
      </PageContent>
      {open && <UserEditDialog
        open={open}
        handleClose={() => setOpen(false)}
        onSave={() => setOpen(false)}
      />}
    </Page>
  );
};

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  selectedPublication: PropTypes.object.isRequired,
};

UsersList.defaultProps = {
  users: [],
};

export default connect(
  ({ usersList: { users }, frame: { selectedPublication } }) => ({ users, selectedPublication }),
  { setSelectedUser },
)(UsersList);
