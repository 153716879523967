import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  withLatestFrom,
  filter,
  mergeMap,
  takeUntil,
  merge,
  debounceTime,
} from 'rxjs/operators';

import { ajax } from 'rxjs/ajax';
import { of, timer } from 'rxjs';

import {
  TWITTER_ADD_KEYWORD,
  TWITTER_ADD_USER_HANDLE,
  TWITTER_ON_LOAD,
  TWITTER_REMOVE_KEYWORD,
  TWITTER_REMOVE_USER_HANDLE,
  TWITTER_ON_DISPOSE,
  TWITTER_FETCH_SUCCESS,
  TWITTER_REQUEST_REJECTED,
  TWITTER_FETCH,
  TWITTER_SEARCH_USER, TWITTER_SEARCH_USER_SUCCESS,
} from '../../constants/actionTypes';
import apiCatchError from '../helper/notification';

const POLLING_INTERVAL = 15000;

export const subscribeToTwitterStream = (action$, state$) =>
  action$.pipe(
    ofType(
      TWITTER_ADD_USER_HANDLE,
      TWITTER_REMOVE_USER_HANDLE,
      TWITTER_ADD_KEYWORD,
      TWITTER_REMOVE_KEYWORD,
      TWITTER_ON_LOAD,
    ),
    withLatestFrom(state$, (a, b) => b),
    filter(({ twitter: { keywords, users } }) => keywords.length > 0 || users.length > 0),
    mergeMap(({ twitter: { keywords, users } }) =>
      timer(0, POLLING_INTERVAL).pipe(
        switchMap(() => {
          const keys = keywords.map(encodeURIComponent).join(' OR ');
          const froms = users.map(u => `from:${u}`).join(' OR ');
          const count = 20;
          return of({ type: TWITTER_FETCH }).pipe(
            merge(ajax.getJSON(`/api/twitter/search?q=${keys} ${froms}&count=${count}`).pipe(
              map(({ statuses }) => ({ type: TWITTER_FETCH_SUCCESS, value: statuses })),
              apiCatchError()
            )),
          );
        }),
        takeUntil(action$.pipe(ofType(
          TWITTER_ADD_USER_HANDLE,
          TWITTER_REMOVE_USER_HANDLE,
          TWITTER_ADD_KEYWORD,
          TWITTER_REMOVE_KEYWORD,
          TWITTER_ON_DISPOSE,
        ))),
        apiCatchError(TWITTER_REQUEST_REJECTED),
      ),
    ),
  );

export const searchUser = action$ => action$.pipe(
  ofType(TWITTER_SEARCH_USER),
  debounceTime(500),
  filter(({ value }) => value && value.length > 2),
  switchMap(({ value }) =>
    ajax.getJSON(`/api/twitter/users?q=${value}&include_entities=false`).pipe(
      map(users => ({
        type: TWITTER_SEARCH_USER_SUCCESS,
        value: users.map(({ screen_name: screenName }) => ({ label: screenName, screenName })) })),
      apiCatchError(),
    )),
);
