import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BaseCheckbox from '@material-ui/core/Checkbox';

const Checkbox = (props) => {
  const { label, checked, ...rest } = props;
  return (
    <FormControlLabel
      control={
        <BaseCheckbox
          checked={checked}
          {...rest}
        />
      }
      label={label}
    />
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

Checkbox.defaultProps = {
};

export default Checkbox;
