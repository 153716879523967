import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { push } from 'connected-react-router';

import {
  LOGIN_CONTEXT_LOGIN,
  LOGIN_CONTEXT_MFA,
  LOGIN_CONTEXT_MFA_ENABLE,
  LOGIN_CONTEXT_PASSWORD,
} from 'constants/login';
import Logo from 'components/header/Logo';
import BsLogo from 'components/header/BsLogo';
import LoginBackground from 'components/login/LoginBackground';
import Notifications from 'components/common/Notifications';
import LoginMfa from 'components/login/LoginMfa';
import LoginPassword from 'components/login/LoginPassword';
import LoginContent from '../components/login/LoginContent';
import LoginMfaEnable from "../components/login/LoginMfaEnable";

const styles = theme => ({
  main: {
    display: 'block', // Fix IE 11 issue
    background: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  body: {
    flexDirection: 'column',
    height: '100vh',
    zIndex: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      zIndex: 'auto',
      flexDirection: 'row',
      '& > *': {
        width: '50%',
      },
    },
  },
  brightsites: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1, 0, 4),
    color: theme.palette.brightsites.brand,
    fontSize: 20,
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(1, 0, 8),
      fontSize: 30,
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, 0),
    },
    '& > span': {
      width: 24,
      margin: theme.spacing(0, 0.5, 0, 1),
      [theme.breakpoints.up('sm')]: {
        width: 36,
        margin: theme.spacing(0, 0.5, 0, 2),
      },
    },
  },
  logo: {
    margin: '0 auto',
    '& svg': {
      height: 50,
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      '& svg': {
        height: 80,
      },
    },
  },
  logoWrapper: {
    [theme.breakpoints.up('md')]: {
      zIndex: 10,
      transition: 'opacity 1000ms',
      '&.login-enter-active': {
        opacity: 0,
      },
      '&.login-enter-done': {
        opacity: 0,
      },
    },
  },
});

// Ensure a valid state defaulting to LOGIN_CONTEXT_LOGIN
const getLoginContext = context =>
  [
    LOGIN_CONTEXT_LOGIN,
    LOGIN_CONTEXT_PASSWORD,
    LOGIN_CONTEXT_MFA,
    LOGIN_CONTEXT_MFA_ENABLE,
  ].includes(context)
    ? context
    : LOGIN_CONTEXT_LOGIN;

const Login = ({ classes }) => {
  const { mfa, context: loginContext, complete } = useSelector((state) => state.login);
  const [context, setContext] = useState(getLoginContext(loginContext));
  const [inState, setInState] = useState(false);
  const dispatch = useDispatch();
  const handleOnEntered = () => dispatch(push('/'));

  useEffect(() => {
    setContext(getLoginContext(loginContext));
  }, [loginContext]);
  useEffect(() => {
    setInState(complete);
  }, [complete]);
  
  return (
    <main className={classes.main}>
      <LoginBackground inState={inState} />
      <Notifications />
      <Grid container justify="center" alignItems="center" className={classes.body}>
        <CSSTransition
          in={inState}
          timeout={1000}
          classNames="login"
          onEntered={handleOnEntered}
        >
          <Grid item className={classes.logoWrapper}>
            <span className={classes.logo}>
              <Logo />
            </span>
            <div className={classes.brightsites}>by <BsLogo /> brightsites</div>
          </Grid>
        </CSSTransition>
        <Grid item>
          {context === LOGIN_CONTEXT_MFA_ENABLE && <LoginMfaEnable />}
          {context === LOGIN_CONTEXT_MFA && <LoginMfa secret={mfa.secret} />}
          {context === LOGIN_CONTEXT_PASSWORD && <LoginPassword />}
          {context === LOGIN_CONTEXT_LOGIN && <LoginContent />}
        </Grid>
      </Grid>
    </main>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
};

export default withStyles(styles)(Login);
