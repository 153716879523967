import {
  AUTHOR_HIGHLIGHT,
  CONTENT_SOURCE,
  CONTENT_SOURCE_CUSTOM,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  CONTENT_SOURCE_VOCAB,
  PINNED_ARTICLES,
} from '../components/layout/constants';
import { ARTICLE_COUNT_MAX, ARTICLE_COUNT_MIN } from '../constants/components';
import generateKey from './generateKey';
import {
  DOMAIN_ES,
  DOMAIN_INDY,
  DOMAIN_INDY_ES,
  ES_LEAD_REQUIRED,
  ES_RELATED_LINK_COUNT,
  INDY_RELATED_LINK_COUNT,
} from '../constants/domainConfig';
import { FIELD_PINNED } from '../constants/layout/layout';
import { VOCAB_LAYOUTS } from '../constants/vocab';

export const CONTENT_SOURCE_PREPROCESS_ACTIONS = [
  CONTENT_SOURCE, CONTENT_SOURCE_MANUAL_LIST_SECTION,
];

export const buildComponentInstance = (component) => {
  const componentInstance = { ...component, id: generateKey() };
  switch (componentInstance.type) {
    case AUTHOR_HIGHLIGHT:
      componentInstance[CONTENT_SOURCE] = CONTENT_SOURCE_CUSTOM;
      componentInstance[CONTENT_SOURCE_VOCAB] = [{
        type: 'authors',
        data: null,
      }];
      break;
    default:
      break;
  }
  return componentInstance;
};

export const processManualArticleIndexes = (components, clearArticles = false) => {
  const listCounts = {};
  const lists = {};
  components.forEach((component) => {
    if (
      typeof component[CONTENT_SOURCE] !== 'undefined' &&
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
      component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL
    ) {
      const listId = component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id;
      lists[listId] = typeof lists[listId] !== 'undefined' ? lists[listId] + 1 : 1;
    }
  });
  const groupedLists = Object.entries(lists).filter(([, count]) => count > 1).map(([id]) => id);
  return components.map((component) => {
    if (
      typeof component[CONTENT_SOURCE] !== 'undefined' &&
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
      component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL
    ) {
      const listId = component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id;
      const articleCount = (typeof ARTICLE_COUNT_MAX[component.type] !== 'undefined')
        ? ARTICLE_COUNT_MAX[component.type]
        : 1;
      const articleOffset = (typeof listCounts[listId] !== 'undefined') ? listCounts[listId] : 0;
      const relativeCount = (typeof component[PINNED_ARTICLES] !== 'undefined')
        ? articleCount - Object.values(component[PINNED_ARTICLES]).filter(value => !!value).length
        : articleCount;
      component.manualArticleIndexes = [];
      component.manualListIndex = groupedLists.indexOf(listId.toString());
      for (let i = articleOffset; i < articleOffset + relativeCount; i += 1) {
        component.manualArticleIndexes.push(i);
      }
      listCounts[listId] = articleOffset + relativeCount;
    }
    if (clearArticles) {
      delete component.articles;
    }
    return { ...component };
  });
};

export const getArticleCountFromType = type => (
  typeof ARTICLE_COUNT_MAX[type] !== 'undefined' ? ARTICLE_COUNT_MAX[type] : 0
);

export const getArticleMinCountFromType = type => (
  typeof ARTICLE_COUNT_MIN[type] !== 'undefined' ? ARTICLE_COUNT_MIN[type] : 0
);

export const getComponentArticleCount = component => (component[FIELD_PINNED]
  ? getArticleCountFromType(component.type) - Object.values(component[FIELD_PINNED]).filter(pinned => !!pinned).length
  : getArticleCountFromType(component.type));

export const getComponentDefaultIndexes = (component) => {
  const count = getComponentArticleCount(component);
  return [...Array(count).keys()];
};

export const getComponentLeadRequired = (domain, componentType, index) => {
  if (domain === DOMAIN_ES) {
    return (
      typeof ES_LEAD_REQUIRED[componentType] !== 'undefined' &&
      typeof ES_LEAD_REQUIRED[componentType][index] !== 'undefined'
    ) ? ES_LEAD_REQUIRED[componentType][index] : false;
  }
  return true;
};

export const getRelatedLinkCount = (domain, componentType, index) => {
  switch (domain) {
    case DOMAIN_INDY_ES:
    case DOMAIN_INDY:
      return (
        typeof INDY_RELATED_LINK_COUNT[componentType] !== 'undefined' &&
        typeof INDY_RELATED_LINK_COUNT[componentType][index] !== 'undefined'
      ) ? INDY_RELATED_LINK_COUNT[componentType][index] : 0;
    case DOMAIN_ES:
      return (
        typeof ES_RELATED_LINK_COUNT[componentType] !== 'undefined' &&
        typeof ES_RELATED_LINK_COUNT[componentType][index] !== 'undefined'
      ) ? ES_RELATED_LINK_COUNT[componentType][index] : 0;
    default:
      return 0;
  }
};

export const getDefaultLayoutFromVocab = (layout, vocab) => {
  if (
    VOCAB_LAYOUTS[vocab] &&
    layout[VOCAB_LAYOUTS[vocab]] &&
    layout[VOCAB_LAYOUTS[vocab]].length > 0
  ) {
    return layout[VOCAB_LAYOUTS[vocab]].find(({ data: { section } }) => !section);
  }
  return null;
};
