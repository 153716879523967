import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import { ApplicationOutline } from 'mdi-material-ui';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';

import { PROP_SRC, PROP_HEIGHT, PROP_WIDTH } from '../defaults';

const VALID = /^http[s]?:\/\//;

const IframeElement = (props) => {
  const { attributes, children, element, className } = props;
  const [valid, setValid] = useState(true);
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Iframe'} Icon={ApplicationOutline} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              margin={'none'}
              placeholder={'Insert iframe url'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_SRC]: e.target.value }, { at: path });
                setValid(VALID.test(e.target.value));
              }}
              value={element[PROP_SRC] || ''}
              error={!valid}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin={'none'}
              label={'width'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_WIDTH]: e.target.value }, { at: path });
              }}
              value={element[PROP_WIDTH] || ''}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              margin={'none'}
              label={'height'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_HEIGHT]: e.target.value }, { at: path });
              }}
              value={element[PROP_HEIGHT] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

IframeElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default IframeElement;
