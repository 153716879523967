import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dialog from './common/Dialog';
import SectionAutoComplete from '../autocomplete';
import { SECTIONS } from '../../constants/vocab';

import TabPanel from '../ui/TabPanel';
import TextField from '../ui/builder/TextField';
import Button from '../ui/buttons/Button';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '30vh',
    margin: theme.spacing(-1, -3),
  },
  ui: {
    marginTop: theme.spacing(2),
  },
  compRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const TAB_SECTION = 'section';
const TAB_CHAIN = 'chain';
const TAB_EXTERNAL = 'external';

const TABS = [
  { label: 'Section menu', value: TAB_SECTION },
  { label: 'Chain menu', value: TAB_CHAIN },
  { label: 'External menu', value: TAB_EXTERNAL },
];

const MenuItemDialog = (props) => {
  const { classes, sections, selectSection, selectChain, chain, ...rest } = props;
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [tab, setTab] = useState(TAB_SECTION);
  return (
    <Dialog
      tabs={TABS.filter(({ value }) => !(chain.items.length < 1 && value === TAB_CHAIN))}
      tab={tab}
      setTab={setTab}
      maxWidth={'md'}
      {...rest}
    >
      <div className={classes.root}>
        <TabPanel value={tab} index={TAB_SECTION}>
          <SectionAutoComplete vocab={SECTIONS} onSelect={term => term && selectSection(term)} />
        </TabPanel>
        {chain.items.length > 0 && <TabPanel value={tab} index={TAB_CHAIN}>
          <div className={classes.compRoot}>
            {chain.items.map((item) => {
              const { name: itemName, uuid } = item;
              return (<ListItem button key={uuid} onClick={() => selectChain(item)}>
                <ListItemText primary={itemName} />
              </ListItem>);
            })}
          </div>
        </TabPanel>}
        <TabPanel value={tab} index={TAB_EXTERNAL}>
          <Grid container justify={'space-between'} spacing={2} wrap={'nowrap'}>
            <Grid item xs={4}>
              <TextField
                id={'Name'}
                label={'Name'}
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id={'Path'}
                label={'External Link'}
                value={path}
                onChange={e => setPath(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid className={classes.ui} container justify={'flex-end'} spacing={2}>
            <Grid item>
              <Button
                disabled={!(name && path)}
                variant={'contained'}
                onClick={() => {
                  selectSection({ name, path });
                  rest.handleClose();
                }}
              >Add menu link</Button>
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </Dialog>
  );
};

MenuItemDialog.propTypes = {
  sections: PropTypes.array.isRequired,
  chain: PropTypes.array.isRequired,
  selectSection: PropTypes.func.isRequired,
  selectChain: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(MenuItemDialog);
