import { filter, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { NewsLetterSignup, TrendingEditorsPickPlusDMPU, Video } from '../../constants/components';
import apiCatchError from '../helper/notification';

import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  EDITORS_PICK_PRIORITY_OPTION,
  NEWSLETTER_TARGET,
  PLAYLIST_ID,
} from '../../components/layout/constants';
import { CONTEXT_ARTICLE_SELECTOR, CONTEXT_COMPONENT } from '../../constants/contexts';
import { PAGE_ENTER_LAYOUT } from '../../constants/actionTypes/route';
import {
  LAYOUT_ADD_COMPONENT,
  LAYOUT_COMPONENT_SET_PROPERTY,
  LAYOUT_COMPONENTS_LOADED,
  LAYOUT_CONTEXT_RESET,
  LAYOUT_CONTEXT_SET,
  LAYOUT_SET_MANUAL_LIST_DEFAULT,
} from '../../constants/actionTypes/layout';
import { MANUAL_LIST_CREATE, MANUAL_LIST_FETCH } from '../../constants/actionTypes/manualList';
import { EDITORS_PICK_PRIORITY_TAB_KEY } from '../../components/layout/contexts/componentProperties/EditorsPickOptions';
import { DATASTATE_LOCAL_ADD_KEYED_ITEM } from '../../constants/actionTypes/dataState';
import { FIELD_COMPONENTS, FIELD_MANUAL_LISTS } from '../../constants/layout/layoutFields';
import { componentSetProp } from '../../actions/layout';
import { getArticleCountFromType } from '../../utils/layoutHelper';

export const loadLayoutComponents = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  withLatestFrom(state$),
  mergeMap(([, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/components/section/${domain}`).pipe(
      map(response => ({
        type: LAYOUT_COMPONENTS_LOADED,
        value: Object.keys(response)
          .map(key => ({
            type: key,
            name: response[key],
            id: key,
          })),
      })),
      apiCatchError(),
    ),
  ),
);

export const setComponentDefaultsOnAdd = (action$, state$) => action$.pipe(
  ofType(LAYOUT_ADD_COMPONENT),
  mergeMap(({ index }) => action$.pipe(
    ofType(
      DATASTATE_LOCAL_ADD_KEYED_ITEM,
    ),
    filter(({ prop, orderIndex }) =>
      (prop === FIELD_COMPONENTS && orderIndex === index),
    ),
    withLatestFrom(state$),
    mergeMap(([
      { value: { item: component } },
      { frame: { selectedPublication: { publicationConfig } } },
    ]) => {
      const articleCount = getArticleCountFromType(component.type);
      const actions = [];
      switch (component.type) {
        case TrendingEditorsPickPlusDMPU:
          actions.push(
            componentSetProp(
              component.id,
              EDITORS_PICK_PRIORITY_TAB_KEY,
              EDITORS_PICK_PRIORITY_OPTION[0].id,
            ),
          );
          break;
        case Video: {
          actions.push(componentSetProp(component.id, PLAYLIST_ID, ''));
          const settings = publicationConfig.videoComponentConfig?.settings;
          if (settings) {
            Object.entries(settings).forEach(([key, { options }]) => {
              actions.push(componentSetProp(component.id, key, options[0]));
            });
          }
          break;
        }
        case NewsLetterSignup:
          actions.push(
            componentSetProp(
              component.id,
              NEWSLETTER_TARGET,
              publicationConfig.newsletters ? publicationConfig.newsletters[0].value : '',
            ),
          );
          break;
        default:
          break;
      }
      if (articleCount > 0) {
        actions.push(
          componentSetProp(component.id, CONTENT_SOURCE, CONTENT_SOURCE_MANUAL),
          { type: LAYOUT_SET_MANUAL_LIST_DEFAULT, value: component },
          { type: LAYOUT_CONTEXT_SET, value: { type: CONTEXT_ARTICLE_SELECTOR } },
        );
      } else {
        actions.push({
          type: LAYOUT_CONTEXT_SET,
          value: { type: CONTEXT_COMPONENT, data: component },
        });
      }
      return from(actions);
    }),
    take(1),
  )),
);

export const resetLayoutContext = action$ => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  map(() => ({ type: LAYOUT_CONTEXT_RESET })),
);

export const fetchListOnChangeProperty = (actions$, state$) => actions$.pipe(
  ofType(LAYOUT_COMPONENT_SET_PROPERTY),
  withLatestFrom(state$),
  filter(([{ value: { prop, value } }, { serverState: { [FIELD_MANUAL_LISTS]: manualLists } }]) =>
    prop === CONTENT_SOURCE_MANUAL_LIST_SECTION &&
    value !== null &&
    typeof manualLists[value.id] === 'undefined'),
  map(([{ value: { value } }]) => ({
    type: MANUAL_LIST_FETCH,
    value: { id: value.id },
  })),
);

export const createDefaultListOnChangeProperty = actions$ => actions$.pipe(
  ofType(LAYOUT_COMPONENT_SET_PROPERTY),
  filter(({ value: { prop, value, quiet } }) =>
    prop === CONTENT_SOURCE && value === CONTENT_SOURCE_MANUAL && !quiet),
  map(({ value: { componentId } }) => ({
    type: MANUAL_LIST_CREATE,
    value: {
      label: 'default',
      isDefault: true,
      callback: response => [componentSetProp(
        componentId,
        CONTENT_SOURCE_MANUAL_LIST_SECTION,
        {
          name: response.name[0].value,
          id: response.id[0].value,
          uuid: response.uuid[0].value,
        },
      )],
    },
  })),
);
