import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import {
  addRelatedArticle,
  sortRelatedArticle,
  removeRelatedArticle,
  overrideProperty,
  setEditionLockField,
} from '../../../../actions/edition';
import { PagePanel, PagePanelContent, PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';
import IconButton from '../../../ui/buttons/IconButton';
import EditionArticlesDialog from '../../../dialog/EditionArticlesDialog';

import AvatarNumbered from '../../../ui/AvatarNumbered';
import TextField from '../../../ui/TextField';
import { FIELD_RELATED_ARTICLE } from '../../../../constants/edition/editionFields';
import FieldLockContainer from '../../../fieldLock/FieldLockContainerDepricated';
import Button from '../../../ui/buttons/Button';
import {
  ArrowSortableDown,
  ArrowSortableList,
  ArrowSortableListItem,
  ArrowSortableUp,
} from '../../../ui/ArrowSortable';

const styles = theme => ({
  listItem: {
    paddingRight: theme.spacing(18),
  },
  listItemAvatar: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiListItemAvatar-root': {
      minWidth: 'auto',
    },
  },
  listItemContent: {
    padding: theme.spacing(1, 0),
  },
});

const RelatedArticle = ({
  classes, relatedArticle,
  lockedFields, sessionLockedFields,
  addRelatedArticle: onAddArticle,
  removeRelatedArticle: onRemoveArticle,
  sortRelatedArticle: onSortArticle,
  overrideProperty: onChangeProperty,
  setEditionLockField: lockField,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const lock = sessionLockedFields.filter(({ field }) => field === FIELD_RELATED_ARTICLE);
  const value = lock.length > 0 ? lock[0].value : relatedArticle;
  return (
    <PagePanel>
      <FieldLockContainer
        field={FIELD_RELATED_ARTICLE}
        onUnlock={() => {
          lockField([FIELD_RELATED_ARTICLE, relatedArticle]);
        }}
      >
        <PagePanelHeading>
          <SectionTitle
            hasChanges={Object.keys(lockedFields).indexOf(FIELD_RELATED_ARTICLE) !== -1}
          >Related article</SectionTitle>
        </PagePanelHeading>
        <PagePanelContent>
          <Grid container spacing={2}>
            {value.length > 0 && <Grid item xs={12}>
              <ArrowSortableList>
                {value.map(({ title, subheadline, id: articleId }, index) =>
                  (<ArrowSortableListItem index={index} key={`article-${articleId}`} className={classes.listItem}>
                    <Grid container spacing={2} wrap={'nowrap'}>
                      <Grid item className={classes.listItemAvatar}>
                        <ListItemAvatar>
                          <AvatarNumbered>{index + 1}</AvatarNumbered>
                        </ListItemAvatar>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container direction={'column'} spacing={2} className={classes.listItemContent}>
                          <Grid item xs={12}>
                            <TextField
                              label={'Headline'}
                              value={title}
                              onChange={e => onChangeProperty([index, 'title', e.target.value])}
                              margin={'none'}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label={'Sub headline'}
                              value={subheadline}
                              onChange={e => onChangeProperty([index, 'subheadline', e.target.value])}
                              margin={'none'}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <ListItemSecondaryAction>
                      <IconButton>
                        <RemoveIcon onClick={() => onRemoveArticle(index)} />
                      </IconButton>
                      {index < value.length - 1 && <ArrowSortableDown
                        onClick={() => onSortArticle([index, index + 1])}
                      />}
                      {index > 0 && <ArrowSortableUp
                        onClick={() => onSortArticle([index, index - 1])}
                      />}
                    </ListItemSecondaryAction>
                  </ArrowSortableListItem>),
                )}
              </ArrowSortableList>
            </Grid>}
            <Grid item container justify={'center'}>
              <Button
                onClick={() => {
                  setOpenDialog(true);
                }}
                startIcon={<AddCircleOutlineIcon />}
              >Add article</Button>
            </Grid>
          </Grid>
        </PagePanelContent>
      </FieldLockContainer>
      {openDialog && <EditionArticlesDialog
        open={openDialog}
        onSelect={(article) => {
          setOpenDialog(false);
          let subHeadlineAsText = '';
          if (article.subheadline) {
            let div = document.createElement('div');
            div.innerHTML = article.subheadline;
            subHeadlineAsText = div.textContent;
            div = null;
          }
          const { title, id, hero } = article;
          onAddArticle({
            title,
            id,
            hero,
            subheadline: subHeadlineAsText,
          });
        }}
        handleClose={() => setOpenDialog(false)}
        onClose={() => setOpenDialog(false)}
      />}
    </PagePanel>
  );
};

RelatedArticle.propTypes = {
  classes: PropTypes.object.isRequired,
  addRelatedArticle: PropTypes.func.isRequired,
  relatedArticle: PropTypes.array.isRequired,
  removeRelatedArticle: PropTypes.func.isRequired,
  sortRelatedArticle: PropTypes.func.isRequired,
  overrideProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  lockedFields: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(
  ({
    edition: { relatedArticle },
    fieldLock: { lockedFields, sessionLockedFields },
  }) => ({ relatedArticle, lockedFields, sessionLockedFields }),
  {
    addRelatedArticle,
    sortRelatedArticle,
    removeRelatedArticle,
    overrideProperty,
    setEditionLockField,
  },
)(RelatedArticle));

