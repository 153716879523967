import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { saveRedirect } from '../../actions/redirect';

import TextField from '../ui/TextField';
import Button from '../ui/buttons/Button';
import { REDIRECT_LOOP_DETECTED_ERROR_MESSAGE } from '../../constants/article/article'

export const initialState = {
  source: '',
  destination: '',
};

export const invalidRedirect = ({ source, destination}) => {
  const errors = [];
  const articlePattern = /(\d+)(.html)?$/;
  if (!/^(http|\/)/.test(source)) {
    errors.push(['source', 'Source must start with http or forward slash']);
  }
  if (!/^(http|\/)/.test(destination)) {
    errors.push(['destination', 'Source must start with http or forward slash']);
  }
  if (source === destination) {
    errors.push(['destination', 'Destination must have different value with source']);
  }
  if (articlePattern.test(source) && articlePattern.test(destination)) {
    const [, sourceId] = (source).match(articlePattern);
    const [, destinationId] = (destination).match(articlePattern);
    if (sourceId === destinationId) {
      errors.push(['destination', REDIRECT_LOOP_DETECTED_ERROR_MESSAGE]);
    }
  }
  if (errors.length === 0) {
    return false;
  }

  return errors.reduce((acc, [key, message]) => {
    acc[key] = message;
    return acc;
  }, {});
};

const AddNewRedirect = ({ saveRedirect: onSave, onSubmit }) => {
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const invalid = invalidRedirect(state);
    if (!invalid) {
      setErrors({});
    } else {
      setErrors(invalid);
    }
  }, [state])
  return (
    <form
      onSubmit={(e) => {
        const invalid = invalidRedirect(state);
        if (!invalid) {
          onSave(state);
          setErrors({});
          setState(initialState);
          if (onSubmit) {
            onSubmit();
          }
        } else {
          setErrors(invalid);
        }
        e.preventDefault();
      }}
    >
      <Grid container spacing={2}>
        {[
          {
            label: 'Source',
            id: 'source',
          },
          {
            label: 'Destination',
            id: 'destination',
          }].map(({ label, id }) =>
          (<Grid key={id} item xs={6}>
            <TextField
              error={errors[id]}
              helperText={errors[id] ? errors[id] : ''}
              label={label}
              value={state[id]}
              onChange={(e) => {
                setState({
                  ...state,
                  [id]: e.target.value,
                });
              }}
            />
          </Grid>))}
      </Grid>
      <Grid container direction={'row-reverse'} spacing={2}>
        <Grid item>
          <Button disabled={Object.keys(errors).length > 0} type={'submit'} color={'primary'} variant={'contained'}>Add</Button>
        </Grid>
      </Grid>
    </form>
  );
};

AddNewRedirect.propTypes = {
  onSubmit: PropTypes.func,
  saveRedirect: PropTypes.func.isRequired,
};

AddNewRedirect.defaultProps = {
  onSubmit: null,
};

export default connect(
  () => ({}),
  { saveRedirect },
)(AddNewRedirect);

