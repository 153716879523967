import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from "../../common/context/ContextPanel";
import FieldLockToggle from "../../fieldLock/form/FieldLockToggle";
import {
  FIELD_IS_PREMIUM,
  FIELD_IS_SENSITIVE,
  FIELD_DISABLE_AUTO_GALLERY,
  FIELD_DISABLE_IMPORT_UPDATE,
  FIELD_DISABLE_AFFILIATE,
  FIELD_HIDE_NEWSLETTER_SIGNUP,
  FIELD_HIDE_COMMENTING,
  FIELD_DISABLE_ALL_ADS,
  FIELD_HIDE_DISPLAY_ADS,
  FIELD_HIDE_HARDCODED_ADS,
  FIELD_HIDE_PREROLL_ADS,
  FIELD_URL_REDIRECT,
  FIELD_NID,
  FIELD_DISPLAY_DATE_PREF,
  FIELD_VANITY_DATE,
  FIELD_PRIORITY,
  FIELD_COMPARE_STATE,
  FIELD_ARTICLE_TYPE,
  FIELD_LUXURY_COMMERCIAL,
} from "../../../constants/article/articleFields";
import { setLocalProp } from "../../../actions/dataState";
import { FEATURE_PREMIUM_TOGGLE_ON } from "../../../constants/features";
import ArticleFieldLockToggle from "./fields/ArticleFieldLockToggle";
import FieldLockTextfield from "../../fieldLock/form/FieldLockTextfield";
import {
  ARTICLE_TYPE_REGEX_LUXURY,
  ARTICLE_TYPE_REGEX_PRODUCT,
  DISPLAY_DATE_PREFERENCE_OPTS,
  DISPLAY_DATE_VANITY,
  EDITORIAL_PRIORITY_OPTS,
  REDIRECT_LOOP_DETECTED_ERROR_MESSAGE,
} from "../../../constants/article/article";
import FieldLockSelect from "../../fieldLock/form/FieldLockSelect";
import FieldLockDateTime from "../../fieldLock/form/FieldLockDateTime";
import ToggleSwitch from "../../ui/ToggleSwitch";
import { checkArticleType, hasFeatures } from "../../helper/utils";
import { getFromState } from "utils/stateHelper";

const ArticleSettings = ({
  id,
  features,
  isSensitive,
  adsDisabled,
  urlRedirect,
  displayDate,
  setLocalProp: setProp,
  dataState,
}) => {
  const [showRedirect, setShowRedirect] = useState(false);
  const type = getFromState(FIELD_ARTICLE_TYPE, dataState);
  const isType = checkArticleType(type);
  const isProduct = isType(ARTICLE_TYPE_REGEX_PRODUCT);
  const isLuxury = isType(ARTICLE_TYPE_REGEX_LUXURY);
  const hasFeature = hasFeatures(features);
  useEffect(() => {
    setShowRedirect(!!urlRedirect);
  }, [urlRedirect]);
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={"full"}>
        {hasFeature(FEATURE_PREMIUM_TOGGLE_ON) && (
          <FieldLockToggle field={FIELD_IS_PREMIUM} label={"Premium article"} />
        )}
        <ArticleFieldLockToggle
          field={FIELD_HIDE_NEWSLETTER_SIGNUP}
          label={"Hide newsletter sign up"}
        />

        <FieldLockToggle
          field={FIELD_DISABLE_AUTO_GALLERY}
          label={"Disable auto-gallery"}
        />
        <FieldLockToggle
          field={FIELD_DISABLE_IMPORT_UPDATE}
          label={"Disable import updates"}
        />
        <FieldLockToggle
          field={FIELD_DISABLE_AFFILIATE}
          label={"Disable affiliates"}
        />
        <FieldLockToggle
          field={FIELD_IS_SENSITIVE}
          label={"Sensitive article"}
        />
        <FieldLockToggle
          field={FIELD_DISABLE_ALL_ADS}
          label={"Disable all ads"}
        />
        {isSensitive && !adsDisabled && (
          <FieldLockToggle
            field={FIELD_HIDE_DISPLAY_ADS}
            label={"Hide display ads"}
          />
        )}
        {isSensitive && !adsDisabled && (
          <FieldLockToggle
            field={FIELD_HIDE_HARDCODED_ADS}
            label={"Hide hardcoded ads"}
          />
        )}
        {isSensitive && !adsDisabled && (
          <FieldLockToggle
            field={FIELD_HIDE_PREROLL_ADS}
            label={"Hide pre-roll ads"}
          />
        )}
        <ArticleFieldLockToggle
          field={FIELD_HIDE_COMMENTING}
          label={"Hide comments"}
        />
        <ToggleSwitch
          label={"URL redirect"}
          value={!!showRedirect}
          onChange={(e) => {
            setShowRedirect(e.target.checked);
            if (!e.target.checked) {
              setProp(FIELD_URL_REDIRECT, "");
            }
          }}
        />
        {showRedirect && (
          <FieldLockTextfield
            validation={(value) => {
              // @todo reformat this test as can produce false positives
              const regExp = new RegExp(id);
              const valid = !regExp.test(value);
              if (valid) return { valid };
              return {
                valid: false,
                message: REDIRECT_LOOP_DETECTED_ERROR_MESSAGE,
              };
            }}
            field={FIELD_URL_REDIRECT}
          />
        )}
        <FieldLockSelect
          field={FIELD_DISPLAY_DATE_PREF}
          items={DISPLAY_DATE_PREFERENCE_OPTS}
          label={"Date to display"}
        />
        {isProduct && (
          <FieldLockSelect
            field={FIELD_COMPARE_STATE}
            items={["PRO/CON", "Compare", "All"]}
            label="Compare display"
          />
        )}
        {displayDate === DISPLAY_DATE_VANITY && (
          <FieldLockDateTime field={FIELD_VANITY_DATE} label={"Vanity date"} />
        )}
        <FieldLockSelect
          field={FIELD_PRIORITY}
          items={EDITORIAL_PRIORITY_OPTS}
          label={"Editorial priority"}
        />
        {isLuxury && (
          <FieldLockTextfield
            field={FIELD_LUXURY_COMMERCIAL}
            label={"Background colour"}
          />
        )}
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ArticleSettings.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  id: PropTypes.number,
  isSensitive: PropTypes.bool,
  adsDisabled: PropTypes.bool,
  urlRedirect: PropTypes.string,
  features: PropTypes.array,
  displayDate: PropTypes.string,
};

ArticleSettings.defaultProps = {
  id: null,
  isSensitive: false,
  adsDisabled: false,
  urlRedirect: "",
  displayDate: null,
  features: [],
};

export default connect(
  ({
    dataState: {
      [FIELD_NID]: id,
      [FIELD_IS_SENSITIVE]: isSensitive,
      [FIELD_DISABLE_ALL_ADS]: adsDisabled,
      [FIELD_URL_REDIRECT]: urlRedirect,
      [FIELD_DISPLAY_DATE_PREF]: displayDate,
    },
    dataState,
    frame: {
      selectedPublication: { features },
    },
  }) => ({
    id,
    isSensitive,
    adsDisabled,
    urlRedirect,
    features,
    displayDate,
    dataState,
  }),
  { setLocalProp }
)(ArticleSettings);
