import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { PREVIEW_MODE_DESKTOP, PREVIEW_MODE_MOBILE, PREVIEW_MODE_HIDDEN } from '../../../constants/preview';
import { setLayoutPreviewMode } from '../../../actions/layout';
import PreviewUI from '../../ui/components/PreviewUI';

const styles = () => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
});

export const getPreviewClass = (mode) => {
  switch (mode) {
    case PREVIEW_MODE_MOBILE:
      return 'preview-mode-mobile';
    case PREVIEW_MODE_DESKTOP:
      return 'preview-mode-desktop';
    default:
      return '';
  }
};

const LayoutPreviewUI = (props) => {
  const {
    classes,
    previewDocked,
    setLayoutPreviewMode: setPreviewMode,
    previewMode,
  } = props;

  const desktopToggle = () => setPreviewMode(
    previewMode === PREVIEW_MODE_DESKTOP ? PREVIEW_MODE_HIDDEN : PREVIEW_MODE_DESKTOP,
  );
  const mobileToggle = () => setPreviewMode(
    previewMode === PREVIEW_MODE_MOBILE ? PREVIEW_MODE_HIDDEN : PREVIEW_MODE_MOBILE,
  );

  return (
    <div className={classes.root}>
      <PreviewUI
        active={previewMode}
        isDocked={previewDocked}
        desktopToggle={desktopToggle}
        mobileToggle={mobileToggle}
      />
    </div>
  );
};

LayoutPreviewUI.propTypes = {
  classes: PropTypes.object.isRequired,
  previewDocked: PropTypes.bool.isRequired,
  previewMode: PropTypes.string.isRequired,
  setLayoutPreviewMode: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  ({ layout: { previewDocked, previewMode } }) => ({ previewDocked, previewMode }),
  { setLayoutPreviewMode },
)(LayoutPreviewUI));
