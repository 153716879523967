import { PURGE } from 'redux-persist';
import {
  RESET_PASSWORD_INPUT_CHANGE,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_SUCCESS,
} from '../../constants/actionTypes';

const defaultState = {
  password: '',
  confirmPassword: '',
  failureMessage: '',
  username: '',
  token: '',
};

export default (state = defaultState, action) => {
  const newState = {};
  switch (action.type) {
    case RESET_PASSWORD_INPUT_CHANGE:
      newState[action.input] = action.value;
      return { ...state, ...newState, failureMessage: ''};

    case RESET_PASSWORD_INIT:
      newState[action.input] = action.value;
      return { ...state, ...newState};

    case RESET_PASSWORD_FAILED:
      return {...state, failureMessage: action.value};

    case RESET_PASSWORD_SUCCESS:
      return {...state, ...defaultState};

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
