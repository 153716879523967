import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography, Grid } from '@material-ui/core';

import { FIELD_BOOKEND, FIELD_SHOW_BOOKEND } from '../../../constants/story/storyFields';
import { updateStoryField, setStoryContext } from '../../../actions/story';
import { CONTEXT_STORY, CONTEXT_ARTICLE_SELECTOR } from '../../../constants/contexts';

import ToggleSwitch from '../../ui/ToggleSwitch';
import ArticleDropZone from './bookend/ArticleDropZone';

const ARTICLE_COUNT_MAX = 3;

const Bookend = (props) => {
  const {
    updateStoryField: updateField,
    setStoryContext: setContext,
    bookend: { articles = [] },
    showBookend,
  } = props;
  while (articles.length < ARTICLE_COUNT_MAX) {
    articles.push({});
  }
  if (articles.length > ARTICLE_COUNT_MAX) {
    articles.length = ARTICLE_COUNT_MAX;
  }
  return (
    <>
      <ToggleSwitch
        label={'Show bookend'}
        value={showBookend}
        onChange={(e) => {
          updateField(FIELD_SHOW_BOOKEND, e.target.checked);
          if (e.target.checked) {
            setContext(CONTEXT_ARTICLE_SELECTOR);
          } else {
            setContext(CONTEXT_STORY);
          }
        }}
      />
      {showBookend && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h2'} color={'primary'}>Related Articles</Typography>
        </Grid>
        {articles.map((article, index) => (<Grid key={`bookend-article-${index}`} item xs={12}>
          <ArticleDropZone index={index} article={article} />
        </Grid>))}
      </Grid>}
    </>
  );
};

Bookend.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  setStoryContext: PropTypes.func.isRequired,
  bookend: PropTypes.object,
  showBookend: PropTypes.bool,
};

Bookend.defaultProps = {
  bookend: {
    articles: [],
  },
  showBookend: false,
};

export default connect(
  ({
    story: { [FIELD_BOOKEND]: bookend, [FIELD_SHOW_BOOKEND]: showBookend },
  }) => ({ bookend, showBookend }),
  { updateStoryField, setStoryContext },
)(Bookend);
