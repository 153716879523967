import InfoboxElement from './components/InfoboxElement';

export const ELEMENT_INFOBOX = 'infobox';
export const PROP_TITLE = 'title';
export const PROP_SUBHEADING = 'subHeadline';
export const PROP_MARKUP = 'markup';
export const PROP_ALIGN = 'align';

export const DEFAULTS_INFOBOX = {
  infobox: {
    component: InfoboxElement,
    type: ELEMENT_INFOBOX,
    rootProps: {
      className: 'slate-infobox',
    },
  },
};
