import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { debounceTime, filter, map, mergeMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  LAYOUT_FETCH_PREVIEW, LAYOUT_FETCH_PREVIEW_REJECTED, LAYOUT_FETCH_PREVIEW_SUCCESS,
  LAYOUT_PREVIEW_SET_OVERRIDE, LAYOUT_PREVIEW_SET_PROP,
} from '../../constants/actionTypes/layout';
import { PAGE_ENTER_LAYOUT, PAGE_LEAVE_LAYOUT } from '../../constants/actionTypes/route';
import {
  DATASTATE_EXTERNAL_SET_PROPERTY, DATASTATE_LOCAL_DISPOSE,
  DATASTATE_LOCAL_SET_PROPERTY,
  DATASTATE_SERVER_SET_DATA,
} from '../../constants/actionTypes/dataState';
import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_CUSTOM,
  CONTENT_SOURCE_CUSTOM_CHAIN,
  CONTENT_SOURCE_CUSTOM_SECTION,
  CONTENT_SOURCE_DEFAULT,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  CONTENT_SOURCE_OPTIONS,
  CONTENT_SOURCE_VOCAB,
} from '../../components/layout/constants';
import { FIELD_COMPONENTS, FIELD_COMPONENTS_ORDER, FIELD_MANUAL_LISTS } from '../../constants/layout/layoutFields';
import { FIELD_ARTICLES, FIELD_ARTICLES_ORDER } from '../../constants/manualList/manualListFields';
import { getFromStates, getPropState, getState } from '../../utils/stateHelper';
import {
  FIELD_OVERRIDES,
  FIELD_PINNED, LAYOUT_PATH_PATTERN,
  OVERRIDE_HERO,
  OVERRIDE_RELATED_LINKS,
  OVERRIDE_UPDATED,
} from '../../constants/layout/layout';
import LayoutEntity from '../../entities/LayoutEntity';
import { getArticleCountFromType, getComponentArticleCount } from '../../utils/layoutHelper';
import { PREVIEW_MAPPING, PREVIEW_CAPSULES } from '../../constants/preview';
import { FIELD_HERO_IMAGE } from '../../constants/article/articleFields';
import apiCatchError from '../helper/notification';
import { VOCAB_LAYOUTS_API } from '../../constants/vocab';

const RELOAD_TRIGGER_PROPS = [
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_CUSTOM,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  CONTENT_SOURCE_VOCAB,
  CONTENT_SOURCE_OPTIONS,
  CONTENT_SOURCE_CUSTOM_SECTION,
  CONTENT_SOURCE_CUSTOM_CHAIN,
  CONTENT_SOURCE_DEFAULT,
  FIELD_COMPONENTS_ORDER,
  FIELD_ARTICLES_ORDER,
];

const processPreviewData = (key, value) => {
  if (typeof PREVIEW_MAPPING[key] !== 'undefined') {
    return { [PREVIEW_MAPPING[key]]: value };
  }
  if (PREVIEW_CAPSULES.includes(key)) {
    if (typeof value.name !== 'undefined' && value.name === '') {
      return { [key]: false };
    }
  }
  return { [key]: value };
};

const getRelatedLinkPreviewData = (localState, serverState, externalState, propChain) => ({
  [OVERRIDE_RELATED_LINKS]:
    getFromStates([...propChain, OVERRIDE_RELATED_LINKS], localState, serverState, externalState),
});

export const fetchLayoutPreview = (action$, state$) => action$.pipe(
  ofType(LAYOUT_FETCH_PREVIEW),
  withLatestFrom(state$, (action, state) => state),
  filter(({ router: { location: { pathname } } }) => pathname.match(LAYOUT_PATH_PATTERN)),
  mergeMap((state) => {
    const {
      localState, serverState, externalState,
      router: { location: { pathname } },
      layout: { selectedTerm },
      frame: { selectedPublication },
    } = state;
    const bundle = pathname.match(LAYOUT_PATH_PATTERN);
    const layoutEntity = new LayoutEntity(VOCAB_LAYOUTS_API[bundle[1]]);
    const payload = layoutEntity.getPayloadFromData(
      getState(localState, serverState, externalState),
      serverState,
      selectedPublication,
    );
    payload[`field_${VOCAB_LAYOUTS_API[bundle[1]]}`] = [{
      target_id: Number(selectedTerm.id),
      target_type: 'taxonomy_term',
    }];

    return ajax.post(`/api/preview/layout?domain=${selectedPublication.domain}`, payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(response => of({
        type: LAYOUT_FETCH_PREVIEW_SUCCESS,
        value: response.response,
      })),
      apiCatchError(LAYOUT_FETCH_PREVIEW_REJECTED),
    );
  }),
);

export const handlePreviewFetchOnDataChange = action$ => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY, DATASTATE_EXTERNAL_SET_PROPERTY),
    filter(({ value: { prop } }) => (RELOAD_TRIGGER_PROPS.includes(prop))),
    map(() => ({
      type: LAYOUT_FETCH_PREVIEW,
    })),
    takeUntil(action$.pipe(ofType(PAGE_LEAVE_LAYOUT))),
  )),
);

export const handlePreviewFetchOnServerDataChange = action$ => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_SERVER_SET_DATA, DATASTATE_LOCAL_DISPOSE),
    map(() => ({
      type: LAYOUT_FETCH_PREVIEW,
    })),
    takeUntil(action$.pipe(ofType(PAGE_LEAVE_LAYOUT))),
  )),
);

// @todo refactor how preview receives props, can simply be sent the entire localState
export const handlePreviewSetPropOnDataChange = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY, DATASTATE_EXTERNAL_SET_PROPERTY),
    filter(({ value: { prop, propChain } }) => (
      prop &&
      !RELOAD_TRIGGER_PROPS.includes(prop) &&
      propChain &&
      propChain.length === 2 &&
      propChain[0] === FIELD_COMPONENTS
    )),
    debounceTime(100),
    withLatestFrom(state$),
    mergeMap(([{ value: { prop, propChain, value } }, { serverState, localState, externalState }]) => {
      const componentOrder = getFromStates([FIELD_COMPONENTS_ORDER], localState, serverState, externalState);
      return of({
        type: LAYOUT_PREVIEW_SET_PROP,
        value: {
          delta: componentOrder ? componentOrder.indexOf(propChain[1]) : 0,
          data: {
            [prop.charAt(0).toLowerCase() + prop.slice(1)]: value,
          },
        },
      });
    }),
    takeUntil(action$.pipe(ofType(PAGE_LEAVE_LAYOUT))),
  )),
);

// @todo refactor how preview receives override props, can simply be sent the entire localState
export const handlePreviewSetOverridePropOnDataChange = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY, DATASTATE_EXTERNAL_SET_PROPERTY),
    filter(({ value: { prop, propChain } }) => (
      propChain &&
      propChain.length === 5 &&
      propChain[0] === FIELD_MANUAL_LISTS &&
      propChain[2] === FIELD_ARTICLES &&
      propChain[4] === FIELD_OVERRIDES &&
      prop !== OVERRIDE_UPDATED
    )),
    debounceTime(100),
    withLatestFrom(state$),
    mergeMap(([{ value: { prop, propChain, value } }, { serverState, localState, externalState }]) => {
      const components = LayoutEntity.getOrderedComponents(
        getPropState([FIELD_COMPONENTS], localState, serverState, externalState),
        getFromStates([FIELD_COMPONENTS_ORDER], localState, serverState, externalState),
      );
      const articleOrder = getFromStates([FIELD_MANUAL_LISTS, propChain[1], FIELD_ARTICLES_ORDER], localState, serverState, externalState);
      let delta = 0;
      let count = 0;
      let articleIndex = articleOrder.indexOf(propChain[3]);
      for (let i = 0; i < components.length; i += 1) {
        if (
          components[i][CONTENT_SOURCE_MANUAL_LIST_SECTION] &&
          parseInt(components[i][CONTENT_SOURCE_MANUAL_LIST_SECTION].id, 10) === parseInt(propChain[1], 10)
        ) {
          const componentCount = getComponentArticleCount(components[i]);
          if (count + componentCount > articleIndex) {
            delta = i;
            articleIndex -= count;
            for (let j = 0; j < getArticleCountFromType(components[i].type); j += 1) {
              if (components[i][FIELD_PINNED] && typeof components[i][FIELD_PINNED][j] !== 'undefined') {
                articleIndex += 1;
              }
              if (articleIndex === j) {
                break;
              }
            }
            break;
          } else if (components[i][FIELD_PINNED]) {
            articleIndex += Object.entries(components[i][FIELD_PINNED]).length;
          }
          count += componentCount;
        }
      }
      const getValue = () => {
        // user remove the override image, send the default image back to pwamp preview..
        if (!value && prop === OVERRIDE_HERO) {
          return [getFromStates(
            [FIELD_MANUAL_LISTS, propChain[1], FIELD_ARTICLES, propChain[3], FIELD_HERO_IMAGE],
            localState,
            serverState,
          )];
        }
        return value;
      };
      return of({
        type: LAYOUT_PREVIEW_SET_OVERRIDE,
        value: {
          delta,
          articleIndex,
          data: prop === OVERRIDE_RELATED_LINKS
            ? getRelatedLinkPreviewData(localState, serverState, externalState, propChain)
            : processPreviewData(prop, getValue()),
        },
      });
    }),
    takeUntil(action$.pipe(ofType(PAGE_LEAVE_LAYOUT))),
  )),
);

// @todo refactor how preview receives override props, can simply be sent the entire localState
export const handlePreviewSetOverridePropOnDataChangePinned = (action$, state$) => action$.pipe(
  ofType(PAGE_ENTER_LAYOUT),
  mergeMap(() => action$.pipe(
    ofType(DATASTATE_LOCAL_SET_PROPERTY, DATASTATE_EXTERNAL_SET_PROPERTY),
    filter(({ value: { prop, propChain } }) => (
      propChain &&
      propChain.length === 5 &&
      propChain[0] === FIELD_COMPONENTS &&
      propChain[2] === FIELD_PINNED &&
      propChain[4] === FIELD_OVERRIDES &&
      prop !== OVERRIDE_UPDATED
    )),
    debounceTime(100),
    withLatestFrom(state$),
    mergeMap(([{ value: { prop, propChain, value } }, { serverState, localState, externalState }]) => {
      const componentsOrder = getFromStates([FIELD_COMPONENTS_ORDER], localState, serverState, externalState);
      const getValue = () => {
        // user remove the override image, send the default image back to pwamp preview..
        if (!value && prop === OVERRIDE_HERO) {
          return [getFromStates(
            [FIELD_COMPONENTS, propChain[1], FIELD_PINNED, propChain[3], FIELD_HERO_IMAGE],
            localState,
            serverState,
          )];
        }
        return value;
      };
      return of({
        type: LAYOUT_PREVIEW_SET_OVERRIDE,
        value: {
          delta: componentsOrder.indexOf(propChain[1]),
          articleIndex: propChain[3],
          data: processPreviewData(prop, getValue()),
        },
      });
    }),
    takeUntil(action$.pipe(ofType(PAGE_LEAVE_LAYOUT))),
  )),
);
