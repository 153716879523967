import {
  INIT_FB_EMBED,
  INIT_EMBED,


}
  from '../constants/actionTypes';
import { PAGE_ENTER_LIVEBLOG_EDIT } from '../constants/actionTypes/route';
import {
  LIVEBLOG_APPEND_TWITTER_TO_BODY,
  LIVEBLOG_ARCHIVED_FETCH_LIST, LIVEBLOG_DISPOSE,
  LIVEBLOG_DISPOSE_LIST,
  LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST,
  LIVEBLOG_EDIT_POST,
  LIVEBLOG_FETCH_LIST,
  LIVEBLOG_FETCH_POSTS,
  LIVEBLOG_FETCH_POSTS_FOR_ARTICLE,
  LIVEBLOG_ON_SEARCH,
  LIVEBLOG_POST_PIN_UPDATE,
  LIVEBLOG_POST_PINNED_SORT,
  LIVEBLOG_REMOVE_POST,
  LIVEBLOG_SAVE_CONTAINER, LIVEBLOG_SAVE_POST,
  LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST,
  LIVEBLOG_SET_PROPERTY,
  LIVEBLOG_START_WATCH_SCHEDULE_POST,
} from '../constants/actionTypes/liveblog';

export const appendTwitterToBody = value => ({ type: LIVEBLOG_APPEND_TWITTER_TO_BODY, value });
export const saveLiveblogPost = () => ({ type: LIVEBLOG_SAVE_POST });
export const saveTwitterPostData = value => ({ type: LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST, value });
export const removeLiveblogPost = value => ({ type: LIVEBLOG_REMOVE_POST, value });
export const editLiveblogPost = value => ({ type: LIVEBLOG_EDIT_POST, value });
export const saveLiveblogContainer = () => ({ type: LIVEBLOG_SAVE_CONTAINER });
export const fetchLiveblogList = value => ({ type: LIVEBLOG_FETCH_LIST, value });
export const disposeLiveblogList = value => ({ type: LIVEBLOG_DISPOSE_LIST, value });
export const fetchLiveblogArchivedList = value => ({ type: LIVEBLOG_ARCHIVED_FETCH_LIST, value });
export const disposeLiveblog = () => ({ type: LIVEBLOG_DISPOSE });
export const fetchLiveblogPosts = value => ({ type: LIVEBLOG_FETCH_POSTS, value });
export const fetchLiveblogPostsForArticleComp = value =>
  ({ type: LIVEBLOG_FETCH_POSTS_FOR_ARTICLE, value });
export const initEmbed = value => ({ type: INIT_EMBED, value });
export const initFBEmbed = () => ({ type: INIT_FB_EMBED });
export const liveBlogOnSearch = value => ({ type: LIVEBLOG_ON_SEARCH, value });
export const setLiveblogProperty = value => ({ type: LIVEBLOG_SET_PROPERTY, value });
export const startWatchSchedulePost = value =>
  ({ type: LIVEBLOG_START_WATCH_SCHEDULE_POST, value });
export const disposeWatchSchedulePost = value =>
  ({ type: LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST, value });
export const liveblogPostPinUpdate = value =>
  ({ type: LIVEBLOG_POST_PIN_UPDATE, value });
export const liveblogPostSortPinned = value =>
  ({ type: LIVEBLOG_POST_PINNED_SORT, value });

export const enterLiveblogEdit = () => ({ type: PAGE_ENTER_LIVEBLOG_EDIT });
export const disposeLiveblogEdit = () => ({ type: PAGE_ENTER_LIVEBLOG_EDIT });
