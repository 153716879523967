import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  mergeMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  TERM_LIST_AUTHOR_REJECTED,
  TERM_AUTHOR_ONSAVE,
  TERM_AUTHOR_SAVED,
} from '../../constants/actionTypes';

import apiCatchError, { showSuccessNotification } from '../helper/notification';
import { AUTHORS } from '../../constants/vocab';

export const saveAuthor = action$ => action$.pipe(
  ofType(TERM_AUTHOR_ONSAVE),
  switchMap(({
    value: {
      id, name, twitter, email, location, facebook,
      snapchat, instagram, jobTitle, description, imageUrl,
      headerImageUrl, linkedin, tiktok, authorBioOverride
    },
  }) => {
    const extras = {
      instagram: instagram || null,
      snapchat: snapchat || null,
      linkedin: linkedin || null,
      tiktok: tiktok || null,
      headerImageUrl: headerImageUrl || null,
      authorBioOverride: authorBioOverride || null
    };

    const payload = {
      tid: [
        {
          value: id,
        },
      ],
      vid: [
        {
          target_id: 'authors',
          target_type: 'taxonomy_vocabulary',
        },
      ],
      name: [{
        value: name,
      }],
      field_author_email: [
        {
          value: email,
        },
      ],
      field_author_image: [
        {
          value: imageUrl,
        },
      ],
      field_author_location: [
        {
          value: location,
        },
      ],
      field_author_twitter: [
        {
          value: twitter,
        },
      ],
      field_author_facebook: [
        {
          value: facebook,
        },
      ],
      description: [
        {
          value: description,
        },
      ],
      field_author_title: [
        {
          value: jobTitle,
        },
      ],
      field_term_extra_json: [
        {
          value: JSON.stringify(extras),
        },
      ],
    };

    return ajax.patch(`/api/term/${AUTHORS}/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
      // got to refetch the term here, otherwise the path still old from the patch response above
      switchMap(() => ajax.getJSON(`/api/term/${AUTHORS}/${id}`).pipe(
        map(response => ({
          type: TERM_AUTHOR_SAVED,
          value: response,
        })),
      )),
      apiCatchError(TERM_LIST_AUTHOR_REJECTED),
    );
  }),
);

export const authorSavedNotification = action$ => action$.pipe(
  ofType(TERM_AUTHOR_SAVED),
  mergeMap(showSuccessNotification('Author is successfully saved.')),
  apiCatchError(),
);
