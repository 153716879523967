import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { CONTENT_SOURCE_MANUAL_LIST_SECTION, LABEL_TEXT } from '../../constants';
import ManualListDialogSelector from '../../../dialog/ManualListDialogSelector';
import { componentSetProp } from '../../../../actions/layout';

const styles = () => ({
  button: {
    textTransform: 'initial',
    padding: '4px 10px',
  },
  text: {
    marginRight: 5,
  },
  icon: {
    width: '0.8em',
    height: '0.8em',
    opacity: '0.7',
  },
});

const ManualListSelector = (props) => {
  const { classes, component, componentSetProp: setProp } = props;
  const { name } = (
    typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null
  ) ? component[CONTENT_SOURCE_MANUAL_LIST_SECTION] : {};
  const [isDialogOpen, openDialog] = useState(false);
  return (
    <>
      <Button
        className={classes.button}
        onClick={() => openDialog(true)}
      >
        {name && name !== 'default' && <Typography className={classes.text} variant={'body2'}>{name}</Typography>}
        <EditOutlinedIcon className={classes.icon} />
      </Button>
      {isDialogOpen && <ManualListDialogSelector
        handleClose={() => openDialog(false)}
        open={isDialogOpen}
        selectSection={(data) => {
          setProp(component.id, CONTENT_SOURCE_MANUAL_LIST_SECTION, data);
          setProp(component.id, LABEL_TEXT, data.name);
          openDialog(false);
        }}
      />}
    </>
  );
};

ManualListSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { componentSetProp },
)(ManualListSelector));
