const jsLoader = function(urls, resolve, reject, id) {
  if (typeof Promise === 'undefined') return
  var promises = urls.map(url => {
    return new Promise(function(ok, error) {
      var element = document.createElement('script')
      var parent = 'head'
      var attr = 'src'
      element.onload = ok
      element.onerror = error
      if (id && id !== 'undefined') {
        element.id = id
      }
      element.async = 'async'
      element[attr] = url
      document[parent].appendChild(element)
    })
  })
  return Promise.all(promises).then(resolve, reject)
}


export default jsLoader
