import { ELEMENT_PARAGRAPH, getNodes, setDefaults } from '@udecode/slate-plugins';
import { Transforms } from 'slate';
import { DEFAULTS_TWITTER, PROP_CONVERSATION, PROP_URL } from './defaults';
import { isValidUrl, getValidUrl } from './helper';

const withTwitter = options => (editor) => {
  const { twitter } = setDefaults(options, DEFAULTS_TWITTER);
  const { insertData } = editor;

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isValidUrl(text.trim())) {
      const [nodeP] = getNodes(editor, { match: { type: ELEMENT_PARAGRAPH } });
      // only insert on empty p
      if (
        nodeP &&
        nodeP[0].children.length === 1 &&
        nodeP[0].children[0].text === ''
      ) {
        const element = {
          type: twitter.type,
          [PROP_URL]: getValidUrl(text.trim()),
          [PROP_CONVERSATION]: false,
          children: [{ text: '' }],
        };
        Transforms.setNodes(editor, element, { at: nodeP[1] });
        return;
      }
    }

    insertData(data);
  };

  return editor;
};

export default withTwitter;
