import {
  MANUAL_LIST_SECTION_SELECTED,
  REMOVE_MANUAL_LIST,
  MANUAL_LIST_FETCH_ALL,
  MANUAL_LIST_LABEL_ADDED,
} from '../constants/actionTypes';
import {
  MANUAL_LIST_CLEAR_ITEM,
  MANUAL_LIST_INSERT_ITEM,
  MANUAL_LIST_REPLACE_ITEM,
  MANUAL_LIST_PIN_ITEM,
  MANUAL_LIST_UNPIN_ITEM,
  RELATED_LINKS_CLEAR_ITEM,
  RELATED_LINKS_INSERT_ITEM,
  RELATED_LINKS_PINNED_CLEAR_ITEM,
  RELATED_LINKS_PINNED_INSERT_ITEM,
  RELATED_LINKS_PINNED_REPLACE_ITEM,
  RELATED_LINKS_REPLACE_ITEM,
  MANUAL_LIST_PINNED_SET_PROPERTY,
  MANUAL_LIST_ITEM_SET_PROPERTY,
  MANUAL_LIST_ITEM_UNSET_PROPERTY,
  MANUAL_LIST_PINNED_UNSET_PROPERTY,
  MANUAL_LIST_SAVE,
  MANUAL_LIST_ADD_ITEM,
  MANUAL_LIST_REORDER_ITEM,
  MANUAL_LIST_SET_ITEM_SETTINGS,
  MANUAL_LIST_SET_ITEM_OVERRIDES,
  MANUAL_LIST_REMOVE_ITEM, MANUAL_LIST_SET_CONTEXT,
} from '../constants/actionTypes/manualList';

export const setLabel = value => ({ type: MANUAL_LIST_LABEL_ADDED, value });
export const selectSection = path => ({ type: MANUAL_LIST_SECTION_SELECTED, value: path });
export const fetchAllManualLists = path => ({ type: MANUAL_LIST_FETCH_ALL, value: path });

export const removeManualList = id => ({ type: REMOVE_MANUAL_LIST, value: id });
export const saveManualList = () => ({ type: MANUAL_LIST_SAVE });

export const setManualListContext = position => ({ type: MANUAL_LIST_SET_CONTEXT, value: { position } });
export const setManualListItemSettings = (pos, data) => ({ type: MANUAL_LIST_SET_ITEM_SETTINGS, value: { pos, data } });
export const setManualListItemOverrides = (pos, data) =>
  ({ type: MANUAL_LIST_SET_ITEM_OVERRIDES, value: { pos, data } });
export const removeManualListItem = value => ({ type: MANUAL_LIST_REMOVE_ITEM, value });
export const reorderManualListItem = (oldIndex, newIndex) =>
  ({ type: MANUAL_LIST_REORDER_ITEM, value: [oldIndex, newIndex] });
export const addManualListItem = value => ({ type: MANUAL_LIST_ADD_ITEM, value });

export const handleManualListItemDrop = (effect, data) => {
  switch (effect) {
    case 'copy':
      return { type: MANUAL_LIST_REPLACE_ITEM, value: data };
    default:
      return { type: MANUAL_LIST_INSERT_ITEM, value: data };
  }
};
export const clearManualListItem = (listId, itemId) =>
  ({ type: MANUAL_LIST_CLEAR_ITEM, value: { listId, itemId } });
export const setListArticleProperty = (listId, itemId, prop, value) =>
  ({ type: MANUAL_LIST_ITEM_SET_PROPERTY, value: { listId, itemId, prop, value } });
export const unsetListArticleProperty = (listId, itemId, prop) =>
  ({ type: MANUAL_LIST_ITEM_UNSET_PROPERTY, value: { listId, itemId, prop } });
export const handleRelatedLinksItemDrop = (effect, data) => {
  switch (effect) {
    case 'copy':
      return { type: RELATED_LINKS_REPLACE_ITEM, value: data };
    default:
      return { type: RELATED_LINKS_INSERT_ITEM, value: data };
  }
};
export const handlePinnedRelatedLinksItemDrop = (effect, data) => {
  switch (effect) {
    case 'copy':
      return { type: RELATED_LINKS_PINNED_REPLACE_ITEM, value: data };
    default:
      return { type: RELATED_LINKS_PINNED_INSERT_ITEM, value: data };
  }
};
export const clearRelatedLinksListItem = (listId, articleId, index) =>
  ({ type: RELATED_LINKS_CLEAR_ITEM, value: { listId, articleId, index } });
export const clearPinnedRelatedLinksListItem = (component, itemIndex, index) =>
  ({ type: RELATED_LINKS_PINNED_CLEAR_ITEM, value: { component, itemIndex, index } });
export const pinManualListItem = (article, componentId, listId, index) =>
  ({ type: MANUAL_LIST_PIN_ITEM, value: { article, componentId, listId, index } });
export const unpinManualListItem = (article, componentId, listId, itemIndex, index) =>
  ({ type: MANUAL_LIST_UNPIN_ITEM, value: { article, componentId, listId, itemIndex, index } });
export const setPinnedArticleProperty = (componentId, index, prop, value) =>
  ({ type: MANUAL_LIST_PINNED_SET_PROPERTY, value: { componentId, index, prop, value } });
export const unsetPinnedArticleProperty = (componentId, index, prop) =>
  ({ type: MANUAL_LIST_PINNED_UNSET_PROPERTY, value: { componentId, index, prop } });
