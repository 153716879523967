import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import { Table, TableBody, TableHead, TableHeadCell, TableRow, TableCell } from '../ui/table/Table';
import { liveBlogOnSearch } from '../../actions/liveblog';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';

const style = theme => ({
  table: {
    margin: theme.spacing(0, -3),
  },
  content: {
    marginTop: 0,
  },
});

const LiveblogSelectorDialog = (props) => {
  const {
    classes,
    liveBlogOnSearch: doSearch,
    selectLiveblog,
    searchResults: { containers, paging: { total } },
    ...rest
  } = props;
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);

  useEffect(() => {
    liveBlogOnSearch('');
  }, []);

  return (
    <Dialog title={'Choose a liveblog'} maxWidth={'md'} {...rest}>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <TextSearchFilter
            placeholder={'Search liveblog by title'}
            onSubmit={(textQuery) => {
              doSearch(textQuery);
              setPage(0);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow hasBorder>
                  {['Title', 'Status', 'Created', 'Last updated', ''].map(col =>
                    <TableHeadCell key={col} classes={{ root: classes.heading }} variant={'head'}>{col}</TableHeadCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {containers.length === 0 && <TableRow><TableCell scope={'row'}colSpan={5}>No liveblog is found</TableCell></TableRow>}
                {containers.length > 0 && containers.map(({ data }) => (
                  <TableRow key={data.uuid} hasActions>
                    <TableCell scope={'row'}>
                      {data.name}
                    </TableCell>
                    <TableCell scope={'row'}>
                      {Number(data.status) === 1 ? 'Active' : 'Archived'}
                    </TableCell>
                    <TableCell scope={'row'}>
                      {moment.unix(data.created).format('lll')}
                    </TableCell>
                    <TableCell scope={'row'}>
                      {moment.unix(data.lastPostEdit).format('lll')}
                    </TableCell>
                    <TableCell scope={'row'}>
                      <Button onClick={() => selectLiveblog(data)} variant={'contained'} color={'primary'} size={'small'}>Select</Button>
                    </TableCell>
                  </TableRow>
                ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={sizePerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={(e, i) => setPage(i++)}
              onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

LiveblogSelectorDialog.defaultProps = {
  items: [],
  searchResults: {
    containers: [],
    paging: {
      total: 0,
      limit: 0,
      offset: 0,
    },
  },
};

LiveblogSelectorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  selectImage: PropTypes.func.isRequired,
  liveBlogOnSearch: PropTypes.func.isRequired,
  selectLiveblog: PropTypes.func.isRequired,
  searchResults: PropTypes.object.isRequired,
};

export default withStyles(style)(
  connect(
    ({ liveblog: { searchResults } }) => ({ searchResults }),
    { liveBlogOnSearch },
  )(LiveblogSelectorDialog),
);
