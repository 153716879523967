import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  searchRevision,
  disposeRevisionList,
} from '../../../actions/revision';

import RevisionDialog from '../../dialog/RevisionDialog';
import { REVISION_EDITION } from '../../../constants/revision';
import { ContextPanelItem } from '../../common/context/ContextPanel';
import Button from '../../ui/buttons/Button';

const EditionRevisionStatus = ({
  searchRevision: onSearchRevision, revisionList, disposeRevisionList: onDisposeRevisionList,
  articleEditionFromServer,
}) => {
  const [revisionShow, setRevisionShow] = useState(false);

  useEffect(() => {
    if (articleEditionFromServer) {
      onSearchRevision({
        id: articleEditionFromServer.id[0].value,
        type: REVISION_EDITION,
      });
    }
    return () => {
      onDisposeRevisionList();
    };
  }, [articleEditionFromServer]);

  return (<ContextPanelItem label={'Revisions'}>
    {revisionList.length > 0 &&
    <Button caption textOnly onClick={() => setRevisionShow(true)}>View</Button>}
    {revisionList.length === 0 && <Typography variant={'caption'}>None</Typography>}
    {revisionShow && <RevisionDialog
      open={revisionShow}
      handleClose={() => {
        setRevisionShow(false);
      }}
    />}
  </ContextPanelItem>);
};

EditionRevisionStatus.defaultProps = {
  articleEditionFromServer: null,
};

EditionRevisionStatus.propTypes = {
  searchRevision: PropTypes.func.isRequired,
  disposeRevisionList: PropTypes.func.isRequired,
  revisionList: PropTypes.array.isRequired,
  articleEditionFromServer: PropTypes.object,
};

export default connect(
  ({
    edition: { articleEditionFromServer },
    revision: { list: revisionList },
  }) => ({ revisionList, articleEditionFromServer }),
  { searchRevision, disposeRevisionList },
)(EditionRevisionStatus);

