import { PURGE } from 'redux-persist';
import { SET_STATIC_PAGE_TYPES } from '../../constants/staticPage/contactUs';

const defaultState = {
  pageTypes: [],
  currentPageId: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_STATIC_PAGE_TYPES:
      return {
        ...state,
        pageTypes: action.value,
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
