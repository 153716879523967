import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';

import FlowPaper from '../../../common/layout/FlowPaper';
import DragHandle from '../../../ui/DragHandle';
import { FIELD_COMPONENTS_ORDER } from '../../../../constants/layout/layoutFields';

const styles = theme => ({
  root: {
    minHeight: '70px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(2),
    width: 'calc(100% - 28px)',
  },
});

const SortDragHandle = SortableHandle(({ ...rest }) => <DragHandle {...rest} />);

const ComponentContent = (props) => {
  const { classes, externalOrder, className, children } = props;
  const rootClasses = [classes.root];
  if (className !== null) {
    rootClasses.push(className);
  }
  return (
    <FlowPaper className={rootClasses.join(' ')}>
      <div className={classes.content}>
        {children && children}
      </div>
      <SortDragHandle disabled={!!externalOrder} />
    </FlowPaper>
  );
};

ComponentContent.propTypes = {
  classes: PropTypes.object.isRequired,
  externalOrder: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.node,
};

ComponentContent.defaultProps = {
  children: null,
  className: null,
  externalOrder: null,
};

export default withStyles(styles)(connect(
  ({ externalState: { [FIELD_COMPONENTS_ORDER]: externalOrder } }) => ({ externalOrder }),
  {},
)(ComponentContent));
