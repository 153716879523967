import { ofType } from 'redux-observable';
import { forkJoin, fromEvent, of } from 'rxjs';
import {
  switchMap,
  map,
  withLatestFrom,
  mapTo,
  filter,
  mergeMap,
  distinctUntilChanged, merge, takeUntil, throttleTime, catchError,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router';
import moment from 'moment';

import apiCatchError, {
  showErrorNotification,
  showSuccessNotification,
} from '../helper/notification';
import {
  EDITION_ACTION_PUBLISH,
  EDITION_DETAIL_DISPOSE, EDITION_DETAIL_ENTER,
  EDITION_DETAIL_FETCH,
  EDITION_DETAIL_FETCH_REJECTED,
  EDITION_DETAIL_FETCH_SUCCESS, EDITION_DETAIL_PUBLISH_SUCCESS,
  EDITION_DETAIL_SAVE,
  EDITION_DETAIL_SAVE_REJECTED,
  EDITION_DETAIL_SAVE_SUCCESS, EDITION_DETAIL_UNPUBLISH_SUCCESS,
  EDITION_LIST_DRAFT_FETCH_REJECTED,
  EDITION_LIST_DRAFT_FETCH_SUCCESS,
  EDITION_LIST_DRAFT_ONLOAD,
  EDITION_LIST_FETCH_REJECTED,
  EDITION_LIST_FETCH_SUCCESS,
  EDITION_LIST_ONLOAD,
  EDITION_UPDATE_PING_APP,
  EDITION_UPDATE_PING_APP_REJECTED,
  EDITION_UPDATE_PING_APP_SUCCESS,
  MOVE_ARTICLE_EDITION, MOVE_ARTICLE_REJECTED, MOVE_ARTICLE_SUCCESS,
  REMOVE_PAGE_FROM_CONTAINER,
} from '../../constants/actionTypes/edition';

import { ERROR, SHOW_NOTIFICATION } from '../../constants/actionTypes/notification';
import {
  SAVE_INITIAL_HISTORY_EDITION_DETAIL,
  SET_UNSAVED_EDITION_DETAIL,
} from '../../constants/actionTypes';
import { serialize } from '../../utils/urlHelper';

export const fetchEditionDetail = action$ =>
  action$.pipe(
    ofType(EDITION_DETAIL_FETCH),
    switchMap(({ value }) => ajax.getJSON(`/api/edition/view/${value}`).pipe(
      map(response => ({
        type: EDITION_DETAIL_FETCH_SUCCESS,
        value: response,
      })),
      apiCatchError(EDITION_DETAIL_FETCH_REJECTED),
    )),
  );

export const loadEditionListing = action$ =>
  action$.pipe(
    ofType(EDITION_LIST_ONLOAD),
    switchMap(({ value }) => ajax.getJSON(`/api/edition?${serialize(value)}`).pipe(
      map(response => ({
        type: EDITION_LIST_FETCH_SUCCESS,
        value: response,
      })),
      apiCatchError(EDITION_LIST_FETCH_REJECTED),
    )),
  );

export const loadEditionDraftListing = action$ =>
  action$.pipe(
    ofType(EDITION_LIST_DRAFT_ONLOAD),
    mergeMap(({ value }) => ajax.getJSON(`/api/edition?${serialize(value)}`).pipe(
      map(response => ({
        type: EDITION_LIST_DRAFT_FETCH_SUCCESS,
        value: {
          ...response,
          type: value.type || 'daily',
        },
      })),
      apiCatchError(EDITION_LIST_DRAFT_FETCH_REJECTED),
    )),
  );

export const removePageFromContainer = (action$, state$) => action$.pipe(
  ofType(REMOVE_PAGE_FROM_CONTAINER),
  distinctUntilChanged((a, b) => a.value === b.value),
  withLatestFrom(state$),
  switchMap(([{ value: pageId }, { edition: { editionDetail } }]) => {
    const { id, pages, key, editionType } = editionDetail;
    const payload = {
      bundle: [{
        target_id: 'pugpig_container',
        target_type: 'flowz_collection_type',
      }],
      field_pugpig_key: [{
        value: key,
      }],
      field_pugpig_edition_type: [{
        value: editionType,
      }],
      field_pugpig_pages: pages
        .filter(({ data: { id: pId } }) => pId !== pageId)
        .map(({ data: { id: pid } }) => ({ target_id: pid })),
    };
    return ajax.patch(`/api/edition/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
      mapTo({ type: EDITION_DETAIL_FETCH, value: id }),
      apiCatchError(EDITION_DETAIL_SAVE_REJECTED),
    );
  }),
);

export const movePageToOtherContainer = (action$, state$) => action$.pipe(
  ofType(MOVE_ARTICLE_EDITION),
  withLatestFrom(state$),
  switchMap(([{ value: { containerId: destinationId } }, {
    edition: { editionDetail, articleEditionFromServer: { id: [{ value: pageId }] } },
  }]) => {
    const { id: currentContainerId, pages } = editionDetail;
    const currentContainer = {
      bundle: [{
        target_id: 'pugpig_container',
        target_type: 'flowz_collection_type',
      }],
      field_pugpig_pages: pages
        .filter(({ data: { id: pId } }) => pId !== pageId)
        .map(({ data: { id: pid } }) => ({ target_id: pid })),
    };
    return ajax.getJSON(`/api/edition/view/${destinationId}`).pipe(
      mergeMap((response) => {
        const { data: { pages: destinationPages, editionType } } = response;
        const targetContainer = {
          bundle: [{
            target_id: 'pugpig_container',
            target_type: 'flowz_collection_type',
          }],
          field_pugpig_pages: [
            ...destinationPages.map(({ data: { id } }) => ({ target_id: id })),
            { target_id: pageId },
          ],
        };
        const requests = [
          ajax.patch(`/api/edition/${currentContainerId}`, currentContainer, { 'Content-Type': 'application/json' }),
          ajax.patch(`/api/edition/${destinationId}`, targetContainer, { 'Content-Type': 'application/json' }),
        ];
        return forkJoin(requests).pipe(
          mapTo({ type: MOVE_ARTICLE_SUCCESS, value: [destinationId, editionType] }),
        );
      }),
      apiCatchError(MOVE_ARTICLE_REJECTED),
    );
  }),
);

export const redirectMoveSuccess = action$ => action$.pipe(
  ofType(MOVE_ARTICLE_SUCCESS),
  map(({ value: [editionId, type] }) => push(`/edition/${type}/${editionId}`)),
);

const getEditionPayload = (editionDetail, typeOverride, statusOverride, bundle = 'daily') => {
  const { pages = [], title, key, editionDate, paid, editionImage } = editionDetail;
  let editionType = editionDetail.editionType || bundle;
  let editionStatus = editionDetail.status;
  if (!typeOverride) {
    editionType = typeOverride;
  }
  if (typeof editionStatus !== 'undefined') {
    editionStatus = statusOverride;
  }
  return {
    bundle: [{
      target_id: 'pugpig_container',
      target_type: 'flowz_collection_type',
    }],
    name: [{
      value: title,
    }],
    field_pugpig_key: [{
      value: key,
    }],
    field_pugpig_edition_type: [{
      value: editionType,
    }],
    field_pugpig_edition_date: [{
      value: editionDate,
    }],
    field_pugpig_paid: [{
      value: paid,
    }],
    field_pugpig_edition_image: editionImage && editionImage.length > 0 ? [{
      target_type: 'media',
      target_id: editionImage[0].data.mid,
    }] : [],
    field_pugpig_updated_time: [{
      value: moment().unix(),
    }],
    field_pugpig_status: [{
      value: editionStatus || 0,
    }],
    field_pugpig_pages: pages.map(({ data: { id: pageId } }) => ({ target_id: pageId })),
  };
};

const preseveEditionDetailState = payload => ({
  ...payload,
  field_pugpig_updated_time: [],
});

const getSaveEditionRequest = (...args) => {
  const [editionDetail, type, status, method] = args;
  const { id, title, key, editionDate } = editionDetail;
  if (!title || title === '') {
    return of({
      type: SHOW_NOTIFICATION,
      value: {
        message: 'Please add a title',
        variant: ERROR,
      },
    });
  }
  if (!key || key === '') {
    return of({
      type: SHOW_NOTIFICATION,
      value: {
        message: 'Please add a key',
        variant: ERROR,
      },
    });
  }
  if (!editionDate || editionDate === '') {
    return of({
      type: SHOW_NOTIFICATION,
      value: {
        message: 'Please add a date',
        variant: ERROR,
      },
    });
  }

  const payload = getEditionPayload(...args);

  let successAction;
  if (method === EDITION_ACTION_PUBLISH && payload.field_pugpig_status[0].value) {
    successAction = EDITION_DETAIL_PUBLISH_SUCCESS;
  } else if (method === EDITION_ACTION_PUBLISH && !payload.field_pugpig_status[0].value) {
    successAction = EDITION_DETAIL_UNPUBLISH_SUCCESS;
  } else {
    successAction = EDITION_DETAIL_SAVE_SUCCESS;
  }

  if (id) {
    payload.client_changed_time = editionDetail.changed;
    return ajax.patch(`/api/edition/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
      map((response) => {
        const responseData = JSON.parse(response.response);
        return { type: successAction, value: { type, id, changed: moment(responseData.changed[0].value).unix() } };
      }),
      apiCatchError(EDITION_DETAIL_SAVE_REJECTED),
    );
  }
  return ajax.post('/api/edition', payload, { 'Content-Type': 'application/json' }).pipe(
    map((response) => {
      // const responseData = JSON.parse(response.response);
      const responseData = response.response;
      if (typeof status !== 'undefined') {
        return { type: successAction, value: { type, changed: responseData.changed[0].value } };
      }
      return {
        type: successAction,
        value: { type, id: responseData.id[0].value, changed: responseData.changed[0].value },
      };
    }),
    apiCatchError(EDITION_DETAIL_SAVE_REJECTED),
  );
};

export const saveEditionDetail = (action$, state$) => action$.pipe(
  ofType(EDITION_DETAIL_SAVE),
  withLatestFrom(state$),
  switchMap(
    ([{ value: { type, status, method } }, {
      edition: { editionDetail },
      router: { location: { pathname } },
    }]) => {
      const [, bundle] = pathname.split('/').filter(x => !!x);
      return getSaveEditionRequest(editionDetail, type, status, bundle, method);
    },
  ),
);

export const redirectOnEditionSave = action$ => action$.pipe(
  ofType(
    EDITION_DETAIL_SAVE_SUCCESS,
  ),
  filter(({ value: { id } }) => typeof id !== 'undefined'),
  map(
    ({ value: { id, type } }) => push(`/edition/${type}/${id}`),
  ),
);

export const editionDetailPublishUpdateState = action$ => action$.pipe(
  ofType(
    EDITION_DETAIL_PUBLISH_SUCCESS,
    EDITION_DETAIL_UNPUBLISH_SUCCESS,
  ),
  map(({ value: { id } }) => ({ type: EDITION_DETAIL_FETCH, value: id })),
);

export const showSuccessMessage = action$ => action$.pipe(
  ofType(EDITION_DETAIL_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Edition is successfully saved.')),
);

export const showPublishSuccessMessage = action$ => action$.pipe(
  ofType(EDITION_DETAIL_PUBLISH_SUCCESS),
  mergeMap(showSuccessNotification('Edition is successfully published.')),
);

export const showUnPublishSuccessMessage = action$ => action$.pipe(
  ofType(EDITION_DETAIL_UNPUBLISH_SUCCESS),
  mergeMap(showSuccessNotification('Edition is successfully unpublished.')),
);

export const pingEditionApp = action$ => action$.pipe(
  ofType(EDITION_UPDATE_PING_APP),
  switchMap(({ value: url }) =>
    ajax.getJSON(`/api/edition/ping/app?url=${url}`).pipe(
      mapTo({
        type: EDITION_UPDATE_PING_APP_SUCCESS,
      }),
      apiCatchError(EDITION_UPDATE_PING_APP_REJECTED),
    ),
  ),
);

export const saveInitialStateHistory = (action$, state$) => action$.pipe(
  ofType(EDITION_DETAIL_FETCH_SUCCESS),
  ofType(EDITION_DETAIL_FETCH_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({
    edition: { editionDetail },
  }) => of({
    type: SAVE_INITIAL_HISTORY_EDITION_DETAIL,
    value: JSON.stringify(preseveEditionDetailState(
      getEditionPayload(editionDetail),
    )),
  })),
);

export const watchUnsavedStateOnEditionDetail = (action$, state$) => action$.pipe(
  ofType(EDITION_DETAIL_ENTER),
  mergeMap(() => fromEvent(document, 'click').pipe(
    merge(
      fromEvent(document, 'keyup').pipe(
        throttleTime(500),
        takeUntil(action$.pipe(ofType(EDITION_DETAIL_DISPOSE))),
      ),
    ),
    withLatestFrom(state$, (a, b) => b),
    map(({ edition: { editionDetail }, stateHistory: { initialEditionDetail } }) => {
      const currentState = (JSON.stringify(
        preseveEditionDetailState(getEditionPayload(editionDetail)),
      ));
      return {
        type: SET_UNSAVED_EDITION_DETAIL,
        value: initialEditionDetail !== currentState,
      };
    }),
    distinctUntilChanged((prev, curr) => prev.value === curr.value),
    takeUntil(action$.pipe(ofType(EDITION_DETAIL_DISPOSE))),
  )),
  catchError(showErrorNotification('Unable to compare the state on new article creation.')),
);
