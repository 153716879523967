import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from './Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginLeft: '0.4em',
  },
  button: {
    textTransform: 'inherit',
    '& .MuiButton-label': {
      color: theme.typography.color.primary,
    },
    '& .MuiButton-endIcon': {
      color: theme.palette.primary.main,
      marginLeft: 2,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
  },
});

const ButtonSelect = ({ classes, active, items, push: routeChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  if (!items || items.length === 0) return null;

  return (<div className={classes.root}>
    <Button
      aria-controls={'term-switch'}
      aria-haspopup={'true'}
      onClick={event => setAnchorEl(event.currentTarget)}
      className={classes.button}
      variant={'outlined'}
      endIcon={<ArrowDropDownIcon />}
    >
      {active}
    </Button>
    <Menu
      id={'term-switch'}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={classes.menu}
    >
      {items.map(
        item => (
          <MenuItem
            className={item.title === active ? 'active' : 'item'}
            key={item.title}
            onClick={() => {
              routeChange(item.link);
              setAnchorEl(null);
            }}
          >{item.title}</MenuItem>
        ),
      )}
    </Menu>
  </div>);
};

ButtonSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(() => ({}), { push })(ButtonSelect));
