import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../FieldLockField';
import TextField from '../../ui/builder/TextField';
import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';
import { setLocalProp } from '../../../actions/dataState';

const FieldLockTextfield = ({
  value,
  label,
  hasChanges,
  field,
  lockValue,
  validation,
  helperText,
  margin,
  maxLength,
  hideChanges,
  setLocalProp: setProp,
  ...rest
}) => {
  const [labelText, setLabelText] = useState(label);
  const [error, setError] = useState(false);
  const [helper, setHelper] = useState(helperText);
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  useEffect(() => {
    // @todo handle maxLength and validation
    if (maxLength && value) {
      const valid = value.length <= maxLength;
      setError(!valid);
      setHelper(!valid ? `${label} has over ${maxLength} characters` : helperText);
    } else if (validation && value) {
      const { valid, message } = validation(value);
      setError(!valid);
      setHelper(message || helperText);
    }
    setHelper(helperText);
  }, [value, validation, maxLength, label, helperText]);
  useEffect(() => {
    if (!maxLength) {
      if (labelText !== label) {
        setLabelText(label);
      }
    } else {
      setLabelText(`${label} (${value.length}/${maxLength})`);
    }
  }, [labelText, maxLength, value, label]);
  return (
    <FieldLockField field={field} margin={margin} lockValue={lockValue}>
      <TextField
        label={labelText}
        hasChanges={hasChanges && !hideChanges}
        value={value || ''}
        onChange={e => setProp(prop, e.target.value, propChain)}
        error={error}
        helperText={helper}
        {...rest}
      />
    </FieldLockField>
  );
};

FieldLockTextfield.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  validation: PropTypes.func,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  maxLength: PropTypes.number,
  hideChanges: PropTypes.bool,
};

FieldLockTextfield.defaultProps = {
  label: '',
  value: '',
  helperText: '',
  lockValue: null,
  hasChanges: false,
  validation: null,
  margin: 'none',
  maxLength: null,
  hideChanges: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockTextfield);
