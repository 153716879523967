import { PURGE } from 'redux-persist';

export const defaultState = {
  items: [],
  total: 0,
  isFetching: false,
};

export default (
  fetch, success, reject, dispose,
) => (state = defaultState, action) => {
  switch (action.type) {
    case fetch:
      return {
        ...state,
        isFetching: true,
      };
    case reject:
      return {
        ...state,
        isFetching: false,
      };
    case success: {
      const { items = [], total = 0, ...rest } = action.value;
      return {
        ...state,
        items,
        total,
        isFetching: false,
        ...rest,
      };
    }
    case dispose:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
