import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import {
  setArticleEditionProperty, setEditionLockField,
} from '../../../../actions/edition';

import {
  FIELD_AWAY_SCORE,
  FIELD_AWAY_SCORER,
  FIELD_AWAY_TEAM,
  FIELD_HOME_SCORE,
  FIELD_HOME_SCORER,
  FIELD_HOME_TEAM,
} from '../../../../constants/edition/editionFields';

import FieldLockTextfield from '../../../fieldLock/form/FieldLockTextfieldDepricated';
import { PagePanel, PagePanelContent, PagePanelHeading } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';

const style = () => ({
  root: {
    margin: 0,
  },
});

const ScorePanel = ({
  classes,
  setArticleEditionProperty: setProperty,
  setEditionLockField: lockField,
  templates,
  awayScore,
  awayTeam,
  awayScorers,
  homeScore,
  homeTeam,
  homeScorers,
}) => {
  if (!templates.find(({ name }) => name === 'score')) return null;

  const update = key => event => setProperty([key, event.target.value]);
  return (
    <Grid item xs={12}>
      <PagePanel>
        <PagePanelHeading>
          <SectionTitle>Score</SectionTitle>
        </PagePanelHeading>
        <PagePanelContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <FormControl className={classes.root} margin="dense" required fullWidth>
                    <FieldLockTextfield
                      field={FIELD_HOME_TEAM}
                      label={'Home team'}
                      value={homeTeam}
                      onChange={update(FIELD_HOME_TEAM)}
                      onUnlock={() => {
                        lockField([FIELD_HOME_TEAM, homeTeam]);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FieldLockTextfield
                    field={FIELD_HOME_SCORE}
                    label={'Score'}
                    value={homeScore}
                    onChange={update(FIELD_HOME_SCORE)}
                    onUnlock={() => {
                      lockField([FIELD_HOME_SCORE, homeScore]);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldLockTextfield
                    field={FIELD_HOME_SCORER}
                    label={'Scorer'}
                    value={homeScorers}
                    onChange={update(FIELD_HOME_SCORER)}
                    onUnlock={() => {
                      lockField([FIELD_HOME_SCORER, homeScorers]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <FormControl className={classes.root} margin="dense" required fullWidth>
                    <FieldLockTextfield
                      field={FIELD_AWAY_TEAM}
                      label={'Away team'}
                      value={awayTeam}
                      onChange={update(FIELD_AWAY_TEAM)}
                      onUnlock={() => {
                        lockField([FIELD_AWAY_TEAM, awayTeam]);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FieldLockTextfield
                    field={FIELD_AWAY_SCORE}
                    label={'Score'}
                    value={awayScore}
                    onChange={update(FIELD_AWAY_SCORE)}
                    onUnlock={() => {
                      lockField([FIELD_AWAY_SCORE, awayScore]);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldLockTextfield
                    field={FIELD_AWAY_SCORER}
                    label={'Scorer'}
                    value={awayScorers}
                    onChange={update(FIELD_AWAY_SCORER)}
                    onUnlock={() => {
                      lockField([FIELD_AWAY_SCORER, awayScorers]);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PagePanelContent>
      </PagePanel>
    </Grid>
  );
};

ScorePanel.propTypes = {
  templates: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
  awayScore: PropTypes.string.isRequired,
  awayTeam: PropTypes.string.isRequired,
  awayScorers: PropTypes.string.isRequired,
  homeScore: PropTypes.string.isRequired,
  homeTeam: PropTypes.string.isRequired,
  homeScorers: PropTypes.string.isRequired,
};

export default withStyles(style)(connect(
  ({ edition: {
    pugpig_templates: templates,
    awayScore,
    awayTeam,
    awayScorers,
    homeScore,
    homeTeam,
    homeScorers,
  } }) => ({
    templates,
    awayScore,
    awayTeam,
    awayScorers,
    homeScore,
    homeTeam,
    homeScorers }),
  { setArticleEditionProperty, setEditionLockField },
)(ScorePanel));

