import React from 'react';
import PropTypes from 'prop-types';

import DialogContent from '@material-ui/core/DialogContent/DialogContent';

import Dialog from './common/Dialog';
import AddNewRedirect from '../redirect/AddNewRedirect';

const RedirectAddNewDialog = ({ handleClose, ...props }) => (
  <Dialog
    title={'Add new'}
    fullScreen={false}
    maxWidth={'sm'}
    handleClose={handleClose}
    {...props}
  >
    <DialogContent>
      <AddNewRedirect onSubmit={handleClose} />
    </DialogContent>
  </Dialog>
);

RedirectAddNewDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default RedirectAddNewDialog;
