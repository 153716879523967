import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { setLocalProp } from '../../../../actions/dataState';
import ToggleSwitch from '../../../ui/ToggleSwitch';
import { FIELD_IS_BREAKING_NEWS } from '../../../../constants/article/articleFields';

const BreakingNewsToggle = ({
  isBreakingNews,
  setLocalProp: setProp,
}) => (
  <Grid item>
    {[
      [isBreakingNews, FIELD_IS_BREAKING_NEWS, 'Breaking News'],
    ]
      .map(([state, propName, label]) => (<Grid key={label} container justify={'flex-start'} alignItems={'center'}>
        <Grid item>
          <ToggleSwitch
            label={label}
            value={state}
            onChange={e => setProp(propName, e.target.checked)}
          />
        </Grid>
      </Grid>))}
  </Grid>
);

BreakingNewsToggle.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  isBreakingNews: PropTypes.bool,
};

BreakingNewsToggle.defaultProps = {
  isBreakingNews: false,
};

export default connect(
  ({
    dataState: { [FIELD_IS_BREAKING_NEWS]: isBreakingNews },
  }) => ({ isBreakingNews }),
  { setLocalProp },
)(BreakingNewsToggle);
