import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import {
  map,
  mergeMap,
  takeUntil,
  switchMap,
  withLatestFrom,
  mapTo,
  filter, merge, distinctUntilChanged, catchError, throttleTime,
} from 'rxjs/operators';
import { from, fromEvent, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import {
  WINDOW_MESSAGE_RECIEVED,
  SET_UNSAVED_EDITION_PAGE,
  SAVE_INITIAL_HISTORY_EDITION,
} from '../../constants/actionTypes';
import {
  PAGE_TYPE_ARTICLE, PAGE_TYPE_SECTION,
  PAGE_TYPE_SINGLE_IMAGE,
  PAGE_TYPE_ARTICLE_CUSTOM,
} from '../../constants/edition/edition';
import apiCatchError, {
  assignErrorPayload,
  showErrorNotification,
  showSuccessNotification,
} from '../helper/notification';
import { getDefaultTemplateBody } from '../helper/edition';

import {
  ARTICLE_EDITION_EDIT_READY,
  ARTICLE_EDITION_FETCH_SUCCESS,
  CREATE_ARTICLE_EDITION_SUCCESS,
  EDITION_DISPOSE_PAGE,
  EDITION_ARTICLE_ENTER_PAGE,
  EDITION_PREVIEW_COMPONENT_CLICK,
  EDITION_PREVIEW_COMPONENT_HOVER, EDITION_REVISION_PATCH_SUCCESS,
  ENTER_ARTICLE_EDITION_CREATION,
  EXPORT_ARTICLE_TO_EDITION,
  LEAVE_ARTICLE_EDITION_CREATION,
  ON_ENTER_ARTICLE_EDITION_CREATION,
  RESET_ARTICLE_EDITION_CREATION,
  SAVE_ARTICLE_EDITION,
  SAVE_ARTICLE_EDITION_READY,
  SAVE_ARTICLE_EDITION_REJECTED,
  SAVE_ARTICLE_EDITION_SUCCESS,
  SET_ARTICLE_EDITION_PROPERTY,
} from '../../constants/actionTypes/edition';
import { CONTENT_SOURCE, EDITION_TEMPLATES } from '../../constants/vocab';
import {
  EXTRA_FIELD_KEYS,
  FIELD_BODY,
  FIELD_TEMPLATE,
  FIELD_CONTENT_SOURCE,
  FIELD_WORKFLOW,
  FIELD_AUTHORS, EDITION_FIELD_MAPPING_CMS_KEYED, IMAGE_OVERRIDE_PROPERTIES,
} from '../../constants/edition/editionFields';
import { getEditionPayload, preserveEditionState } from './editionProcessors';
import { REVISION_EDITION, REVISION_ON_SEARCH } from '../../constants/revision';
import {
  assignComponentIDIfNotExists,
} from '../../utils/helper';
import { TEMPLATE_DEFAULTS } from '../../constants/edition/editionTemplates';

export const watchUnsavedStateOnNewArticleCreation = (action$, state$) => action$.pipe(
  ofType(EDITION_ARTICLE_ENTER_PAGE),
  mergeMap(() => fromEvent(document, 'click').pipe(
    merge(
      fromEvent(document, 'keyup').pipe(
        throttleTime(500),
        takeUntil(action$.pipe(ofType(EDITION_DISPOSE_PAGE))),
      ),
    ),
    withLatestFrom(state$, (a, b) => b),
    filter(({ edition: { pageType } }) => !!pageType),
    map(({ edition, frame: { selectedPublication }, stateHistory: { initialEdition } }) => {
      const currentState = (JSON.stringify(
        preserveEditionState(edition, selectedPublication),
      ));
      return {
        type: SET_UNSAVED_EDITION_PAGE,
        value: initialEdition !== currentState,
      };
    }),
    distinctUntilChanged((prev, curr) => prev.value === curr.value),
    takeUntil(action$.pipe(ofType(EDITION_DISPOSE_PAGE))),
  )),
  catchError(showErrorNotification('Unable to compare the state on new article creation.')),
);

export const saveInitialStateHistory = (action$, state$) => action$.pipe(
  ofType(ARTICLE_EDITION_EDIT_READY, SAVE_ARTICLE_EDITION_SUCCESS, CREATE_ARTICLE_EDITION_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  filter(({ edition: { pageType } }) => pageType),
  mergeMap(({
    edition,
    frame: { selectedPublication },
  }) => of({
    type: SAVE_INITIAL_HISTORY_EDITION,
    value: JSON.stringify(
      preserveEditionState(edition, selectedPublication),
    ),
  })),
);

export const setInitialEditionSection = (action$, state$) => action$.pipe(
  ofType(ON_ENTER_ARTICLE_EDITION_CREATION, RESET_ARTICLE_EDITION_CREATION),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({
    edition: {
      [EDITION_TEMPLATES]: templates,
      [CONTENT_SOURCE]: contentSource,
    },
    vocab: {
      pugpig_templates: { items: [defaultTemplate] },
      defaultContentSource,
    },
  }) => {
    const actions = [];
    if (templates && templates.length === 0) {
      actions.push({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [EDITION_TEMPLATES, [defaultTemplate]],
      });
    }
    if (contentSource && contentSource.length === 0
      && defaultContentSource) {
      actions.push({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [CONTENT_SOURCE, defaultContentSource ? [defaultContentSource] : []],
      });
    }
    actions.push({ type: ARTICLE_EDITION_EDIT_READY });
    return from(actions);
  }),
);

export const successMessageAfterSave = action$ => action$.pipe(
  ofType(SAVE_ARTICLE_EDITION_SUCCESS, CREATE_ARTICLE_EDITION_SUCCESS),
  mergeMap(showSuccessNotification('Save successful')),
);

export const reloadRevisionListAfterSave = action$ => action$.pipe(
  ofType(SAVE_ARTICLE_EDITION_SUCCESS, CREATE_ARTICLE_EDITION_SUCCESS),
  map(({ value: id }) => ({
    type: REVISION_ON_SEARCH,
    value: {
      type: REVISION_EDITION,
      id,
    },
  })),
);

export const purgeArticleCreation = (action$, state$) => action$.pipe(
  ofType(ON_ENTER_ARTICLE_EDITION_CREATION),
  withLatestFrom(state$, (a, state) => state),
  filter(({ edition: { articleEditionFromServer } }) => articleEditionFromServer),
  mapTo({ type: RESET_ARTICLE_EDITION_CREATION }),
);

export const validateArticleBeforeSave = (action$, state$) =>
  action$.pipe(
    ofType(SAVE_ARTICLE_EDITION),
    withLatestFrom(state$, (action, state) => state),
    mergeMap(({ edition: { headline, pugpig_section: sections, pageType, heroImage,
      pugpig_templates: templates,
      content_source: contentSource } }) => {
      const errors = [];

      if (headline === '') {
        errors.push(assignErrorPayload('Please fill in the headline'));
      } else if (headline.length > 8000) {
        errors.push(assignErrorPayload('Headline field has more than 8000 characters'));
      }

      if (templates.length === 0 && pageType === PAGE_TYPE_ARTICLE) {
        errors.push(assignErrorPayload('Please assign article template'));
      }

      if (contentSource.length === 0 && pageType === PAGE_TYPE_ARTICLE) {
        errors.push(assignErrorPayload('Please assign content source'));
      }

      if (sections.length === 0 && pageType !== PAGE_TYPE_SECTION) {
        errors.push(assignErrorPayload('Please assign at least one section'));
      }

      if (pageType === PAGE_TYPE_SINGLE_IMAGE && !heroImage.data.mid) {
        errors.push(assignErrorPayload('Please fill in hero image'));
      }

      if (errors.length > 0) {
        return from(errors);
      }

      return of({ type: SAVE_ARTICLE_EDITION_READY });
    }),
  );

export const saveEditionArticle = (action$, state$) => action$.pipe(
  ofType(SAVE_ARTICLE_EDITION_READY),
  withLatestFrom(state$, (action, state) => state),
  switchMap(({
    edition,
    router: { location: { pathname } },
    fieldLock: { lockedFields },
  }) => {
    const { articleEditionFromServer } = edition;
    const payload = getEditionPayload(edition, Object.keys(lockedFields));

    if (articleEditionFromServer) {
      const { id: [{ value: editionId }] } = articleEditionFromServer;
      payload.id = [{
        value: editionId,
      }];
      return ajax.patch(`/api/edition/${editionId}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mapTo({
          type: SAVE_ARTICLE_EDITION_SUCCESS,
          value: editionId,
        }),
        apiCatchError(SAVE_ARTICLE_EDITION_REJECTED),
      );
    }

    const matches = pathname.match(/(\d+)\/(article|section|single-image)$/);
    const containerId = matches[1];

    return ajax.post('/api/edition', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response: articleResponse }) =>
        ajax.getJSON(`/api/edition/${containerId}`).pipe(
          mergeMap(({ field_pugpig_pages: pages }) => {
            const payloadPages = {
              bundle: [{
                target_id: 'pugpig_container',
                target_type: 'flowz_collection_type',
              }],
              field_pugpig_pages: [
                ...pages,
                {
                  target_id: articleResponse.id[0].value,
                  target_type: 'flowz_collection',
                },
              ],
            };
            return ajax.patch(`/api/edition/${containerId}`, payloadPages, { 'Content-Type': 'application/json' }).pipe(
              mapTo({
                type: CREATE_ARTICLE_EDITION_SUCCESS,
                value: [containerId, articleResponse.id[0].value],
              }),
            );
          }),
        )),
      apiCatchError(SAVE_ARTICLE_EDITION_REJECTED),
    );
  }),
);

export const redirectAfterCreation = action$ => action$.pipe(
  ofType(CREATE_ARTICLE_EDITION_SUCCESS),
  map(({ value: [containerid, pageId] }) =>
    push(`/edition/daily/${containerid}/${pageId}`),
  ),
);

export const fetchArticleContent = action$ => action$.pipe(
  ofType(ENTER_ARTICLE_EDITION_CREATION, EDITION_REVISION_PATCH_SUCCESS),
  switchMap(({ value }) =>
    ajax.get(`/api/edition/${value}`).pipe(
      map(({ response }) => ({
        type: ARTICLE_EDITION_FETCH_SUCCESS,
        value: response,
      })),
    ),
  ),
  apiCatchError(),
);

export const processSectionFromServer = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_EDITION_FETCH_SUCCESS),
    filter(({ value: { bundle: [{ target_id: id }] } }) => /pugpig_section_page/.test(id)),
    withLatestFrom(state$),
    mergeMap(([{ value }]) => {
      const {
        name,
        field_pugpig_body_json: body,
        field_pugpig_sp_template_type: template,
        field_pugpig_subheadline: subHeadline,
      } = value;

      const selectedTemplate = Array.isArray(template) && template.length > 0 ?
        template[0].value : 'Two Story Front Page';

      let parsedBody = TEMPLATE_DEFAULTS[selectedTemplate];
      if (body && body[0] && body[0].value) {
        parsedBody = JSON.parse(body && body[0].value);
      }

      const props = {
        articleEditionFromServer: value,
        headline: name && name[0] && name[0].value,
        body: parsedBody.map(assignComponentIDIfNotExists),
        [FIELD_TEMPLATE]: template[0].value,
        pageType: PAGE_TYPE_SECTION,
        subHeadline: subHeadline && subHeadline[0] && subHeadline[0].value,
      };

      return from(Object.keys(props).map(key => ({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [key, props[key], true],
      })));
    }),
  );

export const processSingleImageFromServer = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_EDITION_FETCH_SUCCESS),
    filter(({ value: { bundle: [{ target_id: id }] } }) => /pugpig_single_image/.test(id)),
    withLatestFrom(state$),
    mergeMap(([{ value }]) => {
      const {
        name,
        field_pugpig_hero_image: hero,
        field_pugpig_home_section: sections,
        field_pugpig_extra_json: extras,
      } = value;

      const extraFields = extras.length > 0 ? JSON.parse(extras[0].value) : {};

      const props = {
        articleEditionFromServer: value,
        headline: name[0].value,
        pageType: PAGE_TYPE_SINGLE_IMAGE,
      };

      if (hero.length > 0) {
        props.heroImage = hero[0].pwamp;
        Object.keys(IMAGE_OVERRIDE_PROPERTIES)
          .filter(key => Boolean(extraFields[key]))
          .forEach((key) => {
            props.heroImage.data[IMAGE_OVERRIDE_PROPERTIES[key]] = extraFields[key];
          });
      }

      if (sections.length > 0) {
        props.pugpig_section = sections.map(({ pwamp }) => pwamp);
      }

      const actions = Object.keys(props).map(key => ({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [key, props[key], true],
      }));

      return from([...actions, { type: ARTICLE_EDITION_EDIT_READY }]);
    }),
  );

export const processCustomArticleFromServer = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_EDITION_FETCH_SUCCESS),
    filter(({ value: { bundle: [{ target_id: id }] } }) => /pugpig_custom/.test(id)),
    withLatestFrom(state$),
    mergeMap(([{ value }]) => {
      const props = {
        articleEditionFromServer: value,
        pageType: PAGE_TYPE_ARTICLE_CUSTOM,
      };

      const actions = Object.keys(props).map(key => ({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [key, props[key], true],
      }));

      return from([...actions, { type: ARTICLE_EDITION_EDIT_READY }]);
    }),
  );

export const processArticleFromServer = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_EDITION_FETCH_SUCCESS),
    filter(({ value: { bundle: [{ target_id: id }] } }) => /pugpig_article/.test(id)),
    withLatestFrom(state$),
    mergeMap(([{ value }, { vocab: { pugpig_templates: { items: templateOptions } } }]) => {
      const {
        field_pugpig_body_json: body,
        name,
        field_pugpig_subheadline: subHeadlineField,
        field_pugpig_hero_image: hero,
        field_pugpig_home_section: sections,
        field_pugpig_template_type: template,
        field_pugpig_extra_json: extraProps,
        field_pugpig_slug: slugField,
        field_pugpig_notes: noteField,
      } = value;

      const extras = extraProps.length > 0 ? JSON.parse(extraProps[0].value) : {};
      const getVal = field => (Array.isArray(field) && field.length > 0 ? field[0].value : '');

      const subHeadline = getVal(subHeadlineField);
      const slug = getVal(slugField);

      let parsedBody = [];
      let notes = [];
      if (body && body[0] && body[0].value) {
        parsedBody = JSON.parse(body[0].value);
      }
      if (noteField && noteField[0] && noteField[0].value) {
        notes = JSON.parse(noteField[0].value);
      }

      const props = {
        articleEditionFromServer: value,
        body: parsedBody.map(assignComponentIDIfNotExists),
        headline: name[0].value,
        subHeadline,
        pageType: PAGE_TYPE_ARTICLE,
        slug,
        notes,
      };

      if (hero.length > 0) {
        props.heroImage = hero[0].pwamp;
        Object.keys(IMAGE_OVERRIDE_PROPERTIES)
          .filter(key => Boolean(extras[key]))
          .forEach((key) => {
            props.heroImage.data[IMAGE_OVERRIDE_PROPERTIES[key]] = extras[key];
          });
      }

      [FIELD_WORKFLOW, FIELD_AUTHORS, FIELD_CONTENT_SOURCE].forEach((stateKey) => {
        const { key } = EDITION_FIELD_MAPPING_CMS_KEYED[stateKey];
        if (value[key].length > 0) {
          props[stateKey] = value[key].filter(({ pwamp }) => pwamp).map(({ pwamp }) => pwamp);
        }
      });

      if (sections.length > 0) {
        props.pugpig_section = sections.map(({ pwamp }) => pwamp);
      }

      if (template.length > 0) {
        const selected = template[0].value;
        const selectedTemplate = templateOptions.find(({ id }) => id === selected);
        if (selectedTemplate) {
          props.pugpig_templates = [selectedTemplate];
        }
      }

      if (extraProps) {
        EXTRA_FIELD_KEYS[PAGE_TYPE_ARTICLE].forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(extras, key)) {
            props[key] = extras[key];
          }
        });
      }

      const actions = Object.keys(props).map(key => ({
        type: SET_ARTICLE_EDITION_PROPERTY,
        value: [key, props[key], true],
      }));

      return from([...actions, { type: ARTICLE_EDITION_EDIT_READY }]);
    }),
  );

export const exportToEdition = action$ => action$.pipe(
  ofType(EXPORT_ARTICLE_TO_EDITION),
  switchMap(({ value: { containerId, articleId, sectionId } }) =>
    ajax.get(
      `/api/edition/import/${containerId}/${articleId}?sectionId=${sectionId}`,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(showSuccessNotification('Article is exported successfully')),
      // catchError((ex) => {
      //   try {
      //     if (ex.status === 500 && ex.response.error) {
      //       return of(assignErrorPayload(ex.response.error));
      //     }
      //   } catch (innerEx) {
      //     // noop
      //   }
      //   return of(showErrorNotification('Unable to complete this request'));
      // }),
      apiCatchError(),
    ),
  ),
);

export const setDefaultBodyOnTemplateChange = (action$, state$) => action$.pipe(
  ofType(SET_ARTICLE_EDITION_PROPERTY),
  filter(({ value: [field, , fromServer] }) =>
    (typeof fromServer === 'undefined' || !fromServer) && field === FIELD_TEMPLATE),
  withLatestFrom(state$),
  switchMap(([{ value: [, data] }, { edition: { body } }]) => of(
    {
      type: SET_ARTICLE_EDITION_PROPERTY,
      value: [FIELD_BODY, getDefaultTemplateBody(data, body)],
    },
  )),
);

export const watchForEditionPreviewMessages = action$ => action$.pipe(
  ofType(ENTER_ARTICLE_EDITION_CREATION),
  mergeMap(() => fromEvent(window, 'message')
    .pipe(
      filter(messageEvent =>
        messageEvent.data && !!messageEvent.data.event),
      map(({ data: { event, target } }) => {
        switch (event) {
          case 'hover':
            return { type: EDITION_PREVIEW_COMPONENT_HOVER, value: target };
          case 'click': {
            return { type: EDITION_PREVIEW_COMPONENT_CLICK, value: target };
          }
          default:
            break;
        }
        return { type: WINDOW_MESSAGE_RECIEVED, value: null };
      }),
      takeUntil(action$.pipe(ofType(LEAVE_ARTICLE_EDITION_CREATION))),
    ),
  ),
);

export const setPageTypeCreationPage = (action$, state$) => action$.pipe(
  ofType(EDITION_ARTICLE_ENTER_PAGE),
  withLatestFrom(state$, (a, b) => b),
  filter(({ router: { location: { pathname } } }) => !/\/\d+$/.test(pathname)),
  map(({ router: { location: { pathname } } }) => {
    const paths = pathname.split('/');
    return ({
      type: SET_ARTICLE_EDITION_PROPERTY,
      value: ['pageType', paths[paths.length - 1]],
    });
  }),
);
