export const H_LIMIT = 30;

export const setHierachySuggestions = (hierarchy, item, suggestions) => {
  let parent = hierarchy.shift();
  if (!suggestions.find(suggestion => suggestion.id === parent.id)) {
    suggestions.push(parent);
  } else {
    parent = suggestions.find(suggestion => suggestion.id === parent.id);
  }
  if (typeof parent.children === 'undefined') {
    parent.children = [];
  }
  if (hierarchy.length > 0) {
    setHierachySuggestions(hierarchy, item, parent.children);
  } else if (!parent.children.find(suggestion => suggestion.id === item.id)) {
    parent.children.push({ id: item.id, name: item.name, label: item.label, path: item.path });
  }
};

export const getHierarchy = (suggestions) => {
  const hierarchicalSuggestions = [];
  for (let i = 0; i < suggestions.length; i += 1) {
    const hierarchy = typeof suggestions[i].hierarchy !== 'undefined' ? [...suggestions[i].hierarchy] : [];
    if (hierarchy.length > 1) {
      // eslint-disable-next-line no-unused-vars
      const publication = hierarchy.shift();
      setHierachySuggestions(hierarchy, suggestions[i], hierarchicalSuggestions);
    } else if (!hierarchicalSuggestions.find(suggestion => suggestion.id === suggestions[i].id)) {
      hierarchicalSuggestions.push({
        id: suggestions[i].id,
        name: suggestions[i].name,
        label: suggestions[i].label,
        path: suggestions[i].path,
      });
    }
  }
  return hierarchicalSuggestions;
};

export const getSuggestions = (suggestions, hierarchical) => (hierarchical
  ? getHierarchy(suggestions.slice(0, H_LIMIT))
  : suggestions.slice(0, H_LIMIT));
