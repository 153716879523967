import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Prompt } from 'react-router-dom';

import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Page, { footerHeight } from './common/Frame';
import Loading from './Loading';

import ArticleMain from '../components/article/ArticleMain';
import ArticleSidebar from '../components/article/ArticleSidebar';
import LiveblogSidebar from '../components/article/LiveblogSidebar';
import ProductSidebar from '../components/article/ProductSidebar';

import { disposeArticleCreation, resetPreviewId, setArticleDefaults } from '../actions/article';
import { disposeContext } from '../actions/page';
import { containInternalTranslateConfig, containTranslateConfig } from '../components/drawer';
import TranslationToggle from '../components/translation/TranslationToggle';
import TranslatedOriginPreview from '../components/translation/TranslatedOriginPreview';
import TranslationComparedToggle from '../components/translation/TranslationComparedToggle';

import {
  LANG_ENGLISH,
  COMPARE_MODE,
} from '../constants/article/article';
import { CONTEXT_DEFAULT, CONTEXT_LIVEBLOG, CONTEXT_PRODUCT } from '../constants/contexts';
import {
  REVISION_OFFLINE,
} from '../constants/revision';
import LivePreview from '../components/common/LivePreview';
import { headerHeight } from '../components/header';
import ControlButtons from '../components/article/ControlButtons';
import PageHeadingToolbar from '../components/common/page/PageToolbar';
import PageMain, { PageMainWrapper, PageMainContent } from '../components/common/page/PageMainDepricated';

import { ArticleListBreadcrumb } from './ArticleList';
import ArticleRevisionView from './ArticleRevisionView';
import { searchRevision, disposeRevisionList } from '../actions/revision';
import PageBusyIndicator from '../components/common/page/PageBusyIndicator';
import { UNSAVE_CHANGES_PROMPT_TEXT } from '../constants/prompt';
import UploadBusyIndicator from '../components/media/UploadBusyIndicator';
import { getClassName } from '../utils/propHelper';
import { FIELD_ARTICLE_TYPE, FIELD_CONTENT_SOURCE, FIELD_NID, FIELD_WORKFLOW } from '../constants/article/articleFields';

export const subToolbarHeight = 40;

const styles = theme => ({
  mainContentTranlationStacking: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(8),
  },
  mainContentTranlationCompare: {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  rootCompare: {
    maxWidth: 'none',
    border: '0 none',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0!important',
    },
  },
  innerCompare: {
    border: '16px solid rgba(0, 0, 0, .05)',
    position: 'relative',
    marginLeft: 52,
    display: 'flex',
    height: 'calc(100% - 32px)',
  },
  articleContentMenu: {
    position: 'relative',
    display: 'flex',
  },
  rootSetHeight: {
    minHeight: 1600,
    height: 'calc(100% - 32px)',
  },
  toggleRoot: {
    position: 'absolute',
    top: subToolbarHeight,
    left: -56,
  },
  contentScollable: {
    height: `calc(100vh - ${headerHeight + footerHeight + 32}px)`,
    width: '100%',
    overflowY: 'auto',
  },
});

const ArticleCreate = (props) => {
  const {
    classes,
    match: { params: { nid, action, revisionId } },
    nid: articleNid,
    articleType,
    contentSource,
    workflow,
    localState,
    selectedPublication,
    originalUrl,
    previewMode,
    previewId,
    autoGeneratedUrl,
    internalTranslatedSourceURL,
    isBusy,
    setArticleDefaults: setDefaults,
    resetPreviewId: resetPreview,
    searchRevision: onSearchRevision,
    disposeRevisionList: onDisposeRevisionList,
    disposeArticleCreation: onDisposeArticleCreation,
    disposeContext: disposeArticleContext,
    revisions,
    context: { type: contextType = CONTEXT_DEFAULT },
  } = props;
  const isPreview = action === 'preview';
  const ORIGIN_LANG = selectedPublication.publicationConfig.lang;
  const [hasChanges, setHasChanges] = useState(false);
  const [translatedPreview, setTranslatedPreview] = useState(ORIGIN_LANG);
  const [revision, setRevision] = useState(null);
  const [hasOffline, setHasOffline] = useState(false);
  const [path, setPath] = useState(autoGeneratedUrl);

  useEffect(() => {
    if (previewId) {
      setPath(`/preview/${previewId}`);
    } else {
      setPath(autoGeneratedUrl);
    }
  }, [autoGeneratedUrl, previewId]);

  useEffect(() => () => {
    onDisposeArticleCreation(nid);
    resetPreview();
    onDisposeRevisionList();
    disposeArticleContext();
  }, [nid]);

  useEffect(() => {
    if (
      !nid &&
      !(articleType && contentSource && workflow)
    ) {
      setDefaults();
    }
  }, [nid, articleType, contentSource, workflow]);

  useEffect(() => {
    if (previewMode && translatedPreview !== ORIGIN_LANG) {
      setTranslatedPreview(ORIGIN_LANG);
    }
  }, [previewMode]);

  useEffect(() => {
    setHasOffline(typeof revisions[0] !== 'undefined' && revisions[0].status === REVISION_OFFLINE);
    if (revision === null && revisionId) {
      if (revisionId && revisions.length > 0) {
        let i = 0;
        for (const revision of revisions) {
          const { revision_id: id, status } = revision;
          if (id === revisionId) {
            setRevision({
              ...revision,
              isOffline: (i === 0 && status === REVISION_OFFLINE),
              isLive: (i === 0 || (i === 1 && hasOffline)),
            });
            break;
          }
          i += 1;
        }
        // @todo handle revision not found
      } else if (articleNid) {
        onSearchRevision(articleNid);
      }
    }
    if (!revisionId && revision !== null) {
      setRevision(null);
      resetPreview();
    }
  }, [revisionId, revisions, articleNid]);

  useEffect(() => {
    setHasChanges(Object.entries(localState).length > 0);
  });

  if (revisionId && !revision) {
    return (<Loading />);
  }

  const breadcrumb = [
    ...ArticleListBreadcrumb,
    {
      title: /edit|preview/.test(action) ? 'Article editor' : 'New article',
    },
  ];

  if (revision && !revision.isLive && !revision.isOffline) {
    return (<ArticleRevisionView urlParams={{ action, nid }} breadcrumb={breadcrumb} revision={revision} />);
  }

  let translatedArticle = containTranslateConfig(selectedPublication.publicationConfig)
    && !!originalUrl;

  // check again for internal translation mode
  if (!translatedArticle) {
    // TODO: find out from tomg how to preview the original article on spanish mode
    // use originalUrl as well same as Russia?
    translatedArticle = containInternalTranslateConfig(selectedPublication.publicationConfig) && !!internalTranslatedSourceURL;
  }
  const rootCls = [];
  if (translatedArticle) {
    rootCls.push(classes.articleContentMenu);
  }
  if (translatedPreview === 'compare') {
    rootCls.push(classes.rootCompare);
  }
  if (translatedPreview === LANG_ENGLISH) {
    rootCls.push(classes.rootSetHeight);
  }

  return (
    <Page
      fullWidth
      toolbarContent={
        <PageHeadingToolbar breadcrumb={breadcrumb}>
          <ControlButtons />
        </PageHeadingToolbar>
      }
    >
      {isBusy && <PageBusyIndicator disableInteraction message={'Saving...'} />}
      <UploadBusyIndicator />
      <Prompt when={!!hasChanges && !isBusy} message={UNSAVE_CHANGES_PROMPT_TEXT} />
      <PageMainWrapper id={'articleCreate'}>
        {isPreview && <LivePreview path={path} previewMode={previewMode} />}
        {!isPreview && <>
          <PageMain
            id={'articleMain'}
            className={getClassName({
              [classes.mainContentTranlationStacking]: translatedArticle && translatedPreview !== COMPARE_MODE,
              [classes.mainContentTranlationCompare]: translatedArticle && translatedPreview === COMPARE_MODE,
            })}
          >
            <PageMainContent className={rootCls.join(' ')}>
              {/* NO TRANSLATION IS SET */}
              {!translatedArticle && <ArticleMain />}

              {/* COMPARE ON TOP OF EACH OTHER */}
              { translatedArticle && translatedPreview !== COMPARE_MODE &&
              <div className={classes.toggleRoot}><TranslationToggle
                langOptions={[ORIGIN_LANG, 'en']}
                selected={translatedPreview}
                onClick={value => setTranslatedPreview(value)}
              /></div> }
              {translatedArticle && translatedPreview === ORIGIN_LANG &&
                <>
                  <div className={classes.inner}><ArticleMain /></div>
                </>
              }
              {translatedArticle && translatedPreview === LANG_ENGLISH &&
              <TranslatedOriginPreview />}

              {/* COMPARE SIDE BY SIDE ENGLISH VS LANG */}
              {translatedArticle && translatedPreview === COMPARE_MODE && <Grid container>
                <Grid item sm={6}>
                  <div className={classes.innerCompare}>
                    <div className={classes.toggleRoot}>
                      <TranslationComparedToggle
                        label={LANG_ENGLISH}
                        onClick={value => setTranslatedPreview(value)}
                      />
                    </div>
                    <div className={classes.contentScollable}>
                      <TranslatedOriginPreview />
                    </div>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className={classes.innerCompare}>

                    <div className={classes.inner}>
                      <div className={classes.toggleRoot}>
                        <TranslationComparedToggle
                          label={ORIGIN_LANG}
                          onClick={value => setTranslatedPreview(value)}
                        />
                      </div>
                      <div className={classes.contentScollable}>
                        <ArticleMain />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid> }
            </PageMainContent>
          </PageMain>
          {contextType === CONTEXT_DEFAULT && <ArticleSidebar />}
          {contextType === CONTEXT_LIVEBLOG && <LiveblogSidebar />}
          {contextType === CONTEXT_PRODUCT && <ProductSidebar />}
        </>}
      </PageMainWrapper>
    </Page>
  );
};

ArticleCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  setArticleDefaults: PropTypes.func.isRequired,
  disposeArticleCreation: PropTypes.func.isRequired,
  resetPreviewId: PropTypes.func.isRequired,
  searchRevision: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  disposeContext: PropTypes.func.isRequired,
  revisions: PropTypes.array.isRequired,
  disposeRevisionList: PropTypes.func.isRequired,
  internalTranslatedSourceURL: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  localState: PropTypes.object,
  nid: PropTypes.number,
  articleType: PropTypes.object,
  contentSource: PropTypes.array,
  workflow: PropTypes.string,
  originalUrl: PropTypes.string,
  autoGeneratedUrl: PropTypes.string,
  previewMode: PropTypes.string,
  previewId: PropTypes.string,
  isBusy: PropTypes.bool,
};

ArticleCreate.defaultProps = {
  nid: null,
  articleType: null,
  contentSource: null,
  workflow: null,
  originalUrl: null,
  autoGeneratedUrl: '',
  previewMode: null,
  previewId: null,
  context: {},
  localState: null,
  isBusy: false,
};

export default withStyles(styles)(connect(
  ({
    drawer: { isExpanded },
    frame: { selectedPublication, context },
    article: {
      originalUrl, previewMode, previewId, autoGeneratedUrl,
      internalTranslatedSourceURL, isBusy,
    },
    localState,
    dataState: {
      [FIELD_NID]: nid,
    },
    serverState: {
      [FIELD_ARTICLE_TYPE]: articleType,
      [FIELD_CONTENT_SOURCE]: contentSource,
      [FIELD_WORKFLOW]: workflow,
    },
    revision: { list: revisions },
  }) => ({
    isBusy,
    internalTranslatedSourceURL,
    localState,
    selectedPublication,
    originalUrl,
    previewMode,
    previewId,
    isExpanded,
    revisions,
    autoGeneratedUrl,
    nid,
    articleType,
    contentSource,
    workflow,
    context,
  }),
  {
    disposeArticleCreation,
    resetPreviewId,
    searchRevision,
    disposeRevisionList,
    disposeContext,
    setArticleDefaults,
  },
)(ArticleCreate));
