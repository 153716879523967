import { PURGE } from 'redux-persist';

import {
  DISPOSE_SCORE_BOARD_COMPETITION,
  FETCH_SCORE_BOARD_MATCH, SCORE_BOARD_COMPETITION_SUCCESS,
  SCORE_BOARD_MATCH_REJECTED,
  SCORE_BOARD_MATCH_SUCCESS, SET_SCOREBOARD_PROPERTY,
} from '../../constants/actionTypes';

const defaultState = {
  competitions: [],
  competitionMatches: [],
  isMatchLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SCORE_BOARD_MATCH_REJECTED: {
      return {
        ...state,
        isMatchLoading: false,
      };
    }

    case SCORE_BOARD_MATCH_SUCCESS: {
      return {
        ...state,
        competitionMatches: action.value,
        isMatchLoading: false,
      };
    }

    case FETCH_SCORE_BOARD_MATCH: {
      return {
        ...state,
        isMatchLoading: true,
      };
    }

    case SCORE_BOARD_COMPETITION_SUCCESS: {
      return {
        ...state,
        competitions: action.value,
      };
    }

    case SET_SCOREBOARD_PROPERTY: {
      const [type, val] = action.value;
      const prop = {};
      prop[type] = val;
      return { ...state, ...prop };
    }

    case DISPOSE_SCORE_BOARD_COMPETITION:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
