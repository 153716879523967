export const ARTICLE_EDIT_READY = 'ARTICLE_EDIT_READY';
export const ARTICLE_EDIT_DISPOSE = 'ARTICLE_EDIT_DISPOSE';

export const ARTICLE_WS_UPDATE = 'ARTICLE_WS_UPDATE';

export const ARTICLE_HANDLE_UPDATE = 'ARTICLE_HANDLE_UPDATE';

export const ARTICLE_FETCH = 'ARTICLE_FETCH';
export const ARTICLE_FETCH_SUCCESS = 'ARTICLE_FETCH_SUCCESS';
export const ARTICLE_FETCH_REJECTED = 'ARTICLE_FETCH_REJECTED';
export const ARTICLE_DISPOSE = 'ARTICLE_DISPOSE';

export const ARTICLE_SET_DEFAULTS = 'ARTICLE_SET_DEFAULTS';

export const ARTICLE_SAVE = 'ARTICLE_SAVE';
export const ARTICLE_SAVE_READY = 'ARTICLE_SAVE_READY';
export const ARTICLE_SAVE_REJECTED = 'ARTICLE_SAVE_REJECTED';
export const ARTICLE_SAVE_SUCCESS = 'ARTICLE_SAVE_SUCCESS';
export const ARTICLE_UNPUBLISH_SUCCESS = 'ARTICLE_UNPUBLISH_SUCCESS';

export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS';
export const ARTICLE_DELETE_REJECTED = 'ARTICLE_DELETE_REJECTED';

export const ARTICLE_REVISION_SET_STATUS = 'ARTICLE_REVISION_SET_STATUS';
export const ARTICLE_SET_ACTIVE_EDITED_REVISION = 'ARTICLE_SET_ACTIVE_EDITED_REVISION  ';
export const ARTICLE_REVISION_SAVED = 'ARTICLE_REVISION_SAVED';
export const ARTICLE_REVISION_OFFLINE_SAVED = 'ARTICLE_REVISION_OFFLINE_SAVED';
export const ARTICLE_REVISION_REQUESTED = 'ARTICLE_REVISION_REQUESTED';
export const ARTICLE_REVISION_SAVE = 'ARTICLE_REVISION_SAVE';
export const ARTICLE_REVISION_REJECTED = 'ARTICLE_REVISION_REJECTED';
export const ARTICLE_REVISION_REVERT = 'ARTICLE_REVISION_REVERT';
export const ARTICLE_REVISION_REVERT_SUCCESS = 'ARTICLE_REVISION_REVERT_SUCCESS';

export const ARTICLE_SET_TRANSLATION_SOURCE_ALIAS = 'ARTICLE_SET_TRANSLATION_SOURCE_ALIAS';
export const ARTICLE_SET_TRANSLATION_SOURCE_ALIAS_REJECTED = 'ARTICLE_SET_TRANSLATION_SOURCE_ALIAS_REJECTED';

// Products
export const ARTICLE_PRODUCTS_SAVE_HANDLED = 'ARTICLE_PRODUCTS_SAVE_HANDLED';

// VideoHub
export const ARTICLE_VIDEOHUB_HANDLED = 'ARTICLE_VIDEOHUB_HANDLED';

// Articles
export const ARTICLE_LIST_FETCH = 'ARTICLE_LIST_FETCH';
export const ARTICLE_LIST_DISPOSE = 'ARTICLE_LIST_DISPOSE';

// Autocomplete
export const ARTICLE_AUTOCOMPLETE = 'ARTICLE_AUTOCOMPLETE';
export const ARTICLE_AUTOCOMPLETE_SUCCESS = 'ARTICLE_AUTOCOMPLETE_SUCCESS';
export const ARTICLE_AUTOCOMPLETE_FAILURE = 'ARTICLE_AUTOCOMPLETE_FAILURE';

// Vocabs
export const ARTICLE_GET_SUGGESTIONS = 'ARTICLE_GET_SUGGESTIONS';
export const ARTICLE_AUTO_LINK_VOCAB = 'ARTICLE_AUTO_LINK_VOCAB';
