import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_ARTICLE_LINK } from './defaults';

const renderElementArticleLink = (options) => {
  const { articleLink } = setDefaults(options, DEFAULTS_ARTICLE_LINK);
  return getRenderElement(articleLink);
};

export default renderElementArticleLink;
