import {
  SET_SELECTED_USER,
  USER_EDIT_ONCHANGE,
  USER_SAVE_EDIT,
  USER_GET,
  SEND_PASSWORD_RESET, UPDATE_PROFILE,
} from '../constants/actionTypes';

export const getUser = value => ({ type: USER_GET, value });
export const setSelectedUser = value => ({ type: SET_SELECTED_USER, value });
export const editUser = (field, value) => ({ type: USER_EDIT_ONCHANGE, value, field });
export const saveUser = callback => ({ type: USER_SAVE_EDIT, value: { callback } });
export const sendPassword = () => ({ type: SEND_PASSWORD_RESET });
export const updateProfile = value => ({ type: UPDATE_PROFILE, value });
export const profileImageRequest = value => ({ type: UPDATE_PROFILE, value });
