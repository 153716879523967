import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

import { onSearchMedia } from '../../actions/dialog';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import { Table, TableRow, TableBody, TableHead, TableCell, TableHeadCell, TablePagination } from '../ui/table/Table';
import MediaRatio from './MediaRatio';
import Loader from '../ui/Loader';
import GenericWorkflowSelector from './common/WorkflowSelector';
import { optimiseImage } from '../helper/media';

import { CMS_DATE_FORMAT } from '../../constants/common';
import PageSection from '../common/page/PageSection';
import ImageSelector from '../ui/media/ImageSelector';

const styles = theme => ({
  root: {
    minHeight: 400,
  },
  gridList: {
    width: '100%',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  imageWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: 'black',
    maxWidth: 180,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    '& button': {
      position: 'absolute',
      zIndex: 2,
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  filter: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  table: {
    margin: theme.spacing(0, -3),
  },
  workflow: {
    marginTop: theme.spacing(1),
  },
  imageDrop: {
    height: 48,
  },
});

const truncateImageDescription = (description) => {
  if (!description || description.length === 0) return '';
  const max = 120;
  if (description.length > max) {
    return `${description.slice(0, max)}...`;
  }
  return description;
};

// @todo refactor removing filters to another object, filters added to redux
const ImageList = ({
  classes, isImageSearching, images, size, action, showDroppableZone, pageStyle,
  onSearchMedia: onSearch,
}) => {
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(size);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedWorkflow, setSelectedWorkflow] = useState('all');

  useEffect(() => {
    onSearch({
      bundle: 'remote_image',
      q: query,
      limit,
      offset,
      workflowTid: selectedWorkflow === 'all' ? '' : selectedWorkflow,
    });
  }, [query, limit, page, selectedWorkflow, onSearch, offset]);

  const TableGrid = props => (<Grid item xs={12} {...props} />);

  const Wrapper = pageStyle ? PageSection : TableGrid;
  const filterAttrs = pageStyle ? { includePadding: true } : { className: classes.filter };
  const tableAttrs = pageStyle ? { } : { className: classes.table };
  const tableRef = React.createRef();
  return (
    <>
      <Wrapper {...filterAttrs}>
        <Grid container spacing={2} justify={'space-between'} alignItems={'center'}>
          {showDroppableZone && <Grid item xs={6}>
            <ImageSelector
              label="Add Images"
              helperText="Drag image from desktop, chp or click to add from image dialog (png and jpeg only)"
              multi
              // rerun search after image upload
              selectImage={() => onSearch({
                bundle: 'remote_image',
                q: query,
                limit,
                offset,
                workflowTid: selectedWorkflow === 'all' ? '' : selectedWorkflow,
              })}
              uploadOnly
            />
          </Grid>}
          <Grid item xs={6}>
            <div>
              <TextSearchFilter
                placeholder={'Search'}
                defaultValue={query}
                onSubmit={(textQuery) => {
                  setQuery(textQuery);
                  setPage(1);
                }}
              />
            </div>
            {showDroppableZone && <div className={classes.workflow}>
              <GenericWorkflowSelector
                selectedValue={selectedWorkflow}
                onChange={setSelectedWorkflow}
                showAllOption
                margin={'none'}
              />
            </div>}
          </Grid>
          {!showDroppableZone && <Grid item xs={6}><GenericWorkflowSelector
            selectedValue={selectedWorkflow}
            onChange={setSelectedWorkflow}
            showAllOption
          /></Grid> }
        </Grid>
      </Wrapper>
      <Wrapper>
        <div {...tableAttrs} ref={tableRef}>
          {isImageSearching && <Loader />}
          <Table>
            <TableHead>
              <TableRow hasBorder>{[
                'Image', 'Description', 'Copyright', 'Workflow', 'Uploaded by',
                'Uploaded date', '',
              ]
                .map((label, index, list) => (<TableHeadCell
                  key={label}
                  align={list.length - 1 === index ? 'right' : 'left'}
                ><Typography className={classes.noWrap}>{label}</Typography></TableHeadCell>))}
              </TableRow>
            </TableHead>
            <TableBody>
              {images.length === 0 && !isImageSearching && <TableRow>
                <TableCell colSpan={7} align={'center'} variant={'body2'}>No images found</TableCell>
              </TableRow>}
              {images.length > 0 && images.map(({ data }) => (
                <TableRow hasActions key={data.mid}>
                  <TableCell>
                    <MediaRatio>
                      <img src={optimiseImage(data.url, { width: 200 })} alt={data.title} />
                    </MediaRatio>
                  </TableCell>
                  <TableCell
                    width={'30%'}
                    title={data.description}
                    dangerouslySetInnerHTML={{ __html: truncateImageDescription(data.description) }}
                  />
                  <TableCell>{data.copyright}</TableCell>
                  <TableCell align={'center'}>{data.workflow ? data.workflow.name : ''}</TableCell>
                  <TableCell>{(data.uid > 0 && data.cmsUser?.name) || ''}</TableCell>
                  <TableCell className={classes.noWrap}>
                    {moment.unix(Number(data.created)).format(CMS_DATE_FORMAT)}
                  </TableCell>
                  <TableCell align={'right'}>{action(data)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {images.length > 0 && <Grid container direction={'row-reverse'}>
            <TablePagination
              rowsPerPageOptions={[12, 24, 48]}
              component="div"
              count={10000}
              rowsPerPage={limit}
              page={page}
              labelDisplayedRows={() => ''} // nothing to show as there is no total
              onChangePage={(e, i) => {
                setPage(i++);
                setOffset(page * limit);
                if (tableRef.current) { tableRef.current.scrollIntoView(); }
              }}
              onChangeRowsPerPage={e => setLimit(e.target.value)}
            />
          </Grid>}
        </div>
      </Wrapper>
    </>
  );
};

ImageList.propTypes = {
  classes: PropTypes.object.isRequired,
  onSearchMedia: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  size: PropTypes.number,
  action: PropTypes.func.isRequired,
  isImageSearching: PropTypes.bool,
  showDroppableZone: PropTypes.bool,
  pageStyle: PropTypes.bool,
};

ImageList.defaultProps = {
  size: 12,
  isImageSearching: false,
  showDroppableZone: false,
  pageStyle: false,
};

export default withStyles(styles)(connect(
  ({ media: { images, isImageSearching } }) =>
    ({ images, isImageSearching }),
  {
    onSearchMedia,
  },
)(ImageList));

