import { FIELD_HIDE_COMMENTING, FIELD_HIDE_NEWSLETTER_SIGNUP, FIELD_LUXURY_COMMERCIAL } from './article/articleFields';

export const FIELD_IS_COMMERCIAL = 'isCommercial';
export const FIELD_IS_DISABLE_ALL_SLOTS = 'isDisableAllSlots';
export const FIELD_IS_DISABLE_SPONSORED_CAPSULE = 'isDisableSponsoredCapsule';
export const FIELD_DISABLE_AFFILIATE_SECTION = 'field_disable_affiliates';
export const FIELD_TAKEOVER = 'takeover';
export const FIELD_COMMERCIAL_CAMPAIGN = 'commercialCampaign';

export const TAXONOMY_EXTRA_FIELDS = [
  FIELD_HIDE_COMMENTING,
  FIELD_HIDE_NEWSLETTER_SIGNUP,
  FIELD_IS_COMMERCIAL,
  FIELD_IS_DISABLE_ALL_SLOTS,
  FIELD_IS_DISABLE_SPONSORED_CAPSULE,
  FIELD_DISABLE_AFFILIATE_SECTION,
  FIELD_TAKEOVER,
  FIELD_COMMERCIAL_CAMPAIGN,
  FIELD_LUXURY_COMMERCIAL,
];
