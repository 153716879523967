/**
 * DUPLICATION: eventStateEpic
 * @todo review entity default state handling, create a generic state fetch system
 */

import { EMPTY, from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  GALLERY_FETCH,
  GALLERY_FETCH_SUCCESS,
  GALLERY_INIT,
  GALLERY_DISPOSE, GALLERY_SAVE_SUCCESS, MEDIA_EDIT_SUCCESS,
} from '../../constants/actionTypes/media';
import {
  DATASTATE_LOCAL_DISPOSE,
  DATASTATE_SERVER_DISPOSE_DATA,
  DATASTATE_SERVER_SET_DATA,
} from '../../constants/actionTypes/dataState';
import GalleryEntity from '../../entities/media/GalleryEntity';
import { getFromState } from '../../utils/stateHelper';
import { FIELD_IMAGES } from '../../constants/media/galleryFields';
import { setServerProp } from '../../actions/dataState';
import {
  FIELD_ALT,
  FIELD_CAPTION,
  FIELD_COPYRIGHT,
  FIELD_DESCRIPTION,
  FIELD_TITLE,
} from '../../constants/media/imageFields';

export const clearServerStateOnFetch = action$ => action$.pipe(
  ofType(GALLERY_FETCH),
  map(() => ({ type: DATASTATE_SERVER_DISPOSE_DATA })),
);

export const setServerStateOnSuccess = (action$, state$) => action$.pipe(
  ofType(GALLERY_FETCH_SUCCESS),
  withLatestFrom(state$),
  filter(([,
    { router: { location: { pathname } } },
  ]) => /\/media\/gallery\/([a-z0-9]+)/.test(pathname)),
  mergeMap(([
    { value },
  ]) => {
    const entity = new GalleryEntity();
    const data = entity.getDataFromPayload(value);
    return from([
      { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: data },
    ]);
  }),
);

export const disposeState = action$ => action$.pipe(
  ofType(GALLERY_INIT, GALLERY_DISPOSE),
  mergeMap(() => from([
    { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
    { type: DATASTATE_SERVER_DISPOSE_DATA },
  ])),
);

export const handleSaveSuccess = action$ => action$.pipe(
  ofType(GALLERY_SAVE_SUCCESS),
  mergeMap(({ value }) => {
    const entity = new GalleryEntity();
    const data = entity.getDataFromPayload(value);
    return from([
      { type: DATASTATE_LOCAL_DISPOSE, quiet: true },
      { type: DATASTATE_SERVER_DISPOSE_DATA, quiet: true },
      { type: DATASTATE_SERVER_SET_DATA, value: data },
    ]);
  }),
);

export const handleMediaEdit = (action$, state$) => action$.pipe(
  ofType(MEDIA_EDIT_SUCCESS),
  withLatestFrom(state$),
  filter(([, {
    router: { location: { pathname } },
  }]) => /^\/media\/gallery/.test(pathname)),
  mergeMap(([
    { value },
    { serverState },
  ]) => {
    const image = getFromState([FIELD_IMAGES, value.mid], serverState);
    if (!image) {
      return EMPTY;
    }
    return of(setServerProp(value.mid, {
      ...value,
      [FIELD_TITLE]: image[FIELD_TITLE] || value[FIELD_TITLE] || '',
      [FIELD_ALT]: image[FIELD_ALT] || value[FIELD_ALT] || '',
      [FIELD_CAPTION]: image[FIELD_CAPTION] || value[FIELD_CAPTION] || '',
      [FIELD_COPYRIGHT]: image[FIELD_COPYRIGHT] || value[FIELD_COPYRIGHT] || '',
      [FIELD_DESCRIPTION]: image[FIELD_DESCRIPTION] || value[FIELD_DESCRIPTION] || '',
    }, [FIELD_IMAGES]));
  }),
);
