import {
  USER_EVENTS_FETCH,
  USER_EVENTS_DISPOSE,
  USER_EVENTS_DELETE,
  USER_EVENTS_SAVE,
  USER_EVENTS_SET_FILTER,
} from '../../constants/actionTypes/service/userEvents';

export const fetchUserEvents = value => ({ type: USER_EVENTS_FETCH, value });
export const disposeUserEvents = () => ({ type: USER_EVENTS_DISPOSE });
export const deleteUserEvent = value => ({ type: USER_EVENTS_DELETE, value });
export const saveUserEvent = value => ({ type: USER_EVENTS_SAVE, value });
export const setUserEventsFilter = value => ({ type: USER_EVENTS_SET_FILTER, value });
