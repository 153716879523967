import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
} from '../components/layout/constants';
import {
  FIELD_ARTICLES,
  FIELD_ARTICLES_ORDER,
  FIELD_IS_DEFAULT,
  FIELD_NAME,
} from '../constants/manualList/manualListFields';
import ManualListEntity, { ARTICLE_ID } from '../entities/ManualListEntity';
import { getArticleCountFromType } from './layoutHelper';
import { FIELD_ID } from '../constants/common/commonFields';
import { sanitiseLayoutArticle } from "./sanitiser";

const ARTICLE_BUFFER = 0;

/**
 * Get list by list Id
 * @param   {object} lists
 * @param   {number} listId
 * @return  {object}
 */
export const getList = (lists, listId) => {
  if (typeof (lists[listId]) === 'undefined') return null;
  return lists[listId];
};

/**
 * Get the manual list for a component
 * @param   {object} lists
 * @param   {object} component
 * @return  {null|*}
 */
export const getComponentList = (lists, component) => {
  if (
    lists && component &&
    component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION] &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id
  ) {
    return getList(lists, component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id);
  }
  return null;
};

/**
 * Gets the ordered array of articles from a manual list
 * @param   {object} list   Manual list data object
 * @return  {array}
 */
export const getListArticles = (list) => {
  if (!list || !list[FIELD_ARTICLES] || !list[FIELD_ARTICLES_ORDER]) {
    return [];
  }
  return ManualListEntity.getOrderedArray(list[FIELD_ARTICLES], list[FIELD_ARTICLES_ORDER]);
};

/**
 * Get a manual list id keyed object of the counts of required articles that components need from
 * manual lists.
 * @param   {array} components
 * @return  {object}
 */
const getManualListCounts = (components) => {
  const lists = {};
  for (let i = 0; i < components.length; i += 1) {
    const component = components[i];
    if (
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null
    ) {
      if (typeof lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id] === 'undefined') {
        lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id] = 0;
      }
      lists[component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id] +=
        getArticleCountFromType(component.type);
    }
  }
  return lists;
};

/**
 * Returns a manual list data object with articles ordered and purged to count
 * @param   {object} list
 * @param   {number} count
 * @return  {object}
 */
const purgeListToCount = ({
  [FIELD_ARTICLES]: articles,
  [FIELD_ARTICLES_ORDER]: articlesOrder,
  ...list
}, count) => ({
  ...list,
  [FIELD_ARTICLES]: ManualListEntity
    .getOrderedArray(articles, articlesOrder, sanitiseLayoutArticle)
    .slice(0, count + ARTICLE_BUFFER),
});

/**
 * Process lists for saving with the Layout save API, lists articles are purged to the required
 * count
 * @param   {array} components
 * @param   {array} lists
 * @return  {object}
 */
export const processManualLists = (components, lists) => {
  const requiredLists = {};
  const listCounts = getManualListCounts(components);
  components.forEach((component) => {
    if (
      typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
      component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null
    ) {
      const id = component[CONTENT_SOURCE_MANUAL_LIST_SECTION].id;
      const list = lists.find(({ id: listId }) => listId === id);
      if (list) {
        requiredLists[id] = purgeListToCount(list, listCounts[id]);
      }
    }
  });
  return requiredLists;
};

export const getListObjectFromData = ({ data, manualListSettings }) => {
  return ({
    [FIELD_NAME]: data?.[FIELD_NAME] || 'default',
    [FIELD_IS_DEFAULT]: manualListSettings ? manualListSettings?.[FIELD_IS_DEFAULT] || false : false,
    [FIELD_ARTICLES]: manualListSettings?.[FIELD_ARTICLES]
      ? ManualListEntity.getArrayAsKeyed(manualListSettings[FIELD_ARTICLES], ARTICLE_ID)
      : {},
    [FIELD_ARTICLES_ORDER]: manualListSettings?.[FIELD_ARTICLES]
      ? ManualListEntity.getArrayOrder(manualListSettings[FIELD_ARTICLES], ARTICLE_ID)
      : [],
    [FIELD_ID]: data[FIELD_ID],
  });
};
