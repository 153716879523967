import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from './builder/TextField';
import debounce from '../../utils/debounce';

const DebounceInputOnChange = ({ onChange, delayTime, ...props }) => {
  const inputEl = useRef(null);
  return (<TextField
    {...props}
    inputRef={inputEl}
    onChange={debounce(() => onChange(inputEl.current.value), delayTime)}
  />);
};

DebounceInputOnChange.defaultProps = {
  delayTime: 200,
};

DebounceInputOnChange.propTypes = {
  onChange: PropTypes.func.isRequired,
  delayTime: PropTypes.number,
};

export default DebounceInputOnChange;
