import BookingElement from './components/BookingElement';

export const ELEMENT_BOOKING = 'booking';
export const PROP_HOTEL = 'hotel';

export const DEFAULTS_BOOKING = {
  booking: {
    component: BookingElement,
    type: ELEMENT_BOOKING,
    rootProps: {
      className: 'slate-booking',
    },
  },
};
