import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export const styles = () => ({
  root: {
    '& img': {
      width: '100%',
    },
  },
});

const PreviewImage = ({ classes, src }) => (
  <div className={classes.root}>
    {src && <img src={src} alt="preview" />}
  </div>
);

PreviewImage.propTypes = {
  classes: PropTypes.object.isRequired,
  src: PropTypes.string,
};

PreviewImage.defaultProps = {
  src: '',
};

export default withStyles(styles)(PreviewImage);
