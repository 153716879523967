export const FIELD_NAME = 'name';
export const FIELD_ARTICLES = 'articles';
export const FIELD_IS_DEFAULT = 'isDefault';
export const FIELD_ARTICLES_ORDER = 'articlesOrder';
export const FIELD_UPDATED = 'updated';

// API only fields
export const FIELD_EXTRA_DATA = 'field_manual_list_json';

export const MANUAL_LIST_PAYLOAD_FIELDS = [
  FIELD_NAME,
];

export const MANUAL_LIST_EXTRA_FIELDS = [
  FIELD_ARTICLES,
  FIELD_IS_DEFAULT,
];

export const MANUAL_LIST_FIELD_MAPPING = [
  [FIELD_NAME, 'name', 'Name'],
  [FIELD_ARTICLES, FIELD_ARTICLES, 'Articles'],
  [FIELD_IS_DEFAULT, FIELD_IS_DEFAULT, 'Is default list'],
];
