import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TimeForm from './TimeForm';
import ListTime from './ListTime';

import { scheduleExport } from '../../../actions/edition';

const TimeSetting = ({ saveExportTime: onSubmit }) => {
  return (<Grid container direction={'column'}>
    <Grid item>
      <TimeForm onSubmit={onSubmit} />
      <ListTime />
    </Grid>
  </Grid>);
};

TimeSetting.propTypes = {
  saveExportTime: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { saveExportTime: scheduleExport },
)(TimeSetting);

