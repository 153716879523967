import { setDefaults } from '@udecode/slate-plugins';
import renderElementfaq from './renderElementfaq';
import { DEFAULTS_FAQ } from './defaults';

const FaqPlugin = (options) => {
  const { faq } = setDefaults(options, DEFAULTS_FAQ);

  return ({
    renderElement: renderElementfaq(options),
    voidTypes: [faq.type],
  });
};

export default FaqPlugin;
