import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import JWPlayerList from '../embed/JWPlayerList';
import VideoDugoutList from '../embed/VideoDugoutList';
import YouTubeForm from '../embed/YouTubeForm';

import {
  VIDEO_DUGOUT, VIDEO_JWPLAYER, VIDEO_YOUTUBE, VIDEO_DM, VIDEO_TYPES, VIDEO_ELEPHANT, VIDEO_PLAYINC,
} from '../../constants/media/media';
import DailyMotionForm from '../embed/DailyMotionForm';
import PlayIncVideoSelectorList from '../integration/playInc/PlayIncVideoSelectorList';

const VideoSelectorDialog = (props) => {
  const {
    selectedVideo,
    publicationConfig,
    validTypes,
    ...rest
  } = props;

  let videoProviders = [];

  if (publicationConfig.dailyMotionConfig) {
    videoProviders.push(VIDEO_DM);
  }
  if (publicationConfig.jwPlayerConfig) {
    videoProviders.push(VIDEO_JWPLAYER);
  }
  if (publicationConfig.dugoutToken) {
    videoProviders.push(VIDEO_DUGOUT);
  }
  videoProviders.push(VIDEO_YOUTUBE);
  if (publicationConfig.videoElephantConfig) {
    videoProviders.push(VIDEO_ELEPHANT);
  }
  if (publicationConfig.playInc) {
    videoProviders.push(VIDEO_PLAYINC);
  }

  if (validTypes.length > 0) {
    videoProviders = videoProviders.filter(type => validTypes.indexOf(type) !== -1);
  }

  const [tab, setTab] = useState(videoProviders[0]);

  return (
    <Dialog
      tabs={videoProviders.map(type => ({ label: VIDEO_TYPES[type], value: type }))}
      tab={tab}
      setTab={setTab}
      maxWidth={'md'}
      margin
      {...rest}
    >
      {tab === VIDEO_DM && <DailyMotionForm
        config={
          typeof publicationConfig.dailyMotionConfig === 'object'
            ? publicationConfig.dailyMotionConfig
            : {}
        }
        videoType={VIDEO_DM}
        onSelect={video => selectedVideo([VIDEO_DM, video])}
      />}
      {tab === VIDEO_JWPLAYER && <JWPlayerList
        selectedVideo={video => selectedVideo([VIDEO_JWPLAYER, video])}
      />}
      {tab === VIDEO_DUGOUT && <VideoDugoutList
        selectedVideo={video => selectedVideo([VIDEO_DUGOUT, video])}
      />}
      {tab === VIDEO_YOUTUBE && <Grid container>
        <YouTubeForm onSelect={youtubeId => selectedVideo([VIDEO_YOUTUBE, { id: youtubeId }])} />
      </Grid>}
      {tab === VIDEO_ELEPHANT && <JWPlayerList
        videoType={VIDEO_ELEPHANT}
        selectedVideo={video => selectedVideo([VIDEO_JWPLAYER, video])}
      />}
      {tab === VIDEO_PLAYINC && <PlayIncVideoSelectorList
        onSelect={
          !publicationConfig.jwPlayerConfig ? video => selectedVideo([VIDEO_DM, { url: video.mp4 }, video]) : video => selectedVideo([VIDEO_JWPLAYER, { mediaid: video.mediaid, mp4: video.mp4 }, video])}
      />
      }
    </Dialog>
  );
};

VideoSelectorDialog.propTypes = {
  selectedVideo: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  validTypes: PropTypes.array,
};

VideoSelectorDialog.defaultProps = {
  validTypes: [],
};

export default connect(
  ({
    frame: { selectedPublication: { publicationConfig } },
  }) => ({ publicationConfig }),
  {},
)(VideoSelectorDialog);
