import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_BLOCKQUOTE } from './defaults';

const renderElementBlockquote = (options) => {
  const { blockquote } = setDefaults(options, DEFAULTS_BLOCKQUOTE);
  return getRenderElement(blockquote);
};

export default renderElementBlockquote;
