export const APP_LOAD = 'APP_LOAD';
export const SENTRY_USER_IS_SET = 'SENTRY_USER_IS_SET';

export const CONTEXT_SET = 'CONTEXT_SET';
export const CONTEXT_DISPOSE = 'CONTEXT_DISPOSE';

export const ADD_SIDEBAR_COMPONENT = 'ADD_SIDEBAR_COMPONENT';

export const SIDEBAR_COMPONENTS = 'SIDEBAR_COMPONENTS';
export const COMPONENT_SIDEBAR_LOADED = 'COMPONENT_SIDEBAR_LOADED';
export const SELECT_SIDEBAR_COMPONENT = 'SELECT_SIDEBAR_COMPONENT';
export const SECTION_SIDEBAR_SELECTED = 'SECTION_SIDEBAR_SELECTED';
export const REMOVE_SIDEBAR_COMPONENT = 'REMOVE_SIDEBAR_COMPONENT';
export const REORDER_SIDEBAR_COMPONENT = 'REORDER_SIDEBAR_COMPONENT';
export const SIDEBAR_SAVE = 'SIDEBAR_SAVE';
export const SIDEBAR_SAVE_SUCCESS = 'SIDEBAR_SAVE_SUCCESS';
export const SIDEBAR_SAVE_REJECTED = 'SIDEBAR_SAVE_REJECTED';
export const REMOVE_SIDEBAR_SECTION = 'REMOVE_SIDEBAR_SECTION';
export const SIDEBAR_CONTEXT_SET = 'SIDEBAR_CONTEXT_SET';
export const REMOVE_SIDEBAR_SECTION_COMPLETED = 'REMOVE_SIDEBAR_SECTION_COMPLETED';
export const LAYOUT_SIDEBAR_SECTIONS_LOADED = 'LAYOUT_SIDEBAR_SECTIONS_LOADED';
export const LAYOUT_SIDEBAR_PAGE_ONLOAD = 'LAYOUT_SIDEBAR_PAGE_ONLOAD';
export const SIDEBAR_FETCH_REJECTED = 'SIDEBAR_FETCH_REJECTED';
export const SIDEBAR_FETCH_LOADED = 'SIDEBAR_FETCH_LOADED';
export const CURRENT_SIDEBAR_RESET = 'CURRENT_SIDEBAR_RESET';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HOVER_SIDEBAR = 'HOVER_SIDEBAR';

export const PROFILE_PERMISSION_SUCCESSFUL = 'PROFILE_PERMISSION_SUCCESSFUL';
export const PROFILE_PERMISSION_REJECTED = 'PROFILE_PERMISSION_REJECTED';
export const PROFILE_FETCH_SUCCESS = 'PROFILE_FETCH_SUCCESS';
export const PROFILE_FETCH_REJECTED = 'PROFILE_FETCH_REJECTED';
export const USER_AUTHENTICATION_SUCCESS = 'USER_AUTHENTICATION_SUCCESS';
export const REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN';
export const AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const REDIRECT_TO_HOME = 'REDIRECT_TO_HOME';
export const CHECK_SESSION_EXISTS = 'CHECK_SESSION_EXISTS';
export const OPEN_NEW_TAB = 'OPEN_NEW_TAB';
export const OPEN_NEW_TAB_SUCCESS = 'OPEN_NEW_TAB_SUCCESS';

export const SET_BREAKING_NEWS_IMAGE = 'SET_BREAKING_NEWS_IMAGE';
export const BREAKING_NEWS_REQUEST_REJECTED = 'BREAKING_NEWS_REQUEST_REJECTED';

export const SET_WORKFLOW_GENERIC = 'SET_WORKFLOW_GENERIC';
export const SET_DEFAULT_ARTICLE_TYPE = 'SET_DEFAULT_ARTICLE_TYPE';
export const SET_DEFAULT_CONTENT_SOURCE = 'SET_DEFAULT_CONTENT_SOURCE';
export const SET_DEFAULT_EDITION_SECTION = 'SET_DEFAULT_EDITION_SECTION';
export const SET_ARTICLE_WORKFLOW = 'SET_ARTICLE_WORKFLOW';
export const SET_VOCAB = 'SET_VOCAB';

export const MENU_SAVE = 'MENU_SAVE';
export const MENU_FETCH_LIST = 'MENU_FETCH_LIST';
export const MENU_TREE_FETCH = 'MENU_TREE_FETCH';
export const MENU_SAVE_SUCCESS = 'MENU_SAVE_SUCCESS';
export const MENU_LIST_LOADED = 'MENU_LIST_LOADED';
export const MENU_TREE_LOADED = 'MENU_TREE_LOADED';
export const MENU_TREE_ONCHANGE = 'MENU_TREE_ONCHANGE';
export const MENU_TREE_ONEDIT = 'MENU_TREE_ONEDIT';
export const MENU_TREE_ONREMOVE = 'MENU_TREE_ONREMOVE';
export const MENU_ITEM_SECTION = 'MENU_ITEM_SECTION';
export const MENU_ITEM_CHAIN = 'MENU_ITEM_CHAIN';
export const MENU_DIALOG_CLOSE = 'MENU_DIALOG_CLOSE';
export const MENU_DIALOG_OPEN = 'MENU_DIALOG_OPEN';

export const USER_GET = 'USER_GET';
export const USER_LOADED = 'USER_LOADED';
export const USERS_LIST_LOADED = 'USERS_LIST_LOADED';
export const USERS_LIST_FAILED = 'USERS_LIST_FAILED';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const USER_EDIT_ONCHANGE = 'USER_EDIT_ONCHANGE';
export const USER_SAVE_EDIT = 'USER_SAVE_EDIT';
export const USER_SAVE_EDIT_SUCCESS = 'USER_SAVE_EDIT_SUCCESS';
export const USER_SAVE_EDIT_FAILED = 'USER_SAVE_EDIT_FAILED';
export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const UPDATE_PROFILE_REJECTED = 'UPDATE_PROFILE_REJECTED';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_RELOGIN = 'UPDATE_PROFILE_RELOGIN';
export const PASSWORD_RESET_SENT = 'PASSWORD_RESET_SENT';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_INPUT_CHANGE = 'RESET_PASSWORD_INPUT_CHANGE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const AD_REPLACEMENT_ONSAVE = 'AD_REPLACEMENT_ONSAVE';
export const AD_REPLACEMENT_ONSAVE_READY = 'AD_REPLACEMENT_ONSAVE_READY';
export const AD_REPLACEMENT_SAVE_SUCCESS = 'AD_REPLACEMENT_SAVE_SUCCESS';
export const AD_REPLACEMENT_SAVE_REJECTED = 'AD_REPLACEMENT_SAVE_REJECTED';
export const AD_REPLACEMENT_FETCH = 'AD_REPLACEMENT_FETCH';
export const AD_REPLACEMENT_SUCCESS = 'AD_REPLACEMENT_SUCCESS';
export const AD_REPLACEMENT_UPDATE_PROPERTY = 'AD_REPLACEMENT_UPDATE_PROPERTY';
export const EDITORS_LETTER_UPDATE_PROPERTY = 'EDITORS_LETTER_UPDATE_PROPERTY';

export const AD_REPLACEMENT_REJECTED = 'AD_REPLACEMENT_REJECTED';
export const EDITORS_LETTER_FETCH = 'EDITORS_LETTER_FETCH';
export const EDITORS_LETTER_SUCCESS = 'EDITORS_LETTER_SUCCESS';
export const EDITORS_LETTER_REJECTED = 'EDITORS_LETTER_REJECTED';
export const EDITORS_LETTER_ONSAVE = 'EDITORS_LETTER_ONSAVE';
export const EDITORS_LETTER_SAVE_SUCCESS = 'EDITORS_LETTER_SAVE_SUCCESS';
export const EDITORS_LETTER_SAVE_REJECTED = 'EDITORS_LETTER_SAVE_REJECTED';
export const AD_ASSIGN_SECTION = 'AD_ASSIGN_SECTION';
export const AD_ASSIGN_ARTICLE = 'AD_ASSIGN_ARTICLE';

export const SIDEBAR_COMPONENT_PROPERTY_ONCHANGE = 'SIDEBAR_COMPONENT_PROPERTY_ONCHANGE';

export const REMOVE_MANUAL_LIST_COMPLETED = 'REMOVE_MANUAL_LIST_COMPLETED';
export const MANUAL_LIST_SECTION_SELECTED = 'MANUAL_LIST_SECTION_SELECTED';
export const MANUAL_LIST_LABEL_ADDED = 'MANUAL_LIST_LABEL_ADDED';
export const REMOVE_MANUAL_LIST = 'REMOVE_MANUAL_LIST';
export const MANUAL_LIST_FETCH_ALL = 'MANUAL_LIST_FETCH_ALL';
export const MANUAL_LIST_ALL_LOADED = 'MANUAL_LIST_ALL_LOADED';
export const MANUAL_LIST_ALL_REJECTED = 'MANUAL_LIST_ALL_REJECTED';
export const MANUAL_LIST_FETCH_LOADED = 'MANUAL_LIST_FETCH_LOADED';
export const MANUAL_LIST_FROM_SERVER = 'MANUAL_LIST_FROM_SERVER';

export const ARTICLE_DRAG_START = 'ARTICLE_DRAG_START';
export const ARTICLE_DRAG_END = 'ARTICLE_DRAG_END';

export const SET_UNSAVED_EDITION_PAGE = 'SET_UNSAVED_EDITION_PAGE';
export const SET_ARTICLE_SUGGESTION_TAG = 'SET_ARTICLE_SUGGESTION_TAG';

export const UPDATE_LOCAL_CAPTION_EDITION_COMPONENT = 'UPDATE_LOCAL_CAPTION_EDITION_COMPONENT';

export const SAVE_INITIAL_HISTORY_EDITION = 'SAVE_INITIAL_HISTORY_EDITION';
export const SAVE_INITIAL_HISTORY_EDITION_DETAIL = 'SAVE_INITIAL_HISTORY_EDITION_DETAIL';
export const SET_UNSAVED_EDITION_DETAIL = 'SET_UNSAVED_EDITION_DETAIL';
export const SUGGESTIVE_VOCAB_SUCCESS = 'SUGGESTIVE_VOCAB_SUCCESS';
export const POPULAR_VOCAB_SUCCESS = 'POPULAR_VOCAB_SUCCESS';
export const POPULAR_VOCAB_REJECTED = 'POPULAR_VOCAB_REJECTED';
export const SUGGESTIVE_VOCAB_REJECTED = 'SUGGESTIVE_VOCAB_REJECTED';
export const PREVIEW_ARTICLE_READY = 'PREVIEW_ARTICLE_READY';
export const ARTICLE_PREVIEW_SUCCESS = 'ARTICLE_PREVIEW_SUCCESS';
export const ARTICLE_PREVIEW_REQUEST = 'ARTICLE_PREVIEW_REQUEST';
export const ARTICLE_PREVIEW_REJECTED = 'ARTICLE_PREVIEW_REJECTED';
export const ARTICLE_LIST_LOADED = 'ARTICLE_LIST_LOADED';
export const ARTICLE_LIST_DRAFT_LOADED = 'ARTICLE_LIST_DRAFT_LOADED';
export const ARTICLE_LIST_REVIEW_LOADED = 'ARTICLE_LIST_REVIEW_LOADED';
export const ARTICLE_LIST_PUBLISHED_LOADED = 'ARTICLE_LIST_PUBLISHED_LOADED';
export const ARTICLE_LIST_REJECTED = 'ARTICLE_LIST_REJECTED';
export const TRANSLATION_LIST_PAGE_LOADED = 'TRANSLATION_LIST_PAGE_LOADED';
export const FETCH_ARTICLE_DRAFT = 'FETCH_ARTICLE_DRAFT';
export const DISPOSE_FETCH_ARTICLE_DRAFT = 'DISPOSE_FETCH_ARTICLE_DRAFT';
export const FETCH_ARTICLE_REVIEW = 'FETCH_ARTICLE_REVIEW';
export const DISPOSE_FETCH_ARTICLE_REVIEW = 'DISPOSE_FETCH_ARTICLE_REVIEW';
export const FETCH_ARTICLE_PUBLISHED = 'FETCH_ARTICLE_PUBLISHED';
export const DISPOSE_FETCH_ARTICLE_PUBLISHED = 'DISPOSE_FETCH_ARTICLE_PUBLISHED';

export const FETCH_HERO_JW_PLAYER_VIDEO_DETAIL = 'FETCH_HERO_JW_PLAYER_VIDEO_DETAIL';
export const FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_SUCCESS = 'FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_SUCCESS';
export const FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_REJECTED = 'FETCH_HERO_JW_PLAYER_VIDEO_DETAIL_REJECTED';
export const ADD_MORE_TRANSLATION_MARKUP = 'ADD_MORE_TRANSLATION_MARKUP';
export const REMOVE_ARTICLE_TRANSLATION_COMPONENT = 'REMOVE_ARTICLE_TRANSLATION_COMPONENT';
export const SAVE_TRANSLATION_ARTICLE_COMPONENT = 'SAVE_TRANSLATION_ARTICLE_COMPONENT';
export const SET_FOCUS_TRANSLATION_COMPONENT_INDEX = 'SET_FOCUS_TRANSLATION_COMPONENT_INDEX';
export const SWITCH_BODY_TRANSLATION_COMPONENT = 'SWITCH_BODY_TRANSLATION_COMPONENT';
export const TRANSLATION_REQUEST_REJECTED = 'TRANSLATION_REQUEST_REJECTED';
export const TRANSLATION_LIST_REQUEST = 'TRANSLATION_LIST_REQUEST';
export const TRANSLATE_ARTICLE_REQUEST = 'TRANSLATE_ARTICLE_REQUEST';
export const TRANSLATE_INTERNAL_ARTICLE_REQUEST = 'TRANSLATE_INTERNAL_ARTICLE_REQUEST';
export const TRANSLATE_INTERNAL_ARTICLE_SUCCESS = 'TRANSLATE_INTERNAL_ARTICLE_SUCCESS';
export const TRANSLATE_INTERNAL_ARTICLE_REJECTED = 'TRANSLATE_INTERNAL_ARTICLE_REJECTED';
export const TRANSLATE_ARTICLE_REQUEST_SUCCESS = 'TRANSLATE_ARTICLE_REQUEST_SUCCESS';
export const TRANSLATE_ARTICLE_REQUEST_REJECTED = 'TRANSLATE_ARTICLE_REQUEST_REJECTED';
export const TRANSLATION_LIST_REQUEST_SUCCESS = 'TRANSLATION_LIST_REQUEST_SUCCESS';
export const TRANSLATION_FETCH_SECTION_SUCCESS = 'TRANSLATION_FETCH_SECTION_SUCCESS';
export const TRANSLATION_FETCH_SECTION_REJECTED = 'TRANSLATION_FETCH_SECTION_REJECTED';
export const TRANSLATION_LIST_REQUEST_REJECTED = 'TRANSLATION_LIST_REQUEST_REJECTED';

export const FETCH_SCORE_BOARD_COMPETITION = 'FETCH_SCORE_BOARD_COMPETITION';
export const DISPOSE_SCORE_BOARD_COMPETITION = 'DISPOSE_SCORE_BOARD_COMPETITION';
export const FETCH_SCORE_BOARD_MATCH = 'FETCH_SCORE_BOARD_MATCH';
export const SCORE_BOARD_MATCH_SUCCESS = 'SCORE_BOARD_MATCH_SUCCESS';
export const SCORE_BOARD_MATCH_REJECTED = 'SCORE_BOARD_MATCH_REJECTED';
export const SCORE_BOARD_COMPETITION_SUCCESS = 'SCORE_BOARD_COMPETITION_SUCCESS';
export const SET_SCOREBOARD_PROPERTY = 'SET_SCOREBOARD_PROPERTY';
export const SCORE_BOARD_COMPETITION_REJECTED = 'SCORE_BOARD_COMPETITION_REJECTED';
export const INIT_EMBED = 'INIT_EMBED';
export const INIT_FB_EMBED = 'INIT_FB_EMBED';

export const ARTICLE_STAT_FREQUENCY_CHANGE = 'ARTICLE_STAT_FREQUENCY_CHANGE';
export const FETCH_CMS_API_VERSION = 'FETCH_CMS_API_VERSION';
export const FETCH_CMS_API_VERSION_SUCCESS = 'FETCH_CMS_API_VERSION_SUCCESS';
export const FETCH_CMS_API_VERSION_REJECTED = 'FETCH_CMS_API_VERSION_REJECTED';

export const TERM_LIST_AUTHOR_LOADED = 'TERM_LIST_AUTHOR_LOADED';
export const TERM_LIST_AUTHOR_REJECTED = 'TERM_LIST_AUTHOR_REJECTED';
export const TERM_AUTHOR_ONSAVE = 'TERM_AUTHOR_ONSAVE';
export const TERM_FETCH_SECTION_TREE = 'TERM_FETCH_SECTION_TREE';
export const ON_CHANGE_SECTION_TREE = 'ON_CHANGE_SECTION_TREE';
export const TERM_FETCH_SECTION_TREE_SUCCESS = 'TERM_FETCH_SECTION_TREE_SUCCESS';
export const TERM_SECTION_TREE_FLATTENED = 'TERM_SECTION_TREE_FLATTENED';
export const TERM_SECTION_TREE_MENU = 'TERM_SECTION_TREE_MENU';
export const TERM_FETCH_SECTION_TREE_REJECTED = 'TERM_FETCH_SECTION_TREE_REJECTED';
export const TERM_AUTHOR_SAVED = 'TERM_AUTHOR_SAVED';

export const ON_BLUR_HEADLINE_FIELD = 'ON_BLUR_HEADLINE_FIELD';
export const REDIRECT_TO_ARTICLE_PREVIEW = 'REDIRECT_TO_ARTICLE_PREVIEW';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';
export const CLONE_ARTICLE_REQUEST = 'CLONE_ARTICLE_REQUEST';
export const CLONE_ARTICLE_REQUEST_REJECTED = 'CLONE_ARTICLE_REQUEST_REJECTED';
export const RESET_PREVIEW_ID = 'RESET_PREVIEW_ID';

export const ARTICLE_EDITION_NOTE_SAVE = 'ARTICLE_EDITION_NOTE_SAVE';
export const ARTICLE_EDITION_NOTE_DELETE = 'ARTICLE_EDITION_NOTE_DELETE';

export const SET_TRANSLATED_ARTICLE_BODY = 'SET_TRANSLATED_ARTICLE_BODY';

export const ARTICLE_STATS_LOADED = 'ARTICLE_STATS_LOADED';
export const ARTICLE_REVERT_REJECTED = 'ARTICLE_REVERT_REJECTED';

export const TWITTER_FETCH = 'TWITTER_FETCH';
export const TWITTER_FETCH_SUCCESS = 'TWITTER_FETCH_SUCCESS';
export const TWITTER_ADD_USER_HANDLE = 'TWITTER_ADD_USER_HANDLE';
export const TWITTER_SEARCH_USER = 'TWITTER_SEARCH_USER';
export const TWITTER_SEARCH_USER_SUCCESS = 'TWITTER_SEARCH_USER_SUCCESS';
export const TWITTER_REMOVE_USER_HANDLE = 'TWITTER_REMOVE_USER_HANDLE';
export const TWITTER_ADD_KEYWORD = 'TWITTER_ADD_KEYWORD';
export const TWITTER_REMOVE_KEYWORD = 'TWITTER_REMOVE_KEYWORD';
export const TWITTER_ON_LOAD = 'TWITTER_ON_LOAD';
export const TWITTER_ON_DISPOSE = 'TWITTER_ON_DISPOSE';
export const TWITTER_REQUEST_REJECTED = 'TWITTER_REQUEST_REJECTED';

export const DRAWER_LOADED = 'DRAWER_LOADED';
export const SCROLL_TO_TOP = 'SCROLL_TO_TOP';
export const DUMMY_ACTION = 'DUMMY_ACTION';
export const STATE_PURGED = 'STATE_PURGED';

export const WINDOW_MESSAGE_RECIEVED = 'WINDOW_MESSAGE_RECIEVED';

export const COMPONENT_DRAG_START = 'COMPONENT_DRAG_START';
export const COMPONENT_DRAG_END = 'COMPONENT_DRAG_END';

export const DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS = 'DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS';

