import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';

import IconButton from '../../ui/buttons/IconButton';
import { FIELD_COMPONENTS_ORDER } from '../../../constants/layout/layoutFields';
import { unlockFieldLock } from '../../../actions/fieldLock';

const styles = theme => ({
  toolbarItem: {
    width: 'auto',
    marginRight: theme.spacing(1),
  },
  toolbarLabel: {
    marginRight: theme.spacing(0.5),
  },
});

const LockButton = (props) => {
  const {
    classes,
    localOrder,
    externalOrder,
    lockData,
    unlockFieldLock: unlock,
  } = props;
  if (!localOrder && !externalOrder) { return null; }
  const notice = !externalOrder ? 'locked by you' : `Locked by ${lockData?.user?.name}`;
  return (
    <Grid className={classes.toolbarItem} item container>
      <Typography className={classes.toolbarLabel} align={'right'} variant={'body2'}>
        {notice}
        {!!externalOrder && lockData?.timestamp && <><br />{moment.unix(lockData.timestamp).format('lll')}</>}
      </Typography>
      <IconButton
        disabled={!externalOrder}
        onClick={() => {
          if (window.confirm(`Unlocking the layout will revert any changes made by ${lockData?.user?.name}`)) {
            unlock(FIELD_COMPONENTS_ORDER);
          }
        }}
      ><LockIcon /></IconButton>
    </Grid>
  );
};

LockButton.propTypes = {
  classes: PropTypes.object.isRequired,
  unlockFieldLock: PropTypes.func.isRequired,
  localOrder: PropTypes.array,
  externalOrder: PropTypes.array,
  lockData: PropTypes.object,
};

LockButton.defaultProps = {
  localOrder: null,
  externalOrder: null,
  lockData: null,
};

export default withStyles(styles)(connect(
  ({
    localState: { [FIELD_COMPONENTS_ORDER]: localOrder },
    externalState: { [FIELD_COMPONENTS_ORDER]: externalOrder },
    fieldLock: { [FIELD_COMPONENTS_ORDER]: lockData },
  }) => ({ localOrder, externalOrder, lockData }),
  { unlockFieldLock },
)(LockButton));
