export const MANUAL_LIST_FETCH = 'MANUAL_LIST_FETCH';
export const MANUAL_LIST_FETCH_SUCCESS = 'MANUAL_LIST_FETCH_SUCCESS';
export const MANUAL_LIST_FETCH_REJECTED = 'MANUAL_LIST_FETCH_REJECTED';

export const MANUAL_LIST_FETCH_FROM_LAYOUT = 'MANUAL_LIST_FETCH_FROM_LAYOUT';
export const MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS = 'MANUAL_LIST_FETCH_FROM_LAYOUT_SUCCESS';
export const MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED = 'MANUAL_LIST_FETCH_FROM_LAYOUT_REJECTED';

export const MANUAL_LIST_CREATE = 'MANUAL_LIST_CREATE';

export const MANUAL_LIST_SAVE = 'MANUAL_LIST_SAVE';
export const MANUAL_LIST_SAVE_SUCCESS = 'MANUAL_LIST_SAVE_SUCCESS';
export const MANUAL_LIST_SAVE_REJECTED = 'MANUAL_LIST_SAVE_REJECTED';

// Manual list item management on Layout Page
export const MANUAL_LIST_INSERT_ITEM = 'MANUAL_LIST_INSERT_ITEM';
export const MANUAL_LIST_REPLACE_ITEM = 'MANUAL_LIST_REPLACE_ITEM';
export const MANUAL_LIST_CLEAR_ITEM = 'MANUAL_LIST_CLEAR_ITEM';

export const MANUAL_LIST_PIN_ITEM = 'MANUAL_LIST_PIN_ITEM';
export const MANUAL_LIST_UNPIN_ITEM = 'MANUAL_LIST_UNPIN_ITEM';

export const RELATED_LINKS_INSERT_ITEM = 'RELATED_LINKS_INSERT_ITEM';
export const RELATED_LINKS_REPLACE_ITEM = 'RELATED_LINKS_REPLACE_ITEM';
export const RELATED_LINKS_CLEAR_ITEM = 'RELATED_LINKS_CLEAR_ITEM';
export const RELATED_LINKS_PINNED_INSERT_ITEM = 'RELATED_LINKS_PINNED_INSERT_ITEM';
export const RELATED_LINKS_PINNED_REPLACE_ITEM = 'RELATED_LINKS_PINNED_REPLACE_ITEM';
export const RELATED_LINKS_PINNED_CLEAR_ITEM = 'RELATED_LINKS_PINNED_CLEAR_ITEM';

export const MANUAL_LIST_ITEM_SET_PROPERTY = 'MANUAL_LIST_ITEM_SET_PROPERTY';
export const MANUAL_LIST_ITEM_UNSET_PROPERTY = 'MANUAL_LIST_ITEM_UNSET_PROPERTY';
export const MANUAL_LIST_PINNED_SET_PROPERTY = 'MANUAL_LIST_PINNED_SET_PROPERTY';
export const MANUAL_LIST_PINNED_UNSET_PROPERTY = 'MANUAL_LIST_PINNED_UNSET_PROPERTY';

// Manual list item management on Manual list page
export const MANUAL_LIST_ADD_ITEM = 'MANUAL_LIST_ADD_ITEM';
export const MANUAL_LIST_REORDER_ITEM = 'MANUAL_LIST_REORDER_ITEM';
export const MANUAL_LIST_REMOVE_ITEM = 'MANUAL_LIST_REMOVE_ITEM';
export const MANUAL_LIST_SET_ITEM_SETTINGS = 'MANUAL_LIST_SET_ITEM_SETTINGS';
export const MANUAL_LIST_SET_ITEM_OVERRIDES = 'MANUAL_LIST_SET_ITEM_OVERRIDES';

export const MANUAL_LIST_SET_CONTEXT = 'MANUAL_LIST_SET_CONTEXT';
