import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import ContextBar from '../common/context/ContextBar';
import { PREMIUM_AD_REPLACEMENT } from '../../constants/premium';
import AdReplacementForm from './AdReplacementForm';
import EditorsLetterForm from './EditorsLetterForm';

const style = theme => ({
  noItem: {
    padding: theme.spacing(2),
  },
});

const PremiumAdSidebar = ({ selected, bundle, classes }) => {
  if (selected) {
    if (bundle === PREMIUM_AD_REPLACEMENT) {
      return (<ContextBar>
        <AdReplacementForm data={selected.data} />
      </ContextBar>);
    }
    return (<ContextBar>
      <EditorsLetterForm data={selected.data} />
    </ContextBar>);
  }

  return (<ContextBar>
    <Typography className={classes.noItem}>No item is selected</Typography>
  </ContextBar>);
};

PremiumAdSidebar.defaultProps = {
  selected: null,
  multipleTermSelected: null,
};

PremiumAdSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.object,
  bundle: PropTypes.string.isRequired,
};

export default withStyles(style)(PremiumAdSidebar);
