import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import {
  SortableContainer,
  SortableElement,
} from 'react-sortable-hoc';
import TextField from '../ui/TextField';

import Button from '../ui/buttons/Button';
import {
  appendContactGroup, appendContact, setGroupProperty, removeContact, removeContactGroup,
  sortContact,
} from '../../actions/contactUs';
import ContactItemForm from './ContactItemForm';
import ButtonWarning from '../ui/buttons/ButtonWarning';
import SectionTitle from '../common/SectionTitle';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  contactRoot: {
    marginTop: theme.spacing(2),
  },
  groupRoot: {
    marginTop: theme.spacing(4),
  },
  actionRoot: {
    marginTop: theme.spacing(2),
  },
});

const SortableItem = SortableElement(props => <ContactItemForm {...props} />);
const SortableRoot = SortableContainer(props => <Grid {...props} />);

const ContactGroup = ({
  classes, contactGroup, appendContactGroup: appendGroup, removeContactGroup: removeGroup,
  appendContact: appendContactItem, setGroupProperty: setProp, removeContact: onRemove,
  sortContact: onSort,
}) => (<Grid className={classes.container} container justify={'space-between'}>
  <Grid item><SectionTitle>Contact group:</SectionTitle></Grid>
  <Grid itemjustify={'center'}>
    <Button
      onClick={appendGroup}
      startIcon={<AddCircleOutlineIcon />}
    >Add contact group</Button>
  </Grid>
  {contactGroup.map(({ title, contacts, id }, index) =>
    (<Grid className={index > 0 ? classes.groupRoot : ''} item xs={12} key={id}>
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label={'Group Headline'}
            value={title}
            onChange={e => setProp({
              groupId: id,
              key: 'title',
              value: e.target.value,
            })}
          />
          <SortableRoot
            className={classes.contactRoot}
            container
            useDragHandle
            spacing={2}
            axis={'xy'}
            distance={1}
            onSortEnd={({ oldIndex, newIndex }) => onSort({ oldIndex, newIndex, groupId: id })}
          >
            {contacts.map((contact, i) => (<SortableItem
              index={i}
              cardIndex={i}
              key={contact.id}
              contact={contact}
              onRemove={() => onRemove({ contactId: contact.id, groupId: id })}
              onChange={props => setProp({ ...props, contactId: contact.id, groupId: id })}
            />))}
          </SortableRoot>
        </Grid>
      </Grid>
      <Grid className={classes.actionRoot} container justify={'space-between'}>
        {contactGroup.length > 1 && <ButtonWarning
          color={'warning'}
          onClick={() => {
            if (window.confirm('Please confirm you want to delete this group')) {
              removeGroup({ groupId: id });
            }
          }}
          startIcon={<DeleteIcon />}
        >Remove group</ButtonWarning>}
        <Button
          onClick={() => appendContactItem(id)}
          startIcon={<AddCircleOutlineIcon />}
        >Add item</Button>
      </Grid>
    </Grid>))}
</Grid>);


ContactGroup.defaultProps = {
  contactGroup: [],
};

ContactGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  contactGroup: PropTypes.array,
  appendContactGroup: PropTypes.func.isRequired,
  appendContact: PropTypes.func.isRequired,
  setGroupProperty: PropTypes.func.isRequired,
  removeContact: PropTypes.func.isRequired,
  removeContactGroup: PropTypes.func.isRequired,
  sortContact: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(({ contactUs: { contactGroup } }) => ({
  contactGroup,
}), {
  appendContactGroup,
  appendContact,
  setGroupProperty,
  removeContact,
  removeContactGroup,
  sortContact,
})(ContactGroup));
