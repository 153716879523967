import { setDefaults } from '@udecode/slate-plugins';
import renderElementPriceComparison from './renderElementPriceComparison';
import { DEFAULTS_PRICE_COMPARISON } from './defaults';

const PriceComparisonPlugin = (options) => {
  const { priceComparison } = setDefaults(options, DEFAULTS_PRICE_COMPARISON);
  return ({
    renderElement: renderElementPriceComparison(options),
    voidTypes: [priceComparison.type],
  });
};

export default PriceComparisonPlugin;
