import {
  AUTHENTICATE_USER,
} from 'constants/actionTypes';
import { USER_LOGIN_MFA, MFA_FETCH_QR, MFA_VERIFY } from 'constants/actionTypes/user';
import {
  FORGOTTEN_PASSWORD_REQUEST, LOGIN,
  LOGIN_RESET_CONTEXT,
  LOGIN_SET_CONTEXT, LOGIN_TRANSITION_COMPLETE,
  LOGOUT,
} from 'constants/actionTypes/login';

export const logout = () => ({ type: LOGOUT });
export const authenticateUser = value => ({ type: AUTHENTICATE_USER, value });
export const submitLogin = value => ({ type: LOGIN, value });
export const requestForgottenPassword = value => ({ type: FORGOTTEN_PASSWORD_REQUEST, value });
export const loginTransitionComplete = () => ({ type: LOGIN_TRANSITION_COMPLETE });
export const getMfa = () => ({ type: MFA_FETCH_QR });
export const verifyMfa = value => ({ type: MFA_VERIFY, value });
export const loginWithMfa = value => ({ type: USER_LOGIN_MFA, value });
export const setLoginContext = value => ({ type: LOGIN_SET_CONTEXT, value });
export const resetLoginContext = () => ({ type: LOGIN_RESET_CONTEXT });
