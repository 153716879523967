import { ofType } from 'redux-observable';
import {
  mapTo,
  switchMap,
  map,
  withLatestFrom,
  flatMap,
  mergeMap,
  filter, take,
} from 'rxjs/operators';

import { ajax } from 'rxjs/ajax';
import { of, from } from 'rxjs';
import moment from 'moment';

import compare from '../../utils/revisionDiff';

import {
  ARTICLE_PREVIEW_SUCCESS,
  ARTICLE_PREVIEW_REJECTED,
  ARTICLE_REVERT_REJECTED,
  CONTEXT_DISPOSE,
} from '../../constants/actionTypes';

import {
  REVISION_ON_SEARCH,
  REVISION_ON_SEARCH_SUCCESS,
  REVISION_ON_SEARCH_REJECTED,
  REVISION_ON_REQUEST_PREVIEW,
  REVISION_ON_SELECT,
  REVISION_COMPARE,
  REVISION_COMPARE_SUCCESS,
  REVISION_COMPARE_REJECTED,
  REVISION_OFFLINE_DELETE,
  REVISION_OFFLINE_DELETED,
  REVISION_OFFLINE,
  REVISION_ARTICLE,
  REVISION_OFFLINE_REJECTED,
  UPDATE_OFFLINE_REVISION,
  UPDATE_OFFLINE_REVISION_READY,
  UPDATE_OFFLINE_REVISION_SUCCESS, UPDATE_OFFLINE_REVISION_REJECTED,
} from '../../constants/revision';

import apiCatchError, {
  showErrorNotification, showSuccessNotification,
  showWarningNotification,
} from '../helper/notification';

import {
  ARTICLE_REVISION_OFFLINE_SAVED,
  ARTICLE_REVISION_REJECTED,
  ARTICLE_REVISION_REQUESTED,
  ARTICLE_REVISION_REVERT,
  ARTICLE_REVISION_SAVE,
  ARTICLE_REVISION_SAVED,
  ARTICLE_REVISION_SET_STATUS,
  ARTICLE_SET_ACTIVE_EDITED_REVISION,
  ARTICLE_REVISION_REVERT_SUCCESS, ARTICLE_FETCH_SUCCESS, ARTICLE_UNPUBLISH_SUCCESS,
  ARTICLE_SAVE_SUCCESS,
} from '../../constants/actionTypes/article';
import {
  EDITION_REVISION_PATCH_SUCCESS, EDITION_REVISION_REVERT,
  SAVE_ARTICLE_EDITION_REJECTED,
  SAVE_ARTICLE_EDITION_SUCCESS,
} from '../../constants/actionTypes/edition';
import { onSelectRevision } from '../../actions/revision';
import { sortAscending } from '../../components/helper/functional';
import ArticleEntity from '../../entities/ArticleEntity';
import { FIELD_IS_PUBLISHED, FIELD_NID, FIELD_PRODUCTS } from '../../constants/article/articleFields';
import { getState } from '../../utils/stateHelper';
import { DATASTATE_LOCAL_DISPOSE } from '../../constants/actionTypes/dataState';
import { API_FIELD_EXTRA_DATA } from '../../constants/common/commonFields';

export const preparePayloadInclusiveRevision = (action$, state$) => action$.pipe(
  ofType(ARTICLE_REVISION_REQUESTED, UPDATE_OFFLINE_REVISION),
  withLatestFrom(state$),
  filter(([, { dataState: { [FIELD_NID]: nid } }]) => !!nid),
  switchMap(([{ type }]) => {
    if (type === UPDATE_OFFLINE_REVISION) {
      return of({
        type: UPDATE_OFFLINE_REVISION_READY,
      });
    }
    return from([
      {
        type: ARTICLE_REVISION_SAVE,
      },
    ]);
  }),
);

export const deleteOfflineRevision = (action$, state$) => action$.pipe(
  ofType(REVISION_OFFLINE_DELETE),
  withLatestFrom(state$, (a, b) => b),
  filter(({ article: { editedRevisionId }, revision: { list: revisions } }) =>
    revisions.find(({ revision_id: id }) => id === editedRevisionId),
  ),
  map(({ article: { editedRevisionId }, revision: { list: revisions } }) =>
    revisions.find(({ revision_id: id }) => id === editedRevisionId),
  ),
  switchMap(offlineVersion => ajax.post(
    `/api/revisions/${REVISION_ARTICLE}/shift-offline`, {
      revision_id: offlineVersion.revision_id,
      time: offlineVersion.time,
      id: offlineVersion.entity_id,
    },
    { 'Content-Type': 'application/json' },
  ).pipe(
    mapTo({
      type: REVISION_OFFLINE_DELETED,
      value: {
        id: offlineVersion.entity_id,
        type: REVISION_ARTICLE,
      },
    }),
    apiCatchError(REVISION_OFFLINE_REJECTED),
  )),
);

export const updateOfflineRevision = (action$, state$) => action$.pipe(
  ofType(UPDATE_OFFLINE_REVISION_READY),
  withLatestFrom(state$),
  switchMap(([, {
    localState, serverState, frame: { selectedPublication },
    revision: {
      list: [offlineVersion],
    },
  }]) => {
    const articleTemp = new ArticleEntity();
    const dataState = getState(localState, serverState);
    const payload = articleTemp.getPayloadFromData(dataState, dataState, selectedPublication);
    // Hack for products @todo deprecate and remove in v1.6
    if (Array.isArray(dataState[FIELD_PRODUCTS]) && dataState[FIELD_PRODUCTS].length > 0) {
      try {
        const extras = JSON.parse(payload[API_FIELD_EXTRA_DATA][0].value);
        extras[FIELD_PRODUCTS] = dataState[FIELD_PRODUCTS];
        payload[API_FIELD_EXTRA_DATA] = [{
          value: JSON.stringify(extras),
        }];
      } catch (e) {
        console.log('Could not parse extras');
      }
    }
    return ajax.post(
      `/api/revisions/${REVISION_ARTICLE}/update`, {
        payload,
        revision_id: offlineVersion.revision_id,
        id: offlineVersion.entity_id,
        time: offlineVersion.time,
      },
      { 'Content-Type': 'application/json' },
    ).pipe(
      mergeMap(({ response }) => {
        const { revision_id: newRevisionId } = response;
        return from([{
          type: UPDATE_OFFLINE_REVISION_SUCCESS,
          value: {
            id: payload.nid[0].value,
            type: REVISION_ARTICLE,
          },
        }, {
          type: ARTICLE_SET_ACTIVE_EDITED_REVISION,
          value: newRevisionId,
        }]);
      }),
      apiCatchError(UPDATE_OFFLINE_REVISION_REJECTED),
    );
  }),
);

export const saveRevision = (action$, state$) => action$.pipe(
  ofType(ARTICLE_REVISION_SAVE),
  withLatestFrom(state$),
  switchMap(([, {
    localState, serverState, frame: { selectedPublication },
  }]) => {
    const articleTemp = new ArticleEntity();
    const dataState = getState(localState, serverState);
    const payload = articleTemp.getPayloadFromData(dataState, dataState, selectedPublication);
    // Hack for products @todo deprecate and remove in v1.6
    if (Array.isArray(dataState[FIELD_PRODUCTS]) && dataState[FIELD_PRODUCTS].length > 0) {
      try {
        const extras = JSON.parse(payload[API_FIELD_EXTRA_DATA][0].value);
        extras[FIELD_PRODUCTS] = dataState[FIELD_PRODUCTS];
        payload[API_FIELD_EXTRA_DATA] = [{
          value: JSON.stringify(extras),
        }];
      } catch (e) {
        console.log('Could not parse extras');
      }
    }
    return ajax.post(
      `/api/revisions/${REVISION_ARTICLE}/${payload.nid[0].value}/offline`, payload,
      { 'Content-Type': 'application/json' },
    ).pipe(
      mapTo({
        type: ARTICLE_REVISION_OFFLINE_SAVED,
        value: {
          id: payload.nid[0].value,
          type: REVISION_ARTICLE,
        },
      }),
      apiCatchError(ARTICLE_REVISION_REJECTED),
    );
  }),
);

export const previewArticle = action$ =>
  action$.pipe(
    ofType(REVISION_ON_REQUEST_PREVIEW),
    switchMap(({ value: payload }) => {
      const modifiedPayload = Object.keys(payload).reduce((acc, key) => {
        if (/^revision/.test(key) || /changed|created/.test(key)) {
          return acc;
        }

        if (/field_article_(publish|un_publish|display_date|updated)/.test(key)) {
          acc[key] = [{
            value: payload[key].length > 0 ? moment(payload[key][0].value).unix() : [],
          }];
          return acc;
        }
        acc[key] = payload[key];
        return acc;
      }, {});
      return ajax.post('/api/preview', modifiedPayload, { 'Content-Type': 'application/json' }).pipe(
        map(({ response }) => ({
          type: ARTICLE_PREVIEW_SUCCESS,
          value: response.preview_id,
        })),
        apiCatchError(ARTICLE_PREVIEW_REJECTED),
      );
    }),
    apiCatchError(ARTICLE_PREVIEW_REJECTED, 'Unable to show a preview with this article revision.')
  );

export const revertRevision = (action$, state$) =>
  action$.pipe(
    ofType(ARTICLE_REVISION_REVERT),
    withLatestFrom(state$),
    switchMap(([{ value: payload }, {
      frame: { socketId },
      dataState: { [FIELD_NID]: nid, [FIELD_IS_PUBLISHED]: isPublished },
    }]) => {
      const nextAction = (isPublished) ? ARTICLE_SAVE_SUCCESS : ARTICLE_UNPUBLISH_SUCCESS;
      const {
        changed, field_article_display_date: displayDate, ...fields
      } = payload;
      return ajax.patch(
        `/api/article/${nid}?socketId=${socketId}`,
        fields,
        { 'Content-Type': 'application/json' },
      ).pipe(
        flatMap(({ response }) => from([
          {
            type: ARTICLE_REVISION_REVERT_SUCCESS,
          },
          {
            type: nextAction,
            value: response,
          },
        ])),
        apiCatchError(ARTICLE_REVERT_REJECTED, 'Api Error. Unable to revert this article.')
      );
    }),
    apiCatchError(ARTICLE_REVERT_REJECTED, 'Api Error. Unable to revert this article.')
  );

export const revertEditionRevision = (action$, state$) =>
  action$.pipe(
    ofType(EDITION_REVISION_REVERT),
    withLatestFrom(state$),
    switchMap(([{ value: payload }, {
      edition: { articleEditionFromServer },
    }]) => {
      const { id: [{ value: editionId }] } = articleEditionFromServer;
      const { changed, ...restOfPayload } = payload;
      return ajax.patch(`/api/edition/${editionId}`, restOfPayload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(() => from([
          {
            type: SAVE_ARTICLE_EDITION_SUCCESS,
            value: editionId,
          },
          {
            type: EDITION_REVISION_PATCH_SUCCESS,
            value: editionId,
          },
        ])),
        apiCatchError(SAVE_ARTICLE_EDITION_REJECTED),
      );
    }),
    apiCatchError(ARTICLE_REVERT_REJECTED, 'Api Error. Unable to revert this article.')
  );

export const reloadRevisionListAfterSave = action$ => action$.pipe(
  ofType(ARTICLE_SAVE_SUCCESS),
  map(({ value: { nid: [{ value: id }] } }) => ({
    type: REVISION_ON_SEARCH,
    value: {
      id,
      type: REVISION_ARTICLE,
    },
  })),
);

export const deleteOfflineRevisionAfterPublish = (action$, state$) => action$.pipe(
  ofType(ARTICLE_SAVE_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  filter(({ article: { editedRevisionId }, revision: { list: revisions } }) =>
    revisions.find(({ revision_id: id, status }) =>
      id === editedRevisionId && status === REVISION_OFFLINE),
  ),
  mapTo({ type: REVISION_OFFLINE_DELETE }),
);

export const applyRevision = action$ => action$.pipe(
  ofType(REVISION_ON_SELECT),
  filter(({ value }) => typeof value !== 'undefined'),
  map(({ value }) => {
    const { changed, ...restOfPayload } = value.payload;
    return {
      ...value,
      payload: {
        ...restOfPayload,
      },
    };
  }),
  switchMap(({ payload, status, revision_id: revisionId }) => {
    const actionsWithPayload = articlePayload => [
      { type: DATASTATE_LOCAL_DISPOSE },
      {
        type: ARTICLE_FETCH_SUCCESS,
        value: articlePayload,
      },
      {
        type: ARTICLE_SET_ACTIVE_EDITED_REVISION,
        value: revisionId,
      },
      {
        type: ARTICLE_REVISION_SET_STATUS,
        value: status,
      },
    ];

    if (status === REVISION_OFFLINE) {
      const newPayload = { ...payload };
      // this is to ensure data is correct for the reload logic ca be removed for new offline.
      delete newPayload.localState;
      return ajax.post(
        '/api/revision/reload-offline',
        newPayload,
        { 'Content-Type': 'application/json' },
      ).pipe(
        mergeMap(({ response }) => from(actionsWithPayload({
          ...response,
          localState: payload.localState,
        }))),
        apiCatchError()
      );
    }
    return from(actionsWithPayload(payload));
  }),
);

export const fetchRevision = action$ => action$.pipe(
  ofType(
    REVISION_ON_SEARCH,
    ARTICLE_REVISION_SAVED,
    UPDATE_OFFLINE_REVISION_SUCCESS,
    ARTICLE_REVISION_OFFLINE_SAVED,
    REVISION_OFFLINE_DELETED,
  ),
  switchMap(({
    value: { id, type },
  }) => ajax.getJSON(`/api/revisions/${type}/${id}`).pipe(
    mergeMap((response) => {
      const actions = [{
        type: REVISION_ON_SEARCH_SUCCESS,
        value: response.map(item => ({
          ...item,
        })),
      }];
      return from(actions);
    }),
    apiCatchError(REVISION_ON_SEARCH_REJECTED),
  )),
  apiCatchError(REVISION_ON_SEARCH_REJECTED),
);

export const compareRevision = (action$, state$) => action$.pipe(
  ofType(REVISION_COMPARE),
  withLatestFrom(state$),
  mergeMap(([{ value: listToCompareTo }, { revision: { list } }]) => of(listToCompareTo).pipe(
    map(items => ({
      type: REVISION_COMPARE_SUCCESS,
      value: items.length > 1 ? compare(items.slice().sort(sortAscending).reverse(), list) : [],
    })),
    apiCatchError(REVISION_COMPARE_REJECTED)
  )),
);

export const goToLiveVersionAfterDelete = action$ => action$.pipe(
  ofType(REVISION_OFFLINE_DELETED),
  switchMap(() =>
    action$.pipe(
      ofType(REVISION_ON_SEARCH_SUCCESS),
      take(1),
      map(({ value: latestRevision }) => onSelectRevision(latestRevision[0])),
    ),
  ),
);

export const setOfflineVersionAfterSave = action$ => action$.pipe(
  ofType(ARTICLE_REVISION_OFFLINE_SAVED, UPDATE_OFFLINE_REVISION_SUCCESS),
  switchMap(() =>
    action$.pipe(
      ofType(REVISION_ON_SEARCH_SUCCESS),
      take(1),
      map(({ value: latestRevision }) => onSelectRevision(latestRevision[0])),
    ),
  ),
);

export const showOfflineUpdatedSuccess = action$ => action$.pipe(
  ofType(UPDATE_OFFLINE_REVISION_SUCCESS),
  mergeMap(showSuccessNotification('Offline revision updated.')),
);

export const resetSidebarContext = action$ => action$.pipe(
  ofType(UPDATE_OFFLINE_REVISION_SUCCESS),
  mapTo({ type: CONTEXT_DISPOSE }),
);

export const showOfflineSuccess = action$ => action$.pipe(
  ofType(ARTICLE_REVISION_OFFLINE_SAVED),
  mergeMap(showSuccessNotification('Offline version saved.')),
);

export const showError = action$ => action$.pipe(
  ofType(REVISION_COMPARE_REJECTED),
  mergeMap(showWarningNotification('Failed to compare this revision.')),
);
