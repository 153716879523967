import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Select from '../../../ui/Select';

import {
  AD_ALIGNMENT, AD_SOURCE,
} from '../../constants';

import { humanFriendlyLabel } from '../../../helper/utils';
import { camelCase } from '../../../../utils/helper';

const LABEL_ALIGNMENT = 'Ad Alignment';
const LABEL_SOURCE = 'Ad Source';

const items = [
  'Right',
  'Left',
];

const AdSettings = (props) => {
  const {
    component,
    disabledAlignmentOption,
    onChangeProperty,
    publicationConfig: { adSources },
  } = props;
  const adSourceKey = camelCase(AD_SOURCE);
  useEffect(() => {
    if (adSources && !component[AD_SOURCE]) {
      onChangeProperty(component.id, AD_SOURCE, adSources[0]);
    }
  }, [adSources, component, onChangeProperty]);
  return (
    <>
      {!disabledAlignmentOption && <Select
        label={LABEL_ALIGNMENT}
        value={component[AD_ALIGNMENT] || items[0]}
        items={items}
        onChange={(e) => {
          onChangeProperty(component.id, AD_ALIGNMENT, e.target.value);
        }}
      /> }
      {adSources && <Select
        label={LABEL_SOURCE}
        // inconsistency between component on layout vs sidebar
        // on layout the component property is PascalCase and api convert it to camelCase to pwamp
        // on sidebar there is no api involvement, json is passed thru to pwamp, hence cms trying to
        // convert it to camelCase (after requested by FE dev)
        value={component[AD_SOURCE] || component[adSourceKey] || adSources[0]}
        items={adSources.reduce((acc, next) => ({ ...acc, [next]: humanFriendlyLabel(next) }), {})}
        onChange={(e) => {
          onChangeProperty(component.id, AD_SOURCE, e.target.value);
        }}
      />}
    </>
  );
};

AdSettings.defaultProps = {
  disabledAlignmentOption: false,
};

AdSettings.propTypes = {
  component: PropTypes.object.isRequired,
  onChangeProperty: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object.isRequired,
  disabledAlignmentOption: PropTypes.bool,
};

export default connect(
  ({ frame: { selectedPublication: { publicationConfig } } }) => ({ publicationConfig }),
  { },
)(AdSettings);
