import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

import { CLIENT_STANDARD } from 'constants/env';
import {
  PAGE_TYPES,
  INTERACTIVE_TYPES,
  PAGE_CONFIG, PAGE_RESULTS,
} from 'constants/story/storyComponents';
import { FIELD_PAGES } from 'constants/story/storyFields';

import { ContextBarBody } from 'components/common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from 'components/common/context/ContextPanel';
import DraggableComponent from 'components/ui/draggables/DraggableComponent';
import { hasResults } from '../helper';

const getPageComponent = config => ({
  type: config.type,
});

const StoryPageSelector = () => {
  const { [FIELD_PAGES]: pages } = useSelector(state => state.story);
  const { client } = useSelector(state => state.frame);
  return (
    <ContextBarBody>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Templates</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails>
          {PAGE_TYPES.map(type => (
            <DraggableComponent key={type} component={getPageComponent(PAGE_CONFIG[type])}>
              <Typography variant={'body1'}>{PAGE_CONFIG[type].title}</Typography>
            </DraggableComponent>
          ))}
        </ContextPanelDetails>
        {client !== CLIENT_STANDARD && <>
          <ContextPanelSummary>
            <ContextPanelHeading>Interactive</ContextPanelHeading>
          </ContextPanelSummary>
          <ContextPanelDetails>
            {INTERACTIVE_TYPES.map(type => (
              <DraggableComponent
                disabled={type === PAGE_RESULTS && hasResults(pages)}
                key={type}
                component={getPageComponent(PAGE_CONFIG[type])}
              >
                <Typography variant={type === PAGE_RESULTS && hasResults(pages) ? 'body2' : 'body1'}>
                  {type === PAGE_RESULTS && hasResults(pages)
                    ? `${PAGE_CONFIG[type].title} (only 1 allowed)`
                    : PAGE_CONFIG[type].title
                  }
                </Typography>
              </DraggableComponent>
            ))}
          </ContextPanelDetails>
        </>}
      </ContextPanel>
    </ContextBarBody>
  );
};

export default StoryPageSelector;
