import { ELEMENT_BOOKING, PROP_HOTEL } from './defaults';
import { EMBED_BOOKING } from '../../../../../../constants/editor/dataTypes';

export const convertToBooking = data => ({
  type: ELEMENT_BOOKING,
  [PROP_HOTEL]: data[PROP_HOTEL] || {},
  url: data?.url || '',
  children: [{ text: '' }],
});

export const convertFromBooking = data => ({
  type: EMBED_BOOKING,
  data: {
    [PROP_HOTEL]: data[PROP_HOTEL] || {},
    url: data?.url || '',
  },
});
