import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../../../../ui/TextField';
import ArticleRef from './common/ArticleRef';

const FIELD_HEADLINE = 'headline';
const FIELD_HEADLINE_PREFIX = 'headlinePrefix';
const FIELD_ARTICLE_REF = 'articleRef';

const Strapline = (props) => {
  const {
    component: { headline, headlinePrefix, articleRef },
    setProperty, delta,
  } = props;
  return (
    <>
      <TextField
        label={'Prefix'}
        value={headlinePrefix || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE_PREFIX,
          value: event.target.value,
        })}
      />
      <TextField
        label={'Headline'}
        value={headline || ''}
        onChange={event => setProperty({
          delta,
          prop: FIELD_HEADLINE,
          value: event.target.value,
        })}
      />
      <ArticleRef
        id={(articleRef) ? articleRef.id : null}
        onChange={value => setProperty({
          delta,
          prop: FIELD_ARTICLE_REF,
          value: value ? {
            title: value.data.title,
            id: value.data.id,
            bundle: value.type,
          } : null,
        })}
      />
    </>
  );
};

Strapline.propTypes = {
  component: PropTypes.object.isRequired,
  setProperty: PropTypes.func.isRequired,
  delta: PropTypes.number.isRequired,
};

export default Strapline;
