import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FindReplace } from 'mdi-material-ui';

import ToolbarInput from '../../../../toolbar/common/ToolbarInput';
import ToolbarTextButton from '../../../../toolbar/common/ToolbarTextButton';
import Separator from '../../../../toolbar/Separator';

const ToolbarReplace = ({ disabled, onReplace, onReplaceAll, ...props }) => {
  const [replace, setReplace] = useState('');
  return (
    <>
      <ToolbarInput
        value={replace}
        placeholder={'Replace with'}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <FindReplace />
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        onChange={e => setReplace(e.target.value)}
        {...props}
      />
      <Separator small />
      <ToolbarTextButton
        disabled={disabled || !replace}
        onClick={() => onReplace(replace)}
      >Replace</ToolbarTextButton>
      <Separator small />
      <ToolbarTextButton
        disabled={disabled || !replace}
        onClick={() => onReplaceAll(replace)}
      >Replace All</ToolbarTextButton>
      <Separator small />
    </>
  );
}

ToolbarReplace.propTypes = {
  disabled: PropTypes.bool,
  onReplace: PropTypes.func.isRequired,
  onReplaceAll: PropTypes.func.isRequired,
};

ToolbarReplace.defaultProps = {
  disabled: false,
};

export default ToolbarReplace;
