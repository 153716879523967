import { setDefaults } from '@udecode/slate-plugins';
import renderElementRelatedArticles from './renderElementRelatedArticles';
import { DEFAULTS_RELATED_ARTICLES } from './defaults';

const RelatedArticlesPlugin = (options) => {
  const { relatedArticles } = setDefaults(options, DEFAULTS_RELATED_ARTICLES);
  return ({
    renderElement: renderElementRelatedArticles(options),
    voidTypes: [relatedArticles.type],
  });
};

export default RelatedArticlesPlugin;
