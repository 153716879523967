import React from 'react';
import PropTypes from 'prop-types';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import OptaIcon from 'assets/icons/OptaIcon';
import TextField from 'components/ui/TextField';
import { PROP_EMBED_AMP, PROP_EMBED } from '../defaults';
import EmbedWrapper from '../../../../common/EmbedWrapper';

const styles = () => ({
  input: {
    fontFamily: 'Consolas,Monaco,Lucida Console,Liberation Mono,Courier New',
    fontSize: 12,
  },
});

const OptaElement = (props) => {
  const {
    classes, attributes, children, element, className,
  } = props;
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title="Opta" Icon={OptaIcon} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Embed Code"
              InputProps={{
                className: classes.input,
              }}
              multiline
              placeholder={'Insert embed code here'}
              rows={5}
              ignoreSmartQuote
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_EMBED]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_EMBED] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Amp Embed Code"
              InputProps={{
                className: classes.input,
              }}
              multiline
              placeholder={'Insert amp embed code here'}
              rows={5}
              ignoreSmartQuote
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_EMBED_AMP]: e.target.value }, { at: path });
              }}
              defaultValue={element[PROP_EMBED_AMP] || ''}
            />
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

OptaElement.propTypes = {
  classes: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default withStyles(styles)(OptaElement);
