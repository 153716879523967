import React from 'react';
import PropTypes from 'prop-types';

import ToggleSwitch from '../../../../ui/ToggleSwitch';

const ShowLeadField = (props) => {
  const { value, onChange } = props;
  return (
    <ToggleSwitch
      value={value}
      label={'Standfirst'}
      onChange={onChange}
    />
  );
};

ShowLeadField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

ShowLeadField.defaultProps = {
  value: false,
};

export default ShowLeadField;
