import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import StoryListTable from 'components/story/storyList/StoryListTable';
import Button from 'components/ui/buttons/Button';
import TextSearchFilter from 'components/ui/table/filters/TextSearchFilter';

import Dialog from './common/Dialog';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(0, -2),
  },
});

const VisualStorySelectorDialog = ({
  classes,
  handleClose,
  onSelect,
  ...rest
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selected, setSelected] = useState([]);
  return (
    <Dialog
      title={'Select Visual Story'}
      maxWidth={'md'}
      handleClose={handleClose}
      margin
      actions={<>
        <Button
          variant={'contained'}
          onClick={() => onSelect(Object.entries(selected)
            .map(([id, title]) => ({ id, title })))}
        >Insert visual stories</Button>
      </>}
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextSearchFilter
            placeholder={'Search by Title / Body'}
            onSubmit={textQuery => setSearchQuery(textQuery)}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <StoryListTable
              textQuery={searchQuery}
              selectOnly
              onSelect={setSelected}
              status="1"
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

VisualStorySelectorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default withStyles(styles)(VisualStorySelectorDialog);
