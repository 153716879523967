import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';


import ContextBar from '../../common/context/ContextBar';
import {
  ContextPanel,
  ContextPanelDetails, ContextPanelHeading, ContextPanelSummary,
} from '../../common/context/ContextPanel';
import { resetGalleryContext } from '../../../actions/media';
import FieldLockTextfield from '../../fieldLock/form/FieldLockTextfield';
import IconButton from '../../ui/buttons/IconButton';
import {
  FIELD_CAPTION,
  FIELD_TITLE,
  FIELD_COPYRIGHT,
  FIELD_ALT,
  FIELD_DESCRIPTION,
  FIELD_URL,
  FIELD_CREDIT
} from '../../../constants/media/imageFields';
import { FIELD_IMAGES } from '../../../constants/media/galleryFields';
import ImagePreview from '../../ui/media/ImagePreview';
import { optimiseImage } from '../../helper/media';
import {CLIENT_JPI} from "../../../constants/env";

const ImageEdit = ({
  dataState,
  context,
  client,
  resetGalleryContext: resetContext,
}) => {
  const image = context?.data?.mid ? dataState?.[FIELD_IMAGES]?.[context.data.mid] : null;
  return (
    <ContextBar>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading appendButton>Edit Image <IconButton
            onClick={() => resetContext()}
          ><CloseIcon /></IconButton></ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {context?.data?.mid && <Grid container spacing={2}>
            <Grid item xs={12}>
              {image?.[FIELD_URL] && <ImagePreview url={optimiseImage(image[FIELD_URL], { width: 500 })} />}
            </Grid>
            <Grid item xs={12}>
              <FieldLockTextfield field={[FIELD_IMAGES, context.data.mid, FIELD_TITLE]} label={'Title'} hideChanges />
            </Grid>
            <Grid item xs={12}>
              <FieldLockTextfield field={[FIELD_IMAGES, context.data.mid, FIELD_ALT]} label={'Alt'} hideChanges />
            </Grid>
            <Grid item xs={12}>
              <FieldLockTextfield
                field={[FIELD_IMAGES, context.data.mid, FIELD_CAPTION]}
                label={'Caption'}
                hideChanges
              />
            </Grid>
            <Grid item xs={12}>
              <FieldLockTextfield
                field={[FIELD_IMAGES, context.data.mid, FIELD_COPYRIGHT]}
                label={'Copyright'}
                hideChanges
              />
            </Grid>
            {client === CLIENT_JPI && <Grid item xs={12}>
              <FieldLockTextfield
                field={[FIELD_IMAGES, context.data.mid, FIELD_CREDIT]}
                label={'Credit'}
                hideChanges
              />
            </Grid>}
            <Grid item xs={12}>
              <FieldLockTextfield
                field={[FIELD_IMAGES, context.data.mid, FIELD_DESCRIPTION]}
                label={'Description'}
                multiline
                hideChanges
              />
            </Grid>
          </Grid>}
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBar>
  );
}

ImageEdit.propTypes = {
  client: PropTypes.string.isRequired,
  dataState: PropTypes.object.isRequired,
  resetGalleryContext: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

export default connect(
  ({
    frame: { client },
    dataState,
    gallery: { context },
  }) => ({ context, dataState, client }),
  { resetGalleryContext },
)(ImageEdit);
