import React, { useReducer } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '../../ui/TextField';
import Checkbox from '../../ui/Checkbox';
import TextButton from '../../ui/buttons/TextButton';
import Select from '../../ui/Select';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
  },
});

const initialState = {
  id: null,
  name: '',
  bundle: 'daily',
  createOnPublish: false,
  createAtTime: '',
  version: '',
  changesOnly: false,
  changesSinceTime: '',
};

function reducer(state, action) {
  return {
    ...state,
    [action[0]]: action[1],
  };
}

const TimeForm = ({ classes, onSubmit, data }) => {
  const [state, dispatch] = useReducer(reducer, data || initialState);
  return (<Grid container direction={'column'}>
    <Grid item className={classes.root}>
      <form onSubmit={(e) => {
        onSubmit(state);
        e.preventDefault();
        // reset state
        Object.keys(initialState).forEach(key => dispatch([key, initialState[key]]));
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              value={state.name}
              label={'Name'}
              required
              onChange={e => dispatch(['name', e.target.value])}
            />
            <TextField
              value={state.createAtTime}
              label={'Create At Time'}
              required
              type="time"
              onChange={e => dispatch(['createAtTime', e.target.value])}
            />
            <FormControl fullWidth>
              <Checkbox
                label={'Create on publish'}
                checked={state.createOnPublish}
                onChange={e => dispatch(['createOnPublish', e.target.checked])}
              />
              <FormHelperText>If checked this edition will be created
                when the edition is published</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <Select
                value={state.bundle}
                items={['daily', 'special']}
                label={'Edition type'}
                onChange={e => dispatch(['bundle', e.target.value])}
              />
            </FormControl>
            <TextField
              value={state.version}
              label={'Edition Version'}
              required
              onChange={e => dispatch(['version', e.target.value])}
            />
            <Grid container>
              <Grid item xs={4}>
                <Checkbox
                  label={'Changes only'}
                  checked={state.changesOnly}
                  onChange={e => {
                    dispatch(['changesOnly', e.target.checked]);
                    if (!e.target.checked) {
                      dispatch(['changesSinceTime', '']);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                { state.changesOnly && <TextField
                  value={state.changesSinceTime}
                  label={'Changes since'}
                  type="time"
                  onChange={e => dispatch(['changesSinceTime', e.target.value])}
                /> }
              </Grid>
            </Grid>
            <FormHelperText>If set the edition version will contain only the updated files since
              the previous version of this edition</FormHelperText>
          </Grid>
        </Grid>
        <Grid container justify={'flex-end'}>
          <TextButton type={'submit'}>Submit</TextButton>
        </Grid>
      </form>
    </Grid>
  </Grid>);
};

TimeForm.defaultProps = {
  data: null,
};

TimeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default withStyles(styles)(TimeForm);
