import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Dialog from './common/Dialog';
import FlatTermForm from '../taxonomy/TaxonomyForm';

const style = () => ({});

const FlatTermAddNewDialog = (props) => {
  const { classes, vocab, onSave, ...rest } = props;
  return (<Dialog title={'Add new'} {...rest}>
    <FlatTermForm addNew onSave={onSave} vocab={vocab} term={{ name: '' }} />
  </Dialog>);
};

FlatTermAddNewDialog.defaultProps = {
};

FlatTermAddNewDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  vocab: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withStyles(style)(FlatTermAddNewDialog);
