import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';

import {
  mapTo,
  switchMap,
  map,
  withLatestFrom, mergeMap,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import apiCatchError, { showSuccessNotification } from '../helper/notification';

import {
  MENU_LIST_LOADED,
  MENU_TREE_LOADED,
  MENU_TREE_ONREMOVE, MENU_TREE_ONCHANGE,
  MENU_SAVE,
  MENU_SAVE_SUCCESS, MENU_FETCH_LIST, MENU_TREE_FETCH,
} from '../../constants/actionTypes';

import generateKey from '../../utils/generateKey';

const removeMenuById = (list, needle) => list.reduce((acc, next) => {
  if (next.id === needle) {
    return acc;
  }

  if (next.children) {
    acc.push({
      ...next,
      children: removeMenuById(next.children, needle),
    });
  } else {
    acc.push(next);
  }

  return acc;
}, []);

const getMenuList = (action$, state$) => action$.pipe(
  ofType(MENU_FETCH_LIST),
  withLatestFrom(state$),
  switchMap(([{ value: requestedMenuId }, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/menus/${domain}`).pipe(
      mergeMap((value) => {
        const actions = [{
          type: MENU_LIST_LOADED,
          value,
        }];
        if (requestedMenuId) {
          actions.push({
            type: MENU_TREE_FETCH,
            value: requestedMenuId,
          });
        }
        return from(actions);
      }),
      apiCatchError(),
    )),
);

const normaliseMenuItem = menu => menu.map(({ title, children, path }) => {
  const item = { title, id: generateKey(), path };
  if (children && children.length > 0) {
    item.children = normaliseMenuItem(children);
    item.expanded = false;
  }
  return item;
});

export const getMenuTree = (action$, state$) => action$.pipe(
  ofType(MENU_TREE_FETCH),
  withLatestFrom(state$),
  switchMap(([{ value: requestedMenuId }, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/menus/${domain}/${requestedMenuId}`).pipe(
      mergeMap(value => of({
        type: MENU_TREE_LOADED,
        value: {
          ...value,
          menu: value.menu ? normaliseMenuItem(value.menu) : [],
        },
      })),
      apiCatchError(),
    ),
  ),
);

export const menuOnRemove = (action$, state$) => action$.pipe(
  ofType(MENU_TREE_ONREMOVE),
  withLatestFrom(state$),
  map(([{ value }, { menu: { currentMenuTree } }]) => ({
    type: MENU_TREE_ONCHANGE,
    value: removeMenuById(currentMenuTree.menu, value.node.id),
  })),
);

export const menuOnSave = (action$, state$) => action$.pipe(
  ofType(MENU_SAVE),
  withLatestFrom(state$),
  switchMap(([, { menu: { currentMenuTree } }]) => ajax.patch(`/api/menu/${currentMenuTree.id}`, {
    field_menu_json: [
      { value: JSON.stringify(currentMenuTree.menu) },
    ],
    bundle: [
      {
        target_id: currentMenuTree.bundle,
        target_type: 'flowz_menu_type',
      },
    ],
  }, { 'Content-Type': 'application/json' }).pipe(
    mapTo({
      type: MENU_SAVE_SUCCESS,
    }),
    apiCatchError(),
  )),
);

export const redirectOnSaveSuccess = action$ => action$.pipe(
  ofType(MENU_SAVE_SUCCESS),
  mapTo(push('/menu')),
);

export const menuSavedNotification = action$ => action$.pipe(
  ofType(MENU_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Menu is successfully saved.')),
);

export default getMenuList;
