import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hasFeatures } from '../components/helper/utils';

/**
 * Access by feature term
 */

const AccessFeature = ({ children, restrictedTo, features }) => {
  if (!hasFeatures(features)(restrictedTo)) {
    return null;
  }
  return (<React.Fragment>
    {children}
  </React.Fragment>);
};

AccessFeature.defaultProps = {
  features: [],
};

AccessFeature.propTypes = {
  features: PropTypes.array,
  children: PropTypes.node.isRequired,
  restrictedTo: PropTypes.string.isRequired,
};

export default connect(({
  frame: { selectedPublication: { features } },
}) => ({ features }), {})(AccessFeature);
