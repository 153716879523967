import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'rxjs';

import { withStyles } from '@material-ui/core/styles';

import { CONTEXT_COMPONENT } from '../../../constants/contexts';

import ComponentUI from './common/ComponentUI';
import ComponentTitle from './common/ComponentTitle';
import ComponentContent from './common/ComponentContent';


const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& .component-ui': {
      display: 'none',
    },
    '&:hover .component-ui': {
      display: 'flex',
    },
  },
  content: {
    position: 'relative',
  },
  highlight: {
    position: 'relative',
    background: theme.palette.background.paleBlue,
  },
  title: {
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerlocked: {
    pointerEvents: 'none',
  },
  metaForm: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    boxSizing: 'border-box',
  },
});

const Component = (props) => {
  const {
    classes, component, position, isHighlighted,
    context, lockData,
    removeComponent,
    setPreviewFocus, setPreviewHighlight,
  } = props;
  const classesTitle = [classes.title];
  if (context.type === CONTEXT_COMPONENT && context.data?.id === component.id) {
    classesTitle.push(classes.active);
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      role="form"
      className={classes.root}
      data-type={'layout-component'}
      onClick={() => setPreviewFocus(position)}
      onMouseEnter={() => setPreviewHighlight(position)}
      onMouseLeave={() => setPreviewHighlight(-1)}
    >
      <ComponentContent className={(isHighlighted) ? classes.highlight : classes.content}>
        <div className={lockData ? `${classes.header} ${classes.headerlocked}` : classes.header}>
          <ComponentTitle component={component} className={classesTitle.join(' ')} position={position} />
          <ComponentUI
            disabled={!!lockData}
            component={component}
            position={position}
            removeComponent={removeComponent}
          />
        </div>
      </ComponentContent>
    </div>
  );
};

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  context: PropTypes.object.isRequired,
  removeComponent: PropTypes.func.isRequired,
  setPreviewFocus: PropTypes.func,
  setPreviewHighlight: PropTypes.func,
  lockData: PropTypes.object,
};

Component.defaultProps = {
  setPreviewFocus: noop,
  setPreviewHighlight: noop,
  publicationConfig: null,
  lockData: null,
};

export default withStyles(styles)(connect(
  ({
    sidebar: { context },
    frame: { selectedPublication: { publicationConfig } },
  }) => ({ context, publicationConfig }),
  {
  },
)(Component));
