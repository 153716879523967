import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import Dialog from 'components/dialog/common/Dialog';
import AutoCompleteGeneric from 'components/autocomplete/AutoComplete';
import { searchArticle } from 'actions/article';
import Button from 'components/ui/buttons/Button';
import TextField from 'components/ui/TextField';
import ToggleSwitch from 'components/ui/ToggleSwitch';

const LinkDialog = (props) => {
  const {
    articles,
    onSelect,
    searchArticle: onSearch,
    allowArticle,
    title,
    path,
    nofollow,
    ...rest
  } = props;
  const [titleVal, setTitle] = useState(title || '');
  const [pathVal, setPath] = useState(path || '');
  const [nofollowVal, setNofollow] = useState(nofollow);
  const [valid, setValid] = useState(true);
  const urlRegex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/);
  const mailtoRegex = new RegExp(/^mailto:[^@\s]+@[^@\s]+/);
  const isEdit = !!path;

  return (<Dialog title={isEdit ? 'Edit link' : 'Insert link'} maxWidth="md" {...rest}>
    <Grid container spacing={2}>
      {allowArticle && <Grid item xs={12}>
        <AutoCompleteGeneric
          placeholder="Search for an article"
          items={articles}
          onSelect={({ label, url }) => {
            setTitle(label);
            setPath(url);
            setValid(true);
          }}
          onChange={onSearch}
        />
      </Grid>}
      <Grid item xs={12}>
        <TextField
          id="path"
          label="URL"
          value={pathVal}
          error={pathVal && !valid}
          onChange={(e) => {
            setValid(urlRegex.test(e.target.value) || mailtoRegex.test(e.target.value));
            setPath(e.target.value);
          }}
          margin="none"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="title"
          label="Title"
          value={titleVal}
          onChange={e => setTitle(e.target.value)}
          margin="none"
        />
      </Grid>
      <Grid item xs={3}>
        <ToggleSwitch
          id="nofollow"
          label="Nofollow"
          value={nofollowVal}
          onChange={e => setNofollow(e.target.checked)}
        />
      </Grid>
      <Grid item xs={12} container justify="flex-end">
        <Button
          disabled={!valid || !pathVal}
          onClick={() => {
            onSelect({ title: titleVal, href: pathVal, nofollow: nofollowVal });
          }}
          startIcon={<AddIcon />}
        >{isEdit ? 'Update Link' : 'Add link'}</Button>
      </Grid>
    </Grid>
  </Dialog>);
};

LinkDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  searchArticle: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  allowArticle: PropTypes.bool,
  articles: PropTypes.array,
  title: PropTypes.string,
  path: PropTypes.string,
  nofollow: PropTypes.bool,
};

LinkDialog.defaultProps = {
  allowArticle: true,
  articles: [],
  title: null,
  path: null,
  nofollow: false,
};

export default connect(
  ({ autocomplete: { articles } }) => ({ articles }),
  { searchArticle },
)(LinkDialog);
