import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import EditOutlined from '@material-ui/icons/EditOutlined';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import { Table, TableBody, TableCell, TableHeadCell, TableHead, TableRow } from '../components/ui/table/Table';
import IconButton from '../components/ui/buttons/IconButton';
import { fetchMenus } from '../actions/menu';

export const MenuListBreadcrumb = [
  {
    title: 'Menu Manager',
    slug: 'menu',
  },
];

const MenuList = ({
  menus, push: onRouteChange, fetchMenus: onFetchMenus,
}) => {
  useEffect(() => {
    onFetchMenus();
  }, []);
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={MenuListBreadcrumb} />}
    >
      <PageContent>
        <PageSection>
          <Table>
            <TableHead>
              <TableRow hasBorder>
                <TableHeadCell>Menus</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {menus.map(({ id, name, bundle }) => (<TableRow hasActions key={id}>
                <TableCell>{name}</TableCell>
                <TableCell align={'right'}>
                  <IconButton
                    color={'primary'}
                    onClick={() => onRouteChange(`/menu/${bundle}/${id}`)}
                  >
                    <EditOutlined />
                  </IconButton>
                </TableCell>
              </TableRow>))}
            </TableBody>
          </Table>
        </PageSection>
      </PageContent>
    </Page>
  );
};

MenuList.propTypes = {
  menus: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  fetchMenus: PropTypes.func.isRequired,
};

MenuList.defaultProps = {
  selectedComponent: {},
  menus: [],
};

export default connect(
  ({ menu: { menus } }) => ({ menus }), { push, fetchMenus },
)(MenuList);
