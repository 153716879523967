import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

import {
  fetchLiveblogPosts,
  disposeLiveblog,
  startWatchSchedulePost,
  disposeWatchSchedulePost,
  liveblogPostSortPinned,
} from '../../../../actions/liveblog';

import LiveblogItem from '../../../liveblog/LiveblogPost';

import { Tabs, Tab } from '../../../ui/Tabs';
import TabPanel from '../../../ui/TabPanel';
import { isPinned } from '../../../../utils/helper';
import { SortableItem, SortableRoot, sortConfig } from '../../../ui/SortableComponents';

const sizePerPage = 10;

const LiveblogBuilderPosts = ({
  posts,
  postTotal,
  futurePosts,
  embedded,
  containerId,
  fetchLiveblogPosts: fetchPosts,
  disposeLiveblog: disposePosts,
  startWatchSchedulePost: startWatch,
  disposeWatchSchedulePost: disposeWatch,
  liveblogPostSortPinned: onPinnedSort,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (containerId) {
      fetchPosts([containerId, {
        posts: sizePerPage,
        offset: page * sizePerPage,
      }]);
    } else {
      disposePosts();
    }
  }, [containerId, page, sizePerPage]);

  useEffect(() => {
    if (futurePosts.length > 0) {
      startWatch();
    }
    return () => {
      disposeWatch();
    };
  }, [futurePosts]);

  return (
    <>
      <Tabs value={activeTab} size={'large'} onChange={(e, current) => setActiveTab(current)}>
        <Tab value={0} label={'Live posts'} />
        <Tab value={1} label={`Scheduled (${futurePosts.length})`} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        {posts.length === 0 && <Typography variant={'subtitle1'}>There are currently no posts</Typography>}
        <SortableRoot
          item
          useDragHandle
          onSortEnd={({ oldIndex, newIndex }) => onPinnedSort([oldIndex, newIndex])}
          container
          spacing={2}
          direction={'column'}
          {...sortConfig}
        >
          {posts
            .filter(({ data: { scheduleTime } }) => scheduleTime < moment().unix())
            .map((post, index) => {
              const postComponent = (<LiveblogItem
                embedded={embedded}
                key={post.data.id}
                post={post}
              />);
              if (isPinned(post.data.extra)) {
                return (<SortableItem
                  index={index}
                  key={post.data.id}
                >{postComponent}</SortableItem>);
              }
              return postComponent;
            })
          }
        </SortableRoot>
        {postTotal > sizePerPage && <TablePagination
          rowsPerPageOptions={[sizePerPage]}
          component="nav"
          count={postTotal}
          rowsPerPage={sizePerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next',
          }}
          onChangePage={(e, i) => setPage(i)}
        /> }
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {futurePosts
          .sort((a, b) => a?.data?.scheduleTime - b?.data?.scheduleTime)
          .map(post =>
            (<LiveblogItem
              key={post.data.id}
              post={post}
            />))}
      </TabPanel>
    </>
  );
};

LiveblogBuilderPosts.defaultProps = {
  posts: [],
  postTotal: 0,
  embedded: false,
};

LiveblogBuilderPosts.propTypes = {
  containerId: PropTypes.number.isRequired,
  fetchLiveblogPosts: PropTypes.func.isRequired,
  disposeLiveblog: PropTypes.func.isRequired,
  futurePosts: PropTypes.array.isRequired,
  posts: PropTypes.array,
  postTotal: PropTypes.number,
  startWatchSchedulePost: PropTypes.func.isRequired,
  disposeWatchSchedulePost: PropTypes.func.isRequired,
  liveblogPostSortPinned: PropTypes.func.isRequired,
  embedded: PropTypes.bool,
};

export default connect(
  ({ liveblog: { posts, postTotal, futurePosts } }) => ({ posts, postTotal, futurePosts }),
  {
    disposeLiveblog,
    fetchLiveblogPosts,
    disposeWatchSchedulePost,
    startWatchSchedulePost,
    liveblogPostSortPinned,
  },
)(LiveblogBuilderPosts);
