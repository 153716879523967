import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtonsStatic } from '../components/common/page/PageToolbar';

import { fetchLiveblogList, fetchLiveblogArchivedList, disposeLiveblogList } from '../actions/liveblog';
import Button from '../components/ui/buttons/Button';
import ButtonSelect from '../components/ui/buttons/ButtonSelect';
import ListTable from '../components/table/ListTable';
import TableHeader from '../components/table/TableHeader';
import { TableBody, TableCell, TableRow } from '../components/ui/table/Table';
import { CMS_DATE_FORMAT } from '../constants/common';
import IconButton from '../components/ui/buttons/IconButton';

export const LiveblogLandingBreadcrumb = [
  {
    title: 'Liveblog manager',
    slug: 'liveblog',
  },
];

const dateLabel = (lastPostEdit, created) => {
  if (lastPostEdit) {
    return `${moment.unix(Number(lastPostEdit)).format(CMS_DATE_FORMAT)}`;
  }
  return `${moment.unix(Number(created)).format(CMS_DATE_FORMAT)}`;
};

const LiveblogList = (props) => {
  const {
    containers,
    fetchLiveblogArchivedList,
    push: routeChange,
    fetchLiveblogList, containerTotal,
    archivedContainers, archivedContainerTotal,
    disposeLiveblogList: onDispose,
    match: { params: { status } },
  } = props;
  const statuses = [
    {
      link: '/liveblog',
      title: 'Active',
    },
    {
      link: '/liveblog/archived',
      title: 'Archived',
    },
  ];
  const [liveblogs, setLiveblogs] = useState(status === 'archived' ? archivedContainers : containers);

  useEffect(() => {
    return () => {
      onDispose();
    };
  }, []);
  useEffect(() => {
    setLiveblogs(status === 'archived' ? archivedContainers : containers);
  }, [archivedContainers, containers, status]);

  const headers = ['Title', 'Author', 'Created/Last Post', ''];

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={LiveblogLandingBreadcrumb}>
          <Grid container justify={'center'} alignItems={'center'}>
            <Typography variant={'body2'}>Manage</Typography>
            <ButtonSelect active={status === 'archived' ? 'Archived' : 'Active'} items={statuses} />
          </Grid>
          <PageToolbarButtonsStatic>
            <Button
              onClick={() => routeChange('/liveblog/build')}
              variant={'contained'}
              startIcon={<AddIcon />}
            >New Liveblog</Button>
          </PageToolbarButtonsStatic>
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection>
          <ListTable
            getFetchData={(page, rowsPerPage) => ({
              limit: rowsPerPage,
              status: status === 'archived' ? 0 : 1,
              offset: page * rowsPerPage,
            })}
            isFetching={false}
            fetchList={
              status === 'archived'
                ? fetchLiveblogArchivedList
                : fetchLiveblogList
            }
            total={status === 'archived' ? archivedContainerTotal : containerTotal}
            queryData={[status]}
          >
            <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
            <TableBody>
              {liveblogs.length === 0 && <TableRow>
                <TableCell colspan={headers.length} align={'center'} variant={'body2'} >No liveblogs found</TableCell>
              </TableRow>}
              {liveblogs.length > 0 && liveblogs
                .map(({ data: { name, lastPostEdit, created, id, user: { name: createdBy } } }) => (
                  <TableRow key={id}>
                    <TableCell>{name}</TableCell>
                    <TableCell>{createdBy}</TableCell>
                    <TableCell>{dateLabel(lastPostEdit, created)}</TableCell>
                    <TableCell>
                      <Grid container wrap={'nowrap'} justify={'flex-end'}>
                        <IconButton
                          onClick={() => routeChange(`/liveblog/edit/2/${id}`)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </ListTable>
        </PageSection>
      </PageContent>
    </Page>
  );
};

LiveblogList.propTypes = {
  match: PropTypes.object.isRequired,
  containers: PropTypes.array,
  archivedContainers: PropTypes.array,
  fetchLiveblogList: PropTypes.func.isRequired,
  fetchLiveblogArchivedList: PropTypes.func.isRequired,
  disposeLiveblogList: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  containerTotal: PropTypes.number,
  archivedContainerTotal: PropTypes.number,
};

LiveblogList.defaultProps = {
  containers: [],
  archivedContainers: [],
  containerTotal: 0,
  archivedContainerTotal: 0,
};

export default connect(({ router: { location: { pathname } }, liveblog: {
  containers, containerTotal, archivedContainers, archivedContainerTotal } }) =>
  ({ containers, pathname, containerTotal, archivedContainers, archivedContainerTotal }),
{
  fetchLiveblogList, fetchLiveblogArchivedList, push, disposeLiveblogList,
})(LiveblogList);
