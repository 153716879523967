import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'rambdax';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 0,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
};

const ArticleSearch = ({ classes, placeholder, onSubmit }) => {
  let $input;
  return (<div className={classes.root}>
    <Input
      className={classes.input}
      inputRef={input => ($input = input)}
      placeholder={placeholder}
      inputProps={{ 'aria-label': 'Search article' }}
      onKeyUp={debounce(() => onSubmit($input.value), 1000)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            className={classes.iconButton}
            aria-label="Search"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  </div>);
};

ArticleSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

ArticleSearch.defaultProps = {
  placeholder: 'Search article',
};

export default withStyles(styles)(ArticleSearch);

