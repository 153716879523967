import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockContainer from '../FieldLockContainer';
import { setLocalProp } from 'actions/dataState';
import { createMediaEntity } from 'actions/media';
import {FIELD_PIXELS_AI_ID, FIELD_PIXELS_AI_PLAYLIST} from 'constants/article/articleFields';
import { PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT, VIDEO_TO_HERO_VIDEO } from 'constants/media/media';
import LocalCaption from 'components/ui/LocalCaption';
import VideoSelector from 'components/ui/media/VideoSelector';
import { PLAYINC_FIELD_PLAYLIST } from 'components/integration/playInc/PlayIncVideoSelectorList';
import { makeGetFieldLockValue, makeGetFieldValue } from 'selectors/fieldLock/fieldLock';

const FieldLockVideo = ({
  label,
  field,
  required,
  hasCaption,
  hasCopyright,
  createEntity,
  validTypes,
  children,
  video,
  lockValue,
  setLocalProp: setProp,
  createMediaEntity: createMedia,
  ...rest
}) => {
  const prop = Array.isArray(field) ? field.shift() : field;
  const propChain = Array.isArray(field) ? field : [];
  return (
    <FieldLockContainer field={field} lockValue={lockValue}>
      <VideoSelector
        label={label}
        video={video?.data || null}
        required={required}
        validTypes={validTypes}
        onSelected={({ provider, video: data, extra: extradata }) => {
          if (hasCaption && data.caption) {
            data[PROP_LOCAL_CAPTION] = `<p>${data.caption}</p>`;
          }
          if (hasCopyright && data.copyright) {
            data[PROP_LOCAL_COPYRIGHT] = data.copyright;
          }
          if (createEntity) {
            createMedia(provider, data, ({ mid, field_extra_media_info }) => {
              let extra = {};
              if (field_extra_media_info?.[0]?.value) {
                extra = JSON.parse(field_extra_media_info[0].value);
              }
              // @todo pixels ai should be handled outside of this component
              // handle playinc integration, data pass through
              if (extradata?.providerKey) {
                extra.providerKey = extradata.providerKey;
              }
              if (extradata?.socialHeadline) {
                extra.socialHeadline = extradata.socialHeadline;
              }
              if (extradata?.description) {
                extra.description = extradata.description;
              }
              setProp(
                prop,
                {
                  type: VIDEO_TO_HERO_VIDEO[provider],
                  data: {
                    videoType: provider,
                    mid: mid?.[0]?.value || null,
                    ...data,
                    ...extra,
                  },
                },
                propChain,
              );
              // @todo pixels ai should be handled outside of this component
              if (extradata?.[PLAYINC_FIELD_PLAYLIST]) {
                setProp(FIELD_PIXELS_AI_PLAYLIST, extradata[PLAYINC_FIELD_PLAYLIST]);
              }
              if (extradata?.id) {
                setProp(FIELD_PIXELS_AI_ID, extradata.id);
              }
            });
          } else {
            setProp(
              prop,
              {
                type: VIDEO_TO_HERO_VIDEO[provider],
                data: {
                  videoType: provider,
                  ...data,
                },
              },
              propChain,
            );
          }
        }}
        onRemove={() => setProp(
          prop,
          {},
          propChain,
        )}
        {...rest}
      />
      {hasCaption && video?.data &&
        <LocalCaption
          data={video.data}
          hideLocalCopyright={!hasCopyright}
          onChange={([localProp, value]) => setProp(
            localProp,
            value,
            [...propChain, prop, 'data'],
          )}
        />
      }
      {children && children}
    </FieldLockContainer>
  );
};

FieldLockVideo.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  createMediaEntity: PropTypes.func.isRequired,
  required: PropTypes.bool,
  hasCaption: PropTypes.bool,
  hasCopyright: PropTypes.bool,
  createEntity: PropTypes.bool,
  children: PropTypes.node,
  validTypes: PropTypes.array,
  label: PropTypes.string,
  video: PropTypes.object,
  lockValue: PropTypes.object,
};

FieldLockVideo.defaultProps = {
  label: 'Add video',
  required: false,
  hasCaption: false,
  hasCopyright: false,
  createEntity: false,
  children: null,
  validTypes: [],
  video: null,
  lockValue: null,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  return {
    video: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp, createMediaEntity },
)(FieldLockVideo);
