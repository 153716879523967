import { ofType } from 'redux-observable';
import {
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  BOOKING_FETCH_AMENITIES,
  BOOKING_FETCH_AMENITIES_SUCCESS,
  BOOKING_FETCH_AMENITIES_FAILURE,
} from '../../constants/actionTypes/integration/booking';

import apiCatchError from '../helper/notification';
import { queryStringify } from '../../utils/urlHelper';

const AMENITIES_PATH = '/json/hotels';

export const fetchHotelAmenities = (action$, state$) => action$.pipe(
  ofType(BOOKING_FETCH_AMENITIES),
  withLatestFrom(state$),
  switchMap(([
    { value }, { frame: { selectedPublication: { publicationConfig: { bookingConfig } } } },
  ]) => {
    const { apiEndpoint, username, password } = bookingConfig;
    const params = {
      hotel_ids: value,
      extras: 'hotel_facilities',
    };
    return ajax.get(`${apiEndpoint}${AMENITIES_PATH}?${queryStringify(params)}`, {
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    }).pipe(
      map(({ response }) => {
        const hotel = response.result && response.result[0];
        const amenities = (typeof hotel !== 'undefined') ?
          hotel.hotel_data.hotel_facilities : [];
        return {
          type: BOOKING_FETCH_AMENITIES_SUCCESS,
          value: {
            hotelId: value,
            amenities: amenities.map(amenity => amenity.name),
          },
        };
      }),
      apiCatchError(BOOKING_FETCH_AMENITIES_FAILURE),
    );
  }),
);
