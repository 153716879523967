import { PURGE } from 'redux-persist';
import { arrayMove } from 'react-sortable-hoc';
import generateKey from 'utils/generateKey';

import { DISPLAY_NAME_KEY } from 'constants/liveblog';
import {
  PAGE_ENTER_ARTICLE_CREATE,
  PAGE_ENTER_ARTICLE_EDIT,
  PAGE_ENTER_LIVEBLOG_BUILD,
} from 'constants/actionTypes/route';
import { LISTBULLETED, TWITTER } from 'constants/builder/builder';
import { ARTICLE_EDIT_DISPOSE } from 'constants/actionTypes/article';
import { initComp } from '../helper/editor';
import {
  LIVEBLOG_APPEND_TWITTER_TO_BODY,
  LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS, LIVEBLOG_CANCEL_LIVEBLOG_POST, LIVEBLOG_DISPOSE,
  LIVEBLOG_EDIT_POST,
  LIVEBLOG_EDIT_POST_SUCCESS,
  LIVEBLOG_FETCH_CONTAINER_SUCCESS,
  LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS,
  LIVEBLOG_FETCH_LIST_SUCCESS,
  LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS,
  LIVEBLOG_ON_SEARCH_SUCCESS,
  LIVEBLOG_POST_PINNED_SORT, LIVEBLOG_SAVE_POST,
  LIVEBLOG_SAVE_POST_FAILURE, LIVEBLOG_SAVE_POST_SUCCESS,
  LIVEBLOG_SET_PROPERTY,
} from 'constants/actionTypes/liveblog';

export const initCompListItem = {
  type: LISTBULLETED, data: { markup: '' }, id: generateKey(),
};

const defaultState = {
  body: [initComp],
  postTitle: '',
  displayName: '',
  keyPoints: [initCompListItem],
  containers: [],
  archivedContainers: [],
  posts: [],
  name: '',
  status: 0,
  currentEditedPostId: null,
  currentContainerFromServer: null,
  containerTotal: 0,
  archivedContainerTotal: 0,
  postTotal: 0,
  searchResults: { containers: [], paging: {} },
  selectedLiveblogForArticleComponent: null,
  futurePosts: [],
  scheduleDateTime: 0,
  isBusy: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LIVEBLOG_APPEND_TWITTER_TO_BODY: {
      return {
        ...state,
        body: [...state.body, {
          type: TWITTER,
          data: {
            url: action.value,
          },
        }],
      };
    }

    case LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS:
      return { ...state, futurePosts: action.value };

    case LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS:
      return { ...state, selectedLiveblogForArticleComponent: action.value };

    case LIVEBLOG_ON_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.value,
      };

    case LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS:
      return { ...state,
        archivedContainers: action.value.containers,
        archivedContainerTotal: action.value.paging.total,
      };

    case LIVEBLOG_FETCH_LIST_SUCCESS: {
      return {
        ...state, containers: action.value.containers, containerTotal: action.value.paging.total,
      };
    }

    case LIVEBLOG_SET_PROPERTY: {
      const [type, val] = action.value;
      const prop = {};
      prop[type] = val;
      return { ...state, ...prop };
    }

    case LIVEBLOG_FETCH_CONTAINER_SUCCESS: {
      const container = action.value.data;
      let keyPointFromServer = action.value.data.keyPoints
        && action.value.data.keyPoints.length > 0 ?
        JSON.parse(action.value.data.keyPoints) : defaultState.keyPoints;

      if (!Array.isArray(keyPointFromServer)) {
        keyPointFromServer = [keyPointFromServer];
      }

      return { ...state,
        currentContainerFromServer: action.value,
        name: container.name,
        status: container.status,
        posts: action.value.posts,
        keyPoints: keyPointFromServer,
        postTotal: action.value.paging.total,
      };
    }

    case LIVEBLOG_POST_PINNED_SORT: {
      const [oldIndex, newIndex] = action.value;
      return {
        ...state,
        posts: arrayMove(state.posts, oldIndex, newIndex),
      };
    }

    case LIVEBLOG_EDIT_POST_SUCCESS: {
      return { ...state,
        posts: state.posts.map((post) => {
          if (post.id === action.value.id) {
            return action.value;
          }
          return post;
        }),
        body: [{ ...defaultState.body, id: generateKey() }],
        currentEditedPostId: defaultState.currentEditedPostId,
        postTitle: defaultState.postTitle };
    }

    case LIVEBLOG_EDIT_POST: {
      const currentPost = [
        ...state.posts,
        ...state.futurePosts,
      ].find(({ data: { id } }) => action.value === id);

      return {
        ...state,
        currentEditedPostId: currentPost.data.id,
        body: currentPost.postJson,
        postTitle: currentPost.data.title,
        scheduleDateTime: currentPost.data.scheduleTime,
        [DISPLAY_NAME_KEY]: currentPost.data.extra[DISPLAY_NAME_KEY],
      };
    }

    case LIVEBLOG_SAVE_POST:
      return {
        ...state,
        isBusy: true,
      };

    case LIVEBLOG_CANCEL_LIVEBLOG_POST:
    case LIVEBLOG_SAVE_POST_SUCCESS:
      return { ...state,
        currentEditedPostId: defaultState.currentEditedPostId,
        body: [{ ...initComp, id: generateKey() }],
        postTitle: defaultState.postTitle,
        scheduleDateTime: defaultState.scheduleDateTime,
        isBusy: false,
      };

    case LIVEBLOG_SAVE_POST_FAILURE:
      return {
        ...state,
        isBusy: false,
      };

    case LIVEBLOG_DISPOSE:
    case ARTICLE_EDIT_DISPOSE:
    case PAGE_ENTER_LIVEBLOG_BUILD:
    case PAGE_ENTER_ARTICLE_EDIT:
    case PAGE_ENTER_ARTICLE_CREATE:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
