import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { updateStoryPageProp } from '../../../../actions/story';
import TextField from '../../../ui/TextField';
import MarkupField from '../../../ui/MarkupField';

const TITLE_LABEL = 'Title';
const BODY_LABEL = 'Body';
const ATTACHMENT_LABEL = 'Attachment';
const ATTACHMENT_PLACEHOLDER = 'Insert url of attached page';
const ATTACHMENT_CTA_LABEL = 'Attachment CTA';
const ATTACHMENT_CTA_PLACEHOLDER = 'Insert text for Attachment CTA';

const TemplateText = (props) => {
  const { id, title, body, attachment, attachmentCTA, updateStoryPageProp: updateProp } = props;
  return (
    <Grid container>
      <TextField
        label={TITLE_LABEL}
        value={title}
        onChange={e => updateProp(id, 'title', e.target.value)}
      />
      <MarkupField
        label={BODY_LABEL}
        data={body}
        onChange={markup => updateProp(id, 'body', markup)}
        multiline
        toolbar={['bold', 'italic', 'underline', 'link']}
      />
      <TextField
        label={ATTACHMENT_LABEL}
        placeholder={ATTACHMENT_PLACEHOLDER}
        value={attachment}
        onChange={e => updateProp(id, 'attachment', e.target.value)}
      />
      <TextField
        label={ATTACHMENT_CTA_LABEL}
        placeholder={ATTACHMENT_CTA_PLACEHOLDER}
        value={attachmentCTA}
        onChange={e => updateProp(id, 'attachmentCTA', e.target.value)}
      />
    </Grid>
  );
};

TemplateText.propTypes = {
  updateStoryPageProp: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  attachment: PropTypes.string,
  attachmentCTA: PropTypes.string,
};

TemplateText.defaultProps = {
  title: '',
  body: '',
  attachment: '',
  attachmentCTA: '',
};

export default connect(
  () => ({ }),
  { updateStoryPageProp },
)(TemplateText);
