import React from 'react';

import ArticleListTranslation from '../components/article/articleList/ArticleListTranslation';

import Page from '../components/common/page/Page';
import PageToolbar from '../components/common/page/PageToolbar';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';

export const ArticleTranslateLandingBreadcrumb = [
  {
    title: 'Article translation source',
    slug: 'translate',
  },
];

const ArticleTranslateLanding = () => (
  <Page toolbarContent={<PageToolbar breadcrumb={ArticleTranslateLandingBreadcrumb} />}>
    <PageContent>
      <PageSection>
        <ArticleListTranslation />
      </PageSection>
    </PageContent>
  </Page>
);


export default ArticleTranslateLanding;
