import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  CONTEXT_ARTICLE_SELECTOR,
  CONTEXT_ARTICLE,
} from '../../constants/contexts';

import { setFeedContext } from '../../actions/feed';

import ArticleSelector from '../contexts/ArticleSelector';
import Error from '../contexts/Error';
import ArticleOverrides from './contexts/ArticlesOverrides';

import ContextBar, { ContextBarHeader } from '../common/context/ContextBar';
import { Tab, Tabs } from '../ui/Tabs';

const CONTEXTS = [CONTEXT_ARTICLE_SELECTOR, CONTEXT_ARTICLE];

const getComponentFromContext = (context) => {
  const { type } = context;
  switch (type) {
    case CONTEXT_ARTICLE_SELECTOR:
      return ArticleSelector;
    case CONTEXT_ARTICLE:
      return ArticleOverrides;
    default:
      return Error;
  }
};

getComponentFromContext.defaultProps = {
  data: {},
};

const ArticleFeedContext = (props) => {
  const { id, feed, onUpdateProp, setFeedContext: setContext } = props;
  const { context } = feed;
  const ContextComponent = getComponentFromContext(context);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  return (<ContextBar>
    <ContextBarHeader>
      <Tabs
        value={CONTEXTS.indexOf(context.type)}
        onChange={(e, val) => {
          setContext(CONTEXTS[val]);
        }}
        indicatorColor={'primary'}
        textColor={'primary'}
        variant={'fullWidth'}
      >
        <Tab label={'Articles'} />
        <Tab label={'Overrides'} />
      </Tabs>
    </ContextBarHeader>
    <ContextComponent
      {...context}
      feedId={id}
      feed={feed[id]}
      onUpdateProp={onUpdateProp}
      page={page}
      query={query}
      setQuery={setQuery}
      setPage={setPage}
    />
  </ContextBar>);
};

ArticleFeedContext.propTypes = {
  id: PropTypes.string.isRequired,
  onUpdateProp: PropTypes.func.isRequired,
  feed: PropTypes.object.isRequired,
  setFeedContext: PropTypes.func.isRequired,
};

export default connect(
  ({ feed }) => ({ feed }),
  { setFeedContext },
)(ArticleFeedContext);
