import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setLocalProp } from '../../../actions/dataState';
import { fetchWorkflowGeneric } from '../../../actions/vocab';

import { makeGetFieldLockValue, makeGetFieldValue } from '../../../selectors/fieldLock/fieldLock';

import FieldLockContainer from '../../fieldLock/FieldLockContainer';
import Select from '../../ui/Select';

const FieldLockWorkflow = (props) => {
  const {
    label,
    field,
    required,
    workflow,
    workflowStates,
    lockValue,
    setLocalProp: setProp,
    fetchWorkflowGeneric: fetchWorkflow,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  useEffect(() => {
    if (!Array.isArray(workflowStates)) {
      setOptions([]);
      fetchWorkflow();
    } else {
      setOptions(workflowStates.reduce((acc, next) => ({ ...acc, [next.id]: next.label }), {}));
    }
  }, [workflowStates]);
  return (
    <FieldLockContainer field={field} lockValue={lockValue}>
      <Select
        value={workflow || workflowStates?.[0]?.id || null}
        onChange={e => setProp(prop, e.target.value, propChain)}
        label={label}
        items={options}
        {...rest}
      />
    </FieldLockContainer>
  );
};

FieldLockWorkflow.propTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  fetchWorkflowGeneric: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  workflowStates: PropTypes.array,
  workflow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  lockValue: PropTypes.object,
};

FieldLockWorkflow.defaultProps = {
  label: 'Workflow',
  required: false,
  workflowStates: [],
  workflow: null,
  lockValue: null,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  return {
    workflow: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    workflowStates: state?.vocab?.workflowGeneric,
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp, fetchWorkflowGeneric },
)(FieldLockWorkflow);
