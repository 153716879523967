export const CONTENT_TYPE_LAYOUT = 'layout';

// Fields
export const FIELD_PINNED = 'pinnedArticles';
export const FIELD_OVERRIDES = 'overrides';

// overrides
export const OVERRIDE_UPDATED = 'overrideUpdated';
export const OVERRIDE_SHOW_RELATED_LINKS = 'showRelatedLinks';
export const OVERRIDE_RELATED_LINKS = 'relatedLinks';
export const OVERRIDE_RELATED_LINKS_PINNED = 'relatedLinksPinned'; // not a field but used by the dnd system
export const OVERRIDE_SHOW_LEAD = 'showLead';
export const OVERRIDE_CAPSULE_SECTION = 'capsuleSection';
export const OVERRIDE_CAPSULE_HIGHLIGHT = 'capsuleHighlight';
export const OVERRIDE_LEAD = 'lead';
export const OVERRIDE_TITLE = 'titleShort';
export const OVERRIDE_HERO = 'hero';

export const LAYOUT_PATH_PATTERN = /\/layout\/([a-z_]+)\/[a-z0-9]+/;
