import React from 'react';

import ContextBar from '../common/context/ContextBar';
import ScheduledPosts from './sidebar/ScheduledPosts';

const LiveblogSidebar = () => (
  <ContextBar>
    <ScheduledPosts />
  </ContextBar>
);

export default LiveblogSidebar;
