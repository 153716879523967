import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import { capitalize } from '@material-ui/core';
import { LinkVariant } from 'mdi-material-ui';

import { setLocalProp } from '../../../actions/dataState';
import { sanitiseTerm } from '../../../utils/sanitiser';

import {
  CHAIN, CONTENT_SOURCE, EVENTS, PEOPLE, TAGS, TEAMS, TOPICS,
  VOCAB_SINGULAR,
} from '../../../constants/vocab';

import FieldLockVocabSelector from '../../fieldLock/form/FieldLockVocabSelector';

import {
  FIELD_CHAIN, FIELD_CONTENT_SOURCE, FIELD_EVENTS, FIELD_PEOPLE, FIELD_TAGS,
  FIELD_TEAMS, FIELD_TOPICS,
} from '../../../constants/article/articleFields';

import { PagePanel, PagePanelContent } from '../../common/page/PagePanel';
import { autoLinkVocab } from '../../../actions/article';
import Button from '../../ui/buttons/Button';

const TaxonomyPanelSecondary = ({
  topics, tags, chain, events, people, teams, sources,
  selectedPublication,
  autoLinkVocab: autoLink,
  setLocalProp: setProp,
}) => {
  const taxonomyList = [
    [FIELD_TOPICS, TOPICS, topics, true, false],
    [FIELD_TAGS, TAGS, tags, true, false],
    [FIELD_CHAIN, CHAIN, chain, false, false],
    [FIELD_EVENTS, EVENTS, events, false, false],
    [FIELD_PEOPLE, PEOPLE, people, false, false],
    [FIELD_TEAMS, TEAMS, teams, false, false],
    [FIELD_CONTENT_SOURCE, CONTENT_SOURCE, sources, true, true],
  ];
  const { vocabs, publicationConfig: { autoLinkVocab: whiteList } } = selectedPublication;
  return (
    <PagePanel>
      <PagePanelContent noHeading>
        <Grid container spacing={2}>
          {taxonomyList
            .filter(([, vocab]) => vocabs.includes(vocab))
            .map(([fieldName, vocab, selectedTerms, required, hideSuggestions]) => (
              <Grid item xs={12} key={vocab}>
                <Grid container spacing={2} wrap={'nowrap'} alignItems={'center'}>
                  <Grid item xs={12}>
                    <FieldLockVocabSelector
                      key={vocab}
                      required={required}
                      field={fieldName}
                      vocab={vocab}
                      selectedTerms={selectedTerms}
                      label={capitalize(VOCAB_SINGULAR[vocab])}
                      onSelect={([, value]) =>
                        setProp(fieldName, [...selectedTerms, sanitiseTerm(value)])}
                      onRemove={([, value]) =>
                        setProp(fieldName, selectedTerms.filter(({ id }) => id !== value.id))}
                      hideSuggestions={hideSuggestions}
                    />
                  </Grid>
                  {whiteList?.includes(vocab) && <Grid item>
                    <Button
                      onClick={() => autoLink(vocab)}
                      startIcon={<LinkVariant />}
                    >Auto link</Button>
                  </Grid>}
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </PagePanelContent>
    </PagePanel>
  );
};

TaxonomyPanelSecondary.propTypes = {
  selectedPublication: PropTypes.object.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  autoLinkVocab: PropTypes.func.isRequired,
  topics: PropTypes.array,
  tags: PropTypes.array,
  chain: PropTypes.array,
  events: PropTypes.array,
  people: PropTypes.array,
  teams: PropTypes.array,
  sources: PropTypes.array,
};

TaxonomyPanelSecondary.defaultProps = {
  topics: [],
  tags: [],
  chain: [],
  events: [],
  people: [],
  teams: [],
  sources: [],
};

export default connect(
  ({
    dataState: {
      [FIELD_TOPICS]: topics,
      [FIELD_TAGS]: tags,
      [FIELD_CHAIN]: chain,
      [FIELD_EVENTS]: events,
      [FIELD_PEOPLE]: people,
      [FIELD_TEAMS]: teams,
      [FIELD_CONTENT_SOURCE]: sources,
    },
    frame: { selectedPublication },
  }) =>
    ({
      topics,
      tags,
      chain,
      events,
      people,
      teams,
      sources,
      selectedPublication,
    }),
  { setLocalProp, autoLinkVocab },
)(TaxonomyPanelSecondary);

