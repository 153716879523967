import GalleryElement from './components/GalleryElement';

export const ELEMENT_GALLERY = 'gallery';
export const PROP_GALLERY = 'gallery';
export const PROP_ALIGN = 'align';

export const DEFAULTS_GALLERY = {
  gallery: {
    component: GalleryElement,
    type: ELEMENT_GALLERY,
    rootProps: {
      className: 'slate-gallery',
    },
  },
};
