import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';

export default withStyles(theme => ({
  root: {
    margin: theme.spacing(0, 0, 2),
  },
}))(Grid);

export const VocabSelectorRoot = withStyles(theme => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}))(FormControl);

