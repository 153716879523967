import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  DatePicker, MuiPickersUtilsProvider, TimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const style = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: '370px',
  },
  dateWrapper: {
    display: 'flex',
    width: `calc(50% - ${theme.spacing(0.5)}px)`,
  },
  wrapperLabel: {
    position: 'absolute',
  },
  fullWidth: {
    flexGrow: 1,
  },
  fullPicker: {
    marginTop: theme.spacing(1),
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 19px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  },
  datePicker: {
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
    },
    '& .MuiOutlinedInput-input': {
      paddingRight: theme.spacing(0.5),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRightWidth: 0,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 19px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
      },
    },
  },
  timePicker: {
    marginTop: theme.spacing(1),
    width: '140%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 4px 4px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderLeftWidth: 0,
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: theme.spacing(0.5),
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
  icon: {
    width: theme.spacing(2),
  },
});

const DatePickerFilter = ({
  classes, disablePast, disableTime, fromDate, toDate, onChangeFromDate, onChangeToDate,
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <div className={`${classes.root} datepicker-wrapper`}>
      <div className={classes.dateWrapper}>
        <DatePicker
          className={!disableTime ? classes.datePicker : classes.fullPicker}
          disabled={disablePast}
          disableFuture
          margin={'normal'}
          inputVariant={'outlined'}
          id={'mui-pickers-to-date'}
          label={'From'}
          value={fromDate}
          format={'DD-MM-YY'}
          onChange={onChangeFromDate}
        />
        {!disableTime && <TimePicker
          className={classes.timePicker}
          disabled={disablePast}
          margin={'normal'}
          inputVariant={'outlined'}
          id={'mui-pickers-from-time'}
          value={fromDate}
          onChange={onChangeFromDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <CalendarTodayOutlinedIcon className={classes.icon} color={'primary'} />
              </InputAdornment>
            ),
          }}
        />}
      </div>
      <div className={classes.dateWrapper}>
        <DatePicker
          className={!disableTime ? classes.datePicker : classes.fullPicker}
          disablePast={disablePast}
          margin={'normal'}
          inputVariant={'outlined'}
          id={'mui-pickers-from-date'}
          label={'To'}
          format={'DD-MM-YY'}
          value={toDate}
          onChange={onChangeToDate}
        />
        {!disableTime && <TimePicker
          className={classes.timePicker}
          margin={'normal'}
          inputVariant={'outlined'}
          id={'mui-pickers-to-time'}
          value={toDate}
          onChange={onChangeToDate}
          InputProps={{
            endAdornment: (
              <InputAdornment position={'end'}>
                <CalendarTodayOutlinedIcon className={classes.icon} color={'primary'} />
              </InputAdornment>
            ),
          }}
        />}
      </div>
    </div>
  </MuiPickersUtilsProvider>
);

DatePickerFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  disablePast: PropTypes.bool,
  disableTime: PropTypes.bool,
  onChangeFromDate: PropTypes.func.isRequired,
  onChangeToDate: PropTypes.func.isRequired,
};

DatePickerFilter.defaultProps = {
  fromDate: null,
  toDate: null,
  disablePast: false,
  disableTime: false,
};

export default withStyles(style)(DatePickerFilter);
