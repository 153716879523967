import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';

import VocabSelectorDialog from '../../dialog/VocabSelectorDialog';
import IconButton from '../buttons/IconButton';
import RequiredIndicator from '../RequiredIndicator';

export const VARIANT_DEFAULT = 'default';
export const VARIANT_HEADING = 'heading';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  title: {
    fontSize: 14,
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  btnPadding: {
    padding: theme.spacing(2),
  },
  changes: {
    color: theme.palette.secondary.main,
  },
  [VARIANT_DEFAULT]: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  [VARIANT_HEADING]: {},
});

const ButtonVariant = ({ label, variant, onClick, classes, hasChanges, disabled, required }) => {
  const labelClasses = [classes.title];
  if (hasChanges) {
    labelClasses.push(classes.changes);
  }
  switch (variant) {
    case VARIANT_HEADING:
      labelClasses.push(classes.btnPadding);
      return disabled ? (
        <Typography component={'h2'} color={'primary'} className={labelClasses.join(' ')}>{label}{ required && <RequiredIndicator />}</Typography>
      ) : (
        <Button
          onClick={onClick}
          color={'primary'}
          endIcon={<AddIcon />}
        ><span className={hasChanges ? classes.changes : ''}>{label}{ required && <RequiredIndicator />}</span></Button>
      );
    default:
      return (
        <>
          <Typography component={'h2'} className={labelClasses.join(' ')}>{label}{ required && <RequiredIndicator />}</Typography>
          {!disabled && <IconButton onClick={onClick}><AddIcon /></IconButton>}
        </>
      );
  }
};

ButtonVariant.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
};

const VocabSelectorButton = (props) => {
  const {
    classes, label, vocab, restrict, variant, onChange, hasChanges, selectedTerms, limit,
    required,
    ...rest
  } = props;
  const [open, openDialog] = useState(false);
  const restrictVocab = restrict ? Array.isArray(restrict) ? restrict : [vocab] : [];
  return (
    <div className={`${classes.root} ${classes[variant]}`}>
      <ButtonVariant
        onClick={() => openDialog(true)}
        variant={variant}
        label={label}
        classes={classes}
        required={required}
        hasChanges={hasChanges}
        disabled={limit > -1 && selectedTerms.length >= limit}
      />
      {open && <VocabSelectorDialog
        handleClose={() => openDialog(false)}
        open={open}
        activeVocab={vocab}
        restrictVocab={restrictVocab}
        selectVocab={(val) => {
          onChange(val);
          openDialog(false);
        }}
        selectedTerms={selectedTerms}
        {...rest}
      />}
    </div>
  );
};

VocabSelectorButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  vocab: PropTypes.string.isRequired,
  required: PropTypes.bool,
  restrict: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array,
  ]),
  onChange: PropTypes.func.isRequired,
  selectedTerms: PropTypes.array,
  variant: PropTypes.string,
  limit: PropTypes.number,
  hasChanges: PropTypes.bool,
};

VocabSelectorButton.defaultProps = {
  variant: VARIANT_DEFAULT,
  hasChanges: false,
  restrict: false,
  limit: -1,
  required: false,
  selectedTerms: [],
};

export default withStyles(styles)(VocabSelectorButton);
