import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import Button from '../ui/buttons/Button';
import { VALID_VIDEO_EXTENSION } from '../../constants/media/media';
import { fetchDailyMotionChannels, uploadDailyMotionVideo } from '../../actions/integration/dailymotion';
import TextField from '../ui/TextField';
import ToggleSwitch from '../ui/ToggleSwitch';
import Select from '../ui/Select';

const DailyMotionUpload = ({
  onSelect,
}) => {
  const { isUploading } = useSelector(state => state.video);
  const { dailyMotionChannels: channels = [] } = useSelector(state => state.media );
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [channel, setChannel] = useState('');
  const [tags, setTags] = useState('');
  const [forKids, setForKids] = useState(false);
  const dispatch = useDispatch();
  const handleUpload = (data, callback) => dispatch(uploadDailyMotionVideo(data, callback));
  useEffect(() => {
    if (channels.length < 1) {
      dispatch(fetchDailyMotionChannels());
    }
  }, [channels, dispatch]);
  return (
    <>
      <Grid item xs={12}>
        <input
          type={'file'}
          accept={VALID_VIDEO_EXTENSION.join(',')}
          onChange={e => setFile(e.target.files ? Array.from(e.target.files) : null)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={title || ''}
          onChange={e => setTitle(e.target.value)}
          label="Title"
          margin="none"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <Select
          value={channel || ''}
          onChange={e => setChannel(e.target.value)}
          emptyValue={'- Select Channel -'}
          items={channels}
          label="Channel"
          margin="none"
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={tags || ''}
          onChange={e => setTags(e.target.value)}
          label="Tags"
          margin="none"
        />
      </Grid>
      <Grid item xs={3}>
        <ToggleSwitch
          label="Is created for kids"
          value={forKids}
          onChange={e => setForKids(e.target.checked)}
        />
      </Grid>
      <Grid item container justify="flex-end">
        <Button
          disabled={!file || !title || !channel || isUploading}
          onClick={() => handleUpload({ file, title, channel, tags, forKids }, media => onSelect(media))}
          variant="contained"
        >{isUploading ? 'Uploading...' : 'Upload'}</Button>
      </Grid>
    </>
  );
};

DailyMotionUpload.propTypes = {
  onSelect: PropTypes.func.isRequired,
  uploadDailyMotionVideo: PropTypes.func.isRequired,
  fetchDailyMotionChannels: PropTypes.func.isRequired,
  channels: PropTypes.array.isRequired,
};

export default DailyMotionUpload;
