import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'rxjs';

import withStyles from '@material-ui/core/styles/withStyles';
import EditOutlined from '@material-ui/icons/EditOutlined';
import SaveOutlined from '@material-ui/icons/SaveOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

import { Table, TableBody, TableRow, TableCell, TablePagination } from '../../ui/table/Table';
import IconButton from '../../ui/buttons/IconButton';

import TableHeader from '../TableHeader';
import TableToolbar from './VocabTableToolbar';
import { fetchVocab } from '../../../actions/vocab';
import { VOCAB_LAYOUTS, VOCAB_LAYOUTS_API } from '../../../constants/vocab';
import AccessToggle from '../../../access/AccessToggle';
import PageSection from '../../common/page/PageSection';

const styles = theme => ({
  row: {
    cursor: 'pointer',
    '&:hover .actions': {
      visibility: 'visible',
    },
  },
  selected: {
    background: theme.palette.background.grey1,
  },
  showActions: {
    '& .actions': {
      visibility: 'visible',
    },
  },
  actions: {
    display: 'flex',
    visibility: 'hidden',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
});

const TableGrid = props => (<Grid item xs={12} {...props} />);

// @todo refactor removing filters to another object, filters added to redux
// @todo refactor to a more generic table, split for vocab select to remove layout
// @todo refactor action logic to make more generic
function VocabTable(props) {
  const {
    classes, headers, data, activeVocab, pageStyle,
    onClickRow, onSelect,
    checked, selectedTermId,
    order, orderBy, actions,
    layout, vocab, showActions,
    fetchFlatVocab: onFetchVocab,
  } = props;

  const pageOptions = [20, 50, 100];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageOptions[0]);
  const [query, setQuery] = useState('');
  const [layouts, setLayouts] = useState([]);
  const { items = [] } = vocab[activeVocab];

  useEffect(() => {
    const term = items.find(({ id }) => id === selectedTermId);
    if (term) {
      onClickRow(term);
    }
  }, [items]);

  useEffect(() => {
    onFetchVocab({
      vocab: activeVocab,
      q: query,
      limit: rowsPerPage,
      offset: page * rowsPerPage,
    });
  }, [activeVocab, page, rowsPerPage, query]);

  useEffect(() => {
    const layoutKey = VOCAB_LAYOUTS[activeVocab];
    if (layoutKey) {
      setLayouts(layout[layoutKey] || []);
    }
  }, [activeVocab, layout]);

  let tableCells = headers;
  if (actions.length > 0) {
    tableCells = [...tableCells, { id: 'action', label: '' }];
  }
  if (onSelect !== null) {
    tableCells = [{ id: 'select', label: '' }, ...tableCells];
  }

  const preProcessData = row => ({
    ...row,
    layout: layouts.find(({ data: layoutData }) => (
      VOCAB_LAYOUTS_API[activeVocab] &&
      layoutData?.[VOCAB_LAYOUTS_API[activeVocab]]?.id === row.id
    )),
  });

  const Wrapper = pageStyle ? PageSection : TableGrid;

  return (
    <>
      <Wrapper>
        <TableToolbar
          activeVocab={activeVocab}
          setFilterBy={(text) => {
            setQuery(text);
            if (page > 0) {
              setPage(0);
            }
          }}
        />
      </Wrapper>
      <Wrapper>
        <Table>
          <TableHeader
            order={order}
            orderBy={orderBy}
            rowCount={items.length || 0}
            headers={tableCells}
            hasBorder
          />
          <TableBody>
            {items.length === 0 && <TableRow><TableCell align={'center'} colSpan={4}>No item found</TableCell></TableRow>}
            {items.map(row => (
              <TableRow
                hover
                tabIndex={-1}
                key={row.id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickRow(row);
                }}
                className={[
                  'term',
                  classes.row,
                  selectedTermId === row.id ?
                    classes.selected : '',
                  showActions ? classes.showActions : '',
                ].filter(className => !!className).join(' ')}
              >
                {onSelect && <TableCell align={'left'}>
                  <AccessToggle restrictedTo={`create terms in ${activeVocab}`}>
                    <Checkbox
                      color={'primary'}
                      checked={checked.includes(row.id)}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const value = !checked.includes(row.id);
                        onSelect([value, row]);
                      }}
                    />
                  </AccessToggle>
                </TableCell>}
                {data.map(cell =>
                  (<TableCell key={cell.id} align={'left'}>
                    {cell.display(preProcessData(row))}
                  </TableCell>),
                )}
                {actions.length > 0 && <TableCell align={'right'}><div className={`${classes.actions} actions`}>
                  {actions.map((action) => {
                    switch (action.type) {
                      case 'select':
                        return (
                          <IconButton
                            key={action.type}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              action.action(row);
                            }}
                          >
                            <SaveOutlined />
                          </IconButton>);
                      case 'edit':
                        return (
                          <IconButton
                            key={action.type}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              action.action(row);
                            }}
                          >
                            <EditOutlined />
                          </IconButton>);
                      case 'config':
                        return (
                          <IconButton
                            key={action.type}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onClickRow(row);
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>);
                      default:
                        return null;
                    }
                  })}
                </div></TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="div"
          count={-1}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(e, i) => setPage(i)}
          onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
        />
      </Wrapper>
    </>
  );
}

VocabTable.propTypes = {
  classes: PropTypes.object.isRequired,
  vocab: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  checked: PropTypes.array,
  data: PropTypes.array,
  actions: PropTypes.array,
  fetchFlatVocab: PropTypes.func.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  activeVocab: PropTypes.string.isRequired,
  selectedTermId: PropTypes.string,
  onSelect: PropTypes.func,
  onClickRow: PropTypes.func,
  showActions: PropTypes.bool,
  pageStyle: PropTypes.bool,
};

VocabTable.defaultProps = {
  headers: [],
  data: [],
  checked: [],
  actions: [],
  onSelect: null,
  onClickRow: noop,
  order: null,
  orderBy: null,
  selectedTermId: '',
  showActions: false,
  pageStyle: false,
};

export default withStyles(styles)(connect(
  ({ vocab, layout }) => ({ vocab, layout }),
  { fetchFlatVocab: fetchVocab },
)(VocabTable));
