// objects
export const ITEM_REVIEWED = 'itemReviewed';

// Props
export const PROP_NAME = 'name';
export const PROP_TYPE = 'type';
export const PROP_AUTHOR = 'author';
export const PROP_ARTIST = 'artist';
export const PROP_EPISODE_NO = 'episodeNumber';
export const PROP_SEASON = 'season';
export const PROP_SERIES = 'series';
export const PROP_LOCATION = 'location';
export const PROP_START_DATE = 'startDate';

// Options
export const ITEM_REVIEWED_TYPES = {
  Book: 'Book',
  Event: 'Event',
  LocalBusiness: 'Local Business',
  MusicAlbum: 'Music (Album)',
  MusicRecording: 'Music (Song)',
  PodcastSeries: 'Podcast (Series)',
  Product: 'Product',
  Movie: 'Movie',
  MobileApplication: 'Software (Mobile App)',
  VideoGame: 'Software (Video Game)',
  TVEpisode: 'TV (Episode)',
  TVSeason: 'TV (Season)',
  TVSeries: 'TV (Series)',
};
