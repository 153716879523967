import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import { CurrencyGbp } from 'mdi-material-ui';
import { useState } from 'react';

import EmbedWrapper from '../../../../common/EmbedWrapper';
import TextField from '../../../../../../ui/TextField';
import { curry } from 'rambdax';
import AddIcon from '@material-ui/icons/Add';

import { initialMoneyItem } from '../helper';
import Button from 'components/ui/buttons/Button';
import { PROP_IMAGE } from '../../../article-link/defaults';
import ImageSelector from 'components/ui/media/ImageSelector';

const MoneyElement = (props) => {
  const { attributes, children, element, className } = props;
  const [items, setItem] = useState(element.items || [initialMoneyItem()]);
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const setMoneyProperty = (id, key, value) => {
    const itemsWithImage = items.map((money) => {
      if (money.id === id) {
        return {
          ...money,
          [key]: value,
        };
      }
      return money;
    });
    setItem(itemsWithImage);
    Transforms.setNodes(editor, { items: itemsWithImage }, { at: path });
  };
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Money Widget'} Icon={CurrencyGbp} contentEditable={false} element={element}>
        {items.map((item, index) => (<div key={item.id}><Grid
          key={item.id}
          item
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <ImageSelector
              label={'Drag & Drop image or browse'}
              selectImage={(e) => setMoneyProperty(item.id, PROP_IMAGE, e)}
              onClose={() => {
                ReactEditor.focus(editor);
                Transforms.setSelection(editor, path);
              }}
              onRemove={() => setMoneyProperty(item.id, PROP_IMAGE, [])}
              image={item[PROP_IMAGE]}
              showEditDialog
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Partner name'}
              margin={'none'}
              placeholder={'Partner name'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerName', e.target.value)}
              value={item['partnerName'] || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Partner attribute 1'}
              margin={'none'}
              placeholder={'Partner attribute 1'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerAttributeOne', e.target.value)}
              value={item.partnerAttributeOne || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Partner attribute 2'}
              margin={'none'}
              placeholder={'Partner attribute 2'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerAttributeTwo', e.target.value)}
              value={item.partnerAttributeTwo || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Partner attribute 3'}
              margin={'none'}
              placeholder={'Partner attribute 3'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerAttributeThree', e.target.value)}
              value={item.partnerAttributeThree || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={'rating 1'}
              margin={'none'}
              placeholder={'rating 1'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'ratingOne', e.target.value)}
              value={item.ratingOne || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'rating 2'}
              margin={'none'}
              placeholder={'rating 2'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'ratingTwo', e.target.value)}
              value={item.ratingTwo || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Rating 3'}
              margin={'none'}
              placeholder={'Rating 3'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'ratingThree', e.target.value)}
              value={item.ratingThree || ''}
            />
          </Grid>


          <Grid item xs={12}>
            <TextField
              label={'Partner cta text '}
              margin={'none'}
              placeholder={'Partner cta text '}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerCtaText', e.target.value)}
              value={item.partnerCtaText || ''}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={'Partner cta link'}
              margin={'none'}
              placeholder={'Partner cta link'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerCtaLink', e.target.value)}
              value={item.partnerCtaLink || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Partner cta site info'}
              margin={'none'}
              placeholder={'Partner cta site info'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'partnerCtaSiteInfo', e.target.value)}
              value={item.partnerCtaSiteInfo || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Disclaimer'}
              margin={'none'}
              placeholder={'Disclaimer'}
              onClick={e => e.stopPropagation()}
              onChange={(e) => setMoneyProperty(item.id, 'disclaimer', e.target.value)}
              value={item.disclaimer || ''}
            />
          </Grid>

        </Grid>
          <br />
        </div>))}

        <Grid item xs={12}>
          <Grid spacing={2} container justify={'flex-end'}>
            <Grid item>
              <Button
                variant={'outlined'}
                onClick={() => setItem([...items, initialMoneyItem()])}
                startIcon={<AddIcon />}
              >Add another item</Button>
            </Grid>
          </Grid>
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

MoneyElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default MoneyElement;
