import React from 'react';
import PropTypes from 'prop-types';

import DropdownFilter from '../../DropdownFilter';

export const STATUS_SCHEDULED = 'scheduled';
export const STATUS_PUBLISHED = '1';
export const STATUS_UNPUBLISHED = '0';

const StatusFilter = ({ exclude, ...props }) => (
  <DropdownFilter
    id={'filterStatus'}
    label={'status'}
    options={[
      {
        id: STATUS_PUBLISHED,
        name: 'Published',
      },
      {
        id: STATUS_UNPUBLISHED,
        name: 'Unpublished',
      },
      {
        id: STATUS_SCHEDULED,
        name: 'Scheduled',
      },
    ].filter(({ id }) => !exclude.includes(id))}
    includeAll
    {...props}
  />
);

StatusFilter.propTypes = {
  exclude: PropTypes.array,
};

StatusFilter.defaultProps = {
  exclude: [],
};

export default StatusFilter;
