import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/EditOutlined';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

import ImageList from '../components/embed/ImageList';
import ImageEditDialog from '../components/dialog/ImageEditDialog';
import { saveMediaEdit } from '../actions/media';
import IconButton from '../components/ui/buttons/IconButton';
import ButtonSelect from '../components/ui/buttons/ButtonSelect';
import { feature } from '../components/helper/utils';
import { galleryRegex } from '../constants/features';

export const MediaListBreadcrumb = [
  {
    title: 'Media',
    slug: 'media',
  },
  {
    title: 'Image library',
    slug: 'image',
  },
];

const MediaList = ({
  features,
  onSaveMediaEdit: onSave,
}) => {
  const [addNewImageDialogOpen, setAddNewImageDialogOpen] = useState(false);
  const [mediaId, setMediaId] = useState(null);
  const featureEnabled = feature(features);
  const types = [{
    link: '/media/image',
    title: 'Image',
  }];
  if (featureEnabled(galleryRegex)) {
    types.push({
      link: '/media/gallery',
      title: 'Gallery',
    });
  }
  return (
    <Page
      toolbarContent={<PageToolbar breadcrumb={MediaListBreadcrumb}>
        <Grid container justify={'center'} alignItems={'center'}>
          <Typography variant={'body2'}>Manage</Typography><ButtonSelect active={'Image'} items={types} />
        </Grid>
      </PageToolbar>}
    >
      <PageContent>
        <ImageList
          pageStyle
          showDroppableZone
          action={data => (<IconButton
            onClick={() => {
              setAddNewImageDialogOpen(true);
              setMediaId(data.mid);
            }}
          ><EditIcon /></IconButton>)}
        />
      </PageContent>
      {addNewImageDialogOpen && <ImageEditDialog
        handleClose={() => {
          setAddNewImageDialogOpen(false);
          setMediaId(null);
        }}
        canEdit
        open={addNewImageDialogOpen}
        mediaId={mediaId}
        onSaveImage={(x) => {
          setAddNewImageDialogOpen(false);
          onSave(x);
        }}
      />}
    </Page>
  );
};

MediaList.propTypes = {
  onSaveMediaEdit: PropTypes.func.isRequired,
  features: PropTypes.array.isRequired,
};

export default connect(
  ({ frame: { selectedPublication: { features } } }) => ({ features }),
  { onSaveMediaEdit: saveMediaEdit },
)(MediaList);
