import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Typography } from '@material-ui/core';

import { AD_COMPONENTS, ALL_COMPONENTS } from '../../../../constants/components';

import { selectComponent } from '../../../../actions/sidebar';

import ComponentDraggable from '../../../layout/contexts/componentSelector/ComponentDraggable';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import { ContextBarBody } from '../../../common/context/ContextBar';

const ComponentSelectorTitle = ({ children }) => (
  <Typography variant="subtitle1" component="h2">{children}</Typography>
);

ComponentSelectorTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

const ComponentSelector = (props) => {
  const { components } = props;
  return (
    <ContextBarBody>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Ad Components</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {components.filter(({ id }) => AD_COMPONENTS.includes(id)).map(comp => (
            <ComponentDraggable
              disabled={false} // TODO implement locking
              key={comp.id}
              component={comp}
            />
          ))}
        </ContextPanelDetails>
      </ContextPanel>
      <ContextPanel>
        <ContextPanelSummary>
          <ContextPanelHeading>Other</ContextPanelHeading>
        </ContextPanelSummary>
        <ContextPanelDetails variant={'full'}>
          {components.filter(({ id }) => !ALL_COMPONENTS.includes(id)).map(comp => (
            <ComponentDraggable
              disabled={false}
              key={comp.id}
              component={comp}
            />
          ))}
        </ContextPanelDetails>
      </ContextPanel>
    </ContextBarBody>
  );
};

ComponentSelector.propTypes = {
  components: PropTypes.array.isRequired,
};

export default connect(
  ({ sidebar: { components } }) => ({ components }),
  { addComponent: selectComponent },
)(ComponentSelector);
