import moment from 'moment';

import {
  FIELD_EXTRA_DATA,
  PAYLOAD_FIELD_KEYS,
  EXTRA_FIELD_KEYS,
  STORY_FIELD_MAPPING_CMS_KEYED,
  FIELD_PUBLICATION,
  FIELD_UPDATED,
  FIELD_AUTHORS,
  FIELD_SECTION,
  FIELD_PUBLISH, FIELD_UNPUBLISH,
} from '../../constants/story/storyFields';

const getReferencePayload = (data) => {
  const value = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(({ id }) => value.push({ target_id: id }));
  }
  return value.length > 0 ? value : null;
};

const getReferenceField = (data) => {
  const value = [];
  if (Array.isArray(data) && data.length > 0) {
    data.forEach(({ target_id: id, pwamp }) =>
      (pwamp && pwamp.name ? value.push({ id, name: pwamp.name }) : value.push({ id })));
  }
  return value.length > 0 ? value : [];
};

const getPayloadDataKey = (field) => {
  switch (field) {
    default:
      return 'value';
  }
};

const getPayloadData = (data, field) => {
  switch (field) {
    case FIELD_UPDATED:
      return moment().unix();
    default:
      return typeof data !== 'undefined' ? data : null;
  }
};

const getFieldData = (data, field) => {
  switch (field) {
    case FIELD_UPDATED:
    case FIELD_PUBLISH:
    case FIELD_UNPUBLISH:
      return moment(data).unix();
    default:
      return typeof data !== 'undefined' ? data : null;
  }
};

const addPayloadFields = (payload, story) => {
  const modifiedPayload = payload;
  PAYLOAD_FIELD_KEYS.forEach((field) => {
    const mapping = STORY_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      case FIELD_SECTION:
      case FIELD_AUTHORS:
        modifiedPayload[mapping.key] = getReferencePayload(story[field]);
        break;
      default: {
        const value = getPayloadData(story[field], field);
        modifiedPayload[mapping.key] = value !== null ? [{
          [getPayloadDataKey(field)]: value,
        }] : null;
        break;
      }
    }
  });
  return modifiedPayload;
};

const addPayloadExtraFields = (payload, story) => {
  const modifiedPayload = payload;
  const extras = {};
  EXTRA_FIELD_KEYS.forEach((field) => {
    const mapping = STORY_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      default:
        extras[mapping.key] = story[field];
        break;
    }
  });
  if (extras !== {}) {
    modifiedPayload[FIELD_EXTRA_DATA] = [{
      value: JSON.stringify(extras),
    }];
  }
  return modifiedPayload;
};

const getPayloadDefaults = (selectedPublication) => {
  const { id, uuid } = selectedPublication;
  return ({
    bundle: [{
      target_id: 'default',
      target_type: 'flowz_visual_story',
    }],
    [FIELD_PUBLICATION]: [{
      target_id: id,
      target_type: 'taxonomy_term',
      target_uuid: uuid,
    }],
  });
};

const getFieldsFromPayload = (payload) => {
  const fields = {};
  PAYLOAD_FIELD_KEYS.forEach((field) => {
    const mapping = STORY_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      case FIELD_SECTION:
      case FIELD_AUTHORS:
        if (typeof payload[mapping.key] !== 'undefined') {
          fields[field] = getReferenceField(payload[mapping.key]);
        }
        break;
      default: {
        const key = getPayloadDataKey(field);
        if (
          typeof payload[mapping.key] !== 'undefined' &&
          typeof payload[mapping.key][0] !== 'undefined' &&
          typeof payload[mapping.key][0][key] !== 'undefined'
        ) {
          fields[field] = getFieldData(payload[mapping.key][0][key], field);
        }
        break;
      }
    }
  });
  return fields;
};

const getExtraFieldsFromPayload = (payload) => {
  const fields = {};
  if (typeof payload[FIELD_EXTRA_DATA] === 'undefined') {
    return fields;
  }
  const extras = JSON.parse(payload[FIELD_EXTRA_DATA][0].value);
  EXTRA_FIELD_KEYS.forEach((field) => {
    const mapping = STORY_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      default:
        if (typeof extras[mapping.key] !== 'undefined') {
          fields[field] = extras[mapping.key];
        }
        break;
    }
  });
  return fields;
};

export const getStoryPayload = (story, selectedPublication) => {
  let payload = getPayloadDefaults(selectedPublication);
  payload = addPayloadFields(payload, story);
  payload = addPayloadExtraFields(payload, story);

  return payload;
};

export const getStoryFields = payload => (
  {
    ...getFieldsFromPayload(payload),
    ...getExtraFieldsFromPayload(payload),
  }
);
