import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';

import { fetchArticle, disposeFetchArticle } from '../../actions/article';
import ThumbnailImage from '../table/content/ThumbnailImage';
import OpenInNewTab from '../ui/buttons/OpenInNewTabButton';
import IconButton from '../ui/buttons/IconButton';
import { TableBody, TableRow, TableCell } from '../ui/table/Table';

import ListTable from '../table/ListTable';
import TableHeader from '../table/TableHeader';
import { getScheduledDisplayDate } from '../helper/scheduler';

const getProductArticleType = (types) => {
  const productType = types.find(({ name }) => name === 'Product');
  return productType ? productType.id : '';
};

const ProductArticlesTable = (props) => {
  const {
    articleTypes,
    totalArticle,
    articles,
    textQuery,
    fromDate,
    toDate,
    isArticleFetching,
    push: routeChange,
    fetchArticle: fetchList,
    disposeFetchArticle: disposeList,
  } = props;

  const queryData = [
    textQuery,
    fromDate,
    toDate,
  ];

  const getFetchData = (page, rowsPerPage) => ({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    q: textQuery,
    start: fromDate,
    end: toDate,
    tids: getProductArticleType(articleTypes),
  });

  useEffect(() => () => disposeList(), []);

  const headers = ['Image', 'Headline', 'Author', 'Updated/Scheduled', 'Matched', 'Errors', ''];
  const orderable = [];// ['Updated/Scheduled', 'Errors'];

  return (
    <ListTable
      isFetching={isArticleFetching}
      total={totalArticle}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={fetchList}
    >
      <TableHeader
        orderBy={'Updated/Scheduled'}
        order={'desc'}
        headers={headers.map(
          header => ({ id: header, label: header, orderable: orderable.includes(header) }),
        )}
        hasBorder
      />
      <TableBody>
        {articles.length === 0 && <TableRow>
          <TableCell colSpan={headers.length} align={'center'}>No articles found</TableCell>
        </TableRow>}
        {articles.length > 0 && articles
          .filter(article => article && article.type)
          .map(({
            id, authors, hero, status, title, path, updated, publish, productStats,
          }) => {
            const {
              matched = 0,
              products = 0,
              outOfStock = 0, // currently unused
              httpErrors = 0,
            } = productStats || {};
            return (
              <TableRow key={id} hasActions>
                <TableCell><ThumbnailImage hero={hero} size={'small'} /></TableCell>
                <TableCell>{title}</TableCell>
                <TableCell>{Array.isArray(authors) && authors.map(({ name }) => name).join(', ')}</TableCell>
                <TableCell>{getScheduledDisplayDate(status, updated, publish)}</TableCell>
                <TableCell>{matched} / {products}</TableCell>
                <TableCell>{httpErrors}</TableCell>
                <TableCell>
                  <Grid container wrap={'nowrap'} justify={'flex-end'}>
                    <IconButton
                      onClick={() => routeChange(`/article/edit/${id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                    {status && <OpenInNewTab target={path} />}
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </ListTable>
  );
};

ProductArticlesTable.propTypes = {
  fetchArticle: PropTypes.func.isRequired,
  disposeFetchArticle: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  articleTypes: PropTypes.array.isRequired,
  articles: PropTypes.array,
  textQuery: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  totalArticle: PropTypes.number,
  isArticleFetching: PropTypes.bool,
};

ProductArticlesTable.defaultProps = {
  textQuery: '',
  fromDate: '',
  toDate: '',
  articles: [],
  totalArticle: 0,
  isArticleFetching: false,
};

export default connect(
  ({
    article: { articles, totalArticle, isArticleFetching },
    vocab: { article_type: { items: articleTypes } },
  }) =>
    ({ articles, totalArticle, isArticleFetching, articleTypes }),
  { push, fetchArticle, disposeFetchArticle },
)(ProductArticlesTable);
