import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import { minWidthSidebar, maxWidthSidebar } from '../../../constants/frame';
import { headerHeight } from '../../header';
import { footerHeight } from '../page/Page';
import IconButton from '../../ui/buttons/IconButton';

const contextHeaderHeight = 48;

const styles = theme => ({
  root: {
    position: 'relative',
    zIndex: 1,
    maxWidth: maxWidthSidebar,
    flexBasis: maxWidthSidebar,
    minWidth: minWidthSidebar,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.2)',
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    overflowY: 'auto',
  },
});


const ContextBar = (props) => {
  const { classes, children, ...rest } = props;
  return (
    <Grid className={classes.root} item {...rest}>
      {children}
    </Grid>
  );
};

ContextBar.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ContextBar);

export const ContextBarHeader = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    height: contextHeaderHeight,
  },
}))(({ classes, children, ...rest }) =>
  <header className={classes.root} {...rest}>{children}</header>);

export const ContextClose = withStyles(theme => ({
  root: {
    marginLeft: theme.spacing(0.5),
  },
}))(({ classes, ...rest }) => (
  <Grid alignItems={'center'} container>
    <Grid item><IconButton className={classes.root} {...rest}><CloseIcon /></IconButton></Grid>
  </Grid>
));


export const ContextBarBody = withStyles(() => ({
  root: {
    height: `calc(100vh - ${headerHeight + footerHeight + contextHeaderHeight}px)`,
    overflowY: 'auto',
  },
}))(({ classes, children, ...rest }) => <div className={classes.root} {...rest}>{children}</div>);
