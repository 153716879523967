import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { componentSetProp } from '../../../../actions/layout';

import {
  ContextPanel,
  ContextPanelDetails, ContextPanelFormLabel,
  ContextPanelHeading,
} from '../../../common/context/ContextPanel';
import ImageSelector from '../../../ui/media/ImageSelector';
import TextField from '../../../ui/TextField';
import { changeHandler } from './helper';

const DESCRIPTION = 'description';
const GRADIENT_COLOR = 'gradientColor';
const LOGO = 'logo';
const DESKTOP_BACKGROUND = 'desktopBackground';
const MOBILE_BACKGROUND = 'mobileBackground';

const imageProps = [
  [LOGO, 'Logo'],
  [DESKTOP_BACKGROUND, 'Desktop background'],
  [MOBILE_BACKGROUND, 'Mobile background'],
];

const ImageGradientSettings = (props) => {
  const {
    component,
    componentPropOnChange,
  } = props;
  const { id } = component;
  return (
    <ContextPanel>
      <ContextPanelHeading>Image Gradient settings</ContextPanelHeading>
      <ContextPanelDetails variant="full">
        <TextField
          margin="dense"
          defaultValue={component[GRADIENT_COLOR] || ''}
          onChange={changeHandler(data => componentPropOnChange(id, GRADIENT_COLOR, data))}
          label="Colour"
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
        />
        <TextField
          margin="dense"
          defaultValue={component[DESCRIPTION] || ''}
          multiline
          rows={3}
          onChange={changeHandler(data => componentPropOnChange(id, DESCRIPTION, data))}
          label="Description"
        />
        {imageProps.map(([key, label]) => (<FormControl
          key={key}
          margin="dense"
          fullWidth
        >
          <ContextPanelFormLabel>{label}</ContextPanelFormLabel>
          <ImageSelector
            selectImage={
              // eslint-disable-next-line no-unused-vars
              ({ cmsUser, workflow, ...rest }) => componentPropOnChange(id, key, { ...rest })
            }
            onRemove={() => componentPropOnChange(id, key, null)}
            label="Add image"
            image={component[key]}
            showEditDialog
          />
        </FormControl>))}
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ImageGradientSettings.defaultProps = {
  disabledAlignmentOption: false,
};

ImageGradientSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentPropOnChange: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentPropOnChange: componentSetProp },
)(ImageGradientSettings);
