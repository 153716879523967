import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';
import { NEWSLETTER_TARGET } from '../../constants';
import Select from '../../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

const getNewsletterOptions = (newsletters) => {
  const options = {};
  // eslint-disable-next-line array-callback-return
  newsletters.map(({ title, value }) => {
    options[value] = title;
  });
  return options;
};

const NewsletterOptions = (props) => {
  const {
    component,
    publicationConfig,
    componentSetProp: setProp,
  } = props;

  if (!publicationConfig || !publicationConfig.newsletters) {
    return null;
  }
  const { newsletters } = publicationConfig;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Newsletter target</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          displayEmpty
          labelId={`newsletter${component.id}`}
          value={component[NEWSLETTER_TARGET]}
          emptyValue={'Select an option'}
          onChange={e => setProp(component.id, NEWSLETTER_TARGET, e.target.value)}
          items={getNewsletterOptions(newsletters)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

NewsletterOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object,
};

NewsletterOptions.defaultProps = {
  publicationConfig: null,
};

export default connect(
  ({ frame: { selectedPublication: { publicationConfig } } }) => ({ publicationConfig }),
  { componentSetProp },
)(NewsletterOptions);
