import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';

import { onChange, onSubmit, onError, onInit } from '../actions/resetPassword';
import Notifications from "../components/common/Notifications";

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      margin: 'auto',
    },
    '& .logo-wrapper': {
      width: '300px',
    },
  },
  brightsites: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px',
    color: theme.palette.brightsites.brand,
    fontSize: '25px',
    '& > span': {
      width: '30px',
      margin: '0 5px 0 15px',
    },
  },
  container: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(),
  },
  input: {
    background: theme.palette.border.secondary.dark,
    borderRadius: '5px',
    '& .MuiFilledInput-root:before': {
      display: 'none',
    },
    '& .MuiFilledInput-root:after': {
      display: 'none',
    },
    '& label.Mui-focused': {
      color: theme.palette.common.white,
    },
    '& label': {
      fontSize: '1.3em',
      color: theme.palette.common.white,
      opacity: '0.5',
    },
    '& input': {
      color: theme.palette.common.white,
    },
    '& svg': {
      fill: theme.palette.common.white,
      opacity: '0.5',
    },
  },
  hidden: {
    display: 'none',
  },
  formControl: {
    '&:before': {
      display: 'none',
    },
  },
  message: {
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: '24px auto',
    borderRadius: '5px',
    display: 'block',
    width: '100%',
    fontSize: '25px',
    fontWeight: 'bold',
    color: theme.palette.common.white,
    boxShadow: theme.palette.primary.glow,
  },
  button: {
    margin: '0 auto',
    display: 'block',
  },
  label: {
    color: theme.palette.common.white,
    fontSize: '19px',
  },
  checkbox: {
    '& .MuiIconButton-label': {
      border: '2px solid',
      borderRadius: '50%',
      padding: '1px',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '19px',
      opacity: 0,
    },
    '& .Mui-checked': {
      color: theme.palette.primary.main,
      '& .MuiSvgIcon-root': {
        opacity: 1,
      },
    },
  },
});

const ResetPassword = (props) => {
  const {
    classes, onChange, onSubmit, onError, onInit, failureMessage, successMessage, location,
  } = props;
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    onInit('token', token);
  }, [token]);

  if (!token) {
    onError('This link has expired, please request a new password reset.')
  }

  const [values, setValues] = React.useState({
    password: '',
    confirmPassword: '',
    tempPassword: '',
  });

  const handleChange = prop => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    onChange(prop, event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  useEffect(() => {
    document.body.classList.add('loggedOut');
    return () => document.body.classList.remove('loggedOut');
  });

  return (
    <main className={classes.main}>
      <Notifications />
      <CssBaseline />
      <div className={classes.container}>
        <div className={classes.brightsites}>Set new password</div>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl margin="normal" required fullWidth>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="filled"
              className={classes.input}
              id="email"
              label="Email"
              onChange={handleChange('email')}
              name="email"
              autoComplete="email"
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="filled"
              className={classes.input}
              name="tempPassword"
              label="Temp password"
              onChange={handleChange('tempPassword')}
              type={values.showPassword ? 'text' : 'password'}
              value={values.tempPassword}
              id="tempPassword"
              autoComplete="none"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="filled"
              className={classes.input}
              name="password"
              label="New password"
              onChange={handleChange('password')}
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              id="password"
              autoComplete="none"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <TextField
              InputLabelProps={{ shrink: true }}
              variant="filled"
              className={classes.input}
              name="confirmPassword"
              label="Confirm new password"
              onChange={handleChange('confirmPassword')}
              type={values.showPassword ? 'text' : 'password'}
              value={values.confirmPassword}
              id="confirmPassword"
              autoComplete="none"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <input
            className={classes.hidden}
            name="token"
            type={'hidden'}
            value={token || ''}
            id="token"
            autoComplete="off"
          />
          {failureMessage && <Typography className={classes.message} variant={'body2'} color={'error'}><strong>{failureMessage}</strong></Typography>}
          {successMessage && <Typography className={classes.message} variant={'body2'} color={'primary'}><strong>{successMessage}</strong></Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size={'large'}
            className={classes.submit}
          >
            Submit
          </Button>

        </form>
      </div>
    </main>
  );
}

ResetPassword.defaultProps = {
  failureMessage: null,
  successMessage: null,
};

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  failureMessage: PropTypes.string,
  successMessage: PropTypes.string,
  location: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(({resetPassword: {failureMessage}}) => ({failureMessage}), {
  onChange, onSubmit, onError, onInit
})(ResetPassword));
