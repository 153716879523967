import BaseEntity from './common/BaseEntity';

import {
  MANUAL_LIST_FIELD_MAPPING,
  MANUAL_LIST_PAYLOAD_FIELDS,
  MANUAL_LIST_EXTRA_FIELDS,
  FIELD_EXTRA_DATA,
  FIELD_ARTICLES,
  FIELD_ARTICLES_ORDER,
} from '../constants/manualList/manualListFields';
import { sanitiseLayoutArticle } from '../utils/sanitiser';

const ENTITY_TYPE = 'flowz_manual_list_type';
export const ENTITY_BUNDLE_SECTION = 'section';
export const ENTITY_BUNDLE_LIST = 'json_list';

export const ARTICLE_ID = 'target_id';

class ManualListEntity extends BaseEntity {
  constructor(bundle = ENTITY_BUNDLE_SECTION) {
    super(
      ENTITY_TYPE,
      bundle,
      MANUAL_LIST_FIELD_MAPPING,
      MANUAL_LIST_PAYLOAD_FIELDS,
      MANUAL_LIST_EXTRA_FIELDS,
      [],
      {
        extrasField: FIELD_EXTRA_DATA,
      },
    );
  }
  addExtraData(data, extras) {
    return (field) => {
      switch (field) {
        case FIELD_ARTICLES: {
          const articles = this.getExtraData(extras, field);
          data[field] = this.constructor.getArrayAsKeyed(articles, ARTICLE_ID);
          data[FIELD_ARTICLES_ORDER] = this.constructor.getArrayOrder(articles, ARTICLE_ID);
          return;
        }
        default:
          data[field] = this.getExtraData(extras, field);
      }
    };
  }
  // @todo this isn't used at present due to manualListHelper direct use of getOrderedArray
  getPayloadExtraData(data, field) {
    switch (field) {
      case FIELD_ARTICLES:
        return this.constructor.getOrderedArray(
          data[field],
          data[FIELD_ARTICLES_ORDER],
          sanitiseLayoutArticle,
        );
      default:
        return data[field];
    }
  }
}

export default ManualListEntity;
