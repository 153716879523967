import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import MediaRatio from '../embed/MediaRatio';
import { sendViafouraNotification } from '../../actions/viafouraNotification';
import TextField from '../ui/TextField';
import { optimiseImage } from '../helper/media';
import Button from '../ui/buttons/Button';
import { setLocalProp } from '../../actions/dataState';
import {
  FIELD_NAME,
  FIELD_TITLE,
  FIELD_TRACKING_TAG,
  FIELD_IMAGE,
  FIELD_TIME,
} from '../../constants/viafouraNotification/viafouraNotificationFields';

const style = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  title: {
    margin: '0',
    color: theme.palette.primary.main,
  },
  buttons: {
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
});

const ViafouraNotificationForm = (props) => {
  const {
    classes,
    fieldName,
    fieldTitle,
    fieldTracking,
    fieldImage,
    sendViafouraNotification: onSave,
    setLocalProp: setProp,
    isSending,
  } = props;

  return (
    <Grid container alignItems={'flex-start'} spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Typography variant={'h2'} className={classes.title}>New Viafoura notification</Typography>
      </Grid>
      <Grid item xs={3}>
        <MediaRatio width={'100%'}>
          <img src={optimiseImage(fieldImage, { width: 200 })} alt={'Notification hero'} />
        </MediaRatio>
      </Grid>
      <Grid item xs={9}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              value={fieldTitle}
              label={'Title'}
              onChange={e => setProp(FIELD_TITLE, e.target.value)}
            />
            <TextField
              value={fieldName}
              label={'Message'}
              multiline
              onChange={e => setProp(FIELD_NAME, e.target.value)}
            />
            <TextField
              value={fieldTracking}
              label={'Tracking tag'}
              onChange={e => setProp(FIELD_TRACKING_TAG, e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify={'flex-end'} className={classes.buttons}>
          <Button
            variant={'contained'}
            disabled={!fieldTitle || !fieldName || isSending}
            onClick={() => {
              setProp(FIELD_TIME, moment().unix());
              onSave();
            }}
          >Send notification</Button>
        </Grid>
      </Grid>
    </Grid>);
};

ViafouraNotificationForm.defaultProps = {
  articles: [],
  fieldName: '',
  fieldTitle: '',
  fieldTracking: '',
  fieldImage: '',
  isSending: false,
};

ViafouraNotificationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  sendViafouraNotification: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  fieldName: PropTypes.string,
  fieldTitle: PropTypes.string,
  fieldTracking: PropTypes.string,
  fieldImage: PropTypes.string,
  isSending: PropTypes.bool,
};

export default withStyles(style)(connect(({
  dataState: {
    [FIELD_NAME]: fieldName,
    [FIELD_TITLE]: fieldTitle,
    [FIELD_TRACKING_TAG]: fieldTracking,
    [FIELD_IMAGE]: fieldImage,
  },
  viafouraNotificationsList: { isSending },
}) => ({
  fieldName,
  fieldTitle,
  fieldTracking,
  fieldImage,
  isSending,
}), {
  sendViafouraNotification, setLocalProp,
})(ViafouraNotificationForm));
