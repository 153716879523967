import React from 'react';
import PropTypes from 'prop-types';
import MaterialRating from '@material-ui/lab/Rating';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Close';

import IconButton from './buttons/IconButton';

const Rating = ({ onReset, value, ...rest }) => (
  <Grid container justify={'space-between'}>
    <Grid item><MaterialRating value={value} {...rest} /></Grid>
    {onReset && value > 0 && <Grid item><IconButton onClick={onReset}><RemoveIcon /></IconButton></Grid>}
  </Grid>
);

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  onReset: PropTypes.func,
};

Rating.defaultProps = {
  onReset: null,
};

export default Rating;
