import { PURGE } from 'redux-persist';

import { DIALOG_OPEN_PUBLICATION } from '../../constants/actionTypes/dialog';

const defaultState = {
  isPublicationDialogOpen: false,
  isProfileDialogOpen: false,
  isSectionDialogOpen: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DIALOG_OPEN_PUBLICATION:
      return { ...state, isPublicationDialogOpen: action.value};

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
