import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withScrolling from 'react-dnd-scrolling';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import Header, { headerHeight } from '../../header';
import Sidebar, { drawerWidth } from '../../drawer';
import Notifications from '../Notifications';

import ModalAppCrash from './ModalAppCrash';

export const footerHeight = 18;

const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    width: `calc(100% - ${drawerWidth}px)`,
    marginTop: headerHeight,
    paddingBottom: footerHeight,
    marginLeft: drawerWidth,
  },
  copyright: {
    boxShadow: '0 3px 3px 3px rgba(0, 0, 0, 0.16)',
    background: theme.palette.background.contrast,
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    height: footerHeight,
  },
  scroll: {
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
  },
});

const Page = (props) => {
  const {
    children, roles, classes, scroll,
    alwaysExpanded,
    toolbarContent,
    selectedPublication,
  } = props;
  const rootClasses = [classes.root, 'container'];
  if (alwaysExpanded) {
    rootClasses.push('expanded');
  }
  return (
    <div className={rootClasses.join(' ')}>
      <Notifications />
      <Header toolbarContent={toolbarContent} />
      {selectedPublication.features && <Sidebar roles={roles} />}
      <Grid container wrap={'nowrap'} className={scroll ? classes.scroll : ''}>
        {children}
      </Grid>
      <ModalAppCrash />
      <Typography className={classes.copyright} variant={'caption'}>Copyright © {new Date().getFullYear()} Bright Sites Consulting Ltd. All Rights Reserved.</Typography>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  roles: PropTypes.array,
  selectedPublication: PropTypes.object,
  toolbarContent: PropTypes.node,
  scroll: PropTypes.bool,
  alwaysExpanded: PropTypes.bool,
};

Page.defaultProps = {
  roles: [],
  toolbarContent: null,
  selectedPublication: {},
  scroll: false,
  alwaysExpanded: false,
};

export default withStyles(styles)(connect(
  ({
    frame: { selectedPublication },
    login: { user: { roles } },
    drawer: { alwaysExpanded },
  }) => ({
    selectedPublication,
    roles,
    alwaysExpanded,
  }), {}
)(Page));
