import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import NotesDialog from '../../../dialog/NotesDialog';

import { saveNote, deleteNote } from '../../../../actions/edition';
import { ContextPanelItem } from '../../../common/context/ContextPanel';
import Button from '../../../ui/buttons/Button';

const ArticleNotes = ({
  saveNote: onSave, deleteNote: onDelete, notes,
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (<ContextPanelItem label={`Notes (${notes.length})`}>
    <Button caption textOnly onClick={() => setShowDialog(true)}>View</Button>
    {showDialog && <NotesDialog
      notes={notes}
      onSave={onSave}
      onDelete={onDelete}
      open={showDialog}
      handleClose={() => {
        setShowDialog(false);
      }}
    />}
  </ContextPanelItem>);
};

ArticleNotes.defaultProps = {
};

ArticleNotes.propTypes = {
  notes: PropTypes.array.isRequired,
  saveNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
};

export default connect(
  ({
    edition: { notes },
  }) => ({ notes }),
  { saveNote, deleteNote },
)(ArticleNotes);

