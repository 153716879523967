import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { of, from } from 'rxjs';
import {
  filter,
  map, mapTo,
  mergeMap, concatMap, switchMap,
  take, takeUntil,
  withLatestFrom,
  toArray,
} from 'rxjs/operators';

import {
  DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS,
} from 'constants/actionTypes';
import apiCatchError, { showWarningNotification } from '../helper/notification';
import {
  INVALID_TYPE,
  MEDIA_ENTITY_BUNDLE,
  MEDIA_ENTITY_IDS,
  VALID_IMAGE_EXTENSION,
  VIDEO_JWPLAYER
} from 'constants/media/media';
import {
  DIALOG_GALLERY_ON_SEARCH,
  DIALOG_ON_EDIT_MEDIA_REQUEST,
  DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED,
  DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS,
  DIALOG_ON_SEARCH_MEDIA,
  DIALOG_ON_SEARCH_MEDIA_REJECTED,
  DIALOG_ON_SEARCH_MEDIA_SUCCESS,
  DIALOG_ON_UPLOAD_CHP,
  DIALOG_ON_UPLOAD_CHP_SUCCESS,
  DIALOG_ON_UPLOAD_MEDIA,
  DIALOG_ON_UPLOAD_MEDIA_REJECTED,
  DIALOG_ON_UPLOAD_MEDIA_SUCCESS,
} from 'constants/actionTypes/dialog';
import {
  CHP_FETCH_MEDIA,
  CHP_FETCH_MEDIA_REJECTED, CHP_FETCH_MEDIA_SUCCESS,
  GALLERY_SEARCH,
  GALLERY_SEARCH_REJECTED,
  GALLERY_SEARCH_SUCCESS,
  MEDIA_CREATE_ENTITY,
  MEDIA_CREATE_ENTITY_REJECTED,
  MEDIA_CREATE_ENTITY_SUCCESS,
  MEDIA_CREATE_REJECTED,
  MEDIA_CREATE_SUCCESS,
  MEDIA_EDIT_REJECTED,
  MEDIA_EDIT_SUCCESS,
  MEDIA_IMAGE_SAVE, MEDIA_IMAGE_SAVE_CALLBACK, MEDIA_IMAGE_SAVE_EDIT,
  PLAYLIST_FETCH,
  PLAYLIST_FETCH_REJECTED,
  PLAYLIST_FETCH_SUCCESS,
} from 'constants/actionTypes/media';
import { serialize } from 'utils/urlHelper';
import { getMediaExtras, getMediaId, getMediaName } from './helper';

const isInvalidImage = (file) => {
  const image = file?.type ? file : file.image;
  // if (image.size > MAX_IMAGE_SIZE_BYTE) {
  //   return [image.name, INVALID_SIZE];
  // }
  try {
    const type = image?.type || image?.mimetype;
    if (!type || !VALID_IMAGE_EXTENSION.find(expectedType => expectedType === type.toLowerCase())) {
      return [image.name, INVALID_TYPE];
    }
    return false;
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error(ex);
    return false;
  }
};

export const onUploadCHPMedia = (action$, state$) => action$.pipe(
  ofType(DIALOG_ON_UPLOAD_CHP),
  withLatestFrom(state$),
  filter(([, { frame: { selectedPublication: { publicationConfig } } }]) =>
    publicationConfig.chpConfig
    && publicationConfig.chpConfig.apiToken
    && publicationConfig.chpConfig.apiEndpoint,
  ),
  mergeMap(([{ value: { id } }, { frame: { selectedPublication: { publicationConfig } } }]) => {
    const payload = {
      ...publicationConfig.chpConfig,
      id,
    };
    return ajax.post('/api/media/metadata-chp', payload).pipe(
      map(({ response }) => ({ type: DIALOG_ON_UPLOAD_CHP_SUCCESS, value: response })),
      apiCatchError(DIALOG_ON_UPLOAD_MEDIA_REJECTED),
    );
  }),
);

export const onUploadMedia = action$ => action$.pipe(
  ofType(DIALOG_ON_UPLOAD_MEDIA),
  mergeMap(({ value: { file, isCrop } }) => {
    const fd = new FormData();
    fd.append('image', file[0]);
    fd.append('bundle', 'remote_image');

    const invalid = isInvalidImage(file[0]);
    if (invalid) {
      return showWarningNotification(`${invalid[0]} - ${invalid[1]}`);
    }

    return ajax.post('/api/image/upload', fd, { enctype: 'multipart/form-data' }).pipe(
      map(({ response }) => {
        if (!response && response.url) return null;
        if (isCrop) {
          return {
            type: DIALOG_ON_UPLOAD_MEDIA_CROP_SUCCESS,
            value: response,
          };
        }
        return {
          type: DIALOG_ON_UPLOAD_MEDIA_SUCCESS,
          value: response.url,
        };
      }),
      apiCatchError(DIALOG_ON_UPLOAD_MEDIA_REJECTED),
    );
  }),
);

export const onUploadImage = (action$, state$) => action$.pipe(
  // handle both action for now, ideally this only for MEDIA_IMAGE_SAVE
  ofType(MEDIA_IMAGE_SAVE, MEDIA_IMAGE_SAVE_EDIT),
  filter(({ value: { data: [state] } }) => !state.mid),
  withLatestFrom(state$),
  mergeMap(([
    { value: { data: [states], callback } },
    { frame: { selectedPublication: { id: publicationId } } },
  ]) => {
    const invalid = isInvalidImage(states);
    if (invalid) {
      return showWarningNotification(`${invalid[0]} - ${invalid[1]}`);
    }
    const fd = new FormData();
    fd.append('bundle', 'remote_image');
    fd.append('publicationId', publicationId);
    Object.keys(states).forEach((key) => {
      switch (key) {
        case 'image':
          if (states[key]?.body?.type === 'Buffer') {
            const blob = new Blob([Buffer.from(states[key].body)]);
            const file = new File(
              [blob],
              states[key]?.originalname,
              { type: states[key]?.mimetype },
            );
            fd.append(key, file, states[key]?.originalname);
          } else {
            fd.append(key, states[key]);
          }
          break;
        case 'extra':
          fd.append(key, JSON.stringify(states[key]));
          break;
        case 'cmsUser':
          break;
        default:
          fd.append(key, states[key]);
          break;
      }
    });
    return ajax.post('/api/image/create', fd, { enctype: 'multipart/form-data' }).pipe(
      map((xhr) => {
        if (!xhr.response.data) {
          throw new Error(xhr);
        }
        if (callback) {
          callback(xhr.response.data);
        }
        return {
          type: MEDIA_CREATE_SUCCESS,
          value: xhr.response.data,
        };
      }),
      apiCatchError(MEDIA_CREATE_REJECTED),
    );
  }),
);

export const onMediaSaveCallback = action$ => action$.pipe(
  ofType(MEDIA_IMAGE_SAVE_CALLBACK),
  mergeMap(({ value: callback }) => action$.pipe(
    ofType(MEDIA_CREATE_SUCCESS),
    switchMap(({ value }) => of(callback(value))),
    take(1),
    takeUntil(action$.pipe(ofType(MEDIA_CREATE_REJECTED))),
  )),
);

export const onEditImage = (action$, state$) => action$.pipe(
  // handle both action for now, ideally this only for MEDIA_IMAGE_SAVE_EDIT
  ofType(MEDIA_IMAGE_SAVE_EDIT, MEDIA_IMAGE_SAVE),
  filter(({ value: { data: [state] } }) => state.mid),
  withLatestFrom(state$),
  mergeMap(([
    { value: { data: [states], callback } },
  ]) => {
    const payload = {
      bundle: [
        {
          target_id: 'remote_image',
          target_type: 'media_bundle',
        },
      ],
      field_title: [
        {
          value: states.title,
        },
      ],
      field_url: [
        {
          value: states.url,
        },
      ],
      field_caption: [
        {
          value: states.caption,
        },
      ],
      field_copyright: [
        {
          value: states.copyright,
        },
      ],
      field_description: [
        {
          value: states.description,
        },
      ],
      field_alt_text: [
        {
          value: states.alt,
        },
      ],
      field_notes: [
        {
          value: states.notes,
        },
      ],
      field_workflow_generic: [{
        target_id: states.workflow,
      }],
    };
    if (states.extra) {
      payload.field_extra_media_info = [{
        value: JSON.stringify(states.extra),
      }];
    }
    return ajax.patch(`/api/media/${states.mid}`, payload, { 'Content-Type': 'application/json' }).pipe(
      switchMap(() => ajax.getJSON(`/api/media/search?id=${states.mid}&bundle=remote_image`).pipe(
        map((response) => {
          if (callback) {
            callback(response[0].data);
          }
          return ({
            type: MEDIA_EDIT_SUCCESS,
            value: response[0].data,
          });
        }),
        apiCatchError(MEDIA_EDIT_REJECTED),
      )),
      apiCatchError(MEDIA_EDIT_REJECTED),
    );
  }),
);

export const editMediaRequest = action$ => action$.pipe(
  ofType(DIALOG_ON_EDIT_MEDIA_REQUEST),
  concatMap(({ value }) => ajax.getJSON(`/api/media/search?${serialize(value)}`).pipe(
    map(response => ({
      type: DIALOG_ON_EDIT_MEDIA_REQUEST_SUCCESS,
      value: response,
    })),
    apiCatchError(DIALOG_ON_EDIT_MEDIA_REQUEST_REJECTED),
  )),
);

export const triggerOnSearchMedia = action$ => action$.pipe(
  ofType(MEDIA_EDIT_SUCCESS),
  mapTo({ type: DIALOG_ON_SEARCH_MEDIA }),
);

export const searchMedia = (action$) => {
  let cached = null;
  return action$.pipe(
    ofType(DIALOG_ON_SEARCH_MEDIA),
    switchMap(({ value }) => {
      if (value) {
        cached = value;
      }
      return ajax.get(`/api/media/search?${serialize(cached)}`).pipe(
        map(({ response }) => ({
          type: DIALOG_ON_SEARCH_MEDIA_SUCCESS,
          value: response,
        })),
        apiCatchError(DIALOG_ON_SEARCH_MEDIA_REJECTED),
      );
    }),
  );
};

export const searchGallery = (action$) => {
  let cached = null;
  return action$.pipe(
    ofType(GALLERY_SEARCH, DIALOG_GALLERY_ON_SEARCH),
    switchMap(({ value }) => {
      if (value) {
        cached = value;
      }
      return ajax.get(`/api/media/search?${serialize(cached)}`).pipe(
        map(({ response }) => ({
          type: GALLERY_SEARCH_SUCCESS,
          value: response,
        })),
        apiCatchError(GALLERY_SEARCH_REJECTED),
      );
    }),
  );
};

export const convertCHPMetadataToMedia = (action$, state$) => action$.pipe(
  ofType(CHP_FETCH_MEDIA),
  withLatestFrom(state$),
  filter(([, { frame: { selectedPublication: { publicationConfig } } }]) =>
    publicationConfig.chpConfig
    && publicationConfig.chpConfig.apiToken
    && publicationConfig.chpConfig.apiEndpoint,
  ),
  switchMap(([
    { value: { data, callback } },
    { frame: { selectedPublication: { publicationConfig } } },
  ]) => {
    const ids = data.split(',');
    const sources$ = from(ids)
      .pipe(
        concatMap(id => ajax.post(
          '/api/media/chp',
          {
            id,
            ...publicationConfig.chpConfig,
          }, { 'Content-Type': 'application/json' })
          .pipe(
            map(({ response }) => response || null),
            apiCatchError(),
          )),
        take(ids.length),
        toArray(),
      );
    return sources$.pipe(
      mergeMap((images) => {
        if (callback) {
          callback(images);
        }
        return of({
          type: CHP_FETCH_MEDIA_SUCCESS,
          value: images,
        });
      }),
      apiCatchError(CHP_FETCH_MEDIA_REJECTED),
    );
  }),
  apiCatchError(),
);

export const fetchTVPlaylist = action$ => action$.pipe(
  ofType(PLAYLIST_FETCH),
  switchMap(({ value: endpoint }) =>
    ajax.getJSON(`/api/tv/playlist?endpoint=${endpoint}`).pipe(
      map(value => ({ type: PLAYLIST_FETCH_SUCCESS, value })),
      apiCatchError(PLAYLIST_FETCH_REJECTED),
    )),
);

export const createMediaEntity = action$ => action$.pipe(
  ofType(MEDIA_CREATE_ENTITY),
  mergeMap(({ value: { type, data, callback } }) => {
    const id = getMediaId(type);
    if (!id) {
      return apiCatchError(MEDIA_CREATE_ENTITY_REJECTED);
    }
    let requestPath = '/api/media';
    const payload = {};
    const extras = getMediaExtras(type, data);
    if (id === MEDIA_ENTITY_IDS[VIDEO_JWPLAYER]) {
      requestPath = '/api/media/create';
      payload.bundle = id;
      payload.id = getMediaName(type, data);
    } else {
      payload.bundle = [{
        target_id: id,
        target_type: MEDIA_ENTITY_BUNDLE,
      }];
      payload.name = [{
        value: getMediaName(type, data),
      }];
    }
    return ajax.post(requestPath, { ...payload, ...extras }, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => {
        if (id === MEDIA_ENTITY_IDS[VIDEO_JWPLAYER] && response?.mid?.[0]?.value) {
          const newExtras = response.field_extra_media_info[0].value
            ? JSON.parse(response.field_extra_media_info[0].value)
            : {};
          newExtras.mp4 = data.mp4;
          const newPayload = {
            bundle: response.bundle,
            field_extra_media_info: [{
              value: JSON.stringify(newExtras),
            }],
          };
          return ajax.patch(
            `/api/media/${response.mid[0].value}`,
            newPayload,
            { 'Content-Type': 'application/json' },
          ).pipe(
            map(({ response: newResponse }) => {
              if (callback) {
                callback(response);
              }
              return ({ type: MEDIA_CREATE_ENTITY_SUCCESS, value: newResponse });
            }),
            apiCatchError(MEDIA_CREATE_ENTITY_REJECTED),
          );
        }
        if (callback) {
          callback(response);
        }
        return of({
          type: MEDIA_CREATE_ENTITY_SUCCESS,
          value: response,
        });
      }),
      apiCatchError(MEDIA_CREATE_ENTITY_REJECTED),
    );
  }),
);
