import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import RequiredIndicator from './RequiredIndicator';

const styles = theme => ({
  root: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .MuiSwitch-thumb': {
      color: theme.palette.grey.lighter,
      boxShadow: 'none',
    },
    '&:hover .MuiSwitch-thumb': {
      color: theme.palette.grey.light,
    },
    '& .Mui-checked .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
    },
  },
  compact: {
    justifyContent: 'flex-start',
  },
  noMargin: {
    margin: 0,
  },
  label: {},
  changes: {
    color: theme.palette.secondary.main,
  },
});

const ToggleSwitch = (props) => {
  const {
    classes, className, value, onChange, label, labelPosition, hasChanges, disabled, required,
    margin, compact,
  } = props;
  const switchClass = `${classes.root} ${className}`;
  const labelClasses = [classes.label];
  const rootClasses = [classes.root];
  if (hasChanges) {
    labelClasses.push(classes.changes);
  }
  if (margin === 'none') {
    rootClasses.push(classes.noMargin);
  }
  if (compact) {
    rootClasses.push(classes.compact);
  }
  return (
    <FormControlLabel
      classes={{
        root: rootClasses.join(' '),
        label: labelClasses.join(' '),
      }}
      labelPlacement={labelPosition}
      control={
        <Switch
          disabled={disabled}
          size={'small'}
          checked={value}
          onChange={onChange}
          className={switchClass}
        />
      }
      label={required ? <>{label}<RequiredIndicator /></> : label}
    />
  );
};

ToggleSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  hasChanges: PropTypes.bool,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  margin: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  className: '',
  label: null,
  labelPosition: 'start',
  hasChanges: false,
  disabled: false,
  required: false,
  compact: false,
  margin: 'normal',
};

export default withStyles(styles)(ToggleSwitch);
