import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_PARALLAX } from './defaults';

const renderElementParallax = (options) => {
  const { parallax } = setDefaults(options, DEFAULTS_PARALLAX);
  return getRenderElement(parallax);
};

export default renderElementParallax;
