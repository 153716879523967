import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { StoryListBreadcrumb } from './StoryList';
import { fetchStory, disposeStory } from 'actions/story';

import Page from 'components/common/page/Page';
import PageContent from 'components/common/page/PageContent';
import PageToolbar from 'components/common/page/PageToolbar';
import PageBusyIndicator from 'components/common/page/PageBusyIndicator';

import StorySidebar from 'components/story/StorySidebar';
import StoryMain from 'components/story/StoryMain';
import StoryControlButtons from 'components/story/StoryControlButtons';

const StoryCreate = ({
  match: { params: { id, action } },
}) => {
  const { isBusy } = useSelector((state) => state.story);
  const dispatch = useDispatch();
  const breadcrumb = [
    ...StoryListBreadcrumb,
    {
      title: /edit|preview/.test(action) ? 'Edit Story' : 'New Story',
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(fetchStory(id));
    }
    return () => {
      dispatch(disposeStory());
    };
  }, [dispatch, id]);

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <StoryControlButtons />
        </PageToolbar>
      }
    >
      {isBusy && <PageBusyIndicator disableInteraction message={'Saving...'} />}
      <PageContent>
        <StoryMain />
      </PageContent>
      <StorySidebar />
    </Page>
  );
};

StoryCreate.propTypes = {
  match: PropTypes.object.isRequired,
};

export default StoryCreate;
