import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { componentSetProp } from '../../../actions/layout';
import { fetchTVPlaylist } from '../../../actions/media';

import Select from '../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../common/context/ContextPanel';
import { PLAYLIST_ID } from '../../layout/constants';

const LAYOUT = 'layout';

const PlayIncComponentSettings = (props) => {
  const {
    component,
    componentSetProp: onChange,
    publicationConfig,
    tvOptions,
    fetchTVPlaylist: onFetchOption,
  } = props;
  const {
    [PLAYLIST_ID]: playlist = '',
  } = component;
  const endpointUrl = `${publicationConfig.playInc.endpoint}/api/${publicationConfig.playInc.domain}/playlists`;

  useEffect(() => {
    const options = Array.isArray(tvOptions) ? tvOptions : Object.entries(tvOptions);
    if (options.length === 0) {
      onFetchOption(endpointUrl);
    }
  }, [tvOptions]);

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Video article Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              label={'Select playlist'}
              value={playlist || ''}
              emptyValue={'- Select playlist -'}
              onChange={(e) => {
                onChange(
                  component.id,
                  PLAYLIST_ID,
                  e.target.value,
                );
              }}
              items={tvOptions}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              label={'Select layout'}
              value={component[LAYOUT] || ''}
              emptyValue={'- Select layout -'}
              onChange={(e) => {
                onChange(
                  component.id,
                  LAYOUT,
                  e.target.value,
                );
              }}
              items={[
                '3 articles', '4 articles', '8 articles', '2 articles', 'hero + 3 articles',
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'caption'}>Powered by Play Inc.</Typography>
          </Grid>
        </Grid>
      </ContextPanelDetails>
    </ContextPanel>
  );
};

PlayIncComponentSettings.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  fetchTVPlaylist: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object,
  tvOptions: PropTypes.array,
};

PlayIncComponentSettings.defaultProps = {
  publicationConfig: {},
  tvOptions: [],
};

export default connect(
  ({
    frame: { selectedPublication: { publicationConfig } },
    layout: { tvOptions },
  }) => ({ publicationConfig, tvOptions }),
  { componentSetProp, fetchTVPlaylist },
)(PlayIncComponentSettings);
