import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { arrayMove } from 'react-sortable-hoc';

import Grid from '@material-ui/core/Grid';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import RemoveIcon from '@material-ui/icons/DeleteOutlined';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { searchStories } from '../../../../../../../actions/story';

import AutocompleteDialog from '../../../../../../dialog/autocomplete/AutocompleteDialog';
import EmbedWrapper from '../../../../common/EmbedWrapper';
import ToggleSwitch from '../../../../../../ui/ToggleSwitch';
import {
  ArrowSortableList,
  ArrowSortableListItem,
  ArrowSortableDown,
  ArrowSortableUp,
} from '../../../../../../ui/ArrowSortable';
import IconButton from '../../../../../../ui/buttons/IconButton';
import Button from '../../../../../../ui/buttons/Button';

import { PROP_STORIES, PROP_BUTTON_DISPLAY } from '../defaults';

const VisualStoryElement = (props) => {
  const {
    stories, attributes, children, element, className,
    searchStories: onSearch,
  } = props;
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper title={'Visual Story'} Icon={DynamicFeed} contentEditable={false} element={element}>
        <Grid container spacing={1}>
          {element[PROP_STORIES] && element[PROP_STORIES].length > 1 && <Grid item xs={12}>
            <ToggleSwitch
              label={'Display as buttons'}
              value={!!element[PROP_BUTTON_DISPLAY]}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [PROP_BUTTON_DISPLAY]: e.target.checked }, { at: path });
              }}
            />
          </Grid>}
          {element[PROP_STORIES] && <Grid item xs={12}>
            <ArrowSortableList>
              {element[PROP_STORIES].map((story, index) => (<ArrowSortableListItem index={index} key={`story-${story.id}`} indexAvatar>
                <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary={story.label} />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => {
                      const path = ReactEditor.findPath(editor, element);
                      Transforms.setNodes(
                        editor,
                        {
                          [PROP_STORIES]: element[PROP_STORIES]
                            .filter(({ id }) => id !== story.id),
                        },
                        { at: path },
                      );
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                  {index < element[PROP_STORIES].length - 1 && <ArrowSortableDown
                    onClick={() => {
                      const path = ReactEditor.findPath(editor, element);
                      Transforms.setNodes(
                        editor,
                        { [PROP_STORIES]: arrayMove(element[PROP_STORIES], index, index + 1) },
                        { at: path },
                      );
                    }}
                  />}
                  {index > 0 && <ArrowSortableUp
                    onClick={() => {
                      const path = ReactEditor.findPath(editor, element);
                      Transforms.setNodes(
                        editor,
                        { [PROP_STORIES]: arrayMove(element[PROP_STORIES], index, index - 1) },
                        { at: path },
                      );
                    }}
                  />}
                </ListItemSecondaryAction>
              </ArrowSortableListItem>))}
            </ArrowSortableList>
          </Grid>}
          <Grid item container justify={'center'}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<AddCircleOutlineIcon />}
            >Add story</Button>
          </Grid>
        </Grid>
        {open && <AutocompleteDialog
          handleClose={() => {
            setOpen(false);
          }}
          label={'stories'}
          open={open}
          items={stories.map(({ name, id }) => ({
            label: name,
            id,
          }))}
          multiple
          onChange={onSearch}
          onSelect={(selected) => {
            const newStories = element[PROP_STORIES] ? [...element[PROP_STORIES]] : [];
            // eslint-disable-next-line array-callback-return
            selected.map((story) => {
              if (!newStories.find(({ id: insertedId }) => insertedId === story.id)) {
                newStories.push(story);
              }
            });
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_STORIES]: newStories }, { at: path });
          }}
        />}
      </EmbedWrapper>
      {children}
    </div>
  );
};

VisualStoryElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  searchStories: PropTypes.func.isRequired,
  stories: PropTypes.array,
};

VisualStoryElement.defaultProps = {
  stories: [],
};

export default connect(
  ({ autocomplete: { stories } }) => ({ stories }),
  {
    searchStories,
  },
)(VisualStoryElement);
