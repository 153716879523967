import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { componentSetProp, layoutComponentOnImageSelect } from '../../../../actions/layout';
import { COMMERCIAL_BADGE_IMAGE, COMMERCIAL_BADGE_LINK, COMMERCIAL_BADGE_TERM } from '../../constants';

import { SPONSOR_PREFIX } from '../../../../constants/vocab';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

import TextField from '../../../ui/TextField';
import VocabSelectorButton from '../../../ui/components/VocabSelectorButton';
import ImageSelector from '../../../ui/media/ImageSelector';

const CommercialBadgeOptions = (props) => {
  const {
    component,
    componentSetProp: setProp,
    layoutComponentOnImageSelect: onImageSelect,
  } = props;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Commercial Badge</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <ImageSelector
          selectImage={data => onImageSelect([
            component.id,
            COMMERCIAL_BADGE_IMAGE,
            {
              type: 'image',
              data,
            },
          ])}
          onRemove={() => setProp(
            component.id,
            COMMERCIAL_BADGE_IMAGE,
            {
              type: 'image',
              data: null,
            },
          )}
          label={'Add image'}
          image={component[COMMERCIAL_BADGE_IMAGE]?.data}
          showEditDialog
        />
        <TextField
          label={'Badge Link'}
          value={component[COMMERCIAL_BADGE_LINK] || ''}
          onChange={e => setProp(component.id, COMMERCIAL_BADGE_LINK, e.target.value)}
        />
        <VocabSelectorButton
          label={'Badge Term'}
          vocab={SPONSOR_PREFIX}
          onChange={vocab => setProp(component.id, COMMERCIAL_BADGE_TERM, vocab)}
        />
        {component[COMMERCIAL_BADGE_TERM] && <Typography component={'h3'}>{component[COMMERCIAL_BADGE_TERM].name}</Typography>}
      </ContextPanelDetails>
    </ContextPanel>
  );
};

CommercialBadgeOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  layoutComponentOnImageSelect: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp, layoutComponentOnImageSelect },
)(CommercialBadgeOptions);
