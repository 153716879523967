import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { logout } from '../actions/login';
import Loader from '../components/ui/Loader';

const Logout = ({ logout }) => {
  useEffect(()=> {
    logout();
  }, []);

  return <Loader />;
};


const mapDispatchToProps = { logout, push };

export default connect(null, mapDispatchToProps)(Logout);

Logout.propTypes = {
  logout: PropTypes.func.isRequired,
};
