import ParallaxElement from './components/parallaxElement';

export const ELEMENT_PARALLAX = 'parallax';
export const PROP_MARKUP = 'markup';
export const PROP_IMAGE = 'image';

export const DEFAULTS_PARALLAX = {
  parallax: {
    component: ParallaxElement,
    type: ELEMENT_PARALLAX,
    rootProps: {
      className: 'slate-parallax',
    },
  },
};
