export const PRODUCT_LIST_FETCH = 'PRODUCT_LIST_FETCH';
export const PRODUCT_LIST_DISPOSE = 'PRODUCT_LIST_DISPOSE';
export const PRODUCT_LIST_FETCH_SUCCESS = 'PRODUCT_LIST_FETCH_SUCCESS';
export const PRODUCT_LIST_FETCH_REJECTED = 'PRODUCT_LIST_FETCH_REJECTED';

export const PRODUCT_FETCH = 'PRODUCT_FETCH';
export const PRODUCT_DISPOSE = 'PRODUCT_DISPOSE';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_REJECTED = 'PRODUCT_FETCH_REJECTED';

export const PRODUCT_COMPARISON_REQUEST = 'PRODUCT_COMPARISON_REQUEST';
export const PRODUCT_COMPARISON_SUCCESS = 'PRODUCT_COMPARISON_SUCCESS';
export const PRODUCT_COMPARISON_DISPOSE = 'PRODUCT_COMPARISON_DISPOSE';
export const PRODUCT_COMPARISON_REJECTED = 'PRODUCT_COMPARISON_REJECTED';

export const PRODUCT_AFFILIATE_LINK_CHECK = 'PRODUCT_AFFILIATE_LINK_CHECK';
export const PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS = 'PRODUCT_AFFILIATE_LINK_CHECK_SUCCESS';
export const PRODUCT_AFFILIATE_LINK_CHECK_REJECTED = 'PRODUCT_AFFILIATE_LINK_CHECK_REJECTED';
export const PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE = 'PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE';
export const PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER = 'PRODUCT_AFFILIATE_LINK_CHECK_FROM_SERVER';

export const PRODUCT_SAVE = 'PRODUCT_SAVE';
export const PRODUCT_SAVE_PAYLOAD = 'PRODUCT_SAVE_PAYLOAD';
export const PRODUCT_SAVE_READY = 'PRODUCT_SAVE_READY';
export const PRODUCT_SAVE_SUCCESS = 'PRODUCT_SAVE_SUCCESS';
export const PRODUCT_SAVE_REJECTED = 'PRODUCT_SAVE_REJECTED';
