import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Dialog from './common/Dialog';

import {
  editionListDraftLoad,
} from '../../actions/edition';

import { EDITION_DAILY, EDITION_SPECIAL } from '../../constants/edition/edition';
import AutoCompleteGeneric from '../autocomplete/AutoComplete';
import RequiredIndicator from '../ui/RequiredIndicator';
import Tag from "../ui/Tag";

const style = theme => ({
  fieldRoot: {
    display: 'flex',
    position: 'relative',
    marginTop: 45,
  },
  formControl: {
    margin: theme.spacing(0, 0, 2),
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
  },
  overflowPaper: {
    overflow: 'visible',
  },
});


const MoveEditionArticle = (props) => {
  const {
    classes,
    editionListDraftLoad: onDraftFetch,
    onSubmit,
    editionDraftList,
    ...rest
  } = props;

  const [dailyQuery, setDailyQuery] = useState('');
  const [specialQuery, setSpecialQuery] = useState('');

  useEffect(() => {
    if (dailyQuery) {
      onDraftFetch({
        type: EDITION_DAILY,
        q: dailyQuery,
      });
    }
  }, [dailyQuery]);

  useEffect(() => {
    if (specialQuery) {
      onDraftFetch({
        type: EDITION_SPECIAL,
        q: specialQuery,
      });
    }
  }, [specialQuery]);

  const [dailyEdition, setDailyEdition] = useState(null);
  const [specialEdition, setSpecialEdition] = useState(null);

  const dailyOptions = editionDraftList[EDITION_DAILY] || [];
  const specialOptions = editionDraftList[EDITION_SPECIAL] || [];

  const onSubmitForm = () => {
    const id = dailyEdition?.id || specialEdition?.id;
    if (!id) {
      return null;
    }

    return onSubmit({
      containerId: id,
    });
  };

  return (<Dialog title={'Export to edition'} {...rest}>
    <Grid container direction={'column'}>
      {!specialEdition && <Grid item className={classes.fieldRoot}>
        <FormControl fullWidth className={classes.formControl}>
          <Typography component={'p'} variant={'body2'} className={classes.label}>Daily edition<RequiredIndicator /></Typography>
          {!dailyEdition && <AutoCompleteGeneric
            placeholder={'Search by edition name'}
            items={dailyOptions.map(({ data: { title, id } }) => ({ label: title, id }))}
            onChange={text => setDailyQuery(text)}
            onSelect={(selection) => {
              setDailyEdition(selection);
              setSpecialEdition(null);
            }}
          />}
        </FormControl>
      </Grid>}
      {dailyEdition && <Grid container>
        <Tag label={dailyEdition.label} onRemove={() => setDailyEdition(null)} />
      </Grid>}
      {!(specialEdition || dailyEdition) && <Grid container justify={'center'}>
        <Grid item>
          <Typography component={'span'} variant={'body2'}>OR</Typography>
        </Grid>
      </Grid>}
      {!dailyEdition && <Grid item>
        <FormControl fullWidth className={classes.formControl}>
          <Typography component={'p'} variant={'body2'} className={classes.label}>Special edition<RequiredIndicator /></Typography>
          {!specialEdition && <AutoCompleteGeneric
            placeholder={'Search by edition name'}
            items={specialOptions.map(({ data: { title, id } }) => ({ label: title, id }))}
            onChange={text => setSpecialQuery(text)}
            onSelect={(selection) => {
              setSpecialEdition(selection);
              setDailyEdition(null);
            }}
          />}
        </FormControl>
      </Grid>}
      {specialEdition && <Grid container>
        <Tag label={specialEdition.label} onRemove={() => setSpecialEdition(null)} />
      </Grid>}
      <Grid item className={classes.buttonRoot}>
        <Grid container justify={'flex-end'}>
          <Grid item>
            <Button disabled={!(specialEdition || dailyEdition)} variant={'contained'} color={'primary'} onClick={onSubmitForm}>Move</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Dialog>);
};

MoveEditionArticle.defaultProps = {
  editionDraftList: {},
};

MoveEditionArticle.propTypes = {
  classes: PropTypes.object.isRequired,
  editionListDraftLoad: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editionDraftList: PropTypes.object,
};

export default withStyles(style)(connect(
  ({
    edition: { editionDraftList },
  }) => ({ editionDraftList }),
  { editionListDraftLoad },
)(MoveEditionArticle));
