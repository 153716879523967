import React from 'react';

const ProductArticleGfx = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 72" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M56.889 7.2H42.027C40.533 3.024 36.622 0 32 0c-4.622 0-8.533 3.024-10.027 7.2H7.111C3.2 7.2 0 10.44 0 14.4v50.4C0 68.76 3.2 72 7.111 72H56.89C60.8 72 64 68.76 64 64.8V14.4c0-3.96-3.2-7.2-7.111-7.2M32 7.2c1.956 0 3.556 1.62 3.556 3.6s-1.6 3.6-3.556 3.6-3.556-1.62-3.556-3.6 1.6-3.6 3.556-3.6M14.222 21.6h35.556v-7.2h7.11v50.4H7.112V14.4h7.111v7.2m3.556 18V28.8h-3.556v-3.6h7.111v14.4h-3.555m4.444 7.2c1.458 0 2.667 1.224 2.667 2.7 0 .72-.285 1.404-.747 1.872L18.204 57.6h6.685v3.6H14.222v-3.312l7.111-7.488h-7.11v-3.6h8" fill="#2196F3" />
      <path fill="#E7792B" d="M32 57.6v-7.2h17.778v7.2H32M32 36v-7.2h17.778V36H32" />
    </g>
  </svg>
);

export default ProductArticleGfx;
