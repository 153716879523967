import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../FieldLockField';
import Select from '../../ui/Select';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../selectors/fieldLock/fieldLock';
import { setLocalProp } from '../../../actions/dataState';

const FieldLockSelect = ({
  value,
  label,
  lockValue,
  field,
  hasChanges,
  disabled,
  setLocalProp: setProp,
  ...rest
}) => {
  const propChain = Array.isArray(field) ? [...field] : [field];
  const prop = propChain.pop();
  return (
    <FieldLockField field={field} lockValue={lockValue}>
      <Select
        margin={'normal'}
        hasChanges={hasChanges}
        label={label}
        value={value || ''}
        onChange={e => setProp(prop, e.target.value, propChain)}
        disabled={disabled}
        {...rest}
      />
    </FieldLockField>
  );
};

FieldLockSelect.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  setLocalProp: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLockSelect.defaultProps = {
  value: '',
  lockValue: null,
  hasChanges: false,
  disabled: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    value: getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(FieldLockSelect);
