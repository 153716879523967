import { mergeDeepRight } from 'rambdax';

/**
 * remove props from the state and return the new state
 *
 * @param   {object} state  The current state
 * @param   {array} props   Props to remove from the state object
 * @return  {object}        The new state
 */
export const removePropsFromState = (state, props) => {
  const newState = { ...state };
  if (Array.isArray(props)) {
    props
      .filter(prop => typeof prop === 'string')
      .forEach(prop => delete newState[prop]);
  } else if (typeof props === 'string') {
    delete newState[props];
  }
  return newState;
};

/**
 * Set a value state of a prop(s), arrays of props are recursively processed
 * to add the value to the final prop in the object chain
 *
 * @param   {array|string} props  prop or chain of props to set
 * @param   {*} value             value to set, can be anything
 * @param   {object} state        The current state or object state when recursive
 * @return  {object}              the new state
 */
export const removeFromState = (props, state) => {
  const propChain = Array.isArray(props) ? [...props] : [props];
  const prop = propChain.shift();
  if (Array.isArray(propChain) && propChain.length > 0) {
    const propState = state[prop] || {};
    return {
      ...state,
      [prop]: removeFromState(propChain, propState),
    };
  }
  const newState = { ...state };
  delete newState[props];
  return newState;
};

/**
 * Set a value state of a prop(s), arrays of props are recursively processed
 * to add the value to the final prop in the object chain
 *
 * @param   {array|string} props  prop or chain of props to set
 * @param   {*} value             value to set, can be anything
 * @param   {object} state        The current state or object state when recursive
 * @return  {object}              the new state
 */
export const setToState = (props, value, state) => {
  const propChain = Array.isArray(props) ? [...props] : [props];
  const prop = propChain.shift();
  const propState = state[prop] || {};
  return {
    ...state,
    [prop]: !Array.isArray(propChain) || propChain.length > 0
      ? setToState(propChain, value, propState)
      : value,
  };
};

/**
 * Get a value from the state, recursively with a chain of props
 *
 * @param   {array|string} props  prop or chain of props to get
 * @param   {object} state        The current state
 * @return  {*}                   value or 'undefined'
 */
export const getFromState = (props, state) => {
  const propChain = Array.isArray(props) ? [...props] : [props];
  const prop = propChain.shift();
  const propState = state[prop] || {};
  return !Array.isArray(propChain) || propChain.length > 0
    ? getFromState(propChain, propState)
    : state[prop];
};

/**
 * Gets the current state of a property from the states, external > local > server
 *
 * @param   {array|string} props    prop or chain of props to get
 * @param   {object} localState     The local state
 * @param   {object} serverState    The server state
 * @param   {object} externalState  The external state, optional
 * @return  {*|null}                value or null
 */
export const getFromStates = (props, localState, serverState, externalState = null) =>
  (externalState && getFromState(Array.isArray(props) ? [...props] : [props], externalState)) ||
  getFromState(Array.isArray(props) ? [...props] : [props], localState) ||
  getFromState(Array.isArray(props) ? [...props] : [props], serverState) ||
  null;

/**
 * Gets the canonical data state deep merging the 3 source states, external > local > server
 *
 * @param   {object} localState     The local state
 * @param   {object} serverState    The server state
 * @param   {object} externalState  The external state
 * @return  {object}                The canonical state
 */
export const getState = (localState, serverState, externalState = {}) =>
  mergeDeepRight(
    serverState || {},
    mergeDeepRight(
      localState || {},
      externalState || {},
    ),
  );

/**
 * Gets the canonical state of a property from the data states, external > local > server
 *
 * @param   {array|string} props    prop or chain of props to get
 * @param   {object} localState     The local state
 * @param   {object} serverState    The server state
 * @param   {object} externalState  The external state
 * @return  {*}                     value or 'undefined'
 */
export const getPropState = (props, localState, serverState, externalState = null) => {
  const state = getState(localState, serverState, externalState);
  return getFromState(props, state);
};

/**
 * Builds a nested value object from a array of properties
 *
 * @param   {array} propChain Chain of props
 * @param   {*} value         value
 * @return  {object}
 */
const buildObjectFromChain = (propChain, value) => {
  const prop = propChain.shift();
  if (propChain.length > 0) {
    return {
      [prop]: buildObjectFromChain(propChain, value),
    };
  }
  return {
    [prop]: value,
  };
};

/**
 * Gets the build state object for local and external from retrieved dynamoDB data
 *
 * @param   {array}   items   Items from dynamoDB query response
 * @param   {number}  uid     Current user id
 * @return  {{externalState: {}, localState: {}}}
 */
export const buildStatesFromDynamoDB = (items, uid) => {
  let localState = {};
  let externalState = {};
  items.forEach(({ payload = {}, fieldName, userData }) => {
    if (userData.uid === uid) {
      localState = mergeDeepRight(localState, buildObjectFromChain(fieldName.split('_'), payload));
    } else {
      externalState = mergeDeepRight(externalState, buildObjectFromChain(fieldName.split('_'), payload));
    }
  });
  return { localState, externalState };
};
