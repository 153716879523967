import { setDefaults } from '@udecode/slate-plugins';
import deserializeImage from './deserializeImage';
import renderElementImage from './renderElementImage';
import { DEFAULTS_IMAGE } from './defaults';

const ImagePlugin = (options) => {
  const { image } = setDefaults(options, DEFAULTS_IMAGE);
  return ({
    renderElement: renderElementImage(options),
    deserialize: deserializeImage(options),
    voidTypes: [image.type],
  });
};

export default ImagePlugin;
