import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import {
  FIELD_IS_HIDDEN_FROM_LAYOUT,
  FIELD_COMMERCIAL_CAMPAIGN,
  FIELD_SHOW_SPONSORED,
} from 'constants/article/articleFields';
import { DOMAIN_ES, DOMAIN_INDY } from 'constants/domainConfig';
import { CONFIG_COMMERCIAL_CAMPAIGNS } from 'constants/config/config';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from 'components/common/context/ContextPanel';
import FieldLockToggle from 'components/fieldLock/form/FieldLockToggle';
import FieldLockSelectAutocomplete from 'components/fieldLock/form/FieldLockSelectAutocomplete';
import ArticleCommercialBadge from './fields/ArticleCommercialBadge';

const CommercialAndSyndication = ({ domain, campaigns }) => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Commercial & Syndication</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails variant={'full'}>
      <FieldLockToggle field={FIELD_IS_HIDDEN_FROM_LAYOUT} label={'Hide from layout pages'} />
      <FieldLockToggle field={FIELD_SHOW_SPONSORED} label={'Show sponsored capsule'} />
      <Grid container spacing={2}>
        <ArticleCommercialBadge />
        {(domain === DOMAIN_ES || domain === DOMAIN_INDY) && <Grid item xs={12}>
          <FieldLockSelectAutocomplete
            field={FIELD_COMMERCIAL_CAMPAIGN}
            label={'Commercial Campaign'}
            options={campaigns}
          />
        </Grid>}
      </Grid>
    </ContextPanelDetails>
  </ContextPanel>
);

CommercialAndSyndication.propTypes = {
  domain: PropTypes.string.isRequired,
  campaigns: PropTypes.array,
};

CommercialAndSyndication.defaultProps = {
  campaigns: [],
};

export default connect(
  ({
    config: { [CONFIG_COMMERCIAL_CAMPAIGNS]: campaigns },
    frame: { selectedPublication: { domain } },
  }) => ({ domain, campaigns }),
  {},
)(CommercialAndSyndication);

