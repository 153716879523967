import React from 'react';
import PropTypes from 'prop-types';

import TableToolbar, { VARIANT_DENSE } from '../../ui/table/TableToolbar';
import ListFilterInput from '../../ui/table/filters/ListFilterInput';

const VocabTableToolbar = ({ setFilterBy, activeVocab }) => (
  <TableToolbar variant={VARIANT_DENSE}>
    <ListFilterInput
      activeVocab={activeVocab}
      onChange={event => setFilterBy(event.target.value)}
    />
  </TableToolbar>
);

VocabTableToolbar.defaultProps = {
  activeVocab: null,
};

VocabTableToolbar.propTypes = {
  setFilterBy: PropTypes.func.isRequired,
  activeVocab: PropTypes.string,
};

export default VocabTableToolbar;
