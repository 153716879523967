import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_VIDEO } from './defaults';

const deserializeVideo = (options) => {
  const { video } = setDefaults(options, DEFAULTS_VIDEO);

  return {
    element: getNodeDeserializer({
      type: video.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: video.type,
          url,
        };
      },
      rules: [
        { className: video.rootProps.className },
      ],
      ...options?.video?.deserialize,
    }),
  };
};

export default deserializeVideo;
