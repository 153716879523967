import React from 'react';
import PropTypes from 'prop-types';

import { DND_TYPE_COMPONENT } from '../../../constants/components';
import { COMPONENT_DRAG_START, COMPONENT_DRAG_END } from '../../../constants/actionTypes';

import Draggable from '../Draggable';

const DraggableComponent = (props) => {
  const { children, component, className, disabled } = props;
  const DraggableEl = Draggable(DND_TYPE_COMPONENT);
  return (
    <DraggableEl
      onDragStart={COMPONENT_DRAG_START}
      onDragEnd={COMPONENT_DRAG_END}
      data={component}
      className={className}
      disabled={disabled}
    >{children}</DraggableEl>
  );
};

DraggableComponent.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

DraggableComponent.defaultProps = {
  disabled: false,
  className: null,
};

export default DraggableComponent;
