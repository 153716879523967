import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { VectorLink } from 'mdi-material-ui';

import EmbedWrapper from '../../../common/EmbedWrapper';
import {
  ArrowSortableList,
} from '../../../../../ui/ArrowSortable';
import Button from '../../../../../ui/buttons/Button';
import AutocompleteDialog from '../../../../../dialog/autocomplete/AutocompleteDialog';
import { searchArticle } from '../../../../../../actions/article';
import { HERO_VIDEO_TYPES } from '../../../../../../constants/media/media';
import AlignmentToggle from '../../../common/AlignmentToggle';

import { PROP_ARTICLES, PROP_ALIGN } from '../defaults';
import RelatedArticlesItem from './RelatedArticlesItem';

const getArticleImage = (media) => {
  if (!media || media.length === 0) return null;
  const image = media.find(({ type }) => type === 'image');
  return image ? image.data.url : null;
};

const getArticleMediaType = (media) => {
  if (!media || media.length === 0) return '';
  if (media[0].type && HERO_VIDEO_TYPES.includes(media[0].type)) {
    return 'video';
  }
  return media[0].type;
};

const RelatedArticlesElement = (props) => {
  const {
    articles, attributes, children, element, className,
    searchArticle: onSearch,
  } = props;
  const [open, setOpen] = useState(false);
  const editor = useSlateStatic();
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Related Articles'}
        Icon={VectorLink}
        contentEditable={false}
        element={element}
        toolbar={<AlignmentToggle
          align={element[PROP_ALIGN] || 'left'}
          disableCenter
          onSelect={(value) => {
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_ALIGN]: value }, { at: path });
          }}
        />}
      >
        <Grid container spacing={1}>
          {element[PROP_ARTICLES] && <Grid item xs={12}>
            <ArrowSortableList>
              {element[PROP_ARTICLES].map((article, index) => (<RelatedArticlesItem
                key={`relate-${index}-${article.id}`}
                editor={editor}
                index={index}
                article={article}
                element={element}
              />))}
            </ArrowSortableList>
          </Grid>}
          <Grid item container justify={'center'}>
            <Button
              onClick={() => {
                setOpen(true);
              }}
              startIcon={<AddCircleOutlineIcon />}
            >Add article</Button>
          </Grid>
        </Grid>
        {open && <AutocompleteDialog
          handleClose={() => {
            setOpen(false);
          }}
          label={'articles'}
          open={open}
          items={articles}
          multiple
          onChange={onSearch}
          onSelect={(selected) => {
            const newArticles = element[PROP_ARTICLES] ? [...element[PROP_ARTICLES]] : [];
            // eslint-disable-next-line array-callback-return
            selected.map((article) => {
              if (!newArticles.find(({ id: insertedId }) => insertedId === article.target_id)) {
                newArticles.push({
                  id: article.target_id,
                  title: article.label,
                  sponsored: article.sponsored,
                  href: article.url, // handle this API and depricated here
                  image: getArticleImage(article.hero), // handle this API and depricated here
                  media_type: getArticleMediaType(article.hero), // handle this API and depricated here
                });
              }
            });
            const path = ReactEditor.findPath(editor, element);
            Transforms.setNodes(editor, { [PROP_ARTICLES]: newArticles }, { at: path });
          }}
        />}
      </EmbedWrapper>
      {children}
    </div>
  );
};

RelatedArticlesElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  searchArticle: PropTypes.func.isRequired,
  articles: PropTypes.array,
};

RelatedArticlesElement.defaultProps = {
  articles: [],
};

export default connect(
  ({ autocomplete: { articles } }) => ({ articles }),
  { searchArticle },
)(RelatedArticlesElement);
