import { mapTo, switchMap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { from } from 'rxjs';
import { push } from 'connected-react-router';

import { perst } from '../../index';
import apiCatchError, { assignErrorPayload } from '../helper/notification';

import {
  AUTHENTICATION_REQUIRED,
  REDIRECT_TO_LOGIN,
  STATE_PURGED,
} from 'constants/actionTypes';
import { LOGOUT } from 'constants/actionTypes/login';

export const onLogout = (action$, state$) => action$.pipe(
  ofType(LOGOUT),
  withLatestFrom(state$),
  switchMap(([, { login: { user: { csrf_token: csrfToken, logoutToken } } }]) => ajax.post('/api/logout', {
    csrf_token: csrfToken,
    logout_token: logoutToken,
  }).pipe(
    mapTo({
      type: REDIRECT_TO_LOGIN,
    }),
    apiCatchError(REDIRECT_TO_LOGIN),
  )),
);

export const logoutOnNoAccessFound = action$ => action$.pipe(
  ofType(AUTHENTICATION_REQUIRED),
  mapTo({ type: REDIRECT_TO_LOGIN }),
);

export const notificationOnNoAccessFound = action$ => action$.pipe(
  ofType(AUTHENTICATION_REQUIRED),
  mergeMap(() => action$.pipe(
    ofType(REDIRECT_TO_LOGIN),
    mapTo(assignErrorPayload('Your session has expired, please login again.')),
  )),
);

export const flushStateAfterLogout = action$ => action$.pipe(
  ofType(REDIRECT_TO_LOGIN),
  mergeMap(() => from(perst.purge())),
  mapTo({ type: STATE_PURGED }),
);

export const redirectAfterLogout = action$ => action$.pipe(
  ofType(REDIRECT_TO_LOGIN),
  mapTo(push('/login')),
);
