import {
  NewsBanner,
  HeroArticle,
  HeroPlusDMPU,
  HeroPlus2Articles,
  HeroPlus3Articles,
  HeroPlus4Articles,
  HeroPlus6Articles,
  HeroPlus2ArticlesNewsletter,
  ArticleX2,
  CommentHighlight,
  HeroPlus6ArticlesWithImages,
  HeroPlus4ArticlesWithImages,
  Banner,
} from './components';

export const DOMAIN_INDY_ES = 'independentespanol.com';
export const DOMAIN_INDY = 'independent.co.uk';
export const DOMAIN_ES = 'standard.co.uk';

export const DOMAINS_JPI = [
  'scotsman.com',
  'thestar.co.uk',
  'sheffieldtelegraph.co.uk',
  'doncasterfreepress.co.uk',
  'southyorkshiretimes.co.uk',
  'thornegazette.co.uk',
  'berwick-advertiser.co.uk',
  'berwickshirenews.co.uk',
  'carricktoday.co.uk',
  'gallowaygazette.co.uk',
  'hartlepoolmail.co.uk',
  'hawick-news.co.uk',
  'morpethherald.co.uk',
  'newsguardian.co.uk',
  'newspostleader.co.uk',
  'northumberlandgazette.co.uk',
  'thesouthernreporter.co.uk',
  'sunderlandecho.com',
  'bexhillobserver.net',
  'bognor.co.uk',
  'chichester.co.uk',
  'crawleyobserver.co.uk',
  'eastbourneherald.co.uk',
  'hastingsobserver.co.uk',
  'littlehamptongazette.co.uk',
  'midhurstandpetworth.co.uk',
  'midsussextimes.co.uk',
  'ryeandbattleobserver.co.uk',
  'shorehamherald.co.uk',
  'sussexexpress.co.uk',
  'westsussextoday.co.uk',
  'worthingherald.co.uk',
  'wscountytimes.co.uk',
  'portsmouth.co.uk',
  'dewsburyreporter.co.uk',
  'batleynews.co.uk',
  'spenboroughguardian.co.uk',
  'mirfieldreporter.co.uk',
  'pontefractandcastlefordexpress.co.uk',
  'hemsworthandsouthelmsallexpress.co.uk',
  'thescarboroughnews.co.uk',
  'fileymercury.co.uk',
  'wakefieldexpress.co.uk',
  'yorkshirepost.co.uk',
  'burnleyexpress.net',
  'chorley-guardian.co.uk',
  'leylandguardian.co.uk',
  'halifaxcourier.co.uk',
  'lep.co.uk',
  'lancasterguardian.co.uk',
  'whitbygazette.co.uk',
  'wigantoday.net',
  'sthelensreporter.co.uk',
  'leighobserver.co.uk',
  'yorkshireeveningpost.co.uk',
  'harrogateadvertiser.co.uk',
  'wetherbynews.co.uk',
  'ripongazette.co.uk',
  'bridlingtonfreepress.co.uk',
  'buxtonadvertiser.co.uk',
  'derbyshiretimes.co.uk',
  'ripleyandheanornews.co.uk',
  'ilkestonadvertiser.co.uk',
  'belpernews.co.uk',
  'chad.co.uk',
  'retfordtoday.co.uk',
  'worksopguardian.co.uk',
  'banburyguardian.co.uk',
  'bucksherald.co.uk',
  'edinburghnews.scotsman.com',
  'falkirkherald.co.uk',
  'fifetoday.co.uk',
  'hemeltoday.co.uk',
  'brightonandhoveindependent.co.uk',
  'leamingtoncourier.co.uk',
  'bedfordtoday.co.uk',
  'lutontoday.co.uk',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'rugbyadvertiser.co.uk',
  'skegnessstandard.co.uk',
  'bostonstandard.co.uk',
  'harboroughmail.co.uk',
  'blackpoolgazette.co.uk',
  'miltonkeynes.co.uk',
  'derryjournal.com',
  'newsletter.co.uk',
  'portadowntimes.co.uk',
  'peterboroughtoday.co.uk',
  'nationalworld.com',
  'lincolnshireworld.com',
  'farminglife.com',
  'ballymoneytimes.co.uk',
  'banbridgeleader.co.uk',
  'dromoreleader.co.uk',
  'carrickfergustimes.co.uk',
  'larnetimes.co.uk',
  'colerainetimes.co.uk',
  'ballymenatimes.com',
  'tyronetimes.co.uk',
  'londonderrysentinel.co.uk',
  'midulstermail.co.uk',
  'newtownabbeytoday.co.uk',
  'lurganmail.co.uk',
  'lisburntoday.co.uk',
  'berkhamstedtoday.co.uk',
  'dunstabletoday.co.uk',
  'buckinghamtoday.co.uk',
  'biggleswadetoday.co.uk',
  'kenilworthweeklynews.co.uk',
  'daventryexpress.co.uk',
  'horncastlenews.co.uk',
  'tringtoday.co.uk',
  'thametoday.co.uk',
  'warwickcourier.co.uk',
  'leightonbuzzardonline.co.uk',
  'louthleader.co.uk',
  'marketrasenmail.co.uk',
  'sleafordstandard.co.uk',
  'meltontimes.co.uk',
  'hucknalldispatch.co.uk',
  'matlockmercury.co.uk',
  'eastwoodadvertiser.co.uk',
  'gainsboroughstandard.co.uk',
  'fleetwoodtoday.co.uk',
  'longridgenews.co.uk',
  'garstangcourier.co.uk',
  'lythamstannesexpress.co.uk',
  'pendletoday.co.uk',
  'clitheroeadvertiser.co.uk',
  'thevisitor.co.uk',
  'mearnsleader.co.uk',
  'donsidepiper.co.uk',
  'fraserburghherald.co.uk',
  'deesidepiper.co.uk',
  'carlukegazette.co.uk',
  'cumbernauld-news.co.uk',
  'ellontimes.co.uk',
  'buchanobserver.co.uk',
  'kincardineshireobserver.co.uk',
  'inverurieherald.co.uk',
  'milngavieherald.co.uk',
  'linlithgowgazette.co.uk',
  'kirkintilloch-herald.co.uk',
  'stornowaygazette.co.uk',
  'glasgowsouthandeastwoodextra.co.uk',
  'motherwelltimes.co.uk',
  'midlothianadvertiser.co.uk',
  'buteman.co.uk',
  'todmordennews.co.uk',
  'brighouseecho.co.uk',
  'morleyobserver.co.uk',
  'pocklingtonpost.co.uk',
  'hebdenbridgetimes.co.uk',
  'northyorkshirenews.com',
  'londonworld.com',
  'walesworld.com',
  'bristolworld.com',
  'manchesterworld.uk',
  'birminghamworld.uk',
  'liverpoolworld.uk',
  'glasgowworld.com',
  'warwickshireworld.com',
  'newcastleworld.com',
  'northernirelandworld.com',
];

export const INDY_RELATED_LINK_COUNT = {
  [NewsBanner]: [4],
  [HeroPlusDMPU]: [4],
  [HeroPlus2Articles]: [2],
  [ArticleX2]: [2, 2],
  [HeroPlus3Articles]: [4, 1],
  [HeroPlus6Articles]: [1],
  [HeroPlus2ArticlesNewsletter]: [4],
};
export const ES_RELATED_LINK_COUNT = {
  [HeroArticle]: [3],
  [HeroPlus6ArticlesWithImages]: [3],
  [HeroPlus4ArticlesWithImages]: [3],
  [HeroPlus2Articles]: [3],
  [Banner]: [3]
};
export const ES_LEAD_REQUIRED = {
  [HeroArticle]: [true],
  [HeroPlusDMPU]: [true],
  [ArticleX2]: [true, true],
  [HeroPlus2Articles]: [true],
  [HeroPlus3Articles]: [true],
  [HeroPlus4Articles]: [true],
  [CommentHighlight]: [true],
};
