import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { noop } from 'rxjs';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from './Table';
import DragHandleBase from '../DragHandle';
import { getClassName } from '../../../utils/propHelper';
import { scrollToView } from '../../../utils/domHelper';

export const SortableBody = SortableContainer(props => (<TableBody {...props} />));
export const SortableRow = SortableElement(props => <TableRow {...props} />);

const DragHandle = SortableHandle(DragHandleBase);

const styles = theme => ({
  row: {
    transform: 'scale(1)',
    '&:hover': {
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
    },
    '&.sortableHelper': {
      borderLeftColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.hover,
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      '& .MuiTableCell-body:last-child *': {
        visibility: 'visible',
      },
    },
  },
  dragging: {
    pointerEvents: 'none',
  },
  actionRow: {
    '& .MuiTableCell-body:nth-last-child(2) *': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& .MuiTableCell-body:nth-last-child(2) *': {
        visibility: 'visible',
      },
    },
  },
  handleRow: {
    padding: '0 !important',
    width: 36,
  },
  handle: {
    width: 20,
    height: '100%',
    position: 'absolute',
    top: 0,
    padding: 0,
    marginLeft: 16,
    marginBottom: '0 !important',
  },
});
const TableSortable = (props) => {
  const { classes, headers, rows, hasActions, onReorder, enableSorting, getContainer, selected, onClick, scrollTrigger } = props;
  const bodyElement = useRef();
  const firstRowEl = useRef();
  const lastRowEl = useRef();
  const getRowRef = index => {
    switch (true) {
      case index === 0:
        return firstRowEl;
      case index === rows.length - 1:
        return lastRowEl;
      default:
        return null;
    }
  };
  useEffect(() => {
    switch (scrollTrigger) {
      case 0:
        if (firstRowEl.current) {
          scrollToView(firstRowEl.current.node, 40, true);
        }
        break;
      case 1:
        if (lastRowEl.current) {
          scrollToView(lastRowEl.current.node, 40, true);
        }
        break;
      default:
        break;
    }
  }, [scrollTrigger]);
  return (
    <Table>
      {headers.length > 0 && <TableHead>
        <TableRow hasBorder>
          {headers.map(header => (<TableHeadCell key={header}>{header}</TableHeadCell>))}
          <TableHeadCell />
        </TableRow>
      </TableHead>}
      <SortableBody
        ref={bodyElement}
        className={classes.body}
        helperClass={'sortableHelper'}
        onSortStart={({ node }) => {
          const tds = document.getElementsByClassName('sortableHelper')[0].childNodes;
          node.childNodes.forEach(
            (child, idx) => tds[idx].style.width = `${child.offsetWidth}px`,
          );
          if (bodyElement.current) {
            bodyElement.current.container.classList.add(classes.dragging);
          }
        }}
        useDragHandle
        useWindowAsScrollContainer={!getContainer}
        getContainer={getContainer}
        onSortEnd={({ oldIndex, newIndex }) => {
          onReorder([oldIndex, newIndex]);
          if (bodyElement.current) {
            bodyElement.current.container.classList.remove(classes.dragging);
          }
        }}
        axis={'y'}
        distance={1}
      >
        {rows.map((row, i) => (<SortableRow
          className={getClassName({
            [classes.row]: true,
            [classes.actionRow]: hasActions,
            [classes.selectedRow]: selected === i,
          })}
          index={i}
          key={`row-${i}`}
          hasActions
          selected={selected === i}
          onClick={onClick ? e => onClick(e, i) : null}
          ref={getRowRef(i)}
        >
          {row.map((column, j) => (<TableCell narrow key={`cell-${i}-${j}`}>{column}</TableCell>))}
          <TableCell narrow classes={{ root: enableSorting ? classes.handleRow : '' }}>
            { enableSorting && <DragHandle className={classes.handle} /> }
          </TableCell>
        </SortableRow>))}
      </SortableBody>
    </Table>
  );
};

TableSortable.propTypes = {
  classes: PropTypes.object.isRequired,
  headers: PropTypes.array,
  rows: PropTypes.array.isRequired,
  hasActions: PropTypes.bool,
  onReorder: PropTypes.func,
  enableSorting: PropTypes.bool,
  selected: PropTypes.number,
  onClick: PropTypes.func,
  getContainer: PropTypes.func,
  scrollTrigger: PropTypes.number,
};

TableSortable.defaultProps = {
  headers: [],
  hasActions: false,
  onReorder: noop,
  enableSorting: true,
  selected: -1,
  scrollTrigger: -1,
  onClick: null,
  getContainer: null,
};

export default withStyles(styles)(TableSortable);
