import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_INSTAGRAM } from './defaults';

const renderElementInstagram = (options) => {
  const { instagram } = setDefaults(options, DEFAULTS_INSTAGRAM);
  return getRenderElement(instagram);
};

export default renderElementInstagram;
