import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Table, TableRow, TableBody, TableHead, TableCell, TableHeadCell } from '../ui/table/Table';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import Duration from './common/Duration';
import MediaRatio from './MediaRatio';
import Loader from '../ui/Loader';
import Button from '../ui/buttons/Button';
import Select from '../ui/Select';
import {searchVideoJWPlayer} from "../../actions/media";

const pageSizeOpts = [12, 24, 99];
const unknownSizeFromAPI = 1000000;

const style = theme => ({
  paginationRoot: {
    flexBasis: '100%',
  },
  table: {
    margin: theme.spacing(0, -2),
  },
});

const optionsDateRange = {
  '1D': 'Today',
  '2D': 'Last 2 days',
  '7D': 'Last 7 days',
  '30D': 'Last 30 days',
  all: 'Show all',
};

const getJWPlayerImage = ({ image = '', images = [] }) => {
  const imageSmall = images.find(({ width }) => width === 320);
  return imageSmall ? imageSmall.src : image;
};

const JWPlayerList = (props) => {
  const {
    classes,
    videoType,
    videoJWPlayerOnSearch: onSearch,
    jwplayer,
    selectedVideo,
    isJWPlayerSearching,
  } = props;
  const { list, total } = jwplayer;

  const [sizePerPage, setSizePerPage] = useState(pageSizeOpts[2]);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [recency, setRecency] = useState('1D');
  const [isSearchById, setSearchById] = useState(false);

  useEffect(() => {
    let params = {
      page,
      limit: sizePerPage,
      tags: '',
      query,
      isSearchById: false,
      recency,
      videoType,
    };

    if (isSearchById && query) {
      params = {
        videoType,
        page,
        limit: sizePerPage,
        tags: '',
        query,
        isSearchById,
        recency,
      };
    }
    onSearch(params);
  }, [sizePerPage, page, query, isSearchById, recency]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextSearchFilter
              placeholder={'Search'}
              defaultValue={query}
              onSubmit={(textQuery) => {
                setQuery(textQuery);
                setPage(1);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              className={classes.searchMode}
              control={
                <Checkbox
                  checked={isSearchById}
                  onChange={e => setSearchById(e.target.checked)}
                  color={'primary'}
                />
              }
              label={'Search by id'}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              value={recency}
              onChange={e => setRecency(e.target.value)}
              items={optionsDateRange}
              label={'Date range'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.table}>
          {isJWPlayerSearching && <Loader />}
          <Table>
            <TableHead>
              <TableRow hasBorder>
                {['Video', 'Title', 'Duration', ''].map(
                  label => (<TableHeadCell key={label}>{label}</TableHeadCell>),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length === 0 && !isJWPlayerSearching && <TableRow>
                <TableCell colSpan={4} align={'center'}>No videos found</TableCell>
              </TableRow>}
              {list.length > 0 && list.map(data => (
                <TableRow key={data.mediaid} hasActions>
                  <TableCell>
                    <MediaRatio>
                      <img
                        src={getJWPlayerImage(data)}
                        alt={data.title}
                      />
                    </MediaRatio>
                  </TableCell>
                  <TableCell>{data.title}</TableCell>
                  <TableCell>
                    <Typography noWrap><Duration duration={data.duration} /></Typography>
                  </TableCell>
                  <TableCell align={'right'}>
                    <Button
                      onClick={() => selectedVideo(data)}
                      variant={'contained'}
                    >Select</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {total > sizePerPage && <TablePagination
            classes={{ root: classes.paginationRoot }}
            rowsPerPageOptions={pageSizeOpts}
            component={'div'}
            count={total || unknownSizeFromAPI}
            rowsPerPage={sizePerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}

            onChangePage={(e, i) => setPage(i + 1)}
            onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
          />}
        </div>
      </Grid>
    </Grid>
  );
};

JWPlayerList.propTypes = {
  classes: PropTypes.object.isRequired,
  videoType: PropTypes.string,
  videoJWPlayerOnSearch: PropTypes.func.isRequired,
  selectedVideo: PropTypes.func.isRequired,
  jwplayer: PropTypes.object.isRequired,
  isJWPlayerSearching: PropTypes.bool.isRequired,
};

JWPlayerList.defaultProps = {
  videoType: null,
};

export default withStyles(style)(connect(
  ({ media: { jwplayer, isJWPlayerSearching } }) => ({ jwplayer, isJWPlayerSearching }),
  { videoJWPlayerOnSearch: searchVideoJWPlayer },
)(JWPlayerList));
