import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import FlowCard from '../layout/FlowCard';

const DashboardCard = withStyles(theme => ({
  root: {
    height: `calc(100% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(1),
  },
}))(FlowCard);

export const DashboardCardHeader = props => (
  <CardHeader
    titleTypographyProps={{ variant: 'h2', color: 'primary' }}
    {...props}
  />
);

export const DashboardCardContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(1, 2, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  noPadding: {
    margin: theme.spacing(0, -1, -1),
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}))(({ classes, className = '', noPadding, ...props }) => (
  <CardContent
    className={noPadding ? `${classes.noPadding} ${className}` : className}
    classes={{ root: classes.root }}
    {...props}
  />
));

export default DashboardCard;
