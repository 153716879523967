import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SectionTitle from '../../../common/SectionTitle';
import ProductForm from '../../../product/ProductForm';
import { FIELD_TITLE } from '../../../../constants/product/productFields';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(0, -3),
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.border.primary.main}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.border.primary.main}`,
    },
    background: theme.palette.background.hover,
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  image: {
    maxHeight: 250,
    width: '100%',
  },
  actions: {
    textAlign: 'right',
    visibility: 'hidden',
    '.product:hover &': {
      visibility: 'visible',
    },
  },
});

const ProductEdit = (props) => {
  const {
    classes,
    index,
    setProp,
    product,
    actions,
  } = props;
  const {
    [FIELD_TITLE]: title = '',
  } = product;
  return (
    <div className={`${classes.wrapper} product`}>
      <Grid container spacing={2}>
        <Grid item xs={actions ? 10 : 12}><SectionTitle>{index + 1} - {title || 'Add new product'}</SectionTitle></Grid>
        {actions && <Grid item xs={2} className={classes.actions}>
          {actions}
        </Grid>}
        <Grid item xs={12}>
          <ProductForm inline product={product} setProp={setProp} />
        </Grid>
      </Grid>
    </div>
  );
};

ProductEdit.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  setProp: PropTypes.func.isRequired,
  product: PropTypes.object,
  actions: PropTypes.node,
};

ProductEdit.defaultProps = {
  product: {},
  actions: null,
};

export default withStyles(styles)(ProductEdit);
