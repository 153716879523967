import React from 'react';
import PropTypes from 'prop-types';
import {
  ArrangeBringForward,
  BookmarkMinusOutline,
  Facebook,
  FormatQuoteClose,
  ApplicationOutline,
  Instagram,
  PlayOutline,
  Table,
  TableBorder,
  TableColumnPlusAfter,
  TableColumnRemove,
  TableRemove,
  TableRowPlusAfter,
  TableRowRemove,
  Twitter,
  VectorLink,
  CurrencyGbp,
  AccountCash,
  ChatQuestion
} from 'mdi-material-ui';
import Remove from '@material-ui/icons/Remove';
import ParallaxIcon from '@material-ui/icons/ArtTrack';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';
import Image from '@material-ui/icons/Image';
import Code from '@material-ui/icons/Code';
import Public from '@material-ui/icons/Public';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import HideShow from '@material-ui/icons/RemoveRedEye';
import Crop75Icon from '@material-ui/icons/Crop75';
import Tooltip from '@material-ui/core/Tooltip';

import {
  ELEMENT_TABLE,
  addColumn,
  addRow,
  deleteColumn,
  deleteRow,
  deleteTable,
  insertTable,
  ToolbarTable,
} from '@udecode/slate-plugins';

import BookingIconNoFill from '../../../assets/icons/BookingIconNoFill';
import TiktokIcon from '../../../assets/icons/TiktokIcon';

import { ELEMENT_BLOCKQUOTE } from '../plugins/elements/blockquote/defaults';
import { ELEMENT_HR } from '../plugins/elements/horizontal-rule/defaults';
import { ELEMENT_INFOBOX } from '../plugins/elements/infobox/defaults';
import { ELEMENT_NUMBERBOX } from '../plugins/elements/numberbox/defaults';
import { ELEMENT_RELATED_ARTICLES } from '../plugins/elements/related-articles/defaults';
import { ELEMENT_IMAGE } from '../plugins/elements/media/image/defaults';
import { ELEMENT_GALLERY } from '../plugins/elements/media/gallery/defaults';
import { ELEMENT_VIDEO } from '../plugins/elements/media/video/defaults';
import { ELEMENT_TWITTER } from '../plugins/elements/social/twitter/defaults';
import { ELEMENT_FACEBOOK } from '../plugins/elements/social/facebook/defaults';
import { ELEMENT_INSTAGRAM } from '../plugins/elements/social/instagram/defaults';
import { ELEMENT_IFRAME } from '../plugins/elements/iframe/defaults';
import { ELEMENT_HTML } from '../plugins/elements/html/defaults';
import { ELEMENT_ARTICLE_LINK } from '../plugins/elements/article-link/defaults';
import { ELEMENT_VISUAL_STORY } from '../plugins/elements/embed/visual-story/defaults';
import { ELEMENT_BOOKING } from '../plugins/elements/embed/booking/defaults';
import { ELEMENT_HIDE_AND_SHOW } from '../plugins/elements/hide-and-show/defaults';
import { ELEMENT_PARALLAX } from '../plugins/elements/parallax/defaults';
import { ELEMENT_BUTTON_LINK } from '../plugins/elements/button-link/defaults';
import { ELEMENT_PRICE_COMPARISON } from '../plugins/elements/embed/price-comparison/defaults';
import { ELEMENT_OPTA } from '../plugins/elements/embed/opta/defaults';

import { MARK_SEARCH_REPLACE } from '../plugins/widgets/search-replace/defaults';

import toggleTableHead from '../plugins/transforms/toggleTableHead';

import Separator from './Separator';
import ToolbarInsert from './ToolbarInsert';
import ToolbarSearchReplace from '../plugins/widgets/search-replace/components/ToolbarSearchReplace';

import { ELEMENT_TIKTOK } from '../plugins/elements/social/tiktok/defaults';
import OptaIcon from '../../../assets/icons/OptaIcon';
import VoucherIcon from '../../../assets/icons/VoucherIcon';
import { connect } from "react-redux";
import { CLIENT_INDEPENDENT, CLIENT_STANDARD } from "../../../constants/env";
import { ELEMENT_VOUCHER } from '../plugins/elements/embed/voucher/defaults';
import { ELEMENT_FAQ } from '../plugins/elements/faq/defaults';
import { ELEMENT_MONEY } from '../plugins/elements/embed/money/defaults';


const TOOLBAR_BUTTONS = {
  [ELEMENT_BLOCKQUOTE]: { type: ELEMENT_BLOCKQUOTE, icon: <FormatQuoteClose />, title: 'Block quote' },
  [ELEMENT_HR]: { type: ELEMENT_HR, icon: <Remove />, title: 'HR' },
  [ELEMENT_TABLE]: [
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <Table />, transform: insertTable, hide: ELEMENT_TABLE, title: 'Insert table' },
    { El: Separator, show: ELEMENT_TABLE },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableRemove />, transform: deleteTable, show: ELEMENT_TABLE, title: 'Delete table' },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableColumnPlusAfter />, transform: addColumn, show: ELEMENT_TABLE, title: 'Add column' },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableColumnRemove />, transform: deleteColumn, show: ELEMENT_TABLE, title: 'Delete column' },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableRowPlusAfter />, transform: addRow, show: ELEMENT_TABLE, title: 'Add row' },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableRowRemove />, transform: deleteRow, show: ELEMENT_TABLE, title: 'Delete row' },
    { El: ToolbarTable, type: ELEMENT_TABLE, icon: <TableBorder />, transform: toggleTableHead, show: ELEMENT_TABLE, title: 'Toggle header' },
    { El: Separator, show: ELEMENT_TABLE },
  ],
  [ELEMENT_PARALLAX]: { type: ELEMENT_PARALLAX, icon: <ParallaxIcon />, title: 'Parallax' },
  [ELEMENT_INFOBOX]: { type: ELEMENT_INFOBOX, icon: <ArrangeBringForward />, title: 'Infobox' },
  [ELEMENT_NUMBERBOX]: { type: ELEMENT_NUMBERBOX, icon: <BookmarkMinusOutline />, title: 'Numberbox' },
  [ELEMENT_BUTTON_LINK]: { type: ELEMENT_BUTTON_LINK, icon: <Crop75Icon />, title: 'Button' },
  [ELEMENT_RELATED_ARTICLES]: { type: ELEMENT_RELATED_ARTICLES, icon: <VectorLink />, title: 'Related articles' },
  [ELEMENT_IMAGE]: { type: ELEMENT_IMAGE, icon: <Image />, title: 'Image' },
  [ELEMENT_GALLERY]: { type: ELEMENT_GALLERY, icon: <PhotoLibrary />, title: 'Gallery' },
  [ELEMENT_VIDEO]: { type: ELEMENT_VIDEO, icon: <PlayOutline />, title: 'Video' },
  [ELEMENT_TWITTER]: { type: ELEMENT_TWITTER, icon: <Twitter />, title: 'Twitter' },
  [ELEMENT_FACEBOOK]: { type: ELEMENT_FACEBOOK, icon: <Facebook />, title: 'Facebook' },
  [ELEMENT_INSTAGRAM]: { type: ELEMENT_INSTAGRAM, icon: <Instagram />, title: 'Instagram' },
  [ELEMENT_TIKTOK]: { type: ELEMENT_TIKTOK, icon: <TiktokIcon />, title: 'Tiktok' },
  [ELEMENT_IFRAME]: { type: ELEMENT_IFRAME, icon: <ApplicationOutline />, title: 'Iframe' },
  [ELEMENT_HTML]: { type: ELEMENT_HTML, icon: <Code />, title: 'HTML' },
  [ELEMENT_ARTICLE_LINK]: { type: ELEMENT_ARTICLE_LINK, icon: <Public />, title: 'Article link' },
  [ELEMENT_VISUAL_STORY]: { type: ELEMENT_VISUAL_STORY, icon: <DynamicFeed />, title: 'Visual Story' },
  [ELEMENT_BOOKING]: { type: ELEMENT_BOOKING, icon: <BookingIconNoFill />, title: 'Booking.com Embed' },
  [ELEMENT_OPTA]: { type: ELEMENT_OPTA, icon: <OptaIcon />, title: 'Opta' },
  [ELEMENT_FAQ]: { type: ELEMENT_FAQ, icon: <ChatQuestion />, title: 'faq' },
  [ELEMENT_VOUCHER]: { type: ELEMENT_VOUCHER, icon: <VoucherIcon />, title: 'Voucher' },
  [ELEMENT_HIDE_AND_SHOW]: { type: ELEMENT_HIDE_AND_SHOW, icon: <HideShow />, title: 'Hide and Show' },
  [ELEMENT_PRICE_COMPARISON]: { type: ELEMENT_PRICE_COMPARISON, icon: <CurrencyGbp />, title: 'Price Comparison' },
  [ELEMENT_MONEY]: { type: ELEMENT_MONEY, icon: <AccountCash />, title: 'Money' },
  [MARK_SEARCH_REPLACE]: { El: ToolbarSearchReplace },
};

const isButtonDisplayed = (conditions, show, hide) => {
  if (hide && conditions.includes(hide)) {
    return false;
  }
  if (show) {
    return conditions.includes(show);
  }
  return true;
};

const ToolbarButtons = ({ elements, conditions, classes, decorators, after, client }) => {
  const buttons = [];
  elements.forEach((element) => {
    // OPTA is Standard and Indy only
    if (
      (client !== CLIENT_STANDARD && client !== CLIENT_INDEPENDENT) &&
      element === ELEMENT_OPTA
    ) {
      return;
    }
    if (Array.isArray(TOOLBAR_BUTTONS[element])) {
      buttons.push(...TOOLBAR_BUTTONS[element]);
    } else if (TOOLBAR_BUTTONS[element]) {
      buttons.push(TOOLBAR_BUTTONS[element]);
    }
  });
  return (
    <>
      {buttons
        .filter(({ show, hide }) => isButtonDisplayed(conditions, show, hide))
        .map(({
          El = ToolbarInsert,
          icon,
          title,
          show,
          hide,
          ...rest
        }, index) => (
          <El
            key={`toolbarbutton-${index}`}
            className={El === Separator ? classes.separator : classes.button}
            icon={icon && <Tooltip
              arrow
              title={title}
            >{icon}</Tooltip>}
            {...rest}
            {...decorators}
          />
        ))
      }
      {after && after}
    </>
  );
};

ToolbarButtons.propTypes = {
  elements: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  client: PropTypes.string.isRequired,
  conditions: PropTypes.array,
  decorators: PropTypes.object,
  after: PropTypes.array,
};

ToolbarButtons.defaultProps = {
  conditions: [],
  decorators: {},
  after: [],
};

export default connect(
  ({
    frame: { client },
  }) => ({ client }),
)(ToolbarButtons);
