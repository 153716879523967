import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  ckeditor: {
    flex: '1 1 auto',
    minHeight: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0, 1),
    fontFamily: theme.typography.fontFamily,
    color: '#333',
    position: 'relative',
    '& .ck.ck-editor__editable > .ck-placeholder::before': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .ck.ck-content': {
      padding: 0,
    },
    '& .ck.ck-editor__editable_inline': {
      border: '0 none',
      padding: 0,
      '&>:first-child': {
        marginTop: theme.spacing(2),
      },
      '&>:last-child': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused': {
      boxShadow: 'none',
      border: '0 none',
    },
  },
  outlined: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    '&:hover': {
      borderColor: '#000',
    },
    '&.focused': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      margin: '-1px 0',
    },
  },
});

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const ListItem = ({
  classes,
  data,
  varient,
}) => {
  const rootClasses = [classes.ckeditor];
  if (varient) {
    rootClasses.push(classes[varient]);
  }
  return (
    <div
      className={rootClasses.join(' ')}
      dangerouslySetInnerHTML={{ __html: data.markup }}
    />
  );
};

ListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  varient: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ListItem.defaultProps = {
  varient: null,
};

export default withStyles(styles)(ListItem);

