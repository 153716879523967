import ImageElement from './components/ImageElement';

export const ELEMENT_IMAGE = 'image';
export const PROP_IMAGE = 'image';
export const PROP_CAPTION = 'caption';
export const PROP_COPYRIGHT = 'copyright';
export const PROP_ALIGN = 'align';

export const DEFAULTS_IMAGE = {
  image: {
    component: ImageElement,
    type: ELEMENT_IMAGE,
    rootProps: {
      className: 'slate-image',
    },
  },
};
