import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { componentSetProp } from '../../../../actions/layout';
import { SERVICE } from '../../constants';
import Select from '../../../ui/Select';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeading,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';

const ServicesOptions = (props) => {
  const {
    component,
    publicationConfig,
    componentSetProp: setProp,
  } = props;

  //@todo get items from config
  const items = [
    'jobs',
    'events',
    'subscriptions',
  ];

  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Settings</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        <Select
          displayEmpty
          label={'Select service'}
          labelId={`service${component.id}`}
          value={component[SERVICE]}
          emptyValue={'Select service'}
          onChange={e => setProp(component.id, SERVICE, e.target.value)}
          items={items}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

ServicesOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
  publicationConfig: PropTypes.object,
};

ServicesOptions.defaultProps = {
  publicationConfig: {},
};

export default connect(
  ({
    frame: { selectedPublication: { publicationConfig } },
  }) => ({ publicationConfig }),
  { componentSetProp },
)(ServicesOptions);
