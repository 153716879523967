import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar, { PageToolbarButtonsStatic } from '../components/common/page/PageToolbar';
import { saveMediaEdit, searchGallery } from '../actions/media';

import GalleryItems from '../components/embed/GalleryList';
import ButtonSelect from '../components/ui/buttons/ButtonSelect';
import Button from '../components/ui/buttons/Button';

export const GalleryListBreadcrumb = [
  {
    title: 'Media',
    slug: 'media',
  },
  {
    title: 'Gallery library',
    slug: 'gallery',
  },
];

const GalleryList = ({
  onSearchGallery: onSearch, push: updateRoute,
}) => (
  <Page
    toolbarContent={<PageToolbar breadcrumb={GalleryListBreadcrumb}>
      <Grid container justify={'center'} alignItems={'center'}>
        <Typography variant={'body2'}>Manage</Typography>
        <ButtonSelect
          active={'Gallery'}
          items={[
            { link: '/media/image', title: 'Image' },
            { link: '/media/gallery', title: 'Gallery' },
          ]}
        />
      </Grid>
      <PageToolbarButtonsStatic>
        <Button
          onClick={() => updateRoute('/media/gallery/create')}
          variant={'contained'}
          startIcon={<AddIcon />}
        >New gallery</Button>
      </PageToolbarButtonsStatic>
    </PageToolbar>}
  >
    <PageContent>
      <PageContent>
        <GalleryItems
          pageStyle
          onSearchGallery={onSearch}
          onSelectGallery={data => updateRoute(`/media/gallery/${data.mid}`)}
        />
      </PageContent>
    </PageContent>
  </Page>
);

GalleryList.propTypes = {
  onSearchGallery: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

GalleryList.defaultTypes = {
  isToggle: false,
};

GalleryList.defaultProps = {
  selectedComponent: {},
};

export default connect(
  () => ({}),
  { onSaveMediaEdit: saveMediaEdit, onSearchGallery: searchGallery, push },
)(GalleryList);
