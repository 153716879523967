import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import TagItem from '../../../common/TagItem';
import {
  addKeyword, removeKeyword,
} from '../../../../actions/twitter';

const style = theme => ({
  root: {
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputAddMoreTagRoot: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    marginBottom: '20px',
  },
  inputAddMoreTag: {
    width: '100%',
    '& input': {
      paddingRight: '80px',
    },
  },
});

const TwitterExplorerAddKeyword = ({
  classes,
  keywords,
  addKeyword: onAddKeyword,
  removeKeyword: onRemoveKeyword,
}) => {
  return (<div className={classes.root}>
    <div className={classes.inputAddMoreTagRoot}>
      <TextField
        label={'Insert keyword'}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            if (e.target.value) {
              onAddKeyword(e.target.value);
              e.target.value = '';
            }
          }
        }}
        className={classes.inputAddMoreTag}
      />
    </div>
    <div className={classes.tags}>{
      keywords.map(item => (<TagItem
        key={item}
        label={item}
        onClose={() => onRemoveKeyword(item)}
      />))}
    </div>
  </div>);
};

TwitterExplorerAddKeyword.defaultProps = {
};

TwitterExplorerAddKeyword.propTypes = {
  classes: PropTypes.object.isRequired,
  keywords: PropTypes.array.isRequired,
  addKeyword: PropTypes.func.isRequired,
  removeKeyword: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(({
  twitter: { keywords },
}) => ({ keywords }),
{
  addKeyword,
  removeKeyword,
})(TwitterExplorerAddKeyword));
