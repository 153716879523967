import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import PictureInPictureIcon from '@material-ui/icons/PictureInPicture';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginBottom: theme.spacing(0.25),
    cursor: 'pointer',
    fontSize: 14,
    color: theme.typography.color.primary,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.background.default,
  },
  compareButton: {
    cursor: 'pointer',
    color: theme.typography.color.primary,
    backgroundColor: 'rgba(0, 0, 0, .05)',
  },
});

const TranslationComparedToggle = ({ classes, onClick, label }) => {

  return (<div className={classes.root}>
    <Avatar
      className={classes.item}
      variant={'square'}
    >{label}</Avatar>
    <Avatar
      onClick={() => onClick(label)}
      variant={'square'}
      className={classes.compareButton}
    >
      <PictureInPictureIcon />
    </Avatar>
  </div>);
};

TranslationComparedToggle.defaultProps = {
};

TranslationComparedToggle.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  {},
)(TranslationComparedToggle));

