import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_NUMBERBOX, PROP_MARKUP, PROP_TITLE, PROP_ALIGN } from './defaults';
import { NUMBERBOX } from '../../../../../constants/editor/dataTypes';

export const convertToNumberbox = data => ({
  type: ELEMENT_NUMBERBOX,
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  [PROP_TITLE]: data[PROP_TITLE] || '',
  [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  children: [{ text: '' }],
});

export const convertFromNumberbox = data => ({
  type: NUMBERBOX,
  data: {
    [PROP_MARKUP]: data[PROP_MARKUP] ? serializeNodes(data[PROP_MARKUP]) : null,
    [PROP_TITLE]: data[PROP_TITLE] || '',
    [PROP_ALIGN]: data[PROP_ALIGN] || 'none',
  },
});
