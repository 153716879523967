import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Dialog from './common/Dialog';

const PublicationSelectorDialog = props =>
  (<Dialog title={props.title} {...props}>
    <List>
      { props.publications.map(publication => ([<ListItem
        onClick={() => props.onSelect(publication)}
        key={`list${publication.url}`}
        button
      >
        <ListItemText primary={publication.name} secondary={publication.url} />
      </ListItem>, <Divider key={`divider${publication.url}`} />]))}
    </List>
  </Dialog>);

PublicationSelectorDialog.defaultProps = {
  publications: [],
  title: 'Select a publication',
};

PublicationSelectorDialog.propTypes = {
  publications: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default connect((
  { frame: { publication } }) => ({ publications: publication }),
)(PublicationSelectorDialog);
