import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockContainer from '../FieldLockContainerDepricated';
import TagSelector from '../../ui/TagSelector';

/**
 * @deprecated
 */
const FieldLockVocabSelectorDepricated = ({
  vocab, selectedTerms, label, field: fieldName, hierarchical,
  lockedFields, sessionLockedFields,
  onSelect, onRemove, onUnlock,
  limit, required, extraFields, showExtras, ...rest
}) => {
  const lock = sessionLockedFields.filter(({ field }) => field === fieldName);
  const terms = lock.length > 0 ? lock[0].value : selectedTerms;
  return (
    <>
      <FieldLockContainer
        field={fieldName}
        onUnlock={onUnlock}
      >
        <TagSelector
          hasChanges={Object.keys(lockedFields).indexOf(fieldName) !== -1}
          required={required}
          label={label}
          placeholder={`Search ${label}`}
          selectedTerms={terms.filter(Boolean)}
          vocab={vocab}
          onSelect={onSelect}
          onRemove={onRemove}
          limit={limit}
          hierarchical={hierarchical}
          {...rest}
        />
      </FieldLockContainer>
      {(showExtras || terms.length > 0) && extraFields.length > 0 &&
        extraFields.map(({ id, component: Component }) => (<Component key={id} />))
      }
    </>
  );
};

FieldLockVocabSelectorDepricated.propTypes = {
  selectedTerms: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired,
  vocab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  lockedFields: PropTypes.object.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  hierarchical: PropTypes.bool,
  limit: PropTypes.number,
  required: PropTypes.bool,
  extraFields: PropTypes.array,
  showExtras: PropTypes.bool,
};

FieldLockVocabSelectorDepricated.defaultProps = {
  required: false,
  hierarchical: false,
  limit: -1,
  extraFields: [],
  showExtras: false,
};

export default connect(
  ({
    fieldLock: { lockedFields, sessionLockedFields },
  }) => ({ lockedFields, sessionLockedFields }),
  {},
)(FieldLockVocabSelectorDepricated);

