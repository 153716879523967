import { setDefaults } from '@udecode/slate-plugins';
import deserializeInstagram from './deserializeInstagram';
import renderElementInstagram from './renderElementInstagram';
import { DEFAULTS_INSTAGRAM } from './defaults';

const InstagramPlugin = (options) => {
  const { instagram } = setDefaults(options, DEFAULTS_INSTAGRAM);
  return ({
    renderElement: renderElementInstagram(options),
    deserialize: deserializeInstagram(options),
    voidTypes: [instagram.type],
  });
};

export default InstagramPlugin;
