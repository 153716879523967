import { setDefaults } from '@udecode/slate-plugins';
import deserializeGallery from './deserializeGallery';
import renderElementGallery from './renderElementGallery';
import { DEFAULTS_GALLERY } from './defaults';

const GalleryPlugin = (options) => {
  const { gallery } = setDefaults(options, DEFAULTS_GALLERY);
  return ({
    renderElement: renderElementGallery(options),
    deserialize: deserializeGallery(options),
    voidTypes: [gallery.type],
  });
};

export default GalleryPlugin;
