import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import RequiredIndicator from '../ui/RequiredIndicator';

import { getClassName } from '../../utils/propHelper';

const styles = theme => ({
  margin: {
    marginBottom: theme.spacing(1),
  },
  marginH2: {
    marginBottom: theme.spacing(2),
  },
});

const SectionTitle = ({ classes, className, children, hasChanges, required, variant, margin }) => (
  <Typography
    className={getClassName([
      className,
      margin && variant !== 'h2' ? classes.margin : '',
      margin && variant === 'h2' ? classes.marginH2 : '',
    ])}
    variant={variant}
    color={hasChanges ? 'secondary' : 'primary'}
  >{children}{required && <RequiredIndicator />}</Typography>
);

SectionTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasChanges: PropTypes.bool,
  required: PropTypes.bool,
  margin: PropTypes.bool,
  variant: PropTypes.string,
};

SectionTitle.defaultProps = {
  className: '',
  variant: 'h2',
  hasChanges: false,
  required: false,
  margin: false,
};

export default withStyles(styles)(SectionTitle);
