import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '../../TextField';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      width: 20,
    },
  },
});

const TextSearchFilter = ({ classes, className, placeholder, onSubmit, defaultValue }) => {
  let $input;
  const rootClasses = [classes.root];
  if (className) {
    rootClasses.push(className);
  }
  return (<div className={rootClasses.join(' ')}>
    <TextField
      defaultValue={defaultValue}
      placeholder={placeholder}
      InputProps={{
        // eslint-disable-next-line no-return-assign
        inputRef: input => ($input = input),
        'aria-label': 'Search article',
        startAdornment: (
          <InputAdornment position={'start'}>
            <SearchIcon color={'primary'} />
          </InputAdornment>
        ),
      }}
      onKeyUp={(e) => {
        if (e.keyCode === 13) {
          onSubmit($input.value);
          e.preventDefault();
        }
      }}
      margin={'none'}
    />
  </div>);
};

TextSearchFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
};

TextSearchFilter.defaultProps = {
  placeholder: 'Search article',
  defaultValue: '',
  className: null,
};

export default withStyles(styles)(TextSearchFilter);

