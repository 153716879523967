import { PURGE } from 'redux-persist';
import {
  FETCH_REDIRECT_LIST,
  FETCH_REDIRECT_LIST_REJECTED,
  FETCH_REDIRECT_LIST_SUCCESS,
} from '../../constants/redirect';

const defaultState = {
  preContent: [],
  paging: {
    total: 0,
    limit: 10,
    offset: 0,
  },
  isFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_REDIRECT_LIST_REJECTED:
      return {
        ...state,
        isFetching: false,
      };

    case FETCH_REDIRECT_LIST:
      return {
        ...state,
        isFetching: true,
      };

    case FETCH_REDIRECT_LIST_SUCCESS: {
      const { paging, pre_content: preContent } = action.value;
      return {
        ...state,
        preContent,
        paging,
        isFetching: false,
      };
    }

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
