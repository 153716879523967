import {
  TABLE_SET_FILTER_BY,
  TABLE_SET_ORDER_BY,
  TABLE_SET_PAGE,
  TABLE_SET_PAGE_ROWS,
  TABLE_SET_HEADER,
} from '../constants/actionTypes/table';

export const setOrderBy = value => ({ type: TABLE_SET_ORDER_BY, value });
export const setFilterBy = value => ({ type: TABLE_SET_FILTER_BY, value });
export const setPage = (event, value) => ({ type: TABLE_SET_PAGE, value });
export const setPageRows = value => ({ type: TABLE_SET_PAGE_ROWS, value });
export const setHeader = value => ({ type: TABLE_SET_HEADER, value });
