import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { InlineAutocomplete } from '../../../autocomplete';

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      width: 20,
    },
  },
});

const VocabFilter = props => (
  <InlineAutocomplete
    {...props}
  />
);

export default withStyles(styles)(VocabFilter);
