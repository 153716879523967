import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SaveIcon from '@material-ui/icons/Save';
import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import AddressForm from '../components/contactUs/AddressForm';
import ContactGroup from '../components/contactUs/ContactGroup';
import PageSection from '../components/common/page/PageSection';
import PageToolbar, { PageToolbarButtons } from '../components/common/page/PageToolbar';
import Button from '../components/ui/buttons/Button';
import { saveContactUs, contactUsPageLoaded } from '../actions/contactUs';

export const breadcrumb = [
  {
    title: 'Contact Us',
    slug: 'contact-us',
  },
];

const ContactUs = ({ saveContactUs: onSave, contactUsPageLoaded: onLoad }) => {
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <PageToolbarButtons>
            <Button
              onClick={onSave}
              variant={'contained'}
              startIcon={<SaveIcon />}
            >Save</Button>
          </PageToolbarButtons>
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection includePadding>
          <AddressForm />
          <ContactGroup />
        </PageSection>
      </PageContent>
    </Page>
  );
};

ContactUs.propTypes = {
  saveContactUs: PropTypes.func.isRequired,
  contactUsPageLoaded: PropTypes.func.isRequired,
};

export default connect(() => ({}), {
  saveContactUs, contactUsPageLoaded,
})(ContactUs);
