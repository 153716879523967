import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

import Header, { headerHeight } from '../../components/header';
import Sidebar, { drawerWidth } from '../../components/drawer';
import Notifications from '../../components/common/Notifications';

import { closeAppCrashAlert } from '../../actions/page';

import Button from '../../components/ui/buttons/ButtonWarning';

export const footerHeight = 21;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    marginTop: headerHeight,
    paddingBottom: footerHeight,
    marginLeft: drawerWidth,
    flexBasis: '1230px',
    maxWidth: `calc(100% - ${drawerWidth}px)`,
    position: 'relative',
    '&.full-width': {
      flexBasis: '100%',
    },
  },
  copyright: {
    boxShadow: '0 3px 3px 3px rgba(0, 0, 0, 0.16)',
    background: theme.palette.background.contrast,
    textAlign: 'center',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  alertText: {
    margin: theme.spacing(2, 0),
  },
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Frame = (props) => {
  const {
    children, roles, alwaysExpanded, fullWidth, classes,
    toolbarContent,
    selectedPublication,
    showAppCrashDialog,
    closeAppCrashAlert: closeAlert,
  } = props;
  const [modalStyle] = React.useState(getModalStyle);
  const rootClasses = [classes.root, 'container'];
  if (alwaysExpanded) {
    rootClasses.push('expanded');
  }
  if (fullWidth) {
    rootClasses.push('full-width');
  }
  return (
    <div className={rootClasses.join(' ')}>
      <Notifications />
      <Header toolbarContent={toolbarContent} />
      {selectedPublication.features && <Sidebar roles={roles} />}
      {children}
      <Modal
        open={showAppCrashDialog}
        onClose={() => closeAlert()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Typography className={classes.alertText} variant={'body1'}>Please refresh your browser to continue editing.</Typography>
          <div>
            <Button size="small" onClick={() => closeAlert()}>Close</Button>
          </div>
        </div>
      </Modal>
      <Typography className={classes.copyright} variant={'caption'}>Copyright © {new Date().getFullYear()} Bright Sites Consulting Ltd. All Rights Reserved.</Typography>
    </div>
  );
};

Frame.defaultProps = {
  roles: [],
  fullWidth: false,
  toolbarContent: null,
  selectedPublication: {},
  alwaysExpanded: false,
};

Frame.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.array,
  fullWidth: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  selectedPublication: PropTypes.any,
  toolbarContent: PropTypes.node,
  alwaysExpanded: PropTypes.bool,
};

export default withStyles(styles)(connect(
  ({
    frame: { selectedPublication, showAppCrashDialog },
    drawer: { alwaysExpanded },
    login: { user: { roles } },
  }) => ({
    selectedPublication,
    roles,
    alwaysExpanded,
    showAppCrashDialog,
  }), { closeAppCrashAlert })(Frame));
