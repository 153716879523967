import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Access by permission string instead of role
 */

const AccessToggle = ({ children, restrictedTo, permissions }) => (<React.Fragment>
  {permissions.includes(restrictedTo) && children}
</React.Fragment>);

AccessToggle.defaultProps = {
  permissions: [],
};

AccessToggle.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.node.isRequired,
  restrictedTo: PropTypes.string.isRequired,
};

export default connect(({ login: { permissions } }) => ({ permissions }), {})(AccessToggle);
