import { deserializeString, serializeNodes } from '../../../helper/serializer';
import { ELEMENT_PARALLAX, PROP_MARKUP } from './defaults';
import { PARALLAX } from '../../../../../constants/editor/dataTypes';
import { PROP_IMAGE } from '../media/image/defaults';
import generateKey from '../../../../../utils/generateKey';

export const initialParallaxItem = () => ({
  id: generateKey(),
  [PROP_MARKUP]: null,
  [PROP_IMAGE]: null,
});

const deserializeParallaxItem = data => ({
  id: data.id || generateKey(),
  [PROP_MARKUP]: data[PROP_MARKUP] ? deserializeString(data[PROP_MARKUP]) : null,
  [PROP_IMAGE]: data[PROP_IMAGE] || null,
});

const serializeParallaxItem = data => ({
  id: data.id || generateKey(),
  [PROP_MARKUP]: data[PROP_MARKUP] ? serializeNodes(data[PROP_MARKUP]) : null,
  [PROP_IMAGE]: data[PROP_IMAGE] || null,
});

export const convertToParallax = data => ({
  type: ELEMENT_PARALLAX,
  items: data.items?.map(deserializeParallaxItem) || [deserializeParallaxItem({})],
  children: [{ text: '' }],
});

export const convertFromParallax = data => ({
  type: PARALLAX,
  data: {
    items: data.items?.map(serializeParallaxItem) || [serializeParallaxItem({})],
  },
});
