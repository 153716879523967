import React from 'react';
import PropTypes from 'prop-types';

import TextFieldBase from '../TextField';
import { reformatQuote } from '../../helper/formatting';

/**
 * @todo replace usage with generic UI Textfield
 * @deprecated
 */
const TextField = (props) => {
  const { margin, variant, multiline, ...rest } = props;
  return (
    <TextFieldBase
      margin={margin}
      variant={variant}
      multiline={multiline}
      onKeyUp={(e) => {
        e.persist();
        // maintain caret focus
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const formatted = reformatQuote(e.target.value);
        if (e.target.value !== formatted && rest.onChange) {
          e.target.value = formatted;
          e.target.setSelectionRange(caretStart, caretEnd);
          // force update
          rest.onChange(e);
          if (rest.onKeyUp) {
            rest.onKeyUp(e);
          }
        }
      }}
      {...rest}
    />
  );
};

TextField.propTypes = {
  margin: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
};

TextField.defaultProps = {
  margin: 'none',
  variant: 'outlined',
  multiline: true,
};

export default TextField;
