import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'rxjs';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = theme => ({
  tag: {
    borderRadius: '18px',
    borderWidth: 1,
    letterSpacing: '0.25px',
    textTransform: 'none',
    padding: theme.spacing(0.75, 1.5, 0.85),
    marginRight: theme.spacing(0.5),
    '& .MuiButton-endIcon svg': {
      fontSize: 18,
    },
  },
});

/**
 * @todo onRemove can be removed once tag dnd for primary has been added,
 *   replace with a bool for the icon
 */
const Tag = ({ classes, label, onRemove, onClick, Icon, color }) => {
  const cssClasses = [classes.tag];
  return (<Button
    variant={'outlined'}
    className={cssClasses.join(' ')}
    onClick={onRemove || onClick}
    color={color}
    startIcon={Icon ? <Icon /> : null}
    endIcon={onRemove ? <CancelIcon /> : null}
  >
    {label}
  </Button>);
};

Tag.defaultProps = {
  color: 'primary',
  onClick: noop,
  onRemove: null,
  Icon: null,
};

Tag.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.node,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  color: PropTypes.string,
};

export default withStyles(styles)(Tag);

