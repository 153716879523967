const frequency = {
  type: 'range',
  data: {
    start: 3,
    length: 8,
  },
};

const ADCONFIG_DESKTOP = [
  { title: 'Leaderboard', tile: 'leaderboard' },
  { title: 'Wallpaper', tile: 'wallpaper' },
  { title: 'Article MPU Frequency', tile: 'article_freq', ...frequency },
  { title: 'Article MPU Limit', tile: 'article_limit', type: 'range' },
  { title: 'Liveblog MPU Frequency', tile: 'liveblog_freq', ...frequency },
  { title: 'Liveblog MPU Limit', tile: 'liveblog_limit', type: 'range' },
  { title: 'Native Display', tile: 'native' },
  { title: 'Outbrain', tile: 'outbrain' },
  { title: 'Sidebar MPU/DMPU', tile: 'sidebar' },
  { title: 'Sticky Unit', tile: 'sticky' },
];

const ADCONFIG_MOBILE = [
  { title: 'Leaderboard', tile: 'leaderboard' },
  { title: 'Wallpaper', tile: 'wallpaper' },
  { title: 'Article MPU Frequency', tile: 'article_freq', ...frequency },
  { title: 'Article MPU Limit', tile: 'article_limit', type: 'range' },
  { title: 'Liveblog MPU Frequency', tile: 'liveblog_freq', ...frequency },
  { title: 'Liveblog MPU Limit', tile: 'liveblog_limit', type: 'range' },
  { title: 'Native Display', tile: 'native' },
  { title: 'Outbrain', tile: 'outbrain' },
];

export default {
  desktop: ADCONFIG_DESKTOP,
  mobile: ADCONFIG_MOBILE,
};
