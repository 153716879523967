import {
  TWITTER_ADD_KEYWORD,
  TWITTER_ADD_USER_HANDLE,
  TWITTER_ON_LOAD,
  TWITTER_REMOVE_KEYWORD,
  TWITTER_REMOVE_USER_HANDLE,
  TWITTER_FETCH_SUCCESS,
  TWITTER_FETCH,
  TWITTER_REQUEST_REJECTED,
  TWITTER_SEARCH_USER_SUCCESS,
} from '../../constants/actionTypes';

const defaultState = {
  users: [],
  keywords: [],
  twits: [],
  userSuggestion: [],
  loading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TWITTER_SEARCH_USER_SUCCESS:
      return {
        ...state,
        userSuggestion: action.value,
      };

    case TWITTER_ADD_KEYWORD:
      return {
        ...state,
        keywords: [...state.keywords, action.value],
      };

    case TWITTER_REMOVE_KEYWORD:
      return {
        ...state,
        keywords: state.keywords.filter(keyword => keyword !== action.value),
      };

    case TWITTER_ADD_USER_HANDLE:
      return {
        ...state,
        users: [...state.users, action.value],
      };

    case TWITTER_REMOVE_USER_HANDLE:
      return {
        ...state,
        users: state.users.filter(user => user !== action.value),
      };

    case TWITTER_REQUEST_REJECTED:
      return {
        ...state,
        loading: false,
      };

    case TWITTER_FETCH:
      return {
        ...state,
        loading: true,
      };

    case TWITTER_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        twits: action.value,
      };

    case TWITTER_ON_LOAD:
      return {
        ...state,
        twits: defaultState.twits,
      };

    default:
      return state;
  }
};
