import {
  catchError,
  pluck,
  flatMap,
  mapTo,
  concat,
  filter,
  tap,
  mergeMap,
} from 'rxjs/operators';

import { of, from } from 'rxjs';

import { AUTHENTICATION_REQUIRED } from '../../constants/actionTypes';
import {
  ERROR,
  SHOW_NOTIFICATION,
  SUCCESS,
  WARNING,
} from '../../constants/actionTypes/notification';

const redirect403$ = xhr => of(xhr).pipe(
  pluck('xhr', 'status'),
  filter(status => status === 403 || status === 401),
  mapTo({
    type: AUTHENTICATION_REQUIRED,
  }),
);

const handleNon403$ = (actionOrigin, message) => xhr => of(xhr).pipe(
  filter(response => response.status !== 403),
  pluck('xhr', 'response'),
  catchError(() => of('Unable to complete this request.')),
  mergeMap((response) => {
    let userFriendlyMessage = message || 'Unable to complete this request.';
    if (response?.error) {
      userFriendlyMessage = response.error;
    } else if (response?.message) {
      userFriendlyMessage = response.message
        .split('\n')
        .filter(text => !/^Unprocessable Entity/.test(text))
        .filter(Boolean)
        .join('\n');
    }

    const actions = [{
      type: SHOW_NOTIFICATION,
      value: {
        message: userFriendlyMessage,
        variant: ERROR,
        actionOrigin,
      },
    }];

    if (actionOrigin) {
      actions.push({
        type: actionOrigin,
      });
    }
    return from(actions);
  }),
);

const apiCatchError = (...args) => catchError(error => of(error).pipe(
  tap(console.error),
  flatMap(handleNon403$(...args)),
  concat(redirect403$(error)),
));

export const assignErrorPayload = message => ({
  type: SHOW_NOTIFICATION,
  value: {
    message,
    variant: ERROR,
  },
});

export const assignNotification = message => ({
  type: SHOW_NOTIFICATION,
  value: {
    message,
    variant: SUCCESS,
  },
});

export const showErrorNotification = message => (ex) => {
  if (ex) console.error(ex);
  return of(assignErrorPayload(message));
};

export const showWarningNotification = message => of({
  type: SHOW_NOTIFICATION,
  value: {
    message,
    variant: WARNING,
  },
});

export const showSuccessNotification = message => () => of(assignNotification(message));

export default apiCatchError;
