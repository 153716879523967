import React from 'react';
import PropTypes from 'prop-types';

import { DND_TYPE_COMPONENT } from '../../../constants/components';

import DropZone from '../DropZone';

const DropZoneComponent = (props) => {
  const { className, children, dropHandler, dataProcessor } = props;
  return (
    <DropZone
      className={className}
      type={DND_TYPE_COMPONENT}
      dropHandler={dropHandler}
      dataProcessor={dataProcessor}
    >
      {children}
    </DropZone>
  );
};

DropZoneComponent.propTypes = {
  children: PropTypes.node.isRequired,
  dropHandler: PropTypes.func.isRequired,
  dataProcessor: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DropZoneComponent.defaultProps = {
  className: null,
};

export default DropZoneComponent;
