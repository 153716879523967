import { getNodeDeserializer, setDefaults } from '@udecode/slate-plugins';

import { DEFAULTS_TIKTOK } from './defaults';

const deserializeTiktok = (options) => {
  const { tiktok } = setDefaults(options, DEFAULTS_TIKTOK);

  return {
    element: getNodeDeserializer({
      type: tiktok.type,
      node: (el) => {
        const url = el.getAttribute('data-slate-url');
        return {
          type: tiktok.type,
          url,
        };
      },
      rules: [
        { className: tiktok.rootProps.className },
      ],
      ...options?.tiktok?.deserialize,
    }),
  };
};

export default deserializeTiktok;
