import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import Scheduler from './Scheduler';
import TextButton from '../buttons/TextButton';

const SchedulerControl = ({
  scheduleDateTime, onSubmit,
}) => {
  const [scheduleTimestamp, setScheduleTimestamp] = useState(scheduleDateTime);
  return (<MuiPickersUtilsProvider utils={MomentUtils}>
    <FormControl component="fieldset">
      <Scheduler
        onChange={setScheduleTimestamp}
        dateTime={scheduleTimestamp}
      />
    </FormControl>
    <Grid container justify={'center'}>
      <TextButton
        size={'large'}
        onClick={() => onSubmit(scheduleTimestamp)}
      >Schedule</TextButton>
    </Grid>
  </MuiPickersUtilsProvider>);
};

SchedulerControl.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  scheduleDateTime: PropTypes.number,
};

SchedulerControl.defaultProps = {
  scheduleDateTime: null,
};

export default SchedulerControl;
