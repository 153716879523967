import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  root: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
  },
});

export default withStyles(styles)(({ classes }) => (<Grid className={classes.root}>
  <LinearProgress className={classes.loading} color={'primary'} />
</Grid>));
