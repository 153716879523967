import { PURGE } from 'redux-persist';
import { omit } from 'rambdax';

import {
  FIELD_LOCKING_DISPOSED,
  FIELD_LOCK_SET,
  FIELD_LOCK_UNSET,
  INIT_FIELD_LOCKING,
  ADD_FIELD_LOCK,
  REMOVE_FIELD_LOCK,
  SESSION_UNLOCK_FIELD,
  SESSION_UNLOCK_FIELD_REQUEST, SESSION_LOCK_FIELD, FIELD_LOCK_DISPOSE,
  FIELD_LOCK_INIT, FIELD_LOCK_REFRESH,
} from '../../constants/actionTypes/fieldLock';
import { ARTICLE_SAVE_SUCCESS } from '../../constants/actionTypes/article';
import { PAGE_ENTER_ARTICLE_CREATE } from '../../constants/actionTypes/route';

const defaultState = {
  contentId: null,
  contentType: null,
  lockedFields: {},
  sessionLockedFields: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FIELD_LOCK_REFRESH:
      return {
        contentId: state.contentId,
        contentType: state.contentType,
        lockedFields: state.lockedFields,
        sessionLockedFields: state.sessionLockedFields,
        ...action.value,
      };

    case FIELD_LOCK_SET: {
      const { fieldName, timestamp, user } = action.value;
      return {
        ...state,
        [fieldName]: {
          timestamp,
          user,
        },
      };
    }

    case FIELD_LOCK_UNSET:
      return omit(action.value, state);

      // @todo remove once article locking refactored

    case ARTICLE_SAVE_SUCCESS: {
      return {
        ...state,
        lockedFields: defaultState.lockedFields,
      };
    }

    case INIT_FIELD_LOCKING: {
      const { contentId, contentType } = action.value;
      return {
        ...state,
        contentId,
        contentType,
        lockedFields: defaultState.lockedFields,
        sessionLockedFields: defaultState.sessionLockedFields,
      };
    }

    case ADD_FIELD_LOCK: {
      const { field, value } = action.value;
      return {
        ...state,
        lockedFields: {
          ...state.lockedFields,
          [field]: value,
        },
      };
    }

    case REMOVE_FIELD_LOCK: {
      const { field } = action.value;
      return {
        ...state,
        lockedFields: omit(field, state.lockedFields),
      };
    }

    case SESSION_UNLOCK_FIELD: // the other user navigates away from the page
    case SESSION_UNLOCK_FIELD_REQUEST: // unlock by another user by clicking the X button
      return {
        ...state,
        sessionLockedFields: state.sessionLockedFields.filter(
          ({ field }) => field !== action.value.field,
        ),
      };

    case SESSION_LOCK_FIELD:
      return { ...state,
        sessionLockedFields: [
          ...state.sessionLockedFields.filter(({ field }) => field !== action.value.field),
          action.value,
        ],
      };

    case PAGE_ENTER_ARTICLE_CREATE:
    case FIELD_LOCK_DISPOSE:
    case FIELD_LOCK_INIT:
    case FIELD_LOCKING_DISPOSED:
    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
