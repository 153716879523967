import {
  AD_REPLACEMENT_FETCH,
  AD_REPLACEMENT_ONSAVE,
  AD_REPLACEMENT_UPDATE_PROPERTY,
  EDITORS_LETTER_FETCH,
  EDITORS_LETTER_ONSAVE,
  EDITORS_LETTER_UPDATE_PROPERTY,
} from 'constants/actionTypes';
import {
  AD_CONFIG_REMOVE,
  AD_CONFIG_SAVE, AD_CONFIG_SELECT_ARTICLE,
  AD_CONFIG_SELECT_SECTION,
  AD_CONFIG_SET_PROPERTY,
} from 'constants/actionTypes/ad';

export const setAdConfigProp = (type, prop, value) =>
  ({ type: AD_CONFIG_SET_PROPERTY, value: { type, prop, value } });
export const removeAdConfig = id => ({ type: AD_CONFIG_REMOVE, value: id });
export const saveAdConfig = id => ({ type: AD_CONFIG_SAVE, value: id });
export const selectSection = value => ({ type: AD_CONFIG_SELECT_SECTION, value });
export const selectArticle = value => ({ type: AD_CONFIG_SELECT_ARTICLE, value });

export const saveAdReplacement = value => ({ type: AD_REPLACEMENT_ONSAVE, value });
export const saveEditorsLetter = value => ({ type: EDITORS_LETTER_ONSAVE, value });
export const fetchEditorsLetter = () => ({ type: EDITORS_LETTER_FETCH });
export const fetchAdReplacement = () => ({ type: AD_REPLACEMENT_FETCH });
export const updateAdReplacementProperty = value =>
  ({ type: AD_REPLACEMENT_UPDATE_PROPERTY, value });
export const updateEditorsLetterProperty = value =>
  ({ type: EDITORS_LETTER_UPDATE_PROPERTY, value });
