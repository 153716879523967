import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line react/display-name
const OptaIcon = forwardRef((props, ref) => (
  <SvgIcon ref={ref} {...props} viewBox={'0 0 38.4375 38.4375'}>
    <path d="M28.0983 19.3957c0-6.2923-4.0245-10.6759-8.9672-10.6759-4.9423 0-8.967 4.3836-8.967 10.676 0 6.1512 4.0247 10.393 8.967 10.393 4.9426 0 8.9672-4.2418 8.9672-10.393m9.3908-.0707c0 10.888-8.2609 19.0186-18.358 19.0186-10.0966 0-18.2874-8.1306-18.2874-19.0186C.8437 8.4366 9.0345.1646 19.1311.1646c10.0971 0 18.358 8.272 18.358 19.1605" />
  </SvgIcon>
));

export default OptaIcon;
