export const FEED_SET_CONTEXT = 'FEED_SET_CONTEXT';
export const FEED_SAVE = 'FEED_SAVE';
export const FEED_SAVE_SUCCESS = 'FEED_SAVE_SUCCESS';
export const FEED_SAVE_REJECTED = 'FEED_SAVE_REJECTED';
export const FEED_FETCH_ALL = 'FEED_FETCH_ALL';
export const FEED_FETCH_ALL_LOADED = 'FEED_FETCH_ALL_LOADED';
export const FEED_FETCH_ALL_REJECTED = 'FEED_FETCH_ALL_REJECTED';

export const FEED_HANDLE_DROP = 'FEED_HANDLE_DROP';

export const FEED_TIMELINE_HANDLE_DROP = 'FEED_TIMELINE_HANDLE_DROP';
export const FEED_TIMELINE_ITEM_PROP_UPDATE = 'FEED_TIMELINE_ITEM_PROP_UPDATE';
export const FEED_TIMELINE_REMOVE_ITEM = 'FEED_TIMELINE_REMOVE_ITEM';
export const FEED_TIMELINE_SAVE = 'FEED_TIMELINE_SAVE';
