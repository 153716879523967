import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { PageToolbarButtons } from '../common/page/PageToolbar';
import Button from '../ui/buttons/Button';

import LiveblogEmbedButton from './LiveblogEmbedButton';

const ControlButtons = ({ editMode, onSave, name }) => {
  const actionTitle = (editMode) ? 'Save Liveblog' : 'Create Liveblog';
  return (
    <PageToolbarButtons>
      <LiveblogEmbedButton />
      <Button
        disabled={!name}
        variant={'contained'}
        onClick={() => onSave({ redirect: false })}
      >{actionTitle}</Button>
    </PageToolbarButtons>
  );
};

ControlButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  name: PropTypes.string,
};

ControlButtons.defaultProps = {
  editMode: false,
  name: '',
};

export default connect(
  ({ liveblog: { name } }) => ({ name }),
  {},
)(ControlButtons);

