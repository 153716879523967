import {
  APP_LOAD,
  CONTEXT_SET, CONTEXT_DISPOSE,
} from '../constants/actionTypes';
import { APP_CRASH_CLOSE } from '../constants/actionTypes/notification';

export const appLoad = () => ({ type: APP_LOAD });
export const closeAppCrashAlert = () => ({ type: APP_CRASH_CLOSE });

export const setContext = (type, data = {}) => ({ type: CONTEXT_SET, value: { type, data } });
export const disposeContext = () => ({ type: CONTEXT_DISPOSE });
