import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';
import { editionDetailFetch } from '../../actions/edition';

import { IMAGE } from '../../constants/builder/builder';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import { Table, TableBody, TableRow, TableCell, TableHead, TableHeadCell, TableCellThumbnail } from '../ui/table/Table';
import Button from '../ui/buttons/Button';

const style = theme => ({
  content: {
    marginTop: 0,
  },
  table: {
    margin: theme.spacing(0, -3),
  },
});


const EditionArticlesDialog = (props) => {
  const {
    classes,
    editionDetail,
    editionDetailFetch: onEditionFetch,
    location,
    onSelect,
    ...rest
  } = props;
  const matches = location.pathname.match(/(\d+)\/(\d+)$/);
  const [articles, setArticles] = useState(editionDetail?.pages || []);
  const [filter, setFilter] = useState('');
  useEffect(() => {
    if (Array.isArray(matches) && matches.length > 0) {
      onEditionFetch(matches[1]);
    }
  }, []);

  useEffect(() => {
    let filtered = editionDetail.pages;
    if (filter) {
      filtered = editionDetail.pages
        .filter(({ data: { title } }) => new RegExp(`${filter}`, 'i').test(title));
    }
    setArticles(filtered);
  }, [filter]);

  if (!editionDetail || !editionDetail.pages) {
    return (<Dialog title={'Articles on edition'} maxWidth={'md'} {...rest}>
      <Typography>Loading...</Typography>
    </Dialog>);
  }

  return (
    <Dialog title={'Articles on edition'} maxWidth={'md'} {...rest}>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <TextSearchFilter
            placeholder={'Search by headline'}
            onSubmit={textQuery => setFilter(textQuery)}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            <Table>
              <TableHead>
                <TableRow hasBorder>
                  <TableHeadCell>Image</TableHeadCell>
                  <TableHeadCell>Title</TableHeadCell>
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {articles.length === 0 && <TableRow>
                  <TableCell colSpan={3} align={'center'}>No articles found</TableCell>
                </TableRow>}
                {articles.length > 0 && articles
                  .filter(page => /article/.test(page.type))
                  .map(({ data }) => {
                    const image = data.hero.find(({ type }) => type === IMAGE)
                    return (
                      <TableRow key={data.id} hasActions>
                        <TableCell>
                          {image && <TableCellThumbnail src={image.data.url} alt={data.title} />}
                        </TableCell>
                        <TableCell>{data.title}</TableCell>
                        <TableCell>
                          <Grid container wrap={'nowrap'} justify={'flex-end'}>
                            <Button variant={'contained'} onClick={() => onSelect(data)}>Select</Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

EditionArticlesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  editionDetailFetch: PropTypes.func.isRequired,
  editionDetail: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

EditionArticlesDialog.defaultProps = {
  editionDetail: null,
};

export default withStyles(style)(connect(({
  edition: { editionDetail },
  router: { location },
}) => ({
  editionDetail, location,
}), { editionDetailFetch })(EditionArticlesDialog));
