import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Select from '../../../ui/Select';

import { fetchScoreBoardMatch } from '../../../../actions/scoreboard';
import { setLocalProp } from '../../../../actions/dataState';
import { FIELD_SCOREBOARD_MATCH, FIELD_SCOREBOARD_SEASON } from '../../../../constants/article/articleFields';

const styles = {
  loader: {
    minHeight: 52,
    lineHeight: '52px',
  },
};

const noValue = 'Choose match';

const Match = ({
  classes,
  competitionMatches,
  selectedSeason,
  selectedMatch,
  isMatchLoading,
  setLocalProp: setProp,
  fetchScoreBoardMatch: onFetch,
}) => {
  useEffect(() => {
    if (selectedSeason && competitionMatches.length === 0) {
      onFetch(selectedSeason);
    }
  }, [selectedSeason]);

  if (competitionMatches.length === 0 && !isMatchLoading) {
    return null;
  }

  const options = competitionMatches.reduce((acc, { matchInfo: { date, id, description } }) => {
    const formattedDate = moment(date).format('DD MMM YYYY');
    const label = `${formattedDate} - ${description}`;
    return ({
      ...acc,
      [id]: label,
    });
  }, { [noValue]: noValue });

  const getValue = () => {
    if (Object.keys(options).find(x => x === selectedMatch)) {
      return selectedMatch;
    }
    return noValue;
  };

  return (<Grid container alignItems={'center'}>
    <Grid item xs={4}>
      <Typography variant={'h3'}>Match</Typography>
    </Grid>
    <Grid item xs={8}>
      {isMatchLoading && <Typography varient={'caption'} className={classes.loader}>Loading...</Typography>}
      {!isMatchLoading && <Select
        items={options}
        value={getValue()}
        onChange={(e) => {
          const value = e.target.value;
          if (value && value !== noValue) {
            setProp(FIELD_SCOREBOARD_MATCH, value);
          }
        }}
      />}
    </Grid>
  </Grid>);
};

Match.propTypes = {
  classes: PropTypes.object.isRequired,
  competitionMatches: PropTypes.array.isRequired,
  fetchScoreBoardMatch: PropTypes.func.isRequired,
  setLocalProp: PropTypes.func.isRequired,
  isMatchLoading: PropTypes.bool.isRequired,
  selectedMatch: PropTypes.string,
  selectedSeason: PropTypes.string,
};

Match.defaultProps = {
  selectedMatch: '',
  selectedSeason: '',
};

export default withStyles(styles)(connect(
  ({
    scoreboard: {
      competitionMatches, isMatchLoading,
    },
    dataState: {
      [FIELD_SCOREBOARD_MATCH]: selectedMatch,
      [FIELD_SCOREBOARD_SEASON]: selectedSeason,
    },
  }) => ({ competitionMatches, selectedMatch, selectedSeason, isMatchLoading }),
  { fetchScoreBoardMatch, setLocalProp },
)(Match));

