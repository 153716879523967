export const FIELD_DEV = 'dev';
export const FIELD_UAT = 'uat';
export const FIELD_PROD = 'prod';

export const CONFIG_PAYLOAD_FIELDS = [
  FIELD_DEV,
  FIELD_UAT,
  FIELD_PROD,
];

export const CONFIG_FIELD_MAPPING = [
  [FIELD_DEV, 'field_config_dev', 'DEV'],
  [FIELD_UAT, 'field_config_uat', 'UAT'],
  [FIELD_PROD, 'field_config_prod', 'PROD'],
];
