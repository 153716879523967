import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockField from '../../../fieldLock/FieldLockField';
import ToggleSwitch from '../../../ui/ToggleSwitch';
import { makeGetFieldHasChanges, makeGetFieldLockValue, makeGetFieldValue } from '../../../../selectors/fieldLock/fieldLock';
import { setLocalProp } from '../../../../actions/dataState';
import { showNotification } from '../../../../actions/notification';
import { makeGetSectionToggleDisabled } from '../../../../selectors/article/article';
import { WARNING } from '../../../../constants/actionTypes/notification';

const ArticleFieldLockToggle = ({
  label,
  field,
  value,
  lockValue,
  hasChanges,
  disabled,
  setLocalProp: setProp,
  showNotification: notify,
  ...rest
}) => (
  <FieldLockField field={field} lockValue={lockValue}>
    <ToggleSwitch
      label={label}
      disabled={disabled}
      hasChanges={hasChanges}
      value={disabled ? true : value}
      onChange={e => setProp(field, e.target.checked)}
      onClick={() => {
        if (disabled) {
          notify({
            message: 'locked on section level',
            variant: WARNING,
          });
        }
      }}
      {...rest}
    />
  </FieldLockField>
);

ArticleFieldLockToggle.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  value: PropTypes.bool,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

ArticleFieldLockToggle.defaultProps = {
  value: false,
  lockValue: null,
  hasChanges: false,
  disabled: false,
};

const mapStateToProps = (state, props) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  const getSectionToggleDisabled = makeGetSectionToggleDisabled(props.field);
  return {
    value: !!getFieldValue(state, props),
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
    disabled: getSectionToggleDisabled(state),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp, showNotification },
)(ArticleFieldLockToggle);
