import HideAndShowElement from './components/HideAndShowElement';

export const ELEMENT_HIDE_AND_SHOW = 'hideAndShow';
export const PROP_MARKUP = 'markup';
export const PROP_SHOW_PROMPT = 'showPrompt';
export const PROP_HIDE_PROMPT = 'hidePrompt';

export const DEFAULTS_HIDE_AND_SHOW = {
  hideAndShow: {
    component: HideAndShowElement,
    type: ELEMENT_HIDE_AND_SHOW,
    rootProps: {
      className: 'slate-hideAndShow',
    },
  },
};
