import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Close';
import { SortableHandle } from 'react-sortable-hoc';

import { removeStoryPage, setStoryContext } from '../../../actions/story';
import {
  PAGE_CONFIG,
  PAGE_COVER,
  PAGE_IMAGE,
  PAGE_POLL,
  PAGE_POLL_BINARY,
  PAGE_QUIZ,
  PAGE_RESULTS,
  PAGE_TEXT,
} from '../../../constants/story/storyComponents';
import FlowPaper from '../../common/layout/FlowPaper';
import TemplateCover from './templates/TemplateCover';
import TemplateImage from './templates/TemplateImage';
import TemplateText from './templates/TemplateText';
import TemplatePoll from './templates/TemplatePoll';
import TemplateBinaryPoll from './templates/TemplatePollBinary';
import TemplateQuiz from './templates/TemplateQuiz';
import TemplateResults from './templates/TemplateResults';
import DragHandle from '../../ui/DragHandle';
import IconButton from '../../ui/buttons/IconButton';
import { CONTEXT_STORY } from '../../../constants/contexts';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(2),
    width: 'calc(100% - 28px)',
  },
  actions: {
    float: 'right',
  },
});

const SortDragHandle = SortableHandle(({ ...rest }) => <DragHandle {...rest} />);

const getTemplate = (type) => {
  switch (type) {
    case PAGE_COVER:
      return TemplateCover;
    case PAGE_IMAGE:
      return TemplateImage;
    case PAGE_TEXT:
      return TemplateText;
    case PAGE_POLL:
      return TemplatePoll;
    case PAGE_POLL_BINARY:
      return TemplateBinaryPoll;
    case PAGE_QUIZ:
      return TemplateQuiz;
    case PAGE_RESULTS:
      return TemplateResults;
    default:
      return null;
  }
};

const Page = (props) => {
  const {
    classes, data,
    removeStoryPage: removePage,
    setStoryContext: setContext,
  } = props;
  const { type, id } = data;
  const PageTemplate = getTemplate(type);
  return (
    <FlowPaper className={classes.root} onClick={() => setContext(CONTEXT_STORY)}>
      <div className={classes.content}>
        <div className={classes.actions}>
          <IconButton
            onClick={() =>
              window.confirm('Please confirm you want to remove this page') && removePage(id)
            }
          ><RemoveIcon /></IconButton>
        </div>
        <Typography variant={'h2'} color={'primary'}>{PAGE_CONFIG[type].title}</Typography>
        {PageTemplate && <PageTemplate {...data} />}
      </div>
      <SortDragHandle />
    </FlowPaper>
  );
};

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  removeStoryPage: PropTypes.func.isRequired,
  setStoryContext: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { removeStoryPage, setStoryContext },
)(Page));
