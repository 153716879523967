import React from 'react';
import PropTypes from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import Grid from '@material-ui/core/Grid';
import Crop75Icon from '@material-ui/icons/Crop75';

import EmbedWrapper from '../../../common/EmbedWrapper';
import TextField from '../../../../../ui/TextField';

import { PROP_DESCRIPTION, PROP_HEADING, PROP_TITLE, PROP_URL } from '../defaults';

const ButtonLinkElement = (props) => {
  const { attributes, children, element, className } = props;
  const editor = useSlateStatic();
  const fields = [
    // key, label, placeholder
    [PROP_TITLE, 'Button text', 'Insert text'],
    [PROP_URL, 'Button URL', 'Insert url'],
    [PROP_HEADING, 'Heading', 'Insert heading'],
    [PROP_DESCRIPTION, 'Description', 'Insert description'],
  ]
  return (
    <div className={className} {...attributes}>
      <EmbedWrapper
        title={'Button'}
        Icon={Crop75Icon}
        contentEditable={false}
        element={element}
      >
        <Grid container spacing={1}>
          {fields.map(([key, label, placeholder]) => (<Grid key={key} item xs={12}>
            <TextField
              label={label}
              margin={'none'}
              placeholder={placeholder}
              onClick={e => e.stopPropagation()}
              onChange={(e) => {
                const path = ReactEditor.findPath(editor, element);
                Transforms.setNodes(editor, { [key]: e.target.value }, { at: path });
              }}
              value={element[key] || ''}
            />
          </Grid>))}
        </Grid>
      </EmbedWrapper>
      {children}
    </div>
  );
};

ButtonLinkElement.propTypes = {
  element: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  attributes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default ButtonLinkElement;
