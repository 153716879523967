import React from 'react';
import PropTypes from 'prop-types';
import withScrolling from 'react-dnd-scrolling';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { headerHeight } from '../../header';
import { footerHeight } from './Page';
import UploadBusyIndicator from '../../media/UploadBusyIndicator';

const styles = theme => ({
  content: {
    padding: theme.spacing(4),
    maxWidth: 1320,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(100% - ${theme.spacing(8)}px)`,
  },
});

const ScrollWrapper = withScrolling('div');

export const PageContentWrapper = withStyles(() => ({
  root: {
    width: '100%',
    height: `calc(100vh - ${headerHeight + footerHeight}px)`,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  rootScroll: {
    width: '100%',
    flex: 1,
    overflowY: 'scroll',
  },
}))(({ classes, children, scroll, scrollRef }) => {
  return scroll
    ? (<ScrollWrapper ref={scrollRef} className={classes.rootScroll}>{children}</ScrollWrapper>)
    : (<div className={classes.root}>{children}</div>);
});

const PageContent = ({ classes, className, children, direction, scroll, scrollRef }) => (
  <PageContentWrapper scroll={scroll} scrollRef={scrollRef}>
    <UploadBusyIndicator />
    <div className={
      [
        classes.content,
        className,
      ].filter(str => !!str).join(' ')}
    >
      <Grid container spacing={4} direction={direction}>
        {children}
      </Grid>
    </div>
  </PageContentWrapper>
);

PageContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.string,
  className: PropTypes.string,
  scroll: PropTypes.bool,
  scrollRef: PropTypes.object,
};

PageContent.defaultProps = {
  direction: 'column',
  className: '',
  scroll: false,
  scrollRef: null,
};

export default withStyles(styles)(PageContent);
