import moment from 'moment';

import {
  PAGE_TYPE_ARTICLE,
  PAGE_TYPE_SECTION,
  PAGE_TYPE_SINGLE_IMAGE,
} from '../../constants/edition/edition';

import {
  PAYLOAD_FIELD_KEYS,
  EDITION_FIELD_MAPPING_CMS_KEYED,
  LOCKABLE_FIELDS,
  FIELD_HERO_IMAGE,
  FIELD_EDITION_SECTIONS,
  EXTRA_FIELD_KEYS,
  FIELD_NOTES,
  FIELD_BODY,
  FIELD_EDITION_TEMPLATES,
  FIELD_AUTHORS,
  FIELD_CONTENT_SOURCE,
  FIELD_WORKFLOW,
} from '../../constants/edition/editionFields';
import {
  FIELD_HERO_IMAGE_LOCAL_CAPTION,
  FIELD_HERO_IMAGE_LOCAL_COPYRIGHT, FIELD_LOCAL_CAPTION,
  FIELD_LOCAL_COPYRIGHT,
} from '../../constants/common/commonFields';

export const processPayloadBody = (comp) => {
  // clean up editor junk
  // eslint-disable-next-line no-param-reassign
  delete comp.editorState;
  return comp;
};

const getBundleTargetId = (type) => {
  switch (type) {
    case PAGE_TYPE_ARTICLE:
      return 'pugpig_article';
    case PAGE_TYPE_SECTION:
      return 'pugpig_section_page';
    case PAGE_TYPE_SINGLE_IMAGE:
      return 'pugpig_single_image';
    default:
      return 'pugpig_article';
  }
};

const getPayloadDataKey = (field) => {
  switch (field) {
    default:
      return 'value';
  }
};

const processPayloadData = (data, field) => {
  switch (field) {
    case FIELD_BODY:
      return JSON.stringify(data.map(processPayloadBody));
    case FIELD_NOTES:
      return JSON.stringify(data);
    default:
      return data;
  }
};

const getPayloadDefaults = edition => ({
  bundle: [{
    target_id: getBundleTargetId(edition.pageType),
    target_type: 'flowz_collection_type',
  }],
  field_pugpig_updated_time: [{
    value: moment().unix(),
  }],
  field_pugpig_publish_date: [{
    value: moment().unix(),
  }],
});

const addPayloadFields = (payload, edition, excludeFields = []) => {
  const modifiedPayload = payload;
  const { pageType } = edition;
  PAYLOAD_FIELD_KEYS[pageType].forEach((field) => {
    if (excludeFields.indexOf(field) !== -1) return;
    const mapping = EDITION_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      case FIELD_EDITION_TEMPLATES:
        modifiedPayload[mapping.key] = edition[field].map(({ id: templateId }) => ({
          value: templateId,
        })) || [];
        break;

      case FIELD_WORKFLOW:
      case FIELD_CONTENT_SOURCE:
      case FIELD_EDITION_SECTIONS:
      case FIELD_AUTHORS:
        modifiedPayload[mapping.key] = edition[field].map(({ id: termId }) => ({
          target_type: 'taxonomy_term',
          target_id: Number(termId),
        })) || [];
        break;
      case FIELD_HERO_IMAGE:
        if (edition[field].data.mid) {
          modifiedPayload[mapping.key] = [{
            target_type: 'media',
            target_id: edition[field].data.mid,
          }];
        } else {
          // make sure user can remove the hero
          modifiedPayload[mapping.key] = [];
        }
        break;
      default:
        modifiedPayload[mapping.key] = [{
          [getPayloadDataKey(field)]: processPayloadData(edition[field], field),
        }];
        break;
    }
  });
  return modifiedPayload;
};

const addPayloadExtraFields = (payload, edition, excludeFields = []) => {
  const modifiedPayload = payload;
  const { pageType, articleEditionFromServer } = edition;
  let extra = {};
  if (
    Array.isArray(articleEditionFromServer?.field_pugpig_extra_json) &&
    articleEditionFromServer?.field_pugpig_extra_json.length > 0) {
    extra = JSON.parse(articleEditionFromServer?.field_pugpig_extra_json[0].value);
  }
  EXTRA_FIELD_KEYS[pageType].forEach((field) => {
    if (excludeFields.indexOf(field) !== -1) return;
    const mapping = EDITION_FIELD_MAPPING_CMS_KEYED[field];
    if (typeof mapping === 'undefined') {
      // eslint-disable-next-line no-console
      console.log(`Mapping not found for ${field}`);
      return;
    }
    switch (field) {
      case FIELD_HERO_IMAGE_LOCAL_COPYRIGHT:
        if (edition[FIELD_HERO_IMAGE] && typeof edition[FIELD_HERO_IMAGE].data[FIELD_LOCAL_COPYRIGHT] === 'string') {
          extra[FIELD_HERO_IMAGE_LOCAL_COPYRIGHT] =
            edition[FIELD_HERO_IMAGE].data[FIELD_LOCAL_COPYRIGHT];
        }
        break;
      case FIELD_HERO_IMAGE_LOCAL_CAPTION:
        if (edition[FIELD_HERO_IMAGE] && typeof edition[FIELD_HERO_IMAGE].data[FIELD_LOCAL_CAPTION] === 'string') {
          extra[FIELD_HERO_IMAGE_LOCAL_CAPTION] =
            edition[FIELD_HERO_IMAGE].data[FIELD_LOCAL_CAPTION];
        }
        break;
      default:
        extra[mapping.key] = edition[field];
        break;
    }
  });
  if (extra !== {}) {
    modifiedPayload.field_pugpig_extra_json = [{
      value: JSON.stringify(extra),
    }];
  }
  return modifiedPayload;
};

export const getEditionPayload = (edition, updatedFields = []) => {
  const excludeFields = updatedFields.length > 0
    ? LOCKABLE_FIELDS.filter(field => updatedFields.indexOf(field) === -1)
    : [];
  let payload = getPayloadDefaults(edition);
  payload = addPayloadFields(payload, edition, excludeFields);
  payload = addPayloadExtraFields(payload, edition, excludeFields);

  return payload;
};

export const preserveEditionState = (...params) => {
  const state = getEditionPayload(...params);

  // remove anything with unix time
  return {
    ...state,
    field_pugpig_updated_time: [],
    field_pugpig_publish_date: [],
  };
};
