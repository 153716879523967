import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageToolbar from '../components/common/page/PageToolbar';

const style = {
  link: {
    textDecoration: 'none',
    marginTop: '30px',
  },
  h1: {
    margin: '20px',
  },
  p: {
    margin: '10px',
  },
};

const NotFound = ({ classes }) => (
  <Page toolbarContent={<PageToolbar breadcrumbText={'Page not found'} />}>
    <PageContent>
      <Grid item xs={12}>
        <Typography className={classes.h1} component={'h1'} variant={'h4'}>Page not found.</Typography>
        <Typography className={classes.p} component={'p'}>The requested page does not exist</Typography>
        <Typography className={classes.p} component={'p'}>Please go to dashboard by clicking the link below.</Typography>
        <Link to={'/'} className={classes.link}><Button color="primary" variant="contained">Home</Button></Link>
      </Grid>
    </PageContent>
  </Page>
);

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(NotFound);
