import { setDefaults } from '@udecode/slate-plugins';
import renderElementBooking from './renderElementBooking';
import { DEFAULTS_BOOKING } from './defaults';

const BookingPlugin = (options) => {
  const { booking } = setDefaults(options, DEFAULTS_BOOKING);
  return ({
    renderElement: renderElementBooking(options),
    voidTypes: [booking.type],
  });
};

export default BookingPlugin;
