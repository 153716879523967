import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { reorderManualListItem } from "actions/manualList";

import ManualListItem from "./ManualListItem";

const ComponentItem = ({ component, position }) => {
  return <ManualListItem component={component} position={position} />;
};

ComponentItem.propTypes = {
  component: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

const ComponentContainer = ({ items }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem
        key={`${value.target_uuid}${index}`}
        position={index}
        index={index}
        component={value}
      />
    ))}
  </div>
);

ComponentContainer.propTypes = {
  items: PropTypes.array.isRequired,
};

const SortableItem = SortableElement((props) => <ComponentItem {...props} />);
const SortableList = SortableContainer((props) => (
  <ComponentContainer {...props} />
));

const ManualListItems = () => {
  const { manualListArticles } = useSelector((state) => state.manualList);
  const dispatch = useDispatch();
  const handleReorder = ({ oldIndex, newIndex }) =>
    dispatch(reorderManualListItem(oldIndex, newIndex));
  if (!manualListArticles || manualListArticles.length < 1) {
    return null;
  }
  return (
    <SortableList
      distance={1}
      items={manualListArticles}
      onSortEnd={handleReorder}
    />
  );
};

export default ManualListItems;
