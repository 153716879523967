import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import withStyles from '@material-ui/core/styles/withStyles';

import { Table, TableBody, TableRow, TableCell, TableHead, TableHeadCell } from '../ui/table/Table';

const styles = theme => ({
  root: {
    width: '100%',
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  selected: {
    background: theme.palette.background.grey1,
  },
  row: {
    cursor: 'pointer',
  },
});

function PremiumAdTable(props) {
  const {
    classes,
    items,
    push: routeChange,
    selectedId,
    pathname,
  } = props;

  return (
    <Table>
      <TableHead>
        <TableRow hasBorder>
          <TableHeadCell>Title</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          items.map(({ data: { name, id } }) => (
            <TableRow
              hover
              tabIndex={-1}
              key={id}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                routeChange(`${pathname.replace(/\/\d+$/, '')}/${id}`);
              }}
              className={selectedId === id ? classes.selected : classes.row}
            >
              <TableCell>{name}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

PremiumAdTable.propTypes = {
  classes: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  selectedId: PropTypes.string,
  items: PropTypes.array,
};

PremiumAdTable.defaultProps = {
  selectedId: null,
  items: [],
};

export default withStyles(styles)(connect(
  ({ router: { location: { pathname } } }) => ({ pathname }),
  { push },
)(PremiumAdTable));
