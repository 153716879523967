import './utils/wdyr';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'rxjs';
import * as Sentry from '@sentry/react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SnackbarProvider } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { loadTranslations, setLocale } from 'react-redux-i18n';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import { fade } from '@material-ui/core/styles/colorManipulator';
import 'react-sortable-tree/style.css';

import translation from './i18n/translation';

import App from './App';
import configureStorage, { history } from './store';
import { appLoad } from './actions/page';

const version = process.env.REACT_APP_CURRENT_RELEASE_TAG || 'dev';

if (!/localhost|127\.0\.0/.test(document.location.host)) {
  Sentry.init({
    dsn: 'https://72c7d65b75a7463d907a35785843aece@o435077.ingest.sentry.io/5392919',
    release: `flow-cms@${version.replace(/^v/, '')}`,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

const { store, persistor } = configureStorage();

export const exportedStore = store;

// eslint-disable-next-line
export const perst = persistor;

// build shadow array for 25 elevations required
const shadows = Array(24).fill('-3px 9px 21px 0 rgba(140,160,179,0.27)');
shadows.unshift('none');

const PRIMARY_MAIN = '#2196F3';

export const THEME = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1520,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
    h1: {
      fontSize: 32,
      lineHeight: '30px',
      fontWeight: 300,
      whiteSpace: 'nowrap',
    },
    h2: {
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '24px',
    },
    h3: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: 1,
      lineHeight: '18px',
    },
    h4: {
      fontSize: 10,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '18px',
      color: '#666',
    },
    h5: {
      fontSize: 10,
      fontWeight: 400,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: '10px',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#666',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#666',
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '0.6px',
      lineHeight: '16px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.4px',
      lineHeight: '16px',
      color: '#666',
    },
    color: {
      secondary: '#666',
      primary: '#212121',
    },
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      label: '#37474F',
      glow: '0 10px 38px 0 rgba(231,121,43,0.3)',
    },
    secondary: {
      main: '#E7792B',
      gradient: 'linear-gradient(0deg, #485173 0%, #24283E 100%)',
    },
    error: {
      main: '#E43532',
    },
    ui: {
      background: '#CCC',
    },
    brightsites: {
      brand: '#E7792B', // @todo that can possibly be depricated in favour of secondary
    },
    border: {
      primary: {
        main: grey[400],
        light: grey[300],
      },
      secondary: {
        main: '#8CA0B3',
        dark: '#405467',
      },
      tertiary: {
        main: '#202124',
      },
    },
    background: {
      default: '#f4f4f4',
      contrast: '#fff',
      grey1: '#eee',
      grey2: '#f2f2f2',
      paleBlue: '#E5F8FE',
      hover: '#F6FBFF',
    },
    grey: {
      lighter: '#EEE',
      light: '#DDD',
      main: '#A9A9A9',
    },
    caption: {
      background: '#333',
      color: '#f1f1f1',
    },
    menuLink: {
      gradient: 'linear-gradient(90deg, rgb(126,142,218,0.25) 0%, rgba(255,255,255,0) 100%)',
    },
    selection: fade(blue[400], 0.3),
  },
  border: {
    radius: 4,
    color: 'rgba(0, 0, 0, 0.23)',
  },
  shadows,
  customShadows: {
    list: '0 3px 8px 0 rgba(0, 0, 0, 0.38)',
    default: '0 1px 5px 0 rgba(0, 0, 0, 0.2)',
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.26)',
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiMenuItem: {
      root: {
        borderLeft: '4px solid #fff',
        '&:hover': {
          borderLeft: '4px solid #E7792B',
          backgroundColor: '#F6FBFF',
        },
        '&.active': {
          borderLeft: '0 none',
          backgroundColor: '#E5F8FE',
          '&:hover': {
            borderLeft: '0 none',
            backgroundColor: '#E5F8FE',
          },
        },
        '&.Mui-selected': {
          borderLeft: '0 none',
          backgroundColor: '#E5F8FE',
          '&:hover': {
            borderLeft: '0 none',
            backgroundColor: '#E5F8FE',
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#666',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: PRIMARY_MAIN,
        },
      },
    },
    MuiTableHead: {
      root: {
        background: '#AAD7FB',
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: 'rgba(198, 203, 212, 0.3)',
      },
      head: {
        color: '#212121',
        fontWeight: 400,
      },
      body: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: 12,
        letterSpacing: '0.1px',
        lineHeight: '20px',
        '& > *:last-child': {
          marginBottom: -6,
        },
      },
    },
  },
});

const Index = () => {
  useEffect(() => {
    store.dispatch(appLoad());
    const state = store.getState();
    const { frame: { selectedPublication: { publicationConfig } } } = state;
    loadTranslations(translation)(store.dispatch);
    setLocale(publicationConfig ? publicationConfig.language : 'en')(store.dispatch);
  });
  return (
    <MuiThemeProvider theme={THEME}>
      <Provider store={store} >
        <DndProvider backend={HTML5Backend}>
          <ConnectedRouter history={history}>
            <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <PersistGate loading={null} persistor={persistor}>
                <App />
              </PersistGate>
            </SnackbarProvider>
          </ConnectedRouter>
        </DndProvider>
      </Provider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(
  <Index />,
  document.getElementById('root'),
);
