import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_FAQ } from './defaults';

const renderElementfaq = (options) => {
  const { faq } = setDefaults(options, DEFAULTS_FAQ);
  return getRenderElement(faq);
};

export default renderElementfaq;
