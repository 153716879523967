import React from 'react';

import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import ArticlePublication from './fields/ArticlePublication';

const ArticlePublications = () => (
  <ContextPanel>
    <ContextPanelSummary>
      <ContextPanelHeading>Publications</ContextPanelHeading>
    </ContextPanelSummary>
    <ContextPanelDetails variant={'full'}>
      <ArticlePublication />
    </ContextPanelDetails>
  </ContextPanel>
);

export default ArticlePublications;

