import React from 'react';

import {
  FIELD_IS_HIDDEN_FROM_FACEBOOK,
  FIELD_IS_HIDDEN_FROM_AMP,
  FIELD_IS_HIDDEN_FROM_APPLE,
  FIELD_IS_HIDDEN_FROM_VIDEO
} from '../../../constants/article/articleFields';
import {
  ContextPanel, ContextPanelDetails,
  ContextPanelSummary,
  ContextPanelHeading,
} from '../../common/context/ContextPanel';
import FieldLockToggle from '../../fieldLock/form/FieldLockToggle';
import { useSelector } from 'react-redux';
import { DOMAINS_JPI } from 'constants/domainConfig';

const ArticlePlatforms = () => {
  const { selectedPublication } = useSelector((state) => state.frame);
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeading>Platforms</ContextPanelHeading>
      </ContextPanelSummary>
      <ContextPanelDetails>
        <FieldLockToggle field={FIELD_IS_HIDDEN_FROM_FACEBOOK} label={'Hide from Facebook IA'} />
        <FieldLockToggle field={FIELD_IS_HIDDEN_FROM_AMP} label={'Hide from AMP'} />
        <FieldLockToggle field={FIELD_IS_HIDDEN_FROM_APPLE} label={'Hide from Apple News'} />
        {DOMAINS_JPI.includes(selectedPublication.domain) && <FieldLockToggle field={FIELD_IS_HIDDEN_FROM_VIDEO} label={'Hide from NewsNow'} />}
      </ContextPanelDetails>
    </ContextPanel>
  )
}

export default ArticlePlatforms;
