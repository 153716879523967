import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_TIKTOK } from './defaults';

const renderElementTiktok = (options) => {
  const { tiktok } = setDefaults(options, DEFAULTS_TIKTOK);
  return getRenderElement(tiktok);
};

export default renderElementTiktok;
