export const TITLE = 'title';
export const STRAPLINE = 'strapline';
export const HEADSHOTSX4 = 'HeadshotsX4';
export const ARTICLEHERO = 'ArticleHero';
export const ARTICLEX1 = 'ArticleX1';
export const ARTICLEX2 = 'ArticleX2';
export const POSTER = 'poster';
export const TEXTSPLASH = 'TextSplash';


export const SPORT_TITLE = {
  type: TITLE, name: 'TITLE', includeLogo: true, title: 'Sport',
};
