import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import { requestFieldLockUnlock } from '../../actions/fieldLock';
import FieldLockOverlay from './FieldLockOverlay';

const styles = () => ({
  lock: {
    position: 'relative',
  },
});

const getFieldLock = (lockedFields, currentField) => (
  Array.isArray(lockedFields)
    ? lockedFields.filter(({ field }) => field === currentField)
    : null
);

/**
 * @deprecated
 */
const FieldLockContainerDepricated = (props) => {
  const {
    classes, children, className, classWrapper,
    field, onUnlock, sessionLockedFields,
    unlockFieldLock: unlock,
    ...rest
  } = props;
  const lock = getFieldLock(sessionLockedFields, field);

  return (
    <div className={classWrapper ? `${classWrapper} ${classes.lock}` : classes.lock} {...rest}>
      {children}
      {lock && lock.length > 0 && <FieldLockOverlay
        className={className}
        lock={lock[0]}
        onUnlock={() => {
          unlock(lock[0]);
          onUnlock();
        }}
      />}
    </div>
  );
};

FieldLockContainerDepricated.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  classWrapper: PropTypes.string,
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
  onUnlock: PropTypes.func.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  unlockFieldLock: PropTypes.func,
};

FieldLockContainerDepricated.defaultProps = {
  unlockFieldLock: () => {},
  className: null,
  classWrapper: null,
};

export default withStyles(styles)(connect(
  ({ fieldLock: { sessionLockedFields } }) => ({ sessionLockedFields }),
  { unlockFieldLock: requestFieldLockUnlock },
)(FieldLockContainerDepricated));
