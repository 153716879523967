import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import DragIndicator from '@material-ui/icons/DragIndicator';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'move',
    border: '0 none',
    padding: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      fontSize: '1.15em',
      width: 20,
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    cursor: 'default',
    '& svg': {
      color: 'rgba(0,0,0,0.1)',
    },
  },
});

const DragHandle = ({ classes, disabled, className }) => {
  const dragClasses = [classes.root, className];
  if (disabled) {
    dragClasses.push(classes.disabled);
  }
  return (
    <div className={dragClasses.filter(name => name !== '').join(' ')}>
      <DragIndicator fontSize={'small'} />
    </div>
  );
};

DragHandle.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

DragHandle.defaultProps = {
  className: '',
  disabled: false,
};

export default withStyles(styles)(DragHandle);
