import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Dialog from './common/Dialog';

const style = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  compRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0, 1),
  },
  fieldContainer: {
    marginBottom: theme.spacing(4),
  },
});

const ManualListLabelDialogSelector = (props) => {
  const { classes, onSubmit, ...rest } = props;
  const [value, setValue] = useState('');
  return (<Dialog title={'Create new manual list'} {...rest}>
    <div className={classes.root}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(value);
        }}
      >
        <FormControl fullWidth className={classes.fieldContainer}>
          <TextField label={'Add manual list name'} onChange={e => setValue(e.target.value)} />
        </FormControl>
        <Grid container justify={'center'}>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => rest.handleClose()}
          >Cancel</Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type={'submit'}
          >Save</Button>
        </Grid>
      </form>
    </div>
  </Dialog>);
};

ManualListLabelDialogSelector.defaultProps = {
  items: [],
};

ManualListLabelDialogSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(
  ({ manualList: { manualList } }) => ({ manualList }),
  {},
)(ManualListLabelDialogSelector));
