import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FieldLockContainer from '../FieldLockContainer';
import TagSelector from '../../ui/TagSelector';
import { makeGetFieldHasChanges, makeGetFieldLockValue } from '../../../selectors/fieldLock/fieldLock';

const FieldLockVocabSelector = ({
  vocab, terms, label, field, hierarchical,
  lockValue, hasChanges, margin,
  onSelect, onRemove,
  limit, required, children, showChildren, ...rest
}) => (
  <>
    <FieldLockContainer field={field} lockValue={lockValue}>
      <TagSelector
        hasChanges={hasChanges}
        required={required}
        label={label}
        placeholder={`Search ${label}`}
        selectedTerms={terms}
        vocab={vocab}
        onSelect={onSelect}
        onRemove={onRemove}
        limit={limit}
        hierarchical={hierarchical}
        margin={margin}
        {...rest}
      />
    </FieldLockContainer>
    {(showChildren || terms.length > 0) && children && children}
  </>
);

FieldLockVocabSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  vocab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  terms: PropTypes.array,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
  hierarchical: PropTypes.bool,
  limit: PropTypes.number,
  required: PropTypes.bool,
  children: PropTypes.node,
  showChildren: PropTypes.bool,
  margin: PropTypes.string,
};

FieldLockVocabSelector.defaultProps = {
  terms: [],
  required: false,
  hierarchical: false,
  limit: -1,
  children: null,
  showChildren: false,
  lockValue: null,
  hasChanges: false,
  margin: 'none',
};

const mapStateToProps = (state, props) => {
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    lockValue: getFieldLockValue(state, props),
    hasChanges: getFieldHasChanges(state, props),
  };
};

export default connect(
  mapStateToProps,
  {},
)(FieldLockVocabSelector);

