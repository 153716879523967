import React, { useState } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import {
  ToolbarButton,
} from '@udecode/slate-plugins';
import Tooltip from '@material-ui/core/Tooltip';
import { Omega } from 'mdi-material-ui';
import SpecialCharactersDialog from '../../dialog/SpecialCharactersDialog';

const ToolbarSpecialChar = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selection, setSelection] = useState(null);
  const editor = useSlate();
  return (
    <>
      <ToolbarButton
        onMouseDown={(e) => {
          e.preventDefault();
          setSelection(editor.selection);
          setOpenDialog(true);
        }}
        icon={<Tooltip title={'Special character'} arrow><Omega /></Tooltip>}
        {...props}
      />
      {openDialog && <SpecialCharactersDialog
        open={openDialog}
        onSelect={(char) => {
          ReactEditor.focus(editor);
          Transforms.select(editor, selection);
          Transforms.insertText(editor, char);
          setTimeout(() => {
            // need to wait for focus to be complete
            ReactEditor.focus(editor);
            Transforms.select(editor, {
              ...selection.anchor,
              offset: selection.anchor.offset + 1,
            });
          }, 30);
          setOpenDialog(false);
        }}
        handleClose={() => {
          setOpenDialog(false);
        }}
      />}
    </>
  );
};

export default ToolbarSpecialChar;
