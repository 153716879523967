import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import LiveblogEmbed from '../dialog/LiveblogEmbedDialog';

const styles = theme => ({
  root: {
    marginRight: theme.spacing(2),
  },
  offscreen: {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  },
});

const LiveblogEmbedButton = ({
  currentContainerFromServer, classes,
}) => {
  const [embedDialog, setEmbedDialog] = useState(false);

  return (<div className={classes.root}>
    <Button disabled={!currentContainerFromServer} onClick={() => { setEmbedDialog(true); }}>Embed</Button>
    {embedDialog && <LiveblogEmbed
      open={embedDialog}
      handleClose={() => setEmbedDialog(false)}
    >
      <div dangerouslySetInnerHTML={{ __html: `<pre id="liveblogId">${currentContainerFromServer.data.id}</pre>` }} />
    </LiveblogEmbed>
    }
  </div>);
};

LiveblogEmbedButton.propTypes = {
  currentContainerFromServer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(({
  liveblog: { currentContainerFromServer },
}) => ({ currentContainerFromServer }), {})(LiveblogEmbedButton));
