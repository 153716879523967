import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/EditOutlined";
import RemoveIcon from "@material-ui/icons/Close";
import DragIndicator from "@material-ui/icons/DragIndicator";

import { removeManualListItem, setManualListContext } from "actions/manualList";

import { OVERRIDE_TITLE } from "constants/layout/layout";

import IconButton from "components/ui/buttons/IconButton";
import FlowPaper from "components/common/layout/FlowPaper";

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
  },
  rowContainer: {
    justifyContent: "space-between",
    display: "flex",
    flexBasis: "100%",
  },
  cta: {
    display: "flex",
    alignItems: "center",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: "15px",
    margin: "15px 0",
    color: theme.palette.border.secondary.main,
  },
  index: {
    color: theme.palette.primary.main,
  },
});

const ManualListItem = ({
  classes,
  component: { label, overrides, target_uuid: uuid, target_id },
  position,
}) => {
  const dispatch = useDispatch();
  const handleRemove = () =>
    window.confirm(`Please confirm you want to remove '${label}'`) &&
    dispatch(removeManualListItem(target_id));
  const handleSetContext = () => dispatch(setManualListContext(position));
  return (
    <FlowPaper className={classes.root}>
      <div className={classes.rowContainer}>
        <div className={classes.titleContainer}>
          <DragIndicator color="disabled" fontSize="small" />
          <Typography color="primary" component="h3" className={classes.title}>
            <span className={classes.index}>{position + 1}.</span>{" "}
            {overrides?.[OVERRIDE_TITLE] || label}
          </Typography>
        </div>
        <div className={classes.cta}>
          <IconButton onClick={handleSetContext} aria-label="Edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleRemove} aria-label="Remove">
            <RemoveIcon />
          </IconButton>
        </div>
      </div>
    </FlowPaper>
  );
};

ManualListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
};

export default withStyles(styles)(ManualListItem);
