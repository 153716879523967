import {
  MENU_TREE_ONCHANGE,
  MENU_TREE_ONEDIT,
  MENU_TREE_ONREMOVE,
  MENU_ITEM_SECTION,
  MENU_DIALOG_CLOSE,
  MENU_DIALOG_OPEN,
  MENU_SAVE, MENU_ITEM_CHAIN, MENU_FETCH_LIST, MENU_TREE_FETCH,
} from '../constants/actionTypes';

const menuOnChange = value => ({ type: MENU_TREE_ONCHANGE, value });
export const menuOnRemove = value => ({ type: MENU_TREE_ONREMOVE, value });
export const menuOnEdit = value => ({ type: MENU_TREE_ONEDIT, value });
export const selectSection = value => ({ type: MENU_ITEM_SECTION, value });
export const selectChain = value => ({ type: MENU_ITEM_CHAIN, value });
export const closeMenuDialog = () => ({ type: MENU_DIALOG_CLOSE });
export const openMenuDialog = () => ({ type: MENU_DIALOG_OPEN });
export const saveMenu = () => ({ type: MENU_SAVE });
export const fetchMenus = value => ({ type: MENU_FETCH_LIST, value });
export const fetchMenu = value => ({ type: MENU_TREE_FETCH, value });

export default menuOnChange;
