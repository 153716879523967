import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import capitalize from '@material-ui/core/utils/capitalize';

import Page from '../components/common/page/Page';
import PageContent from '../components/common/page/PageContent';
import PageSection from '../components/common/page/PageSection';
import PageToolbar from '../components/common/page/PageToolbar';

import EditionListGroup from '../components/edition/EditionList';
import EditionListControlBar from '../components/edition/EditionListControlBar';
import { editionListLoad, editionListDraftLoad } from '../actions/edition';

const pageOptions = [10, 20, 50];

export const EditionListBreadcrumb = [
  {
    title: 'Editions',
    slug: 'edition',
  },
];

const EditionList = ({
  match: { params: { type } },
  editionListLoad: onPageLoad,
  editionListDraftLoad: fetchDraft,
  editionList,
  paging,
  editionDraftList,
  pagingDraft,
}) => {
  const [limitDraft, setLimitDraft] = useState(pageOptions[0]);
  const [pageDraft, setPageDraft] = useState(0);
  const [limit, setLimit] = useState(pageOptions[0]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    fetchDraft({
      offset: pageDraft * limitDraft,
      limit: limitDraft,
      status: 0,
      type,
    });
  }, [limitDraft, pageDraft, type]);

  useEffect(() => {
    onPageLoad({
      offset: page * limit,
      limit,
      status: 1,
      type,
    });
  }, [limit, page, type]);

  const breadcrumb = [
    ...EditionListBreadcrumb,
    {
      title: capitalize(type),
      slug: type,
    },
  ];

  return (
    <Page
      toolbarContent={
        <PageToolbar breadcrumb={breadcrumb}>
          <EditionListControlBar type={type} />
        </PageToolbar>
      }
    >
      <PageContent>
        <PageSection>
          {Array.isArray(editionDraftList[type]) && <EditionListGroup
            type={type}
            title={'Draft Editions'}
            list={editionDraftList[type]}
          />}
          {pagingDraft[type] && pagingDraft[type].total > limitDraft && <TablePagination
            rowsPerPageOptions={pageOptions}
            component="div"
            count={pagingDraft[type].total}
            rowsPerPage={limitDraft}
            page={pageDraft}
            onChangePage={(e, next) => setPageDraft(next)}
            onChangeRowsPerPage={(event) => {
              setLimitDraft(parseInt(event.target.value, 10));
              setPageDraft(0);
            }}
          /> }
        </PageSection>
        <PageSection>
          <EditionListGroup
            type={type}
            title={'Published Editions'}
            list={editionList}
          />
          {paging.total > limit && <TablePagination
            rowsPerPageOptions={pageOptions}
            component="div"
            count={paging.total}
            rowsPerPage={limit}
            page={page}
            onChangePage={(e, next) => setPage(next)}
            onChangeRowsPerPage={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
          }
        </PageSection>
      </PageContent>
    </Page>
  );
};

EditionList.propTypes = {
  editionListLoad: PropTypes.func.isRequired,
  editionListDraftLoad: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  editionList: PropTypes.array,
  paging: PropTypes.object.isRequired,
  editionDraftList: PropTypes.array,
  pagingDraft: PropTypes.object.isRequired,
};

EditionList.defaultProps = {
  editionList: [],
  editionDraftList: [],
};

export default connect(({
  edition: {
    editionList, paging, editionDraftList, pagingDraft,
  } }) =>
  ({ editionList, paging, editionDraftList, pagingDraft }), {
  editionListLoad,
  editionListDraftLoad,
})(EditionList);
