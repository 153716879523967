import { REHYDRATE } from 'redux-persist';
import { filter, mapTo, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { REDIRECT_TO_LOGIN } from '../../constants/actionTypes';

const externalPaths = ['/reset-password'];

const onRehydrate = (action$, state$) => action$.pipe(
  ofType(REHYDRATE),
  filter(() => !externalPaths.includes(window.location.pathname)),
  withLatestFrom(state$, (action, state) => state),
  filter((payload) => {
    if (typeof payload === 'undefined') {
      return true;
    }
    if (typeof payload.login === 'undefined') {
      return true;
    }
    if (typeof payload.login.user === 'undefined') {
      return true;
    }
    return payload.login.user.name === '';
  }),
  mapTo({
    type: REDIRECT_TO_LOGIN,
  }),
);

export default onRehydrate;
