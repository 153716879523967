import { PURGE } from 'redux-persist/es/constants';

import {
  DATASTATE_DISPOSE,
  DATASTATE_SET_DATA,
  DATASTATE_SET_PROPERTY,
  DATASTATE_UNSET_PROPERTY,
} from '../../constants/actionTypes/dataState';

import { removeFromState, setToState } from '../../utils/stateHelper';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DATASTATE_SET_DATA:
      return {
        ...action.value,
      };
    case DATASTATE_SET_PROPERTY: {
      const { prop, value, propChain = [] } = action.value;
      return setToState([...propChain, prop], value, state);
    }
    case DATASTATE_UNSET_PROPERTY:
      return removeFromState(action.value, state);
    case DATASTATE_DISPOSE:
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
