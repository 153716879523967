import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import {
  FormatBold,
  FormatItalic,
  FormatUnderline,
  FormatStrikethrough,
  FormatSubscript,
  FormatSuperscript,
  FormatHeader2,
  FormatHeader3,
  FormatHeader4,
  FormatHeader5,
  FormatHeader6,
  FormatListBulleted,
  FormatListNumbered,
  Link,
  LinkOff,
  Food
} from 'mdi-material-ui';

import {
  BalloonToolbar,
  BoldPlugin,
  ItalicPlugin,
  UnderlinePlugin,
  StrikethroughPlugin,
  SubscriptPlugin,
  SuperscriptPlugin,
  LinkPlugin,
  HeadingPlugin,
  ListPlugin,
  ToolbarElement,
  ToolbarList,
  withLink,
  withList,
  withMarks,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_UL,
  DEFAULTS_HEADING,
} from '@udecode/slate-plugins';

import Separator from './toolbar/Separator';
import ToolbarLink from './toolbar/ToolbarLink';
import ProductLink from './toolbar/ProductLink';
import ToolbarLinkRemove from './toolbar/ToolbarLinkRemove';
import ToolbarMark from './toolbar/ToolbarMark';

// h1 are mapped to h2, setting is still required for the HeadingPlugin
const HEADING_OPTIONS = DEFAULTS_HEADING;
HEADING_OPTIONS.h1 = {
  ...DEFAULTS_HEADING.h2,
};

export const toolbarInlinePlugins = [
  BoldPlugin(),
  ItalicPlugin(),
  UnderlinePlugin(),
  StrikethroughPlugin(),
  SubscriptPlugin(),
  SuperscriptPlugin(),
  LinkPlugin(),
  HeadingPlugin(HEADING_OPTIONS),
  ListPlugin(),
];

export const withToolbarInlinePlugins = [
  withLink(),
  withList(),
  withMarks(),
];
const Product = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.48122 3.19983C1.06121 3.19983 0.713357 3.52376 0.683707 3.94305C0.683707 3.94305 0.230133 10.291 0.00619451 13.4289C-0.0416633 14.0935 0.189411 14.7482 0.642984 15.2353C1.09728 15.7232 1.73337 16 2.3998 16H11.7631C12.4295 16 13.0656 15.7232 13.5199 15.2353C13.9735 14.7482 14.2046 14.0939 14.1567 13.4289C13.9328 10.2914 13.4792 3.94305 13.4792 3.94305C13.4495 3.52376 13.1017 3.19983 12.6817 3.19983H1.48122ZM11.9371 4.79984H2.22637L1.60172 13.5432C1.58636 13.7646 1.66315 13.9824 1.81458 14.1446C1.96565 14.3078 2.1778 14.3999 2.40029 14.3999H11.7636C11.9861 14.3999 12.1979 14.3078 12.3493 14.1446C12.5004 13.9821 12.5772 13.7646 12.5622 13.5432L11.9375 4.79984H11.9371Z"
      fill="#6d6d6d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5727 3.73289C5.51341 3.56646 5.48163 3.38718 5.48163 3.20003C5.48163 2.3168 6.19842 1.60002 7.08164 1.60002C7.96487 1.60002 8.68166 2.3168 8.68166 3.20003C8.68166 3.38718 8.64952 3.56646 8.59059 3.73289C8.44344 4.14968 8.66166 4.60719 9.07773 4.75434C9.49452 4.90149 9.95203 4.68327 10.0992 4.2672C10.2167 3.93291 10.2817 3.57433 10.2817 3.20003C10.2817 1.43361 8.84807 0 7.08165 0C5.31524 0 3.88162 1.43361 3.88162 3.20003C3.88162 3.57433 3.94627 3.93289 4.06412 4.2672C4.21127 4.68327 4.66877 4.90149 5.08557 4.75434C5.50165 4.60719 5.71987 4.14969 5.57272 3.73289H5.5727Z"
      fill="#6d6d6d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48127 7.19988C5.48127 7.64167 5.12305 7.99989 4.68126 7.99989C4.23948 7.99989 3.88126 7.64167 3.88126 7.19988C3.88126 6.75809 4.23948 6.39987 4.68126 6.39987C5.12305 6.39987 5.48127 6.75809 5.48127 7.19988Z"
      fill="#6d6d6d"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2814 7.19988C10.2814 7.64167 9.92313 7.99989 9.48134 7.99989C9.03956 7.99989 8.68134 7.64167 8.68134 7.19988C8.68134 6.75809 9.03956 6.39987 9.48134 6.39987C9.92313 6.39987 10.2814 6.75809 10.2814 7.19988Z"
      fill="#6d6d6d"
    />
  </svg>
);
const styleButtons = [
  [MARK_BOLD, FormatBold, 'Bold'],
  [MARK_ITALIC, FormatItalic, 'Italic'],
  [MARK_UNDERLINE, FormatUnderline, 'Underline'],
  [MARK_STRIKETHROUGH, FormatStrikethrough, 'Strikethrough'],
  [MARK_SUBSCRIPT, FormatSubscript, 'Subscript'],
  [MARK_SUPERSCRIPT, FormatSuperscript, 'Superscript'],
];

const elementButtons = [
  [ELEMENT_H2, FormatHeader2, 'H2'],
  [ELEMENT_H3, FormatHeader3, 'H3'],
  [ELEMENT_H4, FormatHeader4, 'H4'],
  [ELEMENT_H5, FormatHeader5, 'H5'],
  [ELEMENT_H6, FormatHeader6, 'H6'],
];

const styles = () => ({
  root: {
    top: 0,
    zIndex: 10000,
    transition: 'none',
  },
});

const EditorToolbarInline = ({ classes, isProduct = false }) => {
  return (
    <BalloonToolbar
      className={classes.root}
      direction={'top'}
      hiddenDelay={0.3}
      theme={'light'}
      arrow
    >
      {styleButtons.map(([type, Icon, tooltip]) => (
        <ToolbarMark key={`inline-${type}`} type={type} icon={<Tooltip title={tooltip} arrow><Icon /></Tooltip>} />
      ))}
      <Separator />
      <ToolbarLink icon={<Tooltip title={'Link'} arrow><Link /></Tooltip>} />
      {isProduct && <ProductLink icon={<Tooltip title={'Product'} arrow><Product /></Tooltip>} />}
      <ToolbarLinkRemove icon={<Tooltip title={'Remove link'} arrow><LinkOff /></Tooltip>} />
      <Separator />
      {elementButtons.map(([type, Icon, tooltip]) => (
        <ToolbarElement key={`inline-${type}`} type={type} icon={<Tooltip title={tooltip} arrow><Icon /></Tooltip>} />
      ))}
      <ToolbarList icon={<Tooltip title={'Bullet list'} arrow><FormatListBulleted /></Tooltip>} typeList={ELEMENT_UL} />
      <ToolbarList icon={<Tooltip title={'Numbered list'} arrow><FormatListNumbered /></Tooltip>} typeList={ELEMENT_OL} />
    </BalloonToolbar>
  );
};

EditorToolbarInline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditorToolbarInline);
