import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RemoveIcon from '@material-ui/icons/Close';
import { fade } from '@material-ui/core/styles/colorManipulator';

import PinIcon from '../../../../assets/icons/PinIcon';

import { handleManualListItemDrop, clearManualListItem, pinManualListItem, unpinManualListItem } from '../../../../actions/manualList';
import { setLayoutContext } from '../../../../actions/layout';

import { CONTEXT_ARTICLE_SELECTOR, CONTEXT_COMPONENT } from '../../../../constants/contexts';
import { OVERRIDE_TITLE } from '../../../../constants/layout/layout';
import { ARTICLE_ID } from '../../../../entities/ManualListEntity';
import { COMPONENT_ID } from '../../../../entities/LayoutEntity';

import IconButton from '../../../ui/buttons/IconButton';
import DropZoneArticle from '../../../ui/dropzones/DropZoneArticle';
import DraggableArticle from '../../../ui/draggables/DraggableArticle';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    '& .remove-article': {
      display: 'none',
      padding: 0,
      margin: '12px 0',
    },
    '& .pin-article': {
      display: 'none',
      padding: 0,
      margin: '14px 5px',
    },
    '& .unpin-article': {
      padding: 0,
      margin: '14px 5px',
    },
    '&:hover .remove-article': {
      display: 'block',
    },
    '&:hover .pin-article': {
      display: 'block',
    },
  },
  dropzone: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: fade(theme.palette.border.secondary.main, 0.6),
    overflow: 'hidden',
    '&.drop-enabled': {
      borderStyle: 'dashed',
      borderColor: '#000',
    },
  },
  dropzoneEmpty: {
    borderColor: fade(theme.palette.border.secondary.main, 0.3),
  },
  dropzoneFocused: {
    backgroundColor: theme.palette.background.paleBlue,
  },
  articleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    margin: '-2px -5px !important',
    padding: '2px 5px !important',
    border: '0 none !important',
  },
  articleTitle: {
    maxWidth: '100%',
    flexGrow: 1,
    height: 42,
    lineHeight: '21px',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  articleIndex: {
    display: 'flex',
    alignItems: 'center',
    margin: '-2px 6px -2px -5px',
    padding: 5,
    background: theme.palette.background.default,
    color: theme.palette.grey.main,
  },
  icon: {
    width: 20,
    height: 20,
  },
  pinIcon: {
    width: 16,
    height: 16,
  },
});

const LoadingArticleContent = () => (
  <Typography variant={'body2'}>Loading...</Typography>
);

const EmptyArticleContent = ({ classes, setContext }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <div role="form" className={classes.articleWrapper} onClick={() => setContext(CONTEXT_ARTICLE_SELECTOR)} />
);

EmptyArticleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  setContext: PropTypes.func.isRequired,
};

const ArticleContent = ({
  classes, article, removeAction, listId, index, setContext, component,
  pinItem, unpinItem, isPinnable, isPinned, itemIndex,
}) => {
  const { overrides = {} } = article;
  const source = {
    listId,
    itemId: article[ARTICLE_ID],
    itemIndex,
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      role="form"
      className={classes.wrapper}
      onClick={() => setContext(
        CONTEXT_COMPONENT,
        { ...component, targetIndex: index },
      )}
    >
      <DraggableArticle
        disabled={isPinned}
        article={article}
        source={source}
        className={classes.articleWrapper}
      >
        <Typography className={classes.articleIndex} variant={'body2'}>{index + 1}</Typography>
        <Typography className={classes.articleTitle} variant={'body2'}>{overrides[OVERRIDE_TITLE] || article.label}</Typography>
        {isPinnable && <IconButton
          className={!isPinned ? 'pin-article' : 'unpin-article'}
          onClick={() => {
            if (!isPinned) {
              pinItem(article, component[COMPONENT_ID], listId, index);
            } else {
              unpinItem(article, component[COMPONENT_ID], listId, itemIndex, index);
            }
          }}
        >
          <PinIcon className={classes.pinIcon} />
        </IconButton>}
        {!isPinned && <IconButton
          className={'remove-article'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeAction(listId, article[ARTICLE_ID]);
          }}
        >
          <RemoveIcon className={classes.icon} />
        </IconButton>}
      </DraggableArticle>
    </div>
  );
};

ArticleContent.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  removeAction: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setContext: PropTypes.func.isRequired,
  pinItem: PropTypes.func.isRequired,
  unpinItem: PropTypes.func.isRequired,
  component: PropTypes.object.isRequired,
  listId: PropTypes.number,
  itemIndex: PropTypes.number,
  isPinnable: PropTypes.bool,
  isPinned: PropTypes.bool,
};

ArticleContent.defaultProps = {
  isPinnable: false,
  isPinned: false,
  listId: null,
  itemIndex: null,
};

const getContents = (loading, article) => {
  if (loading) return LoadingArticleContent;
  if (typeof article === 'undefined' || article === null || typeof article.target_id === 'undefined') return EmptyArticleContent;
  return ArticleContent;
};

const articleDataProcessor = (listId, itemIndex) => ({ article, source }) => ({
  listId,
  source,
  item: article,
  itemIndex,
});

const ArticleDropZone = ({
  classes, loading, listId, itemIndex, index, component, article, pinned, focused, pinnable,
  handleManualListItemDrop: handleDrop,
  clearManualListItem: clearItem,
  setLayoutContext: setContext,
  pinManualListItem: pinItem,
  unpinManualListItem: unpinItem,
}) => {
  const Content = getContents(loading, article);
  const dropzoneClasses = [classes.dropzone];
  if (article?.[ARTICLE_ID]) {
    dropzoneClasses.push(classes.dropzoneEmpty);
  }
  if (focused) {
    dropzoneClasses.push(classes.dropzoneFocused);
  }
  return (
    <DropZoneArticle
      dropHandler={handleDrop}
      dataProcessor={articleDataProcessor(listId, itemIndex)}
      className={dropzoneClasses.join((' '))}
      disabled={pinned}
    >
      <Content
        classes={classes}
        article={article}
        removeAction={clearItem}
        listId={listId}
        index={index}
        itemIndex={itemIndex}
        isPinnable={pinnable}
        isPinned={pinned}
        setContext={setContext}
        component={component}
        pinItem={pinItem}
        unpinItem={unpinItem}
      />
    </DropZoneArticle>
  );
};

ArticleDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  article: PropTypes.object,
  pinned: PropTypes.bool,
  listId: PropTypes.number,
  itemIndex: PropTypes.number,
  index: PropTypes.number.isRequired,
  component: PropTypes.object.isRequired,
  handleManualListItemDrop: PropTypes.func.isRequired,
  clearManualListItem: PropTypes.func.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
  pinManualListItem: PropTypes.func.isRequired,
  unpinManualListItem: PropTypes.func.isRequired,
  focused: PropTypes.bool,
  loading: PropTypes.bool,
  pinnable: PropTypes.bool,
};

ArticleDropZone.defaultProps = {
  pinned: false,
  article: null,
  listId: null,
  itemIndex: null,
  focused: false,
  loading: false,
  pinnable: false,
};

export default withStyles(styles)(connect(
  () => ({ }),
  {
    handleManualListItemDrop,
    clearManualListItem,
    setLayoutContext,
    pinManualListItem,
    unpinManualListItem,
  },
)(ArticleDropZone));
