import {
  REVISION_COMPARE,
  REVISION_LIST_DISPOSE,
  REVISION_ON_REQUEST_PREVIEW,
  REVISION_ON_SEARCH,
  REVISION_ON_SELECT,
  REVISION_OFFLINE_DELETE,
  UPDATE_OFFLINE_REVISION,
} from '../constants/revision';

export const searchRevision = value => ({ type: REVISION_ON_SEARCH, value });
export const onSelectRevision = value => ({ type: REVISION_ON_SELECT, value });
export const deleteOfflineRevision = value => ({ type: REVISION_OFFLINE_DELETE, value });
export const onPreviewRevision = value => ({ type: REVISION_ON_REQUEST_PREVIEW, value });
export const disposeRevisionList = value => ({ type: REVISION_LIST_DISPOSE, value });
export const compareRevision = value => ({ type: REVISION_COMPARE, value });
export const updateOfflineRevision = value => ({ type: UPDATE_OFFLINE_REVISION, value });
