import { PURGE } from 'redux-persist';

import {
  AFFILIATE_CHECK_DOMAIN_DISPOSE,
  AFFILIATE_CHECK_DOMAIN,
  AFFILIATE_CHECK_DOMAIN_SUCCESS,
  AFFILIATE_CHECK_DOMAIN_FAILURE,
  AFFILIATE_FETCH_DASHBOARD_SUCCESS,
} from '../../constants/actionTypes/affilate';

const getStatus = (codes) => {
  const status = {};
  let error = 0;
  Object.entries(codes).forEach(([key, value]) => {
    const code = key.replace('status_', '');
    if (code !== '200') {
      error += value;
    }
    status[code] = value;
  });
  return ({ status, error });
};

const defaultState = {
  products: {
    total: 0,
    matched: 0,
    unmatched: 0,
    outOfStock: 0,
    error: 0,
    status: {},
  },
  productArticles: 0,
  domainResults: [],
  domainResultsFetching: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case AFFILIATE_FETCH_DASHBOARD_SUCCESS: {
      const {
        totalProducts,
        totalProductArticles,
        totalProductsMatched,
        totalProductsUnMatched,
        outOfStock,
        linkStatusCodes,
      } = action.value;
      const { status, error } = getStatus(linkStatusCodes);
      return {
        ...state,
        products: {
          total: totalProducts,
          matched: totalProductsMatched,
          unmatched: totalProductsUnMatched,
          outOfStock,
          status,
          error,
        },
        productArticles: totalProductArticles,
      };
    }
    case AFFILIATE_CHECK_DOMAIN:
      return {
        ...state,
        domainResults: defaultState.domainResults,
        domainResultsFetching: true,
      };
    case AFFILIATE_CHECK_DOMAIN_SUCCESS:
      return {
        ...state,
        domainResults: action.value,
        domainResultsFetching: false,
      };
    case AFFILIATE_CHECK_DOMAIN_FAILURE:
      return {
        ...state,
        domainResultsFetching: false,
      };
    case AFFILIATE_CHECK_DOMAIN_DISPOSE:
      return {
        ...state,
        domainResults: defaultState.domainResults,
      };
    case PURGE:
      return defaultState;
    default:
      return state;
  }
};
