import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { Table, TableRow, TableBody, TableHead, TableCell, TableHeadCell } from '../ui/table/Table';
import Duration from './common/Duration';
import Loader from '../ui/Loader';
import MediaRatio from './MediaRatio';
import Button from '../ui/buttons/Button';
import TextSearchFilter from '../ui/table/filters/TextSearchFilter';
import {sanitiseVideoDugout} from "../../utils/sanitiser";
import moment from "moment";
import {CMS_DATE_FORMAT} from "../../constants/common";
import {searchVideoDugout} from "../../actions/media";

const pageSizeOpts = [12, 24, 60];
const unknownSizeFromAPI = 1000000;

const style = theme => ({
  paginationRoot: {
    flexBasis: '100%',
  },
  table: {
    margin: theme.spacing(0, -3),
  },
});
const VideoDugoutList = (props) => {
  const { classes, videoDugoutOnSearch: onSearch, dugout, selectedVideo,
    isDugoutSearching } = props;
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(pageSizeOpts[0]);
  const [query, setQuery] = useState('');
  const { playlist, total } = dugout;

  useEffect(() => {
    const params = {
      page,
      limit: sizePerPage,
      q: query,
    };
    onSearch(params);
  }, [page, sizePerPage, query]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <TextSearchFilter
          placeholder={'Search by title'}
          defaultValue={query}
          onSubmit={(textQuery) => {
            setQuery(textQuery);
            setPage(0);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.table}>
          {isDugoutSearching && <Loader />}
          <Table>
            <TableHead>
              <TableRow hasBorder>
                {['Video', 'Title', 'Duration', 'Published' ,''].map(label => (
                  <TableHeadCell key={label}>{label}</TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {playlist.length === 0 && !isDugoutSearching && <TableRow>
                <TableCell colSpan={4} align={'center'}>No videos found</TableCell>
              </TableRow>}
              {playlist.length > 0 && playlist.map(data => (
                <TableRow key={data.mediaid} hasActions>
                  <TableCell>
                    <MediaRatio>
                      <img
                        src={data.thumbnail_640}
                        alt={data.title}
                      />
                    </MediaRatio>
                  </TableCell>
                  <TableCell><div dangerouslySetInnerHTML={{ __html: data.title }} /></TableCell>
                  <TableCell>
                    <Typography noWrap><Duration duration={data.duration} /></Typography>
                  </TableCell>
                  <TableCell>
                    <Typography noWrap>{moment(data.pubdate).format(CMS_DATE_FORMAT)}</Typography>
                  </TableCell>
                  <TableCell align={'right'}>
                    <Button
                      onClick={() => selectedVideo(sanitiseVideoDugout(data))}
                      variant={'contained'}
                    >Select</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {total > sizePerPage && <TablePagination
            classes={{ root: classes.paginationRoot }}
            rowsPerPageOptions={pageSizeOpts}
            component={'div'}
            count={total || unknownSizeFromAPI}
            rowsPerPage={sizePerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            labelDisplayedRows={({ from, to, count }) => {
              if (count === unknownSizeFromAPI) {
                return `${from}-${to} of many videos`;
              }
              return `${from}-${to} of ${count}`;
            }
            }
            onChangePage={(e, i) => setPage(i)}
            onChangeRowsPerPage={e => setSizePerPage(e.target.value)}
          />}
        </div>
      </Grid>
    </Grid>
  );
};

VideoDugoutList.defaultProps = {
  dugout: {
    playlist: [],
    total: 0,
  },
};

VideoDugoutList.propTypes = {
  classes: PropTypes.object.isRequired,
  videoDugoutOnSearch: PropTypes.func.isRequired,
  selectedVideo: PropTypes.func.isRequired,
  dugout: PropTypes.object.isRequired,
  isDugoutSearching: PropTypes.bool.isRequired,
};

export default withStyles(style)(connect(({
  media: { dugout, isDugoutSearching },
}) => ({ dugout, isDugoutSearching }), { videoDugoutOnSearch: searchVideoDugout })(VideoDugoutList));
