import { switchMap, withLatestFrom, mapTo, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';

import {
  USER_UPDATE_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
} from '../../constants/actionTypes';
import apiCatchError from '../helper/notification';
const onSubmit = (action$, state$) => action$.pipe(
  ofType(USER_UPDATE_PASSWORD),
  withLatestFrom(state$),
  switchMap(([, {
    resetPassword: { email, tempPassword, password, confirmPassword, token },
  }]) => {
    if (password !== confirmPassword) {
      return of({
        type: RESET_PASSWORD_FAILED,
        value: 'Password and confirm password fields don\'t match',
      });
    }
    if (password.length < 8) {
      return of({
        type: RESET_PASSWORD_FAILED,
        value: 'Password should be at least 8 characters',
      });
    }
    if (!email) {
      return of({
        type: RESET_PASSWORD_FAILED,
        value: 'Email is required',
      });
    }
    if (!tempPassword) {
      return of({
        type: RESET_PASSWORD_FAILED,
        value: 'Temporary Password is required',
      });
    }
    const payload = {
      token,
      email,
      temp_pass: tempPassword,
      new_pass: password,
    };
    return ajax.post(
      '/api/user/reset-password',
      payload,
      { 'Content-Type': 'application/json' }
    ).pipe(
      map(() => ({
        type: RESET_PASSWORD_SUCCESS,
        value: 'Your password has been reset, please log in to continue',
      })),
      apiCatchError(RESET_PASSWORD_FAILED, 'Password reset failed, please try again'),
    );
  }),
);

export const redirectAfterPasswordReset = (action$) => {
  return action$.pipe(
    ofType(RESET_PASSWORD_SUCCESS),
    mapTo(push('/login'))
  )
};

export default onSubmit;

