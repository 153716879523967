import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from './common/Dialog';

const style = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  compRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  code: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2),
  },
  btnContainer: {
    marginTop: theme.spacing(1),
  }
});

const LiveblogEmbedDialog = (props) => {
  const {classes, children, ...rest} = props;
  return (<Dialog title={'Liveblog embed'} maxWidth={'md'} {...rest}>
    <div className={classes.root}>
      <Typography component={'p'}>Liveblog id</Typography>
      <div className={classes.code}>
        {children}
      </div>
      <div className={classes.btnContainer}>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => {
            try {
              navigator.clipboard.writeText(document.getElementById('liveblogId').innerHTML);
            } catch (ex) {
              console.error(ex);
            }
          }}
        >Copy to clipboard</Button>
      </div>
    </div>
  </Dialog>)
};

LiveblogEmbedDialog.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
}

LiveblogEmbedDialog.propTypes = {
}

export default withStyles(style)(LiveblogEmbedDialog);
