import { ofType } from 'redux-observable';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { from, of } from 'rxjs';
import apiCatchError, { showSuccessNotification, assignErrorPayload } from '../helper/notification';
import {
  VIAFOURA_NOTIFICATIONS_FETCH, VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS,
  VIAFOURA_NOTIFICATIONS_FETCH_REJECTED,
  VIAFOURA_NOTIFICATION_SAVE_SUCCESS,
  VIAFOURA_NOTIFICATION_SAVE_REJECTED,
  VIAFOURA_NOTIFICATION_SEND, VIAFOURA_NOTIFICATION_SEND_SUCCESS,
  VIAFOURA_NOTIFICATION_SEND_REJECTED,
  VIAFOURA_NOTIFICATION_SEND_READY,
} from '../../constants/actionTypes/viafouraNotification';
import {
  FIELD_NAME, FIELD_TITLE, FIELD_IMAGE, FIELD_URL, FIELD_ARTICLE,
} from '../../constants/viafouraNotification/viafouraNotificationFields';

import { sendViafouraNotificationRequest, saveViafouraNotificationRequest } from './helper';

export const fetchViafouraNotifications = (action$, state$) => action$.pipe(
  ofType(VIAFOURA_NOTIFICATIONS_FETCH, VIAFOURA_NOTIFICATION_SAVE_SUCCESS),
  withLatestFrom(state$),
  switchMap(([, { dataState: { [FIELD_ARTICLE]: article }, frame: { selectedPublication } }]) =>
    ajax.getJSON(`/api/viafoura-notifications/default/${selectedPublication.domain}?article=${article}`)
      .pipe(
        map(response => ({ type: VIAFOURA_NOTIFICATIONS_FETCH_SUCCESS, value: response })),
        apiCatchError(VIAFOURA_NOTIFICATIONS_FETCH_REJECTED),
      )),
);

export const validateSaveViafouraNotification = (action$, state$) => action$.pipe(
  ofType(VIAFOURA_NOTIFICATION_SEND),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({ dataState }) => {
    const errors = [];
    if (dataState[FIELD_NAME] === '') {
      errors.push(assignErrorPayload('Unable to send notification, description missing.'));
    }
    if (dataState[FIELD_TITLE] === '') {
      errors.push(assignErrorPayload('Unable to send notification, title missing.'));
    }
    if (dataState[FIELD_IMAGE] === '') {
      errors.push(assignErrorPayload('Unable to send notification, article image missing.'));
    }
    if (dataState[FIELD_URL] === '') {
      errors.push(assignErrorPayload('Unable to send notification, article url missing.'));
    }
    if (errors.length > 0) {
      apiCatchError(VIAFOURA_NOTIFICATION_SEND_REJECTED);
      return from(errors);
    }

    return of({
      type: VIAFOURA_NOTIFICATION_SEND_READY,
    });
  }),
);

export const sendViafouraNotification = (action$, state$) => action$.pipe(
  ofType(VIAFOURA_NOTIFICATION_SEND_READY),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({
    localState, serverState, frame: { selectedPublication: { publicationConfig: { viafoura } } },
  }) => sendViafouraNotificationRequest(localState, serverState, viafoura)
    .pipe(
      map(( { status } ) => {
        if(status !== 200) {
          throw new Error('Viafoura request failed');
        }
        return ({ type: VIAFOURA_NOTIFICATION_SEND_SUCCESS, value: status });
      }),
      apiCatchError(VIAFOURA_NOTIFICATION_SEND_REJECTED),
    ),
  ),
);

export const saveViafouraNotification = (action$, state$) => action$.pipe(
  ofType(VIAFOURA_NOTIFICATION_SEND_SUCCESS),
  withLatestFrom(state$, (a, b) => b),
  mergeMap(({ localState, serverState }) => saveViafouraNotificationRequest(localState, serverState)
    .pipe(
      map(({ response }) => ({ type: VIAFOURA_NOTIFICATION_SAVE_SUCCESS, value: response })),
      apiCatchError(VIAFOURA_NOTIFICATION_SAVE_REJECTED),
    ),
  ),
);

export const viafouraNotificationSentNotification = action$ => action$.pipe(
  ofType(VIAFOURA_NOTIFICATION_SEND_SUCCESS),
  mergeMap(showSuccessNotification('Notification has been sent successfully.')),
);

export const viafouraNotificationSavedNotification = action$ => action$.pipe(
  ofType(VIAFOURA_NOTIFICATION_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Notification log has been saved successfully.')),
);
