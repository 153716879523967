import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_MONEY } from './defaults';

const renderElementMoney = (options) => {
  const { money } = setDefaults(options, DEFAULTS_MONEY);
  return getRenderElement(money);
};

export default renderElementMoney;
