import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import LinkIcon from '@material-ui/icons/Link';

import {
  CONTENT_SOURCE,
  CONTENT_SOURCE_MANUAL,
  CONTENT_SOURCE_MANUAL_LIST_SECTION,
  MANUAL_LIST_COLORS,
} from '../../constants';

const styles = theme => ({
  root: {
    padding: theme.spacing(0, 0, 0, 1.5),
    width: '0.8em',
    '&.list-index-0': {
      color: MANUAL_LIST_COLORS[0],
    },
    '&.list-index-1': {
      color: MANUAL_LIST_COLORS[1],
    },
    '&.list-index-2': {
      color: MANUAL_LIST_COLORS[2],
    },
    '&.list-index-3': {
      color: MANUAL_LIST_COLORS[3],
    },
    '&.list-index-4': {
      color: MANUAL_LIST_COLORS[4],
    },
    '&.list-index-5': {
      color: MANUAL_LIST_COLORS[5],
    },
    '&.list-index-6': {
      color: MANUAL_LIST_COLORS[6],
    },
    '&.list-index-7': {
      color: MANUAL_LIST_COLORS[7],
    },
    '&.list-index-8': {
      color: MANUAL_LIST_COLORS[8],
    },
    '&.list-index-9': {
      color: MANUAL_LIST_COLORS[9],
    },
  },
});

const ComponentLinkIndicator = ({ classes, component, manualListIndex }) => (
  (
    typeof component[CONTENT_SOURCE] !== 'undefined' &&
    component[CONTENT_SOURCE] === CONTENT_SOURCE_MANUAL &&
    typeof component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== 'undefined' &&
    component[CONTENT_SOURCE_MANUAL_LIST_SECTION] !== null &&
    manualListIndex > -1
  ) ? <LinkIcon className={manualListIndex > -1 ? `${classes.root} list-index-${manualListIndex}` : classes.root} /> : ''
);

ComponentLinkIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  manualListIndex: PropTypes.number.isRequired,
};

export default withStyles(styles)(ComponentLinkIndicator);
