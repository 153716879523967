import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  removeHeroImageComponent,
  setEditionLockField,
  changeHeroImageProperty, setHeroImageComponent,
} from '../../../../actions/edition';
import { FIELD_HERO_IMAGE } from '../../../../constants/edition/editionFields';
import ImageSelector from '../../../ui/media/ImageSelector';
import FieldLockContainer from '../../../fieldLock/FieldLockContainerDepricated';
import TextField from '../../../ui/builder/TextField';
import MarkupField from '../../../ui/MarkupField';
import { PROP_LOCAL_CAPTION, PROP_LOCAL_COPYRIGHT } from '../../../../constants/media/media';

const styles = () => ({
  preview: {
    width: 208,
    flexShrink: 0,
  },
  dropZone: {
    width: '100%',
  },
  fields: {
    width: '100%',
  },
});

const HeroImage = (props) => {
  const {
    classes, heroImage, sessionLockedFields,
    setHeroImageComponent: setHero,
    removeHeroImageComponent: onRemove,
    changeHeroImageProperty: onPropChange,
    setEditionLockField: lockField,
    noImageRatio,
  } = props;
  const lock = sessionLockedFields.filter(({ field }) => field === FIELD_HERO_IMAGE);
  const value = lock.length > 0 ? lock[0].value : heroImage;
  return (
    <Grid container wrap={'nowrap'} spacing={2}>
      <Grid item>
        <FieldLockContainer
          field={FIELD_HERO_IMAGE}
          onUnlock={() => lockField([FIELD_HERO_IMAGE, heroImage])}
          classWrapper={value?.data?.url ? classes.preview : classes.dropZone}
        >
          <ImageSelector
            label={'Drag & Drop image or browse'}
            noImageRatio={noImageRatio}
            image={value?.data}
            selectImage={imageData => setHero(imageData)}
            onRemove={onRemove}
            showEditDialog
          />
        </FieldLockContainer>
      </Grid>
      {value?.data?.url && <Grid item className={classes.fields}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MarkupField
              label={'Local Caption'}
              data={value?.data[PROP_LOCAL_CAPTION] || ''}
              onChange={(text) => {
                if (!(!value?.data[PROP_LOCAL_CAPTION] && text === '<p></p>')) {
                  onPropChange([PROP_LOCAL_CAPTION, text === '<p></p>' ? '' : text]);
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Local Copyright'}
              value={value.data[PROP_LOCAL_COPYRIGHT]}
              onChange={e => onPropChange([PROP_LOCAL_COPYRIGHT, e.target.value])}
            />
          </Grid>
        </Grid>
      </Grid>}
    </Grid>
  );
};

HeroImage.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedArticleImage: PropTypes.func.isRequired,
  articleHeroImageRequest: PropTypes.func.isRequired,
  removeHeroImageComponent: PropTypes.func.isRequired,
  setHeroImageComponent: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
  changeHeroImageProperty: PropTypes.func.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  heroImage: PropTypes.object.isRequired,
  noImageRatio: PropTypes.bool,
};

HeroImage.defaultProps = {
  noImageRatio: false,
};

export default withStyles(styles)(connect(
  ({
    edition: { heroImage },
    fieldLock: { sessionLockedFields },
  }) => ({ heroImage, sessionLockedFields }),
  {
    setHeroImageComponent,
    removeHeroImageComponent,
    changeHeroImageProperty,
    setEditionLockField,
  },
)(HeroImage));
