import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { InputLabel } from '@material-ui/core';
import TextField from '../../ui/builder/TextField';

const styles = theme => ({
  ckeditorRoot: {
    // backgroundColor: 'cyan',
  },
  root: {
    width: '100%',
    '& a': {
      cursor: 'text',
    },
  },
  infoTitlePreview: {
    margin: theme.spacing(1, 1, 0),
    borderBottom: '1px solid #333',
  },
  ckeditor: {
    ...theme.typography.subtitle2,
    fontSize: '16px',
    position: 'relative',
    '.range-selected &': {
      color: theme.typography.color.primary,
    },
    '& >*': {
      margin: 0,
      paddingTop: '8px!important',
      paddingBottom: '8px!important',
    },
    '& .ck.ck-editor__editable > .ck-placeholder::before': {
      fontFamily: theme.typography.fontFamily,
    },
    '& .ck.ck-content': {
      '& >*': {
        margin: 0,
      },
    },
    '& .ck.ck-editor__editable_inline': {
      border: '0 none',
      padding: 0,
      '& >:first-child': {
        marginTop: 0,
      },
      '& >:last-child': {
        marginBottom: 0,
      },
    },
    '& .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused': {
      boxShadow: 'none',
      border: '0 none',

    },
    '& blockquote': {
      overflow: 'hidden',
      fontStyle: 'italic',
      borderLeft: '5px solid #ccc',
      margin: 0,
      paddingLeft: '1.5em',
    },
    '& p': {
      margin: 0,
    },
    '& > .table table': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      border: '1px double #b3b3b3',
      marginRight: 'auto',
      marginLeft: 'auto',
    },
    '& > .table table td, & > .table table th': {
      border: '1px double #b3b3b3',
      padding: theme.spacing(0.75),
    },
    '& > .table table th': {
      backgroundColor: '#fafafa',
    },
    '& .string-replace-candidate': {
      backgroundColor: 'yellow',
    },
  },
  wrapper: {
    // backgroundColor: 'cyan',
    '& .string-replace-candidate': {
      backgroundColor: 'yellow',
    },
  },
  wrapperWithExtras: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    '&:hover': {
      borderColor: '#000',
    },
  },
  ckeditorWithExtras: {
    padding: '18.5px 14px',
  },
  extraField: {
    marginBottom: theme.spacing(2),
  },
  endField: {
    marginTop: theme.spacing(2),
  },
  label: {
    display: 'inline-block',
    padding: '0 5px',
    marginLeft: -5,
    position: 'absolute',
    background: theme.palette.background.contrast,
  },
});

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const Markup = ({
  classes,
  data,
  position,
  placeholder,
  extraFields,
}) => {
  const ExtraFields = extraFields ? extraFields
    .filter(({ position: fieldPosition = 'start' }) => fieldPosition === 'start')
    .map(({ key, label }) => (<TextField
      key={key}
      label={label}
      onChange={() => {}}
      value={data[key]}
      className={classes.extraField}
    />)) : [];
  const EndFields = extraFields ? extraFields
    .filter(({ position: fieldPosition = 'start' }) => fieldPosition === 'end')
    .map(({ key, label }) => (<TextField
      key={key}
      label={label}
      onChange={() => {}}
      value={data[key]}
      className={classes.endField}
    />)) : [];
  const preview = ExtraFields;
  preview.push(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      key={`markup${position}Extra`}
      className={extraFields ? classes.wrapperWithExtras : ''}
      onClick={(e) => {
        if (e.target.nodeName === 'A') {
          e.preventDefault();
        }
      }}
    >
      {extraFields && <InputLabel
        className={classes.label}
        shrink
        variant={'outlined'}
      >Content</InputLabel>}
      <div
        className={extraFields ? `${classes.ckeditorWithExtras} ${classes.ckeditor}` : classes.ckeditor}
        dangerouslySetInnerHTML={{ __html: data.markup || placeholder }}
      />
    </div>,
  );
  preview.push(EndFields);
  return (
    <div className={classes.root}>{preview}</div>
  );
};

Markup.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  position: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
  extraFields: PropTypes.array,
};

Markup.defaultProps = {
  data: { markup: '' },
  placeholder: '',
  extraFields: [],
};

export default withStyles(styles)(Markup);
