import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import { Grid, capitalize } from '@material-ui/core';

import { Export } from 'mdi-material-ui';
import SaveIcon from '@material-ui/icons/Save';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReplyIcon from '@material-ui/icons/Reply';
import OpenInBrowserIcon from '@material-ui/icons/OpenInNew';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  saveArticleEdition,
  moveArticleEdition,
  editionRevert,
  onClone,
} from '../../../actions/edition';
import { PAGE_TYPE_SECTION } from '../../../constants/edition/edition';
import PreviewUI from '../../ui/components/PreviewUI';

import MoveEditionArticleDialog from '../../dialog/MoveEditionArticleDialog';
import { REVISION_REVERT_WARNING } from '../../../constants/article/article';
import Button from '../../ui/buttons/Button';
import { PageToolbarButtonsStatic } from '../../common/page/PageToolbar';
import IconButton from '../../ui/buttons/IconButton';
import EditionSelectorDialog from '../../dialog/EditionSelectorDialog';

const ControlButtons = (props) => {
  const {
    desktopToggle, mobileToggle, popoutToggle, type, previewMode,
    saveArticleEdition: onSaveArticle, moveArticleEdition: onMoveArticleEdition,
    revisionId, pathname, push: routeChange, editionRevert: revert, revisionList,
    onClone: clone,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openMoveArticle, setOpenMoveArticle] = useState(null);

  const editionIds = pathname.match(/(\w+)\/(\d+)\/(\d+)$/);

  return (
    <>
      <Grid container justify={'center'} alignItems={'center'}>
        {type === PAGE_TYPE_SECTION && <PreviewUI
          active={previewMode}
          desktopToggle={desktopToggle}
          mobileToggle={mobileToggle}
        />}
      </Grid>
      <PageToolbarButtonsStatic>
        <Button
          onClick={() => popoutToggle()}
          startIcon={<OpenInBrowserIcon />}
        >Preview</Button>
        {!revisionId && <>
          <Button
            onClick={() => setOpenMoveArticle(true)}
            variant={'outlined'}
            startIcon={<Export />}
          >Move</Button>
          <Button
            variant={'contained'}
            onClick={() => onSaveArticle()}
            startIcon={<SaveIcon />}
          >Save {capitalize(type)}</Button>
        </>}
        {revisionId && <>
          <Button
            variant={'outlined'}
            onClick={() => routeChange(pathname.replace(`/${revisionId}`, ''))}
            startIcon={<ExitToAppIcon />}
          >Go to Live</Button>
          <Button
            variant={'contained'}
            onClick={() => {
              if (window.confirm(REVISION_REVERT_WARNING)) {
                const revision = revisionList.find(({ revision_id: id }) => id === revisionId);
                if (revision) revert(revision.payload);
              }
            }}
            startIcon={<ReplyIcon />}
          >Revert</Button>
        </>}
        { editionIds && <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVert style={{ fill: '#DADADA' }} />
        </IconButton> }
        <Menu
          id={'long-menu'}
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setCloneDialogOpen(true);
              setAnchorEl(null);
            }}
          >Clone</MenuItem>
        </Menu>
        {cloneDialogOpen && <EditionSelectorDialog
          open={cloneDialogOpen}
          dialogTitle={'Clone to another edition'}
          onClose={() => setCloneDialogOpen(false)}
          handleClose={() => setCloneDialogOpen(false)}
          hideSection
          submitButtonText={'Clone'}
          onSubmit={(data) => {
            if (Array.isArray(editionIds) && editionIds.length > 3) {
              clone({
                ...data,
                editionType: editionIds[1],
                pageId: editionIds[3],
              });
            }
            setCloneDialogOpen(false);
          }}
        />}
      </PageToolbarButtonsStatic>
      {openMoveArticle && <MoveEditionArticleDialog
        open={openMoveArticle}
        handleClose={() => setOpenMoveArticle(false)}
        onSubmit={(data) => {
          onMoveArticleEdition(data);
          setOpenMoveArticle(false);
        }}
      />}
    </>
  );
};

ControlButtons.propTypes = {
  type: PropTypes.string.isRequired,
  previewMode: PropTypes.string.isRequired,
  desktopToggle: PropTypes.func.isRequired,
  mobileToggle: PropTypes.func.isRequired,
  onClone: PropTypes.func.isRequired,
  popoutToggle: PropTypes.func.isRequired,
  saveArticleEdition: PropTypes.func.isRequired,
  moveArticleEdition: PropTypes.func.isRequired,
  revisionList: PropTypes.array.isRequired,
  push: PropTypes.func.isRequired,
  editionRevert: PropTypes.func.isRequired,
  revisionId: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default connect(
  ({
    router: { location: { pathname } },
    revision: { list: revisionList },
  }) => ({ pathname, revisionList }),
  { saveArticleEdition, moveArticleEdition, push, editionRevert, onClone },
)(ControlButtons);
