import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { capitalize } from '@material-ui/core';
import TextField from '../ui/builder/TextField';
import { setAddressProperty } from '../../actions/contactUs';
import SectionTitle from '../common/SectionTitle';

const AddressForm = ({ address, setAddressProperty: setProp }) => (<Grid container spacing={2}>
  <Grid item xs={12}><SectionTitle>Address:</SectionTitle></Grid>
  {Object.entries(address).map(([key, value]) => (<Grid key={key} item xs={6}><TextField
    label={capitalize(key)}
    multiline={key === 'map'}
    value={value}
    onChange={({ target: { value: val } }) => setProp([key, val])}
  /></Grid>))}
</Grid>);

AddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  setAddressProperty: PropTypes.func.isRequired,
};

export default connect(
  ({ contactUs: { address } }) => ({ address }),
  { setAddressProperty },
)(AddressForm);
