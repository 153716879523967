export const LIVEBLOG_DISPOSE = 'LIVEBLOG_DISPOSE';
export const LIVEBLOG_APPEND_TWITTER_TO_BODY = 'LIVEBLOG_APPEND_TWITTER_TO_BODY';
export const LIVEBLOG_SAVE_POST = 'LIVEBLOG_SAVE_POST';
export const LIVEBLOG_SAVE_POST_SUCCESS = 'LIVEBLOG_SAVE_POST_SUCCESS';
export const LIVEBLOG_SAVE_POST_FAILURE = 'LIVEBLOG_SAVE_POST_FAILURE';
export const LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST = 'LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST';
export const LIVEBLOG_CANCEL_LIVEBLOG_POST = 'LIVEBLOG_CANCEL_LIVEBLOG_POST';
export const LIVEBLOG_SAVE_NO_ACTION_PAYLOAD = 'LIVEBLOG_SAVE_NO_ACTION_PAYLOAD';
export const LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_SUCCESS = 'LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_SUCCESS';
export const LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_REJECTED = 'LIVEBLOG_SAVE_TWITTER_LIVEBLOG_POST_REJECTED';
export const LIVEBLOG_SET_PROPERTY = 'LIVEBLOG_SET_PROPERTY';
export const LIVEBLOG_START_WATCH_SCHEDULE_POST = 'LIVEBLOG_START_WATCH_SCHEDULE_POST';
export const LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST = 'LIVEBLOG_DISPOSE_WATCH_SCHEDULE_POST';
export const LIVEBLOG_POST_PIN_UPDATE = 'LIVEBLOG_POST_PIN_UPDATE';
export const LIVEBLOG_POST_PINNED_SORT = 'LIVEBLOG_POST_PINNED_SORT';
export const LIVEBLOG_POST_PINNED_SORT_REJECTED = 'LIVEBLOG_POST_PINNED_SORT_REJECTED';
export const LIVEBLOG_POST_PIN_UPDATE_REJECTED = 'LIVEBLOG_POST_PIN_UPDATE_REJECTED';
export const LIVEBLOG_EDIT_POST = 'LIVEBLOG_EDIT_POST';
export const LIVEBLOG_SAVE_CONTAINER = 'LIVEBLOG_SAVE_CONTAINER';
export const LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE = 'LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE';
export const LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE_SKIPPED = 'LIVEBLOG_SAVE_CONTAINER_FROM_ARTICLE_SKIPPED';
export const LIVEBLOG_SAVE_CONTAINER_REJECTED = 'LIVEBLOG_SAVE_CONTAINER_REJECTED';
export const LIVEBLOG_FETCH_CONTAINER_REJECTED = 'LIVEBLOG_FETCH_CONTAINER_REJECTED';
export const LIVEBLOG_FETCH_LIST_SUCCESS = 'LIVEBLOG_FETCH_LIST_SUCCESS';
export const LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS = 'LIVEBLOG_ARCHIVED_FETCH_LIST_SUCCESS';
export const LIVEBLOG_ARCHIVED_FETCH_LIST_REJECTED = 'LIVEBLOG_ARCHIVED_FETCH_LIST_REJECTED';
export const LIVEBLOG_FETCH_LIST = 'LIVEBLOG_FETCH_LIST';
export const LIVEBLOG_DISPOSE_LIST = 'LIVEBLOG_DISPOSE_LIST';
export const LIVEBLOG_ENTER_LIST = 'LIVEBLOG_ENTER_LIST';
export const LIVEBLOG_ARCHIVED_FETCH_LIST = 'LIVEBLOG_ARCHIVED_FETCH_LIST';
export const LIVEBLOG_FETCH_POSTS = 'LIVEBLOG_FETCH_POSTS';
export const LIVEBLOG_FETCH_POSTS_FOR_ARTICLE = 'LIVEBLOG_FETCH_POSTS_FOR_ARTICLE';
export const LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS = 'LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_SUCCESS';
export const LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_REJECTED = 'LIVEBLOG_FETCH_POSTS_FOR_ARTICLE_REJECTED';
export const LIVEBLOG_ON_SEARCH = 'LIVEBLOG_ON_SEARCH';
export const LIVEBLOG_ON_SEARCH_SUCCESS = 'LIVEBLOG_ON_SEARCH_SUCCESS';
export const LIVEBLOG_ON_SEARCH_REJECTED = 'LIVEBLOG_ON_SEARCH_REJECTED';
export const LIVEBLOG_FETCH_LIST_REJECTED = 'LIVEBLOG_FETCH_LIST_REJECTED';
export const LIVEBLOG_FETCH_CONTAINER_SUCCESS = 'LIVEBLOG_FETCH_CONTAINER_SUCCESS';
export const LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS = 'LIVEBLOG_FETCH_FUTURE_POSTS_SUCCESS';
export const LIVEBLOG_FETCH_FUTURE_POSTS_REJECTED = 'LIVEBLOG_FETCH_FUTURE_POSTS_REJECTED';
export const LIVEBLOG_SAVE_CONTAINER_SUCCESS = 'LIVEBLOG_SAVE_CONTAINER_SUCCESS';
export const LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS = 'LIVEBLOG_SAVE_CONTAINER_ARTICLE_SUCCESS';
export const LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED = 'LIVEBLOG_SAVE_CONTAINER_ARTICLE_REJECTED';
export const LIVEBLOG_EDIT_POST_SUCCESS = 'LIVEBLOG_EDIT_POST_SUCCESS';
export const LIVEBLOG_REMOVE_POST = 'LIVEBLOG_REMOVE_POST';
export const LIVEBLOG_REMOVE_POST_SUCCESS = 'LIVEBLOG_REMOVE_POST_SUCCESS';
export const LIVEBLOG_REMOVE_POST_REJECTED = 'LIVEBLOG_REMOVE_POST_REJECTED';
