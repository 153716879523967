import isHotkey from 'is-hotkey';
import { getBlockAbove } from '@udecode/slate-plugins';
import { Transforms, Editor } from 'slate';

const selectAll = 'mod+a';

const onKeyDownNested = (event, editor) => {
  const entry = getBlockAbove(editor);
  if (!entry) return;

  if (isHotkey(selectAll, event)) {
    event.preventDefault();
    Transforms.setSelection(
      editor,
      {
        anchor: Editor.start(editor, [0]),
        focus: Editor.end(editor, [editor.children.length - 1]),
      },
    );
  }
};

export default onKeyDownNested;
