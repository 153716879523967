import { ELEMENT_RELATED_ARTICLES, PROP_ARTICLES, PROP_ALIGN } from './defaults';
import { PROP_LINKS, RELATED } from '../../../../../constants/editor/dataTypes';

export const convertToRelatedArticles = data => ({
  type: ELEMENT_RELATED_ARTICLES,
  [PROP_ARTICLES]: data[PROP_LINKS] || [],
  [PROP_ALIGN]: data[PROP_ALIGN] || 'left', // @todo defaults should be publisher specific config
  children: [{ text: '' }],
});

export const convertFromRelatedArticles = data => ({
  type: RELATED,
  data: {
    [PROP_LINKS]: data[PROP_ARTICLES] || [],
    [PROP_ALIGN]: data[PROP_ALIGN] || 'left', // @todo defaults should be publisher specific config
    title: 'Related', // @todo this should be reviewed and removed
  },
});
