import { setDefaults } from '@udecode/slate-plugins';
import renderElementVisualStory from './renderElementVisualStory';
import { DEFAULTS_VISUAL_STORY } from './defaults';

const VisualStoryPlugin = (options) => {
  const { visualStory } = setDefaults(options, DEFAULTS_VISUAL_STORY);
  return ({
    renderElement: renderElementVisualStory(options),
    voidTypes: [visualStory.type],
  });
};

export default VisualStoryPlugin;
