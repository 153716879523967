import {
  getRenderElement,
  setDefaults,
} from '@udecode/slate-plugins';

import { DEFAULTS_IFRAME } from './defaults';

const renderElementIframe = (options) => {
  const { iframe } = setDefaults(options, DEFAULTS_IFRAME);
  return getRenderElement(iframe);
};

export default renderElementIframe;
