import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { setLayoutContext } from '../../../../actions/sidebar';
import { CONTEXT_COMPONENT } from '../../../../constants/contexts';
import { COMPONENT_NAMES } from '../../../../constants/components';

const ComponentTitle = ({
  className, component, position, setLayoutContext: setContext,
}) => {
  return (
    <Typography
      variant={'h2'}
      color={'primary'}
      className={className}
      onClick={() => setContext(CONTEXT_COMPONENT, component)}
    >
      {position + 1} - {COMPONENT_NAMES[component.type] || component.name}
    </Typography>
  );
};

ComponentTitle.propTypes = {
  className: PropTypes.string,
  component: PropTypes.object.isRequired,
  position: PropTypes.number.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
};

ComponentTitle.defaultProps = {
  className: '',
};

export default connect(
  () => ({}),
  { setLayoutContext },
)(ComponentTitle);
