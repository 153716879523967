import {
  REORDER_SIDEBAR_COMPONENT,
  SELECT_SIDEBAR_COMPONENT,
  SIDEBAR_SAVE,
  SECTION_SIDEBAR_SELECTED,
  REMOVE_SIDEBAR_COMPONENT,
  REMOVE_SIDEBAR_SECTION,
  LAYOUT_SIDEBAR_PAGE_ONLOAD,
  SIDEBAR_CONTEXT_SET,
  ADD_SIDEBAR_COMPONENT,
  SIDEBAR_COMPONENT_PROPERTY_ONCHANGE,
} from '../constants/actionTypes';

export const selectComponent = id => ({ type: SELECT_SIDEBAR_COMPONENT, value: id });
export const removeComponent = index => ({ type: REMOVE_SIDEBAR_COMPONENT, value: index });
export const moveComponent = (oldIndex, newIndex) =>
  ({ type: REORDER_SIDEBAR_COMPONENT, value: [oldIndex, newIndex] });
export const selectSection = path => ({ type: SECTION_SIDEBAR_SELECTED, value: path });
export const sidebarLayoutLoadPage = path => ({ type: LAYOUT_SIDEBAR_PAGE_ONLOAD, value: path });
export const onSidebarSave = () => ({ type: SIDEBAR_SAVE });
export const removeSectionSidebar = value => ({ type: REMOVE_SIDEBAR_SECTION, value });
export const componentPropOnChange = value =>
  ({ type: SIDEBAR_COMPONENT_PROPERTY_ONCHANGE, value });
export const setLayoutContext =
  (type, data) => ({ type: SIDEBAR_CONTEXT_SET, value: { type, data } });
export const handleDropComponent = (effect, { component, delta }) =>
  ({ type: ADD_SIDEBAR_COMPONENT, value: { component, index: delta } });
