import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRODUCTION } from '../../constants/env';
import Loader from '../ui/Loader';
import Dialog from './common/Dialog';
import {
  FIELD_NAME, FIELD_USER, FIELD_ARTICLE, FIELD_PUBLICATION,
  FIELD_TITLE, FIELD_TRACKING_TAG, FIELD_URL, FIELD_IMAGE,
} from '../../constants/viafouraNotification/viafouraNotificationFields';
import { setLocalProp } from '../../actions/dataState';
import ViafouraNotificationsList from '../viafoura/ViafouraNotificationsList';
import ViafouraNotificationForm from '../viafoura/ViafouraNotificationForm';

const ViafouraNotificationDialog = (props) => {
  const {
    user,
    articles,
    fieldArticle,
    selectedPublication,
    isFetching,
    mode,
    setLocalProp: setProp,
    ...rest
  } = props;

  const article = articles.find(a => a.id === fieldArticle);
  const image = article?.hero?.find(hero => hero.type === 'image')?.data;

  const liveArticleUrl = () => {
    const frontEndUrl = mode === PRODUCTION
      ? selectedPublication.url
      : selectedPublication.environment.web;
    return (`${frontEndUrl.replace(/\/$/, '')}${article.path}`);
  };

  useEffect(() => {
    setProp(FIELD_NAME, article.titleSocial);
    setProp(FIELD_USER, user.uid);
    setProp(FIELD_ARTICLE, article.id);
    setProp(FIELD_PUBLICATION, selectedPublication.id);
    setProp(FIELD_TRACKING_TAG, '');
    setProp(FIELD_URL, liveArticleUrl());
    setProp(FIELD_IMAGE, image.url);
    setProp(FIELD_TITLE,
      selectedPublication.publicationConfig.viafoura.defaultTitle || selectedPublication.name);
  }, []);

  return (<>
    <Dialog title={'Viafoura Notifications'} maxWidth={'md'} {...rest}>
      <ViafouraNotificationForm />
      <ViafouraNotificationsList />
      {isFetching && <Loader />}
    </Dialog>
  </>);
};

ViafouraNotificationDialog.defaultProps = {
  isFetching: false,
  mode: PRODUCTION,
};

ViafouraNotificationDialog.propTypes = {
  user: PropTypes.object.isRequired,
  selectedPublication: PropTypes.object.isRequired,
  fieldArticle: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  articles: PropTypes.array.isRequired,
  mode: PropTypes.string,
  setLocalProp: PropTypes.func.isRequired,
};

export default (connect(({
  login: { user },
  frame: { selectedPublication },
  viafouraNotificationsList: { isFetching },
  dataState: { [FIELD_ARTICLE]: fieldArticle },
  article: { articles },
  dashboard: { mode },
}) => ({
  user,
  selectedPublication,
  isFetching,
  fieldArticle,
  articles,
  mode,
}), {
  setLocalProp,
})(ViafouraNotificationDialog));
