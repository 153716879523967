import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';


import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

import Dialog from './common/Dialog';
import TextField from '../ui/TextField';
import { getClassName } from '../../utils/propHelper';
import Button from '../ui/buttons/Button';
import Select from '../ui/Select';
import IconButton from '../ui/buttons/IconButton';
import { CMS_DATE_FORMAT } from '../../constants/common';

const style = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  legal: {
    '& $caption': {
      color: theme.palette.error.main,
    },
  },
  title: {
    margin: '0',
    color: theme.palette.primary.main,
  },
  select: {
    textTransform: 'capitalize',
  },
  caption: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  list: {
    '& .MuiListItem-gutters': {
      padding: 0,
    },
  },
  icons: {
    opacity: 0,
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  row: {
    '&:hover $icons': {
      opacity: 1,
    },
  },
  buttons: {
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
  notes: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      display: 'none',
    },
  },
});

const typeOpts = ['normal', 'legal'];

const NoteEditor = withStyles(style)((
  { classes, user, onSave, onCancel, state, editMode, actionLabel }) => {
  const [type, setType] = useState(state.type);
  const [note, setNote] = useState(state.note);
  return (<>
    <Grid container alignItems={'center'} spacing={2} className={classes.root}>
      {!editMode && <Grid item xs={12}>
        <Typography variant={'h2'} className={classes.title}>New note</Typography>
      </Grid>}
      <Grid item xs={10}>
        <TextField
          margin={'none'}
          value={note}
          label={'Note'}
          multiline
          onChange={e => setNote(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          className={classes.select}
          margin={'none'}
          label={'Note Type'}
          items={typeOpts}
          value={type}
          onChange={e => setType(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify={'flex-end'} className={classes.buttons}>
          {editMode && <Button
            variant={'outlined'}
            onClick={() => {
              setNote('');
              onCancel();
            }}
          >Cancel</Button>}
          <Button
            variant={'contained'}
            disabled={!note}
            onClick={() => {
              onSave({
                ...state,
                note,
                type,
                user: {
                  uid: user.uid,
                  name: user.name,
                  image: user.imageUrl,
                },
                timestamp: moment().unix(),
              });
              setNote('');
              setType(typeOpts[0]);
            }}
          >{actionLabel}</Button>
        </Grid>
      </Grid>
    </Grid>
    <Divider />
  </>);
});

const NotesDialog = (props) => {
  const {
    classes,
    onSave,
    onDelete,
    user,
    notes,
    ...rest
  } = props;
  const [editId, setEditId] = useState(null);

  const initial = {
    note: '',
    type: typeOpts[0],
  };

  return (<Dialog title={'Notes'} maxWidth={'md'} {...rest}>
    <NoteEditor user={user} state={initial} onSave={onSave} actionLabel={'Add note'} />
    {notes.length > 0 &&
    <Grid item xs={12} className={classes.notes} >
      <Typography variant={'h2'} className={classes.title}>Notes</Typography>
      <List className={classes.list}>
        {[...notes].reverse().map(noteEntry => ([
          <>
            <ListItem
              key={`top${noteEntry.timestamp}`}
              alignItems={'flex-start'}
              className={
                getClassName({
                  [classes.row]: true,
                  [classes.legal]: noteEntry.type === 'legal',
                })}
            >
              <Grid container>
                <Grid item xs={editId === noteEntry.id ? 12 : 11}>
                  <Grid container>
                    <ListItemAvatar>
                      <Avatar alt={noteEntry.user.name} src={noteEntry.user.image} />
                    </ListItemAvatar>
                    <Grid item>
                      <Typography variant={'body1'}>{noteEntry.user.name}</Typography>
                      <Typography variant={'caption'}>{moment.unix(noteEntry.timestamp).format(
                        CMS_DATE_FORMAT)}</Typography>
                    </Grid>
                  </Grid>
                  {editId !== noteEntry.id && <Grid item xs={12}>
                    <ListItemText
                      primary={noteEntry.note}
                    />
                    <Typography variant={'caption'} className={classes.caption}>{noteEntry.type}</Typography>
                  </Grid>}
                  {editId === noteEntry.id && <Grid container xs={12}>
                    <NoteEditor
                      editMode
                      actionLabel={'Save'}
                      user={user}
                      state={noteEntry}
                      onSave={(newState) => {
                        onSave(newState);
                        setEditId(null);
                      }}
                      onCancel={() => {
                        setEditId(null);
                      }}
                    />
                  </Grid>}
                </Grid>
                {editId !== noteEntry.id && <Grid xs={1} container alignContent={'center'} justify={'flex-end'} className={classes.icons}>
                  <IconButton onClick={() => setEditId(noteEntry.id)}><EditOutlined /></IconButton>
                  <IconButton
                    onClick={() =>
                      window.confirm('Please confirm you want to remove this note') && onDelete(noteEntry.id)
                    }
                  ><DeleteOutlined /></IconButton>
                </Grid>}
              </Grid>
            </ListItem>
            <Divider component={'li'} className={classes.divider} />
          </>,
        ]))}
      </List>
    </Grid>
    }
  </Dialog>);
};

NotesDialog.defaultProps = {
  notes: [],
};

NotesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  notes: PropTypes.array,
};

export default withStyles(style)(connect(({
  login: { user },
}) => ({ user }), {
})(NotesDialog));
