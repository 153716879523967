import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import Grid from '@material-ui/core/Grid/Grid';
import EditIcon from '@material-ui/icons/Edit';

import { fetchStoryList, disposeStoryList } from '../../../actions/story';

import OpenInNewTab from 'components/ui/buttons/OpenInNewTabButton';
import Button from 'components/ui/buttons/Button';
import IconButton from 'components/ui/buttons/IconButton';
import { TableBody, TableRow, TableCell } from 'components/ui/table/Table';

import ListTable from 'components/table/ListTable';
import TableHeader from 'components/table/TableHeader';
import ThumbnailImage from 'components/table/content/ThumbnailImage';
import {
  getScheduledDisplayStatus, getScheduledDisplayDate, getIsPublished,
} from 'components/helper/scheduler';
import { slugify } from 'utils/urlHelper';

const StoryListTable = ({ textQuery, status, fromDate, toDate, author, selectOnly, onSelect }) => {
  const { stories, total, isFetching } = useSelector(state => state.storyList);
  const { pathname } = useSelector(state => state.router?.location || {});
  const queryData = [
    textQuery,
    status,
    fromDate,
    toDate,
    author,
    pathname,
  ];
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const handleFetch = data => dispatch(fetchStoryList(data));
  const handleEditClick = id => () => dispatch(push(`/story/edit/${id}`));

  const getFetchData = (page, rowsPerPage) => ({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    q: textQuery,
    authors: author && [author.id],
    start: fromDate,
    end: toDate,
    status,
  });

  useEffect(() => () => {
    dispatch(disposeStoryList());
  }, [dispatch]);
  useEffect(() => {
    if (onSelect) {
      onSelect(selected);
    }
  }, [onSelect, selected]);

  const headers = ['Story Cover', 'Name', 'Author', 'Pages', 'Status', 'Updated/Scheduled', ''];

  return (
    <ListTable
      isFetching={isFetching}
      total={total}
      queryData={queryData}
      getFetchData={getFetchData}
      fetchList={handleFetch}
    >
      <TableHeader headers={headers.map(header => ({ id: header, label: header }))} hasBorder />
      <TableBody>
        {stories.length === 0 && <TableRow>
          <TableCell colSpan={headers.length} align={'center'}>No stories found</TableCell>
        </TableRow>}
        {stories.length > 0 && stories
          .map(({ id, authors, name: title, updated, publish, unpublish, cover, pageCount, path }) =>
            (<TableRow key={id} hasActions selected={!!selected[id]}>
              <TableCell><ThumbnailImage image={cover.image} /></TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>{Array.isArray(authors) && authors.map(({ name }) => name).join(', ')}</TableCell>
              <TableCell>{pageCount}</TableCell>
              <TableCell>{getScheduledDisplayStatus(
                getIsPublished(publish, unpublish),
                publish,
              )}</TableCell>
              <TableCell>{getScheduledDisplayDate(
                getIsPublished(publish, unpublish),
                updated,
                publish,
              )}</TableCell>
              <TableCell>
                <Grid container wrap={'nowrap'} justify={'flex-end'}>
                  {!selectOnly && <IconButton
                    onClick={handleEditClick(id)}
                  >
                    <EditIcon />
                  </IconButton>}
                  {!selectOnly && getIsPublished(publish, unpublish) && <OpenInNewTab
                    target={path || `/visual-story/${slugify(title)}-st${id}.html`}
                  />}
                  {selectOnly && <Button
                    onClick={() => {
                      if (!selected[id]) {
                        setSelected({
                          ...selected,
                          [id]: title,
                        });
                      } else {
                        delete selected[id];
                        setSelected({ ...selected });
                      }
                    }}
                    variant={'contained'}
                  >{selected[id] ? 'Deselect' : 'Select'}</Button>}
                </Grid>
              </TableCell>
            </TableRow>),
          )
        }
      </TableBody>
    </ListTable>
  );
};

StoryListTable.defaultProps = {
  textQuery: '',
  status: '',
  fromDate: '',
  toDate: '',
  author: '',
  selectOnly: false,
  onSelect: null,
};

StoryListTable.propTypes = {
  textQuery: PropTypes.string,
  status: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  author: PropTypes.string,
  selectOnly: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default StoryListTable;
