import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    position: 'relative',
    flexBasis: '100%',
    '& hr': {
      border: 0,
      height: 1,
      background: '#333',
      margin: theme.spacing(1, 0),
    },
  },
});

const Separator = (props) => {
  return (<Grid className={props.classes.root}>
    <Typography component={'hr'} />
  </Grid>);
};

Separator.defaultProps = {
  removeAble: false,
};

Separator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Separator);

