export const FEATURE_ARTICLE_CREATION = 'articlecreation';
export const FEATURE_CONTACT_US = 'contactus';
export const FEATURE_EDITION = 'edition';
export const FEATURE_LIVEBLOGS = 'liveblogs';
export const FEATURE_MANUALLIST_AUTHOR_IMAGE = 'manuallistauthorimage';
export const FEATURE_PREMIUM_AD_REPLACEMENT = 'premiumadreplacement';
export const FEATURE_HIDE_DEDICATED_LIVEBLOG_KEYPOINT = 'hidededicatedliveblogkeypoint';
export const FEATURE_ATEX_TOGGLE_ON = 'atextoggleon';
export const FEATURE_PREMIUM_TOGGLE_ON = 'premiumtoggleon';
export const FEATURE_EMBARGO_TOGGLE_ON = 'embargotoggleon';
export const FEATURE_VIDEO = 'video';
export const FEATURE_GALLERY = 'gallery';
export const FEATURE_STORIES = 'visualstory';
export const FEATURE_LIVEBLOG_YOUTUBE = 'liveblogyoutube';
export const FEATURE_EVENTS = 'events';
export const FEATURE_AUTHOR_PROFILE = 'authorprofile';
export const FEATURE_USER_EVENTS = 'userevents';
export const FEATURE_USER_COMMENTS = 'usercomments';

// Feature regex @todo replace these with just using string const
export const videoRegex = new RegExp(FEATURE_VIDEO);
export const galleryRegex = new RegExp(FEATURE_GALLERY);
export const liveblogYoutubeRegex = new RegExp(FEATURE_LIVEBLOG_YOUTUBE, 'i');

// Third party integrations
export const FEATURE_PLAY_INC = 'playinc';
