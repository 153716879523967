import { setDefaults, HighlightPlugin } from '@udecode/slate-plugins';
import { DEFAULTS_SEARCH_REPLACE } from './defaults';

const SearchReplacePlugin = (options) => {
  const { searchReplace } = setDefaults(options, DEFAULTS_SEARCH_REPLACE);

  return {
    ...HighlightPlugin({ highlight: searchReplace }),
  };
};

export default SearchReplacePlugin;
