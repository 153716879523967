import React from 'react';

import { ContextBarBody } from '../../common/context/ContextBar';
import Status from './storySettings/Status';
import Display from './storySettings/Display';

const StorySettings = () => (
  <ContextBarBody>
    <Status />
    <Display />
  </ContextBarBody>
);

export default StorySettings;
