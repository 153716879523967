import {
  LAYOUT_ADD_COMPONENT,
  LAYOUT_COMPONENT_DRAG_END,
  LAYOUT_COMPONENT_DRAG_START,
  LAYOUT_COMPONENT_IMAGE_SELECTED,
  LAYOUT_COMPONENT_SET_PROPERTY,
  LAYOUT_CONTEXT_SET,
  LAYOUT_CONTEXT_SET_FOCUS,
  LAYOUT_CONTEXT_SET_TARGET_INDEX,
  LAYOUT_DELETE,
  LAYOUT_PREVIEW_DOCK,
  LAYOUT_PREVIEW_MODE_SET,
  LAYOUT_PREVIEW_UNDOCK,
  LAYOUT_REMOVE_COMPONENT,
  LAYOUT_SAVE,
  LAYOUT_SELECT_MANUAL_LIST,
  LAYOUT_SELECT_MANUAL_LIST_GET_COUNT,
  LAYOUT_SET_MANUAL_LIST_DEFAULT,
  LAYOUT_SET_MANUAL_LIST_LINK,
  LAYOUT_SORT_COMPONENT,
  LAYOUT_TERM_SELECTED,
} from '../constants/actionTypes/layout';
import { PAGE_ENTER_LAYOUT, PAGE_LEAVE_LAYOUT } from '../constants/actionTypes/route';

export const onLayoutPageEnter = (term, id) => ({ type: PAGE_ENTER_LAYOUT, value: { term, id } });
export const onLayoutPageLeave = () => ({ type: PAGE_LEAVE_LAYOUT });

// deleting is currently unused but might be added later
export const deleteLayout = (type, id) => ({ type: LAYOUT_DELETE, value: { type, id } });

export const removeComponent = index => ({ type: LAYOUT_REMOVE_COMPONENT, value: index });
export const sortComponent = (oldIndex, newIndex) =>
  ({ type: LAYOUT_SORT_COMPONENT, value: { oldIndex, newIndex } });

export const componentSetProp = (componentId, prop, value, quiet = false) =>
  ({ type: LAYOUT_COMPONENT_SET_PROPERTY, value: { componentId, prop, value, quiet } });

export const setTermForLayout = value => ({ type: LAYOUT_TERM_SELECTED, value });
export const onLayoutSave = () => ({ type: LAYOUT_SAVE });
export const layoutComponentOnImageSelect = value => ({ type: LAYOUT_COMPONENT_IMAGE_SELECTED, value });
export const dockLayoutPreview = () => ({ type: LAYOUT_PREVIEW_DOCK });
export const undockLayoutPreview = () => ({ type: LAYOUT_PREVIEW_UNDOCK });
export const setLayoutPreviewMode = value => ({ type: LAYOUT_PREVIEW_MODE_SET, value });
export const setLayoutContext = (type, data) => ({ type: LAYOUT_CONTEXT_SET, value: { type, data } });
export const setLayoutContextTarget = value => ({ type: LAYOUT_CONTEXT_SET_TARGET_INDEX, value });
export const setLayoutContextFocus = value => ({ type: LAYOUT_CONTEXT_SET_FOCUS, value });
export const onComponentDragStart = () => ({ type: LAYOUT_COMPONENT_DRAG_START });
export const onComponentDragEnd = () => ({ type: LAYOUT_COMPONENT_DRAG_END });
export const selectComponentManualList = (listId, index = -1) => ({ type: LAYOUT_SELECT_MANUAL_LIST, value: { listId, index } });
export const componentManualListSetDefault = value => ({ type: LAYOUT_SET_MANUAL_LIST_DEFAULT, value });
export const componentManualListSetLink = value => ({ type: LAYOUT_SET_MANUAL_LIST_LINK, value });
export const getComponentManualListCount = value => ({ type: LAYOUT_SELECT_MANUAL_LIST_GET_COUNT, value: { listId: value } });
export const handleDropComponent = (effect, { component, delta }) =>
  ({ type: LAYOUT_ADD_COMPONENT, value: { component, index: delta } });
