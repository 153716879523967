import {
  PLAYINC_FETCH_VIDEOS,
  PLAYINC_DISPOSE_VIDEOS,
  PLAYINC_FETCH_PLAYLISTS,
  PLAYINC_DISPOSE_PLAYLISTS,
  PLAYINC_SAVE_VIDEO,
} from '../../constants/actionTypes/integration/playInc';

export const fetchPlayIncVideos = value => ({ type: PLAYINC_FETCH_VIDEOS, value });
export const disposePlayIncVideos = () => ({ type: PLAYINC_DISPOSE_VIDEOS });
export const fetchPlayIncPlaylists = () => ({ type: PLAYINC_FETCH_PLAYLISTS });
export const disposePlayIncPlaylists = () => ({ type: PLAYINC_DISPOSE_PLAYLISTS });
export const savePlayIncVideo = (data, id) => ({ type: PLAYINC_SAVE_VIDEO, value: { data, id } });
