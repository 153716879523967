import React from 'react';

import ContextBar from '../../common/context/ContextBar';
import ArticleStatus from './ArticleStatus';
import StarRating from './articleBuilder/StarRating';

const Sidebar = () => (
  <ContextBar>
    <ArticleStatus />
    <StarRating />
  </ContextBar>
);

export default Sidebar;
