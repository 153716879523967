import React from 'react';
import PropTypes from 'prop-types';

import ToggleSwitch from '../../../../ui/ToggleSwitch';

const ShowRelatedLinksField = (props) => {
  const { value, onChange } = props;
  return (
    <ToggleSwitch
      value={value}
      label={'Related Links'}
      onChange={onChange}
    />
  );
};

ShowRelatedLinksField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
};

ShowRelatedLinksField.defaultProps = {
  value: false,
};

export default ShowRelatedLinksField;
