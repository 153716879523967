import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';

import { setLocalProp } from '../../../../actions/dataState';

import { FIELD_SUB_HEADLINE } from '../../../../constants/article/articleFields';
import {
  deserializePlainText,
  serializeNodes,
} from '../../../editor/helper/serializer';
import EditorSimple from '../../../editor/EditorSimple';
import {
  makeGetFieldHasChanges,
  makeGetFieldLockValue,
  makeGetFieldValue,
} from '../../../../selectors/fieldLock/fieldLock';
import { PagePanelContent, PagePanelHeading, PagePanelSection } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';
import FieldLockContainer from '../../../fieldLock/FieldLockContainer';
import { MAX_LENGTH_SUB_HEADLINE } from '../../../../constants/article/article';

const SubHeadline = ({
  subHeadline, lockValue, hasChanges,
  setLocalProp: setProp,
}) => {
  const [valid, setValid] = useState(true);
  return (
    <PagePanelSection>
      <FieldLockContainer field={FIELD_SUB_HEADLINE} lockValue={lockValue}>
        <PagePanelHeading>
          <SectionTitle hasChanges={hasChanges}>Standfirst</SectionTitle>
        </PagePanelHeading>
        <PagePanelContent>
          {lockValue && <div dangerouslySetInnerHTML={{ __html: subHeadline }} />}
          {!lockValue && <EditorSimple
            id={'standfirst'}
            content={subHeadline ? deserializePlainText(subHeadline) : []}
            placeholder={'Standfirst'}
            onChange={(nodes) => {
              const markup = serializeNodes(nodes);
              if (markup.length < MAX_LENGTH_SUB_HEADLINE) {
                setValid(true);
                setProp(FIELD_SUB_HEADLINE, markup);
              } else {
                setValid(false);
              }
            }}
          />}
          {!valid && <FormHelperText error>Sub headline is too long</FormHelperText>}
        </PagePanelContent>
      </FieldLockContainer>
    </PagePanelSection>
  );
};

SubHeadline.propTypes = {
  setLocalProp: PropTypes.func.isRequired,
  subHeadline: PropTypes.string,
  lockValue: PropTypes.object,
  hasChanges: PropTypes.bool,
};

SubHeadline.defaultProps = {
  subHeadline: '',
  lockValue: null,
  hasChanges: false,
};

const mapStateToProps = (state) => {
  const getFieldValue = makeGetFieldValue();
  const getFieldLockValue = makeGetFieldLockValue();
  const getFieldHasChanges = makeGetFieldHasChanges();
  return {
    subHeadline: getFieldValue(state, { field: FIELD_SUB_HEADLINE }),
    lockValue: getFieldLockValue(state, { field: FIELD_SUB_HEADLINE }),
    hasChanges: getFieldHasChanges(state, { field: FIELD_SUB_HEADLINE }),
  };
};

export default connect(
  mapStateToProps,
  { setLocalProp },
)(SubHeadline);

