import { mergeMap, withLatestFrom, flatMap, filter } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';

import {
  VOCAB_FETCH_LAYOUT, VOCAB_FETCH_LAYOUT_SUCCESS, VOCAB_FETCH_LAYOUT_REJECTED,
} from '../../constants/actionTypes/vocab';

import { VOCAB_LAYOUTS, VOCAB_LAYOUTS_API } from '../../constants/vocab';

import apiCatchError from '../helper/notification';

export const fetchVocabLayout = (action$, state$) => action$.pipe(
  ofType(VOCAB_FETCH_LAYOUT),
  withLatestFrom(state$),
  filter(([, { frame: { selectedPublication: { domain } } }]) => domain),
  mergeMap(([{ value: vocab }, { frame: { selectedPublication: { domain } } }]) =>
    ajax.getJSON(`/api/layout/${VOCAB_LAYOUTS_API[vocab]}/${domain}`).pipe(
      flatMap(response => of({
        type: VOCAB_FETCH_LAYOUT_SUCCESS,
        value: {
          key: VOCAB_LAYOUTS[vocab],
          value: response,
        },
      })),
      apiCatchError(VOCAB_FETCH_LAYOUT_REJECTED),
    ),
  ),
);
