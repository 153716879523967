export const USER_EVENTS_FETCH = 'USER_EVENTS_FETCH';
export const USER_EVENTS_FETCH_SUCCESS = 'USER_EVENTS_FETCH_SUCCESS';
export const USER_EVENTS_FETCH_REJECTED = 'USER_EVENTS_FETCH_REJECTED';
export const USER_EVENTS_DISPOSE = 'USER_EVENTS_DISPOSE';

export const USER_EVENTS_DELETE = 'USER_EVENTS_DELETE';
export const USER_EVENTS_DELETE_SUCCESS = 'USER_EVENTS_DELETE_SUCCESS';
export const USER_EVENTS_DELETE_REJECTED = 'USER_EVENTS_DELETE_REJECTED';

export const USER_EVENTS_SAVE = 'USER_EVENTS_SAVE';
export const USER_EVENTS_SAVE_SUCCESS = 'USER_EVENTS_SAVE_SUCCESS';
export const USER_EVENTS_SAVE_REJECTED = 'USER_EVENTS_SAVE_REJECTED';

export const USER_EVENTS_SET_FILTER = 'USER_EVENTS_SET_FILTER';
