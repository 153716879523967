import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FTPForm from './FTPForm';

import { saveExportFTP } from '../../../actions/edition';
import ListFTP from './ListFTP';

const styles = {
  root: {

  },
};

const FTPSetting = ({ classes, saveExportFTP: onSubmit }) => (<Grid container direction={'column'}>
  <Grid item>
    <FTPForm onSubmit={onSubmit} />
    <ListFTP />
  </Grid>
</Grid>);

FTPSetting.defaultProps = {
};

FTPSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  saveExportFTP: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { saveExportFTP },
)(FTPSetting));

