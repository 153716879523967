export const CONTENT_TYPE_EDITION = 'edition';

export const EDITION_DAILY = 'daily';
export const EDITION_SPECIAL = 'special';
export const EDITION_EXPORT_SETTING = 'export-setting';
export const EDITION_IMPORT = 'import';

export const PAGE_TYPE_ARTICLE = 'article';
export const PAGE_TYPE_ARTICLE_CUSTOM = 'custom';
export const PAGE_TYPE_SINGLE_IMAGE = 'single-image';
export const PAGE_TYPE_SECTION = 'section';

export const VALID_PAGE_TYPES = [
  PAGE_TYPE_ARTICLE,
  PAGE_TYPE_SECTION,
  PAGE_TYPE_SINGLE_IMAGE,
];

export const EDITION_TYPES = {
  [EDITION_DAILY]: 'Daily Editions',
  [EDITION_SPECIAL]: 'Special Editions',
  [EDITION_EXPORT_SETTING]: 'Export Settings',
};

export const EDITION_EXPORT_FORMAT = {
  a4: 'A4',
  complete: 'Complete Edition',
  single: 'Single',
  xml: 'XML',
  json: 'JSON',
};

export const EDITION_EXPORT_TRANSPORT = {
  ftp: 'FTP',
  ssh: 'SSH',
  sftp: 'SFTP',
};
