import React from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 25,
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
};

const svg = `
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 26.6"><style>.st1{fill:none;stroke:#e7792b;stroke-width:2.1175;stroke-linecap:round}</style><path id="Combined-Shape" d="M12.8 0c7.7 0 12.7 6.2 12.7 13.2v.1c0 7-5.1 13.3-12.8 13.3S0 20.4 0 13.4v-.1C0 6.3 5.1 0 12.8 0zm-.1 1.7C6.4 1.7 2 6.9 2 13.2v.1c0 6.3 4.6 11.6 10.8 11.6s10.7-5.1 10.7-11.5v-.1c0-6.4-4.6-11.6-10.8-11.6z" fill="${grey[800]}"/><g id="Group" transform="translate(53.5206 8.1983)"><path id="Line-4" class="st1" d="M-40.8.4v9.5"/><path id="Line-4_1_" class="st1" d="M-36.1 5.2h-9.4"/></g></svg>
`;

const Stamp = ({ classes }) =>
  (<span className={classes.root} dangerouslySetInnerHTML={{ __html: svg }} />);

Stamp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Stamp);
