import { PURGE } from 'redux-persist';
import moment from 'moment';

import {
  FEED_SET_CONTEXT,
  FEED_TIMELINE_HANDLE_DROP,
  FEED_TIMELINE_ITEM_PROP_UPDATE,
  FEED_TIMELINE_REMOVE_ITEM,
  FEED_TIMELINE_SAVE,
  FEED_SAVE_SUCCESS,
  FEED_FETCH_ALL_LOADED,
} from '../../constants/actionTypes/feed';
import { DESC } from '../../constants/feed';
import {
  CONTEXT_ARTICLE_SELECTOR,
} from '../../constants/contexts';

const defaultState = {
  context: {
    type: CONTEXT_ARTICLE_SELECTOR,
    data: {},
  },
};

const defaultTimelineFeed = {
  items: [],
};

const compareDates = (a, b) => {
  const momentA = moment(a);
  const momentB = moment(b);
  if (momentA > momentB) return 1;
  else if (momentA < momentB) return -1;
  return 0;
};

const getDirection = direction => (direction === DESC ? -1 : 1);

const orderTimeline = (items, direction = DESC) => (
  items.sort((a, b) => compareDates(a.timeline.date, b.timeline.date) * getDirection(direction))
);

const addTimelineItem = (items, newItem, date, index) => {
  const orderedDate = date + index;
  const timelineItem = {
    ...newItem,
    timeline: {
      date: orderedDate,
    },
  };
  if (!Array.isArray(items)) {
    return [timelineItem];
  }
  return ([
    ...items.map((item) => {
      if (item.timeline.date >= date && item.timeline.date < moment(date).add(1, 'days').valueOf()) {
        return {
          ...item,
          timeline: {
            date: item.timeline.date >= orderedDate
              ? item.timeline.date + 1
              : item.timeline.date,
          },
        };
      }
      return item;
    }),
    timelineItem,
  ]);
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case FEED_SET_CONTEXT:
      return {
        ...state,
        context: action.value,
      };

    case FEED_SAVE_SUCCESS: {
      const { response: { id: [{ value: id }], name: [{ value: name }] } } = action.value;
      return {
        ...state,
        [name]: {
          ...state[name],
          id,
        },
      };
    }

    case FEED_FETCH_ALL_LOADED: {
      const feeds = {};
      for (let i = 0; i < action.value.length; i += 1) {
        const loadedFeed = action.value[i];
        feeds[loadedFeed.data.name] = {
          id: loadedFeed.data.id,
          ...loadedFeed.manualListSettings,
        };
      }
      return {
        ...state,
        ...feeds,
      };
    }

    case FEED_TIMELINE_REMOVE_ITEM: {
      const { id, date } = action.value;
      return {
        ...state,
        [id]: {
          ...state[id],
          updated: moment().unix(),
          items: state[id].items.filter(item => (item.timeline.date !== date)),
        },
      };
    }

    case FEED_TIMELINE_ITEM_PROP_UPDATE: {
      const { id, date, key, value } = action.value;
      return {
        ...state,
        [id]: {
          ...state[id],
          items: state[id].items.map((item) => {
            if (item.timeline.date === date) {
              if (typeof item.overrides === 'undefined') {
                item.overrides = {};
              }
              return {
                ...item,
                updated: moment().unix(),
                overrides: {
                  ...item.overrides,
                  [key]: value,
                },
              };
            }
            return item;
          }),
        },
      };
    }

    case FEED_TIMELINE_HANDLE_DROP: {
      const { id, date, index, item, source } = action.value;
      if (typeof state[id] === 'undefined') {
        state[id] = { ...defaultTimelineFeed };
      }
      const items = source !== null && typeof source !== 'undefined'
        ? state[id].items.filter(({ timeline }) => timeline.date !== source.timeline.date)
        : state[id].items;
      return {
        ...state,
        [id]: {
          ...state[id],
          updated: moment().unix(),
          items: orderTimeline(addTimelineItem(items, item, date, index)),
        },
      };
    }

    case FEED_TIMELINE_SAVE:
      return {
        ...state,
        [action.value]: {
          ...state[action.value],
          items: state[action.value].items.filter(item => (item.timeline.date >= moment().subtract(10, 'days').valueOf())),
        },
      };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
