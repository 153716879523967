import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Avatar from '@material-ui/core/Avatar';

import openDialogPublication, { closeDialogPublication, selectPublication } from '../../actions/dialog';

import PublicationSelectorDialog from '../dialog/PublicationSelectorDialog';

const style = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1',
  },
  textStyle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontSize: 14,
  },
  avatar: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: 5,
  },
  chevron: {
    color: theme.palette.primary.main,
  },
  publicationLogo: {
    display: 'flex',
    '& svg': {
      width: '110px',
    },
  },
  publicationButton: {
    padding: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    width: '100%',
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const PublicationSelector = ({
  classes,
  publication,
  selectPublication: onSelect,
  selectedPublication,
}) => {
  const [open, setOpen] = useState(false);
  return (<div className={classes.root}>
    <Button
      aria-haspopup="true"
      onClick={() => setOpen(true)}
      className={classes.publicationButton}
      variant={'outlined'}
    >
      <Typography component={'div'} variant={'body2'} color={'inherit'} className={classes.textStyle}>
        {selectedPublication.logoIcon && <Avatar
          className={classes.avatar}
          alt={selectedPublication.name}
          src={selectedPublication.logoIcon}
        />}
        {!selectedPublication.logoIcon && <Avatar className={classes.avatar} alt={selectedPublication.name} src={`https://www.google.com/s2/favicons?domain=${selectedPublication.domain}`} />}
        {selectedPublication.name}
      </Typography>
      {publication.length > 1 && <ArrowDropDown className={classes.chevron} />}
    </Button>
    {publication.length > 1 && open && <PublicationSelectorDialog
      onSelect={(item) => {
        onSelect(item);
        setOpen(false);
      }}
      open={open}
      handleClose={() => setOpen(false)}
    />}
  </div>);
};

PublicationSelector.defaultProps = {
  publication: [],
  selectedPublication: {
    name: '',
    logoSvg: '',
  },
};

PublicationSelector.propTypes = {
  publication: PropTypes.array,
  classes: PropTypes.object.isRequired,
  selectedPublication: PropTypes.object,
  selectPublication: PropTypes.func.isRequired,
};

export default withStyles(style)(connect(({
  frame: { selectedPublication, publication } }) => ({ selectedPublication, publication }), {
  openDialogPublication, closeDialogPublication, selectPublication,
})(PublicationSelector));
