import React, { useState } from 'react';
import { noop } from 'rxjs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';

import MarkupField from '../../../ui/MarkupField';

import {
  setArticleEditionProperty,
  setEditionLockField,
} from '../../../../actions/edition';

import { FIELD_SUBHEADLINE } from '../../../../constants/edition/editionFields';
import FieldLockField from '../../../fieldLock/FieldLockFieldDepricated';

const subHeadlineMaxLength = 2048;

const SubHeadline = ({
  subHeadline, sessionLockedFields, lockedFields,
  setArticleEditionProperty: setProperty, setEditionLockField: lockField,
}) => {
  const [valid, setValid] = useState(true);
  const lock = sessionLockedFields.filter(({ field }) => field === FIELD_SUBHEADLINE);
  const value = lock.length > 0 ? lock[0].value : subHeadline;
  const hasChanges = Object.keys(lockedFields).indexOf(FIELD_SUBHEADLINE) !== -1;
  return (
    <FieldLockField
      field={FIELD_SUBHEADLINE}
      onUnlock={() => {
        lockField([FIELD_SUBHEADLINE, subHeadline]);
      }}
    >
      {lock.length > 0 && <MarkupField hasChanges={hasChanges} data={value} onChange={noop} label={'Standfirst'} />}
      {lock.length < 1 && <MarkupField
        name={'subheadline'}
        label={'Standfirst'}
        hasChanges={hasChanges}
        data={value}
        placeholder={'Type your standfirst here'}
        onChange={(markup) => {
          if (markup.length < subHeadlineMaxLength) {
            setValid(true);
            setProperty([FIELD_SUBHEADLINE, markup]);
          } else {
            setValid(false);
          }
        }}
      />}
      {!valid && <FormHelperText error>Standfirst is too long</FormHelperText>}
    </FieldLockField>
  );
};

SubHeadline.propTypes = {
  subHeadline: PropTypes.string.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  lockedFields: PropTypes.object.isRequired,
  setArticleEditionProperty: PropTypes.func.isRequired,
  setEditionLockField: PropTypes.func.isRequired,
};

export default connect(
  ({
    edition: { subHeadline },
    fieldLock: { sessionLockedFields, lockedFields },
  }) => ({ subHeadline, sessionLockedFields, lockedFields }),
  { setArticleEditionProperty, setEditionLockField },
)(SubHeadline);

