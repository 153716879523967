import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Tabs, Tab } from '../../../ui/Tabs';

import { setLayoutContext } from '../../../../actions/layout';

import {
  CONTEXT_ARTICLE_SELECTOR,
  CONTEXT_COMPONENT_SELECTOR,
} from '../../../../constants/contexts';

import { ContextBarHeader } from '../../../common/context/ContextBar';

export const UI_HEIGHT = 40;

const contexts = [
  CONTEXT_ARTICLE_SELECTOR,
  CONTEXT_COMPONENT_SELECTOR,
];

const ContextUI = (props) => {
  const { type, setLayoutContext: setContext } = props;
  return (
    <ContextBarHeader>
      <Tabs
        value={contexts.indexOf(type) > -1 ? contexts.indexOf(type) : false}
        onChange={(e, val) => {
          setContext(contexts[val]);
        }}
        indicatorColor={'primary'}
        textColor={'primary'}
        variant={'fullWidth'}
      >
        <Tab label={'Articles'} />
        <Tab label={'Components'} />
      </Tabs>
    </ContextBarHeader>
  );
};

ContextUI.propTypes = {
  type: PropTypes.string.isRequired,
  setLayoutContext: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { setLayoutContext },
)(ContextUI);
