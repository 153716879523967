import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TagSelector from '../../ui/TagSelector';
import { FIELD_SECTION } from '../../../constants/story/storyFields';
import { SECTIONS } from '../../../constants/vocab';
import { updateStoryField } from '../../../actions/story';

const LABEL = 'Section';
const PLACEHOLDER = 'Search sections';
const VOCAB = SECTIONS;

const Authors = (props) => {
  const {
    values,
    updateStoryField: updateField,
  } = props;
  return (
    <TagSelector
      label={LABEL}
      placeholder={PLACEHOLDER}
      selectedTerms={values.filter(Boolean)}
      vocab={VOCAB}
      onSelect={value => updateField(FIELD_SECTION, [value[1]])}
      onRemove={() => updateField(FIELD_SECTION, [])}
      hierarchical
    />
  );
};

Authors.propTypes = {
  updateStoryField: PropTypes.func.isRequired,
  values: PropTypes.array,
};

Authors.defaultProps = {
  values: [],
};

export default connect(
  ({ story: { [FIELD_SECTION]: values } }) => ({ values }),
  { updateStoryField },
)(Authors);
