import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import {
  CONTENT_SOURCE_CUSTOM_SECTION,
  LABEL_LINK,
  LABEL_TEXT,
} from '../../constants';

import IconButton from '../../../ui/buttons/IconButton';
import VocabSelectorDialog from '../../../dialog/VocabSelectorDialog';
import { componentSetProp } from '../../../../actions/layout';

const CUSTOM_LIST_VOCAB = ['authors', 'sections', 'topics', 'chain'];

const styles = () => ({
  sourceOptions: {
    display: 'flex',
    margin: '-3px 0',
  },
  sourceButton: {
    textTransform: 'inherit',
  },
  icon: {
    width: '0.8em',
    height: '0.8em',
  },
});

const CustomListSelector = (props) => {
  const {
    classes,
    component,
    componentSetProp: setProp,
  } = props;

  const [open, openDialog] = useState(false);
  const customLists = component?.[CONTENT_SOURCE_CUSTOM_SECTION] || [];
  return (
    <div className={classes.sourceOptions}>
      {customLists.length > 0 && customLists.map((list, index) => (
        <Button
          className={classes.sourceButton}
          key={list.id}
          onClick={() => {
            customLists.splice(index, 1);
            setProp(component.id, CONTENT_SOURCE_CUSTOM_SECTION, customLists);
          }}
        >{list.name}</Button>
      ))}
      <IconButton onClick={() => openDialog(true)}>
        <AddIcon className={classes.icon} />
      </IconButton>
      {open && <VocabSelectorDialog
        handleClose={() => openDialog(false)}
        open={open}
        restrictVocab={CUSTOM_LIST_VOCAB}
        selectVocab={(val) => {
          customLists.push(val);
          setProp(component.id, CONTENT_SOURCE_CUSTOM_SECTION, customLists);
          if (customLists.length === 0) {
            setProp(component.id, LABEL_LINK, val.path);
            setProp(component.id, LABEL_TEXT, val.name);
          }
          openDialog(false);
        }}
      />}
    </div>
  );
};

CustomListSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default withStyles(styles)(connect(
  () => ({}),
  { componentSetProp },
)(CustomListSelector));
