import BlockquoteElement from './components/BlockquoteElement';

export const ELEMENT_BLOCKQUOTE = 'blockquote';
export const PROP_CITATION = 'cite';
export const PROP_MARKUP = 'markup';
export const PROP_ALIGN = 'align';

export const DEFAULTS_BLOCKQUOTE = {
  blockquote: {
    component: BlockquoteElement,
    type: ELEMENT_BLOCKQUOTE,
    rootProps: {
      className: 'slate-blockquote',
    },
  },
};
