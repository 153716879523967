import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'rxjs';

import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/Close';
import PreviewIcon from '@material-ui/icons/Visibility';
import ImageIcon from '@material-ui/icons/Image';

import IconButton from '../buttons/IconButton';
import ImageLibraryDialog from '../../dialog/ImageLibraryDialog';
import ImagePreview from './ImagePreview';
import { parseMediaDroppedData } from '../../helper/utils';
import {
  MEDIA_KEY_SOURCE_IDENTIFIER,
  MEDIA_SOURCE_CHP,
  VALID_IMAGE_EXTENSION,
} from '../../../constants/media/media';

import {
  fetchCHPMediaObject,
  saveMediaImage,
  saveMediaEdit,
} from '../../../actions/media';
import ImageEditDialog from '../../dialog/ImageEditDialog';
import DroppableWrapperMedia from '../DroppableWrapper';
import { optimiseImage } from '../../helper/media';

const styles = () => ({
  root: {
    position: 'relative',
  },
  image: {
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
      width: '100%',
    },
  },
  removeAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  offscreen: {
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  },
});

const ImageSelector = (props) => {
  const {
    classes, className, image, imageClassName, label,
    showEditDialog, onRemove, required, noImageRatio, onClose, uploadOnly,
    saveMediaEdit: saveEditedMedia,
    selectImage: onSelectImage,
    saveMediaImage: saveImage,
    fetchCHPMediaObject: fetchFromCHP,
  } = props;

  const [imagesToUpload, setImagesToUpload] = useState(null);

  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageEditDialogOpen, setImageEditDialogOpen] = useState(false);
  const [editableImage, setEditableImage] = useState(null);
  const [canEdit, setCanEdit] = useState(false);

  const inputEl = useRef(null);

  const hasImage = !!(image && image.url);

  useEffect(() => {
    if (image && image.url) {
      setEditableImage(image);
    } else if (Array.isArray(imagesToUpload) && imagesToUpload.length > 0) {
      setEditableImage(imagesToUpload[0]);
      setCanEdit(true);
      setImageEditDialogOpen(true);
    } else {
      setEditableImage(null);
      setCanEdit(false);
      setImageEditDialogOpen(false);
    }
  }, [image, imagesToUpload]);

  return (
    <Grid
      container
      className={className ? `${className} ${classes.root}` : classes.root}
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={(event) => {
        event.preventDefault();
        const droppedPayload = parseMediaDroppedData(event.dataTransfer.getData('Text'));
        if (!droppedPayload) {
          inputEl.current.files = event.dataTransfer.files;
          const customEvent = document.createEvent('UIEvents');
          customEvent.initUIEvent('change', true, true);
          inputEl.current.dispatchEvent(customEvent);
          return false;
        }
        switch (droppedPayload[MEDIA_KEY_SOURCE_IDENTIFIER]) {
          case MEDIA_SOURCE_CHP:
            fetchFromCHP(droppedPayload.id, (images) => {
              if (Array.isArray(images) && images.filter(({ binary }) => !!binary).length > 0) {
                setImagesToUpload(images.filter(({ binary }) => !!binary));
              }
            });
            break;
          default:
            // eslint-disable-next-line no-console
            console.warn('Unhandled drop source');
        }
      }}

    >
      <input
        type={'file'}
        accept={VALID_IMAGE_EXTENSION.join(',')}
        ref={inputEl}
        className={classes.offscreen}
        onChange={e => setImagesToUpload(e.target.files ? Array.from(e.target.files) : null)}
        multiple
      />
      {!hasImage && <DroppableWrapperMedia
        icon={<ImageIcon />}
        required={required}
        label={label}
        onClick={() => setImageDialogOpen(true)}
      />}
      {hasImage && (<div
        className={imageClassName ? `${imageClassName} ${classes.image}` : classes.image}
      >
        <ImagePreview
          url={optimiseImage(image.url, { width: 200 })}
          noImageRatio={noImageRatio}
          onClick={() => {
            setImageDialogOpen(true);
          }}
        />
        <div className={classes.removeAction}>
          {showEditDialog && <IconButton
            onClick={(e) => {
              setCanEdit(false);
              setImageEditDialogOpen(true);
              e.preventDefault();
              e.stopPropagation();
            }}
            color={'primary'}
          >
            <PreviewIcon />
          </IconButton>}
          {onRemove && <IconButton
            onClick={(e) => {
              onRemove(null);
              e.preventDefault();
              e.stopPropagation();
            }}
            color={'primary'}
          >
            <RemoveIcon />
          </IconButton>}
        </div>
      </div>)}
      {imageEditDialogOpen && <ImageEditDialog
        handleClose={() => {
          setImageEditDialogOpen(false);
          setCanEdit(false);
        }}
        open={imageEditDialogOpen}
        onSaveImage={(state) => {
          saveEditedMedia(state, savedMedia => {
            onSelectImage(savedMedia);
            if (
              !editableImage.mid &&
              Array.isArray(imagesToUpload) &&
              imagesToUpload.length > 1
            ) {
              // modifying the images array causes a next image upload
              setImagesToUpload(imagesToUpload.filter((imageToUpload,i) => i !== 0));
            } else {
              setImagesToUpload(null);
              setImageEditDialogOpen(false);
              setCanEdit(false);
            }
          });
        }}
        canEdit={canEdit}
        file={editableImage}
        mediaId={editableImage?.mid}
      />}
      {imageDialogOpen && <ImageLibraryDialog
        onSaveNewImage={(state) => {
          saveImage(state, onSelectImage);
        }}
        handleClose={() => {
          setImageDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        open={imageDialogOpen}
        selectImage={(data) => {
          onSelectImage(data);
          setImageDialogOpen(false);
          if (onClose) {
            onClose();
          }
        }}
        uploadOnly={uploadOnly}
      />}
    </Grid>
  );
};

ImageSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  image: PropTypes.object,
  imageClassName: PropTypes.string,
  label: PropTypes.string,
  context: PropTypes.string,
  showEditDialog: PropTypes.bool,
  required: PropTypes.bool,
  saveMediaEdit: PropTypes.func.isRequired,
  fetchCHPMediaObject: PropTypes.func.isRequired,
  selectImage: PropTypes.func,
  // remove image state from this component, if value is unset, no remove icon will be shown
  onRemove: PropTypes.func,
  // will pass new state for save image entity
  saveMediaImage: PropTypes.func.isRequired,
  noImageRatio: PropTypes.bool,
  onClose: PropTypes.func,
  uploadOnly: PropTypes.bool,
};

ImageSelector.defaultProps = {
  image: null,
  className: null,
  label: 'Image',
  imageClassName: null,
  onRemove: null,
  showEditDialog: false,
  required: false,
  noImageRatio: false,
  selectImage: noop,
  onClose: null,
  context: '',
  onChangeCaption: null,
  onChangeCopyright: null,
  uploadOnly: false,
};

export default withStyles(styles)(
  connect(
    () => ({}),
    {
      fetchCHPMediaObject,
      saveMediaEdit,
      saveMediaImage,
    },
  )(ImageSelector));

