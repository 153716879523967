import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';

import { componentSetProp } from '../../../../actions/layout';
import { AUTHOR, BIOGRAPHY } from '../../constants';

import { AUTHORS } from '../../../../constants/vocab';

import {
  ContextPanel,
  ContextPanelDetails,
  ContextPanelHeadingButton,
  ContextPanelSummary,
} from '../../../common/context/ContextPanel';
import VocabSelectorButton, { VARIANT_HEADING } from '../../../ui/components/VocabSelectorButton';
import TextField from '../../../ui/TextField';

const AuthorOptions = (props) => {
  const { component, componentSetProp: setProp } = props;
  return (
    <ContextPanel>
      <ContextPanelSummary>
        <ContextPanelHeadingButton>
          <VocabSelectorButton
            label={'Author'}
            vocab={AUTHORS}
            variant={VARIANT_HEADING}
            onChange={vocab => setProp(component.id, AUTHOR, vocab)}
          />
        </ContextPanelHeadingButton>
      </ContextPanelSummary>
      <ContextPanelDetails variant={'full'}>
        {component[AUTHOR] && <Typography component={'h3'}>{component[AUTHOR].name}</Typography>}
        <TextField
          multiline
          label={'Biography'}
          value={component[BIOGRAPHY]}
          onChange={e => setProp(component.id, BIOGRAPHY, e.target.value)}
        />
      </ContextPanelDetails>
    </ContextPanel>
  );
};

AuthorOptions.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(AuthorOptions);
