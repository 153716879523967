import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LoginForm from './common/LoginForm';
import MfaForm from './common/MfaForm';

const LoginMfaEnable = () => (
  <LoginForm noForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">
          Two-factor authentication
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MfaForm />
      </Grid>
    </Grid>
  </LoginForm>
);

export default LoginMfaEnable;
