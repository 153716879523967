import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';

import { requestFieldLockUnlock } from '../../actions/fieldLock';
import FieldLockOverlay from './FieldLockOverlay';

const styles = () => ({
  lock: {
    position: 'relative',
    '&:first-child > *': {
      marginTop: 0,
    },
    '&:last-child > *': {
      marginBottom: 0,
    },
  },
});

const getFieldLock = (lockedFields, currentField) => (
  Array.isArray(lockedFields)
    ? lockedFields.filter(({ field }) => field === currentField)
    : null
);

/**
 * @deprecated
 */
const FieldLockFieldDepricated = (props) => {
  const {
    classes, fullWidth, children,
    field, onUnlock, sessionLockedFields,
    className,
    requestFieldLockUnlock: unlock,
    ...rest
  } = props;
  const lock = getFieldLock(sessionLockedFields, field);
  return (
    <FormControl fullWidth={fullWidth} className={classes.lock} {...rest}>
      {children}
      {lock && lock.length > 0 && <FieldLockOverlay
        className={className}
        lock={lock[0]}
        onUnlock={() => {
          unlock(lock[0]);
          onUnlock();
        }}
      />}
    </FormControl>
  );
};

FieldLockFieldDepricated.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
  onUnlock: PropTypes.func.isRequired,
  sessionLockedFields: PropTypes.array.isRequired,
  requestFieldLockUnlock: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

FieldLockFieldDepricated.defaultProps = {
  fullWidth: true,
  className: null,
};

export default withStyles(styles)(connect(
  ({ fieldLock: { sessionLockedFields } }) => ({ sessionLockedFields }),
  { requestFieldLockUnlock },
)(FieldLockFieldDepricated));
