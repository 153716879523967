import { PURGE } from 'redux-persist';
import { arrayMove } from 'react-sortable-hoc';
import {
  COMPONENT_SIDEBAR_LOADED,
  REMOVE_SIDEBAR_COMPONENT,
  REORDER_SIDEBAR_COMPONENT,
  SIDEBAR_COMPONENTS,
  APP_LOAD,
  SIDEBAR_SAVE_REJECTED,
  SIDEBAR_FETCH_REJECTED,
  SIDEBAR_FETCH_LOADED,
  CURRENT_SIDEBAR_RESET,
  LAYOUT_SIDEBAR_SECTIONS_LOADED,
  SECTION_SIDEBAR_SELECTED,
  SIDEBAR_CONTEXT_SET,
  ADD_SIDEBAR_COMPONENT, SIDEBAR_COMPONENT_PROPERTY_ONCHANGE,
} from '../../constants/actionTypes';
import { CONTEXT_COMPONENT_SELECTOR } from '../../constants/contexts';
import generateKey from '../../utils/generateKey';
import { ERROR_SAVE_MESSAGE_RESET } from '../../constants/actionTypes/notification';

const defaultState = {
  components: [],
  sidebarSection: [],
  selectedSection: {},
  selectedComponents: [],
  error: [],
  errorSaveRejected: [],
  currentSidebarLayout: {},
  context: {
    type: CONTEXT_COMPONENT_SELECTOR,
    data: {},
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIDEBAR_COMPONENT_PROPERTY_ONCHANGE: {
      const [id, key, value] = action.value;
      return {
        ...state,
        selectedComponents: state.selectedComponents.map((component) => {
          const { id: compId } = component;
          if (id === compId) {
            return {
              ...component,
              [key]: value,
            };
          }
          return component;
        }),
      };
    }
    case ADD_SIDEBAR_COMPONENT: {
      const { index, component } = action.value;
      const componentWithId = {
        ...component,
        id: generateKey(),
      };
      if (index === 0) {
        return {
          ...state,
          selectedComponents: [componentWithId, ...state.selectedComponents],
        };
      }
      return {
        ...state,
        selectedComponents: [
          ...state.selectedComponents.slice(0, index),
          componentWithId,
          ...state.selectedComponents.slice(index),
        ],
      };
    }
    case SIDEBAR_CONTEXT_SET:
      return { ...state, context: action.value };

    case COMPONENT_SIDEBAR_LOADED:
      return { ...state, components: action.value };

    case REMOVE_SIDEBAR_COMPONENT:
      return {
        ...state,
        selectedComponents: state.selectedComponents
          .filter((v, i) => i !== action.value),
      };

    case REORDER_SIDEBAR_COMPONENT:
      return {
        ...state,
        selectedComponents: arrayMove(state.selectedComponents, action.value[0], action.value[1]),
      };

    case SIDEBAR_COMPONENTS:
      return { ...state, selectedComponents: action.value };

    case APP_LOAD:
      return { ...state, error: [] };

    case ERROR_SAVE_MESSAGE_RESET:
      return { ...state, errorSaveRejected: defaultState.errorSaveRejected };

    case SIDEBAR_SAVE_REJECTED:
      return { ...state, errorSaveRejected: [action.value] };

    case SIDEBAR_FETCH_REJECTED:
      return { ...state, error: [action.value] };

    case SIDEBAR_FETCH_LOADED:
      return { ...state, currentSidebarLayout: action.value };

    case CURRENT_SIDEBAR_RESET:
      return {
        ...state,
        currentSidebarLayout: defaultState.currentSidebarLayout,
        selectedComponents: defaultState.selectedComponents
      };

    case LAYOUT_SIDEBAR_SECTIONS_LOADED:
      return { ...state, sidebarSection: action.value };

    case SECTION_SIDEBAR_SELECTED:
      return { ...state, selectedSection: action.value };

    case PURGE:
      return defaultState;

    default:
      return state;
  }
};
