import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    '& > div': {
      width: '100%',
    },
  },
  rootValue: {
    flexGrow: 1,
    display: 'flex',
    '& > div': {
      width: 'calc(100% - 30px)',
    },
    '& > button': {
      padding: 5,
      margin: '10px 0',
    },
  },
  removeIcon: {

  },
  input: {
    display: 'flex',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  placeholder: {
    position: 'absolute',
    left: 14,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
});

const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = props => (
  <TextField
    fullWidth
    margin={'dense'}
    variant={'outlined'}
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps
      },
    }}
    {...props.selectProps.textFieldProps}
  />
);

const Option = props => (
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component={'div'}
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
);

const Placeholder = props => (
  <Typography
    color={'textSecondary'}
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = props => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = props => (
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>
);

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
);

const IndicatorSeparator = () => null;

const ClearIndicator = props => (
  <IconButton {...props.innerProps}>
    <RemoveIcon />
  </IconButton>
);

const DropdownIndicator = props => (
  <IconButton {...props.innerProps}>
    <ArrowDropDownIcon />
  </IconButton>
);

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={props.isFocused ? props.selectProps.classes.chip :
      [props.selectProps.classes.chipFocused, props.selectProps.classes.chip].join(' ')
    }
    onDelete={props.removeProps.onClick}
    deleteIcon={<RemoveIcon {...props.removeProps} />}
  />
);

const LoadingIndicator = () => <CircularProgress size={20} />;

const LoadingMessage = props => (
  <Typography
    color={'textSecondary'}
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SelectAutocomplete = (props) => {
  const {
    classes, label, loadOptions, defaultOptions, value, onChange,
    ...rest
  } = props;

  const filterOptions = inputValue =>
    new Promise((resolve, reject) => {
      if (Array.isArray(defaultOptions)) {
        resolve(
          defaultOptions.filter(
            option =>
              inputValue &&
              option.label
                .toLowerCase()
                .includes(inputValue.toLowerCase()),
          ),
        );
      } else {
        reject('Autocomplete options not found.');
      }
    });

  // @todo rework with redux epic pattern, ensure data disposed
  return (
    <div className={value ? classes.rootValue : classes.root}>
      <AsyncSelect
        value={value}
        onChange={onChange}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
        }}
        loadOptions={loadOptions || filterOptions}
        defaultOptions={defaultOptions}
        classes={classes}
        {...rest}
      />
      {value && <IconButton className={classes.removeIcon} onClick={() => onChange({})}><RemoveIcon /></IconButton>}
    </div>
  );
};

SelectAutocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
};

SelectAutocomplete.defaultProps = {
  value: '',
  label: '',
  cacheOptions: true,
  defaultOptions: true,
  loadOptions: null,
  components: {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    MultiValue,
    ValueContainer,
    IndicatorSeparator,
    ClearIndicator,
    DropdownIndicator,
    LoadingIndicator,
    LoadingMessage,
  },
};

export default withStyles(styles)(SelectAutocomplete);
