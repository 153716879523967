import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { componentSetProp } from '../../../actions/layout';

import Button from '../../ui/buttons/Button';

import PlayIncVideoSelector from './PlayIncVideoSelector';
import { ArrowSortableDown, ArrowSortableList, ArrowSortableListItem, ArrowSortableUp } from '../../ui/ArrowSortable';
import IconButton from '../../ui/buttons/IconButton';
import { PLAYLIST_ID } from '../../layout/constants';

const LAYOUT = 'layout';
const VARTICLES = 'varticles';

const VIDEO_COUNT = {
  '3 articles': 3,
  '4 articles': 4,
  '8 articles': 8,
  '2 articles': 2,
  'hero + 3 articles': 4,
};

const PlayIncComponentContents = (props) => {
  const {
    component,
    componentSetProp: onChange,
  } = props;
  const {
    [PLAYLIST_ID]: playlist = '',
    [LAYOUT]: layout = '',
    [VARTICLES]: varticles = [],
  } = component;

  const [open, setOpen] = useState(false);
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (!!playlist || !layout || !VIDEO_COUNT[layout]) {
      setLimit(0);
      if (varticles.length > 0) {
        onChange(
          component.id,
          VARTICLES,
          [],
        );
      }
    } else if (VIDEO_COUNT[layout] < varticles.length) {
      setLimit(0);
      onChange(
        component.id,
        VARTICLES,
        varticles.slice(0, VIDEO_COUNT[layout]),
      );
    } else {
      setLimit(VIDEO_COUNT[layout] - varticles.length);
    }
  }, [layout, varticles, playlist]);

  return (
    <Grid container spacing={2}>
      {!playlist && varticles.length > 0 && <Grid item xs={12}>
        <ArrowSortableList>
          {varticles.map(({ title }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ArrowSortableListItem index={index} key={`varticle-${index}`} indexAvatar>
              <Typography variant={'body1'}>{title}</Typography>
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    onChange(
                      component.id,
                      VARTICLES,
                      varticles.filter((point, i) => i !== index),
                    );
                  }}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
                {index < varticles.length - 1 && <ArrowSortableDown
                  onClick={() => {
                    onChange(
                      component.id,
                      VARTICLES,
                      arrayMove(varticles, index, index + 1),
                    );
                  }}
                />}
                {index > 0 && <ArrowSortableUp
                  onClick={() => {
                    onChange(
                      component.id,
                      VARTICLES,
                      arrayMove(varticles, index, index - 1),
                    );
                  }}
                />}
              </ListItemSecondaryAction>
            </ArrowSortableListItem>
          ))}
        </ArrowSortableList>
      </Grid>}
      {!playlist && limit > 0 && <Grid item container justify={'center'}>
        <Button
          onClick={() => setOpen(true)}
          startIcon={<AddCircleOutlineIcon />}
        >Add video article(s)</Button>
      </Grid>}
      {open && <PlayIncVideoSelector
        handleClose={() => {
          setOpen(false);
        }}
        onSelect={(selected) => {
          onChange(
            component.id,
            VARTICLES,
            [
              ...varticles,
              ...selected,
            ],
          );
          setOpen(false);
        }}
        limit={limit}
        open={open}
      />}
    </Grid>
  );
};

PlayIncComponentContents.propTypes = {
  component: PropTypes.object.isRequired,
  componentSetProp: PropTypes.func.isRequired,
};

export default connect(
  () => ({}),
  { componentSetProp },
)(PlayIncComponentContents);
