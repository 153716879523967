import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import ImageWrap from '../../media/Image';

import { optimiseImage } from '../../helper/media';

const styles = theme => ({
  removeComponent: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 2,
  },
  previewHero: {},
  preview: {
    maxWidth: '208px',
  },
});

/**
 * @deprecated Only used for article revision diff display, no longer a functional component
 */
const GalleryEmbed = ({
  classes,
  hero,
  data,
}) => (
  <Grid container wrap={'nowrap'}>
    {data.mid && data.gallery.length > 0 &&
      <div className={hero ? classes.previewHero : classes.preview}>
        <ImageWrap
          captionEditable={false}
          {...data.gallery[0].data}
          caption={data.title}
          src={optimiseImage(data.gallery[0].data.url, { width: 300 })}
        />
      </div>
    }
  </Grid>
);

GalleryEmbed.propTypes = {
  classes: PropTypes.object.isRequired,
  hero: PropTypes.bool,
  data: PropTypes.object,
};

GalleryEmbed.defaultProps = {
  data: null,
  hero: false,
};

export default withStyles(styles)(GalleryEmbed);

