import React from 'react';

import ContextBar from '../common/context/ContextBar';
import StarRating from './articleSidebar/StarRating';
import ArticleSettings from './articleSidebar/ArticleSettings';

import ArticlePlatforms from './articleSidebar/ArticlePlatforms';
import CommercialAndSyndication from './articleSidebar/CommercialAndSyndication';
import ArticleStatus from './articleSidebar/ArticleStatus';
import SEOSettings from './articleSidebar/SEOSettings';
import ArticleNotes from './articleSidebar/ArticleNotes';
import ArticleLocation from './articleSidebar/ArticleLocation';
import ArticlePublications from './articleSidebar/ArticlePublications';
import StructuredData from './articleSidebar/StructuredData';

const ArticleSidebar = () => (<ContextBar>
  <ArticleNotes />
  <ArticleStatus />
  <ArticlePublications />
  <SEOSettings />
  <StarRating />
  <StructuredData />
  <ArticleLocation />
  <ArticleSettings />
  <ArticlePlatforms />
  <CommercialAndSyndication />
</ContextBar>);

export default ArticleSidebar;
