import { map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import {closeDialogPublication} from '../../actions/dialog';
import { PUBLICATION_SELECTED } from 'constants/actionTypes/publication';

const closePublicationDialog = (action$) => {
  return action$.pipe(
    ofType(PUBLICATION_SELECTED),
    map(() => closeDialogPublication())
  )
};

export default closePublicationDialog;
