import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Competition from './Competition';
import Match from './Match';
import { PagePanel, PagePanelHeading, PagePanelContent } from '../../../common/page/PagePanel';
import SectionTitle from '../../../common/SectionTitle';

const ScoreBoard = ({ selectedPublication }) => {
  if (!selectedPublication.publicationConfig.optaApiToken) return null;
  return (
    <PagePanel>
      <PagePanelHeading>
        <SectionTitle>Score board</SectionTitle>
      </PagePanelHeading>
      <PagePanelContent>
        <Competition />
        <Match />
      </PagePanelContent>
    </PagePanel>
  );
};

ScoreBoard.propTypes = {
  selectedPublication: PropTypes.object.isRequired,
};

export default connect(
  ({ frame: { selectedPublication } }) => ({ selectedPublication }),
  {},
)(ScoreBoard);

