import {
  PRODUCT_DISPOSE, PRODUCT_FETCH,
  PRODUCT_LIST_DISPOSE, PRODUCT_LIST_FETCH,
  PRODUCT_COMPARISON_DISPOSE, PRODUCT_COMPARISON_REQUEST, PRODUCT_SAVE,
  PRODUCT_AFFILIATE_LINK_CHECK, PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE,
} from '../constants/actionTypes/product';

export const fetchProduct = value => ({ type: PRODUCT_FETCH, value });
export const disposeProduct = () => ({ type: PRODUCT_DISPOSE });
export const saveProduct = () => ({ type: PRODUCT_SAVE });

export const fetchProductList = value => ({ type: PRODUCT_LIST_FETCH, value });
export const disposeProductList = value => ({ type: PRODUCT_LIST_DISPOSE, value });

export const productPriceCompare = value => ({ type: PRODUCT_COMPARISON_REQUEST, value });
export const productPriceComparisonDispose = value => ({ type: PRODUCT_COMPARISON_DISPOSE, value });

export const checkAffiliateLink = value => ({ type: PRODUCT_AFFILIATE_LINK_CHECK, value });
export const disposeAffiliateLinkCheck = () => ({ type: PRODUCT_AFFILIATE_LINK_CHECK_DISPOSE });
