import { ofType } from 'redux-observable';
import {
  mergeMap, switchMap, withLatestFrom, map,
} from 'rxjs/operators';

import { from } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router';
import apiCatchError, { showSuccessNotification } from '../helper/notification';

import {
  AD_REPLACEMENT_FETCH,
  AD_REPLACEMENT_ONSAVE, AD_REPLACEMENT_ONSAVE_READY,
  AD_REPLACEMENT_REJECTED,
  AD_REPLACEMENT_SAVE_REJECTED,
  AD_REPLACEMENT_SAVE_SUCCESS,
  AD_REPLACEMENT_SUCCESS,
  EDITORS_LETTER_FETCH,
  EDITORS_LETTER_ONSAVE,
  EDITORS_LETTER_REJECTED,
  EDITORS_LETTER_SAVE_REJECTED,
  EDITORS_LETTER_SAVE_SUCCESS,
  EDITORS_LETTER_SUCCESS,

} from '../../constants/actionTypes';
import { PREMIUM_AD_REPLACEMENT, PREMIUM_EDITORS_LETTER } from '../../constants/premium';
import { AdsReplacementsBreadcrumb, EditorLettersBreadcrumb } from '../../layouts/PremiumAd';
import { ERROR } from '../../constants/actionTypes/notification';
import { showNotification } from '../../actions/notification';

export const fetchPremiumLetter = (action$, state$) => action$.pipe(
  ofType(EDITORS_LETTER_FETCH, EDITORS_LETTER_SAVE_SUCCESS),
  withLatestFrom(state$),
  switchMap(([, { frame: { selectedPublication } }]) =>
    ajax.getJSON(`/api/revenue/premium_letter/${selectedPublication.domain}`).pipe(
      map(response => ({ type: EDITORS_LETTER_SUCCESS, value: response })),
      apiCatchError(EDITORS_LETTER_REJECTED),
    ),
  ),
);

export const fetchAdReplacement = (action$, state$) => action$.pipe(
  ofType(AD_REPLACEMENT_FETCH, AD_REPLACEMENT_SAVE_SUCCESS),
  withLatestFrom(state$),
  switchMap(([action, { frame: { selectedPublication } }]) =>
    ajax.getJSON(`/api/revenue/premium_ad/${selectedPublication.domain}`).pipe(
      mergeMap((response) => {
        const actions = [{ type: AD_REPLACEMENT_SUCCESS, value: response }];
        if (action.type === AD_REPLACEMENT_SAVE_SUCCESS) {
          actions.push(push(`${AdsReplacementsBreadcrumb[0].path}/${action.value.id[0].value}`));
        }
        return from(actions);
      }),
      apiCatchError(AD_REPLACEMENT_REJECTED),
    ),
  ),
);

export const editorsLetterSave = action$ => action$.pipe(
  ofType(EDITORS_LETTER_ONSAVE),
  switchMap(({ value }) => {
    const { id, name, heading, link, body, author } = value;
    const payload = {
      bundle: [
        {
          target_id: PREMIUM_EDITORS_LETTER,
          target_type: 'flowz_ad_type',
        },
      ],
      name: [{
        value: name,
      }],
    };

    if (id) {
      payload.id = [{
        value: Number(id),
      }];
      payload.field_heading = [{
        value: heading,
      }];
      payload.field_link = [{
        value: link,
      }];
      payload.field_body = [{
        value: body,
      }];

      if (author) {
        payload.field_author = [{
          target_id: author.id,
          target_type: 'taxonomy_term',
        }];
      } else {
        payload.field_author = [];
      }

      return ajax.patch(`/api/revenue/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => from([
          {
            type: EDITORS_LETTER_SAVE_SUCCESS,
            value: response,
          },
        ])),
        apiCatchError(EDITORS_LETTER_SAVE_REJECTED),
      );
    }

    return ajax.post('/api/revenue', payload, { 'Content-Type': 'application/json' }).pipe(
      mergeMap(({ response }) => from([
        {
          type: EDITORS_LETTER_SAVE_SUCCESS,
          value: response,
        },
        push(`/${EditorLettersBreadcrumb[0].path}/${response.id[0].value}`),
      ])),
      apiCatchError(EDITORS_LETTER_SAVE_REJECTED),
    );
  }),
);

export const adReplacementValidateSave = action$ => action$.pipe(
  ofType(AD_REPLACEMENT_ONSAVE),
  map(({ value }) => {
    const { addNew, MPU, DMPU } = value;
    if (!addNew && !(DMPU?.data?.mid || MPU?.data?.mid)) {
      return showNotification({
        message: 'Ad replacements must contain both MPU and DMPU',
        variant: ERROR,
      });
    }
    return ({
      type: AD_REPLACEMENT_ONSAVE_READY,
      value,
    });
  }),
);

export const adReplacementSave = action$ => action$.pipe(
  ofType(AD_REPLACEMENT_ONSAVE_READY),
  switchMap(({ value }) => {
    const { id, name, MPU, DMPU, link, category } = value;
    const payload = {
      bundle: [
        {
          target_id: PREMIUM_AD_REPLACEMENT,
          target_type: 'flowz_ad_type',
        },
      ],
      name: [{
        value: name,
      }],
    };

    if (id) {
      payload.id = [{
        value: Number(id),
      }];

      if (DMPU?.data) {
        payload.field_dmpu = [{
          target_id: DMPU.data.mid,
          target_type: 'media',
        }];
      }

      if (MPU?.data) {
        payload.field_mpu = [{
          target_id: MPU.data.mid,
          target_type: 'media',
        }];
      }

      if (link) {
        payload.field_premium_ad_link = [{
          value: link,
        }];
      }

      if (category) {
        payload.field_premium_ad_category = [{
          value: category,
        }];
      }

      return ajax.patch(`/api/revenue/${id}`, payload, { 'Content-Type': 'application/json' }).pipe(
        mergeMap(({ response }) => from([
          {
            type: AD_REPLACEMENT_SAVE_SUCCESS,
            value: response,
          },
        ])),
        apiCatchError(AD_REPLACEMENT_SAVE_REJECTED),
      );
    }

    return ajax.post('/api/revenue', payload, { 'Content-Type': 'application/json' }).pipe(
      map(({ response }) => ({
        type: AD_REPLACEMENT_SAVE_SUCCESS,
        value: response,
      })),
      apiCatchError(AD_REPLACEMENT_SAVE_REJECTED),
    );
  }),
);

export const showNotificationAfterSave = action$ => action$.pipe(
  ofType(AD_REPLACEMENT_SAVE_SUCCESS, EDITORS_LETTER_SAVE_SUCCESS),
  mergeMap(showSuccessNotification('Saved successfully')),
);
